const boundaries = [
  [-79.5870664, 47.4379882],
  [-79.586899, 47.4349557],
  [-79.5860133, 47.4297332],
  [-79.5832131, 47.4198988],
  [-79.5786359, 47.4111861],
  [-79.5695196, 47.3965684],
  [-79.5428544, 47.3687058],
  [-79.5331574, 47.3602028],
  [-79.5198969, 47.3502425],
  [-79.5140298, 47.3438681],
  [-79.5093853, 47.3376024],
  [-79.4966687, 47.3214563],
  [-79.486747, 47.3099233],
  [-79.4784467, 47.2984085],
  [-79.4654078, 47.2874069],
  [-79.449662, 47.2764589],
  [-79.4358133, 47.2591285],
  [-79.4343103, 47.2577056],
  [-79.428116, 47.2528687],
  [-79.4260252, 47.2504654],
  [-79.4254613, 47.2489167],
  [-79.4252776, 47.2470779],
  [-79.4252552, 47.2441826],
  [-79.4257355, 47.2415428],
  [-79.429734, 47.2331201],
  [-79.434273, 47.2267113],
  [-79.4360198, 47.2238121],
  [-79.4379203, 47.2194558],
  [-79.4386978, 47.2166519],
  [-79.4387588, 47.2134209],
  [-79.437805, 47.2048683],
  [-79.4364323, 47.1984787],
  [-79.4341049, 47.1900482],
  [-79.4333573, 47.1844978],
  [-79.4332817, 47.1818391],
  [-79.4342123, 47.1725503],
  [-79.4353326, 47.1663817],
  [-79.4414666, 47.1446418],
  [-79.4441149, 47.1322174],
  [-79.4460749, 47.1249999],
  [-79.4457992, 47.1109127],
  [-79.4453559, 47.1088995],
  [-79.4362264, 47.086834],
  [-79.4282005, 47.0761339],
  [-79.4193646, 47.0678748],
  [-79.3817061, 47.0341187],
  [-79.355347, 47.0160027],
  [-79.3506706, 47.0114023],
  [-79.3465806, 47.0066452],
  [-79.340469, 46.9946594],
  [-79.338494, 46.9899522],
  [-79.3377, 46.9863129],
  [-79.3382117, 46.970852],
  [-79.3327096, 46.9650839],
  [-79.3212349, 46.9422187],
  [-79.3169329, 46.9345703],
  [-79.3110126, 46.9283982],
  [-79.3010715, 46.9225274],
  [-79.2965463, 46.9190864],
  [-79.2925575, 46.9144898],
  [-79.2870486, 46.9059754],
  [-79.2845378, 46.9014663],
  [-79.2823559, 46.8991317],
  [-79.2810287, 46.8982353],
  [-79.2657474, 46.890007],
  [-79.2623603, 46.8877069],
  [-79.2570337, 46.8834495],
  [-79.2513817, 46.8769799],
  [-79.2442174, 46.8701058],
  [-79.2389294, 46.8644447],
  [-79.2344899, 46.8601991],
  [-79.2329257, 46.8573838],
  [-79.2299493, 46.847782],
  [-79.2285001, 46.8458785],
  [-79.2264707, 46.8438949],
  [-79.2234272, 46.8420403],
  [-79.2167894, 46.8364983],
  [-79.2159352, 46.8357125],
  [-79.2148439, 46.8337861],
  [-79.2139511, 46.8329467],
  [-79.2122188, 46.8318901],
  [-79.2104796, 46.8313332],
  [-79.2028045, 46.8306923],
  [-79.2003675, 46.8303239],
  [-79.1933389, 46.8284906],
  [-79.1885605, 46.8282128],
  [-79.186838, 46.8283517],
  [-79.1837819, 46.8291014],
  [-79.1807817, 46.8295459],
  [-79.1785421, 46.8295182],
  [-79.1773681, 46.8281822],
  [-79.1702958, 46.8245697],
  [-79.1688386, 46.8234291],
  [-79.1654585, 46.8199997],
  [-79.162727, 46.8154944],
  [-79.1584394, 46.8066062],
  [-79.1572031, 46.80484],
  [-79.1548393, 46.8023073],
  [-79.1520311, 46.798069],
  [-79.1483457, 46.7933578],
  [-79.1432426, 46.7850763],
  [-79.1418917, 46.7823716],
  [-79.1404192, 46.7784424],
  [-79.1384735, 46.7706527],
  [-79.1370247, 46.7677766],
  [-79.1317903, 46.7594299],
  [-79.1252746, 46.7504845],
  [-79.1226578, 46.7463646],
  [-79.1213385, 46.7431489],
  [-79.1213995, 46.738083],
  [-79.1210636, 46.7360802],
  [-79.1196143, 46.7329141],
  [-79.1180259, 46.7307547],
  [-79.1129067, 46.7251929],
  [-79.1041864, 46.7129745],
  [-79.1035316, 46.7125818],
  [-79.1009135, 46.7119941],
  [-79.0998075, 46.7106781],
  [-79.0994796, 46.7055283],
  [-79.0957343, 46.6973954],
  [-79.0942311, 46.6929246],
  [-79.0936208, 46.6894113],
  [-79.0929713, 46.6877632],
  [-79.0915686, 46.6865693],
  [-79.0873795, 46.6840896],
  [-79.0835721, 46.6804618],
  [-79.0822834, 46.6799203],
  [-79.0780022, 46.6791075],
  [-79.0764164, 46.6782914],
  [-79.07544, 46.6774445],
  [-79.0748747, 46.6763229],
  [-79.0737006, 46.6751901],
  [-79.0733343, 46.6740724],
  [-79.0751497, 46.6722069],
  [-79.0760044, 46.6709672],
  [-79.0764386, 46.6691284],
  [-79.0750352, 46.6671257],
  [-79.0723502, 46.664982],
  [-79.0506438, 46.6510353],
  [-79.0452732, 46.6465226],
  [-79.0430753, 46.6450996],
  [-79.0393442, 46.6432532],
  [-79.0325699, 46.6411667],
  [-79.0251842, 46.6380882],
  [-79.0214378, 46.6361426],
  [-79.0182566, 46.634159],
  [-79.0167771, 46.6329117],
  [-79.0159914, 46.6318092],
  [-79.0114287, 46.620121],
  [-79.0095217, 46.6126595],
  [-79.0077743, 46.6040916],
  [-79.0066604, 46.6024398],
  [-79.0052642, 46.6010132],
  [-78.9976573, 46.5957946],
  [-78.9962919, 46.5945549],
  [-78.9951482, 46.5928193],
  [-78.9947743, 46.5905533],
  [-78.9949639, 46.5891417],
  [-78.9967498, 46.5829925],
  [-78.9974512, 46.5791434],
  [-78.9975808, 46.5762824],
  [-78.9972607, 46.571453],
  [-78.9964751, 46.567028],
  [-78.9945226, 46.5607492],
  [-78.9910742, 46.5536042],
  [-78.9889836, 46.5482674],
  [-78.9871143, 46.5452386],
  [-78.9848715, 46.54348],
  [-78.9832455, 46.5425338],
  [-78.9710162, 46.5329667],
  [-78.9675143, 46.5273514],
  [-78.9661859, 46.5257529],
  [-78.961044, 46.5210685],
  [-78.9570391, 46.5183182],
  [-78.9453198, 46.511753],
  [-78.9423903, 46.5095863],
  [-78.9395826, 46.5069427],
  [-78.937813, 46.5048676],
  [-78.9340364, 46.4988861],
  [-78.932038, 46.4963647],
  [-78.9197764, 46.484661],
  [-78.9163203, 46.4809875],
  [-78.9132766, 46.4787254],
  [-78.9064327, 46.4744605],
  [-78.9021919, 46.4713327],
  [-78.8984369, 46.4680861],
  [-78.891929, 46.4615592],
  [-78.8882518, 46.4589347],
  [-78.8855825, 46.457554],
  [-78.87555, 46.4530832],
  [-78.870026, 46.4503098],
  [-78.8662035, 46.4478874],
  [-78.8617403, 46.4444389],
  [-78.857186, 46.4421655],
  [-78.8542328, 46.4411469],
  [-78.8450088, 46.4396171],
  [-78.8382029, 46.4372901],
  [-78.8307109, 46.4341621],
  [-78.8179552, 46.4292641],
  [-78.8039171, 46.4232254],
  [-78.7958755, 46.4192276],
  [-78.7941358, 46.4181709],
  [-78.7914047, 46.4156952],
  [-78.7900474, 46.4147798],
  [-78.7820279, 46.4106635],
  [-78.7687538, 46.4020806],
  [-78.7614667, 46.3966979],
  [-78.7576909, 46.3930703],
  [-78.755455, 46.392479],
  [-78.7481685, 46.3917235],
  [-78.7422413, 46.3904267],
  [-78.7393033, 46.3893852],
  [-78.7352985, 46.3876229],
  [-78.7329336, 46.3863449],
  [-78.7303853, 46.3846207],
  [-78.7275772, 46.381874],
  [-78.7256011, 46.3787117],
  [-78.7235342, 46.3721429],
  [-78.723015, 46.3695793],
  [-78.72316, 46.3658562],
  [-78.7243884, 46.3569413],
  [-78.725273, 46.352684],
  [-78.7272945, 46.347557],
  [-78.7275627, 46.3454667],
  [-78.7273788, 46.343998],
  [-78.7251201, 46.3390006],
  [-78.7210766, 46.3335761],
  [-78.7174078, 46.3296662],
  [-78.712951, 46.3262137],
  [-78.7091219, 46.3241006],
  [-78.7045817, 46.3226317],
  [-78.6955407, 46.3191833],
  [-78.692085, 46.3186912],
  [-78.682137, 46.317997],
  [-78.6782153, 46.3178635],
  [-78.6733634, 46.3180963],
  [-78.6628723, 46.3195038],
  [-78.6491623, 46.3201675],
  [-78.6375879, 46.321205],
  [-78.635056, 46.3210984],
  [-78.6256944, 46.3200875],
  [-78.6073754, 46.3195037],
  [-78.5966027, 46.3184584],
  [-78.5932923, 46.3178673],
  [-78.5845259, 46.3157386],
  [-78.5749057, 46.3138963],
  [-78.5492483, 46.3081934],
  [-78.5345, 46.3040123],
  [-78.520546, 46.2987098],
  [-78.5140003, 46.2966843],
  [-78.508904, 46.295456],
  [-78.502342, 46.2944069],
  [-78.4985654, 46.2941322],
  [-78.4928517, 46.2941704],
  [-78.489692, 46.294525],
  [-78.4857783, 46.2954825],
  [-78.4838861, 46.2956389],
  [-78.4820639, 46.2955171],
  [-78.4783936, 46.2945443],
  [-78.4750439, 46.2940215],
  [-78.4700769, 46.2941626],
  [-78.4578704, 46.2950363],
  [-78.4448095, 46.2943765],
  [-78.442719, 46.2944299],
  [-78.4374766, 46.2950477],
  [-78.4336324, 46.295189],
  [-78.4157101, 46.295002],
  [-78.406875, 46.2944487],
  [-78.4002914, 46.2931404],
  [-78.3945382, 46.29253],
  [-78.3899606, 46.2917251],
  [-78.3839638, 46.2902182],
  [-78.3798756, 46.2885972],
  [-78.3772511, 46.2872582],
  [-78.3723227, 46.2839395],
  [-78.3631752, 46.2784197],
  [-78.3468552, 46.2672233],
  [-78.3435892, 46.2652473],
  [-78.3392337, 46.2632828],
  [-78.3308258, 46.2608986],
  [-78.324753, 46.258751],
  [-78.316795, 46.2552071],
  [-78.3139496, 46.252861],
  [-78.3133616, 46.2526054],
  [-78.3123931, 46.2527542],
  [-78.30925, 46.2539292],
  [-78.3066489, 46.2546427],
  [-78.3040617, 46.2552299],
  [-78.2972343, 46.2563097],
  [-78.2930979, 46.257553],
  [-78.2855459, 46.2613336],
  [-78.283173, 46.2630349],
  [-78.2805335, 46.2645456],
  [-78.2748564, 46.2666626],
  [-78.272133, 46.2687645],
  [-78.2673334, 46.2702178],
  [-78.2592923, 46.2745514],
  [-78.257789, 46.2750739],
  [-78.2554162, 46.2755393],
  [-78.2503278, 46.2757835],
  [-78.2373735, 46.2747535],
  [-78.2307813, 46.2734032],
  [-78.2213973, 46.2707024],
  [-78.2141187, 46.2698669],
  [-78.2123482, 46.2699698],
  [-78.2093888, 46.2704812],
  [-78.205024, 46.2706908],
  [-78.2029801, 46.2714691],
  [-78.1999053, 46.2732125],
  [-78.1973044, 46.2739831],
  [-78.1834265, 46.2755967],
  [-78.1772612, 46.2759094],
  [-78.1722254, 46.2757797],
  [-78.1680991, 46.2752992],
  [-78.162834, 46.2742385],
  [-78.157409, 46.2737616],
  [-78.1545102, 46.2740249],
  [-78.146156, 46.2759132],
  [-78.1436536, 46.2760925],
  [-78.1314998, 46.2737349],
  [-78.1292946, 46.2730407],
  [-78.1250761, 46.270298],
  [-78.1189735, 46.2647172],
  [-78.1163555, 46.2632636],
  [-78.1092374, 46.2614212],
  [-78.100349, 46.2583427],
  [-78.0930099, 46.256977],
  [-78.0876618, 46.2555543],
  [-78.0838858, 46.2541429],
  [-78.0818018, 46.2530249],
  [-78.0806584, 46.2528192],
  [-78.0775459, 46.2516138],
  [-78.0703121, 46.2479629],
  [-78.0661997, 46.2466238],
  [-78.0634838, 46.2461052],
  [-78.0555042, 46.2451783],
  [-78.0476841, 46.2424661],
  [-78.0391764, 46.2422447],
  [-78.0377419, 46.2430914],
  [-78.0361635, 46.243679],
  [-78.0305862, 46.2447242],
  [-78.0259168, 46.2462844],
  [-78.0232398, 46.2468644],
  [-78.0204927, 46.2471161],
  [-78.0169139, 46.2471008],
  [-78.0125277, 46.2475739],
  [-78.0013804, 46.2478941],
  [-77.9947513, 46.2486648],
  [-77.9938348, 46.248558],
  [-77.9913795, 46.2479135],
  [-77.9888152, 46.246746],
  [-77.9872817, 46.246418],
  [-77.9835886, 46.2460936],
  [-77.9648587, 46.2389907],
  [-77.9473038, 46.2327958],
  [-77.9280326, 46.2252237],
  [-77.9118568, 46.2224731],
  [-77.8942163, 46.2201458],
  [-77.8784719, 46.2152444],
  [-77.855407, 46.2087057],
  [-77.8498824, 46.2079875],
  [-77.8487304, 46.2164489],
  [-77.8487369, 46.2204225],
  [-77.8492398, 46.2218353],
  [-77.8519643, 46.2245853],
  [-77.8534448, 46.2285183],
  [-77.8554404, 46.2317097],
  [-77.8557301, 46.2329045],
  [-77.8554297, 46.2360437],
  [-77.8545177, 46.237936],
  [-77.8535092, 46.2389527],
  [-77.8516317, 46.2402438],
  [-77.8507841, 46.2412753],
  [-77.8506446, 46.2445624],
  [-77.8503013, 46.2453711],
  [-77.849105, 46.2461279],
  [-77.8481555, 46.2474783],
  [-77.8484345, 46.2482944],
  [-77.848075, 46.2500813],
  [-77.8497219, 46.255016],
  [-77.8497005, 46.2557578],
  [-77.8489924, 46.2569447],
  [-77.8499365, 46.2582355],
  [-77.8500009, 46.2599268],
  [-77.8515029, 46.2614845],
  [-77.8529191, 46.2623152],
  [-77.853241, 46.2633388],
  [-77.854743, 46.2655195],
  [-77.8512669, 46.2712896],
  [-77.8525114, 46.2749531],
  [-77.8522325, 46.2755167],
  [-77.8524041, 46.2759468],
  [-77.852962, 46.2762138],
  [-77.8537989, 46.2773113],
  [-77.8535414, 46.2778452],
  [-77.8524256, 46.2782901],
  [-77.8502584, 46.2786609],
  [-77.8493142, 46.2805295],
  [-77.8475976, 46.2823239],
  [-77.8453446, 46.2840144],
  [-77.8424659, 46.286655],
  [-77.8419543, 46.287514],
  [-77.8414822, 46.2890264],
  [-77.8419972, 46.2917547],
  [-77.8425336, 46.2922884],
  [-77.8432417, 46.2923774],
  [-77.8452802, 46.2919622],
  [-77.8459883, 46.2921995],
  [-77.8465247, 46.2927629],
  [-77.8467393, 46.2936525],
  [-77.8466749, 46.2962026],
  [-77.8463102, 46.296588],
  [-77.8455806, 46.2967363],
  [-77.8452802, 46.2966029],
  [-77.8428984, 46.297611],
  [-77.842319, 46.2983523],
  [-77.8411174, 46.2992418],
  [-77.8409028, 46.3003685],
  [-77.841254, 46.3018696],
  [-77.8406239, 46.3029331],
  [-77.8409243, 46.3032295],
  [-77.8408385, 46.3046081],
  [-77.839787, 46.3071132],
  [-77.8398437, 46.3094148],
  [-77.838521, 46.3110708],
  [-77.8387249, 46.3135571],
  [-77.8391862, 46.3149688],
  [-77.8365898, 46.3174289],
  [-77.8319121, 46.319726],
  [-77.8295946, 46.3205855],
  [-77.8262258, 46.3227342],
  [-77.8254104, 46.3252533],
  [-77.8254803, 46.3286113],
  [-77.8265411, 46.331089],
  [-77.8267376, 46.3328691],
  [-77.8248371, 46.3353943],
  [-77.8215065, 46.3380364],
  [-77.8199296, 46.3380226],
  [-77.8194806, 46.3382],
  [-77.8189724, 46.3388564],
  [-77.8192648, 46.3410112],
  [-77.8190431, 46.3414789],
  [-77.8180018, 46.3422211],
  [-77.817322, 46.3423919],
  [-77.815792, 46.3435578],
  [-77.8150648, 46.3450943],
  [-77.8150349, 46.3465609],
  [-77.8104747, 46.3544066],
  [-77.8080234, 46.3578283],
  [-77.8045997, 46.3597954],
  [-77.8013901, 46.360246],
  [-77.7994788, 46.3615155],
  [-77.7979553, 46.3633591],
  [-77.7939856, 46.3647436],
  [-77.7914965, 46.3659059],
  [-77.7895117, 46.3661428],
  [-77.7890289, 46.3659503],
  [-77.7884817, 46.3652988],
  [-77.7875148, 46.3653553],
  [-77.786808, 46.3662687],
  [-77.7844155, 46.3669572],
  [-77.78404, 46.3687192],
  [-77.7828169, 46.3704292],
  [-77.7822912, 46.3707846],
  [-77.7793408, 46.3718358],
  [-77.7775383, 46.3716433],
  [-77.7767122, 46.3717543],
  [-77.7744521, 46.3730279],
  [-77.7738047, 46.3737012],
  [-77.772249, 46.3770914],
  [-77.7720773, 46.3780832],
  [-77.772603, 46.3791269],
  [-77.7717233, 46.3816212],
  [-77.7705217, 46.382983],
  [-77.7701783, 46.3837527],
  [-77.7705431, 46.3866982],
  [-77.7701998, 46.3883411],
  [-77.7695346, 46.388948],
  [-77.7667666, 46.3905464],
  [-77.7583337, 46.3924556],
  [-77.7537632, 46.3943203],
  [-77.7528835, 46.3949419],
  [-77.7526903, 46.3962442],
  [-77.7517033, 46.3970729],
  [-77.7502227, 46.3977388],
  [-77.7491284, 46.399189],
  [-77.7460814, 46.4010683],
  [-77.744236, 46.4012015],
  [-77.7438283, 46.4014383],
  [-77.7418542, 46.4044568],
  [-77.7417898, 46.4054186],
  [-77.7432704, 46.4103011],
  [-77.7447295, 46.4122984],
  [-77.7480126, 46.4154347],
  [-77.7506165, 46.4163908],
  [-77.7520895, 46.4176093],
  [-77.7531195, 46.4189998],
  [-77.7571964, 46.4212925],
  [-77.7573681, 46.4234077],
  [-77.7583337, 46.4249164],
  [-77.7613695, 46.4280894],
  [-77.7639985, 46.4294275],
  [-77.7654791, 46.4304923],
  [-77.765801, 46.4321043],
  [-77.7646208, 46.4355944],
  [-77.7632904, 46.436526],
  [-77.76299, 46.4375759],
  [-77.7624321, 46.4380048],
  [-77.7602005, 46.4381231],
  [-77.7595568, 46.4384336],
  [-77.7601361, 46.4409917],
  [-77.7613592, 46.4418789],
  [-77.7633119, 46.4428696],
  [-77.7713371, 46.4486654],
  [-77.7727318, 46.4511343],
  [-77.7750707, 46.4523022],
  [-77.775929, 46.4524205],
  [-77.7762079, 46.4527753],
  [-77.7760363, 46.4532779],
  [-77.7747703, 46.4540467],
  [-77.774427, 46.454638],
  [-77.7745342, 46.4560571],
  [-77.7742982, 46.4567519],
  [-77.772882, 46.4577718],
  [-77.7716589, 46.4582892],
  [-77.7716375, 46.4602699],
  [-77.7702642, 46.4641868],
  [-77.7695132, 46.4649258],
  [-77.7690625, 46.4659013],
  [-77.7695775, 46.4677044],
  [-77.7693629, 46.4687389],
  [-77.7689338, 46.4694336],
  [-77.7679897, 46.4701873],
  [-77.7671099, 46.4704533],
  [-77.7661872, 46.4710444],
  [-77.7651143, 46.4724631],
  [-77.764771, 46.4734385],
  [-77.7656079, 46.4752265],
  [-77.7655649, 46.4759949],
  [-77.7652431, 46.4767486],
  [-77.7631402, 46.4785956],
  [-77.76299, 46.4792753],
  [-77.7635908, 46.4803835],
  [-77.7636767, 46.4814917],
  [-77.762003, 46.4840921],
  [-77.7622175, 46.4866777],
  [-77.7615524, 46.4890858],
  [-77.7599645, 46.4911688],
  [-77.760222, 46.4986286],
  [-77.7605868, 46.499825],
  [-77.7623248, 46.5014793],
  [-77.7631831, 46.5028529],
  [-77.7628559, 46.503331],
  [-77.7617481, 46.5037353],
  [-77.7608228, 46.5048911],
  [-77.759943, 46.5054228],
  [-77.7592135, 46.5061169],
  [-77.7590203, 46.5067667],
  [-77.7603722, 46.5116549],
  [-77.7609086, 46.5125853],
  [-77.7615953, 46.5131612],
  [-77.7640629, 46.5142539],
  [-77.7664662, 46.5162031],
  [-77.7709937, 46.515893],
  [-77.7723456, 46.5162031],
  [-77.7738261, 46.5168971],
  [-77.7750492, 46.5171186],
  [-77.7770448, 46.518477],
  [-77.7775169, 46.5193039],
  [-77.7775598, 46.5205146],
  [-77.7784825, 46.5214448],
  [-77.7792764, 46.5214891],
  [-77.7805209, 46.5212233],
  [-77.7811218, 46.5213414],
  [-77.7828464, 46.5227256],
  [-77.7839756, 46.5240728],
  [-77.7837396, 46.5248258],
  [-77.7831173, 46.525564],
  [-77.7831388, 46.5259626],
  [-77.7833104, 46.5262726],
  [-77.784791, 46.5268927],
  [-77.7844477, 46.5275423],
  [-77.7834606, 46.5279556],
  [-77.7813578, 46.5280294],
  [-77.7792629, 46.5287473],
  [-77.7777744, 46.529801],
  [-77.7768087, 46.5301774],
  [-77.774207, 46.530683],
  [-77.7713371, 46.5324581],
  [-77.7696204, 46.5341704],
  [-77.7687192, 46.5346428],
  [-77.7686548, 46.5359122],
  [-77.7679038, 46.5372407],
  [-77.7680112, 46.5380967],
  [-77.7684322, 46.5383883],
  [-77.7686119, 46.5390562],
  [-77.7687836, 46.5411078],
  [-77.770114, 46.5422295],
  [-77.7759719, 46.5445761],
  [-77.7779246, 46.5456387],
  [-77.7791476, 46.5472325],
  [-77.7815294, 46.5493576],
  [-77.7826452, 46.5521171],
  [-77.7821732, 46.5531795],
  [-77.781272, 46.5542125],
  [-77.7809501, 46.5551273],
  [-77.7809715, 46.5572816],
  [-77.7827096, 46.5603357],
  [-77.7828813, 46.5614718],
  [-77.783525, 46.5626816],
  [-77.7874196, 46.5670484],
  [-77.7874196, 46.5684646],
  [-77.7892971, 46.571142],
  [-77.7883852, 46.5722484],
  [-77.7851772, 46.5738562],
  [-77.7842867, 46.5756336],
  [-77.7811432, 46.5763932],
  [-77.7793193, 46.5775805],
  [-77.7787185, 46.5797781],
  [-77.7772164, 46.5822264],
  [-77.7744913, 46.583856],
  [-77.774251, 46.5846813],
  [-77.776165, 46.5871373],
  [-77.7768087, 46.588988],
  [-77.7781391, 46.5890543],
  [-77.7799201, 46.5885898],
  [-77.7813363, 46.5896368],
  [-77.7819693, 46.5910082],
  [-77.7820384, 46.5923062],
  [-77.7830851, 46.5954465],
  [-77.7850914, 46.5985059],
  [-77.7848554, 46.5995232],
  [-77.7857995, 46.60186],
  [-77.7854455, 46.6024423],
  [-77.7864647, 46.6032237],
  [-77.7875483, 46.6048232],
  [-77.7894366, 46.6051991],
  [-77.7927411, 46.6078084],
  [-77.7930737, 46.6085823],
  [-77.7929127, 46.6094962],
  [-77.7938354, 46.6097542],
  [-77.7951872, 46.6107418],
  [-77.798599, 46.6112724],
  [-77.7993474, 46.611289],
  [-77.8006321, 46.6107012],
  [-77.8037381, 46.6107934],
  [-77.8074932, 46.6125696],
  [-77.8078151, 46.6131518],
  [-77.8098321, 46.614611],
  [-77.8146064, 46.6169471],
  [-77.8151965, 46.6177356],
  [-77.8167951, 46.6186273],
  [-77.8189731, 46.6194305],
  [-77.8204858, 46.6203369],
  [-77.8241444, 46.6215822],
  [-77.8302062, 46.6219286],
  [-77.8334248, 46.623196],
  [-77.8348303, 46.6245591],
  [-77.8353667, 46.6259443],
  [-77.8358281, 46.626438],
  [-77.835635, 46.6269538],
  [-77.8372657, 46.6297756],
  [-77.8390575, 46.6306302],
  [-77.8415144, 46.6307334],
  [-77.8430593, 46.6313375],
  [-77.8437889, 46.6319638],
  [-77.8445721, 46.6337024],
  [-77.8482628, 46.6370764],
  [-77.8505159, 46.6386234],
  [-77.8526187, 46.6395516],
  [-77.8561163, 46.6407007],
  [-77.8565776, 46.6410542],
  [-77.8576076, 46.6440374],
  [-77.8603435, 46.6484124],
  [-77.8605688, 46.6501432],
  [-77.859807, 46.6539949],
  [-77.8615987, 46.6560863],
  [-77.8617382, 46.656668],
  [-77.8611052, 46.6571467],
  [-77.8611589, 46.6578904],
  [-77.8602469, 46.6589508],
  [-77.8592169, 46.6594663],
  [-77.859335, 46.6603499],
  [-77.857039, 46.6615722],
  [-77.8544641, 46.6614176],
  [-77.8537667, 46.6615575],
  [-77.8533161, 46.6619035],
  [-77.8532195, 46.6624263],
  [-77.8524792, 46.663413],
  [-77.8512776, 46.6642671],
  [-77.8513527, 46.6647015],
  [-77.8523719, 46.6656513],
  [-77.8525972, 46.6672858],
  [-77.8531122, 46.6680147],
  [-77.8530049, 46.6686552],
  [-77.850945, 46.6699289],
  [-77.8495073, 46.6703559],
  [-77.8471148, 46.6706209],
  [-77.8453446, 46.6714381],
  [-77.8450549, 46.6719387],
  [-77.8451085, 46.6734847],
  [-77.8432202, 46.6764587],
  [-77.8393579, 46.6770402],
  [-77.8361392, 46.6778205],
  [-77.8343046, 46.6793884],
  [-77.8343475, 46.6816702],
  [-77.8330922, 46.6829288],
  [-77.8337896, 46.6854681],
  [-77.8337467, 46.6867266],
  [-77.8345621, 46.6876172],
  [-77.8362358, 46.688596],
  [-77.8371477, 46.6902666],
  [-77.8389287, 46.694395],
  [-77.8383386, 46.6957711],
  [-77.8343582, 46.6972281],
  [-77.8300452, 46.6975004],
  [-77.8289294, 46.6980081],
  [-77.8282321, 46.699752],
  [-77.827524, 46.7007306],
  [-77.8248847, 46.701665],
  [-77.8243482, 46.702055],
  [-77.8236938, 46.7036148],
  [-77.8242088, 46.7059545],
  [-77.822195, 46.7065279],
  [-77.8209901, 46.7072052],
  [-77.8202605, 46.7077643],
  [-77.8198529, 46.7091327],
  [-77.8196597, 46.7114868],
  [-77.8227926, 46.7142528],
  [-77.8223892, 46.7155371],
  [-77.822752, 46.7160934],
  [-77.8279424, 46.7188134],
  [-77.8292942, 46.7192547],
  [-77.8300023, 46.7197843],
  [-77.8303886, 46.7209758],
  [-77.8312469, 46.7319045],
  [-77.8308392, 46.7343165],
  [-77.8300667, 46.7358018],
  [-77.8297663, 46.7391401],
  [-77.8299809, 46.7411988],
  [-77.8317619, 46.7448307],
  [-77.8334141, 46.7464628],
  [-77.8347016, 46.748389],
  [-77.8351307, 46.7498886],
  [-77.8350234, 46.7509619],
  [-77.8344012, 46.7521087],
  [-77.8334999, 46.753373],
  [-77.8324485, 46.7542992],
  [-77.8328133, 46.7554018],
  [-77.8340578, 46.7562838],
  [-77.8344226, 46.7571512],
  [-77.8333068, 46.7581802],
  [-77.8316975, 46.7587388],
  [-77.8315258, 46.7595032],
  [-77.8318048, 46.760297],
  [-77.8338433, 46.76137],
  [-77.8344226, 46.7619433],
  [-77.8344655, 46.7625607],
  [-77.8339076, 46.7635602],
  [-77.8326202, 46.7647655],
  [-77.8322768, 46.7657943],
  [-77.8326845, 46.7687633],
  [-77.833736, 46.7705122],
  [-77.8353453, 46.771394],
  [-77.8368688, 46.771541],
  [-77.8369761, 46.7726579],
  [-77.8383215, 46.7732517],
  [-77.8384352, 46.7736426],
  [-77.8381992, 46.7744802],
  [-77.8365899, 46.7764347],
  [-77.8362894, 46.7792562],
  [-77.8380061, 46.7831941],
  [-77.8383065, 46.7863972],
  [-77.837534, 46.7917597],
  [-77.8376412, 46.7932434],
  [-77.837137, 46.7943379],
  [-77.8353667, 46.7963798],
  [-77.8354418, 46.7972979],
  [-77.8351629, 46.7981352],
  [-77.8325236, 46.7992956],
  [-77.8326523, 46.7998758],
  [-77.8333497, 46.8002357],
  [-77.8333926, 46.8008967],
  [-77.8313971, 46.8017633],
  [-77.831161, 46.8021452],
  [-77.8319335, 46.8039372],
  [-77.8319121, 46.8058906],
  [-77.8322554, 46.8069774],
  [-77.8325343, 46.8074621],
  [-77.8333068, 46.807609],
  [-77.8346372, 46.8075796],
  [-77.8360534, 46.8070655],
  [-77.8371906, 46.8070949],
  [-77.8375983, 46.8075355],
  [-77.8373838, 46.8080202],
  [-77.8359032, 46.8094595],
  [-77.836032, 46.8103113],
  [-77.8371478, 46.811633],
  [-77.8392077, 46.8122791],
  [-77.841611, 46.8125141],
  [-77.8437996, 46.811633],
  [-77.847662, 46.8116036],
  [-77.8506446, 46.8123085],
  [-77.8526187, 46.8130721],
  [-77.8528548, 46.8132777],
  [-77.8521157, 46.815148],
  [-77.85146, 46.8158182],
  [-77.8492713, 46.8169342],
  [-77.8488636, 46.8188284],
  [-77.8492499, 46.8203702],
  [-77.8494215, 46.8206785],
  [-77.8512025, 46.8213833],
  [-77.8518248, 46.8214421],
  [-77.852726, 46.8210897],
  [-77.8544426, 46.8208401],
  [-77.8549147, 46.820238],
  [-77.8548503, 46.8197241],
  [-77.8546143, 46.8190781],
  [-77.8535414, 46.8184907],
  [-77.8535414, 46.8180649],
  [-77.8544426, 46.8178887],
  [-77.8549791, 46.8179915],
  [-77.8572321, 46.8193864],
  [-77.8568888, 46.821075],
  [-77.8580475, 46.8226754],
  [-77.8578115, 46.8239528],
  [-77.8586912, 46.8240409],
  [-77.8595066, 46.8236298],
  [-77.8604508, 46.8225286],
  [-77.8611374, 46.8198563],
  [-77.8626726, 46.8196697],
  [-77.8642506, 46.8199058],
  [-77.8687334, 46.8220588],
  [-77.8698337, 46.8222644],
  [-77.8714234, 46.8221189],
  [-77.8742266, 46.8225873],
  [-77.8768015, 46.822558],
  [-77.8794623, 46.8215595],
  [-77.8815651, 46.8214127],
  [-77.8843546, 46.821677],
  [-77.8912211, 46.8229984],
  [-77.8976155, 46.8292529],
  [-77.9013062, 46.8348313],
  [-77.9022504, 46.8391762],
  [-77.9005338, 46.8426988],
  [-77.9001046, 46.8543805],
  [-77.897873, 46.8588412],
  [-77.8945256, 46.8635363],
  [-77.892809, 46.8676441],
  [-77.8916074, 46.8736879],
  [-77.8925515, 46.8777363],
  [-77.8947831, 46.8823711],
  [-77.8952981, 46.8872401],
  [-77.8934098, 46.8942788],
  [-77.8881741, 46.9004369],
  [-77.8728963, 46.9110507],
  [-77.8677464, 46.9158584],
  [-77.8624249, 46.9237141],
  [-77.8611375, 46.9267036],
  [-77.8599358, 46.9305722],
  [-77.8583909, 46.9411799],
  [-77.8570176, 46.9428207],
  [-77.8522112, 46.9452233],
  [-77.8483487, 46.9542463],
  [-77.8472758, 46.9581422],
  [-77.8465891, 46.9631507],
  [-77.8465891, 46.9670752],
  [-77.8482199, 46.9864884],
  [-77.8469754, 46.9928116],
  [-77.846632, 46.9973193],
  [-77.8473616, 47.0018852],
  [-77.8487778, 47.0055727],
  [-77.8500224, 47.0084406],
  [-77.8531123, 47.0124203],
  [-77.8537131, 47.0136786],
  [-77.854743, 47.0180382],
  [-77.8514386, 47.0251475],
  [-77.8493357, 47.0328994],
  [-77.8486062, 47.0373452],
  [-77.8474474, 47.0395972],
  [-77.8444434, 47.041118],
  [-77.842598, 47.043019],
  [-77.8415251, 47.0445982],
  [-77.8407526, 47.049891],
  [-77.841096, 47.0523472],
  [-77.837534, 47.0592473],
  [-77.8382636, 47.0762305],
  [-77.840066, 47.0845886],
  [-77.8468466, 47.0942455],
  [-77.849561, 47.0963016],
  [-77.8499633, 47.0969151],
  [-77.8498936, 47.0990003],
  [-77.8489924, 47.0997818],
  [-77.8472436, 47.1001762],
  [-77.8464711, 47.0994458],
  [-77.8456665, 47.0990661],
  [-77.8445292, 47.0989054],
  [-77.8434134, 47.0992267],
  [-77.841568, 47.1002492],
  [-77.8406239, 47.1013009],
  [-77.842083, 47.1036963],
  [-77.8429413, 47.1033165],
  [-77.8435421, 47.1034334],
  [-77.8449154, 47.1028783],
  [-77.8455162, 47.1030536],
  [-77.8461171, 47.1041344],
  [-77.847662, 47.1050984],
  [-77.8469754, 47.1056534],
  [-77.8468895, 47.1064129],
  [-77.848177, 47.1092461],
  [-77.8480053, 47.114591],
  [-77.8456879, 47.118884],
  [-77.845645, 47.1206069],
  [-77.8463316, 47.1215706],
  [-77.8469325, 47.1233519],
  [-77.8467179, 47.1257171],
  [-77.8492499, 47.1388551],
  [-77.8539277, 47.142971],
  [-77.8545284, 47.1459629],
  [-77.8541851, 47.1474369],
  [-77.8526831, 47.1484438],
  [-77.851224, 47.1504577],
  [-77.8513098, 47.1510414],
  [-77.8527904, 47.152953],
  [-77.8530693, 47.1551418],
  [-77.8527045, 47.1575639],
  [-77.8535162, 47.1599239],
  [-77.8531841, 47.1602879],
  [-77.853447, 47.161836],
  [-77.8548169, 47.1632739],
  [-77.8557394, 47.1647012],
  [-77.855792, 47.1656192],
  [-77.8552195, 47.1684119],
  [-77.8559572, 47.1723164],
  [-77.8557451, 47.17363],
  [-77.8551369, 47.1750215],
  [-77.8523218, 47.1778975],
  [-77.8546768, 47.181752],
  [-77.8546073, 47.1846856],
  [-77.8539406, 47.1878366],
  [-77.8542429, 47.1913319],
  [-77.8534722, 47.1931547],
  [-77.8534556, 47.1960917],
  [-77.8542495, 47.1978486],
  [-77.8545284, 47.1994815],
  [-77.856642, 47.2033449],
  [-77.8562772, 47.2068874],
  [-77.8569102, 47.2073976],
  [-77.8572321, 47.2087751],
  [-77.8571463, 47.2091395],
  [-77.8565751, 47.2094528],
  [-77.855757, 47.209579],
  [-77.855237, 47.2114384],
  [-77.8573632, 47.2127627],
  [-77.8573726, 47.2134284],
  [-77.8565412, 47.2134402],
  [-77.8560515, 47.2136684],
  [-77.8568511, 47.2168691],
  [-77.8571974, 47.2246639],
  [-77.8568931, 47.2261294],
  [-77.8572868, 47.2305022],
  [-77.858293, 47.236128],
  [-77.8708901, 47.2427064],
  [-77.9017353, 47.2426189],
  [-77.904203, 47.2455032],
  [-77.9109407, 47.2498439],
  [-77.9143739, 47.2507907],
  [-77.9153609, 47.2506742],
  [-77.9157901, 47.2510529],
  [-77.9156828, 47.251417],
  [-77.9164338, 47.2518102],
  [-77.9178715, 47.2531793],
  [-77.9212507, 47.2538184],
  [-77.9223296, 47.2596501],
  [-77.9240107, 47.2619815],
  [-77.9259237, 47.2631413],
  [-77.9283825, 47.2635961],
  [-77.9297275, 47.263561],
  [-77.930174, 47.2647457],
  [-77.9311636, 47.2659205],
  [-77.9316134, 47.2697153],
  [-77.6471026, 47.2694987],
  [-77.6464148, 47.2892174],
  [-77.4316947, 47.2891311],
  [-77.4336564, 47.1449819],
  [-77.4333732, 47.1448336],
  [-77.4327493, 47.1446938],
  [-77.0037136, 47.1454606],
  [-77.002943, 47.2891886],
  [-76.7806222, 47.2891434],
  [-76.5752135, 47.2880112],
  [-76.5737775, 47.5763453],
  [-76.5733757, 47.6026412],
  [-76.5512016, 47.5877772],
  [-76.5197025, 47.5676894],
  [-76.5178941, 47.5666471],
  [-76.5105682, 47.5633835],
  [-76.4998965, 47.5577486],
  [-76.4584071, 47.5326615],
  [-76.4416081, 47.5218522],
  [-76.4264425, 47.5130886],
  [-76.4243123, 47.5129776],
  [-76.4095966, 47.5608807],
  [-76.4168724, 47.5995249],
  [-76.4160773, 47.6010165],
  [-76.4165793, 47.6022123],
  [-76.4184514, 47.6035198],
  [-76.4209416, 47.6062992],
  [-76.4284453, 47.6056116],
  [-76.4344296, 47.601587],
  [-76.4397522, 47.6012777],
  [-76.4402004, 47.6015135],
  [-76.4422508, 47.6005709],
  [-76.4447926, 47.6001993],
  [-76.4467686, 47.600098],
  [-76.4484789, 47.6007005],
  [-76.4490595, 47.6018845],
  [-76.4507597, 47.6024426],
  [-76.4526166, 47.6035984],
  [-76.4541835, 47.6036111],
  [-76.4569014, 47.6029666],
  [-76.457851, 47.603643],
  [-76.4589044, 47.6033105],
  [-76.4681003, 47.6028336],
  [-76.479726, 47.6008607],
  [-76.4814629, 47.6023722],
  [-76.4820726, 47.6033209],
  [-76.4772685, 47.6063201],
  [-76.4786261, 47.6071123],
  [-76.4793897, 47.6082518],
  [-76.4788944, 47.6097332],
  [-76.4751056, 47.6128209],
  [-76.4707292, 47.6126881],
  [-76.4578455, 47.6137251],
  [-76.4570832, 47.6159661],
  [-76.4489151, 47.6205028],
  [-76.4290475, 47.6221173],
  [-76.4147588, 47.6263857],
  [-76.3994548, 47.6273139],
  [-76.3979202, 47.6343617],
  [-76.3910566, 47.6403179],
  [-76.39816, 47.6424831],
  [-76.3926097, 47.6526657],
  [-76.3861697, 47.6565612],
  [-76.3842791, 47.650398],
  [-76.3775935, 47.6508184],
  [-76.3758405, 47.6506614],
  [-76.3755902, 47.6508672],
  [-76.370569, 47.651184],
  [-76.3677108, 47.6523919],
  [-76.3642571, 47.6531469],
  [-76.3535542, 47.6540689],
  [-76.3470793, 47.6540811],
  [-76.3409905, 47.6551556],
  [-76.3373306, 47.6575554],
  [-76.335969, 47.6594405],
  [-76.3332868, 47.6613933],
  [-76.3313502, 47.6616101],
  [-76.3323346, 47.6624357],
  [-76.3309372, 47.6645348],
  [-76.3249116, 47.6682124],
  [-76.3242129, 47.668925],
  [-76.3228959, 47.6676155],
  [-76.32123, 47.6681721],
  [-76.3184729, 47.6667078],
  [-76.3168623, 47.6669507],
  [-76.3162712, 47.6664628],
  [-76.3148403, 47.6663833],
  [-76.3139219, 47.6670417],
  [-76.3134543, 47.6669459],
  [-76.3128948, 47.6663187],
  [-76.3118109, 47.6661389],
  [-76.3111073, 47.666508],
  [-76.3095945, 47.6660685],
  [-76.3071245, 47.6664278],
  [-76.3071902, 47.6669715],
  [-76.3061748, 47.6681348],
  [-76.3054329, 47.6682412],
  [-76.3052261, 47.6688351],
  [-76.303359, 47.6702838],
  [-76.3005444, 47.6700552],
  [-76.3001155, 47.6704469],
  [-76.2989196, 47.670827],
  [-76.2966652, 47.6725446],
  [-76.2958485, 47.6728552],
  [-76.295532, 47.6733537],
  [-76.2961234, 47.6738621],
  [-76.2959649, 47.6743242],
  [-76.2951095, 47.675185],
  [-76.2940755, 47.675334],
  [-76.2938999, 47.6758975],
  [-76.2932789, 47.6764754],
  [-76.2933835, 47.6768095],
  [-76.2941049, 47.6771843],
  [-76.2938572, 47.6776105],
  [-76.293228, 47.677829],
  [-76.2929544, 47.6784159],
  [-76.2913585, 47.6789775],
  [-76.290704, 47.6797089],
  [-76.2907121, 47.6802732],
  [-76.2894648, 47.681122],
  [-76.2892918, 47.6816475],
  [-76.2883705, 47.6820456],
  [-76.2884335, 47.6829169],
  [-76.2893361, 47.6839272],
  [-76.2919915, 47.6849682],
  [-76.2915784, 47.6866728],
  [-76.2906933, 47.6871747],
  [-76.2872666, 47.6879841],
  [-76.2841916, 47.6881678],
  [-76.2828914, 47.6883859],
  [-76.2815228, 47.6889893],
  [-76.2792885, 47.6891753],
  [-76.2772604, 47.6902063],
  [-76.2753725, 47.6906847],
  [-76.2742782, 47.6906414],
  [-76.2735486, 47.6908436],
  [-76.2703469, 47.6922601],
  [-76.2696171, 47.6930163],
  [-76.2666583, 47.6946359],
  [-76.2649639, 47.6951629],
  [-76.2621975, 47.6955809],
  [-76.2594943, 47.6966999],
  [-76.2581208, 47.6974941],
  [-76.2561893, 47.6998965],
  [-76.2526596, 47.7018125],
  [-76.2499666, 47.7029678],
  [-76.2487865, 47.7038847],
  [-76.2477994, 47.7060796],
  [-76.2489367, 47.7095386],
  [-76.248592, 47.7098816],
  [-76.2478879, 47.7099592],
  [-76.2477069, 47.7103355],
  [-76.2468848, 47.710617],
  [-76.2468419, 47.7111964],
  [-76.245541, 47.7114797],
  [-76.2442655, 47.7125224],
  [-76.2441731, 47.7140641],
  [-76.2433148, 47.7154139],
  [-76.243304, 47.7169984],
  [-76.2425157, 47.7178579],
  [-76.2418395, 47.7197773],
  [-76.2403428, 47.720493],
  [-76.2394371, 47.7219481],
  [-76.2372851, 47.723498],
  [-76.2363303, 47.7244724],
  [-76.2344461, 47.7253692],
  [-76.2324464, 47.725656],
  [-76.2284115, 47.7256935],
  [-76.2272859, 47.7259735],
  [-76.2262908, 47.7265617],
  [-76.2263632, 47.7273105],
  [-76.2273824, 47.7284922],
  [-76.2293454, 47.729673],
  [-76.2294638, 47.7300005],
  [-76.2286165, 47.7307747],
  [-76.2276191, 47.7310359],
  [-76.2262023, 47.7309747],
  [-76.2239599, 47.7305345],
  [-76.2218142, 47.7304696],
  [-76.2212026, 47.7306861],
  [-76.2201834, 47.7317685],
  [-76.2185204, 47.7319417],
  [-76.2169325, 47.731083],
  [-76.2152512, 47.7285572],
  [-76.2118632, 47.7263344],
  [-76.2104309, 47.7246456],
  [-76.2086606, 47.7241909],
  [-76.2071586, 47.7242126],
  [-76.2051201, 47.7247106],
  [-76.2036073, 47.726089],
  [-76.1990476, 47.7266014],
  [-76.1972129, 47.726486],
  [-76.1960113, 47.7268179],
  [-76.1950457, 47.7275541],
  [-76.1875892, 47.7316098],
  [-76.1865914, 47.7319128],
  [-76.1842847, 47.7321798],
  [-76.1837804, 47.7329664],
  [-76.1829543, 47.7358744],
  [-76.1823813, 47.7361623],
  [-76.1819136, 47.7361414],
  [-76.1803566, 47.7356584],
  [-76.179115, 47.7361482],
  [-76.1769462, 47.7376061],
  [-76.1758518, 47.7376927],
  [-76.1748089, 47.7370338],
  [-76.1738563, 47.7352683],
  [-76.1729658, 47.7349724],
  [-76.1722577, 47.7349652],
  [-76.168693, 47.7354487],
  [-76.1679072, 47.7351474],
  [-76.1676604, 47.7347956],
  [-76.16795, 47.7344637],
  [-76.1704552, 47.7331829],
  [-76.1750659, 47.7297984],
  [-76.1775336, 47.7261143],
  [-76.1776811, 47.7256091],
  [-76.1774853, 47.7253619],
  [-76.1762488, 47.7248531],
  [-76.1701334, 47.7252717],
  [-76.167089, 47.72573],
  [-76.164321, 47.7253222],
  [-76.1607724, 47.7221953],
  [-76.160641, 47.7212335],
  [-76.1623469, 47.7191494],
  [-76.161899, 47.7183789],
  [-76.1588278, 47.7175199],
  [-76.1558452, 47.7162838],
  [-76.1535519, 47.7158092],
  [-76.1519989, 47.7158507],
  [-76.1505613, 47.7162405],
  [-76.1480204, 47.7187296],
  [-76.144288, 47.7195013],
  [-76.141467, 47.7206089],
  [-76.1396714, 47.720833],
  [-76.137716, 47.7223092],
  [-76.1367537, 47.723725],
  [-76.1356413, 47.723968],
  [-76.1319726, 47.7241552],
  [-76.1268523, 47.7232354],
  [-76.126195, 47.7230196],
  [-76.1247066, 47.7218352],
  [-76.124565, 47.7201592],
  [-76.1190516, 47.7193146],
  [-76.1176894, 47.7181265],
  [-76.1170226, 47.7178968],
  [-76.1162233, 47.7180026],
  [-76.1141169, 47.7190417],
  [-76.1135446, 47.7190144],
  [-76.1124013, 47.7184062],
  [-76.110077, 47.7188758],
  [-76.1080157, 47.721263],
  [-76.1082605, 47.7221848],
  [-76.108038, 47.7225432],
  [-76.106652, 47.7229048],
  [-76.1060835, 47.7237847],
  [-76.1051349, 47.7243685],
  [-76.1009737, 47.7250674],
  [-76.0999124, 47.7251312],
  [-76.0979674, 47.7245751],
  [-76.0967553, 47.7244564],
  [-76.0939655, 47.7248265],
  [-76.090424, 47.7245551],
  [-76.0897252, 47.7252632],
  [-76.0873858, 47.7260823],
  [-76.0858425, 47.725699],
  [-76.0824047, 47.7238246],
  [-76.0810732, 47.7236218],
  [-76.08009, 47.7232225],
  [-76.0790832, 47.7224362],
  [-76.074586, 47.7213942],
  [-76.0739431, 47.7217786],
  [-76.0744177, 47.722428],
  [-76.0734448, 47.7223974],
  [-76.0729418, 47.7219638],
  [-76.0723684, 47.7219886],
  [-76.0718194, 47.7222834],
  [-76.0719228, 47.7226886],
  [-76.0726511, 47.723276],
  [-76.0714658, 47.7247647],
  [-76.071471, 47.7257632],
  [-76.0704778, 47.726569],
  [-76.0687783, 47.7273452],
  [-76.0679255, 47.727297],
  [-76.0671958, 47.7268715],
  [-76.0658252, 47.7265695],
  [-76.0655357, 47.7277197],
  [-76.0637344, 47.7279018],
  [-76.0621491, 47.7293529],
  [-76.0619312, 47.7299008],
  [-76.0601882, 47.7294804],
  [-76.0594631, 47.7300892],
  [-76.0576577, 47.7307479],
  [-76.0570651, 47.7314472],
  [-76.0564147, 47.7311119],
  [-76.0554022, 47.7297118],
  [-76.053767, 47.7291462],
  [-76.0473847, 47.7295039],
  [-76.0431663, 47.7286499],
  [-76.0344169, 47.724303],
  [-76.0335987, 47.7243585],
  [-76.0323376, 47.7262225],
  [-76.0308349, 47.7271028],
  [-76.0262254, 47.7280239],
  [-76.024472, 47.7264955],
  [-76.0224286, 47.7254436],
  [-76.0228008, 47.7255531],
  [-76.0228298, 47.7253014],
  [-76.0218625, 47.7246587],
  [-76.0224519, 47.7243463],
  [-76.0218158, 47.7239389],
  [-76.0209237, 47.7238183],
  [-76.0202422, 47.7240043],
  [-76.0197797, 47.7235526],
  [-76.0194452, 47.7236862],
  [-76.0189668, 47.7235312],
  [-76.017204, 47.7238746],
  [-76.0158055, 47.7237428],
  [-76.0145853, 47.7245022],
  [-76.0138127, 47.724436],
  [-76.0113788, 47.7252622],
  [-76.0118153, 47.727054],
  [-76.012535, 47.7270751],
  [-76.0132613, 47.7263856],
  [-76.0131783, 47.726718],
  [-76.0127071, 47.7272107],
  [-76.0107257, 47.7280928],
  [-76.0100469, 47.7293942],
  [-76.0076855, 47.7309852],
  [-76.0082421, 47.7313474],
  [-76.0079299, 47.7319307],
  [-76.0069391, 47.7323762],
  [-76.0071217, 47.7328087],
  [-76.0065703, 47.7333101],
  [-76.0062517, 47.7331558],
  [-76.0055391, 47.7337914],
  [-76.0046198, 47.7337154],
  [-76.0038498, 47.7333703],
  [-76.0032887, 47.7334848],
  [-76.0012592, 47.7351761],
  [-76.000489, 47.7362431],
  [-76.0008353, 47.7362806],
  [-76.0006165, 47.7368553],
  [-76.0000438, 47.7367809],
  [-75.9986789, 47.7359024],
  [-75.9977596, 47.7358264],
  [-75.997051, 47.7360212],
  [-75.9957231, 47.7368339],
  [-75.9945488, 47.7369277],
  [-75.9932172, 47.7367419],
  [-75.991745, 47.7373111],
  [-75.9905317, 47.7372967],
  [-75.9887599, 47.7384942],
  [-75.987959, 47.7385715],
  [-75.9874275, 47.7383893],
  [-75.9867942, 47.7390521],
  [-75.9862732, 47.7418651],
  [-75.9857577, 47.7427533],
  [-75.9862487, 47.7429984],
  [-75.9859802, 47.7431771],
  [-75.9857558, 47.7429422],
  [-75.9853004, 47.7431471],
  [-75.983686, 47.7432298],
  [-75.9830813, 47.7436948],
  [-75.9837459, 47.7439136],
  [-75.9826045, 47.7447001],
  [-75.9824607, 47.7457608],
  [-75.9833019, 47.7470059],
  [-75.9833684, 47.7483734],
  [-75.984959, 47.74937],
  [-75.9857915, 47.7528548],
  [-75.9862425, 47.7531177],
  [-75.9868957, 47.7531476],
  [-75.9868795, 47.7548026],
  [-75.9858096, 47.7550856],
  [-75.9854724, 47.7568111],
  [-75.9846215, 47.7578867],
  [-75.9845994, 47.7587681],
  [-75.9834512, 47.7602201],
  [-75.9838231, 47.7603837],
  [-75.9837124, 47.7607879],
  [-75.982829, 47.7610808],
  [-75.9822606, 47.7618968],
  [-75.9803254, 47.7633272],
  [-75.9800114, 47.7640454],
  [-75.9800249, 47.7653677],
  [-75.980582, 47.765685],
  [-75.9810363, 47.7656241],
  [-75.9812595, 47.7659849],
  [-75.981659, 47.7660587],
  [-75.9821418, 47.7658],
  [-75.9828877, 47.7659293],
  [-75.9836293, 47.7664813],
  [-75.9841682, 47.7673023],
  [-75.9842148, 47.7679861],
  [-75.9852472, 47.7688273],
  [-75.9851047, 47.7697441],
  [-75.9854706, 47.7705283],
  [-75.9860788, 47.7710887],
  [-75.985832, 47.7717622],
  [-75.9354144, 47.7996163],
  [-75.9346654, 47.7997475],
  [-75.9345129, 47.8002685],
  [-75.9334757, 47.7998946],
  [-75.9331077, 47.7993531],
  [-75.9311338, 47.7991814],
  [-75.9301165, 47.7994282],
  [-75.9301121, 47.7998329],
  [-75.9292151, 47.8000623],
  [-75.928783, 47.8005099],
  [-75.9261564, 47.8014143],
  [-75.9257178, 47.8012322],
  [-75.9250049, 47.8017144],
  [-75.9232979, 47.8015529],
  [-75.9217052, 47.8019047],
  [-75.9210414, 47.8015685],
  [-75.9212575, 47.8013447],
  [-75.9203596, 47.8004587],
  [-75.9203148, 47.7996849],
  [-75.9195185, 47.7992582],
  [-75.9195384, 47.7986736],
  [-75.9184098, 47.7981192],
  [-75.9176843, 47.797342],
  [-75.9172475, 47.7958106],
  [-75.9176796, 47.7953721],
  [-75.9172055, 47.794794],
  [-75.9166729, 47.7946743],
  [-75.9168784, 47.7941987],
  [-75.9159833, 47.7942661],
  [-75.9143044, 47.795184],
  [-75.9135873, 47.7948565],
  [-75.9133559, 47.7952511],
  [-75.912861, 47.7953385],
  [-75.9106992, 47.7940951],
  [-75.9105196, 47.7934466],
  [-75.9098307, 47.7929843],
  [-75.908473, 47.7926535],
  [-75.9072572, 47.7927462],
  [-75.9067822, 47.7934363],
  [-75.9065013, 47.7934799],
  [-75.9053145, 47.7922054],
  [-75.9047112, 47.7924362],
  [-75.9048803, 47.7928238],
  [-75.9039719, 47.7928911],
  [-75.9034337, 47.7932571],
  [-75.9030876, 47.7931743],
  [-75.9022558, 47.7935388],
  [-75.9026129, 47.7938285],
  [-75.9016045, 47.7944619],
  [-75.9002364, 47.7950304],
  [-75.8999315, 47.7948489],
  [-75.8997292, 47.7950277],
  [-75.8995711, 47.794847],
  [-75.8992901, 47.7948995],
  [-75.8996196, 47.79527],
  [-75.8987892, 47.7955085],
  [-75.8993083, 47.7956372],
  [-75.898969, 47.7961211],
  [-75.897932, 47.7969162],
  [-75.8972768, 47.7970027],
  [-75.8970599, 47.7972894],
  [-75.8966868, 47.7972335],
  [-75.8968697, 47.7975763],
  [-75.896338, 47.7973846],
  [-75.897044, 47.7986656],
  [-75.8963632, 47.7998133],
  [-75.8955839, 47.80025],
  [-75.895386, 47.8000421],
  [-75.8946492, 47.800263],
  [-75.8936884, 47.800222],
  [-75.8927265, 47.7991375],
  [-75.8916163, 47.7993205],
  [-75.8917017, 47.7988712],
  [-75.8910155, 47.7993263],
  [-75.890204, 47.7979458],
  [-75.8889244, 47.7977771],
  [-75.8884029, 47.7978463],
  [-75.8876364, 47.7983279],
  [-75.8867853, 47.7991779],
  [-75.8867682, 47.7995016],
  [-75.885618, 47.7996754],
  [-75.8848082, 47.8004176],
  [-75.8838229, 47.8001964],
  [-75.8837335, 47.8009875],
  [-75.8827385, 47.8004515],
  [-75.8832381, 47.7999774],
  [-75.8816231, 47.7999507],
  [-75.8790577, 47.8001258],
  [-75.876602, 47.8011829],
  [-75.8753648, 47.8008163],
  [-75.874604, 47.8008032],
  [-75.8740666, 47.8010881],
  [-75.8731353, 47.8008132],
  [-75.8721856, 47.8009699],
  [-75.8710522, 47.8008558],
  [-75.8705401, 47.8012487],
  [-75.8696891, 47.8009652],
  [-75.8694943, 47.8005144],
  [-75.8687193, 47.8005731],
  [-75.8683805, 47.7998876],
  [-75.8652215, 47.8005718],
  [-75.8624913, 47.8022117],
  [-75.8619439, 47.8022177],
  [-75.8614989, 47.802575],
  [-75.8607393, 47.8024628],
  [-75.8599836, 47.8031153],
  [-75.8586032, 47.8035483],
  [-75.8569091, 47.8034219],
  [-75.8563505, 47.8032479],
  [-75.8553061, 47.8002557],
  [-75.8536545, 47.7988702],
  [-75.851638, 47.7978514],
  [-75.8519411, 47.7970975],
  [-75.8523862, 47.7967313],
  [-75.8521633, 47.7964062],
  [-75.8534191, 47.7963233],
  [-75.8547784, 47.7954315],
  [-75.8548894, 47.7950993],
  [-75.8543849, 47.7948716],
  [-75.8520066, 47.7950471],
  [-75.8514879, 47.7949002],
  [-75.8508013, 47.7942937],
  [-75.8500808, 47.7942626],
  [-75.8496222, 47.7935764],
  [-75.8490524, 47.7932404],
  [-75.8489527, 47.7926732],
  [-75.8498558, 47.7919677],
  [-75.8481035, 47.7883142],
  [-75.8480481, 47.7851748],
  [-75.8495588, 47.7832555],
  [-75.8546562, 47.7810781],
  [-75.8631743, 47.7797116],
  [-75.8647286, 47.7765431],
  [-75.862396, 47.7706818],
  [-75.8588676, 47.7723485],
  [-75.8566172, 47.773043],
  [-75.8528668, 47.7728486],
  [-75.8500611, 47.7722375],
  [-75.846644, 47.7721542],
  [-75.8421991, 47.7715986],
  [-75.8402547, 47.7711541],
  [-75.8351434, 47.7695152],
  [-75.8331157, 47.7685707],
  [-75.8317825, 47.7675429],
  [-75.8314166, 47.7667144],
  [-75.8316089, 47.7656164],
  [-75.8340897, 47.7649556],
  [-75.8352609, 47.7641258],
  [-75.8349046, 47.7638179],
  [-75.8340911, 47.7637952],
  [-75.8337773, 47.7632987],
  [-75.8331767, 47.7633222],
  [-75.8333934, 47.7630626],
  [-75.8336859, 47.7631452],
  [-75.834065, 47.7627067],
  [-75.8339964, 47.7607634],
  [-75.8335102, 47.7601849],
  [-75.8336702, 47.7591514],
  [-75.8340358, 47.7587218],
  [-75.8339745, 47.7582987],
  [-75.8349904, 47.7570902],
  [-75.8358262, 47.7543066],
  [-75.8365147, 47.753654],
  [-75.8370549, 47.752083],
  [-75.8369708, 47.7513539],
  [-75.8379749, 47.75],
  [-75.8376177, 47.7497746],
  [-75.836413, 47.7501184],
  [-75.8357385, 47.7507172],
  [-75.8353511, 47.7507599],
  [-75.8349411, 47.7504877],
  [-75.8343744, 47.7499537],
  [-75.8346804, 47.748966],
  [-75.8342741, 47.748406],
  [-75.8339414, 47.7483501],
  [-75.8337928, 47.7474587],
  [-75.8327862, 47.7469042],
  [-75.8326708, 47.7465437],
  [-75.8318846, 47.7464942],
  [-75.8318942, 47.7457476],
  [-75.8304787, 47.7448489],
  [-75.8305719, 47.743824],
  [-75.8310277, 47.7436378],
  [-75.8312215, 47.7430812],
  [-75.8320631, 47.7429692],
  [-75.832846, 47.7422271],
  [-75.8330889, 47.7409603],
  [-75.8334696, 47.7403868],
  [-75.8329891, 47.7393766],
  [-75.833232, 47.7391531],
  [-75.834355, 47.7389258],
  [-75.8349604, 47.7385065],
  [-75.8373132, 47.7390868],
  [-75.839208, 47.7389898],
  [-75.8400945, 47.7384822],
  [-75.8401644, 47.7382217],
  [-75.8396886, 47.7378862],
  [-75.8382165, 47.7383095],
  [-75.8369022, 47.7378521],
  [-75.8362337, 47.7379922],
  [-75.8366848, 47.7360699],
  [-75.8361505, 47.7361477],
  [-75.8357167, 47.7367029],
  [-75.8346877, 47.7368858],
  [-75.834505, 47.7376224],
  [-75.8340318, 47.7381323],
  [-75.8330783, 47.7386575],
  [-75.8325981, 47.7386727],
  [-75.8323824, 47.7378079],
  [-75.8315559, 47.7377941],
  [-75.8312002, 47.7374592],
  [-75.8310656, 47.736514],
  [-75.8300017, 47.7362919],
  [-75.8289723, 47.7354673],
  [-75.8288004, 47.7363928],
  [-75.8278657, 47.7364953],
  [-75.8278438, 47.7361264],
  [-75.8281053, 47.7354893],
  [-75.8292027, 47.7351719],
  [-75.8302588, 47.7339187],
  [-75.8306857, 47.7339032],
  [-75.8319222, 47.7331369],
  [-75.8324296, 47.7330769],
  [-75.8326483, 47.7326554],
  [-75.8342082, 47.7326554],
  [-75.8353366, 47.7319963],
  [-75.8358516, 47.7313427],
  [-75.836775, 47.7310692],
  [-75.8376136, 47.731173],
  [-75.8383089, 47.7310151],
  [-75.8387164, 47.7314762],
  [-75.8392894, 47.7314975],
  [-75.8405491, 47.7320444],
  [-75.8411678, 47.7316162],
  [-75.8415657, 47.7317804],
  [-75.8419291, 47.7315126],
  [-75.8427291, 47.7315082],
  [-75.843178, 47.7318616],
  [-75.8446322, 47.7317889],
  [-75.8444951, 47.732085],
  [-75.8450537, 47.7321961],
  [-75.8457505, 47.7319213],
  [-75.8461082, 47.7321032],
  [-75.8463099, 47.7319604],
  [-75.8464937, 47.7321953],
  [-75.8471745, 47.7321273],
  [-75.8472496, 47.7325145],
  [-75.8477849, 47.7323556],
  [-75.8474886, 47.7315264],
  [-75.84803, 47.7298114],
  [-75.8497677, 47.7283821],
  [-75.8503509, 47.7265054],
  [-75.8499722, 47.7258736],
  [-75.8493236, 47.7255012],
  [-75.8464156, 47.7245852],
  [-75.8441026, 47.7229978],
  [-75.8428383, 47.7228287],
  [-75.8422424, 47.7225105],
  [-75.842132, 47.7217542],
  [-75.8424102, 47.7208294],
  [-75.8431474, 47.7194483],
  [-75.8435101, 47.7192255],
  [-75.843598, 47.7185964],
  [-75.8478163, 47.7170283],
  [-75.8479438, 47.7164264],
  [-75.8494695, 47.7169928],
  [-75.8505504, 47.7168909],
  [-75.8541537, 47.7154541],
  [-75.8562781, 47.7150343],
  [-75.8569258, 47.7143903],
  [-75.8569047, 47.7139404],
  [-75.8562325, 47.713325],
  [-75.8555293, 47.7130691],
  [-75.8546752, 47.7131543],
  [-75.8536404, 47.7138501],
  [-75.8517161, 47.7131826],
  [-75.8502175, 47.7136509],
  [-75.8497291, 47.7132703],
  [-75.8482704, 47.7137478],
  [-75.8477281, 47.7134119],
  [-75.8474025, 47.7042902],
  [-75.8445718, 47.7036523],
  [-75.8431269, 47.7030504],
  [-75.8413548, 47.7030402],
  [-75.8390401, 47.7037825],
  [-75.8384172, 47.7045614],
  [-75.8352039, 47.7047048],
  [-75.8327465, 47.7040879],
  [-75.8324841, 47.7037625],
  [-75.8306629, 47.7034191],
  [-75.8307453, 47.7021872],
  [-75.8316961, 47.701824],
  [-75.831527, 47.7014992],
  [-75.83274, 47.7014613],
  [-75.8336125, 47.7009626],
  [-75.8332833, 47.7006549],
  [-75.8314828, 47.7007883],
  [-75.8307381, 47.700667],
  [-75.8318194, 47.7005114],
  [-75.8320371, 47.7001619],
  [-75.8333623, 47.6996839],
  [-75.8335655, 47.6994242],
  [-75.8345243, 47.6994657],
  [-75.834297, 47.698484],
  [-75.8351993, 47.6977336],
  [-75.8347797, 47.6972005],
  [-75.8336132, 47.6977694],
  [-75.833216, 47.6975782],
  [-75.8326691, 47.69762],
  [-75.8319801, 47.6983626],
  [-75.8321855, 47.6989754],
  [-75.8311569, 47.6991763],
  [-75.8267471, 47.6991325],
  [-75.8258473, 47.6986505],
  [-75.8244742, 47.6987054],
  [-75.8234429, 47.6991131],
  [-75.8211446, 47.6975614],
  [-75.8207404, 47.6968844],
  [-75.8209832, 47.6966519],
  [-75.8205219, 47.6962624],
  [-75.8208173, 47.6960933],
  [-75.8204213, 47.6958031],
  [-75.8209554, 47.6957163],
  [-75.8210934, 47.6953483],
  [-75.8219043, 47.695488],
  [-75.82244, 47.6952753],
  [-75.8240745, 47.6935489],
  [-75.8242575, 47.6927854],
  [-75.8269416, 47.691272],
  [-75.8268251, 47.6910015],
  [-75.8275297, 47.6911226],
  [-75.8288262, 47.6907883],
  [-75.8303605, 47.689574],
  [-75.8300443, 47.6893023],
  [-75.8291374, 47.6893779],
  [-75.8287082, 47.6896003],
  [-75.8290221, 47.6900609],
  [-75.8288436, 47.6904736],
  [-75.8283378, 47.6904347],
  [-75.8282482, 47.6901553],
  [-75.8279537, 47.6902615],
  [-75.8271309, 47.6900138],
  [-75.8254767, 47.6912274],
  [-75.8245582, 47.6911681],
  [-75.8232212, 47.691538],
  [-75.8219719, 47.6923312],
  [-75.8209257, 47.6938991],
  [-75.8203362, 47.6941475],
  [-75.8192157, 47.6942578],
  [-75.8190263, 47.6944725],
  [-75.8186176, 47.6941463],
  [-75.8177146, 47.693916],
  [-75.8176435, 47.693241],
  [-75.8179287, 47.6928199],
  [-75.8176537, 47.6924585],
  [-75.8169948, 47.6919059],
  [-75.8159623, 47.6924124],
  [-75.8161931, 47.692081],
  [-75.815865, 47.6917012],
  [-75.8160691, 47.6913786],
  [-75.8153514, 47.6912484],
  [-75.8150103, 47.6908416],
  [-75.8144916, 47.6907756],
  [-75.8143519, 47.690253],
  [-75.8147273, 47.6900753],
  [-75.8147768, 47.689347],
  [-75.8141002, 47.6891271],
  [-75.8137461, 47.6887022],
  [-75.8132131, 47.688717],
  [-75.8132962, 47.6884747],
  [-75.812895, 47.6885892],
  [-75.8123121, 47.6883428],
  [-75.8120531, 47.6877746],
  [-75.8124689, 47.6875612],
  [-75.8127411, 47.6861057],
  [-75.8132114, 47.6858026],
  [-75.8139304, 47.683792],
  [-75.8152493, 47.6827655],
  [-75.8152574, 47.6821449],
  [-75.8158882, 47.6807634],
  [-75.8156905, 47.6806003],
  [-75.8154888, 47.680743],
  [-75.8151594, 47.6814877],
  [-75.8146949, 47.681359],
  [-75.8137474, 47.6814972],
  [-75.8134442, 47.6802361],
  [-75.8117393, 47.678211],
  [-75.8119289, 47.6779783],
  [-75.8113269, 47.6771651],
  [-75.8109467, 47.6746802],
  [-75.811551, 47.674306],
  [-75.8139505, 47.6751479],
  [-75.8143195, 47.675447],
  [-75.8147777, 47.6750359],
  [-75.8146132, 47.6743783],
  [-75.812074, 47.6740303],
  [-75.8115736, 47.6725881],
  [-75.8108995, 47.6721972],
  [-75.8103798, 47.6722211],
  [-75.8101312, 47.6718868],
  [-75.8102946, 47.6716089],
  [-75.8099123, 47.6713188],
  [-75.8093676, 47.6712166],
  [-75.8088006, 47.6707904],
  [-75.8085068, 47.6708516],
  [-75.8079421, 47.6702545],
  [-75.8080011, 47.6698141],
  [-75.8077344, 47.6698485],
  [-75.8078384, 47.670038],
  [-75.8069594, 47.6700417],
  [-75.8064969, 47.669769],
  [-75.8068718, 47.6696184],
  [-75.8057951, 47.669468],
  [-75.8056316, 47.6697458],
  [-75.8048965, 47.6699483],
  [-75.8043853, 47.6703409],
  [-75.8010006, 47.6714987],
  [-75.7973808, 47.6742831],
  [-75.795756, 47.6742821],
  [-75.7954124, 47.67534],
  [-75.795524, 47.6764288],
  [-75.7949098, 47.6767955],
  [-75.7944895, 47.6763522],
  [-75.793787, 47.676105],
  [-75.7938428, 47.6769059],
  [-75.792931, 47.677359],
  [-75.7932225, 47.6784582],
  [-75.7917833, 47.6814627],
  [-75.7903641, 47.6820026],
  [-75.7877839, 47.6817168],
  [-75.7876072, 47.6809961],
  [-75.7870531, 47.6806059],
  [-75.7871973, 47.6797972],
  [-75.7862099, 47.679926],
  [-75.7859935, 47.6801675],
  [-75.7850886, 47.6801079],
  [-75.784846, 47.6803133],
  [-75.7855307, 47.6808932],
  [-75.7870204, 47.6830073],
  [-75.7894742, 47.6886804],
  [-75.7912341, 47.6944751],
  [-75.7919988, 47.6990043],
  [-75.7943186, 47.7028505],
  [-75.7953524, 47.7052406],
  [-75.7974833, 47.7083119],
  [-75.7976463, 47.7090775],
  [-75.7971492, 47.7103607],
  [-75.7951241, 47.7122912],
  [-75.7919613, 47.7175158],
  [-75.791373, 47.719617],
  [-75.7908549, 47.7204684],
  [-75.7878957, 47.7224199],
  [-75.7857081, 47.7254557],
  [-75.7855959, 47.7287831],
  [-75.7860128, 47.7324197],
  [-75.7858314, 47.7359356],
  [-75.7856409, 47.7371937],
  [-75.7847754, 47.7370983],
  [-75.7852981, 47.7378842],
  [-75.7844647, 47.7383647],
  [-75.7848566, 47.7389608],
  [-75.7858526, 47.7392638],
  [-75.7854323, 47.7397649],
  [-75.785515, 47.740548],
  [-75.7852246, 47.7413018],
  [-75.7844948, 47.7420078],
  [-75.7843313, 47.7442106],
  [-75.7848834, 47.7448125],
  [-75.7859547, 47.7452454],
  [-75.786439, 47.7464792],
  [-75.7857168, 47.7472014],
  [-75.7860501, 47.7476181],
  [-75.7861334, 47.7484792],
  [-75.7847984, 47.7500242],
  [-75.7838688, 47.7507021],
  [-75.7826734, 47.7513152],
  [-75.7791896, 47.7524718],
  [-75.7779462, 47.7546137],
  [-75.7773735, 47.7574435],
  [-75.7781162, 47.7587165],
  [-75.778068, 47.7602723],
  [-75.7800454, 47.7619848],
  [-75.7804196, 47.7629046],
  [-75.780786, 47.766325],
  [-75.7805263, 47.7667731],
  [-75.779868, 47.7671018],
  [-75.7797006, 47.7676314],
  [-75.7804546, 47.7681039],
  [-75.7808295, 47.7699412],
  [-75.7828186, 47.7718066],
  [-75.7837933, 47.7737016],
  [-75.7837495, 47.7739712],
  [-75.7826857, 47.7746751],
  [-75.7824711, 47.7757262],
  [-75.7834676, 47.7770097],
  [-75.7855055, 47.7782637],
  [-75.7863586, 47.7793034],
  [-75.7893804, 47.7798529],
  [-75.7906604, 47.7809222],
  [-75.7893736, 47.7832889],
  [-75.7891181, 47.7844117],
  [-75.7886196, 47.7847594],
  [-75.7863044, 47.7852127],
  [-75.7856786, 47.7860814],
  [-75.7857199, 47.7869631],
  [-75.7865368, 47.7877418],
  [-75.7868361, 47.7892727],
  [-75.7882874, 47.7914945],
  [-75.7872956, 47.7927656],
  [-75.7866907, 47.7930767],
  [-75.784509, 47.7935038],
  [-75.7839735, 47.7945889],
  [-75.7824547, 47.795344],
  [-75.7821944, 47.7958281],
  [-75.7826558, 47.7981876],
  [-75.7815525, 47.8007532],
  [-75.7797511, 47.800688],
  [-75.7788809, 47.8008624],
  [-75.7782881, 47.8031794],
  [-75.7784318, 47.8043676],
  [-75.7745111, 47.8069154],
  [-75.7748321, 47.8087974],
  [-75.7747117, 47.8107305],
  [-75.7741485, 47.8128137],
  [-75.7732007, 47.8137522],
  [-75.7727307, 47.815854],
  [-75.7729861, 47.8176456],
  [-75.7721017, 47.8188183],
  [-75.7681563, 47.8201695],
  [-75.7669123, 47.8203054],
  [-75.7654454, 47.8201521],
  [-75.7634344, 47.8206969],
  [-75.7603089, 47.8226017],
  [-75.7556662, 47.8259718],
  [-75.7535438, 47.825877],
  [-75.7525736, 47.8264734],
  [-75.7509157, 47.8265885],
  [-75.7499987, 47.8271942],
  [-75.7491681, 47.8282861],
  [-75.7470497, 47.8297564],
  [-75.7447611, 47.8300652],
  [-75.7425064, 47.8307879],
  [-75.7418717, 47.8312604],
  [-75.7417187, 47.8316732],
  [-75.742411, 47.8336746],
  [-75.7408246, 47.8352742],
  [-75.7395236, 47.8374603],
  [-75.7387411, 47.8379768],
  [-75.7335508, 47.8393365],
  [-75.7302897, 47.8412216],
  [-75.7298266, 47.8427206],
  [-75.7285562, 47.844601],
  [-75.7274622, 47.8453852],
  [-75.7270708, 47.8465519],
  [-75.7261375, 47.8473101],
  [-75.7253164, 47.8475333],
  [-75.5206427, 47.8477379],
  [-75.520506, 48.2019316],
  [-75.5207778, 48.5855391],
  [-75.5205521, 49.0000699],
  [-77.9871084, 49.000079],
  [-77.98711, 49.000401],
  [-78.2057738, 49.0000795],
  [-78.6437899, 49.0002497],
  [-78.7548263, 48.9999406],
  [-78.7549784, 49.0091635],
  [-78.9034654, 49.0097265],
  [-78.903186, 49.0000422],
  [-79.0512765, 49.0000984],
  [-79.0532184, 49.0095294],
  [-79.1633606, 49.0101487],
  [-79.1786922, 49.0100149],
  [-79.1935301, 49.0104583],
  [-79.2993915, 49.0105146],
  [-79.5173032, 49.0088925],
  [-79.5177608, 47.5834121],
  [-79.5175858, 47.5602074],
  [-79.5172114, 47.5567664],
  [-79.5165027, 47.5542832],
  [-79.5153962, 47.5519295],
  [-79.5122605, 47.5481987],
  [-79.5110631, 47.5463486],
  [-79.5102697, 47.5432588],
  [-79.5107724, 47.5405769],
  [-79.5117565, 47.5382423],
  [-79.5136558, 47.5359343],
  [-79.5169761, 47.5332147],
  [-79.5222328, 47.5301515],
  [-79.5388647, 47.5215836],
  [-79.5463339, 47.5181314],
  [-79.5480807, 47.5169106],
  [-79.5529475, 47.5118331],
  [-79.5549472, 47.5081254],
  [-79.5557715, 47.5054475],
  [-79.5562899, 47.5014496],
  [-79.5559699, 47.4975472],
  [-79.5562204, 47.4926833],
  [-79.5572809, 47.486904],
  [-79.5592732, 47.4805909],
  [-79.5622787, 47.4750862],
  [-79.5673064, 47.4687691],
  [-79.5690838, 47.4668388],
  [-79.5792467, 47.4574738],
  [-79.583031, 47.4530564],
  [-79.5843588, 47.4509126],
  [-79.5859372, 47.4471168],
  [-79.5866472, 47.4438133],
  [-79.5870664, 47.4379882],
];

let polygons = [];
boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;
