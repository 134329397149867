const boundaries = [
  [-73.8953597, 45.5265897],
  [-73.8920175, 45.5246628],
  [-73.8881185, 45.522942],
  [-73.8780808, 45.5202541],
  [-73.8743901, 45.5203172],
  [-73.8637338, 45.5197588],
  [-73.8630604, 45.5180921],
  [-73.8616013, 45.516769],
  [-73.8571381, 45.5153256],
  [-73.8539922, 45.5116834],
  [-73.8531573, 45.5114469],
  [-73.8520418, 45.5116716],
  [-73.8498468, 45.5129799],
  [-73.8465852, 45.5165886],
  [-73.8448875, 45.517839],
  [-73.8420319, 45.5187536],
  [-73.8317365, 45.5186905],
  [-73.8206644, 45.5164081],
  [-73.8179135, 45.5164081],
  [-73.8103196, 45.5179703],
  [-73.8048264, 45.5157751],
  [-73.8007473, 45.5158067],
  [-73.7950825, 45.51301],
  [-73.7845683, 45.5116552],
  [-73.7705371, 45.5109049],
  [-73.7634387, 45.5119868],
  [-73.7606976, 45.5135449],
  [-73.7578572, 45.5167952],
  [-73.7525278, 45.5191378],
  [-73.7467884, 45.5200163],
  [-73.7419861, 45.5266635],
  [-73.7403107, 45.5283138],
  [-73.7301647, 45.5323448],
  [-73.7222002, 45.5374275],
  [-73.7166385, 45.5387067],
  [-73.7109357, 45.5409735],
  [-73.707967, 45.5443295],
  [-73.7006753, 45.5475393],
  [-73.6973673, 45.5486445],
  [-73.6937482, 45.5494725],
  [-73.6816649, 45.5516973],
  [-73.6787307, 45.5533341],
  [-73.676769, 45.5553444],
  [-73.6735399, 45.5605229],
  [-73.6674503, 45.5672005],
  [-73.6671258, 45.5749585],
  [-73.6601466, 45.584473],
  [-73.6511233, 45.5952632],
  [-73.643882, 45.6028754],
  [-73.6411817, 45.6096921],
  [-73.6399865, 45.6113523],
  [-73.6375403, 45.6188853],
  [-73.6325192, 45.6264789],
  [-73.6238275, 45.6334297],
  [-73.6161993, 45.6385411],
  [-73.6075723, 45.6448267],
  [-73.579998, 45.6585365],
  [-73.5637314, 45.6659784],
  [-73.5487041, 45.6756084],
  [-73.5333978, 45.6826772],
  [-73.5301989, 45.6861203],
  [-73.5277068, 45.6916678],
  [-73.5235036, 45.6992671],
  [-73.5296826, 45.699646],
  [-73.5327907, 45.7000494],
  [-73.541133, 45.6985893],
  [-73.5438459, 45.6986334],
  [-73.5513267, 45.7002827],
  [-73.555752, 45.7007485],
  [-73.5846475, 45.6935532],
  [-73.596824, 45.6897757],
  [-73.6011052, 45.6892595],
  [-73.6035477, 45.6896183],
  [-73.6098477, 45.6915826],
  [-73.6134097, 45.6930332],
  [-73.6151293, 45.6932825],
  [-73.6173465, 45.6929677],
  [-73.623998, 45.6929488],
  [-73.626026, 45.6921933],
  [-73.6284685, 45.6901535],
  [-73.6306857, 45.6893728],
  [-73.6368145, 45.6902889],
  [-73.6430447, 45.6904699],
  [-73.6459413, 45.6900548],
  [-73.6484749, 45.6893209],
  [-73.6509535, 45.688101],
  [-73.6522829, 45.686582],
  [-73.6562486, 45.6841186],
  [-73.657364, 45.683788],
  [-73.6583329, 45.6839297],
  [-73.6599327, 45.6846223],
  [-73.6614987, 45.6861964],
  [-73.6617465, 45.687495],
  [-73.6624458, 45.6878992],
  [-73.6640335, 45.6875737],
  [-73.6660953, 45.6859996],
  [-73.668495, 45.6857871],
  [-73.6714875, 45.6847418],
  [-73.6763285, 45.6846957],
  [-73.6800032, 45.685326],
  [-73.68436, 45.6863866],
  [-73.6925598, 45.6887479],
  [-73.6963083, 45.6860331],
  [-73.7012373, 45.6833429],
  [-73.7081793, 45.6806869],
  [-73.7125791, 45.6800219],
  [-73.7171614, 45.6817039],
  [-73.7229186, 45.6823969],
  [-73.7239877, 45.6822671],
  [-73.7363121, 45.6759635],
  [-73.7385426, 45.6717692],
  [-73.7454152, 45.6687165],
  [-73.7495071, 45.6672762],
  [-73.7499327, 45.6667226],
  [-73.749635, 45.6644281],
  [-73.7504608, 45.6630627],
  [-73.7525263, 45.6619914],
  [-73.7540007, 45.6608317],
  [-73.7549093, 45.6594196],
  [-73.7663803, 45.6499083],
  [-73.7694274, 45.648261],
  [-73.7712354, 45.6463155],
  [-73.7753794, 45.6438587],
  [-73.7770451, 45.6412171],
  [-73.7787312, 45.6349535],
  [-73.7846831, 45.6329223],
  [-73.7881162, 45.6305501],
  [-73.7885021, 45.623035],
  [-73.7916305, 45.6203356],
  [-73.794901, 45.6158884],
  [-73.7972192, 45.6150668],
  [-73.8000789, 45.6128148],
  [-73.8032259, 45.6107927],
  [-73.8048564, 45.6103616],
  [-73.8074841, 45.6104682],
  [-73.8103867, 45.6103152],
  [-73.8180123, 45.6076303],
  [-73.8201884, 45.607701],
  [-73.8220718, 45.608545],
  [-73.8248196, 45.6081799],
  [-73.8259964, 45.6061161],
  [-73.8261136, 45.6050618],
  [-73.8256397, 45.6039143],
  [-73.8254852, 45.6022974],
  [-73.8255917, 45.6004792],
  [-73.8261098, 45.5988327],
  [-73.8276799, 45.5969537],
  [-73.8293597, 45.5958843],
  [-73.8320977, 45.593056],
  [-73.8322645, 45.5921411],
  [-73.8320207, 45.5881608],
  [-73.8334508, 45.5853858],
  [-73.8330608, 45.5822808],
  [-73.8339709, 45.5801766],
  [-73.836311, 45.5789822],
  [-73.8404388, 45.5791756],
  [-73.8478168, 45.5774808],
  [-73.8524881, 45.5751485],
  [-73.8533413, 45.574466],
  [-73.8543367, 45.5717216],
  [-73.8584248, 45.5680092],
  [-73.8616941, 45.5671426],
  [-73.8742316, 45.561076],
  [-73.8778745, 45.5609154],
  [-73.8823728, 45.5603055],
  [-73.8850639, 45.5576657],
  [-73.885454, 45.5561909],
  [-73.8848282, 45.5549816],
  [-73.885467, 45.5524858],
  [-73.8852913, 45.5483208],
  [-73.8831743, 45.5387477],
  [-73.8826351, 45.5379831],
  [-73.8806463, 45.5364017],
  [-73.8855655, 45.5316411],
  [-73.8953597, 45.5265897],
];

let polygons = [];
boundaries.map((boundary) => 
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;

