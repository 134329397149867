const boundaries = [
  [-67.6666152, 48.1184115],
  [-67.6662283, 48.1150777],
  [-67.6625057, 48.112105],
  [-67.6598177, 48.108591],
  [-67.6592476, 48.1056794],
  [-67.6576263, 48.1020468],
  [-67.655503, 48.0986993],
  [-67.6522307, 48.0955137],
  [-67.6505725, 48.0917903],
  [-67.6510898, 48.0884273],
  [-67.6508689, 48.0850423],
  [-67.6491684, 48.0843077],
  [-67.6495037, 48.0813206],
  [-67.6491818, 48.0808332],
  [-67.6475832, 48.0805178],
  [-67.6448581, 48.0806039],
  [-67.6444289, 48.0804892],
  [-67.6436403, 48.0797957],
  [-67.6415321, 48.0793298],
  [-67.6410654, 48.0789445],
  [-67.6391396, 48.0796254],
  [-67.6386032, 48.0796398],
  [-67.6375195, 48.0785556],
  [-67.6368463, 48.0783513],
  [-67.6344645, 48.0783083],
  [-67.6336357, 48.0789767],
  [-67.633118, 48.0789337],
  [-67.6310688, 48.0777851],
  [-67.6292557, 48.0758174],
  [-67.6286656, 48.0737349],
  [-67.6260209, 48.0725986],
  [-67.6245886, 48.0714588],
  [-67.6243955, 48.0711146],
  [-67.6251948, 48.0700464],
  [-67.6241487, 48.0690105],
  [-67.6202488, 48.0674475],
  [-67.619825, 48.0670317],
  [-67.617926, 48.0671356],
  [-67.6152974, 48.0675981],
  [-67.6148415, 48.067469],
  [-67.6147074, 48.0666087],
  [-67.6136077, 48.0666374],
  [-67.6130283, 48.0664366],
  [-67.612052, 48.0653002],
  [-67.6110864, 48.063522],
  [-67.6100028, 48.0624967],
  [-67.6083934, 48.0623784],
  [-67.6070148, 48.0618764],
  [-67.6060814, 48.061016],
  [-67.6049548, 48.0607686],
  [-67.6039249, 48.0595962],
  [-67.6037854, 48.0587035],
  [-67.6022404, 48.0590333],
  [-67.6014841, 48.0588433],
  [-67.5986409, 48.0571796],
  [-67.5976378, 48.0569215],
  [-67.5945157, 48.0539795],
  [-67.5935823, 48.0535779],
  [-67.5940329, 48.0524806],
  [-67.5937647, 48.0522798],
  [-67.5919434, 48.0518674],
  [-67.5901383, 48.0507074],
  [-67.5870618, 48.0491672],
  [-67.5867292, 48.0486454],
  [-67.5868043, 48.0482312],
  [-67.5882876, 48.0473418],
  [-67.588183, 48.0466425],
  [-67.5876948, 48.0462409],
  [-67.585144, 48.045208],
  [-67.5854096, 48.0443024],
  [-67.5864852, 48.0437465],
  [-67.5869653, 48.0419049],
  [-67.586925, 48.040628],
  [-67.5858763, 48.0377622],
  [-67.5871235, 48.0350971],
  [-67.5885719, 48.0333322],
  [-67.5912166, 48.0317682],
  [-67.5932255, 48.0300194],
  [-67.5946873, 48.0292965],
  [-67.5956288, 48.0264838],
  [-67.5945666, 48.0256659],
  [-67.5918254, 48.0247904],
  [-67.5900579, 48.0237769],
  [-67.5897333, 48.0232136],
  [-67.590723, 48.0204544],
  [-67.590251, 48.0178225],
  [-67.590538, 48.0165756],
  [-67.5928044, 48.0128292],
  [-67.5918952, 48.0122012],
  [-67.5882769, 48.0116504],
  [-67.58744, 48.011392],
  [-67.587212, 48.0110923],
  [-67.5873274, 48.010231],
  [-67.5877807, 48.0097017],
  [-67.5899506, 48.0084617],
  [-67.592268, 48.0075824],
  [-67.5926049, 48.0070126],
  [-67.5912273, 48.0047758],
  [-67.5898781, 48.0034765],
  [-67.5894678, 48.0027712],
  [-67.5905138, 48.002292],
  [-67.5973964, 48.001226],
  [-67.5980455, 48.0006086],
  [-67.5965622, 47.9982431],
  [-67.5970933, 47.9963926],
  [-67.5980133, 47.9960139],
  [-67.6027662, 47.9948974],
  [-67.6051614, 47.9925353],
  [-67.6056013, 47.9923414],
  [-67.6097319, 47.991539],
  [-67.6105177, 47.9912105],
  [-67.6107591, 47.990865],
  [-67.6095495, 47.9894334],
  [-67.6092597, 47.9885464],
  [-67.6113895, 47.9867119],
  [-67.6102603, 47.9857084],
  [-67.6101556, 47.9852345],
  [-67.6108933, 47.9840226],
  [-67.6122236, 47.9825828],
  [-67.6134038, 47.9801662],
  [-67.61298, 47.9792972],
  [-67.6125348, 47.97915],
  [-67.6070255, 47.9784282],
  [-67.6041395, 47.9784138],
  [-67.6026374, 47.9781481],
  [-67.6022083, 47.9778787],
  [-67.6013113, 47.9757315],
  [-67.6012266, 47.9736376],
  [-67.5974822, 47.9723339],
  [-67.5966781, 47.9704408],
  [-67.5979221, 47.9654595],
  [-67.599049, 47.9640546],
  [-67.6005618, 47.9632941],
  [-67.6067953, 47.9622755],
  [-67.6072025, 47.9621009],
  [-67.6077617, 47.9611338],
  [-67.6076263, 47.9591194],
  [-67.606827, 47.9562562],
  [-67.606194, 47.9556095],
  [-67.6025087, 47.9545461],
  [-67.6017898, 47.9539533],
  [-67.6016879, 47.9534935],
  [-67.6016933, 47.952933],
  [-67.6021151, 47.9524854],
  [-67.6083881, 47.9493401],
  [-67.6085076, 47.9484689],
  [-67.6080695, 47.9472986],
  [-67.6051372, 47.942901],
  [-67.6049346, 47.9420934],
  [-67.6062201, 47.9392909],
  [-67.6079187, 47.9372949],
  [-67.6079053, 47.936763],
  [-67.6076102, 47.9364791],
  [-67.6058448, 47.9350676],
  [-67.6018015, 47.9337055],
  [-67.5966051, 47.9326694],
  [-67.5957441, 47.9321123],
  [-67.594741, 47.9302181],
  [-67.5946176, 47.9260269],
  [-67.5935501, 47.9246268],
  [-67.5930163, 47.9244273],
  [-67.5908491, 47.9242601],
  [-67.5894383, 47.9245135],
  [-67.5867185, 47.9257016],
  [-67.5838968, 47.927711],
  [-67.5832719, 47.9279895],
  [-67.5821373, 47.928056],
  [-67.5786048, 47.9257735],
  [-67.5746781, 47.924465],
  [-67.5691098, 47.921848],
  [-67.5650192, 47.9206133],
  [-67.562069, 47.9201296],
  [-67.5595093, 47.9194423],
  [-67.5577697, 47.9192502],
  [-67.5571176, 47.9193674],
  [-67.552051, 47.9212242],
  [-67.5488071, 47.9218048],
  [-67.5478238, 47.9217851],
  [-67.5464036, 47.9209652],
  [-67.5450684, 47.9195701],
  [-67.5427516, 47.9179121],
  [-67.5397182, 47.916565],
  [-67.5336349, 47.9152167],
  [-67.5299281, 47.9148859],
  [-67.5286996, 47.9137713],
  [-67.5282168, 47.9136419],
  [-67.5214094, 47.9145947],
  [-67.5202292, 47.9143142],
  [-67.5190544, 47.9136275],
  [-67.5183088, 47.9134369],
  [-67.5166029, 47.9134369],
  [-67.5141031, 47.9139619],
  [-67.5132287, 47.9138432],
  [-67.5103587, 47.9126172],
  [-67.5086796, 47.9113515],
  [-67.5074603, 47.9084139],
  [-67.5024405, 47.9062577],
  [-67.4906276, 47.9031391],
  [-67.4892781, 47.9029751],
  [-67.4863941, 47.9035029],
  [-67.4855082, 47.9034949],
  [-67.4817058, 47.9027811],
  [-67.4815799, 47.9006254],
  [-67.4810404, 47.8990265],
  [-67.4782288, 47.8978549],
  [-67.4749584, 47.8968369],
  [-67.4735899, 47.8949656],
  [-67.4728134, 47.8943818],
  [-67.4709946, 47.8937693],
  [-67.4675497, 47.8933237],
  [-67.4653412, 47.8927995],
  [-67.4636124, 47.8915557],
  [-67.4610752, 47.890609],
  [-67.4588603, 47.8892658],
  [-67.4549722, 47.8896631],
  [-67.4529094, 47.8894733],
  [-67.4514413, 47.8888234],
  [-67.4498802, 47.8873629],
  [-67.4482441, 47.8863448],
  [-67.4433675, 47.8857879],
  [-67.4413666, 47.8853097],
  [-67.4402419, 47.8844879],
  [-67.4384708, 47.8819541],
  [-67.438347, 47.8805341],
  [-67.4356763, 47.8795894],
  [-67.4284748, 47.877614],
  [-67.4271698, 47.8776177],
  [-67.4229813, 47.8784682],
  [-67.4214596, 47.8782213],
  [-67.4190377, 47.8753296],
  [-67.4184578, 47.8750128],
  [-67.4150491, 47.8740763],
  [-67.4116931, 47.873854],
  [-67.4096018, 47.873167],
  [-67.4082534, 47.8722052],
  [-67.4059705, 47.8711578],
  [-67.4005034, 47.8699719],
  [-67.3998555, 47.8683825],
  [-67.398127, 47.8668811],
  [-67.3971642, 47.8667691],
  [-67.3935711, 47.867556],
  [-67.392869, 47.8673326],
  [-67.3919177, 47.8665266],
  [-67.391561, 47.8652922],
  [-67.3939194, 47.8613951],
  [-67.3940286, 47.8605829],
  [-67.3933975, 47.8596751],
  [-67.392604, 47.859231],
  [-67.3892463, 47.8582244],
  [-67.385881, 47.8579257],
  [-67.381497, 47.8578191],
  [-67.3799793, 47.857143],
  [-67.3793703, 47.8558623],
  [-67.3797809, 47.8545362],
  [-67.38074, 47.8537071],
  [-67.3828747, 47.8528806],
  [-67.3863725, 47.8522693],
  [-67.3889926, 47.8515861],
  [-67.3897962, 47.8508963],
  [-67.3898685, 47.8502036],
  [-67.3874584, 47.8487614],
  [-67.3837147, 47.8471806],
  [-67.3784865, 47.8462497],
  [-67.3770024, 47.8457616],
  [-67.3756787, 47.8450374],
  [-67.3746282, 47.8440582],
  [-67.3741962, 47.8439573],
  [-67.370765, 47.8450293],
  [-67.3608138, 47.844752],
  [-67.3587335, 47.8456471],
  [-67.3574824, 47.846509],
  [-67.3567279, 47.8475822],
  [-67.356639, 47.8485302],
  [-67.3569147, 47.8495794],
  [-67.3577177, 47.8508439],
  [-67.3589634, 47.8523772],
  [-67.3606888, 47.8536659],
  [-67.3630457, 47.8546641],
  [-67.3637196, 47.8552001],
  [-67.3642304, 47.8562987],
  [-67.3638753, 47.8574868],
  [-67.3621332, 47.8593539],
  [-67.3590177, 47.8613718],
  [-67.356774, 47.8625318],
  [-67.3518299, 47.866201],
  [-67.3512893, 47.8670966],
  [-67.3510591, 47.8683577],
  [-67.3514959, 47.869365],
  [-67.3527314, 47.8709269],
  [-67.3528466, 47.8722386],
  [-67.3521831, 47.8731317],
  [-67.350993, 47.8737978],
  [-67.3496982, 47.8739821],
  [-67.3475094, 47.8737281],
  [-67.3465107, 47.8732411],
  [-67.3461469, 47.8726696],
  [-67.3467605, 47.8710339],
  [-67.3464633, 47.8701527],
  [-67.3455377, 47.8687136],
  [-67.3444773, 47.8680563],
  [-67.3428857, 47.8674569],
  [-67.3418024, 47.8675683],
  [-67.3407937, 47.868059],
  [-67.3379002, 47.8703415],
  [-67.3366948, 47.871853],
  [-67.3365869, 47.8731273],
  [-67.3380982, 47.8785812],
  [-67.3372945, 47.8832531],
  [-67.3377004, 47.8864652],
  [-67.3383256, 47.8882078],
  [-67.3412925, 47.8899854],
  [-67.3460998, 47.8916885],
  [-67.3484357, 47.89292],
  [-67.3491116, 47.8935781],
  [-67.3492176, 47.8943293],
  [-67.348959, 47.8952625],
  [-67.3480366, 47.895801],
  [-67.345696, 47.8958664],
  [-67.3428284, 47.8952132],
  [-67.3386392, 47.8926446],
  [-67.3362277, 47.8907564],
  [-67.3334495, 47.8898998],
  [-67.3264543, 47.8893734],
  [-67.3226543, 47.8888777],
  [-67.3120481, 47.8873087],
  [-67.3064774, 47.8858041],
  [-67.3044214, 47.8856894],
  [-67.3011528, 47.8861091],
  [-67.2951934, 47.8881989],
  [-67.2943444, 47.8888936],
  [-67.2941621, 47.8919975],
  [-67.2977341, 47.8949536],
  [-67.2984401, 47.8958119],
  [-67.2986541, 47.8969128],
  [-67.298402, 47.8981402],
  [-67.2979648, 47.8986924],
  [-67.2972213, 47.8991156],
  [-67.2961848, 47.8992275],
  [-67.291033, 47.8990708],
  [-67.2872971, 47.8987133],
  [-67.2863732, 47.8985106],
  [-67.2834603, 47.8969663],
  [-67.2824548, 47.8962493],
  [-67.2798248, 47.8934962],
  [-67.2776508, 47.8924808],
  [-67.2739005, 47.891321],
  [-67.2704101, 47.890515],
  [-67.2680494, 47.8908167],
  [-67.2664203, 47.891437],
  [-67.2636415, 47.8918608],
  [-67.2620002, 47.8917263],
  [-67.2594194, 47.8910989],
  [-67.2574445, 47.8898062],
  [-67.2551512, 47.8872675],
  [-67.2527513, 47.8850805],
  [-67.2479966, 47.882729],
  [-67.2466497, 47.8822555],
  [-67.2425905, 47.8816646],
  [-67.2413935, 47.8818959],
  [-67.2389387, 47.8831382],
  [-67.2356998, 47.8854884],
  [-67.2330685, 47.8868595],
  [-67.2314791, 47.8873415],
  [-67.2289555, 47.8872571],
  [-67.2265722, 47.8862665],
  [-67.224523, 47.8847218],
  [-67.2214725, 47.8813258],
  [-67.2165572, 47.8770455],
  [-67.2133061, 47.8757579],
  [-67.2116087, 47.875524],
  [-67.2102856, 47.8756545],
  [-67.2051156, 47.8763946],
  [-67.2014348, 47.8772819],
  [-67.198846, 47.8780957],
  [-67.1944289, 47.8802395],
  [-67.192458, 47.8809339],
  [-67.1902453, 47.8814178],
  [-67.1805075, 47.8817532],
  [-67.1753109, 47.882734],
  [-67.1739599, 47.8835097],
  [-67.1733284, 47.8843326],
  [-67.1727302, 47.8866753],
  [-67.1726179, 47.8892238],
  [-67.1709477, 47.8929372],
  [-67.1704451, 47.8937256],
  [-67.1681488, 47.8957588],
  [-67.1666497, 47.8965206],
  [-67.1644551, 47.8969895],
  [-67.1608306, 47.8962187],
  [-67.1598965, 47.8957366],
  [-67.1554692, 47.8923408],
  [-67.1525733, 47.890906],
  [-67.1504558, 47.8904711],
  [-67.1456323, 47.8915418],
  [-67.1401865, 47.8934376],
  [-67.1379448, 47.894348],
  [-67.1368037, 47.8952071],
  [-67.1337988, 47.8978157],
  [-67.1330454, 47.8990273],
  [-67.1326311, 47.9024194],
  [-67.1330354, 47.9054413],
  [-67.1351746, 47.9088313],
  [-67.135645, 47.910017],
  [-67.1349108, 47.913273],
  [-67.13277, 47.914941],
  [-67.1302677, 47.9154161],
  [-67.1246907, 47.9153118],
  [-67.1180349, 47.91485],
  [-67.1112219, 47.9136222],
  [-67.1059467, 47.9132675],
  [-67.1034159, 47.914091],
  [-67.0941756, 47.9190175],
  [-67.0912543, 47.9201498],
  [-67.0848221, 47.9213665],
  [-67.0727006, 47.9216116],
  [-67.0706922, 47.9222056],
  [-67.0687609, 47.9239019],
  [-67.0678398, 47.9252261],
  [-67.0657798, 47.9294789],
  [-67.0635712, 47.9327364],
  [-67.0611099, 47.9349246],
  [-67.0596908, 47.935402],
  [-67.0584682, 47.9355278],
  [-67.0562527, 47.9353963],
  [-67.0533169, 47.934984],
  [-67.0486919, 47.9332892],
  [-67.0471562, 47.9321564],
  [-67.0455549, 47.9278462],
  [-67.0431119, 47.9256457],
  [-67.0410449, 47.9241659],
  [-67.0402343, 47.9238181],
  [-67.0368559, 47.9235013],
  [-67.0322, 47.9202937],
  [-67.0260793, 47.9179957],
  [-67.0213199, 47.9159126],
  [-67.0196816, 47.9149165],
  [-67.0170607, 47.9137508],
  [-67.0122825, 47.9120398],
  [-67.0056402, 47.9091455],
  [-67.0035739, 47.9079306],
  [-66.9969222, 47.9021226],
  [-66.995466, 47.9012188],
  [-66.9911747, 47.898966],
  [-66.9852395, 47.8964787],
  [-66.9808427, 47.8942961],
  [-66.9785033, 47.8935106],
  [-66.9734699, 47.8924384],
  [-66.9664642, 47.8920851],
  [-66.9571241, 47.8922034],
  [-66.9558831, 47.8923938],
  [-66.9515592, 47.8943709],
  [-66.9478069, 47.8968531],
  [-66.9450748, 47.9003031],
  [-66.9444092, 47.9028112],
  [-66.9450819, 47.9045447],
  [-66.947709, 47.9071959],
  [-66.9508466, 47.9091993],
  [-66.9551714, 47.9101924],
  [-66.9604284, 47.9106326],
  [-66.9652067, 47.9117358],
  [-66.9671159, 47.912553],
  [-66.968784, 47.9136905],
  [-66.9702922, 47.9153344],
  [-66.9703796, 47.917027],
  [-66.9700304, 47.9179376],
  [-66.9664927, 47.9243742],
  [-66.9649384, 47.9317103],
  [-66.964628, 47.9352233],
  [-66.9649359, 47.9396774],
  [-66.9646839, 47.9414031],
  [-66.9633938, 47.9440895],
  [-66.9617996, 47.9460614],
  [-66.9588983, 47.9477991],
  [-66.9556018, 47.9491124],
  [-66.9510839, 47.9514806],
  [-66.9478276, 47.9540139],
  [-66.9454181, 47.9570091],
  [-66.943696, 47.961866],
  [-66.9436202, 47.9647447],
  [-66.9425788, 47.970357],
  [-66.9404033, 47.9718832],
  [-66.9361567, 47.9735932],
  [-66.9344948, 47.9740742],
  [-66.9309209, 47.9746115],
  [-66.930108, 47.9750296],
  [-66.9270179, 47.9781456],
  [-66.9257218, 47.9789967],
  [-66.9248794, 47.9799906],
  [-66.9238748, 47.9821433],
  [-66.9214104, 47.9843466],
  [-66.9195322, 47.9849506],
  [-66.9091735, 47.9872822],
  [-66.9054944, 47.9884354],
  [-66.8992246, 47.9898332],
  [-66.8969769, 47.9905752],
  [-66.8958283, 47.9914454],
  [-66.8941239, 47.9920502],
  [-66.8905663, 47.9926579],
  [-66.8837194, 47.9925116],
  [-66.8818889, 47.9930011],
  [-66.8792507, 47.9931579],
  [-66.8754654, 47.9938068],
  [-66.8726276, 47.9953018],
  [-66.8678261, 47.9972203],
  [-66.861892, 47.9983974],
  [-66.8598141, 47.9990948],
  [-66.8587833, 47.9998887],
  [-66.8572447, 48.0003862],
  [-66.855432, 48.0006281],
  [-66.8490169, 48.0009254],
  [-66.8441805, 48.0005955],
  [-66.8351307, 48.0005273],
  [-66.8266329, 47.9988937],
  [-66.8108649, 47.9951173],
  [-66.8082795, 47.9949599],
  [-66.8028587, 47.9952547],
  [-66.8016211, 47.9950897],
  [-66.8010781, 47.9945529],
  [-66.801329, 47.9932409],
  [-66.8006376, 47.9917487],
  [-66.7984525, 47.9912835],
  [-66.7841289, 47.9921065],
  [-66.7812939, 47.99262],
  [-66.7800258, 47.9931881],
  [-66.7806057, 47.9937586],
  [-66.7808569, 47.9948077],
  [-66.7826271, 47.9951424],
  [-66.7834295, 47.9957642],
  [-66.7835117, 47.9959676],
  [-66.7831752, 47.9960961],
  [-66.7788437, 47.9957811],
  [-66.7773589, 47.9959277],
  [-66.7758711, 47.9965942],
  [-66.7733005, 47.9981925],
  [-66.7668357, 47.9996782],
  [-66.76546, 48.0011263],
  [-66.7644437, 48.0016959],
  [-66.7596029, 48.0029268],
  [-66.7576831, 48.0031136],
  [-66.7484277, 48.0029578],
  [-66.7434309, 48.0033701],
  [-66.7312595, 48.0080045],
  [-66.7267447, 48.0093662],
  [-66.7221338, 48.0089909],
  [-66.7136515, 48.0066065],
  [-66.7106674, 48.006169],
  [-66.7048396, 48.0063929],
  [-66.702899, 48.0061076],
  [-66.7004491, 48.0053563],
  [-66.6990865, 48.0053468],
  [-66.6963506, 48.00613],
  [-66.6943395, 48.0074281],
  [-66.6907579, 48.0090423],
  [-66.6848216, 48.0106343],
  [-66.6788794, 48.0118324],
  [-66.6770902, 48.0124949],
  [-66.6754984, 48.01329],
  [-66.6740218, 48.0144286],
  [-66.6732856, 48.0161653],
  [-66.6721148, 48.0176668],
  [-66.6713038, 48.0181598],
  [-66.6687892, 48.0183292],
  [-66.6613215, 48.0168326],
  [-66.6584216, 48.0167978],
  [-66.6554676, 48.0171949],
  [-66.6487246, 48.018836],
  [-66.6393677, 48.020559],
  [-66.6194986, 48.0253045],
  [-66.5679153, 48.0382134],
  [-66.53526, 48.0464888],
  [-66.5300694, 48.0481209],
  [-66.5274341, 48.0491895],
  [-66.5229202, 48.0519316],
  [-66.5193991, 48.0555204],
  [-66.5156983, 48.0602623],
  [-66.5084417, 48.0711375],
  [-66.5055948, 48.0745646],
  [-66.5038822, 48.0759609],
  [-66.5024311, 48.0770251],
  [-66.4999356, 48.0780195],
  [-66.4876054, 48.0817495],
  [-66.4799075, 48.0835891],
  [-66.4670769, 48.0858132],
  [-66.4526721, 48.087128],
  [-66.4010991, 48.0896594],
  [-66.38682, 48.0888487],
  [-66.3821862, 48.0882842],
  [-66.3706665, 48.0861118],
  [-66.3591934, 48.0831302],
  [-66.3537576, 48.0812634],
  [-66.3482135, 48.0783542],
  [-66.3429564, 48.0745987],
  [-66.3295442, 48.0628998],
  [-66.3212715, 48.0518558],
  [-66.3134329, 48.0385062],
  [-66.3094057, 48.0347175],
  [-66.3058314, 48.0331311],
  [-66.3034842, 48.0328304],
  [-66.301538, 48.0327075],
  [-66.2639904, 48.0357279],
  [-66.204689, 48.0458307],
  [-66.1886945, 48.0476876],
  [-66.1704309, 48.0490229],
  [-66.1608784, 48.0489975],
  [-66.0030068, 48.0340324],
  [-65.9166561, 48.0273594],
  [-65.8581465, 48.0222337],
  [-65.6064082, 47.9336109],
  [-65.5361394, 47.8286068],
  [-65.1116784, 47.9211034],
  [-64.9481528, 47.9927772],
  [-64.4468737, 48.2100036],
  [-64.2737962, 48.2206848],
  [-63.625121, 48.0000632],
  [-63.4991594, 47.831836],
  [-63.3313838, 47.6058436],
  [-63.2524197, 47.5000123],
  [-62.98636, 47.1397285],
  [-62.2999563, 46.839999],
  [-61.3993669, 46.8399985],
  [-61.3504814, 47.0097234],
  [-60.9918489, 47.3294321],
  [-60.7626788, 47.4233275],
  [-60.4037537, 47.7610742],
  [-60.45868, 48.5285048],
  [-62.9029945, 48.5149234],
  [-63.7519074, 48.5108858],
  [-63.8638025, 48.6671273],
  [-64.0862123, 48.7493091],
  [-64.1975649, 49.3371535],
  [-64.4126832, 49.4359649],
  [-64.6512454, 49.5297118],
  [-64.7840664, 49.573766],
  [-64.9991962, 49.6317991],
  [-65.0707799, 49.6464685],
  [-65.1928245, 49.6502903],
  [-65.3629273, 49.7136403],
  [-65.5853033, 49.7154402],
  [-65.7001159, 49.7188035],
  [-65.7571653, 49.7186618],
  [-65.8185992, 49.7170238],
  [-65.8801974, 49.7140517],
  [-65.9436114, 49.7093604],
  [-66.0000147, 49.7040485],
  [-66.0784907, 49.6926989],
  [-66.1575492, 49.6786465],
  [-66.2476645, 49.6580255],
  [-66.2957067, 49.6452296],
  [-66.3770014, 49.6190925],
  [-66.4706501, 49.5825882],
  [-66.4992642, 49.5699979],
  [-66.563434, 49.5391654],
  [-66.9992862, 49.2792181],
  [-67.1533414, 49.189062],
  [-66.9087298, 49.0341985],
  [-66.8553762, 49.0000611],
  [-66.8324701, 48.9851157],
  [-66.8092177, 48.992837],
  [-66.7883122, 49.0000304],
  [-66.733505, 49.0174386],
  [-66.7067929, 49.0000355],
  [-66.7051875, 48.9992502],
  [-66.7055935, 48.9983344],
  [-66.7066853, 48.9974014],
  [-66.7066147, 48.9956757],
  [-66.7036242, 48.9919477],
  [-66.6997148, 48.9882586],
  [-66.7018103, 48.9848136],
  [-66.701062, 48.9816299],
  [-66.7004939, 48.9815369],
  [-66.7008096, 48.9803908],
  [-66.7015804, 48.9791386],
  [-66.701947, 48.9789249],
  [-66.7035098, 48.9788775],
  [-66.7040479, 48.9786778],
  [-66.704195, 48.9782657],
  [-66.7019981, 48.9772874],
  [-66.7008061, 48.9764053],
  [-66.6971653, 48.9727623],
  [-66.6967489, 48.9707887],
  [-66.6970199, 48.969788],
  [-66.6977435, 48.9694574],
  [-66.698821, 48.9693275],
  [-66.6995911, 48.9695593],
  [-66.7001211, 48.9690783],
  [-66.7002418, 48.9685982],
  [-66.6992558, 48.967432],
  [-66.6972769, 48.9663625],
  [-66.6968377, 48.9657365],
  [-66.6965419, 48.9643487],
  [-66.6969701, 48.9632979],
  [-66.6983798, 48.9623958],
  [-66.6987254, 48.9618768],
  [-66.6988844, 48.9597354],
  [-66.6986149, 48.9584157],
  [-66.6988548, 48.9578336],
  [-66.7003057, 48.9564565],
  [-66.6972312, 48.9557102],
  [-66.6960075, 48.9549773],
  [-66.6956727, 48.9539358],
  [-66.6941872, 48.9525828],
  [-66.693666, 48.951423],
  [-66.6941753, 48.9502184],
  [-66.6956403, 48.9499913],
  [-66.6969852, 48.9502643],
  [-66.6979478, 48.9493316],
  [-66.6979207, 48.9488051],
  [-66.6957596, 48.9464431],
  [-66.6945589, 48.9439244],
  [-66.6945816, 48.9434336],
  [-66.6981673, 48.9385641],
  [-66.6982233, 48.9381733],
  [-66.6964557, 48.9373989],
  [-66.6954413, 48.9367117],
  [-66.6934159, 48.9343722],
  [-66.6934286, 48.9339578],
  [-66.6967043, 48.9313119],
  [-66.6993179, 48.9287867],
  [-66.7000046, 48.9273991],
  [-66.6999062, 48.9265116],
  [-66.6993004, 48.9255943],
  [-66.6986328, 48.9250758],
  [-66.6960214, 48.9240062],
  [-66.6953409, 48.9234637],
  [-66.6951225, 48.9230763],
  [-66.695334, 48.9206331],
  [-66.6956863, 48.9201462],
  [-66.6977186, 48.9190919],
  [-66.699977, 48.9191244],
  [-66.7007353, 48.9189743],
  [-66.7026316, 48.9185045],
  [-66.7047603, 48.9176298],
  [-66.7083361, 48.9168013],
  [-66.7087438, 48.916551],
  [-66.7089479, 48.9155795],
  [-66.709899, 48.9144941],
  [-66.7102817, 48.9135886],
  [-66.7101959, 48.9129343],
  [-66.7113964, 48.912465],
  [-66.7130855, 48.9126571],
  [-66.7122845, 48.9115292],
  [-66.7123324, 48.9108571],
  [-66.7128752, 48.9097929],
  [-66.7141165, 48.9092727],
  [-66.7044371, 48.9029412],
  [-66.5656166, 48.9489396],
  [-66.5378745, 48.9583402],
  [-66.4123399, 48.8750545],
  [-66.0996203, 48.6688857],
  [-66.0000226, 48.6037398],
  [-65.9840578, 48.5930566],
  [-65.9804727, 48.5895201],
  [-65.9484142, 48.5671378],
  [-66.130073, 48.5208536],
  [-66.2801179, 48.4835809],
  [-66.6074952, 48.4009606],
  [-66.7732667, 48.3579555],
  [-66.8353211, 48.3414044],
  [-66.9753461, 48.3026796],
  [-66.8431507, 48.1520863],
  [-66.8405418, 48.1531018],
  [-66.8368481, 48.1475467],
  [-66.8396509, 48.1468225],
  [-66.8757255, 48.1829091],
  [-66.9003771, 48.200353],
  [-66.9345734, 48.1811386],
  [-66.8795784, 48.1369584],
  [-66.977272, 48.1139088],
  [-67.0390557, 48.0982212],
  [-67.0744698, 48.0907188],
  [-67.0977119, 48.0847243],
  [-67.0967782, 48.0816414],
  [-67.097953, 48.0787993],
  [-67.1206009, 48.0733045],
  [-67.1602818, 48.062354],
  [-67.2022792, 48.0521928],
  [-67.2775862, 48.0315154],
  [-67.3749868, 48.1952593],
  [-67.6017731, 48.1350013],
  [-67.6666152, 48.1184115],
];

let polygons = [];
boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons