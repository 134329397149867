import saintLaurent from "./quebec-regions-borders/bas-saint-laurent";
import lacSaintJean from "./quebec-regions-borders/saguanay-lac";
import appalaches from "./quebec-regions-borders/chaudiere-apalache";
import northCoast from "./quebec-regions-borders/cote-nord";
import northQuebec from "./quebec-regions-borders/nord-quebec";
import nationalCapital from "./quebec-regions-borders/capital-nationale";
import abitibi from "./quebec-regions-borders/abitibi";
import centralQuebec from "./quebec-regions-borders/centre-quebec";
import outaouais from "./quebec-regions-borders/outaouais";
import mauricie from "./quebec-regions-borders/mauricie";
import lanaudiere from "./quebec-regions-borders/lanaudiere";
import laurentides from "./quebec-regions-borders/laurentides";
import estrie from "./quebec-regions-borders/estrie";
import monteregie from "./quebec-regions-borders/monteregie";
import gaspesie from "./quebec-regions-borders/gaspesie";
import madeleine from "./quebec-regions-borders/madeleine";
import montreal from "./quebec-regions-borders/montreal";
import laval from "./quebec-regions-borders/laval";

const quebecRegionsBorders = {
  "Bas-Saint-Laurent": saintLaurent,
  "Saguenay-Lac-Saint-Jean": lacSaintJean,
  "Chaudière-Appalaches": appalaches,
  "Côte-Nord": northCoast,
  "Nord-du-Québec": northQuebec,
  "Capitale-Nationale": nationalCapital,
  "Abitibi-Témiscamingue": abitibi,
  "Centre-du-Québec": centralQuebec,
  "Outaouais": outaouais,
  "Mauricie": mauricie,
  "Lanaudière": lanaudiere,
  "Laurentides": laurentides,
  "Estrie": estrie,
  "Montérégie": monteregie,
  "Gaspésie": gaspesie,
  "Iles-de-la-Madeleine": madeleine,
  "Montréal": montreal,
  "Laval": laval,
}

const defaultZoom = 4;
const defaultCenter = { lat: 40.71, lng: -74.0 };

const polygonStyle = {
  fillColor: "##686868",
  fillOpacity: 0.4,
  strokeColor: "#686868",
  strokeOpacity: 1,
  strokeWeight: 1,
};

const availableLanguages = {
  FR: {
    abbreviation: "fr",
    plaintext: "French",
  },
  EN: {
    abbreviation: "en",
    plaintext: "English",
  },
};

const availableStatus = {
  PUBLISHED: "Published",
  DRAFT: "Unpublished",
};

const defaultCountryName = {
  EN: "Quebec",
  FR: "Québec",
};

export {
  quebecRegionsBorders,
  defaultCenter,
  defaultZoom,
  polygonStyle,
  availableLanguages,
  availableStatus,
  defaultCountryName
};
