import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useJsApiLoader, GoogleMap, Polygon } from "@react-google-maps/api";

import "./App.css";
import {
  defaultZoom,
  defaultCenter,
  availableLanguages,
  quebecRegionsBorders,
  availableStatus,
  defaultCountryName,
} from "./configs/constants";
import { containerStyle } from "./styles";
import CustomGoogleMap from "./styles.json";
import Mountain from "./components/mountains/Mountain";
import RegionList from "./components/lists/RegionList";
import QuebecRegionsList from "./components/lists/QuebecRegionsList";
import ModalBackdrop from "./components/modals/ModalBackdrop";
import DonationModal from "./components/modals/DonationModal";

function App() {
  const search = useLocation().search;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: new URLSearchParams(search).get("lang"),
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [mountainName, setMountainName] = useState([]);
  const [mountainInfo, setMountainInfo] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultRegion, setDefaultRegion] = useState(null);
  const [mountains, setMountains] = useState([]);
  const [quebecId, setQuebecId] = useState(null);
  const [currentCountryId, setCurrentCountryId] = useState(null);
  const [currentCountryName, setCurrentCountryName] = useState(null);
  const [quebecRegions, setQuebecRegions] = useState([]);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [selectedRegionName, selectClickedRegionName] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(
    availableLanguages.FR.abbreviation
  );

  useEffect(() => {
    setCurrentLanguage(new URLSearchParams(search).get("lang"));

    const initCountries = (countries) => {
      const defaultCountry = currentLanguage
        ? defaultCountryName[currentLanguage.toUpperCase()]
        : defaultCountryName.FR;

      setCountries(countries);
      countries.sort((countryA, countryB) => {
        if (countryA.position < countryB.position) return -1;
        if (countryA.position > countryB.position) return 1;
        return 0;
      });
      let defaultFound = countries.find(
        (country) => country.name === defaultCountry
      );
      defaultFound = defaultFound || countries[0] || null;
      setDefaultRegion(defaultFound);
      if (defaultFound) {
        setCurrentCountryId(defaultFound.id);
        setQuebecId(defaultFound.id);
        setCurrentCountryName(defaultCountry);
      }
    };

    const lang = currentLanguage
      ? availableLanguages[currentLanguage.toUpperCase()].plaintext
      : availableLanguages.FR.plaintext;

    /**
     * @description Fetch and process Quebec administrative regions data
     */
    const fetchQuebecRegionsData = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_API_SERVER_BASE_URL}/api/v2/all-regions`
      );
      const allData = await data.json();

      let allRegions = [];
      let allCountries = [];
      allData.forEach((fetchedData, index) => {
        let alreadyAdded = false;

        const hasLang = fetchedData.langcode
          ? fetchedData.langcode &&
            fetchedData.langcode_1 &&
            fetchedData.langcode === fetchedData.langcode_1
          : fetchedData.langcode_1 !== null &&
            fetchedData.langcode_1 !== undefined;

        if (hasLang && fetchedData.langcode_1 === lang) {
          alreadyAdded = allRegions
            .map((elmt) => elmt.name)
            .includes(fetchedData.name);

          if (
            !alreadyAdded &&
            fetchedData.name &&
            fetchedData.parent_target_id &&
            fetchedData.view_node !== null &&
            fetchedData.view_node !== undefined
          ) {
            const data = {
              id: index + 1,
              borders: quebecRegionsBorders[fetchedData.name],
              name: fetchedData.name,
              country: fetchedData.parent_target_id,
              hasChallenge:
                fetchedData.view_node && fetchedData.view_node.length > 0
                  ? true
                  : false,
              link: fetchedData.view_node,
              position: Number.parseFloat(fetchedData.field_menu_order),
            };
            allRegions.push(data);
          }

          if (
            fetchedData.name &&
            fetchedData.field_zoom &&
            fetchedData.field_menu_order &&
            fetchedData.field_region_coordinates &&
            !allCountries.map((elmt) => elmt.name).includes(fetchedData.name)
          ) {
            const lng = Number.parseFloat(
              fetchedData.field_region_coordinates?.split(",")[1]
            );
            const lat = Number.parseFloat(
              fetchedData.field_region_coordinates?.split(",")[0]
            );
            const countryData = {
              id: index + 1,
              name: fetchedData.name,
              center: { lat, lng },
              zoom: Number.parseFloat(fetchedData.field_zoom),
              position: Number.parseFloat(fetchedData.field_menu_order),
            };
            allCountries.push(countryData);
          }
        }
      });

      allRegions.sort((regionA, regionB) => {
        if (regionA.position < regionB.position) return -1;
        if (regionA.position > regionB.position) return 1;
        return 0;
      });

      setQuebecRegions(allRegions);
      initCountries(allCountries);
    };
    fetchQuebecRegionsData().catch(console.error);

    /**
     * @description Fetch and process mountains data
     */
    const fetchMountainsData = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_API_SERVER_BASE_URL}/api/v2/mountain-data`
      );
      const mountainsData = await data.json();

      const allMountains = [];
      mountainsData.forEach((mountain, index) => {
        const alreadyAdded = allMountains
          .map((elmt) => elmt.description)
          .includes(mountain.title);

        mountain.content_translation_source =
          mountain.content_translation_source ===
          availableLanguages.FR.plaintext
            ? availableLanguages.EN.plaintext
            : availableLanguages.FR.plaintext;

        if (
          !alreadyAdded &&
          mountain.content_translation_source &&
          mountain.content_translation_source === lang &&
          mountain.view_node !== null &&
          mountain.view_node !== undefined &&
          mountain.status &&
          mountain.field_coordinates !== "" &&
          mountain.field_coordinates.split(",").length === 2
        ) {
          const hasChallenge = mountain.status === availableStatus.PUBLISHED;
          let imageUrl = null;

          if (hasChallenge) {
            const image = mountain.field_image
              .split('"')
              .find((item) => /\.(jpg|jpeg|png|webp|svg)$/.test(item));
            const siteHosturl = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(
              mountain.view_node
            )[1];
            imageUrl = `https://${siteHosturl}${image}`;
          }

          const data = {
            id: index + 1,
            description: mountain.title,
            link: mountain.view_node,
            status: mountain.status,
            imageUrl,
            name: mountain.field_administrative_region,
            hasChallenge,
            lat: Number.parseFloat(mountain.field_coordinates.split(",")[0]),
            lng: Number.parseFloat(mountain.field_coordinates.split(",")[1]),
          };
          allMountains.push(data);
        }
      });
      setMountains(allMountains);
    };
    fetchMountainsData().catch(console.error);
  }, [search, currentLanguage]);

  const handleMountainOver = (e) => {
    setMountainInfo([]);
    if (!mountainName.includes(e)) {
      setMountainName([...mountainName, e]);
    }
  };
  const handleMountainOut = (e) => {
    let newArray = [];
    for (let i = 0; mountainName.length < i; i++) {
      if (mountainName[i] !== e) {
        newArray.push(mountainName[i]);
      }
    }
    setMountainName(newArray);
  };

  const handleMountainClick = (e) => {
    if (!mountainInfo.includes(e)) {
      setMountainInfo([e]);
    } else {
      let newArray = [];
      for (let i = 0; mountainInfo.length < 0; i++) {
        if (mountainInfo[i] !== e) {
          newArray.push(mountainInfo[i]);
        }
      }
      setMountainInfo(newArray);
    }
  };

  const handleInfoClick = () => {
    setMountainInfo([]);
  };

  const centerMap = (position, zoom) => {
    map.panTo(position);
    map.setZoom(zoom);
  };

  const selectCurrentCountry = (id, position, zoom) => {
    setCurrentCountryId(id);
    centerMap(position, zoom);
    setCurrentCountryName(countries.find((country) => country.id === id)?.name);
  };

  const handleShowDonate = (region) => {
    showDonationModal
      ? setShowDonationModal(false)
      : setShowDonationModal(true);

    if (showDonationModal && region) {
      selectClickedRegionName(region);
    }
  };

  if (isLoaded) {
    return (
      <div id="app-map" className="App">
        <div className="container">
          <div className="col-lg-10 offset-lg-1">
            <RegionList
              countries={countries}
              currentCountryId={currentCountryId}
              onRegionclick={selectCurrentCountry}
            />

            <GoogleMap
              center={defaultRegion?.center || defaultCenter}
              zoom={defaultRegion?.zoom || defaultZoom}
              mapContainerStyle={containerStyle}
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                styles: CustomGoogleMap,
              }}
              onLoad={(map) => setMap(map)}
              onUnmount={() => setMap(null)}
            >
              <Mountain
                mountains={mountains}
                mountainName={mountainName}
                mountainInfo={mountainInfo}
                currentLanguage={currentLanguage}
                showDonationModal={showDonationModal}
                handleMountainOver={handleMountainOver}
                handleMountainOut={handleMountainOut}
                handleMountainClick={handleMountainClick}
                handleInfoClick={handleInfoClick}
              />

              {quebecRegions.map((region) => (
                <Polygon
                  key={region.id}
                  paths={region.borders}
                  options={{
                    fillColor: "transparent",
                    fillOpacity: 0.4,
                    strokeColor: "#fff",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              ))}
            </GoogleMap>

            <QuebecRegionsList
              mountains={mountains}
              areas={quebecRegions}
              quebecId={quebecId}
              currentCountryId={currentCountryId}
              currentCountryName={currentCountryName}
              onAreaClick={handleMountainClick}
              handleShowDonate={handleShowDonate}
            />

            {showDonationModal && (
              <>
                <DonationModal
                  region={selectedRegionName}
                  currentLanguage={currentLanguage}
                  onCancled={handleShowDonate}
                />
                <ModalBackdrop
                  region={selectedRegionName}
                  onCancled={handleShowDonate}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
