import { Marker, MarkerClusterer } from "@react-google-maps/api";

import markerIcon from "../../assets/images/pointeur.svg";

import { Fragment } from "react";
import MontainInfoModal from "./MontainInfoModal";
import MountainInfoBox from "./MountainInfoBox";

const Mountain = ({
  mountains,
  mountainName,
  mountainInfo,
  currentLanguage,
  handleMountainOver,
  handleMountainOut,
  handleMountainClick,
  handleInfoClick,
}) => {
  return (
    <MarkerClusterer averageCenter enableRetinaIcons gridSize={100}>
      {(clusterer) =>
        mountains.map((mountain, index) => (
          <Fragment key={index}>
            <Marker
              key={index}
              icon={markerIcon}
              position={{ lat: mountain.lat, lng: mountain.lng }}
              clusterer={clusterer}
              onMouseOver={(e) => handleMountainOver(mountain.id, e)}
              onMouseOut={(e) => handleMountainOut(mountain.id, e)}
              onClick={(e) => handleMountainClick(mountain.id, e)}
            />

            {mountainName.includes(mountain.id) && (
              <MountainInfoBox mountain={mountain} />
            )}

            {mountainInfo.includes(mountain.id) && (
              <MontainInfoModal
                mountain={mountain}
                currentLanguage={currentLanguage}
                handleInfoClick={handleInfoClick}
              />
            )}
          </Fragment>
        ))
      }
    </MarkerClusterer> 
  );
};

export default Mountain;
