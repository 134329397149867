import RegionItem from "../lits-items/RegionItem";

export default function RegionList({ countries, currentCountryId, onRegionclick }) {
  return (
    <div className="row regions mt-3">
      <div className="col-lg-12">
        {countries.map((country, index) => (
          <RegionItem
            key={index}
            id={country.id}
            isCurrentRegion={currentCountryId === country.id}
            zoom={country.zoom}
            name={country.name}
            center={country.center}
            onClickHandler={onRegionclick}
          />
        ))}
      </div>
    </div>
  );
}
