const boundaries1 = [
  [-70.0778758, 48.2518562],
  [-70.0669298, 48.2650017],
  [-70.0230606, 48.3350798],
  [-70.0138558, 48.348496],
  [-70.007992, 48.3578028],
  [-70.0099961, 49.0000124],
  [-70.0185347, 49.0000222],
  [-70.0198137, 49.797350899],
  [-70.0266642, 49.796972799],
  [-70.0338906, 49.797140999],
  [-70.0348783, 50.000037999],
  [-70.025598, 50.000027899],
  [-70.0198369, 51.558939399],
  [-70.0191912, 51.602073399],
  [-70.0188961, 52.692901099],
  [-70.0238312, 52.714239399],
  [-70.0247758, 52.816306099],
  [-70.0238543, 52.893115399],
  [-70.0247618, 52.918830299],
  [-70, 52.918384799],
  [-70, 54.999999999],
  [-67.4224881, 55.000060399],
  [-67.4217291, 54.999031499],
  [-67.4163428, 54.997135199],
  [-67.4143369, 54.994414899],
  [-67.40886, 54.991852999],
  [-67.4019405, 54.988954899],
  [-67.4029257, 54.985964399],
  [-67.409603, 54.982044999],
  [-67.4143658, 54.976070399],
  [-67.4133341, 54.971966999],
  [-67.4045861, 54.969286699],
  [-67.3989306, 54.965393899],
  [-67.3977063, 54.961567099],
  [-67.3997557, 54.957858999],
  [-67.393038, 54.954683899],
  [-67.3844903, 54.951725399],
  [-67.3758656, 54.946494099],
  [-67.3703206, 54.941658199],
  [-67.3577269, 54.939684399],
  [-67.3543918, 54.935685199],
  [-67.3540565, 54.929810299],
  [-67.3469861, 54.923971699],
  [-67.3399321, 54.916525799],
  [-67.3347103, 54.908863399],
  [-67.3351432, 54.903489799],
  [-67.3156995, 54.895395499],
  [-67.3140066, 54.891455999],
  [-67.3091524, 54.886453599],
  [-67.3106529, 54.880363499],
  [-67.2923803, 54.872213799],
  [-67.2827233, 54.867692899],
  [-67.2829684, 54.862596299],
  [-67.2716112, 54.855741499],
  [-67.2670688, 54.849519899],
  [-67.2680959, 54.843318299],
  [-67.2634319, 54.839644799],
  [-67.2591765, 54.833811899],
  [-67.2544965, 54.831744699],
  [-67.2447298, 54.829768899],
  [-67.2436144, 54.826606699],
  [-67.2350898, 54.825244199],
  [-67.2279821, 54.825825699],
  [-67.221372, 54.824912899],
  [-67.2184331, 54.821964799],
  [-67.2176228, 54.817584799],
  [-67.2218785, 54.814721199],
  [-67.2258888, 54.808255399],
  [-67.2221404, 54.800927699],
  [-67.2134185, 54.792748799],
  [-67.1932331, 54.781861899],
  [-67.1801812, 54.775877899],
  [-67.175925, 54.771648999],
  [-67.1774641, 54.763955999],
  [-67.1704104, 54.761321099],
  [-67.1634227, 54.760957099],
  [-67.1580847, 54.759049299],
  [-67.1572608, 54.756275799],
  [-67.1496239, 54.754467799],
  [-67.1377156, 54.750035399],
  [-67.1315589, 54.745352799],
  [-67.1249032, 54.742162999],
  [-67.0979992, 54.723527299],
  [-67.0860124, 54.712941099],
  [-67.0732239, 54.705063399],
  [-67.0741688, 54.699806699],
  [-67.0707755, 54.696742199],
  [-67.0629008, 54.692933099],
  [-67.0676563, 54.688583599],
  [-67.0774528, 54.685756399],
  [-67.0827923, 54.680578699],
  [-67.0782523, 54.677563799],
  [-67.0715285, 54.673706299],
  [-67.0767309, 54.671075899],
  [-67.0887066, 54.669090199],
  [-67.1054655, 54.666629199],
  [-67.1170099, 54.661315399],
  [-67.1290035, 54.657719399],
  [-67.1288783, 54.653177599],
  [-67.1234568, 54.645120999],
  [-67.1326759, 54.641511099],
  [-67.1425864, 54.636131699],
  [-67.1411415, 54.630310199],
  [-67.138551, 54.624538699],
  [-67.1449736, 54.622520399],
  [-67.1534703, 54.622283399],
  [-67.1576442, 54.617152899],
  [-67.1601369, 54.616056299],
  [-67.1602929, 54.615820299],
  [-67.1608144, 54.615758099],
  [-67.1681065, 54.612549299],
  [-67.1757787, 54.609538199],
  [-67.1852892, 54.611795099],
  [-67.1938291, 54.615431399],
  [-67.2064104, 54.616481399],
  [-67.2202256, 54.618144099],
  [-67.229516, 54.615191099],
  [-67.2348929, 54.612277499],
  [-67.234423, 54.603471299],
  [-67.2317698, 54.593826199],
  [-67.2311757, 54.587566899],
  [-67.2328333, 54.584417399],
  [-67.2416898, 54.585227099],
  [-67.2519552, 54.587979299],
  [-67.2682275, 54.590863199],
  [-67.280327, 54.591792299],
  [-67.2799182, 54.585257099],
  [-67.2758116, 54.576881899],
  [-67.2704575, 54.569498799],
  [-67.2685027, 54.565173099],
  [-67.2744496, 54.561429399],
  [-67.2925728, 54.559277799],
  [-67.3020044, 54.559254099],
  [-67.3027043, 54.555878999],
  [-67.2971068, 54.553590399],
  [-67.28998, 54.551905299],
  [-67.2778218, 54.548707299],
  [-67.2690613, 54.548565299],
  [-67.2622713, 54.551142699],
  [-67.2631681, 54.547491899],
  [-67.2677933, 54.542470599],
  [-67.266315, 54.538257599],
  [-67.2595091, 54.533748199],
  [-67.2491705, 54.530332299],
  [-67.241101, 54.528419099],
  [-67.2344376, 54.528448499],
  [-67.227812, 54.525266599],
  [-67.2268325, 54.519560399],
  [-67.2293489, 54.515971899],
  [-67.2370651, 54.515227299],
  [-67.2445875, 54.514758199],
  [-67.2483499, 54.510177699],
  [-67.2474553, 54.505136499],
  [-67.2449447, 54.500033899],
  [-67.2497417, 54.496343199],
  [-67.2563993, 54.496312899],
  [-67.2627584, 54.497498999],
  [-67.2688711, 54.495085499],
  [-67.269607, 54.488500599],
  [-67.2725744, 54.486629199],
  [-67.2820757, 54.487271699],
  [-67.286609, 54.490276799],
  [-67.2880713, 54.496094299],
  [-67.2879014, 54.503456399],
  [-67.2897506, 54.508721899],
  [-67.3035136, 54.511978999],
  [-67.314253, 54.513231799],
  [-67.3183474, 54.512912699],
  [-67.3234058, 54.511212399],
  [-67.3269653, 54.506905399],
  [-67.3307022, 54.503927499],
  [-67.3343065, 54.505100999],
  [-67.3294732, 54.513611599],
  [-67.3296224, 54.518151599],
  [-67.3353058, 54.521102999],
  [-67.3432137, 54.520074599],
  [-67.3492353, 54.516991999],
  [-67.357796, 54.517403799],
  [-67.3576798, 54.519950099],
  [-67.3686416, 54.529613099],
  [-67.3779631, 54.530523699],
  [-67.3828945, 54.532972399],
  [-67.3837303, 54.535742699],
  [-67.3864671, 54.538960699],
  [-67.394071, 54.540751399],
  [-67.3939455, 54.544903299],
  [-67.4060789, 54.552905099],
  [-67.4165398, 54.555365299],
  [-67.4230961, 54.557871399],
  [-67.4234284, 54.565346599],
  [-67.4290253, 54.569234399],
  [-67.4483316, 54.575696799],
  [-67.4676361, 54.583761499],
  [-67.4917488, 54.584909499],
  [-67.5009308, 54.578057899],
  [-67.5130385, 54.577355499],
  [-67.5242923, 54.575487899],
  [-67.5268824, 54.570951099],
  [-67.5233706, 54.567236999],
  [-67.5169113, 54.562190199],
  [-67.5205521, 54.556935299],
  [-67.5182845, 54.552226299],
  [-67.5088751, 54.547452099],
  [-67.4975585, 54.543836799],
  [-67.4854848, 54.539720899],
  [-67.4821798, 54.534123799],
  [-67.4741223, 54.527411299],
  [-67.473113, 54.520101899],
  [-67.4935183, 54.511388899],
  [-67.5, 54.506940999],
  [-67.5, 54.494613299],
  [-67.4949545, 54.488583799],
  [-67.4962096, 54.484378599],
  [-67.5004055, 54.481506599],
  [-67.5098171, 54.481464899],
  [-67.5182726, 54.482805099],
  [-67.5265316, 54.486026399],
  [-67.5319361, 54.490184699],
  [-67.5425945, 54.487539999],
  [-67.5508714, 54.485943599],
  [-67.5523847, 54.488546899],
  [-67.5684415, 54.493267399],
  [-67.5816579, 54.494108499],
  [-67.5863268, 54.489738799],
  [-67.5816828, 54.482871299],
  [-67.5865125, 54.479169199],
  [-67.5885646, 54.474614099],
  [-67.5926396, 54.469003099],
  [-67.6004842, 54.466096399],
  [-67.6094744, 54.465766199],
  [-67.6209423, 54.468201099],
  [-67.6278775, 54.473586899],
  [-67.6318991, 54.481087099],
  [-67.6324629, 54.485050299],
  [-67.6370056, 54.492254199],
  [-67.6387124, 54.499395599],
  [-67.642702, 54.508032299],
  [-67.6474304, 54.509387199],
  [-67.6628051, 54.485619199],
  [-67.6665615, 54.479302899],
  [-67.6623963, 54.468953099],
  [-67.6709484, 54.464524699],
  [-67.6736084, 54.462255099],
  [-67.665057, 54.458657599],
  [-67.6678117, 54.453841999],
  [-67.6776893, 54.449080999],
  [-67.6857609, 54.446146099],
  [-67.6966809, 54.443874099],
  [-67.7066534, 54.444590199],
  [-67.7080841, 54.449737199],
  [-67.7034345, 54.454111699],
  [-67.7054669, 54.456252799],
  [-67.7274967, 54.457399299],
  [-67.7279901, 54.452426299],
  [-67.7343181, 54.444338599],
  [-67.7405172, 54.441462599],
  [-67.7501385, 54.439381399],
  [-67.7586236, 54.439347799],
  [-67.7669648, 54.436814099],
  [-67.7710973, 54.430375199],
  [-67.7708957, 54.423701299],
  [-67.7689679, 54.419553399],
  [-67.7629876, 54.415329999],
  [-67.7548204, 54.412791199],
  [-67.7510228, 54.411908799],
  [-67.7542413, 54.408806799],
  [-67.7523351, 54.405155599],
  [-67.7443496, 54.397524299],
  [-67.7348543, 54.392107199],
  [-67.7246984, 54.386855799],
  [-67.725924, 54.382649099],
  [-67.7315104, 54.378495199],
  [-67.7301723, 54.372408599],
  [-67.7295966, 54.368424499],
  [-67.7319582, 54.364161899],
  [-67.7393377, 54.358180499],
  [-67.7480367, 54.350260899],
  [-67.7337222, 54.344681899],
  [-67.7333354, 54.340421299],
  [-67.7288652, 54.334343899],
  [-67.722499, 54.331681999],
  [-67.7062918, 54.326853499],
  [-67.6983248, 54.324076799],
  [-67.6918662, 54.320734099],
  [-67.6847124, 54.316442199],
  [-67.6799563, 54.311923499],
  [-67.6791045, 54.307923899],
  [-67.6790244, 54.300828499],
  [-67.6766866, 54.293188599],
  [-67.6701589, 54.284281299],
  [-67.6687397, 54.277531399],
  [-67.6704405, 54.271832699],
  [-67.6741904, 54.270986399],
  [-67.6756959, 54.264540099],
  [-67.6808611, 54.264910199],
  [-67.6848968, 54.263402199],
  [-67.6813021, 54.251914499],
  [-67.6808276, 54.246990799],
  [-67.6773304, 54.241680299],
  [-67.6640126, 54.233106299],
  [-67.6557989, 54.225084499],
  [-67.6549158, 54.224843299],
  [-67.6517623, 54.221522699],
  [-67.6439416, 54.217822099],
  [-67.6352291, 54.216010599],
  [-67.6283027, 54.212751499],
  [-67.6230984, 54.208426299],
  [-67.6306063, 54.208262799],
  [-67.6381579, 54.205222399],
  [-67.6358956, 54.202124099],
  [-67.6314631, 54.199799499],
  [-67.6209923, 54.198302299],
  [-67.6156181, 54.195755499],
  [-67.6171323, 54.191939399],
  [-67.6243021, 54.191057199],
  [-67.6331661, 54.194102499],
  [-67.6442974, 54.193828499],
  [-67.6533548, 54.188574799],
  [-67.6556195, 54.183651999],
  [-67.6518492, 54.177954399],
  [-67.6534544, 54.169989499],
  [-67.648082, 54.167444499],
  [-67.6406378, 54.163128799],
  [-67.6444092, 54.160806499],
  [-67.6570383, 54.161527099],
  [-67.675322, 54.158761199],
  [-67.6963413, 54.162407499],
  [-67.7059536, 54.161132099],
  [-67.7217763, 54.153934499],
  [-67.7295928, 54.151275099],
  [-67.7344016, 54.153042099],
  [-67.7482634, 54.157567599],
  [-67.7650366, 54.157000199],
  [-67.781784, 54.148410299],
  [-67.8011411, 54.134339199],
  [-67.8102524, 54.128132899],
  [-67.7981649, 54.118580599],
  [-67.7997422, 54.112218599],
  [-67.8054611, 54.106183199],
  [-67.7995085, 54.099664999],
  [-67.7918731, 54.095637199],
  [-67.7819009, 54.095869799],
  [-67.7740037, 54.097868899],
  [-67.7677054, 54.099921299],
  [-67.7610189, 54.097715399],
  [-67.7580867, 54.091579499],
  [-67.7618353, 54.086046099],
  [-67.7883584, 54.085521199],
  [-67.7852355, 54.079662899],
  [-67.7788153, 54.071430299],
  [-67.7808642, 54.065179499],
  [-67.7858254, 54.058648899],
  [-67.7940822, 54.054492099],
  [-67.7934935, 54.047304299],
  [-67.79103, 54.041280099],
  [-67.7984443, 54.039169999],
  [-67.8080091, 54.034678899],
  [-67.8146677, 54.032070999],
  [-67.8216853, 54.025702299],
  [-67.8206285, 54.020008799],
  [-67.8160064, 54.014762799],
  [-67.7972146, 54.010364299],
  [-67.7831287, 54.008666799],
  [-67.7873244, 54.000036799],
  [-67.7765923, 54.000027499],
  [-67.7808313, 53.994349499],
  [-67.7762276, 53.992308799],
  [-67.766474, 53.993866499],
  [-67.7623199, 54.000013599],
  [-67.7545091, 54.000005099],
  [-67.7476103, 53.992002999],
  [-67.743376, 53.986200999],
  [-67.7389591, 53.982278799],
  [-67.7336079, 53.979739299],
  [-67.7251633, 53.977754099],
  [-67.716909, 53.977094999],
  [-67.7080932, 53.977264899],
  [-67.6998393, 53.976604499],
  [-67.6872707, 53.974285999],
  [-67.6812677, 53.971909799],
  [-67.6845467, 53.966270299],
  [-67.6766695, 53.961848799],
  [-67.6674816, 53.956155399],
  [-67.6619515, 53.952285699],
  [-67.6643883, 53.950295599],
  [-67.668418, 53.948360599],
  [-67.6768514, 53.945540599],
  [-67.679567, 53.940730899],
  [-67.6729133, 53.935314199],
  [-67.669259, 53.930284099],
  [-67.6626085, 53.928073099],
  [-67.6533358, 53.928127799],
  [-67.6429381, 53.929839899],
  [-67.6371303, 53.930391399],
  [-67.6323587, 53.923811999],
  [-67.6181212, 53.925575699],
  [-67.6110969, 53.925517199],
  [-67.6059502, 53.922695399],
  [-67.5963059, 53.921694799],
  [-67.5981894, 53.915725999],
  [-67.6026947, 53.909095599],
  [-67.6091646, 53.901968599],
  [-67.6028055, 53.898538099],
  [-67.593826, 53.895768899],
  [-67.5945797, 53.893060999],
  [-67.6127307, 53.895116299],
  [-67.6157311, 53.890695899],
  [-67.6142416, 53.884891599],
  [-67.6079799, 53.880521999],
  [-67.6052721, 53.877314799],
  [-67.6107944, 53.874775199],
  [-67.6091189, 53.869247399],
  [-67.5992168, 53.863051699],
  [-67.5927668, 53.862163299],
  [-67.5852805, 53.865197899],
  [-67.5805049, 53.868234099],
  [-67.5763947, 53.866296399],
  [-67.5794926, 53.860937799],
  [-67.5735146, 53.858556299],
  [-67.5642588, 53.858603399],
  [-67.5602466, 53.855725699],
  [-67.5674537, 53.852305799],
  [-67.5714897, 53.845566799],
  [-67.569071, 53.841143399],
  [-67.5559057, 53.837372799],
  [-67.5458128, 53.837859599],
  [-67.5366479, 53.840170299],
  [-67.5281483, 53.839109799],
  [-67.5177882, 53.836001399],
  [-67.5093872, 53.834000099],
  [-67.5039547, 53.837197499],
  [-67.49779, 53.836690699],
  [-67.4949139, 53.832154499],
  [-67.4996083, 53.826856399],
  [-67.5115913, 53.820408299],
  [-67.5220677, 53.816167399],
  [-67.5293788, 53.808605899],
  [-67.536033, 53.801209099],
  [-67.5450973, 53.798235499],
  [-67.5593815, 53.797145199],
  [-67.5872906, 53.796726699],
  [-67.6022266, 53.795465399],
  [-67.6041951, 53.790161599],
  [-67.5912313, 53.786119699],
  [-67.5758435, 53.782461199],
  [-67.5642754, 53.781953699],
  [-67.5572825, 53.780289299],
  [-67.5590692, 53.775262699],
  [-67.5674761, 53.770850099],
  [-67.5861382, 53.767273599],
  [-67.5992903, 53.766232499],
  [-67.6033062, 53.762698599],
  [-67.5996752, 53.759270699],
  [-67.592219, 53.757497799],
  [-67.5800053, 53.757157399],
  [-67.5667684, 53.755930599],
  [-67.5565103, 53.756694399],
  [-67.5482112, 53.756961999],
  [-67.5389751, 53.758609299],
  [-67.5280681, 53.757932999],
  [-67.5167019, 53.755541999],
  [-67.5, 53.757267399],
  [-67.5, 53.746035899],
  [-67.5057462, 53.744586299],
  [-67.5175269, 53.735210299],
  [-67.5159682, 53.728743899],
  [-67.5057366, 53.724695999],
  [-67.5063191, 53.719061499],
  [-67.4830594, 53.713499999],
  [-67.4651093, 53.708882599],
  [-67.4463138, 53.706304699],
  [-67.4337421, 53.706499399],
  [-67.4327731, 53.712684999],
  [-67.4359989, 53.718271999],
  [-67.440621, 53.724193399],
  [-67.4258069, 53.724492999],
  [-67.417791, 53.725137899],
  [-67.4194159, 53.718787899],
  [-67.4274606, 53.713336399],
  [-67.4255625, 53.704216399],
  [-67.4216082, 53.696528299],
  [-67.4138286, 53.690488699],
  [-67.4033308, 53.687646299],
  [-67.3968497, 53.682879299],
  [-67.3963309, 53.677298399],
  [-67.4042486, 53.675992099],
  [-67.4142114, 53.674855699],
  [-67.4131478, 53.666898499],
  [-67.403994, 53.658921899],
  [-67.3894215, 53.642258699],
  [-67.3926042, 53.639173499],
  [-67.3914234, 53.635359099],
  [-67.3871956, 53.628885599],
  [-67.3899252, 53.624087199],
  [-67.3950722, 53.618908199],
  [-67.3947309, 53.614654299],
  [-67.3832404, 53.610924099],
  [-67.3736939, 53.608302699],
  [-67.3738062, 53.605762299],
  [-67.3793165, 53.601634699],
  [-67.3769358, 53.597209799],
  [-67.3628349, 53.595734999],
  [-67.3533679, 53.595376499],
  [-67.3473453, 53.593922799],
  [-67.3399473, 53.590531599],
  [-67.3349673, 53.586760699],
  [-67.3253289, 53.585073699],
  [-67.3135498, 53.584152099],
  [-67.3132031, 53.581499899],
  [-67.3179932, 53.575274699],
  [-67.3215957, 53.566836499],
  [-67.3237072, 53.559001099],
  [-67.3186608, 53.552965299],
  [-67.3081518, 53.546248599],
  [-67.2983523, 53.543233399],
  [-67.2862965, 53.543522799],
  [-67.2784559, 53.548685899],
  [-67.272394, 53.552032499],
  [-67.2642924, 53.555205599],
  [-67.2574059, 53.557388599],
  [-67.250332, 53.551176199],
  [-67.2408635, 53.543738999],
  [-67.2290877, 53.535739499],
  [-67.2187691, 53.530340299],
  [-67.211611, 53.532132599],
  [-67.206216, 53.533710999],
  [-67.1974366, 53.531574499],
  [-67.18908, 53.532752599],
  [-67.1889996, 53.539158099],
  [-67.1904104, 53.544962899],
  [-67.1891324, 53.550755899],
  [-67.1800153, 53.553034399],
  [-67.1576855, 53.537633599],
  [-67.1413498, 53.532363899],
  [-67.1298867, 53.530208699],
  [-67.1233985, 53.530231199],
  [-67.1187574, 53.530704599],
  [-67.1143289, 53.529301299],
  [-67.0999085, 53.526741099],
  [-67.0948059, 53.527100499],
  [-67.0843038, 53.529031999],
  [-67.0804786, 53.530667799],
  [-67.0720685, 53.535039399],
  [-67.0620248, 53.537081799],
  [-67.0562383, 53.533735499],
  [-67.0537098, 53.529579399],
  [-67.0428221, 53.526589699],
  [-67.0359281, 53.528758299],
  [-67.0303082, 53.526737099],
  [-67.0275116, 53.522192099],
  [-67.0204278, 53.513700699],
  [-67.0114199, 53.509559599],
  [-67.0084921, 53.502086999],
  [-67.0066532, 53.500007599],
  [-67, 53.499999999],
  [-67, 53.495962999],
  [-66.9856293, 53.496145999],
  [-66.9838428, 53.492490199],
  [-66.9927145, 53.488239399],
  [-66.9962964, 53.484617499],
  [-66.9989873, 53.473701499],
  [-66.9958222, 53.464239999],
  [-66.9853611, 53.464560399],
  [-66.9710764, 53.466511399],
  [-66.9544779, 53.473359299],
  [-66.9445241, 53.476053399],
  [-66.937916, 53.473137299],
  [-66.9343239, 53.467425599],
  [-66.9346373, 53.460746799],
  [-66.930445, 53.457460399],
  [-66.9249522, 53.454496099],
  [-66.9305997, 53.449455599],
  [-66.9350064, 53.441535299],
  [-66.9317673, 53.436875299],
  [-66.9256546, 53.423141099],
  [-66.9244479, 53.418660899],
  [-66.9162816, 53.415677799],
  [-66.9078945, 53.414569599],
  [-66.8999032, 53.416776099],
  [-66.8944793, 53.419939099],
  [-66.8900909, 53.413061499],
  [-66.8905368, 53.409310599],
  [-66.9032049, 53.399575199],
  [-66.9067078, 53.395294199],
  [-66.9119846, 53.394669199],
  [-66.9144093, 53.398164199],
  [-66.9228724, 53.399934899],
  [-66.9288303, 53.397547199],
  [-66.9332801, 53.391891799],
  [-66.9380965, 53.391151899],
  [-66.9463197, 53.390931699],
  [-66.9534734, 53.383692899],
  [-66.9541421, 53.372601799],
  [-66.9589052, 53.369597499],
  [-66.9661033, 53.364621799],
  [-66.9735914, 53.363897799],
  [-66.9761001, 53.368054099],
  [-66.9846917, 53.372746099],
  [-66.9960466, 53.372652499],
  [-67.0057272, 53.367965599],
  [-67.0136759, 53.361887399],
  [-67.0093259, 53.357279299],
  [-66.997057, 53.357147799],
  [-66.9969518, 53.352620899],
  [-67.0212978, 53.342228399],
  [-67.0270506, 53.334646099],
  [-67.0253483, 53.326191199],
  [-67.0188142, 53.320080499],
  [-67.0050998, 53.318947699],
  [-66.9954878, 53.320432999],
  [-66.9869586, 53.318005699],
  [-66.9584155, 53.306450299],
  [-66.9574573, 53.298496499],
  [-66.9533705, 53.290411599],
  [-66.9543268, 53.283574699],
  [-66.9647254, 53.273930899],
  [-66.9692721, 53.261875199],
  [-66.9761925, 53.245586199],
  [-66.9756099, 53.237084599],
  [-66.9780617, 53.213263299],
  [-66.9736089, 53.200268099],
  [-66.963396, 53.190986899],
  [-66.9596173, 53.181692799],
  [-66.9826478, 53.177760799],
  [-66.9940009, 53.174467899],
  [-67.006133, 53.168475299],
  [-67.0065901, 53.168588399],
  [-67.0135298, 53.160024999],
  [-67.0141228, 53.152139599],
  [-67.0071909, 53.144318299],
  [-66.9951823, 53.138450599],
  [-66.9743872, 53.136827599],
  [-66.9586954, 53.137110299],
  [-66.959965, 53.132926199],
  [-66.9729734, 53.128764399],
  [-66.984906, 53.123049499],
  [-66.9908732, 53.117461099],
  [-66.9901012, 53.109237099],
  [-66.9868319, 53.102321099],
  [-66.9859528, 53.095034499],
  [-66.9844299, 53.087909299],
  [-66.9895314, 53.084356799],
  [-67.0012569, 53.084375799],
  [-67.011561, 53.081792499],
  [-67.0174908, 53.077802499],
  [-67.023983, 53.078448799],
  [-67.0416076, 53.076072099],
  [-67.0567668, 53.078862999],
  [-67.0684907, 53.078874599],
  [-67.0678785, 53.082897499],
  [-67.0653097, 53.089005399],
  [-67.0671664, 53.093318199],
  [-67.0689739, 53.100829899],
  [-67.0640401, 53.105711199],
  [-67.0530633, 53.110061099],
  [-67.046786, 53.118464299],
  [-67.0508999, 53.124942199],
  [-67.0525178, 53.132729599],
  [-67.0489226, 53.139549199],
  [-67.061178, 53.141936699],
  [-67.0752554, 53.139312899],
  [-67.0827129, 53.131520899],
  [-67.0886671, 53.125926799],
  [-67.115279, 53.111946999],
  [-67.1162019, 53.105111899],
  [-67.1199148, 53.108275199],
  [-67.1206693, 53.119697299],
  [-67.125088, 53.124960299],
  [-67.1410822, 53.128901999],
  [-67.1392102, 53.131650899],
  [-67.1327451, 53.134873199],
  [-67.1344465, 53.137860599],
  [-67.146019, 53.143599899],
  [-67.154228, 53.147225499],
  [-67.1747661, 53.148425799],
  [-67.2049505, 53.148781699],
  [-67.2176947, 53.149663799],
  [-67.2251834, 53.152784399],
  [-67.2367561, 53.160113699],
  [-67.248995, 53.165679099],
  [-67.2497566, 53.171640299],
  [-67.2543685, 53.178223299],
  [-67.2629683, 53.181290599],
  [-67.2739089, 53.180117699],
  [-67.2841828, 53.172045199],
  [-67.2939944, 53.163859999],
  [-67.3026698, 53.158924999],
  [-67.2967794, 53.151071199],
  [-67.2872146, 53.143921299],
  [-67.2880761, 53.140283599],
  [-67.2931408, 53.138315599],
  [-67.3044252, 53.138189099],
  [-67.3179845, 53.129959999],
  [-67.3320675, 53.124102899],
  [-67.342758, 53.117737299],
  [-67.3406158, 53.121702299],
  [-67.3375307, 53.128643099],
  [-67.3463979, 53.132090099],
  [-67.3499862, 53.130721799],
  [-67.3650048, 53.121884499],
  [-67.3633223, 53.125961599],
  [-67.3618988, 53.132025299],
  [-67.3714124, 53.135306599],
  [-67.385379, 53.131979399],
  [-67.3923368, 53.120854999],
  [-67.3918264, 53.115282599],
  [-67.3883971, 53.107716999],
  [-67.3930288, 53.100834299],
  [-67.3925412, 53.092063199],
  [-67.3907462, 53.086818799],
  [-67.3905427, 53.076835399],
  [-67.3870188, 53.070207799],
  [-67.3807861, 53.046255999],
  [-67.3744403, 53.038297399],
  [-67.3690102, 53.030561799],
  [-67.3639274, 53.025473999],
  [-67.3630522, 53.020453299],
  [-67.359267, 53.013439099],
  [-67.3553714, 53.008961199],
  [-67.3589605, 53.005993899],
  [-67.3688617, 53.002327599],
  [-67.3634254, 52.996191499],
  [-67.3538136, 52.986789799],
  [-67.3453626, 52.981195499],
  [-67.3231853, 52.978644799],
  [-67.308302, 52.977878799],
  [-67.2941145, 52.980807399],
  [-67.2833002, 52.984242699],
  [-67.2553037, 52.979285499],
  [-67.2445214, 52.979518799],
  [-67.2484876, 52.976005699],
  [-67.2758321, 52.963814299],
  [-67.2882465, 52.964300499],
  [-67.2974572, 52.965601099],
  [-67.306924, 52.968886699],
  [-67.3214764, 52.965406199],
  [-67.3326657, 52.959818199],
  [-67.3400631, 52.948814199],
  [-67.3442289, 52.941824999],
  [-67.3519371, 52.936665799],
  [-67.3557429, 52.928627799],
  [-67.3507742, 52.922603899],
  [-67.3421157, 52.916063099],
  [-67.3387125, 52.916043499],
  [-67.3329086, 52.907219599],
  [-67.3418573, 52.896213999],
  [-67.336145, 52.889625199],
  [-67.3248103, 52.882125799],
  [-67.3245801, 52.877825499],
  [-67.3168245, 52.875447699],
  [-67.3072698, 52.879228399],
  [-67.299845, 52.884475999],
  [-67.2685327, 52.877710699],
  [-67.2598642, 52.873215999],
  [-67.2399116, 52.858860199],
  [-67.2409042, 52.853915999],
  [-67.2318861, 52.847792699],
  [-67.2274723, 52.841770799],
  [-67.2219891, 52.841205199],
  [-67.2148473, 52.843583099],
  [-67.2071765, 52.838530099],
  [-67.2039632, 52.835565199],
  [-67.1950288, 52.833354899],
  [-67.1855249, 52.831989199],
  [-67.1754107, 52.821394299],
  [-67.1675593, 52.818067599],
  [-67.1611291, 52.817460399],
  [-67.1549002, 52.822823099],
  [-67.1552634, 52.837714799],
  [-67.1559334, 52.846367399],
  [-67.1555517, 52.849825299],
  [-67.1494948, 52.854442799],
  [-67.1403437, 52.851582299],
  [-67.1341877, 52.851042599],
  [-67.1225978, 52.862380799],
  [-67.1061878, 52.865415699],
  [-67.0956397, 52.869904699],
  [-67.0742522, 52.875451799],
  [-67.0631508, 52.875456799],
  [-67.0541288, 52.868077599],
  [-67.0559252, 52.857267099],
  [-67.048367, 52.846300799],
  [-67.0476717, 52.837240199],
  [-67.0622528, 52.818161099],
  [-67.0619705, 52.814733899],
  [-67.0578472, 52.806400599],
  [-67.0626756, 52.783908999],
  [-67.0688117, 52.777737799],
  [-67.0670067, 52.775116199],
  [-67.0491851, 52.774029799],
  [-67.0396166, 52.767495999],
  [-67.0383014, 52.755517299],
  [-67.0343041, 52.747015599],
  [-67.0248279, 52.755707299],
  [-67.0197529, 52.755031899],
  [-67.0171175, 52.752200999],
  [-67.0215701, 52.746599299],
  [-67.0161365, 52.744056399],
  [-66.9996142, 52.759280699],
  [-66.9898372, 52.762168699],
  [-66.9790404, 52.753145999],
  [-66.9618309, 52.755948499],
  [-66.9495783, 52.759595999],
  [-66.9491922, 52.747249599],
  [-66.9441881, 52.736906599],
  [-66.9381455, 52.731439399],
  [-66.9297583, 52.728939399],
  [-66.9176234, 52.715222399],
  [-66.9020242, 52.687984699],
  [-66.9028867, 52.683854499],
  [-66.8947725, 52.676675199],
  [-66.8863802, 52.675155299],
  [-66.8799859, 52.676904499],
  [-66.8782611, 52.679434399],
  [-66.8726508, 52.680985599],
  [-66.8694017, 52.676214299],
  [-66.8637105, 52.673594199],
  [-66.8511915, 52.674786099],
  [-66.8493969, 52.677891399],
  [-66.8579144, 52.692095899],
  [-66.8722968, 52.709159099],
  [-66.8768428, 52.713465699],
  [-66.8978939, 52.727456599],
  [-66.8956403, 52.731237899],
  [-66.8914801, 52.732563699],
  [-66.8816986, 52.727336899],
  [-66.8670751, 52.718715499],
  [-66.8559921, 52.717308099],
  [-66.8540128, 52.721158399],
  [-66.8587729, 52.736590099],
  [-66.8603777, 52.748064299],
  [-66.8663373, 52.759843399],
  [-66.8664188, 52.764015299],
  [-66.8601781, 52.768376399],
  [-66.8548201, 52.765248999],
  [-66.8491053, 52.757879599],
  [-66.8365074, 52.745809999],
  [-66.8345946, 52.738605699],
  [-66.8263276, 52.723076899],
  [-66.8171481, 52.711270699],
  [-66.8046154, 52.712457099],
  [-66.80135, 52.708666399],
  [-66.7923545, 52.684246699],
  [-66.7914248, 52.678474999],
  [-66.7863602, 52.673041699],
  [-66.7735357, 52.668017199],
  [-66.7649883, 52.666248899],
  [-66.760392, 52.669633599],
  [-66.7578512, 52.673341199],
  [-66.7607398, 52.678621499],
  [-66.7659633, 52.684294799],
  [-66.7724393, 52.696217399],
  [-66.7638638, 52.700178999],
  [-66.7478878, 52.703058199],
  [-66.7504304, 52.708844899],
  [-66.7507535, 52.714069599],
  [-66.7557953, 52.720488399],
  [-66.7552632, 52.735980799],
  [-66.7540593, 52.751501999],
  [-66.7752536, 52.775253799],
  [-66.7800099, 52.787333899],
  [-66.7871087, 52.797432699],
  [-66.7819648, 52.799695999],
  [-66.7731789, 52.797281499],
  [-66.7653667, 52.791550799],
  [-66.7579967, 52.781380499],
  [-66.7465536, 52.773344799],
  [-66.7196704, 52.763576199],
  [-66.6932039, 52.762215299],
  [-66.6866248, 52.763714199],
  [-66.6851525, 52.771059999],
  [-66.6866821, 52.783521299],
  [-66.6842634, 52.788450499],
  [-66.6805045, 52.791060799],
  [-66.6738997, 52.791168299],
  [-66.6670933, 52.788254799],
  [-66.660481, 52.782222399],
  [-66.6511252, 52.780640199],
  [-66.64741, 52.783656699],
  [-66.6463756, 52.788937699],
  [-66.6515456, 52.796585199],
  [-66.6618777, 52.808114899],
  [-66.6651776, 52.814692699],
  [-66.6650932, 52.830905699],
  [-66.6566716, 52.840255399],
  [-66.6481814, 52.847806199],
  [-66.6453588, 52.852833399],
  [-66.6477147, 52.856994799],
  [-66.6534377, 52.861019099],
  [-66.6559311, 52.866403099],
  [-66.6517871, 52.870502099],
  [-66.645395, 52.872645399],
  [-66.6494685, 52.877637899],
  [-66.6570281, 52.882324499],
  [-66.6640125, 52.885478499],
  [-66.663606, 52.890326099],
  [-66.6543929, 52.895123499],
  [-66.6496169, 52.899657699],
  [-66.6423264, 52.897415799],
  [-66.6327287, 52.897559599],
  [-66.6324161, 52.900847499],
  [-66.6424395, 52.914279299],
  [-66.650602, 52.921890499],
  [-66.6485008, 52.924923899],
  [-66.6332255, 52.931357999],
  [-66.6324524, 52.947603599],
  [-66.6258683, 52.954257599],
  [-66.6020553, 52.949812399],
  [-66.5939239, 52.941212999],
  [-66.5850899, 52.939765499],
  [-66.5773104, 52.936801299],
  [-66.5702844, 52.936999999],
  [-66.5632182, 52.940557099],
  [-66.556442, 52.941809299],
  [-66.5480939, 52.941076499],
  [-66.5378409, 52.944021199],
  [-66.5308587, 52.948391499],
  [-66.529496, 52.954586699],
  [-66.5264303, 52.961340499],
  [-66.5203391, 52.963955399],
  [-66.5145852, 52.960906499],
  [-66.5105644, 52.956315299],
  [-66.5073228, 52.950137699],
  [-66.5010881, 52.946370299],
  [-66.4951289, 52.950206599],
  [-66.4886352, 52.960450399],
  [-66.4896438, 52.966060899],
  [-66.4901222, 52.974312699],
  [-66.4926601, 52.977734599],
  [-66.4955949, 52.981058999],
  [-66.4941539, 52.984063399],
  [-66.4890417, 52.985737299],
  [-66.4885381, 52.991161699],
  [-66.4930142, 52.997456199],
  [-66.4847864, 52.997941399],
  [-66.4699269, 52.990986399],
  [-66.4665303, 52.993493299],
  [-66.4622164, 53.000130099],
  [-66.4671911, 53.004768399],
  [-66.4681541, 53.009972099],
  [-66.474636, 53.022331799],
  [-66.4702766, 53.028561699],
  [-66.4714021, 53.034005099],
  [-66.4753043, 53.042534799],
  [-66.4667565, 53.043931599],
  [-66.4620451, 53.046898999],
  [-66.4595316, 53.055186499],
  [-66.4470509, 53.049035299],
  [-66.4429349, 53.047392399],
  [-66.4321499, 53.048217499],
  [-66.4238972, 53.042146799],
  [-66.4063902, 53.028158799],
  [-66.3938412, 53.021186099],
  [-66.3829674, 53.019798699],
  [-66.3735416, 53.020566299],
  [-66.3705853, 53.024772799],
  [-66.361086, 53.014405499],
  [-66.3552088, 53.000072199],
  [-66.3501111, 53.000071099],
  [-66.3451558, 52.993352399],
  [-66.3433103, 52.987712299],
  [-66.33918, 52.983147999],
  [-66.3269973, 52.972644099],
  [-66.3257906, 52.968274299],
  [-66.3273158, 52.963497399],
  [-66.3270917, 52.956426599],
  [-66.3253677, 52.951924199],
  [-66.3236937, 52.943005099],
  [-66.3222685, 52.938948299],
  [-66.315817, 52.933786099],
  [-66.311941, 52.926700499],
  [-66.3042125, 52.923792899],
  [-66.295367, 52.918566999],
  [-66.289092, 52.910124499],
  [-66.2806588, 52.905186899],
  [-66.2730586, 52.896032099],
  [-66.2764733, 52.883805199],
  [-66.2700756, 52.879019999],
  [-66.2611734, 52.874327299],
  [-66.2581314, 52.869112999],
  [-66.2600724, 52.865921299],
  [-66.2705009, 52.865839299],
  [-66.2832365, 52.862474299],
  [-66.2908293, 52.862952299],
  [-66.294943, 52.860135399],
  [-66.2932331, 52.856927099],
  [-66.2883926, 52.853835499],
  [-66.278912, 52.850840399],
  [-66.2740297, 52.846075799],
  [-66.2753313, 52.840320599],
  [-66.2800969, 52.835273499],
  [-66.2835499, 52.833394799],
  [-66.2912527, 52.835009899],
  [-66.3053117, 52.841028099],
  [-66.3165236, 52.851108799],
  [-66.3208634, 52.856653099],
  [-66.3286259, 52.861231399],
  [-66.3399528, 52.863772099],
  [-66.3515699, 52.859375399],
  [-66.3569474, 52.859095499],
  [-66.3690593, 52.863125399],
  [-66.37343, 52.866459799],
  [-66.3747178, 52.871585899],
  [-66.3797343, 52.877483199],
  [-66.3988762, 52.878882499],
  [-66.4110279, 52.872403599],
  [-66.416593, 52.858337999],
  [-66.4141964, 52.848688899],
  [-66.4083736, 52.843509499],
  [-66.4035952, 52.839887999],
  [-66.3886609, 52.826838699],
  [-66.3845333, 52.820984999],
  [-66.3777868, 52.814089899],
  [-66.3788622, 52.809939799],
  [-66.3806112, 52.807437999],
  [-66.3850705, 52.803234699],
  [-66.3858171, 52.799553699],
  [-66.3817717, 52.794458199],
  [-66.3544928, 52.802573599],
  [-66.3480756, 52.800001799],
  [-66.34459, 52.795416399],
  [-66.3459107, 52.788746899],
  [-66.3515825, 52.784118899],
  [-66.3477229, 52.778330699],
  [-66.331154, 52.761154699],
  [-66.3275818, 52.753225399],
  [-66.3321449, 52.741489599],
  [-66.3267548, 52.736596199],
  [-66.3086444, 52.728606299],
  [-66.3085154, 52.724883799],
  [-66.3169425, 52.721523899],
  [-66.3212965, 52.718773499],
  [-66.3237958, 52.712593699],
  [-66.3376787, 52.701419399],
  [-66.3386461, 52.697427299],
  [-66.3363955, 52.688917299],
  [-66.3390849, 52.684631899],
  [-66.3374569, 52.682183599],
  [-66.3288805, 52.676651599],
  [-66.3140293, 52.669682499],
  [-66.3080926, 52.662984999],
  [-66.311811, 52.658966099],
  [-66.307392, 52.654873499],
  [-66.3002963, 52.650272899],
  [-66.2970482, 52.645375499],
  [-66.3023004, 52.640462999],
  [-66.3039696, 52.637206199],
  [-66.2960812, 52.634612799],
  [-66.2776919, 52.634465099],
  [-66.2811643, 52.624297099],
  [-66.280219, 52.617412499],
  [-66.2848838, 52.604234599],
  [-66.2908308, 52.600057499],
  [-66.303214, 52.602709799],
  [-66.3063316, 52.604744499],
  [-66.3093025, 52.604590099],
  [-66.3199762, 52.613436599],
  [-66.3194344, 52.617590799],
  [-66.3238213, 52.621947199],
  [-66.3407723, 52.645449899],
  [-66.3508316, 52.650415399],
  [-66.3555018, 52.655529499],
  [-66.3631741, 52.660014599],
  [-66.366264, 52.665816799],
  [-66.3661317, 52.667396599],
  [-66.3748523, 52.674674399],
  [-66.3829204, 52.680070199],
  [-66.3886574, 52.682284199],
  [-66.3947234, 52.679236699],
  [-66.402243, 52.674535399],
  [-66.405231, 52.667194199],
  [-66.4111547, 52.660424099],
  [-66.4175701, 52.651200799],
  [-66.4163623, 52.642044199],
  [-66.4237944, 52.641376099],
  [-66.4327578, 52.639811299],
  [-66.4402759, 52.636400399],
  [-66.4399245, 52.629492699],
  [-66.4345945, 52.622778899],
  [-66.427381, 52.618343799],
  [-66.4261981, 52.614929699],
  [-66.4220894, 52.612075599],
  [-66.4198743, 52.610234999],
  [-66.4204963, 52.610202399],
  [-66.4207863, 52.606975099],
  [-66.4040777, 52.592955899],
  [-66.3907873, 52.576457099],
  [-66.378191, 52.564738199],
  [-66.371111, 52.552042799],
  [-66.3680919, 52.530897299],
  [-66.3901136, 52.531535899],
  [-66.3953211, 52.530933299],
  [-66.3992139, 52.528774399],
  [-66.3985245, 52.526892799],
  [-66.3954665, 52.524399599],
  [-66.3888822, 52.521238899],
  [-66.382884, 52.518959199],
  [-66.376445, 52.515591399],
  [-66.3702535, 52.513017199],
  [-66.3663846, 52.509713699],
  [-66.3645589, 52.507238699],
  [-66.3647257, 52.499173699],
  [-66.3638549, 52.494341499],
  [-66.3581227, 52.488251199],
  [-66.3555838, 52.473220599],
  [-66.360611, 52.466414599],
  [-66.3660593, 52.468562299],
  [-66.3725097, 52.473179799],
  [-66.3815845, 52.484083499],
  [-66.3938665, 52.486366299],
  [-66.3931049, 52.476111499],
  [-66.3908067, 52.465937499],
  [-66.3868254, 52.459933699],
  [-66.3743129, 52.448405299],
  [-66.358817, 52.430757599],
  [-66.3615168, 52.425561599],
  [-66.3727885, 52.416691799],
  [-66.3729098, 52.413038899],
  [-66.3563867, 52.403412899],
  [-66.3417121, 52.397210299],
  [-66.3401194, 52.392559199],
  [-66.346492, 52.380764099],
  [-66.3436549, 52.374868899],
  [-66.3440422, 52.360415399],
  [-66.3489643, 52.356351499],
  [-66.3545115, 52.353555199],
  [-66.3638437, 52.351373099],
  [-66.3701881, 52.353940699],
  [-66.3748694, 52.363259499],
  [-66.3810102, 52.367578399],
  [-66.3871224, 52.369833899],
  [-66.3946935, 52.370421799],
  [-66.4052784, 52.372798699],
  [-66.4098033, 52.374522899],
  [-66.413946, 52.378541799],
  [-66.4142606, 52.381569699],
  [-66.4198765, 52.383023099],
  [-66.4411065, 52.375809499],
  [-66.4365987, 52.358809999],
  [-66.4400988, 52.355480999],
  [-66.4486004, 52.350131499],
  [-66.4533271, 52.348380499],
  [-66.463415, 52.353359699],
  [-66.4672675, 52.354522899],
  [-66.471135, 52.353678099],
  [-66.4768735, 52.351552299],
  [-66.4835706, 52.345180799],
  [-66.4905173, 52.341637499],
  [-66.4867912, 52.332209599],
  [-66.484806, 52.329856399],
  [-66.4852791, 52.326113899],
  [-66.4881422, 52.321516699],
  [-66.486185, 52.318250699],
  [-66.4796218, 52.315900599],
  [-66.479761, 52.315504199],
  [-66.4677246, 52.300233699],
  [-66.4644905, 52.298893599],
  [-66.4553647, 52.297660199],
  [-66.4527356, 52.294875699],
  [-66.4564268, 52.293438599],
  [-66.4632708, 52.291345099],
  [-66.4690833, 52.287696299],
  [-66.4687652, 52.284669499],
  [-66.4631103, 52.281549599],
  [-66.4563415, 52.279612999],
  [-66.4478636, 52.281466299],
  [-66.4433193, 52.280657099],
  [-66.4393704, 52.277241099],
  [-66.4328916, 52.266174299],
  [-66.4360324, 52.264227699],
  [-66.4432453, 52.263336799],
  [-66.4550681, 52.262719599],
  [-66.4704234, 52.255655199],
  [-66.4540899, 52.246271399],
  [-66.4471254, 52.237656799],
  [-66.4450335, 52.236605999],
  [-66.4455208, 52.236150199],
  [-66.4380974, 52.223473799],
  [-66.4317878, 52.218537499],
  [-66.4281532, 52.219057499],
  [-66.4259695, 52.217443899],
  [-66.4223338, 52.220548899],
  [-66.4174762, 52.227272499],
  [-66.4110084, 52.230404799],
  [-66.405128, 52.229839599],
  [-66.4026869, 52.226558399],
  [-66.4052566, 52.221691899],
  [-66.4026629, 52.216927299],
  [-66.3896048, 52.212569399],
  [-66.3834619, 52.207842899],
  [-66.3801568, 52.202410599],
  [-66.375536, 52.198319899],
  [-66.3713443, 52.187361799],
  [-66.3568397, 52.176603199],
  [-66.3571001, 52.172820499],
  [-66.3623762, 52.169142199],
  [-66.3797905, 52.162611199],
  [-66.3809929, 52.158593399],
  [-66.3763659, 52.152815999],
  [-66.3579767, 52.146577599],
  [-66.3502714, 52.146973699],
  [-66.3332219, 52.150705999],
  [-66.3301132, 52.135934699],
  [-66.3235674, 52.131817499],
  [-66.313803, 52.135763999],
  [-66.3068118, 52.136824599],
  [-66.3026201, 52.140061299],
  [-66.2916824, 52.146829699],
  [-66.275001, 52.149446299],
  [-66.2694204, 52.149204399],
  [-66.2679421, 52.150368799],
  [-66.2651283, 52.154980799],
  [-66.264117, 52.165181399],
  [-66.2674396, 52.173493399],
  [-66.2705665, 52.178265399],
  [-66.2743891, 52.185312299],
  [-66.2779745, 52.189971099],
  [-66.2834233, 52.202326199],
  [-66.286698, 52.208530999],
  [-66.2871767, 52.213307399],
  [-66.288658, 52.217182899],
  [-66.2900586, 52.223362999],
  [-66.2929344, 52.231788799],
  [-66.2993967, 52.243243499],
  [-66.299324, 52.247178199],
  [-66.3000697, 52.249931099],
  [-66.2997163, 52.252629799],
  [-66.2976696, 52.255584399],
  [-66.297637, 52.258366899],
  [-66.2982463, 52.261316799],
  [-66.3009265, 52.266202399],
  [-66.303511, 52.270132499],
  [-66.3094039, 52.275855199],
  [-66.3125692, 52.285517999],
  [-66.3089954, 52.285749599],
  [-66.3031248, 52.284917299],
  [-66.2968852, 52.283523199],
  [-66.2892801, 52.284098599],
  [-66.2834652, 52.285373099],
  [-66.2800898, 52.289144999],
  [-66.2795501, 52.291563099],
  [-66.2896123, 52.296746499],
  [-66.2863252, 52.299843999],
  [-66.2818481, 52.303561699],
  [-66.2871002, 52.309118599],
  [-66.2721464, 52.308243099],
  [-66.2666964, 52.310078399],
  [-66.2600436, 52.309272899],
  [-66.2562293, 52.307113799],
  [-66.2537423, 52.304137699],
  [-66.2538556, 52.297419599],
  [-66.2499002, 52.293826999],
  [-66.2486935, 52.289556199],
  [-66.2521438, 52.280219999],
  [-66.2496473, 52.273983699],
  [-66.2444527, 52.268903199],
  [-66.2372867, 52.262841199],
  [-66.2316898, 52.259980999],
  [-66.2239894, 52.257965999],
  [-66.2181248, 52.257128899],
  [-66.2147756, 52.254855799],
  [-66.2090365, 52.248931199],
  [-66.2057277, 52.243875499],
  [-66.2057161, 52.238985499],
  [-66.2083592, 52.234093099],
  [-66.2067054, 52.231565399],
  [-66.200791, 52.226989799],
  [-66.1799948, 52.215483599],
  [-66.1769718, 52.213293599],
  [-66.1723058, 52.213464999],
  [-66.1697013, 52.215573999],
  [-66.1638148, 52.227295599],
  [-66.1600217, 52.231653199],
  [-66.1521026, 52.230504299],
  [-66.1440455, 52.227921599],
  [-66.1384143, 52.224578999],
  [-66.1350246, 52.218565899],
  [-66.1279755, 52.210192999],
  [-66.1180028, 52.205472999],
  [-66.1090843, 52.201960499],
  [-66.1039171, 52.198503899],
  [-66.1015859, 52.195328299],
  [-66.1008095, 52.192096799],
  [-66.0873802, 52.172256699],
  [-66.0843681, 52.166804999],
  [-66.0826357, 52.161690799],
  [-66.0759261, 52.155029699],
  [-66.0761116, 52.152051599],
  [-66.0786789, 52.141319899],
  [-66.0758122, 52.130783399],
  [-66.0781914, 52.124659699],
  [-66.0827139, 52.119800899],
  [-66.0872795, 52.117048999],
  [-66.0912066, 52.112498899],
  [-66.0963648, 52.107808399],
  [-66.0955459, 52.102470699],
  [-66.0959582, 52.098622199],
  [-66.0981948, 52.094324599],
  [-66.0969193, 52.090728699],
  [-66.0926347, 52.086570499],
  [-66.0912212, 52.086429799],
  [-66.0905348, 52.090671299],
  [-66.0771215, 52.096903799],
  [-66.0700039, 52.098979899],
  [-66.0663106, 52.097770299],
  [-66.0594776, 52.091305999],
  [-66.0535093, 52.087398099],
  [-66.0495909, 52.085429399],
  [-66.0442568, 52.084948499],
  [-66.0350915, 52.085082399],
  [-66.0244721, 52.082966999],
  [-66.0156342, 52.079925299],
  [-66.0165182, 52.072707099],
  [-66.0122441, 52.068545799],
  [-66.0029498, 52.067244199],
  [-65.9980892, 52.061761599],
  [-65.9961962, 52.054737399],
  [-65.9880922, 52.052817899],
  [-65.9787529, 52.052665699],
  [-65.9704015, 52.056502599],
  [-65.9680533, 52.063100099],
  [-65.9659732, 52.070933999],
  [-65.9614356, 52.077416099],
  [-65.9631484, 52.082530999],
  [-65.9675443, 52.089756599],
  [-65.9678511, 52.093099899],
  [-65.9645434, 52.099443899],
  [-65.9612846, 52.104636099],
  [-65.9547801, 52.099878799],
  [-65.9522721, 52.099678299],
  [-65.9457487, 52.099808399],
  [-65.9396963, 52.096567899],
  [-65.9273469, 52.094214799],
  [-65.9192895, 52.091138099],
  [-65.9132738, 52.081379099],
  [-65.9037558, 52.079309699],
  [-65.89771, 52.076066899],
  [-65.8920758, 52.080858399],
  [-65.8875677, 52.087815099],
  [-65.8890196, 52.097060299],
  [-65.8856988, 52.103401799],
  [-65.876989, 52.110405399],
  [-65.8669254, 52.114483499],
  [-65.8561475, 52.115805799],
  [-65.8420421, 52.116471999],
  [-65.8341673, 52.113669799],
  [-65.8221918, 52.110237599],
  [-65.8202251, 52.110878099],
  [-65.8166942, 52.113199799],
  [-65.8126092, 52.116306399],
  [-65.8100338, 52.118883399],
  [-65.8053356, 52.118560499],
  [-65.8047237, 52.115131299],
  [-65.806079, 52.111061799],
  [-65.8053706, 52.108306599],
  [-65.7973616, 52.105697899],
  [-65.7855583, 52.104172299],
  [-65.7762491, 52.104479399],
  [-65.7751406, 52.106048999],
  [-65.7736853, 52.110791899],
  [-65.7712604, 52.111542499],
  [-65.7677282, 52.108496599],
  [-65.7616065, 52.104290199],
  [-65.7593766, 52.103692699],
  [-65.7566746, 52.104835199],
  [-65.753043, 52.107828499],
  [-65.747688, 52.099859499],
  [-65.744965, 52.093529199],
  [-65.7452834, 52.093614599],
  [-65.7383401, 52.085218399],
  [-65.7339397, 52.082870799],
  [-65.7293284, 52.083499799],
  [-65.7268884, 52.085878399],
  [-65.7139246, 52.096166799],
  [-65.7065509, 52.099087699],
  [-65.6865665, 52.103754799],
  [-65.678996, 52.111757899],
  [-65.6748236, 52.117639899],
  [-65.6682561, 52.117274699],
  [-65.6644932, 52.115095199],
  [-65.666874, 52.106396599],
  [-65.665196, 52.097540099],
  [-65.6624276, 52.092359199],
  [-65.6485619, 52.080920699],
  [-65.6497931, 52.077049799],
  [-65.6536851, 52.067825499],
  [-65.6515355, 52.060709299],
  [-65.6512352, 52.055258699],
  [-65.6580867, 52.049867999],
  [-65.6687511, 52.045504699],
  [-65.6799101, 52.041507499],
  [-65.6694941, 52.032166399],
  [-65.6686948, 52.026349099],
  [-65.6730959, 52.019599299],
  [-65.678171, 52.013498199],
  [-65.6773707, 52.007681199],
  [-65.6741106, 52.002134799],
  [-65.6686474, 51.994360199],
  [-65.6596973, 51.989378599],
  [-65.6501752, 51.986808799],
  [-65.6468035, 51.987298999],
  [-65.6382354, 51.992455299],
  [-65.6340497, 51.999962399],
  [-65.6375223, 52.006269399],
  [-65.6367288, 52.011657999],
  [-65.6317068, 52.020341199],
  [-65.6268023, 52.026721799],
  [-65.6239606, 52.035527599],
  [-65.6205422, 52.043010699],
  [-65.61248, 52.039432799],
  [-65.5982836, 52.034896999],
  [-65.5793217, 52.032806399],
  [-65.5641883, 52.033850499],
  [-65.5484495, 52.038933599],
  [-65.5398958, 52.044560199],
  [-65.5418313, 52.049289799],
  [-65.5408545, 52.050660699],
  [-65.5325908, 52.058001999],
  [-65.5310935, 52.060633999],
  [-65.5306691, 52.070800199],
  [-65.5137566, 52.082810599],
  [-65.5065493, 52.091842199],
  [-65.5017553, 52.098022399],
  [-65.5042612, 52.104077199],
  [-65.5031675, 52.109857999],
  [-65.4993254, 52.110453399],
  [-65.4989822, 52.106152999],
  [-65.495624, 52.105009199],
  [-65.4901041, 52.102110599],
  [-65.4939613, 52.086092899],
  [-65.4950319, 52.070255799],
  [-65.4981079, 52.065948299],
  [-65.4971936, 52.060324799],
  [-65.484489, 52.056877499],
  [-65.4960768, 52.050205999],
  [-65.4561845, 52.011086799],
  [-65.4511752, 52.008555099],
  [-65.4439191, 52.007047799],
  [-65.4346586, 52.005695399],
  [-65.4267696, 52.004014199],
  [-65.4232081, 52.002108699],
  [-65.4290828, 52.001838899],
  [-65.4248617, 51.993441599],
  [-65.4171444, 51.989935399],
  [-65.4109758, 51.986382999],
  [-65.4121826, 51.979931999],
  [-65.412291, 51.971311299],
  [-65.4101399, 51.969555499],
  [-65.3997553, 51.965552599],
  [-65.3899762, 51.963350099],
  [-65.3784508, 51.964644499],
  [-65.371217, 51.967344799],
  [-65.3712956, 51.970406299],
  [-65.3748635, 51.976525999],
  [-65.364502, 51.980944499],
  [-65.3838668, 51.988806499],
  [-65.3772321, 51.993308999],
  [-65.3712238, 51.995877799],
  [-65.3714199, 51.990318499],
  [-65.3590869, 51.981101599],
  [-65.3594307, 51.963187199],
  [-65.3604325, 51.955977499],
  [-65.3572215, 51.950421999],
  [-65.3496543, 51.944609599],
  [-65.3493334, 51.942416899],
  [-65.3547794, 51.942737999],
  [-65.3588048, 51.936589899],
  [-65.3652814, 51.928072899],
  [-65.3697179, 51.919232199],
  [-65.374097, 51.915753999],
  [-65.382349, 51.916374499],
  [-65.3932276, 51.914906899],
  [-65.3995023, 51.901894699],
  [-65.39757, 51.890847299],
  [-65.39217, 51.880955099],
  [-65.3851332, 51.873886899],
  [-65.3833951, 51.867333299],
  [-65.3727972, 51.864197399],
  [-65.36789, 51.864723399],
  [-65.3625944, 51.864207599],
  [-65.3593131, 51.855593099],
  [-65.3567631, 51.844373499],
  [-65.3560763, 51.835301299],
  [-65.3550479, 51.829875999],
  [-65.3597599, 51.822752699],
  [-65.3560784, 51.821041399],
  [-65.3460941, 51.819704199],
  [-65.3367848, 51.823227099],
  [-65.3315058, 51.829025799],
  [-65.3269636, 51.836429899],
  [-65.325152, 51.843181299],
  [-65.3178383, 51.850760699],
  [-65.3185822, 51.856577499],
  [-65.3232994, 51.870033199],
  [-65.3191652, 51.872640299],
  [-65.3002328, 51.877966899],
  [-65.2906524, 51.869720899],
  [-65.2835359, 51.873846899],
  [-65.2674386, 51.867314599],
  [-65.2538704, 51.861474899],
  [-65.2531004, 51.857285399],
  [-65.2566994, 51.850908399],
  [-65.2568741, 51.848478799],
  [-65.2592928, 51.843790499],
  [-65.2596648, 51.845652499],
  [-65.2644277, 51.836686599],
  [-65.2630544, 51.834692399],
  [-65.2632466, 51.827968899],
  [-65.25889, 51.822383899],
  [-65.2547919, 51.823360199],
  [-65.2509732, 51.826051099],
  [-65.2476791, 51.829588399],
  [-65.2405372, 51.822706699],
  [-65.2366845, 51.822814199],
  [-65.2288751, 51.821596299],
  [-65.2211744, 51.817599799],
  [-65.2101848, 51.812928199],
  [-65.2046175, 51.812794899],
  [-65.1953581, 51.821470499],
  [-65.1885718, 51.817255099],
  [-65.1853111, 51.819162599],
  [-65.1801895, 51.823130299],
  [-65.1745821, 51.824623099],
  [-65.1696146, 51.822079299],
  [-65.1696976, 51.816718499],
  [-65.1729008, 51.809646099],
  [-65.169762, 51.804564599],
  [-65.1671911, 51.796597299],
  [-65.1687129, 51.789762599],
  [-65.1860637, 51.775606399],
  [-65.1867396, 51.769942899],
  [-65.180418, 51.765619499],
  [-65.170173, 51.764655699],
  [-65.1623044, 51.764583099],
  [-65.1560872, 51.761690499],
  [-65.1486405, 51.761031299],
  [-65.1422509, 51.757855699],
  [-65.1321161, 51.756215599],
  [-65.1196078, 51.753681399],
  [-65.1077051, 51.748738099],
  [-65.0911537, 51.745971799],
  [-65.0825858, 51.746198599],
  [-65.0747785, 51.757545499],
  [-65.0710376, 51.762197199],
  [-65.0615991, 51.765738999],
  [-65.0492169, 51.769647399],
  [-65.0469888, 51.769027399],
  [-65.0426222, 51.764490899],
  [-65.0302766, 51.762002099],
  [-65.0161262, 51.765177199],
  [-65.0100347, 51.765140199],
  [-65.0094444, 51.763314799],
  [-65.0153928, 51.763549199],
  [-65.0203998, 51.761385699],
  [-65.0205235, 51.758573099],
  [-65.0141419, 51.754184599],
  [-65.0095272, 51.750525399],
  [-65.0082796, 51.750009799],
  [-64.999232, 51.750000899],
  [-64.9992321, 51.747659299],
  [-64.9976169, 51.747440499],
  [-64.9945477, 51.745872199],
  [-64.9963096, 51.741855499],
  [-64.9930486, 51.737386199],
  [-64.9870383, 51.731437099],
  [-64.9723545, 51.721640099],
  [-64.9576764, 51.716587299],
  [-64.950894, 51.715402099],
  [-64.9445241, 51.715755899],
  [-64.9375677, 51.714283699],
  [-64.9307058, 51.714261599],
  [-64.9249031, 51.718571099],
  [-64.9200645, 51.725762099],
  [-64.9192798, 51.730527699],
  [-64.9164452, 51.734956799],
  [-64.9098472, 51.736670099],
  [-64.9124321, 51.740480499],
  [-64.916401, 51.743966099],
  [-64.9229815, 51.744383799],
  [-64.927637, 51.746398899],
  [-64.9250263, 51.749466799],
  [-64.9208259, 51.752089699],
  [-64.9183576, 51.754960199],
  [-64.9198289, 51.760832599],
  [-64.9277466, 51.762573099],
  [-64.9340124, 51.762901199],
  [-64.9372101, 51.761658799],
  [-64.9386391, 51.764432299],
  [-64.9348009, 51.770243699],
  [-64.9246753, 51.777858499],
  [-64.9025559, 51.771431399],
  [-64.8922373, 51.768779199],
  [-64.8839199, 51.768111299],
  [-64.8771406, 51.769536299],
  [-64.8721765, 51.770044799],
  [-64.8677779, 51.767148999],
  [-64.8597705, 51.763953099],
  [-64.847815, 51.762498699],
  [-64.838424, 51.769602299],
  [-64.8212872, 51.775243499],
  [-64.8117635, 51.777795099],
  [-64.8053822, 51.778140299],
  [-64.801941, 51.775509599],
  [-64.7990124, 51.768507699],
  [-64.7940348, 51.761166799],
  [-64.7877076, 51.757247799],
  [-64.7730987, 51.753135199],
  [-64.7638655, 51.755288099],
  [-64.7554981, 51.758872799],
  [-64.7447609, 51.759412199],
  [-64.7394215, 51.761472699],
  [-64.7371984, 51.758230799],
  [-64.7360144, 51.754093199],
  [-64.7309061, 51.752176799],
  [-64.7247301, 51.753287199],
  [-64.7159561, 51.755328299],
  [-64.7097506, 51.755954099],
  [-64.7063748, 51.754287599],
  [-64.7069826, 51.751368099],
  [-64.7108525, 51.745565599],
  [-64.7109137, 51.738687799],
  [-64.7078611, 51.729266199],
  [-64.7058722, 51.727277699],
  [-64.7009112, 51.727777899],
  [-64.7003358, 51.725950799],
  [-64.7031352, 51.723175599],
  [-64.6881174, 51.717027399],
  [-64.6919016, 51.709775599],
  [-64.6916155, 51.707554699],
  [-64.684551, 51.709360599],
  [-64.6811488, 51.709824699],
  [-64.6773837, 51.701387599],
  [-64.6664326, 51.700664999],
  [-64.6535478, 51.701533599],
  [-64.6467995, 51.703426699],
  [-64.6398782, 51.705032599],
  [-64.6417357, 51.699374999],
  [-64.6447209, 51.691658999],
  [-64.6413316, 51.686892499],
  [-64.637777, 51.679224899],
  [-64.6404389, 51.674034099],
  [-64.6331295, 51.671484599],
  [-64.6260482, 51.670188199],
  [-64.6211006, 51.668069999],
  [-64.6182119, 51.661065399],
  [-64.6168864, 51.651412399],
  [-64.6233109, 51.649431799],
  [-64.6259658, 51.646856099],
  [-64.6236759, 51.642162399],
  [-64.6231745, 51.636074099],
  [-64.6241718, 51.628984699],
  [-64.6143971, 51.626682299],
  [-64.6066566, 51.627335599],
  [-64.6021591, 51.630338099],
  [-64.6010575, 51.632879199],
  [-64.5968745, 51.627645599],
  [-64.5904351, 51.618201299],
  [-64.587303, 51.609459799],
  [-64.5819952, 51.606283299],
  [-64.575371, 51.601779699],
  [-64.5677955, 51.591294599],
  [-64.5617536, 51.586003499],
  [-64.5543558, 51.575804699],
  [-64.5485035, 51.573896799],
  [-64.5448771, 51.581426999],
  [-64.5415775, 51.586433699],
  [-64.5226087, 51.597675599],
  [-64.507645, 51.605539399],
  [-64.4908898, 51.609082199],
  [-64.4726896, 51.617685599],
  [-64.4553272, 51.624622999],
  [-64.4477936, 51.632229899],
  [-64.4411605, 51.642238399],
  [-64.4343032, 51.650992699],
  [-64.4307683, 51.657840599],
  [-64.4322335, 51.662070799],
  [-64.4298862, 51.667829999],
  [-64.4246229, 51.674422199],
  [-64.4229969, 51.676100699],
  [-64.4173316, 51.674955299],
  [-64.4067516, 51.669066599],
  [-64.3955922, 51.663962999],
  [-64.391023, 51.662891399],
  [-64.3850938, 51.662620799],
  [-64.3775372, 51.664027599],
  [-64.3701021, 51.667851699],
  [-64.3659464, 51.668803999],
  [-64.3589225, 51.671554299],
  [-64.3500523, 51.668531199],
  [-64.3435403, 51.665945999],
  [-64.3371252, 51.665294799],
  [-64.334326, 51.671641099],
  [-64.3220625, 51.685041699],
  [-64.3243429, 51.690226299],
  [-64.325628, 51.694171299],
  [-64.3223897, 51.698008899],
  [-64.3052109, 51.703562399],
  [-64.303187, 51.706795499],
  [-64.3069288, 51.710016899],
  [-64.3015026, 51.710603199],
  [-64.29651, 51.713698499],
  [-64.2946025, 51.719349999],
  [-64.2939882, 51.722750299],
  [-64.2831427, 51.730602099],
  [-64.2764115, 51.736536899],
  [-64.2740371, 51.742293799],
  [-64.2782893, 51.746378499],
  [-64.2841541, 51.748302899],
  [-64.2892869, 51.751208999],
  [-64.2988276, 51.754903699],
  [-64.3018933, 51.756976399],
  [-64.3048319, 51.762828099],
  [-64.3059428, 51.769585899],
  [-64.3056927, 51.774045199],
  [-64.3043177, 51.781043599],
  [-64.3057976, 51.785761099],
  [-64.3071593, 51.788059699],
  [-64.3011737, 51.796597799],
  [-64.3005352, 51.799515199],
  [-64.3024552, 51.803643599],
  [-64.3064992, 51.806472999],
  [-64.320929, 51.813948499],
  [-64.3273892, 51.818184299],
  [-64.3334112, 51.823009099],
  [-64.3387021, 51.828816299],
  [-64.3443102, 51.837813699],
  [-64.3527034, 51.849275899],
  [-64.3541917, 51.853993699],
  [-64.3536047, 51.857879699],
  [-64.3520159, 51.863625399],
  [-64.3518424, 51.872637699],
  [-64.3523283, 51.882315599],
  [-64.3530358, 51.887048899],
  [-64.3517399, 51.889301999],
  [-64.3400573, 51.890494699],
  [-64.3419358, 51.896755799],
  [-64.3429342, 51.904196399],
  [-64.3440813, 51.908340299],
  [-64.3442994, 51.912695799],
  [-64.3457647, 51.916930599],
  [-64.3497739, 51.921891799],
  [-64.3572578, 51.927367299],
  [-64.356132, 51.929908099],
  [-64.3494032, 51.933234699],
  [-64.3450706, 51.937483999],
  [-64.3475156, 51.942475499],
  [-64.3496441, 51.944275699],
  [-64.3444772, 51.950672699],
  [-64.3439834, 51.959596499],
  [-64.3429755, 51.967658299],
  [-64.3452993, 51.973330999],
  [-64.3547522, 51.977219599],
  [-64.3587691, 51.979080399],
  [-64.3572009, 51.982211499],
  [-64.3515665, 51.982513499],
  [-64.3458335, 51.983979999],
  [-64.3416922, 51.985900299],
  [-64.3396569, 51.989136999],
  [-64.3435999, 51.992647299],
  [-64.3479583, 52.001285599],
  [-64.3610459, 52.005492099],
  [-64.3582759, 52.006611499],
  [-64.351192, 52.006262999],
  [-64.3385187, 52.007183499],
  [-64.3311161, 52.006742799],
  [-64.3228574, 52.004864099],
  [-64.3222715, 52.002549399],
  [-64.3267821, 52.001688799],
  [-64.3269322, 51.998391099],
  [-64.3229661, 51.994396099],
  [-64.3202472, 51.993381499],
  [-64.3135599, 51.991472499],
  [-64.3090306, 51.988747399],
  [-64.2991849, 51.986412199],
  [-64.2935313, 51.983126099],
  [-64.2878845, 51.976738799],
  [-64.2800997, 51.974751299],
  [-64.2619714, 51.976247799],
  [-64.2371051, 51.977959899],
  [-64.2336311, 51.984127199],
  [-64.2339518, 51.990904399],
  [-64.2382038, 51.994509899],
  [-64.2465025, 51.997363499],
  [-64.2465163, 52.000948699],
  [-64.2548463, 52.010972199],
  [-64.2661951, 52.021618499],
  [-64.2722336, 52.029551399],
  [-64.2793783, 52.037560499],
  [-64.2711947, 52.043817099],
  [-64.2692982, 52.046856399],
  [-64.2727508, 52.049991299],
  [-64.2810572, 52.055944199],
  [-64.2873462, 52.065617099],
  [-64.2899337, 52.073517099],
  [-64.291866, 52.077649199],
  [-64.2880313, 52.079658899],
  [-64.2787316, 52.076050499],
  [-64.2724379, 52.079268099],
  [-64.2678938, 52.079642299],
  [-64.2616845, 52.078108199],
  [-64.2515378, 52.079746999],
  [-64.2496985, 52.087340799],
  [-64.2518448, 52.092729899],
  [-64.2556192, 52.095956999],
  [-64.2566231, 52.097198799],
  [-64.246041, 52.103013199],
  [-64.256433, 52.112905699],
  [-64.2538529, 52.121483299],
  [-64.2503314, 52.123582999],
  [-64.244847, 52.124166999],
  [-64.2362743, 52.129362699],
  [-64.2421752, 52.137496399],
  [-64.2512363, 52.142470399],
  [-64.2499262, 52.144723899],
  [-64.2424293, 52.142823699],
  [-64.2112066, 52.132234499],
  [-64.1984927, 52.126449299],
  [-64.1930431, 52.124412699],
  [-64.1911414, 52.120762499],
  [-64.1866057, 52.118030999],
  [-64.1803266, 52.115038899],
  [-64.1729873, 52.112937399],
  [-64.1663599, 52.116057399],
  [-64.164673, 52.123938399],
  [-64.177411, 52.133798699],
  [-64.1647209, 52.138769199],
  [-64.1607974, 52.146010699],
  [-64.1682977, 52.151503699],
  [-64.1785043, 52.157530699],
  [-64.1882683, 52.164146399],
  [-64.1932191, 52.168906999],
  [-64.2006929, 52.177016299],
  [-64.1976637, 52.183080899],
  [-64.198403, 52.188788299],
  [-64.203688, 52.197227299],
  [-64.2099472, 52.202837299],
  [-64.2102307, 52.212237599],
  [-64.2097378, 52.218549099],
  [-64.213509, 52.224881999],
  [-64.2202999, 52.228252099],
  [-64.2239931, 52.229543899],
  [-64.2290115, 52.225478399],
  [-64.2317315, 52.222908499],
  [-64.2339955, 52.224031499],
  [-64.2197971, 52.237667199],
  [-64.2235598, 52.240412299],
  [-64.2221766, 52.247903999],
  [-64.218802, 52.253394699],
  [-64.2175549, 52.257101999],
  [-64.2212165, 52.261012899],
  [-64.233129, 52.262254799],
  [-64.2365285, 52.263939499],
  [-64.2435369, 52.271668899],
  [-64.2465614, 52.275396699],
  [-64.2387554, 52.277476899],
  [-64.2344649, 52.283663999],
  [-64.2350709, 52.286913699],
  [-64.23268, 52.287934699],
  [-64.2248399, 52.284852599],
  [-64.2218576, 52.283294599],
  [-64.2144809, 52.282044799],
  [-64.2098139, 52.284544099],
  [-64.20689, 52.294204799],
  [-64.2142753, 52.297584099],
  [-64.2203774, 52.299604799],
  [-64.2255833, 52.302504199],
  [-64.2255502, 52.307741199],
  [-64.2204526, 52.311111899],
  [-64.2162244, 52.313028299],
  [-64.2102422, 52.313594599],
  [-64.2070545, 52.311694499],
  [-64.1996345, 52.305610599],
  [-64.1975728, 52.297648699],
  [-64.1945529, 52.291257199],
  [-64.1915714, 52.289698399],
  [-64.1896131, 52.291976599],
  [-64.1861779, 52.296812399],
  [-64.1828352, 52.303660499],
  [-64.1846141, 52.305585899],
  [-64.1800528, 52.306413399],
  [-64.1694564, 52.306654699],
  [-64.1676862, 52.308986899],
  [-64.1647423, 52.314389099],
  [-64.1678965, 52.319686199],
  [-64.1732825, 52.324484199],
  [-64.169192, 52.327432199],
  [-64.1719762, 52.330779199],
  [-64.1827743, 52.332720999],
  [-64.181234, 52.335970399],
  [-64.1615049, 52.333894699],
  [-64.1583547, 52.338955399],
  [-64.1629636, 52.341120399],
  [-64.166408, 52.344513899],
  [-64.1665639, 52.360164499],
  [-64.1652871, 52.363063999],
  [-64.1561959, 52.361608899],
  [-64.1380119, 52.366925799],
  [-64.1270191, 52.383801699],
  [-64.1222842, 52.387276999],
  [-64.1154638, 52.388312299],
  [-64.1104478, 52.391849299],
  [-64.1092291, 52.394056699],
  [-64.1182284, 52.401733799],
  [-64.1189536, 52.403218099],
  [-64.1074172, 52.408303099],
  [-64.1093766, 52.414256399],
  [-64.1147181, 52.420038199],
  [-64.1218769, 52.422220699],
  [-64.1280161, 52.420563299],
  [-64.132594, 52.421867499],
  [-64.1349741, 52.424532599],
  [-64.1337319, 52.428294999],
  [-64.1277862, 52.434266599],
  [-64.1285773, 52.437188899],
  [-64.135237, 52.444905099],
  [-64.1370815, 52.456501899],
  [-64.1425926, 52.467864299],
  [-64.1428073, 52.476726199],
  [-64.1449039, 52.483426299],
  [-64.1447107, 52.491604599],
  [-64.1485653, 52.497526599],
  [-64.1529287, 52.502173299],
  [-64.153357, 52.507405299],
  [-64.1484606, 52.513821899],
  [-64.1519486, 52.519923099],
  [-64.1670811, 52.528761499],
  [-64.1700842, 52.532452499],
  [-64.1668791, 52.536653299],
  [-64.1704387, 52.544192699],
  [-64.1702206, 52.543563099],
  [-64.1783671, 52.548144299],
  [-64.1914048, 52.554252299],
  [-64.1992678, 52.560737299],
  [-64.2111212, 52.573601499],
  [-64.2247614, 52.580156199],
  [-64.2256994, 52.581982399],
  [-64.2225712, 52.581807399],
  [-64.2058093, 52.577207599],
  [-64.2005449, 52.579833599],
  [-64.1991202, 52.581701299],
  [-64.2041964, 52.589099099],
  [-64.1986067, 52.594898199],
  [-64.1946758, 52.595772999],
  [-64.1875379, 52.590771599],
  [-64.1739269, 52.584787299],
  [-64.1715385, 52.584252599],
  [-64.1701565, 52.589113699],
  [-64.1684533, 52.593174199],
  [-64.1677306, 52.595950899],
  [-64.1610796, 52.597331799],
  [-64.1545504, 52.603432599],
  [-64.1611245, 52.608561199],
  [-64.1684766, 52.612063599],
  [-64.1701213, 52.616930699],
  [-64.1700579, 52.623727899],
  [-64.1633168, 52.627356699],
  [-64.1563893, 52.635192699],
  [-64.1531246, 52.640374299],
  [-64.1529689, 52.647288599],
  [-64.1574656, 52.652395999],
  [-64.1588825, 52.658477299],
  [-64.17299, 52.670562499],
  [-64.1708544, 52.677626699],
  [-64.1675666, 52.678258999],
  [-64.1639935, 52.676822499],
  [-64.1534942, 52.673142099],
  [-64.1449751, 52.675417699],
  [-64.1328523, 52.683916899],
  [-64.1276578, 52.686423299],
  [-64.1244371, 52.692755999],
  [-64.1243525, 52.699266799],
  [-64.1310415, 52.704569799],
  [-64.140602, 52.710400199],
  [-64.1461022, 52.714512799],
  [-64.1445832, 52.718628699],
  [-64.1404933, 52.726705499],
  [-64.1373824, 52.729235099],
  [-64.124224, 52.732047999],
  [-64.1049114, 52.733117499],
  [-64.089635, 52.733195699],
  [-64.0832904, 52.735661299],
  [-64.0795348, 52.736586699],
  [-64.062895, 52.725045999],
  [-64.0535177, 52.723237999],
  [-64.0393048, 52.725884199],
  [-64.0354, 52.732167799],
  [-64.0381614, 52.736790399],
  [-64.0372748, 52.738244699],
  [-64.0295597, 52.737617799],
  [-64.0164972, 52.738456899],
  [-64.0137171, 52.737809099],
  [-64.007041, 52.737049099],
  [-63.9859997, 52.726371499],
  [-63.9827349, 52.725442399],
  [-63.9694053, 52.726625299],
  [-63.9591708, 52.729257599],
  [-63.9374172, 52.731024299],
  [-63.9211969, 52.735930999],
  [-63.9182301, 52.740064799],
  [-63.9227622, 52.744203299],
  [-63.922843, 52.746218399],
  [-63.919263, 52.746904899],
  [-63.9210626, 52.751544699],
  [-63.9212587, 52.756438699],
  [-63.9195085, 52.757789499],
  [-63.9146615, 52.757687999],
  [-63.9034634, 52.753070199],
  [-63.8929633, 52.750072999],
  [-63.888641, 52.750073699],
  [-63.8833436, 52.753303799],
  [-63.8939186, 52.763866699],
  [-63.8834886, 52.764305199],
  [-63.878821, 52.766388599],
  [-63.8729413, 52.773962199],
  [-63.8673016, 52.773063199],
  [-63.8589266, 52.772663499],
  [-63.854111, 52.773422799],
  [-63.8504172, 52.771227999],
  [-63.8346501, 52.763728799],
  [-63.8189753, 52.758530499],
  [-63.809911, 52.759922199],
  [-63.8047494, 52.764140599],
  [-63.7979977, 52.766479099],
  [-63.783357, 52.768692999],
  [-63.7781774, 52.769914599],
  [-63.7722222, 52.773335399],
  [-63.7643643, 52.774073499],
  [-63.7597571, 52.772751799],
  [-63.7547046, 52.769592199],
  [-63.7516234, 52.768422899],
  [-63.7410794, 52.768387899],
  [-63.7359995, 52.769778899],
  [-63.7234854, 52.770458999],
  [-63.7214856, 52.770311799],
  [-63.7194237, 52.776559999],
  [-63.7257775, 52.778955599],
  [-63.7318695, 52.781987999],
  [-63.7339612, 52.784726199],
  [-63.7334562, 52.786576299],
  [-63.7307281, 52.787360599],
  [-63.7140659, 52.786422199],
  [-63.7179538, 52.794380599],
  [-63.7195394, 52.798969299],
  [-63.7175373, 52.801529899],
  [-63.7118961, 52.803330299],
  [-63.7006852, 52.803529999],
  [-63.694841, 52.804986399],
  [-63.6946959, 52.809078799],
  [-63.6963802, 52.813839599],
  [-63.6929821, 52.814573699],
  [-63.6898234, 52.808390899],
  [-63.6804011, 52.808105099],
  [-63.6729572, 52.810098699],
  [-63.6600641, 52.811236699],
  [-63.6530312, 52.814203199],
  [-63.644122, 52.823759999],
  [-63.6400893, 52.831298799],
  [-63.639406, 52.833669299],
  [-63.6417144, 52.837328999],
  [-63.6587617, 52.843517899],
  [-63.6760784, 52.849066899],
  [-63.6765602, 52.852057199],
  [-63.6718086, 52.855112199],
  [-63.6582904, 52.852224199],
  [-63.652125, 52.852875499],
  [-63.637938, 52.846939199],
  [-63.6279022, 52.845618799],
  [-63.6188446, 52.842153999],
  [-63.6164481, 52.841606199],
  [-63.6099641, 52.844160099],
  [-63.6066266, 52.849903599],
  [-63.610022, 52.854876699],
  [-63.6176358, 52.857783199],
  [-63.6241628, 52.862373199],
  [-63.6193625, 52.869978599],
  [-63.6233382, 52.875117599],
  [-63.6198883, 52.877405799],
  [-63.614368, 52.877528599],
  [-63.611518, 52.868860999],
  [-63.6084525, 52.871092599],
  [-63.6072383, 52.869605599],
  [-63.6094015, 52.863597499],
  [-63.6079214, 52.859294499],
  [-63.5940624, 52.851466499],
  [-63.5870625, 52.839549299],
  [-63.5891875, 52.833079499],
  [-63.5988848, 52.826850199],
  [-63.5950283, 52.816443599],
  [-63.6005867, 52.805758599],
  [-63.5963715, 52.795588499],
  [-63.6128095, 52.793505799],
  [-63.6156936, 52.791819699],
  [-63.6165332, 52.774184299],
  [-63.609804, 52.770262699],
  [-63.5853207, 52.765947999],
  [-63.5701188, 52.759756599],
  [-63.5571907, 52.748926499],
  [-63.5509155, 52.741102499],
  [-63.5279926, 52.727582899],
  [-63.5183947, 52.725339799],
  [-63.5064734, 52.727052499],
  [-63.459437, 52.718095199],
  [-63.4500489, 52.713555599],
  [-63.4357933, 52.699547799],
  [-63.423197, 52.692349299],
  [-63.3964631, 52.682343099],
  [-63.3836495, 52.667145199],
  [-63.3838175, 52.659363099],
  [-63.3912564, 52.658245499],
  [-63.4024718, 52.662277699],
  [-63.4080681, 52.661667399],
  [-63.4164037, 52.652515999],
  [-63.4261429, 52.652016299],
  [-63.4294085, 52.645519899],
  [-63.4630893, 52.648232399],
  [-63.4667554, 52.646984999],
  [-63.5019392, 52.649647999],
  [-63.5144518, 52.646085099],
  [-63.5233136, 52.648805099],
  [-63.5834844, 52.648664399],
  [-63.6134497, 52.647096799],
  [-63.6530284, 52.638830599],
  [-63.7138556, 52.633112799],
  [-63.7366986, 52.631960899],
  [-63.7531518, 52.635344699],
  [-63.7666371, 52.634703899],
  [-63.7738891, 52.631507199],
  [-63.7967284, 52.630340099],
  [-63.8108279, 52.628074299],
  [-63.8257744, 52.622573599],
  [-63.8509597, 52.622470799],
  [-63.8717009, 52.618839299],
  [-63.8799104, 52.613773399],
  [-63.8944716, 52.612625299],
  [-63.9076289, 52.608314699],
  [-63.9133702, 52.605156199],
  [-63.9117108, 52.603378499],
  [-63.9305903, 52.604374299],
  [-63.9397267, 52.605682799],
  [-63.9464181, 52.596313899],
  [-63.9595562, 52.596119499],
  [-63.9698563, 52.589378999],
  [-63.9926613, 52.583828699],
  [-63.9959994, 52.579144299],
  [-64.006124, 52.574692499],
  [-64.0125405, 52.570820699],
  [-64.0325988, 52.568329799],
  [-64.0298522, 52.567047999],
  [-64.0379549, 52.561058999],
  [-64.0448372, 52.541607799],
  [-64.0489802, 52.537583299],
  [-64.053848, 52.533303199],
  [-64.0571594, 52.526627499],
  [-64.0644687, 52.515454199],
  [-64.0720386, 52.500061499],
  [-64.0731137, 52.500061999],
  [-64.0845958, 52.504128499],
  [-64.0854963, 52.493344199],
  [-64.0935156, 52.466072899],
  [-64.090197, 52.454285399],
  [-64.0741447, 52.438347299],
  [-64.04545, 52.423286599],
  [-64.0206314, 52.401000799],
  [-64.0300923, 52.389701699],
  [-64.0182667, 52.374534299],
  [-64.0127202, 52.362590399],
  [-63.9982546, 52.359633799],
  [-63.988584, 52.360179299],
  [-63.9749158, 52.353533899],
  [-63.9651917, 52.357741499],
  [-63.9424405, 52.345442699],
  [-63.9125288, 52.340918199],
  [-63.8997989, 52.336294599],
  [-63.8732138, 52.322500399],
  [-63.8597476, 52.322244699],
  [-63.854632, 52.319200599],
  [-63.8365956, 52.322290299],
  [-63.825918, 52.319654299],
  [-63.8230339, 52.320886399],
  [-63.8276917, 52.327375299],
  [-63.8268638, 52.330835199],
  [-63.7694816, 52.328053899],
  [-63.7648238, 52.325909899],
  [-63.7633628, 52.321835799],
  [-63.7476002, 52.316372999],
  [-63.742847, 52.313088499],
  [-63.7414089, 52.309243299],
  [-63.743179, 52.308045399],
  [-63.7660258, 52.308942099],
  [-63.7746548, 52.313935599],
  [-63.7828567, 52.309331299],
  [-63.7889755, 52.315549299],
  [-63.7946023, 52.315831699],
  [-63.7960025, 52.310296599],
  [-63.8027242, 52.310315699],
  [-63.8080916, 52.311975099],
  [-63.8115133, 52.317132599],
  [-63.8175865, 52.318311399],
  [-63.8175257, 52.308705999],
  [-63.8235568, 52.309430799],
  [-63.8341733, 52.315732799],
  [-63.8373892, 52.314027699],
  [-63.8196887, 52.303376799],
  [-63.7941297, 52.296853499],
  [-63.7706537, 52.283860399],
  [-63.7685176, 52.276146999],
  [-63.7845588, 52.276105099],
  [-63.7896135, 52.274117699],
  [-63.7990016, 52.279313399],
  [-63.8033924, 52.278260599],
  [-63.8012829, 52.275353799],
  [-63.7919138, 52.270383099],
  [-63.7657176, 52.269368199],
  [-63.7515687, 52.260655299],
  [-63.7481584, 52.255497699],
  [-63.7494936, 52.253628499],
  [-63.7564671, 52.252266699],
  [-63.7580617, 52.249016599],
  [-63.7527976, 52.234993699],
  [-63.7260143, 52.212940599],
  [-63.710809, 52.190986799],
  [-63.7125488, 52.180408599],
  [-63.7001662, 52.165458599],
  [-63.7033366, 52.154151799],
  [-63.6966915, 52.136508699],
  [-63.6905727, 52.129832899],
  [-63.6918508, 52.122699399],
  [-63.6970963, 52.114074299],
  [-63.7020065, 52.087615499],
  [-63.6997979, 52.083335399],
  [-63.6923515, 52.078528699],
  [-63.6889257, 52.082064999],
  [-63.6888875, 52.086184899],
  [-63.6809756, 52.084822899],
  [-63.6774966, 52.083098699],
  [-63.6702753, 52.071879499],
  [-63.6605935, 52.066912699],
  [-63.6594721, 52.057566599],
  [-63.6480012, 52.048762199],
  [-63.64638, 52.042402999],
  [-63.6553906, 52.043730599],
  [-63.6569852, 52.040480499],
  [-63.6599837, 52.040846699],
  [-63.6719695, 52.051466999],
  [-63.6781723, 52.054709599],
  [-63.6845049, 52.054972799],
  [-63.6892621, 52.049567399],
  [-63.6999435, 52.048552799],
  [-63.7063485, 52.049731599],
  [-63.7257923, 52.060573099],
  [-63.7443322, 52.064803799],
  [-63.7716916, 52.081340799],
  [-63.7793097, 52.083618299],
  [-63.7841469, 52.083698399],
  [-63.7874125, 52.078331099],
  [-63.8102897, 52.085392299],
  [-63.8239772, 52.084732499],
  [-63.8322477, 52.081493899],
  [-63.8338882, 52.078926599],
  [-63.8386569, 52.069393599],
  [-63.8401409, 52.064999099],
  [-63.8309247, 52.048130299],
  [-63.8274878, 52.038170099],
  [-63.8276901, 52.031757499],
  [-63.8251838, 52.028404399],
  [-63.8160587, 52.034645199],
  [-63.812263, 52.033618999],
  [-63.8089785, 52.029834799],
  [-63.8092419, 52.024105099],
  [-63.8027492, 52.017444599],
  [-63.8017499, 52.009925699],
  [-63.8080711, 52.001262599],
  [-63.8081114, 51.997848699],
  [-63.7172, 51.997776399],
  [-63.5330043, 51.997284599],
  [-63.3748717, 51.997457799],
  [-63.1026458, 51.997410399],
  [-62.8441823, 51.996957099],
  [-62.6391307, 51.997287399],
  [-62.2712588, 51.997328399],
  [-62.0358236, 51.996820499],
  [-61.8065217, 51.995808699],
  [-61.638541, 51.996332699],
  [-61.4682058, 51.996584499],
  [-61.3001062, 51.996556799],
  [-61.1298861, 51.996249399],
  [-60.9616752, 51.996902899],
  [-60.7868139, 51.997291099],
  [-60.6158787, 51.997049599],
  [-60.4416921, 51.996511899],
  [-60.2185869, 51.997199899],
  [-59.8654295, 51.997668499],
  [-59.5784872, 51.997611399],
  [-59.3881448, 51.997279699],
  [-59.2106155, 51.997281599],
  [-59.0344095, 51.996981099],
  [-58.8206749, 51.997552099],
  [-58.6146652, 51.997665699],
  [-58.3229264, 51.997069799],
  [-58.1290771, 51.996888599],
  [-57.964735, 51.996449299],
  [-57.810372, 51.997158299],
  [-57.6582132, 51.997630799],
  [-57.4901498, 51.997891599],
  [-57.3193817, 51.997875799],
  [-57.2553291, 51.998108999],
  [-57.2551534, 51.998480999],
  [-57.2537366, 51.998114299],
  [-57.1077594, 51.998494799],
  [-57.1075495, 51.923450199],
  [-57.1068575, 51.839674499],
  [-57.1071318, 51.839062899],
  [-57.106296, 51.789803199],
  [-57.106313, 51.747751299],
  [-57.1054864, 51.656451399],
  [-57.1058591, 51.590975499],
  [-57.10788, 51.590947899],
  [-57.1077756, 51.543427899],
  [-57.1080849, 51.520529999],
  [-57.1074572, 51.520529399],
  [-57.1087638, 51.377135799],
  [-57.3040495, 51.138838999],
  [-57.7361373, 50.998606899],
  [-58.5015291, 50.270919099],
  [-60.4768198, 48.7813802],
  [-60.45868, 48.5285048],
  [-62.9029945, 48.5149234],
  [-63.7519074, 48.5108858],
  [-63.8638025, 48.6671273],
  [-64.0862123, 48.7493091],
  [-64.1975649, 49.3371535],
  [-64.4126832, 49.4359649],
  [-64.6512454, 49.5297118],
  [-64.7840664, 49.573766],
  [-64.9991962, 49.6317991],
  [-65.0707799, 49.6464685],
  [-65.1928245, 49.6502903],
  [-65.3629273, 49.7136403],
  [-65.5853033, 49.7154402],
  [-65.7001159, 49.7188035],
  [-65.7571653, 49.7186618],
  [-65.8185992, 49.7170238],
  [-65.8801974, 49.7140517],
  [-65.9436114, 49.7093604],
  [-66.0000147, 49.7040485],
  [-66.0784907, 49.6926989],
  [-66.1575492, 49.6786465],
  [-66.2476645, 49.6580255],
  [-66.2957067, 49.6452296],
  [-66.3770014, 49.6190925],
  [-66.4706501, 49.5825882],
  [-66.4992642, 49.5699979],
  [-66.563434, 49.5391654],
  [-66.9992862, 49.2792181],
  [-67.1533414, 49.189062],
  [-67.2050698, 49.1686363],
  [-67.2411758, 49.1567568],
  [-67.2938852, 49.1440553],
  [-67.3332106, 49.1349754],
  [-67.4254559, 49.1182805],
  [-67.4557879, 49.1143406],
  [-67.4968638, 49.1028405],
  [-67.577792, 49.0825326],
  [-67.6310261, 49.066716],
  [-67.699465, 49.0439692],
  [-67.8135816, 49.0042538],
  [-67.9067066, 48.9729191],
  [-68.0190341, 48.9335549],
  [-68.1388552, 48.8932348],
  [-68.242536, 48.8544451],
  [-68.3820243, 48.7965309],
  [-68.5310961, 48.7250152],
  [-68.6437698, 48.6638403],
  [-68.8829054, 48.5257012],
  [-68.9896781, 48.4597183],
  [-69.122888, 48.3635498],
  [-69.2055846, 48.3044214],
  [-69.2387183, 48.2810549],
  [-69.3889621, 48.1674596],
  [-69.4535811, 48.1194071],
  [-69.5413584, 48.053092],
  [-69.6579507, 48.1120049],
  [-69.6680341, 48.1163656],
  [-69.6766734, 48.1195045],
  [-69.6864515, 48.1222824],
  [-69.6943963, 48.1250879],
  [-69.708427, 48.129559],
  [-69.7445951, 48.1372055],
  [-69.7677026, 48.1401638],
  [-69.7859599, 48.1450383],
  [-69.7904778, 48.1450716],
  [-69.7960568, 48.1439834],
  [-69.8015928, 48.1432675],
  [-69.8060159, 48.1432297],
  [-69.823587, 48.1468469],
  [-69.8332858, 48.149925],
  [-69.8435641, 48.1541481],
  [-69.8564816, 48.1625933],
  [-69.8655796, 48.1719529],
  [-69.8687983, 48.177648],
  [-69.8713941, 48.185746],
  [-69.8714578, 48.1877012],
  [-69.8723745, 48.1900622],
  [-69.8736801, 48.1924234],
  [-69.8769302, 48.1968402],
  [-69.8790691, 48.1988402],
  [-69.8821248, 48.203118],
  [-69.883847, 48.206257],
  [-69.8861247, 48.2125626],
  [-69.8868746, 48.2197016],
  [-69.8872635, 48.220646],
  [-69.8891802, 48.2232017],
  [-69.8904858, 48.2243128],
  [-69.8936248, 48.225785],
  [-69.898125, 48.2274795],
  [-69.9029308, 48.2288963],
  [-69.9076532, 48.2299797],
  [-69.9203759, 48.2319798],
  [-69.929543, 48.233091],
  [-69.944016, 48.2336744],
  [-69.9491551, 48.2341189],
  [-69.953822, 48.2347578],
  [-69.9584611, 48.2358411],
  [-69.9624612, 48.2375634],
  [-69.9681557, 48.2417579],
  [-69.972878, 48.2446191],
  [-69.9776282, 48.2467858],
  [-69.9820727, 48.2482581],
  [-69.9894897, 48.250147],
  [-70.0016568, 48.2527027],
  [-70.0117685, 48.2555916],
  [-70.0164632, 48.2565361],
  [-70.0249915, 48.2569528],
  [-70.031992, 48.2567861],
  [-70.0489375, 48.2552028],
  [-70.0581325, 48.2548139],
  [-70.0634663, 48.2542306],
  [-70.0778758, 48.2518562],
];

const boundaries2 = [
  [-66.2594048, 54.999749699],
  [-63.5587502, 55.000050799],
  [-63.5575977, 54.999873799],
  [-63.5575345, 54.989937899],
  [-63.576303, 54.988104399],
  [-63.5731611, 54.978795299],
  [-63.5869179, 54.973751499],
  [-63.6110058, 54.977044299],
  [-63.6125394, 54.969018899],
  [-63.5975246, 54.959398099],
  [-63.5990604, 54.951373199],
  [-63.6113432, 54.948863799],
  [-63.5960394, 54.945771499],
  [-63.6020065, 54.939328899],
  [-63.5900249, 54.935309199],
  [-63.5778733, 54.929955499],
  [-63.5835934, 54.923813399],
  [-63.6045297, 54.926251499],
  [-63.6122984, 54.919936899],
  [-63.6044279, 54.907865699],
  [-63.5914437, 54.896592099],
  [-63.6195865, 54.899540599],
  [-63.6307236, 54.909200999],
  [-63.6457838, 54.905618199],
  [-63.6634529, 54.910455099],
  [-63.6612828, 54.899952499],
  [-63.6848621, 54.904568599],
  [-63.693805, 54.909948599],
  [-63.6964187, 54.925339199],
  [-63.715995, 54.925548199],
  [-63.7259608, 54.930468699],
  [-63.7385037, 54.935356699],
  [-63.7519003, 54.942308799],
  [-63.7843137, 54.947832399],
  [-63.7917108, 54.955894499],
  [-63.8205183, 54.947958099],
  [-63.8298169, 54.942052299],
  [-63.8240991, 54.934267499],
  [-63.7995727, 54.926133499],
  [-63.8126999, 54.919438499],
  [-63.8377335, 54.914512799],
  [-63.8289655, 54.901134099],
  [-63.8462105, 54.894825899],
  [-63.8380232, 54.883071999],
  [-63.852251, 54.885106699],
  [-63.8526581, 54.876056099],
  [-63.8421701, 54.871006299],
  [-63.8490321, 54.862611299],
  [-63.8278626, 54.847323599],
  [-63.8181462, 54.828917999],
  [-63.8171615, 54.816921899],
  [-63.8295707, 54.812607499],
  [-63.8481718, 54.812360399],
  [-63.8479639, 54.807174199],
  [-63.8690052, 54.807039099],
  [-63.871818, 54.800477599],
  [-63.9036644, 54.801077999],
  [-63.8908399, 54.788801899],
  [-63.9100696, 54.788535499],
  [-63.9240093, 54.778407399],
  [-63.8897412, 54.768358799],
  [-63.8958107, 54.760121699],
  [-63.8844651, 54.758498999],
  [-63.8797083, 54.755006399],
  [-63.8606504, 54.752745299],
  [-63.8328352, 54.736367099],
  [-63.8029597, 54.725642099],
  [-63.8038947, 54.716736499],
  [-63.7689473, 54.699843799],
  [-63.7597105, 54.685584199],
  [-63.7680587, 54.683107299],
  [-63.7630996, 54.673684799],
  [-63.744036, 54.669922699],
  [-63.7238489, 54.659798599],
  [-63.7253447, 54.651777599],
  [-63.7126254, 54.624950799],
  [-63.7276534, 54.627110699],
  [-63.7325879, 54.623118899],
  [-63.7427579, 54.626399299],
  [-63.7522227, 54.631915199],
  [-63.7645127, 54.637394599],
  [-63.7804551, 54.640794899],
  [-63.8016715, 54.649038599],
  [-63.8133772, 54.650785799],
  [-63.817138, 54.645822499],
  [-63.8196481, 54.640678999],
  [-63.8254782, 54.642436599],
  [-63.8343513, 54.641139599],
  [-63.8352043, 54.637000499],
  [-63.8307491, 54.632866599],
  [-63.8345833, 54.629867299],
  [-63.8496369, 54.627107099],
  [-63.8634516, 54.622632899],
  [-63.8700734, 54.623351999],
  [-63.8773761, 54.618985299],
  [-63.8734408, 54.615728599],
  [-63.8775077, 54.613028499],
  [-63.8773712, 54.612910299],
  [-63.8834552, 54.612630199],
  [-63.8928428, 54.610338599],
  [-63.8997578, 54.605591099],
  [-63.8978634, 54.600703799],
  [-63.9018377, 54.601303699],
  [-63.9114392, 54.604248699],
  [-63.9234715, 54.605913799],
  [-63.9343355, 54.606611499],
  [-63.9423978, 54.610427499],
  [-63.9543171, 54.614646099],
  [-63.9664818, 54.619252999],
  [-63.9744225, 54.620120199],
  [-63.9788297, 54.615010899],
  [-63.9848706, 54.608567099],
  [-63.9920448, 54.604857999],
  [-64.0000324, 54.604281299],
  [-64.0040003, 54.602125799],
  [-64.0086328, 54.602253399],
  [-64.0219328, 54.604412999],
  [-64.0239649, 54.605155999],
  [-64.0290452, 54.604914199],
  [-64.0351022, 54.607030799],
  [-64.0434536, 54.603628799],
  [-64.0476177, 54.600879099],
  [-64.0542772, 54.600908299],
  [-64.0719448, 54.617016199],
  [-64.079947, 54.621609599],
  [-64.0861067, 54.622955099],
  [-64.0932126, 54.617929799],
  [-64.0983805, 54.612541399],
  [-64.1073289, 54.613186599],
  [-64.1085049, 54.618999499],
  [-64.1117864, 54.626351699],
  [-64.1130252, 54.633474199],
  [-64.10897, 54.638518399],
  [-64.105174, 54.646703599],
  [-64.1027652, 54.655587699],
  [-64.1061548, 54.660383399],
  [-64.1183983, 54.663531599],
  [-64.1290095, 54.665590599],
  [-64.1418498, 54.666957399],
  [-64.1456095, 54.669911299],
  [-64.1427286, 54.673496399],
  [-64.1453432, 54.676141099],
  [-64.1585755, 54.680841899],
  [-64.1647413, 54.686705199],
  [-64.171272, 54.690727399],
  [-64.1806889, 54.693587599],
  [-64.1781881, 54.695660299],
  [-64.1759697, 54.701201799],
  [-64.1806691, 54.704729199],
  [-64.1877601, 54.710838699],
  [-64.1835036, 54.714054699],
  [-64.1786645, 54.716952499],
  [-64.1776349, 54.721360599],
  [-64.1849141, 54.722027399],
  [-64.1866172, 54.724424399],
  [-64.1901891, 54.727970199],
  [-64.1965098, 54.729831999],
  [-64.2037838, 54.728072599],
  [-64.2153291, 54.723422999],
  [-64.2261093, 54.719374999],
  [-64.2342815, 54.717532999],
  [-64.2378076, 54.720094899],
  [-64.2367354, 54.723521299],
  [-64.2342284, 54.727692999],
  [-64.2389438, 54.729120599],
  [-64.2499398, 54.729326799],
  [-64.2644202, 54.729013399],
  [-64.2755784, 54.727968899],
  [-64.288195, 54.731027299],
  [-64.2957894, 54.735483099],
  [-64.3007481, 54.741492199],
  [-64.2984904, 54.748152099],
  [-64.2945278, 54.753273799],
  [-64.2946817, 54.753358999],
  [-64.3066249, 54.759266799],
  [-64.3227672, 54.764487399],
  [-64.3303279, 54.767411899],
  [-64.3361398, 54.769265899],
  [-64.3439336, 54.772552299],
  [-64.3531825, 54.775322699],
  [-64.3626311, 54.777844099],
  [-64.3757721, 54.779828999],
  [-64.3790622, 54.779401499],
  [-64.3841082, 54.780162999],
  [-64.385838, 54.781791099],
  [-64.391606, 54.788229599],
  [-64.3974746, 54.790492999],
  [-64.4093223, 54.789563299],
  [-64.4217831, 54.787862299],
  [-64.4352452, 54.787943099],
  [-64.4469316, 54.789999999],
  [-64.4553781, 54.790535399],
  [-64.4563071, 54.790275499],
  [-64.4575162, 54.790670899],
  [-64.4630743, 54.791022799],
  [-64.4782721, 54.792725199],
  [-64.4852881, 54.793719699],
  [-64.4927843, 54.791061599],
  [-64.4941881, 54.789409899],
  [-64.4925027, 54.787347399],
  [-64.4798334, 54.784159299],
  [-64.4805434, 54.777933399],
  [-64.4733209, 54.773566299],
  [-64.4714931, 54.770281699],
  [-64.4839051, 54.772178399],
  [-64.4854758, 54.770962799],
  [-64.4751696, 54.754231199],
  [-64.474805, 54.752151699],
  [-64.4981302, 54.751466699],
  [-64.5141886, 54.751297799],
  [-64.5198159, 54.753606599],
  [-64.524361, 54.757026999],
  [-64.5289556, 54.759877099],
  [-64.5320722, 54.761286099],
  [-64.5341871, 54.761792699],
  [-64.5548163, 54.744868899],
  [-64.5470643, 54.741596199],
  [-64.5364976, 54.738911899],
  [-64.5289569, 54.737495099],
  [-64.5306912, 54.733525399],
  [-64.5362604, 54.729290699],
  [-64.5470354, 54.723056199],
  [-64.5505356, 54.719904899],
  [-64.5526337, 54.715216499],
  [-64.5568825, 54.713662299],
  [-64.5620695, 54.715503199],
  [-64.5694012, 54.718960699],
  [-64.5795643, 54.723167099],
  [-64.5862021, 54.725568699],
  [-64.5976319, 54.728719499],
  [-64.6003388, 54.728909399],
  [-64.611392, 54.726421599],
  [-64.615942, 54.724768999],
  [-64.6196813, 54.722826799],
  [-64.6228994, 54.721873999],
  [-64.6263087, 54.722324199],
  [-64.6409475, 54.726641999],
  [-64.6502602, 54.730597499],
  [-64.6601193, 54.732807499],
  [-64.6703156, 54.734681599],
  [-64.6799684, 54.735712599],
  [-64.6847454, 54.733478299],
  [-64.6878003, 54.726016299],
  [-64.6993635, 54.723590899],
  [-64.7111211, 54.722962199],
  [-64.7223175, 54.723814299],
  [-64.7309538, 54.725621199],
  [-64.7320752, 54.729058999],
  [-64.7348946, 54.735969699],
  [-64.7637158, 54.729229399],
  [-64.7696721, 54.730095599],
  [-64.7780869, 54.733468799],
  [-64.785386, 54.739806199],
  [-64.7891531, 54.744750599],
  [-64.7968235, 54.752928999],
  [-64.8010293, 54.755776799],
  [-64.7999991, 54.756321199],
  [-64.7923379, 54.754545399],
  [-64.7851645, 54.753943999],
  [-64.7820842, 54.763259799],
  [-64.7720272, 54.762483499],
  [-64.7663415, 54.764599199],
  [-64.7596077, 54.765741399],
  [-64.7523278, 54.767321799],
  [-64.7653191, 54.774107099],
  [-64.7725203, 54.782629999],
  [-64.7762452, 54.788145499],
  [-64.7726498, 54.789646799],
  [-64.7683551, 54.790499199],
  [-64.7684798, 54.793674299],
  [-64.7697739, 54.797156099],
  [-64.7595132, 54.799702999],
  [-64.7545812, 54.803414499],
  [-64.7503019, 54.808344899],
  [-64.748739, 54.810845099],
  [-64.7478748, 54.813994299],
  [-64.7521151, 54.818598699],
  [-64.7584709, 54.826667399],
  [-64.7611143, 54.829528099],
  [-64.7714878, 54.836715099],
  [-64.7781841, 54.840342099],
  [-64.7838724, 54.842823099],
  [-64.7865355, 54.843079299],
  [-64.7891101, 54.841989799],
  [-64.7905886, 54.839621799],
  [-64.7910027, 54.837636399],
  [-64.7905364, 54.834098199],
  [-64.7928063, 54.831937399],
  [-64.7957262, 54.831379199],
  [-64.8007068, 54.832572199],
  [-64.8031439, 54.835303299],
  [-64.806507, 54.840259799],
  [-64.8126089, 54.846593099],
  [-64.8158677, 54.849047899],
  [-64.821593, 54.847958599],
  [-64.8247659, 54.846227699],
  [-64.8255735, 54.841086499],
  [-64.827189, 54.836970899],
  [-64.8301053, 54.836336699],
  [-64.8341962, 54.837289099],
  [-64.8380957, 54.840285499],
  [-64.8372862, 54.844146099],
  [-64.839298, 54.845951199],
  [-64.8459962, 54.844093399],
  [-64.8568206, 54.840054999],
  [-64.8614903, 54.841040799],
  [-64.864324, 54.842826999],
  [-64.8665962, 54.844815599],
  [-64.8654936, 54.846738099],
  [-64.8607198, 54.846656099],
  [-64.8584015, 54.848843099],
  [-64.861692, 54.854936199],
  [-64.8558152, 54.861662799],
  [-64.8514872, 54.868212099],
  [-64.8469263, 54.873770299],
  [-64.8484309, 54.876273199],
  [-64.8528819, 54.880463399],
  [-64.8561247, 54.882906099],
  [-64.8623976, 54.886358699],
  [-64.8669926, 54.889758999],
  [-64.871709, 54.892639799],
  [-64.8754002, 54.893050399],
  [-64.8830748, 54.893010999],
  [-64.8861389, 54.893368099],
  [-64.8896666, 54.894231099],
  [-64.8928796, 54.895617899],
  [-64.8957663, 54.898454999],
  [-64.9025705, 54.901640399],
  [-64.9047359, 54.905576299],
  [-64.9064804, 54.910565799],
  [-64.9123346, 54.911854799],
  [-64.9150389, 54.920005799],
  [-64.9269133, 54.921263699],
  [-64.9539194, 54.932227899],
  [-64.9719006, 54.937395599],
  [-64.9808749, 54.936046599],
  [-64.9848828, 54.935071599],
  [-64.9894717, 54.933671899],
  [-64.9933342, 54.933521699],
  [-65.0016706, 54.931797799],
  [-65.0095606, 54.930964199],
  [-65.0125656, 54.931722499],
  [-65.0177214, 54.932623999],
  [-65.0280336, 54.937013699],
  [-65.0360836, 54.940918899],
  [-65.0402428, 54.943716799],
  [-65.0415305, 54.946583999],
  [-65.0433884, 54.952335599],
  [-65.0481511, 54.955491399],
  [-65.0527181, 54.955960499],
  [-65.0613112, 54.954426999],
  [-65.0706017, 54.953579599],
  [-65.0717502, 54.955497599],
  [-65.0742506, 54.961109099],
  [-65.0763526, 54.968138199],
  [-65.0794342, 54.968932399],
  [-65.0858147, 54.969562799],
  [-65.0917791, 54.968463999],
  [-65.1050047, 54.964370099],
  [-65.1264493, 54.961477999],
  [-65.1223433, 54.947046599],
  [-65.1228547, 54.941402699],
  [-65.128266, 54.933980699],
  [-65.1345173, 54.928193699],
  [-65.1395562, 54.928274699],
  [-65.1486359, 54.929496099],
  [-65.1728929, 54.933604299],
  [-65.1803042, 54.930849099],
  [-65.1778076, 54.924536099],
  [-65.1736334, 54.917893099],
  [-65.1736425, 54.914249299],
  [-65.1787822, 54.905341299],
  [-65.185298, 54.899420499],
  [-65.1947568, 54.886965399],
  [-65.2006137, 54.881888699],
  [-65.2016585, 54.880537099],
  [-65.2022077, 54.873691799],
  [-65.2010405, 54.870658099],
  [-65.2063371, 54.867665599],
  [-65.2056381, 54.866979699],
  [-65.1995871, 54.863905699],
  [-65.1994599, 54.862252999],
  [-65.2006235, 54.857793399],
  [-65.2054356, 54.851335899],
  [-65.2096231, 54.847957799],
  [-65.2168496, 54.846778099],
  [-65.2270008, 54.849082499],
  [-65.2424921, 54.849922499],
  [-65.2671948, 54.848365999],
  [-65.2827332, 54.844355299],
  [-65.2910771, 54.840118899],
  [-65.2915933, 54.833977999],
  [-65.2879965, 54.825402499],
  [-65.2887317, 54.822284199],
  [-65.2903421, 54.820670399],
  [-65.2929637, 54.818942399],
  [-65.2963128, 54.817583199],
  [-65.3008484, 54.816924599],
  [-65.3048672, 54.819674199],
  [-65.3094943, 54.827333999],
  [-65.3163685, 54.835264999],
  [-65.3202025, 54.842821699],
  [-65.3256953, 54.848677999],
  [-65.3357907, 54.851967799],
  [-65.3412198, 54.848312299],
  [-65.3388528, 54.845871899],
  [-65.3384048, 54.841658099],
  [-65.34015, 54.835272499],
  [-65.3430912, 54.829041099],
  [-65.3453779, 54.825375199],
  [-65.3511007, 54.821762399],
  [-65.3513091, 54.821243699],
  [-65.3558397, 54.818761399],
  [-65.3619251, 54.820418099],
  [-65.3683621, 54.825501199],
  [-65.3732801, 54.827768699],
  [-65.3785874, 54.829528499],
  [-65.384574, 54.829944699],
  [-65.3916832, 54.825692799],
  [-65.4006031, 54.819072199],
  [-65.4075489, 54.819833299],
  [-65.4116921, 54.823114099],
  [-65.41367, 54.829683899],
  [-65.412422, 54.838868499],
  [-65.4113764, 54.841836999],
  [-65.4563357, 54.849242299],
  [-65.4615437, 54.846322299],
  [-65.4638431, 54.841247799],
  [-65.4692482, 54.838984399],
  [-65.4803711, 54.838174499],
  [-65.4872946, 54.834376799],
  [-65.4892208, 54.827490399],
  [-65.4866552, 54.820068699],
  [-65.4737365, 54.807184099],
  [-65.4669132, 54.801910899],
  [-65.4560308, 54.796916799],
  [-65.4485926, 54.794516499],
  [-65.4346198, 54.784183499],
  [-65.4339241, 54.770665499],
  [-65.4310605, 54.758615799],
  [-65.4291144, 54.748113899],
  [-65.4316867, 54.746345199],
  [-65.4401763, 54.746895299],
  [-65.4481621, 54.743981499],
  [-65.4506169, 54.741677499],
  [-65.4535479, 54.737373099],
  [-65.4522561, 54.732193499],
  [-65.4555303, 54.728583199],
  [-65.4633573, 54.727245799],
  [-65.4714217, 54.727888099],
  [-65.487353, 54.728514099],
  [-65.4947217, 54.731906499],
  [-65.4977571, 54.734800799],
  [-65.5039637, 54.736279999],
  [-65.5135984, 54.736005199],
  [-65.5290223, 54.733162399],
  [-65.5356995, 54.730114099],
  [-65.5487823, 54.729198699],
  [-65.5573875, 54.730274499],
  [-65.5624222, 54.733067799],
  [-65.5620705, 54.739743099],
  [-65.5925537, 54.743035999],
  [-65.5971422, 54.739051299],
  [-65.6016084, 54.737761099],
  [-65.621279, 54.738506399],
  [-65.6275167, 54.749169099],
  [-65.6320649, 54.754458099],
  [-65.6469845, 54.753186799],
  [-65.6509547, 54.747934599],
  [-65.6509607, 54.740771899],
  [-65.6485338, 54.735213599],
  [-65.6512043, 54.733063899],
  [-65.6563698, 54.727610399],
  [-65.6696698, 54.718766499],
  [-65.6901153, 54.711526999],
  [-65.6931728, 54.708163999],
  [-65.7062097, 54.717912399],
  [-65.713273, 54.724032399],
  [-65.7204701, 54.731597199],
  [-65.7195576, 54.732577199],
  [-65.7159861, 54.735004499],
  [-65.7135067, 54.739220199],
  [-65.7135928, 54.740956599],
  [-65.7246329, 54.750599099],
  [-65.7324992, 54.759137099],
  [-65.7330608, 54.763716099],
  [-65.7429752, 54.769541099],
  [-65.7466556, 54.775308199],
  [-65.7523647, 54.782089699],
  [-65.7570246, 54.786791499],
  [-65.7656999, 54.789174599],
  [-65.7719952, 54.796807099],
  [-65.7774252, 54.802809599],
  [-65.7870382, 54.809055199],
  [-65.8190604, 54.824043799],
  [-65.8304494, 54.830727699],
  [-65.8383149, 54.836527499],
  [-65.8506459, 54.850801299],
  [-65.8530718, 54.855943599],
  [-65.8485509, 54.858240099],
  [-65.8446959, 54.861509499],
  [-65.8363866, 54.874652699],
  [-65.8366473, 54.881976099],
  [-65.8447708, 54.885821499],
  [-65.8572339, 54.892967699],
  [-65.8554275, 54.896226199],
  [-65.8440871, 54.902108399],
  [-65.8386204, 54.907956699],
  [-65.8392708, 54.914286999],
  [-65.8498454, 54.927210899],
  [-65.8599352, 54.925898499],
  [-65.8712126, 54.923470399],
  [-65.8880077, 54.925339899],
  [-65.9135755, 54.935336099],
  [-65.9247, 54.940060699],
  [-65.9283392, 54.939625699],
  [-65.9304738, 54.940930299],
  [-65.9389897, 54.936217099],
  [-65.9460017, 54.934333199],
  [-65.9566013, 54.936765699],
  [-65.9651306, 54.929818099],
  [-65.9765772, 54.931350999],
  [-65.9865951, 54.931520799],
  [-65.9966155, 54.930985299],
  [-66.0021969, 54.933577999],
  [-66.0233959, 54.941953299],
  [-66.031222, 54.942481699],
  [-66.0424076, 54.940618999],
  [-66.0489955, 54.937416599],
  [-66.0463037, 54.932823999],
  [-66.04066, 54.928205199],
  [-66.0364311, 54.923332199],
  [-66.0372587, 54.920818699],
  [-66.0408256, 54.919084299],
  [-66.0484349, 54.920364299],
  [-66.0644904, 54.929068399],
  [-66.0745395, 54.939505299],
  [-66.0877388, 54.949711499],
  [-66.0991533, 54.954341199],
  [-66.106708, 54.959224599],
  [-66.1111193, 54.963633799],
  [-66.1166717, 54.967921399],
  [-66.1214649, 54.970411199],
  [-66.1370094, 54.977259499],
  [-66.1435813, 54.982298799],
  [-66.1525612, 54.985514899],
  [-66.1615837, 54.987734099],
  [-66.1607484, 54.978976699],
  [-66.1627702, 54.974845899],
  [-66.1679833, 54.973009299],
  [-66.1778909, 54.975445399],
  [-66.1886083, 54.980795299],
  [-66.196952, 54.981335899],
  [-66.2101254, 54.979138699],
  [-66.214883, 54.981211499],
  [-66.2173213, 54.984525299],
  [-66.235532, 54.991806999],
  [-66.2395402, 54.977906399],
  [-66.2422735, 54.974453599],
  [-66.246416, 54.975468999],
  [-66.2477991, 54.978321799],
  [-66.2477401, 54.985245599],
  [-66.2505454, 54.995384899],
  [-66.2594048, 54.999749699],
];

const boundaries3 = [
  [-67.2192425, 55.000030499],
  [-66.7659904, 55.000016899],
  [-66.7602267, 54.995691899],
  [-66.7402285, 54.985115399],
  [-66.7349272, 54.978059499],
  [-66.7355405, 54.975727499],
  [-66.7397483, 54.974877399],
  [-66.7422237, 54.969480199],
  [-66.7380437, 54.966824899],
  [-66.7132262, 54.955495399],
  [-66.7148853, 54.953207999],
  [-66.7112227, 54.947369299],
  [-66.6945297, 54.934264499],
  [-66.6865683, 54.928422799],
  [-66.6814082, 54.923667799],
  [-66.6826898, 54.919430299],
  [-66.6865141, 54.916631699],
  [-66.6880729, 54.913494299],
  [-66.6881549, 54.910414599],
  [-66.6872436, 54.907715699],
  [-66.6890342, 54.902774699],
  [-66.6896536, 54.897965099],
  [-66.68447, 54.894236999],
  [-66.6772514, 54.890844599],
  [-66.6696293, 54.886527599],
  [-66.663612, 54.881976899],
  [-66.6589719, 54.876517899],
  [-66.6562406, 54.870899199],
  [-66.6545874, 54.861820899],
  [-66.6511419, 54.857682599],
  [-66.6531405, 54.851965799],
  [-66.6517229, 54.849970299],
  [-66.6426022, 54.849213499],
  [-66.6376982, 54.849061799],
  [-66.6332843, 54.846754899],
  [-66.6334284, 54.841622999],
  [-66.6320002, 54.835697699],
  [-66.6232148, 54.827579199],
  [-66.6183322, 54.822470799],
  [-66.6076679, 54.815961699],
  [-66.6026511, 54.813506299],
  [-66.6034957, 54.811850699],
  [-66.6070892, 54.808380799],
  [-66.6096219, 54.803413899],
  [-66.6134595, 54.802070099],
  [-66.6256776, 54.799035599],
  [-66.6198842, 54.787299699],
  [-66.6240352, 54.789958399],
  [-66.6335355, 54.794118399],
  [-66.6382266, 54.795047199],
  [-66.6432584, 54.792545399],
  [-66.645983, 54.789278699],
  [-66.647264, 54.785042999],
  [-66.6443855, 54.780627199],
  [-66.6446192, 54.778824199],
  [-66.6541676, 54.774523199],
  [-66.6594284, 54.770217799],
  [-66.6683193, 54.774227499],
  [-66.6900805, 54.787129299],
  [-66.70714, 54.797650099],
  [-66.7244895, 54.806790399],
  [-66.7328882, 54.811498799],
  [-66.7373806, 54.813200399],
  [-66.7399972, 54.809080899],
  [-66.7404556, 54.805474199],
  [-66.7344947, 54.798875599],
  [-66.7236676, 54.788624899],
  [-66.7245587, 54.784915899],
  [-66.7295832, 54.782410199],
  [-66.732122, 54.778892299],
  [-66.7305324, 54.766738499],
  [-66.7208533, 54.759860299],
  [-66.7158367, 54.757410399],
  [-66.6997442, 54.754969899],
  [-66.6862448, 54.749435499],
  [-66.6795129, 54.744915899],
  [-66.6749926, 54.737832299],
  [-66.6746445, 54.734856199],
  [-66.6645813, 54.727049799],
  [-66.666515, 54.723386699],
  [-66.66587, 54.717859899],
  [-66.6687849, 54.715671099],
  [-66.6670092, 54.713164599],
  [-66.6672818, 54.711048099],
  [-66.669113, 54.709672699],
  [-66.6724898, 54.708186699],
  [-66.6752961, 54.709229999],
  [-66.682388, 54.715068999],
  [-66.691755, 54.721821199],
  [-66.6963122, 54.725954199],
  [-66.6941783, 54.730651499],
  [-66.695587, 54.733053099],
  [-66.6991839, 54.734165099],
  [-66.7038556, 54.732626999],
  [-66.7093148, 54.731157999],
  [-66.7141763, 54.731968799],
  [-66.7177877, 54.733932099],
  [-66.726185, 54.739717499],
  [-66.7410846, 54.748403399],
  [-66.7442149, 54.750006399],
  [-66.7526307, 54.750002999],
  [-66.753834, 54.745538699],
  [-66.7584753, 54.748111699],
  [-66.7688026, 54.756174499],
  [-66.770235, 54.762118499],
  [-66.7732377, 54.765994499],
  [-66.7876276, 54.769819999],
  [-66.7999401, 54.776445599],
  [-66.8127175, 54.779397199],
  [-66.8283774, 54.788975599],
  [-66.8344478, 54.791765599],
  [-66.8409056, 54.797374899],
  [-66.8429268, 54.799481199],
  [-66.8459036, 54.800467899],
  [-66.8709399, 54.812862799],
  [-66.8756633, 54.816009599],
  [-66.8813254, 54.813776299],
  [-66.8873522, 54.810764399],
  [-66.89573, 54.809216499],
  [-66.9025267, 54.809873199],
  [-66.9112657, 54.809379199],
  [-66.9134707, 54.804740499],
  [-66.9185056, 54.802392499],
  [-66.9270452, 54.802172999],
  [-66.9311109, 54.805080999],
  [-66.9394293, 54.816105699],
  [-66.9478467, 54.822310499],
  [-66.9605135, 54.827267699],
  [-66.9770058, 54.833133799],
  [-66.9885274, 54.838136099],
  [-66.9969874, 54.842730899],
  [-67.013013, 54.853963399],
  [-67.0213954, 54.857891099],
  [-67.033043, 54.861947799],
  [-67.0398547, 54.868081499],
  [-67.0413534, 54.881665299],
  [-67.0565105, 54.894937699],
  [-67.0661467, 54.899478699],
  [-67.0764353, 54.905463199],
  [-67.0850932, 54.911382799],
  [-67.1010039, 54.921273399],
  [-67.1183618, 54.931501699],
  [-67.1300599, 54.941035099],
  [-67.1392552, 54.950943599],
  [-67.1571846, 54.956458299],
  [-67.1666967, 54.965146999],
  [-67.1734625, 54.970608499],
  [-67.1877855, 54.980425299],
  [-67.2092282, 54.984173499],
  [-67.2152192, 54.990737899],
  [-67.2181518, 54.995294399],
  [-67.2192425, 55.000030499],
];

let polygons = [[], [], []];
boundaries1.map((boundary) =>
  polygons[0].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries2.map((boundary) =>
  polygons[1].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries3.map((boundary) =>
  polygons[2].push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons