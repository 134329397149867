import mountainIcon from "../../assets/images/ico-mountain.svg";
import iconClose from "../../assets/images/icon-close.svg";

import { availableLanguages } from "../../configs/constants";

export default function DonationModal({ region, currentLanguage, onCancled }) {
  return (
    <div className="info-modal donate">
      <div id="mountain-description">
        <div className="actions">
          <img
            src={iconClose}
            onClick={() => onCancled(null)}
            alt="Close modal"
          />
        </div>

        <div id="container-mountain">
          <div id="container-mountain-description">
            <div>
              <img
                id="mountain-icon"
                src={mountainIcon}
                width="45px"
                height="30px"
                alt={region}
              />

              <h3 className="mountain-name-display">{region}</h3>
            </div>

            <p>
              {currentLanguage === availableLanguages.FR.abbreviation
                ? `Il n'y a pas de défi dans cette région cette année.
              Vous pouvez tout de même faire un don pour encourager 
              le déploiement du programme Chaîne de vie dans cette région`
                : `There is no challenge in this region this year. You can still
              make a donation to help deployment of the Chain of Life program 
              in this region.`}
            </p>
          </div>
        </div>

        <div>
          <a
            target="_parent"
            className="btn"
            href={`${process.env.REACT_APP_API_SERVER_BASE_URL}/${
              currentLanguage === availableLanguages.FR.abbreviation
                ? "dons-en-ligne"
                : "en/online-donations"
            }`}
          >
            {currentLanguage === availableLanguages.FR.abbreviation
              ? "Faire un don"
              : "Donate"}
          </a>
        </div>
      </div>
    </div>
  );
}
