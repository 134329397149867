const boundaries = [
  [-73.1398437, 45.0143666],
  [-73.0653617, 45.0159617],
  [-72.9668525, 45.0141475],
  [-72.9086553, 45.0155978],
  [-72.8470056, 45.016665],
  [-72.7481758, 45.0156214],
  [-72.7339058, 45.01583],
  [-72.6916648, 45.0154784],
  [-72.6346761, 45.0146878],
  [-72.6043017, 45.0131128],
  [-72.5847503, 45.0115683],
  [-72.5553981, 45.0080186],
  [-72.5309186, 45.0077714],
  [-72.4790242, 45.0088297],
  [-72.4504375, 45.0085628],
  [-72.3922522, 45.0063522],
  [-72.3686311, 45.0062164],
  [-72.3346281, 45.0050873],
  [-72.3119542, 45.0037886],
  [-72.2933481, 45.0043556],
  [-72.2702128, 45.0041994],
  [-72.1723582, 45.005853],
  [-72.1510661, 45.0060225],
  [-72.1101303, 45.0056176],
  [-72.0542833, 45.00622],
  [-71.9457533, 45.00837],
  [-71.913899, 45.007629],
  [-71.8049542, 45.0104511],
  [-71.7541008, 45.0113372],
  [-71.6967964, 45.0112986],
  [-71.6126561, 45.0127153],
  [-71.5725103, 45.0124986],
  [-71.5010194, 45.0134458],
  [-71.5003114, 45.0136867],
  [-71.5002825, 45.0143519],
  [-71.4995356, 45.0148714],
  [-71.4996744, 45.0154394],
  [-71.5006483, 45.0156389],
  [-71.5007058, 45.0164364],
  [-71.5013967, 45.0175133],
  [-71.5011703, 45.0178997],
  [-71.5001164, 45.0176386],
  [-71.4993569, 45.0183256],
  [-71.4995678, 45.0191011],
  [-71.5007358, 45.0189936],
  [-71.5008722, 45.0193478],
  [-71.5006739, 45.0197911],
  [-71.4997753, 45.0195611],
  [-71.4985589, 45.0202356],
  [-71.4990306, 45.0203417],
  [-71.4996403, 45.0200397],
  [-71.5000739, 45.0200878],
  [-71.5003381, 45.0207464],
  [-71.4997997, 45.0211894],
  [-71.4996267, 45.0223544],
  [-71.4999444, 45.0225203],
  [-71.5001572, 45.0234875],
  [-71.4997647, 45.0236008],
  [-71.4979331, 45.0231106],
  [-71.4971167, 45.0236025],
  [-71.497685, 45.0242303],
  [-71.4994783, 45.0245631],
  [-71.4992747, 45.0254658],
  [-71.50014, 45.0260811],
  [-71.4999589, 45.0268061],
  [-71.4995958, 45.0269872],
  [-71.4991633, 45.0265278],
  [-71.4979783, 45.0265453],
  [-71.498075, 45.0275833],
  [-71.4986858, 45.0283044],
  [-71.4978906, 45.0282858],
  [-71.4971667, 45.0286389],
  [-71.4977244, 45.0294056],
  [-71.4977478, 45.0300233],
  [-71.4970278, 45.0302436],
  [-71.496945, 45.0310008],
  [-71.4962742, 45.0317508],
  [-71.4957175, 45.0318178],
  [-71.4949844, 45.0313614],
  [-71.4941483, 45.0314931],
  [-71.4935686, 45.0319936],
  [-71.4936839, 45.0322394],
  [-71.4949517, 45.0328597],
  [-71.4948133, 45.0330661],
  [-71.4929733, 45.0332289],
  [-71.4925769, 45.0338547],
  [-71.4931058, 45.0344839],
  [-71.492255, 45.0361278],
  [-71.4922878, 45.0378886],
  [-71.4919144, 45.0385414],
  [-71.4921514, 45.0392644],
  [-71.4918811, 45.0401225],
  [-71.4911761, 45.0410622],
  [-71.4909228, 45.0426383],
  [-71.4909572, 45.0431686],
  [-71.492235, 45.0451694],
  [-71.4932792, 45.0457739],
  [-71.4949886, 45.0452325],
  [-71.4955339, 45.0452942],
  [-71.4968183, 45.0446742],
  [-71.4993467, 45.0446742],
  [-71.5011467, 45.0450867],
  [-71.5018067, 45.0461075],
  [-71.5031189, 45.0452039],
  [-71.504515, 45.0460775],
  [-71.5028894, 45.0470972],
  [-71.5027467, 45.0475992],
  [-71.5031636, 45.0481103],
  [-71.5040394, 45.0481431],
  [-71.5047494, 45.047905],
  [-71.50399, 45.0474192],
  [-71.5046144, 45.0470903],
  [-71.5066058, 45.0475547],
  [-71.5064503, 45.0484822],
  [-71.5053547, 45.0489239],
  [-71.5051922, 45.0511464],
  [-71.5043886, 45.0523747],
  [-71.5035797, 45.0525517],
  [-71.5018214, 45.0522556],
  [-71.5013389, 45.0525467],
  [-71.5005475, 45.0522508],
  [-71.4995908, 45.0524811],
  [-71.4990292, 45.0528692],
  [-71.5003969, 45.0537922],
  [-71.5001983, 45.0540719],
  [-71.4985781, 45.0539908],
  [-71.4972044, 45.0558022],
  [-71.4976258, 45.056315],
  [-71.4982, 45.0559953],
  [-71.4990822, 45.0564328],
  [-71.4985558, 45.0569669],
  [-71.4979953, 45.0569481],
  [-71.4975625, 45.0574886],
  [-71.49788, 45.0576278],
  [-71.4986689, 45.0573639],
  [-71.4992475, 45.0574197],
  [-71.4999958, 45.0577706],
  [-71.5009281, 45.0575286],
  [-71.5011772, 45.0577119],
  [-71.5012678, 45.0587269],
  [-71.5009303, 45.0591478],
  [-71.4992031, 45.0583597],
  [-71.4986989, 45.0585739],
  [-71.4979772, 45.0596789],
  [-71.4971028, 45.0601164],
  [-71.4968711, 45.0607561],
  [-71.4974458, 45.06122],
  [-71.4956672, 45.0647542],
  [-71.4971956, 45.0659364],
  [-71.4976075, 45.0667675],
  [-71.4974325, 45.0676569],
  [-71.4977561, 45.0678042],
  [-71.4973194, 45.0682664],
  [-71.4981328, 45.0691692],
  [-71.4980422, 45.0701875],
  [-71.4976864, 45.070465],
  [-71.4964956, 45.0700083],
  [-71.4956697, 45.0688467],
  [-71.4953222, 45.0690169],
  [-71.4950906, 45.0695753],
  [-71.4942439, 45.0696747],
  [-71.4940656, 45.0703561],
  [-71.4947567, 45.0705797],
  [-71.4944325, 45.0713094],
  [-71.4925664, 45.0713811],
  [-71.4896978, 45.0706458],
  [-71.4889933, 45.0712381],
  [-71.4884089, 45.0711178],
  [-71.4877578, 45.0713683],
  [-71.4877847, 45.0716133],
  [-71.4891136, 45.0723364],
  [-71.489285, 45.0737186],
  [-71.4889989, 45.0742494],
  [-71.4883728, 45.0742356],
  [-71.4863692, 45.0756342],
  [-71.4863097, 45.07644],
  [-71.4872153, 45.0767711],
  [-71.4868133, 45.0781411],
  [-71.4872914, 45.0786731],
  [-71.4859517, 45.0790956],
  [-71.4834628, 45.0793189],
  [-71.4831347, 45.0788217],
  [-71.4826358, 45.0789997],
  [-71.4821272, 45.0799783],
  [-71.4821944, 45.0803611],
  [-71.4811667, 45.0803056],
  [-71.4799708, 45.0816269],
  [-71.4799906, 45.0822556],
  [-71.4808872, 45.0825228],
  [-71.4811961, 45.0829064],
  [-71.4796253, 45.0835142],
  [-71.4785078, 45.08355],
  [-71.4781264, 45.0843419],
  [-71.4774361, 45.0840664],
  [-71.4769922, 45.0829222],
  [-71.4764078, 45.0829936],
  [-71.4758333, 45.0834778],
  [-71.4758525, 45.0840192],
  [-71.4751722, 45.0842375],
  [-71.4744353, 45.0836897],
  [-71.4725656, 45.0837517],
  [-71.4702936, 45.0834122],
  [-71.4693819, 45.0836936],
  [-71.4678872, 45.0836514],
  [-71.4679786, 45.0838919],
  [-71.4684881, 45.0840458],
  [-71.4684553, 45.0843039],
  [-71.4679758, 45.0849436],
  [-71.4671661, 45.0852461],
  [-71.4673636, 45.0867028],
  [-71.4669817, 45.0870158],
  [-71.4662103, 45.086635],
  [-71.4654425, 45.0875094],
  [-71.4663647, 45.0888103],
  [-71.465825, 45.0912883],
  [-71.4643892, 45.0933503],
  [-71.4638914, 45.0934314],
  [-71.4620792, 45.09309],
  [-71.4605072, 45.0948983],
  [-71.45871, 45.0949636],
  [-71.4583528, 45.0951586],
  [-71.4592692, 45.096025],
  [-71.4594381, 45.0965506],
  [-71.4584522, 45.0988261],
  [-71.4581306, 45.0991411],
  [-71.4559692, 45.0998964],
  [-71.4556644, 45.1009553],
  [-71.4551572, 45.1014272],
  [-71.4540608, 45.1016306],
  [-71.4530742, 45.1020975],
  [-71.4525172, 45.1019986],
  [-71.4515981, 45.1024619],
  [-71.4507586, 45.1034483],
  [-71.4492947, 45.1044683],
  [-71.4491903, 45.104805],
  [-71.4495425, 45.1051264],
  [-71.4500333, 45.1046536],
  [-71.4508131, 45.1048083],
  [-71.45108, 45.1051053],
  [-71.4498956, 45.1063294],
  [-71.4504011, 45.1066125],
  [-71.4503992, 45.1070697],
  [-71.4496767, 45.1076214],
  [-71.4486983, 45.1078858],
  [-71.4486094, 45.1082347],
  [-71.4491342, 45.1092247],
  [-71.4495508, 45.1094567],
  [-71.4503806, 45.1089361],
  [-71.4506119, 45.1091708],
  [-71.4502206, 45.1105008],
  [-71.4493314, 45.1107658],
  [-71.4484656, 45.11141],
  [-71.4487872, 45.1125669],
  [-71.4482961, 45.1130386],
  [-71.4472661, 45.1134153],
  [-71.4460197, 45.1135161],
  [-71.4441114, 45.1125381],
  [-71.4432069, 45.1130219],
  [-71.4416028, 45.1130956],
  [-71.4410542, 45.1135378],
  [-71.4403342, 45.1136889],
  [-71.4399406, 45.1139442],
  [-71.4407711, 45.1145394],
  [-71.4407083, 45.1148175],
  [-71.4400986, 45.1160075],
  [-71.4386211, 45.1172686],
  [-71.4376494, 45.1177292],
  [-71.4363092, 45.1177553],
  [-71.4354711, 45.118125],
  [-71.4351744, 45.1184828],
  [-71.4356108, 45.1191439],
  [-71.4341408, 45.1199964],
  [-71.4328814, 45.1198331],
  [-71.4317836, 45.1204761],
  [-71.4312486, 45.12132],
  [-71.4295056, 45.1223053],
  [-71.4290103, 45.1231964],
  [-71.4283997, 45.1236853],
  [-71.4291019, 45.1241997],
  [-71.4290911, 45.1247281],
  [-71.4281742, 45.1249908],
  [-71.4278389, 45.1258786],
  [-71.4265425, 45.1270242],
  [-71.4267942, 45.1282608],
  [-71.42611, 45.1294814],
  [-71.4266136, 45.1299553],
  [-71.4269781, 45.1297714],
  [-71.4272614, 45.1298811],
  [-71.4278489, 45.1315394],
  [-71.4282947, 45.1316125],
  [-71.4288428, 45.1325747],
  [-71.4287622, 45.1335469],
  [-71.4294006, 45.1336967],
  [-71.4301486, 45.1342386],
  [-71.4308833, 45.1350839],
  [-71.4311697, 45.1364717],
  [-71.4325111, 45.137365],
  [-71.4324378, 45.1380189],
  [-71.4339956, 45.1385414],
  [-71.4346925, 45.1391458],
  [-71.4358003, 45.1396072],
  [-71.4376258, 45.1415233],
  [-71.4376217, 45.1418869],
  [-71.4367386, 45.1423106],
  [-71.4362456, 45.1422428],
  [-71.4358011, 45.1418319],
  [-71.4354108, 45.1418825],
  [-71.4349178, 45.1439242],
  [-71.4341867, 45.1446117],
  [-71.4342364, 45.1456089],
  [-71.4338433, 45.1454839],
  [-71.4336875, 45.1451611],
  [-71.43333, 45.145285],
  [-71.4335503, 45.1465169],
  [-71.4332703, 45.1483144],
  [-71.4322142, 45.1489006],
  [-71.4317614, 45.1494589],
  [-71.4303406, 45.1499033],
  [-71.4301519, 45.1506942],
  [-71.4296844, 45.1509492],
  [-71.4287978, 45.1508917],
  [-71.4274611, 45.1514736],
  [-71.4264014, 45.1526781],
  [-71.4262522, 45.1544711],
  [-71.4259761, 45.1549428],
  [-71.4261583, 45.155345],
  [-71.4257783, 45.1557703],
  [-71.4262489, 45.1560136],
  [-71.4261247, 45.156245],
  [-71.4256181, 45.1559958],
  [-71.4253136, 45.1560892],
  [-71.4254211, 45.1565292],
  [-71.4248594, 45.1567283],
  [-71.4252278, 45.1568456],
  [-71.4249942, 45.1572536],
  [-71.4256883, 45.1575131],
  [-71.4257319, 45.1577406],
  [-71.4252733, 45.1577617],
  [-71.4242358, 45.1587039],
  [-71.4236611, 45.1596783],
  [-71.4241153, 45.1598731],
  [-71.4237458, 45.1602931],
  [-71.4242903, 45.1603006],
  [-71.4243983, 45.1605378],
  [-71.4241811, 45.1606725],
  [-71.4237217, 45.1604622],
  [-71.4234989, 45.1606747],
  [-71.4240625, 45.1608928],
  [-71.4240372, 45.1615283],
  [-71.4235361, 45.1618203],
  [-71.4239406, 45.1621392],
  [-71.42372, 45.1624233],
  [-71.4243775, 45.1631078],
  [-71.4241708, 45.1636817],
  [-71.4234633, 45.1638619],
  [-71.4236933, 45.1643825],
  [-71.4234422, 45.1646417],
  [-71.4237125, 45.1649792],
  [-71.4239869, 45.1645911],
  [-71.4243317, 45.1646472],
  [-71.4249361, 45.1658386],
  [-71.424725, 45.1662119],
  [-71.4230739, 45.1663944],
  [-71.4229767, 45.1668758],
  [-71.4220647, 45.1673911],
  [-71.4214272, 45.1685147],
  [-71.4203267, 45.1690858],
  [-71.4196903, 45.1698744],
  [-71.4184553, 45.1705306],
  [-71.4188186, 45.1712278],
  [-71.4179006, 45.1721319],
  [-71.4182411, 45.1729242],
  [-71.4176928, 45.1731925],
  [-71.4177722, 45.1738625],
  [-71.417375, 45.1748642],
  [-71.4163064, 45.1754406],
  [-71.4158125, 45.1762136],
  [-71.4156808, 45.1775081],
  [-71.4152567, 45.1781358],
  [-71.4141081, 45.1789914],
  [-71.4146744, 45.1797311],
  [-71.4143681, 45.1800647],
  [-71.4145628, 45.1803489],
  [-71.4152997, 45.1802728],
  [-71.4157517, 45.1806869],
  [-71.4152467, 45.1820244],
  [-71.4153931, 45.1824928],
  [-71.4148414, 45.1832572],
  [-71.4144225, 45.1847883],
  [-71.4132686, 45.1855369],
  [-71.4116589, 45.1861153],
  [-71.4107081, 45.1862036],
  [-71.4100358, 45.1866558],
  [-71.4077281, 45.1894061],
  [-71.4072744, 45.1895164],
  [-71.4069456, 45.1899514],
  [-71.4068439, 45.1902922],
  [-71.4076211, 45.1912086],
  [-71.4074267, 45.1915656],
  [-71.4064872, 45.1919636],
  [-71.4065575, 45.1930364],
  [-71.4056283, 45.1930231],
  [-71.4051489, 45.1932556],
  [-71.4049436, 45.1942219],
  [-71.4042042, 45.1949703],
  [-71.4050294, 45.1960186],
  [-71.4047736, 45.1966972],
  [-71.4054917, 45.1969375],
  [-71.4054436, 45.1972344],
  [-71.4058639, 45.1977053],
  [-71.4054039, 45.1976586],
  [-71.4041267, 45.1985411],
  [-71.403335, 45.19871],
  [-71.4027758, 45.1991289],
  [-71.4030922, 45.1992189],
  [-71.4029275, 45.1997836],
  [-71.4022233, 45.2001344],
  [-71.4021944, 45.2003547],
  [-71.4013936, 45.2004153],
  [-71.4013369, 45.2001103],
  [-71.40096, 45.19999],
  [-71.3999817, 45.2003289],
  [-71.3998725, 45.2009267],
  [-71.4002361, 45.2015844],
  [-71.3998178, 45.2020167],
  [-71.3986058, 45.20217],
  [-71.3974653, 45.203145],
  [-71.3973478, 45.2035219],
  [-71.3977861, 45.2045006],
  [-71.3978536, 45.2059475],
  [-71.3981525, 45.2060847],
  [-71.3983192, 45.2069169],
  [-71.3987078, 45.2069789],
  [-71.3989342, 45.2075681],
  [-71.3986892, 45.2077294],
  [-71.3991083, 45.2077994],
  [-71.3992661, 45.2083572],
  [-71.3989228, 45.2084986],
  [-71.3989533, 45.2087561],
  [-71.3993742, 45.2087994],
  [-71.3995322, 45.2091236],
  [-71.3998697, 45.2090886],
  [-71.3998089, 45.2093433],
  [-71.4002153, 45.2094831],
  [-71.3997944, 45.2098317],
  [-71.4000922, 45.2097694],
  [-71.4001825, 45.2102656],
  [-71.4015731, 45.2114192],
  [-71.4015561, 45.2119508],
  [-71.4019961, 45.2126897],
  [-71.4017444, 45.2133719],
  [-71.4022839, 45.2137086],
  [-71.4020581, 45.2137411],
  [-71.4021792, 45.2142203],
  [-71.4025911, 45.2143622],
  [-71.4031294, 45.2153011],
  [-71.4047028, 45.2155653],
  [-71.4054839, 45.2162003],
  [-71.4057892, 45.2160503],
  [-71.4066561, 45.2162403],
  [-71.4075422, 45.2168747],
  [-71.4092867, 45.2167247],
  [-71.4112139, 45.2175703],
  [-71.4116992, 45.2172636],
  [-71.4128364, 45.2174939],
  [-71.4136917, 45.2174369],
  [-71.4158175, 45.2183586],
  [-71.4161139, 45.2186667],
  [-71.4157808, 45.2189739],
  [-71.4160403, 45.2196319],
  [-71.4168753, 45.2203239],
  [-71.41697, 45.2210958],
  [-71.4174183, 45.2217472],
  [-71.4189642, 45.2220408],
  [-71.4192328, 45.2224475],
  [-71.4200375, 45.2227578],
  [-71.4199533, 45.2230486],
  [-71.4205933, 45.2233781],
  [-71.420745, 45.2238481],
  [-71.4221233, 45.2242228],
  [-71.4233253, 45.2248722],
  [-71.4237111, 45.2247133],
  [-71.4244153, 45.2249547],
  [-71.4254556, 45.2248639],
  [-71.4263992, 45.2251794],
  [-71.4264011, 45.2261969],
  [-71.4297478, 45.2282042],
  [-71.4305067, 45.2280819],
  [-71.4317278, 45.2286022],
  [-71.43185, 45.2292094],
  [-71.4326231, 45.2294889],
  [-71.4329975, 45.2301378],
  [-71.4335014, 45.2300592],
  [-71.4349847, 45.2312281],
  [-71.4352906, 45.2317414],
  [-71.4357544, 45.2313431],
  [-71.4366439, 45.2318217],
  [-71.43663, 45.2320222],
  [-71.4374747, 45.2320908],
  [-71.4378653, 45.2325167],
  [-71.4382675, 45.2325125],
  [-71.4383725, 45.2330011],
  [-71.4389211, 45.2334725],
  [-71.43946, 45.2336058],
  [-71.4392658, 45.2338117],
  [-71.439575, 45.2343931],
  [-71.4405058, 45.2350069],
  [-71.4412744, 45.2351186],
  [-71.4435167, 45.2348011],
  [-71.4438317, 45.2351967],
  [-71.4431819, 45.2362075],
  [-71.4434061, 45.2367586],
  [-71.4428239, 45.2382931],
  [-71.4419131, 45.2386458],
  [-71.4403947, 45.2385797],
  [-71.4396781, 45.2390992],
  [-71.4373872, 45.2388144],
  [-71.4352781, 45.2381406],
  [-71.4342281, 45.2384167],
  [-71.431335, 45.2364172],
  [-71.4296428, 45.2359711],
  [-71.4296589, 45.2350867],
  [-71.4291367, 45.2339619],
  [-71.4279267, 45.2338675],
  [-71.4274131, 45.2342492],
  [-71.4267669, 45.2341617],
  [-71.4264186, 45.2336872],
  [-71.4254586, 45.2333258],
  [-71.4242089, 45.2337144],
  [-71.4236169, 45.2336225],
  [-71.4216531, 45.2327819],
  [-71.4210481, 45.2322025],
  [-71.4199931, 45.2327436],
  [-71.4192619, 45.2339886],
  [-71.4168244, 45.2357811],
  [-71.4139889, 45.2360939],
  [-71.4130086, 45.2358447],
  [-71.4123117, 45.2369056],
  [-71.4124481, 45.2378419],
  [-71.4119319, 45.2387275],
  [-71.4109822, 45.2391281],
  [-71.4098767, 45.2389464],
  [-71.4085219, 45.2392953],
  [-71.4077297, 45.2400108],
  [-71.4074508, 45.2412222],
  [-71.4057664, 45.2418469],
  [-71.4021647, 45.2425972],
  [-71.3996589, 45.2421061],
  [-71.39779, 45.2421189],
  [-71.3963617, 45.2418492],
  [-71.3945058, 45.2409078],
  [-71.3924803, 45.2394258],
  [-71.3927056, 45.2387053],
  [-71.3917306, 45.2373822],
  [-71.3904444, 45.2364681],
  [-71.3892633, 45.2349525],
  [-71.3869242, 45.2338908],
  [-71.3859231, 45.2330922],
  [-71.3853158, 45.2330475],
  [-71.3841436, 45.2335083],
  [-71.3825317, 45.2353758],
  [-71.3819489, 45.2368092],
  [-71.3803478, 45.2387078],
  [-71.3797106, 45.2406539],
  [-71.3790119, 45.2414011],
  [-71.3788833, 45.2428319],
  [-71.3783419, 45.2434969],
  [-71.3774239, 45.2437719],
  [-71.3759564, 45.2451011],
  [-71.3734678, 45.2454511],
  [-71.3723817, 45.2462439],
  [-71.36802, 45.2463922],
  [-71.3669169, 45.2473919],
  [-71.3657222, 45.2474164],
  [-71.3627586, 45.24831],
  [-71.3620458, 45.2492222],
  [-71.3588142, 45.2509144],
  [-71.35654, 45.2539044],
  [-71.3563683, 45.25467],
  [-71.3570036, 45.2553106],
  [-71.3566197, 45.2565836],
  [-71.3587178, 45.2594669],
  [-71.3593408, 45.2620989],
  [-71.3619289, 45.2645767],
  [-71.3632189, 45.2667408],
  [-71.3630481, 45.2673925],
  [-71.3608633, 45.2688742],
  [-71.3602833, 45.2703356],
  [-71.3584669, 45.2694636],
  [-71.3556842, 45.2686031],
  [-71.3534569, 45.2686083],
  [-71.3526703, 45.2686994],
  [-71.3508522, 45.2695433],
  [-71.350315, 45.2695067],
  [-71.3486967, 45.2707753],
  [-71.3476831, 45.2720186],
  [-71.3469178, 45.2720442],
  [-71.345835, 45.2714931],
  [-71.344315, 45.2712514],
  [-71.3423542, 45.2712614],
  [-71.3404256, 45.2720669],
  [-71.3385444, 45.2725256],
  [-71.3364156, 45.2727439],
  [-71.3350142, 45.2735333],
  [-71.3330347, 45.2758578],
  [-71.3330444, 45.2769097],
  [-71.3324511, 45.2782183],
  [-71.3315767, 45.2789708],
  [-71.3317056, 45.2795067],
  [-71.3308067, 45.2802803],
  [-71.3291261, 45.2805306],
  [-71.3265747, 45.2812522],
  [-71.3246356, 45.2813239],
  [-71.3208431, 45.2819203],
  [-71.3196572, 45.2829094],
  [-71.3191547, 45.2829789],
  [-71.3186986, 45.2839208],
  [-71.3170408, 45.2854383],
  [-71.3158239, 45.2860083],
  [-71.31544, 45.2865958],
  [-71.3129639, 45.2869106],
  [-71.3113028, 45.2865433],
  [-71.3102556, 45.2869033],
  [-71.3090314, 45.2869603],
  [-71.3076022, 45.2874861],
  [-71.3063306, 45.2895231],
  [-71.3064183, 45.2900089],
  [-71.3044939, 45.2924331],
  [-71.3030256, 45.2929747],
  [-71.3018278, 45.2953589],
  [-71.3005353, 45.2966506],
  [-71.3000706, 45.2963733],
  [-71.2995194, 45.2969003],
  [-71.2987919, 45.2970383],
  [-71.2962422, 45.2991442],
  [-71.29434, 45.2985286],
  [-71.2909739, 45.2987117],
  [-71.2905561, 45.2995875],
  [-71.2898817, 45.3000067],
  [-71.2887972, 45.3000378],
  [-71.2879208, 45.3009089],
  [-71.2857997, 45.3003642],
  [-71.2847933, 45.3012828],
  [-71.2842303, 45.3023608],
  [-71.2836331, 45.3019356],
  [-71.2830853, 45.3009239],
  [-71.2837158, 45.3003139],
  [-71.2835942, 45.3000111],
  [-71.2822311, 45.2995019],
  [-71.2820131, 45.2991169],
  [-71.2821508, 45.2979283],
  [-71.2827114, 45.2971103],
  [-71.2813436, 45.2962061],
  [-71.2805197, 45.2945872],
  [-71.2791178, 45.2944872],
  [-71.2778919, 45.2949428],
  [-71.2773222, 45.2950308],
  [-71.2767147, 45.2948561],
  [-71.2757803, 45.2950486],
  [-71.2748367, 45.2956875],
  [-71.2742336, 45.2957778],
  [-71.2726942, 45.2965736],
  [-71.2719764, 45.2960281],
  [-71.2700289, 45.2953389],
  [-71.2690044, 45.2952769],
  [-71.2649536, 45.2937939],
  [-71.2644497, 45.2932319],
  [-71.2656511, 45.2922531],
  [-71.2661814, 45.2914444],
  [-71.2657825, 45.2905053],
  [-71.2659228, 45.2894139],
  [-71.2645731, 45.2872297],
  [-71.2637619, 45.2847928],
  [-71.2639353, 45.2828917],
  [-71.2632947, 45.2819103],
  [-71.2635275, 45.2802144],
  [-71.2627403, 45.27917],
  [-71.2629914, 45.2774431],
  [-71.2622206, 45.2765844],
  [-71.2623117, 45.2760044],
  [-71.2596417, 45.27458],
  [-71.2598919, 45.2739358],
  [-71.2592658, 45.2727978],
  [-71.2595456, 45.2724003],
  [-71.2594075, 45.2720386],
  [-71.2587714, 45.271895],
  [-71.2564183, 45.2732022],
  [-71.2559836, 45.2729783],
  [-71.2554256, 45.2719731],
  [-71.2530794, 45.2710611],
  [-71.25167, 45.2702544],
  [-71.2514608, 45.2698744],
  [-71.2503078, 45.2696253],
  [-71.2501269, 45.269195],
  [-71.2497503, 45.2690697],
  [-71.2498417, 45.2686675],
  [-71.2494778, 45.2680206],
  [-71.2487864, 45.2678375],
  [-71.2474544, 45.2686497],
  [-71.2469725, 45.2685075],
  [-71.2456206, 45.2688],
  [-71.2417358, 45.2649442],
  [-71.239195, 45.2633344],
  [-71.2394267, 45.2616675],
  [-71.2369906, 45.2613622],
  [-71.2355961, 45.2609072],
  [-71.2349697, 45.2591953],
  [-71.2338256, 45.2577808],
  [-71.2332131, 45.2575694],
  [-71.2327075, 45.2569333],
  [-71.2325011, 45.2553075],
  [-71.2321936, 45.2546811],
  [-71.2313353, 45.2539039],
  [-71.2320503, 45.2525817],
  [-71.2331161, 45.2515219],
  [-71.2327169, 45.2509953],
  [-71.2318561, 45.2509731],
  [-71.2306507, 45.2494258],
  [-71.2294456, 45.2491983],
  [-71.2289942, 45.2495267],
  [-71.2282667, 45.2493792],
  [-71.2268658, 45.2503072],
  [-71.2262169, 45.2512789],
  [-71.2243697, 45.2521133],
  [-71.2236894, 45.2522125],
  [-71.2221075, 45.2533628],
  [-71.2213983, 45.2530469],
  [-71.2215589, 45.2522119],
  [-71.2208194, 45.2519392],
  [-71.2206311, 45.2507342],
  [-71.2185758, 45.2505056],
  [-71.2180931, 45.2510303],
  [-71.2176697, 45.2511019],
  [-71.2169819, 45.2504247],
  [-71.2149683, 45.2504181],
  [-71.2142919, 45.2506908],
  [-71.21374, 45.2506708],
  [-71.2126586, 45.2501189],
  [-71.2123025, 45.2503511],
  [-71.2111242, 45.2502144],
  [-71.2105839, 45.2506958],
  [-71.2096342, 45.2505992],
  [-71.2083714, 45.2513083],
  [-71.2080481, 45.2521344],
  [-71.2071908, 45.2530617],
  [-71.2052833, 45.2524139],
  [-71.2046514, 45.2529925],
  [-71.2038975, 45.253135],
  [-71.203795, 45.2535083],
  [-71.20251, 45.2543225],
  [-71.2008006, 45.2540336],
  [-71.2006175, 45.2536178],
  [-71.2000086, 45.2532833],
  [-71.1996867, 45.2535914],
  [-71.1992414, 45.2535856],
  [-71.1986433, 45.2542564],
  [-71.1977272, 45.2539308],
  [-71.1973239, 45.2539144],
  [-71.1971489, 45.2541694],
  [-71.1964358, 45.2540533],
  [-71.1959842, 45.2534158],
  [-71.1961222, 45.2531367],
  [-71.1954653, 45.2528442],
  [-71.1948725, 45.2518678],
  [-71.1951436, 45.2512689],
  [-71.19339, 45.2498972],
  [-71.1911906, 45.2494061],
  [-71.1914764, 45.2488897],
  [-71.1910361, 45.2478836],
  [-71.1893542, 45.2475925],
  [-71.1890017, 45.2478039],
  [-71.1877386, 45.24762],
  [-71.1872789, 45.2459225],
  [-71.1877828, 45.2450628],
  [-71.1868917, 45.2443897],
  [-71.1853922, 45.2443211],
  [-71.18496, 45.2450792],
  [-71.1845239, 45.2448836],
  [-71.1839708, 45.244945],
  [-71.1839775, 45.2435836],
  [-71.1832981, 45.2434639],
  [-71.1824953, 45.2425019],
  [-71.1826619, 45.2421764],
  [-71.1826289, 45.2415789],
  [-71.1822769, 45.2413453],
  [-71.1824019, 45.2406364],
  [-71.1818494, 45.2401764],
  [-71.1801067, 45.2399381],
  [-71.1791136, 45.2411464],
  [-71.1783728, 45.2413731],
  [-71.1782742, 45.2419469],
  [-71.1768247, 45.2440453],
  [-71.1761319, 45.2442181],
  [-71.1745322, 45.2454828],
  [-71.1735258, 45.2458119],
  [-71.1732108, 45.2463161],
  [-71.1708289, 45.2459794],
  [-71.1701611, 45.2465908],
  [-71.1697264, 45.2464139],
  [-71.1694497, 45.2466264],
  [-71.1688292, 45.2462722],
  [-71.1680961, 45.2470436],
  [-71.1674997, 45.2470011],
  [-71.1667344, 45.2474811],
  [-71.165045, 45.2490889],
  [-71.1638819, 45.2488517],
  [-71.1623372, 45.2503475],
  [-71.1598608, 45.2487847],
  [-71.1591186, 45.24882],
  [-71.1570197, 45.2481928],
  [-71.1569178, 45.2474811],
  [-71.1561964, 45.2474556],
  [-71.1557086, 45.2469156],
  [-71.1559833, 45.2464556],
  [-71.1552303, 45.2464444],
  [-71.1547481, 45.2460467],
  [-71.1536806, 45.2457886],
  [-71.1507719, 45.2437567],
  [-71.1494364, 45.2434389],
  [-71.148575, 45.2428717],
  [-71.1480275, 45.242445],
  [-71.1478367, 45.2415161],
  [-71.1473475, 45.2409078],
  [-71.1483192, 45.2397253],
  [-71.1475683, 45.2394375],
  [-71.1462661, 45.2397281],
  [-71.1465064, 45.2402181],
  [-71.1457861, 45.2412142],
  [-71.1435172, 45.2425525],
  [-71.1415322, 45.2425364],
  [-71.1403769, 45.2427953],
  [-71.1392544, 45.2427906],
  [-71.1382564, 45.2435367],
  [-71.1370967, 45.2438697],
  [-71.1357133, 45.2437275],
  [-71.1339653, 45.2440764],
  [-71.1329108, 45.2445131],
  [-71.1309278, 45.24655],
  [-71.1307775, 45.2474242],
  [-71.1298861, 45.2485267],
  [-71.1301417, 45.2497781],
  [-71.1296542, 45.2500789],
  [-71.1294075, 45.2507122],
  [-71.1297233, 45.2514111],
  [-71.1289283, 45.2524958],
  [-71.1282442, 45.2530272],
  [-71.1279458, 45.2536931],
  [-71.1269378, 45.2541697],
  [-71.126485, 45.2539069],
  [-71.1256183, 45.25462],
  [-71.1249706, 45.2544586],
  [-71.1241194, 45.2551411],
  [-71.1237881, 45.2559717],
  [-71.1242631, 45.2574969],
  [-71.1239033, 45.2583956],
  [-71.1212344, 45.2603164],
  [-71.1200647, 45.2615733],
  [-71.1195508, 45.2616242],
  [-71.1196633, 45.2624936],
  [-71.1192289, 45.2630697],
  [-71.1203214, 45.2636703],
  [-71.1199775, 45.2642269],
  [-71.1202064, 45.2651447],
  [-71.1191714, 45.2671325],
  [-71.117515, 45.2682964],
  [-71.11644, 45.2694422],
  [-71.1163036, 45.2714131],
  [-71.116795, 45.2727136],
  [-71.1163172, 45.2740875],
  [-71.1154761, 45.2745547],
  [-71.1140636, 45.2749447],
  [-71.1132611, 45.2747939],
  [-71.1116308, 45.2756347],
  [-71.11066, 45.2753833],
  [-71.1095686, 45.2761353],
  [-71.1097175, 45.2769406],
  [-71.1087144, 45.2770589],
  [-71.1071944, 45.2783483],
  [-71.1069033, 45.2797122],
  [-71.1063672, 45.2800075],
  [-71.1064433, 45.2815731],
  [-71.1059811, 45.2818867],
  [-71.1054497, 45.2828006],
  [-71.1083172, 45.2823072],
  [-71.1091803, 45.2823483],
  [-71.1100586, 45.2831178],
  [-71.1102972, 45.2841519],
  [-71.1109947, 45.2843778],
  [-71.1090442, 45.2861189],
  [-71.1078642, 45.2895578],
  [-71.1070869, 45.2898348],
  [-71.1065592, 45.2910917],
  [-71.1058914, 45.2913381],
  [-71.1045994, 45.2911939],
  [-71.1041897, 45.2915217],
  [-71.1039386, 45.2923156],
  [-71.1052047, 45.2934744],
  [-71.1054411, 45.2941278],
  [-71.1052842, 45.2948778],
  [-71.1024336, 45.2972133],
  [-71.0995003, 45.2991914],
  [-71.0987889, 45.3000811],
  [-71.0982636, 45.3002267],
  [-71.0984294, 45.3008825],
  [-71.0982114, 45.3016753],
  [-71.0974983, 45.3017389],
  [-71.0968275, 45.3022919],
  [-71.0955897, 45.3022431],
  [-71.0946914, 45.3024711],
  [-71.0925558, 45.3039197],
  [-71.0904603, 45.3041797],
  [-71.0893106, 45.3047811],
  [-71.0878567, 45.3046156],
  [-71.0867894, 45.3047233],
  [-71.0869111, 45.3051914],
  [-71.0859167, 45.3053789],
  [-71.0857181, 45.3057789],
  [-71.0839881, 45.3054686],
  [-71.0830808, 45.3057236],
  [-71.082735, 45.3063222],
  [-71.0816633, 45.3063133],
  [-71.0799372, 45.3070467],
  [-71.0786369, 45.3064461],
  [-71.0782167, 45.3066542],
  [-71.0773747, 45.3065964],
  [-71.0766861, 45.3070592],
  [-71.0749794, 45.3070528],
  [-71.0742875, 45.3077058],
  [-71.0722603, 45.3084567],
  [-71.0705367, 45.3095019],
  [-71.0690131, 45.3094633],
  [-71.0685983, 45.3096706],
  [-71.0687506, 45.3102347],
  [-71.0673606, 45.3105669],
  [-71.0670056, 45.3111558],
  [-71.0664661, 45.3109767],
  [-71.0660497, 45.3114392],
  [-71.0651925, 45.3116489],
  [-71.0659508, 45.3120458],
  [-71.0655417, 45.3125381],
  [-71.0646861, 45.3124142],
  [-71.0626333, 45.3131306],
  [-71.0623522, 45.3128181],
  [-71.0612203, 45.3128406],
  [-71.0603206, 45.3134686],
  [-71.0591031, 45.3138375],
  [-71.0575369, 45.3132158],
  [-71.0557753, 45.3129108],
  [-71.0537089, 45.3115183],
  [-71.0531711, 45.3114161],
  [-71.0522239, 45.3114669],
  [-71.0519083, 45.3117667],
  [-71.0466333, 45.3130861],
  [-71.0435789, 45.31313],
  [-71.0424192, 45.3128539],
  [-71.0414978, 45.3131078],
  [-71.0393264, 45.3124022],
  [-71.0390661, 45.3126039],
  [-71.0361211, 45.3106672],
  [-71.0346436, 45.3106886],
  [-71.0337197, 45.3100131],
  [-71.0326775, 45.3101858],
  [-71.0314536, 45.311425],
  [-71.0308397, 45.3125517],
  [-71.0273706, 45.3141914],
  [-71.0270942, 45.3146867],
  [-71.0240092, 45.3154172],
  [-71.0224356, 45.3144383],
  [-71.0216436, 45.3144364],
  [-71.0210075, 45.3150336],
  [-71.0201047, 45.3151094],
  [-71.0194014, 45.3154289],
  [-71.0180797, 45.3152911],
  [-71.0162294, 45.3155283],
  [-71.0160706, 45.3163358],
  [-71.0147517, 45.3168447],
  [-71.0134625, 45.3168647],
  [-71.0113217, 45.3179592],
  [-71.0111, 45.3183636],
  [-71.0085189, 45.3192389],
  [-71.0081208, 45.31968],
  [-71.0085236, 45.3201753],
  [-71.0082464, 45.3213094],
  [-71.0055164, 45.3243969],
  [-71.0039292, 45.3273706],
  [-71.0021667, 45.328115],
  [-71.0018772, 45.3284283],
  [-71.0024767, 45.3285414],
  [-71.0034894, 45.3293472],
  [-71.0034161, 45.3298697],
  [-71.0043236, 45.3309861],
  [-71.0066603, 45.3327256],
  [-71.0072458, 45.3327739],
  [-71.0083533, 45.3333572],
  [-71.0117019, 45.3336525],
  [-71.0121225, 45.3338697],
  [-71.0118967, 45.3344036],
  [-71.0112339, 45.3344089],
  [-71.0108892, 45.3347633],
  [-71.0109103, 45.3362125],
  [-71.0112914, 45.3365411],
  [-71.0114528, 45.3378644],
  [-71.0106275, 45.3381572],
  [-71.0113608, 45.3391789],
  [-71.0109372, 45.3395878],
  [-71.0112039, 45.3400892],
  [-71.0124867, 45.3412614],
  [-71.0125742, 45.3438008],
  [-71.0130075, 45.3442369],
  [-71.0118847, 45.3453975],
  [-71.0110894, 45.3469256],
  [-71.0111414, 45.3473364],
  [-71.0103778, 45.3476667],
  [-71.0043983, 45.3453764],
  [-71.0023147, 45.3441736],
  [-71.0014361, 45.3441703],
  [-71.0004392, 45.3420378],
  [-70.9971, 45.3400289],
  [-70.9945278, 45.3389525],
  [-70.9938192, 45.3383672],
  [-70.9908508, 45.3380986],
  [-70.9902175, 45.3369775],
  [-70.9901169, 45.33565],
  [-70.9891247, 45.3341381],
  [-70.9869339, 45.3331681],
  [-70.9856858, 45.3329831],
  [-70.9849844, 45.3319097],
  [-70.9829811, 45.3326394],
  [-70.9817236, 45.3326478],
  [-70.9811267, 45.3322233],
  [-70.9777361, 45.332465],
  [-70.9767033, 45.3330119],
  [-70.9766289, 45.3333672],
  [-70.973325, 45.3336197],
  [-70.9717333, 45.3328969],
  [-70.9697706, 45.3329453],
  [-70.9686319, 45.3321361],
  [-70.9673228, 45.3322317],
  [-70.9662269, 45.3335333],
  [-70.9656364, 45.3333947],
  [-70.96399, 45.3341347],
  [-70.9611819, 45.3367233],
  [-70.9592169, 45.3370969],
  [-70.9584564, 45.3377722],
  [-70.9574053, 45.3381531],
  [-70.9567356, 45.3380886],
  [-70.95534, 45.3386072],
  [-70.9523783, 45.3391153],
  [-70.9508533, 45.3383569],
  [-70.9502433, 45.3375014],
  [-70.9507194, 45.3363689],
  [-70.9509319, 45.3347986],
  [-70.9489247, 45.3318953],
  [-70.9494911, 45.3313139],
  [-70.9484114, 45.3308244],
  [-70.9480886, 45.3302461],
  [-70.9451272, 45.3290342],
  [-70.9444658, 45.327365],
  [-70.9452031, 45.3256997],
  [-70.9434633, 45.3238758],
  [-70.9405883, 45.3232283],
  [-70.9389003, 45.3199803],
  [-70.9299636, 45.3188114],
  [-70.9293533, 45.3165769],
  [-70.9275342, 45.3158944],
  [-70.9271611, 45.3148556],
  [-70.9250414, 45.3148339],
  [-70.9240308, 45.314285],
  [-70.9202386, 45.3133744],
  [-70.9178356, 45.3117242],
  [-70.9193114, 45.3093533],
  [-70.9193364, 45.3087381],
  [-70.9199567, 45.3077314],
  [-70.9193028, 45.3071994],
  [-70.9191061, 45.3064458],
  [-70.9196117, 45.3052675],
  [-70.9137681, 45.3003553],
  [-70.9120097, 45.2963189],
  [-70.9131603, 45.2950306],
  [-70.9138119, 45.2918686],
  [-70.9146692, 45.2917775],
  [-70.9148967, 45.290575],
  [-70.9163278, 45.2897806],
  [-70.9175478, 45.2885858],
  [-70.9179581, 45.2877844],
  [-70.9187069, 45.2872867],
  [-70.9186661, 45.2858564],
  [-70.9196483, 45.2852167],
  [-70.9207608, 45.2809517],
  [-70.9216811, 45.2805358],
  [-70.9213422, 45.2792247],
  [-70.9211194, 45.2788281],
  [-70.9193078, 45.2781139],
  [-70.9164158, 45.2773356],
  [-70.9162689, 45.2761222],
  [-70.9157353, 45.2757225],
  [-70.9148253, 45.2742069],
  [-70.913985, 45.2736056],
  [-70.9135306, 45.2727325],
  [-70.9119803, 45.2718814],
  [-70.9113128, 45.2699947],
  [-70.9085669, 45.2695156],
  [-70.9074981, 45.2684439],
  [-70.9067039, 45.2671136],
  [-70.9053458, 45.2658761],
  [-70.9050742, 45.2652222],
  [-70.9056147, 45.2647925],
  [-70.9059492, 45.2640872],
  [-70.905935, 45.2627928],
  [-70.9066903, 45.2615589],
  [-70.9058936, 45.2608253],
  [-70.9047033, 45.2605125],
  [-70.903335, 45.2606633],
  [-70.9001708, 45.2587914],
  [-70.8989144, 45.2586514],
  [-70.8985106, 45.2583292],
  [-70.8981239, 45.2555683],
  [-70.8983289, 45.2548547],
  [-70.8965364, 45.2532886],
  [-70.8957583, 45.2529922],
  [-70.8951503, 45.2522608],
  [-70.8975583, 45.2482447],
  [-70.8982581, 45.2440825],
  [-70.8977694, 45.2431622],
  [-70.8965958, 45.2430333],
  [-70.8963539, 45.2424917],
  [-70.8969522, 45.2418661],
  [-70.8955181, 45.2411003],
  [-70.8950581, 45.2403264],
  [-70.8926256, 45.2389756],
  [-70.8909889, 45.2391244],
  [-70.8893253, 45.2387036],
  [-70.8890561, 45.2378672],
  [-70.8869886, 45.2362525],
  [-70.8847425, 45.2348589],
  [-70.8833611, 45.2351122],
  [-70.8828531, 45.2342567],
  [-70.8808358, 45.2342792],
  [-70.8795689, 45.2333911],
  [-70.8771236, 45.2335819],
  [-70.8748617, 45.2341433],
  [-70.8689897, 45.2320964],
  [-70.866685, 45.2314128],
  [-70.8647797, 45.2312597],
  [-70.8635128, 45.2309411],
  [-70.8617544, 45.2299475],
  [-70.8582, 45.2291594],
  [-70.8575392, 45.2291397],
  [-70.8565569, 45.2296306],
  [-70.8565731, 45.2299108],
  [-70.853245, 45.2317317],
  [-70.8511947, 45.2321744],
  [-70.8461436, 45.2343861],
  [-70.8434019, 45.2344756],
  [-70.8404489, 45.2340647],
  [-70.8380717, 45.23462],
  [-70.8375897, 45.23499],
  [-70.8365717, 45.2369214],
  [-70.8366417, 45.2373108],
  [-70.8380503, 45.2372022],
  [-70.8392658, 45.2375439],
  [-70.8419861, 45.2397944],
  [-70.8433314, 45.2404308],
  [-70.8446403, 45.241905],
  [-70.8463697, 45.2428547],
  [-70.8482353, 45.2443414],
  [-70.8486353, 45.2451783],
  [-70.8487797, 45.2465278],
  [-70.8479489, 45.2491586],
  [-70.8480439, 45.2496606],
  [-70.8465356, 45.2530122],
  [-70.8465617, 45.2533811],
  [-70.8482578, 45.2543906],
  [-70.8496211, 45.2559064],
  [-70.8481025, 45.2589344],
  [-70.8484086, 45.2619711],
  [-70.8489469, 45.2630919],
  [-70.8462564, 45.26461],
  [-70.8438289, 45.2653506],
  [-70.8412558, 45.2670136],
  [-70.8392628, 45.2690253],
  [-70.8370686, 45.2749733],
  [-70.8365094, 45.2752397],
  [-70.83646, 45.2759753],
  [-70.83449, 45.2775544],
  [-70.8318956, 45.2789086],
  [-70.8311439, 45.2841936],
  [-70.8300122, 45.2868394],
  [-70.8297258, 45.2886892],
  [-70.8299428, 45.2901778],
  [-70.8301094, 45.290735],
  [-70.8307422, 45.2910239],
  [-70.8317897, 45.2908908],
  [-70.8325256, 45.291145],
  [-70.8357119, 45.2935961],
  [-70.8354672, 45.2939658],
  [-70.8343067, 45.2942422],
  [-70.8332572, 45.2949819],
  [-70.8311181, 45.2952733],
  [-70.8289847, 45.2962958],
  [-70.8266789, 45.29678],
  [-70.8250103, 45.2978833],
  [-70.8240131, 45.2979867],
  [-70.8233922, 45.2975964],
  [-70.8229656, 45.2976692],
  [-70.8218411, 45.2982844],
  [-70.8209681, 45.2983164],
  [-70.8193272, 45.2989458],
  [-70.8186867, 45.2986817],
  [-70.8175789, 45.2986086],
  [-70.8169928, 45.2979256],
  [-70.8162956, 45.2981811],
  [-70.8159792, 45.2993703],
  [-70.814105, 45.3004233],
  [-70.8135336, 45.3012981],
  [-70.8124358, 45.3019956],
  [-70.8115475, 45.3037667],
  [-70.8119286, 45.3050767],
  [-70.8117953, 45.3055967],
  [-70.8121606, 45.3062656],
  [-70.8130419, 45.3072397],
  [-70.8143103, 45.3077258],
  [-70.8146281, 45.3081553],
  [-70.8140144, 45.3089819],
  [-70.8125281, 45.3094642],
  [-70.8101647, 45.3113236],
  [-70.808805, 45.3115242],
  [-70.8084297, 45.311815],
  [-70.8088058, 45.3135058],
  [-70.8079042, 45.3154978],
  [-70.8080122, 45.3165753],
  [-70.8077089, 45.3182228],
  [-70.8070217, 45.3194736],
  [-70.8069956, 45.3203589],
  [-70.8064447, 45.3205089],
  [-70.8061006, 45.3215731],
  [-70.8080819, 45.3249867],
  [-70.8080658, 45.3267403],
  [-70.8075908, 45.3276589],
  [-70.8085631, 45.3283547],
  [-70.8113608, 45.3278408],
  [-70.812915, 45.3280361],
  [-70.8139472, 45.3288811],
  [-70.8144817, 45.3299444],
  [-70.8152586, 45.330475],
  [-70.8164072, 45.3307719],
  [-70.8168556, 45.3318489],
  [-70.8180897, 45.3325906],
  [-70.8184506, 45.3343339],
  [-70.8190581, 45.3354478],
  [-70.8180164, 45.3378844],
  [-70.8193806, 45.3391014],
  [-70.8199006, 45.3401936],
  [-70.8196136, 45.3413997],
  [-70.8178275, 45.3436578],
  [-70.817805, 45.3441217],
  [-70.8173253, 45.3447464],
  [-70.8158133, 45.3459392],
  [-70.8123881, 45.3464558],
  [-70.8098042, 45.3486708],
  [-70.8099375, 45.3493972],
  [-70.8094389, 45.3515111],
  [-70.8116428, 45.3521192],
  [-70.8140097, 45.3549442],
  [-70.8144703, 45.356675],
  [-70.8138139, 45.3573278],
  [-70.8123781, 45.3571094],
  [-70.8112942, 45.3572339],
  [-70.8098331, 45.3579689],
  [-70.8095181, 45.3599836],
  [-70.8084467, 45.3618831],
  [-70.8084481, 45.3630683],
  [-70.8066606, 45.3639989],
  [-70.8029219, 45.3650272],
  [-70.8025322, 45.3656786],
  [-70.8027536, 45.3675203],
  [-70.8038947, 45.3693397],
  [-70.8039956, 45.3702136],
  [-70.8048706, 45.3712344],
  [-70.8048186, 45.3725922],
  [-70.8067322, 45.3774511],
  [-70.8076572, 45.3785033],
  [-70.8097108, 45.3799717],
  [-70.8103953, 45.3816531],
  [-70.8124672, 45.3834353],
  [-70.8129656, 45.3859258],
  [-70.8137786, 45.3877561],
  [-70.8165092, 45.3900408],
  [-70.8172981, 45.3903603],
  [-70.8224878, 45.3904881],
  [-70.8239333, 45.3910197],
  [-70.8238306, 45.3920481],
  [-70.8252389, 45.3930711],
  [-70.8243136, 45.3949722],
  [-70.8243022, 45.3966339],
  [-70.8249853, 45.39756],
  [-70.8259075, 45.3982308],
  [-70.8255922, 45.3993861],
  [-70.8257442, 45.4002592],
  [-70.8245969, 45.4008283],
  [-70.8188708, 45.40492],
  [-70.8170817, 45.4082086],
  [-70.8147231, 45.4095917],
  [-70.8128225, 45.4114106],
  [-70.8120594, 45.41294],
  [-70.81188, 45.4146425],
  [-70.8113375, 45.4161647],
  [-70.8077831, 45.4164753],
  [-70.8033142, 45.4175661],
  [-70.8025128, 45.4179369],
  [-70.8012425, 45.4207486],
  [-70.7984472, 45.4241447],
  [-70.7986269, 45.4257944],
  [-70.7981056, 45.4265078],
  [-70.7954033, 45.4280556],
  [-70.7938328, 45.428355],
  [-70.7916028, 45.4281006],
  [-70.7900019, 45.4268386],
  [-70.7889117, 45.4268197],
  [-70.7875908, 45.4264653],
  [-70.7857503, 45.4274286],
  [-70.7844642, 45.4284236],
  [-70.7814386, 45.4312322],
  [-70.78054, 45.4305242],
  [-70.7795444, 45.4301206],
  [-70.7769825, 45.4299336],
  [-70.7737508, 45.4288456],
  [-70.768215, 45.4285011],
  [-70.7675367, 45.4282889],
  [-70.7651769, 45.4262128],
  [-70.7643986, 45.4259283],
  [-70.7601719, 45.4271411],
  [-70.7584908, 45.4280442],
  [-70.7576081, 45.4282744],
  [-70.7554128, 45.4284517],
  [-70.7545847, 45.4283122],
  [-70.7515744, 45.4262478],
  [-70.7506406, 45.423725],
  [-70.7500172, 45.4232325],
  [-70.7491575, 45.4227639],
  [-70.7469197, 45.4223558],
  [-70.7446936, 45.4215992],
  [-70.7440106, 45.4211672],
  [-70.7438706, 45.4202328],
  [-70.7442781, 45.4175433],
  [-70.7452594, 45.4162589],
  [-70.7450906, 45.4152142],
  [-70.7435517, 45.4133586],
  [-70.7435689, 45.4118617],
  [-70.742175, 45.4108242],
  [-70.7406978, 45.4103389],
  [-70.7381764, 45.4089036],
  [-70.7366386, 45.4074906],
  [-70.7346039, 45.4062808],
  [-70.7319778, 45.4053486],
  [-70.7303778, 45.4043767],
  [-70.7312319, 45.4029381],
  [-70.7314372, 45.4018753],
  [-70.7296544, 45.3990981],
  [-70.7284689, 45.3983447],
  [-70.7251392, 45.3968547],
  [-70.7244089, 45.3959044],
  [-70.7238469, 45.3955717],
  [-70.7208011, 45.3946797],
  [-70.7174608, 45.3929242],
  [-70.7145425, 45.3919889],
  [-70.7120156, 45.3904786],
  [-70.7098344, 45.390915],
  [-70.7074631, 45.392025],
  [-70.7073669, 45.39268],
  [-70.7066456, 45.3931197],
  [-70.7042081, 45.3935536],
  [-70.7023353, 45.3934967],
  [-70.6994931, 45.3945386],
  [-70.6987972, 45.394535],
  [-70.6977742, 45.3940103],
  [-70.6972317, 45.3927817],
  [-70.6961422, 45.3924169],
  [-70.6920408, 45.3925697],
  [-70.6904667, 45.3932108],
  [-70.6876381, 45.3928772],
  [-70.6866214, 45.393285],
  [-70.6848081, 45.3947119],
  [-70.6829017, 45.3952075],
  [-70.6806828, 45.3945408],
  [-70.6781678, 45.3942717],
  [-70.6753089, 45.3928819],
  [-70.6741661, 45.3925792],
  [-70.6699619, 45.3903292],
  [-70.6648744, 45.3879692],
  [-70.6631686, 45.3867325],
  [-70.6616633, 45.3863092],
  [-70.6611422, 45.3856225],
  [-70.6608681, 45.3830519],
  [-70.6611664, 45.3822764],
  [-70.6601594, 45.3798761],
  [-70.6601453, 45.3793192],
  [-70.6607819, 45.3781319],
  [-70.6587692, 45.3776719],
  [-70.6528625, 45.3775353],
  [-70.6517839, 45.3769581],
  [-70.6504378, 45.3771919],
  [-70.6458347, 45.3787656],
  [-70.6407878, 45.3813022],
  [-70.6368589, 45.3823169],
  [-70.6354833, 45.3834383],
  [-70.6348875, 45.3834186],
  [-70.6346336, 45.3838028],
  [-70.6351114, 45.3844675],
  [-70.6349031, 45.3853175],
  [-70.6371197, 45.3872228],
  [-70.6375775, 45.3882222],
  [-70.6388844, 45.3888294],
  [-70.6383403, 45.3895214],
  [-70.6381475, 45.3905767],
  [-70.6365525, 45.3926186],
  [-70.6363247, 45.3952383],
  [-70.6344606, 45.3974783],
  [-70.6330486, 45.3984075],
  [-70.6305397, 45.3993919],
  [-70.6291728, 45.3992964],
  [-70.6284467, 45.3998644],
  [-70.6263156, 45.3996439],
  [-70.6233444, 45.4002158],
  [-70.62309, 45.4008411],
  [-70.6223228, 45.4014031],
  [-70.6212694, 45.4027358],
  [-70.6227356, 45.4042439],
  [-70.6237978, 45.4057894],
  [-70.6244528, 45.4061483],
  [-70.6301653, 45.4070286],
  [-70.6307331, 45.4073058],
  [-70.6314769, 45.408265],
  [-70.6328319, 45.4086064],
  [-70.6330414, 45.4091156],
  [-70.6339114, 45.4096442],
  [-70.635045, 45.4110617],
  [-70.6350497, 45.4117769],
  [-70.6356097, 45.4128386],
  [-70.6355, 45.4148819],
  [-70.6348436, 45.41529],
  [-70.6348622, 45.4155847],
  [-70.6344364, 45.4158972],
  [-70.6320836, 45.4156242],
  [-70.6311503, 45.4158856],
  [-70.6309442, 45.4163056],
  [-70.6311681, 45.4177914],
  [-70.6302281, 45.4181608],
  [-70.6304047, 45.4186411],
  [-70.6287058, 45.41922],
  [-70.6287953, 45.4199056],
  [-70.6277739, 45.4207019],
  [-70.6280167, 45.4215444],
  [-70.6278664, 45.4225992],
  [-70.6296142, 45.4244025],
  [-70.6299478, 45.4251228],
  [-70.6298244, 45.4263144],
  [-70.6304881, 45.4267689],
  [-70.6322806, 45.4268136],
  [-70.6359897, 45.4273806],
  [-70.6365297, 45.4276775],
  [-70.6368972, 45.4285386],
  [-70.6404461, 45.4307761],
  [-70.6430894, 45.4335942],
  [-70.6432622, 45.4344492],
  [-70.6425514, 45.43629],
  [-70.6447794, 45.4368956],
  [-70.6461692, 45.4377714],
  [-70.6501094, 45.4428189],
  [-70.6502347, 45.4440789],
  [-70.6507344, 45.4449447],
  [-70.6544783, 45.4456081],
  [-70.6617208, 45.4457036],
  [-70.6629319, 45.4459625],
  [-70.6661267, 45.4483997],
  [-70.6686333, 45.4492267],
  [-70.6697028, 45.4499172],
  [-70.6750822, 45.4518789],
  [-70.681115, 45.4521831],
  [-70.6814714, 45.4526289],
  [-70.6808972, 45.4559964],
  [-70.6810225, 45.458445],
  [-70.6818272, 45.4616897],
  [-70.6827375, 45.462535],
  [-70.6836981, 45.4628436],
  [-70.6859106, 45.4633019],
  [-70.690465, 45.4636108],
  [-70.6916314, 45.4641653],
  [-70.6928703, 45.4663631],
  [-70.6917583, 45.4697369],
  [-70.6917219, 45.4742669],
  [-70.6921822, 45.4746631],
  [-70.6935703, 45.4750347],
  [-70.6954328, 45.4760014],
  [-70.6968719, 45.4773297],
  [-70.6994764, 45.4789219],
  [-70.7008431, 45.4787272],
  [-70.7039017, 45.4791706],
  [-70.7047414, 45.4788617],
  [-70.7074364, 45.4802725],
  [-70.7106164, 45.4831256],
  [-70.7117333, 45.4835911],
  [-70.7163969, 45.4864725],
  [-70.7178442, 45.4902358],
  [-70.71671, 45.4922472],
  [-70.7132967, 45.4954006],
  [-70.7118917, 45.4971286],
  [-70.7113967, 45.4983656],
  [-70.7113692, 45.4992306],
  [-70.7122306, 45.5008208],
  [-70.7115967, 45.5018106],
  [-70.7128331, 45.5028558],
  [-70.7139653, 45.5032378],
  [-70.7153997, 45.5044847],
  [-70.7168194, 45.5043353],
  [-70.7203108, 45.5056572],
  [-70.7221617, 45.5061125],
  [-70.7226886, 45.5065175],
  [-70.7235928, 45.5089744],
  [-70.723635, 45.510135],
  [-70.7225622, 45.513675],
  [-70.7213228, 45.5153789],
  [-70.7199106, 45.5164992],
  [-70.71761, 45.5175372],
  [-70.7170856, 45.5181356],
  [-70.7157544, 45.51885],
  [-70.7144625, 45.5186025],
  [-70.7111167, 45.5195347],
  [-70.7103633, 45.5209894],
  [-70.7103061, 45.5218928],
  [-70.7098572, 45.5225108],
  [-70.7092047, 45.5248372],
  [-70.7070472, 45.5260144],
  [-70.7064581, 45.5279192],
  [-70.7052903, 45.5285711],
  [-70.7023542, 45.5296711],
  [-70.6995672, 45.5298517],
  [-70.6991014, 45.5301342],
  [-70.6990764, 45.5305203],
  [-70.7007767, 45.53157],
  [-70.7009225, 45.5324933],
  [-70.69902, 45.5347194],
  [-70.6980908, 45.5353331],
  [-70.6964731, 45.5385531],
  [-70.6965253, 45.5400003],
  [-70.6958678, 45.5408103],
  [-70.6922408, 45.5428731],
  [-70.6915989, 45.543915],
  [-70.6900883, 45.5453711],
  [-70.6892286, 45.5469886],
  [-70.6869842, 45.5493158],
  [-70.6848556, 45.5508914],
  [-70.6840306, 45.5510931],
  [-70.6813642, 45.5509706],
  [-70.6797358, 45.5506272],
  [-70.6787433, 45.5513378],
  [-70.6775175, 45.55168],
  [-70.6771031, 45.5522169],
  [-70.6790836, 45.5538186],
  [-70.6842728, 45.5569689],
  [-70.6862269, 45.5594906],
  [-70.6876303, 45.5600317],
  [-70.6891903, 45.5621847],
  [-70.6883592, 45.5641789],
  [-70.6871656, 45.5643333],
  [-70.6857333, 45.5651578],
  [-70.6857958, 45.5661531],
  [-70.6851183, 45.5665744],
  [-70.6861425, 45.5681064],
  [-70.6873467, 45.5680594],
  [-70.6880117, 45.5684264],
  [-70.6881286, 45.5688853],
  [-70.6876922, 45.5694256],
  [-70.6862733, 45.5695186],
  [-70.6841717, 45.5689319],
  [-70.6806369, 45.5700808],
  [-70.6780906, 45.5705022],
  [-70.6776703, 45.5713539],
  [-70.6756561, 45.5730536],
  [-70.6749722, 45.5742639],
  [-70.6736936, 45.5748408],
  [-70.6720353, 45.5762361],
  [-70.6725061, 45.5779381],
  [-70.6721336, 45.5785233],
  [-70.6654975, 45.5824856],
  [-70.664095, 45.5827883],
  [-70.6635339, 45.5835664],
  [-70.6638086, 45.5846061],
  [-70.6617186, 45.5852781],
  [-70.6590642, 45.5872906],
  [-70.6591278, 45.5896614],
  [-70.65688, 45.5904744],
  [-70.6539633, 45.5928056],
  [-70.65204, 45.5954008],
  [-70.6497883, 45.5975714],
  [-70.6495964, 45.5981953],
  [-70.6508544, 45.5993725],
  [-70.6486197, 45.6010761],
  [-70.6461747, 45.6038522],
  [-70.6452697, 45.6064386],
  [-70.6445761, 45.6069825],
  [-70.6413158, 45.6077583],
  [-70.6361544, 45.6099403],
  [-70.6349514, 45.6100967],
  [-70.6328203, 45.6092344],
  [-70.6318042, 45.6091833],
  [-70.6297561, 45.6101358],
  [-70.6269128, 45.6101169],
  [-70.6244664, 45.6115581],
  [-70.6232236, 45.6112008],
  [-70.6191458, 45.61203],
  [-70.6187292, 45.6124908],
  [-70.6188503, 45.6143683],
  [-70.6183522, 45.6147803],
  [-70.6181522, 45.6154853],
  [-70.6173822, 45.6161003],
  [-70.6171439, 45.6166142],
  [-70.6154953, 45.6177547],
  [-70.6155172, 45.6182947],
  [-70.6120047, 45.6198492],
  [-70.6114594, 45.6207383],
  [-70.6108178, 45.6211572],
  [-70.6090219, 45.6214881],
  [-70.6085381, 45.6217742],
  [-70.6071803, 45.6237814],
  [-70.6070419, 45.6256542],
  [-70.60506, 45.6266056],
  [-70.6032042, 45.6267131],
  [-70.6004872, 45.6272406],
  [-70.5985783, 45.6282928],
  [-70.5967322, 45.6297761],
  [-70.5923469, 45.6295231],
  [-70.5908731, 45.6304633],
  [-70.5905364, 45.6316931],
  [-70.5906022, 45.6329744],
  [-70.5899108, 45.6338439],
  [-70.5867172, 45.6355144],
  [-70.5860517, 45.6377581],
  [-70.5840236, 45.638875],
  [-70.5838764, 45.6391686],
  [-70.5841289, 45.6394181],
  [-70.5836053, 45.6405553],
  [-70.5839033, 45.6410253],
  [-70.5835714, 45.6423978],
  [-70.5817414, 45.642505],
  [-70.5795244, 45.6435822],
  [-70.5785017, 45.644755],
  [-70.5771203, 45.6457594],
  [-70.5771247, 45.6465603],
  [-70.5762881, 45.6470358],
  [-70.5755728, 45.6483694],
  [-70.5741164, 45.6484339],
  [-70.57283, 45.648775],
  [-70.5727467, 45.6495317],
  [-70.5712778, 45.6501739],
  [-70.5683169, 45.6522053],
  [-70.5670033, 45.6540406],
  [-70.5649489, 45.6548081],
  [-70.5647367, 45.6552967],
  [-70.5653478, 45.6565258],
  [-70.5652128, 45.6571286],
  [-70.5632539, 45.65822],
  [-70.5621919, 45.6603733],
  [-70.5637261, 45.6619572],
  [-70.5637008, 45.6627433],
  [-70.5602136, 45.6640231],
  [-70.5605747, 45.6647444],
  [-70.5585819, 45.6664767],
  [-70.5573983, 45.6670631],
  [-70.5557919, 45.666595],
  [-70.5538497, 45.6672872],
  [-70.5528731, 45.6679061],
  [-70.5471756, 45.6660867],
  [-70.5465161, 45.6660186],
  [-70.5449314, 45.6669008],
  [-70.5423883, 45.6664578],
  [-70.5393853, 45.6677708],
  [-70.5382025, 45.6687953],
  [-70.5367142, 45.6694322],
  [-70.5350461, 45.6698197],
  [-70.5339153, 45.6710047],
  [-70.5331889, 45.6709931],
  [-70.5304347, 45.6694681],
  [-70.5289731, 45.6679175],
  [-70.5272342, 45.6666458],
  [-70.5263858, 45.6663558],
  [-70.525065, 45.6668386],
  [-70.5230861, 45.6682994],
  [-70.5199144, 45.6690736],
  [-70.5198531, 45.6696419],
  [-70.5193278, 45.6698264],
  [-70.5198097, 45.671365],
  [-70.5185969, 45.672265],
  [-70.517685, 45.6726486],
  [-70.5179381, 45.6732139],
  [-70.5171992, 45.6740278],
  [-70.5154583, 45.6747908],
  [-70.5151489, 45.6751822],
  [-70.5124914, 45.6767972],
  [-70.511865, 45.6775939],
  [-70.5108133, 45.6781394],
  [-70.5107739, 45.6791494],
  [-70.5090556, 45.6797442],
  [-70.5086803, 45.6801792],
  [-70.5059694, 45.6811697],
  [-70.50512, 45.6811631],
  [-70.5043911, 45.6815078],
  [-70.5036356, 45.6814017],
  [-70.50231, 45.6818503],
  [-70.5027589, 45.6825672],
  [-70.5023936, 45.6832861],
  [-70.4964625, 45.685645],
  [-70.4970675, 45.6862253],
  [-70.4964989, 45.6864117],
  [-70.4963269, 45.6868889],
  [-70.4945386, 45.6876286],
  [-70.4943144, 45.6886625],
  [-70.4894906, 45.6900925],
  [-70.4894897, 45.6903044],
  [-70.4863503, 45.6917772],
  [-70.4850958, 45.6927481],
  [-70.4847094, 45.6936244],
  [-70.4837542, 45.6934717],
  [-70.4832047, 45.6937681],
  [-70.4826542, 45.6936314],
  [-70.4794003, 45.6948783],
  [-70.4777603, 45.6963681],
  [-70.4760594, 45.6969197],
  [-70.4750058, 45.6977603],
  [-70.4749525, 45.6986147],
  [-70.4691142, 45.7016833],
  [-70.4705364, 45.7030603],
  [-70.4702067, 45.7034111],
  [-70.4679208, 45.7042336],
  [-70.4671439, 45.7047131],
  [-70.4671356, 45.7052036],
  [-70.4665597, 45.7059639],
  [-70.4654736, 45.7065367],
  [-70.4621894, 45.7055542],
  [-70.4613539, 45.7055636],
  [-70.460445, 45.7059519],
  [-70.4598389, 45.7065106],
  [-70.4585439, 45.7064914],
  [-70.4573808, 45.7070086],
  [-70.4559272, 45.7070597],
  [-70.4544883, 45.7067475],
  [-70.4509156, 45.7039397],
  [-70.4496794, 45.7044511],
  [-70.4490758, 45.7044628],
  [-70.4476142, 45.7042494],
  [-70.4462692, 45.7036567],
  [-70.4441219, 45.7038711],
  [-70.4429456, 45.7046978],
  [-70.4410642, 45.7054011],
  [-70.4403067, 45.7043883],
  [-70.4391664, 45.7038811],
  [-70.4313081, 45.7070275],
  [-70.4298781, 45.7071419],
  [-70.4295036, 45.7074125],
  [-70.4288642, 45.7073678],
  [-70.4282553, 45.70759],
  [-70.4259064, 45.7071497],
  [-70.4254125, 45.7075303],
  [-70.4255292, 45.7077583],
  [-70.4267775, 45.70837],
  [-70.4284608, 45.7079233],
  [-70.4288586, 45.70801],
  [-70.4288358, 45.7087108],
  [-70.4296133, 45.7100497],
  [-70.4294631, 45.7102558],
  [-70.4283975, 45.7103503],
  [-70.4281147, 45.7106581],
  [-70.4257356, 45.7117225],
  [-70.4229461, 45.7118558],
  [-70.4224203, 45.7122017],
  [-70.4176586, 45.7133939],
  [-70.4172289, 45.7137261],
  [-70.4143242, 45.7148097],
  [-70.4136528, 45.7153569],
  [-70.4131244, 45.7154317],
  [-70.4132511, 45.7160742],
  [-70.4129711, 45.7165778],
  [-70.4105833, 45.7163889],
  [-70.4094133, 45.7166572],
  [-70.4076761, 45.7167031],
  [-70.4073714, 45.7172008],
  [-70.4062192, 45.7178919],
  [-70.4037167, 45.71837],
  [-70.4031286, 45.7187008],
  [-70.4028383, 45.7192242],
  [-70.4003497, 45.7197272],
  [-70.3995289, 45.720825],
  [-70.4006267, 45.7216717],
  [-70.3991675, 45.7222968],
  [-70.3987974, 45.7227125],
  [-70.3976923, 45.7230945],
  [-70.3977245, 45.7242442],
  [-70.3970861, 45.7244502],
  [-70.3967482, 45.7251804],
  [-70.3973986, 45.7257244],
  [-70.3976186, 45.726376],
  [-70.3967214, 45.7269442],
  [-70.3970469, 45.7285219],
  [-70.3965156, 45.7290936],
  [-70.3951464, 45.7296636],
  [-70.3924783, 45.7292547],
  [-70.3911622, 45.7284536],
  [-70.3901106, 45.7285075],
  [-70.3865831, 45.73208],
  [-70.3857656, 45.7326361],
  [-70.3856606, 45.7335436],
  [-70.3842422, 45.7340292],
  [-70.3838408, 45.7345703],
  [-70.3843347, 45.7347886],
  [-70.3853758, 45.7344306],
  [-70.3858664, 45.7346331],
  [-70.3858514, 45.7351008],
  [-70.3848017, 45.7357303],
  [-70.3854469, 45.7362831],
  [-70.3863317, 45.7362103],
  [-70.3869639, 45.7364519],
  [-70.3881267, 45.7359231],
  [-70.3893519, 45.7359042],
  [-70.3901447, 45.7361128],
  [-70.3903725, 45.7369808],
  [-70.3893756, 45.7374667],
  [-70.3896764, 45.7376222],
  [-70.3909353, 45.7375733],
  [-70.3919947, 45.7380806],
  [-70.3923992, 45.7385306],
  [-70.3919106, 45.7393058],
  [-70.3934422, 45.7395892],
  [-70.3938972, 45.7401042],
  [-70.3936942, 45.7411181],
  [-70.3929267, 45.7414767],
  [-70.3932556, 45.7419575],
  [-70.3942786, 45.7420981],
  [-70.3945847, 45.7424839],
  [-70.3943614, 45.7429225],
  [-70.3945142, 45.7436064],
  [-70.3941958, 45.7440722],
  [-70.3930428, 45.7443106],
  [-70.3931186, 45.7445156],
  [-70.3920675, 45.7457239],
  [-70.3903586, 45.7462569],
  [-70.3891533, 45.7470167],
  [-70.388085, 45.7482642],
  [-70.3880297, 45.7485158],
  [-70.3886986, 45.7491553],
  [-70.3884325, 45.7502211],
  [-70.3944117, 45.7546267],
  [-70.3958792, 45.7566478],
  [-70.3974978, 45.7571419],
  [-70.4007847, 45.757375],
  [-70.4070294, 45.7625344],
  [-70.4069861, 45.7638525],
  [-70.4063256, 45.7646894],
  [-70.4063831, 45.7685739],
  [-70.4060014, 45.76964],
  [-70.4054678, 45.7700089],
  [-70.4061103, 45.7711925],
  [-70.4074228, 45.7721892],
  [-70.4076706, 45.7732167],
  [-70.4070272, 45.7746094],
  [-70.4056314, 45.7765217],
  [-70.4057503, 45.7768886],
  [-70.4067625, 45.7773483],
  [-70.4071736, 45.7779647],
  [-70.4083549, 45.7799348],
  [-70.4081836, 45.7803636],
  [-70.4088425, 45.7809783],
  [-70.4091228, 45.7826361],
  [-70.4101861, 45.784235],
  [-70.4110336, 45.7847064],
  [-70.4148033, 45.7843853],
  [-70.4151306, 45.7844814],
  [-70.4157411, 45.7853444],
  [-70.4156853, 45.7861061],
  [-70.4151144, 45.7867517],
  [-70.4151847, 45.7873428],
  [-70.4143594, 45.79016],
  [-70.4147761, 45.7913614],
  [-70.4157208, 45.792215],
  [-70.4166325, 45.7935314],
  [-70.4170208, 45.7934353],
  [-70.4178094, 45.7939772],
  [-70.4168725, 45.7955344],
  [-70.4136293, 45.7959907],
  [-70.4215843, 45.8068791],
  [-70.4713198, 45.7888289],
  [-70.4997817, 45.8264303],
  [-70.5934828, 45.7921329],
  [-70.6318616, 45.778683],
  [-70.6490006, 45.8023736],
  [-70.6518476, 45.802722],
  [-70.6550291, 45.8025311],
  [-70.6560046, 45.8017134],
  [-70.6559475, 45.8003375],
  [-70.6540785, 45.7994303],
  [-70.6530449, 45.7984754],
  [-70.6528113, 45.7975428],
  [-70.6530034, 45.7962714],
  [-70.6538887, 45.7950341],
  [-70.655502, 45.7935625],
  [-70.6562259, 45.7918472],
  [-70.6587567, 45.7880304],
  [-70.6621951, 45.7847953],
  [-70.6629922, 45.7835483],
  [-70.6957281, 45.7825641],
  [-70.7248527, 45.7821106],
  [-70.7996049, 45.7813743],
  [-70.89557, 45.7801169],
  [-70.898331, 45.8417999],
  [-70.8994228, 45.8871875],
  [-70.8966333, 45.8903939],
  [-70.9283711, 45.9057882],
  [-70.9423023, 45.9117771],
  [-70.949434, 45.9153609],
  [-70.9554657, 45.9105399],
  [-70.9632565, 45.9060821],
  [-70.9692515, 45.9011406],
  [-70.9996079, 45.9161824],
  [-71.0045319, 45.9123888],
  [-71.0577141, 45.9386783],
  [-71.0688894, 45.9274428],
  [-71.089508, 45.9375267],
  [-71.090255, 45.9382566],
  [-71.091994, 45.9387973],
  [-71.1298426, 45.9573791],
  [-71.1632852, 45.9244006],
  [-71.1571563, 45.917225],
  [-71.1552118, 45.9145582],
  [-71.1534618, 45.9109471],
  [-71.1530174, 45.9080304],
  [-71.1530729, 45.9065304],
  [-71.1545731, 45.8975304],
  [-71.1552122, 45.8904192],
  [-71.1549344, 45.8865859],
  [-71.1536844, 45.8831136],
  [-71.1486287, 45.8750302],
  [-71.1349106, 45.8600227],
  [-71.153743, 45.8431197],
  [-71.148704, 45.8403795],
  [-71.1575246, 45.8326866],
  [-71.1668823, 45.8201578],
  [-71.1718881, 45.8174791],
  [-71.1780711, 45.8151051],
  [-71.194883, 45.8259726],
  [-71.2288608, 45.8456913],
  [-71.2466298, 45.8320262],
  [-71.2610822, 45.8403585],
  [-71.2733222, 45.829555],
  [-71.3075391, 45.8492294],
  [-71.3277372, 45.8611151],
  [-71.3483842, 45.8722965],
  [-71.3505303, 45.8664214],
  [-71.3516841, 45.8647382],
  [-71.3522237, 45.8614494],
  [-71.3521037, 45.8600333],
  [-71.3507063, 45.8586267],
  [-71.3465515, 45.8568541],
  [-71.3447668, 45.8557021],
  [-71.3426104, 45.8534228],
  [-71.3413859, 45.8510866],
  [-71.3401915, 45.84742],
  [-71.3379694, 45.8396423],
  [-71.3375805, 45.8365589],
  [-71.3377194, 45.8335034],
  [-71.3406997, 45.8270056],
  [-71.3415528, 45.8237008],
  [-71.3413444, 45.8214727],
  [-71.340081, 45.8173367],
  [-71.3395254, 45.8140589],
  [-71.3396921, 45.8119755],
  [-71.3405255, 45.8096422],
  [-71.3428312, 45.80542],
  [-71.3468592, 45.7998088],
  [-71.3505262, 45.7955311],
  [-71.355082, 45.7910867],
  [-71.3610267, 45.7860867],
  [-71.3670825, 45.7816978],
  [-71.3725828, 45.7781145],
  [-71.3789997, 45.7750312],
  [-71.3817221, 45.7740868],
  [-71.3842777, 45.7734757],
  [-71.3858333, 45.7735035],
  [-71.3883334, 45.7740312],
  [-71.3895834, 45.7737535],
  [-71.3907224, 45.7725313],
  [-71.3919447, 45.7695869],
  [-71.3927144, 45.7687422],
  [-71.4086856, 45.7802534],
  [-71.4361424, 45.7990603],
  [-71.4403475, 45.802315],
  [-71.461408, 45.8166167],
  [-71.4979688, 45.7918856],
  [-71.522464, 45.804399],
  [-71.5415558, 45.7878052],
  [-71.5942751, 45.815395],
  [-71.5902726, 45.8185217],
  [-71.5915978, 45.8192899],
  [-71.5930774, 45.8205895],
  [-71.5931807, 45.8212633],
  [-71.5928436, 45.8218116],
  [-71.6002379, 45.8254246],
  [-71.6032079, 45.8228298],
  [-71.6173046, 45.8304973],
  [-71.6193728, 45.829007],
  [-71.6204277, 45.8277048],
  [-71.6355207, 45.8139117],
  [-71.6813894, 45.8384082],
  [-71.6940039, 45.8455654],
  [-71.7240142, 45.8614443],
  [-71.7241354, 45.8613313],
  [-71.7297882, 45.8644774],
  [-71.7699647, 45.8852954],
  [-71.788016, 45.8679831],
  [-71.7980847, 45.858836],
  [-71.8047755, 45.8519843],
  [-71.856916, 45.8034177],
  [-71.8614426, 45.798568],
  [-71.8694414, 45.8031932],
  [-71.8744711, 45.7983511],
  [-71.8790627, 45.8011811],
  [-71.8799327, 45.8028628],
  [-71.8800712, 45.8041293],
  [-71.8805389, 45.8044256],
  [-71.8826527, 45.8042793],
  [-71.8836992, 45.8044075],
  [-71.8848278, 45.8041371],
  [-71.8886269, 45.8014237],
  [-71.8911565, 45.8007287],
  [-71.893434, 45.8010029],
  [-71.9003173, 45.8038477],
  [-71.9020493, 45.8043901],
  [-71.9046686, 45.8048209],
  [-71.9059238, 45.8047048],
  [-71.9090291, 45.8037204],
  [-71.9166237, 45.8019028],
  [-71.9178792, 45.8008958],
  [-71.9176103, 45.7990622],
  [-71.9178398, 45.7981283],
  [-71.9200805, 45.7966511],
  [-71.9212721, 45.7963418],
  [-71.9375076, 45.8050594],
  [-72.0026639, 45.8387803],
  [-72.0296451, 45.853415],
  [-72.061503, 45.8254994],
  [-72.111367, 45.7802805],
  [-72.1493092, 45.7470173],
  [-72.1504735, 45.7462387],
  [-72.1512718, 45.7453052],
  [-72.1879187, 45.7130135],
  [-72.2063388, 45.6963874],
  [-72.2083039, 45.6974382],
  [-72.2093178, 45.698376],
  [-72.2096294, 45.6992113],
  [-72.2093188, 45.7014576],
  [-72.2098562, 45.7031083],
  [-72.2103011, 45.7037751],
  [-72.2124491, 45.705444],
  [-72.2128936, 45.7061295],
  [-72.2146831, 45.7108948],
  [-72.2165195, 45.7123709],
  [-72.21729, 45.7126443],
  [-72.2201633, 45.7128088],
  [-72.2220157, 45.7135858],
  [-72.2232852, 45.7146965],
  [-72.2239368, 45.7164051],
  [-72.2237603, 45.71784],
  [-72.2231856, 45.7184385],
  [-72.215674, 45.7222676],
  [-72.2141259, 45.723271],
  [-72.2127176, 45.7250699],
  [-72.2125417, 45.7264669],
  [-72.2156644, 45.7308502],
  [-72.2153141, 45.7323208],
  [-72.215652, 45.732646],
  [-72.2170301, 45.7327937],
  [-72.2180438, 45.7331455],
  [-72.2188201, 45.7344397],
  [-72.2212208, 45.735185],
  [-72.2223143, 45.7351972],
  [-72.2248621, 45.7345451],
  [-72.2288133, 45.7354588],
  [-72.2327767, 45.735843],
  [-72.2348814, 45.7369629],
  [-72.2359459, 45.7382601],
  [-72.2362648, 45.7394358],
  [-72.2360141, 45.7403404],
  [-72.2351938, 45.7409741],
  [-72.2344404, 45.7411926],
  [-72.2304715, 45.7410352],
  [-72.2277509, 45.7416854],
  [-72.2268746, 45.7422428],
  [-72.226055, 45.7428386],
  [-72.2219661, 45.747935],
  [-72.2214788, 45.7491017],
  [-72.2213902, 45.7504618],
  [-72.2218557, 45.7527734],
  [-72.222738, 45.7544847],
  [-72.223097, 45.7550589],
  [-72.2239953, 45.7545404],
  [-72.2271002, 45.7562153],
  [-72.2426169, 45.7448162],
  [-72.2816345, 45.710827],
  [-72.2978637, 45.6996157],
  [-72.3112598, 45.6887339],
  [-72.2769755, 45.6689824],
  [-72.270709, 45.6656435],
  [-72.2372392, 45.6462396],
  [-72.2791463, 45.6072087],
  [-72.2976749, 45.5894066],
  [-72.3155509, 45.5992243],
  [-72.3434731, 45.5991002],
  [-72.3436625, 45.5938529],
  [-72.3506458, 45.5940191],
  [-72.3506198, 45.5889633],
  [-72.3596753, 45.5893945],
  [-72.3607806, 45.5673171],
  [-72.3781172, 45.5665017],
  [-72.378635, 45.5297098],
  [-72.4028825, 45.5284844],
  [-72.4048857, 45.5038292],
  [-72.4051498, 45.4943055],
  [-72.4050617, 45.4805259],
  [-72.4043315, 45.4698642],
  [-72.4040344, 45.4517687],
  [-72.4042648, 45.4492023],
  [-72.4397097, 45.4504005],
  [-72.4543952, 45.4513822],
  [-72.451257, 45.4665953],
  [-72.450542, 45.478528],
  [-72.448891, 45.4935907],
  [-72.4892835, 45.4947009],
  [-72.5860047, 45.4982895],
  [-72.5845813, 45.5110624],
  [-72.583592, 45.5254861],
  [-72.6062206, 45.5260354],
  [-72.6049236, 45.5376856],
  [-72.6542015, 45.5386407],
  [-72.676213, 45.5387429],
  [-72.6754598, 45.5313858],
  [-72.6905714, 45.5316275],
  [-72.6899857, 45.5237968],
  [-72.7247698, 45.5245569],
  [-72.7731685, 45.5247909],
  [-72.772811, 45.5383015],
  [-72.788545, 45.5383815],
  [-72.7998206, 45.5379843],
  [-72.8121759, 45.4965591],
  [-72.8185098, 45.4651223],
  [-72.8232062, 45.4365426],
  [-72.8239039, 45.4306922],
  [-72.8561551, 45.3393682],
  [-72.8579048, 45.3350787],
  [-72.871423, 45.2968546],
  [-72.8830536, 45.2655474],
  [-72.8990137, 45.2768442],
  [-72.9077398, 45.2826962],
  [-72.9076835, 45.2841217],
  [-72.9096685, 45.2841071],
  [-72.9128866, 45.286678],
  [-72.9123574, 45.2871782],
  [-72.9123523, 45.2876715],
  [-72.9136534, 45.2895741],
  [-72.9134399, 45.2899972],
  [-72.9127962, 45.2902796],
  [-72.9116735, 45.2900919],
  [-72.9090157, 45.2878881],
  [-72.9080636, 45.2876494],
  [-72.9069871, 45.2877108],
  [-72.9237477, 45.3004269],
  [-72.9270658, 45.3005866],
  [-72.9279556, 45.2977894],
  [-72.931208, 45.3004843],
  [-72.9434821, 45.309322],
  [-72.9899987, 45.3419322],
  [-72.9901604, 45.340396],
  [-72.9894078, 45.3382465],
  [-72.9901397, 45.3363015],
  [-72.9893523, 45.3351912],
  [-72.9892346, 45.3347947],
  [-72.9895137, 45.3342231],
  [-72.9947084, 45.3377786],
  [-73.0028493, 45.3375893],
  [-73.002848, 45.3377264],
  [-73.0030903, 45.3375837],
  [-73.0097945, 45.3374376],
  [-73.0104595, 45.3301771],
  [-73.0335902, 45.3257028],
  [-73.0461688, 45.3260898],
  [-73.0488569, 45.325352],
  [-73.0477378, 45.3110596],
  [-73.0165879, 45.2895529],
  [-73.0142559, 45.2892422],
  [-73.0157815, 45.2721019],
  [-73.0461696, 45.29311],
  [-73.0454549, 45.2837391],
  [-73.0455988, 45.2812471],
  [-73.0470916, 45.2776299],
  [-73.0489435, 45.2768605],
  [-73.0498765, 45.2775181],
  [-73.0504443, 45.2680019],
  [-73.0581911, 45.2733135],
  [-73.0589424, 45.2650205],
  [-73.0634469, 45.2677689],
  [-73.0640225, 45.2616992],
  [-73.0639623, 45.2593465],
  [-73.0653873, 45.2445776],
  [-73.0668064, 45.2455614],
  [-73.0682591, 45.2296602],
  [-73.0700152, 45.2308729],
  [-73.0740037, 45.2286776],
  [-73.0582443, 45.2145809],
  [-73.0913157, 45.1961422],
  [-73.0862257, 45.1916095],
  [-73.0858309, 45.1611439],
  [-73.0860168, 45.1400163],
  [-73.10126, 45.1399634],
  [-73.097095, 45.1017718],
  [-73.0984341, 45.1002822],
  [-73.1070928, 45.10454],
  [-73.1076987, 45.0971718],
  [-73.1154082, 45.0967585],
  [-73.1156013, 45.0819375],
  [-73.1145143, 45.0821025],
  [-73.10406, 45.0818109],
  [-73.1115191, 45.0730398],
  [-73.1193611, 45.0572507],
  [-73.1398437, 45.0143666],
];

let polygons = [];
boundaries.map((boundary) =>
    polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;