const boundaries = [
  [-71.24925, 61.159500002],
  [-71.24625, 61.160250002],
  [-71.2345, 61.160500002],
  [-71.2285, 61.161500002],
  [-71.22675, 61.160750002],
  [-71.22675, 61.161500002],
  [-71.22575, 61.161750002],
  [-71.22175, 61.161750002],
  [-71.21775, 61.162750002],
  [-71.2155, 61.162250002],
  [-71.21375, 61.160750002],
  [-71.2135, 61.159000002],
  [-71.21225, 61.158500002],
  [-71.21225, 61.157250002],
  [-71.214, 61.154750002],
  [-71.2195, 61.150500002],
  [-71.2205, 61.149250002],
  [-71.22025, 61.148500002],
  [-71.2225, 61.147000002],
  [-71.222, 61.146250002],
  [-71.22275, 61.145750002],
  [-71.22225, 61.145000002],
  [-71.22325, 61.142500002],
  [-71.21725, 61.138750002],
  [-71.21675, 61.137750002],
  [-71.21775, 61.137250002],
  [-71.217, 61.136500002],
  [-71.21775, 61.134750002],
  [-71.217, 61.134000002],
  [-71.21425, 61.134000002],
  [-71.21275, 61.132500002],
  [-71.211, 61.132000002],
  [-71.20925, 61.132250002],
  [-71.20825, 61.131750002],
  [-71.20675, 61.132250002],
  [-71.20675, 61.131250002],
  [-71.2045, 61.131250002],
  [-71.2035, 61.131750002],
  [-71.204, 61.132750002],
  [-71.20325, 61.133500002],
  [-71.201, 61.134750002],
  [-71.19975, 61.134750002],
  [-71.19875, 61.135750002],
  [-71.1965, 61.135750002],
  [-71.19475, 61.137500002],
  [-71.19475, 61.139250002],
  [-71.19575, 61.140250002],
  [-71.1955, 61.141250002],
  [-71.19425, 61.141750002],
  [-71.194, 61.143500002],
  [-71.1895, 61.143750002],
  [-71.1825, 61.141000002],
  [-71.188, 61.136500002],
  [-71.1925, 61.135500002],
  [-71.19275, 61.134500002],
  [-71.19425, 61.133750002],
  [-71.19425, 61.132000002],
  [-71.1975, 61.131250002],
  [-71.2, 61.129250002],
  [-71.20325, 61.128500002],
  [-71.20325, 61.126500002],
  [-71.2055, 61.124500002],
  [-71.2045, 61.123750002],
  [-71.204, 61.122250002],
  [-71.2045, 61.120750002],
  [-71.20325, 61.119250002],
  [-71.20175, 61.118750002],
  [-71.2005, 61.117250002],
  [-71.20025, 61.115500002],
  [-71.198, 61.113250002],
  [-71.1965, 61.112750002],
  [-71.19625, 61.111750002],
  [-71.195, 61.112250002],
  [-71.19025, 61.111750002],
  [-71.189, 61.109750002],
  [-71.18775, 61.109250002],
  [-71.1885, 61.108500002],
  [-71.188, 61.108000002],
  [-71.18675, 61.108250002],
  [-71.185, 61.106750002],
  [-71.18525, 61.105000002],
  [-71.1845, 61.104250002],
  [-71.18525, 61.103500002],
  [-71.1835, 61.102750002],
  [-71.183, 61.101750002],
  [-71.18375, 61.101250002],
  [-71.18225, 61.100750002],
  [-71.18, 61.097750002],
  [-71.17775, 61.096500002],
  [-71.17575, 61.096500002],
  [-71.17325, 61.095500002],
  [-71.1715, 61.095750002],
  [-71.17125, 61.096500002],
  [-71.16725, 61.095750002],
  [-71.167, 61.094500002],
  [-71.16675, 61.096500002],
  [-71.16875, 61.096500002],
  [-71.168, 61.098000002],
  [-71.17275, 61.101000002],
  [-71.17575, 61.105500002],
  [-71.17525, 61.105750002],
  [-71.1755, 61.106750002],
  [-71.174, 61.107250002],
  [-71.17525, 61.107500002],
  [-71.17375, 61.108250002],
  [-71.17375, 61.109500002],
  [-71.1785, 61.115250002],
  [-71.17825, 61.115750002],
  [-71.18025, 61.117500002],
  [-71.18, 61.119000002],
  [-71.17925, 61.119250002],
  [-71.17975, 61.121250002],
  [-71.179, 61.121500002],
  [-71.17925, 61.122250002],
  [-71.1785, 61.123500002],
  [-71.17575, 61.124250002],
  [-71.1755, 61.125000002],
  [-71.1735, 61.124250002],
  [-71.17325, 61.123500002],
  [-71.1705, 61.124250002],
  [-71.16975, 61.126250002],
  [-71.17025, 61.126750002],
  [-71.16975, 61.127500002],
  [-71.17025, 61.129500002],
  [-71.16925, 61.134250002],
  [-71.16675, 61.137500002],
  [-71.164, 61.139250002],
  [-71.1645, 61.139750002],
  [-71.16375, 61.140500002],
  [-71.163, 61.140250002],
  [-71.16, 61.142250002],
  [-71.14975, 61.141750002],
  [-71.148, 61.142000002],
  [-71.14825, 61.143000002],
  [-71.14525, 61.143250002],
  [-71.1445, 61.142000002],
  [-71.142, 61.140500002],
  [-71.144, 61.139500002],
  [-71.14625, 61.137000002],
  [-71.14625, 61.133750002],
  [-71.1505, 61.132000002],
  [-71.15075, 61.131000002],
  [-71.1495, 61.130500002],
  [-71.15225, 61.130250002],
  [-71.153, 61.129750002],
  [-71.152, 61.129250002],
  [-71.1545, 61.128500002],
  [-71.1545, 61.127750002],
  [-71.15225, 61.127250002],
  [-71.14975, 61.127750002],
  [-71.14475, 61.130250002],
  [-71.14375, 61.129750002],
  [-71.133, 61.134750002],
  [-71.13025, 61.134750002],
  [-71.1275, 61.136750002],
  [-71.12575, 61.136500002],
  [-71.123, 61.134500002],
  [-71.11825, 61.135750002],
  [-71.116, 61.135250002],
  [-71.112, 61.131750002],
  [-71.111, 61.131750002],
  [-71.11225, 61.130750002],
  [-71.11175, 61.130000002],
  [-71.1125, 61.129500002],
  [-71.11275, 61.128250002],
  [-71.11575, 61.126750002],
  [-71.117, 61.125250002],
  [-71.11475, 61.123250002],
  [-71.1145, 61.121750002],
  [-71.11525, 61.121500002],
  [-71.1155, 61.120250002],
  [-71.11375, 61.119500002],
  [-71.11175, 61.120000002],
  [-71.10875, 61.122250002],
  [-71.1077146, 61.124831602],
  [-71.1068738, 61.125057902],
  [-71.1061792, 61.126414502],
  [-71.1041808, 61.127081002],
  [-71.1035, 61.126750002],
  [-71.10025, 61.119000002],
  [-71.0985267, 61.117682002],
  [-71.0919929, 61.118184702],
  [-71.0907757, 61.119393802],
  [-71.0881889, 61.125901502],
  [-71.0862792, 61.127419802],
  [-71.0825481, 61.127377102],
  [-71.0775, 61.128500002],
  [-71.0725, 61.128250002],
  [-71.072, 61.129000002],
  [-71.075, 61.130750002],
  [-71.07475, 61.131250002],
  [-71.06875, 61.131500002],
  [-71.06725, 61.131000002],
  [-71.067, 61.130000002],
  [-71.06475, 61.130750002],
  [-71.0535, 61.131500002],
  [-71.046, 61.134500002],
  [-71.04525, 61.134250002],
  [-71.0435, 61.135750002],
  [-71.0425, 61.135750002],
  [-71.0413569, 61.135086102],
  [-71.0395, 61.135250002],
  [-71.03925, 61.134250002],
  [-71.04025, 61.132750002],
  [-71.0396362, 61.129491202],
  [-71.0392822, 61.128648002],
  [-71.0369325, 61.127309002],
  [-71.03425, 61.127500002],
  [-71.0318873, 61.132418502],
  [-71.0301008, 61.133608802],
  [-71.0265107, 61.133946802],
  [-71.0235, 61.131750002],
  [-71.0194164, 61.131005202],
  [-71.0173939, 61.131330202],
  [-71.0160955, 61.130492402],
  [-71.0155, 61.129000002],
  [-71.0125, 61.128250002],
  [-71.0061717, 61.128446902],
  [-71.0055828, 61.128971902],
  [-70.9997463, 61.129645302],
  [-70.9955621, 61.128422802],
  [-70.9894252, 61.128029102],
  [-70.983, 61.126750002],
  [-70.9809721, 61.125139902],
  [-70.9832156, 61.124266902],
  [-70.97975, 61.123250002],
  [-70.9795, 61.122000002],
  [-70.983, 61.121250002],
  [-70.9865, 61.116250002],
  [-70.9845, 61.115000002],
  [-70.972, 61.118250002],
  [-70.971, 61.116750002],
  [-70.96975, 61.117000002],
  [-70.97025, 61.116250002],
  [-70.9695, 61.115500002],
  [-70.97125, 61.112750002],
  [-70.9705, 61.111250002],
  [-70.96925, 61.110000002],
  [-70.9675, 61.109500002],
  [-70.96225, 61.110750002],
  [-70.9571099, 61.108394202],
  [-70.9547789, 61.108496002],
  [-70.9528828, 61.110011502],
  [-70.95375, 61.113250002],
  [-70.9535, 61.114750002],
  [-70.9512864, 61.116835802],
  [-70.93175, 61.115000002],
  [-70.93025, 61.112750002],
  [-70.93125, 61.110500002],
  [-70.9305, 61.109250002],
  [-70.9269109, 61.107552302],
  [-70.92225, 61.107000002],
  [-70.91975, 61.106000002],
  [-70.9215, 61.102750002],
  [-70.92, 61.101250002],
  [-70.9162116, 61.101597702],
  [-70.91275, 61.101000002],
  [-70.91075, 61.098750002],
  [-70.911, 61.097250002],
  [-70.91025, 61.096500002],
  [-70.91075, 61.094750002],
  [-70.9095, 61.094000002],
  [-70.90975, 61.092750002],
  [-70.9135, 61.092000002],
  [-70.91325, 61.090750002],
  [-70.91675, 61.089500002],
  [-70.91875, 61.087750002],
  [-70.9195, 61.087750002],
  [-70.9195, 61.086750002],
  [-70.92475, 61.083750002],
  [-70.92525, 61.082000002],
  [-70.92425, 61.081250002],
  [-70.9255, 61.081000002],
  [-70.92575, 61.079000002],
  [-70.92775, 61.077250002],
  [-70.92925, 61.074750002],
  [-70.93025, 61.074500002],
  [-70.93025, 61.073750002],
  [-70.9315, 61.073000002],
  [-70.931, 61.072000002],
  [-70.933, 61.072250002],
  [-70.93275, 61.071500002],
  [-70.93425, 61.071250002],
  [-70.93475, 61.070000002],
  [-70.93025, 61.071250002],
  [-70.93025, 61.072000002],
  [-70.929, 61.072000002],
  [-70.9275, 61.073500002],
  [-70.92875, 61.074250002],
  [-70.917, 61.077000002],
  [-70.91175, 61.076500002],
  [-70.9115, 61.076000002],
  [-70.9125, 61.075000002],
  [-70.91, 61.073750002],
  [-70.9075, 61.073500002],
  [-70.90275, 61.074250002],
  [-70.8985, 61.072000002],
  [-70.8965, 61.072250002],
  [-70.89575, 61.074000002],
  [-70.89875, 61.076000002],
  [-70.9005, 61.078750002],
  [-70.90025, 61.079250002],
  [-70.90275, 61.080250002],
  [-70.901, 61.081000002],
  [-70.90375, 61.081000002],
  [-70.898, 61.082000002],
  [-70.89425, 61.085000002],
  [-70.88775, 61.085750002],
  [-70.8865, 61.084750002],
  [-70.887, 61.084500002],
  [-70.88675, 61.083500002],
  [-70.8855, 61.082500002],
  [-70.8855, 61.081500002],
  [-70.88325, 61.079500002],
  [-70.87675, 61.078250002],
  [-70.874, 61.080000002],
  [-70.87225, 61.079250002],
  [-70.87175, 61.078250002],
  [-70.87025, 61.078000002],
  [-70.872, 61.076500002],
  [-70.8705, 61.075750002],
  [-70.87025, 61.076500002],
  [-70.87075, 61.076500002],
  [-70.87, 61.076750002],
  [-70.86925, 61.076000002],
  [-70.869, 61.074500002],
  [-70.8675, 61.072750002],
  [-70.86775, 61.071750002],
  [-70.86525, 61.069000002],
  [-70.8595, 61.068750002],
  [-70.85675, 61.069750002],
  [-70.85275, 61.070000002],
  [-70.85, 61.072250002],
  [-70.851, 61.074250002],
  [-70.8445, 61.077250002],
  [-70.844, 61.078500002],
  [-70.84325, 61.078750002],
  [-70.8395, 61.079000002],
  [-70.837, 61.078250002],
  [-70.832, 61.078000002],
  [-70.82825, 61.079750002],
  [-70.827, 61.077750002],
  [-70.8285, 61.077250002],
  [-70.828, 61.076500002],
  [-70.829, 61.075500002],
  [-70.831, 61.075500002],
  [-70.8315, 61.074750002],
  [-70.83, 61.074000002],
  [-70.8285, 61.074500002],
  [-70.8275, 61.073250002],
  [-70.8235, 61.073783602],
  [-70.81925, 61.072500002],
  [-70.8165, 61.073250002],
  [-70.81425, 61.073000002],
  [-70.814, 61.072000002],
  [-70.8135, 61.072750002],
  [-70.8125, 61.072500002],
  [-70.81125, 61.071250002],
  [-70.81, 61.071500002],
  [-70.81075, 61.070750002],
  [-70.8105, 61.069500002],
  [-70.8115, 61.068750002],
  [-70.81075, 61.065750002],
  [-70.80725, 61.064500002],
  [-70.802, 61.064750002],
  [-70.80175, 61.064250002],
  [-70.799, 61.064500002],
  [-70.79625, 61.063750002],
  [-70.79525, 61.061250002],
  [-70.7953835, 61.060024602],
  [-70.7939324, 61.059480002],
  [-70.7938047, 61.058549902],
  [-70.79605, 61.056683302],
  [-70.7958172, 61.055831002],
  [-70.7987952, 61.055502402],
  [-70.8021212, 61.053087902],
  [-70.8014667, 61.047577902],
  [-70.7993961, 61.044274602],
  [-70.7989454, 61.042248702],
  [-70.7972396, 61.041521502],
  [-70.7919932, 61.042160402],
  [-70.792315, 61.043744702],
  [-70.7945037, 61.044534302],
  [-70.7960272, 61.047988202],
  [-70.7948899, 61.050221302],
  [-70.795126, 61.050891302],
  [-70.7934094, 61.052854202],
  [-70.788753, 61.054032902],
  [-70.78425, 61.053250002],
  [-70.782, 61.054500002],
  [-70.7795, 61.054500002],
  [-70.77775, 61.056250002],
  [-70.7735, 61.055500002],
  [-70.7725, 61.054000002],
  [-70.773, 61.053750002],
  [-70.7725, 61.052500002],
  [-70.77325, 61.052000002],
  [-70.7705, 61.049250002],
  [-70.7639587, 61.048569902],
  [-70.75975, 61.046000002],
  [-70.75875, 61.043750002],
  [-70.75975, 61.042000002],
  [-70.75675, 61.039500002],
  [-70.7575, 61.039250002],
  [-70.75725, 61.038250002],
  [-70.7555, 61.035750002],
  [-70.7465, 61.033250002],
  [-70.743, 61.027500002],
  [-70.7405, 61.025250002],
  [-70.74, 61.022500002],
  [-70.7385, 61.021500002],
  [-70.738, 61.020250002],
  [-70.739, 61.019000002],
  [-70.73775, 61.018250002],
  [-70.73725, 61.016250002],
  [-70.7325, 61.012750002],
  [-70.7335, 61.012250002],
  [-70.7335, 61.011500002],
  [-70.73125, 61.011750002],
  [-70.73025, 61.010500002],
  [-70.73025, 61.009500002],
  [-70.7275, 61.008500002],
  [-70.7265, 61.007500002],
  [-70.723, 61.008250002],
  [-70.72325, 61.011750002],
  [-70.72275, 61.012000002],
  [-70.72425, 61.012750002],
  [-70.7245, 61.014000002],
  [-70.724, 61.014250002],
  [-70.727, 61.016750002],
  [-70.72725, 61.018500002],
  [-70.729, 61.020500002],
  [-70.72875, 61.021000002],
  [-70.73075, 61.022500002],
  [-70.731, 61.024500002],
  [-70.732, 61.025000002],
  [-70.732, 61.026000002],
  [-70.73275, 61.026500002],
  [-70.731, 61.027000002],
  [-70.73275, 61.027250002],
  [-70.7345, 61.029750002],
  [-70.737, 61.031500002],
  [-70.73775, 61.032500002],
  [-70.7375, 61.033250002],
  [-70.7385, 61.033500002],
  [-70.739, 61.034750002],
  [-70.7425, 61.038000002],
  [-70.7415, 61.041750002],
  [-70.746, 61.046500002],
  [-70.746, 61.048500002],
  [-70.74525, 61.049250002],
  [-70.746, 61.049750002],
  [-70.7465, 61.051750002],
  [-70.748, 61.052500002],
  [-70.75075, 61.057000002],
  [-70.75325, 61.057750002],
  [-70.75525, 61.060000002],
  [-70.75525, 61.061000002],
  [-70.75625, 61.061500002],
  [-70.756, 61.062000002],
  [-70.759, 61.063750002],
  [-70.76075, 61.067500002],
  [-70.766, 61.070500002],
  [-70.76825, 61.074000002],
  [-70.7692272, 61.076305802],
  [-70.768577, 61.077481202],
  [-70.7622743, 61.083518302],
  [-70.7608473, 61.083624702],
  [-70.7574678, 61.082185002],
  [-70.753659, 61.082501502],
  [-70.7524574, 61.081775202],
  [-70.7522321, 61.083466402],
  [-70.7502794, 61.084509202],
  [-70.7386781, 61.084916002],
  [-70.7320404, 61.084011102],
  [-70.732, 61.082750002],
  [-70.7346904, 61.078319802],
  [-70.7403423, 61.073966102],
  [-70.7443658, 61.068651402],
  [-70.7441318, 61.068044602],
  [-70.7420611, 61.067468502],
  [-70.738381, 61.069492202],
  [-70.7328129, 61.071086002],
  [-70.7192731, 61.076887702],
  [-70.7144184, 61.080447202],
  [-70.711999, 61.080618202],
  [-70.71175, 61.077250002],
  [-70.7173937, 61.065654902],
  [-70.716, 61.064250002],
  [-70.7155, 61.062250002],
  [-70.7138777, 61.061718902],
  [-70.71375, 61.061000002],
  [-70.711249, 61.059317502],
  [-70.7068401, 61.059556302],
  [-70.7052972, 61.063803802],
  [-70.7026534, 61.066746602],
  [-70.7014045, 61.069342502],
  [-70.6969464, 61.072139202],
  [-70.6935, 61.071000002],
  [-70.69275, 61.069500002],
  [-70.69325, 61.069000002],
  [-70.6915, 61.066000002],
  [-70.68625, 61.064500002],
  [-70.6860289, 61.063652202],
  [-70.6881931, 61.060777602],
  [-70.6864636, 61.057897702],
  [-70.6833787, 61.057988002],
  [-70.6816245, 61.059008002],
  [-70.6745152, 61.058509602],
  [-70.6708358, 61.056994802],
  [-70.67075, 61.056000002],
  [-70.667, 61.055250002],
  [-70.6625, 61.056750002],
  [-70.65775, 61.056500002],
  [-70.656, 61.057250002],
  [-70.65075, 61.057750002],
  [-70.65025, 61.055750002],
  [-70.64825, 61.054250002],
  [-70.6445, 61.054250002],
  [-70.64375, 61.050750002],
  [-70.6445, 61.050750002],
  [-70.6455, 61.049500002],
  [-70.645, 61.048750002],
  [-70.64625, 61.047250002],
  [-70.64475, 61.046250002],
  [-70.6465, 61.045000002],
  [-70.6475, 61.043000002],
  [-70.65025, 61.041750002],
  [-70.6495, 61.041250002],
  [-70.654, 61.041500002],
  [-70.6555, 61.042750002],
  [-70.66025, 61.043500002],
  [-70.66475, 61.043250002],
  [-70.6665, 61.042250002],
  [-70.66725, 61.039750002],
  [-70.66625, 61.038750002],
  [-70.6685, 61.037250002],
  [-70.6695, 61.035500002],
  [-70.67175, 61.034500002],
  [-70.67225, 61.033250002],
  [-70.67525, 61.032250002],
  [-70.6785, 61.029750002],
  [-70.678, 61.029000002],
  [-70.6785, 61.027500002],
  [-70.67775, 61.026750002],
  [-70.67825, 61.025750002],
  [-70.679, 61.026000002],
  [-70.68, 61.025250002],
  [-70.679, 61.024500002],
  [-70.6805, 61.021750002],
  [-70.67825, 61.020250002],
  [-70.6715, 61.020250002],
  [-70.66525, 61.018000002],
  [-70.66225, 61.019750002],
  [-70.6635, 61.020250002],
  [-70.66275, 61.021750002],
  [-70.6565, 61.024000002],
  [-70.656, 61.025000002],
  [-70.6565, 61.025750002],
  [-70.65525, 61.026500002],
  [-70.65425, 61.026250002],
  [-70.65375, 61.027500002],
  [-70.652, 61.028750002],
  [-70.6505, 61.028750002],
  [-70.64775, 61.030500002],
  [-70.64275, 61.032000002],
  [-70.64125, 61.030750002],
  [-70.64, 61.032000002],
  [-70.6395, 61.031750002],
  [-70.638, 61.034000002],
  [-70.63575, 61.033250002],
  [-70.63475, 61.034250002],
  [-70.6365, 61.035000002],
  [-70.63425, 61.035000002],
  [-70.6315, 61.034000002],
  [-70.63175, 61.034500002],
  [-70.63, 61.036250002],
  [-70.62575, 61.036500002],
  [-70.6245, 61.037250002],
  [-70.62525, 61.038250002],
  [-70.62275, 61.038250002],
  [-70.6205, 61.039250002],
  [-70.6165, 61.038250002],
  [-70.61625, 61.037250002],
  [-70.61525, 61.037000002],
  [-70.61475, 61.035000002],
  [-70.6115, 61.032500002],
  [-70.611, 61.030750002],
  [-70.59975, 61.027000002],
  [-70.59875, 61.024500002],
  [-70.59925, 61.024000002],
  [-70.598, 61.023250002],
  [-70.59675, 61.019000002],
  [-70.595, 61.016250002],
  [-70.59075, 61.013750002],
  [-70.58775, 61.013750002],
  [-70.58625, 61.015000002],
  [-70.587, 61.016000002],
  [-70.58675, 61.017000002],
  [-70.5835, 61.019000002],
  [-70.58025, 61.019250002],
  [-70.58, 61.020500002],
  [-70.58075, 61.021250002],
  [-70.58075, 61.022750002],
  [-70.58025, 61.023000002],
  [-70.58, 61.025250002],
  [-70.579, 61.025000002],
  [-70.577, 61.025750002],
  [-70.573, 61.024750002],
  [-70.573, 61.024000002],
  [-70.572, 61.023250002],
  [-70.56875, 61.023000002],
  [-70.56675, 61.020750002],
  [-70.56275, 61.020500002],
  [-70.5615, 61.019250002],
  [-70.5605, 61.019500002],
  [-70.55975, 61.018750002],
  [-70.5565, 61.018250002],
  [-70.551, 61.019000002],
  [-70.5485, 61.018250002],
  [-70.545, 61.016250002],
  [-70.54225, 61.013750002],
  [-70.539, 61.012750002],
  [-70.53625, 61.010250002],
  [-70.5345, 61.009500002],
  [-70.53125, 61.009000002],
  [-70.52625, 61.009250002],
  [-70.525, 61.009750002],
  [-70.52425, 61.011250002],
  [-70.5275, 61.013000002],
  [-70.52975, 61.015500002],
  [-70.52925, 61.018750002],
  [-70.5387617, 61.027598202],
  [-70.5407285, 61.030463602],
  [-70.548116, 61.036846002],
  [-70.54875, 61.038250002],
  [-70.55075, 61.039500002],
  [-70.5541134, 61.045417302],
  [-70.5535041, 61.047299802],
  [-70.5488895, 61.049920702],
  [-70.5477253, 61.049785602],
  [-70.5453111, 61.047921402],
  [-70.5445, 61.045000002],
  [-70.54175, 61.043000002],
  [-70.5405472, 61.040437702],
  [-70.5395429, 61.039764902],
  [-70.5358422, 61.039817602],
  [-70.532412, 61.041965002],
  [-70.5324012, 61.045651302],
  [-70.5313361, 61.047128602],
  [-70.529, 61.044750002],
  [-70.52125, 61.041250002],
  [-70.5173336, 61.040056502],
  [-70.5143069, 61.040335102],
  [-70.5134378, 61.040969502],
  [-70.5145, 61.043000002],
  [-70.51675, 61.044750002],
  [-70.51975, 61.045500002],
  [-70.5205, 61.047000002],
  [-70.5143988, 61.047862902],
  [-70.5076598, 61.047583102],
  [-70.506456, 61.048082402],
  [-70.5059045, 61.049561602],
  [-70.5165514, 61.053864202],
  [-70.5172403, 61.055461402],
  [-70.5158004, 61.059093502],
  [-70.5136352, 61.060113402],
  [-70.5069516, 61.059639402],
  [-70.5041138, 61.060388602],
  [-70.5020043, 61.062253602],
  [-70.50225, 61.064250002],
  [-70.503, 61.064750002],
  [-70.5025719, 61.065759502],
  [-70.4967571, 61.068231502],
  [-70.4929464, 61.070679902],
  [-70.4885, 61.068750002],
  [-70.48825, 61.068000002],
  [-70.487, 61.067500002],
  [-70.4865, 61.065750002],
  [-70.48325, 61.063500002],
  [-70.47975, 61.059750002],
  [-70.479, 61.057750002],
  [-70.4795, 61.056750002],
  [-70.479, 61.054500002],
  [-70.473, 61.053250002],
  [-70.471, 61.051250002],
  [-70.469, 61.050500002],
  [-70.469, 61.049750002],
  [-70.46675, 61.047750002],
  [-70.46525, 61.047250002],
  [-70.461, 61.044000002],
  [-70.45975, 61.043750002],
  [-70.45825, 61.042250002],
  [-70.46075, 61.040000002],
  [-70.4605, 61.038250002],
  [-70.461, 61.037500002],
  [-70.46, 61.035250002],
  [-70.458, 61.034250002],
  [-70.45825, 61.032500002],
  [-70.45775, 61.032000002],
  [-70.4585, 61.031500002],
  [-70.457, 61.029750002],
  [-70.457, 61.028500002],
  [-70.45875, 61.027000002],
  [-70.459, 61.025500002],
  [-70.45825, 61.024750002],
  [-70.45575, 61.023500002],
  [-70.45275, 61.023250002],
  [-70.452, 61.022000002],
  [-70.448, 61.019250002],
  [-70.4468176, 61.017182002],
  [-70.4435698, 61.016759902],
  [-70.4414281, 61.017379502],
  [-70.4406223, 61.018457902],
  [-70.4431632, 61.023204302],
  [-70.4459506, 61.024676702],
  [-70.4459775, 61.027108302],
  [-70.4404736, 61.029129902],
  [-70.4333711, 61.027279802],
  [-70.42025, 61.020250002],
  [-70.416, 61.016750002],
  [-70.41525, 61.015250002],
  [-70.4155, 61.013000002],
  [-70.41625, 61.012250002],
  [-70.4155, 61.010500002],
  [-70.414, 61.010250002],
  [-70.41075, 61.008000002],
  [-70.40625, 61.007500002],
  [-70.4035, 61.007750002],
  [-70.40225, 61.009250002],
  [-70.403, 61.010000002],
  [-70.4035, 61.012250002],
  [-70.403, 61.014000002],
  [-70.40375, 61.014750002],
  [-70.40275, 61.016500002],
  [-70.4035, 61.018500002],
  [-70.4025, 61.019000002],
  [-70.40325, 61.020500002],
  [-70.4030085, 61.023137502],
  [-70.408, 61.030250002],
  [-70.407, 61.032000002],
  [-70.40775, 61.032750002],
  [-70.41025, 61.033500002],
  [-70.414, 61.033500002],
  [-70.41675, 61.035000002],
  [-70.41825, 61.035000002],
  [-70.4175, 61.037750002],
  [-70.41825, 61.039500002],
  [-70.4175, 61.040750002],
  [-70.4195, 61.041750002],
  [-70.419, 61.042250002],
  [-70.42, 61.043000002],
  [-70.41975, 61.044750002],
  [-70.42175, 61.045000002],
  [-70.4235, 61.047000002],
  [-70.42125, 61.048750002],
  [-70.421, 61.049750002],
  [-70.42525, 61.053250002],
  [-70.4272371, 61.057111102],
  [-70.427, 61.063250002],
  [-70.4280364, 61.065955902],
  [-70.426, 61.066750002],
  [-70.426, 61.067750002],
  [-70.42725, 61.068500002],
  [-70.42675, 61.069250002],
  [-70.42725, 61.069750002],
  [-70.42575, 61.070750002],
  [-70.42225, 61.070250002],
  [-70.4151911, 61.065795802],
  [-70.40775, 61.062750002],
  [-70.4045, 61.062250002],
  [-70.4025, 61.061000002],
  [-70.4005, 61.058500002],
  [-70.3995, 61.056250002],
  [-70.39975, 61.055500002],
  [-70.39875, 61.054500002],
  [-70.393, 61.055000002],
  [-70.39175, 61.054000002],
  [-70.37725, 61.048750002],
  [-70.3755, 61.047750002],
  [-70.3745, 61.045750002],
  [-70.3745, 61.043750002],
  [-70.3735, 61.042250002],
  [-70.37225, 61.041250002],
  [-70.36875, 61.040750002],
  [-70.368, 61.039750002],
  [-70.36375, 61.039500002],
  [-70.3675, 61.040750002],
  [-70.36425, 61.040750002],
  [-70.362, 61.042000002],
  [-70.363, 61.043500002],
  [-70.36425, 61.043750002],
  [-70.3658867, 61.045438002],
  [-70.36725, 61.048500002],
  [-70.369, 61.049250002],
  [-70.36875, 61.050250002],
  [-70.3699484, 61.052405002],
  [-70.367765, 61.053462202],
  [-70.3617771, 61.053077102],
  [-70.3606792, 61.053691402],
  [-70.37625, 61.063000002],
  [-70.38175, 61.067250002],
  [-70.38975, 61.072000002],
  [-70.4, 61.080250002],
  [-70.4103787, 61.085486402],
  [-70.4104581, 61.087487602],
  [-70.4073858, 61.089628902],
  [-70.4045664, 61.090610002],
  [-70.4022425, 61.090633802],
  [-70.3959704, 61.088487802],
  [-70.3909925, 61.089663902],
  [-70.3849247, 61.088537402],
  [-70.3793508, 61.089539402],
  [-70.3773992, 61.088770702],
  [-70.3735, 61.085500002],
  [-70.3716803, 61.083124002],
  [-70.36575, 61.078750002],
  [-70.364, 61.076500002],
  [-70.36375, 61.075000002],
  [-70.36425, 61.074500002],
  [-70.363, 61.073750002],
  [-70.363, 61.072250002],
  [-70.3615, 61.070000002],
  [-70.3585, 61.069500002],
  [-70.355, 61.071250002],
  [-70.35275, 61.070750002],
  [-70.35005, 61.069189902],
  [-70.34726, 61.065620102],
  [-70.3483099, 61.065610102],
  [-70.3425101, 61.059529902],
  [-70.3411801, 61.059289902],
  [-70.3351601, 61.054230002],
  [-70.33088, 61.052859902],
  [-70.3277301, 61.053280102],
  [-70.32781, 61.055069902],
  [-70.3267499, 61.055079902],
  [-70.32688, 61.058150102],
  [-70.3279301, 61.058140002],
  [-70.32932, 61.059660002],
  [-70.33056, 61.064000002],
  [-70.3316299, 61.064749902],
  [-70.33143, 61.066030002],
  [-70.3324901, 61.066020002],
  [-70.33388, 61.067800002],
  [-70.33343, 61.069600102],
  [-70.3351701, 61.073160102],
  [-70.33414, 61.073940102],
  [-70.3349799, 61.075209902],
  [-70.33711, 61.075450002],
  [-70.3426357, 61.081784302],
  [-70.3463955, 61.084026502],
  [-70.3471893, 61.085521202],
  [-70.3465209, 61.087096702],
  [-70.34556, 61.088046002],
  [-70.3414243, 61.087177502],
  [-70.3379799, 61.083630102],
  [-70.3337201, 61.082649902],
  [-70.3315299, 61.081000002],
  [-70.32889, 61.080900002],
  [-70.32813, 61.081680002],
  [-70.32701, 61.086549902],
  [-70.3259501, 61.086560002],
  [-70.3239599, 61.089390102],
  [-70.3208201, 61.090060102],
  [-70.3171, 61.089720002],
  [-70.3139699, 61.084370002],
  [-70.3115001, 61.082089902],
  [-70.31044, 61.082110002],
  [-70.30988, 61.081340002],
  [-70.3061899, 61.081380002],
  [-70.3051699, 61.082410102],
  [-70.30411, 61.082419902],
  [-70.3033999, 61.083960102],
  [-70.3039301, 61.084220002],
  [-70.30255, 61.089350002],
  [-70.30347, 61.092410102],
  [-70.30453, 61.092400102],
  [-70.30545, 61.095460002],
  [-70.30378, 61.099830002],
  [-70.3006299, 61.100370002],
  [-70.30065, 61.100879902],
  [-70.29531, 61.099649902],
  [-70.2952701, 61.098630102],
  [-70.2920599, 61.097640102],
  [-70.2895801, 61.095109902],
  [-70.2905099, 61.092020002],
  [-70.2891401, 61.091010002],
  [-70.28067, 61.090840102],
  [-70.28019, 61.091860102],
  [-70.2791401, 61.091869902],
  [-70.2791699, 61.092639902],
  [-70.27707, 61.093170102],
  [-70.2770499, 61.092659902],
  [-70.2754601, 61.092419902],
  [-70.27486, 61.090630102],
  [-70.2737901, 61.090260002],
  [-70.26906, 61.090940002],
  [-70.26729, 61.092749902],
  [-70.26722, 61.097860102],
  [-70.2624899, 61.098549902],
  [-70.25772, 61.098219902],
  [-70.2576999, 61.097700002],
  [-70.25397, 61.096970102],
  [-70.25443, 61.095180102],
  [-70.2554799, 61.095170102],
  [-70.25542, 61.093630102],
  [-70.2564799, 61.093620102],
  [-70.25642, 61.092089902],
  [-70.25429, 61.091850102],
  [-70.2542699, 61.091340002],
  [-70.2500399, 61.091250002],
  [-70.24216, 61.092470002],
  [-70.24108, 61.091470002],
  [-70.2400101, 61.091470002],
  [-70.23884, 61.088670002],
  [-70.2353599, 61.087419902],
  [-70.23453, 61.079749902],
  [-70.2394599, 61.077400102],
  [-70.24575, 61.076060102],
  [-70.2451399, 61.074270102],
  [-70.24409, 61.074290102],
  [-70.2446, 61.073770002],
  [-70.24354, 61.073780002],
  [-70.24298, 61.073020002],
  [-70.2355899, 61.073089902],
  [-70.2339649, 61.071490802],
  [-70.2360785, 61.070167402],
  [-70.2415755, 61.069357202],
  [-70.242923, 61.067584302],
  [-70.2432239, 61.066004802],
  [-70.2425802, 61.065143102],
  [-70.2398658, 61.064717502],
  [-70.2377415, 61.066134502],
  [-70.234158, 61.066041102],
  [-70.2349413, 61.064598102],
  [-70.234555, 61.063238102],
  [-70.2316904, 61.060694302],
  [-70.2315724, 61.058010202],
  [-70.2280721, 61.058235302],
  [-70.2262109, 61.059176702],
  [-70.2241524, 61.061659502],
  [-70.2230249, 61.064661402],
  [-70.2205121, 61.066275702],
  [-70.2206183, 61.068594802],
  [-70.219599, 61.069482302],
  [-70.2172279, 61.069134602],
  [-70.212492, 61.066192702],
  [-70.2106619, 61.066253902],
  [-70.2104044, 61.067442602],
  [-70.2116052, 61.070780402],
  [-70.2110803, 61.073130702],
  [-70.2090799, 61.075041802],
  [-70.2085032, 61.077028402],
  [-70.2068317, 61.077458402],
  [-70.1999002, 61.076290902],
  [-70.1992214, 61.076584402],
  [-70.1990533, 61.077920202],
  [-70.199697, 61.079865902],
  [-70.1988495, 61.080670102],
  [-70.1948438, 61.078425802],
  [-70.1933688, 61.076137302],
  [-70.1943014, 61.074905002],
  [-70.1970918, 61.073581002],
  [-70.1984418, 61.071553102],
  [-70.1984255, 61.070152402],
  [-70.1974, 61.068820002],
  [-70.1945397, 61.068621102],
  [-70.1940039, 61.069472802],
  [-70.1920581, 61.070172602],
  [-70.1919752, 61.071277202],
  [-70.1902789, 61.072643902],
  [-70.1809526, 61.075434502],
  [-70.1800565, 61.076477402],
  [-70.1801316, 61.077376202],
  [-70.1789153, 61.078112202],
  [-70.1798083, 61.079728502],
  [-70.1783509, 61.081110902],
  [-70.1765079, 61.081691402],
  [-70.1730334, 61.085150902],
  [-70.1701123, 61.086072102],
  [-70.1681543, 61.085899402],
  [-70.1664753, 61.086740202],
  [-70.1661842, 61.087909302],
  [-70.1679611, 61.089073902],
  [-70.1663089, 61.090396502],
  [-70.1628401, 61.091260002],
  [-70.1565101, 61.091570002],
  [-70.1437, 61.088099902],
  [-70.13947, 61.088250002],
  [-70.1356406, 61.090929302],
  [-70.1302761, 61.090748002],
  [-70.1274923, 61.089603602],
  [-70.1289511, 61.086164002],
  [-70.129, 61.083490002],
  [-70.1299477, 61.082918602],
  [-70.1291442, 61.080914002],
  [-70.1297144, 61.079930402],
  [-70.13483, 61.077299502],
  [-70.1368261, 61.074594102],
  [-70.140904, 61.071601702],
  [-70.1417776, 61.070230402],
  [-70.1442182, 61.069757402],
  [-70.14523, 61.064660002],
  [-70.1436201, 61.063920002],
  [-70.1446808, 61.062170602],
  [-70.1426965, 61.060957202],
  [-70.1371102, 61.060544802],
  [-70.1301587, 61.062372502],
  [-70.1209825, 61.067407502],
  [-70.1123643, 61.069830102],
  [-70.1084238, 61.070088102],
  [-70.1059914, 61.068605202],
  [-70.10454, 61.063990002],
  [-70.10489, 61.058859902],
  [-70.106957, 61.056945802],
  [-70.1055193, 61.054339302],
  [-70.1072799, 61.051680002],
  [-70.1111799, 61.050100002],
  [-70.11032, 61.047820102],
  [-70.1115901, 61.046530002],
  [-70.1168301, 61.045460002],
  [-70.1144153, 61.043995302],
  [-70.1159668, 61.041910802],
  [-70.1194202, 61.040429102],
  [-70.13056, 61.037920102],
  [-70.131805, 61.035848502],
  [-70.1374901, 61.032880002],
  [-70.13823, 61.031460002],
  [-70.1406101, 61.031570002],
  [-70.14635, 61.029860002],
  [-70.1476, 61.028059902],
  [-70.14699, 61.025760002],
  [-70.1481713, 61.024845302],
  [-70.14611, 61.023210002],
  [-70.1458, 61.021930102],
  [-70.14756, 61.019740002],
  [-70.14966, 61.019340002],
  [-70.1496399, 61.018829902],
  [-70.1517499, 61.018810102],
  [-70.1529601, 61.015980002],
  [-70.1515299, 61.012929902],
  [-70.1522801, 61.011640002],
  [-70.1534343, 61.011607102],
  [-70.1539064, 61.010889502],
  [-70.1516811, 61.008668502],
  [-70.1528657, 61.007884002],
  [-70.1526296, 61.006469502],
  [-70.1537948, 61.005042302],
  [-70.15493, 61.004709902],
  [-70.1549471, 61.003942002],
  [-70.1562238, 61.002699002],
  [-70.1555693, 61.001903202],
  [-70.1564485, 61.001365202],
  [-70.1565886, 61.001939602],
  [-70.1600808, 61.002472702],
  [-70.1602568, 60.999217202],
  [-70.1580477, 60.996941002],
  [-70.1546316, 60.996348202],
  [-70.151546, 60.996930602],
  [-70.1514924, 60.997690002],
  [-70.1493012, 60.998656602],
  [-70.1474395, 60.998416302],
  [-70.1446796, 61.000041202],
  [-70.14312, 60.999128202],
  [-70.1407528, 60.999182902],
  [-70.1400447, 61.000077602],
  [-70.1377626, 60.999740002],
  [-70.1373732, 60.999032102],
  [-70.138886, 60.998530102],
  [-70.1384487, 60.997643402],
  [-70.1327567, 60.996040202],
  [-70.1281501, 60.992650002],
  [-70.1249599, 60.991910102],
  [-70.12223, 60.989120102],
  [-70.1211699, 60.989130102],
  [-70.12168, 60.988359902],
  [-70.1169, 60.987630002],
  [-70.11501, 60.986110102],
  [-70.1154901, 60.984819902],
  [-70.11224, 60.982290002],
  [-70.1124901, 60.981780102],
  [-70.11143, 60.981790102],
  [-70.1100301, 60.978980002],
  [-70.1119401, 60.973589902],
  [-70.11109, 60.972070002],
  [-70.1131699, 60.971280002],
  [-70.1136099, 60.968720002],
  [-70.11466, 60.968720002],
  [-70.1151601, 60.967809902],
  [-70.11621, 60.967930002],
  [-70.1156001, 60.965370002],
  [-70.1134801, 60.965139902],
  [-70.11423, 60.963850002],
  [-70.11554, 60.963579902],
  [-70.1132899, 60.959510002],
  [-70.10277, 60.959850002],
  [-70.10441, 60.961630002],
  [-70.10232, 60.961899902],
  [-70.10175, 60.960630002],
  [-70.09964, 60.960640002],
  [-70.09863, 60.961930002],
  [-70.09707, 60.961940002],
  [-70.09657, 60.963229902],
  [-70.0944701, 60.963239902],
  [-70.09429, 60.965809902],
  [-70.0967099, 60.967190002],
  [-70.10042, 60.967799902],
  [-70.10044, 60.968560102],
  [-70.1014899, 60.968560102],
  [-70.10323, 60.973149902],
  [-70.10112, 60.973040002],
  [-70.0973999, 60.971909902],
  [-70.0973799, 60.971410002],
  [-70.09476, 60.971689902],
  [-70.09465, 60.968620002],
  [-70.0917099, 60.967359902],
  [-70.0905701, 60.964809902],
  [-70.0862401, 60.961260002],
  [-70.0828199, 60.953359902],
  [-70.08399, 60.949260002],
  [-70.0850399, 60.949250002],
  [-70.0851401, 60.944380002],
  [-70.0834999, 60.942610002],
  [-70.0840201, 60.942349902],
  [-70.08318, 60.940820002],
  [-70.08423, 60.940810002],
  [-70.08342, 60.940300002],
  [-70.0811, 60.933669902],
  [-70.08678, 60.930810002],
  [-70.0922401, 60.929099902],
  [-70.0932601, 60.927950002],
  [-70.09456, 60.927669902],
  [-70.0950401, 60.926390002],
  [-70.09423, 60.925630102],
  [-70.09318, 60.925640102],
  [-70.0904899, 60.916190102],
  [-70.09098, 60.915160002],
  [-70.0893601, 60.913900002],
  [-70.0895901, 60.912869902],
  [-70.08854, 60.912879902],
  [-70.0879399, 60.910840102],
  [-70.08478, 60.910610002],
  [-70.08475, 60.909590002],
  [-70.0836901, 60.909590002],
  [-70.08, 60.901429902],
  [-70.0789399, 60.900930002],
  [-70.07766, 60.894290102],
  [-70.07932, 60.888900002],
  [-70.0814, 60.888500102],
  [-70.10086, 60.889110002],
  [-70.10242, 60.888710002],
  [-70.10243, 60.889230002],
  [-70.10555, 60.888299902],
  [-70.1144999, 60.888869902],
  [-70.1150699, 60.890140002],
  [-70.11611, 60.889749902],
  [-70.1256101, 60.890950102],
  [-70.13135, 60.890140002],
  [-70.1318499, 60.889490002],
  [-70.1360501, 60.889460002],
  [-70.13603, 60.888690002],
  [-70.1397101, 60.888910002],
  [-70.14125, 60.887880002],
  [-70.13914, 60.887639902],
  [-70.13911, 60.886869902],
  [-70.14067, 60.886350002],
  [-70.1396201, 60.886360002],
  [-70.13959, 60.885590002],
  [-70.1406399, 60.885580002],
  [-70.1392801, 60.884309902],
  [-70.1394901, 60.882780002],
  [-70.1414099, 60.882670002],
  [-70.1426001, 60.881600102],
  [-70.14347, 60.881629902],
  [-70.1436299, 60.880950102],
  [-70.1394001, 60.880220002],
  [-70.1397201, 60.879869902],
  [-70.13666, 60.876910002],
  [-70.13346, 60.875920002],
  [-70.1329, 60.874639902],
  [-70.12868, 60.873910002],
  [-70.12867, 60.873399902],
  [-70.12658, 60.873930102],
  [-70.1266101, 60.874690002],
  [-70.12191, 60.875629902],
  [-70.1082899, 60.876380102],
  [-70.10576, 60.878959902],
  [-70.0958299, 60.880320002],
  [-70.09585, 60.880830102],
  [-70.0921699, 60.880859902],
  [-70.09221, 60.881890002],
  [-70.08589, 60.881430002],
  [-70.08587, 60.880920002],
  [-70.0811499, 60.880950102],
  [-70.0784601, 60.878930002],
  [-70.07319, 60.878199902],
  [-70.07262, 60.877179902],
  [-70.0736701, 60.877170102],
  [-70.07417, 60.876390102],
  [-70.07206, 60.876160102],
  [-70.06985, 60.872849902],
  [-70.0688099, 60.873250002],
  [-70.0651201, 60.872639902],
  [-70.0634501, 60.869830102],
  [-70.0603401, 60.871000002],
  [-70.05719, 60.870910002],
  [-70.05716, 60.870399902],
  [-70.0532, 60.869150102],
  [-70.05449, 60.868749902],
  [-70.05609, 60.869380102],
  [-70.0560399, 60.867849902],
  [-70.0544699, 60.867859902],
  [-70.0554601, 60.866320002],
  [-70.0538801, 60.866069902],
  [-70.05649, 60.865540002],
  [-70.0565, 60.866050102],
  [-70.05809, 60.866289902],
  [-70.0560101, 60.866820102],
  [-70.05863, 60.866550002],
  [-70.05864, 60.867060102],
  [-70.0601499, 60.865250002],
  [-70.06274, 60.864210002],
  [-70.0658901, 60.864189902],
  [-70.06741, 60.862639902],
  [-70.06584, 60.862650002],
  [-70.06817, 60.861859902],
  [-70.06842, 60.861350002],
  [-70.0673699, 60.861100002],
  [-70.06841, 60.861100002],
  [-70.06839, 60.860580002],
  [-70.07574, 60.860519902],
  [-70.07519, 60.859760002],
  [-70.0762301, 60.859749902],
  [-70.0756801, 60.858990002],
  [-70.0799001, 60.859470002],
  [-70.07643, 60.857700102],
  [-70.0750299, 60.855160102],
  [-70.07451, 60.855160102],
  [-70.0744501, 60.853629902],
  [-70.0712699, 60.852499902],
  [-70.0639199, 60.852430002],
  [-70.0639101, 60.851920102],
  [-70.06128, 60.851680002],
  [-70.06151, 60.850660002],
  [-70.06043, 60.849890002],
  [-70.0613799, 60.846820102],
  [-70.06108, 60.845800002],
  [-70.06265, 60.845790002],
  [-70.06228, 60.842460002],
  [-70.0606701, 60.841200002],
  [-70.0623699, 60.837340002],
  [-70.06531, 60.836470102],
  [-70.0654501, 60.835269902],
  [-70.0591499, 60.835070002],
  [-70.05997, 60.836080002],
  [-70.06129, 60.836330002],
  [-70.0607999, 60.837360102],
  [-70.05243, 60.837820102],
  [-70.04565, 60.839010002],
  [-70.04563, 60.838499902],
  [-70.0445799, 60.838240002],
  [-70.0452201, 60.841829902],
  [-70.0421001, 60.842609902],
  [-70.0421201, 60.843389902],
  [-70.03794, 60.844059902],
  [-70.0332101, 60.843460002],
  [-70.0332299, 60.843970002],
  [-70.0280001, 60.844389902],
  [-70.02536, 60.844030102],
  [-70.0248701, 60.845059902],
  [-70.0212101, 60.845470102],
  [-70.01962, 60.844710102],
  [-70.00337, 60.844959902],
  [-70.00335, 60.844450002],
  [-69.9996599, 60.843839902],
  [-69.98814, 60.844560002],
  [-69.9859999, 60.843040102],
  [-69.98705, 60.843040102],
  [-69.98535, 60.838949902],
  [-69.98167, 60.838470102],
  [-69.9831901, 60.837049902],
  [-69.98845, 60.837399902],
  [-69.9884201, 60.836630002],
  [-69.9920701, 60.835829902],
  [-69.9940999, 60.833770002],
  [-69.9972099, 60.832470102],
  [-69.99614, 60.831970002],
  [-69.99461, 60.833260102],
  [-69.99304, 60.833269902],
  [-69.99202, 60.834300002],
  [-69.9909701, 60.834310002],
  [-69.9909899, 60.834820102],
  [-69.98838, 60.835350002],
  [-69.9884001, 60.835860002],
  [-69.9863001, 60.835880002],
  [-69.9863199, 60.836389902],
  [-69.98317, 60.836159902],
  [-69.9826, 60.834619902],
  [-69.9810801, 60.836430002],
  [-69.98003, 60.836430002],
  [-69.98005, 60.837200002],
  [-69.97426, 60.836480102],
  [-69.97424, 60.835709902],
  [-69.9731901, 60.835719902],
  [-69.96715, 60.826550002],
  [-69.96712, 60.825520002],
  [-69.96549, 60.823740002],
  [-69.96338, 60.823259902],
  [-69.9628301, 60.822240102],
  [-69.9507201, 60.820520002],
  [-69.9507101, 60.820010102],
  [-69.94598, 60.819790102],
  [-69.9459599, 60.819020102],
  [-69.94335, 60.819300002],
  [-69.94333, 60.818780002],
  [-69.9407101, 60.818800102],
  [-69.9401701, 60.818039902],
  [-69.9359499, 60.817300002],
  [-69.9359401, 60.816790102],
  [-69.93277, 60.816039902],
  [-69.93064, 60.814269902],
  [-69.9290501, 60.814020102],
  [-69.9290401, 60.813250102],
  [-69.92745, 60.813269902],
  [-69.92211, 60.809719902],
  [-69.92001, 60.809479902],
  [-69.91944, 60.807950002],
  [-69.91841, 60.807950002],
  [-69.91409, 60.803890102],
  [-69.9130401, 60.803900102],
  [-69.91197, 60.802880002],
  [-69.90934, 60.802640002],
  [-69.90933, 60.802130102],
  [-69.9067199, 60.802400002],
  [-69.9066901, 60.801379902],
  [-69.90196, 60.800900102],
  [-69.9019399, 60.800379902],
  [-69.8998399, 60.800020102],
  [-69.88887, 60.800990002],
  [-69.88833, 60.800479902],
  [-69.88731, 60.801510002],
  [-69.88573, 60.801010102],
  [-69.88575, 60.801780102],
  [-69.8846999, 60.801780102],
  [-69.8847199, 60.802290002],
  [-69.8821, 60.802310002],
  [-69.8805999, 60.804880002],
  [-69.87955, 60.804890102],
  [-69.8833601, 60.810240102],
  [-69.88232, 60.810240102],
  [-69.8844899, 60.813039902],
  [-69.88344, 60.813049902],
  [-69.8834701, 60.814070002],
  [-69.88242, 60.814070002],
  [-69.8827199, 60.815360102],
  [-69.88144, 60.816650002],
  [-69.88039, 60.816650002],
  [-69.87942, 60.819220002],
  [-69.8783699, 60.819220002],
  [-69.87788, 60.820250102],
  [-69.8768199, 60.820000002],
  [-69.87633, 60.821290002],
  [-69.8690199, 60.822240102],
  [-69.8674699, 60.823010102],
  [-69.86484, 60.822770002],
  [-69.86173, 60.823939902],
  [-69.86127, 60.826499902],
  [-69.85763, 60.827550002],
  [-69.85664, 60.829599902],
  [-69.8555899, 60.829609902],
  [-69.8538099, 60.831670002],
  [-69.8541001, 60.832430002],
  [-69.85304, 60.832180002],
  [-69.8525799, 60.834740002],
  [-69.8510199, 60.835010002],
  [-69.8479901, 60.839379902],
  [-69.84904, 60.839370102],
  [-69.84905, 60.839880002],
  [-69.8454, 60.840680002],
  [-69.8438999, 60.843240002],
  [-69.84285, 60.843250102],
  [-69.84318, 60.845810102],
  [-69.8416899, 60.849140102],
  [-69.8419901, 60.850420002],
  [-69.83937, 60.850440002],
  [-69.83939, 60.851200002],
  [-69.8367799, 60.851729902],
  [-69.83529, 60.855069902],
  [-69.83536, 60.857880002],
  [-69.8335799, 60.859949902],
  [-69.8338701, 60.860969902],
  [-69.83492, 60.860969902],
  [-69.8352001, 60.861720102],
  [-69.8344301, 60.862240002],
  [-69.83548, 60.862230002],
  [-69.8352399, 60.863260102],
  [-69.8368263, 60.863403002],
  [-69.8370332, 60.862785702],
  [-69.8380548, 60.862708202],
  [-69.8397681, 60.863646002],
  [-69.8425467, 60.862871102],
  [-69.8436928, 60.861282002],
  [-69.8426843, 60.860879802],
  [-69.8431027, 60.860273702],
  [-69.8426707, 60.859396902],
  [-69.8437899, 60.859110002],
  [-69.8430755, 60.857661002],
  [-69.8448837, 60.857705902],
  [-69.8462623, 60.856945702],
  [-69.84971, 60.851739902],
  [-69.84883, 60.851150102],
  [-69.8519199, 60.849080002],
  [-69.8543097, 60.848706802],
  [-69.8645899, 60.852069902],
  [-69.8690236, 60.852109702],
  [-69.8737818, 60.850923502],
  [-69.8729001, 60.849200002],
  [-69.87474, 60.849190002],
  [-69.8731101, 60.847410002],
  [-69.8737901, 60.843560002],
  [-69.87639, 60.842520002],
  [-69.87583, 60.841250102],
  [-69.87922, 60.840710102],
  [-69.87941, 60.837900002],
  [-69.8813799, 60.833530002],
  [-69.88243, 60.833530002],
  [-69.8829299, 60.832499902],
  [-69.88711, 60.832220002],
  [-69.88662, 60.833240102],
  [-69.8871401, 60.833240102],
  [-69.8954701, 60.831140102],
  [-69.8959399, 60.829090002],
  [-69.89489, 60.829100002],
  [-69.8942699, 60.825520002],
  [-69.89686, 60.824220002],
  [-69.89734, 60.822939902],
  [-69.89996, 60.822920102],
  [-69.90208, 60.823670002],
  [-69.9025901, 60.823410002],
  [-69.9020599, 60.823159902],
  [-69.9083501, 60.823630002],
  [-69.9089086, 60.824929802],
  [-69.9131914, 60.827802302],
  [-69.9204299, 60.829988202],
  [-69.9254453, 60.832821802],
  [-69.9266133, 60.835802902],
  [-69.9243935, 60.838431102],
  [-69.9239899, 60.840661102],
  [-69.9211013, 60.843159502],
  [-69.9210719, 60.844385102],
  [-69.9198568, 60.845260802],
  [-69.9195886, 60.846384502],
  [-69.9109917, 60.851109902],
  [-69.9062312, 60.851694302],
  [-69.9031166, 60.853097002],
  [-69.9020569, 60.851514102],
  [-69.8938823, 60.851056702],
  [-69.8912957, 60.851136802],
  [-69.8901379, 60.851924202],
  [-69.8821825, 60.851806202],
  [-69.872886, 60.852770802],
  [-69.8709199, 60.853560002],
  [-69.8701608, 60.854667602],
  [-69.8709977, 60.855979102],
  [-69.8661091, 60.857068202],
  [-69.8650404, 60.858065802],
  [-69.8620184, 60.858434902],
  [-69.85954, 60.859519902],
  [-69.85483, 60.860059902],
  [-69.8549901, 60.861408702],
  [-69.8539281, 60.861804402],
  [-69.846611, 60.862373902],
  [-69.8459137, 60.862718602],
  [-69.8462248, 60.863847002],
  [-69.8429418, 60.864536502],
  [-69.83903, 60.867580002],
  [-69.8375599, 60.871430002],
  [-69.83629, 60.872720102],
  [-69.8336501, 60.872990002],
  [-69.8343301, 60.878620102],
  [-69.8327701, 60.879260002],
  [-69.8290901, 60.879170102],
  [-69.8265301, 60.881480002],
  [-69.8294301, 60.882230002],
  [-69.8299999, 60.883500102],
  [-69.8321299, 60.884770002],
  [-69.8324599, 60.887330002],
  [-69.83141, 60.887330002],
  [-69.8309099, 60.888360002],
  [-69.8246401, 60.889680002],
  [-69.82574, 60.891720102],
  [-69.8236599, 60.892240002],
  [-69.82369, 60.893520102],
  [-69.81692, 60.895360002],
  [-69.8147501, 60.893070102],
  [-69.81159, 60.892570002],
  [-69.81104, 60.891810002],
  [-69.80759, 60.890820002],
  [-69.80783, 60.889270002],
  [-69.8067699, 60.888890002],
  [-69.8009899, 60.888930002],
  [-69.79656, 60.890360002],
  [-69.7964001, 60.894460002],
  [-69.79535, 60.894460002],
  [-69.79537, 60.895230002],
  [-69.79328, 60.895749902],
  [-69.79517, 60.897790002],
  [-69.7944399, 60.899850102],
  [-69.79338, 60.899850102],
  [-69.79393, 60.900610002],
  [-69.7928699, 60.900360002],
  [-69.7928799, 60.901130002],
  [-69.7913199, 60.901140002],
  [-69.78769, 60.903340002],
  [-69.7777301, 60.904030002],
  [-69.77771, 60.903520102],
  [-69.77299, 60.903549902],
  [-69.773, 60.904070102],
  [-69.7682901, 60.904600002],
  [-69.7672701, 60.906140002],
  [-69.76622, 60.906150002],
  [-69.766, 60.907940002],
  [-69.76734, 60.909209902],
  [-69.76577, 60.909219902],
  [-69.76422, 60.910510102],
  [-69.7631701, 60.910510102],
  [-69.7658301, 60.911790002],
  [-69.76745, 60.913560002],
  [-69.75746, 60.913620102],
  [-69.75801, 60.914380002],
  [-69.75644, 60.914640102],
  [-69.7551701, 60.916700002],
  [-69.76124, 60.917559902],
  [-69.76178, 60.918200102],
  [-69.7586299, 60.918470002],
  [-69.7580799, 60.917710002],
  [-69.7528401, 60.917989902],
  [-69.7522899, 60.917230002],
  [-69.7465199, 60.917510102],
  [-69.7411501, 60.912680002],
  [-69.73432, 60.912590002],
  [-69.72957, 60.911720002],
  [-69.72956, 60.911209902],
  [-69.72745, 60.911219902],
  [-69.7271501, 60.909429902],
  [-69.72775, 60.908999902],
  [-69.72687, 60.908670002],
  [-69.7305801, 60.909800002],
  [-69.73109, 60.909150002],
  [-69.7337001, 60.908630102],
  [-69.7333899, 60.906329902],
  [-69.7347001, 60.906190102],
  [-69.7349301, 60.905040002],
  [-69.7343801, 60.904020002],
  [-69.7372501, 60.902850102],
  [-69.74198, 60.902950102],
  [-69.74401, 60.900000002],
  [-69.7450599, 60.900120002],
  [-69.7458301, 60.899089902],
  [-69.74554, 60.898070102],
  [-69.7468499, 60.897810002],
  [-69.7489, 60.895749902],
  [-69.7498599, 60.891649902],
  [-69.7482601, 60.890890002],
  [-69.7479699, 60.889620102],
  [-69.7427001, 60.888880002],
  [-69.73975, 60.885820002],
  [-69.73997, 60.884539902],
  [-69.74102, 60.884539902],
  [-69.74021, 60.883519902],
  [-69.74123, 60.881979902],
  [-69.7406299, 60.878649902],
  [-69.7424099, 60.876340002],
  [-69.73768, 60.876100002],
  [-69.7381799, 60.875079902],
  [-69.73713, 60.875079902],
  [-69.73658, 60.873810002],
  [-69.7339599, 60.874079902],
  [-69.73397, 60.874600102],
  [-69.73133, 60.873969902],
  [-69.7284701, 60.875140002],
  [-69.7287399, 60.875650002],
  [-69.7255801, 60.874900002],
  [-69.72557, 60.874380102],
  [-69.7219101, 60.875170102],
  [-69.72189, 60.874399902],
  [-69.7198001, 60.874160102],
  [-69.7192799, 60.874930102],
  [-69.71667, 60.875460002],
  [-69.7161301, 60.874950102],
  [-69.7161599, 60.875969902],
  [-69.71141, 60.875240002],
  [-69.7075801, 60.879849902],
  [-69.70786, 60.880360002],
  [-69.70575, 60.880120002],
  [-69.7041499, 60.879100002],
  [-69.70156, 60.880920002],
  [-69.7012501, 60.878350002],
  [-69.70252, 60.876810002],
  [-69.69989, 60.876570002],
  [-69.6998299, 60.873749902],
  [-69.6987901, 60.874010002],
  [-69.69875, 60.872220002],
  [-69.6976999, 60.872230002],
  [-69.6976901, 60.871720102],
  [-69.6935, 60.872000002],
  [-69.69397, 60.869430002],
  [-69.68822, 60.870490102],
  [-69.6861499, 60.872289902],
  [-69.6809099, 60.872570002],
  [-69.6808901, 60.871550002],
  [-69.6803699, 60.871550002],
  [-69.6795999, 60.872330002],
  [-69.6804, 60.873089902],
  [-69.6814401, 60.872820102],
  [-69.6814601, 60.873590002],
  [-69.6769199, 60.873959902],
  [-69.67729, 60.874890002],
  [-69.6746701, 60.875160102],
  [-69.6752201, 60.876440002],
  [-69.67627, 60.876430002],
  [-69.6763, 60.877700002],
  [-69.67263, 60.878240002],
  [-69.6726601, 60.879519902],
  [-69.6705699, 60.880030002],
  [-69.67004, 60.879529902],
  [-69.67161, 60.879270102],
  [-69.6705601, 60.879280102],
  [-69.67051, 60.877230002],
  [-69.6694599, 60.877230002],
  [-69.66917, 60.875700002],
  [-69.67094, 60.872620102],
  [-69.6693599, 60.872370002],
  [-69.6693499, 60.871859902],
  [-69.6714401, 60.871600102],
  [-69.6714599, 60.872360002],
  [-69.67251, 60.872360002],
  [-69.6720001, 60.873130002],
  [-69.67331, 60.872870002],
  [-69.67406, 60.871069902],
  [-69.6651299, 60.870600102],
  [-69.6651399, 60.871370002],
  [-69.66095, 60.871650002],
  [-69.6596799, 60.873700002],
  [-69.66048, 60.874220002],
  [-69.6567899, 60.873460002],
  [-69.6567701, 60.872690002],
  [-69.65572, 60.872440002],
  [-69.6546899, 60.873720102],
  [-69.6505, 60.874000002],
  [-69.65051, 60.874510102],
  [-69.6494699, 60.874519902],
  [-69.64948, 60.875540002],
  [-69.6484301, 60.875550002],
  [-69.6484699, 60.877340002],
  [-69.64583, 60.876840102],
  [-69.64637, 60.877600102],
  [-69.64427, 60.877610102],
  [-69.64374, 60.877100002],
  [-69.6437799, 60.879409902],
  [-69.64274, 60.879409902],
  [-69.6412001, 60.881470002],
  [-69.63962, 60.880959902],
  [-69.63963, 60.881730102],
  [-69.63858, 60.881730102],
  [-69.63859, 60.882250002],
  [-69.6354599, 60.883030002],
  [-69.6360099, 60.884560002],
  [-69.63496, 60.884570002],
  [-69.6349701, 60.885079902],
  [-69.63863, 60.884290102],
  [-69.63759, 60.884560002],
  [-69.6378601, 60.885060102],
  [-69.6370801, 60.885580002],
  [-69.6381299, 60.885580002],
  [-69.63814, 60.886089902],
  [-69.6365699, 60.886089902],
  [-69.63658, 60.886610102],
  [-69.6392001, 60.886340002],
  [-69.6392199, 60.887620102],
  [-69.64027, 60.887600002],
  [-69.63718, 60.890189902],
  [-69.6387601, 60.890429902],
  [-69.63718, 60.890440002],
  [-69.6372001, 60.891209902],
  [-69.6403399, 60.890680002],
  [-69.6403499, 60.891189902],
  [-69.6387701, 60.890950102],
  [-69.63983, 60.891840102],
  [-69.64194, 60.891950102],
  [-69.64195, 60.892470002],
  [-69.6388, 60.892740102],
  [-69.6393201, 60.892220002],
  [-69.63249, 60.892000002],
  [-69.6333101, 60.893529902],
  [-69.6327899, 60.894040002],
  [-69.63363, 60.896979902],
  [-69.63573, 60.896850102],
  [-69.63574, 60.897360002],
  [-69.63706, 60.897610102],
  [-69.6373499, 60.898879902],
  [-69.63629, 60.898630102],
  [-69.6363101, 60.899659902],
  [-69.63736, 60.899649902],
  [-69.63737, 60.900160002],
  [-69.6358, 60.900170102],
  [-69.6387601, 60.903740102],
  [-69.6387799, 60.904759902],
  [-69.6422101, 60.905639902],
  [-69.64328, 60.906529902],
  [-69.63907, 60.906300102],
  [-69.6383, 60.907070102],
  [-69.6391001, 60.907580002],
  [-69.63805, 60.907580002],
  [-69.63751, 60.906820002],
  [-69.63358, 60.907600002],
  [-69.6338899, 60.909390002],
  [-69.63493, 60.909390002],
  [-69.63603, 60.911680002],
  [-69.6334, 60.911439902],
  [-69.63394, 60.912209902],
  [-69.6328899, 60.912470002],
  [-69.6328801, 60.911960102],
  [-69.6313001, 60.911960102],
  [-69.6318801, 60.914250002],
  [-69.63293, 60.914250002],
  [-69.63218, 60.916559902],
  [-69.6338, 60.918600002],
  [-69.63302, 60.919120002],
  [-69.6393499, 60.919980102],
  [-69.6430099, 60.919070102],
  [-69.64307, 60.921889902],
  [-69.6441199, 60.921879902],
  [-69.6446699, 60.923160002],
  [-69.64782, 60.922889902],
  [-69.6483201, 60.921610002],
  [-69.65042, 60.921850102],
  [-69.65017, 60.922360002],
  [-69.6517601, 60.922870102],
  [-69.65205, 60.924400002],
  [-69.6531001, 60.924400002],
  [-69.6528801, 60.926190102],
  [-69.65474, 60.927200102],
  [-69.65948, 60.927690002],
  [-69.6600299, 60.928710002],
  [-69.6626701, 60.929209902],
  [-69.66427, 60.930480002],
  [-69.6626899, 60.930490002],
  [-69.66326, 60.932540102],
  [-69.66063, 60.932290002],
  [-69.66197, 60.933820002],
  [-69.66329, 60.934070002],
  [-69.66549, 60.938669902],
  [-69.66707, 60.938789902],
  [-69.6692301, 60.941210102],
  [-69.67238, 60.941200102],
  [-69.67184, 60.940430102],
  [-69.6736899, 60.940669902],
  [-69.6719099, 60.943500002],
  [-69.67296, 60.943490002],
  [-69.6740299, 60.944380002],
  [-69.67876, 60.944100102],
  [-69.67983, 60.945250002],
  [-69.6819299, 60.945490002],
  [-69.68197, 60.946770102],
  [-69.6809099, 60.946779902],
  [-69.68199, 60.947540102],
  [-69.68094, 60.947540102],
  [-69.6806999, 60.948830002],
  [-69.68361, 60.949579902],
  [-69.6839299, 60.952140002],
  [-69.68341, 60.952390002],
  [-69.68319, 60.954700002],
  [-69.6842499, 60.954689902],
  [-69.6842599, 60.955200102],
  [-69.6811101, 60.955730002],
  [-69.6803501, 60.957019902],
  [-69.68065, 60.958930002],
  [-69.6801399, 60.959579902],
  [-69.6782201, 60.959930002],
  [-69.6785799, 60.960860002],
  [-69.67542, 60.960370002],
  [-69.67442, 60.962930002],
  [-69.67284, 60.962940002],
  [-69.67287, 60.964220102],
  [-69.67182, 60.964220102],
  [-69.67183, 60.964730002],
  [-69.6744601, 60.964980002],
  [-69.6744799, 60.965740002],
  [-69.67184, 60.965500002],
  [-69.67129, 60.964229902],
  [-69.66864, 60.963340102],
  [-69.66549, 60.963740002],
  [-69.66609, 60.967320102],
  [-69.66452, 60.967830002],
  [-69.66774, 60.970510002],
  [-69.6687901, 60.970630002],
  [-69.67208, 60.976500002],
  [-69.6742, 60.977129902],
  [-69.67529, 60.979300002],
  [-69.6763501, 60.979300002],
  [-69.6769199, 60.981340102],
  [-69.67797, 60.981340102],
  [-69.6782499, 60.982100002],
  [-69.6814401, 60.983369902],
  [-69.68173, 60.984640002],
  [-69.6806801, 60.984650002],
  [-69.6804899, 60.988489902],
  [-69.6823401, 60.988730002],
  [-69.68132, 60.990269902],
  [-69.68027, 60.990280002],
  [-69.6802499, 60.989259902],
  [-69.6755, 60.989020102],
  [-69.67549, 60.988510002],
  [-69.6739001, 60.988269902],
  [-69.6738901, 60.987489902],
  [-69.6702, 60.987259902],
  [-69.67019, 60.986750002],
  [-69.6670101, 60.986249902],
  [-69.6660001, 60.988300002],
  [-69.66706, 60.988300002],
  [-69.66709, 60.989819902],
  [-69.66919, 60.989570102],
  [-69.6692, 60.990080002],
  [-69.67185, 60.990579902],
  [-69.6734699, 60.992620002],
  [-69.6718801, 60.992630002],
  [-69.67405, 60.995170002],
  [-69.6751, 60.995170002],
  [-69.6754, 60.996450102],
  [-69.67672, 60.996699902],
  [-69.67673, 60.997460102],
  [-69.6714401, 60.996470102],
  [-69.67094, 60.997489902],
  [-69.6720001, 60.997740002],
  [-69.6693599, 60.997760002],
  [-69.6693499, 60.996990002],
  [-69.66829, 60.996990002],
  [-69.666237, 60.998293502],
  [-69.6677793, 60.998429602],
  [-69.67154, 61.001200002],
  [-69.6726, 61.001070002],
  [-69.67261, 61.001829902],
  [-69.66893, 61.002110002],
  [-69.6689001, 61.000829902],
  [-69.6667801, 61.000330002],
  [-69.66676, 60.999300002],
  [-69.6620199, 60.999589902],
  [-69.6620099, 60.999070002],
  [-69.6599199, 60.999589902],
  [-69.65993, 61.000100002],
  [-69.6583401, 61.000110002],
  [-69.6586601, 61.002410002],
  [-69.6600001, 61.003690102],
  [-69.66531, 61.005450002],
  [-69.6647899, 61.005970002],
  [-69.66585, 61.005960002],
  [-69.6661299, 61.006980002],
  [-69.6714599, 61.009259902],
  [-69.6722599, 61.010279902],
  [-69.67438, 61.010520002],
  [-69.67441, 61.012059902],
  [-69.6781201, 61.012810102],
  [-69.6776, 61.013320002],
  [-69.67865, 61.013320002],
  [-69.67973, 61.014340002],
  [-69.67552, 61.015120002],
  [-69.6760399, 61.014350102],
  [-69.6644, 61.012620002],
  [-69.6638999, 61.013900102],
  [-69.66496, 61.013900102],
  [-69.665661, 61.014509202],
  [-69.6652679, 61.015078002],
  [-69.6692, 61.015030102],
  [-69.6735, 61.018970002],
  [-69.6745601, 61.018970002],
  [-69.67409, 61.021780002],
  [-69.6751399, 61.021780002],
  [-69.67517, 61.023059902],
  [-69.6704401, 61.023590102],
  [-69.67284, 61.024860002],
  [-69.6725899, 61.025630002],
  [-69.6662499, 61.025410002],
  [-69.66626, 61.025920102],
  [-69.6652101, 61.025920102],
  [-69.66575, 61.026430002],
  [-69.66364, 61.026440002],
  [-69.66363, 61.025930102],
  [-69.6599199, 61.025179902],
  [-69.66072, 61.025690102],
  [-69.6594301, 61.026970002],
  [-69.6562499, 61.026220002],
  [-69.6557, 61.025200002],
  [-69.65307, 61.025470102],
  [-69.65361, 61.025980002],
  [-69.64673, 61.024729902],
  [-69.6476, 61.024330002],
  [-69.64514, 61.024489902],
  [-69.64517, 61.025760002],
  [-69.6441099, 61.025770002],
  [-69.6441199, 61.026279902],
  [-69.6454499, 61.026530002],
  [-69.6452101, 61.027810102],
  [-69.6465301, 61.028059902],
  [-69.64682, 61.029080002],
  [-69.64203, 61.027839902],
  [-69.6398999, 61.026300002],
  [-69.63885, 61.026310002],
  [-69.63883, 61.025800102],
  [-69.6340801, 61.025049902],
  [-69.6335301, 61.024030102],
  [-69.6289701, 61.024140102],
  [-69.6287799, 61.024560002],
  [-69.6304, 61.025070002],
  [-69.62828, 61.025080002],
  [-69.62939, 61.027890002],
  [-69.6325599, 61.028130002],
  [-69.63258, 61.028640002],
  [-69.63414, 61.028379902],
  [-69.63363, 61.028890002],
  [-69.6352101, 61.028880002],
  [-69.6352199, 61.029389902],
  [-69.6370801, 61.029900002],
  [-69.6375501, 61.031340002],
  [-69.6384201, 61.031430002],
  [-69.63737, 61.031939902],
  [-69.6357899, 61.031440002],
  [-69.6353101, 61.033739902],
  [-69.63637, 61.033739902],
  [-69.6361099, 61.034250102],
  [-69.63905, 61.036160102],
  [-69.64061, 61.035380102],
  [-69.64327, 61.036260102],
  [-69.6419818, 61.037442302],
  [-69.6448483, 61.038971502],
  [-69.6445187, 61.039722702],
  [-69.6466058, 61.040611902],
  [-69.6476998, 61.041911802],
  [-69.6423486, 61.043858002],
  [-69.6422638, 61.044556202],
  [-69.6431405, 61.045014002],
  [-69.6446214, 61.044312902],
  [-69.6426853, 61.045462602],
  [-69.6397715, 61.045806602],
  [-69.6361991, 61.044665502],
  [-69.6353079, 61.045171102],
  [-69.6366754, 61.045915602],
  [-69.6351283, 61.045251002],
  [-69.6345204, 61.046052202],
  [-69.6357933, 61.046704202],
  [-69.6375266, 61.046066302],
  [-69.6375187, 61.046575702],
  [-69.634224, 61.046869202],
  [-69.6336039, 61.048062802],
  [-69.6387202, 61.049536402],
  [-69.6391983, 61.050379202],
  [-69.6458379, 61.052021902],
  [-69.6477797, 61.053615902],
  [-69.6493242, 61.054015702],
  [-69.646457, 61.054309202],
  [-69.6423764, 61.053260602],
  [-69.6406779, 61.053658302],
  [-69.638475, 61.054956102],
  [-69.638929, 61.056461602],
  [-69.6432533, 61.056789002],
  [-69.6443025, 61.057494902],
  [-69.6441226, 61.058077702],
  [-69.6407445, 61.058094102],
  [-69.638746, 61.056853202],
  [-69.6376702, 61.057641502],
  [-69.6387067, 61.058331002],
  [-69.6324387, 61.059019302],
  [-69.6310201, 61.060083002],
  [-69.6297775, 61.060074902],
  [-69.630461, 61.061374402],
  [-69.6289108, 61.061339702],
  [-69.6281918, 61.059913002],
  [-69.6293279, 61.058699902],
  [-69.6291657, 61.057184702],
  [-69.6272084, 61.054787902],
  [-69.6249118, 61.053077102],
  [-69.6236739, 61.053037002],
  [-69.6239892, 61.053708202],
  [-69.6221038, 61.054030902],
  [-69.6231838, 61.055230602],
  [-69.6221623, 61.055452902],
  [-69.622905, 61.056099802],
  [-69.6226024, 61.056439502],
  [-69.6246146, 61.057656702],
  [-69.6231885, 61.058678402],
  [-69.6240075, 61.059373102],
  [-69.6229072, 61.059632702],
  [-69.6211937, 61.058709002],
  [-69.6217382, 61.059515402],
  [-69.6204752, 61.059663402],
  [-69.62091, 61.060613902],
  [-69.619075, 61.061242202],
  [-69.618708, 61.062204302],
  [-69.6175193, 61.062592002],
  [-69.6179933, 61.062974302],
  [-69.6142767, 61.063231602],
  [-69.6076765, 61.061100002],
  [-69.6062108, 61.061533602],
  [-69.6045221, 61.063456302],
  [-69.6054138, 61.063748602],
  [-69.6054149, 61.064731102],
  [-69.6104179, 61.065410902],
  [-69.61095, 61.067055502],
  [-69.6105858, 61.068396402],
  [-69.6097527, 61.068748502],
  [-69.6113399, 61.069824902],
  [-69.6113494, 61.070572202],
  [-69.6040385, 61.073440602],
  [-69.6057768, 61.073752702],
  [-69.6053678, 61.074394502],
  [-69.6065515, 61.075084502],
  [-69.6077037, 61.074454302],
  [-69.6105068, 61.074487102],
  [-69.6097131, 61.075892602],
  [-69.6077108, 61.075760602],
  [-69.6071643, 61.076508702],
  [-69.6125349, 61.077575202],
  [-69.6133741, 61.078050202],
  [-69.6131184, 61.078519402],
  [-69.6081858, 61.079984802],
  [-69.599923, 61.080341102],
  [-69.5935465, 61.081676602],
  [-69.5866368, 61.081299902],
  [-69.5843009, 61.080004402],
  [-69.5736172, 61.080810402],
  [-69.572036, 61.079902702],
  [-69.5710686, 61.078254302],
  [-69.5685604, 61.077899602],
  [-69.5668204, 61.076724902],
  [-69.5615869, 61.077324802],
  [-69.5588762, 61.078651202],
  [-69.5577442, 61.077958202],
  [-69.5560044, 61.078848602],
  [-69.5500139, 61.078971602],
  [-69.5493469, 61.078710402],
  [-69.5494172, 61.077552302],
  [-69.5509261, 61.077001102],
  [-69.5483348, 61.075440902],
  [-69.5432601, 61.075600002],
  [-69.5409863, 61.077338002],
  [-69.5344383, 61.076774602],
  [-69.5316262, 61.074293102],
  [-69.5274878, 61.074431802],
  [-69.5190157, 61.072974802],
  [-69.5101099, 61.070369902],
  [-69.5089098, 61.069699702],
  [-69.5093337, 61.069351402],
  [-69.5088502, 61.068643702],
  [-69.5050924, 61.068450302],
  [-69.4947199, 61.064628202],
  [-69.4926955, 61.056001802],
  [-69.4807257, 61.035377602],
  [-69.4583265, 60.986550002],
  [-69.4326002, 60.951822602],
  [-69.4023836, 60.930508002],
  [-69.3728459, 60.920524002],
  [-69.3898163, 60.878971102],
  [-69.3770294, 60.853752102],
  [-69.3634109, 60.807594302],
  [-69.4211807, 60.788276702],
  [-69.4921665, 60.766336902],
  [-69.5473824, 60.750135502],
  [-69.5887717, 60.736313702],
  [-69.6098336, 60.731511902],
  [-69.6194305, 60.706218702],
  [-69.6507568, 60.696456902],
  [-69.6962296, 60.690578102],
  [-69.6744045, 60.665063302],
  [-69.6847477, 60.642545902],
  [-69.6643753, 60.631454502],
  [-69.6648482, 60.604953702],
  [-69.6269465, 60.584000102],
  [-69.7014618, 60.545494102],
  [-69.7582791, 60.542681002],
  [-69.8038577, 60.524921102],
  [-69.7913666, 60.498630502],
  [-69.7169342, 60.455688502],
  [-69.7598231, 60.419477402],
  [-69.7093201, 60.399192802],
  [-69.6680992, 60.357989202],
  [-69.6927719, 60.338192002],
  [-69.7500007, 60.303639002],
  [-69.6790674, 60.276503302],
  [-69.6443863, 60.260211902],
  [-69.5468963, 60.198268902],
  [-69.5814947, 60.137733902],
  [-69.5674762, 60.112571902],
  [-69.5404519, 60.092962502],
  [-69.557076, 60.072350202],
  [-69.5879821, 59.961289602],
  [-69.6155529, 59.955946602],
  [-69.6137737, 59.928773002],
  [-69.6240016, 59.924093302],
  [-69.618854, 59.907406002],
  [-69.5775311, 59.893436402],
  [-69.5592843, 59.890445702],
  [-69.5114807, 59.884111202],
  [-69.4899778, 59.869197602],
  [-69.5044746, 59.858587002],
  [-69.5267939, 59.832909502],
  [-69.5473604, 59.812683502],
  [-69.5487299, 59.789105702],
  [-69.542207, 59.771801502],
  [-69.539849, 59.757374202],
  [-69.5484637, 59.743963602],
  [-69.5708623, 59.728192702],
  [-69.5836618, 59.722306002],
  [-69.5993276, 59.717692902],
  [-69.6108299, 59.710203502],
  [-69.6132993, 59.704102902],
  [-69.5986027, 59.695004702],
  [-69.5611165, 59.689652102],
  [-69.5320687, 59.686754902],
  [-69.4994848, 59.677123602],
  [-69.4848327, 59.668012402],
  [-69.4825881, 59.663541102],
  [-69.5006498, 59.622938902],
  [-69.5302067, 59.596057301],
  [-69.5728869, 59.580970401],
  [-69.638768, 59.559175701],
  [-69.6603366, 59.539563801],
  [-69.6769027, 59.515297601],
  [-69.6463668, 59.481853001],
  [-69.6275412, 59.459874301],
  [-69.6056805, 59.430954801],
  [-69.6013171, 59.402201101],
  [-69.6036302, 59.380267701],
  [-69.6064062, 59.362024101],
  [-69.6017927, 59.348469401],
  [-69.59152, 59.341768701],
  [-69.5403319, 59.344179401],
  [-69.4879661, 59.360681801],
  [-69.4574238, 59.372025901],
  [-69.3874117, 59.363115601],
  [-69.3628694, 59.354371201],
  [-69.3627878, 59.350530301],
  [-69.353943, 59.347895601],
  [-69.3495848, 59.339990001],
  [-69.3303376, 59.328217101],
  [-69.3042233, 59.334201601],
  [-69.2819259, 59.341916201],
  [-69.2689647, 59.342679001],
  [-69.2360313, 59.333671901],
  [-69.2243565, 59.320320201],
  [-69.2226685, 59.305558901],
  [-69.2347696, 59.297420601],
  [-69.2487743, 59.293296801],
  [-69.2545012, 59.286459001],
  [-69.234565, 59.276690801],
  [-69.2214228, 59.269308801],
  [-69.2059646, 59.254219101],
  [-69.2087354, 59.240437001],
  [-69.2228562, 59.221521401],
  [-69.2388211, 59.212877301],
  [-69.2737288, 59.196270801],
  [-69.3153852, 59.188332601],
  [-69.3616829, 59.183197801],
  [-69.3934, 59.182947201],
  [-69.4509828, 59.193717501],
  [-69.4651394, 59.191414401],
  [-69.4817193, 59.173478401],
  [-69.4712155, 59.152337201],
  [-69.436938, 59.143062501],
  [-69.3915308, 59.143015601],
  [-69.3603097, 59.153248501],
  [-69.3347733, 59.162927201],
  [-69.3037189, 59.168696801],
  [-69.2722279, 59.170769201],
  [-69.2436922, 59.173484801],
  [-69.2031157, 59.165447101],
  [-69.1786134, 59.159142201],
  [-69.1549802, 59.147624401],
  [-69.1520795, 59.128090101],
  [-69.1548799, 59.108017901],
  [-69.1471094, 59.096401601],
  [-69.1294533, 59.079893401],
  [-69.1099094, 59.065659401],
  [-69.0817166, 59.057595901],
  [-69.0830508, 59.043492601],
  [-69.1020732, 59.027024601],
  [-69.1360146, 59.009374901],
  [-69.176229, 58.990418101],
  [-69.1985384, 58.971985501],
  [-69.1912301, 58.945186201],
  [-69.1698604, 58.926949501],
  [-69.1371457, 58.922368601],
  [-69.1048438, 58.921467801],
  [-69.0612102, 58.921626801],
  [-68.9842527, 58.917906901],
  [-68.9511866, 58.909587401],
  [-68.9127262, 58.897339301],
  [-68.8808335, 58.900069001],
  [-68.8503233, 58.909412401],
  [-68.8192609, 58.927642101],
  [-68.6693146, 58.923450001],
  [-68.6274332, 58.919340801],
  [-68.5764494, 58.909526901],
  [-68.5241739, 58.893066601],
  [-68.4693478, 58.871469301],
  [-68.3389055, 58.816117301],
  [-68.3197222, 58.791783701],
  [-68.3144417, 58.771567801],
  [-68.3270772, 58.752789701],
  [-68.3426004, 58.734683001],
  [-68.3486001, 58.713468401],
  [-68.348308, 58.693509701],
  [-68.3252975, 58.681841801],
  [-68.324024, 58.675216401],
  [-68.2895622, 58.628316201],
  [-68.2761485, 58.603484101],
  [-68.1907171, 58.571758301],
  [-68.1504876, 58.564137101],
  [-68.1241847, 58.562487601],
  [-68.0617038, 58.568619201],
  [-68.033522, 58.576703301],
  [-68.0307102, 58.580201701],
  [-68.0035141, 58.588185301],
  [-68, 58.587462001],
  [-67.9543927, 58.552344601],
  [-67.9192804, 58.528674901],
  [-67.9228899, 58.524511401],
  [-67.9228638, 58.509778201],
  [-67.9012548, 58.499292001],
  [-67.8992035, 58.494228301],
  [-67.9074513, 58.450552501],
  [-67.9236372, 58.428477901],
  [-67.9301154, 58.423147201],
  [-67.9349118, 58.417595101],
  [-67.9341197, 58.406698601],
  [-67.9247702, 58.392427001],
  [-67.9119856, 58.388043901],
  [-67.9036112, 58.389383601],
  [-67.8887633, 58.386263001],
  [-67.8672975, 58.391116501],
  [-67.8533711, 58.403209401],
  [-67.8377372, 58.421244901],
  [-67.8218179, 58.445565001],
  [-67.8080734, 58.481296301],
  [-67.7836656, 58.485450901],
  [-67.7644896, 58.483591801],
  [-67.7177087, 58.469673101],
  [-67.6862925, 58.442162501],
  [-67.6782498, 58.410493401],
  [-67.6785251, 58.409422301],
  [-67.6863844, 58.400415201],
  [-67.6927914, 58.369269201],
  [-67.6995, 58.353633101],
  [-67.7029365, 58.341750201],
  [-67.704338, 58.320300401],
  [-67.6987302, 58.298978501],
  [-67.6895759, 58.287694001],
  [-67.6684989, 58.276253501],
  [-67.6299343, 58.265118501],
  [-67.591085, 58.260255201],
  [-67.558071, 58.270422801],
  [-67.5155181, 58.285541201],
  [-67.4569351, 58.303119801],
  [-67.4193574, 58.308521101],
  [-67.3819741, 58.317600601],
  [-67.3240267, 58.336211801],
  [-67.2726646, 58.360977401],
  [-67.227779, 58.390061301],
  [-67.1848144, 58.416886001],
  [-67.1119287, 58.438185301],
  [-67.0825372, 58.443753601],
  [-67.0476217, 58.441103801],
  [-67.0134019, 58.443441901],
  [-66.9891159, 58.470465301],
  [-66.9683277, 58.494988101],
  [-66.9569782, 58.509931901],
  [-66.9302335, 58.515886401],
  [-66.8862588, 58.499311401],
  [-66.8725266, 58.491340801],
  [-66.8463581, 58.472057301],
  [-66.8339088, 58.478103001],
  [-66.8245956, 58.492238701],
  [-66.8180525, 58.505236101],
  [-66.795003, 58.511391601],
  [-66.7401222, 58.516022501],
  [-66.7180734, 58.511442501],
  [-66.7029526, 58.503097501],
  [-66.6851881, 58.499577501],
  [-66.6725058, 58.500068901],
  [-66.6636697, 58.509755501],
  [-66.6745755, 58.518884601],
  [-66.6758379, 58.534753801],
  [-66.6664416, 58.548878501],
  [-66.6507156, 58.564170301],
  [-66.6400623, 58.583476201],
  [-66.6344242, 58.604951601],
  [-66.6064159, 58.635495801],
  [-66.5819601, 58.667850301],
  [-66.5656886, 58.691264501],
  [-66.5472759, 58.715064801],
  [-66.534535, 58.725363901],
  [-66.4949952, 58.723994201],
  [-66.4660088, 58.728657401],
  [-66.4578994, 58.743128101],
  [-66.4787038, 58.773997501],
  [-66.4857269, 58.795000301],
  [-66.4861095, 58.807928301],
  [-66.4704455, 58.832436901],
  [-66.4452377, 58.848149701],
  [-66.4126718, 58.856522301],
  [-66.3871225, 58.861141401],
  [-66.3620689, 58.857623201],
  [-66.1592431, 58.860843201],
  [-66.1522701, 58.861119901],
  [-66.1467266, 58.862064801],
  [-66.1472795, 58.863247401],
  [-66.1416248, 58.865781601],
  [-66.1405692, 58.867029101],
  [-66.1375281, 58.868536401],
  [-66.125062, 58.864794001],
  [-66.1238556, 58.861363001],
  [-66.0958658, 58.861509701],
  [-66.0539976, 58.884605401],
  [-66.0572579, 58.883624601],
  [-66.0591071, 58.884504701],
  [-66.0567226, 58.886994201],
  [-66.0544841, 58.886742801],
  [-66.0521969, 58.888427501],
  [-66.0478173, 58.888050301],
  [-66.0344339, 58.894850701],
  [-66.005578, 58.911481701],
  [-66.0031935, 58.914241201],
  [-65.9969646, 58.916112901],
  [-65.9904194, 58.916414401],
  [-65.9901031, 58.917582701],
  [-65.9776789, 58.931412301],
  [-65.9525938, 58.957961801],
  [-65.9577927, 58.957256501],
  [-65.9617149, 58.954857501],
  [-65.9647251, 58.950058901],
  [-65.970358, 58.943816201],
  [-65.9742114, 58.942601001],
  [-65.9755341, 58.935894601],
  [-65.9766743, 58.935282801],
  [-65.9816, 58.936694801],
  [-65.9842452, 58.938812701],
  [-65.9879851, 58.938718601],
  [-65.9974715, 58.940130401],
  [-66.0065931, 58.942389301],
  [-66.0109717, 58.940883501],
  [-66.0211878, 58.945024601],
  [-66.0335932, 58.946153901],
  [-66.0427147, 58.956692201],
  [-66.0569275, 58.965199601],
  [-66.0556214, 58.969413601],
  [-66.049647, 58.971459001],
  [-66.0469105, 58.974750201],
  [-66.0383362, 58.977947001],
  [-66.034857, 58.977619601],
  [-66.0314265, 58.974174201],
  [-66.0291233, 58.974350501],
  [-66.0277551, 58.975173201],
  [-66.0305143, 58.976736401],
  [-66.0308564, 58.977664901],
  [-66.0265693, 58.979169201],
  [-65.9951911, 58.977805901],
  [-65.9968105, 58.979134001],
  [-65.9923637, 58.982835701],
  [-65.9734819, 58.985150401],
  [-65.968921, 58.983446601],
  [-65.9638129, 58.975220301],
  [-65.9543265, 58.975784401],
  [-65.9488535, 58.975126201],
  [-65.9421949, 58.968825901],
  [-65.9345328, 58.977758901],
  [-65.9400057, 58.986783601],
  [-65.9290598, 58.993268701],
  [-65.9259585, 58.998906901],
  [-65.9336206, 59.002007501],
  [-65.9334387, 59.007080901],
  [-65.9317963, 59.013374001],
  [-65.9270531, 59.015722001],
  [-65.9243166, 59.020229701],
  [-65.9226749, 59.027835201],
  [-65.9288776, 59.035438801],
  [-65.9212154, 59.045011501],
  [-65.9255938, 59.058521201],
  [-65.8543541, 59.130202701],
  [-65.8533507, 59.135022901],
  [-65.8613777, 59.134695301],
  [-65.8633616, 59.137339201],
  [-65.8516176, 59.146813301],
  [-65.8469656, 59.146158301],
  [-65.8497021, 59.139093801],
  [-65.8476953, 59.136801101],
  [-65.8362934, 59.148450501],
  [-65.8376616, 59.151257001],
  [-65.8340131, 59.154531001],
  [-65.8422225, 59.164070401],
  [-65.8118182, 59.186976801],
  [-65.806079, 59.202850201],
  [-65.7988351, 59.206959701],
  [-65.8447578, 59.225698401],
  [-65.8594669, 59.253871901],
  [-65.8494469, 59.280000001],
  [-65.8363457, 59.290470801],
  [-65.7695163, 59.331524101],
  [-65.6400884, 59.413391701],
  [-65.6337945, 59.412440201],
  [-65.6315141, 59.414737701],
  [-65.5907182, 59.436150401],
  [-65.5903302, 59.439118601],
  [-65.5947542, 59.439002701],
  [-65.6015957, 59.440347801],
  [-65.6019605, 59.445773501],
  [-65.5680283, 59.447628201],
  [-65.5647445, 59.448555601],
  [-65.5698526, 59.454860801],
  [-65.555623, 59.452264601],
  [-65.5274173, 59.469426501],
  [-65.5254749, 59.474720601],
  [-65.5248443, 59.478525801],
  [-65.5213342, 59.481291101],
  [-65.5204458, 59.484361001],
  [-65.5148539, 59.485676801],
  [-65.50858, 59.491805101],
  [-65.5139675, 59.498036201],
  [-65.5098759, 59.502743201],
  [-65.5003286, 59.502051101],
  [-65.4744146, 59.532839201],
  [-65.4380594, 59.571115801],
  [-65.438145, 59.577648001],
  [-65.4424579, 59.590883301],
  [-65.4546609, 59.603329802],
  [-65.4617931, 59.613064402],
  [-65.4745615, 59.614531402],
  [-65.4816954, 59.617851402],
  [-65.4825412, 59.619931602],
  [-65.4865482, 59.624646002],
  [-65.493925, 59.631687502],
  [-65.50226, 59.636426502],
  [-65.5032744, 59.637709602],
  [-65.5030798, 59.639908402],
  [-65.5017447, 59.641040702],
  [-65.5029089, 59.642824202],
  [-65.509056, 59.644604002],
  [-65.5140143, 59.647828902],
  [-65.5173961, 59.652620802],
  [-65.5239894, 59.655389602],
  [-65.5247119, 59.656328802],
  [-65.5234132, 59.657233502],
  [-65.5201718, 59.657187102],
  [-65.520606, 59.659048202],
  [-65.5242858, 59.661423602],
  [-65.5271395, 59.661798802],
  [-65.5323403, 59.661242802],
  [-65.5364653, 59.662580802],
  [-65.5527396, 59.672343002],
  [-65.5606005, 59.680917002],
  [-65.5650216, 59.688271402],
  [-65.565973, 59.695993802],
  [-65.5566369, 59.719190302],
  [-65.5572366, 59.725241802],
  [-65.5609532, 59.732894702],
  [-65.5592203, 59.735925302],
  [-65.5523045, 59.740766802],
  [-65.5466691, 59.743615502],
  [-65.5365547, 59.750030902],
  [-65.5259764, 59.764135702],
  [-65.4875915, 59.800348502],
  [-65.4534678, 59.823823602],
  [-65.347499, 59.878047602],
  [-65.2998846, 59.904300902],
  [-65.2704258, 59.908544602],
  [-65.2595367, 59.915687902],
  [-65.2637893, 59.918811002],
  [-65.2636617, 59.921291702],
  [-65.2601044, 59.923675902],
  [-65.2612245, 59.925390402],
  [-65.2583687, 59.926558402],
  [-65.2420218, 59.926758902],
  [-65.238742, 59.924830302],
  [-65.2316338, 59.931324402],
  [-65.2315408, 59.940178802],
  [-65.2138633, 59.949427602],
  [-65.2094905, 59.968129402],
  [-65.1852716, 59.984121102],
  [-65.1584854, 60.004386902],
  [-65.1495385, 60.014881502],
  [-65.1449399, 60.021977402],
  [-65.1457497, 60.028462602],
  [-65.1388346, 60.040750802],
  [-65.1472311, 60.042613902],
  [-65.1487761, 60.043745402],
  [-65.1480551, 60.045455402],
  [-65.1360045, 60.046586802],
  [-65.1350002, 60.049235102],
  [-65.1405106, 60.050597802],
  [-65.1410129, 60.054493702],
  [-65.1392778, 60.057653002],
  [-65.1414422, 60.059284002],
  [-65.142406, 60.063505102],
  [-65.1390623, 60.066190902],
  [-65.1237896, 60.070802502],
  [-65.0865103, 60.091193102],
  [-65.0780269, 60.096540902],
  [-65.0791644, 60.102318902],
  [-65.071974, 60.109783902],
  [-65.0563854, 60.112917802],
  [-65.0536956, 60.117970502],
  [-65.0459721, 60.121613602],
  [-65.0276935, 60.135038102],
  [-65.0131147, 60.150083102],
  [-65.0097502, 60.160370302],
  [-65.0055172, 60.165065602],
  [-64.9899504, 60.164087002],
  [-64.9745135, 60.164710402],
  [-64.971902, 60.167250402],
  [-64.9727703, 60.171420402],
  [-64.9714059, 60.174700502],
  [-64.9634588, 60.181537302],
  [-64.9656361, 60.184103302],
  [-64.9660559, 60.187550302],
  [-64.9642335, 60.189187502],
  [-64.9499146, 60.220409802],
  [-64.9474502, 60.223860102],
  [-64.9532514, 60.236846302],
  [-64.9601955, 60.237192302],
  [-64.9591191, 60.241265002],
  [-64.9711557, 60.243134102],
  [-64.9706443, 60.247322802],
  [-64.9654278, 60.257446502],
  [-64.9426164, 60.274435902],
  [-64.9227533, 60.283247802],
  [-64.8953078, 60.285694202],
  [-64.8821994, 60.290860202],
  [-64.8721227, 60.299080102],
  [-64.8631521, 60.301228302],
  [-64.85242, 60.309972502],
  [-64.8438754, 60.322058602],
  [-64.8454032, 60.341564302],
  [-64.8580748, 60.351037402],
  [-64.8629416, 60.361462502],
  [-64.8582303, 60.370971002],
  [-64.8450018, 60.375133302],
  [-64.8275644, 60.374666002],
  [-64.8058166, 60.370771602],
  [-64.7787367, 60.362195902],
  [-64.7537569, 60.356505602],
  [-64.7380045, 60.351350602],
  [-64.7374462, 60.362781002],
  [-64.7334259, 60.367162602],
  [-64.7030476, 60.356410402],
  [-64.6927415, 60.355161402],
  [-64.6589178, 60.348024102],
  [-64.6428249, 60.347708402],
  [-64.6270717, 60.346483302],
  [-64.5972759, 60.338137902],
  [-64.5727979, 60.330496202],
  [-64.5490645, 60.318831002],
  [-64.5174759, 60.304398202],
  [-64.5297431, 60.296677902],
  [-64.5633219, 60.306885902],
  [-64.5812247, 60.308375202],
  [-64.5972712, 60.300613802],
  [-64.6436625, 60.297705402],
  [-64.6552154, 60.294806002],
  [-64.6809795, 60.296590302],
  [-64.6992122, 60.302547902],
  [-64.7074122, 60.303297102],
  [-64.7234259, 60.291478302],
  [-64.7535365, 60.286881102],
  [-64.8072608, 60.275842402],
  [-64.841639, 60.272190802],
  [-64.8537778, 60.269411502],
  [-64.8622585, 60.258468502],
  [-64.8527657, 60.252664702],
  [-64.8578337, 60.250059902],
  [-64.8560159, 60.248335502],
  [-64.8541148, 60.244845902],
  [-64.854548, 60.243094402],
  [-64.849596, 60.239297302],
  [-64.8535919, 60.236475402],
  [-64.8520923, 60.233848702],
  [-64.8515673, 60.229779002],
  [-64.8530626, 60.227973102],
  [-64.8530201, 60.225740802],
  [-64.844561, 60.223837702],
  [-64.8331938, 60.224548902],
  [-64.8281003, 60.220357802],
  [-64.8211227, 60.216403302],
  [-64.7841465, 60.203979002],
  [-64.7802413, 60.196119302],
  [-64.7498796, 60.188155602],
  [-64.7455625, 60.168628002],
  [-64.73323, 60.164366202],
  [-64.6988975, 60.171282202],
  [-64.6923314, 60.180082602],
  [-64.6824938, 60.181158802],
  [-64.6615669, 60.170160402],
  [-64.6412423, 60.167379802],
  [-64.6144553, 60.170845902],
  [-64.6024711, 60.167014602],
  [-64.6013356, 60.159401602],
  [-64.6025188, 60.155802102],
  [-64.5941865, 60.149498902],
  [-64.5988221, 60.142833102],
  [-64.5937159, 60.117031302],
  [-64.6033954, 60.109678902],
  [-64.6330729, 60.105415002],
  [-64.6577853, 60.111410002],
  [-64.6720321, 60.113548502],
  [-64.6811913, 60.107546302],
  [-64.7054756, 60.107553302],
  [-64.7353046, 60.109548502],
  [-64.7672961, 60.108353202],
  [-64.7680704, 60.106692302],
  [-64.7574764, 60.101353102],
  [-64.726712, 60.094741002],
  [-64.7079868, 60.084609102],
  [-64.7063326, 60.074313002],
  [-64.7178569, 60.064074302],
  [-64.742714, 60.056131102],
  [-64.7832355, 60.057615302],
  [-64.804093, 60.052429502],
  [-64.8198062, 60.050482102],
  [-64.8647239, 60.058441102],
  [-64.8958755, 60.066209302],
  [-64.9204032, 60.065870602],
  [-64.9236433, 60.061465102],
  [-64.8876715, 60.041975102],
  [-64.8820906, 60.031907902],
  [-64.8707407, 60.016865402],
  [-64.860977, 59.995206302],
  [-64.8402284, 59.970319102],
  [-64.8068306, 59.970814002],
  [-64.7827765, 59.980401402],
  [-64.7470998, 59.974488802],
  [-64.7416305, 59.965163302],
  [-64.7474206, 59.954729702],
  [-64.7321913, 59.950524502],
  [-64.7094588, 59.953788202],
  [-64.6931206, 59.958876402],
  [-64.6622305, 59.952554002],
  [-64.6614582, 59.939699302],
  [-64.6694185, 59.930128602],
  [-64.6635792, 59.927690502],
  [-64.6340589, 59.936001002],
  [-64.6267982, 59.930294602],
  [-64.6370313, 59.919039902],
  [-64.640977, 59.907448102],
  [-64.6587313, 59.901592902],
  [-64.666415, 59.888287002],
  [-64.6837761, 59.877198402],
  [-64.7041922, 59.883707102],
  [-64.7415216, 59.889298902],
  [-64.7561663, 59.886031602],
  [-64.7665541, 59.869675402],
  [-64.7392015, 59.861960102],
  [-64.7369445, 59.855568402],
  [-64.7780856, 59.852847902],
  [-64.796954, 59.840816702],
  [-64.7949611, 59.834121602],
  [-64.8167318, 59.835049402],
  [-64.8252245, 59.825608002],
  [-64.7921155, 59.812181202],
  [-64.7884738, 59.803273502],
  [-64.7997224, 59.798868502],
  [-64.8139892, 59.795152202],
  [-64.8139577, 59.783033502],
  [-64.7899867, 59.760304102],
  [-64.7972355, 59.738471002],
  [-64.8102029, 59.729693102],
  [-64.801535, 59.724773702],
  [-64.7743083, 59.725145802],
  [-64.7494319, 59.717239402],
  [-64.7604854, 59.706558202],
  [-64.7875629, 59.692577702],
  [-64.824921, 59.675997602],
  [-64.871656, 59.668346002],
  [-64.8895081, 59.662155502],
  [-64.8982595, 59.641783302],
  [-64.8825397, 59.610682002],
  [-64.8598084, 59.591537301],
  [-64.8687066, 59.586424201],
  [-64.9270598, 59.593178901],
  [-64.9394392, 59.567046001],
  [-64.8922058, 59.562468701],
  [-64.8691913, 59.546923101],
  [-64.8396296, 59.553342001],
  [-64.8227553, 59.540659301],
  [-64.8031315, 59.534310301],
  [-64.8041995, 59.525015901],
  [-64.7641451, 59.514868001],
  [-64.7630926, 59.496640801],
  [-64.7539479, 59.488289801],
  [-64.7369247, 59.480685701],
  [-64.7285526, 59.466635401],
  [-64.717305, 59.461462701],
  [-64.6944328, 59.458000901],
  [-64.6798349, 59.446905101],
  [-64.6428142, 59.459247601],
  [-64.6361333, 59.477165201],
  [-64.6267672, 59.481970401],
  [-64.5896726, 59.466182701],
  [-64.5788352, 59.471010101],
  [-64.5614624, 59.487914901],
  [-64.5322426, 59.499488801],
  [-64.5053012, 59.501296401],
  [-64.5093515, 59.513639801],
  [-64.5030926, 59.521373401],
  [-64.4629001, 59.523396101],
  [-64.4495505, 59.530945801],
  [-64.4337662, 59.541973601],
  [-64.4190394, 59.532643901],
  [-64.3858059, 59.523760701],
  [-64.3240693, 59.512774101],
  [-64.3278521, 59.497462801],
  [-64.3496559, 59.502952301],
  [-64.3693506, 59.488434201],
  [-64.3808216, 59.483764801],
  [-64.3869632, 59.473796901],
  [-64.407199, 59.477806101],
  [-64.4260763, 59.464936901],
  [-64.4580247, 59.459174801],
  [-64.4668455, 59.450204901],
  [-64.5115984, 59.422979901],
  [-64.4725591, 59.418258901],
  [-64.4607255, 59.412177101],
  [-64.4959946, 59.404255701],
  [-64.5165003, 59.403905401],
  [-64.5282681, 59.395628201],
  [-64.5240513, 59.385084501],
  [-64.538339, 59.370781901],
  [-64.5257175, 59.361580401],
  [-64.4933027, 59.349576101],
  [-64.494424, 59.344324001],
  [-64.5113787, 59.335063201],
  [-64.527792, 59.319978401],
  [-64.5426233, 59.318674301],
  [-64.5435363, 59.310435101],
  [-64.5169642, 59.279946901],
  [-64.4886035, 59.257110101],
  [-64.5113506, 59.238930401],
  [-64.5218475, 59.196741101],
  [-64.5034218, 59.195713301],
  [-64.4727147, 59.180391201],
  [-64.4866578, 59.152799001],
  [-64.491272, 59.136427001],
  [-64.4839856, 59.118166001],
  [-64.4980488, 59.106416001],
  [-64.4903091, 59.099075101],
  [-64.4504578, 59.102889301],
  [-64.4169614, 59.087603801],
  [-64.3760279, 59.092607601],
  [-64.3635141, 59.087428901],
  [-64.3608407, 59.067295301],
  [-64.315176, 59.065629801],
  [-64.2945649, 59.043235801],
  [-64.2736166, 59.022040301],
  [-64.2617912, 59.012956601],
  [-64.2943826, 59.000498701],
  [-64.3453346, 59.004925401],
  [-64.3877053, 59.006183801],
  [-64.424171, 58.991684801],
  [-64.4367878, 58.994313801],
  [-64.4603011, 58.981514301],
  [-64.4812921, 58.988330701],
  [-64.4710255, 58.999863801],
  [-64.4977808, 59.019432701],
  [-64.5067846, 59.019725801],
  [-64.5227608, 59.002560201],
  [-64.5449556, 59.009644301],
  [-64.5706195, 59.027274801],
  [-64.5957882, 59.035044901],
  [-64.6166005, 59.034070701],
  [-64.6306849, 59.040689601],
  [-64.650155, 59.041229101],
  [-64.6702752, 59.038614401],
  [-64.6792731, 59.046966801],
  [-64.6832569, 59.074695401],
  [-64.7248711, 59.076003601],
  [-64.752495, 59.070542001],
  [-64.7792388, 59.074957401],
  [-64.7969895, 59.063697901],
  [-64.8217486, 59.049307301],
  [-64.8320066, 59.035201101],
  [-64.840793, 59.032484601],
  [-64.8294409, 59.020303701],
  [-64.8436329, 59.016283401],
  [-64.8650159, 59.015405001],
  [-64.8700884, 58.998261201],
  [-64.8622185, 58.986162901],
  [-64.8403501, 58.980324301],
  [-64.8420984, 58.966986001],
  [-64.8329136, 58.956407701],
  [-64.848578, 58.953104501],
  [-64.8794405, 58.951882501],
  [-64.8891115, 58.938382201],
  [-64.8685378, 58.941640801],
  [-64.8570282, 58.938585101],
  [-64.8563114, 58.932919801],
  [-64.8392697, 58.936999001],
  [-64.8320574, 58.921748301],
  [-64.8207135, 58.916892701],
  [-64.7657328, 58.923802801],
  [-64.750292, 58.934115701],
  [-64.7562316, 58.940278201],
  [-64.7306699, 58.950784001],
  [-64.7025896, 58.946386101],
  [-64.6812996, 58.947982501],
  [-64.6516502, 58.943752001],
  [-64.6324277, 58.933345701],
  [-64.6147682, 58.907515601],
  [-64.5796286, 58.904713601],
  [-64.5571788, 58.897194001],
  [-64.5501825, 58.887455401],
  [-64.5089105, 58.887581401],
  [-64.4764335, 58.903382801],
  [-64.455801, 58.908216701],
  [-64.426398, 58.904526801],
  [-64.4206027, 58.890278501],
  [-64.4059135, 58.890821001],
  [-64.4002942, 58.902718201],
  [-64.3787024, 58.903819101],
  [-64.3502617, 58.891877501],
  [-64.3325402, 58.904562801],
  [-64.2876126, 58.896753101],
  [-64.2874177, 58.883458401],
  [-64.2336567, 58.864113301],
  [-64.2265732, 58.845843901],
  [-64.2302408, 58.829055801],
  [-64.2375285, 58.805042101],
  [-64.2297046, 58.783648501],
  [-64.2001257, 58.779461701],
  [-64.178785, 58.789039601],
  [-64.158267, 58.758119201],
  [-64.1377929, 58.746166901],
  [-64.0940964, 58.756945701],
  [-64.087148, 58.755997501],
  [-64.0697088, 58.769086201],
  [-64.0718737, 58.783247001],
  [-64.0463011, 58.789573501],
  [-64.0385861, 58.802076801],
  [-64.0453376, 58.810350401],
  [-64.0197388, 58.816671601],
  [-63.9954028, 58.813709101],
  [-63.9754723, 58.830253201],
  [-63.9571616, 58.826905601],
  [-63.8978713, 58.837365301],
  [-63.862186, 58.831977201],
  [-63.8508018, 58.835102101],
  [-63.8352834, 58.849030301],
  [-63.812138, 58.847062801],
  [-63.799286, 58.856473801],
  [-63.7776833, 58.884058801],
  [-63.7254631, 58.877174701],
  [-63.7099968, 58.887053201],
  [-63.6799383, 58.878562701],
  [-63.6803243, 58.866459301],
  [-63.661121, 58.854712201],
  [-63.6359551, 58.848699701],
  [-63.6177578, 58.855307901],
  [-63.5953411, 58.857175801],
  [-63.5507851, 58.836550101],
  [-63.5502798, 58.822365801],
  [-63.5345135, 58.816242801],
  [-63.512786, 58.795686101],
  [-63.4921574, 58.768545201],
  [-63.4675041, 58.762047101],
  [-63.4948284, 58.754034501],
  [-63.4990411, 58.750001001],
  [-63.5, 58.750000001],
  [-63.5, 58.749082601],
  [-63.5067939, 58.742574601],
  [-63.5533088, 58.733772701],
  [-63.5617082, 58.727718001],
  [-63.5724699, 58.731496001],
  [-63.6192835, 58.723860601],
  [-63.6298452, 58.714643001],
  [-63.6606843, 58.725224701],
  [-63.669554, 58.717066701],
  [-63.6949196, 58.719633201],
  [-63.7183553, 58.706385201],
  [-63.7288229, 58.706842101],
  [-63.7364845, 58.714547901],
  [-63.7543739, 58.716737101],
  [-63.7942766, 58.703287401],
  [-63.8110207, 58.712202101],
  [-63.8356154, 58.710118701],
  [-63.840683, 58.701395801],
  [-63.8550967, 58.696443101],
  [-63.8750292, 58.705758401],
  [-63.9099652, 58.710253501],
  [-63.93106, 58.701025701],
  [-63.9502538, 58.685247301],
  [-63.9648373, 58.690285801],
  [-63.9842167, 58.687642501],
  [-64.0161572, 58.695432201],
  [-64.0335381, 58.704457801],
  [-64.0472222, 58.702628501],
  [-64.0600313, 58.683633501],
  [-64.0766188, 58.665929001],
  [-64.0788698, 58.653949401],
  [-64.1079267, 58.639650701],
  [-64.1093298, 58.619916801],
  [-64.1158519, 58.602350601],
  [-64.0927854, 58.587893201],
  [-64.1110504, 58.581660801],
  [-64.1049435, 58.563230001],
  [-64.0746959, 58.547379001],
  [-64.0671845, 58.534492301],
  [-64.0469398, 58.535070801],
  [-64.0340817, 58.523450401],
  [-64.0087748, 58.531259001],
  [-63.973579, 58.529636101],
  [-63.9518617, 58.541267701],
  [-63.9466928, 58.558805201],
  [-63.9073661, 58.573492101],
  [-63.8871291, 58.571654501],
  [-63.8581941, 58.563797601],
  [-63.8625555, 58.552694901],
  [-63.8497144, 58.546579901],
  [-63.8488471, 58.533450801],
  [-63.8591516, 58.521231301],
  [-63.8586919, 58.504514401],
  [-63.8235768, 58.497323401],
  [-63.8078501, 58.489746101],
  [-63.8222674, 58.473749501],
  [-63.8430965, 58.467826201],
  [-63.847154, 58.450010901],
  [-63.8811133, 58.429439701],
  [-63.9104139, 58.441463801],
  [-63.9242394, 58.444871201],
  [-63.9411048, 58.440175801],
  [-63.9641037, 58.438831701],
  [-63.9865786, 58.444357901],
  [-63.998477, 58.425536901],
  [-64.013201, 58.423995301],
  [-64.0209974, 58.408960001],
  [-64.0285652, 58.389448901],
  [-64.0225389, 58.371019201],
  [-64.0305298, 58.365983801],
  [-64.0616682, 58.360926101],
  [-64.0979964, 58.376836501],
  [-64.1192487, 58.364738201],
  [-64.1390889, 58.369228101],
  [-64.1585071, 58.371033801],
  [-64.1722227, 58.361873301],
  [-64.1755165, 58.343909201],
  [-64.1867687, 58.336724601],
  [-64.1782053, 58.330582501],
  [-64.1845691, 58.317051601],
  [-64.1981913, 58.311920601],
  [-64.1969095, 58.293578101],
  [-64.2169854, 58.287451701],
  [-64.2085437, 58.275637901],
  [-64.2171407, 58.261922901],
  [-64.217777, 58.247732201],
  [-64.2412827, 58.237668201],
  [-64.2475038, 58.227421401],
  [-64.271197, 58.221080401],
  [-64.3191178, 58.218078101],
  [-64.3735628, 58.208380601],
  [-64.3895344, 58.196471301],
  [-64.4217325, 58.186673701],
  [-64.4217983, 58.173835201],
  [-64.4308915, 58.156215901],
  [-64.4250692, 58.130194301],
  [-64.4336725, 58.116315501],
  [-64.4353949, 58.098375301],
  [-64.4268832, 58.088222701],
  [-64.4330914, 58.069908101],
  [-64.4122832, 58.061159201],
  [-64.3871477, 58.069343801],
  [-64.3539107, 58.069299301],
  [-64.3205078, 58.061488001],
  [-64.2992661, 58.044519401],
  [-64.2777333, 58.046952101],
  [-64.2692577, 58.061412401],
  [-64.2509603, 58.038718501],
  [-64.2336861, 58.041822701],
  [-64.2185076, 58.036983301],
  [-64.238668, 58.025032301],
  [-64.2381741, 58.016086801],
  [-64.2280328, 58.005500301],
  [-64.2274386, 57.989543501],
  [-64.2046618, 57.973629701],
  [-64.1987869, 57.937612201],
  [-64.1847026, 57.926039601],
  [-64.1625254, 57.922941801],
  [-64.1579694, 57.916296201],
  [-64.1565965, 57.890208801],
  [-64.121367, 57.826285601],
  [-64.1166754, 57.811139401],
  [-64.092071, 57.794343001],
  [-64.0720013, 57.770020301],
  [-64.0259519, 57.764403601],
  [-64.0232843, 57.778460401],
  [-64.0339687, 57.791437401],
  [-64.0298336, 57.804023801],
  [-64.0208795, 57.809517401],
  [-64.0118775, 57.802481901],
  [-63.9972489, 57.812078701],
  [-63.9723479, 57.799587801],
  [-63.9536355, 57.799688001],
  [-63.9457115, 57.791440401],
  [-63.9385963, 57.788551001],
  [-63.9273711, 57.793470501],
  [-63.9123556, 57.801122501],
  [-63.9038453, 57.798249101],
  [-63.9081877, 57.776865601],
  [-63.9011415, 57.762936901],
  [-63.9071784, 57.751375901],
  [-63.8834217, 57.712607301],
  [-63.866084, 57.711185701],
  [-63.8474764, 57.719022901],
  [-63.8207115, 57.705632101],
  [-63.8133557, 57.689765801],
  [-63.8042251, 57.684507901],
  [-63.7898731, 57.687512801],
  [-63.7731329, 57.700236601],
  [-63.7536305, 57.720744401],
  [-63.7513942, 57.699893901],
  [-63.7448142, 57.688637301],
  [-63.7485511, 57.674279601],
  [-63.7352909, 57.662801901],
  [-63.7027774, 57.663766301],
  [-63.692612, 57.652696901],
  [-63.6854453, 57.683342501],
  [-63.6851318, 57.696914901],
  [-63.6724348, 57.692580901],
  [-63.6594172, 57.701818001],
  [-63.6599512, 57.715977901],
  [-63.6680557, 57.723942301],
  [-63.6525057, 57.732908201],
  [-63.6325659, 57.728945401],
  [-63.6231961, 57.739482301],
  [-63.5967704, 57.741247701],
  [-63.59487, 57.719347301],
  [-63.5969599, 57.707695201],
  [-63.6094861, 57.692058601],
  [-63.5944994, 57.685652701],
  [-63.5987273, 57.663840501],
  [-63.6166104, 57.650683301],
  [-63.6229662, 57.641969201],
  [-63.6449231, 57.634281501],
  [-63.6499515, 57.620063901],
  [-63.6702778, 57.621185501],
  [-63.684602, 57.603435601],
  [-63.6995057, 57.615346301],
  [-63.7306136, 57.601129301],
  [-63.7326204, 57.588732001],
  [-63.7455751, 57.579488701],
  [-63.7582755, 57.591566201],
  [-63.7719159, 57.592449801],
  [-63.7711026, 57.579339701],
  [-63.7654903, 57.560770301],
  [-63.7511686, 57.535743601],
  [-63.753394, 57.515294901],
  [-63.7675031, 57.514831501],
  [-63.7752214, 57.493572801],
  [-63.7657407, 57.464618],
  [-63.7582758, 57.4544209],
  [-63.7612609, 57.4393317],
  [-63.7314572, 57.4265619],
  [-63.7164396, 57.4382101],
  [-63.7103055, 57.42263],
  [-63.7234409, 57.398336],
  [-63.7082721, 57.3844993],
  [-63.7013012, 57.3683336],
  [-63.7146194, 57.3632649],
  [-63.7419786, 57.3577345],
  [-63.7681925, 57.3581724],
  [-63.7854988, 57.3531974],
  [-63.7973763, 57.3403871],
  [-63.8017259, 57.3322871],
  [-63.8243351, 57.3391641],
  [-63.8558208, 57.3343012],
  [-63.8656718, 57.3136113],
  [-63.8693841, 57.2976189],
  [-63.8833735, 57.277621],
  [-63.8814453, 57.2649796],
  [-63.8737335, 57.2425781],
  [-63.8669754, 57.226125],
  [-63.8733898, 57.2093554],
  [-63.8840749, 57.1962559],
  [-63.8723856, 57.192532],
  [-63.8612434, 57.2014643],
  [-63.8459868, 57.2041896],
  [-63.8416006, 57.2108003],
  [-63.844717, 57.225959],
  [-63.826124, 57.2410904],
  [-63.8187379, 57.2550388],
  [-63.8091364, 57.2621597],
  [-63.7795997, 57.2593876],
  [-63.7597696, 57.251726],
  [-63.7414669, 57.2407656],
  [-63.7371374, 57.2215956],
  [-63.7500971, 57.2090781],
  [-63.770985, 57.1960183],
  [-63.7793657, 57.1793809],
  [-63.7797483, 57.1617966],
  [-63.7648855, 57.1399256],
  [-63.7857543, 57.1174795],
  [-63.7987187, 57.1064479],
  [-63.8073872, 57.0909988],
  [-63.8118098, 57.0788788],
  [-63.8201484, 57.0754989],
  [-63.8397651, 57.0801703],
  [-63.8816843, 57.0879792],
  [-63.8900834, 57.0860831],
  [-63.8920685, 57.075183],
  [-63.8915147, 57.0625287],
  [-63.898572, 57.0551379],
  [-63.8896931, 57.0458693],
  [-63.8919348, 57.0127735],
  [-63.857774, 56.9992124],
  [-63.8869999, 56.9809645],
  [-63.9078697, 56.9841174],
  [-63.9141295, 56.9776306],
  [-63.9117789, 56.9553217],
  [-63.9143625, 56.9397991],
  [-63.9080998, 56.9275211],
  [-63.9172943, 56.9168262],
  [-63.918094, 56.9041576],
  [-63.9080196, 56.8978875],
  [-63.8999277, 56.9057374],
  [-63.8786194, 56.9039301],
  [-63.8638906, 56.8968229],
  [-63.867396, 56.8833761],
  [-63.8571468, 56.8726371],
  [-63.8591562, 56.86874],
  [-63.8743581, 56.874501],
  [-63.8994988, 56.877301],
  [-63.9188115, 56.8712386],
  [-63.9480196, 56.8677197],
  [-63.9838769, 56.8611235],
  [-64.0034787, 56.8617437],
  [-64.012978, 56.8498464],
  [-64.0099266, 56.8369347],
  [-64.0089185, 56.8067225],
  [-64.0019787, 56.7975887],
  [-64.0127595, 56.7786769],
  [-64.0789073, 56.7670032],
  [-64.0853892, 56.7609522],
  [-64.0740082, 56.755462],
  [-64.076282, 56.7464957],
  [-64.0994431, 56.7436221],
  [-64.1123717, 56.7339003],
  [-64.1181075, 56.7239881],
  [-64.1453144, 56.6997548],
  [-64.1339813, 56.695015],
  [-64.1282334, 56.6828951],
  [-64.0945221, 56.6628585],
  [-64.0908363, 56.653833],
  [-64.0510175, 56.6575436],
  [-64.024858, 56.653008],
  [-64.0305251, 56.6471217],
  [-64.0399485, 56.6407345],
  [-64.0336506, 56.6332339],
  [-64.0533297, 56.6155345],
  [-64.0504614, 56.6063481],
  [-64.0040162, 56.5912367],
  [-64.0015709, 56.5792159],
  [-63.9825715, 56.5647486],
  [-63.9641799, 56.5574137],
  [-63.9558877, 56.5468128],
  [-63.931063, 56.5337576],
  [-63.9413799, 56.511445],
  [-63.9390937, 56.502399],
  [-63.9443295, 56.493102],
  [-63.9311543, 56.4749788],
  [-63.9306324, 56.466207],
  [-63.9234977, 56.4628812],
  [-63.9127534, 56.4630254],
  [-63.8976934, 56.4690288],
  [-63.883717, 56.4690643],
  [-63.8854453, 56.4592215],
  [-63.8729165, 56.4489704],
  [-63.8742302, 56.4419604],
  [-63.8864711, 56.4330219],
  [-63.9018416, 56.4407041],
  [-63.9520122, 56.4214283],
  [-63.9657551, 56.4106753],
  [-63.993016, 56.4082113],
  [-64.0111288, 56.4110789],
  [-64.0205736, 56.4179366],
  [-64.0448217, 56.4198182],
  [-64.0643573, 56.4241448],
  [-64.0955009, 56.4241287],
  [-64.1193603, 56.4288276],
  [-64.1373148, 56.4334636],
  [-64.1527402, 56.4411121],
  [-64.164328, 56.4312603],
  [-64.1779849, 56.4252436],
  [-64.1581063, 56.4197511],
  [-64.1436946, 56.4053926],
  [-64.1327836, 56.4018406],
  [-64.1153559, 56.4021063],
  [-64.1104028, 56.385964],
  [-64.1278439, 56.3699282],
  [-64.1158594, 56.3660939],
  [-64.0993521, 56.3519126],
  [-64.1074897, 56.3430125],
  [-64.0981924, 56.3336318],
  [-64.1094344, 56.3226024],
  [-64.1260228, 56.3169944],
  [-64.1434381, 56.3119664],
  [-64.1486292, 56.3034068],
  [-64.1303816, 56.3023493],
  [-64.1172625, 56.2967484],
  [-64.1112621, 56.2889557],
  [-64.0904958, 56.2798978],
  [-64.0873772, 56.2568893],
  [-64.0755781, 56.2560242],
  [-64.0221505, 56.2678133],
  [-63.9671099, 56.2563967],
  [-63.9530741, 56.2652179],
  [-63.9348928, 56.2711193],
  [-63.9129113, 56.2635342],
  [-63.914234, 56.2447765],
  [-63.9114924, 56.2308333],
  [-63.8953848, 56.2225718],
  [-63.8743903, 56.2247827],
  [-63.8629333, 56.2125891],
  [-63.8999835, 56.1991621],
  [-63.9500445, 56.1850982],
  [-63.9788708, 56.1797891],
  [-64.0047035, 56.1733258],
  [-64.0153032, 56.1661845],
  [-64.0195282, 56.1595805],
  [-64.0359633, 56.154881],
  [-64.0239343, 56.1425645],
  [-64.0085505, 56.1381764],
  [-64.0141849, 56.1275379],
  [-64.0052892, 56.1193386],
  [-64.0021333, 56.1027303],
  [-64.0142813, 56.1007717],
  [-64.0357598, 56.0821719],
  [-64.0346669, 56.0701446],
  [-64.0180538, 56.0672635],
  [-63.9931921, 56.0757972],
  [-63.9689566, 56.0866957],
  [-63.9615013, 56.0924498],
  [-63.9481454, 56.0911484],
  [-63.9332488, 56.0971519],
  [-63.9252536, 56.1090071],
  [-63.9142109, 56.1182268],
  [-63.9056632, 56.1236948],
  [-63.8801004, 56.1360793],
  [-63.8561788, 56.1371362],
  [-63.8382669, 56.1224988],
  [-63.8629807, 56.1087971],
  [-63.8875806, 56.0928614],
  [-63.8825503, 56.0871297],
  [-63.8638793, 56.0921331],
  [-63.8396281, 56.1006251],
  [-63.8353749, 56.0877448],
  [-63.8186668, 56.08885],
  [-63.8110506, 56.0846349],
  [-63.8228402, 56.0737811],
  [-63.8213729, 56.0638391],
  [-63.8378738, 56.0450449],
  [-63.8232733, 56.0452319],
  [-63.7911757, 56.0529206],
  [-63.7741995, 56.0535767],
  [-63.7500802, 56.0618987],
  [-63.7312628, 56.0701524],
  [-63.7148422, 56.0716849],
  [-63.7143641, 56.0660414],
  [-63.6965061, 56.064615],
  [-63.6830339, 56.0595676],
  [-63.6904249, 56.0438735],
  [-63.6367213, 56.0235227],
  [-63.6179975, 56.0269992],
  [-63.6032763, 56.0311727],
  [-63.6009794, 56.0261436],
  [-63.5851597, 56.029286],
  [-63.577584, 56.0250557],
  [-63.5485925, 56.0313045],
  [-63.5209658, 56.0390186],
  [-63.5105093, 56.0358534],
  [-63.4957718, 56.040013],
  [-63.4435451, 56.0295164],
  [-63.4597543, 56.0213364],
  [-63.4671617, 56.0119014],
  [-63.4863798, 56.0155796],
  [-63.4912432, 56.0098838],
  [-63.4701945, 55.9983467],
  [-63.4719913, 55.9877767],
  [-63.4873604, 55.9874786],
  [-63.5050366, 56.0014239],
  [-63.5266492, 56.0053686],
  [-63.5630099, 56.0058859],
  [-63.5739782, 56.0007171],
  [-63.5844867, 55.989904],
  [-63.5993971, 55.9916764],
  [-63.6057884, 55.9878096],
  [-63.5982757, 55.9821761],
  [-63.6271403, 55.9736867],
  [-63.6245373, 55.967473],
  [-63.6355898, 55.9652699],
  [-63.6546523, 55.9721907],
  [-63.6680724, 55.9695119],
  [-63.6754681, 55.96155],
  [-63.6864705, 55.9617207],
  [-63.6814094, 55.9537532],
  [-63.688092, 55.9481769],
  [-63.7123959, 55.9535407],
  [-63.7132435, 55.9478829],
  [-63.7036406, 55.9460637],
  [-63.7066014, 55.9402326],
  [-63.7173589, 55.9375792],
  [-63.7239768, 55.9305154],
  [-63.7339419, 55.9280185],
  [-63.7508348, 55.933611],
  [-63.7715788, 55.9291958],
  [-63.7925828, 55.9314617],
  [-63.8097077, 55.9227747],
  [-63.8494282, 55.9167658],
  [-63.8544417, 55.9100124],
  [-63.8251099, 55.904447],
  [-63.8300658, 55.8962095],
  [-63.843735, 55.8877106],
  [-63.8450987, 55.8790734],
  [-63.8130161, 55.8635846],
  [-63.795478, 55.8611313],
  [-63.7736126, 55.856054],
  [-63.7714686, 55.848502],
  [-63.752902, 55.8465007],
  [-63.7666282, 55.8394973],
  [-63.7529212, 55.8332757],
  [-63.7499686, 55.8251391],
  [-63.7553547, 55.8210616],
  [-63.7728124, 55.8220358],
  [-63.7803773, 55.8130267],
  [-63.7701582, 55.8081014],
  [-63.7770228, 55.8014788],
  [-63.7612827, 55.7969184],
  [-63.7683988, 55.7899962],
  [-63.7501149, 55.7876944],
  [-63.7406921, 55.7826086],
  [-63.7171435, 55.7803652],
  [-63.7055332, 55.7871884],
  [-63.6753846, 55.7919968],
  [-63.6665305, 55.7877898],
  [-63.6794756, 55.7739724],
  [-63.6742006, 55.7593251],
  [-63.6821376, 55.7529938],
  [-63.7054138, 55.7555445],
  [-63.7009974, 55.7429686],
  [-63.7140209, 55.7391008],
  [-63.7258374, 55.7382171],
  [-63.6982068, 55.7315624],
  [-63.7025213, 55.7272036],
  [-63.7251387, 55.7266379],
  [-63.7242154, 55.7230836],
  [-63.7094104, 55.7213286],
  [-63.711147, 55.7184855],
  [-63.7234328, 55.7162595],
  [-63.7196019, 55.7053124],
  [-63.7279107, 55.6954088],
  [-63.7100433, 55.6887889],
  [-63.7049331, 55.6786],
  [-63.723103, 55.6794237],
  [-63.7357372, 55.6728845],
  [-63.7511924, 55.6715083],
  [-63.7520227, 55.6588724],
  [-63.7651537, 55.658711],
  [-63.7646158, 55.6515879],
  [-63.7383027, 55.6504244],
  [-63.7159824, 55.6576737],
  [-63.6684975, 55.6522881],
  [-63.6436901, 55.6415794],
  [-63.6486774, 55.6340969],
  [-63.6616541, 55.6372168],
  [-63.6802248, 55.6352205],
  [-63.6877464, 55.6254789],
  [-63.6826875, 55.6090522],
  [-63.6731764, 55.6072317],
  [-63.6626462, 55.5988875],
  [-63.6749045, 55.5896872],
  [-63.681476, 55.5756512],
  [-63.6649332, 55.5675262],
  [-63.6662021, 55.559047],
  [-63.6654728, 55.5459876],
  [-63.6706669, 55.5374636],
  [-63.6840319, 55.5377539],
  [-63.6890853, 55.5255185],
  [-63.686477, 55.5185701],
  [-63.6914827, 55.5087113],
  [-63.7164408, 55.5044064],
  [-63.7259559, 55.4930074],
  [-63.7408061, 55.4914911],
  [-63.7561215, 55.4816522],
  [-63.7754714, 55.481709],
  [-63.7801308, 55.4737813],
  [-63.7926828, 55.4672386],
  [-63.7805914, 55.4654608],
  [-63.7673805, 55.4549358],
  [-63.7493506, 55.4548615],
  [-63.7383753, 55.4478692],
  [-63.7367067, 55.4382393],
  [-63.6653971, 55.4230506],
  [-63.6445236, 55.4232893],
  [-63.6309317, 55.4302717],
  [-63.6039809, 55.4284925],
  [-63.5783912, 55.4204573],
  [-63.5663435, 55.427118],
  [-63.5490392, 55.4207691],
  [-63.5343764, 55.4274538],
  [-63.5106572, 55.4202745],
  [-63.4785667, 55.4292056],
  [-63.4709718, 55.4197796],
  [-63.4501211, 55.420724],
  [-63.4490786, 55.4119759],
  [-63.4325407, 55.4085708],
  [-63.4349101, 55.4003842],
  [-63.4035547, 55.4076535],
  [-63.3930383, 55.404781],
  [-63.3733479, 55.4097084],
  [-63.3517957, 55.4183597],
  [-63.3369563, 55.4098788],
  [-63.3197869, 55.4118063],
  [-63.3260161, 55.4006209],
  [-63.3336604, 55.3839313],
  [-63.3248487, 55.3660469],
  [-63.3414316, 55.362491],
  [-63.3506619, 55.3569192],
  [-63.3578392, 55.3621995],
  [-63.3998344, 55.3545495],
  [-63.4422496, 55.3440597],
  [-63.4706829, 55.3455655],
  [-63.4820103, 55.349758],
  [-63.5077297, 55.3475707],
  [-63.5299478, 55.3418514],
  [-63.5519224, 55.3448874],
  [-63.560841, 55.338856],
  [-63.5744069, 55.3396017],
  [-63.5803668, 55.334788],
  [-63.5907285, 55.3331914],
  [-63.5982866, 55.3256877],
  [-63.5908314, 55.3207229],
  [-63.5538797, 55.3209709],
  [-63.5326812, 55.3254969],
  [-63.526191, 55.3178464],
  [-63.5263241, 55.3138379],
  [-63.5225555, 55.3098695],
  [-63.5333666, 55.306196],
  [-63.5420639, 55.3096672],
  [-63.5516788, 55.3012552],
  [-63.5695098, 55.2976522],
  [-63.5878848, 55.3026483],
  [-63.6157628, 55.2962568],
  [-63.6440783, 55.2877756],
  [-63.6401187, 55.2786193],
  [-63.6572603, 55.2781267],
  [-63.6794751, 55.2668869],
  [-63.6635454, 55.257426],
  [-63.6526984, 55.2519118],
  [-63.6386391, 55.2510333],
  [-63.6305034, 55.2561708],
  [-63.6012343, 55.2511545],
  [-63.6083243, 55.245733999],
  [-63.6225031, 55.242608199],
  [-63.6240963, 55.236061099],
  [-63.6090939, 55.230144399],
  [-63.597689, 55.238431599],
  [-63.5781455, 55.236418599],
  [-63.5633125, 55.243403799],
  [-63.5474105, 55.225468999],
  [-63.5084185, 55.232104899],
  [-63.4903627, 55.244454899],
  [-63.4748062, 55.245351499],
  [-63.4626356, 55.2552647],
  [-63.445895, 55.2514203],
  [-63.4323685, 55.2552592],
  [-63.4025624, 55.2577639],
  [-63.4028344, 55.2490065],
  [-63.4226635, 55.243034699],
  [-63.4125262, 55.233632999],
  [-63.4258141, 55.226237799],
  [-63.4318712, 55.211342699],
  [-63.4458665, 55.210763799],
  [-63.4526114, 55.201944599],
  [-63.4899207, 55.200390099],
  [-63.5029562, 55.202484299],
  [-63.5131446, 55.196445599],
  [-63.5629184, 55.187616399],
  [-63.5559564, 55.172853499],
  [-63.5313858, 55.174595399],
  [-63.5239238, 55.168144699],
  [-63.54155, 55.160097799],
  [-63.5734097, 55.155753099],
  [-63.5651309, 55.140264299],
  [-63.5736994, 55.133051099],
  [-63.6022965, 55.128733299],
  [-63.5860753, 55.115856399],
  [-63.5854081, 55.103550799],
  [-63.5916021, 55.095324199],
  [-63.6051531, 55.098289999],
  [-63.6113669, 55.090803799],
  [-63.6028631, 55.076360999],
  [-63.6160552, 55.068945699],
  [-63.6064881, 55.061190699],
  [-63.5876098, 55.060805399],
  [-63.573431, 55.053988099],
  [-63.5754999, 55.042247899],
  [-63.556392, 55.034443899],
  [-63.5757337, 55.025781799],
  [-63.5959839, 55.021407499],
  [-63.6061298, 55.008391699],
  [-63.5819728, 55.003615099],
  [-63.5587502, 55.000050799],
  [-66.2594048, 54.999749699],
  [-66.2602838, 55.000182599],
  [-66.2679743, 55.014834199],
  [-66.279621, 55.025059499],
  [-66.2940655, 55.033930299],
  [-66.3035972, 55.035503199],
  [-66.3101618, 55.040824799],
  [-66.3217197, 55.056912799],
  [-66.333146, 55.067035199],
  [-66.3461127, 55.075318899],
  [-66.354995, 55.083209199],
  [-66.3723483, 55.093157199],
  [-66.3846007, 55.102665699],
  [-66.4021027, 55.113143699],
  [-66.4113324, 55.122013599],
  [-66.419657, 55.131164899],
  [-66.4341338, 55.135073099],
  [-66.4447074, 55.140947499],
  [-66.4664262, 55.160262399],
  [-66.4723994, 55.167631699],
  [-66.4804894, 55.178323499],
  [-66.4882869, 55.197279999],
  [-66.4927486, 55.203377899],
  [-66.495338, 55.205106899],
  [-66.502552, 55.206041999],
  [-66.5074041, 55.204700199],
  [-66.5115655, 55.200480399],
  [-66.5123043, 55.194231099],
  [-66.5164166, 55.195322099],
  [-66.522037, 55.205813999],
  [-66.5295451, 55.215654699],
  [-66.5318867, 55.219632299],
  [-66.545595, 55.235379899],
  [-66.5558008, 55.235120999],
  [-66.5656889, 55.238399299],
  [-66.5810742, 55.2521309],
  [-66.6027926, 55.2689814],
  [-66.6074704, 55.2748195],
  [-66.6180349, 55.2781513],
  [-66.6222447, 55.2731363],
  [-66.6217995, 55.2687139],
  [-66.6265729, 55.2660422],
  [-66.6395714, 55.268449],
  [-66.6444267, 55.267101],
  [-66.6538225, 55.2562014],
  [-66.6586375, 55.2551448],
  [-66.6697735, 55.2694447],
  [-66.6895821, 55.2807943],
  [-66.6974203, 55.2861749],
  [-66.7006336, 55.2899506],
  [-66.6994192, 55.2925705],
  [-66.6969402, 55.3053231],
  [-66.700288, 55.3120401],
  [-66.7085477, 55.3166158],
  [-66.7209835, 55.3183722],
  [-66.7276146, 55.3184453],
  [-66.7345688, 55.3212028],
  [-66.7400361, 55.3279192],
  [-66.7548381, 55.3353481],
  [-66.7626228, 55.3401052],
  [-66.7663727, 55.3446058],
  [-66.7784623, 55.3523882],
  [-66.7892622, 55.3525421],
  [-66.8078429, 55.356744],
  [-66.8187415, 55.355314],
  [-66.8183295, 55.3483988],
  [-66.8150051, 55.3408943],
  [-66.8025224, 55.3317204],
  [-66.8005938, 55.3278542],
  [-66.7998887, 55.3232754],
  [-66.8046695, 55.3213007],
  [-66.8160455, 55.3231047],
  [-66.8159611, 55.3220292],
  [-66.8199483, 55.3237234],
  [-66.8276816, 55.3249487],
  [-66.8230268, 55.3137211],
  [-66.8237258, 55.3069698],
  [-66.7863572, 55.2731406],
  [-66.7802553, 55.2689837],
  [-66.7671834, 55.2618653],
  [-66.7441076, 55.2506578],
  [-66.7402322, 55.24803],
  [-66.7265319, 55.235538499],
  [-66.7072309, 55.227123099],
  [-66.6894121, 55.209727399],
  [-66.6718469, 55.181451499],
  [-66.6703633, 55.177484999],
  [-66.6716112, 55.175777399],
  [-66.6748689, 55.176356499],
  [-66.6835841, 55.185062099],
  [-66.7106337, 55.205167199],
  [-66.7197173, 55.209541999],
  [-66.7489004, 55.215698899],
  [-66.7552015, 55.214994299],
  [-66.7570444, 55.213427899],
  [-66.7553137, 55.205695999],
  [-66.7394086, 55.194082999],
  [-66.7381033, 55.191064199],
  [-66.7419621, 55.188174299],
  [-66.7491047, 55.187270799],
  [-66.760627, 55.187811899],
  [-66.7570181, 55.177126099],
  [-66.7569681, 55.171900699],
  [-66.746735, 55.158530999],
  [-66.7457294, 55.153177599],
  [-66.7467703, 55.151483899],
  [-66.7404616, 55.145049199],
  [-66.7460346, 55.146094999],
  [-66.7559394, 55.146094599],
  [-66.7611606, 55.147964299],
  [-66.7668162, 55.147978999],
  [-66.7681265, 55.146006399],
  [-66.7713635, 55.145234999],
  [-66.7711698, 55.144613399],
  [-66.7688416, 55.144181999],
  [-66.7690705, 55.143582699],
  [-66.7732832, 55.142922399],
  [-66.7738327, 55.142092999],
  [-66.7736762, 55.140482299],
  [-66.7717898, 55.138106799],
  [-66.7652524, 55.137940899],
  [-66.762118, 55.136654099],
  [-66.7567189, 55.126580199],
  [-66.7484505, 55.119092599],
  [-66.7468263, 55.114441599],
  [-66.7399392, 55.106167299],
  [-66.7389224, 55.104179599],
  [-66.7393704, 55.103107399],
  [-66.7352705, 55.101334099],
  [-66.7284048, 55.091589699],
  [-66.7249289, 55.091417999],
  [-66.724788, 55.088853899],
  [-66.7237212, 55.087259099],
  [-66.7074528, 55.078149899],
  [-66.6948628, 55.069517399],
  [-66.6922952, 55.062483999],
  [-66.6944418, 55.061695199],
  [-66.6992507, 55.063249299],
  [-66.7313565, 55.081566999],
  [-66.7467883, 55.092074899],
  [-66.7536266, 55.090601899],
  [-66.7555501, 55.086751199],
  [-66.7465817, 55.074081599],
  [-66.7470422, 55.067675999],
  [-66.745914, 55.066102999],
  [-66.732872, 55.058571099],
  [-66.7333713, 55.053076699],
  [-66.7351463, 55.049191199],
  [-66.7350377, 55.044052699],
  [-66.7243932, 55.041402699],
  [-66.7210612, 55.039501499],
  [-66.7164446, 55.034084099],
  [-66.7105232, 55.032864799],
  [-66.7060413, 55.030386299],
  [-66.6978804, 55.027927099],
  [-66.6925998, 55.029667799],
  [-66.6820687, 55.029746699],
  [-66.6763734, 55.027977899],
  [-66.6716836, 55.025547599],
  [-66.6647584, 55.020674399],
  [-66.6596553, 55.015937599],
  [-66.6591571, 55.012307799],
  [-66.6564074, 55.007935899],
  [-66.6436292, 54.999846699],
  [-66.6278731, 54.988429599],
  [-66.6256122, 54.983872699],
  [-66.6285016, 54.982061499],
  [-66.6368149, 54.978618099],
  [-66.6469027, 54.976103099],
  [-66.6465116, 54.975420099],
  [-66.6501198, 54.974622999],
  [-66.6583197, 54.978663999],
  [-66.6663874, 54.985359199],
  [-66.6854009, 54.992824199],
  [-66.6893782, 55.000051899],
  [-66.6997682, 55.000045299],
  [-66.7088055, 55.004644399],
  [-66.712403, 55.000036099],
  [-66.7369077, 55.000013999],
  [-66.7400407, 55.007176099],
  [-66.7474415, 55.009362499],
  [-66.7504025, 55.006766399],
  [-66.7493683, 55.000000699],
  [-67.2192425, 55.000030499],
  [-67.2327432, 55.008709599],
  [-67.2421465, 55.012847399],
  [-67.2496095, 55.018141099],
  [-67.2574846, 55.021274199],
  [-67.2727921, 55.024211599],
  [-67.2734348, 55.027262399],
  [-67.2686945, 55.028409499],
  [-67.2627425, 55.034429799],
  [-67.2620121, 55.039415699],
  [-67.2632964, 55.045517699],
  [-67.2573053, 55.046050099],
  [-67.243719, 55.043837599],
  [-67.2362838, 55.044030799],
  [-67.2339788, 55.051227899],
  [-67.2437303, 55.059635399],
  [-67.2538077, 55.065216499],
  [-67.2631032, 55.071902399],
  [-67.2728118, 55.076427299],
  [-67.2862159, 55.078912899],
  [-67.3228379, 55.089065299],
  [-67.3356915, 55.089159599],
  [-67.3458613, 55.086694699],
  [-67.3684386, 55.091302799],
  [-67.3811226, 55.088453299],
  [-67.3887857, 55.084758499],
  [-67.3952636, 55.084330899],
  [-67.4096325, 55.088633199],
  [-67.428123, 55.083906799],
  [-67.4380175, 55.077829699],
  [-67.4413411, 55.071517099],
  [-67.4432167, 55.064868899],
  [-67.4478636, 55.063048899],
  [-67.4550048, 55.064060399],
  [-67.4637039, 55.062856899],
  [-67.4687559, 55.057267399],
  [-67.4706345, 55.049011599],
  [-67.4630314, 55.044674599],
  [-67.4491649, 55.038883999],
  [-67.439649, 55.032490899],
  [-67.4391045, 55.026891599],
  [-67.44435, 55.021026899],
  [-67.4458718, 55.008503799],
  [-67.427566, 55.005861299],
  [-67.4224881, 55.000060399],
  [-70, 54.999999999],
  [-70, 52.918384799],
  [-70.0247618, 52.918830299],
  [-70.0238543, 52.893115399],
  [-70.0247758, 52.816306099],
  [-70.0238312, 52.714239399],
  [-70.0188961, 52.692901099],
  [-70.037254, 52.694745299],
  [-70.0491917, 52.685492099],
  [-70.0529527, 52.653703399],
  [-70.0797854, 52.650169899],
  [-70.0901034, 52.643636699],
  [-70.1038875, 52.636100599],
  [-70.1275637, 52.624339099],
  [-70.1236716, 52.623455899],
  [-70.1280663, 52.621369299],
  [-70.133509, 52.621383799],
  [-70.1360484, 52.620121199],
  [-70.1478866, 52.608071399],
  [-70.1597829, 52.602901099],
  [-70.1752847, 52.594419299],
  [-70.1727012, 52.590208899],
  [-70.1636791, 52.588404299],
  [-70.1692508, 52.581769899],
  [-70.1759042, 52.579361399],
  [-70.1709811, 52.565588599],
  [-70.1579984, 52.558250999],
  [-70.1584125, 52.554250499],
  [-70.1657128, 52.547066599],
  [-70.1763834, 52.544993799],
  [-70.1916032, 52.512651899],
  [-70.1995757, 52.506345099],
  [-70.2040518, 52.498844099],
  [-70.2114391, 52.495185199],
  [-70.2111208, 52.491612299],
  [-70.2154511, 52.489920499],
  [-70.2197443, 52.473037199],
  [-70.2234785, 52.467421199],
  [-70.2291195, 52.464699199],
  [-70.2590408, 52.454085499],
  [-70.2645721, 52.437912299],
  [-70.2697581, 52.431005599],
  [-70.2814467, 52.425648599],
  [-70.2834945, 52.421275199],
  [-70.2737816, 52.422388899],
  [-70.2701612, 52.422044899],
  [-70.2735212, 52.416641599],
  [-70.2716873, 52.413618899],
  [-70.2655346, 52.412289899],
  [-70.2317158, 52.417528999],
  [-70.2353742, 52.407082199],
  [-70.2510278, 52.402295599],
  [-70.253789, 52.397930899],
  [-70.2603808, 52.391476099],
  [-70.2635275, 52.386027799],
  [-70.2634172, 52.382501899],
  [-70.2621228, 52.378136299],
  [-70.2620382, 52.373740599],
  [-70.2704968, 52.361562599],
  [-70.2754961, 52.358266499],
  [-70.286514, 52.353207599],
  [-70.2918346, 52.348522099],
  [-70.3210862, 52.341564499],
  [-70.3357366, 52.340932699],
  [-70.3399702, 52.339366299],
  [-70.3640825, 52.339457699],
  [-70.3793041, 52.338651499],
  [-70.3824991, 52.335985499],
  [-70.3958119, 52.334766599],
  [-70.4018789, 52.330822599],
  [-70.4161855, 52.329523699],
  [-70.4439067, 52.322648199],
  [-70.452933, 52.319337799],
  [-70.4635128, 52.308927999],
  [-70.4715922, 52.306433999],
  [-70.4856961, 52.304210399],
  [-70.4910413, 52.312348799],
  [-70.5013461, 52.311786099],
  [-70.5045205, 52.309551499],
  [-70.5242556, 52.306196499],
  [-70.539643, 52.307137999],
  [-70.5431908, 52.308671499],
  [-70.549569, 52.314687399],
  [-70.5520563, 52.315713599],
  [-70.5620039, 52.316555899],
  [-70.5763855, 52.312102499],
  [-70.5827843, 52.306019899],
  [-70.5885527, 52.302358099],
  [-70.6010043, 52.300557699],
  [-70.6106139, 52.293260799],
  [-70.6170744, 52.291205599],
  [-70.6300958, 52.290910699],
  [-70.6373901, 52.287061399],
  [-70.6503182, 52.282293199],
  [-70.6612195, 52.283003999],
  [-70.6650234, 52.284906299],
  [-70.6791731, 52.280175899],
  [-70.6896534, 52.280575499],
  [-70.6995855, 52.279836399],
  [-70.7017363, 52.276863699],
  [-70.7071666, 52.273345999],
  [-70.7136275, 52.271797299],
  [-70.7178165, 52.270058899],
  [-70.7208216, 52.265881399],
  [-70.7261322, 52.260879399],
  [-70.7279143, 52.256443099],
  [-70.7373563, 52.254868799],
  [-70.7464789, 52.251111299],
  [-70.7493642, 52.248513199],
  [-70.7610937, 52.246983799],
  [-70.7681204, 52.247375799],
  [-70.7727031, 52.245136299],
  [-70.783528, 52.243413599],
  [-70.799053, 52.233933899],
  [-70.8079758, 52.233265999],
  [-70.8117027, 52.238061799],
  [-70.8244563, 52.246145899],
  [-70.8239583, 52.253403399],
  [-70.8205374, 52.258878599],
  [-70.8124459, 52.260598999],
  [-70.8066715, 52.264119999],
  [-70.8076614, 52.268866799],
  [-70.8096509, 52.272695099],
  [-70.8193603, 52.270407699],
  [-70.8268526, 52.270033899],
  [-70.846331, 52.272456399],
  [-70.8579515, 52.268713299],
  [-70.8602639, 52.271611999],
  [-70.8719875, 52.272572499],
  [-70.8820386, 52.279823399],
  [-70.8920584, 52.271022999],
  [-70.9025452, 52.263574099],
  [-70.9170624, 52.251081099],
  [-70.9207578, 52.243711299],
  [-70.9326753, 52.243203499],
  [-70.9463844, 52.240939799],
  [-70.9576703, 52.236789199],
  [-70.9642682, 52.231155399],
  [-71.0111936, 52.219175899],
  [-71.0224227, 52.219517499],
  [-71.0300355, 52.214970899],
  [-71.0380638, 52.211512599],
  [-71.0811717, 52.212985799],
  [-71.0862145, 52.208277199],
  [-71.0874863, 52.201907499],
  [-71.0837431, 52.189486599],
  [-71.0962456, 52.181951399],
  [-71.1066414, 52.178232599],
  [-71.1159174, 52.177283899],
  [-71.126772, 52.172908399],
  [-71.1403884, 52.169982099],
  [-71.1468546, 52.160064099],
  [-71.1547047, 52.152435899],
  [-71.1532844, 52.145760099],
  [-71.1532679, 52.138750499],
  [-71.1623831, 52.127508099],
  [-71.1704455, 52.123553299],
  [-71.1916798, 52.115577399],
  [-71.197569, 52.107043499],
  [-71.2029103, 52.090017399],
  [-71.2094727, 52.088444099],
  [-71.2127422, 52.085426099],
  [-71.2274319, 52.078220599],
  [-71.2370507, 52.076212599],
  [-71.2437069, 52.069589599],
  [-71.2450083, 52.063482999],
  [-71.2565874, 52.057067599],
  [-71.2626135, 52.054527699],
  [-71.2684768, 52.046197999],
  [-71.2687972, 52.040098899],
  [-71.2472277, 52.035699999],
  [-71.2386034, 52.035503599],
  [-71.2320724, 52.037769499],
  [-71.2119179, 52.039886899],
  [-71.2053578, 52.039634099],
  [-71.2099224, 52.025804199],
  [-71.2461056, 52.021469499],
  [-71.2562166, 52.015681199],
  [-71.2649989, 52.008813899],
  [-71.2737269, 52.000824199],
  [-71.2807363, 51.986469999],
  [-71.2874695, 51.983790799],
  [-71.2929456, 51.977592699],
  [-71.3151183, 51.966866799],
  [-71.3180548, 51.961935499],
  [-71.3169623, 51.955193699],
  [-71.3136597, 51.947180099],
  [-71.3287301, 51.918862499],
  [-71.3362807, 51.914398499],
  [-71.3480553, 51.909179399],
  [-71.3535877, 51.905274899],
  [-71.3635622, 51.878516099],
  [-71.3779766, 51.854469699],
  [-71.3886249, 51.846414399],
  [-71.3997416, 51.841385699],
  [-71.4158226, 51.840310399],
  [-71.4351367, 51.841526599],
  [-71.4501175, 51.843962099],
  [-71.4670206, 51.839631199],
  [-71.5305378, 51.834297399],
  [-71.5450165, 51.827832799],
  [-71.571119, 51.813828399],
  [-71.5722488, 51.801864299],
  [-71.5788129, 51.790526099],
  [-71.5835096, 51.784659199],
  [-71.5822425, 51.757249199],
  [-71.5781118, 51.748274099],
  [-71.5869472, 51.728952199],
  [-71.5933416, 51.718396899],
  [-71.5976676, 51.703354999],
  [-71.6040651, 51.694752199],
  [-71.5998582, 51.685926199],
  [-71.5997486, 51.679727999],
  [-71.6110235, 51.666601399],
  [-71.6131807, 51.659081199],
  [-71.6084523, 51.655186699],
  [-71.6033163, 51.648120699],
  [-71.6098415, 51.621023999],
  [-71.6095691, 51.606507799],
  [-71.6036483, 51.597980699],
  [-71.5980622, 51.593258599],
  [-71.5896549, 51.587956099],
  [-71.5811123, 51.586849299],
  [-71.5808469, 51.573909899],
  [-71.5832164, 51.560950599],
  [-71.5931515, 51.551102099],
  [-71.5908729, 51.543890299],
  [-71.582222, 51.537205599],
  [-71.5793074, 51.532314299],
  [-71.5771819, 51.518880899],
  [-71.5792219, 51.506673999],
  [-71.5834995, 51.500445299],
  [-71.5913777, 51.495203699],
  [-71.6441352, 51.481913799],
  [-71.6654447, 51.477222799],
  [-71.68665, 51.446413499],
  [-71.6914422, 51.433187199],
  [-71.6940043, 51.417299499],
  [-71.6877966, 51.413452999],
  [-71.6864613, 51.407789399],
  [-71.7063629, 51.394115699],
  [-71.7096309, 51.386948499],
  [-71.7088205, 51.381026499],
  [-71.7111325, 51.375867799],
  [-71.749069, 51.350466499],
  [-71.7595097, 51.348443399],
  [-71.766308, 51.359383999],
  [-71.7721615, 51.363152699],
  [-71.7847963, 51.362921799],
  [-71.7993747, 51.354274099],
  [-71.8169955, 51.353467599],
  [-71.8160618, 51.371022999],
  [-71.8206959, 51.375640399],
  [-71.8291196, 51.377113999],
  [-71.8344583, 51.370615099],
  [-71.8363931, 51.359383999],
  [-71.8429053, 51.355694699],
  [-71.8642791, 51.349160499],
  [-71.8747017, 51.336234699],
  [-71.8738723, 51.330682799],
  [-71.8674196, 51.321293999],
  [-71.8708731, 51.315391499],
  [-71.8810863, 51.305483999],
  [-71.8732124, 51.287365899],
  [-71.8735138, 51.278131099],
  [-71.8855956, 51.273255199],
  [-71.894701, 51.274498799],
  [-71.9034247, 51.277987399],
  [-71.9192122, 51.278754099],
  [-71.937004, 51.271488399],
  [-71.9384404, 51.264932999],
  [-71.9454438, 51.256085799],
  [-71.9546735, 51.249525199],
  [-71.9568898, 51.240317299],
  [-71.9893113, 51.229541299],
  [-71.9785008, 51.215735899],
  [-71.9791697, 51.210712499],
  [-71.9919211, 51.206680199],
  [-71.9959303, 51.188235299],
  [-71.9963873, 51.178148899],
  [-72.016884, 51.168072699],
  [-72.0250005, 51.163443899],
  [-72.0397065, 51.161955199],
  [-72.0270589, 51.150366999],
  [-72.0175741, 51.136753799],
  [-72.0274574, 51.137727799],
  [-72.0409208, 51.143981299],
  [-72.0599341, 51.149809099],
  [-72.0692069, 51.150912399],
  [-72.074262, 51.149443099],
  [-72.0776144, 51.142271999],
  [-72.0878071, 51.136056799],
  [-72.0914172, 51.132482599],
  [-72.0925861, 51.127572799],
  [-72.0950066, 51.125161699],
  [-72.1019196, 51.128804399],
  [-72.105298, 51.127428699],
  [-72.1033419, 51.123103999],
  [-72.1027538, 51.109980799],
  [-72.1093891, 51.103089399],
  [-72.1173077, 51.098047699],
  [-72.1206907, 51.094615299],
  [-72.1273781, 51.078568299],
  [-72.1336844, 51.073762599],
  [-72.1406451, 51.070585499],
  [-72.1432043, 51.068764099],
  [-72.141157, 51.061991199],
  [-72.1369125, 51.057205999],
  [-72.1371903, 51.052866599],
  [-72.1427912, 51.048854399],
  [-72.1476775, 51.040115799],
  [-72.1530549, 51.036245699],
  [-72.1552192, 51.032568299],
  [-72.1585143, 51.022574199],
  [-72.1587204, 51.014574799],
  [-72.1548896, 51.003142199],
  [-72.1601061, 50.995781499],
  [-72.1609829, 50.992592899],
  [-72.1599412, 50.987053499],
  [-72.1559061, 50.983620299],
  [-72.155085, 50.980275599],
  [-72.1576861, 50.978651099],
  [-72.1677016, 50.977589899],
  [-72.1759817, 50.979075299],
  [-72.1835587, 50.981072299],
  [-72.1876069, 50.985067399],
  [-72.1944239, 50.989153299],
  [-72.1944811, 50.994305999],
  [-72.1904835, 51.006586999],
  [-72.1915424, 51.015026999],
  [-72.1897552, 51.021771599],
  [-72.1908657, 51.026015399],
  [-72.1941272, 51.028355399],
  [-72.2003524, 51.029235699],
  [-72.2214104, 51.025996899],
  [-72.2251184, 51.019545699],
  [-72.2252693, 51.016024299],
  [-72.2295646, 51.007822299],
  [-72.2363113, 51.003852299],
  [-72.241489, 51.003865599],
  [-72.2455326, 50.990286899],
  [-72.2445044, 50.983818899],
  [-72.2486032, 50.975111799],
  [-72.2620001, 50.971731899],
  [-72.2641513, 50.965997499],
  [-72.2663463, 50.949901299],
  [-72.27449, 50.951351399],
  [-72.2814198, 50.954331999],
  [-72.2907908, 50.952757299],
  [-72.2966486, 50.947580999],
  [-72.299081, 50.942744799],
  [-72.2979978, 50.938136999],
  [-72.302698, 50.923201399],
  [-72.3126303, 50.911372099],
  [-72.3395032, 50.892293999],
  [-72.342, 50.888264899],
  [-72.3478506, 50.886115999],
  [-72.3581037, 50.887566699],
  [-72.3704831, 50.887630199],
  [-72.3737509, 50.885868899],
  [-72.3755015, 50.869009999],
  [-72.3743209, 50.861829699],
  [-72.3756759, 50.852440299],
  [-72.3792194, 50.844395299],
  [-72.3845171, 50.837607599],
  [-72.3902911, 50.832726599],
  [-72.3979824, 50.818268999],
  [-72.4029773, 50.811119799],
  [-72.4203202, 50.804111399],
  [-72.4239639, 50.798262599],
  [-72.4236125, 50.793097199],
  [-72.4283385, 50.786538899],
  [-72.4377919, 50.782222799],
  [-72.4517667, 50.784089099],
  [-72.4587075, 50.786586699],
  [-72.4657281, 50.785394799],
  [-72.4853288, 50.762336899],
  [-72.4955864, 50.755274999],
  [-72.5054017, 50.750803199],
  [-72.5099908, 50.750914199],
  [-72.5148122, 50.754820799],
  [-72.5176232, 50.755112899],
  [-72.5202464, 50.753224799],
  [-72.5221091, 50.746900199],
  [-72.5249727, 50.744524399],
  [-72.5314146, 50.742593199],
  [-72.5348956, 50.744089399],
  [-72.533442, 50.751298599],
  [-72.5361027, 50.755309499],
  [-72.5393438, 50.757293299],
  [-72.5440544, 50.757158899],
  [-72.5493672, 50.755805099],
  [-72.5538878, 50.753490199],
  [-72.5574956, 50.750458399],
  [-72.5610057, 50.748478199],
  [-72.5633159, 50.744652999],
  [-72.5659129, 50.741955899],
  [-72.569492, 50.742399099],
  [-72.5724697, 50.744061599],
  [-72.5734526, 50.747201999],
  [-72.5726339, 50.749716899],
  [-72.5592605, 50.763126899],
  [-72.5568515, 50.768003999],
  [-72.5562213, 50.772699099],
  [-72.557945, 50.779468599],
  [-72.55712, 50.786267599],
  [-72.5514825, 50.794253699],
  [-72.5406379, 50.807633799],
  [-72.5414992, 50.811019099],
  [-72.5462101, 50.815168899],
  [-72.5552547, 50.819107199],
  [-72.5602092, 50.822768699],
  [-72.5618891, 50.827922999],
  [-72.5613516, 50.835851599],
  [-72.5528572, 50.851309599],
  [-72.5505345, 50.859420799],
  [-72.5560741, 50.870414199],
  [-72.5577546, 50.878505499],
  [-72.5573022, 50.885162199],
  [-72.55841, 50.890104299],
  [-72.5616968, 50.892442099],
  [-72.5664738, 50.892488599],
  [-72.5713634, 50.890870699],
  [-72.5777228, 50.884484199],
  [-72.5792016, 50.881853699],
  [-72.5785253, 50.878909199],
  [-72.573934, 50.875976399],
  [-72.5720945, 50.873588399],
  [-72.5717327, 50.870368799],
  [-72.5733633, 50.867431299],
  [-72.5804577, 50.860529499],
  [-72.5862414, 50.857389199],
  [-72.5969873, 50.849338799],
  [-72.6009376, 50.844764399],
  [-72.606957, 50.832565799],
  [-72.6061855, 50.823844299],
  [-72.610274, 50.806980899],
  [-72.6221499, 50.786305699],
  [-72.6278649, 50.772980099],
  [-72.6349312, 50.770630299],
  [-72.6429562, 50.766328399],
  [-72.6451148, 50.761937099],
  [-72.6526074, 50.756994399],
  [-72.6641836, 50.752323199],
  [-72.6732104, 50.747683099],
  [-72.6806113, 50.748073899],
  [-72.685517, 50.750112499],
  [-72.6866284, 50.753007899],
  [-72.6842355, 50.757887899],
  [-72.6836202, 50.762583599],
  [-72.6866132, 50.768526399],
  [-72.6918644, 50.773308599],
  [-72.6970649, 50.776474699],
  [-72.7057718, 50.777655199],
  [-72.714374, 50.775602999],
  [-72.7179065, 50.766665599],
  [-72.7203488, 50.763400399],
  [-72.7263995, 50.761380799],
  [-72.7310704, 50.763905399],
  [-72.7322921, 50.770032099],
  [-72.7308591, 50.772960999],
  [-72.7257359, 50.779980099],
  [-72.7253641, 50.784188299],
  [-72.7267183, 50.786595399],
  [-72.7273556, 50.790466899],
  [-72.7254435, 50.794372299],
  [-72.7253106, 50.798092399],
  [-72.7310695, 50.801056599],
  [-72.74121, 50.800172299],
  [-72.744451, 50.800908399],
  [-72.7457766, 50.802359499],
  [-72.7417104, 50.808109399],
  [-72.7424204, 50.809538799],
  [-72.748914, 50.811310399],
  [-72.7502844, 50.812670899],
  [-72.750439, 50.815876399],
  [-72.7487517, 50.820605599],
  [-72.750837, 50.822106499],
  [-72.7536511, 50.822758099],
  [-72.7623572, 50.821291399],
  [-72.78227, 50.825096599],
  [-72.7877967, 50.823401599],
  [-72.7933492, 50.819727399],
  [-72.8229235, 50.815595599],
  [-72.8366956, 50.814320399],
  [-72.8532181, 50.810605299],
  [-72.8615108, 50.809645599],
  [-72.8637328, 50.808294299],
  [-72.8651197, 50.804796599],
  [-72.8669012, 50.804350899],
  [-72.8691883, 50.804797199],
  [-72.8721127, 50.807152599],
  [-72.8747301, 50.806274799],
  [-72.8773916, 50.801439199],
  [-72.8946674, 50.777442299],
  [-72.9041799, 50.771365199],
  [-72.9327786, 50.762659499],
  [-72.9365993, 50.761222199],
  [-72.9380467, 50.759521499],
  [-72.9381534, 50.757361799],
  [-72.9364589, 50.755049799],
  [-72.9301721, 50.751249299],
  [-72.9292019, 50.748086999],
  [-72.9291245, 50.738556999],
  [-72.9351534, 50.715746999],
  [-72.9368391, 50.712904099],
  [-72.9390533, 50.711551499],
  [-72.9443641, 50.712188999],
  [-72.9475945, 50.715196099],
  [-72.9497899, 50.715821499],
  [-72.9543743, 50.715690599],
  [-72.9586695, 50.713962399],
  [-72.9728227, 50.705544499],
  [-73.0072531, 50.676532099],
  [-73.0224369, 50.668312799],
  [-73.0284928, 50.662898299],
  [-73.0337882, 50.660264699],
  [-73.039012, 50.660179299],
  [-73.0465743, 50.657618699],
  [-73.0511354, 50.654110299],
  [-73.0544568, 50.654387699],
  [-73.0567001, 50.658116199],
  [-73.057363, 50.661538599],
  [-73.0547489, 50.665790499],
  [-73.0391839, 50.673024199],
  [-73.0326456, 50.679444199],
  [-73.0318594, 50.685881199],
  [-73.0335485, 50.693164099],
  [-73.0378302, 50.699850999],
  [-73.0456254, 50.702824799],
  [-73.0510153, 50.702403799],
  [-73.0573693, 50.699972799],
  [-73.0632191, 50.693783599],
  [-73.0643304, 50.691414699],
  [-73.0663991, 50.690453099],
  [-73.0718264, 50.690311199],
  [-73.073511, 50.691828399],
  [-73.0759422, 50.691787699],
  [-73.0810601, 50.690053599],
  [-73.083438, 50.690683399],
  [-73.085635, 50.692809899],
  [-73.0866274, 50.695214299],
  [-73.0873215, 50.698241899],
  [-73.0868536, 50.702576799],
  [-73.0848817, 50.707812899],
  [-73.0754094, 50.721983899],
  [-73.0748973, 50.725289599],
  [-73.0772879, 50.728134599],
  [-73.0821767, 50.732483199],
  [-73.0909836, 50.735374799],
  [-73.0955526, 50.735915899],
  [-73.1072832, 50.734943999],
  [-73.1101024, 50.736338499],
  [-73.1116849, 50.739196699],
  [-73.1118467, 50.741100199],
  [-73.1077092, 50.746941099],
  [-73.1079456, 50.748688799],
  [-73.1093138, 50.750314199],
  [-73.1120377, 50.751349699],
  [-73.1197278, 50.752660799],
  [-73.1276946, 50.752833099],
  [-73.1376571, 50.752197299],
  [-73.1455126, 50.749793899],
  [-73.1573124, 50.744744799],
  [-73.1691678, 50.737323199],
  [-73.1742843, 50.729968399],
  [-73.1798799, 50.718792599],
  [-73.1833313, 50.708736799],
  [-73.1877568, 50.700569899],
  [-73.1930366, 50.700733199],
  [-73.1950459, 50.702139599],
  [-73.1954341, 50.703575099],
  [-73.1976891, 50.705028699],
  [-73.2001438, 50.705499799],
  [-73.2076899, 50.703715399],
  [-73.2137942, 50.707777999],
  [-73.2164717, 50.707780999],
  [-73.2210437, 50.706616199],
  [-73.2296575, 50.699762099],
  [-73.2321478, 50.695646799],
  [-73.2391875, 50.688151099],
  [-73.2425061, 50.686235599],
  [-73.2498013, 50.684401199],
  [-73.2534148, 50.681861699],
  [-73.2710017, 50.657169499],
  [-73.2739042, 50.651603699],
  [-73.2738326, 50.650059799],
  [-73.2717494, 50.648810999],
  [-73.2679236, 50.648418899],
  [-73.2665763, 50.647310899],
  [-73.267412, 50.646110599],
  [-73.2905268, 50.634550999],
  [-73.2943543, 50.631542699],
  [-73.3008328, 50.622921399],
  [-73.3031155, 50.618088099],
  [-73.3035099, 50.614423799],
  [-73.3021671, 50.606517999],
  [-73.3034688, 50.601497499],
  [-73.3107268, 50.587247999],
  [-73.3138867, 50.585642599],
  [-73.3256664, 50.585004399],
  [-73.3285316, 50.584022099],
  [-73.3303681, 50.580124299],
  [-73.329413, 50.577052799],
  [-73.3307597, 50.576460399],
  [-73.3350936, 50.577457999],
  [-73.3375664, 50.576740599],
  [-73.3402588, 50.573855999],
  [-73.3410903, 50.570955999],
  [-73.3452488, 50.563253699],
  [-73.3528054, 50.552292299],
  [-73.3542931, 50.544590699],
  [-73.3542154, 50.537949699],
  [-73.3572929, 50.531401599],
  [-73.3617165, 50.527658899],
  [-73.3731533, 50.520327899],
  [-73.3856647, 50.515239899],
  [-73.3914754, 50.510232699],
  [-73.3927656, 50.506911799],
  [-73.3912934, 50.503234099],
  [-73.3879893, 50.500056399],
  [-73.3862985, 50.496846499],
  [-73.3870925, 50.488335699],
  [-73.3876242, 50.484360899],
  [-73.3906695, 50.478997399],
  [-73.3955196, 50.472619699],
  [-73.4096335, 50.459517599],
  [-73.420361, 50.453071699],
  [-73.4362677, 50.440650699],
  [-73.4469452, 50.428592399],
  [-73.4507575, 50.422697399],
  [-73.4538208, 50.411567499],
  [-73.4503016, 50.400882599],
  [-73.4500216, 50.396925199],
  [-73.4538979, 50.389176399],
  [-73.4581671, 50.382860599],
  [-73.4597273, 50.375519999],
  [-73.4608681, 50.374209799],
  [-73.463518, 50.372507899],
  [-73.4675577, 50.371240399],
  [-73.4710169, 50.371219899],
  [-73.4746272, 50.372585699],
  [-73.4773876, 50.374638199],
  [-73.4781778, 50.377503799],
  [-73.4771936, 50.388178199],
  [-73.4816091, 50.395806399],
  [-73.4824064, 50.400370599],
  [-73.4841734, 50.403421899],
  [-73.4869628, 50.405988499],
  [-73.4889148, 50.406359299],
  [-73.4904249, 50.405967299],
  [-73.4927258, 50.402161499],
  [-73.4936879, 50.398950099],
  [-73.4937201, 50.394883199],
  [-73.4948269, 50.391359999],
  [-73.4983389, 50.386087599],
  [-73.5023523, 50.384304399],
  [-73.5261235, 50.381689099],
  [-73.5305508, 50.380153299],
  [-73.5371107, 50.371726199],
  [-73.5404347, 50.369132899],
  [-73.5447607, 50.367238299],
  [-73.5495288, 50.366106099],
  [-73.5538634, 50.365909399],
  [-73.5588846, 50.366521099],
  [-73.5630847, 50.368334099],
  [-73.5670288, 50.363822099],
  [-73.5706906, 50.357051599],
  [-73.5738843, 50.353585099],
  [-73.5777574, 50.353809099],
  [-73.5804666, 50.354830499],
  [-73.582699, 50.357457799],
  [-73.5833352, 50.361818499],
  [-73.5822135, 50.369408899],
  [-73.5820395, 50.376669799],
  [-73.5832084, 50.380452899],
  [-73.5761481, 50.397438799],
  [-73.5749347, 50.406370299],
  [-73.5779779, 50.413561699],
  [-73.5842583, 50.419498399],
  [-73.5867002, 50.419959599],
  [-73.5880378, 50.419363899],
  [-73.591307, 50.415740099],
  [-73.5912683, 50.413527499],
  [-73.5930886, 50.408442799],
  [-73.5958682, 50.404726799],
  [-73.6109024, 50.389415099],
  [-73.6095133, 50.368291499],
  [-73.6042482, 50.349828699],
  [-73.601915, 50.346843999],
  [-73.602419, 50.345751999],
  [-73.6080465, 50.342745199],
  [-73.6119671, 50.339415799],
  [-73.6139372, 50.335717999],
  [-73.6193897, 50.322164799],
  [-73.6190612, 50.310233399],
  [-73.6330425, 50.286764399],
  [-73.640929, 50.275369599],
  [-73.654624, 50.261681799],
  [-73.655703, 50.256497799],
  [-73.6627538, 50.252670199],
  [-73.6692889, 50.250124299],
  [-73.6710442, 50.246753999],
  [-73.6728801, 50.240080999],
  [-73.6699373, 50.233115699],
  [-73.6734892, 50.228632699],
  [-73.6821546, 50.220099499],
  [-73.6855265, 50.211584999],
  [-73.6895008, 50.210978799],
  [-73.7004816, 50.212370799],
  [-73.7121764, 50.212201599],
  [-73.7169541, 50.214456499],
  [-73.7234763, 50.216361699],
  [-73.7370338, 50.217895099],
  [-73.7420984, 50.219524799],
  [-73.7437345, 50.222881599],
  [-73.7416907, 50.231984699],
  [-73.7421526, 50.234443399],
  [-73.7452734, 50.235707299],
  [-73.749534, 50.234474599],
  [-73.7624206, 50.227068499],
  [-73.7677585, 50.222310799],
  [-73.7720627, 50.216342899],
  [-73.7830671, 50.193340199],
  [-73.7887859, 50.184251499],
  [-73.7910573, 50.179258399],
  [-73.7919522, 50.172652399],
  [-73.7887966, 50.167996099],
  [-73.7791625, 50.164833099],
  [-73.7773838, 50.161789499],
  [-73.7765171, 50.157901099],
  [-73.7778771, 50.153753599],
  [-73.786183, 50.142443699],
  [-73.7884524, 50.137451299],
  [-73.7873834, 50.132848099],
  [-73.7823498, 50.128854899],
  [-73.7787134, 50.124107799],
  [-73.7773845, 50.117762399],
  [-73.7786834, 50.112588299],
  [-73.7842879, 50.107207599],
  [-73.7914494, 50.098394899],
  [-73.7921793, 50.094468899],
  [-73.7843884, 50.081492399],
  [-73.7855655, 50.074264799],
  [-73.7886807, 50.067401899],
  [-73.7930471, 50.060097399],
  [-73.8035287, 50.044710799],
  [-73.806546, 50.031544199],
  [-73.8088889, 50.030377499],
  [-73.8116931, 50.031196699],
  [-73.8145149, 50.029973799],
  [-73.8159179, 50.022008499],
  [-73.8218095, 50.015737599],
  [-73.8294374, 50.011408999],
  [-73.8396989, 50.010173999],
  [-73.8453279, 50.012916499],
  [-73.8548326, 50.012872699],
  [-73.8548464, 50.005358299],
  [-73.8533913, 49.998200899],
  [-73.8589032, 49.983815699],
  [-73.8643498, 49.975846399],
  [-73.8696634, 49.970099999],
  [-73.8805543, 49.965504499],
  [-73.892429, 49.957265299],
  [-73.8972795, 49.948904199],
  [-73.8960117, 49.940836899],
  [-73.9008143, 49.932818699],
  [-73.9092509, 49.921549499],
  [-73.9107697, 49.912601099],
  [-73.9109629, 49.902246399],
  [-73.9127647, 49.896054799],
  [-73.9216495, 49.891997299],
  [-73.9225123, 49.881839499],
  [-73.9298369, 49.874225999],
  [-73.9404718, 49.866003899],
  [-73.9495561, 49.854929899],
  [-73.9580904, 49.841191999],
  [-73.9693045, 49.840622399],
  [-73.9849967, 49.830994299],
  [-73.9904319, 49.818573199],
  [-74.004852, 49.805953699],
  [-74.0168305, 49.800170099],
  [-74.0171205, 49.7348801],
  [-74.0970864, 49.7350211],
  [-74.1028653, 49.7276992],
  [-74.1027245, 49.7249971],
  [-74.1072398, 49.7176534],
  [-74.1160333, 49.7078233],
  [-74.1167113, 49.7056157],
  [-74.1182388, 49.7039813],
  [-74.1179975, 49.7027562],
  [-74.1224515, 49.6933906],
  [-74.1298341, 49.6868352],
  [-74.1374533, 49.6813446],
  [-74.1438647, 49.6789988],
  [-74.1470275, 49.6786535],
  [-74.1511317, 49.6792383],
  [-74.1592862, 49.6813037],
  [-74.1692865, 49.6801065],
  [-74.1773325, 49.6766891],
  [-74.1948055, 49.6562368],
  [-74.1975669, 49.6497178],
  [-74.1987528, 49.6432455],
  [-74.196112, 49.6359258],
  [-74.1919044, 49.6271633],
  [-74.1931903, 49.6131695],
  [-74.2027829, 49.6045446],
  [-74.2117212, 49.598612],
  [-74.215552, 49.5900694],
  [-74.2214696, 49.5832956],
  [-74.249492, 49.5709942],
  [-74.2511163, 49.5641035],
  [-74.2514015, 49.5525382],
  [-74.2526892, 49.5440349],
  [-74.2587561, 49.5329196],
  [-74.2656178, 49.5273014],
  [-74.2603245, 49.5119822],
  [-74.2730751, 49.5054676],
  [-74.2757461, 49.5047067],
  [-74.2857023, 49.4954345],
  [-74.2920877, 49.4908792],
  [-74.2968014, 49.4860145],
  [-74.310975, 49.4790399],
  [-74.3248451, 49.4764147],
  [-74.3314259, 49.4786647],
  [-74.3271388, 49.4848726],
  [-74.3280012, 49.4902155],
  [-74.333799, 49.4933575],
  [-74.3430338, 49.5005014],
  [-74.3499317, 49.5020303],
  [-74.3625237, 49.4950166],
  [-74.3664336, 49.4878313],
  [-74.3675421, 49.4779284],
  [-74.3882825, 49.4745662],
  [-74.3918609, 49.4718692],
  [-74.3929901, 49.4691417],
  [-74.3890018, 49.4640818],
  [-74.3867377, 49.4593026],
  [-74.3786563, 49.4513393],
  [-74.3773162, 49.4461698],
  [-74.3801336, 49.4424129],
  [-74.4044845, 49.4292717],
  [-74.4117905, 49.421547],
  [-74.4137611, 49.4086983],
  [-74.4120071, 49.3962021],
  [-74.4154007, 49.3846523],
  [-74.4124058, 49.3750369],
  [-74.4011271, 49.374517],
  [-74.3983841, 49.3716813],
  [-74.3990883, 49.3663717],
  [-74.4124582, 49.355528],
  [-74.424209, 49.3419528],
  [-74.4293636, 49.3327192],
  [-74.4302851, 49.3272009],
  [-74.430331, 49.3256025],
  [-74.4255382, 49.3193316],
  [-74.4148765, 49.3149642],
  [-74.3997861, 49.3142359],
  [-74.3923322, 49.3157147],
  [-74.3880065, 49.3191067],
  [-74.3844086, 49.3204936],
  [-74.3792883, 49.3203518],
  [-74.3750699, 49.3189685],
  [-74.3720686, 49.3119952],
  [-74.37328, 49.3033241],
  [-74.3563452, 49.2946932],
  [-74.3366999, 49.2826546],
  [-74.3329722, 49.2779016],
  [-74.3160013, 49.2673716],
  [-74.3139774, 49.2607953],
  [-74.3076791, 49.2607478],
  [-74.3047929, 49.2665024],
  [-74.3035637, 49.2735985],
  [-74.2977741, 49.2808832],
  [-74.2897987, 49.2856084],
  [-74.2854228, 49.2874357],
  [-74.2820216, 49.2862481],
  [-74.2813218, 49.2795438],
  [-74.2791692, 49.2767841],
  [-74.2759986, 49.2751839],
  [-74.2729374, 49.2763011],
  [-74.2676682, 49.2795368],
  [-74.2610759, 49.285224],
  [-74.2564172, 49.2882359],
  [-74.2497445, 49.2893558],
  [-74.2432355, 49.2871335],
  [-74.2407088, 49.2834599],
  [-74.2395982, 49.2717882],
  [-74.2401267, 49.2439004],
  [-74.2390209, 49.2188706],
  [-74.2413209, 49.2185302],
  [-74.2443414, 49.2165298],
  [-74.2461311, 49.2130826],
  [-74.2433307, 49.2009095],
  [-74.2439535, 49.1860241],
  [-74.2541499, 49.1752822],
  [-74.2548007, 49.168078],
  [-74.2500238, 49.1508191],
  [-74.2481108, 49.1474804],
  [-74.2437013, 49.1423795],
  [-74.2436182, 49.1398359],
  [-74.2463435, 49.1383348],
  [-74.2517616, 49.1388269],
  [-74.2580056, 49.1408338],
  [-74.264438, 49.13944],
  [-74.2692549, 49.1342448],
  [-74.2730555, 49.1180525],
  [-74.2672759, 49.1109146],
  [-74.272061, 49.1023859],
  [-74.2752053, 49.0995519],
  [-74.27388, 49.0867647],
  [-74.2804274, 49.073465],
  [-74.2914829, 49.0605225],
  [-74.290987, 49.0543465],
  [-74.2919179, 49.0468201],
  [-74.2905336, 49.0417173],
  [-74.2987263, 49.0432443],
  [-74.3032827, 49.0433265],
  [-74.3060129, 49.0417269],
  [-74.3062658, 49.0396179],
  [-74.3044916, 49.0361251],
  [-74.2979064, 49.027735],
  [-74.2965768, 49.0213544],
  [-74.3060365, 49.0144459],
  [-74.299115, 49.0112393],
  [-74.2984566, 49.0039781],
  [-74.3165641, 48.9920677],
  [-74.3264406, 48.9872788],
  [-74.327411, 48.9364963],
  [-74.330556, 48.9317228],
  [-74.3361053, 48.9286343],
  [-74.3409813, 48.9278422],
  [-74.3498796, 48.9297056],
  [-74.3622792, 48.9372692],
  [-74.3749664, 48.9394222],
  [-74.3807365, 48.939464],
  [-74.3853249, 48.9389768],
  [-74.3906709, 48.9370844],
  [-74.4011696, 48.9322843],
  [-74.409862, 48.9265819],
  [-74.4145753, 48.9265289],
  [-74.418763, 48.9284389],
  [-74.4237386, 48.9339975],
  [-74.4311112, 48.9357758],
  [-74.4438903, 48.9292816],
  [-74.4511009, 48.9327583],
  [-74.4554563, 48.9404114],
  [-74.4509994, 48.9454136],
  [-74.4428869, 48.9505051],
  [-74.4335729, 48.9523539],
  [-74.4305458, 48.9544518],
  [-74.4297696, 48.9573282],
  [-74.4346691, 48.959469],
  [-74.4376625, 48.9600231],
  [-74.4444483, 48.9592584],
  [-74.45219, 48.9571601],
  [-74.456135, 48.9569965],
  [-74.4737195, 48.9636271],
  [-74.4814698, 48.9672696],
  [-74.4931035, 48.9711908],
  [-74.4941345, 48.971933],
  [-74.496821, 48.9792978],
  [-74.4985469, 48.9815866],
  [-74.5078287, 48.9887607],
  [-74.5079383, 48.994672],
  [-74.5105354, 48.9976387],
  [-74.5113562, 49.000014],
  [-74.5154687, 49.0000188],
  [-74.5260664, 48.9955078],
  [-74.5290317, 48.9913434],
  [-74.5409413, 48.9830099],
  [-74.5561691, 48.9764789],
  [-74.5680507, 48.9741932],
  [-74.5756221, 48.9674222],
  [-74.5838837, 48.9631129],
  [-74.5847756, 48.9635558],
  [-74.588246, 48.9692939],
  [-74.5892038, 48.9696726],
  [-74.5908758, 48.9695477],
  [-74.6041719, 48.9643579],
  [-74.6115703, 48.9662333],
  [-74.6110105, 48.9733067],
  [-74.6147671, 48.9755933],
  [-74.6212121, 48.9737973],
  [-74.6306312, 48.9662941],
  [-74.6762542, 48.9947554],
  [-74.6762761, 49.0001006],
  [-77.98711, 49.000401],
  [-78.7548263, 48.9999406],
  [-78.7549784, 49.0091635],
  [-78.9034654, 49.0097265],
  [-78.903186, 49.0000422],
  [-79.0512765, 49.0000984],
  [-79.0532184, 49.0095294],
  [-79.2993915, 49.0105146],
  [-79.5173032, 49.0088925],
  [-79.51621, 51.488559899],
  [-79.5183799, 51.491159999],
  [-79.51851, 51.493719999],
  [-79.51685, 51.493949999],
  [-79.51684, 51.494469999],
  [-79.5155799, 51.494959999],
  [-79.5168199, 51.494980099],
  [-79.5177801, 51.497299999],
  [-79.51919, 51.497829999],
  [-79.51977, 51.499119899],
  [-79.51932, 51.500139999],
  [-79.5229299, 51.503000099],
  [-79.52286, 51.504789899],
  [-79.52387, 51.505579899],
  [-79.5226799, 51.506057099],
  [-79.52363, 51.506599999],
  [-79.5260499, 51.514320099],
  [-79.5252401, 51.514049999],
  [-79.5255601, 51.516869999],
  [-79.5268099, 51.516629999],
  [-79.52677, 51.517399999],
  [-79.52554, 51.517389999],
  [-79.5267199, 51.518939999],
  [-79.52756, 51.518440099],
  [-79.52885, 51.529469899],
  [-79.5304799, 51.529749999],
  [-79.53063, 51.531560099],
  [-79.53143, 51.532319999],
  [-79.53099, 51.533089999],
  [-79.5320101, 51.533359899],
  [-79.5385701, 51.540099999],
  [-79.5381499, 51.547020099],
  [-79.54141, 51.547949999],
  [-79.5428001, 51.549379899],
  [-79.54208, 51.552189999],
  [-79.53834, 51.553690099],
  [-79.53707, 51.554949999],
  [-79.5292699, 51.554340099],
  [-79.5288299, 51.555099999],
  [-79.5272, 51.555209999],
  [-79.52352, 51.554389899],
  [-79.52164, 51.555269899],
  [-79.5211599, 51.557570099],
  [-79.52196, 51.558350099],
  [-79.5199199, 51.563959999],
  [-79.51539, 51.564159899],
  [-79.5153501, 51.565439999],
  [-79.5115999, 51.567059899],
  [-79.5037701, 51.567599899],
  [-79.5008099, 51.569869999],
  [-79.49876, 51.569839899],
  [-79.49776, 51.569059899],
  [-79.4984501, 51.566759999],
  [-79.49518, 51.565949899],
  [-79.49482, 51.564659999],
  [-79.49196, 51.564119999],
  [-79.4904699, 51.565629999],
  [-79.49018, 51.568189999],
  [-79.48228, 51.570649999],
  [-79.48184, 51.571669999],
  [-79.4810099, 51.571659999],
  [-79.4805501, 51.573199999],
  [-79.4776501, 51.573930099],
  [-79.47692, 51.577250099],
  [-79.4752299, 51.578509899],
  [-79.4760099, 51.579800099],
  [-79.47492, 51.581829899],
  [-79.4719901, 51.583079999],
  [-79.4720099, 51.582569999],
  [-79.4707701, 51.582810099],
  [-79.47073, 51.584089999],
  [-79.4658, 51.584019999],
  [-79.46581, 51.583509899],
  [-79.46051, 51.582159899],
  [-79.4594399, 51.583169899],
  [-79.4598, 51.584710099],
  [-79.45769, 51.586480099],
  [-79.4578701, 51.587250099],
  [-79.45703, 51.587499899],
  [-79.4603201, 51.587539999],
  [-79.4569999, 51.588519899],
  [-79.4577601, 51.590319999],
  [-79.45983, 51.590349999],
  [-79.4602001, 51.591119999],
  [-79.4523399, 51.592429999],
  [-79.44536, 51.591949899],
  [-79.44479, 51.596559999],
  [-79.44315, 51.596539999],
  [-79.4418501, 51.598309999],
  [-79.4386, 51.597119999],
  [-79.43614, 51.597089999],
  [-79.43445, 51.598099999],
  [-79.42993, 51.598160099],
  [-79.42845, 51.599160099],
  [-79.42856, 51.601979999],
  [-79.4231701, 51.603199999],
  [-79.42079, 51.606490099],
  [-79.4213901, 51.607009999],
  [-79.42015, 51.606989999],
  [-79.41903, 51.609799999],
  [-79.42079, 51.612129999],
  [-79.41547, 51.611539999],
  [-79.4139799, 51.613060099],
  [-79.41416, 51.613579999],
  [-79.41249, 51.614069899],
  [-79.41182, 51.615849899],
  [-79.4124199, 51.616369999],
  [-79.40991, 51.617490099],
  [-79.40413, 51.618050099],
  [-79.4030501, 51.619319999],
  [-79.4027499, 51.622139999],
  [-79.3924199, 51.623019999],
  [-79.39198, 51.624039999],
  [-79.38781, 51.625259999],
  [-79.38779, 51.626029999],
  [-79.3840599, 51.626749899],
  [-79.38109, 51.629019999],
  [-79.3798499, 51.629259999],
  [-79.3798301, 51.629769999],
  [-79.3765199, 51.630229999],
  [-79.3760699, 51.631249999],
  [-79.3752501, 51.631239999],
  [-79.3737001, 51.634300099],
  [-79.3750401, 51.637129999],
  [-79.37891, 51.638209899],
  [-79.3790599, 51.639749899],
  [-79.38235, 51.639799999],
  [-79.3827201, 51.641080099],
  [-79.3798301, 51.641300099],
  [-79.3789599, 51.642569999],
  [-79.3777299, 51.642549899],
  [-79.37812, 51.643070099],
  [-79.37688, 51.643309899],
  [-79.37644, 51.644070099],
  [-79.36444, 51.645190099],
  [-79.3608001, 51.649239999],
  [-79.36097, 51.650259999],
  [-79.3601501, 51.650249999],
  [-79.3605999, 51.654869899],
  [-79.3628101, 51.656439899],
  [-79.3607101, 51.657559899],
  [-79.3578101, 51.657909999],
  [-79.35779, 51.658420099],
  [-79.3424701, 51.660899999],
  [-79.33255, 51.661009999],
  [-79.32672, 51.662980099],
  [-79.31762, 51.663870099],
  [-79.31428, 51.665109899],
  [-79.3109901, 51.664919999],
  [-79.31228, 51.658029999],
  [-79.3119199, 51.656489999],
  [-79.3093301, 51.654139999],
  [-79.3064799, 51.653329899],
  [-79.30649, 51.652819999],
  [-79.29551, 51.649080099],
  [-79.2927801, 51.644939999],
  [-79.29361, 51.644950099],
  [-79.29363, 51.644429899],
  [-79.2907601, 51.643620099],
  [-79.29082, 51.642339999],
  [-79.28959, 51.642329899],
  [-79.29039, 51.642850099],
  [-79.2895599, 51.642970099],
  [-79.2834499, 51.641080099],
  [-79.2744301, 51.640049999],
  [-79.2739901, 51.640819999],
  [-79.26739, 51.641099899],
  [-79.2578501, 51.642879899],
  [-79.2578701, 51.642369999],
  [-79.25583, 51.642089899],
  [-79.25625, 51.641579999],
  [-79.2525599, 51.641009999],
  [-79.2529999, 51.640510099],
  [-79.25094, 51.640479999],
  [-79.2509601, 51.639960099],
  [-79.24516, 51.640649899],
  [-79.24478, 51.639869899],
  [-79.2447501, 51.640639899],
  [-79.2389601, 51.641319899],
  [-79.2376599, 51.643099899],
  [-79.2364199, 51.643080099],
  [-79.2364001, 51.643589999],
  [-79.23145, 51.643899999],
  [-79.22894, 51.644759899],
  [-79.22892, 51.645279999],
  [-79.22566, 51.644449999],
  [-79.2260899, 51.643950099],
  [-79.22526, 51.643929999],
  [-79.22516, 51.641369999],
  [-79.22438, 51.640589999],
  [-79.2242099, 51.634180099],
  [-79.2226201, 51.632359999],
  [-79.22246, 51.626209899],
  [-79.22549, 51.622419899],
  [-79.2257499, 51.620879999],
  [-79.2276499, 51.619879999],
  [-79.22793, 51.617830099],
  [-79.22916, 51.617849899],
  [-79.2291999, 51.616830099],
  [-79.23003, 51.616840099],
  [-79.23093, 51.615060099],
  [-79.23237, 51.614820099],
  [-79.23347, 51.613050099],
  [-79.2338799, 51.613050099],
  [-79.23344, 51.613809999],
  [-79.2340701, 51.613569999],
  [-79.2347499, 51.611789999],
  [-79.23556, 51.612050099],
  [-79.2356, 51.611029999],
  [-79.2364101, 51.611549999],
  [-79.23836, 51.609019999],
  [-79.2381901, 51.607989999],
  [-79.23902, 51.607879999],
  [-79.24034, 51.605720099],
  [-79.2418, 51.605219999],
  [-79.2420899, 51.602930099],
  [-79.24291, 51.602940099],
  [-79.24714, 51.594799999],
  [-79.2484201, 51.593539999],
  [-79.2498699, 51.587920099],
  [-79.2490899, 51.581499899],
  [-79.24746, 51.580959899],
  [-79.2477599, 51.578659999],
  [-79.24724, 51.576349999],
  [-79.24805, 51.576360099],
  [-79.24872, 51.575079999],
  [-79.2470701, 51.569939899],
  [-79.24624, 51.569929899],
  [-79.24701, 51.566089999],
  [-79.2456499, 51.564030099],
  [-79.24483, 51.564009999],
  [-79.2438501, 51.562719899],
  [-79.2440999, 51.561439999],
  [-79.24328, 51.561419999],
  [-79.2429601, 51.559109999],
  [-79.24213, 51.559099999],
  [-79.2429799, 51.558599899],
  [-79.24216, 51.558589899],
  [-79.2420801, 51.555259899],
  [-79.2405201, 51.553179999],
  [-79.24081, 51.550879999],
  [-79.2416301, 51.550889999],
  [-79.2418799, 51.549869999],
  [-79.24291, 51.549629999],
  [-79.2425399, 51.548599899],
  [-79.24336, 51.548609999],
  [-79.2434101, 51.547329999],
  [-79.24424, 51.547340099],
  [-79.24405, 51.546829899],
  [-79.24601, 51.544039899],
  [-79.247608, 51.543261499],
  [-79.24606, 51.542769999],
  [-79.24235, 51.542959999],
  [-79.24081, 51.540369899],
  [-79.2379501, 51.539819899],
  [-79.23758, 51.538790099],
  [-79.23636, 51.538509999],
  [-79.2359799, 51.537739999],
  [-79.23511, 51.538749999],
  [-79.2342901, 51.538739999],
  [-79.23425, 51.539759999],
  [-79.23301, 51.539989999],
  [-79.2326301, 51.539220099],
  [-79.22726, 51.539910099],
  [-79.22649, 51.538619999],
  [-79.20943, 51.533359899],
  [-79.2001501, 51.529120099],
  [-79.18878, 51.525609999],
  [-79.1879499, 51.525979999],
  [-79.18798, 51.525469899],
  [-79.1871501, 51.525459899],
  [-79.1867199, 51.525959999],
  [-79.18675, 51.525199999],
  [-79.18141, 51.525110099],
  [-79.18143, 51.524599999],
  [-79.16996, 51.523649999],
  [-79.16998, 51.523139899],
  [-79.1560301, 51.522660099],
  [-79.1560101, 51.523179999],
  [-79.1531401, 51.523129899],
  [-79.1535701, 51.522629999],
  [-79.15108, 51.523359899],
  [-79.15152, 51.522599999],
  [-79.14943, 51.523330099],
  [-79.1494501, 51.522819999],
  [-79.1478099, 51.522790099],
  [-79.1477901, 51.523309999],
  [-79.14617, 51.522770099],
  [-79.14552, 51.523529999],
  [-79.1420399, 51.523210099],
  [-79.14243, 51.523729999],
  [-79.1395601, 51.523679899],
  [-79.13995, 51.524210099],
  [-79.1308099, 51.526619999],
  [-79.1286801, 51.528379999],
  [-79.1257801, 51.529110099],
  [-79.12532, 51.530120099],
  [-79.12119, 51.530699899],
  [-79.1154501, 51.530220099],
  [-79.1150299, 51.530729999],
  [-79.11505, 51.530220099],
  [-79.1142301, 51.530199999],
  [-79.1142101, 51.530719999],
  [-79.11216, 51.530550099],
  [-79.1063499, 51.531869999],
  [-79.10548, 51.533139899],
  [-79.0952101, 51.532969999],
  [-79.0888601, 51.527489999],
  [-79.08763, 51.527479899],
  [-79.08727, 51.526429999],
  [-79.0864301, 51.526680099],
  [-79.08528, 51.524859999],
  [-79.08404, 51.524839999],
  [-79.08207, 51.523019899],
  [-79.0804399, 51.522739999],
  [-79.08048, 51.521709999],
  [-79.0776401, 51.520900099],
  [-79.0776699, 51.520129899],
  [-79.0752299, 51.519569899],
  [-79.07526, 51.518550099],
  [-79.0672001, 51.515089999],
  [-79.0668501, 51.513799899],
  [-79.06316, 51.513479999],
  [-79.06238, 51.512440099],
  [-79.0615499, 51.512689899],
  [-79.06157, 51.512169999],
  [-79.0563001, 51.510550099],
  [-79.0555499, 51.509000099],
  [-79.05348, 51.509349899],
  [-79.0486, 51.508100099],
  [-79.0486301, 51.507599999],
  [-79.04058, 51.503619999],
  [-79.0386399, 51.501029999],
  [-79.0374001, 51.501269999],
  [-79.0366201, 51.500220099],
  [-79.0353901, 51.500200099],
  [-79.0354099, 51.499689899],
  [-79.0309799, 51.497819999],
  [-79.0314199, 51.497059999],
  [-79.0306, 51.497049999],
  [-79.03023, 51.496029999],
  [-79.02499, 51.493880099],
  [-79.02502, 51.493119899],
  [-79.0233901, 51.492829999],
  [-79.0226101, 51.491919999],
  [-79.02178, 51.492029999],
  [-79.0218401, 51.491009899],
  [-79.0206201, 51.490469999],
  [-79.02145, 51.490229899],
  [-79.01901, 51.489679899],
  [-79.01865, 51.488650099],
  [-79.0178201, 51.488629999],
  [-79.0168401, 51.487589999],
  [-79.0170699, 51.487080099],
  [-79.0158499, 51.486799999],
  [-79.01623, 51.487569899],
  [-79.0154099, 51.487569899],
  [-79.01501, 51.487169999],
  [-79.01545, 51.486540099],
  [-79.0146199, 51.486779899],
  [-79.0150501, 51.486279999],
  [-79.01301, 51.485990099],
  [-79.01346, 51.485229899],
  [-79.0118201, 51.485200099],
  [-79.01265, 51.484699999],
  [-79.0098301, 51.483629999],
  [-79.0110501, 51.483640099],
  [-79.0110799, 51.483129999],
  [-79.01025, 51.483379999],
  [-79.0102801, 51.482860099],
  [-79.00543, 51.480989899],
  [-79.0050699, 51.479959999],
  [-79.00265, 51.478889899],
  [-79.0031, 51.477870099],
  [-79.00189, 51.477599999],
  [-79.00191, 51.477080099],
  [-79.0002701, 51.477059999],
  [-79.0002899, 51.476540099],
  [-78.99667, 51.474939999],
  [-78.99509, 51.473379999],
  [-78.99386, 51.473359999],
  [-78.9934901, 51.472569999],
  [-78.99187, 51.472039999],
  [-78.9914999, 51.471009999],
  [-78.98987, 51.470729999],
  [-78.98832, 51.468640099],
  [-78.9859301, 51.467070099],
  [-78.9793335, 51.460410199],
  [-78.9780153, 51.457391799],
  [-78.98022, 51.452359999],
  [-78.9765801, 51.432569999],
  [-78.97663, 51.431539899],
  [-78.97875, 51.430039999],
  [-78.97796, 51.424639899],
  [-78.97712, 51.424889999],
  [-78.9780301, 51.423109999],
  [-78.97721, 51.423089899],
  [-78.97764, 51.417969899],
  [-78.9770499, 51.417449999],
  [-78.97788, 51.417209999],
  [-78.9764701, 51.416659999],
  [-78.9761, 51.415639899],
  [-78.9768101, 51.413600099],
  [-78.9755801, 51.413579999],
  [-78.9754899, 51.411019999],
  [-78.97432, 51.409720099],
  [-78.97379, 51.407659999],
  [-78.9745999, 51.407920099],
  [-78.97463, 51.407160099],
  [-78.9738101, 51.407139999],
  [-78.97253, 51.399179899],
  [-78.9717099, 51.399160099],
  [-78.9713899, 51.397109999],
  [-78.96977, 51.396580099],
  [-78.9698199, 51.395539999],
  [-78.9685999, 51.395270099],
  [-78.9682599, 51.393719899],
  [-78.9670399, 51.393439999],
  [-78.95588, 51.354289999],
  [-78.9535801, 51.350919899],
  [-78.94729, 51.326979999],
  [-78.94866, 51.319049999],
  [-78.9494699, 51.319059999],
  [-78.95153, 51.304999899],
  [-78.9502301, 51.297549899],
  [-78.9424783, 51.285677099],
  [-78.9338681, 51.280225099],
  [-78.9324976, 51.278311899],
  [-78.9245286, 51.275929199],
  [-78.92206, 51.273979899],
  [-78.9252399, 51.271479999],
  [-78.92549, 51.270449999],
  [-78.9300199, 51.269640099],
  [-78.93287, 51.269819999],
  [-78.93895, 51.266339999],
  [-78.9414346, 51.260558699],
  [-78.9422941, 51.254653899],
  [-78.9502748, 51.249405999],
  [-78.9519022, 51.245366999],
  [-78.9522064, 51.239190099],
  [-78.9511228, 51.227044199],
  [-78.9518416, 51.223261299],
  [-78.9511077, 51.219179199],
  [-78.93809, 51.189680099],
  [-78.9345899, 51.186030099],
  [-78.9329089, 51.185396999],
  [-78.9302599, 51.182369899],
  [-78.9235401, 51.177629999],
  [-78.91671, 51.175460099],
  [-78.90938, 51.175199999],
  [-78.9015664, 51.171256299],
  [-78.8981822, 51.166533399],
  [-78.8887882, 51.165351299],
  [-78.8833273, 51.166716999],
  [-78.8790357, 51.166501799],
  [-78.8738711, 51.165884199],
  [-78.8695201, 51.164099999],
  [-78.8659036, 51.164409399],
  [-78.8571382, 51.157970299],
  [-78.85033, 51.151709999],
  [-78.8504112, 51.148495099],
  [-78.8493966, 51.147158499],
  [-78.8430799, 51.145550099],
  [-78.8312815, 51.145634899],
  [-78.8284901, 51.152579899],
  [-78.83734, 51.154929899],
  [-78.8441886, 51.155436599],
  [-78.8559555, 51.161367299],
  [-78.8588119, 51.163245499],
  [-78.8633287, 51.167853999],
  [-78.8640154, 51.170840899],
  [-78.8613036, 51.175301599],
  [-78.8630712, 51.176390299],
  [-78.8635299, 51.179120099],
  [-78.86556, 51.179409999],
  [-78.86471, 51.180159899],
  [-78.86226, 51.180120099],
  [-78.862157, 51.181056999],
  [-78.86302, 51.181409999],
  [-78.863157, 51.182551099],
  [-78.8623001, 51.183709899],
  [-78.8621273, 51.185507999],
  [-78.8629601, 51.187049899],
  [-78.8660183, 51.189250599],
  [-78.8681901, 51.193040099],
  [-78.8704799, 51.194989599],
  [-78.8789, 51.199649999],
  [-78.9001514, 51.205475899],
  [-78.904555, 51.207400899],
  [-78.91375, 51.213859999],
  [-78.9176432, 51.218152899],
  [-78.9204389, 51.222035599],
  [-78.92305, 51.229399899],
  [-78.9231399, 51.231969899],
  [-78.9224574, 51.234164299],
  [-78.9228901, 51.241959899],
  [-78.9247781, 51.244758599],
  [-78.9251299, 51.250532399],
  [-78.922647, 51.255359599],
  [-78.9189942, 51.259602699],
  [-78.9151104, 51.266675899],
  [-78.9133963, 51.271367999],
  [-78.9115208, 51.272727399],
  [-78.90883, 51.277329899],
  [-78.9080199, 51.277310099],
  [-78.9069999, 51.295239999],
  [-78.90519, 51.298799999],
  [-78.90536, 51.299829999],
  [-78.9045401, 51.299809999],
  [-78.90203, 51.305660099],
  [-78.8984, 51.308929999],
  [-78.88793, 51.314379999],
  [-78.88662, 51.316149999],
  [-78.88539, 51.316129899],
  [-78.88492, 51.317399999],
  [-78.8841099, 51.317389999],
  [-78.88303, 51.318650099],
  [-78.8832199, 51.319169999],
  [-78.8819701, 51.319389999],
  [-78.8793001, 51.323959999],
  [-78.87849, 51.323949999],
  [-78.8784399, 51.324969999],
  [-78.87762, 51.324959999],
  [-78.8779801, 51.326249899],
  [-78.87717, 51.325979999],
  [-78.8775399, 51.326749999],
  [-78.8758799, 51.327230099],
  [-78.87347, 51.335139899],
  [-78.87014, 51.336359899],
  [-78.87095, 51.336629999],
  [-78.8708899, 51.337909899],
  [-78.8700701, 51.337900099],
  [-78.86935, 51.340189999],
  [-78.87068, 51.342269999],
  [-78.87348, 51.343849999],
  [-78.8738, 51.345649999],
  [-78.8731639, 51.346287299],
  [-78.8835223, 51.360810699],
  [-78.8838, 51.364289999],
  [-78.8863101, 51.367409999],
  [-78.8871199, 51.367419999],
  [-78.8868899, 51.368189999],
  [-78.8878999, 51.368460099],
  [-78.8894101, 51.371049899],
  [-78.89258, 51.373429999],
  [-78.8942101, 51.373700099],
  [-78.89714, 51.376829899],
  [-78.8987501, 51.381729899],
  [-78.89793, 51.381709899],
  [-78.8978801, 51.382989999],
  [-78.89706, 51.382979999],
  [-78.8969999, 51.384260099],
  [-78.89618, 51.384250099],
  [-78.8939801, 51.390439999],
  [-78.8942299, 51.391129999],
  [-78.8934, 51.391119999],
  [-78.89248, 51.393409999],
  [-78.89125, 51.393380099],
  [-78.88946, 51.396679999],
  [-78.8881901, 51.397689999],
  [-78.8844201, 51.399409899],
  [-78.8835499, 51.400419999],
  [-78.8796301, 51.401119999],
  [-78.8798, 51.401889999],
  [-78.8789801, 51.401879999],
  [-78.87893, 51.402899999],
  [-78.88017, 51.402669999],
  [-78.87929, 51.404189899],
  [-78.87679, 51.404909999],
  [-78.8763199, 51.406189899],
  [-78.8746699, 51.406409899],
  [-78.87379, 51.407679999],
  [-78.8713199, 51.407889999],
  [-78.87128, 51.408909999],
  [-78.8647, 51.409309999],
  [-78.86383, 51.410319999],
  [-78.86179, 51.410279899],
  [-78.8617599, 51.410789999],
  [-78.8584199, 51.412270099],
  [-78.85622, 51.415299899],
  [-78.8554101, 51.415289899],
  [-78.8524101, 51.418050099],
  [-78.84824, 51.419509899],
  [-78.8481899, 51.420539999],
  [-78.8448501, 51.421769999],
  [-78.84271, 51.423769999],
  [-78.83979, 51.424739899],
  [-78.8397301, 51.426019999],
  [-78.83843, 51.427539999],
  [-78.83877, 51.429079899],
  [-78.8375299, 51.429309899],
  [-78.8336201, 51.433840099],
  [-78.8290799, 51.434539899],
  [-78.83068, 51.435339999],
  [-78.8281999, 51.435799999],
  [-78.8277499, 51.436569999],
  [-78.82355, 51.438789999],
  [-78.82259, 51.441599999],
  [-78.82001, 51.444109999],
  [-78.81835, 51.444589999],
  [-78.81788, 51.445869899],
  [-78.8147399, 51.447089899],
  [-78.8140964, 51.446402399],
  [-78.8118791, 51.447550499],
  [-78.8067279, 51.451566499],
  [-78.7957526, 51.458289599],
  [-78.7850996, 51.467538599],
  [-78.7825612, 51.471248799],
  [-78.7762096, 51.475931299],
  [-78.765569, 51.481714599],
  [-78.7648226, 51.484494899],
  [-78.7744067, 51.493079099],
  [-78.79496, 51.495929999],
  [-78.7949301, 51.496440099],
  [-78.79657, 51.496469999],
  [-78.79655, 51.496980099],
  [-78.8074901, 51.499749999],
  [-78.80747, 51.500519999],
  [-78.80911, 51.500420099],
  [-78.8107399, 51.500839999],
  [-78.8107101, 51.501349899],
  [-78.8143801, 51.501929999],
  [-78.81435, 51.502440099],
  [-78.81598, 51.502729999],
  [-78.8159499, 51.503499999],
  [-78.81777, 51.504039999],
  [-78.8175099, 51.505069999],
  [-78.8191501, 51.505220099],
  [-78.82114, 51.506540099],
  [-78.82481, 51.506990099],
  [-78.82769, 51.511409999],
  [-78.82888, 51.512199999],
  [-78.82721, 51.517039999],
  [-78.8259771, 51.517015899],
  [-78.8259599, 51.517519999],
  [-78.82387, 51.518249899],
  [-78.8234099, 51.519269999],
  [-78.82259, 51.519259999],
  [-78.8229501, 51.520289999],
  [-78.8217201, 51.520269999],
  [-78.8199699, 51.522539999],
  [-78.8191501, 51.522529999],
  [-78.8191, 51.523550099],
  [-78.8182801, 51.523529999],
  [-78.81821, 51.524809899],
  [-78.8173901, 51.524799899],
  [-78.8179699, 51.525589899],
  [-78.8163999, 51.528369899],
  [-78.81723, 51.528129899],
  [-78.8169699, 51.537859999],
  [-78.8182999, 51.540189999],
  [-78.8191501, 51.539709899],
  [-78.8188599, 51.540279999],
  [-78.82035, 51.544589899],
  [-78.8203801, 51.548179999],
  [-78.8213999, 51.548450099],
  [-78.8246399, 51.556629999],
  [-78.82522, 51.558769999],
  [-78.8244099, 51.558499899],
  [-78.82514, 51.560570099],
  [-78.82635, 51.561099999],
  [-78.82592, 51.565709899],
  [-78.8277301, 51.566509999],
  [-78.82743, 51.567109999],
  [-78.8293901, 51.570639999],
  [-78.8264099, 51.576999999],
  [-78.8247499, 51.577219999],
  [-78.8229599, 51.580260099],
  [-78.8217201, 51.580499899],
  [-78.8208201, 51.582019999],
  [-78.81501, 51.583189999],
  [-78.8130699, 51.584949899],
  [-78.8131799, 51.591359999],
  [-78.8081074, 51.599253799],
  [-78.8048727, 51.600411699],
  [-78.8048887, 51.602372599],
  [-78.8199301, 51.600579999],
  [-78.82288, 51.599229999],
  [-78.8310601, 51.600270099],
  [-78.8389699, 51.598370099],
  [-78.8432455, 51.599366399],
  [-78.8444814, 51.601349599],
  [-78.8454099, 51.601449999],
  [-78.8458401, 51.605289899],
  [-78.8468, 51.606849899],
  [-78.8459799, 51.606839899],
  [-78.84679, 51.607099999],
  [-78.84668, 51.609409899],
  [-78.8475001, 51.609419899],
  [-78.84788, 51.610199899],
  [-78.8483199, 51.609439999],
  [-78.8478401, 51.610969899],
  [-78.8498599, 51.611519899],
  [-78.8492099, 51.612529899],
  [-78.85022, 51.612809999],
  [-78.85037, 51.614089899],
  [-78.85138, 51.614359999],
  [-78.85094, 51.615119999],
  [-78.85393, 51.617229999],
  [-78.8540799, 51.618259999],
  [-78.8549, 51.618270099],
  [-78.8546599, 51.619039999],
  [-78.85645, 51.620349999],
  [-78.85769, 51.620380099],
  [-78.85746, 51.620879999],
  [-78.85967, 51.621950099],
  [-78.86083, 51.623510099],
  [-78.86247, 51.623789999],
  [-78.86202, 51.624549999],
  [-78.86304, 51.624830099],
  [-78.86281, 51.625339999],
  [-78.86405, 51.625099999],
  [-78.8639999, 51.626379999],
  [-78.8656301, 51.626669999],
  [-78.86637, 51.628479999],
  [-78.8684001, 51.629029999],
  [-78.8687599, 51.630060099],
  [-78.87082, 51.630099899],
  [-78.8716, 51.631129999],
  [-78.8690999, 51.631599999],
  [-78.8744299, 51.632209899],
  [-78.8744001, 51.632720099],
  [-78.8756499, 51.632740099],
  [-78.87561, 51.633259999],
  [-78.8784399, 51.634459999],
  [-78.88202, 51.637219999],
  [-78.88238, 51.638249999],
  [-78.88404, 51.638019999],
  [-78.8876599, 51.639889999],
  [-78.8876401, 51.640390099],
  [-78.88928, 51.640419899],
  [-78.8896699, 51.641070099],
  [-78.8945499, 51.642439899],
  [-78.89493, 51.643080099],
  [-78.8982001, 51.643659899],
  [-78.89817, 51.644170099],
  [-78.9075799, 51.645750099],
  [-78.9285699, 51.645989899],
  [-78.92852, 51.647269999],
  [-78.9329199, 51.650169999],
  [-78.9328901, 51.650679999],
  [-78.9316601, 51.650649899],
  [-78.93305, 51.651709999],
  [-78.93321, 51.652740099],
  [-78.9350299, 51.653539899],
  [-78.9347801, 51.654559899],
  [-78.9360101, 51.654709999],
  [-78.93718, 51.656139999],
  [-78.94043, 51.656970099],
  [-78.94021, 51.657479999],
  [-78.9420299, 51.658279999],
  [-78.94177, 51.659300099],
  [-78.94484, 51.659860099],
  [-78.9451301, 51.662429899],
  [-78.94431, 51.662420099],
  [-78.94363, 51.663689999],
  [-78.9442401, 51.663949999],
  [-78.94416, 51.665750099],
  [-78.9429199, 51.665980099],
  [-78.94027, 51.669779899],
  [-78.94109, 51.669789899],
  [-78.94064, 51.670549899],
  [-78.93941, 51.670279999],
  [-78.93886, 51.673599999],
  [-78.9380299, 51.673579999],
  [-78.93842, 51.674109899],
  [-78.93761, 51.673829999],
  [-78.9366701, 51.676379999],
  [-78.93749, 51.676389999],
  [-78.9370001, 51.678169999],
  [-78.9382401, 51.678200099],
  [-78.9375799, 51.678959999],
  [-78.93698, 51.678689999],
  [-78.9369199, 51.679970099],
  [-78.9385899, 51.679479999],
  [-78.9373, 51.680750099],
  [-78.9381201, 51.680760099],
  [-78.93806, 51.682039999],
  [-78.93683, 51.682019899],
  [-78.9378442, 51.683396799],
  [-78.93786, 51.685863199],
  [-78.9421301, 51.692369999],
  [-78.94653, 51.695259999],
  [-78.95022, 51.695709999],
  [-78.9505999, 51.696609999],
  [-78.95264, 51.697029899],
  [-78.9572676, 51.697117199],
  [-78.9665535, 51.694231799],
  [-78.967989, 51.694884499],
  [-78.96755, 51.695629999],
  [-78.9679201, 51.696660099],
  [-78.9671, 51.696389999],
  [-78.96688, 51.696899899],
  [-78.96789, 51.697169999],
  [-78.96764, 51.698189999],
  [-78.97022, 51.700409999],
  [-78.9853999, 51.702340099],
  [-78.98542, 51.701829999],
  [-78.9919499, 51.703479999],
  [-78.9917199, 51.703990099],
  [-78.99388, 51.706330099],
  [-78.9922, 51.707069999],
  [-78.9921401, 51.708349899],
  [-78.99108, 51.709100099],
  [-78.99189, 51.713990099],
  [-78.9927399, 51.713489999],
  [-78.99531, 51.716099999],
  [-78.99502, 51.717880099],
  [-78.99356, 51.718359899],
  [-78.9937199, 51.719139899],
  [-78.9924901, 51.719120099],
  [-78.9921699, 51.721680099],
  [-78.9931401, 51.722979999],
  [-78.9966199, 51.723809899],
  [-78.9967001, 51.726629999],
  [-78.99587, 51.726609999],
  [-78.9963999, 51.733289999],
  [-78.99723, 51.733299999],
  [-78.9965901, 51.733809899],
  [-78.9998499, 51.739369899],
  [-79.0022899, 51.740309999],
  [-79.0022601, 51.740819899],
  [-79.00433, 51.740849999],
  [-79.0042999, 51.741360099],
  [-79.00921, 51.742340099],
  [-79.00958, 51.743499899],
  [-79.01122, 51.743790099],
  [-79.0111899, 51.744299999],
  [-79.01821, 51.744289999],
  [-79.0186001, 51.744939899],
  [-79.0227599, 51.744240099],
  [-79.0227301, 51.744749999],
  [-79.0285001, 51.744979999],
  [-79.03059, 51.744369899],
  [-79.03056, 51.744879999],
  [-79.0297499, 51.744859999],
  [-79.03011, 51.745900099],
  [-79.0317499, 51.746169899],
  [-79.03278, 51.751069999],
  [-79.0336101, 51.751089999],
  [-79.0335199, 51.757749999],
  [-79.03859, 51.760020099],
  [-79.04066, 51.759789999],
  [-79.04125, 51.760439999],
  [-79.0409899, 51.761719899],
  [-79.04405, 51.762539999],
  [-79.0438, 51.763559999],
  [-79.0434199, 51.762769999],
  [-79.0417699, 51.762749999],
  [-79.04214, 51.763789999],
  [-79.03677, 51.763700099],
  [-79.0363199, 51.764710099],
  [-79.0320899, 51.766949899],
  [-79.03077, 51.768719899],
  [-79.02699, 51.770209999],
  [-79.0265299, 51.771209999],
  [-79.0257101, 51.771199999],
  [-79.02567, 51.771969999],
  [-79.0231601, 51.772690099],
  [-79.02313, 51.773459999],
  [-79.02232, 51.773189999],
  [-79.0222899, 51.773690099],
  [-79.0185199, 51.774920099],
  [-79.0185001, 51.775449999],
  [-79.01558, 51.776150099],
  [-79.01511, 51.777429999],
  [-79.0142801, 51.777409899],
  [-79.0117101, 51.779419999],
  [-79.0104801, 51.779399899],
  [-79.01002, 51.780419999],
  [-79.0091899, 51.780389899],
  [-79.0070501, 51.782160099],
  [-79.0053701, 51.782649999],
  [-79.00535, 51.783169899],
  [-79.0032899, 51.783119999],
  [-79.0028101, 51.784649999],
  [-79.0011501, 51.784610099],
  [-79.00024, 51.786649999],
  [-78.99688, 51.787879999],
  [-78.99643, 51.788639999],
  [-78.9922501, 51.789849899],
  [-78.9930599, 51.790119999],
  [-78.99199, 51.790869999],
  [-78.99097, 51.790600099],
  [-78.98968, 51.791859899],
  [-78.9854701, 51.793579999],
  [-78.98544, 51.794089999],
  [-78.98379, 51.794069899],
  [-78.9829201, 51.795079899],
  [-78.9812799, 51.794789999],
  [-78.98167, 51.795309899],
  [-78.9795801, 51.795789999],
  [-78.97912, 51.796809999],
  [-78.9770499, 51.797029999],
  [-78.97744, 51.797419899],
  [-78.97701, 51.797789999],
  [-78.97577, 51.797769999],
  [-78.97574, 51.798539999],
  [-78.9749201, 51.798270099],
  [-78.9744801, 51.799040099],
  [-78.96951, 51.799199899],
  [-78.96877, 51.797150099],
  [-78.9576901, 51.795409899],
  [-78.9576701, 51.795919999],
  [-78.9490499, 51.794490099],
  [-78.94944, 51.795009999],
  [-78.94821, 51.794989999],
  [-78.9482401, 51.794219999],
  [-78.94621, 51.793419899],
  [-78.94665, 51.792909999],
  [-78.94095, 51.791029999],
  [-78.94097, 51.790500099],
  [-78.94053, 51.791009999],
  [-78.9268099, 51.792820099],
  [-78.9272, 51.793339999],
  [-78.92639, 51.793069899],
  [-78.92549, 51.794589999],
  [-78.9171101, 51.796999999],
  [-78.9166601, 51.797769999],
  [-78.91205, 51.799349999],
  [-78.9028999, 51.800349999],
  [-78.9024401, 51.801369999],
  [-78.89995, 51.801569999],
  [-78.8995, 51.802329999],
  [-78.8978601, 51.802040099],
  [-78.89784, 51.802559999],
  [-78.8915499, 51.804490099],
  [-78.8911001, 51.805249999],
  [-78.8898601, 51.805229999],
  [-78.88984, 51.805739899],
  [-78.8877401, 51.806479999],
  [-78.8877, 51.807239999],
  [-78.88646, 51.807219999],
  [-78.88725, 51.807999999],
  [-78.8855599, 51.808869899],
  [-78.88138, 51.809689999],
  [-78.8783101, 51.813479999],
  [-78.8716699, 51.813999999],
  [-78.86872, 51.815099999],
  [-78.8669799, 51.817119999],
  [-78.8649, 51.817339999],
  [-78.8631099, 51.820129999],
  [-78.8568501, 51.821039999],
  [-78.8554299, 51.825109999],
  [-78.85334, 51.825589999],
  [-78.85023, 51.830139999],
  [-78.84814, 51.830620099],
  [-78.8453199, 51.833389999],
  [-78.84502, 51.835169999],
  [-78.84211, 51.835630099],
  [-78.8418501, 51.836649899],
  [-78.84282, 51.837950099],
  [-78.84159, 51.837669999],
  [-78.8409501, 51.838169999],
  [-78.84111, 51.839199899],
  [-78.8402801, 51.839190099],
  [-78.8398301, 51.840199899],
  [-78.8410501, 51.840479999],
  [-78.84022, 51.840469999],
  [-78.84103, 51.840740099],
  [-78.84012, 51.842520099],
  [-78.8388699, 51.842750099],
  [-78.8388401, 51.843259999],
  [-78.8371899, 51.843229999],
  [-78.83758, 51.843750099],
  [-78.83504, 51.844989899],
  [-78.8393099, 51.846599999],
  [-78.8389799, 51.849159999],
  [-78.8377201, 51.849389999],
  [-78.83767, 51.854769899],
  [-78.83988, 51.856099899],
  [-78.8398499, 51.856860099],
  [-78.83777, 51.857080099],
  [-78.83878, 51.857609999],
  [-78.8389599, 51.858129999],
  [-78.83813, 51.858109899],
  [-78.8385199, 51.858630099],
  [-78.83688, 51.858349999],
  [-78.836, 51.859359999],
  [-78.83434, 51.859579999],
  [-78.8347301, 51.859970099],
  [-78.8338599, 51.861109899],
  [-78.8351, 51.860879899],
  [-78.83504, 51.862159999],
  [-78.83421, 51.862139999],
  [-78.8339699, 51.862909999],
  [-78.83702, 51.863990099],
  [-78.8369599, 51.865269999],
  [-78.8419, 51.865860099],
  [-78.8426499, 51.867549899],
  [-78.8455399, 51.867729999],
  [-78.84724, 51.866750099],
  [-78.8471899, 51.867770099],
  [-78.8488699, 51.867279999],
  [-78.84926, 51.867799999],
  [-78.85055, 51.866799999],
  [-78.85803, 51.866169999],
  [-78.8575299, 51.867949999],
  [-78.85214, 51.868109899],
  [-78.8516599, 51.869640099],
  [-78.85247, 51.869909999],
  [-78.85082, 51.869880099],
  [-78.84993, 51.871139999],
  [-78.84869, 51.871119899],
  [-78.8486, 51.872909899],
  [-78.84693, 51.873139999],
  [-78.84769, 51.874689899],
  [-78.8501701, 51.874739999],
  [-78.85012, 51.875760099],
  [-78.84716, 51.876990099],
  [-78.8473099, 51.878269999],
  [-78.8487301, 51.878939999],
  [-78.8512099, 51.878980099],
  [-78.85294, 51.877479999],
  [-78.8590701, 51.879269999],
  [-78.8596499, 51.880039999],
  [-78.85911, 51.882589999],
  [-78.86591, 51.887839999],
  [-78.8648601, 51.892179999],
  [-78.86648, 51.893230099],
  [-78.86716, 51.896320099],
  [-78.8697501, 51.898419999],
  [-78.87058, 51.898430099],
  [-78.87124, 51.902029899],
  [-78.87494, 51.902609999],
  [-78.87492, 51.903120099],
  [-78.87783, 51.902790099],
  [-78.8794499, 51.903589899],
  [-78.8856501, 51.903959999],
  [-78.89563, 51.903239899],
  [-78.89491, 51.905279999],
  [-78.89926, 51.905349899],
  [-78.8992101, 51.906379999],
  [-78.8979701, 51.906359899],
  [-78.89662, 51.908639999],
  [-78.89538, 51.908619999],
  [-78.8934499, 51.909859999],
  [-78.89362, 51.910629999],
  [-78.8928, 51.910619999],
  [-78.89191, 51.911890099],
  [-78.8889899, 51.912350099],
  [-78.8880901, 51.913869999],
  [-78.8864101, 51.914359899],
  [-78.88596, 51.915110099],
  [-78.8838999, 51.914819899],
  [-78.8834499, 51.915579899],
  [-78.88262, 51.915560099],
  [-78.8826401, 51.915049999],
  [-78.8809701, 51.915279999],
  [-78.8809999, 51.914759999],
  [-78.8756499, 51.914159999],
  [-78.87348, 51.915910099],
  [-78.87182, 51.916139899],
  [-78.8713299, 51.917670099],
  [-78.86758, 51.918110099],
  [-78.86848, 51.920949999],
  [-78.8682199, 51.921969999],
  [-78.87436, 51.923619999],
  [-78.8746599, 51.926179999],
  [-78.8755201, 51.925429999],
  [-78.8784499, 51.924969999],
  [-78.87706, 51.928020099],
  [-78.87284, 51.929479899],
  [-78.8715201, 51.931240099],
  [-78.8686201, 51.931199999],
  [-78.8655499, 51.934729999],
  [-78.8638699, 51.935209999],
  [-78.86383, 51.935979999],
  [-78.86301, 51.935709899],
  [-78.8629899, 51.936219999],
  [-78.86047, 51.936949999],
  [-78.8608401, 51.937719899],
  [-78.86001, 51.937709899],
  [-78.85913, 51.938969999],
  [-78.8524001, 51.940769999],
  [-78.84826, 51.940570099],
  [-78.84788, 51.940049899],
  [-78.8474001, 51.941319999],
  [-78.84615, 51.941549999],
  [-78.84612, 51.942069999],
  [-78.8449, 51.941529999],
  [-78.84488, 51.942039899],
  [-78.83902, 51.943219999],
  [-78.8389799, 51.943989999],
  [-78.83523, 51.944429999],
  [-78.83478, 51.945189999],
  [-78.82979, 51.945480099],
  [-78.82857, 51.944819899],
  [-78.82724, 51.946590099],
  [-78.8206101, 51.946719899],
  [-78.8206299, 51.946209999],
  [-78.81855, 51.946169899],
  [-78.8167201, 51.949729899],
  [-78.80913, 51.952279899],
  [-78.80287, 51.953059899],
  [-78.8029, 51.952539999],
  [-78.7991799, 51.952219999],
  [-78.7988201, 51.951199999],
  [-78.7971799, 51.950639999],
  [-78.79713, 51.951669999],
  [-78.793, 51.951459999],
  [-78.7888199, 51.952020099],
  [-78.78878, 51.952789999],
  [-78.78754, 51.952509899],
  [-78.7866199, 51.954549999],
  [-78.78387, 51.955779999],
  [-78.78442, 51.957199999],
  [-78.78708, 51.957899999],
  [-78.7870599, 51.962499899],
  [-78.78874, 51.962019999],
  [-78.7891301, 51.962669999],
  [-78.7944999, 51.963150099],
  [-78.7948101, 51.965209999],
  [-78.7927001, 51.965939899],
  [-78.79309, 51.966459999],
  [-78.7905999, 51.966409899],
  [-78.7901401, 51.967429999],
  [-78.7884701, 51.967639999],
  [-78.78844, 51.968160099],
  [-78.78343, 51.968839899],
  [-78.7834, 51.969349999],
  [-78.7804999, 51.969299899],
  [-78.77944, 51.965689999],
  [-78.7802599, 51.965700099],
  [-78.7779001, 51.963099999],
  [-78.77543, 51.962789999],
  [-78.7754701, 51.962279899],
  [-78.7721401, 51.962219999],
  [-78.77176, 51.961439999],
  [-78.7631, 51.960380099],
  [-78.7622499, 51.960879999],
  [-78.7547999, 51.960609899],
  [-78.7537901, 51.964179899],
  [-78.7512401, 51.965279899],
  [-78.74797, 51.964449999],
  [-78.7405, 51.968529999],
  [-78.7414799, 51.969710099],
  [-78.7431201, 51.970129999],
  [-78.7430299, 51.971920099],
  [-78.73805, 51.971820099],
  [-78.7381001, 51.970799999],
  [-78.73687, 51.970519899],
  [-78.7381201, 51.970539999],
  [-78.7381399, 51.970030099],
  [-78.73395, 51.970839899],
  [-78.72886, 51.972799999],
  [-78.7276501, 51.972389899],
  [-78.7181001, 51.980659999],
  [-78.71727, 51.980639899],
  [-78.7133201, 51.984930099],
  [-78.70991, 51.986649999],
  [-78.70984, 51.987930099],
  [-78.7090099, 51.987919999],
  [-78.7097601, 51.989469999],
  [-78.71059, 51.989479999],
  [-78.7101299, 51.990500099],
  [-78.71471, 51.990079899],
  [-78.7146699, 51.990849899],
  [-78.71049, 51.991519899],
  [-78.7113399, 51.991040099],
  [-78.70927, 51.990999999],
  [-78.70883, 51.991500099],
  [-78.7096601, 51.991519899],
  [-78.70803, 51.993699999],
  [-78.7088701, 51.994830099],
  [-78.7082101, 51.995589999],
  [-78.7096033, 51.996091899],
  [-78.7092627, 51.996690299],
  [-78.7123101, 51.996569999],
  [-78.71347, 51.998259999],
  [-78.7139099, 51.997749899],
  [-78.71514, 51.998029999],
  [-78.7142299, 51.999549999],
  [-78.7134, 51.999529899],
  [-78.71338, 52.000050099],
  [-78.71462, 52.000070099],
  [-78.71449, 52.002629899],
  [-78.7136699, 52.002359999],
  [-78.7140099, 52.003649899],
  [-78.71773, 52.004229999],
  [-78.7177, 52.004740099],
  [-78.71941, 52.004009999],
  [-78.7197799, 52.004659999],
  [-78.72353, 52.004599999],
  [-78.7238701, 52.005889999],
  [-78.7222001, 52.006109999],
  [-78.72214, 52.007390099],
  [-78.7196, 52.008369999],
  [-78.71914, 52.009129999],
  [-78.7183301, 52.008859899],
  [-78.7180199, 52.009449999],
  [-78.7186501, 52.010659999],
  [-78.71782, 52.010639899],
  [-78.7175699, 52.011410099],
  [-78.7235799, 52.011649899],
  [-78.72582, 52.012589999],
  [-78.72595, 52.013999999],
  [-78.7272101, 52.013899999],
  [-78.7264499, 52.015329999],
  [-78.7283499, 52.015969899],
  [-78.7258801, 52.015400099],
  [-78.7254301, 52.016170099],
  [-78.7246, 52.016149999],
  [-78.72463, 52.015639899],
  [-78.7225501, 52.015599999],
  [-78.7223101, 52.016369999],
  [-78.7233399, 52.016639899],
  [-78.72364, 52.018699999],
  [-78.7211199, 52.019290099],
  [-78.7128899, 52.017719999],
  [-78.7125501, 52.016180099],
  [-78.7109099, 52.015889999],
  [-78.71126, 52.017180099],
  [-78.7104301, 52.017159999],
  [-78.7104, 52.017669999],
  [-78.7095699, 52.017659899],
  [-78.70919, 52.016879899],
  [-78.7104399, 52.016909999],
  [-78.71048, 52.016129999],
  [-78.70502, 52.017309899],
  [-78.7043499, 52.014219999],
  [-78.7076501, 52.014539899],
  [-78.6982101, 52.012309899],
  [-78.69569, 52.008929999],
  [-78.6943001, 52.003519899],
  [-78.69292, 52.002209999],
  [-78.69127, 52.001919999],
  [-78.6913101, 52.001409899],
  [-78.6834299, 52.000739899],
  [-78.68347, 52.000229999],
  [-78.68182, 52.000070099],
  [-78.67857, 51.998600099],
  [-78.6758, 51.995979899],
  [-78.6749701, 51.995959899],
  [-78.6749999, 51.995449999],
  [-78.6717599, 51.993849899],
  [-78.67178, 51.993339999],
  [-78.6697401, 51.992779999],
  [-78.6697699, 51.992270099],
  [-78.6518, 51.986529899],
  [-78.6518301, 51.986019999],
  [-78.6496101, 51.984699999],
  [-78.64967, 51.983669999],
  [-78.64868, 51.982629899],
  [-78.6429401, 51.981229999],
  [-78.6425001, 51.981729899],
  [-78.6471915, 51.982423399],
  [-78.6485199, 51.983050099],
  [-78.6485676, 51.983854299],
  [-78.6428197, 51.982590399],
  [-78.6419798, 51.982830399],
  [-78.6450307, 51.983134199],
  [-78.64859, 51.984419899],
  [-78.6517599, 51.987309899],
  [-78.6570601, 51.989189899],
  [-78.65703, 51.989710099],
  [-78.64381, 51.988419899],
  [-78.6271241, 51.983440399],
  [-78.6343801, 51.986179899],
  [-78.63436, 51.986699999],
  [-78.64365, 51.988849899],
  [-78.66233, 51.991610099],
  [-78.6623001, 51.992119999],
  [-78.66594, 51.993989999],
  [-78.6745, 51.996979899],
  [-78.67446, 51.997749899],
  [-78.67813, 51.999089899],
  [-78.6797, 52.000929999],
  [-78.68134, 52.001219999],
  [-78.6813199, 52.001730099],
  [-78.68338, 52.002019999],
  [-78.68335, 52.002539899],
  [-78.69038, 52.003060099],
  [-78.6923101, 52.006039999],
  [-78.69148, 52.006029999],
  [-78.69084, 52.010369999],
  [-78.6883299, 52.010579999],
  [-78.6885, 52.011349999],
  [-78.6905201, 52.012419899],
  [-78.6944, 52.017869899],
  [-78.6941199, 52.019149999],
  [-78.69483, 52.018819999],
  [-78.69551, 52.016359999],
  [-78.69468, 52.016339999],
  [-78.69471, 52.015829999],
  [-78.6955401, 52.015850099],
  [-78.6947601, 52.014809999],
  [-78.6976699, 52.014859899],
  [-78.6976401, 52.015369999],
  [-78.7009999, 52.014669999],
  [-78.69927, 52.015919999],
  [-78.7001001, 52.015939999],
  [-78.70003, 52.017219999],
  [-78.7008799, 52.016979899],
  [-78.7008601, 52.017489999],
  [-78.69958, 52.017979899],
  [-78.69961, 52.017459999],
  [-78.69917, 52.017970099],
  [-78.6971199, 52.017410099],
  [-78.6966699, 52.018180099],
  [-78.6937, 52.019400099],
  [-78.6936699, 52.019909999],
  [-78.69451, 52.019669999],
  [-78.69446, 52.020689999],
  [-78.6965, 52.021369999],
  [-78.6978799, 52.022809999],
  [-78.6968701, 52.026379999],
  [-78.6974399, 52.027420099],
  [-78.69827, 52.027429899],
  [-78.6979899, 52.028709999],
  [-78.6951099, 52.032239999],
  [-78.69594, 52.032259999],
  [-78.69506, 52.033259999],
  [-78.6963101, 52.033159999],
  [-78.69728, 52.034589999],
  [-78.7011099, 52.036839999],
  [-78.70641, 52.038740099],
  [-78.70973, 52.038929999],
  [-78.7101001, 52.039709999],
  [-78.7051199, 52.039609999],
  [-78.7038601, 52.043939999],
  [-78.70607, 52.045410099],
  [-78.70814, 52.045569999],
  [-78.7080901, 52.046589999],
  [-78.7097701, 52.046239999],
  [-78.7126601, 52.046679899],
  [-78.71263, 52.047190099],
  [-78.70804, 52.047359999],
  [-78.70802, 52.047870099],
  [-78.70514, 52.047559899],
  [-78.7046501, 52.048829999],
  [-78.6988601, 52.048200099],
  [-78.69936, 52.046679899],
  [-78.69772, 52.046139999],
  [-78.6977, 52.046640099],
  [-78.6952199, 52.046339899],
  [-78.6951901, 52.046849999],
  [-78.6898, 52.046619999],
  [-78.6881901, 52.045559899],
  [-78.6865399, 52.045410099],
  [-78.68561, 52.047179999],
  [-78.68305, 52.048530099],
  [-78.67682, 52.048410099],
  [-78.6708601, 52.051109899],
  [-78.6691999, 52.051090099],
  [-78.6667699, 52.049879899],
  [-78.6667, 52.051159999],
  [-78.6658799, 52.050889899],
  [-78.6649601, 52.052659899],
  [-78.66035, 52.053339899],
  [-78.66002, 52.055640099],
  [-78.6567599, 52.058389999],
  [-78.6520899, 52.060219899],
  [-78.64959, 52.060299999],
  [-78.65071, 52.062760099],
  [-78.6515399, 52.062899899],
  [-78.65178, 52.066239899],
  [-78.6467201, 52.067420099],
  [-78.64601, 52.068939999],
  [-78.64745, 52.069489999],
  [-78.64732, 52.071789899],
  [-78.6481801, 52.071289999],
  [-78.64855, 52.072330099],
  [-78.6506101, 52.072619999],
  [-78.65058, 52.073139899],
  [-78.65222, 52.073679899],
  [-78.65212, 52.075469899],
  [-78.6529501, 52.075489999],
  [-78.65293, 52.076000099],
  [-78.66037, 52.076919899],
  [-78.657, 52.077619999],
  [-78.65656, 52.078119899],
  [-78.65738, 52.078399999],
  [-78.6497599, 52.080809899],
  [-78.6495299, 52.081320099],
  [-78.6507, 52.082880099],
  [-78.65003, 52.083629999],
  [-78.638, 52.082749999],
  [-78.63432, 52.081780099],
  [-78.63436, 52.081009899],
  [-78.63232, 52.080069999],
  [-78.6272999, 52.080869999],
  [-78.6272701, 52.081379999],
  [-78.6251799, 52.081599999],
  [-78.6251501, 52.082110099],
  [-78.62223, 52.082309999],
  [-78.62366, 52.086689899],
  [-78.6244901, 52.086709999],
  [-78.62602, 52.089169999],
  [-78.6297201, 52.090149899],
  [-78.62998, 52.092969999],
  [-78.6291501, 52.092949999],
  [-78.62698, 52.094699899],
  [-78.6197399, 52.097890099],
  [-78.61822, 52.095039899],
  [-78.6157199, 52.095249899],
  [-78.6157399, 52.094729999],
  [-78.6077299, 52.096880099],
  [-78.6076901, 52.097389999],
  [-78.6043601, 52.097579899],
  [-78.6048001, 52.096819899],
  [-78.6031799, 52.096269999],
  [-78.60312, 52.097299999],
  [-78.5976099, 52.099110099],
  [-78.59298, 52.100169999],
  [-78.5895876, 52.099612499],
  [-78.58877, 52.101099999],
  [-78.5851392, 52.100536199],
  [-78.5841401, 52.102049999],
  [-78.58331, 52.102019899],
  [-78.5832699, 52.102790099],
  [-78.5780185, 52.103274999],
  [-78.5787784, 52.104045599],
  [-78.5823501, 52.104309999],
  [-78.5813646, 52.106190699],
  [-78.5799812, 52.107119799],
  [-78.5799401, 52.112169399],
  [-78.5755099, 52.115524799],
  [-78.5750233, 52.116506399],
  [-78.5763815, 52.118249899],
  [-78.5790101, 52.119869999],
  [-78.57618, 52.122120099],
  [-78.57498, 52.125169899],
  [-78.57244, 52.125889999],
  [-78.57233, 52.127929899],
  [-78.57066, 52.127900099],
  [-78.57062, 52.128669999],
  [-78.5698001, 52.128389899],
  [-78.5701599, 52.129429999],
  [-78.56933, 52.129409999],
  [-78.5694899, 52.130429999],
  [-78.5684, 52.131189999],
  [-78.5692301, 52.131199999],
  [-78.5719101, 52.135609899],
  [-78.57397, 52.136039899],
  [-78.57495, 52.136959999],
  [-78.5730299, 52.137939899],
  [-78.5721301, 52.139209999],
  [-78.5713, 52.139189999],
  [-78.57083, 52.140209999],
  [-78.5695601, 52.140439999],
  [-78.5697099, 52.141459999],
  [-78.56818, 52.142969999],
  [-78.56475, 52.144690099],
  [-78.5642201, 52.146729899],
  [-78.56338, 52.146969999],
  [-78.56386, 52.145700099],
  [-78.5634501, 52.145690099],
  [-78.5625, 52.147719899],
  [-78.5612401, 52.147949899],
  [-78.56118, 52.148979999],
  [-78.55862, 52.150199999],
  [-78.55595, 52.153229999],
  [-78.55298, 52.154439999],
  [-78.5522301, 52.155370099],
  [-78.5533301, 52.155490099],
  [-78.55421, 52.154600099],
  [-78.55585, 52.155019999],
  [-78.55716, 52.154019999],
  [-78.5570399, 52.156069899],
  [-78.55621, 52.156050099],
  [-78.5556899, 52.158089999],
  [-78.55485, 52.158069899],
  [-78.5548099, 52.158839899],
  [-78.5531101, 52.159319999],
  [-78.5523301, 52.162380099],
  [-78.5512, 52.163889999],
  [-78.54951, 52.164370099],
  [-78.54998, 52.163349999],
  [-78.54915, 52.163349999],
  [-78.5492301, 52.161799999],
  [-78.5488099, 52.161789999],
  [-78.54783, 52.164590099],
  [-78.5469901, 52.164569999],
  [-78.5446, 52.174009999],
  [-78.5426, 52.176270099],
  [-78.54342, 52.176539999],
  [-78.5425799, 52.176529899],
  [-78.5410001, 52.178799999],
  [-78.5410001, 52.182639899],
  [-78.5434, 52.184490099],
  [-78.5452399, 52.185050099],
  [-78.54539, 52.186069899],
  [-78.55209, 52.185439999],
  [-78.5520299, 52.186720099],
  [-78.54906, 52.187679999],
  [-78.5490299, 52.188189899],
  [-78.54531, 52.187600099],
  [-78.5456651, 52.188207899],
  [-78.5448099, 52.188869899],
  [-78.54184, 52.190089899],
  [-78.54174, 52.191889999],
  [-78.5446799, 52.191429899],
  [-78.54507, 52.191960099],
  [-78.5451201, 52.191189899],
  [-78.5461201, 52.191720099],
  [-78.5456999, 52.195809999],
  [-78.5440783, 52.196609699],
  [-78.5441101, 52.198089899],
  [-78.5430099, 52.199089899],
  [-78.54384, 52.199109999],
  [-78.54387, 52.198589999],
  [-78.54509, 52.199129999],
  [-78.54493, 52.196678399],
  [-78.54563, 52.196840099],
  [-78.5457999, 52.197610099],
  [-78.54683, 52.197889999],
  [-78.54905, 52.203060099],
  [-78.54575, 52.202349999],
  [-78.5445799, 52.200919999],
  [-78.54541, 52.200929999],
  [-78.5446, 52.200529899],
  [-78.5432619, 52.200782599],
  [-78.54449, 52.202449999],
  [-78.5461399, 52.202740099],
  [-78.5465, 52.203769899],
  [-78.54775, 52.203799999],
  [-78.54771, 52.204569999],
  [-78.5468801, 52.204559999],
  [-78.5472499, 52.205329999],
  [-78.54795, 52.205249999],
  [-78.5483699, 52.204070099],
  [-78.5475581, 52.203030699],
  [-78.5481284, 52.203147199],
  [-78.5493401, 52.205369999],
  [-78.55017, 52.205390099],
  [-78.5511201, 52.210919999],
  [-78.5523301, 52.211579999],
  [-78.56706, 52.209199899],
  [-78.56752, 52.208569999],
  [-78.57418, 52.208829999],
  [-78.57453, 52.210249999],
  [-78.5728199, 52.210730099],
  [-78.57253, 52.212259999],
  [-78.5656887, 52.214783799],
  [-78.56154, 52.218699999],
  [-78.55684, 52.224489999],
  [-78.55697, 52.229619999],
  [-78.55797, 52.230539899],
  [-78.5616701, 52.231509999],
  [-78.55906, 52.233509999],
  [-78.5536396, 52.235143399],
  [-78.5515876, 52.234249799],
  [-78.5485572, 52.234428599],
  [-78.54472, 52.236029999],
  [-78.5325262, 52.238344499],
  [-78.5275545, 52.240338499],
  [-78.5179651, 52.242007999],
  [-78.5205753, 52.256309199],
  [-78.5218899, 52.256179999],
  [-78.527441, 52.257887499],
  [-78.53517, 52.257739999],
  [-78.54026, 52.256310099],
  [-78.54328, 52.254579899],
  [-78.55583, 52.254210099],
  [-78.55586, 52.253689899],
  [-78.5688901, 52.252159999],
  [-78.5693599, 52.251139999],
  [-78.57018, 52.251420099],
  [-78.5722054, 52.250547199],
  [-78.5752676, 52.250977899],
  [-78.5748375, 52.252261999],
  [-78.5760575, 52.253663199],
  [-78.579399, 52.253979099],
  [-78.5767099, 52.255564599],
  [-78.5715396, 52.256898399],
  [-78.5671729, 52.256792099],
  [-78.5641266, 52.258742599],
  [-78.5634149, 52.260394799],
  [-78.5643202, 52.260569899],
  [-78.5659796, 52.262907499],
  [-78.5682748, 52.263448899],
  [-78.5685431, 52.264000099],
  [-78.5648342, 52.266313499],
  [-78.5594839, 52.265784199],
  [-78.5582751, 52.266171099],
  [-78.5578868, 52.267321099],
  [-78.5539603, 52.268216699],
  [-78.5547297, 52.268529599],
  [-78.5555731, 52.270697499],
  [-78.5548876, 52.272927599],
  [-78.560092, 52.274580499],
  [-78.5582141, 52.275629899],
  [-78.5565884, 52.277622999],
  [-78.5574767, 52.277767099],
  [-78.5570693, 52.278960999],
  [-78.5578808, 52.279496999],
  [-78.5610236, 52.280279299],
  [-78.5584055, 52.283258699],
  [-78.5595845, 52.283760199],
  [-78.5601589, 52.285273199],
  [-78.5614841, 52.285506899],
  [-78.5600132, 52.287318299],
  [-78.5631314, 52.287945999],
  [-78.5641815, 52.288924899],
  [-78.5639774, 52.289540699],
  [-78.5605146, 52.291170299],
  [-78.5608435, 52.292079399],
  [-78.559868, 52.292776899],
  [-78.5549486, 52.293088899],
  [-78.5452579, 52.297206399],
  [-78.5453685, 52.297732199],
  [-78.5379392, 52.300372999],
  [-78.5372084, 52.301354699],
  [-78.5328831, 52.301743499],
  [-78.5315563, 52.303145699],
  [-78.5330474, 52.304488199],
  [-78.5328262, 52.305674899],
  [-78.529152, 52.307147399],
  [-78.5244715, 52.307149099],
  [-78.5230075, 52.307767199],
  [-78.5224669, 52.308591699],
  [-78.5234041, 52.309298899],
  [-78.521415, 52.310527699],
  [-78.5216933, 52.311409899],
  [-78.5125129, 52.315674599],
  [-78.5115296, 52.316953899],
  [-78.5095315, 52.317917799],
  [-78.5117914, 52.318228699],
  [-78.5111586, 52.319442699],
  [-78.5114003, 52.320657699],
  [-78.5095058, 52.322729599],
  [-78.5101299, 52.323592899],
  [-78.5089565, 52.323750299],
  [-78.507905, 52.329016299],
  [-78.5061103, 52.332948199],
  [-78.5114586, 52.335171699],
  [-78.5109987, 52.335595599],
  [-78.5116675, 52.335788899],
  [-78.5066466, 52.337879799],
  [-78.5062265, 52.339984499],
  [-78.5037829, 52.342080199],
  [-78.5047229, 52.343455899],
  [-78.5029983, 52.344671399],
  [-78.5034466, 52.346437499],
  [-78.5022709, 52.347271899],
  [-78.5035469, 52.348297599],
  [-78.5027464, 52.349719699],
  [-78.5037144, 52.350676599],
  [-78.5028383, 52.351173999],
  [-78.5067957, 52.352136499],
  [-78.5100659, 52.351837599],
  [-78.5119971, 52.352734099],
  [-78.5124348, 52.353945099],
  [-78.52439, 52.353759999],
  [-78.529764, 52.351947699],
  [-78.53195, 52.353270099],
  [-78.53407, 52.352799999],
  [-78.53581, 52.351559999],
  [-78.53875, 52.351490099],
  [-78.5386899, 52.352519899],
  [-78.5374499, 52.352229999],
  [-78.53742, 52.352749999],
  [-78.53315, 52.354319999],
  [-78.5247601, 52.354519899],
  [-78.5192841, 52.355344799],
  [-78.5173014, 52.357577899],
  [-78.5105809, 52.358411399],
  [-78.5036051, 52.360163399],
  [-78.5050415, 52.362105599],
  [-78.5112719, 52.360946299],
  [-78.5263499, 52.363659999],
  [-78.5310001, 52.362859899],
  [-78.53095, 52.363629899],
  [-78.5301199, 52.363610099],
  [-78.52974, 52.366679999],
  [-78.53149, 52.369019999],
  [-78.53394, 52.370229999],
  [-78.5360299, 52.370139999],
  [-78.53642, 52.370789999],
  [-78.5447899, 52.370830099],
  [-78.54476, 52.371349999],
  [-78.5460001, 52.371629899],
  [-78.54473, 52.371849899],
  [-78.54337, 52.373899999],
  [-78.54164, 52.374749899],
  [-78.53407, 52.375359999],
  [-78.5238801, 52.377840099],
  [-78.52338, 52.379359999],
  [-78.5204201, 52.379809999],
  [-78.52015, 52.380830099],
  [-78.52214, 52.382669999],
  [-78.52206, 52.384199899],
  [-78.5203101, 52.385449999],
  [-78.52018, 52.387749899],
  [-78.52407, 52.389369999],
  [-78.52539, 52.391960099],
  [-78.5241299, 52.395779999],
  [-78.52116, 52.396489999],
  [-78.51848, 52.399510099],
  [-78.51548, 52.400719999],
  [-78.51716, 52.400499999],
  [-78.5168801, 52.401779999],
  [-78.5185, 52.402840099],
  [-78.5182101, 52.406840099],
  [-78.5197799, 52.406199899],
  [-78.5175401, 52.408709999],
  [-78.51671, 52.408439899],
  [-78.51627, 52.408939999],
  [-78.5175301, 52.408970099],
  [-78.51751, 52.413070099],
  [-78.5251099, 52.415659899],
  [-78.53884, 52.417489999],
  [-78.54897, 52.420129999],
  [-78.54839, 52.422939999],
  [-78.5443, 52.428750099],
  [-78.5440099, 52.433870099],
  [-78.54807, 52.436259999],
  [-78.5485699, 52.438579999],
  [-78.5435, 52.439109899],
  [-78.53794, 52.441179999],
  [-78.5365599, 52.443449899],
  [-78.5297799, 52.444589999],
  [-78.5284401, 52.446100099],
  [-78.5237701, 52.447029999],
  [-78.5224499, 52.448279999],
  [-78.5198999, 52.448729999],
  [-78.5181299, 52.450499999],
  [-78.51472, 52.451449899],
  [-78.51429, 52.455279999],
  [-78.5125201, 52.457049999],
  [-78.5123299, 52.460369999],
  [-78.5133101, 52.461560099],
  [-78.52374, 52.462660099],
  [-78.5232199, 52.464450099],
  [-78.52238, 52.464430099],
  [-78.52228, 52.466220099],
  [-78.5197601, 52.466169999],
  [-78.51972, 52.466929999],
  [-78.5188801, 52.466919899],
  [-78.51885, 52.467429999],
  [-78.51203, 52.469340099],
  [-78.5119999, 52.469859999],
  [-78.50814, 52.471299999],
  [-78.5076699, 52.472319999],
  [-78.50849, 52.472589899],
  [-78.5094, 52.471330099],
  [-78.51024, 52.471349899],
  [-78.51028, 52.470569899],
  [-78.5196799, 52.467699899],
  [-78.51962, 52.468469899],
  [-78.5187799, 52.468709999],
  [-78.52463, 52.469220099],
  [-78.52563, 52.470129899],
  [-78.52559, 52.474489999],
  [-78.52936, 52.474570099],
  [-78.53072, 52.476649999],
  [-78.5342001, 52.478129899],
  [-78.54806, 52.478029899],
  [-78.5479901, 52.479319999],
  [-78.5496701, 52.479220099],
  [-78.5574799, 52.474499999],
  [-78.56728, 52.472019899],
  [-78.5626147, 52.475542699],
  [-78.5648941, 52.478282999],
  [-78.5684, 52.478199999],
  [-78.56787, 52.480240099],
  [-78.5695999, 52.483089999],
  [-78.57044, 52.483110099],
  [-78.5703599, 52.484649999],
  [-78.57206, 52.484169999],
  [-78.57152, 52.486460099],
  [-78.5706801, 52.486439999],
  [-78.57008, 52.489759999],
  [-78.5692401, 52.489749999],
  [-78.56921, 52.490259899],
  [-78.56297, 52.489230099],
  [-78.55207, 52.488879999],
  [-78.5511299, 52.490659999],
  [-78.5494401, 52.490879999],
  [-78.5491101, 52.496759999],
  [-78.5569099, 52.499999999],
  [-78.56129, 52.504449999],
  [-78.5621201, 52.504589899],
  [-78.5642499, 52.507839899],
  [-78.5671101, 52.509179999],
  [-78.5700201, 52.513599899],
  [-78.57087, 52.513489899],
  [-78.57488, 52.516899999],
  [-78.5832501, 52.517329999],
  [-78.5858199, 52.516480099],
  [-78.58844, 52.518590099],
  [-78.5880376, 52.522801499],
  [-78.586321, 52.523950399],
  [-78.5874368, 52.525360399],
  [-78.5899666, 52.525262199],
  [-78.5923291, 52.525987099],
  [-78.6003591, 52.523697699],
  [-78.6051301, 52.516889999],
  [-78.6077001, 52.515899999],
  [-78.6081799, 52.514889999],
  [-78.6098499, 52.515049899],
  [-78.6107399, 52.514169899],
  [-78.61408, 52.514489899],
  [-78.6142601, 52.515009999],
  [-78.6169599, 52.515580099],
  [-78.61834, 52.513299999],
  [-78.6191799, 52.513319999],
  [-78.6191401, 52.514079999],
  [-78.6208101, 52.514250099],
  [-78.6212701, 52.513609899],
  [-78.6233701, 52.513659999],
  [-78.6242899, 52.512140099],
  [-78.6309499, 52.513169899],
  [-78.63134, 52.513829899],
  [-78.63302, 52.513849999],
  [-78.63299, 52.514360099],
  [-78.6355199, 52.514159899],
  [-78.63548, 52.514920099],
  [-78.6367499, 52.514690099],
  [-78.6367201, 52.515209999],
  [-78.64269, 52.513539999],
  [-78.6426399, 52.514559999],
  [-78.64391, 52.514319999],
  [-78.6447, 52.515360099],
  [-78.6447499, 52.514339999],
  [-78.6468599, 52.514130099],
  [-78.64636, 52.515649999],
  [-78.6455199, 52.515639999],
  [-78.64633, 52.516289999],
  [-78.6517599, 52.516910099],
  [-78.65326, 52.516289999],
  [-78.6533099, 52.515289999],
  [-78.65611, 52.514049899],
  [-78.65614, 52.513539999],
  [-78.6624299, 52.513669999],
  [-78.6627699, 52.515209999],
  [-78.66194, 52.514939899],
  [-78.6608, 52.516709899],
  [-78.66179, 52.517749999],
  [-78.6680701, 52.518130099],
  [-78.6703199, 52.515360099],
  [-78.67182, 52.514879999],
  [-78.6718601, 52.514109999],
  [-78.67294, 52.513489899],
  [-78.6773001, 52.514470099],
  [-78.67701, 52.515999999],
  [-78.6778501, 52.516020099],
  [-78.67782, 52.516529999],
  [-78.67526, 52.517250099],
  [-78.6748, 52.518009999],
  [-78.66715, 52.519639999],
  [-78.6670899, 52.520930099],
  [-78.6658501, 52.520399899],
  [-78.6658, 52.521419999],
  [-78.6602999, 52.522079999],
  [-78.6598401, 52.522839899],
  [-78.6575101, 52.523309999],
  [-78.65615, 52.525069899],
  [-78.6557599, 52.532499899],
  [-78.65444, 52.533499899],
  [-78.65502, 52.534529999],
  [-78.6537599, 52.534509899],
  [-78.6538, 52.533739899],
  [-78.6530899, 52.534069899],
  [-78.65288, 52.535260099],
  [-78.65838, 52.534600099],
  [-78.65835, 52.535109999],
  [-78.6646301, 52.535619899],
  [-78.66679, 52.534509899],
  [-78.6660899, 52.535779999],
  [-78.66838, 52.536209999],
  [-78.67136, 52.535629899],
  [-78.6713099, 52.536399899],
  [-78.67345, 52.535659999],
  [-78.67335, 52.537459999],
  [-78.67457, 52.538249999],
  [-78.67881, 52.537569999],
  [-78.68114, 52.533260099],
  [-78.6870701, 52.532349999],
  [-78.68713, 52.531319999],
  [-78.6879701, 52.531339999],
  [-78.68801, 52.530559999],
  [-78.69225, 52.529759999],
  [-78.69518, 52.529939899],
  [-78.69804, 52.523590099],
  [-78.6988799, 52.523609899],
  [-78.69893, 52.522580099],
  [-78.7030901, 52.523439999],
  [-78.7038701, 52.524470099],
  [-78.70596, 52.524769999],
  [-78.7059801, 52.524260099],
  [-78.70963, 52.522789999],
  [-78.7098685, 52.521761499],
  [-78.71539, 52.521109999],
  [-78.7149228, 52.522602799],
  [-78.7186699, 52.522449999],
  [-78.7184201, 52.523470099],
  [-78.71963, 52.524519999],
  [-78.7191001, 52.526559999],
  [-78.71629, 52.528040099],
  [-78.7162399, 52.529069899],
  [-78.7196, 52.528999999],
  [-78.72048, 52.528250099],
  [-78.7238701, 52.527810099],
  [-78.7242499, 52.528580099],
  [-78.72595, 52.527969999],
  [-78.7267701, 52.528499899],
  [-78.7247701, 52.529409999],
  [-78.72631, 52.529250099],
  [-78.72629, 52.529769999],
  [-78.7301101, 52.528949899],
  [-78.7304501, 52.530619899],
  [-78.72919, 52.530600099],
  [-78.72874, 52.531099999],
  [-78.7295799, 52.531119999],
  [-78.72931, 52.532399899],
  [-78.72693, 52.533619899],
  [-78.7268901, 52.534399899],
  [-78.72605, 52.534380099],
  [-78.7255799, 52.535399899],
  [-78.72474, 52.535380099],
  [-78.7247601, 52.534869999],
  [-78.7224, 52.535849899],
  [-78.72215, 52.536609899],
  [-78.7114401, 52.540639999],
  [-78.7051001, 52.541150099],
  [-78.70504, 52.542429999],
  [-78.7075599, 52.542480099],
  [-78.7068, 52.548879999],
  [-78.71308, 52.549380099],
  [-78.7165501, 52.547399899],
  [-78.71952, 52.546809999],
  [-78.7202201, 52.545549999],
  [-78.72172, 52.544930099],
  [-78.72673, 52.545539999],
  [-78.7364501, 52.544579999],
  [-78.73662, 52.545349999],
  [-78.74141, 52.546209999],
  [-78.7418801, 52.545319999],
  [-78.7425, 52.545459999],
  [-78.74171, 52.548519899],
  [-78.74297, 52.548549999],
  [-78.74162, 52.550309999],
  [-78.7407801, 52.550299899],
  [-78.74051, 52.551319999],
  [-78.7372499, 52.553559999],
  [-78.73361, 52.554769999],
  [-78.73331, 52.556559999],
  [-78.7303301, 52.557270099],
  [-78.7287999, 52.558519899],
  [-78.72893, 52.559940099],
  [-78.7347999, 52.560309899],
  [-78.7420199, 52.558909999],
  [-78.74527, 52.557050099],
  [-78.7453401, 52.551669999],
  [-78.74618, 52.551689999],
  [-78.7467701, 52.548359999],
  [-78.7539299, 52.548109999],
  [-78.75599, 52.548789999],
  [-78.7560201, 52.548280099],
  [-78.75855, 52.548199999],
  [-78.76098, 52.545809999],
  [-78.76039, 52.540930099],
  [-78.7633, 52.541500099],
  [-78.7664899, 52.540789999],
  [-78.76686, 52.541689999],
  [-78.7679101, 52.541839899],
  [-78.76808, 52.542619899],
  [-78.76521, 52.544009999],
  [-78.7654501, 52.544619899],
  [-78.7692501, 52.544429999],
  [-78.76998, 52.546490099],
  [-78.7691401, 52.546480099],
  [-78.76819, 52.548779999],
  [-78.7669101, 52.548999999],
  [-78.76531, 52.551529899],
  [-78.7657199, 52.551799999],
  [-78.765, 52.553569999],
  [-78.7608001, 52.553500099],
  [-78.76077, 52.554019999],
  [-78.75787, 52.553189899],
  [-78.75783, 52.553950099],
  [-78.75442, 52.554909999],
  [-78.7547801, 52.555950099],
  [-78.75685, 52.556639899],
  [-78.76088, 52.556060099],
  [-78.76111, 52.555549999],
  [-78.7619101, 52.556349999],
  [-78.7678099, 52.556189899],
  [-78.7673401, 52.557209999],
  [-78.7665, 52.557189899],
  [-78.76644, 52.558219999],
  [-78.7655999, 52.558199899],
  [-78.76507, 52.560500099],
  [-78.7642301, 52.560479999],
  [-78.76286, 52.562510099],
  [-78.7624701, 52.561989999],
  [-78.7599299, 52.562189899],
  [-78.75986, 52.563730099],
  [-78.75731, 52.564189899],
  [-78.7572699, 52.565229999],
  [-78.7610399, 52.565029999],
  [-78.7592499, 52.567309899],
  [-78.75841, 52.567290099],
  [-78.7579299, 52.568559999],
  [-78.75274, 52.571280099],
  [-78.7527801, 52.570519899],
  [-78.7431499, 52.569689999],
  [-78.73928, 52.571409899],
  [-78.73716, 52.571500099],
  [-78.73537, 52.569669999],
  [-78.7349199, 52.566070099],
  [-78.72983, 52.566999999],
  [-78.7292101, 52.570830099],
  [-78.7342399, 52.571199899],
  [-78.73375, 52.572459999],
  [-78.73164, 52.572929999],
  [-78.7278801, 52.576189899],
  [-78.7271299, 52.578479999],
  [-78.72373, 52.579189899],
  [-78.7232399, 52.580459999],
  [-78.71828, 52.582919999],
  [-78.71708, 52.585719999],
  [-78.71581, 52.585950099],
  [-78.71529, 52.587740099],
  [-78.7106799, 52.587389999],
  [-78.7093299, 52.589159999],
  [-78.7072101, 52.589369999],
  [-78.7055599, 52.591559999],
  [-78.71172, 52.591769899],
  [-78.71082, 52.593039999],
  [-78.71374, 52.593339999],
  [-78.7148701, 52.595809999],
  [-78.71571, 52.595819999],
  [-78.71661, 52.594679999],
  [-78.7348999, 52.590929999],
  [-78.7322101, 52.593960099],
  [-78.73007, 52.594939999],
  [-78.73004, 52.595709999],
  [-78.72125, 52.594630099],
  [-78.7188701, 52.595750099],
  [-78.7185699, 52.597539899],
  [-78.71519, 52.597979899],
  [-78.71426, 52.599499999],
  [-78.7155301, 52.599269999],
  [-78.71528, 52.600039999],
  [-78.7158701, 52.600819999],
  [-78.7179801, 52.600860099],
  [-78.7180199, 52.602819999],
  [-78.72297, 52.601980099],
  [-78.7224699, 52.603759899],
  [-78.72074, 52.604369999],
  [-78.71317, 52.604219899],
  [-78.7059712, 52.605305199],
  [-78.70472, 52.604960099],
  [-78.70474, 52.604449899],
  [-78.70305, 52.604669899],
  [-78.70507, 52.606249999],
  [-78.70885, 52.606319899],
  [-78.71593, 52.607870099],
  [-78.7226899, 52.607359999],
  [-78.72318, 52.605829999],
  [-78.7299901, 52.604689999],
  [-78.72842, 52.606439899],
  [-78.7285601, 52.607719999],
  [-78.7302499, 52.607630099],
  [-78.73627, 52.605310099],
  [-78.73705, 52.606349999],
  [-78.7353499, 52.606829999],
  [-78.7361399, 52.607609999],
  [-78.73318, 52.608070099],
  [-78.7331199, 52.609090099],
  [-78.7348099, 52.609129999],
  [-78.7343301, 52.610139999],
  [-78.73198, 52.610870099],
  [-78.7316899, 52.612410099],
  [-78.7335601, 52.612949999],
  [-78.73352, 52.613459999],
  [-78.7317999, 52.614449899],
  [-78.73148, 52.616489999],
  [-78.7281101, 52.616430099],
  [-78.72704, 52.612819999],
  [-78.72251, 52.610809999],
  [-78.71763, 52.611609999],
  [-78.71752, 52.613919999],
  [-78.72015, 52.615760099],
  [-78.72329, 52.620049999],
  [-78.73172, 52.619959999],
  [-78.740881, 52.617363799],
  [-78.73887, 52.620219899],
  [-78.7358901, 52.620679899],
  [-78.7353499, 52.622970099],
  [-78.7325401, 52.624459899],
  [-78.73183, 52.625980099],
  [-78.7254, 52.628169999],
  [-78.72461, 52.631229899],
  [-78.7213599, 52.632959999],
  [-78.72007, 52.637540099],
  [-78.7218999, 52.638859999],
  [-78.7267999, 52.637669899],
  [-78.7282101, 52.630779899],
  [-78.72904, 52.631049999],
  [-78.7286701, 52.630009899],
  [-78.72951, 52.630159999],
  [-78.7299901, 52.629019899],
  [-78.7394385, 52.625333499],
  [-78.7457213, 52.624301799],
  [-78.7456698, 52.625005199],
  [-78.7503819, 52.625130299],
  [-78.7531371, 52.626208799],
  [-78.7605528, 52.622113499],
  [-78.761866, 52.622160399],
  [-78.7622523, 52.623645299],
  [-78.7657284, 52.623426499],
  [-78.7669129, 52.621816499],
  [-78.7711615, 52.621019299],
  [-78.7755904, 52.622222899],
  [-78.77984, 52.621769899],
  [-78.77955, 52.623299999],
  [-78.7787099, 52.623279999],
  [-78.7782401, 52.624299999],
  [-78.77673, 52.625039999],
  [-78.77562, 52.630399999],
  [-78.7780399, 52.632499999],
  [-78.77675, 52.637079999],
  [-78.7733601, 52.637529999],
  [-78.7733799, 52.637019899],
  [-78.77421, 52.637289999],
  [-78.7718001, 52.635069999],
  [-78.77053, 52.635169999],
  [-78.769, 52.632069999],
  [-78.7681599, 52.632049999],
  [-78.76778, 52.631279999],
  [-78.76774, 52.631789899],
  [-78.7634799, 52.632990099],
  [-78.76274, 52.635029999],
  [-78.7652401, 52.639689899],
  [-78.7690201, 52.640009899],
  [-78.76907, 52.638990099],
  [-78.7678199, 52.638709999],
  [-78.7688001, 52.636039999],
  [-78.7709001, 52.636079999],
  [-78.77229, 52.637770099],
  [-78.7795999, 52.639059999],
  [-78.7804, 52.639839999],
  [-78.7841599, 52.640430099],
  [-78.78631, 52.639699999],
  [-78.7866901, 52.640349899],
  [-78.78921, 52.640639999],
  [-78.7897099, 52.639119899],
  [-78.79096, 52.639269999],
  [-78.7901501, 52.638619999],
  [-78.7918399, 52.638650099],
  [-78.79186, 52.638139999],
  [-78.79265, 52.639179999],
  [-78.79432, 52.639459899],
  [-78.7950599, 52.637430099],
  [-78.79467, 52.636650099],
  [-78.79601, 52.635139999],
  [-78.79542, 52.634359999],
  [-78.7962601, 52.634369999],
  [-78.7957101, 52.633069999],
  [-78.7979421, 52.632271999],
  [-78.8035199, 52.632459899],
  [-78.8041799, 52.636059999],
  [-78.80334, 52.636039999],
  [-78.80408, 52.638110099],
  [-78.8057399, 52.638389999],
  [-78.80579, 52.637629999],
  [-78.81256, 52.636980099],
  [-78.81253, 52.637489999],
  [-78.81379, 52.637650099],
  [-78.8141799, 52.638289999],
  [-78.81588, 52.638069999],
  [-78.8149599, 52.639589999],
  [-78.81412, 52.639569899],
  [-78.81366, 52.640330099],
  [-78.8124099, 52.640049999],
  [-78.8123701, 52.640819999],
  [-78.81111, 52.640799899],
  [-78.8110799, 52.641309999],
  [-78.8077299, 52.640990099],
  [-78.80821, 52.639719999],
  [-78.8073899, 52.639320099],
  [-78.80399, 52.639899899],
  [-78.8034999, 52.645529999],
  [-78.8014901, 52.647799899],
  [-78.7989499, 52.648009899],
  [-78.79897, 52.647499999],
  [-78.79731, 52.647210099],
  [-78.7981699, 52.646450099],
  [-78.79487, 52.645369999],
  [-78.79546, 52.642049999],
  [-78.79463, 52.641780099],
  [-78.7932699, 52.643540099],
  [-78.79243, 52.643529999],
  [-78.791, 52.646829999],
  [-78.78888, 52.647049999],
  [-78.7883899, 52.648579899],
  [-78.78799, 52.648059999],
  [-78.78585, 52.648789899],
  [-78.78587, 52.648269999],
  [-78.7846099, 52.648249899],
  [-78.78465, 52.647479999],
  [-78.78299, 52.646939999],
  [-78.7818299, 52.644880099],
  [-78.7805801, 52.644719999],
  [-78.7788299, 52.645839999],
  [-78.77545, 52.646029899],
  [-78.77498, 52.647049999],
  [-78.7733, 52.647009899],
  [-78.7711, 52.648770099],
  [-78.76855, 52.649230099],
  [-78.76852, 52.649739999],
  [-78.7642599, 52.650939999],
  [-78.76422, 52.651459899],
  [-78.7454799, 52.655459899],
  [-78.7445701, 52.656719999],
  [-78.74373, 52.656709999],
  [-78.74064, 52.659340099],
  [-78.7376899, 52.659409999],
  [-78.7372, 52.660680099],
  [-78.7384599, 52.660829999],
  [-78.73962, 52.662790099],
  [-78.74384, 52.662729999],
  [-78.7474799, 52.661649999],
  [-78.75184, 52.658649999],
  [-78.75365, 52.656379999],
  [-78.76074, 52.653569899],
  [-78.76706, 52.653689899],
  [-78.7685, 52.654359899],
  [-78.7694, 52.657319999],
  [-78.7702401, 52.657459899],
  [-78.77018, 52.658489999],
  [-78.7689199, 52.658459899],
  [-78.7684501, 52.659479899],
  [-78.76717, 52.659709999],
  [-78.7671301, 52.660479899],
  [-78.7641599, 52.660939999],
  [-78.7612401, 52.664469899],
  [-78.7641399, 52.665539999],
  [-78.76208, 52.668589899],
  [-78.7608099, 52.668819899],
  [-78.7594601, 52.670330099],
  [-78.75819, 52.670560099],
  [-78.7582301, 52.669790099],
  [-78.75442, 52.669979999],
  [-78.75395, 52.670989999],
  [-78.75096, 52.671829999],
  [-78.74251, 52.672059999],
  [-78.7423301, 52.679739999],
  [-78.74586, 52.684929899],
  [-78.74771, 52.685739999],
  [-78.7482399, 52.687800099],
  [-78.7475701, 52.688559999],
  [-78.73364, 52.688800099],
  [-78.73107, 52.689519999],
  [-78.7288801, 52.691020099],
  [-78.734204, 52.689295699],
  [-78.7456476, 52.689336299],
  [-78.76283, 52.686919899],
  [-78.7677901, 52.684839999],
  [-78.7684799, 52.683829899],
  [-78.7743601, 52.684179999],
  [-78.7744799, 52.685989999],
  [-78.7759299, 52.686399999],
  [-78.777, 52.686039899],
  [-78.7772699, 52.685020099],
  [-78.7853501, 52.683629999],
  [-78.7857199, 52.684659999],
  [-78.7878299, 52.684570099],
  [-78.7881, 52.687519999],
  [-78.79063, 52.687570099],
  [-78.79475, 52.685340099],
  [-78.7988201, 52.684139899],
  [-78.7989201, 52.685079999],
  [-78.8000301, 52.685179999],
  [-78.8009201, 52.684299999],
  [-78.80989, 52.682039899],
  [-78.8098499, 52.682800099],
  [-78.81112, 52.682570099],
  [-78.8101401, 52.685369899],
  [-78.81098, 52.685389999],
  [-78.8109201, 52.686669999],
  [-78.8147399, 52.686230099],
  [-78.81477, 52.685460099],
  [-78.8160401, 52.685479899],
  [-78.8159499, 52.687539999],
  [-78.8197499, 52.687089999],
  [-78.82047, 52.689659999],
  [-78.8221501, 52.689819899],
  [-78.82379, 52.690749999],
  [-78.82376, 52.691259899],
  [-78.82588, 52.691039899],
  [-78.8258201, 52.692319999],
  [-78.82666, 52.692329999],
  [-78.8267, 52.691570099],
  [-78.82754, 52.691589899],
  [-78.8275199, 52.692099999],
  [-78.8288, 52.691869999],
  [-78.82676, 52.694649999],
  [-78.8264199, 52.696949899],
  [-78.82559, 52.696929899],
  [-78.8264199, 52.697199999],
  [-78.82636, 52.698480099],
  [-78.8276199, 52.698509999],
  [-78.82578, 52.701549999],
  [-78.8207399, 52.700939899],
  [-78.8207299, 52.701449999],
  [-78.81946, 52.701429999],
  [-78.8199301, 52.700419999],
  [-78.81909, 52.700399999],
  [-78.8190699, 52.700910099],
  [-78.81523, 52.701739999],
  [-78.8122701, 52.701810099],
  [-78.81189, 52.701039899],
  [-78.80976, 52.701519999],
  [-78.80855, 52.700209999],
  [-78.8035199, 52.699599899],
  [-78.8027399, 52.698299999],
  [-78.7976901, 52.698209999],
  [-78.79721, 52.699229999],
  [-78.78964, 52.698829899],
  [-78.78918, 52.699590099],
  [-78.7808445, 52.701388199],
  [-78.7771279, 52.700169699],
  [-78.7763842, 52.700846999],
  [-78.7771941, 52.700730899],
  [-78.7772293, 52.701194799],
  [-78.77165, 52.704140099],
  [-78.76713, 52.708216099],
  [-78.768, 52.713800099],
  [-78.7670399, 52.716089999],
  [-78.76884, 52.718040099],
  [-78.77175, 52.718739899],
  [-78.7733699, 52.720179899],
  [-78.7796901, 52.720429999],
  [-78.7801599, 52.719409999],
  [-78.7838199, 52.717939899],
  [-78.7908201, 52.717299999],
  [-78.7915901, 52.718729899],
  [-78.7958399, 52.718159899],
  [-78.7958001, 52.718930099],
  [-78.79708, 52.718700099],
  [-78.7970301, 52.719719899],
  [-78.79787, 52.719739899],
  [-78.7978399, 52.720250099],
  [-78.7953, 52.720459999],
  [-78.79397, 52.721719899],
  [-78.7926901, 52.721949899],
  [-78.7922, 52.723219999],
  [-78.78589, 52.722849899],
  [-78.78543, 52.723609899],
  [-78.7841699, 52.723579999],
  [-78.78454, 52.724619899],
  [-78.79041, 52.725370099],
  [-78.79212, 52.725009999],
  [-78.7926199, 52.727590099],
  [-78.78976, 52.729839899],
  [-78.78764, 52.730059899],
  [-78.7876099, 52.730569999],
  [-78.7854601, 52.731419999],
  [-78.7779299, 52.729869999],
  [-78.77756, 52.729099999],
  [-78.7729299, 52.728759999],
  [-78.77277, 52.731830099],
  [-78.78285, 52.732909999],
  [-78.7888101, 52.731869999],
  [-78.78785, 52.734160099],
  [-78.7908101, 52.734089999],
  [-78.7919, 52.733469999],
  [-78.7940699, 52.728129999],
  [-78.79709, 52.726769999],
  [-78.80087, 52.727229999],
  [-78.80219, 52.726099999],
  [-78.8030301, 52.726239999],
  [-78.8034999, 52.725229999],
  [-78.80434, 52.725239999],
  [-78.80397, 52.724209999],
  [-78.80524, 52.724219999],
  [-78.8061501, 52.722969999],
  [-78.8087001, 52.722619899],
  [-78.8091799, 52.725590099],
  [-78.8123899, 52.724749999],
  [-78.8134099, 52.725409899],
  [-78.8139201, 52.727979999],
  [-78.8151799, 52.727999999],
  [-78.8159499, 52.729559999],
  [-78.8197399, 52.729629899],
  [-78.82066, 52.728099999],
  [-78.82491, 52.727539999],
  [-78.82623, 52.726419999],
  [-78.8312701, 52.727019999],
  [-78.83124, 52.727789999],
  [-78.83336, 52.727439999],
  [-78.8333099, 52.728339999],
  [-78.83921, 52.728569999],
  [-78.8396, 52.729219999],
  [-78.83834, 52.729199999],
  [-78.8369799, 52.730969899],
  [-78.8378301, 52.730979999],
  [-78.8382999, 52.734319999],
  [-78.8413099, 52.737449999],
  [-78.84257, 52.737600099],
  [-78.8429601, 52.738249999],
  [-78.84211, 52.738239999],
  [-78.84247, 52.739509899],
  [-78.84459, 52.739309999],
  [-78.84436, 52.739799999],
  [-78.84703, 52.741270099],
  [-78.84779, 52.742950099],
  [-78.8515399, 52.744040099],
  [-78.8515101, 52.744559999],
  [-78.8536, 52.744979999],
  [-78.85702, 52.744139999],
  [-78.8570701, 52.743119999],
  [-78.86005, 52.742659999],
  [-78.8611901, 52.740889999],
  [-78.86269, 52.740399899],
  [-78.86272, 52.739889999],
  [-78.86614, 52.739050099],
  [-78.8716312, 52.738964999],
  [-78.87157, 52.740309899],
  [-78.87327, 52.740079899],
  [-78.87324, 52.740589999],
  [-78.8724001, 52.740579999],
  [-78.8718501, 52.742859899],
  [-78.87016, 52.743099999],
  [-78.87056, 52.743619899],
  [-78.86972, 52.743600099],
  [-78.86924, 52.744619899],
  [-78.8662901, 52.744569999],
  [-78.86537, 52.746089899],
  [-78.86327, 52.746050099],
  [-78.86126, 52.748060099],
  [-78.86094, 52.750620099],
  [-78.8575, 52.751579999],
  [-78.8569899, 52.753369999],
  [-78.85571, 52.753600099],
  [-78.8538599, 52.756899999],
  [-78.85302, 52.756879999],
  [-78.8501801, 52.762979899],
  [-78.84848, 52.763209899],
  [-78.84844, 52.763969899],
  [-78.84591, 52.763929999],
  [-78.84603, 52.758639899],
  [-78.84533, 52.758539899],
  [-78.8435299, 52.760809999],
  [-78.8409799, 52.761019999],
  [-78.8405101, 52.762039999],
  [-78.83967, 52.762019999],
  [-78.83932, 52.764840099],
  [-78.84078, 52.765249999],
  [-78.8465101, 52.764699999],
  [-78.8458, 52.766229999],
  [-78.8449501, 52.766219999],
  [-78.84491, 52.766989899],
  [-78.8436399, 52.767219999],
  [-78.8414099, 52.769489999],
  [-78.84057, 52.769469999],
  [-78.8409601, 52.769989899],
  [-78.83925, 52.770469999],
  [-78.8378599, 52.772999999],
  [-78.8429501, 52.772589999],
  [-78.8416201, 52.773850099],
  [-78.8395199, 52.773549899],
  [-78.84035, 52.773819999],
  [-78.8405101, 52.774850099],
  [-78.8389699, 52.776099899],
  [-78.8355199, 52.777579999],
  [-78.8350601, 52.778339999],
  [-78.8320601, 52.779180099],
  [-78.8222999, 52.779899999],
  [-78.8325939, 52.774361899],
  [-78.8333664, 52.773302699],
  [-78.8308687, 52.774034799],
  [-78.8226299, 52.778709999],
  [-78.82068, 52.778589999],
  [-78.82065, 52.779099899],
  [-78.8193901, 52.778950099],
  [-78.81766, 52.779809999],
  [-78.81768, 52.779300099],
  [-78.81642, 52.779279999],
  [-78.8159499, 52.780279999],
  [-78.81468, 52.780269999],
  [-78.81479, 52.777970099],
  [-78.8139499, 52.777950099],
  [-78.81443, 52.776679999],
  [-78.8127299, 52.776639899],
  [-78.8123701, 52.775620099],
  [-78.8102999, 52.774809999],
  [-78.81035, 52.773789999],
  [-78.80208, 52.770300099],
  [-78.80212, 52.769529899],
  [-78.8012701, 52.769520099],
  [-78.79846, 52.766649899],
  [-78.79345, 52.765529899],
  [-78.7939101, 52.764769999],
  [-78.7930599, 52.764749899],
  [-78.79309, 52.764239999],
  [-78.7895595, 52.763875799],
  [-78.7912975, 52.761616499],
  [-78.7946771, 52.760493299],
  [-78.8052343, 52.760986699],
  [-78.8068329, 52.761765799],
  [-78.8122831, 52.762570899],
  [-78.8170575, 52.762440999],
  [-78.817787, 52.761804799],
  [-78.8093113, 52.760739999],
  [-78.8033246, 52.758818199],
  [-78.7992905, 52.758474099],
  [-78.7966727, 52.756883299],
  [-78.797928, 52.755565199],
  [-78.7975846, 52.755136599],
  [-78.794999, 52.754928799],
  [-78.7907504, 52.755454799],
  [-78.7885402, 52.756506699],
  [-78.7851392, 52.756305399],
  [-78.7865769, 52.757233899],
  [-78.7848388, 52.758947999],
  [-78.7841092, 52.757571599],
  [-78.7843989, 52.756604099],
  [-78.7837337, 52.756415799],
  [-78.7841414, 52.755915799],
  [-78.7833904, 52.755285999],
  [-78.7778865, 52.757376799],
  [-78.7781762, 52.757720899],
  [-78.7774037, 52.758279299],
  [-78.777511, 52.759032399],
  [-78.7787233, 52.759045399],
  [-78.7787448, 52.760006299],
  [-78.7796031, 52.759850499],
  [-78.7787555, 52.760317999],
  [-78.7788414, 52.761012699],
  [-78.7813519, 52.761908599],
  [-78.7825214, 52.761694399],
  [-78.7843345, 52.764382099],
  [-78.7909435, 52.764804099],
  [-78.7925697, 52.765908599],
  [-78.792976, 52.767808399],
  [-78.794331, 52.768333499],
  [-78.7925051, 52.770938699],
  [-78.7951503, 52.772914499],
  [-78.7947344, 52.773859899],
  [-78.7957528, 52.774268099],
  [-78.7948237, 52.776088799],
  [-78.7962617, 52.776258399],
  [-78.7953701, 52.777609999],
  [-78.7924581, 52.779348999],
  [-78.7891408, 52.780188999],
  [-78.7842501, 52.780219899],
  [-78.783861, 52.779283399],
  [-78.7826168, 52.779130099],
  [-78.7813134, 52.777954099],
  [-78.7779498, 52.777205899],
  [-78.7748675, 52.775629099],
  [-78.7774, 52.773679999],
  [-78.7716366, 52.773363299],
  [-78.7673519, 52.770866199],
  [-78.7668329, 52.768998099],
  [-78.7624547, 52.767798399],
  [-78.76117, 52.768769899],
  [-78.7606892, 52.770072099],
  [-78.7660222, 52.769252199],
  [-78.7654601, 52.771669999],
  [-78.76884, 52.775829999],
  [-78.7743575, 52.776456199],
  [-78.7717897, 52.777976099],
  [-78.7641001, 52.780756299],
  [-78.7631499, 52.779569999],
  [-78.7594456, 52.779921799],
  [-78.7596782, 52.778716599],
  [-78.7564899, 52.777649899],
  [-78.7582699, 52.775889999],
  [-78.7570201, 52.775489999],
  [-78.74728, 52.775939999],
  [-78.74627, 52.775149999],
  [-78.7474799, 52.772099899],
  [-78.7436701, 52.772269999],
  [-78.7404401, 52.769400099],
  [-78.7373401, 52.772159999],
  [-78.7298331, 52.774397299],
  [-78.7253442, 52.773475799],
  [-78.7175937, 52.775189299],
  [-78.7161107, 52.774702799],
  [-78.715302, 52.776373099],
  [-78.7163598, 52.777376299],
  [-78.7223594, 52.777968199],
  [-78.7239043, 52.778840399],
  [-78.7200419, 52.781581599],
  [-78.7230288, 52.781597199],
  [-78.7315185, 52.776282799],
  [-78.7372478, 52.774588699],
  [-78.7375283, 52.774880199],
  [-78.7359432, 52.775559299],
  [-78.7385312, 52.775023799],
  [-78.7425822, 52.775745599],
  [-78.7430331, 52.776304599],
  [-78.74475, 52.775889999],
  [-78.7391346, 52.779960199],
  [-78.7363301, 52.783410099],
  [-78.7353955, 52.783945699],
  [-78.7333599, 52.783609999],
  [-78.7324599, 52.784620099],
  [-78.7301182, 52.784991699],
  [-78.7244432, 52.789695999],
  [-78.7466, 52.797745099],
  [-78.7514624, 52.796276799],
  [-78.7472535, 52.799966399],
  [-78.73351, 52.805139999],
  [-78.73439, 52.808489999],
  [-78.735741, 52.810694899],
  [-78.7336703, 52.813191499],
  [-78.74699, 52.806169999],
  [-78.7486142, 52.804705999],
  [-78.7513301, 52.803939999],
  [-78.7526026, 52.802701899],
  [-78.7576214, 52.801161499],
  [-78.7601926, 52.801801299],
  [-78.7612006, 52.801481999],
  [-78.7612472, 52.802495599],
  [-78.7658207, 52.802001199],
  [-78.7658367, 52.802914099],
  [-78.7625157, 52.806586099],
  [-78.7630797, 52.807882099],
  [-78.7649353, 52.808537299],
  [-78.7670973, 52.808335899],
  [-78.7675188, 52.806846199],
  [-78.7698362, 52.805002099],
  [-78.7732807, 52.804742999],
  [-78.775044, 52.802757999],
  [-78.7775305, 52.801814299],
  [-78.7816091, 52.802291999],
  [-78.7813799, 52.803489999],
  [-78.7793518, 52.804100799],
  [-78.7806253, 52.805698699],
  [-78.7833601, 52.806290799],
  [-78.7852517, 52.805827299],
  [-78.7862799, 52.806909999],
  [-78.7905808, 52.805060299],
  [-78.7886821, 52.807398199],
  [-78.7872287, 52.807576099],
  [-78.7869026, 52.808183099],
  [-78.7874133, 52.808407499],
  [-78.7903602, 52.807921299],
  [-78.7912088, 52.807025799],
  [-78.7944222, 52.806011299],
  [-78.7945267, 52.807440199],
  [-78.7914839, 52.808594199],
  [-78.7924801, 52.809739199],
  [-78.7868582, 52.809761799],
  [-78.784378, 52.810561299],
  [-78.7871412, 52.811168999],
  [-78.7881167, 52.812536399],
  [-78.7891868, 52.812369399],
  [-78.7898535, 52.813524899],
  [-78.7919849, 52.813971399],
  [-78.7938758, 52.813218799],
  [-78.7946941, 52.811828899],
  [-78.7969847, 52.811498499],
  [-78.8005606, 52.809787399],
  [-78.8089327, 52.809125299],
  [-78.8063494, 52.810957899],
  [-78.8044936, 52.810946199],
  [-78.8026272, 52.812251599],
  [-78.8021, 52.811559899],
  [-78.7979429, 52.813032899],
  [-78.797386, 52.812468999],
  [-78.796244, 52.812606199],
  [-78.7948906, 52.813285799],
  [-78.7943777, 52.814300099],
  [-78.7950544, 52.815537199],
  [-78.793014, 52.815568999],
  [-78.7939971, 52.815779299],
  [-78.7863655, 52.819373099],
  [-78.7817498, 52.818525399],
  [-78.7808458, 52.819151199],
  [-78.779163, 52.818110599],
  [-78.7762988, 52.819454999],
  [-78.7759955, 52.818924299],
  [-78.7746996, 52.819832899],
  [-78.7739875, 52.819485599],
  [-78.7691232, 52.820928499],
  [-78.7699494, 52.822857299],
  [-78.7741333, 52.822761799],
  [-78.7697475, 52.826380999],
  [-78.7663388, 52.826396899],
  [-78.7655395, 52.825524399],
  [-78.7633599, 52.824929999],
  [-78.7610054, 52.825166599],
  [-78.7594971, 52.827216699],
  [-78.7615114, 52.828827099],
  [-78.7638446, 52.828308099],
  [-78.7632777, 52.829510799],
  [-78.75138, 52.835719999],
  [-78.7449299, 52.837650099],
  [-78.7449099, 52.838159999],
  [-78.7415, 52.838609999],
  [-78.74072, 52.840039999],
  [-78.7448416, 52.841570699],
  [-78.74796, 52.840529999],
  [-78.7479045, 52.841938899],
  [-78.75283, 52.840359899],
  [-78.7546358, 52.839148999],
  [-78.7555489, 52.839341999],
  [-78.757814, 52.837827899],
  [-78.7585465, 52.838546099],
  [-78.75929, 52.838179999],
  [-78.7585603, 52.839032999],
  [-78.7531556, 52.841639599],
  [-78.75151, 52.841359899],
  [-78.75154, 52.840849999],
  [-78.74719, 52.843069999],
  [-78.74606, 52.844589899],
  [-78.74609, 52.847919899],
  [-78.74006, 52.849979999],
  [-78.73538, 52.850539999],
  [-78.73397, 52.853069999],
  [-78.73271, 52.853049999],
  [-78.7322101, 52.854319999],
  [-78.7279199, 52.855519999],
  [-78.7256701, 52.858049999],
  [-78.72229, 52.857969999],
  [-78.72283, 52.859780099],
  [-78.7259187, 52.861412599],
  [-78.7196108, 52.865085699],
  [-78.7211335, 52.864763899],
  [-78.7152136, 52.867710299],
  [-78.71029, 52.868759999],
  [-78.7163765, 52.865957299],
  [-78.71597, 52.865626299],
  [-78.71017, 52.868329999],
  [-78.70905, 52.868240099],
  [-78.7094301, 52.868999999],
  [-78.7068601, 52.869470099],
  [-78.70705, 52.869989999],
  [-78.7040099, 52.871460099],
  [-78.7018044, 52.874404899],
  [-78.7019227, 52.876851899],
  [-78.7024784, 52.877393799],
  [-78.7031438, 52.874954299],
  [-78.7041199, 52.873509999],
  [-78.70496, 52.873529999],
  [-78.7041199, 52.873259899],
  [-78.70416, 52.872489899],
  [-78.70541, 52.873020099],
  [-78.70747, 52.871360099],
  [-78.7067701, 52.871259899],
  [-78.70681, 52.870489899],
  [-78.70804, 52.871279999],
  [-78.7085, 52.870519999],
  [-78.7138347, 52.869073499],
  [-78.720316, 52.868575899],
  [-78.7221789, 52.866313199],
  [-78.7240623, 52.865431599],
  [-78.7317519, 52.863854599],
  [-78.7375888, 52.863848199],
  [-78.749162, 52.860185399],
  [-78.7499697, 52.861152799],
  [-78.7515861, 52.861342199],
  [-78.7553356, 52.860131199],
  [-78.757626, 52.860768799],
  [-78.757856, 52.859966799],
  [-78.7693794, 52.854126599],
  [-78.7723169, 52.854969099],
  [-78.7761119, 52.853225899],
  [-78.7768983, 52.853784299],
  [-78.7786025, 52.853036199],
  [-78.7794083, 52.854380099],
  [-78.7874697, 52.852655899],
  [-78.7914836, 52.852793999],
  [-78.7941904, 52.853569699],
  [-78.7974976, 52.852965699],
  [-78.8027798, 52.855834599],
  [-78.8029656, 52.856523999],
  [-78.8082158, 52.855430299],
  [-78.8086151, 52.854964499],
  [-78.8076014, 52.853681499],
  [-78.8130647, 52.854246899],
  [-78.8155392, 52.855885299],
  [-78.816615, 52.855567799],
  [-78.8165544, 52.854443399],
  [-78.8212596, 52.854043699],
  [-78.8217476, 52.854512399],
  [-78.8211628, 52.855343899],
  [-78.8232029, 52.856535099],
  [-78.8293649, 52.852253599],
  [-78.8312591, 52.851497499],
  [-78.8339132, 52.852004299],
  [-78.8290699, 52.856129899],
  [-78.827339, 52.856961799],
  [-78.8232936, 52.857508399],
  [-78.8220949, 52.859109799],
  [-78.8239234, 52.859731799],
  [-78.8271329, 52.859000999],
  [-78.8278719, 52.860159999],
  [-78.8306409, 52.856838599],
  [-78.8334825, 52.855926499],
  [-78.8357089, 52.856115599],
  [-78.8391601, 52.857469899],
  [-78.8396382, 52.858315199],
  [-78.8386337, 52.860025599],
  [-78.8394299, 52.860680099],
  [-78.83058, 52.862649999],
  [-78.8305299, 52.865130099],
  [-78.83157, 52.865399999],
  [-78.8314011, 52.866526899],
  [-78.8356376, 52.866338299],
  [-78.8414695, 52.864920999],
  [-78.8406209, 52.866550999],
  [-78.83912, 52.866819899],
  [-78.8379717, 52.867968899],
  [-78.8384981, 52.868351999],
  [-78.8373506, 52.870693899],
  [-78.8374555, 52.872308999],
  [-78.8389903, 52.872819199],
  [-78.8415994, 52.872768199],
  [-78.8428164, 52.871869499],
  [-78.843953, 52.867561399],
  [-78.8488333, 52.865817999],
  [-78.8485733, 52.866570499],
  [-78.8522988, 52.864836399],
  [-78.8520937, 52.861821899],
  [-78.8557779, 52.858000499],
  [-78.8570091, 52.858469099],
  [-78.8525071, 52.862003899],
  [-78.8526668, 52.863714899],
  [-78.8534776, 52.863294399],
  [-78.8543219, 52.863864199],
  [-78.8535885, 52.864622199],
  [-78.8555939, 52.863548399],
  [-78.8558055, 52.864433499],
  [-78.857014, 52.863559799],
  [-78.8559825, 52.862819899],
  [-78.8576771, 52.861620099],
  [-78.8610846, 52.860030699],
  [-78.8620816, 52.860153999],
  [-78.8676274, 52.857078699],
  [-78.8680318, 52.857056299],
  [-78.8677189, 52.857828099],
  [-78.869273, 52.858039199],
  [-78.86915, 52.858909899],
  [-78.8702442, 52.859290699],
  [-78.8738252, 52.858830699],
  [-78.8721639, 52.857718999],
  [-78.8745693, 52.855457899],
  [-78.8766341, 52.855250799],
  [-78.8785706, 52.857539099],
  [-78.8785225, 52.858557599],
  [-78.8773224, 52.859889199],
  [-78.8761787, 52.860208299],
  [-78.8729582, 52.858960999],
  [-78.8688822, 52.861666599],
  [-78.8727619, 52.861106799],
  [-78.8723023, 52.861912599],
  [-78.8728521, 52.862279199],
  [-78.8762103, 52.861679499],
  [-78.8826785, 52.859032599],
  [-78.8825827, 52.859732899],
  [-78.8809098, 52.860552299],
  [-78.8809987, 52.861185899],
  [-78.8787208, 52.862673999],
  [-78.8791905, 52.862805499],
  [-78.8747915, 52.865324599],
  [-78.8738691, 52.865680399],
  [-78.8737068, 52.864729299],
  [-78.8707385, 52.865954199],
  [-78.8704409, 52.865362799],
  [-78.8691669, 52.865948699],
  [-78.8688151, 52.867221399],
  [-78.8673838, 52.868443699],
  [-78.865701, 52.868682799],
  [-78.8658601, 52.869869999],
  [-78.8624315, 52.873597599],
  [-78.8653964, 52.872882199],
  [-78.8675615, 52.873399699],
  [-78.8776548, 52.870349499],
  [-78.8738204, 52.872314499],
  [-78.8737619, 52.873319899],
  [-78.8745213, 52.872945199],
  [-78.8715424, 52.874591999],
  [-78.8689755, 52.876711499],
  [-78.8684846, 52.877844099],
  [-78.8695029, 52.878979699],
  [-78.8707401, 52.878409999],
  [-78.8705957, 52.880203599],
  [-78.8724013, 52.880623399],
  [-78.876053, 52.878482599],
  [-78.8796038, 52.874697499],
  [-78.8803464, 52.876864299],
  [-78.8820291, 52.876348299],
  [-78.87717, 52.881089999],
  [-78.8756036, 52.884185399],
  [-78.8793088, 52.882419399],
  [-78.8810434, 52.882617299],
  [-78.8811484, 52.881864199],
  [-78.8846498, 52.881107499],
  [-78.8850071, 52.881606299],
  [-78.8823372, 52.883324699],
  [-78.88402, 52.884030099],
  [-78.8832757, 52.885584899],
  [-78.8845346, 52.884902399],
  [-78.8861476, 52.885326599],
  [-78.8890331, 52.884705499],
  [-78.8921093, 52.882979699],
  [-78.8945917, 52.882876699],
  [-78.8898709, 52.886169199],
  [-78.8885699, 52.886159899],
  [-78.8770442, 52.899364499],
  [-78.8790074, 52.898645299],
  [-78.87929, 52.897697699],
  [-78.8821049, 52.896104299],
  [-78.8836018, 52.896137599],
  [-78.8823934, 52.897918799],
  [-78.8787746, 52.900701299],
  [-78.88191, 52.901159899],
  [-78.88039, 52.906260099],
  [-78.8808647, 52.907022899],
  [-78.8836771, 52.907761899],
  [-78.88506, 52.906099999],
  [-78.8847626, 52.907787599],
  [-78.8891402, 52.904609999],
  [-78.8894065, 52.905709599],
  [-78.891831, 52.906558799],
  [-78.8946191, 52.905280599],
  [-78.8956914, 52.905610899],
  [-78.8967852, 52.905074099],
  [-78.897127, 52.907112999],
  [-78.8978259, 52.907297699],
  [-78.9066535, 52.902726399],
  [-78.9081747, 52.903121699],
  [-78.9081436, 52.903759399],
  [-78.9090809, 52.903539999],
  [-78.9084581, 52.904772199],
  [-78.9116238, 52.904726399],
  [-78.9096099, 52.907431499],
  [-78.9104504, 52.907864699],
  [-78.9118379, 52.907087099],
  [-78.910915, 52.908378199],
  [-78.91163, 52.908849899],
  [-78.9095039, 52.909044699],
  [-78.9098983, 52.909522899],
  [-78.9065, 52.913899999],
  [-78.9123596, 52.914679899],
  [-78.9151299, 52.912959599],
  [-78.9167827, 52.913494799],
  [-78.9188496, 52.913230599],
  [-78.9186446, 52.912165999],
  [-78.9201816, 52.911521399],
  [-78.9190279, 52.912195499],
  [-78.9195565, 52.913568799],
  [-78.9175344, 52.913973899],
  [-78.9161673, 52.915128499],
  [-78.91607, 52.913549999],
  [-78.9145239, 52.913552999],
  [-78.910982, 52.916831099],
  [-78.9090044, 52.917145799],
  [-78.90914, 52.916629899],
  [-78.9081389, 52.916979299],
  [-78.9079874, 52.916521099],
  [-78.9018751, 52.919331599],
  [-78.895979, 52.919639799],
  [-78.8937217, 52.920608499],
  [-78.8893882, 52.924488299],
  [-78.88537, 52.925820099],
  [-78.8807737, 52.929292299],
  [-78.8746727, 52.930459299],
  [-78.8716499, 52.929040099],
  [-78.8678068, 52.931293099],
  [-78.8696778, 52.931986599],
  [-78.87304, 52.939429999],
  [-78.8726135, 52.941647799],
  [-78.8703788, 52.942584499],
  [-78.8716162, 52.944259899],
  [-78.8693988, 52.946049099],
  [-78.8676573, 52.945558599],
  [-78.8671692, 52.944639599],
  [-78.8595964, 52.946709899],
  [-78.854485, 52.946901499],
  [-78.8527682, 52.947507699],
  [-78.84955, 52.947089899],
  [-78.8467609, 52.945788799],
  [-78.833815, 52.952379199],
  [-78.8304217, 52.953545599],
  [-78.8299445, 52.957070799],
  [-78.8341799, 52.957439999],
  [-78.83322, 52.959730099],
  [-78.8319301, 52.959960099],
  [-78.8319501, 52.959449999],
  [-78.8281899, 52.958489999],
  [-78.8218399, 52.958119999],
  [-78.81499, 52.959269999],
  [-78.8087181, 52.958372199],
  [-78.8072889, 52.957192999],
  [-78.8066586, 52.954371399],
  [-78.8019905, 52.952716899],
  [-78.80179, 52.951733699],
  [-78.8001, 52.951689999],
  [-78.7968192, 52.953774899],
  [-78.7926686, 52.954741399],
  [-78.7834208, 52.955127699],
  [-78.783544, 52.956731099],
  [-78.7818574, 52.956973199],
  [-78.7854748, 52.957612099],
  [-78.7835342, 52.958004199],
  [-78.7836099, 52.958689999],
  [-78.7880076, 52.959381999],
  [-78.7862315, 52.962611599],
  [-78.779556, 52.965722899],
  [-78.7816345, 52.966398499],
  [-78.7868516, 52.966645599],
  [-78.79943, 52.964879899],
  [-78.80174, 52.965992399],
  [-78.8130804, 52.966534799],
  [-78.8229122, 52.964542499],
  [-78.8263442, 52.965317999],
  [-78.8304149, 52.963960099],
  [-78.8348035, 52.964214399],
  [-78.83479, 52.966542699],
  [-78.8319231, 52.968618599],
  [-78.8340101, 52.969652699],
  [-78.8387704, 52.966889699],
  [-78.8413882, 52.967161099],
  [-78.8389206, 52.970172099],
  [-78.8402902, 52.972392799],
  [-78.8402792, 52.974503299],
  [-78.83792, 52.975960099],
  [-78.832011, 52.977371199],
  [-78.8292206, 52.975909099],
  [-78.8277101, 52.976549899],
  [-78.8161792, 52.977834299],
  [-78.8152533, 52.979251899],
  [-78.8163025, 52.983096099],
  [-78.8187824, 52.984907499],
  [-78.8208101, 52.985096999],
  [-78.8201993, 52.985479799],
  [-78.8161818, 52.986619699],
  [-78.8103094, 52.986949499],
  [-78.8035969, 52.984420499],
  [-78.8009419, 52.984515899],
  [-78.7971558, 52.986183599],
  [-78.7981443, 52.985879099],
  [-78.797743, 52.986387199],
  [-78.7933378, 52.986901299],
  [-78.7944942, 52.987277499],
  [-78.7980522, 52.986793499],
  [-78.7991976, 52.985726799],
  [-78.8029868, 52.985011799],
  [-78.8108133, 52.987528799],
  [-78.8205298, 52.987272699],
  [-78.82298, 52.986070099],
  [-78.8302395, 52.987226299],
  [-78.8346881, 52.986639899],
  [-78.8385323, 52.984993799],
  [-78.8449179, 52.980492599],
  [-78.8459682, 52.980820899],
  [-78.8485692, 52.979824099],
  [-78.8493694, 52.979922799],
  [-78.842181, 52.986389799],
  [-78.8430901, 52.986954099],
  [-78.8462474, 52.986295999],
  [-78.8489596, 52.987518599],
  [-78.8525919, 52.986349099],
  [-78.85222, 52.987239999],
  [-78.8530317, 52.987546299],
  [-78.8562625, 52.986824099],
  [-78.8561776, 52.988557699],
  [-78.8524299, 52.991599999],
  [-78.8486258, 52.992883699],
  [-78.8403651, 52.992137599],
  [-78.8383947, 52.991296999],
  [-78.8288553, 52.994062999],
  [-78.8236284, 52.993631199],
  [-78.8220492, 52.992612899],
  [-78.8196647, 52.992252499],
  [-78.818937, 52.993350999],
  [-78.8141418, 52.995954199],
  [-78.8071544, 52.996425599],
  [-78.8022641, 52.998015499],
  [-78.8022032, 53.000096899],
  [-78.80069, 53.001881999],
  [-78.7940806, 53.004042699],
  [-78.7978598, 53.003562999],
  [-78.8014194, 53.001998999],
  [-78.7982852, 53.003601899],
  [-78.8009639, 53.002790299],
  [-78.8017483, 53.002088199],
  [-78.801455, 53.001593399],
  [-78.8047351, 52.999246099],
  [-78.8064159, 52.999050399],
  [-78.8073752, 52.997405699],
  [-78.8092101, 52.998154199],
  [-78.8156279, 52.998117099],
  [-78.8164955, 52.999093799],
  [-78.8194323, 53.000156799],
  [-78.8226728, 53.000303999],
  [-78.8318412, 52.997273799],
  [-78.835841, 52.998074699],
  [-78.8394022, 52.996965899],
  [-78.8431457, 52.997158399],
  [-78.8464424, 52.995592999],
  [-78.8454436, 52.997464499],
  [-78.84048, 53.001379999],
  [-78.8428757, 53.002186799],
  [-78.8432703, 53.003057799],
  [-78.8457483, 53.003578099],
  [-78.84582, 53.005059999],
  [-78.8478599, 53.006760099],
  [-78.8520799, 53.007229899],
  [-78.85116, 53.008739999],
  [-78.84436, 53.012980099],
  [-78.8444256, 53.014315199],
  [-78.8458, 53.016760099],
  [-78.8492041, 53.017536699],
  [-78.8520875, 53.015909899],
  [-78.8547609, 53.015968099],
  [-78.8572578, 53.014185599],
  [-78.867962, 53.010708999],
  [-78.8688098, 53.009179099],
  [-78.8725985, 53.007068199],
  [-78.8729207, 53.006476099],
  [-78.8723606, 53.005789899],
  [-78.8738326, 53.004074699],
  [-78.8818578, 53.001504999],
  [-78.8877587, 53.001840799],
  [-78.8881664, 53.001337199],
  [-78.8877158, 53.000639899],
  [-78.8911275, 52.998651199],
  [-78.8890032, 52.997256399],
  [-78.8863854, 52.997243499],
  [-78.87402, 53.003734099],
  [-78.8670899, 53.004289999],
  [-78.86489, 53.005919999],
  [-78.8617861, 53.003890899],
  [-78.8613485, 53.002681399],
  [-78.8573525, 53.001763399],
  [-78.856094, 53.000464199],
  [-78.856259, 52.999728899],
  [-78.8587576, 52.998138699],
  [-78.8609536, 52.997606699],
  [-78.8629024, 52.998122199],
  [-78.864925, 52.997497299],
  [-78.8687016, 52.998117199],
  [-78.8750959, 52.997975099],
  [-78.8768984, 52.997736099],
  [-78.8802028, 52.995417999],
  [-78.8792801, 52.994643099],
  [-78.8774777, 52.994591399],
  [-78.8706756, 52.995482599],
  [-78.8706587, 52.996114999],
  [-78.8693131, 52.996644899],
  [-78.8657725, 52.997348699],
  [-78.8641096, 52.997213199],
  [-78.8634996, 52.997020499],
  [-78.8660944, 52.994947099],
  [-78.8679486, 52.994515799],
  [-78.8679067, 52.993074199],
  [-78.8639884, 52.991615199],
  [-78.8608605, 52.992804899],
  [-78.8612498, 52.991370899],
  [-78.8580864, 52.990476599],
  [-78.8578829, 52.989964499],
  [-78.8585035, 52.989580699],
  [-78.8674044, 52.987328099],
  [-78.8690993, 52.985369399],
  [-78.8684069, 52.983049599],
  [-78.8706543, 52.981930799],
  [-78.872263, 52.983060199],
  [-78.876771, 52.981090999],
  [-78.8820944, 52.980853099],
  [-78.8870004, 52.985400699],
  [-78.8884138, 52.985518599],
  [-78.8904599, 52.986769899],
  [-78.8960988, 52.987194599],
  [-78.9022797, 52.985838599],
  [-78.9072195, 52.985523599],
  [-78.9125151, 52.983797099],
  [-78.9160672, 52.984888499],
  [-78.9214099, 52.985257399],
  [-78.9277245, 52.983119999],
  [-78.9343296, 52.981817999],
  [-78.931873, 52.984397599],
  [-78.9325487, 52.984550199],
  [-78.9329571, 52.985722699],
  [-78.9314948, 52.984792399],
  [-78.9274227, 52.986616699],
  [-78.9261576, 52.986371999],
  [-78.9250584, 52.986976999],
  [-78.9253256, 52.987968299],
  [-78.9190126, 52.991379199],
  [-78.9184846, 52.992708799],
  [-78.9143682, 52.994296099],
  [-78.9118119, 52.994388199],
  [-78.9098528, 52.995494699],
  [-78.9100621, 52.996343299],
  [-78.9118084, 52.996426599],
  [-78.9158179, 52.994176099],
  [-78.9245276, 52.991972299],
  [-78.9341862, 52.991743399],
  [-78.9383156, 52.992790799],
  [-78.9386459, 52.993475999],
  [-78.9416636, 52.993545199],
  [-78.9476633, 52.990764399],
  [-78.9522553, 52.990477199],
  [-78.9555941, 52.991606199],
  [-78.9551685, 52.992260899],
  [-78.9477175, 52.992792999],
  [-78.9465428, 52.993487999],
  [-78.9451585, 52.995359099],
  [-78.9453853, 52.996534799],
  [-78.94428, 52.998090099],
  [-78.9444838, 52.998651699],
  [-78.9475543, 52.998044499],
  [-78.9489112, 52.996492099],
  [-78.9506004, 52.996109399],
  [-78.9508395, 52.995396799],
  [-78.9542394, 52.994313299],
  [-78.956406, 52.994048899],
  [-78.9574146, 52.995055799],
  [-78.957291, 52.996072999],
  [-78.9563788, 52.995874999],
  [-78.9539981, 52.997344899],
  [-78.9531484, 52.996989799],
  [-78.9521399, 52.997980099],
  [-78.9534695, 52.998034699],
  [-78.9550681, 52.997008399],
  [-78.9549501, 52.998233499],
  [-78.9576019, 52.998515299],
  [-78.9614803, 52.996110699],
  [-78.9630752, 52.996694399],
  [-78.9658668, 52.995824499],
  [-78.9662483, 52.998066399],
  [-78.9681797, 52.997129499],
  [-78.9682517, 52.997565299],
  [-78.9668159, 52.998332699],
  [-78.967175, 52.998831699],
  [-78.9652867, 53.000390799],
  [-78.9662115, 53.000497099],
  [-78.9657646, 53.001641399],
  [-78.9664074, 53.002592499],
  [-78.9699001, 53.004169999],
  [-78.9762762, 53.003624499],
  [-78.9764352, 53.005797099],
  [-78.9776866, 53.006808499],
  [-78.9779546, 53.005311199],
  [-78.9793255, 53.006281399],
  [-78.97854, 53.010210099],
  [-78.98064, 53.010760099],
  [-78.9813466, 53.010224199],
  [-78.98277, 53.010669899],
  [-78.9846937, 53.010204899],
  [-78.9852918, 53.011288899],
  [-78.9897101, 53.012189999],
  [-78.9902379, 53.012925899],
  [-78.9893868, 53.014733399],
  [-78.9945737, 53.013439199],
  [-78.9918023, 53.014925899],
  [-78.9930364, 53.014945499],
  [-78.9921819, 53.016999399],
  [-78.993462, 53.016357299],
  [-78.9943701, 53.016890099],
  [-78.9936074, 53.016963799],
  [-78.9942862, 53.017828899],
  [-78.9920261, 53.018095599],
  [-78.9925686, 53.018418399],
  [-78.9922788, 53.019345199],
  [-78.9886044, 53.020136299],
  [-78.9861815, 53.023619399],
  [-78.9867965, 53.024191499],
  [-78.9877645, 53.023409699],
  [-78.99068, 53.023229899],
  [-78.991718, 53.021450199],
  [-78.9921671, 53.022941599],
  [-78.993279, 53.023032099],
  [-78.9920868, 53.025224499],
  [-78.98901, 53.025738399],
  [-78.9892527, 53.026501299],
  [-78.9886688, 53.026876899],
  [-78.9852038, 53.026524399],
  [-78.9860441, 53.025626699],
  [-78.9855841, 53.025089699],
  [-78.9836587, 53.025504899],
  [-78.9840787, 53.026251899],
  [-78.9797767, 53.026534099],
  [-78.9770387, 53.025995999],
  [-78.9691767, 53.027032299],
  [-78.9620499, 53.026459899],
  [-78.9590499, 53.027049999],
  [-78.9555, 53.030320099],
  [-78.95465, 53.030289999],
  [-78.9545999, 53.031330099],
  [-78.9554501, 53.031340099],
  [-78.95539, 53.032619999],
  [-78.95709, 53.032649999],
  [-78.9574799, 53.033550099],
  [-78.9668001, 53.034100099],
  [-78.96677, 53.034609999],
  [-78.9689101, 53.034389999],
  [-78.96888, 53.034900099],
  [-78.97056, 53.035319999],
  [-78.97867, 53.034560099],
  [-78.98241, 53.036539999],
  [-78.9853701, 53.036839999],
  [-78.98886, 53.034859999],
  [-78.9904685, 53.035287899],
  [-78.9912704, 53.034822399],
  [-78.9899016, 53.035734499],
  [-78.9904999, 53.036289999],
  [-78.9895901, 53.037560099],
  [-78.9887399, 53.037550099],
  [-78.98919, 53.037039899],
  [-78.98835, 53.037029899],
  [-78.98901, 53.036259899],
  [-78.9879599, 53.036000099],
  [-78.98488, 53.038249899],
  [-78.97207, 53.039440099],
  [-78.96911, 53.039010099],
  [-78.9651479, 53.041096899],
  [-78.96384, 53.042759999],
  [-78.9597653, 53.043974399],
  [-78.9601478, 53.043031799],
  [-78.9595844, 53.042682299],
  [-78.9566112, 53.043642199],
  [-78.9564412, 53.043110699],
  [-78.9577014, 53.042121599],
  [-78.9604565, 53.041964899],
  [-78.9642777, 53.040276099],
  [-78.9635701, 53.039419999],
  [-78.957718, 53.039833999],
  [-78.9492921, 53.042015599],
  [-78.9489894, 53.042572099],
  [-78.9470408, 53.042312199],
  [-78.9475225, 53.041992099],
  [-78.9469738, 53.041368699],
  [-78.942762, 53.044868599],
  [-78.9427106, 53.045875399],
  [-78.9435235, 53.046029499],
  [-78.94368, 53.046766399],
  [-78.9349729, 53.050317099],
  [-78.9331687, 53.051622099],
  [-78.9315177, 53.055036399],
  [-78.9296382, 53.055720699],
  [-78.9268424, 53.058394699],
  [-78.927368, 53.058594699],
  [-78.9289964, 53.056811399],
  [-78.9309564, 53.056000699],
  [-78.9300956, 53.056861199],
  [-78.9314631, 53.057122599],
  [-78.9320098, 53.058830099],
  [-78.9306273, 53.061926999],
  [-78.9309162, 53.062578199],
  [-78.9360674, 53.065508399],
  [-78.9406447, 53.064631299],
  [-78.93775, 53.068189999],
  [-78.9395899, 53.069629999],
  [-78.9484699, 53.070939899],
  [-78.9543625, 53.069352199],
  [-78.9541117, 53.070038799],
  [-78.9555791, 53.070197799],
  [-78.9566505, 53.071545599],
  [-78.9589634, 53.071266299],
  [-78.9603097, 53.072008499],
  [-78.9600233, 53.072727199],
  [-78.9620301, 53.071949999],
  [-78.9622389, 53.071205799],
  [-78.9608079, 53.070775999],
  [-78.96067, 53.069350099],
  [-78.9618099, 53.067580099],
  [-78.961065, 53.067229099],
  [-78.9628872, 53.065234499],
  [-78.9640517, 53.066011199],
  [-78.9684373, 53.065023699],
  [-78.9724452, 53.062615999],
  [-78.9796037, 53.062554299],
  [-78.9794235, 53.062916499],
  [-78.9805976, 53.062745699],
  [-78.9809201, 53.064304499],
  [-78.98624, 53.063769999],
  [-78.9883797, 53.064324699],
  [-78.9882325, 53.065180099],
  [-78.9920653, 53.064657199],
  [-78.9898818, 53.067717599],
  [-78.9914057, 53.067687199],
  [-78.986695, 53.070590699],
  [-78.9909663, 53.071578399],
  [-78.9905627, 53.072339999],
  [-78.98657, 53.074920099],
  [-78.984, 53.075389899],
  [-78.98387, 53.074959999],
  [-78.97933, 53.075049899],
  [-78.97887, 53.075819899],
  [-78.9780201, 53.075800099],
  [-78.97798, 53.076570099],
  [-78.9758299, 53.077030099],
  [-78.97341, 53.078800099],
  [-78.9734999, 53.081489899],
  [-78.97067, 53.082849999],
  [-78.9708399, 53.083619899],
  [-78.9681599, 53.086389899],
  [-78.9662252, 53.087562199],
  [-78.9625263, 53.087990099],
  [-78.9593354, 53.089149199],
  [-78.9583272, 53.091101599],
  [-78.959987, 53.091789399],
  [-78.9655112, 53.089739799],
  [-78.9674353, 53.088275399],
  [-78.9673295, 53.089487599],
  [-78.9701486, 53.087900699],
  [-78.9696796, 53.087046899],
  [-78.9708733, 53.085010199],
  [-78.9745448, 53.084710099],
  [-78.9773465, 53.083412099],
  [-78.9715315, 53.088128599],
  [-78.9685439, 53.092026899],
  [-78.9716486, 53.091042399],
  [-78.9744913, 53.088665299],
  [-78.9765473, 53.089363099],
  [-78.9738077, 53.090136099],
  [-78.9637243, 53.098402999],
  [-78.9639546, 53.099297499],
  [-78.9676093, 53.096895299],
  [-78.9719953, 53.095051099],
  [-78.9738257, 53.095118099],
  [-78.9736134, 53.095761999],
  [-78.9760868, 53.093871399],
  [-78.9714769, 53.099106999],
  [-78.9721642, 53.099650199],
  [-78.97085, 53.101559999],
  [-78.96785, 53.102019999],
  [-78.9674601, 53.101239999],
  [-78.96744, 53.101759999],
  [-78.9657199, 53.101979999],
  [-78.96578, 53.100959899],
  [-78.9649201, 53.100949899],
  [-78.9627199, 53.102700099],
  [-78.96143, 53.102679999],
  [-78.95918, 53.105199999],
  [-78.9565701, 53.106439999],
  [-78.95632, 53.107199999],
  [-78.9569299, 53.107729899],
  [-78.9556701, 53.107449999],
  [-78.95163, 53.111739899],
  [-78.9509088, 53.111552699],
  [-78.95033, 53.112229999],
  [-78.953782, 53.112201599],
  [-78.9544169, 53.113570999],
  [-78.9593287, 53.114047599],
  [-78.9631537, 53.111332599],
  [-78.9638226, 53.109814999],
  [-78.9671037, 53.107099399],
  [-78.9678365, 53.106850199],
  [-78.9669138, 53.108579899],
  [-78.9677759, 53.109270199],
  [-78.9743578, 53.105898999],
  [-78.9681698, 53.111797299],
  [-78.9623617, 53.114147999],
  [-78.9625211, 53.113728099],
  [-78.9609091, 53.114008399],
  [-78.96042, 53.114959899],
  [-78.9591401, 53.115199999],
  [-78.95866, 53.116199999],
  [-78.95997, 53.115590099],
  [-78.9623799, 53.115639899],
  [-78.9616801, 53.115500099],
  [-78.96255, 53.114999999],
  [-78.96295, 53.115639899],
  [-78.9626901, 53.116539999],
  [-78.9609299, 53.117789999],
  [-78.96109, 53.118820099],
  [-78.95854, 53.118769999],
  [-78.9589199, 53.119799999],
  [-78.9554899, 53.120260099],
  [-78.95551, 53.119749899],
  [-78.95297, 53.119569999],
  [-78.94952, 53.120409899],
  [-78.9494699, 53.121439999],
  [-78.94862, 53.121419899],
  [-78.94898, 53.122710099],
  [-78.94983, 53.122720099],
  [-78.9501499, 53.125029999],
  [-78.9526999, 53.125209999],
  [-78.9540101, 53.124459999],
  [-78.9570101, 53.124129999],
  [-78.95697, 53.124899999],
  [-78.95996, 53.124820099],
  [-78.9604284, 53.123685399],
  [-78.9618035, 53.123141299],
  [-78.9614608, 53.124149699],
  [-78.9677541, 53.122627799],
  [-78.9687854, 53.123173399],
  [-78.973355, 53.122392199],
  [-78.9733222, 53.123065699],
  [-78.9740187, 53.123108499],
  [-78.9759771, 53.122401599],
  [-78.9682543, 53.126755499],
  [-78.9656437, 53.126760499],
  [-78.9529001, 53.129950099],
  [-78.9500001, 53.132720099],
  [-78.9501301, 53.134390099],
  [-78.95099, 53.134270099],
  [-78.95096, 53.134789999],
  [-78.9501101, 53.134769999],
  [-78.9486505, 53.136521999],
  [-78.9450764, 53.138976599],
  [-78.943857, 53.139342899],
  [-78.9424375, 53.139114099],
  [-78.9431201, 53.138489999],
  [-78.94099, 53.138329999],
  [-78.93839, 53.139050099],
  [-78.9387056, 53.139800999],
  [-78.9368771, 53.140359299],
  [-78.93698, 53.141969899],
  [-78.9380606, 53.142952799],
  [-78.9392165, 53.143109499],
  [-78.93953, 53.142280099],
  [-78.9407445, 53.143021699],
  [-78.94164, 53.142699999],
  [-78.9441277, 53.143951899],
  [-78.944002, 53.144585499],
  [-78.9403359, 53.147523799],
  [-78.9349901, 53.148099899],
  [-78.9342499, 53.148759899],
  [-78.9402482, 53.148305399],
  [-78.944342, 53.146088199],
  [-78.945415, 53.146393799],
  [-78.9446245, 53.146876699],
  [-78.9476958, 53.146531199],
  [-78.9497379, 53.145545799],
  [-78.9487205, 53.146645699],
  [-78.9499678, 53.146355499],
  [-78.94972, 53.147579999],
  [-78.9503501, 53.147709999],
  [-78.9524721, 53.147257199],
  [-78.9550096, 53.145278599],
  [-78.9560469, 53.145237899],
  [-78.9551214, 53.145365499],
  [-78.95076, 53.148907899],
  [-78.9505479, 53.150087299],
  [-78.9493546, 53.151301199],
  [-78.9500544, 53.151530499],
  [-78.9498366, 53.152001999],
  [-78.9492111, 53.151443999],
  [-78.9459236, 53.153976199],
  [-78.9435579, 53.153885999],
  [-78.9441287, 53.154408699],
  [-78.9499933, 53.153560099],
  [-78.950131, 53.155004299],
  [-78.9442306, 53.158524199],
  [-78.9443565, 53.157998199],
  [-78.9313756, 53.163827399],
  [-78.9336186, 53.163292699],
  [-78.9356802, 53.163830199],
  [-78.9358199, 53.166300099],
  [-78.9404601, 53.167400099],
  [-78.9439393, 53.168861299],
  [-78.9447805, 53.169807099],
  [-78.9492499, 53.170759899],
  [-78.9582973, 53.171258699],
  [-78.9594109, 53.172292699],
  [-78.9587537, 53.173048699],
  [-78.9594445, 53.173037099],
  [-78.9583547, 53.174168599],
  [-78.9599242, 53.173184799],
  [-78.9595428, 53.174543899],
  [-78.9599806, 53.174814399],
  [-78.966719, 53.171942499],
  [-78.9730396, 53.170141399],
  [-78.9713899, 53.171520099],
  [-78.97054, 53.171509999],
  [-78.9686901, 53.174549899],
  [-78.9672052, 53.174808499],
  [-78.9670035, 53.175526999],
  [-78.9583893, 53.177408499],
  [-78.9599909, 53.176085699],
  [-78.9565601, 53.178439899],
  [-78.9559073, 53.180045799],
  [-78.9573165, 53.180819999],
  [-78.961519, 53.178921399],
  [-78.9612979, 53.180001499],
  [-78.9590059, 53.180753099],
  [-78.9582906, 53.181737599],
  [-78.9607888, 53.180723799],
  [-78.9604636, 53.181592099],
  [-78.9615012, 53.181800499],
  [-78.9670224, 53.179613699],
  [-78.9607387, 53.185251899],
  [-78.9580557, 53.185014399],
  [-78.95531, 53.186879899],
  [-78.9526859, 53.187086399],
  [-78.9498493, 53.188657199],
  [-78.9503437, 53.187617099],
  [-78.9557901, 53.185599999],
  [-78.9583375, 53.183897999],
  [-78.9519235, 53.184626499],
  [-78.9524272, 53.184979799],
  [-78.9520872, 53.185379299],
  [-78.9464854, 53.188366399],
  [-78.9411233, 53.189817699],
  [-78.9381844, 53.189688699],
  [-78.9382028, 53.189285099],
  [-78.9293229, 53.190304999],
  [-78.92719, 53.192264699],
  [-78.9241272, 53.193269599],
  [-78.9222491, 53.193488599],
  [-78.9214909, 53.192822899],
  [-78.9109901, 53.194559899],
  [-78.91097, 53.195079999],
  [-78.9096799, 53.195310099],
  [-78.90919, 53.196589999],
  [-78.9083399, 53.196569899],
  [-78.90829, 53.197589999],
  [-78.9074301, 53.197579999],
  [-78.90674, 53.198589999],
  [-78.90748, 53.200909899],
  [-78.9059901, 53.205239899],
  [-78.90786, 53.206299999],
  [-78.9163499, 53.207339899],
  [-78.9172201, 53.206839999],
  [-78.9193499, 53.207009899],
  [-78.91931, 53.207780099],
  [-78.9214699, 53.207299999],
  [-78.9214501, 53.207809999],
  [-78.92441, 53.208249899],
  [-78.926856, 53.207685799],
  [-78.9265552, 53.206354499],
  [-78.928147, 53.206728799],
  [-78.9317417, 53.205084199],
  [-78.9317761, 53.205827599],
  [-78.933027, 53.205865199],
  [-78.9336285, 53.207077599],
  [-78.9346977, 53.207421099],
  [-78.9306278, 53.207658599],
  [-78.92907, 53.208969999],
  [-78.9364838, 53.209251299],
  [-78.9425408, 53.207634399],
  [-78.9379392, 53.211578099],
  [-78.9333653, 53.213039899],
  [-78.92975, 53.212569899],
  [-78.9270299, 53.215849999],
  [-78.92618, 53.215839999],
  [-78.92609, 53.217629999],
  [-78.9273699, 53.217780099],
  [-78.92894, 53.220759999],
  [-78.9263499, 53.221220099],
  [-78.92596, 53.220450099],
  [-78.92341, 53.220149999],
  [-78.92261, 53.219110099],
  [-78.9160748, 53.218269999],
  [-78.912296, 53.218830899],
  [-78.9097017, 53.216623799],
  [-78.910577, 53.219092799],
  [-78.9092307, 53.219936599],
  [-78.9088061, 53.219405899],
  [-78.9067221, 53.219287399],
  [-78.9048239, 53.221268499],
  [-78.9113221, 53.220620699],
  [-78.9003912, 53.223643899],
  [-78.9063198, 53.223318099],
  [-78.9079972, 53.224212099],
  [-78.9082175, 53.225936799],
  [-78.9068167, 53.227649199],
  [-78.9086887, 53.227833299],
  [-78.9130668, 53.230437299],
  [-78.9102432, 53.232431799],
  [-78.9117301, 53.234264699],
  [-78.913475, 53.234944799],
  [-78.92072, 53.231629999],
  [-78.92709, 53.232379999],
  [-78.92938, 53.233829999],
  [-78.9304699, 53.237440099],
  [-78.9337527, 53.237489799],
  [-78.9350092, 53.238509499],
  [-78.9372461, 53.238049799],
  [-78.9366727, 53.238658599],
  [-78.9377704, 53.238230799],
  [-78.9382185, 53.238460199],
  [-78.9374758, 53.239121799],
  [-78.9380923, 53.239359499],
  [-78.9433198, 53.237538499],
  [-78.944824, 53.236310699],
  [-78.9461755, 53.237042799],
  [-78.9496189, 53.235372599],
  [-78.948799, 53.236701999],
  [-78.9499292, 53.237089999],
  [-78.948542, 53.237044199],
  [-78.9496975, 53.238414799],
  [-78.9466944, 53.238094799],
  [-78.9404555, 53.243231199],
  [-78.9403519, 53.242495299],
  [-78.9367862, 53.243118899],
  [-78.9366165, 53.243946399],
  [-78.9341558, 53.243690499],
  [-78.9321571, 53.244215399],
  [-78.9293834, 53.247835599],
  [-78.924172, 53.249899299],
  [-78.9174803, 53.254721199],
  [-78.9150921, 53.257570599],
  [-78.9109889, 53.258992399],
  [-78.9113679, 53.259877499],
  [-78.9088874, 53.260918499],
  [-78.9154301, 53.261389899],
  [-78.9206079, 53.259531599],
  [-78.92105, 53.260079999],
  [-78.91919, 53.263130099],
  [-78.92004, 53.263140099],
  [-78.92085, 53.264179999],
  [-78.9239749, 53.263180699],
  [-78.9231985, 53.264058999],
  [-78.9242031, 53.264320699],
  [-78.927739, 53.263193599],
  [-78.9278117, 53.263735999],
  [-78.9292744, 53.263868399],
  [-78.9332946, 53.263058699],
  [-78.9341528, 53.264248699],
  [-78.9377084, 53.263583999],
  [-78.9371851, 53.264222299],
  [-78.9403255, 53.263965299],
  [-78.941475, 53.263054099],
  [-78.9420854, 53.264127899],
  [-78.9450235, 53.264200899],
  [-78.9422248, 53.266223999],
  [-78.949091, 53.263448099],
  [-78.9499084, 53.263329099],
  [-78.9504055, 53.263968699],
  [-78.9527245, 53.263702899],
  [-78.9491095, 53.266113199],
  [-78.9454019, 53.267207999],
  [-78.9459872, 53.266561099],
  [-78.945216, 53.265925199],
  [-78.9406405, 53.267942999],
  [-78.941839, 53.266317099],
  [-78.9338684, 53.267936699],
  [-78.9304011, 53.267639199],
  [-78.9265291, 53.268762899],
  [-78.9222398, 53.271087999],
  [-78.9251282, 53.270789399],
  [-78.9283634, 53.271946599],
  [-78.9282786, 53.272765799],
  [-78.9332291, 53.272708499],
  [-78.9346956, 53.274550599],
  [-78.9338479, 53.275137999],
  [-78.9391599, 53.273275299],
  [-78.9426625, 53.273394199],
  [-78.9476881, 53.271868899],
  [-78.9484438, 53.273337199],
  [-78.9503604, 53.272956499],
  [-78.9500196, 53.273310099],
  [-78.9518787, 53.274184599],
  [-78.9560881, 53.272689799],
  [-78.9534166, 53.275903899],
  [-78.9535801, 53.276789999],
  [-78.9586999, 53.276999999],
  [-78.9649654, 53.275738699],
  [-78.9665287, 53.277130199],
  [-78.970194, 53.276819399],
  [-78.9701434, 53.277801099],
  [-78.9726566, 53.276641399],
  [-78.9722932, 53.276314499],
  [-78.9736127, 53.275461199],
  [-78.9724499, 53.277460699],
  [-78.9738554, 53.277164799],
  [-78.9746749, 53.276023299],
  [-78.9772146, 53.275887499],
  [-78.9794348, 53.274587499],
  [-78.9795664, 53.275224299],
  [-78.9812866, 53.275174599],
  [-78.9832181, 53.274420599],
  [-78.9835913, 53.273878499],
  [-78.9830903, 53.273537499],
  [-78.9837275, 53.273080899],
  [-78.987054, 53.272926099],
  [-78.9843583, 53.273845199],
  [-78.9842428, 53.275062799],
  [-78.98255, 53.275984599],
  [-78.9770615, 53.277435299],
  [-78.9741649, 53.277514499],
  [-78.9700188, 53.281420399],
  [-78.9635479, 53.282336599],
  [-78.9633547, 53.282750499],
  [-78.9642582, 53.283087599],
  [-78.9637199, 53.283879999],
  [-78.9652756, 53.284954999],
  [-78.9665229, 53.284403099],
  [-78.966703, 53.285778399],
  [-78.9680447, 53.286178699],
  [-78.9658199, 53.289050099],
  [-78.9599484, 53.292128999],
  [-78.9563059, 53.293029499],
  [-78.9520319, 53.292891099],
  [-78.947778, 53.294449399],
  [-78.9469986, 53.296397499],
  [-78.9480611, 53.297901699],
  [-78.952096, 53.297506799],
  [-78.9490101, 53.300789999],
  [-78.9484862, 53.302418899],
  [-78.9489858, 53.302467399],
  [-78.9523699, 53.301879999],
  [-78.9599213, 53.297363699],
  [-78.9593735, 53.299651299],
  [-78.9565924, 53.302012599],
  [-78.958463, 53.304068499],
  [-78.9582791, 53.304958899],
  [-78.9554915, 53.306857199],
  [-78.9591093, 53.304637999],
  [-78.9622373, 53.304647599],
  [-78.957805, 53.307672599],
  [-78.9567548, 53.308012899],
  [-78.9587108, 53.306593499],
  [-78.9551858, 53.307997699],
  [-78.9546575, 53.307325499],
  [-78.952962, 53.308988699],
  [-78.95242, 53.309950099],
  [-78.9545801, 53.309600099],
  [-78.9554703, 53.310563099],
  [-78.9540399, 53.311909999],
  [-78.9579295, 53.311758799],
  [-78.9609872, 53.310132199],
  [-78.9613582, 53.310697999],
  [-78.95564, 53.314239999],
  [-78.9494017, 53.320657399],
  [-78.9573118, 53.320425799],
  [-78.9558692, 53.322196699],
  [-78.958572, 53.322324699],
  [-78.959809, 53.322905299],
  [-78.9599479, 53.323601999],
  [-78.960339, 53.322933999],
  [-78.9637553, 53.321868899],
  [-78.9627121, 53.323142399],
  [-78.968428, 53.321164099],
  [-78.9682608, 53.322262799],
  [-78.9696203, 53.322340099],
  [-78.9775324, 53.322477499],
  [-78.9811308, 53.321663499],
  [-78.981037, 53.322952399],
  [-78.9879255, 53.321421799],
  [-78.98822, 53.321959899],
  [-78.9865244, 53.323129499],
  [-78.9880166, 53.323575099],
  [-78.9868299, 53.324239999],
  [-78.9802266, 53.325109399],
  [-78.9737269, 53.327584199],
  [-78.9736879, 53.328358999],
  [-78.9749669, 53.328531299],
  [-78.9776368, 53.327420299],
  [-78.9727333, 53.331746399],
  [-78.9718029, 53.334031399],
  [-78.9725492, 53.335723599],
  [-78.9706204, 53.338922299],
  [-78.9621704, 53.342688699],
  [-78.9608599, 53.344097599],
  [-78.963867, 53.345529499],
  [-78.96786, 53.345375799],
  [-78.9715444, 53.344023099],
  [-78.9736285, 53.344344199],
  [-78.9760967, 53.343422399],
  [-78.9827492, 53.343536599],
  [-78.9837981, 53.344189299],
  [-78.9828959, 53.345201799],
  [-78.9835175, 53.345258099],
  [-78.9811788, 53.347132699],
  [-78.9819402, 53.348231399],
  [-78.9783399, 53.350669899],
  [-78.9784731, 53.351241399],
  [-78.9775519, 53.350759299],
  [-78.9703211, 53.351985599],
  [-78.9531424, 53.353427699],
  [-78.9493582, 53.354256499],
  [-78.9539302, 53.354169599],
  [-78.9511152, 53.354656599],
  [-78.9530906, 53.354642199],
  [-78.9607509, 53.353354399],
  [-78.9636099, 53.353569999],
  [-78.9626801, 53.355089899],
  [-78.9564397, 53.357869599],
  [-78.9503699, 53.361799999],
  [-78.958782, 53.363244599],
  [-78.9583132, 53.364498799],
  [-78.95528, 53.366499999],
  [-78.954538, 53.367734699],
  [-78.9582393, 53.366953099],
  [-78.9560756, 53.369271499],
  [-78.9589963, 53.368529199],
  [-78.9594066, 53.368929199],
  [-78.958315, 53.370310599],
  [-78.9535135, 53.372551599],
  [-78.9522129, 53.372677899],
  [-78.9523071, 53.371600899],
  [-78.9499114, 53.372638099],
  [-78.9498458, 53.373888999],
  [-78.9512408, 53.374182799],
  [-78.9487694, 53.376137999],
  [-78.9472165, 53.380446099],
  [-78.94518, 53.383660099],
  [-78.9471399, 53.384409999],
  [-78.95595, 53.383929999],
  [-78.95566, 53.385459899],
  [-78.95651, 53.385479999],
  [-78.9570299, 53.388049999],
  [-78.9563401, 53.389059999],
  [-78.95808, 53.388579899],
  [-78.9580299, 53.389599999],
  [-78.95717, 53.389589999],
  [-78.9566801, 53.390859999],
  [-78.95884, 53.390639999],
  [-78.95797, 53.390880099],
  [-78.95766, 53.392929999],
  [-78.95865, 53.394479999],
  [-78.9552, 53.395059999],
  [-78.9464317, 53.393609199],
  [-78.94196, 53.393939999],
  [-78.9276992, 53.396725699],
  [-78.9163654, 53.400225099],
  [-78.9114227, 53.399591699],
  [-78.9024224, 53.400715799],
  [-78.8957616, 53.402351899],
  [-78.9088554, 53.400566199],
  [-78.9181664, 53.400764799],
  [-78.9242333, 53.399000699],
  [-78.9249449, 53.399551499],
  [-78.9443861, 53.395528299],
  [-78.9531878, 53.396890099],
  [-78.9554601, 53.398529999],
  [-78.9558816, 53.400268599],
  [-78.948136, 53.403480699],
  [-78.9454623, 53.403918299],
  [-78.9371986, 53.402889399],
  [-78.9285699, 53.404979999],
  [-78.930355, 53.405308999],
  [-78.9371783, 53.404068499],
  [-78.9428296, 53.404961699],
  [-78.950305, 53.404048999],
  [-78.9623756, 53.399933599],
  [-78.9637307, 53.400587299],
  [-78.9698633, 53.399845299],
  [-78.974478, 53.398850399],
  [-78.9760025, 53.397713899],
  [-78.9782621, 53.397911499],
  [-78.9777983, 53.398463999],
  [-78.9786572, 53.398707799],
  [-78.979108, 53.398215599],
  [-78.9818374, 53.399970999],
  [-78.9832337, 53.399047099],
  [-78.982743, 53.399070099],
  [-78.9831144, 53.398381499],
  [-78.9833779, 53.399652899],
  [-78.98144, 53.402039999],
  [-78.9835901, 53.401949999],
  [-78.9852601, 53.403000099],
  [-78.9880094, 53.402399999],
  [-78.9866936, 53.404174299],
  [-78.9875652, 53.404747099],
  [-78.98687, 53.405210099],
  [-78.98611, 53.407759999],
  [-78.9875801, 53.408289999],
  [-78.9879201, 53.410100099],
  [-78.9850443, 53.411486399],
  [-78.9808811, 53.412182099],
  [-78.9799094, 53.411419199],
  [-78.9814675, 53.409254799],
  [-78.9814773, 53.406962299],
  [-78.97598, 53.408869999],
  [-78.9759998, 53.409422799],
  [-78.9742, 53.410120099],
  [-78.9734801, 53.411649999],
  [-78.9747277, 53.412515299],
  [-78.9744788, 53.413761999],
  [-78.9751741, 53.414509899],
  [-78.9773759, 53.415388999],
  [-78.976534, 53.417013799],
  [-78.98323, 53.418729999],
  [-78.98187, 53.420230099],
  [-78.98101, 53.420230099],
  [-78.98008, 53.421749999],
  [-78.98176, 53.422550099],
  [-78.9818101, 53.421519999],
  [-78.98267, 53.421539999],
  [-78.9826901, 53.421029899],
  [-78.98564, 53.422230099],
  [-78.9909201, 53.419239899],
  [-78.9935963, 53.419678499],
  [-78.9907379, 53.422726999],
  [-78.9912184, 53.424035199],
  [-78.9929595, 53.424000099],
  [-78.9928583, 53.425578399],
  [-78.9947364, 53.423190499],
  [-79.00277, 53.420843299],
  [-79.0102697, 53.421886899],
  [-79.0147128, 53.420195399],
  [-79.0156641, 53.421465799],
  [-79.0173366, 53.420953599],
  [-79.0209983, 53.421117699],
  [-79.0235804, 53.419266399],
  [-79.0247489, 53.419719399],
  [-79.0276405, 53.419278099],
  [-79.0284869, 53.418467099],
  [-79.0269716, 53.416976399],
  [-79.0288266, 53.415673299],
  [-79.0309799, 53.415680099],
  [-79.0322099, 53.416859999],
  [-79.03438, 53.416249899],
  [-79.03478, 53.416900099],
  [-79.0395101, 53.416589899],
  [-79.03957, 53.415309999],
  [-79.03827, 53.415550099],
  [-79.03812, 53.414259899],
  [-79.0414101, 53.412649999],
  [-79.04444, 53.411929999],
  [-79.0478799, 53.411859999],
  [-79.0478501, 53.412379999],
  [-79.05695, 53.410340099],
  [-79.0595201, 53.410509999],
  [-79.06261, 53.408770099],
  [-79.06262, 53.408259999],
  [-79.0647799, 53.407909899],
  [-79.0651801, 53.408560099],
  [-79.0638799, 53.408789899],
  [-79.0634101, 53.409809899],
  [-79.05993, 53.410780099],
  [-79.05902, 53.412039999],
  [-79.0564001, 53.413019899],
  [-79.05594, 53.413790099],
  [-79.04857, 53.415459899],
  [-79.0485499, 53.415969999],
  [-79.0450701, 53.416939999],
  [-79.04504, 53.417709999],
  [-79.0413099, 53.419440099],
  [-79.04011, 53.422240099],
  [-79.03881, 53.422469899],
  [-79.03833, 53.423749999],
  [-79.03747, 53.423739999],
  [-79.0369899, 53.424739999],
  [-79.0382801, 53.424639999],
  [-79.03867, 53.425550099],
  [-79.0399699, 53.425309999],
  [-79.03993, 53.426079999],
  [-79.0390799, 53.426049999],
  [-79.03927, 53.426579899],
  [-79.0354299, 53.430619999],
  [-79.02935, 53.432309999],
  [-79.02937, 53.431800099],
  [-79.02422, 53.431969999],
  [-79.02391, 53.429399999],
  [-79.0187499, 53.429689899],
  [-79.01224, 53.431259899],
  [-79.01221, 53.431769999],
  [-79.0100501, 53.432249899],
  [-79.01044, 53.433020099],
  [-79.0087101, 53.433249899],
  [-79.0087399, 53.432739999],
  [-79.0031501, 53.432910099],
  [-79.0015099, 53.431340099],
  [-78.9968399, 53.430230099],
  [-78.9963601, 53.431249899],
  [-78.9955099, 53.431230099],
  [-78.9947399, 53.433769999],
  [-78.99546, 53.436869999],
  [-78.9922601, 53.440919899],
  [-78.99435, 53.442230099],
  [-78.9945099, 53.443259899],
  [-78.99668, 53.442779999],
  [-78.99665, 53.443299999],
  [-78.99836, 53.443580099],
  [-78.99833, 53.444089999],
  [-79.00435, 53.443669999],
  [-79.0038301, 53.441369899],
  [-79.00667, 53.440259899],
  [-79.01743, 53.439539999],
  [-79.01736, 53.441079999],
  [-79.0129699, 53.443179999],
  [-79.00521, 53.443949999],
  [-79.01031, 53.445059999],
  [-79.0102801, 53.445580099],
  [-79.01366, 53.446779999],
  [-79.02179, 53.447299999],
  [-79.02177, 53.447810099],
  [-79.02391, 53.447849999],
  [-79.02393, 53.447339999],
  [-79.02566, 53.447109999],
  [-79.0257, 53.446340099],
  [-79.0244099, 53.446319999],
  [-79.0248699, 53.445690099],
  [-79.02615, 53.445709899],
  [-79.03203, 53.448879999],
  [-79.03501, 53.449189999],
  [-79.03538, 53.450350099],
  [-79.0377499, 53.450130099],
  [-79.03803, 53.448849999],
  [-79.0388799, 53.448869999],
  [-79.03893, 53.447839999],
  [-79.03979, 53.447859999],
  [-79.0398401, 53.446829899],
  [-79.04069, 53.446849999],
  [-79.043, 53.443299999],
  [-79.04472, 53.443319999],
  [-79.0439, 53.442539999],
  [-79.04691, 53.442340099],
  [-79.04693, 53.441819899],
  [-79.04734, 53.442340099],
  [-79.05035, 53.442140099],
  [-79.0508601, 53.440350099],
  [-79.05172, 53.440360099],
  [-79.05668, 53.435059999],
  [-79.0578401, 53.434659999],
  [-79.05758, 53.434309999],
  [-79.06193, 53.432969999],
  [-79.06549, 53.430079999],
  [-79.0667501, 53.430619999],
  [-79.0672099, 53.429979999],
  [-79.07024, 53.429519999],
  [-79.0706501, 53.429910099],
  [-79.06979, 53.429900099],
  [-79.06948, 53.430579899],
  [-79.07062, 53.430680099],
  [-79.07059, 53.431189999],
  [-79.06973, 53.431179999],
  [-79.06704, 53.433699899],
  [-79.0653001, 53.434179999],
  [-79.06491, 53.433399999],
  [-79.0623001, 53.434130099],
  [-79.06135, 53.436169999],
  [-79.0746301, 53.436759999],
  [-79.0760999, 53.437429999],
  [-79.07628, 53.438199999],
  [-79.0775699, 53.438219999],
  [-79.0748501, 53.441250099],
  [-79.0765599, 53.441539999],
  [-79.0765301, 53.442049899],
  [-79.08207, 53.442910099],
  [-79.0816, 53.443919899],
  [-79.08074, 53.443910099],
  [-79.07892, 53.446189999],
  [-79.07546, 53.446769999],
  [-79.07238, 53.448509999],
  [-79.0706699, 53.448360099],
  [-79.0694299, 53.447309999],
  [-79.0694101, 53.447829899],
  [-79.0680999, 53.448319999],
  [-79.06769, 53.447539999],
  [-79.06557, 53.447120099],
  [-79.0564101, 53.450429999],
  [-79.0550701, 53.451680099],
  [-79.0542099, 53.451680099],
  [-79.05423, 53.451169899],
  [-79.04861, 53.452099999],
  [-79.0482099, 53.454570099],
  [-79.05234, 53.454979999],
  [-79.0523001, 53.455749999],
  [-79.0557, 53.456570099],
  [-79.0560701, 53.457989999],
  [-79.0581999, 53.458279899],
  [-79.0649701, 53.455700099],
  [-79.0656499, 53.454680099],
  [-79.07129, 53.453489899],
  [-79.07102, 53.454769999],
  [-79.0716501, 53.455159899],
  [-79.07596, 53.454849999],
  [-79.0759801, 53.454339999],
  [-79.07858, 53.453879999],
  [-79.0785499, 53.454379899],
  [-79.07983, 53.454529999],
  [-79.0833399, 53.452920099],
  [-79.08369, 53.454719899],
  [-79.0845499, 53.454729899],
  [-79.08452, 53.455240099],
  [-79.08669, 53.454769999],
  [-79.0866601, 53.455529999],
  [-79.0892399, 53.455319999],
  [-79.08906, 53.454549999],
  [-79.0945, 53.453219999],
  [-79.0952399, 53.455669999],
  [-79.09439, 53.455399999],
  [-79.09348, 53.456669999],
  [-79.0913201, 53.456889999],
  [-79.09037, 53.458920099],
  [-79.0912299, 53.458939899],
  [-79.0907601, 53.459690099],
  [-79.0898999, 53.459690099],
  [-79.09026, 53.461360099],
  [-79.09495, 53.462069999],
  [-79.0966, 53.463639999],
  [-79.0974699, 53.463389899],
  [-79.0979147, 53.466374199],
  [-79.1020151, 53.467118899],
  [-79.1043302, 53.468307599],
  [-79.1121155, 53.465544999],
  [-79.10631, 53.468315799],
  [-79.1051245, 53.470279599],
  [-79.1050869, 53.472003799],
  [-79.103955, 53.471346099],
  [-79.1010788, 53.471214899],
  [-79.1013748, 53.473082999],
  [-79.0973783, 53.473408699],
  [-79.0954557, 53.472664799],
  [-79.0956259, 53.471949099],
  [-79.0975607, 53.470761699],
  [-79.09547, 53.469639999],
  [-79.090169, 53.469651199],
  [-79.0851199, 53.470629999],
  [-79.08461, 53.472409999],
  [-79.09103, 53.472769999],
  [-79.09229, 53.473559999],
  [-79.09217, 53.476380099],
  [-79.0994499, 53.476749999],
  [-79.0994201, 53.477260099],
  [-79.10327, 53.477579999],
  [-79.1033, 53.477069899],
  [-79.1054499, 53.476839899],
  [-79.10559, 53.478639999],
  [-79.10684, 53.479429999],
  [-79.1068901, 53.483270099],
  [-79.1060299, 53.483260099],
  [-79.1060001, 53.484030099],
  [-79.1030001, 53.483729899],
  [-79.10219, 53.482679999],
  [-79.10005, 53.482519899],
  [-79.09614, 53.483359999],
  [-79.09683, 53.482339999],
  [-79.0966799, 53.481059899],
  [-79.08895, 53.480679999],
  [-79.0889801, 53.480169899],
  [-79.0877, 53.480150099],
  [-79.0874399, 53.481169899],
  [-79.0893101, 53.482480099],
  [-79.08871, 53.486309999],
  [-79.0895699, 53.486329999],
  [-79.0895301, 53.486839899],
  [-79.0946799, 53.487179899],
  [-79.09471, 53.486659999],
  [-79.0959901, 53.486689999],
  [-79.0973599, 53.484909999],
  [-79.09953, 53.484559999],
  [-79.10508, 53.485160099],
  [-79.1056501, 53.486839899],
  [-79.1043201, 53.487839899],
  [-79.1048901, 53.489390099],
  [-79.10662, 53.489289899],
  [-79.10706, 53.488909999],
  [-79.1062101, 53.488899999],
  [-79.1062399, 53.488129999],
  [-79.1084, 53.487779999],
  [-79.11074, 53.488459999],
  [-79.10695, 53.491469999],
  [-79.1095601, 53.490739899],
  [-79.10953, 53.491509899],
  [-79.11431, 53.490179899],
  [-79.12073, 53.490789999],
  [-79.12106, 53.492979999],
  [-79.1171299, 53.494319999],
  [-79.1145501, 53.494409899],
  [-79.1201101, 53.495009999],
  [-79.11874, 53.496779999],
  [-79.1174599, 53.496759999],
  [-79.11916, 53.497170099],
  [-79.1200299, 53.496799999],
  [-79.1200001, 53.497569999],
  [-79.1182599, 53.498060099],
  [-79.1182399, 53.498579999],
  [-79.11652, 53.498539999],
  [-79.1157999, 53.500309899],
  [-79.1164, 53.501099999],
  [-79.1181299, 53.500869999],
  [-79.1176799, 53.501380099],
  [-79.1186799, 53.501559999],
  [-79.1205601, 53.501079899],
  [-79.12053, 53.500139999],
  [-79.1233699, 53.499150099],
  [-79.12517, 53.497139999],
  [-79.1260199, 53.497399899],
  [-79.1273699, 53.496150099],
  [-79.12952, 53.496050099],
  [-79.13121, 53.496720099],
  [-79.1309099, 53.497139999],
  [-79.1329101, 53.496999999],
  [-79.1320101, 53.498009999],
  [-79.13116, 53.497749899],
  [-79.13108, 53.499539999],
  [-79.13284, 53.498799999],
  [-79.1323599, 53.499809999],
  [-79.1315, 53.499799999],
  [-79.1314799, 53.500569999],
  [-79.13577, 53.500380099],
  [-79.13575, 53.500889999],
  [-79.1342301, 53.501380099],
  [-79.1350299, 53.502669999],
  [-79.13477, 53.503689999],
  [-79.1334899, 53.503419899],
  [-79.1325899, 53.504429999],
  [-79.12443, 53.504299899],
  [-79.1253501, 53.502779999],
  [-79.12664, 53.502789999],
  [-79.1271201, 53.501769999],
  [-79.12797, 53.501799999],
  [-79.1275799, 53.501019999],
  [-79.1271299, 53.501529899],
  [-79.1193499, 53.502429999],
  [-79.1188901, 53.503189899],
  [-79.11672, 53.503659999],
  [-79.1135699, 53.507209999],
  [-79.1105, 53.508050099],
  [-79.10837, 53.507899999],
  [-79.1070299, 53.509019999],
  [-79.1039901, 53.509620099],
  [-79.1043399, 53.511419899],
  [-79.10607, 53.511309899],
  [-79.1066899, 53.511709999],
  [-79.10642, 53.512730099],
  [-79.1090199, 53.512510099],
  [-79.1090001, 53.513029999],
  [-79.10683, 53.513500099],
  [-79.1067801, 53.514529899],
  [-79.1059199, 53.514510099],
  [-79.1054401, 53.515789999],
  [-79.10415, 53.515769999],
  [-79.1036799, 53.516529899],
  [-79.09414, 53.518429899],
  [-79.0941199, 53.518940099],
  [-79.0884499, 53.520769999],
  [-79.0836799, 53.521589999],
  [-79.08371, 53.520819999],
  [-79.0820099, 53.520539899],
  [-79.08203, 53.520029999],
  [-79.07902, 53.519969899],
  [-79.0785599, 53.520740099],
  [-79.0755301, 53.521199899],
  [-79.07647, 53.519419899],
  [-79.0730801, 53.518349999],
  [-79.07349, 53.518859899],
  [-79.07091, 53.519079899],
  [-79.07128, 53.520109999],
  [-79.0704201, 53.520099899],
  [-79.0686, 53.522369999],
  [-79.06728, 53.522859899],
  [-79.06814, 53.522879899],
  [-79.0672299, 53.524139999],
  [-79.06984, 53.523290099],
  [-79.0728501, 53.523479999],
  [-79.07282, 53.523979899],
  [-79.0719601, 53.523960099],
  [-79.07235, 53.524999999],
  [-79.0766301, 53.525319899],
  [-79.07573, 53.526329999],
  [-79.06918, 53.528529899],
  [-79.06916, 53.529039999],
  [-79.0608699, 53.531469999],
  [-79.06072, 53.530190099],
  [-79.06203, 53.529699999],
  [-79.0631901, 53.527919999],
  [-79.06404, 53.527939999],
  [-79.0640801, 53.527170099],
  [-79.0632199, 53.527149999],
  [-79.06368, 53.526390099],
  [-79.06234, 53.527649899],
  [-79.0562199, 53.529740099],
  [-79.0506399, 53.529510099],
  [-79.05016, 53.530529899],
  [-79.04805, 53.529979899],
  [-79.0476599, 53.528950099],
  [-79.04546, 53.529939999],
  [-79.04548, 53.529429899],
  [-79.0420601, 53.529109999],
  [-79.04123, 53.528329999],
  [-79.0411899, 53.529099899],
  [-79.04035, 53.528829999],
  [-79.0376399, 53.531599999],
  [-79.0298, 53.533779999],
  [-79.02889, 53.534789999],
  [-79.0271701, 53.534759899],
  [-79.02702, 53.533479999],
  [-79.02814, 53.532469999],
  [-79.0344099, 53.531809999],
  [-79.03303, 53.528969899],
  [-79.02744, 53.529129999],
  [-79.0268401, 53.528099899],
  [-79.02858, 53.527610099],
  [-79.0288301, 53.526850099],
  [-79.0254099, 53.526410099],
  [-79.02455, 53.526520099],
  [-79.0241999, 53.527960099],
  [-79.02492, 53.527809999],
  [-79.02488, 53.528319899],
  [-79.0192701, 53.529009999],
  [-79.0192999, 53.528479999],
  [-79.0158499, 53.528679999],
  [-79.01587, 53.528170099],
  [-79.01113, 53.528479999],
  [-79.0086101, 53.527029999],
  [-79.0071501, 53.530840099],
  [-79.00368, 53.531300099],
  [-79.00275, 53.532819999],
  [-79.00013, 53.533799999],
  [-78.9998499, 53.535080099],
  [-79.00046, 53.535730099],
  [-79.00133, 53.535620099],
  [-79.00131, 53.536129999],
  [-79.00781, 53.535080099],
  [-79.0184901, 53.536669999],
  [-79.01846, 53.537180099],
  [-79.0218699, 53.537879899],
  [-79.0279, 53.537719999],
  [-79.0274001, 53.539119999],
  [-79.0287, 53.538889899],
  [-79.0241701, 53.544190099],
  [-79.02199, 53.544410099],
  [-79.0224001, 53.544929999],
  [-79.0210899, 53.545300099],
  [-79.0189501, 53.545129999],
  [-79.0180401, 53.546139999],
  [-79.0171799, 53.546129999],
  [-79.0173099, 53.547919999],
  [-79.01966, 53.548349999],
  [-79.0227599, 53.546469999],
  [-79.0314001, 53.545589999],
  [-79.0290899, 53.549139999],
  [-79.02645, 53.550379999],
  [-79.02788, 53.551939999],
  [-79.03024, 53.552109899],
  [-79.0308501, 53.552760099],
  [-79.0297, 53.554789999],
  [-79.03355, 53.554980099],
  [-79.0356201, 53.556679999],
  [-79.0413199, 53.554349999],
  [-79.0420799, 53.556530099],
  [-79.03592, 53.559499999],
  [-79.0359, 53.560009899],
  [-79.0355001, 53.559239999],
  [-79.0346399, 53.559229899],
  [-79.0347, 53.557949999],
  [-79.0325399, 53.558169999],
  [-79.0320601, 53.559179999],
  [-79.02202, 53.562339899],
  [-79.0220699, 53.561320099],
  [-79.01603, 53.561469999],
  [-79.0148499, 53.559410099],
  [-79.00712, 53.558899899],
  [-79.00545, 53.557850099],
  [-79.00458, 53.557959999],
  [-79.00423, 53.556159999],
  [-78.99653, 53.555259999],
  [-78.9965099, 53.555779899],
  [-78.9939, 53.556239999],
  [-78.99388, 53.556760099],
  [-78.9894999, 53.558219899],
  [-78.98963, 53.555659899],
  [-78.9845801, 53.553269999],
  [-78.98488, 53.551479999],
  [-78.98324, 53.549659899],
  [-78.9834999, 53.548899999],
  [-78.9800599, 53.548709999],
  [-78.97656, 53.549929999],
  [-78.97398, 53.550019999],
  [-78.9736199, 53.548469999],
  [-78.97711, 53.547509999],
  [-78.9780301, 53.546239999],
  [-78.9745801, 53.546310099],
  [-78.9732601, 53.547070099],
  [-78.9693601, 53.547630099],
  [-78.9693899, 53.546860099],
  [-78.97688, 53.543279999],
  [-78.9790499, 53.542929999],
  [-78.9795999, 53.540369999],
  [-78.9741, 53.538359999],
  [-78.9641, 53.540620099],
  [-78.9654899, 53.538850099],
  [-78.96207, 53.538149999],
  [-78.96076, 53.538520099],
  [-78.96074, 53.539029999],
  [-78.95988, 53.539009999],
  [-78.96028, 53.539659899],
  [-78.96219, 53.540080099],
  [-78.9610399, 53.541599999],
  [-78.9549299, 53.543539899],
  [-78.9544601, 53.544300099],
  [-78.9501301, 53.544999899],
  [-78.9501, 53.545510099],
  [-78.9474899, 53.546099899],
  [-78.9444899, 53.545929999],
  [-78.9438901, 53.544889899],
  [-78.94541, 53.544659899],
  [-78.94919, 53.542159999],
  [-78.94944, 53.541400099],
  [-78.9477099, 53.541369999],
  [-78.94774, 53.540860099],
  [-78.94564, 53.539919999],
  [-78.93517, 53.542949999],
  [-78.9342599, 53.543960099],
  [-78.93119, 53.545049999],
  [-78.91951, 53.546520099],
  [-78.91948, 53.547029999],
  [-78.91731, 53.547489999],
  [-78.91684, 53.548259999],
  [-78.9098701, 53.549939999],
  [-78.90941, 53.550709999],
  [-78.9085401, 53.550679999],
  [-78.90807, 53.551699999],
  [-78.90637, 53.551410099],
  [-78.9063301, 53.551919999],
  [-78.9020099, 53.552359999],
  [-78.9024, 53.553139999],
  [-78.89938, 53.553459999],
  [-78.89414, 53.555169999],
  [-78.88594, 53.555410099],
  [-78.8945, 53.556200099],
  [-78.8993101, 53.554750099],
  [-78.90359, 53.554949999],
  [-78.90397, 53.556239999],
  [-78.9031199, 53.555970099],
  [-78.9021001, 53.559279999],
  [-78.89994, 53.559499999],
  [-78.89881, 53.560499999],
  [-78.8985301, 53.561779899],
  [-78.9002399, 53.562069999],
  [-78.9004, 53.563349999],
  [-78.9016601, 53.563889899],
  [-78.90183, 53.564659899],
  [-78.90953, 53.565559899],
  [-78.9103399, 53.566599999],
  [-78.9129099, 53.566769899],
  [-78.9159801, 53.565799999],
  [-78.9176701, 53.566339899],
  [-78.9220001, 53.565909999],
  [-78.93251, 53.562119899],
  [-78.93249, 53.562629999],
  [-78.9354799, 53.562939999],
  [-78.93606, 53.564229899],
  [-78.93575, 53.566269999],
  [-78.9432401, 53.567169999],
  [-78.94297, 53.568449899],
  [-78.9385799, 53.570159999],
  [-78.93831, 53.571179999],
  [-78.94229, 53.573430099],
  [-78.9539199, 53.573249999],
  [-78.95432, 53.573889899],
  [-78.96166, 53.573509999],
  [-78.9651499, 53.572550099],
  [-78.9656099, 53.571909899],
  [-78.96776, 53.572069999],
  [-78.97342, 53.570760099],
  [-78.9711401, 53.573279999],
  [-78.9702799, 53.573259999],
  [-78.9671599, 53.575519999],
  [-78.96544, 53.575489999],
  [-78.96542, 53.575999899],
  [-78.9562599, 53.578409999],
  [-78.9550499, 53.581210099],
  [-78.9552, 53.582489999],
  [-78.95389, 53.582980099],
  [-78.9564899, 53.582509999],
  [-78.9564701, 53.583019899],
  [-78.96077, 53.583100099],
  [-78.96255, 53.581849999],
  [-78.96253, 53.582359899],
  [-78.9638199, 53.582249999],
  [-78.9640101, 53.582899899],
  [-78.9661201, 53.583699999],
  [-78.9666901, 53.585249899],
  [-78.97143, 53.585330099],
  [-78.96485, 53.587780099],
  [-78.9648199, 53.588289999],
  [-78.96266, 53.588509999],
  [-78.96131, 53.589639999],
  [-78.9604601, 53.589499999],
  [-78.95906, 53.591780099],
  [-78.95821, 53.591770099],
  [-78.95987, 53.592819999],
  [-78.96021, 53.594619999],
  [-78.9593501, 53.594609999],
  [-78.95844, 53.595609999],
  [-78.9593, 53.595629999],
  [-78.95928, 53.596139899],
  [-78.97177, 53.596230099],
  [-78.97918, 53.594299999],
  [-78.98176, 53.594469899],
  [-78.9821, 53.596269999],
  [-78.9838201, 53.596049999],
  [-78.9833799, 53.596550099],
  [-78.98467, 53.596560099],
  [-78.98374, 53.598089999],
  [-78.98589, 53.598129899],
  [-78.9841301, 53.598869999],
  [-78.98452, 53.599639999],
  [-78.98234, 53.600379999],
  [-78.9818001, 53.602670099],
  [-78.98563, 53.603509999],
  [-78.9842899, 53.604509999],
  [-78.9851501, 53.604519999],
  [-78.98552, 53.605809899],
  [-78.98723, 53.606099999],
  [-78.98721, 53.606619999],
  [-78.9893801, 53.606259899],
  [-78.9911601, 53.605019899],
  [-78.9972501, 53.603709999],
  [-78.99765, 53.604349899],
  [-79.00067, 53.604399999],
  [-79.0046001, 53.603309999],
  [-79.0046299, 53.602529999],
  [-79.0007101, 53.603369899],
  [-78.99943, 53.603230099],
  [-78.99947, 53.602459899],
  [-79.001, 53.601969999],
  [-79.0025901, 53.600199999],
  [-79.00345, 53.600220099],
  [-79.0046199, 53.598189999],
  [-79.00878, 53.596849999],
  [-79.01144, 53.595100099],
  [-79.01489, 53.595029899],
  [-79.01514, 53.594259999],
  [-79.01623, 53.594029899],
  [-79.0162701, 53.593259999],
  [-79.0218201, 53.591129899],
  [-79.02111, 53.591039999],
  [-79.02159, 53.590019899],
  [-79.0232999, 53.590299999],
  [-79.02332, 53.589789899],
  [-79.0241799, 53.589799899],
  [-79.0237, 53.586210099],
  [-79.0265101, 53.586000099],
  [-79.0261, 53.585479999],
  [-79.0281999, 53.586789899],
  [-79.02781, 53.586019899],
  [-79.0303901, 53.585929999],
  [-79.03079, 53.586579899],
  [-79.0299401, 53.586309999],
  [-79.03014, 53.586819999],
  [-79.0320501, 53.587369999],
  [-79.03203, 53.587880099],
  [-79.0341701, 53.587919999],
  [-79.03415, 53.588430099],
  [-79.0358699, 53.588459899],
  [-79.0358501, 53.588969999],
  [-79.03738, 53.588479999],
  [-79.03656, 53.587440099],
  [-79.03725, 53.586559899],
  [-79.0398301, 53.586599999],
  [-79.04046, 53.586990099],
  [-79.0401999, 53.588009899],
  [-79.03758, 53.588739999],
  [-79.0387499, 53.591320099],
  [-79.0521099, 53.591159999],
  [-79.05257, 53.590519999],
  [-79.05171, 53.590509999],
  [-79.05178, 53.588969999],
  [-79.05092, 53.588959999],
  [-79.05094, 53.588449899],
  [-79.05267, 53.588349899],
  [-79.05313, 53.587459899],
  [-79.0553299, 53.586980099],
  [-79.0552901, 53.587489999],
  [-79.05917, 53.587430099],
  [-79.0596401, 53.586540099],
  [-79.0587799, 53.586529999],
  [-79.0579999, 53.584719999],
  [-79.0588699, 53.584599999],
  [-79.0665399, 53.586389999],
  [-79.06649, 53.587419999],
  [-79.06349, 53.587110099],
  [-79.06347, 53.587629999],
  [-79.0716699, 53.587119899],
  [-79.0720901, 53.587509999],
  [-79.0679801, 53.587699999],
  [-79.0675201, 53.588459899],
  [-79.0703101, 53.588499999],
  [-79.0696, 53.588839999],
  [-79.06939, 53.590029899],
  [-79.0685301, 53.590019899],
  [-79.06825, 53.591289999],
  [-79.0690801, 53.592069999],
  [-79.0679601, 53.593079999],
  [-79.06925, 53.593100099],
  [-79.06939, 53.594639999],
  [-79.07127, 53.595949999],
  [-79.0655399, 53.598799899],
  [-79.06037, 53.598849999],
  [-79.06035, 53.599359899],
  [-79.0581999, 53.599340099],
  [-79.0582199, 53.598809899],
  [-79.05382, 53.600780099],
  [-79.05945, 53.600110099],
  [-79.0531099, 53.606929899],
  [-79.0539701, 53.606939999],
  [-79.05557, 53.609519999],
  [-79.0583001, 53.611110099],
  [-79.05779, 53.612900099],
  [-79.06103, 53.612689899],
  [-79.06101, 53.613209999],
  [-79.05971, 53.613189999],
  [-79.0596699, 53.614209999],
  [-79.05615, 53.615819899],
  [-79.0539999, 53.615659999],
  [-79.05402, 53.615139899],
  [-79.0522901, 53.615369899],
  [-79.0533199, 53.616409999],
  [-79.05305, 53.617429999],
  [-79.05045, 53.617900099],
  [-79.05048, 53.617139899],
  [-79.0496201, 53.617120099],
  [-79.0490701, 53.619670099],
  [-79.05339, 53.619489899],
  [-79.05337, 53.620000099],
  [-79.0507401, 53.620849999],
  [-79.0481801, 53.620169999],
  [-79.04724, 53.621949999],
  [-79.0463901, 53.621680099],
  [-79.04592, 53.622699899],
  [-79.0446101, 53.622929899],
  [-79.0445399, 53.624470099],
  [-79.04645, 53.625269899],
  [-79.0478601, 53.627209999],
  [-79.04872, 53.627360099],
  [-79.04779, 53.628879999],
  [-79.04133, 53.628769999],
  [-79.0392801, 53.626429999],
  [-79.03368, 53.626340099],
  [-79.03366, 53.626859999],
  [-79.0328, 53.626839999],
  [-79.0327201, 53.628369899],
  [-79.03145, 53.628219999],
  [-79.02792, 53.629699899],
  [-79.02059, 53.629709899],
  [-79.0206201, 53.629199999],
  [-79.01679, 53.628109999],
  [-79.0170401, 53.627350099],
  [-79.0215479, 53.626404499],
  [-79.0204269, 53.624558799],
  [-79.0155358, 53.622663099],
  [-79.0176486, 53.622022599],
  [-79.0238169, 53.622330099],
  [-79.0278634, 53.621805399],
  [-79.0336263, 53.623809099],
  [-79.0361693, 53.622377599],
  [-79.0353558, 53.619276299],
  [-79.0415337, 53.615554099],
  [-79.0385741, 53.615736499],
  [-79.0413223, 53.614715499],
  [-79.0389465, 53.612818999],
  [-79.0396969, 53.612294399],
  [-79.0395368, 53.611623599],
  [-79.0431843, 53.609329599],
  [-79.0430726, 53.608539199],
  [-79.0418599, 53.608029899],
  [-79.0359079, 53.609007299],
  [-79.0357076, 53.609906299],
  [-79.0328202, 53.611210999],
  [-79.0335357, 53.611567199],
  [-79.0289681, 53.613313699],
  [-79.0292011, 53.612668199],
  [-79.0281932, 53.612418999],
  [-79.0238919, 53.613116699],
  [-79.0199422, 53.612876999],
  [-79.0190773, 53.613504499],
  [-79.0194047, 53.615215599],
  [-79.0200212, 53.615595499],
  [-79.0258318, 53.616960699],
  [-79.02766, 53.616612099],
  [-79.0283024, 53.617029999],
  [-79.0281727, 53.617743599],
  [-79.0253143, 53.619780799],
  [-79.0222623, 53.620227299],
  [-79.0138947, 53.619043199],
  [-79.0134642, 53.619401799],
  [-79.0141088, 53.619957699],
  [-79.0139235, 53.620661599],
  [-79.0129389, 53.621270099],
  [-79.0158313, 53.621845499],
  [-79.0147042, 53.623208799],
  [-79.0149837, 53.625539899],
  [-79.0129223, 53.626140999],
  [-79.0074133, 53.625841899],
  [-79.0121696, 53.627466199],
  [-79.0129174, 53.628393499],
  [-79.0150047, 53.628588099],
  [-79.0159115, 53.629925699],
  [-79.01578, 53.631169999],
  [-79.015018, 53.631775399],
  [-79.0135051, 53.631501799],
  [-79.0086336, 53.632441699],
  [-79.0080139, 53.633727999],
  [-79.0063858, 53.634346899],
  [-79.0004844, 53.634952699],
  [-78.9984901, 53.636769999],
  [-79.001, 53.638099999],
  [-79.0001512, 53.640385299],
  [-79.0140401, 53.640619999],
  [-79.01402, 53.641130099],
  [-79.02048, 53.641369899],
  [-79.0257101, 53.640169899],
  [-79.03046, 53.639989999],
  [-79.036, 53.641369899],
  [-79.04812, 53.640279899],
  [-79.04915, 53.641449999],
  [-79.0475299, 53.643729899],
  [-79.0483901, 53.643749999],
  [-79.04836, 53.644519999],
  [-79.05115, 53.644819899],
  [-79.0509799, 53.643790099],
  [-79.05404, 53.642810099],
  [-79.0544299, 53.643849999],
  [-79.05327, 53.643999999],
  [-79.0596, 53.643929899],
  [-79.05957, 53.644439999],
  [-79.0613001, 53.644480099],
  [-79.0620999, 53.645629999],
  [-79.0607799, 53.646250099],
  [-79.0615301, 53.645619899],
  [-79.05949, 53.646229999],
  [-79.0595301, 53.645470099],
  [-79.0556201, 53.645920099],
  [-79.0559799, 53.647460099],
  [-79.0520701, 53.648159899],
  [-79.05167, 53.647389899],
  [-79.04516, 53.648429999],
  [-79.04216, 53.647999999],
  [-79.04102, 53.649260099],
  [-79.0411899, 53.650289999],
  [-79.04033, 53.650279899],
  [-79.0403099, 53.650789999],
  [-79.03381, 53.651449999],
  [-79.03559, 53.650069999],
  [-79.0372999, 53.650480099],
  [-79.03692, 53.649449999],
  [-79.03477, 53.649159899],
  [-79.03444, 53.647109999],
  [-79.0257399, 53.648759999],
  [-79.0248301, 53.649769999],
  [-79.0230899, 53.649989999],
  [-79.0227499, 53.652810099],
  [-79.0250501, 53.654250099],
  [-79.03368, 53.654140099],
  [-79.03934, 53.652949899],
  [-79.0399601, 53.653599899],
  [-79.0390701, 53.654099999],
  [-79.0387499, 53.656399899],
  [-79.04004, 53.656549999],
  [-79.0406301, 53.657710099],
  [-79.0427499, 53.658519999],
  [-79.0425001, 53.659019999],
  [-79.04511, 53.658810099],
  [-79.0450899, 53.659329999],
  [-79.0502199, 53.659920099],
  [-79.0491999, 53.658879999],
  [-79.05202, 53.658669999],
  [-79.0524001, 53.659700099],
  [-79.0515299, 53.659679999],
  [-79.0520999, 53.661490099],
  [-79.05379, 53.662279899],
  [-79.0530999, 53.663299999],
  [-79.05003, 53.664279899],
  [-79.05001, 53.664779999],
  [-79.0435201, 53.664930099],
  [-79.0435101, 53.665449999],
  [-79.0387499, 53.665749999],
  [-79.0366101, 53.665329999],
  [-79.0366301, 53.664820099],
  [-79.0289, 53.664179899],
  [-79.02893, 53.663409999],
  [-79.0274001, 53.663899999],
  [-79.02757, 53.664930099],
  [-79.0228, 53.665490099],
  [-79.0185099, 53.665030099],
  [-79.0185299, 53.664519899],
  [-79.0163901, 53.664099999],
  [-79.0141899, 53.664959899],
  [-79.015, 53.666260099],
  [-79.01413, 53.666239999],
  [-79.01367, 53.666999999],
  [-79.00799, 53.668189999],
  [-79.0052601, 53.671480099],
  [-79.0030599, 53.672339999],
  [-79.0000401, 53.672409899],
  [-78.9969201, 53.674409899],
  [-79.0003899, 53.673959899],
  [-79.00036, 53.674729899],
  [-79.00603, 53.673409899],
  [-79.0185299, 53.673749999],
  [-79.0189, 53.675030099],
  [-79.0176201, 53.674749999],
  [-79.0182701, 53.679249999],
  [-79.01913, 53.679390099],
  [-79.0190799, 53.680419999],
  [-79.02037, 53.680179899],
  [-79.02099, 53.680959899],
  [-79.0202899, 53.682229999],
  [-79.01768, 53.682569999],
  [-79.007, 53.680219999],
  [-79.00703, 53.679700099],
  [-79.0048499, 53.680179899],
  [-79.00656, 53.680469999],
  [-79.0073701, 53.681500099],
  [-79.01013, 53.682569999],
  [-79.0102801, 53.683859899],
  [-79.00942, 53.683839899],
  [-79.0081701, 53.686040099],
  [-79.00843, 53.686649999],
  [-79.0175299, 53.685899999],
  [-79.02225, 53.686490099],
  [-79.0240501, 53.684859899],
  [-79.02234, 53.684569999],
  [-79.02089, 53.683270099],
  [-79.02114, 53.682500099],
  [-79.02243, 53.682519899],
  [-79.02248, 53.681500099],
  [-79.0289601, 53.681089999],
  [-79.0297301, 53.683409899],
  [-79.03103, 53.683299899],
  [-79.03193, 53.682419999],
  [-79.02937, 53.681869999],
  [-79.0302701, 53.680989999],
  [-79.0333099, 53.680649999],
  [-79.03326, 53.681679999],
  [-79.03647, 53.682239999],
  [-79.0371899, 53.685329999],
  [-79.03782, 53.685720099],
  [-79.04215, 53.685409899],
  [-79.04211, 53.686179899],
  [-79.04602, 53.685729899],
  [-79.0460701, 53.684579999],
  [-79.04949, 53.685139999],
  [-79.05167, 53.684799999],
  [-79.0511999, 53.685809999],
  [-79.0499, 53.685789999],
  [-79.0489799, 53.687060099],
  [-79.04812, 53.687040099],
  [-79.04827, 53.688329999],
  [-79.0492901, 53.689619899],
  [-79.05316, 53.689940099],
  [-79.05313, 53.690459999],
  [-79.0565499, 53.691280099],
  [-79.0496779, 53.693047499],
  [-79.0498066, 53.693504899],
  [-79.0553856, 53.694521299],
  [-79.0552569, 53.694013099],
  [-79.0574027, 53.692844199],
  [-79.0609217, 53.692590099],
  [-79.0645266, 53.693199999],
  [-79.0692902, 53.695258199],
  [-79.0726805, 53.695258199],
  [-79.0735817, 53.695664699],
  [-79.0735388, 53.696909799],
  [-79.0777445, 53.698942399],
  [-79.0809632, 53.700212699],
  [-79.08463, 53.700439999],
  [-79.0837501, 53.700689999],
  [-79.08348, 53.701960099],
  [-79.0849701, 53.702500099],
  [-79.08494, 53.702999999],
  [-79.0871001, 53.703050099],
  [-79.0868601, 53.703549999],
  [-79.0890901, 53.706919999],
  [-79.0903399, 53.707709999],
  [-79.0851299, 53.708400099],
  [-79.0851001, 53.709159999],
  [-79.0768899, 53.709290099],
  [-79.0768601, 53.709799999],
  [-79.07382, 53.710390099],
  [-79.07125, 53.709709999],
  [-79.07128, 53.709199899],
  [-79.06306, 53.709449999],
  [-79.0600701, 53.708759899],
  [-79.06101, 53.707239999],
  [-79.05667, 53.707419899],
  [-79.0567, 53.706909999],
  [-79.05756, 53.706929999],
  [-79.0576201, 53.705649899],
  [-79.05848, 53.705789999],
  [-79.05981, 53.704909999],
  [-79.0534001, 53.703529899],
  [-79.05245, 53.705309899],
  [-79.0459601, 53.705579999],
  [-79.0438699, 53.704139999],
  [-79.0399799, 53.704199899],
  [-79.03613, 53.703500099],
  [-79.03615, 53.702989999],
  [-79.0314199, 53.702659999],
  [-79.03223, 53.703689999],
  [-79.0381999, 53.705459999],
  [-79.0416499, 53.705510099],
  [-79.0433099, 53.706950099],
  [-79.0476399, 53.706639899],
  [-79.0493299, 53.707429899],
  [-79.05236, 53.707349999],
  [-79.05189, 53.708109999],
  [-79.0497, 53.708850099],
  [-79.0492199, 53.709859899],
  [-79.04836, 53.709859899],
  [-79.04716, 53.712390099],
  [-79.0495201, 53.712689999],
  [-79.04836, 53.714459999],
  [-79.0498401, 53.715129999],
  [-79.0528699, 53.714919999],
  [-79.05361, 53.713009999],
  [-79.05691, 53.711779999],
  [-79.0565301, 53.710500099],
  [-79.0814599, 53.713070099],
  [-79.0844201, 53.714529899],
  [-79.0835599, 53.714520099],
  [-79.08351, 53.715539899],
  [-79.08263, 53.715909999],
  [-79.08005, 53.715740099],
  [-79.0804301, 53.716769899],
  [-79.07915, 53.716500099],
  [-79.07951, 53.718039999],
  [-79.0825301, 53.718089899],
  [-79.0807699, 53.718829999],
  [-79.0807501, 53.719339999],
  [-79.0686, 53.720549899],
  [-79.0668899, 53.720139999],
  [-79.0669601, 53.718609999],
  [-79.06805, 53.718369999],
  [-79.0683299, 53.716829999],
  [-79.0631901, 53.716119999],
  [-79.0609899, 53.716969899],
  [-79.0609, 53.718769899],
  [-79.0624001, 53.719049999],
  [-79.0612199, 53.721329899],
  [-79.05948, 53.721559999],
  [-79.05945, 53.722070099],
  [-79.0564399, 53.721769899],
  [-79.05648, 53.720999999],
  [-79.05349, 53.720180099],
  [-79.05346, 53.720950099],
  [-79.0495101, 53.722170099],
  [-79.0486, 53.723180099],
  [-79.03823, 53.723400099],
  [-79.0270601, 53.722060099],
  [-79.02703, 53.722569999],
  [-79.0252901, 53.722799999],
  [-79.02526, 53.723559999],
  [-79.0243801, 53.723809999],
  [-79.02847, 53.724129999],
  [-79.0286399, 53.725159999],
  [-79.0277599, 53.725529899],
  [-79.0173801, 53.725740099],
  [-79.02634, 53.727939999],
  [-79.0256399, 53.729209899],
  [-79.0279599, 53.730400099],
  [-79.0418, 53.730109899],
  [-79.04825, 53.730860099],
  [-79.0479601, 53.732389999],
  [-79.04936, 53.734979899],
  [-79.0510801, 53.734999899],
  [-79.0510601, 53.735520099],
  [-79.0539799, 53.734449899],
  [-79.05415, 53.733769899],
  [-79.0515499, 53.734239999],
  [-79.0516, 53.733219899],
  [-79.0573101, 53.731129999],
  [-79.05947, 53.731169999],
  [-79.05827, 53.733839999],
  [-79.0597501, 53.734499999],
  [-79.07572, 53.734759899],
  [-79.07848, 53.735960099],
  [-79.07902, 53.738269999],
  [-79.0750999, 53.738980099],
  [-79.0755499, 53.738469999],
  [-79.06303, 53.738019999],
  [-79.0630701, 53.737499999],
  [-79.0622001, 53.737489999],
  [-79.06261, 53.738009999],
  [-79.05872, 53.737960099],
  [-79.05761, 53.738699999],
  [-79.05756, 53.739719999],
  [-79.0626301, 53.740740099],
  [-79.0733101, 53.740359999],
  [-79.07414, 53.741019999],
  [-79.06982, 53.740939999],
  [-79.0706401, 53.741980099],
  [-79.0697799, 53.741970099],
  [-79.0707699, 53.743769899],
  [-79.0731099, 53.744709999],
  [-79.07615, 53.744630099],
  [-79.07394, 53.745369999],
  [-79.07339, 53.747919999],
  [-79.0748899, 53.748200099],
  [-79.0746401, 53.748959999],
  [-79.0726699, 53.749439899],
  [-79.0727799, 53.751750099],
  [-79.07193, 53.751739999],
  [-79.07228, 53.753279999],
  [-79.0795699, 53.754679899],
  [-79.0793299, 53.755190099],
  [-79.0821001, 53.755999899],
  [-79.0815499, 53.758549899],
  [-79.08024, 53.758789899],
  [-79.08151, 53.759579999],
  [-79.0723001, 53.762249999],
  [-79.07025, 53.759909999],
  [-79.0667699, 53.760369999],
  [-79.0668501, 53.758569899],
  [-79.0629801, 53.758129899],
  [-79.05603, 53.758909999],
  [-79.0550899, 53.760430099],
  [-79.05636, 53.760970099],
  [-79.0528699, 53.761679899],
  [-79.05383, 53.764259999],
  [-79.05356, 53.765530099],
  [-79.05658, 53.765579999],
  [-79.0547699, 53.767349899],
  [-79.05128, 53.768059999],
  [-79.0513001, 53.767550099],
  [-79.0496, 53.767009899],
  [-79.04911, 53.768279999],
  [-79.04345, 53.768959999],
  [-79.04255, 53.769709999],
  [-79.04423, 53.770760099],
  [-79.04683, 53.770799899],
  [-79.0468, 53.771320099],
  [-79.04379, 53.771000099],
  [-79.04268, 53.771760099],
  [-79.0432901, 53.772540099],
  [-79.0419899, 53.772519999],
  [-79.04169, 53.774309999],
  [-79.04271, 53.775599999],
  [-79.0405299, 53.776079999],
  [-79.0410899, 53.777880099],
  [-79.04079, 53.779670099],
  [-79.03993, 53.779660099],
  [-79.04247, 53.780719999],
  [-79.0407301, 53.780949999],
  [-79.04025, 53.781969999],
  [-79.03824, 53.782019899],
  [-79.0364493, 53.783674399],
  [-79.03669, 53.784220099],
  [-79.03582, 53.784199999],
  [-79.0359799, 53.785489999],
  [-79.03546, 53.785899899],
  [-79.03723, 53.786529999],
  [-79.0374001, 53.787560099],
  [-79.03826, 53.787569899],
  [-79.0387301, 53.786679899],
  [-79.04258, 53.787770099],
  [-79.051101, 53.788302099],
  [-79.0528373, 53.787403899],
  [-79.05556, 53.787729999],
  [-79.0575265, 53.788769199],
  [-79.0675397, 53.789955099],
  [-79.0742075, 53.786587299],
  [-79.0798285, 53.786617999],
  [-79.0815011, 53.786010499],
  [-79.0810326, 53.786599599],
  [-79.0817624, 53.786213699],
  [-79.0814501, 53.786906899],
  [-79.0831028, 53.786689099],
  [-79.081857, 53.787114599],
  [-79.0829792, 53.787687499],
  [-79.080274, 53.788778699],
  [-79.07923, 53.790076299],
  [-79.0805401, 53.790309999],
  [-79.081822, 53.791476699],
  [-79.0798964, 53.792758399],
  [-79.0817856, 53.794007599],
  [-79.0751099, 53.795340099],
  [-79.0798701, 53.795419999],
  [-79.07938, 53.796689899],
  [-79.0763201, 53.797409999],
  [-79.0767799, 53.796649999],
  [-79.0742299, 53.795709999],
  [-79.06817, 53.795749999],
  [-79.0672199, 53.797519999],
  [-79.0692099, 53.796790099],
  [-79.06827, 53.798309999],
  [-79.0688899, 53.798829999],
  [-79.06804, 53.798560099],
  [-79.06801, 53.799330099],
  [-79.06714, 53.799319999],
  [-79.0678799, 53.802149899],
  [-79.06657, 53.802379999],
  [-79.06695, 53.803409999],
  [-79.0656401, 53.803649999],
  [-79.06484, 53.806979999],
  [-79.0667049, 53.808094599],
  [-79.0548079, 53.808153899],
  [-79.0512046, 53.806832399],
  [-79.0570918, 53.804146499],
  [-79.0599585, 53.801938999],
  [-79.0626579, 53.801145699],
  [-79.0622521, 53.800771299],
  [-79.0559628, 53.802481799],
  [-79.0586273, 53.802230099],
  [-79.0544775, 53.804848399],
  [-79.0537673, 53.804794599],
  [-79.0540579, 53.804029999],
  [-79.0538843, 53.803567799],
  [-79.0538401, 53.804252299],
  [-79.0507977, 53.806568199],
  [-79.0465813, 53.807182299],
  [-79.0447406, 53.809933899],
  [-79.0391829, 53.813156099],
  [-79.0398583, 53.814679999],
  [-79.031412, 53.815166899],
  [-79.0228464, 53.816784599],
  [-79.0115569, 53.815601799],
  [-79.0083087, 53.814532999],
  [-78.9986608, 53.813916899],
  [-78.994879, 53.814937399],
  [-78.9931809, 53.816727999],
  [-78.99219, 53.819321599],
  [-78.9896147, 53.820858199],
  [-78.987617, 53.821095899],
  [-78.9848925, 53.820419399],
  [-78.9802134, 53.815214899],
  [-78.9738267, 53.811724999],
  [-78.9657562, 53.810807099],
  [-78.9625911, 53.809860099],
  [-78.953122, 53.809399799],
  [-78.948929, 53.810455899],
  [-78.9430958, 53.815391199],
  [-78.9335191, 53.817171799],
  [-78.9265603, 53.829188199],
  [-78.9285899, 53.831329999],
  [-78.92875, 53.832360099],
  [-78.9304699, 53.832649999],
  [-78.940061, 53.838401199],
  [-78.9368213, 53.838308699],
  [-78.9401274, 53.838943099],
  [-78.9436083, 53.838669299],
  [-78.9447292, 53.839214399],
  [-78.9556999, 53.839360099],
  [-78.9643206, 53.840503799],
  [-78.970932, 53.842757799],
  [-78.9825616, 53.843183799],
  [-78.9882745, 53.842333199],
  [-78.9996592, 53.844119999],
  [-79.0018377, 53.843621199],
  [-79.0063037, 53.845313899],
  [-79.0069972, 53.846241799],
  [-79.0095271, 53.846717799],
  [-79.0128044, 53.846012599],
  [-79.0136314, 53.845424299],
  [-79.0135083, 53.844441799],
  [-79.0207189, 53.844546599],
  [-79.0252917, 53.846425699],
  [-79.0300174, 53.844626499],
  [-79.0310795, 53.842773599],
  [-79.0368013, 53.841285999],
  [-79.0408145, 53.842499699],
  [-79.0398888, 53.844100599],
  [-79.042835, 53.843754199],
  [-79.0467673, 53.844329899],
  [-79.0540877, 53.843513999],
  [-79.0543844, 53.841357399],
  [-79.0589865, 53.841429699],
  [-79.0632486, 53.843288199],
  [-79.0622723, 53.843833399],
  [-79.0632058, 53.844264699],
  [-79.0624486, 53.844533899],
  [-79.06377, 53.845960299],
  [-79.0628496, 53.845821499],
  [-79.06298, 53.846803599],
  [-79.0640633, 53.847281099],
  [-79.0637714, 53.847968999],
  [-79.0647509, 53.850087399],
  [-79.0687865, 53.853700399],
  [-79.0743898, 53.854967899],
  [-79.0718514, 53.854839699],
  [-79.0737574, 53.855951099],
  [-79.0769059, 53.856120999],
  [-79.0782349, 53.860432799],
  [-79.0798799, 53.861519899],
  [-79.0833977, 53.862410099],
  [-79.0836175, 53.865626999],
  [-79.0812253, 53.866477799],
  [-79.0793126, 53.866193799],
  [-79.0815911, 53.865591199],
  [-79.0793425, 53.865551499],
  [-79.07769, 53.866610099],
  [-79.0841717, 53.869845899],
  [-79.0783334, 53.871239399],
  [-79.07092, 53.867779999],
  [-79.0725925, 53.862616099],
  [-79.076408, 53.861953199],
  [-79.0740406, 53.861277599],
  [-79.0738701, 53.860150099],
  [-79.0708158, 53.859840699],
  [-79.0722103, 53.860997399],
  [-79.0715843, 53.861316999],
  [-79.0664476, 53.858825499],
  [-79.0708993, 53.861769499],
  [-79.0684543, 53.864420399],
  [-79.06413, 53.864729899],
  [-79.0606578, 53.863637799],
  [-79.06281, 53.865089999],
  [-79.0618149, 53.866552899],
  [-79.0676047, 53.867650499],
  [-79.0646679, 53.868695299],
  [-79.0651593, 53.871837799],
  [-79.0663545, 53.870341699],
  [-79.0709724, 53.870749299],
  [-79.0712222, 53.871439699],
  [-79.06858, 53.871849899],
  [-79.0699985, 53.872429199],
  [-79.0691848, 53.872755999],
  [-79.0676432, 53.871986299],
  [-79.0680336, 53.872431599],
  [-79.0650183, 53.873730999],
  [-79.0597722, 53.872586099],
  [-79.0555, 53.873170099],
  [-79.05779, 53.875270099],
  [-79.058022, 53.876607399],
  [-79.057231, 53.878415099],
  [-79.0595055, 53.882133899],
  [-79.0620804, 53.883803399],
  [-79.0766903, 53.887370899],
  [-79.0817939, 53.887119699],
  [-79.0835556, 53.887888999],
  [-79.08391, 53.887079899],
  [-79.0889306, 53.887763799],
  [-79.0895885, 53.887383799],
  [-79.0886953, 53.887098199],
  [-79.0917423, 53.887617399],
  [-79.0940497, 53.886928999],
  [-79.0903906, 53.888149499],
  [-79.0919338, 53.889019699],
  [-79.091496, 53.889273499],
  [-79.089234, 53.888764199],
  [-79.0883386, 53.889232299],
  [-79.0921719, 53.889331199],
  [-79.0927715, 53.890415599],
  [-79.0943622, 53.890287299],
  [-79.0938221, 53.889375099],
  [-79.0954969, 53.889359799],
  [-79.0953703, 53.889872299],
  [-79.0970894, 53.890162199],
  [-79.0947937, 53.890727899],
  [-79.0977264, 53.891203299],
  [-79.0992274, 53.889671199],
  [-79.0996312, 53.890641999],
  [-79.0986476, 53.891466699],
  [-79.1016465, 53.892781099],
  [-79.1037117, 53.891954099],
  [-79.1063931, 53.892621499],
  [-79.1071197, 53.892071699],
  [-79.107041, 53.891061499],
  [-79.1052166, 53.891746799],
  [-79.105278, 53.891090599],
  [-79.1095712, 53.889883799],
  [-79.1122091, 53.890900899],
  [-79.1140669, 53.890869899],
  [-79.1150477, 53.891992099],
  [-79.1099809, 53.893372899],
  [-79.1048676, 53.893352599],
  [-79.105536, 53.892879099],
  [-79.1051123, 53.892577099],
  [-79.1004274, 53.893701399],
  [-79.10049, 53.894390099],
  [-79.1017899, 53.894409899],
  [-79.1011199, 53.894909999],
  [-79.1009901, 53.897730099],
  [-79.10482, 53.899319899],
  [-79.1045601, 53.900349999],
  [-79.1036899, 53.900329999],
  [-79.1053599, 53.901899999],
  [-79.1023599, 53.901079899],
  [-79.1018801, 53.902099899],
  [-79.10316, 53.902630099],
  [-79.09717, 53.900749899],
  [-79.09808, 53.899730099],
  [-79.08941, 53.899589999],
  [-79.08948, 53.898060099],
  [-79.08861, 53.898039999],
  [-79.0886501, 53.897269999],
  [-79.08173, 53.896909999],
  [-79.0817501, 53.896400099],
  [-79.07617, 53.895149999],
  [-79.0735499, 53.895510099],
  [-79.0735301, 53.896009999],
  [-79.0704499, 53.896859899],
  [-79.0630901, 53.896610099],
  [-79.0631099, 53.896099899],
  [-79.06139, 53.895809999],
  [-79.06136, 53.896319899],
  [-79.05893, 53.897309899],
  [-79.05868, 53.898070099],
  [-79.06037, 53.899129999],
  [-79.0577401, 53.899599999],
  [-79.05734, 53.898819999],
  [-79.05647, 53.899060099],
  [-79.0556301, 53.898280099],
  [-79.05538, 53.899049999],
  [-79.0568601, 53.899969899],
  [-79.0559701, 53.900339999],
  [-79.05628, 53.899960099],
  [-79.0547, 53.899799999],
  [-79.05334, 53.900929999],
  [-79.04812, 53.901229999],
  [-79.0485201, 53.902009999],
  [-79.0450801, 53.901439999],
  [-79.04505, 53.901950099],
  [-79.0424101, 53.902679999],
  [-79.04179, 53.902159999],
  [-79.04203, 53.901649899],
  [-79.0382997, 53.902907699],
  [-79.038115, 53.904188999],
  [-79.0333479, 53.904718099],
  [-79.029835, 53.904462299],
  [-79.0364577, 53.902177099],
  [-79.0357362, 53.901414699],
  [-79.0377204, 53.900519799],
  [-79.0373402, 53.898034399],
  [-79.0332061, 53.898500199],
  [-79.0339895, 53.896389799],
  [-79.033185, 53.894949599],
  [-79.0300027, 53.894500199],
  [-79.027503, 53.893013799],
  [-79.0250019, 53.893272599],
  [-79.0134099, 53.892209999],
  [-79.0126199, 53.890659999],
  [-79.0018201, 53.889579999],
  [-78.9980599, 53.891189899],
  [-78.9969201, 53.892449999],
  [-78.9938299, 53.893419899],
  [-78.9848328, 53.890025599],
  [-78.9841462, 53.888457499],
  [-78.9815713, 53.887091699],
  [-78.9789964, 53.887041099],
  [-78.9761639, 53.887445799],
  [-78.9761639, 53.888103399],
  [-78.9783955, 53.889317399],
  [-78.9783955, 53.890278499],
  [-78.9741898, 53.890885499],
  [-78.9720441, 53.890632599],
  [-78.9718724, 53.889216299],
  [-78.9678384, 53.888609299],
  [-78.9674951, 53.890177399],
  [-78.9655209, 53.889974999],
  [-78.9641477, 53.888912799],
  [-78.9602853, 53.889722099],
  [-78.9586545, 53.891644199],
  [-78.9539163, 53.893261999],
  [-78.9521056, 53.894498699],
  [-78.9545759, 53.895961199],
  [-78.9549621, 53.898026399],
  [-78.9530578, 53.898371499],
  [-78.9507719, 53.897802099],
  [-78.9501135, 53.898815699],
  [-78.9520066, 53.899944799],
  [-78.9560905, 53.900167199],
  [-78.95685, 53.900512799],
  [-78.9571237, 53.902701999],
  [-78.9641579, 53.904556499],
  [-78.9743334, 53.905061299],
  [-78.9796538, 53.903649699],
  [-78.9878221, 53.905054499],
  [-78.9923894, 53.904735399],
  [-78.99977, 53.905429899],
  [-79.0080699, 53.904159999],
  [-79.0080501, 53.904679999],
  [-79.0058499, 53.905149999],
  [-79.00514, 53.906429899],
  [-79.0100601, 53.907909999],
  [-79.0152701, 53.907609999],
  [-79.0099703, 53.910875999],
  [-79.00946, 53.911489999],
  [-79.0107499, 53.911639899],
  [-79.0107299, 53.912149999],
  [-79.00592, 53.912840099],
  [-79.005, 53.913840099],
  [-78.9988353, 53.915202099],
  [-78.9941592, 53.918135699],
  [-78.9918042, 53.920934599],
  [-78.9913061, 53.922801799],
  [-78.9917021, 53.924318099],
  [-78.9927399, 53.925300099],
  [-78.99532, 53.925729999],
  [-78.9956001, 53.924449899],
  [-78.998942, 53.923888299],
  [-79.0018519, 53.924724099],
  [-79.0030011, 53.927643899],
  [-79.0051501, 53.928970099],
  [-79.007744, 53.929946299],
  [-79.0095766, 53.929898099],
  [-79.0086351, 53.930731399],
  [-79.006464, 53.930936799],
  [-79.0047296, 53.932411599],
  [-79.0002964, 53.933515899],
  [-79.0003183, 53.934712099],
  [-79.0024421, 53.935884899],
  [-79.0059244, 53.935986299],
  [-79.0050475, 53.936667899],
  [-78.9978025, 53.938169999],
  [-78.990589, 53.936776399],
  [-78.9869599, 53.937119899],
  [-78.9908353, 53.937320499],
  [-78.9984053, 53.938912099],
  [-79.0067012, 53.936928899],
  [-79.007, 53.935659899],
  [-79.0026999, 53.935274699],
  [-79.0011836, 53.934139699],
  [-79.0115363, 53.930574699],
  [-79.01076, 53.929403099],
  [-79.0079179, 53.928949999],
  [-79.0058882, 53.927477099],
  [-79.00109, 53.918659899],
  [-78.9990735, 53.916559999],
  [-79.0001499, 53.916043699],
  [-79.0075199, 53.915809999],
  [-79.01023, 53.916669999],
  [-79.01399, 53.916559999],
  [-79.01914, 53.917669999],
  [-79.0191, 53.918689999],
  [-79.02122, 53.919620099],
  [-79.02815, 53.919989899],
  [-79.03158, 53.920949999],
  [-79.03134, 53.921459999],
  [-79.04159, 53.924960099],
  [-79.0417699, 53.925469999],
  [-79.0455101, 53.924759899],
  [-79.0457499, 53.924129999],
  [-79.06049, 53.924630099],
  [-79.06223, 53.924389999],
  [-79.06248, 53.923759899],
  [-79.0614299, 53.922719999],
  [-79.06404, 53.922869899],
  [-79.0650999, 53.923539899],
  [-79.06413, 53.925579999],
  [-79.0649801, 53.926099899],
  [-79.06513, 53.927379999],
  [-79.06557, 53.927379999],
  [-79.0656301, 53.926109899],
  [-79.07168, 53.926719999],
  [-79.0720801, 53.927369999],
  [-79.07337, 53.927520099],
  [-79.07384, 53.926889899],
  [-79.07514, 53.926909999],
  [-79.07727, 53.927839999],
  [-79.07724, 53.928349999],
  [-79.07791, 53.928099899],
  [-79.07759, 53.927159999],
  [-79.07558, 53.926789999],
  [-79.0747601, 53.925750099],
  [-79.07606, 53.925769899],
  [-79.0778999, 53.926989899],
  [-79.07947, 53.927090099],
  [-79.0794399, 53.927870099],
  [-79.08117, 53.928029999],
  [-79.0843399, 53.929740099],
  [-79.0845301, 53.930520099],
  [-79.0936699, 53.929889899],
  [-79.0944499, 53.931949999],
  [-79.0883, 53.933389999],
  [-79.08959, 53.933669899],
  [-79.08951, 53.935459999],
  [-79.0882101, 53.935439899],
  [-79.0903201, 53.936630099],
  [-79.0937799, 53.937069999],
  [-79.09396, 53.937839999],
  [-79.09547, 53.938119899],
  [-79.0958701, 53.938769899],
  [-79.1027801, 53.939640099],
  [-79.1057899, 53.940329899],
  [-79.1057601, 53.940839999],
  [-79.1096501, 53.941420099],
  [-79.10962, 53.941929999],
  [-79.1105, 53.941819999],
  [-79.1130099, 53.943779899],
  [-79.12253, 53.944689999],
  [-79.1230199, 53.943289999],
  [-79.1252201, 53.942809999],
  [-79.1291201, 53.942879899],
  [-79.1311101, 53.943719999],
  [-79.13132, 53.942009899],
  [-79.13176, 53.942019999],
  [-79.13174, 53.942540099],
  [-79.13387, 53.943459899],
  [-79.13518, 53.943349899],
  [-79.1360001, 53.944519999],
  [-79.14597, 53.944799999],
  [-79.14595, 53.945310099],
  [-79.15243, 53.946049999],
  [-79.15474, 53.948009899],
  [-79.1533899, 53.949019899],
  [-79.15311, 53.950289999],
  [-79.1509001, 53.951149999],
  [-79.14785, 53.951359999],
  [-79.13972, 53.948799999],
  [-79.1388901, 53.947779899],
  [-79.13544, 53.947200099],
  [-79.13506, 53.946179999],
  [-79.13332, 53.946139999],
  [-79.1351201, 53.944889899],
  [-79.1334, 53.944349899],
  [-79.13342, 53.943849999],
  [-79.1314, 53.943899899],
  [-79.1302401, 53.947119899],
  [-79.1293699, 53.947109899],
  [-79.1293301, 53.947880099],
  [-79.1254401, 53.947430099],
  [-79.12193, 53.948399999],
  [-79.1149901, 53.948029999],
  [-79.10729, 53.945609999],
  [-79.0865699, 53.942849999],
  [-79.08659, 53.942329899],
  [-79.0766401, 53.941530099],
  [-79.0667601, 53.939200099],
  [-79.0667799, 53.938689999],
  [-79.0447699, 53.935769899],
  [-79.0448, 53.935259999],
  [-79.0400601, 53.934410099],
  [-79.03959, 53.935169999],
  [-79.0387301, 53.935159999],
  [-79.0444299, 53.938579999],
  [-79.0446101, 53.939349999],
  [-79.04805, 53.939919999],
  [-79.04803, 53.940430099],
  [-79.0501701, 53.941109899],
  [-79.05494, 53.941310099],
  [-79.0549601, 53.940799999],
  [-79.06282, 53.939760099],
  [-79.06323, 53.940420099],
  [-79.0649601, 53.940449899],
  [-79.06494, 53.940959999],
  [-79.06625, 53.940980099],
  [-79.0684689, 53.945463999],
  [-79.07513, 53.946119899],
  [-79.0806079, 53.948001499],
  [-79.0806946, 53.946626699],
  [-79.0826279, 53.946678199],
  [-79.0842783, 53.945671899],
  [-79.0952266, 53.949476299],
  [-79.0986845, 53.949708199],
  [-79.0984487, 53.948912499],
  [-79.1017582, 53.948733899],
  [-79.1061747, 53.950023599],
  [-79.1039149, 53.951505799],
  [-79.0967635, 53.953012999],
  [-79.0957, 53.951959999],
  [-79.0922795, 53.952016599],
  [-79.0869701, 53.953100099],
  [-79.08694, 53.953609999],
  [-79.07827, 53.953219899],
  [-79.07874, 53.952459899],
  [-79.07617, 53.951650099],
  [-79.0762001, 53.951129899],
  [-79.0678501, 53.953179999],
  [-79.0523299, 53.950619999],
  [-79.04791, 53.952339899],
  [-79.0422099, 53.953279999],
  [-79.0291999, 53.952929999],
  [-79.02914, 53.954210099],
  [-79.0369799, 53.953829999],
  [-79.03956, 53.954389999],
  [-79.0385101, 53.958210099],
  [-79.0376399, 53.958200099],
  [-79.03759, 53.959220099],
  [-79.0358301, 53.959699999],
  [-79.0366, 53.961890099],
  [-79.0387599, 53.962059999],
  [-79.0404199, 53.963880099],
  [-79.0360601, 53.964059999],
  [-79.03604, 53.964579899],
  [-79.03691, 53.964589999],
  [-79.03681, 53.966639999],
  [-79.039, 53.966289999],
  [-79.05001, 53.967880099],
  [-79.0518799, 53.969569899],
  [-79.0538771, 53.970071999],
  [-79.0658942, 53.969293099],
  [-79.0656994, 53.969899999],
  [-79.0689005, 53.969370699],
  [-79.0697225, 53.969775499],
  [-79.0709989, 53.968983199],
  [-79.0768082, 53.968468299],
  [-79.0787242, 53.969060399],
  [-79.0778161, 53.968071799],
  [-79.0790885, 53.968752499],
  [-79.0788821, 53.969522599],
  [-79.0878259, 53.969305099],
  [-79.0935233, 53.968877699],
  [-79.0955751, 53.967814999],
  [-79.1021909, 53.967974099],
  [-79.1043185, 53.968480699],
  [-79.1035788, 53.968805099],
  [-79.1057338, 53.969548599],
  [-79.0951498, 53.970450499],
  [-79.0953773, 53.971561399],
  [-79.0919877, 53.973535099],
  [-79.080017, 53.975565199],
  [-79.077719, 53.975332099],
  [-79.0788254, 53.975230099],
  [-79.0790354, 53.974384299],
  [-79.0690701, 53.973699899],
  [-79.05903, 53.974689899],
  [-79.05901, 53.975199999],
  [-79.0559601, 53.975399999],
  [-79.0535285, 53.977718899],
  [-79.0519501, 53.977389999],
  [-79.0531431, 53.978305099],
  [-79.05624, 53.978609999],
  [-79.0605101, 53.980349899],
  [-79.05959, 53.981359899],
  [-79.0635599, 53.980139899],
  [-79.0635399, 53.980649999],
  [-79.0665599, 53.980959999],
  [-79.06481, 53.981179999],
  [-79.06523, 53.981829999],
  [-79.07436, 53.981589899],
  [-79.0647599, 53.982459899],
  [-79.0642901, 53.983220099],
  [-79.0603199, 53.984440099],
  [-79.06034, 53.983929899],
  [-79.0572901, 53.984139899],
  [-79.05526, 53.985639999],
  [-79.05545, 53.986279999],
  [-79.06414, 53.986419999],
  [-79.0658, 53.988120099],
  [-79.0679601, 53.988409999],
  [-79.06772, 53.988919899],
  [-79.06879, 53.989189999],
  [-79.0678501, 53.990709899],
  [-79.0700099, 53.991000099],
  [-79.06913, 53.991249899],
  [-79.07193, 53.992059999],
  [-79.0712199, 53.993069999],
  [-79.0720901, 53.993089999],
  [-79.07114, 53.994859999],
  [-79.0685201, 53.995079999],
  [-79.06849, 53.995589899],
  [-79.0663001, 53.996069999],
  [-79.0663199, 53.995560099],
  [-79.06371, 53.995509999],
  [-79.06368, 53.996279999],
  [-79.06193, 53.996509999],
  [-79.06195, 53.996000099],
  [-79.05894, 53.995439999],
  [-79.0588899, 53.996460099],
  [-79.05758, 53.996439999],
  [-79.05761, 53.995929899],
  [-79.0558501, 53.996399999],
  [-79.0558899, 53.995379899],
  [-79.0489799, 53.994519999],
  [-79.0480601, 53.995519999],
  [-79.0454399, 53.995729999],
  [-79.0458401, 53.996509999],
  [-79.04238, 53.996059999],
  [-79.0405516, 53.996555099],
  [-79.039501, 53.995881499],
  [-79.0391047, 53.996339099],
  [-79.0368809, 53.996177199],
  [-79.0371591, 53.995811399],
  [-79.035627, 53.994946599],
  [-79.0333927, 53.994944399],
  [-79.0318911, 53.995368399],
  [-79.0323856, 53.996057999],
  [-79.029618, 53.996246399],
  [-79.0301959, 53.995928299],
  [-79.0280693, 53.995644999],
  [-79.0184417, 53.998286199],
  [-79.01625, 53.997039899],
  [-79.01587, 53.995759999],
  [-79.00712, 53.996890099],
  [-79.0062999, 53.995859999],
  [-79.00414, 53.995560099],
  [-79.00322, 53.996570099],
  [-79.0010501, 53.996539999],
  [-79.00136, 53.996110099],
  [-78.9959401, 53.994399999],
  [-78.99554, 53.993629999],
  [-78.99255, 53.992550099],
  [-78.9926099, 53.991269999],
  [-78.98957, 53.991220099],
  [-78.9896199, 53.990199999],
  [-78.98178, 53.990450099],
  [-78.9786901, 53.991289999],
  [-78.9808101, 53.992609999],
  [-78.9790401, 53.993089999],
  [-78.97856, 53.994110099],
  [-78.97552, 53.993799899],
  [-78.97599, 53.993299999],
  [-78.97299, 53.992350099],
  [-78.96687, 53.993139899],
  [-78.9668199, 53.994169999],
  [-78.9659299, 53.994409999],
  [-78.9668001, 53.994419999],
  [-78.9659001, 53.995179999],
  [-78.96677, 53.995189999],
  [-78.96674, 53.995699899],
  [-78.96587, 53.995689899],
  [-78.9662799, 53.996209999],
  [-78.96543, 53.995939999],
  [-78.9648299, 53.999259899],
  [-78.9661301, 53.999279999],
  [-78.96611, 53.999790099],
  [-78.9680301, 54.000589899],
  [-78.96778, 54.001099999],
  [-78.96865, 54.001120099],
  [-78.96632, 54.004409999],
  [-78.96545, 54.004389999],
  [-78.9663, 54.004659999],
  [-78.9662699, 54.005429999],
  [-78.98222, 54.008259899],
  [-78.9849599, 54.010099999],
  [-78.98513, 54.010869999],
  [-78.9890599, 54.010669999],
  [-78.98856, 54.012209999],
  [-78.98986, 54.012230099],
  [-78.9898201, 54.012999999],
  [-78.9946099, 54.013079999],
  [-78.9946299, 54.012559999],
  [-78.9959499, 54.012209999],
  [-78.99812, 54.012369899],
  [-78.9985199, 54.013020099],
  [-79.00025, 54.013179999],
  [-79.00234, 54.015130099],
  [-79.0170699, 54.016269899],
  [-79.0171, 54.015759999],
  [-79.01925, 54.016309999],
  [-79.0197301, 54.015289999],
  [-79.02232, 54.015589899],
  [-79.0222999, 54.016099999],
  [-79.0291801, 54.017749999],
  [-79.03001, 54.018659999],
  [-79.0326, 54.018839999],
  [-79.03213, 54.019859999],
  [-79.033, 54.019869999],
  [-79.03289, 54.022169899],
  [-79.03722, 54.022609899],
  [-79.03871, 54.023289999],
  [-79.0393099, 54.024580099],
  [-79.038, 54.024819899],
  [-79.0379601, 54.025329999],
  [-79.0318301, 54.026250099],
  [-79.03136, 54.027009999],
  [-79.0291701, 54.027229999],
  [-79.02913, 54.028009999],
  [-79.02826, 54.027989999],
  [-79.02866, 54.028759999],
  [-79.02648, 54.028979999],
  [-79.0270601, 54.030529999],
  [-79.0289501, 54.031839899],
  [-79.0350601, 54.031429999],
  [-79.03547, 54.032089999],
  [-79.03981, 54.032269899],
  [-79.03978, 54.033040099],
  [-79.0279599, 54.034389899],
  [-79.0274901, 54.035150099],
  [-79.0257399, 54.035379899],
  [-79.0257, 54.036140099],
  [-79.0152701, 54.035729899],
  [-79.01523, 54.036749999],
  [-79.00865, 54.037659999],
  [-79.0086299, 54.038169899],
  [-78.9902601, 54.040040099],
  [-78.9898101, 54.040419999],
  [-79.0086001, 54.038810099],
  [-79.009, 54.039449999],
  [-79.0032899, 54.040509899],
  [-78.9875901, 54.041150099],
  [-78.9871699, 54.040629899],
  [-78.9898299, 54.039899999],
  [-78.9846099, 54.039820099],
  [-78.98421, 54.039040099],
  [-78.9824899, 54.038759999],
  [-78.98245, 54.039519999],
  [-78.9815801, 54.039499899],
  [-78.9820499, 54.038749999],
  [-78.9811799, 54.038729899],
  [-78.98165, 54.038229999],
  [-78.97989, 54.038189999],
  [-78.98196, 54.040679999],
  [-78.9853899, 54.041749999],
  [-79.0028201, 54.041270099],
  [-79.0100799, 54.039729899],
  [-79.00969, 54.038700099],
  [-79.01081, 54.038189999],
  [-79.01078, 54.038719899],
  [-79.01691, 54.038059899],
  [-79.0165001, 54.037529999],
  [-79.01912, 54.037329999],
  [-79.01923, 54.037999999],
  [-79.0282801, 54.036829899],
  [-79.02922, 54.035299999],
  [-79.0540999, 54.033789999],
  [-79.05413, 54.033279899],
  [-79.0554399, 54.033169899],
  [-79.0562901, 54.033569999],
  [-79.0576599, 54.032309999],
  [-79.06204, 54.031609899],
  [-79.06157, 54.032379899],
  [-79.06372, 54.032789999],
  [-79.0752457, 54.032124899],
  [-79.0794479, 54.031543399],
  [-79.0874326, 54.028037799],
  [-79.0716773, 54.025554699],
  [-79.0706956, 54.025239599],
  [-79.0703845, 54.023941299],
  [-79.0679866, 54.023266899],
  [-79.0753107, 54.022192999],
  [-79.0785201, 54.023419999],
  [-79.07849, 54.023920099],
  [-79.0806501, 54.024350099],
  [-79.08717, 54.024580099],
  [-79.0880901, 54.023570099],
  [-79.0928701, 54.023649999],
  [-79.0936799, 54.024939899],
  [-79.09516, 54.025739999],
  [-79.09492, 54.026240099],
  [-79.0962299, 54.026250099],
  [-79.0962001, 54.026779999],
  [-79.0987601, 54.027969999],
  [-79.10007, 54.027849999],
  [-79.10005, 54.028370099],
  [-79.10092, 54.028260099],
  [-79.1013399, 54.028910099],
  [-79.09348, 54.029549999],
  [-79.0936501, 54.030319999],
  [-79.09516, 54.030599899],
  [-79.0951199, 54.031370099],
  [-79.09119, 54.031820099],
  [-79.09116, 54.032329999],
  [-79.0894, 54.032820099],
  [-79.0902101, 54.034109999],
  [-79.09459, 54.033409999],
  [-79.0945599, 54.034179899],
  [-79.0884, 54.035619899],
  [-79.0861299, 54.037629999],
  [-79.07382, 54.040250099],
  [-79.0731199, 54.041270099],
  [-79.0736599, 54.043710099],
  [-79.07718, 54.043009999],
  [-79.0863201, 54.043019999],
  [-79.08585, 54.043779999],
  [-79.0998137, 54.043314199],
  [-79.1012943, 54.042655899],
  [-79.10469, 54.042901599],
  [-79.1075653, 54.041427399],
  [-79.1135929, 54.041293299],
  [-79.1134793, 54.042236799],
  [-79.1158377, 54.042719999],
  [-79.110183, 54.044850299],
  [-79.1050547, 54.044967799],
  [-79.1044217, 54.045493799],
  [-79.1110806, 54.046184199],
  [-79.1106702, 54.046964799],
  [-79.1127701, 54.047280899],
  [-79.1082293, 54.049299699],
  [-79.1106337, 54.049544099],
  [-79.0964818, 54.051672799],
  [-79.09063, 54.053459999],
  [-79.0836501, 54.053729899],
  [-79.0836699, 54.053219999],
  [-79.0749899, 54.052700099],
  [-79.0727799, 54.053299899],
  [-79.07184, 54.054830099],
  [-79.07751, 54.054789999],
  [-79.0805, 54.055859899],
  [-79.0896699, 54.055370099],
  [-79.08984, 54.056390099],
  [-79.09219, 54.057459999],
  [-79.09217, 54.057969899],
  [-79.09519, 54.058529999],
  [-79.0952299, 54.057500099],
  [-79.0965799, 54.056629899],
  [-79.10708, 54.055639999],
  [-79.1074902, 54.056727199],
  [-79.1065085, 54.057293999],
  [-79.1061022, 54.060259599],
  [-79.1089654, 54.061563499],
  [-79.1127432, 54.061723999],
  [-79.1084826, 54.063103099],
  [-79.1080588, 54.062479699],
  [-79.1012085, 54.063156599],
  [-79.099347, 54.062740999],
  [-79.0871161, 54.063043299],
  [-79.0830797, 54.062432999],
  [-79.0790963, 54.062948799],
  [-79.0767735, 54.062416799],
  [-79.0766984, 54.062914199],
  [-79.0824544, 54.064491499],
  [-79.0951681, 54.063531299],
  [-79.1081929, 54.064538699],
  [-79.11565, 54.063459999],
  [-79.1163401, 54.062579999],
  [-79.12287, 54.062679999],
  [-79.12373, 54.062820099],
  [-79.12231, 54.065359999],
  [-79.1249299, 54.065529899],
  [-79.1275601, 54.064799999],
  [-79.1397901, 54.064219999],
  [-79.1414799, 54.065399899],
  [-79.13885, 54.065619899],
  [-79.1379199, 54.066889999],
  [-79.1392301, 54.066899999],
  [-79.1339001, 54.069129999],
  [-79.13454, 54.069639899],
  [-79.13428, 54.070409899],
  [-79.1290199, 54.071229999],
  [-79.1251101, 54.071039999],
  [-79.1254799, 54.072329999],
  [-79.12808, 54.072620099],
  [-79.1281101, 54.072109999],
  [-79.12985, 54.072139999],
  [-79.1303, 54.071510099],
  [-79.1346701, 54.071319999],
  [-79.13509, 54.071710099],
  [-79.1333501, 54.071679999],
  [-79.13376, 54.072329999],
  [-79.1384899, 54.073809999],
  [-79.13017, 54.074959899],
  [-79.12877, 54.076730099],
  [-79.13005, 54.077390099],
  [-79.1322401, 54.077170099],
  [-79.13265, 54.077559999],
  [-79.1313401, 54.077809999],
  [-79.13261, 54.078459999],
  [-79.1361, 54.078510099],
  [-79.1369299, 54.079419899],
  [-79.1386801, 54.079189899],
  [-79.1386999, 54.078669999],
  [-79.14044, 54.078709999],
  [-79.1418001, 54.077699999],
  [-79.1391499, 54.078429899],
  [-79.13784, 54.078409899],
  [-79.13787, 54.077899999],
  [-79.14665, 54.076239999],
  [-79.1465999, 54.077390099],
  [-79.1491, 54.076959899],
  [-79.1488299, 54.076280099],
  [-79.1496999, 54.076290099],
  [-79.14888, 54.075249999],
  [-79.14975, 54.075259999],
  [-79.1500201, 54.073989999],
  [-79.1529001, 54.073009999],
  [-79.15274, 54.071969899],
  [-79.15644, 54.071649999],
  [-79.1564, 54.072549999],
  [-79.1585701, 54.072699999],
  [-79.1621, 54.071869899],
  [-79.1615999, 54.073149999],
  [-79.16031, 54.073119999],
  [-79.1600499, 54.073889999],
  [-79.16156, 54.074170099],
  [-79.16023, 54.074659999],
  [-79.1641401, 54.074969899],
  [-79.16411, 54.075479999],
  [-79.1605701, 54.076840099],
  [-79.15145, 54.076050099],
  [-79.15185, 54.076830099],
  [-79.14833, 54.077809999],
  [-79.14133, 54.078469999],
  [-79.14306, 54.078749899],
  [-79.1430101, 54.079769999],
  [-79.1441599, 54.079960099],
  [-79.14565, 54.079170099],
  [-79.14695, 54.079319999],
  [-79.14677, 54.078549999],
  [-79.14874, 54.078319999],
  [-79.1478199, 54.079329999],
  [-79.14428, 54.080299899],
  [-79.1403799, 54.080239999],
  [-79.13987, 54.081769999],
  [-79.1390001, 54.081759899],
  [-79.1394, 54.082529899],
  [-79.1511499, 54.082840099],
  [-79.16432, 54.080739899],
  [-79.17255, 54.081759899],
  [-79.1733799, 54.082799999],
  [-79.17598, 54.083089899],
  [-79.1763899, 54.083610099],
  [-79.1720301, 54.083799999],
  [-79.17244, 54.084319899],
  [-79.1711301, 54.084299899],
  [-79.1702, 54.085569999],
  [-79.1649499, 54.086259999],
  [-79.1617299, 54.090049999],
  [-79.1680301, 54.090400099],
  [-79.16821, 54.091180099],
  [-79.16734, 54.091159999],
  [-79.16734, 54.096300099],
  [-79.1652699, 54.098559999],
  [-79.1665801, 54.098709999],
  [-79.1678299, 54.099879899],
  [-79.1691501, 54.099649899],
  [-79.1691, 54.100669999],
  [-79.1628962, 54.101588999],
  [-79.1599399, 54.100779999],
  [-79.16043, 54.099769899],
  [-79.1573799, 54.099719999],
  [-79.1563213, 54.098384899],
  [-79.1567141, 54.098075899],
  [-79.1542933, 54.098235999],
  [-79.1531146, 54.097415299],
  [-79.1494568, 54.096995399],
  [-79.1493058, 54.096213599],
  [-79.1481762, 54.096422099],
  [-79.1453254, 54.095411399],
  [-79.1492033, 54.094342799],
  [-79.1536593, 54.094538899],
  [-79.1579966, 54.095542499],
  [-79.1596801, 54.096939999],
  [-79.16401, 54.097639899],
  [-79.1644701, 54.097009999],
  [-79.1631501, 54.097249999],
  [-79.16212, 54.095950099],
  [-79.16243, 54.093909999],
  [-79.14855, 54.092410099],
  [-79.1478099, 54.089329999],
  [-79.1443799, 54.088119999],
  [-79.14133, 54.088199899],
  [-79.1413501, 54.087689999],
  [-79.1361599, 54.086840099],
  [-79.1349299, 54.085029999],
  [-79.13295, 54.085510099],
  [-79.1331101, 54.086789999],
  [-79.1295999, 54.087249999],
  [-79.13307, 54.087819999],
  [-79.1313, 54.088299899],
  [-79.13132, 54.087789999],
  [-79.12821, 54.089149999],
  [-79.11864, 54.088859899],
  [-79.11861, 54.089379999],
  [-79.1106999, 54.090799999],
  [-79.1106799, 54.091309899],
  [-79.10894, 54.091280099],
  [-79.1089199, 54.091789999],
  [-79.10014, 54.093190099],
  [-79.09919, 54.094719999],
  [-79.09392, 54.095659899],
  [-79.0938999, 54.096170099],
  [-79.0886601, 54.096209899],
  [-79.0852101, 54.095520099],
  [-79.0853399, 54.092699999],
  [-79.08363, 54.092029999],
  [-79.0744499, 54.092659899],
  [-79.07047, 54.094129999],
  [-79.06524, 54.093919999],
  [-79.06492, 54.091349999],
  [-79.0637599, 54.091159999],
  [-79.06401, 54.092109999],
  [-79.06012, 54.091269999],
  [-79.0600701, 54.092549999],
  [-79.05881, 54.091510099],
  [-79.05661, 54.091989899],
  [-79.05615, 54.092489999],
  [-79.0578899, 54.092520099],
  [-79.05648, 54.094539899],
  [-79.05735, 54.094559999],
  [-79.0568799, 54.095449999],
  [-79.05861, 54.095850099],
  [-79.0585499, 54.097139999],
  [-79.06683, 54.097269999],
  [-79.06506, 54.097759899],
  [-79.06459, 54.098520099],
  [-79.05115, 54.100009999],
  [-79.0531901, 54.099869899],
  [-79.0528799, 54.100290099],
  [-79.05358, 54.100899999],
  [-79.05258, 54.100459999],
  [-79.0509601, 54.101119999],
  [-79.05704, 54.101469999],
  [-79.0578501, 54.102640099],
  [-79.05916, 54.102789999],
  [-79.0586499, 54.104319899],
  [-79.0547301, 54.104249999],
  [-79.05382, 54.105009999],
  [-79.0586201, 54.104829999],
  [-79.0577, 54.105840099],
  [-79.0612099, 54.105510099],
  [-79.0614101, 54.105909999],
  [-79.05912, 54.108169999],
  [-79.0588, 54.110469999],
  [-79.0600999, 54.110489999],
  [-79.0600801, 54.111009999],
  [-79.06835, 54.111259999],
  [-79.07346, 54.114029999],
  [-79.0734201, 54.114799999],
  [-79.07429, 54.114819999],
  [-79.07427, 54.115329899],
  [-79.0759899, 54.115609999],
  [-79.07638, 54.116899999],
  [-79.0879801, 54.120669899],
  [-79.08814, 54.121949999],
  [-79.0904599, 54.123659899],
  [-79.0985, 54.124169999],
  [-79.0982201, 54.125439899],
  [-79.0971199, 54.125939999],
  [-79.10248, 54.127819999],
  [-79.1015699, 54.128569899],
  [-79.10026, 54.128549899],
  [-79.1010901, 54.129459899],
  [-79.1068099, 54.128530099],
  [-79.10808, 54.129310099],
  [-79.1115899, 54.128860099],
  [-79.1120099, 54.129249999],
  [-79.1098099, 54.129729999],
  [-79.1100199, 54.134599999],
  [-79.11349, 54.135019999],
  [-79.11252, 54.137200099],
  [-79.11554, 54.138009899],
  [-79.11504, 54.139289999],
  [-79.11283, 54.139760099],
  [-79.1123599, 54.140779899],
  [-79.10883, 54.141499999],
  [-79.1079099, 54.142509999],
  [-79.1035501, 54.142559899],
  [-79.10096, 54.141880099],
  [-79.1009901, 54.141369999],
  [-79.09751, 54.141059999],
  [-79.0952299, 54.143069999],
  [-79.0873201, 54.144499999],
  [-79.08684, 54.145249999],
  [-79.08027, 54.145909899],
  [-79.07928, 54.148459899],
  [-79.0779801, 54.148179999],
  [-79.07837, 54.149210099],
  [-79.07969, 54.148980099],
  [-79.08048, 54.150779899],
  [-79.0819901, 54.151059999],
  [-79.0816699, 54.153110099],
  [-79.09084, 54.153000099],
  [-79.09082, 54.153760099],
  [-79.08484, 54.154009899],
  [-79.0828999, 54.155049999],
  [-79.07549, 54.154799899],
  [-79.0745499, 54.156320099],
  [-79.06793, 54.157749999],
  [-79.06517, 54.160780099],
  [-79.0577353, 54.162810099],
  [-79.0624399, 54.163299999],
  [-79.0615599, 54.163289999],
  [-79.0610901, 54.164049999],
  [-79.07206, 54.162699899],
  [-79.0693399, 54.164949999],
  [-79.0719999, 54.164100099],
  [-79.0798601, 54.164089999],
  [-79.0780901, 54.164589899],
  [-79.07717, 54.165589899],
  [-79.0758601, 54.165569899],
  [-79.0779875, 54.166724599],
  [-79.0761339, 54.168174699],
  [-79.08541, 54.166739999],
  [-79.0854399, 54.166230099],
  [-79.0863101, 54.166249899],
  [-79.08662, 54.164199999],
  [-79.09519, 54.163059999],
  [-79.0978801, 54.161430099],
  [-79.10226, 54.161119899],
  [-79.10229, 54.160609999],
  [-79.1035699, 54.161269999],
  [-79.11008, 54.162009899],
  [-79.11208, 54.161269999],
  [-79.1113401, 54.163049999],
  [-79.11828, 54.164059999],
  [-79.11831, 54.163430099],
  [-79.11918, 54.163430099],
  [-79.11904, 54.161890099],
  [-79.1245601, 54.160309999],
  [-79.12496, 54.161220099],
  [-79.1337801, 54.162540099],
  [-79.1432599, 54.161890099],
  [-79.15152, 54.162780099],
  [-79.15373, 54.162049999],
  [-79.1559001, 54.162340099],
  [-79.1580201, 54.163780099],
  [-79.16021, 54.163679899],
  [-79.16321, 54.164749999],
  [-79.1737199, 54.164269999],
  [-79.1784999, 54.164599999],
  [-79.1784801, 54.165110099],
  [-79.1805099, 54.165320099],
  [-79.18199, 54.164909899],
  [-79.18112, 54.164639999],
  [-79.1811601, 54.163869999],
  [-79.1824701, 54.163890099],
  [-79.1820599, 54.163120099],
  [-79.18119, 54.163110099],
  [-79.1808201, 54.161560099],
  [-79.1860599, 54.161509999],
  [-79.1891799, 54.160019899],
  [-79.1913701, 54.159919899],
  [-79.18934, 54.161430099],
  [-79.1917201, 54.161979999],
  [-79.1917001, 54.162499999],
  [-79.2033999, 54.160789899],
  [-79.20492, 54.159479999],
  [-79.21023, 54.157780099],
  [-79.2137201, 54.157829999],
  [-79.21682, 54.156980099],
  [-79.2168599, 54.155949999],
  [-79.20902, 54.155579899],
  [-79.2094901, 54.154819999],
  [-79.20778, 54.154279999],
  [-79.20781, 54.153259999],
  [-79.2003701, 54.153670099],
  [-79.20088, 54.152129899],
  [-79.2017499, 54.152139899],
  [-79.20177, 54.151619999],
  [-79.21357, 54.151289999],
  [-79.2127, 54.151279999],
  [-79.2122999, 54.150249899],
  [-79.2092701, 54.149689899],
  [-79.2093099, 54.148919999],
  [-79.2058401, 54.148359899],
  [-79.2085299, 54.146729999],
  [-79.21159, 54.146650099],
  [-79.2116101, 54.146139999],
  [-79.20988, 54.145859999],
  [-79.2099, 54.145349899],
  [-79.20859, 54.145330099],
  [-79.2081899, 54.144550099],
  [-79.20602, 54.144259999],
  [-79.2073801, 54.143129899],
  [-79.20912, 54.143159999],
  [-79.21002, 54.142530099],
  [-79.2126201, 54.142949999],
  [-79.21337, 54.142409999],
  [-79.2130899, 54.142059999],
  [-79.2157, 54.142359999],
  [-79.2152901, 54.141839999],
  [-79.21703, 54.141860099],
  [-79.21701, 54.142379999],
  [-79.22093, 54.142440099],
  [-79.2209, 54.143200099],
  [-79.2278501, 54.143819999],
  [-79.2271701, 54.144579899],
  [-79.23213, 54.145929999],
  [-79.23569, 54.144320099],
  [-79.24528, 54.144459899],
  [-79.2461901, 54.143569899],
  [-79.2453199, 54.143559899],
  [-79.2497599, 54.141709999],
  [-79.2552001, 54.142169999],
  [-79.2549, 54.143959999],
  [-79.25137, 54.144919999],
  [-79.2513199, 54.146469899],
  [-79.25305, 54.146489999],
  [-79.25303, 54.146999899],
  [-79.25649, 54.147819999],
  [-79.2543001, 54.147789899],
  [-79.25383, 54.148809999],
  [-79.2485499, 54.149760099],
  [-79.25025, 54.150809999],
  [-79.25027, 54.150299999],
  [-79.25092, 54.150559899],
  [-79.25023, 54.151320099],
  [-79.25415, 54.151379999],
  [-79.25413, 54.151890099],
  [-79.2646599, 54.150499999],
  [-79.26462, 54.151529999],
  [-79.26548, 54.151799899],
  [-79.26062, 54.153389999],
  [-79.23736, 54.156379999],
  [-79.2347401, 54.156220099],
  [-79.23436, 54.154929999],
  [-79.23127, 54.155780099],
  [-79.22734, 54.155839999],
  [-79.2266301, 54.157119899],
  [-79.2303099, 54.157819999],
  [-79.2320799, 54.157199999],
  [-79.2320601, 54.157709999],
  [-79.2368601, 54.157909899],
  [-79.2465, 54.156650099],
  [-79.24646, 54.157929999],
  [-79.24072, 54.159509999],
  [-79.23502, 54.160059999],
  [-79.23501, 54.160579899],
  [-79.23278, 54.161309999],
  [-79.2314101, 54.162829999],
  [-79.2305299, 54.162819999],
  [-79.2296201, 54.164079999],
  [-79.2278501, 54.164309999],
  [-79.22782, 54.165079999],
  [-79.2269501, 54.165069999],
  [-79.22648, 54.165829999],
  [-79.22122, 54.166269999],
  [-79.2198501, 54.167529999],
  [-79.2176499, 54.168010099],
  [-79.21756, 54.170049999],
  [-79.2206201, 54.170100099],
  [-79.2203199, 54.171890099],
  [-79.2218301, 54.172419999],
  [-79.2226, 54.174739999],
  [-79.23223, 54.174110099],
  [-79.2321999, 54.174880099],
  [-79.2299699, 54.176129899],
  [-79.23236, 54.176419999],
  [-79.2322901, 54.177959999],
  [-79.23337, 54.178359899],
  [-79.2364299, 54.178279999],
  [-79.2364001, 54.178790099],
  [-79.2297699, 54.180989999],
  [-79.22859, 54.182769999],
  [-79.2324001, 54.184020099],
  [-79.24057, 54.183460099],
  [-79.2414001, 54.184489899],
  [-79.24448, 54.183900099],
  [-79.24928, 54.184350099],
  [-79.2435301, 54.185809899],
  [-79.24435, 54.186969999],
  [-79.2496, 54.186790099],
  [-79.25002, 54.187179999],
  [-79.24914, 54.187429999],
  [-79.2513199, 54.187709899],
  [-79.2491099, 54.188189999],
  [-79.2481901, 54.189209999],
  [-79.2455499, 54.189680099],
  [-79.24417, 54.191199999],
  [-79.2433099, 54.190929899],
  [-79.2432901, 54.191439999],
  [-79.2404101, 54.192419999],
  [-79.2402901, 54.193609999],
  [-79.24101, 54.193699899],
  [-79.2409899, 54.194219999],
  [-79.24667, 54.194309999],
  [-79.2466201, 54.195329999],
  [-79.24882, 54.195109999],
  [-79.24879, 54.195619999],
  [-79.24747, 54.195859999],
  [-79.2465399, 54.197379899],
  [-79.2427501, 54.199120099],
  [-79.24245, 54.200910099],
  [-79.24158, 54.200889999],
  [-79.2467799, 54.201869999],
  [-79.2475499, 54.200889999],
  [-79.2472901, 54.200209999],
  [-79.2464201, 54.200199999],
  [-79.2451801, 54.198379899],
  [-79.25138, 54.196549999],
  [-79.2544399, 54.196340099],
  [-79.25559, 54.194949999],
  [-79.2547401, 54.194419999],
  [-79.2542001, 54.191849999],
  [-79.2553001, 54.191609999],
  [-79.25468, 54.190839999],
  [-79.2573299, 54.190109999],
  [-79.2574, 54.188309999],
  [-79.2560901, 54.188299999],
  [-79.25569, 54.187519999],
  [-79.25261, 54.187989999],
  [-79.2530999, 54.186709899],
  [-79.25092, 54.186680099],
  [-79.25046, 54.187189999],
  [-79.2516301, 54.185399999],
  [-79.2576, 54.183709899],
  [-79.2581099, 54.181919899],
  [-79.26168, 54.180179999],
  [-79.27526, 54.179089999],
  [-79.2753299, 54.177299999],
  [-79.2762001, 54.177309999],
  [-79.27716, 54.175279999],
  [-79.2797799, 54.175309999],
  [-79.2797601, 54.175829999],
  [-79.28105, 54.176359899],
  [-79.27794, 54.177589899],
  [-79.2787601, 54.178890099],
  [-79.2826799, 54.179199999],
  [-79.28271, 54.178429999],
  [-79.2835799, 54.178440099],
  [-79.2845699, 54.175639999],
  [-79.2867701, 54.175409999],
  [-79.2894401, 54.174039899],
  [-79.2934, 54.173709999],
  [-79.2921299, 54.172419999],
  [-79.2947701, 54.171809899],
  [-79.29608, 54.171959999],
  [-79.29606, 54.172469899],
  [-79.2934301, 54.172689899],
  [-79.29341, 54.173199999],
  [-79.29559, 54.173230099],
  [-79.2960001, 54.173749999],
  [-79.29604, 54.172979999],
  [-79.2991101, 54.172509999],
  [-79.29909, 54.173039899],
  [-79.3008099, 54.173429999],
  [-79.3034301, 54.173340099],
  [-79.30416, 54.171560099],
  [-79.30661, 54.170709999],
  [-79.3140399, 54.170419999],
  [-79.3144401, 54.171450099],
  [-79.3095601, 54.173429999],
  [-79.30951, 54.174450099],
  [-79.3055601, 54.174909899],
  [-79.30598, 54.175299999],
  [-79.3051001, 54.175670099],
  [-79.3054, 54.175289999],
  [-79.30162, 54.175239899],
  [-79.29985, 54.175859999],
  [-79.29931, 54.178409999],
  [-79.3014501, 54.179460099],
  [-79.3102201, 54.178570099],
  [-79.31063, 54.179209999],
  [-79.3146, 54.178110099],
  [-79.3142201, 54.177079999],
  [-79.32299, 54.175919899],
  [-79.32352, 54.173629999],
  [-79.32409, 54.174059999],
  [-79.3256999, 54.173660099],
  [-79.32483, 54.173639999],
  [-79.32531, 54.172629999],
  [-79.3322599, 54.173489999],
  [-79.33221, 54.174770099],
  [-79.33328, 54.175039899],
  [-79.3332599, 54.175809899],
  [-79.33186, 54.178099999],
  [-79.3329199, 54.178749999],
  [-79.33684, 54.178929899],
  [-79.3368199, 54.179450099],
  [-79.34118, 54.179759999],
  [-79.3418001, 54.180539999],
  [-79.34154, 54.181560099],
  [-79.3358299, 54.182509999],
  [-79.3349199, 54.183259899],
  [-79.33622, 54.183649999],
  [-79.35896, 54.182819899],
  [-79.3589399, 54.183340099],
  [-79.36308, 54.183649999],
  [-79.3628399, 54.184159999],
  [-79.36631, 54.184839999],
  [-79.36677, 54.184209999],
  [-79.3724701, 54.183519999],
  [-79.37297, 54.181989999],
  [-79.3633601, 54.182120099],
  [-79.3636199, 54.181089999],
  [-79.3660401, 54.180359899],
  [-79.36679, 54.178059999],
  [-79.37276, 54.176220099],
  [-79.3758201, 54.176139899],
  [-79.37535, 54.176900099],
  [-79.37686, 54.177429999],
  [-79.3766199, 54.177939999],
  [-79.3779301, 54.177959999],
  [-79.3777399, 54.177440099],
  [-79.3792799, 54.177079999],
  [-79.3840401, 54.178039899],
  [-79.3835901, 54.178550099],
  [-79.38846, 54.176689899],
  [-79.3924099, 54.176359899],
  [-79.39232, 54.178660099],
  [-79.3826001, 54.181469899],
  [-79.37867, 54.181550099],
  [-79.3782, 54.182319999],
  [-79.3794999, 54.182460099],
  [-79.3818499, 54.183900099],
  [-79.3816101, 54.184409999],
  [-79.3842, 54.185209999],
  [-79.38332, 54.185460099],
  [-79.3828201, 54.186989999],
  [-79.38501, 54.186890099],
  [-79.3854099, 54.187790099],
  [-79.38365, 54.188020099],
  [-79.3836299, 54.188539999],
  [-79.3819, 54.188259899],
  [-79.38188, 54.188769999],
  [-79.3783801, 54.188979999],
  [-79.3783999, 54.188469899],
  [-79.37399, 54.189429999],
  [-79.37401, 54.188919899],
  [-79.3720699, 54.188379899],
  [-79.37232, 54.187359899],
  [-79.37057, 54.187599899],
  [-79.3704999, 54.189379899],
  [-79.3665901, 54.188819899],
  [-79.3666099, 54.188309999],
  [-79.3648499, 54.188539999],
  [-79.3635801, 54.187629999],
  [-79.3604899, 54.188479899],
  [-79.35974, 54.190780099],
  [-79.36824, 54.191149899],
  [-79.3681799, 54.192680099],
  [-79.37124, 54.192729999],
  [-79.36989, 54.193599899],
  [-79.36553, 54.193539999],
  [-79.36287, 54.194409999],
  [-79.36289, 54.193889999],
  [-79.3602899, 54.193470099],
  [-79.35678, 54.193809899],
  [-79.3563601, 54.193289999],
  [-79.3585999, 54.192039899],
  [-79.3548101, 54.192079999],
  [-79.3554999, 54.193020099],
  [-79.35419, 54.193010099],
  [-79.3521301, 54.195279999],
  [-79.35231, 54.196309999],
  [-79.3549299, 54.196479899],
  [-79.35532, 54.197629999],
  [-79.354, 54.197869999],
  [-79.35352, 54.198629999],
  [-79.3553, 54.198020099],
  [-79.3559499, 54.198149899],
  [-79.3557001, 54.198919899],
  [-79.3543799, 54.199159899],
  [-79.3543601, 54.199669999],
  [-79.3526199, 54.199649999],
  [-79.3499101, 54.201659999],
  [-79.3531599, 54.202470099],
  [-79.3533501, 54.203240099],
  [-79.3568299, 54.203419999],
  [-79.3608001, 54.202570099],
  [-79.36032, 54.203589899],
  [-79.35633, 54.205199999],
  [-79.34886, 54.205999999],
  [-79.3488299, 54.207020099],
  [-79.3431201, 54.207709899],
  [-79.3431401, 54.207199999],
  [-79.34053, 54.206779999],
  [-79.33084, 54.208819899],
  [-79.33264, 54.207309999],
  [-79.33133, 54.207289999],
  [-79.32987, 54.205739999],
  [-79.3336701, 54.203479899],
  [-79.3336999, 54.202719899],
  [-79.3274899, 54.205069999],
  [-79.3200201, 54.206109999],
  [-79.32084, 54.207399999],
  [-79.3177801, 54.207360099],
  [-79.31597, 54.209130099],
  [-79.31062, 54.211609899],
  [-79.3110199, 54.212519999],
  [-79.31275, 54.212800099],
  [-79.31849, 54.211599899],
  [-79.32161, 54.210099999],
  [-79.32817, 54.209810099],
  [-79.3281, 54.211599899],
  [-79.3241201, 54.212829899],
  [-79.32274, 54.214350099],
  [-79.31966, 54.215069999],
  [-79.31964, 54.215590099],
  [-79.3086601, 54.216839899],
  [-79.3042, 54.218829899],
  [-79.3024501, 54.218930099],
  [-79.3024301, 54.219439999],
  [-79.3011299, 54.219049899],
  [-79.2954, 54.220240099],
  [-79.2927801, 54.220329999],
  [-79.2927999, 54.219820099],
  [-79.2910199, 54.220429999],
  [-79.2866501, 54.220489899],
  [-79.2862399, 54.219729899],
  [-79.28186, 54.219910099],
  [-79.27936, 54.222449999],
  [-79.27949, 54.224499899],
  [-79.2809999, 54.225030099],
  [-79.28073, 54.226049899],
  [-79.2842299, 54.226229999],
  [-79.29129, 54.224539999],
  [-79.2956701, 54.224470099],
  [-79.2965, 54.225509899],
  [-79.2977999, 54.225779999],
  [-79.29782, 54.225269899],
  [-79.2995699, 54.225289899],
  [-79.29381, 54.227129999],
  [-79.28808, 54.228209999],
  [-79.28536, 54.230470099],
  [-79.29196, 54.229539999],
  [-79.29194, 54.230049899],
  [-79.2822001, 54.232989999],
  [-79.28217, 54.233499899],
  [-79.28085, 54.233739899],
  [-79.2812001, 54.235779999],
  [-79.28252, 54.235810099],
  [-79.2822101, 54.236229999],
  [-79.28292, 54.236590099],
  [-79.28294, 54.236069899],
  [-79.28825, 54.234739899],
  [-79.2983, 54.234759999],
  [-79.29952, 54.237079999],
  [-79.2977701, 54.237050099],
  [-79.29641, 54.238319999],
  [-79.2999099, 54.238109999],
  [-79.29853, 54.239879999],
  [-79.2972, 54.240380099],
  [-79.30338, 54.238930099],
  [-79.3033599, 54.239439999],
  [-79.3004699, 54.240679999],
  [-79.30285, 54.241219999],
  [-79.30196, 54.241459999],
  [-79.3050299, 54.241249999],
  [-79.30429, 54.243289899],
  [-79.31152, 54.243139999],
  [-79.31154, 54.242639999],
  [-79.31329, 54.242519899],
  [-79.3173699, 54.244500099],
  [-79.3204501, 54.249280099],
  [-79.3370499, 54.250029999],
  [-79.3388299, 54.249409899],
  [-79.33889, 54.247619899],
  [-79.34197, 54.247150099],
  [-79.34199, 54.246639899],
  [-79.34287, 54.246649999],
  [-79.342, 54.246380099],
  [-79.34378, 54.245889999],
  [-79.34289, 54.246139999],
  [-79.3498399, 54.247260099],
  [-79.35023, 54.248539999],
  [-79.3550201, 54.249119999],
  [-79.3588399, 54.251989999],
  [-79.3663, 54.251579999],
  [-79.36709, 54.253509899],
  [-79.3688101, 54.254179899],
  [-79.3697299, 54.253289899],
  [-79.3732601, 54.252439999],
  [-79.3746199, 54.251309999],
  [-79.37947, 54.250219999],
  [-79.37812, 54.251229999],
  [-79.37943, 54.251239999],
  [-79.37757, 54.254040099],
  [-79.3762501, 54.254270099],
  [-79.37621, 54.255289899],
  [-79.3812999, 54.253830099],
  [-79.3824099, 54.253329999],
  [-79.38244, 54.252569999],
  [-79.3868399, 54.252109999],
  [-79.38546, 54.253629899],
  [-79.38414, 54.253869999],
  [-79.38434, 54.254380099],
  [-79.39281, 54.256019999],
  [-79.3928499, 54.254999999],
  [-79.3919599, 54.254999999],
  [-79.39243, 54.254619899],
  [-79.3959301, 54.254539999],
  [-79.3972899, 54.253399899],
  [-79.40302, 54.252069899],
  [-79.40277, 54.253089999],
  [-79.40604, 54.253270099],
  [-79.4184299, 54.249710099],
  [-79.4175101, 54.250729899],
  [-79.4166399, 54.250710099],
  [-79.4139, 54.253749899],
  [-79.41302, 54.253739899],
  [-79.41344, 54.254260099],
  [-79.41258, 54.253979999],
  [-79.41255, 54.254759999],
  [-79.40237, 54.257699999],
  [-79.40212, 54.258459999],
  [-79.40714, 54.258799999],
  [-79.40712, 54.259299899],
  [-79.4097599, 54.258820099],
  [-79.40958, 54.257789999],
  [-79.41071, 54.256909999],
  [-79.41825, 54.254449999],
  [-79.41867, 54.254840099],
  [-79.41555, 54.256079899],
  [-79.4137401, 54.257849899],
  [-79.4110899, 54.258579999],
  [-79.4110601, 54.259349999],
  [-79.4097499, 54.259089899],
  [-79.4097399, 54.259589999],
  [-79.4075199, 54.260329999],
  [-79.40704, 54.261349999],
  [-79.41056, 54.260879999],
  [-79.4109699, 54.261529899],
  [-79.41404, 54.261309899],
  [-79.4166201, 54.262629899],
  [-79.41969, 54.262539999],
  [-79.4191899, 54.264069899],
  [-79.4183001, 54.264439999],
  [-79.4047399, 54.264259999],
  [-79.40121, 54.265109999],
  [-79.4011899, 54.265620099],
  [-79.39899, 54.265849899],
  [-79.39858, 54.265079899],
  [-79.39637, 54.265819999],
  [-79.398, 54.264979899],
  [-79.3981799, 54.264299899],
  [-79.3990501, 54.264319999],
  [-79.39847, 54.262259999],
  [-79.39915, 54.261759899],
  [-79.39569, 54.260809999],
  [-79.3921799, 54.261019999],
  [-79.3903999, 54.261639899],
  [-79.3912701, 54.261909999],
  [-79.39081, 54.262280099],
  [-79.38075, 54.262409899],
  [-79.3772799, 54.261459999],
  [-79.3772501, 54.262229999],
  [-79.3759301, 54.262469999],
  [-79.3762899, 54.264519899],
  [-79.3780401, 54.264549999],
  [-79.37844, 54.265319999],
  [-79.3749401, 54.265529899],
  [-79.3754099, 54.264510099],
  [-79.37454, 54.264500099],
  [-79.3741601, 54.262959899],
  [-79.3728499, 54.262809999],
  [-79.37064, 54.263419899],
  [-79.3706099, 54.264189899],
  [-79.36578, 54.264639899],
  [-79.3658101, 54.263869899],
  [-79.3631699, 54.264089899],
  [-79.36355, 54.265629899],
  [-79.3713801, 54.266759899],
  [-79.3713601, 54.267270099],
  [-79.37269, 54.267039999],
  [-79.372, 54.267799999],
  [-79.3748101, 54.268599999],
  [-79.37434, 54.269359999],
  [-79.37078, 54.270979899],
  [-79.36991, 54.270840099],
  [-79.36989, 54.271349999],
  [-79.3623999, 54.272280099],
  [-79.3619499, 54.272779999],
  [-79.36609, 54.273359999],
  [-79.3658299, 54.274379999],
  [-79.3675801, 54.274400099],
  [-79.36756, 54.274909999],
  [-79.3719301, 54.275219999],
  [-79.3715099, 54.274709999],
  [-79.3737001, 54.274740099],
  [-79.3728299, 54.274469999],
  [-79.37264, 54.273699999],
  [-79.3759599, 54.273099899],
  [-79.3825099, 54.273189899],
  [-79.3831799, 54.272809999],
  [-79.3819599, 54.270489999],
  [-79.38222, 54.269730099],
  [-79.3870501, 54.269149999],
  [-79.39148, 54.267799999],
  [-79.39078, 54.268819999],
  [-79.3913801, 54.270109999],
  [-79.39358, 54.269879999],
  [-79.39355, 54.270649899],
  [-79.40191, 54.269479999],
  [-79.40146, 54.269979899],
  [-79.4054001, 54.269779999],
  [-79.40537, 54.270799999],
  [-79.4045001, 54.270789999],
  [-79.40533, 54.271699999],
  [-79.4128, 54.270899999],
  [-79.41147, 54.271400099],
  [-79.4114199, 54.272679999],
  [-79.4101, 54.272909999],
  [-79.4135101, 54.275139999],
  [-79.41657, 54.275309899],
  [-79.41659, 54.274789999],
  [-79.42079, 54.273819999],
  [-79.4215101, 54.272050099],
  [-79.4202099, 54.271769999],
  [-79.42023, 54.271259999],
  [-79.41758, 54.271730099],
  [-79.4176499, 54.269940099],
  [-79.41289, 54.268599999],
  [-79.41292, 54.267830099],
  [-79.41204, 54.267830099],
  [-79.4116399, 54.267039999],
  [-79.4125101, 54.267060099],
  [-79.4120999, 54.266280099],
  [-79.41691, 54.266600099],
  [-79.4170799, 54.267879999],
  [-79.41859, 54.268419899],
  [-79.4190601, 54.267779999],
  [-79.4212099, 54.268449999],
  [-79.42124, 54.267940099],
  [-79.42647, 54.268249999],
  [-79.42649, 54.267749899],
  [-79.42869, 54.267519899],
  [-79.4273199, 54.269039999],
  [-79.4264399, 54.269029999],
  [-79.4267, 54.273639899],
  [-79.4253901, 54.273629899],
  [-79.42535, 54.274649899],
  [-79.41824, 54.277379999],
  [-79.4183901, 54.279170099],
  [-79.41771, 54.279929999],
  [-79.4155001, 54.280419899],
  [-79.41415, 54.281419899],
  [-79.41724, 54.280819999],
  [-79.42292, 54.280899999],
  [-79.4244299, 54.281559999],
  [-79.42503, 54.283359999],
  [-79.4170601, 54.285300099],
  [-79.4175399, 54.284290099],
  [-79.4140501, 54.283989899],
  [-79.4167, 54.283249999],
  [-79.4167301, 54.282479999],
  [-79.41189, 54.283190099],
  [-79.41325, 54.281919999],
  [-79.40712, 54.282099899],
  [-79.4059799, 54.283099899],
  [-79.40501, 54.285659899],
  [-79.4065199, 54.285929999],
  [-79.40882, 54.289039999],
  [-79.4120701, 54.289850099],
  [-79.41246, 54.291139999],
  [-79.4108501, 54.291459999],
  [-79.4104099, 54.293149999],
  [-79.4154199, 54.294119999],
  [-79.42382, 54.291799999],
  [-79.42422, 54.292699999],
  [-79.4268601, 54.292599999],
  [-79.4259, 54.294909999],
  [-79.41657, 54.298099899],
  [-79.4158699, 54.299379999],
  [-79.4173901, 54.299649899],
  [-79.41693, 54.300159999],
  [-79.42526, 54.299879899],
  [-79.42838, 54.298640099],
  [-79.4345201, 54.298339999],
  [-79.4357799, 54.299889899],
  [-79.4379601, 54.300049999],
  [-79.43836, 54.300949999],
  [-79.4357501, 54.300659899],
  [-79.43527, 54.301679999],
  [-79.4378899, 54.301970099],
  [-79.4376499, 54.302469999],
  [-79.43915, 54.303269999],
  [-79.43917, 54.302759899],
  [-79.44312, 54.302679999],
  [-79.46218, 54.296520099],
  [-79.46527, 54.295929999],
  [-79.4665501, 54.296829999],
  [-79.4678999, 54.295689999],
  [-79.4670199, 54.295939999],
  [-79.46704, 54.295429899],
  [-79.4723101, 54.295109899],
  [-79.4732101, 54.294229999],
  [-79.4763, 54.293750099],
  [-79.4758801, 54.293229999],
  [-79.47918, 54.292759899],
  [-79.47892, 54.293779999],
  [-79.47714, 54.294269999],
  [-79.4757701, 54.296309899],
  [-79.4740099, 54.296279999],
  [-79.47308, 54.297809999],
  [-79.4722101, 54.297799999],
  [-79.4722299, 54.297290099],
  [-79.4652001, 54.297709999],
  [-79.46514, 54.299499999],
  [-79.4660099, 54.299510099],
  [-79.46596, 54.300789999],
  [-79.46771, 54.300819999],
  [-79.46615, 54.301819999],
  [-79.46937, 54.303530099],
  [-79.47028, 54.302389999],
  [-79.46941, 54.302369999],
  [-79.4689999, 54.301609999],
  [-79.47186, 54.301379999],
  [-79.4738801, 54.299869899],
  [-79.47497, 54.300139999],
  [-79.4759901, 54.302200099],
  [-79.4742201, 54.302439899],
  [-79.47464, 54.302949999],
  [-79.4737701, 54.302939999],
  [-79.47325, 54.304980099],
  [-79.4846799, 54.304099899],
  [-79.48526, 54.306159999],
  [-79.4845799, 54.306919999],
  [-79.48105, 54.307389999],
  [-79.48252, 54.309200099],
  [-79.4817999, 54.310729999],
  [-79.4756, 54.312699999],
  [-79.4749801, 54.311669899],
  [-79.4734599, 54.311139999],
  [-79.4734201, 54.312420099],
  [-79.4725401, 54.312399999],
  [-79.47295, 54.313179999],
  [-79.4716401, 54.313159999],
  [-79.47117, 54.313919999],
  [-79.47248, 54.314069999],
  [-79.4733201, 54.314980099],
  [-79.4715599, 54.315209899],
  [-79.4715301, 54.315980099],
  [-79.4697799, 54.315959999],
  [-79.46982, 54.314929999],
  [-79.46894, 54.314919999],
  [-79.4685501, 54.313379999],
  [-79.4694401, 54.313389999],
  [-79.4694599, 54.312879899],
  [-79.46551, 54.313080099],
  [-79.46804, 54.315679899],
  [-79.4654, 54.315899899],
  [-79.4648601, 54.318709999],
  [-79.4635499, 54.318439899],
  [-79.4626401, 54.319449899],
  [-79.4617601, 54.319439899],
  [-79.4613499, 54.318669899],
  [-79.45872, 54.318760099],
  [-79.45495, 54.320119899],
  [-79.4553399, 54.321410099],
  [-79.45685, 54.322069999],
  [-79.45948, 54.321970099],
  [-79.4598801, 54.322870099],
  [-79.46427, 54.322799999],
  [-79.4642299, 54.323829999],
  [-79.4633499, 54.323819999],
  [-79.4628701, 54.324829999],
  [-79.4593299, 54.325809999],
  [-79.4566401, 54.327439899],
  [-79.4469999, 54.327579999],
  [-79.4439, 54.328430099],
  [-79.4474, 54.328479999],
  [-79.44694, 54.328990099],
  [-79.4504201, 54.329669899],
  [-79.45794, 54.327849999],
  [-79.45796, 54.327329899],
  [-79.45929, 54.327090099],
  [-79.4598733, 54.325938899],
  [-79.4636601, 54.327409999],
  [-79.46363, 54.328169999],
  [-79.46491, 54.328699999],
  [-79.46405, 54.328679899],
  [-79.46439, 54.331259999],
  [-79.4676699, 54.331559899],
  [-79.4677899, 54.334119899],
  [-79.4695301, 54.334399999],
  [-79.4695699, 54.333369999],
  [-79.4708801, 54.333650099],
  [-79.47086, 54.334159999],
  [-79.48049, 54.334149999],
  [-79.4820001, 54.335069999],
  [-79.4825799, 54.337119899],
  [-79.4751199, 54.337540099],
  [-79.47515, 54.336770099],
  [-79.4645401, 54.339069999],
  [-79.46325, 54.338420099],
  [-79.4627799, 54.339179999],
  [-79.46102, 54.339279999],
  [-79.4579901, 54.338220099],
  [-79.4518601, 54.338009899],
  [-79.45161, 54.339039999],
  [-79.4524399, 54.340339899],
  [-79.4547799, 54.342149999],
  [-79.4565599, 54.341279999],
  [-79.4576599, 54.341419999],
  [-79.45738, 54.342959999],
  [-79.45826, 54.342959999],
  [-79.4573299, 54.344229899],
  [-79.4546599, 54.345220099],
  [-79.45418, 54.346499999],
  [-79.4519999, 54.345959999],
  [-79.4515499, 54.346459899],
  [-79.4558999, 54.347289999],
  [-79.4563001, 54.348320099],
  [-79.45849, 54.348349899],
  [-79.4584499, 54.349369999],
  [-79.45582, 54.349340099],
  [-79.4548899, 54.350859999],
  [-79.4627701, 54.351349899],
  [-79.4628, 54.350450099],
  [-79.4636799, 54.350459899],
  [-79.4642299, 54.347389999],
  [-79.4682001, 54.346799899],
  [-79.4716401, 54.348639999],
  [-79.47473, 54.347899899],
  [-79.4782299, 54.348210099],
  [-79.47864, 54.348859999],
  [-79.4777701, 54.348849999],
  [-79.47748, 54.350639999],
  [-79.48183, 54.351719999],
  [-79.4811299, 54.352729999],
  [-79.47937, 54.352969999],
  [-79.4788801, 54.354239899],
  [-79.4780099, 54.354230099],
  [-79.4746, 54.357509999],
  [-79.4787601, 54.357569899],
  [-79.47874, 54.358079999],
  [-79.48005, 54.358230099],
  [-79.4804699, 54.358869999],
  [-79.47738, 54.359349899],
  [-79.4755699, 54.360729999],
  [-79.47248, 54.361079999],
  [-79.47092, 54.362079999],
  [-79.4711099, 54.362849999],
  [-79.4742001, 54.362379999],
  [-79.47304, 54.363900099],
  [-79.4732299, 54.364790099],
  [-79.47981, 54.364759999],
  [-79.4788801, 54.366279999],
  [-79.4775501, 54.366519999],
  [-79.47818, 54.367299999],
  [-79.4805799, 54.367699899],
  [-79.48193, 54.366699899],
  [-79.4841299, 54.366350099],
  [-79.48416, 54.365829999],
  [-79.4881001, 54.365879999],
  [-79.48828, 54.367169999],
  [-79.4893499, 54.367819899],
  [-79.49729, 54.366770099],
  [-79.49727, 54.367279999],
  [-79.50039, 54.365909899],
  [-79.50832, 54.364849999],
  [-79.50739, 54.366379999],
  [-79.5043, 54.366849999],
  [-79.50383, 54.367619999],
  [-79.50162, 54.368230099],
  [-79.49764, 54.369079999],
  [-79.4972201, 54.368560099],
  [-79.49719, 54.369330099],
  [-79.49365, 54.370309999],
  [-79.49228, 54.371570099],
  [-79.49361, 54.371589899],
  [-79.4942301, 54.372359899],
  [-79.49395, 54.373900099],
  [-79.49173, 54.374639999],
  [-79.4916999, 54.375149899],
  [-79.49039, 54.375010099],
  [-79.4864, 54.376369899],
  [-79.48731, 54.375609999],
  [-79.4860001, 54.375340099],
  [-79.48437, 54.377879999],
  [-79.48626, 54.380329999],
  [-79.49109, 54.380269899],
  [-79.4909901, 54.383079999],
  [-79.49275, 54.382859999],
  [-79.49228, 54.383609999],
  [-79.49316, 54.383619999],
  [-79.4926601, 54.385149899],
  [-79.49354, 54.385159899],
  [-79.49371, 54.386450099],
  [-79.49784, 54.387519999],
  [-79.4973599, 54.388800099],
  [-79.4942399, 54.390169999],
  [-79.48806, 54.390989999],
  [-79.48809, 54.390219999],
  [-79.48898, 54.389969999],
  [-79.4854699, 54.389669999],
  [-79.48542, 54.391209999],
  [-79.4836501, 54.391449999],
  [-79.48272, 54.392969999],
  [-79.48051, 54.393449999],
  [-79.48305, 54.395920099],
  [-79.48837, 54.394829899],
  [-79.4872399, 54.395329999],
  [-79.4869901, 54.396109999],
  [-79.4856799, 54.396079999],
  [-79.4856601, 54.396590099],
  [-79.49008, 54.395879999],
  [-79.4886799, 54.397910099],
  [-79.4902, 54.398439999],
  [-79.49039, 54.399209999],
  [-79.5026601, 54.400009999],
  [-79.5028199, 54.401669999],
  [-79.5007701, 54.403700099],
  [-79.50343, 54.402959999],
  [-79.50341, 54.403480099],
  [-79.50031, 54.404460099],
  [-79.50071, 54.405229999],
  [-79.49851, 54.405460099],
  [-79.4984599, 54.406739999],
  [-79.4971399, 54.406739999],
  [-79.49709, 54.407759999],
  [-79.4962301, 54.407739999],
  [-79.4967701, 54.404929899],
  [-79.49194, 54.404999999],
  [-79.4888999, 54.404059899],
  [-79.48983, 54.402539999],
  [-79.49072, 54.402289999],
  [-79.4872499, 54.401099999],
  [-79.48462, 54.401059899],
  [-79.47928, 54.402920099],
  [-79.47926, 54.403429999],
  [-79.47441, 54.403879999],
  [-79.47448, 54.402089999],
  [-79.47095, 54.402559999],
  [-79.47186, 54.401800099],
  [-79.46969, 54.400999999],
  [-79.4688701, 54.399460099],
  [-79.46491, 54.399659999],
  [-79.46483, 54.401709899],
  [-79.4639801, 54.401189999],
  [-79.46371, 54.402460099],
  [-79.46072, 54.406009999],
  [-79.4615599, 54.407049899],
  [-79.46039, 54.407119999],
  [-79.4608601, 54.408059899],
  [-79.46195, 54.408339999],
  [-79.46192, 54.409099999],
  [-79.4671099, 54.411339999],
  [-79.4717, 54.411789999],
  [-79.4723101, 54.413079999],
  [-79.4714301, 54.413319999],
  [-79.4745, 54.413360099],
  [-79.47404, 54.413859999],
  [-79.4810901, 54.413189999],
  [-79.48126, 54.414729899],
  [-79.4827701, 54.415260099],
  [-79.48371, 54.413859999],
  [-79.4907701, 54.412920099],
  [-79.49161, 54.413829899],
  [-79.4946601, 54.414509899],
  [-79.50041, 54.413559999],
  [-79.5030299, 54.413839899],
  [-79.5034501, 54.414490099],
  [-79.5025701, 54.414480099],
  [-79.50162, 54.416769999],
  [-79.50073, 54.416759999],
  [-79.50071, 54.417269899],
  [-79.5020299, 54.417289899],
  [-79.4971399, 54.418769999],
  [-79.4962301, 54.420040099],
  [-79.48159, 54.423569999],
  [-79.47808, 54.423399899],
  [-79.4776699, 54.422619899],
  [-79.4754599, 54.423109999],
  [-79.47294, 54.425889999],
  [-79.4728899, 54.427169899],
  [-79.47562, 54.430539999],
  [-79.47474, 54.430529999],
  [-79.47515, 54.431299899],
  [-79.4746601, 54.432569999],
  [-79.4755401, 54.432590099],
  [-79.47552, 54.433099999],
  [-79.4817, 54.432409899],
  [-79.48208, 54.433949899],
  [-79.4922399, 54.432539999],
  [-79.49306, 54.434089999],
  [-79.49395, 54.433839899],
  [-79.49349, 54.434349999],
  [-79.48594, 54.436559999],
  [-79.4859199, 54.437069899],
  [-79.4793, 54.438009999],
  [-79.4784499, 54.436849899],
  [-79.4744699, 54.437949899],
  [-79.4739999, 54.438710099],
  [-79.4700199, 54.439179899],
  [-79.47396, 54.439739899],
  [-79.4738701, 54.442040099],
  [-79.47564, 54.441809999],
  [-79.47541, 54.442319999],
  [-79.47625, 54.443099999],
  [-79.4759901, 54.444380099],
  [-79.47993, 54.444549999],
  [-79.4812201, 54.445339999],
  [-79.48608, 54.444759999],
  [-79.48606, 54.445270099],
  [-79.4794, 54.446979899],
  [-79.47939, 54.447490099],
  [-79.47761, 54.447979899],
  [-79.4766699, 54.449510099],
  [-79.4748999, 54.449999999],
  [-79.4757799, 54.450009999],
  [-79.4761199, 54.452319999],
  [-79.4783301, 54.452219999],
  [-79.4787601, 54.452859899],
  [-79.4778701, 54.452849899],
  [-79.47804, 54.454139999],
  [-79.4802001, 54.454919999],
  [-79.4821199, 54.456620099],
  [-79.4834499, 54.456510099],
  [-79.4834301, 54.457019999],
  [-79.4874, 54.456559999],
  [-79.4869099, 54.458349999],
  [-79.49042, 54.458139999],
  [-79.48993, 54.459659999],
  [-79.4921101, 54.460079899],
  [-79.49962, 54.459019999],
  [-79.4996, 54.459529899],
  [-79.5005, 54.459029999],
  [-79.5031299, 54.459319899],
  [-79.50294, 54.458549999],
  [-79.5115799, 54.456730099],
  [-79.51421, 54.456889999],
  [-79.5141599, 54.458299899],
  [-79.5216901, 54.456599999],
  [-79.5221599, 54.455959899],
  [-79.5239101, 54.456229999],
  [-79.52388, 54.456749899],
  [-79.5225601, 54.456989999],
  [-79.52118, 54.458769999],
  [-79.5224899, 54.458909999],
  [-79.5264899, 54.457679999],
  [-79.5278099, 54.457830099],
  [-79.52821, 54.458849899],
  [-79.52733, 54.458840099],
  [-79.5272699, 54.460639899],
  [-79.53078, 54.460679999],
  [-79.53086, 54.458380099],
  [-79.53411, 54.459699999],
  [-79.53387, 54.460469999],
  [-79.5321, 54.460699999],
  [-79.53208, 54.461209899],
  [-79.52853, 54.462070099],
  [-79.5259001, 54.461899999],
  [-79.52543, 54.462659999],
  [-79.5227901, 54.462889999],
  [-79.52239, 54.461599999],
  [-79.52064, 54.461449999],
  [-79.5104401, 54.463759899],
  [-79.5107099, 54.462479999],
  [-79.5125, 54.461740099],
  [-79.5123501, 54.459689999],
  [-79.5079001, 54.461050099],
  [-79.50572, 54.460759899],
  [-79.50085, 54.461589999],
  [-79.50131, 54.460569999],
  [-79.4946601, 54.462539899],
  [-79.4951201, 54.462029999],
  [-79.4924699, 54.462259999],
  [-79.4908901, 54.463259999],
  [-79.48993, 54.465549999],
  [-79.4914401, 54.466339999],
  [-79.4909901, 54.466850099],
  [-79.4923, 54.466859899],
  [-79.49242, 54.467799999],
  [-79.4953201, 54.468569999],
  [-79.49796, 54.468459999],
  [-79.49798, 54.467960099],
  [-79.50197, 54.466989899],
  [-79.4987999, 54.469510099],
  [-79.5010001, 54.469539899],
  [-79.50008, 54.470549999],
  [-79.50118, 54.470819999],
  [-79.50174, 54.473259999],
  [-79.5056801, 54.473439899],
  [-79.50519, 54.475219999],
  [-79.50827, 54.475009999],
  [-79.5069099, 54.476269999],
  [-79.5011299, 54.477989899],
  [-79.49994, 54.480539899],
  [-79.5023599, 54.480309899],
  [-79.5023301, 54.481339999],
  [-79.51077, 54.478879899],
  [-79.51075, 54.479379999],
  [-79.50408, 54.481359999],
  [-79.5022301, 54.483909999],
  [-79.5158901, 54.483679999],
  [-79.5163, 54.484070099],
  [-79.51453, 54.484559999],
  [-79.5142699, 54.485329899],
  [-79.51888, 54.485889899],
  [-79.5175601, 54.485879899],
  [-79.51707, 54.487149999],
  [-79.51619, 54.487139999],
  [-79.5161599, 54.487909999],
  [-79.5214501, 54.487850099],
  [-79.5236701, 54.487099899],
  [-79.5302599, 54.487569999],
  [-79.5302401, 54.488080099],
  [-79.5284899, 54.487809999],
  [-79.52844, 54.489089899],
  [-79.52977, 54.488970099],
  [-79.5301401, 54.490899999],
  [-79.5319101, 54.490659899],
  [-79.52967, 54.491789999],
  [-79.5261401, 54.491879899],
  [-79.52617, 54.491109899],
  [-79.52308, 54.491319899],
  [-79.5203699, 54.493339899],
  [-79.51773, 54.493310099],
  [-79.5172599, 54.494070099],
  [-79.51154, 54.493999899],
  [-79.5111499, 54.492719999],
  [-79.51028, 54.492319899],
  [-79.5072, 54.492410099],
  [-79.5072201, 54.491899999],
  [-79.50877, 54.491659899],
  [-79.5090299, 54.490129999],
  [-79.5099199, 54.490139999],
  [-79.50996, 54.489119999],
  [-79.5024699, 54.489539899],
  [-79.5029299, 54.488769899],
  [-79.5046999, 54.488539899],
  [-79.50472, 54.488029999],
  [-79.50073, 54.488750099],
  [-79.50027, 54.489510099],
  [-79.49894, 54.489750099],
  [-79.4993499, 54.490520099],
  [-79.49849, 54.489999899],
  [-79.49887, 54.491539899],
  [-79.4940299, 54.491219899],
  [-79.4949001, 54.491750099],
  [-79.4940199, 54.491740099],
  [-79.4940001, 54.492499999],
  [-79.4922301, 54.492740099],
  [-79.4922101, 54.493249999],
  [-79.49309, 54.493259999],
  [-79.4939001, 54.495059999],
  [-79.4916799, 54.495559899],
  [-79.4916701, 54.496059999],
  [-79.4947801, 54.495080099],
  [-79.49476, 54.495589999],
  [-79.4965, 54.496249999],
  [-79.50921, 54.497559899],
  [-79.51005, 54.498469999],
  [-79.52019, 54.498209899],
  [-79.5219001, 54.499640099],
  [-79.52322, 54.499769899],
  [-79.5232401, 54.499269999],
  [-79.5369201, 54.498019999],
  [-79.53733, 54.498929999],
  [-79.53821, 54.498939999],
  [-79.5386099, 54.500219899],
  [-79.5403799, 54.499729999],
  [-79.54077, 54.501019999],
  [-79.5292501, 54.503439899],
  [-79.5286099, 54.502919999],
  [-79.52886, 54.501899899],
  [-79.52484, 54.503509999],
  [-79.5134601, 54.501709999],
  [-79.5134501, 54.502219899],
  [-79.5081399, 54.502929999],
  [-79.5051299, 54.506989899],
  [-79.50484, 54.508779899],
  [-79.50661, 54.508540099],
  [-79.5065799, 54.509569899],
  [-79.5052599, 54.509809999],
  [-79.5052301, 54.510320099],
  [-79.5029766, 54.510032299],
  [-79.5056601, 54.510579999],
  [-79.50561, 54.511870099],
  [-79.50473, 54.511849999],
  [-79.5042399, 54.513379999],
  [-79.50728, 54.514310099],
  [-79.5119199, 54.513990099],
  [-79.51285, 54.512459899],
  [-79.51396, 54.512219899],
  [-79.5148901, 54.510949999],
  [-79.5135899, 54.510420099],
  [-79.5167099, 54.509439899],
  [-79.51832, 54.507410099],
  [-79.5181301, 54.506630099],
  [-79.51811, 54.507149999],
  [-79.51543, 54.508269999],
  [-79.5083401, 54.509589999],
  [-79.5075601, 54.506769899],
  [-79.51155, 54.505909999],
  [-79.52606, 54.506479999],
  [-79.52506, 54.510049999],
  [-79.52061, 54.511019999],
  [-79.5201201, 54.512289999],
  [-79.5183501, 54.512789899],
  [-79.51786, 54.514059999],
  [-79.51519, 54.514799999],
  [-79.5142201, 54.517349899],
  [-79.5133401, 54.517329899],
  [-79.5135, 54.518870099],
  [-79.5145899, 54.519149999],
  [-79.51275, 54.521430099],
  [-79.51143, 54.521409999],
  [-79.51273, 54.521939999],
  [-79.51176, 54.524489999],
  [-79.51088, 54.524479999],
  [-79.51105, 54.525760099],
  [-79.5121399, 54.526029999],
  [-79.5121, 54.527310099],
  [-79.5155999, 54.527870099],
  [-79.51507, 54.530289999],
  [-79.5301301, 54.527789899],
  [-79.5301201, 54.528299999],
  [-79.53144, 54.528320099],
  [-79.53074, 54.529589999],
  [-79.5325866, 54.531597499],
  [-79.5315052, 54.532840599],
  [-79.5226801, 54.535992599],
  [-79.5212638, 54.535563099],
  [-79.5207185, 54.536308199],
  [-79.5170367, 54.537623399],
  [-79.512286, 54.538314599],
  [-79.5140863, 54.540492799],
  [-79.5196116, 54.540480399],
  [-79.5253408, 54.538762499],
  [-79.5341255, 54.537715699],
  [-79.535423, 54.538070599],
  [-79.5359302, 54.539048799],
  [-79.5409023, 54.539839699],
  [-79.5451838, 54.540019799],
  [-79.551326, 54.539089299],
  [-79.5519851, 54.539455899],
  [-79.5500791, 54.541036699],
  [-79.5497344, 54.543495399],
  [-79.5507789, 54.544164899],
  [-79.55348, 54.544188699],
  [-79.54998, 54.545699899],
  [-79.54996, 54.546469899],
  [-79.5514801, 54.546869999],
  [-79.55943, 54.546330099],
  [-79.5596099, 54.547609999],
  [-79.56156, 54.548269999],
  [-79.56509, 54.548189999],
  [-79.56511, 54.547670099],
  [-79.56689, 54.547179999],
  [-79.56687, 54.547699899],
  [-79.56734, 54.546929999],
  [-79.56822, 54.546939999],
  [-79.5682, 54.547459899],
  [-79.56642, 54.547949999],
  [-79.5672601, 54.549239899],
  [-79.5729499, 54.550199999],
  [-79.5778399, 54.549110099],
  [-79.5772701, 54.552689899],
  [-79.5838599, 54.553269999],
  [-79.5838401, 54.554039899],
  [-79.5896001, 54.553089999],
  [-79.58957, 54.553849999],
  [-79.58735, 54.554340099],
  [-79.58733, 54.554849999],
  [-79.5815199, 54.557350099],
  [-79.58148, 54.558369899],
  [-79.5774801, 54.559599899],
  [-79.5760401, 54.562919899],
  [-79.5742601, 54.563399999],
  [-79.57287, 54.565189999],
  [-79.57199, 54.565169999],
  [-79.5723901, 54.566469899],
  [-79.5715099, 54.566450099],
  [-79.57235, 54.567489899],
  [-79.56564, 54.570219999],
  [-79.56033, 54.570929899],
  [-79.5599, 54.570409999],
  [-79.56078, 54.570419999],
  [-79.5603701, 54.569649999],
  [-79.5590499, 54.569629999],
  [-79.55978, 54.567589899],
  [-79.5589301, 54.566809899],
  [-79.5596299, 54.565539999],
  [-79.55652, 54.566269899],
  [-79.5586099, 54.565450099],
  [-79.5606001, 54.563249899],
  [-79.5641601, 54.562259899],
  [-79.5641799, 54.561749999],
  [-79.5686299, 54.560399999],
  [-79.5752801, 54.559450099],
  [-79.5775299, 54.558069999],
  [-79.57622, 54.557539999],
  [-79.58066, 54.556699899],
  [-79.58134, 54.556059999],
  [-79.5811701, 54.554780099],
  [-79.5798499, 54.554509999],
  [-79.5798399, 54.555019899],
  [-79.5758399, 54.556000099],
  [-79.57581, 54.556769999],
  [-79.5740501, 54.556749999],
  [-79.5736299, 54.555969999],
  [-79.57011, 54.555929899],
  [-79.5678399, 54.557699899],
  [-79.5642999, 54.558299999],
  [-79.5585801, 54.557969999],
  [-79.5510499, 54.559029899],
  [-79.54831, 54.561560099],
  [-79.54298, 54.562780099],
  [-79.5453, 54.559729999],
  [-79.5496901, 54.560039899],
  [-79.54931, 54.558240099],
  [-79.55019, 54.558259899],
  [-79.55045, 54.557230099],
  [-79.54852, 54.555670099],
  [-79.54196, 54.554309999],
  [-79.54156, 54.553279999],
  [-79.5393501, 54.553259899],
  [-79.54021, 54.554039899],
  [-79.5389, 54.553759999],
  [-79.5379499, 54.555550099],
  [-79.53663, 54.555529999],
  [-79.5370399, 54.556299999],
  [-79.5361599, 54.556289999],
  [-79.5361401, 54.556809899],
  [-79.53964, 54.557619999],
  [-79.53788, 54.557589899],
  [-79.5369399, 54.559120099],
  [-79.5329001, 54.561120099],
  [-79.53108, 54.562890099],
  [-79.52929, 54.563379899],
  [-79.5288199, 54.564399999],
  [-79.52128, 54.565719999],
  [-79.5199299, 54.566469899],
  [-79.51641, 54.566039899],
  [-79.51506, 54.567039899],
  [-79.5185801, 54.567089999],
  [-79.51922, 54.567609999],
  [-79.51897, 54.568379899],
  [-79.5203, 54.568399999],
  [-79.5171301, 54.570659999],
  [-79.5135701, 54.571639999],
  [-79.51198, 54.572900099],
  [-79.5116801, 54.574949999],
  [-79.5147801, 54.574599899],
  [-79.51431, 54.575499899],
  [-79.52006, 54.575059999],
  [-79.5184701, 54.576309999],
  [-79.5186701, 54.576829899],
  [-79.5119299, 54.580329999],
  [-79.51055, 54.581849999],
  [-79.51184, 54.582639999],
  [-79.51005, 54.583130099],
  [-79.5100399, 54.583639999],
  [-79.50828, 54.583619999],
  [-79.5069099, 54.584749999],
  [-79.5060399, 54.584609899],
  [-79.5060199, 54.585130099],
  [-79.5029299, 54.585339999],
  [-79.5024, 54.587639999],
  [-79.50152, 54.587629999],
  [-79.5010399, 54.588649999],
  [-79.5032301, 54.588939899],
  [-79.5066899, 54.590899999],
  [-79.51288, 54.590590099],
  [-79.5128901, 54.590079999],
  [-79.5140101, 54.589839899],
  [-79.5138099, 54.589069999],
  [-79.5178099, 54.588219999],
  [-79.5194, 54.587079999],
  [-79.52407, 54.585859999],
  [-79.52409, 54.585350099],
  [-79.53154, 54.586719899],
  [-79.5323899, 54.587759999],
  [-79.5385701, 54.587570099],
  [-79.5385901, 54.587059899],
  [-79.5443601, 54.585969999],
  [-79.54609, 54.586900099],
  [-79.5408001, 54.586829899],
  [-79.53944, 54.587839899],
  [-79.5429299, 54.589039899],
  [-79.54557, 54.589069999],
  [-79.5460401, 54.588179999],
  [-79.5473701, 54.588189999],
  [-79.54598, 54.589969999],
  [-79.54285, 54.591209999],
  [-79.54256, 54.593250099],
  [-79.5449201, 54.594819899],
  [-79.5458299, 54.594189999],
  [-79.5493799, 54.593849999],
  [-79.549, 54.591789999],
  [-79.55031, 54.592329999],
  [-79.55033, 54.591810099],
  [-79.55208, 54.592099999],
  [-79.5511699, 54.592849999],
  [-79.55075, 54.592329999],
  [-79.5506901, 54.593869999],
  [-79.55422, 54.593910099],
  [-79.55332, 54.594539999],
  [-79.552, 54.594399899],
  [-79.5514004, 54.595995199],
  [-79.5503, 54.596769999],
  [-79.5503, 54.598729899],
  [-79.5543601, 54.602360099],
  [-79.5534701, 54.602609899],
  [-79.5530499, 54.602089999],
  [-79.5538299, 54.604920099],
  [-79.5507299, 54.605389899],
  [-79.5507001, 54.606159899],
  [-79.5498201, 54.606150099],
  [-79.5498001, 54.606659999],
  [-79.54668, 54.607399899],
  [-79.54666, 54.607909999],
  [-79.5449, 54.607889999],
  [-79.54488, 54.608389899],
  [-79.54179, 54.608360099],
  [-79.54176, 54.609389899],
  [-79.54, 54.609109999],
  [-79.53998, 54.609619899],
  [-79.53644, 54.610089999],
  [-79.53507, 54.611229999],
  [-79.53331, 54.611079999],
  [-79.5359399, 54.611619899],
  [-79.5340301, 54.615700099],
  [-79.5329101, 54.616189899],
  [-79.53441, 54.617239999],
  [-79.53531, 54.616979999],
  [-79.5352401, 54.618529999],
  [-79.5374701, 54.618309999],
  [-79.53876, 54.619079899],
  [-79.53878, 54.618569999],
  [-79.53962, 54.619859999],
  [-79.54718, 54.618289899],
  [-79.55219, 54.613869999],
  [-79.55486, 54.613129999],
  [-79.55488, 54.612629899],
  [-79.55399, 54.612859999],
  [-79.5549101, 54.611719899],
  [-79.55668, 54.611739899],
  [-79.5584801, 54.610609899],
  [-79.55453, 54.610049899],
  [-79.55501, 54.609030099],
  [-79.55612, 54.608789999],
  [-79.5561501, 54.608019999],
  [-79.5572601, 54.607779999],
  [-79.5573, 54.606749999],
  [-79.5581799, 54.606759999],
  [-79.55866, 54.605619899],
  [-79.55954, 54.605759999],
  [-79.5618101, 54.603859999],
  [-79.56758, 54.603289899],
  [-79.5675901, 54.602779999],
  [-79.56979, 54.602930099],
  [-79.5719499, 54.604239999],
  [-79.5748301, 54.603889999],
  [-79.5750699, 54.603379899],
  [-79.5781501, 54.603539999],
  [-79.5795299, 54.601769999],
  [-79.5801899, 54.601899999],
  [-79.57946, 54.603939899],
  [-79.57053, 54.606909999],
  [-79.5705099, 54.607419999],
  [-79.5691899, 54.607409999],
  [-79.56833, 54.606629999],
  [-79.56765, 54.607129999],
  [-79.5678301, 54.608419999],
  [-79.5656099, 54.608649999],
  [-79.56346, 54.611349999],
  [-79.56486, 54.611199999],
  [-79.5646099, 54.611959899],
  [-79.5654999, 54.611969999],
  [-79.56547, 54.612739899],
  [-79.56769, 54.612380099],
  [-79.5690599, 54.611119999],
  [-79.5703899, 54.611009999],
  [-79.57058, 54.611779999],
  [-79.56811, 54.613030099],
  [-79.5671601, 54.614799999],
  [-79.5653801, 54.615050099],
  [-79.5649201, 54.615810099],
  [-79.56088, 54.617679999],
  [-79.55999, 54.617539999],
  [-79.5581501, 54.620079899],
  [-79.56343, 54.620659999],
  [-79.56347, 54.623649999],
  [-79.5681799, 54.623390099],
  [-79.5686001, 54.624050099],
  [-79.5708101, 54.623949899],
  [-79.5712799, 54.623309999],
  [-79.5703899, 54.623559999],
  [-79.5704099, 54.622789999],
  [-79.5689, 54.621999999],
  [-79.57269, 54.620769999],
  [-79.5727201, 54.619999999],
  [-79.5756101, 54.619270099],
  [-79.57544, 54.617979999],
  [-79.57632, 54.617989999],
  [-79.57612, 54.617480099],
  [-79.57724, 54.616849899],
  [-79.58302, 54.615769999],
  [-79.5832899, 54.614229999],
  [-79.5848499, 54.613999999],
  [-79.5848599, 54.613480099],
  [-79.5875101, 54.613509899],
  [-79.58748, 54.614279899],
  [-79.5852701, 54.614519899],
  [-79.585, 54.615789999],
  [-79.58365, 54.616799999],
  [-79.58913, 54.617759999],
  [-79.59178, 54.617529999],
  [-79.59359, 54.616270099],
  [-79.59756, 54.616189899],
  [-79.5975299, 54.617219999],
  [-79.59532, 54.617449999],
  [-79.59482, 54.618979999],
  [-79.5939401, 54.618969899],
  [-79.5937, 54.619480099],
  [-79.59456, 54.620260099],
  [-79.5940799, 54.621529999],
  [-79.59159, 54.623169899],
  [-79.5876101, 54.623509899],
  [-79.58759, 54.624019999],
  [-79.5867101, 54.623749899],
  [-79.5867, 54.624260099],
  [-79.58313, 54.625119999],
  [-79.5800501, 54.624949899],
  [-79.58003, 54.625469999],
  [-79.5773801, 54.625689999],
  [-79.57578, 54.627209999],
  [-79.5759599, 54.628239999],
  [-79.57198, 54.628449999],
  [-79.5710401, 54.629969899],
  [-79.5777101, 54.628769999],
  [-79.5777299, 54.628260099],
  [-79.58302, 54.628319999],
  [-79.58299, 54.629209999],
  [-79.58387, 54.629099999],
  [-79.5838401, 54.630119999],
  [-79.5825099, 54.630359999],
  [-79.5820401, 54.631129999],
  [-79.58511, 54.631669999],
  [-79.58421, 54.632429999],
  [-79.58687, 54.631950099],
  [-79.5870332, 54.632838899],
  [-79.5905764, 54.632307599],
  [-79.5917499, 54.631490099],
  [-79.59613, 54.632309899],
  [-79.59615, 54.631799999],
  [-79.59791, 54.631950099],
  [-79.59837, 54.631319999],
  [-79.59925, 54.631579999],
  [-79.59926, 54.631069899],
  [-79.6032801, 54.629959899],
  [-79.6046399, 54.628830099],
  [-79.6068501, 54.628720099],
  [-79.60801, 54.629289899],
  [-79.6077401, 54.628610099],
  [-79.6095001, 54.628749899],
  [-79.6108401, 54.628129999],
  [-79.6095201, 54.628109999],
  [-79.60893, 54.626299899],
  [-79.6096101, 54.625549999],
  [-79.59858, 54.624909999],
  [-79.5986101, 54.624399899],
  [-79.5955101, 54.624619899],
  [-79.59649, 54.621820099],
  [-79.5991601, 54.621079899],
  [-79.6003099, 54.619810099],
  [-79.60013, 54.618779999],
  [-79.60148, 54.618030099],
  [-79.60324, 54.618050099],
  [-79.6037401, 54.616519899],
  [-79.60111, 54.615979999],
  [-79.60157, 54.615469999],
  [-79.60602, 54.614619899],
  [-79.6086499, 54.614779999],
  [-79.6077499, 54.615030099],
  [-79.6081899, 54.615419999],
  [-79.6099699, 54.614930099],
  [-79.61349, 54.615349999],
  [-79.6135299, 54.614069899],
  [-79.6157401, 54.613839899],
  [-79.61572, 54.614609899],
  [-79.6148401, 54.614600099],
  [-79.61538, 54.618189899],
  [-79.61689, 54.619099999],
  [-79.6224299, 54.618529999],
  [-79.6258, 54.616639999],
  [-79.6373399, 54.614849899],
  [-79.63781, 54.613959899],
  [-79.64135, 54.613619899],
  [-79.64181, 54.612979999],
  [-79.6475201, 54.613810099],
  [-79.65152, 54.612959899],
  [-79.6515, 54.613729899],
  [-79.65326, 54.613749999],
  [-79.65328, 54.613239999],
  [-79.65459, 54.613649999],
  [-79.65571, 54.613270099],
  [-79.6551001, 54.611470099],
  [-79.6595, 54.612030099],
  [-79.6581099, 54.614059899],
  [-79.6549801, 54.615050099],
  [-79.65451, 54.616069899],
  [-79.65318, 54.616059899],
  [-79.6540199, 54.617600099],
  [-79.65314, 54.617590099],
  [-79.6535401, 54.618619899],
  [-79.6513299, 54.618849899],
  [-79.65326, 54.620669999],
  [-79.65341, 54.622720099],
  [-79.65163, 54.622949899],
  [-79.65116, 54.624229999],
  [-79.64939, 54.623949899],
  [-79.64869, 54.625229999],
  [-79.64904, 54.625909999],
  [-79.6502001, 54.626139999],
  [-79.6573399, 54.623909999],
  [-79.65952, 54.624839899],
  [-79.65819, 54.624820099],
  [-79.65772, 54.625839899],
  [-79.6564, 54.626079899],
  [-79.6549999, 54.628119999],
  [-79.6536601, 54.628359999],
  [-79.6532001, 54.629119999],
  [-79.6492001, 54.629839899],
  [-79.64782, 54.631370099],
  [-79.64694, 54.631359999],
  [-79.64691, 54.632119999],
  [-79.64604, 54.631859899],
  [-79.6464599, 54.632629899],
  [-79.6446799, 54.632869999],
  [-79.6437501, 54.634390099],
  [-79.64462, 54.634659999],
  [-79.6428601, 54.634639899],
  [-79.64283, 54.635409899],
  [-79.64417, 54.635419899],
  [-79.6434399, 54.636950099],
  [-79.64895, 54.637399899],
  [-79.65118, 54.636529899],
  [-79.6521099, 54.635380099],
  [-79.6534301, 54.635399899],
  [-79.6561199, 54.634150099],
  [-79.6578801, 54.634419899],
  [-79.66106, 54.632019999],
  [-79.66016, 54.632009999],
  [-79.66017, 54.631500099],
  [-79.6619801, 54.630500099],
  [-79.6713, 54.628940099],
  [-79.67284, 54.629339999],
  [-79.6725799, 54.630359999],
  [-79.6738901, 54.631009999],
  [-79.6758801, 54.630909999],
  [-79.6754599, 54.629879999],
  [-79.6761399, 54.629500099],
  [-79.6772399, 54.629639899],
  [-79.6769901, 54.630659999],
  [-79.67919, 54.630689999],
  [-79.67918, 54.631199999],
  [-79.68317, 54.630469999],
  [-79.6835501, 54.632529899],
  [-79.68443, 54.632539999],
  [-79.6859099, 54.634600099],
  [-79.6874401, 54.635259999],
  [-79.69829, 54.634219999],
  [-79.6981101, 54.632940099],
  [-79.7074, 54.632270099],
  [-79.7096581, 54.630340199],
  [-79.7102094, 54.631126799],
  [-79.7088146, 54.633396599],
  [-79.710086, 54.634703799],
  [-79.7168275, 54.635501699],
  [-79.7200906, 54.635275099],
  [-79.7250071, 54.633138699],
  [-79.7275289, 54.633182499],
  [-79.7280353, 54.632583099],
  [-79.7265233, 54.631782899],
  [-79.7317529, 54.630216999],
  [-79.7305834, 54.628341399],
  [-79.7319567, 54.627294899],
  [-79.7394347, 54.628977999],
  [-79.7386926, 54.630893999],
  [-79.7455073, 54.628114699],
  [-79.7482055, 54.628099199],
  [-79.7502333, 54.629217099],
  [-79.7531569, 54.629263699],
  [-79.7538972, 54.629009099],
  [-79.753747, 54.628170599],
  [-79.7562962, 54.624824699],
  [-79.7591972, 54.623388099],
  [-79.7616971, 54.623077499],
  [-79.7628726, 54.623162299],
  [-79.7626627, 54.623962599],
  [-79.7598396, 54.624879999],
  [-79.7588271, 54.626220399],
  [-79.7600877, 54.626512299],
  [-79.7609246, 54.627679999],
  [-79.75699, 54.627919999],
  [-79.7560599, 54.629449999],
  [-79.7626795, 54.632081699],
  [-79.7601575, 54.634855899],
  [-79.7555563, 54.634907699],
  [-79.7549406, 54.637578799],
  [-79.7529345, 54.637846099],
  [-79.7526205, 54.639460099],
  [-79.7510433, 54.640099699],
  [-79.751097, 54.644482899],
  [-79.7496264, 54.647062699],
  [-79.7505016, 54.648712499],
  [-79.74886, 54.648079899],
  [-79.7452498, 54.649089099],
  [-79.7433475, 54.648723399],
  [-79.7453406, 54.645515199],
  [-79.7465036, 54.645331699],
  [-79.7472561, 54.644433199],
  [-79.7480929, 54.641443899],
  [-79.7444665, 54.642139199],
  [-79.7408628, 54.641700399],
  [-79.7403145, 54.641179999],
  [-79.7406203, 54.639311099],
  [-79.7398637, 54.638726699],
  [-79.7382975, 54.638171699],
  [-79.73679, 54.638628299],
  [-79.7363448, 54.637389399],
  [-79.7314512, 54.635767799],
  [-79.731769, 54.637274499],
  [-79.7301499, 54.637373199],
  [-79.7284105, 54.638532199],
  [-79.7305569, 54.638830799],
  [-79.7312794, 54.640280599],
  [-79.7289097, 54.639146599],
  [-79.7267324, 54.639245499],
  [-79.7260908, 54.640082499],
  [-79.723438, 54.640751599],
  [-79.7228587, 54.641710799],
  [-79.7240281, 54.642167199],
  [-79.71984, 54.643919999],
  [-79.71934, 54.645720099],
  [-79.7175801, 54.645699999],
  [-79.7181499, 54.648519899],
  [-79.71499, 54.650799999],
  [-79.7160301, 54.652859899],
  [-79.71467, 54.653869899],
  [-79.71111, 54.654469999],
  [-79.7093601, 54.654070099],
  [-79.70917, 54.653039999],
  [-79.70985, 54.652529899],
  [-79.70631, 54.652749899],
  [-79.70673, 54.653529899],
  [-79.7040499, 54.654259999],
  [-79.70364, 54.653489999],
  [-79.70142, 54.653720099],
  [-79.7014, 54.654239999],
  [-79.69695, 54.655209899],
  [-79.6973501, 54.656749899],
  [-79.69298, 54.655039999],
  [-79.69031, 54.655400099],
  [-79.69007, 54.656419899],
  [-79.6928099, 54.660290099],
  [-79.6919299, 54.660280099],
  [-79.69151, 54.659500099],
  [-79.68797, 54.659730099],
  [-79.6880001, 54.658960099],
  [-79.6835799, 54.658909999],
  [-79.6839901, 54.659950099],
  [-79.6822101, 54.660429899],
  [-79.68305, 54.661589999],
  [-79.6848099, 54.661740099],
  [-79.6847999, 54.662249999],
  [-79.6870299, 54.661510099],
  [-79.68652, 54.663549899],
  [-79.68475, 54.663659899],
  [-79.6834501, 54.663009999],
  [-79.68051, 54.665029999],
  [-79.6815699, 54.666319899],
  [-79.6798099, 54.666039999],
  [-79.67939, 54.665269999],
  [-79.67807, 54.665249999],
  [-79.67808, 54.664740099],
  [-79.67672, 54.666009999],
  [-79.67496, 54.665730099],
  [-79.6745501, 54.664960099],
  [-79.6763201, 54.664719999],
  [-79.67817, 54.662180099],
  [-79.6742101, 54.661630099],
  [-79.67418, 54.662649899],
  [-79.6733, 54.662639899],
  [-79.67275, 54.665709999],
  [-79.67142, 54.665950099],
  [-79.6676899, 54.671799999],
  [-79.66681, 54.671789999],
  [-79.6663399, 54.672809999],
  [-79.6627899, 54.673029999],
  [-79.66095, 54.675310099],
  [-79.6562299, 54.677569999],
  [-79.6536501, 54.682149999],
  [-79.64925, 54.681589999],
  [-79.6491901, 54.683379999],
  [-79.65073, 54.683649899],
  [-79.6504599, 54.684929999],
  [-79.64959, 54.684659899],
  [-79.6486501, 54.686449899],
  [-79.6473101, 54.686689999],
  [-79.6466699, 54.685909999],
  [-79.6469999, 54.682839999],
  [-79.63992, 54.683019999],
  [-79.6398999, 54.683539899],
  [-79.6305201, 54.686499999],
  [-79.63094, 54.687019999],
  [-79.62917, 54.687249999],
  [-79.62915, 54.687750099],
  [-79.62604, 54.688239999],
  [-79.62556, 54.689259999],
  [-79.62468, 54.688989899],
  [-79.61904, 54.692520099],
  [-79.6214199, 54.700479999],
  [-79.62115, 54.701509999],
  [-79.6202901, 54.701499999],
  [-79.6198799, 54.700210099],
  [-79.61901, 54.699819999],
  [-79.61547, 54.699899899],
  [-79.61514, 54.696569899],
  [-79.61426, 54.696430099],
  [-79.61068, 54.697669899],
  [-79.60669, 54.698009899],
  [-79.60667, 54.698519999],
  [-79.60401, 54.698750099],
  [-79.6039899, 54.699249999],
  [-79.59866, 54.699970099],
  [-79.5981899, 54.700729999],
  [-79.5973099, 54.700719999],
  [-79.59278, 54.703739999],
  [-79.5896399, 54.704990099],
  [-79.5878201, 54.706760099],
  [-79.58379, 54.707999899],
  [-79.5833099, 54.709019899],
  [-79.5806299, 54.709750099],
  [-79.57945, 54.711789899],
  [-79.58048, 54.713839999],
  [-79.57734, 54.715090099],
  [-79.5763901, 54.717129899],
  [-79.5750501, 54.717369999],
  [-79.57503, 54.717880099],
  [-79.57233, 54.719259999],
  [-79.57145, 54.719119899],
  [-79.57143, 54.719629999],
  [-79.5701, 54.719870099],
  [-79.5700699, 54.720639999],
  [-79.5682899, 54.720619999],
  [-79.5682799, 54.721129899],
  [-79.56156, 54.723479999],
  [-79.55445, 54.724289999],
  [-79.55443, 54.724809899],
  [-79.55354, 54.724919899],
  [-79.5509101, 54.724249899],
  [-79.54877, 54.722309999],
  [-79.54699, 54.722540099],
  [-79.5443701, 54.721479999],
  [-79.53688, 54.720629999],
  [-79.52933, 54.721430099],
  [-79.5293, 54.722199999],
  [-79.5271, 54.722039999],
  [-79.52219, 54.723129899],
  [-79.52217, 54.723650099],
  [-79.5163401, 54.725629999],
  [-79.51631, 54.726139899],
  [-79.51455, 54.726369999],
  [-79.51408, 54.727139899],
  [-79.50963, 54.727589999],
  [-79.50961, 54.728110099],
  [-79.5046999, 54.729330099],
  [-79.5046801, 54.729829999],
  [-79.4993301, 54.730919899],
  [-79.49573, 54.732419999],
  [-79.48639, 54.733709999],
  [-79.4859199, 54.734469899],
  [-79.4850299, 54.734459899],
  [-79.4845501, 54.735479899],
  [-79.4801299, 54.735429999],
  [-79.47694, 54.737949999],
  [-79.47605, 54.737939999],
  [-79.47185, 54.743770099],
  [-79.47096, 54.743759999],
  [-79.47093, 54.744529999],
  [-79.46871, 54.745019899],
  [-79.4673, 54.746790099],
  [-79.4659701, 54.747029899],
  [-79.46548, 54.748299999],
  [-79.4632199, 54.749299999],
  [-79.46274, 54.750319999],
  [-79.45961, 54.751299999],
  [-79.4591099, 54.752579899],
  [-79.4582299, 54.752570099],
  [-79.4581901, 54.753589899],
  [-79.4573101, 54.753579899],
  [-79.45593, 54.754839999],
  [-79.45415, 54.755329999],
  [-79.4527501, 54.756849999],
  [-79.45051, 54.757589899],
  [-79.4505401, 54.756819899],
  [-79.4527899, 54.755829999],
  [-79.4565201, 54.751010099],
  [-79.4574, 54.751020099],
  [-79.4581901, 54.747699899],
  [-79.4630901, 54.741099999],
  [-79.45825, 54.740269999],
  [-79.45829, 54.739249899],
  [-79.4569701, 54.739230099],
  [-79.45695, 54.739729999],
  [-79.4538701, 54.739059999],
  [-79.4516501, 54.739419999],
  [-79.4506401, 54.738469899],
  [-79.44506, 54.738049999],
  [-79.44504, 54.738579899],
  [-79.43258, 54.740199999],
  [-79.43256, 54.740709999],
  [-79.4285299, 54.741939999],
  [-79.42668, 54.743969999],
  [-79.42579, 54.743949999],
  [-79.4239701, 54.745340099],
  [-79.4226399, 54.745189999],
  [-79.4226201, 54.745699899],
  [-79.41904, 54.746680099],
  [-79.419, 54.747709899],
  [-79.41722, 54.747939999],
  [-79.4171899, 54.748709899],
  [-79.4078301, 54.750379899],
  [-79.4034901, 54.753649999],
  [-79.40324, 54.754419999],
  [-79.40236, 54.754409999],
  [-79.4005101, 54.756169999],
  [-79.39646, 54.757910099],
  [-79.3950501, 54.759939899],
  [-79.39235, 54.760929899],
  [-79.39232, 54.761699899],
  [-79.39144, 54.761690099],
  [-79.3900501, 54.763209999],
  [-79.3882801, 54.763189999],
  [-79.3882601, 54.763699899],
  [-79.38692, 54.763939899],
  [-79.3869401, 54.763419999],
  [-79.3807399, 54.763340099],
  [-79.38002, 54.759230099],
  [-79.37825, 54.759209999],
  [-79.3778499, 54.758179999],
  [-79.37433, 54.757619999],
  [-79.3743601, 54.756849999],
  [-79.3690301, 54.757039899],
  [-79.3690499, 54.756519999],
  [-79.3677299, 54.756509999],
  [-79.3681501, 54.757020099],
  [-79.3619499, 54.756939899],
  [-79.3619301, 54.757450099],
  [-79.3605901, 54.757690099],
  [-79.3610499, 54.757179999],
  [-79.3557299, 54.757369899],
  [-79.3557199, 54.757879999],
  [-79.3517199, 54.758089999],
  [-79.3517001, 54.758589899],
  [-79.3508101, 54.758709899],
  [-79.34509, 54.757989999],
  [-79.3441499, 54.758999999],
  [-79.34151, 54.758580099],
  [-79.3284899, 54.763139899],
  [-79.3257099, 54.765669999],
  [-79.31942, 54.767889999],
  [-79.3118901, 54.767779999],
  [-79.31096, 54.768790099],
  [-79.30964, 54.768769999],
  [-79.30872, 54.769529999],
  [-79.30854, 54.768759999],
  [-79.3101299, 54.767240099],
  [-79.30838, 54.766959999],
  [-79.3083599, 54.767479899],
  [-79.3065799, 54.767709899],
  [-79.3061001, 54.768470099],
  [-79.3043401, 54.768189999],
  [-79.3043201, 54.768959999],
  [-79.30209, 54.769179999],
  [-79.30207, 54.769690099],
  [-79.29848, 54.770669999],
  [-79.2984599, 54.771179999],
  [-79.2957899, 54.771399999],
  [-79.29664, 54.772179999],
  [-79.2908801, 54.772360099],
  [-79.29085, 54.772869999],
  [-79.28997, 54.772849999],
  [-79.28993, 54.773879999],
  [-79.2886, 54.773859999],
  [-79.2885501, 54.774879999],
  [-79.2867899, 54.774599899],
  [-79.2869901, 54.775369899],
  [-79.2847, 54.776879999],
  [-79.28528, 54.778929899],
  [-79.2844, 54.778920099],
  [-79.2843499, 54.779949899],
  [-79.2830199, 54.779920099],
  [-79.28249, 54.781969999],
  [-79.27758, 54.782920099],
  [-79.2771001, 54.783690099],
  [-79.27441, 54.784539999],
  [-79.2730801, 54.784399899],
  [-79.27313, 54.783370099],
  [-79.2740099, 54.783389899],
  [-79.2723399, 54.781059899],
  [-79.27014, 54.780639999],
  [-79.26748, 54.780989999],
  [-79.2674399, 54.781499899],
  [-79.2656599, 54.781729899],
  [-79.2656401, 54.782240099],
  [-79.2616, 54.783339999],
  [-79.25357, 54.784629999],
  [-79.25127, 54.786649999],
  [-79.24858, 54.787379899],
  [-79.24816, 54.786859999],
  [-79.24768, 54.787619899],
  [-79.24138, 54.789820099],
  [-79.24136, 54.790349999],
  [-79.24002, 54.790580099],
  [-79.2386201, 54.792099999],
  [-79.2372901, 54.792079999],
  [-79.23591, 54.793339999],
  [-79.23114, 54.794380099],
  [-79.2314001, 54.795059899],
  [-79.22825, 54.796049899],
  [-79.2264199, 54.797429999],
  [-79.22157, 54.796719899],
  [-79.2210899, 54.797480099],
  [-79.21669, 54.796899999],
  [-79.21621, 54.797659999],
  [-79.21135, 54.797209999],
  [-79.2096001, 54.796799999],
  [-79.2096201, 54.796289899],
  [-79.19892, 54.797549999],
  [-79.1896199, 54.797389899],
  [-79.18469, 54.798600099],
  [-79.1807001, 54.798669999],
  [-79.18022, 54.799429999],
  [-79.1779599, 54.800299999],
  [-79.17664, 54.800009999],
  [-79.1636801, 54.802260099],
  [-79.1632, 54.803019999],
  [-79.1524601, 54.805160099],
  [-79.15245, 54.805669999],
  [-79.15066, 54.805899999],
  [-79.15063, 54.806419999],
  [-79.14931, 54.806140099],
  [-79.14839, 54.807019999],
  [-79.1453, 54.806329999],
  [-79.14529, 54.806849899],
  [-79.14217, 54.807309999],
  [-79.1425801, 54.807830099],
  [-79.1403501, 54.808050099],
  [-79.14032, 54.808820099],
  [-79.13899, 54.808799999],
  [-79.13851, 54.809559999],
  [-79.1335999, 54.810249999],
  [-79.1335799, 54.810769999],
  [-79.1322599, 54.810490099],
  [-79.1322401, 54.810999999],
  [-79.1309199, 54.810720099],
  [-79.1313401, 54.811239999],
  [-79.12464, 54.812289899],
  [-79.1193599, 54.811439999],
  [-79.11308, 54.812749899],
  [-79.11306, 54.813260099],
  [-79.10948, 54.813720099],
  [-79.10853, 54.815249999],
  [-79.10539, 54.815959899],
  [-79.1035401, 54.817729899],
  [-79.0936799, 54.819879999],
  [-79.0937, 54.819370099],
  [-79.0897501, 54.818409899],
  [-79.0831001, 54.818429999],
  [-79.08306, 54.819199999],
  [-79.0781199, 54.820529899],
  [-79.07193, 54.820179899],
  [-79.0706301, 54.819380099],
  [-79.0661801, 54.819699999],
  [-79.06616, 54.820209999],
  [-79.06262, 54.819899999],
  [-79.0625599, 54.821179899],
  [-79.0594101, 54.822160099],
  [-79.05938, 54.822669999],
  [-79.05313, 54.823329999],
  [-79.05312, 54.823849899],
  [-79.0515299, 54.824329999],
  [-79.04203, 54.823799999],
  [-79.0370799, 54.825129999],
  [-79.03569, 54.826380099],
  [-79.0280601, 54.828050099],
  [-79.02804, 54.828569999],
  [-79.02671, 54.828539999],
  [-79.02577, 54.829549999],
  [-79.02488, 54.829539999],
  [-79.0252999, 54.830060099],
  [-79.0230601, 54.830529899],
  [-79.0216399, 54.832299899],
  [-79.02076, 54.832290099],
  [-79.0211799, 54.832809999],
  [-79.0193999, 54.832789999],
  [-79.01935, 54.833809999],
  [-79.01389, 54.836529899],
  [-79.01477, 54.836799999],
  [-79.0067001, 54.838339999],
  [-79.0032, 54.837639899],
  [-79.00365, 54.837129999],
  [-79.00277, 54.837119999],
  [-79.0028301, 54.835830099],
  [-78.99755, 54.834979899],
  [-78.99542, 54.833149999],
  [-78.9962899, 54.833429999],
  [-78.9959201, 54.832139999],
  [-78.9928399, 54.831449999],
  [-78.97944, 54.833149999],
  [-78.97755, 54.835419899],
  [-78.9767001, 54.834889999],
  [-78.97666, 54.835659999],
  [-78.9730399, 54.836879999],
  [-78.973, 54.837649899],
  [-78.97166, 54.837869899],
  [-78.97164, 54.838400099],
  [-78.9673501, 54.839859899],
  [-78.9665801, 54.841769999],
  [-78.9621401, 54.842079899],
  [-78.96165, 54.842840099],
  [-78.96032, 54.842819999],
  [-78.96029, 54.843329999],
  [-78.95541, 54.843249999],
  [-78.95533, 54.844779999],
  [-78.9562301, 54.844539899],
  [-78.95554, 54.845039999],
  [-78.9557099, 54.846070099],
  [-78.9548299, 54.846049999],
  [-78.95432, 54.847329999],
  [-78.9480299, 54.848759899],
  [-78.94776, 54.849779999],
  [-78.94885, 54.850049999],
  [-78.94883, 54.850559999],
  [-78.95462, 54.850149999],
  [-78.95509, 54.849649899],
  [-78.9550499, 54.850410099],
  [-78.93887, 54.854239999],
  [-78.9387701, 54.856290099],
  [-78.93965, 54.856300099],
  [-78.9396899, 54.859889999],
  [-78.9369299, 54.861889899],
  [-78.9349901, 54.869290099],
  [-78.9336601, 54.869259999],
  [-78.93361, 54.870290099],
  [-78.9437801, 54.870970099],
  [-78.93298, 54.873860099],
  [-78.93296, 54.874379999],
  [-78.90269, 54.884099899],
  [-78.90192, 54.881779899],
  [-78.8952299, 54.882310099],
  [-78.8907, 54.883899899],
  [-78.8885201, 54.887439899],
  [-78.8904301, 54.889009899],
  [-78.8926401, 54.889310099],
  [-78.89273, 54.891870099],
  [-78.8944, 54.893949999],
  [-78.8941199, 54.894970099],
  [-78.8847799, 54.895059999],
  [-78.88707, 54.893819999],
  [-78.8850099, 54.890709999],
  [-78.88326, 54.890289999],
  [-78.8792299, 54.890870099],
  [-78.8776, 54.892119899],
  [-78.8782199, 54.893029999],
  [-78.88071, 54.893619999],
  [-78.8800801, 54.891519999],
  [-78.8805399, 54.891149999],
  [-78.88425, 54.892489999],
  [-78.8844201, 54.893519999],
  [-78.8817501, 54.893359999],
  [-78.8809999, 54.893809999],
  [-78.88126, 54.894489999],
  [-78.87857, 54.894949999],
  [-78.87859, 54.894439899],
  [-78.87636, 54.894669899],
  [-78.87583, 54.896189999],
  [-78.87672, 54.896210099],
  [-78.8766699, 54.897229899],
  [-78.8753399, 54.897210099],
  [-78.8753101, 54.897729999],
  [-78.87306, 54.898189999],
  [-78.8707501, 54.899939999],
  [-78.86849, 54.900540099],
  [-78.86627, 54.900640099],
  [-78.8658799, 54.899599999],
  [-78.8645301, 54.899829999],
  [-78.86434, 54.899330099],
  [-78.86571, 54.898569899],
  [-78.8648601, 54.897530099],
  [-78.86512, 54.897029999],
  [-78.858, 54.897159999],
  [-78.8584199, 54.897679899],
  [-78.8561999, 54.897640099],
  [-78.8566201, 54.898149999],
  [-78.84944, 54.899569899],
  [-78.8469799, 54.903880099],
  [-78.8447, 54.904849999],
  [-78.84692, 54.905029999],
  [-78.84755, 54.905679899],
  [-78.84638, 54.906689899],
  [-78.8378499, 54.908330099],
  [-78.83632, 54.911890099],
  [-78.8351999, 54.912119899],
  [-78.8322999, 54.912330099],
  [-78.8319501, 54.910529999],
  [-78.8271, 54.909670099],
  [-78.82501, 54.907339899],
  [-78.8134901, 54.906609999],
  [-78.8135099, 54.906100099],
  [-78.8001, 54.907519999],
  [-78.79835, 54.906980099],
  [-78.7894601, 54.906819999],
  [-78.78241, 54.905660099],
  [-78.77652, 54.907729999],
  [-78.7764899, 54.908489999],
  [-78.7698199, 54.908249899],
  [-78.76308, 54.909409999],
  [-78.7595601, 54.908959999],
  [-78.75997, 54.909479999],
  [-78.75785, 54.910379999],
  [-78.76529, 54.909829999],
  [-78.7655801, 54.912660099],
  [-78.76376, 54.913259999],
  [-78.75664, 54.913519999],
  [-78.7556899, 54.914519999],
  [-78.7525899, 54.914339899],
  [-78.7462599, 54.916139899],
  [-78.7462201, 54.916909899],
  [-78.7453301, 54.916890099],
  [-78.7424799, 54.920169999],
  [-78.73583, 54.919660099],
  [-78.7339901, 54.920780099],
  [-78.7285699, 54.922089999],
  [-78.72637, 54.921919899],
  [-78.7258901, 54.922419999],
  [-78.7338801, 54.922829999],
  [-78.72939, 54.923509999],
  [-78.7293599, 54.924029899],
  [-78.7280299, 54.924000099],
  [-78.7280001, 54.924519999],
  [-78.7271299, 54.924359899],
  [-78.7227899, 54.926210099],
  [-78.72141, 54.926949999],
  [-78.7215501, 54.928489999],
  [-78.71885, 54.928949999],
  [-78.7165, 54.931220099],
  [-78.71782, 54.931489999],
  [-78.7177899, 54.932010099],
  [-78.7123499, 54.933699899],
  [-78.7123299, 54.934209999],
  [-78.7011901, 54.934509999],
  [-78.70071, 54.935019899],
  [-78.70126, 54.933230099],
  [-78.69815, 54.933169999],
  [-78.69818, 54.932660099],
  [-78.69369, 54.933340099],
  [-78.6932001, 54.934099999],
  [-78.67938, 54.934609999],
  [-78.6779701, 54.935859999],
  [-78.67525, 54.936709899],
  [-78.6663001, 54.937689899],
  [-78.6638401, 54.941739999],
  [-78.6607, 54.942189999],
  [-78.6606499, 54.942959999],
  [-78.6466101, 54.947169999],
  [-78.6278499, 54.948219999],
  [-78.62754, 54.949749999],
  [-78.6281699, 54.950399999],
  [-78.63191, 54.951109999],
  [-78.63158, 54.952900099],
  [-78.6279699, 54.953979999],
  [-78.62175, 54.953609899],
  [-78.61912, 54.952900099],
  [-78.6191501, 54.952399999],
  [-78.6182501, 54.952639999],
  [-78.61967, 54.951130099],
  [-78.6179301, 54.950460099],
  [-78.61524, 54.950790099],
  [-78.6138299, 54.951790099],
  [-78.6169301, 54.952360099],
  [-78.61554, 54.953230099],
  [-78.61466, 54.953079999],
  [-78.6146299, 54.953589899],
  [-78.6097199, 54.953749999],
  [-78.6082699, 54.955779999],
  [-78.6061799, 54.956079999],
  [-78.60654, 54.957449999],
  [-78.61035, 54.958120099],
  [-78.6098299, 54.959389899],
  [-78.60532, 54.960339999],
  [-78.6052899, 54.960839999],
  [-78.5999399, 54.960989999],
  [-78.60034, 54.961769999],
  [-78.59853, 54.962240099],
  [-78.5984999, 54.962759999],
  [-78.5889499, 54.966149899],
  [-78.58796, 54.964079999],
  [-78.5882699, 54.962549999],
  [-78.5891599, 54.962570099],
  [-78.5897099, 54.960790099],
  [-78.5865899, 54.960849999],
  [-78.5836901, 54.959729999],
  [-78.58395, 54.960419999],
  [-78.57907, 54.960069999],
  [-78.5779101, 54.960810099],
  [-78.57755, 54.963109999],
  [-78.57622, 54.963079999],
  [-78.57617, 54.963849999],
  [-78.57306, 54.963529999],
  [-78.5725801, 54.964289999],
  [-78.5756999, 54.964350099],
  [-78.5756701, 54.964859999],
  [-78.5729299, 54.965959999],
  [-78.5697901, 54.966279899],
  [-78.56976, 54.966800099],
  [-78.56439, 54.967199999],
  [-78.5639001, 54.967959999],
  [-78.56209, 54.968439999],
  [-78.56251, 54.968959999],
  [-78.56162, 54.968939899],
  [-78.5614799, 54.971240099],
  [-78.5575601, 54.972279899],
  [-78.5576, 54.972700099],
  [-78.56361, 54.972819899],
  [-78.56374, 54.974109999],
  [-78.5650299, 54.975159899],
  [-78.56428, 54.976419999],
  [-78.5620399, 54.976629999],
  [-78.56198, 54.977669999],
  [-78.5471101, 54.980419999],
  [-78.5463, 54.982719899],
  [-78.54716, 54.983250099],
  [-78.5481399, 54.985449999],
  [-78.55076, 54.986270099],
  [-78.55432, 54.986339999],
  [-78.5549099, 54.987759999],
  [-78.55418, 54.988769999],
  [-78.5519001, 54.989499899],
  [-78.5550299, 54.989299999],
  [-78.55316, 54.990799999],
  [-78.5473, 54.991839899],
  [-78.54286, 54.991619899],
  [-78.5461201, 54.989119999],
  [-78.54484, 54.988459999],
  [-78.54127, 54.988509899],
  [-78.5412399, 54.989009999],
  [-78.53942, 54.989629899],
  [-78.5358801, 54.989169899],
  [-78.53585, 54.989679999],
  [-78.5336, 54.990150099],
  [-78.5331001, 54.990920099],
  [-78.52681, 54.991810099],
  [-78.52586, 54.992810099],
  [-78.5205301, 54.992449999],
  [-78.52071, 54.993219999],
  [-78.5218, 54.993499899],
  [-78.52085, 54.994499899],
  [-78.52004, 54.993209999],
  [-78.5178, 54.993419999],
  [-78.51729, 54.994429999],
  [-78.5145599, 54.995399899],
  [-78.51448, 54.996679999],
  [-78.51314, 54.996649999],
  [-78.5139901, 54.997439999],
  [-78.5086401, 54.997329999],
  [-78.5086799, 54.996820099],
  [-78.50736, 54.996529999],
  [-78.50681, 54.998059899],
  [-78.5047699, 54.998789999],
  [-78.5044299, 55.000569999],
  [-78.4995, 55.000979999],
  [-78.4995399, 55.000469999],
  [-78.4977599, 55.000429999],
  [-78.49882, 55.001219999],
  [-78.4985499, 55.001989999],
  [-78.4913299, 55.003380099],
  [-78.4900899, 55.001810099],
  [-78.4883099, 55.001779999],
  [-78.4882199, 55.003309999],
  [-78.49046, 55.003099999],
  [-78.49038, 55.004380099],
  [-78.48636, 55.004549999],
  [-78.48513, 55.002739899],
  [-78.48027, 55.002129999],
  [-78.4803001, 55.001610099],
  [-78.47357, 55.002370099],
  [-78.47226, 55.001959899],
  [-78.4721899, 55.002979999],
  [-78.4712999, 55.002959899],
  [-78.4717301, 55.003349999],
  [-78.4775201, 55.003349999],
  [-78.47748, 55.003859899],
  [-78.47389, 55.004299899],
  [-78.4738599, 55.004809999],
  [-78.4688599, 55.006370099],
  [-78.46344, 55.007409899],
  [-78.4619799, 55.009429999],
  [-78.45078, 55.010219999],
  [-78.45021, 55.009779999],
  [-78.4467399, 55.010649999],
  [-78.4467101, 55.011160099],
  [-78.44132, 55.011809999],
  [-78.4408301, 55.012569999],
  [-78.43858, 55.012779999],
  [-78.43713, 55.014539999],
  [-78.43533, 55.015019999],
  [-78.4352899, 55.015529899],
  [-78.43075, 55.016710099],
  [-78.4307001, 55.017479999],
  [-78.4293701, 55.017449999],
  [-78.4273899, 55.020490099],
  [-78.4224801, 55.020510099],
  [-78.42034, 55.019179899],
  [-78.40879, 55.018549999],
  [-78.40875, 55.019069899],
  [-78.4029001, 55.019969899],
  [-78.4022, 55.020459999],
  [-78.4023699, 55.021239999],
  [-78.3952, 55.021720099],
  [-78.3912501, 55.020869899],
  [-78.3881, 55.021189899],
  [-78.3880201, 55.022469999],
  [-78.3889101, 55.022490099],
  [-78.38888, 55.022999999],
  [-78.3856901, 55.023950099],
  [-78.38566, 55.024459999],
  [-78.3824799, 55.025429899],
  [-78.38244, 55.025929999],
  [-78.36443, 55.028629899],
  [-78.3639199, 55.029629899],
  [-78.36695, 55.030979899],
  [-78.35353, 55.031579999],
  [-78.34852, 55.033129999],
  [-78.3465799, 55.035400099],
  [-78.34474, 55.036259999],
  [-78.33938, 55.036400099],
  [-78.3375599, 55.036999999],
  [-78.33703, 55.038269999],
  [-78.33295, 55.039209899],
  [-78.3346501, 55.040520099],
  [-78.3215599, 55.042799999],
  [-78.32159, 55.042290099],
  [-78.31669, 55.042180099],
  [-78.31525, 55.043679999],
  [-78.31436, 55.043659899],
  [-78.31427, 55.044939999],
  [-78.31158, 55.045139999],
  [-78.3115301, 55.045909999],
  [-78.3106401, 55.045889999],
  [-78.3094399, 55.047139999],
  [-78.30714, 55.051449999],
  [-78.30579, 55.051669999],
  [-78.30548, 55.052949999],
  [-78.3060801, 55.053989899],
  [-78.3047401, 55.053970099],
  [-78.3037799, 55.054960099],
  [-78.3015201, 55.055410099],
  [-78.30157, 55.054649899],
  [-78.2944101, 55.055009999],
  [-78.29291, 55.057279999],
  [-78.28927, 55.058219899],
  [-78.2850601, 55.061459999],
  [-78.2828, 55.061669899],
  [-78.28158, 55.063180099],
  [-78.28247, 55.066530099],
  [-78.2721601, 55.067069999],
  [-78.26657, 55.073860099],
  [-78.2644901, 55.074839999],
  [-78.2646101, 55.076389999],
  [-78.2681799, 55.079659899],
  [-78.27045, 55.079200099],
  [-78.2714199, 55.078069999],
  [-78.27411, 55.077870099],
  [-78.27177, 55.079349899],
  [-78.2728699, 55.079640099],
  [-78.27304, 55.080409999],
  [-78.27215, 55.080389999],
  [-78.27212, 55.080889899],
  [-78.26703, 55.083479999],
  [-78.26568, 55.083569899],
  [-78.2651701, 55.084589999],
  [-78.2638301, 55.084559899],
  [-78.26287, 55.085550099],
  [-78.26013, 55.086389999],
  [-78.25703, 55.085939999],
  [-78.2571701, 55.083889899],
  [-78.2580601, 55.083909899],
  [-78.25811, 55.083149999],
  [-78.25591, 55.082839999],
  [-78.25599, 55.081559899],
  [-78.25332, 55.081619999],
  [-78.24421, 55.084109899],
  [-78.24369, 55.085129899],
  [-78.2428, 55.085109899],
  [-78.24442, 55.087440099],
  [-78.24669, 55.086990099],
  [-78.24578, 55.087219899],
  [-78.2466199, 55.088009899],
  [-78.24803, 55.086889899],
  [-78.2502601, 55.087200099],
  [-78.2506299, 55.088100099],
  [-78.2451401, 55.090029999],
  [-78.2446001, 55.091299999],
  [-78.24097, 55.092239899],
  [-78.24, 55.093239899],
  [-78.2382, 55.093459899],
  [-78.2371799, 55.092149999],
  [-78.2372501, 55.091129899],
  [-78.23163, 55.091519999],
  [-78.2316001, 55.092029999],
  [-78.22978, 55.092499999],
  [-78.2278399, 55.094760099],
  [-78.2291699, 55.094789899],
  [-78.2259499, 55.096249899],
  [-78.23056, 55.097379999],
  [-78.2304901, 55.098409999],
  [-78.2293, 55.099399999],
  [-78.2274999, 55.099619999],
  [-78.22746, 55.100129899],
  [-78.22007, 55.103679899],
  [-78.2169201, 55.103990099],
  [-78.21653, 55.103210099],
  [-78.2143, 55.103159999],
  [-78.21331, 55.104419999],
  [-78.207, 55.105299999],
  [-78.20696, 55.105809899],
  [-78.2051401, 55.106279999],
  [-78.20451, 55.108829999],
  [-78.19855, 55.111000099],
  [-78.1989299, 55.112029899],
  [-78.1896899, 55.116419999],
  [-78.1926901, 55.118179999],
  [-78.1966901, 55.118389999],
  [-78.19441, 55.119099999],
  [-78.1943699, 55.119609999],
  [-78.1993401, 55.118959999],
  [-78.19789, 55.120460099],
  [-78.1942499, 55.121399999],
  [-78.19375, 55.122159999],
  [-78.19053, 55.123369899],
  [-78.19298, 55.120349899],
  [-78.19209, 55.120330099],
  [-78.1920299, 55.121099999],
  [-78.1911301, 55.121079999],
  [-78.18875, 55.123330099],
  [-78.1851, 55.124269999],
  [-78.1841399, 55.125269999],
  [-78.1827999, 55.125240099],
  [-78.17951, 55.127469899],
  [-78.1765, 55.128000099],
  [-78.17676, 55.128429999],
  [-78.17587, 55.128409999],
  [-78.1753499, 55.129419999],
  [-78.1744599, 55.129399999],
  [-78.1750001, 55.128130099],
  [-78.17084, 55.129959999],
  [-78.1658999, 55.130230099],
  [-78.1664, 55.129469899],
  [-78.1655, 55.129450099],
  [-78.1653401, 55.128680099],
  [-78.1674301, 55.127570099],
  [-78.1727701, 55.127949999],
  [-78.1744699, 55.126069999],
  [-78.17817, 55.124359899],
  [-78.1784501, 55.123599899],
  [-78.1793499, 55.123619999],
  [-78.17917, 55.122849999],
  [-78.18086, 55.121099999],
  [-78.17772, 55.121279999],
  [-78.17675, 55.122279999],
  [-78.1703401, 55.124440099],
  [-78.17028, 55.125209999],
  [-78.16441, 55.126089999],
  [-78.1634401, 55.127099999],
  [-78.1589099, 55.128020099],
  [-78.15817, 55.129020099],
  [-78.1601001, 55.130089999],
  [-78.15826, 55.130819899],
  [-78.1580001, 55.131329999],
  [-78.1595501, 55.131619999],
  [-78.1580199, 55.134139899],
  [-78.1571299, 55.134120099],
  [-78.15707, 55.135149899],
  [-78.1538701, 55.136099999],
  [-78.15148, 55.138089999],
  [-78.14462, 55.140240099],
  [-78.14226, 55.141969999],
  [-78.1399901, 55.142429999],
  [-78.13995, 55.142949999],
  [-78.1376599, 55.143659999],
  [-78.1362199, 55.145159899],
  [-78.1353201, 55.145140099],
  [-78.1315699, 55.147619999],
  [-78.1302299, 55.147580099],
  [-78.12926, 55.148589899],
  [-78.12792, 55.148559999],
  [-78.1265, 55.149669999],
  [-78.1224499, 55.149959999],
  [-78.1265, 55.146470099],
  [-78.12661, 55.144939899],
  [-78.1277501, 55.144709899],
  [-78.1279899, 55.141379899],
  [-78.1288799, 55.141399999],
  [-78.1287501, 55.140120099],
  [-78.12992, 55.139379899],
  [-78.12905, 55.139099999],
  [-78.1280801, 55.139979999],
  [-78.1267401, 55.140069999],
  [-78.12693, 55.140589899],
  [-78.12435, 55.142189999],
  [-78.12302, 55.142029899],
  [-78.12205, 55.143039899],
  [-78.11839, 55.144230099],
  [-78.11926, 55.144509999],
  [-78.1194299, 55.145279999],
  [-78.11872, 55.145779999],
  [-78.12006, 55.145809899],
  [-78.11757, 55.149339999],
  [-78.11572, 55.150189999],
  [-78.1126, 55.149989999],
  [-78.1120999, 55.150739999],
  [-78.10535, 55.151350099],
  [-78.1048501, 55.152109999],
  [-78.1126401, 55.152549999],
  [-78.1105, 55.154289999],
  [-78.10779, 55.154739999],
  [-78.1078401, 55.153969999],
  [-78.1069601, 55.153819899],
  [-78.10423, 55.154399999],
  [-78.10427, 55.153889999],
  [-78.10248, 55.153839899],
  [-78.1019799, 55.154599899],
  [-78.09148, 55.157429999],
  [-78.08958, 55.158920099],
  [-78.08826, 55.158629999],
  [-78.0873099, 55.159389899],
  [-78.08735, 55.158869999],
  [-78.08515, 55.158299999],
  [-78.0847399, 55.160849999],
  [-78.0853199, 55.162140099],
  [-78.0807399, 55.163580099],
  [-78.0807, 55.164089999],
  [-78.07936, 55.164049899],
  [-78.07933, 55.164569999],
  [-78.0770501, 55.165020099],
  [-78.07702, 55.165539999],
  [-78.07524, 55.165489899],
  [-78.0749699, 55.162920099],
  [-78.0731501, 55.163389899],
  [-78.0692701, 55.167399899],
  [-78.06511, 55.169219999],
  [-78.0556001, 55.170659999],
  [-78.05622, 55.168370099],
  [-78.05577, 55.168360099],
  [-78.0511, 55.171059899],
  [-78.0492899, 55.171269899],
  [-78.04877, 55.172289899],
  [-78.04743, 55.172250099],
  [-78.0473899, 55.172759999],
  [-78.0423601, 55.174179899],
  [-78.04233, 55.174690099],
  [-78.0395801, 55.175389899],
  [-78.03955, 55.175909999],
  [-78.0328199, 55.175999999],
  [-78.03087, 55.178260099],
  [-78.02997, 55.178229999],
  [-78.0299101, 55.178999999],
  [-78.0325999, 55.179069899],
  [-78.03256, 55.179579999],
  [-78.03029, 55.179920099],
  [-78.02855, 55.179219999],
  [-78.0266099, 55.181229999],
  [-78.02343, 55.181920099],
  [-78.02153, 55.183399899],
  [-78.0202, 55.183380099],
  [-78.0201301, 55.184140099],
  [-78.01829, 55.184609899],
  [-78.0187099, 55.185129999],
  [-78.01362, 55.187309999],
  [-78.01263, 55.188569999],
  [-78.0149101, 55.188119999],
  [-78.0139199, 55.189370099],
  [-78.0121301, 55.189329999],
  [-78.01089, 55.187889999],
  [-78.00775, 55.187940099],
  [-78.0077099, 55.188449999],
  [-78.0058901, 55.188920099],
  [-78.0063, 55.189439999],
  [-78.0044899, 55.189649999],
  [-78.0044501, 55.190160099],
  [-78.0012301, 55.191370099],
  [-78.0006999, 55.192380099],
  [-77.99699, 55.193199999],
  [-77.9932, 55.196370099],
  [-77.9892499, 55.197490099],
  [-77.98929, 55.198009999],
  [-77.9854, 55.199769999],
  [-77.9796899, 55.201439999],
  [-77.97148, 55.205490099],
  [-77.9657, 55.206009999],
  [-77.96574, 55.206519899],
  [-77.9631199, 55.207479999],
  [-77.9573, 55.207620099],
  [-77.9542199, 55.208339999],
  [-77.95258, 55.210429999],
  [-77.94774, 55.211569999],
  [-77.9477, 55.211060099],
  [-77.9463299, 55.210709999],
  [-77.9387601, 55.211280099],
  [-77.9371199, 55.213369999],
  [-77.9339999, 55.213699999],
  [-77.93235, 55.215529899],
  [-77.9301801, 55.216479999],
  [-77.92701, 55.216039999],
  [-77.91813, 55.217280099],
  [-77.9133099, 55.218809999],
  [-77.91168, 55.220889999],
  [-77.9081701, 55.221999999],
  [-77.9076499, 55.224060099],
  [-77.90814, 55.224569999],
  [-77.9070899, 55.225620099],
  [-77.90304, 55.225459999],
  [-77.9024099, 55.225979899],
  [-77.9022999, 55.227520099],
  [-77.9014001, 55.227539899],
  [-77.9001701, 55.229109999],
  [-77.8979401, 55.229159999],
  [-77.8975399, 55.229939999],
  [-77.8966499, 55.229960099],
  [-77.89721, 55.231489999],
  [-77.90103, 55.231649899],
  [-77.90135, 55.232929999],
  [-77.90045, 55.232950099],
  [-77.9005101, 55.233709999],
  [-77.89878, 55.234649899],
  [-77.89432, 55.234889999],
  [-77.8932901, 55.233119999],
  [-77.8888599, 55.233740099],
  [-77.88934, 55.234239999],
  [-77.888, 55.234269999],
  [-77.88811, 55.235799999],
  [-77.88591, 55.236369999],
  [-77.8858499, 55.235599999],
  [-77.8823099, 55.236199899],
  [-77.8828301, 55.237209899],
  [-77.8819599, 55.237609999],
  [-77.87836, 55.237579999],
  [-77.8784099, 55.238080099],
  [-77.8766399, 55.238379999],
  [-77.8773801, 55.239389999],
  [-77.8752899, 55.241489999],
  [-77.8756101, 55.242759899],
  [-77.8689, 55.243169999],
  [-77.8693899, 55.243669899],
  [-77.86676, 55.244510099],
  [-77.8659401, 55.245549899],
  [-77.8646001, 55.245589999],
  [-77.8660501, 55.247080099],
  [-77.8669499, 55.247059999],
  [-77.86799, 55.2490901],
  [-77.8652899, 55.2489],
  [-77.86577, 55.2494001],
  [-77.86211, 55.24846],
  [-77.86157, 55.247190099],
  [-77.86023, 55.247219899],
  [-77.8591699, 55.24801],
  [-77.8594801, 55.2492901],
  [-77.8586269, 55.2503487],
  [-77.8570479, 55.2502421],
  [-77.849994, 55.2533013],
  [-77.8484392, 55.2559644],
  [-77.8490825, 55.2568358],
  [-77.8483963, 55.2574974],
  [-77.8446169, 55.2584976],
  [-77.8427774, 55.2583783],
  [-77.8397671, 55.2576177],
  [-77.8367605, 55.2547189],
  [-77.8349393, 55.2541841],
  [-77.8304423, 55.2567339],
  [-77.8304231, 55.2575294],
  [-77.8289409, 55.2589396],
  [-77.8262928, 55.2589774],
  [-77.8214928, 55.2612225],
  [-77.8165447, 55.2586909],
  [-77.8125872, 55.258854],
  [-77.813287, 55.2581661],
  [-77.8127108, 55.2574666],
  [-77.808314, 55.255516],
  [-77.8087634, 55.2550358],
  [-77.8077383, 55.2551587],
  [-77.8077123, 55.2556585],
  [-77.8100763, 55.2565839],
  [-77.8102878, 55.2575205],
  [-77.8065749, 55.2589294],
  [-77.8050845, 55.2583988],
  [-77.8055035, 55.2592162],
  [-77.8048431, 55.2598912],
  [-77.7970069, 55.260362],
  [-77.7939655, 55.2616202],
  [-77.7886544, 55.2700854],
  [-77.7774606, 55.275882],
  [-77.7721864, 55.2797053],
  [-77.7670719, 55.2845333],
  [-77.7614366, 55.2916941],
  [-77.7618464, 55.293214],
  [-77.7602054, 55.2945989],
  [-77.7605807, 55.2970453],
  [-77.7599796, 55.2981767],
  [-77.7572638, 55.2995434],
  [-77.7549163, 55.2977961],
  [-77.7504818, 55.2984371],
  [-77.7509923, 55.2992446],
  [-77.7501991, 55.3003298],
  [-77.7483852, 55.3013729],
  [-77.746649, 55.3010075],
  [-77.7480353, 55.3016194],
  [-77.7478119, 55.3020462],
  [-77.743386, 55.3066212],
  [-77.7446324, 55.309418],
  [-77.7478283, 55.3098394],
  [-77.7455046, 55.3125],
  [-77.74149, 55.3140001],
  [-77.74146, 55.31349],
  [-77.7378501, 55.31331],
  [-77.7384001, 55.3145799],
  [-77.7375, 55.3145899],
  [-77.73671, 55.31616],
  [-77.7318401, 55.31729],
  [-77.73193, 55.3185701],
  [-77.73017, 55.3191201],
  [-77.7302099, 55.31963],
  [-77.7292901, 55.31941],
  [-77.7285, 55.32095],
  [-77.7258601, 55.3217801],
  [-77.7259, 55.32229],
  [-77.7219799, 55.32417],
  [-77.72125, 55.32675],
  [-77.72036, 55.32677],
  [-77.7204199, 55.32754],
  [-77.7195201, 55.32755],
  [-77.71912, 55.32833],
  [-77.71822, 55.3283501],
  [-77.7178599, 55.32964],
  [-77.7165399, 55.3299299],
  [-77.71657, 55.33044],
  [-77.71169, 55.33132],
  [-77.7117201, 55.3318299],
  [-77.7051601, 55.33454],
  [-77.70435, 55.33584],
  [-77.69992, 55.3366999],
  [-77.69823, 55.3382799],
  [-77.69733, 55.3383],
  [-77.69737, 55.3388101],
  [-77.6960401, 55.33909],
  [-77.6960601, 55.3396099],
  [-77.693, 55.3407001],
  [-77.6924199, 55.3422501],
  [-77.6937101, 55.34452],
  [-77.6968201, 55.3439399],
  [-77.696, 55.3452401],
  [-77.69114, 55.3463799],
  [-77.6910501, 55.3451],
  [-77.6892899, 55.34565],
  [-77.68934, 55.34641],
  [-77.6853801, 55.34766],
  [-77.68131, 55.3473701],
  [-77.6809201, 55.3483899],
  [-77.67958, 55.34842],
  [-77.6792601, 55.35022],
  [-77.67836, 55.3502401],
  [-77.67701, 55.3535999],
  [-77.67732, 55.35488],
  [-77.67824, 55.35511],
  [-77.67432, 55.35699],
  [-77.6743801, 55.35776],
  [-77.67347, 55.35778],
  [-77.6735199, 55.35855],
  [-77.6708499, 55.35887],
  [-77.67133, 55.3593601],
  [-77.6656099, 55.3612799],
  [-77.66565, 55.36179],
  [-77.6643, 55.3618201],
  [-77.66366, 55.36235],
  [-77.66397, 55.3636199],
  [-77.6595711, 55.3639515],
  [-77.6583, 55.36631],
  [-77.65741, 55.36633],
  [-77.6575099, 55.36786],
  [-77.6504801, 55.37032],
  [-77.65089, 55.3698],
  [-77.64953, 55.3697001],
  [-77.64645, 55.37067],
  [-77.64652, 55.37169],
  [-77.64742, 55.37167],
  [-77.6474701, 55.37244],
  [-77.6465701, 55.37246],
  [-77.64662, 55.37322],
  [-77.6457199, 55.37324],
  [-77.64576, 55.37375],
  [-77.64398, 55.3740501],
  [-77.6440301, 55.3748201],
  [-77.6404999, 55.3759201],
  [-77.64054, 55.37643],
  [-77.63964, 55.37645],
  [-77.6383699, 55.37776],
  [-77.6353, 55.3788499],
  [-77.6349199, 55.37988],
  [-77.6295899, 55.38102],
  [-77.62963, 55.38153],
  [-77.62262, 55.3845001],
  [-77.62298, 55.3829599],
  [-77.62161, 55.3827299],
  [-77.62266, 55.38168],
  [-77.6259101, 55.3798201],
  [-77.6294701, 55.37923],
  [-77.62896, 55.37822],
  [-77.62854, 55.3787399],
  [-77.6253799, 55.37855],
  [-77.6253501, 55.3780401],
  [-77.62261, 55.37746],
  [-77.61588, 55.3778599],
  [-77.6089199, 55.38146],
  [-77.60819, 55.3840401],
  [-77.60729, 55.3840601],
  [-77.6077901, 55.3848201],
  [-77.60474, 55.3862899],
  [-77.6011201, 55.38599],
  [-77.6006, 55.3849699],
  [-77.5978801, 55.3846299],
  [-77.5935, 55.3863999],
  [-77.59353, 55.38692],
  [-77.58994, 55.38712],
  [-77.5885699, 55.38676],
  [-77.58781, 55.3855001],
  [-77.5887899, 55.3831701],
  [-77.58649, 55.38246],
  [-77.5864599, 55.3819401],
  [-77.5846501, 55.3818499],
  [-77.5824401, 55.38254],
  [-77.58248, 55.3830501],
  [-77.5802299, 55.3831],
  [-77.58161, 55.38358],
  [-77.5817, 55.3848599],
  [-77.5789901, 55.38492],
  [-77.57836, 55.38544],
  [-77.5786501, 55.38646],
  [-77.57685, 55.3865001],
  [-77.5762399, 55.3872801],
  [-77.5765501, 55.38881],
  [-77.5756601, 55.3888301],
  [-77.5757, 55.3896001],
  [-77.57029, 55.38946],
  [-77.5703299, 55.3899699],
  [-77.5689999, 55.39025],
  [-77.56949, 55.39101],
  [-77.5686, 55.39103],
  [-77.56863, 55.39154],
  [-77.56595, 55.3918499],
  [-77.56515, 55.3934099],
  [-77.56425, 55.39343],
  [-77.56305, 55.3957599],
  [-77.56172, 55.39604],
  [-77.5617501, 55.39655],
  [-77.55592, 55.39693],
  [-77.55559, 55.3987301],
  [-77.55157, 55.3991899],
  [-77.54836, 55.39837],
  [-77.5497, 55.40168],
  [-77.55157, 55.40266],
  [-77.5514101, 55.40369],
  [-77.5474101, 55.4045399],
  [-77.5474399, 55.4050501],
  [-77.5461099, 55.40533],
  [-77.54616, 55.4061],
  [-77.54526, 55.40612],
  [-77.54591, 55.4091801],
  [-77.54193, 55.4102901],
  [-77.54113, 55.4118401],
  [-77.53889, 55.41214],
  [-77.53681, 55.4147499],
  [-77.5328699, 55.4165001],
  [-77.53016, 55.4164199],
  [-77.52934, 55.41772],
  [-77.5257499, 55.4178],
  [-77.52366, 55.4204001],
  [-77.5227599, 55.4204199],
  [-77.52382, 55.4229601],
  [-77.51711, 55.42361],
  [-77.5170799, 55.4230999],
  [-77.51571, 55.4228699],
  [-77.5158401, 55.42492],
  [-77.5145001, 55.42494],
  [-77.5145399, 55.42571],
  [-77.50881, 55.4278899],
  [-77.50633, 55.43126],
  [-77.50455, 55.4315499],
  [-77.50378, 55.4336201],
  [-77.49933, 55.43448],
  [-77.5011899, 55.43546],
  [-77.49581, 55.43583],
  [-77.4954099, 55.43661],
  [-77.49631, 55.43659],
  [-77.4963701, 55.43761],
  [-77.4931899, 55.43717],
  [-77.4931601, 55.4366499],
  [-77.4918201, 55.43694],
  [-77.49187, 55.4377],
  [-77.49275, 55.4374299],
  [-77.49279, 55.43794],
  [-77.49414, 55.43791],
  [-77.4941699, 55.4384299],
  [-77.49055, 55.43824],
  [-77.49165, 55.4413001],
  [-77.4894099, 55.4416],
  [-77.48946, 55.44236],
  [-77.48544, 55.4429601],
  [-77.4850599, 55.4439899],
  [-77.4841601, 55.4440099],
  [-77.4842, 55.4447799],
  [-77.4833, 55.4448],
  [-77.4829499, 55.4463399],
  [-77.48097, 55.44715],
  [-77.4808499, 55.44894],
  [-77.4777399, 55.4495201],
  [-77.47777, 55.45003],
  [-77.4751401, 55.4511099],
  [-77.4751699, 55.45161],
  [-77.47022, 55.4519801],
  [-77.46986, 55.45327],
  [-77.46766, 55.4540801],
  [-77.46686, 55.45563],
  [-77.4659499, 55.4556501],
  [-77.466, 55.4564301],
  [-77.4642501, 55.4572199],
  [-77.4643, 55.4579901],
  [-77.46119, 55.45882],
  [-77.4611401, 55.4616401],
  [-77.46264, 55.4639099],
  [-77.4624801, 55.46494],
  [-77.4606801, 55.4649801],
  [-77.4602699, 55.4657601],
  [-77.4593701, 55.4657701],
  [-77.4581499, 55.46785],
  [-77.45278, 55.46847],
  [-77.45152, 55.47003],
  [-77.4474701, 55.4701001],
  [-77.44576, 55.4716799],
  [-77.43911, 55.47361],
  [-77.4389299, 55.4741199],
  [-77.44052, 55.4746],
  [-77.4401301, 55.47538],
  [-77.43142, 55.47795],
  [-77.43131, 55.47863],
  [-77.4304, 55.4786501],
  [-77.42874, 55.4809901],
  [-77.42784, 55.4810099],
  [-77.4265899, 55.4825699],
  [-77.42749, 55.4825501],
  [-77.42731, 55.4833201],
  [-77.4294501, 55.4853301],
  [-77.4263599, 55.48616],
  [-77.42595, 55.48693],
  [-77.4205701, 55.4875501],
  [-77.42064, 55.4885799],
  [-77.41798, 55.4894],
  [-77.41808, 55.49094],
  [-77.42038, 55.49165],
  [-77.41597, 55.4934],
  [-77.40997, 55.4912301],
  [-77.4059901, 55.4924501],
  [-77.40504, 55.4916999],
  [-77.4046501, 55.49273],
  [-77.4055501, 55.49272],
  [-77.40582, 55.4934799],
  [-77.4041001, 55.4947901],
  [-77.4049199, 55.49721],
  [-77.40582, 55.49707],
  [-77.40585, 55.4975799],
  [-77.4032101, 55.4987801],
  [-77.40049, 55.4984501],
  [-77.4007899, 55.49973],
  [-77.39986, 55.50043],
  [-77.40151, 55.5004799],
  [-77.4014301, 55.4992],
  [-77.40684, 55.4991],
  [-77.4068701, 55.49961],
  [-77.4046701, 55.50042],
  [-77.4047, 55.50119],
  [-77.4020299, 55.50162],
  [-77.4006601, 55.5012599],
  [-77.40062, 55.50076],
  [-77.39929, 55.5010399],
  [-77.3992499, 55.50039],
  [-77.39308, 55.5028199],
  [-77.3867601, 55.5028199],
  [-77.38581, 55.50207],
  [-77.3854399, 55.5033599],
  [-77.3827799, 55.50419],
  [-77.38113, 55.50677],
  [-77.37982, 55.50744],
  [-77.37394, 55.5073],
  [-77.3677601, 55.5095899],
  [-77.3658501, 55.5116801],
  [-77.36617, 55.5132],
  [-77.3675201, 55.51318],
  [-77.36715, 55.5144701],
  [-77.35595, 55.51597],
  [-77.3564299, 55.5164701],
  [-77.35335, 55.5176801],
  [-77.34793, 55.51766],
  [-77.34581, 55.5200101],
  [-77.34491, 55.5200201],
  [-77.3458699, 55.5210299],
  [-77.3440701, 55.52106],
  [-77.34434, 55.5218299],
  [-77.3429, 55.5241599],
  [-77.34067, 55.5247199],
  [-77.3407201, 55.5254799],
  [-77.3379899, 55.5252799],
  [-77.33893, 55.5259001],
  [-77.34348, 55.52645],
  [-77.3435299, 55.52722],
  [-77.3444299, 55.5272],
  [-77.34425, 55.52798],
  [-77.34991, 55.5282501],
  [-77.35046, 55.5299101],
  [-77.3441801, 55.53053],
  [-77.3427301, 55.5290301],
  [-77.3414001, 55.52931],
  [-77.34136, 55.5288001],
  [-77.34046, 55.5288199],
  [-77.3405001, 55.52933],
  [-77.33781, 55.52976],
  [-77.3346399, 55.5297001],
  [-77.3338501, 55.53151],
  [-77.3271601, 55.5329101],
  [-77.3237301, 55.5360499],
  [-77.3228301, 55.5360599],
  [-77.32288, 55.5368399],
  [-77.32021, 55.53766],
  [-77.31812, 55.5405099],
  [-77.31678, 55.5408001],
  [-77.3160601, 55.54388],
  [-77.3186001, 55.54486],
  [-77.31845, 55.5461401],
  [-77.31755, 55.5461599],
  [-77.31765, 55.5479499],
  [-77.31458, 55.5492899],
  [-77.3146299, 55.55031],
  [-77.31013, 55.55066],
  [-77.3101601, 55.5511699],
  [-77.3110501, 55.55089],
  [-77.31109, 55.55166],
  [-77.3101899, 55.55168],
  [-77.3078301, 55.5499301],
  [-77.3051, 55.5497299],
  [-77.30792, 55.5514701],
  [-77.30701, 55.5514801],
  [-77.30698, 55.5548101],
  [-77.30554, 55.5573999],
  [-77.302, 55.5584999],
  [-77.3045199, 55.5589599],
  [-77.3057299, 55.56047],
  [-77.30133, 55.56222],
  [-77.2963899, 55.5629499],
  [-77.29598, 55.5637399],
  [-77.29733, 55.5637001],
  [-77.2973801, 55.56447],
  [-77.2825801, 55.56654],
  [-77.2826099, 55.5670501],
  [-77.2794999, 55.56788],
  [-77.27953, 55.5683999],
  [-77.2728399, 55.5700501],
  [-77.27311, 55.5708201],
  [-77.2724801, 55.5715901],
  [-77.26755, 55.5722],
  [-77.2675701, 55.5727101],
  [-77.26578, 55.573],
  [-77.2653699, 55.57379],
  [-77.2631599, 55.57459],
  [-77.26266, 55.5738301],
  [-77.2622401, 55.57435],
  [-77.26176, 55.5738399],
  [-77.25954, 55.5743999],
  [-77.26097, 55.57591],
  [-77.25733, 55.57546],
  [-77.2573601, 55.5759599],
  [-77.2546801, 55.57654],
  [-77.25428, 55.57756],
  [-77.2533799, 55.57758],
  [-77.25139, 55.58222],
  [-77.24865, 55.5819],
  [-77.2460101, 55.58311],
  [-77.24573, 55.5861799],
  [-77.23719, 55.5871],
  [-77.2356801, 55.5884099],
  [-77.2352399, 55.5925199],
  [-77.2334601, 55.5930601],
  [-77.23349, 55.59357],
  [-77.2298901, 55.5939],
  [-77.22894, 55.59314],
  [-77.2285799, 55.59469],
  [-77.22993, 55.59466],
  [-77.2319001, 55.59745],
  [-77.2327999, 55.5972999],
  [-77.23285, 55.5981999],
  [-77.2351101, 55.59816],
  [-77.2351299, 55.59867],
  [-77.2342301, 55.59869],
  [-77.2343301, 55.60048],
  [-77.23029, 55.6010601],
  [-77.2303401, 55.6018301],
  [-77.22898, 55.6018501],
  [-77.2290101, 55.60237],
  [-77.2213599, 55.60302],
  [-77.22094, 55.60379],
  [-77.21253, 55.60702],
  [-77.2125799, 55.60779],
  [-77.2103101, 55.60783],
  [-77.21162, 55.60703],
  [-77.20982, 55.6070701],
  [-77.20985, 55.60758],
  [-77.20894, 55.6076],
  [-77.2090199, 55.6088799],
  [-77.2027701, 55.6105201],
  [-77.20236, 55.6113001],
  [-77.2005699, 55.6113299],
  [-77.20105, 55.6120899],
  [-77.20015, 55.61211],
  [-77.2002001, 55.61313],
  [-77.19929, 55.61289],
  [-77.1982399, 55.61445],
  [-77.1978701, 55.6170999],
  [-77.1984301, 55.6177699],
  [-77.1976799, 55.62061],
  [-77.19682, 55.62139],
  [-77.1989099, 55.62238],
  [-77.1926501, 55.6237699],
  [-77.19138, 55.6253199],
  [-77.1896, 55.6258699],
  [-77.18971, 55.62792],
  [-77.18748, 55.62847],
  [-77.18751, 55.6289801],
  [-77.1897799, 55.6292001],
  [-77.1889801, 55.6309999],
  [-77.18716, 55.6307799],
  [-77.1863001, 55.6317],
  [-77.18494, 55.63158],
  [-77.1849701, 55.6320999],
  [-77.18317, 55.63239],
  [-77.1836599, 55.63302],
  [-77.1845599, 55.6328799],
  [-77.1846, 55.6336501],
  [-77.1814201, 55.6334499],
  [-77.18192, 55.63446],
  [-77.1796501, 55.63425],
  [-77.17968, 55.6347601],
  [-77.1783399, 55.63504],
  [-77.18029, 55.63757],
  [-77.1758501, 55.63918],
  [-77.1742001, 55.64228],
  [-77.1719601, 55.64258],
  [-77.17073, 55.64491],
  [-77.16983, 55.64492],
  [-77.16957, 55.64851],
  [-77.1664201, 55.64883],
  [-77.1640701, 55.6473299],
  [-77.1666201, 55.6524201],
  [-77.16481, 55.6524401],
  [-77.15959, 55.65638],
  [-77.15645, 55.65694],
  [-77.15604, 55.65772],
  [-77.15291, 55.6585401],
  [-77.1534101, 55.6593],
  [-77.1498301, 55.6601199],
  [-77.1498501, 55.66064],
  [-77.1489501, 55.6606601],
  [-77.1486101, 55.65862],
  [-77.14905, 55.6583499],
  [-77.1480601, 55.65683],
  [-77.1481999, 55.65529],
  [-77.1472999, 55.6553101],
  [-77.14657, 55.6586501],
  [-77.14524, 55.65893],
  [-77.14526, 55.6594401],
  [-77.1307301, 55.65893],
  [-77.13077, 55.6597],
  [-77.1267101, 55.6600199],
  [-77.1258599, 55.66106],
  [-77.1236101, 55.6613599],
  [-77.12191, 55.6634401],
  [-77.12101, 55.6634499],
  [-77.1210501, 55.6642201],
  [-77.11969, 55.6642399],
  [-77.1199699, 55.66526],
  [-77.1211501, 55.6660099],
  [-77.1166299, 55.6663499],
  [-77.11622, 55.6671101],
  [-77.11488, 55.6674],
  [-77.11579, 55.66764],
  [-77.1162, 55.67096],
  [-77.11781, 55.6714501],
  [-77.1178399, 55.67196],
  [-77.1147001, 55.67253],
  [-77.1148101, 55.6743201],
  [-77.11389, 55.6743301],
  [-77.1143801, 55.67509],
  [-77.1148101, 55.6745699],
  [-77.1162, 55.67506],
  [-77.1174099, 55.6765899],
  [-77.1168301, 55.67864],
  [-77.1157399, 55.67917],
  [-77.1167499, 55.6812],
  [-77.1127299, 55.68639],
  [-77.1118101, 55.6861499],
  [-77.1118499, 55.6869099],
  [-77.1109401, 55.68694],
  [-77.1109699, 55.6874501],
  [-77.1087201, 55.68774],
  [-77.1092, 55.6882499],
  [-77.1082899, 55.6882599],
  [-77.10578, 55.6918901],
  [-77.1021799, 55.69272],
  [-77.1002701, 55.69506],
  [-77.09931, 55.69841],
  [-77.09841, 55.69843],
  [-77.09757, 55.69972],
  [-77.09577, 55.7000001],
  [-77.0953999, 55.70155],
  [-77.0935901, 55.7015801],
  [-77.0941301, 55.7031],
  [-77.09146, 55.70417],
  [-77.089, 55.70908],
  [-77.08809, 55.7091],
  [-77.0881301, 55.70987],
  [-77.08497, 55.71018],
  [-77.08445, 55.7089001],
  [-77.08221, 55.7094501],
  [-77.0837099, 55.71199],
  [-77.0823601, 55.7122699],
  [-77.08209, 55.7156099],
  [-77.08118, 55.71562],
  [-77.0817199, 55.7171499],
  [-77.0772799, 55.7190201],
  [-77.07964, 55.72077],
  [-77.08596, 55.7201499],
  [-77.0863799, 55.7194999],
  [-77.0897299, 55.71855],
  [-77.0899201, 55.71778],
  [-77.0948399, 55.71654],
  [-77.1010301, 55.7175899],
  [-77.1012899, 55.7183501],
  [-77.10269, 55.71897],
  [-77.10588, 55.7193],
  [-77.10423, 55.7180399],
  [-77.1043999, 55.7170201],
  [-77.1053, 55.717],
  [-77.10542, 55.71495],
  [-77.1043999, 55.7129201],
  [-77.1041601, 55.70831],
  [-77.11225, 55.7028001],
  [-77.1207399, 55.7004699],
  [-77.1211601, 55.6998199],
  [-77.1301799, 55.69877],
  [-77.1308401, 55.6983699],
  [-77.13077, 55.69709],
  [-77.13456, 55.6960001],
  [-77.1345299, 55.6954899],
  [-77.14126, 55.69409],
  [-77.14255, 55.6927901],
  [-77.14391, 55.69277],
  [-77.14223, 55.69099],
  [-77.1424199, 55.69049],
  [-77.1415199, 55.6905],
  [-77.14144, 55.6892201],
  [-77.14235, 55.68921],
  [-77.1422999, 55.68819],
  [-77.14711, 55.6850199],
  [-77.15694, 55.68229],
  [-77.15714, 55.6817801],
  [-77.1636201, 55.6802499],
  [-77.1659, 55.6803401],
  [-77.1654001, 55.6795799],
  [-77.1663199, 55.67982],
  [-77.16716, 55.67865],
  [-77.17037, 55.6792399],
  [-77.1718699, 55.67793],
  [-77.17106, 55.67538],
  [-77.17458, 55.6734],
  [-77.1799999, 55.67305],
  [-77.18128, 55.67162],
  [-77.1822001, 55.67186],
  [-77.18547, 55.66949],
  [-77.19194, 55.66785],
  [-77.19281, 55.66706],
  [-77.1905, 55.6663301],
  [-77.1904599, 55.6655599],
  [-77.19483, 55.6654],
  [-77.1953399, 55.6636799],
  [-77.19027, 55.6622399],
  [-77.1897799, 55.66148],
  [-77.1888799, 55.66175],
  [-77.1881199, 55.6602201],
  [-77.1896501, 55.6593],
  [-77.19962, 55.65926],
  [-77.1996501, 55.6597601],
  [-77.2010099, 55.65974],
  [-77.2015599, 55.66152],
  [-77.2002199, 55.6617999],
  [-77.19894, 55.6633599],
  [-77.19849, 55.66337],
  [-77.1988899, 55.6623399],
  [-77.1973499, 55.6631299],
  [-77.1981199, 55.6649099],
  [-77.19514, 55.6655699],
  [-77.19591, 55.66572],
  [-77.1964301, 55.6669901],
  [-77.1973299, 55.6669801],
  [-77.1974301, 55.6687701],
  [-77.1987899, 55.66874],
  [-77.1988801, 55.67028],
  [-77.1979701, 55.67029],
  [-77.1972199, 55.67287],
  [-77.1927501, 55.6742301],
  [-77.1927799, 55.67474],
  [-77.1905301, 55.67504],
  [-77.1905599, 55.6755501],
  [-77.1878899, 55.67662],
  [-77.18802, 55.6786701],
  [-77.1907501, 55.6788801],
  [-77.1907899, 55.67963],
  [-77.19216, 55.67975],
  [-77.1926501, 55.68038],
  [-77.1885599, 55.6803201],
  [-77.1849999, 55.68154],
  [-77.18505, 55.68231],
  [-77.1809999, 55.6828901],
  [-77.18104, 55.6836601],
  [-77.17382, 55.68429],
  [-77.17224, 55.68843],
  [-77.1713299, 55.6884401],
  [-77.1705101, 55.68999],
  [-77.1696, 55.6900101],
  [-77.1687699, 55.69131],
  [-77.1660899, 55.6921201],
  [-77.16616, 55.6934],
  [-77.16302, 55.69397],
  [-77.1631099, 55.6955],
  [-77.16035, 55.6947901],
  [-77.16037, 55.6953],
  [-77.15858, 55.6955799],
  [-77.1586, 55.6961],
  [-77.15638, 55.6969001],
  [-77.1559701, 55.6976801],
  [-77.1523901, 55.69851],
  [-77.14979, 55.70086],
  [-77.14889, 55.70088],
  [-77.1480701, 55.70243],
  [-77.1489699, 55.70241],
  [-77.14856, 55.70319],
  [-77.1458401, 55.7032401],
  [-77.14582, 55.70272],
  [-77.14358, 55.70328],
  [-77.1436201, 55.7040399],
  [-77.14271, 55.70406],
  [-77.1427401, 55.7045701],
  [-77.1409799, 55.7055],
  [-77.1400601, 55.70539],
  [-77.1401, 55.7061499],
  [-77.13658, 55.7080101],
  [-77.1357499, 55.7095601],
  [-77.1330501, 55.70986],
  [-77.1318301, 55.7126999],
  [-77.1296201, 55.71351],
  [-77.13103, 55.71451],
  [-77.12926, 55.71532],
  [-77.12923, 55.7148001],
  [-77.1278599, 55.71456],
  [-77.12624, 55.71818],
  [-77.12222, 55.7192699],
  [-77.1220501, 55.7203],
  [-77.1202999, 55.7216099],
  [-77.1192899, 55.7239299],
  [-77.10897, 55.7261599],
  [-77.10902, 55.7269299],
  [-77.10811, 55.7269399],
  [-77.1081899, 55.7284801],
  [-77.10597, 55.7292799],
  [-77.10601, 55.7300499],
  [-77.1073801, 55.7300301],
  [-77.10697, 55.7310599],
  [-77.1060699, 55.73108],
  [-77.10524, 55.73263],
  [-77.10433, 55.73265],
  [-77.1043701, 55.73341],
  [-77.1016901, 55.73422],
  [-77.0987199, 55.73812],
  [-77.0973801, 55.7383999],
  [-77.0961401, 55.7407199],
  [-77.09523, 55.7407399],
  [-77.09533, 55.74253],
  [-77.09441, 55.74255],
  [-77.0927299, 55.74488],
  [-77.0900401, 55.74569],
  [-77.08789, 55.74779],
  [-77.08609, 55.74808],
  [-77.08567, 55.7488399],
  [-77.08153, 55.74776],
  [-77.0779499, 55.7488399],
  [-77.0729001, 55.74779],
  [-77.0724899, 55.74855],
  [-77.0670299, 55.7483899],
  [-77.067, 55.74788],
  [-77.0651599, 55.7473999],
  [-77.06519, 55.74791],
  [-77.0624899, 55.74846],
  [-77.0629101, 55.74769],
  [-77.06199, 55.74745],
  [-77.0611201, 55.74823],
  [-77.0620299, 55.74823],
  [-77.0620499, 55.7487299],
  [-77.0673, 55.7491501],
  [-77.0676901, 55.75222],
  [-77.07084, 55.75178],
  [-77.07678, 55.75232],
  [-77.0768299, 55.75335],
  [-77.0759299, 55.75336],
  [-77.07376, 55.75545],
  [-77.07242, 55.7557299],
  [-77.0715899, 55.7572799],
  [-77.0706901, 55.7572899],
  [-77.07095, 55.7580599],
  [-77.0699001, 55.7596101],
  [-77.06855, 55.75989],
  [-77.0669101, 55.76325],
  [-77.066, 55.7632601],
  [-77.0664799, 55.76377],
  [-77.0651301, 55.7640501],
  [-77.06443, 55.76354],
  [-77.06584, 55.7601899],
  [-77.0644601, 55.7599599],
  [-77.06444, 55.75945],
  [-77.06266, 55.76024],
  [-77.0626999, 55.76101],
  [-77.0617901, 55.7610401],
  [-77.0618199, 55.76154],
  [-77.0600201, 55.7618301],
  [-77.05965, 55.7633701],
  [-77.05831, 55.76366],
  [-77.0574699, 55.7652],
  [-77.05655, 55.76521],
  [-77.0565999, 55.76599],
  [-77.05483, 55.76678],
  [-77.05487, 55.76755],
  [-77.05396, 55.76756],
  [-77.0531201, 55.7688599],
  [-77.05086, 55.7691501],
  [-77.0500399, 55.7707],
  [-77.0482499, 55.7715001],
  [-77.0483, 55.7722701],
  [-77.04739, 55.7722801],
  [-77.04743, 55.7730501],
  [-77.04653, 55.7730699],
  [-77.0465799, 55.7740899],
  [-77.0456601, 55.7738401],
  [-77.04484, 55.7756499],
  [-77.04394, 55.77567],
  [-77.0431101, 55.77722],
  [-77.0422, 55.77724],
  [-77.0396899, 55.7813801],
  [-77.0387999, 55.78177],
  [-77.0351299, 55.7811899],
  [-77.03471, 55.7819699],
  [-77.0338099, 55.78199],
  [-77.03385, 55.7825001],
  [-77.0359199, 55.78323],
  [-77.0348901, 55.7852999],
  [-77.0308099, 55.78536],
  [-77.03074, 55.7840899],
  [-77.0284699, 55.78412],
  [-77.02805, 55.7849],
  [-77.02715, 55.78492],
  [-77.02948, 55.78615],
  [-77.0300199, 55.78769],
  [-77.0286601, 55.78771],
  [-77.02783, 55.78926],
  [-77.0269199, 55.78927],
  [-77.0247701, 55.7916201],
  [-77.0203101, 55.79322],
  [-77.0194699, 55.7947699],
  [-77.0163201, 55.79534],
  [-77.01636, 55.79636],
  [-77.01282, 55.7979601],
  [-77.0124301, 55.7995],
  [-77.0115401, 55.7995101],
  [-77.0115699, 55.80002],
  [-77.00615, 55.8008799],
  [-77.0011199, 55.80481],
  [-76.99892, 55.8107301],
  [-76.9980099, 55.8107501],
  [-76.9973201, 55.8148601],
  [-76.99368, 55.81492],
  [-76.99447, 55.81694],
  [-76.99429, 55.8223399],
  [-76.9936599, 55.82337],
  [-76.9886499, 55.8233199],
  [-76.9868701, 55.8241199],
  [-76.9827599, 55.8238],
  [-76.9827401, 55.82329],
  [-76.9790999, 55.82336],
  [-76.97868, 55.8241199],
  [-76.9777699, 55.82414],
  [-76.97669, 55.8266301],
  [-76.9795201, 55.82718],
  [-76.9797799, 55.82795],
  [-76.97578, 55.8298],
  [-76.9736401, 55.8324],
  [-76.97227, 55.8324201],
  [-76.9699, 55.83502],
  [-76.9701701, 55.8357899],
  [-76.96838, 55.8363299],
  [-76.96783, 55.8345401],
  [-76.9641999, 55.83473],
  [-76.9628699, 55.83552],
  [-76.96105, 55.8354201],
  [-76.9606499, 55.8364499],
  [-76.96015, 55.83569],
  [-76.95656, 55.8367701],
  [-76.9562801, 55.8401099],
  [-76.95788, 55.8403399],
  [-76.96049, 55.84695],
  [-76.96033, 55.8482399],
  [-76.9594199, 55.84826],
  [-76.9594299, 55.8487601],
  [-76.95716, 55.8485501],
  [-76.95593, 55.85138],
  [-76.95415, 55.85219],
  [-76.9529, 55.8545],
  [-76.94926, 55.8545599],
  [-76.94843, 55.8561101],
  [-76.9475199, 55.8561101],
  [-76.94756, 55.8568901],
  [-76.94354, 55.85849],
  [-76.94367, 55.86105],
  [-76.9396001, 55.86163],
  [-76.9399501, 55.86418],
  [-76.9384099, 55.8652301],
  [-76.94048, 55.86571],
  [-76.93988, 55.86726],
  [-76.93478, 55.8655501],
  [-76.9341601, 55.86684],
  [-76.9369599, 55.86807],
  [-76.93635, 55.86962],
  [-76.9345299, 55.8699001],
  [-76.9328499, 55.87249],
  [-76.93056, 55.87227],
  [-76.92958, 55.8710101],
  [-76.92732, 55.8713],
  [-76.9242799, 55.87416],
  [-76.9229201, 55.8744401],
  [-76.9226101, 55.8772599],
  [-76.924, 55.87775],
  [-76.9208201, 55.87806],
  [-76.9208399, 55.8785701],
  [-76.91947, 55.8783401],
  [-76.9194999, 55.87885],
  [-76.91675, 55.87851],
  [-76.9136299, 55.87996],
  [-76.91367, 55.88073],
  [-76.9123, 55.88075],
  [-76.9123601, 55.8820399],
  [-76.91011, 55.8825799],
  [-76.9101501, 55.8833501],
  [-76.90924, 55.8833599],
  [-76.9092601, 55.88387],
  [-76.90788, 55.88363],
  [-76.9079101, 55.8841499],
  [-76.90656, 55.88443],
  [-76.9066099, 55.8854501],
  [-76.9043701, 55.8862499],
  [-76.9012899, 55.88861],
  [-76.9003799, 55.88862],
  [-76.8991301, 55.89095],
  [-76.8968399, 55.89073],
  [-76.89643, 55.89176],
  [-76.89507, 55.8920299],
  [-76.8951401, 55.89331],
  [-76.89423, 55.89333],
  [-76.89408, 55.89487],
  [-76.89256, 55.89643],
  [-76.89119, 55.89644],
  [-76.89123, 55.89721],
  [-76.88987, 55.8974899],
  [-76.8901401, 55.8982599],
  [-76.8881699, 55.89995],
  [-76.8872501, 55.89984],
  [-76.88533, 55.90243],
  [-76.8836801, 55.9060399],
  [-76.8843699, 55.91089],
  [-76.88198, 55.9132401],
  [-76.8774899, 55.9148399],
  [-76.87799, 55.9155999],
  [-76.87528, 55.9160301],
  [-76.8711399, 55.9155801],
  [-76.86888, 55.916],
  [-76.8675799, 55.9173],
  [-76.8630201, 55.9173701],
  [-76.8621, 55.92199],
  [-76.86742, 55.92319],
  [-76.86016, 55.92433],
  [-76.86019, 55.92485],
  [-76.8529001, 55.9252],
  [-76.85293, 55.9257101],
  [-76.8502101, 55.926],
  [-76.8502201, 55.92652],
  [-76.84475, 55.92635],
  [-76.84472, 55.9258399],
  [-76.8428901, 55.9256099],
  [-76.8429199, 55.9263799],
  [-76.8384, 55.92721],
  [-76.83838, 55.9267001],
  [-76.8329901, 55.92857],
  [-76.83303, 55.92934],
  [-76.83075, 55.9293801],
  [-76.8298999, 55.93066],
  [-76.8289901, 55.93068],
  [-76.82948, 55.93144],
  [-76.8285699, 55.93146],
  [-76.8277701, 55.9340301],
  [-76.82686, 55.9340401],
  [-76.82714, 55.9350599],
  [-76.8287601, 55.93555],
  [-76.8283499, 55.93658],
  [-76.8267799, 55.9373601],
  [-76.82751, 55.9383899],
  [-76.82661, 55.9383999],
  [-76.8271001, 55.9391601],
  [-76.82528, 55.9391899],
  [-76.8253399, 55.94047],
  [-76.82395, 55.93998],
  [-76.8228899, 55.94179],
  [-76.82383, 55.9422799],
  [-76.8217501, 55.94667],
  [-76.8221199, 55.9497499],
  [-76.82074, 55.9495001],
  [-76.8207701, 55.95001],
  [-76.8198601, 55.9500401],
  [-76.81947, 55.95157],
  [-76.8172299, 55.9523701],
  [-76.81594, 55.9541799],
  [-76.81503, 55.9542],
  [-76.81284, 55.9562801],
  [-76.8087699, 55.95711],
  [-76.80838, 55.9583901],
  [-76.8097601, 55.95837],
  [-76.8097699, 55.95888],
  [-76.8088601, 55.9589],
  [-76.8088899, 55.95967],
  [-76.81072, 55.95965],
  [-76.80982, 55.95991],
  [-76.8094201, 55.9611899],
  [-76.8107799, 55.96092],
  [-76.81107, 55.9621899],
  [-76.8086799, 55.96479],
  [-76.8077501, 55.96455],
  [-76.80804, 55.96582],
  [-76.80624, 55.97123],
  [-76.80347, 55.9707599],
  [-76.80293, 55.9738401],
  [-76.8049899, 55.97381],
  [-76.8050999, 55.97611],
  [-76.80192, 55.97667],
  [-76.8008601, 55.97848],
  [-76.80202, 55.97871],
  [-76.80206, 55.9797301],
  [-76.80658, 55.9786499],
  [-76.8066501, 55.9801899],
  [-76.8057401, 55.9801999],
  [-76.80625, 55.98147],
  [-76.80717, 55.98146],
  [-76.8067699, 55.9827499],
  [-76.8054, 55.9827699],
  [-76.8058999, 55.9835299],
  [-76.8040901, 55.9840701],
  [-76.80357, 55.9825399],
  [-76.80217, 55.98193],
  [-76.7994399, 55.98234],
  [-76.79773, 55.98467],
  [-76.79637, 55.9849501],
  [-76.7959899, 55.9867499],
  [-76.7978601, 55.98749],
  [-76.79693, 55.9875],
  [-76.79659, 55.98981],
  [-76.79751, 55.98992],
  [-76.79848, 55.9910601],
  [-76.79668, 55.9916],
  [-76.79651, 55.99289],
  [-76.7987601, 55.9972199],
  [-76.7969701, 55.9979999],
  [-76.79758, 56.0013199],
  [-76.79347, 56.00125],
  [-76.7912199, 56.00205],
  [-76.78439, 56.0025399],
  [-76.78482, 56.00201],
  [-76.7797499, 56.00145],
  [-76.7779601, 56.0018601],
  [-76.77756, 56.00315],
  [-76.77481, 56.00306],
  [-76.7708, 56.0053001],
  [-76.7712999, 56.0063101],
  [-76.77867, 56.00749],
  [-76.7787599, 56.0095301],
  [-76.7778501, 56.0095499],
  [-76.7778699, 56.01006],
  [-76.77516, 56.0106],
  [-76.77513, 56.0100999],
  [-76.7719899, 56.0113001],
  [-76.76379, 56.01205],
  [-76.76236, 56.01079],
  [-76.7591601, 56.01071],
  [-76.75055, 56.01237],
  [-76.7414099, 56.01249],
  [-76.7314901, 56.01559],
  [-76.7315099, 56.0160999],
  [-76.72791, 56.0174301],
  [-76.7261799, 56.01925],
  [-76.7252501, 56.01926],
  [-76.7252899, 56.02003],
  [-76.72211, 56.02058],
  [-76.7181799, 56.02474],
  [-76.7159301, 56.0255401],
  [-76.71641, 56.0263],
  [-76.7155099, 56.0263101],
  [-76.7121, 56.0319901],
  [-76.71118, 56.0319999],
  [-76.71076, 56.0327799],
  [-76.70756, 56.03282],
  [-76.7075999, 56.03359],
  [-76.7066801, 56.0336],
  [-76.7069299, 56.0341099],
  [-76.7041499, 56.0385],
  [-76.70232, 56.03853],
  [-76.7023601, 56.0393],
  [-76.70099, 56.0393201],
  [-76.7002599, 56.04342],
  [-76.69888, 56.0434401],
  [-76.6973799, 56.0457701],
  [-76.69764, 56.04653],
  [-76.6962699, 56.0465501],
  [-76.6963, 56.0473201],
  [-76.69173, 56.04738],
  [-76.69131, 56.04816],
  [-76.69039, 56.04818],
  [-76.6877901, 56.0566601],
  [-76.6882699, 56.05716],
  [-76.6859101, 56.0607801],
  [-76.68454, 56.0607999],
  [-76.6845, 56.0651499],
  [-76.68611, 56.06539],
  [-76.6861399, 56.06616],
  [-76.68342, 56.0667099],
  [-76.68407, 56.07105],
  [-76.6831499, 56.07106],
  [-76.68464, 56.0735999],
  [-76.6828199, 56.07388],
  [-76.68195, 56.0749199],
  [-76.6837801, 56.0749001],
  [-76.6822699, 56.07697],
  [-76.6832401, 56.0782301],
  [-76.68266, 56.0808001],
  [-76.6844899, 56.0807801],
  [-76.68452, 56.08154],
  [-76.6831499, 56.0815601],
  [-76.68274, 56.0825899],
  [-76.6845701, 56.08244],
  [-76.6845999, 56.0833401],
  [-76.6873401, 56.0830399],
  [-76.6869299, 56.08407],
  [-76.6860101, 56.08409],
  [-76.68651, 56.0851],
  [-76.68422, 56.0851301],
  [-76.68633, 56.0861301],
  [-76.6846996, 56.0885396],
  [-76.6770235, 56.0909177],
  [-76.6764893, 56.0928517],
  [-76.6775369, 56.0937015],
  [-76.6798604, 56.0940512],
  [-76.6793548, 56.0953611],
  [-76.6775145, 56.0959253],
  [-76.6772955, 56.0979143],
  [-76.6754418, 56.1000242],
  [-76.675636, 56.1011629],
  [-76.6739079, 56.1017255],
  [-76.6728689, 56.1029796],
  [-76.6730628, 56.1048551],
  [-76.674186, 56.1055408],
  [-76.6679398, 56.1079798],
  [-76.6618881, 56.1138305],
  [-76.6600397, 56.1142328],
  [-76.658701, 56.1163895],
  [-76.6572787, 56.1173521],
  [-76.6567449, 56.1187791],
  [-76.6538893, 56.1200844],
  [-76.6531504, 56.1223589],
  [-76.649467, 56.1246277],
  [-76.6490418, 56.1255949],
  [-76.6495492, 56.1280503],
  [-76.6489181, 56.1294696],
  [-76.6504442, 56.1312913],
  [-76.6507379, 56.1328869],
  [-76.6497163, 56.1334578],
  [-76.6497128, 56.1348776],
  [-76.6479568, 56.1359614],
  [-76.6484743, 56.1371497],
  [-76.6478337, 56.1402315],
  [-76.6473229, 56.141083],
  [-76.6458886, 56.1414166],
  [-76.6447956, 56.1429588],
  [-76.6455686, 56.1440988],
  [-76.643714, 56.1447704],
  [-76.6422811, 56.1466496],
  [-76.6442667, 56.1501727],
  [-76.6477457, 56.1543499],
  [-76.6534449, 56.1584613],
  [-76.6541535, 56.1603389],
  [-76.6569042, 56.1617668],
  [-76.6597563, 56.1647928],
  [-76.6547306, 56.1683985],
  [-76.6537801, 56.1707588],
  [-76.6525567, 56.1711526],
  [-76.650798, 56.1737102],
  [-76.6504734, 56.1774078],
  [-76.6514826, 56.179524],
  [-76.6507535, 56.1816814],
  [-76.6519474, 56.185844],
  [-76.6516266, 56.189254],
  [-76.6475262, 56.1902163],
  [-76.6432357, 56.1897523],
  [-76.6414824, 56.1902069],
  [-76.6654292, 56.1924117],
  [-76.6703238, 56.1937834],
  [-76.6649971, 56.1950864],
  [-76.6534401, 56.1943249],
  [-76.640323, 56.1958925],
  [-76.6334855, 56.1942936],
  [-76.6290809, 56.1945674],
  [-76.6258031, 56.1940444],
  [-76.6224194, 56.1959759],
  [-76.622204, 56.1965988],
  [-76.6230229, 56.1972889],
  [-76.6213577, 56.1998449],
  [-76.6185919, 56.2000069],
  [-76.6178743, 56.2005734],
  [-76.6178633, 56.202227],
  [-76.6151972, 56.2028459],
  [-76.6153024, 56.203815],
  [-76.6168255, 56.2055294],
  [-76.6157854, 56.2061001],
  [-76.6168092, 56.2082075],
  [-76.6187383, 56.2088382],
  [-76.6214071, 56.2082731],
  [-76.6224357, 56.2089605],
  [-76.6213902, 56.2105468],
  [-76.6185241, 56.2102608],
  [-76.6167738, 56.2119641],
  [-76.6198392, 56.2127687],
  [-76.6203386, 56.2139033],
  [-76.6198203, 56.2165251],
  [-76.6202158, 56.2174904],
  [-76.6228738, 56.2181834],
  [-76.624381, 56.2233126],
  [-76.6268406, 56.2238823],
  [-76.6238602, 56.2247302],
  [-76.6240519, 56.2262104],
  [-76.6260893, 56.2274686],
  [-76.62638, 56.2312838],
  [-76.6258547, 56.2325937],
  [-76.6265635, 56.2344715],
  [-76.6257285, 56.2353271],
  [-76.6265357, 56.2368621],
  [-76.6260186, 56.2376057],
  [-76.6227233, 56.2420971],
  [-76.6169764, 56.2462609],
  [-76.6185736, 56.2477766],
  [-76.6153886, 56.2487977],
  [-76.6121089, 56.2483373],
  [-76.6093342, 56.2495236],
  [-76.6100488, 56.2507813],
  [-76.6124007, 56.2506698],
  [-76.6139426, 56.2516382],
  [-76.6102419, 56.25192],
  [-76.6107576, 56.2530545],
  [-76.6084893, 56.2536232],
  [-76.6046108, 56.2523616],
  [-76.6031675, 56.252587],
  [-76.5992493, 56.2546235],
  [-76.5901872, 56.2610933],
  [-76.5876182, 56.2618272],
  [-76.5876571, 56.2623314],
  [-76.5841452, 56.2642519],
  [-76.5819812, 56.2663431],
  [-76.5803973, 56.2668259],
  [-76.5804023, 56.267338],
  [-76.5774218, 56.2678973],
  [-76.5762715, 56.2693766],
  [-76.5767912, 56.2702326],
  [-76.5737003, 56.2720422],
  [-76.5736873, 56.2733004],
  [-76.5675135, 56.2755623],
  [-76.5672971, 56.2765894],
  [-76.563454, 56.2801784],
  [-76.5633545, 56.2820936],
  [-76.5610726, 56.2843689],
  [-76.561055, 56.2875051],
  [-76.5572554, 56.2878315],
  [-76.55672, 56.2893659],
  [-76.5572214, 56.290968],
  [-76.556704, 56.2917563],
  [-76.5536154, 56.2940864],
  [-76.553597, 56.2956232],
  [-76.5510109, 56.2976056],
  [-76.5509049, 56.2985774],
  [-76.5481109, 56.3010205],
  [-76.5473787, 56.3028987],
  [-76.5478334, 56.304151],
  [-76.5496138, 56.3050094],
  [-76.5482579, 56.3074525],
  [-76.5493711, 56.3085977],
  [-76.5464998, 56.3095502],
  [-76.5457631, 56.3105208],
  [-76.5452055, 56.3159282],
  [-76.5457064, 56.3175214],
  [-76.5472355, 56.318949],
  [-76.548774, 56.3194061],
  [-76.5476307, 56.3199235],
  [-76.5472078, 56.321061],
  [-76.5487462, 56.3219134],
  [-76.548434, 56.323391],
  [-76.54894, 56.3239058],
  [-76.5486212, 56.3256171],
  [-76.5460542, 56.3253257],
  [-76.5451088, 56.3263439],
  [-76.5447546, 56.331587],
  [-76.5471044, 56.3328965],
  [-76.5447446, 56.3337437],
  [-76.5445271, 56.3351661],
  [-76.5474948, 56.3357491],
  [-76.5470787, 56.3370572],
  [-76.5501504, 56.3370099],
  [-76.5511766, 56.3375811],
  [-76.5487955, 56.3407019],
  [-76.5446811, 56.3401782],
  [-76.5429311, 56.3413143],
  [-76.5423917, 56.3443851],
  [-76.5502746, 56.347252],
  [-76.5505683, 56.348111],
  [-76.5520064, 56.3488567],
  [-76.5564383, 56.3480104],
  [-76.558991, 56.3487062],
  [-76.5586881, 56.3496085],
  [-76.5569334, 56.3498373],
  [-76.556716, 56.3508554],
  [-76.5504525, 56.3512394],
  [-76.5478608, 56.3523771],
  [-76.5427938, 56.356349],
  [-76.5437115, 56.3578292],
  [-76.5400968, 56.3585032],
  [-76.5404109, 56.3594697],
  [-76.5424483, 56.3602172],
  [-76.5424457, 56.360954],
  [-76.5406881, 56.361524],
  [-76.5434561, 56.3623252],
  [-76.5360472, 56.364466],
  [-76.5360333, 56.3649245],
  [-76.538598, 56.365899],
  [-76.5384772, 56.3673203],
  [-76.5374571, 56.3677193],
  [-76.5338628, 56.3673145],
  [-76.5292863, 56.3731039],
  [-76.5271439, 56.3810736],
  [-76.530121, 56.3822141],
  [-76.5296922, 56.3836391],
  [-76.528037, 56.3847737],
  [-76.5281334, 56.3859676],
  [-76.5291537, 56.3867727],
  [-76.5336756, 56.387238],
  [-76.5319138, 56.3885447],
  [-76.5322118, 56.3895115],
  [-76.5339638, 56.3899751],
  [-76.5341646, 56.3905387],
  [-76.5332317, 56.3911073],
  [-76.5346281, 56.3952145],
  [-76.5339009, 56.3956638],
  [-76.5311349, 56.3953744],
  [-76.5310281, 56.3959418],
  [-76.5321575, 56.3966288],
  [-76.5295635, 56.3986107],
  [-76.5320124, 56.3998744],
  [-76.532817, 56.4017514],
  [-76.5336387, 56.4020378],
  [-76.5382926, 56.4009109],
  [-76.5391977, 56.4016544],
  [-76.5402889, 56.4055914],
  [-76.5357865, 56.4043212],
  [-76.5343727, 56.4047296],
  [-76.5332298, 56.4067319],
  [-76.5341211, 56.4078046],
  [-76.5367699, 56.4086663],
  [-76.537714, 56.4100553],
  [-76.53366, 56.4105501],
  [-76.53302, 56.41183],
  [-76.53331, 56.41337],
  [-76.5314999, 56.4138999],
  [-76.5312799, 56.4144201],
  [-76.53199, 56.41492],
  [-76.5301501, 56.4152001],
  [-76.5302699, 56.41827],
  [-76.52799, 56.41907],
  [-76.52764, 56.42163],
  [-76.5262601, 56.4218999],
  [-76.5253899, 56.4232],
  [-76.524, 56.4232101],
  [-76.52408, 56.4250001],
  [-76.5231501, 56.4250199],
  [-76.52209, 56.4273301],
  [-76.52387, 56.43142],
  [-76.5257299, 56.43164],
  [-76.52485, 56.43293],
  [-76.5239301, 56.43294],
  [-76.524, 56.43474],
  [-76.5249499, 56.43498],
  [-76.52401, 56.4349901],
  [-76.5256199, 56.44061],
  [-76.52655, 56.4406],
  [-76.5259101, 56.4418801],
  [-76.52666, 56.44341],
  [-76.5252899, 56.4436801],
  [-76.52512, 56.4452201],
  [-76.5258399, 56.44597],
  [-76.5249201, 56.44599],
  [-76.5245099, 56.44728],
  [-76.5206199, 56.4483501],
  [-76.5200599, 56.4516899],
  [-76.51729, 56.45198],
  [-76.5192, 56.4534899],
  [-76.5182799, 56.4535],
  [-76.5183, 56.4540101],
  [-76.51922, 56.4540001],
  [-76.5190201, 56.45477],
  [-76.52067, 56.45552],
  [-76.5206901, 56.4560299],
  [-76.5183799, 56.45606],
  [-76.51874, 56.4591301],
  [-76.5238299, 56.46496],
  [-76.52475, 56.46495],
  [-76.5269101, 56.46697],
  [-76.5341601, 56.4689299],
  [-76.53467, 56.46995],
  [-76.5411501, 56.4701201],
  [-76.5411699, 56.47063],
  [-76.54397, 56.47111],
  [-76.5447201, 56.47264],
  [-76.5436199, 56.4739399],
  [-76.5463701, 56.4733899],
  [-76.5463899, 56.4739001],
  [-76.5503801, 56.4751301],
  [-76.5521601, 56.4792],
  [-76.55031, 56.47923],
  [-76.5508, 56.4797299],
  [-76.5480401, 56.4802799],
  [-76.5488301, 56.48821],
  [-76.54745, 56.4884801],
  [-76.54775, 56.4902699],
  [-76.5487001, 56.49077],
  [-76.5485199, 56.4920499],
  [-76.54714, 56.49233],
  [-76.54721, 56.49399],
  [-76.5485901, 56.4938399],
  [-76.5473866, 56.4953662],
  [-76.5465452, 56.49541],
  [-76.5464643, 56.4964174],
  [-76.54825, 56.49667],
  [-76.5507438, 56.4990412],
  [-76.5502104, 56.4991426],
  [-76.5513592, 56.4996613],
  [-76.5497957, 56.5004216],
  [-76.5504887, 56.5008335],
  [-76.545712, 56.4995058],
  [-76.5441971, 56.5002047],
  [-76.5438679, 56.5011325],
  [-76.5452924, 56.5027876],
  [-76.5451106, 56.5050358],
  [-76.5465787, 56.5069342],
  [-76.5483715, 56.5076372],
  [-76.5492387, 56.5093402],
  [-76.5489993, 56.5104711],
  [-76.5512501, 56.5102706],
  [-76.5527515, 56.5123888],
  [-76.5542286, 56.5132135],
  [-76.551873, 56.5133293],
  [-76.5541822, 56.51438],
  [-76.552615, 56.5144554],
  [-76.5528645, 56.5165866],
  [-76.5547656, 56.5166805],
  [-76.5562851, 56.517961],
  [-76.5558023, 56.5188945],
  [-76.5520601, 56.519119],
  [-76.5510652, 56.5200771],
  [-76.5529289, 56.5212064],
  [-76.5499623, 56.5234571],
  [-76.5531822, 56.5233501],
  [-76.5504616, 56.5244086],
  [-76.5488168, 56.5243596],
  [-76.549048, 56.5252808],
  [-76.5439803, 56.5273346],
  [-76.5420943, 56.528931],
  [-76.5412265, 56.530942],
  [-76.5416677, 56.5341783],
  [-76.5409368, 56.5351663],
  [-76.5416348, 56.5379704],
  [-76.5439308, 56.5413484],
  [-76.5445358, 56.5414234],
  [-76.5442474, 56.5418142],
  [-76.546062, 56.544779],
  [-76.5491797, 56.5489916],
  [-76.550867, 56.5496454],
  [-76.551922, 56.5492775],
  [-76.5534009, 56.5495713],
  [-76.5546073, 56.5505287],
  [-76.5546461, 56.5511576],
  [-76.5533165, 56.5496678],
  [-76.5511006, 56.5497285],
  [-76.5537254, 56.5508712],
  [-76.5525049, 56.5519823],
  [-76.5548, 56.5548401],
  [-76.5577129, 56.5554575],
  [-76.5543841, 56.5596772],
  [-76.550511, 56.5609453],
  [-76.5464118, 56.5639853],
  [-76.5460058, 56.5650435],
  [-76.5451347, 56.5651697],
  [-76.54464, 56.5677501],
  [-76.5459064, 56.5705009],
  [-76.5442918, 56.5705427],
  [-76.5429473, 56.5717383],
  [-76.543463, 56.5729614],
  [-76.5414026, 56.5752311],
  [-76.5406903, 56.577158],
  [-76.5407576, 56.5793285],
  [-76.5398957, 56.5795899],
  [-76.541002, 56.5829429],
  [-76.5380779, 56.5838434],
  [-76.5383222, 56.5859127],
  [-76.5394246, 56.5876513],
  [-76.5377496, 56.5909642],
  [-76.5360967, 56.5910348],
  [-76.5370459, 56.5949031],
  [-76.5340658, 56.5972396],
  [-76.5356422, 56.5996027],
  [-76.5390836, 56.6019196],
  [-76.53911, 56.6025401],
  [-76.54286, 56.60314],
  [-76.54469, 56.60273],
  [-76.54467, 56.6022201],
  [-76.5486199, 56.6024201],
  [-76.54892, 56.60395],
  [-76.54799, 56.60397],
  [-76.5482, 56.6090901],
  [-76.54587, 56.60886],
  [-76.5466001, 56.60962],
  [-76.54613, 56.61526],
  [-76.54333, 56.61504],
  [-76.5420301, 56.6171001],
  [-76.5429499, 56.61709],
  [-76.54324, 56.61837],
  [-76.54191, 56.6199199],
  [-76.5421799, 56.62094],
  [-76.54124, 56.62095],
  [-76.54136, 56.62351],
  [-76.5399599, 56.62353],
  [-76.5391, 56.62508],
  [-76.5377101, 56.62509],
  [-76.5377399, 56.62586],
  [-76.5368101, 56.62587],
  [-76.5373, 56.62664],
  [-76.5363801, 56.62665],
  [-76.53555, 56.6292201],
  [-76.5329506, 56.6300186],
  [-76.53, 56.6297999],
  [-76.52998, 56.62929],
  [-76.5286001, 56.6295601],
  [-76.52956, 56.63031],
  [-76.5315026, 56.6299864],
  [-76.53421, 56.63052],
  [-76.5344901, 56.6317901],
  [-76.53297, 56.6343699],
  [-76.5320401, 56.63438],
  [-76.5318499, 56.63542],
  [-76.53257, 56.6359099],
  [-76.53163, 56.6359199],
  [-76.5325801, 56.63617],
  [-76.5314999, 56.6382301],
  [-76.53223, 56.63873],
  [-76.5312899, 56.63875],
  [-76.53009, 56.6492599],
  [-76.53424, 56.6542],
  [-76.5351799, 56.65432],
  [-76.53521, 56.65509],
  [-76.5361401, 56.6552],
  [-76.5392799, 56.65811],
  [-76.5381601, 56.66478],
  [-76.5372501, 56.6653],
  [-76.53789, 56.66965],
  [-76.53656, 56.6712],
  [-76.5362601, 56.6753],
  [-76.5353701, 56.67634],
  [-76.53568, 56.6781301],
  [-76.5342701, 56.67763],
  [-76.5342899, 56.6783999],
  [-76.5354701, 56.67864],
  [-76.5351401, 56.6817199],
  [-76.53587, 56.6824801],
  [-76.5349301, 56.6824901],
  [-76.5350599, 56.69145],
  [-76.53632, 56.6937399],
  [-76.5353801, 56.69375],
  [-76.53391, 56.6976101],
  [-76.5343899, 56.69786],
  [-76.5343701, 56.70324],
  [-76.53344, 56.70325],
  [-76.5329399, 56.70812],
  [-76.53342, 56.7083701],
  [-76.5330401, 56.71043],
  [-76.5354999, 56.7137301],
  [-76.53541, 56.7173099],
  [-76.53402, 56.71733],
  [-76.5335801, 56.7181],
  [-76.5317199, 56.71813],
  [-76.53175, 56.71889],
  [-76.5308201, 56.7189],
  [-76.5319, 56.72248],
  [-76.52865, 56.72277],
  [-76.52867, 56.7232801],
  [-76.5323801, 56.7229799],
  [-76.5325901, 56.72221],
  [-76.5337399, 56.7219401],
  [-76.53263, 56.72324],
  [-76.53502, 56.7306299],
  [-76.53642, 56.7306201],
  [-76.5369499, 56.73214],
  [-76.536, 56.7319],
  [-76.53645, 56.7426499],
  [-76.5341501, 56.74346],
  [-76.5337299, 56.74448],
  [-76.53279, 56.74449],
  [-76.5324901, 56.74859],
  [-76.53343, 56.74858],
  [-76.533, 56.74961],
  [-76.5320699, 56.7496201],
  [-76.5304999, 56.75116],
  [-76.53079, 56.75834],
  [-76.53256, 56.7616599],
  [-76.53163, 56.7616599],
  [-76.53365, 56.76548],
  [-76.5348299, 56.76572],
  [-76.53486, 56.76649],
  [-76.53655, 56.7679999],
  [-76.5356199, 56.7680099],
  [-76.53488, 56.7728899],
  [-76.5362799, 56.7728601],
  [-76.5362899, 56.77338],
  [-76.5349201, 56.7736501],
  [-76.53364, 56.77674],
  [-76.5327101, 56.7767501],
  [-76.5327299, 56.77727],
  [-76.5301886, 56.7774045],
  [-76.5303701, 56.7765301],
  [-76.5289022, 56.7762941],
  [-76.53019, 56.77781],
  [-76.53323, 56.77816],
  [-76.53444, 56.77904],
  [-76.53356, 56.7862199],
  [-76.52942, 56.7875501],
  [-76.5266199, 56.78758],
  [-76.5303601, 56.7876699],
  [-76.5315704, 56.7869789],
  [-76.5345901, 56.78851],
  [-76.5341501, 56.78928],
  [-76.53555, 56.78927],
  [-76.5341699, 56.7897999],
  [-76.5338101, 56.79236],
  [-76.53288, 56.79237],
  [-76.5339201, 56.79492],
  [-76.53298, 56.79493],
  [-76.53223, 56.79917],
  [-76.5331501, 56.79903],
  [-76.5332, 56.80031],
  [-76.5322701, 56.8003201],
  [-76.53211, 56.8021101],
  [-76.53376, 56.80261],
  [-76.53264, 56.80364],
  [-76.5341401, 56.80619],
  [-76.5339399, 56.80696],
  [-76.53209, 56.8072201],
  [-76.53241, 56.8095401],
  [-76.5340599, 56.8097701],
  [-76.5324999, 56.8115799],
  [-76.53276, 56.8123499],
  [-76.5337001, 56.8123401],
  [-76.5321699, 56.8149199],
  [-76.5327001, 56.8223401],
  [-76.5346001, 56.82308],
  [-76.53321, 56.8233501],
  [-76.53278, 56.8241299],
  [-76.5339499, 56.8243699],
  [-76.5330699, 56.8256601],
  [-76.5333601, 56.82694],
  [-76.5342899, 56.8269299],
  [-76.53508, 56.83485],
  [-76.53791, 56.8353401],
  [-76.5389401, 56.83775],
  [-76.5415199, 56.83809],
  [-76.5392701, 56.84018],
  [-76.5392899, 56.8406901],
  [-76.5409401, 56.8409299],
  [-76.5404999, 56.8416999],
  [-76.5391, 56.8417199],
  [-76.53868, 56.84275],
  [-76.54102, 56.84298],
  [-76.53967, 56.8442699],
  [-76.5406099, 56.8442599],
  [-76.54112, 56.84528],
  [-76.5401799, 56.84529],
  [-76.5400699, 56.84811],
  [-76.5420599, 56.8511599],
  [-76.5416099, 56.85167],
  [-76.5419599, 56.8544899],
  [-76.54434, 56.8554801],
  [-76.54534, 56.85701],
  [-76.5416099, 56.85731],
  [-76.54101, 56.8596199],
  [-76.54166, 56.86422],
  [-76.5421401, 56.8644701],
  [-76.5417201, 56.8654999],
  [-76.5488399, 56.89076],
  [-76.5503999, 56.8946001],
  [-76.5516101, 56.8956001],
  [-76.5488, 56.8956299],
  [-76.5477399, 56.8982],
  [-76.55025, 56.9079],
  [-76.5437299, 56.9085001],
  [-76.54286, 56.91004],
  [-76.5404999, 56.9099401],
  [-76.53822, 56.91125],
  [-76.5330301, 56.9104199],
  [-76.53146, 56.9119799],
  [-76.5313662, 56.9145676],
  [-76.53545, 56.9123099],
  [-76.54155, 56.9126201],
  [-76.5410301, 56.91147],
  [-76.54291, 56.91145],
  [-76.5486101, 56.91356],
  [-76.54864, 56.91433],
  [-76.5505199, 56.91456],
  [-76.5505001, 56.9140501],
  [-76.55145, 56.91403],
  [-76.55413, 56.91682],
  [-76.5551899, 56.91988],
  [-76.5603099, 56.92468],
  [-76.5617, 56.9244101],
  [-76.56167, 56.9236399],
  [-76.5687101, 56.9240601],
  [-76.5687301, 56.92457],
  [-76.56546, 56.9246101],
  [-76.56526, 56.9253901],
  [-76.56736, 56.92536],
  [-76.56692, 56.9258799],
  [-76.5678499, 56.9258599],
  [-76.5684099, 56.92791],
  [-76.5651701, 56.9289699],
  [-76.5670601, 56.9291999],
  [-76.5670799, 56.92972],
  [-76.5647399, 56.9297401],
  [-76.5638599, 56.9312801],
  [-76.56666, 56.9309899],
  [-76.56668, 56.9315101],
  [-76.5652899, 56.93179],
  [-76.56514, 56.9338401],
  [-76.5658499, 56.9340801],
  [-76.5664099, 56.93613],
  [-76.56854, 56.93661],
  [-76.56835, 56.9376401],
  [-76.5674099, 56.9376499],
  [-76.56745, 56.93893],
  [-76.5684001, 56.93892],
  [-76.5689, 56.93968],
  [-76.5675001, 56.9397],
  [-76.5689401, 56.9407],
  [-76.56754, 56.94072],
  [-76.5676201, 56.9427699],
  [-76.5694901, 56.94249],
  [-76.5690799, 56.94403],
  [-76.5667399, 56.9438],
  [-76.5660601, 56.94458],
  [-76.5672901, 56.94584],
  [-76.56543, 56.94612],
  [-76.56454, 56.9476699],
  [-76.5659501, 56.9475301],
  [-76.56645, 56.94816],
  [-76.56459, 56.9487],
  [-76.5646101, 56.9492099],
  [-76.5669599, 56.9491801],
  [-76.5637399, 56.9507599],
  [-76.56479, 56.9533001],
  [-76.5661899, 56.95316],
  [-76.56623, 56.9543101],
  [-76.56436, 56.9543299],
  [-76.56499, 56.95791],
  [-76.56404, 56.95792],
  [-76.56337, 56.9587],
  [-76.56344, 56.9602299],
  [-76.5646201, 56.96047],
  [-76.56512, 56.96149],
  [-76.5641899, 56.9615],
  [-76.564, 56.9625301],
  [-76.56565, 56.9627699],
  [-76.56567, 56.96328],
  [-76.5647399, 56.96329],
  [-76.5657399, 56.96481],
  [-76.56714, 56.9648],
  [-76.5660601, 56.9668601],
  [-76.56756, 56.96726],
  [-76.5677, 56.96684],
  [-76.56867, 56.96759],
  [-76.56788, 56.9674399],
  [-76.5666001, 56.9686401],
  [-76.56734, 56.9694],
  [-76.5659401, 56.9696799],
  [-76.56715, 56.9704301],
  [-76.56743, 56.97171],
  [-76.56556, 56.9719901],
  [-76.56735, 56.97529],
  [-76.56715, 56.97606],
  [-76.5680699, 56.9757999],
  [-76.5676399, 56.97658],
  [-76.56623, 56.97659],
  [-76.56543, 56.97993],
  [-76.56733, 56.98041],
  [-76.56592, 56.9804301],
  [-76.5655001, 56.9814599],
  [-76.56456, 56.9814699],
  [-76.5646399, 56.98326],
  [-76.5623099, 56.98381],
  [-76.56292, 56.9871299],
  [-76.5591899, 56.9876899],
  [-76.55988, 56.9927999],
  [-76.56081, 56.9927899],
  [-76.5630501, 56.99583],
  [-76.5628401, 56.9963499],
  [-76.56402, 56.9965899],
  [-76.5642899, 56.9973499],
  [-76.5612999, 56.9986701],
  [-76.56254, 57.00608],
  [-76.56523, 57.0084799],
  [-76.5666299, 57.0083301],
  [-76.56767, 57.0108801],
  [-76.5686101, 57.01074],
  [-76.5696101, 57.0124],
  [-76.56822, 57.0126701],
  [-76.57089, 57.0146801],
  [-76.56956, 57.01675],
  [-76.57137, 57.0206999],
  [-76.57326, 57.0208001],
  [-76.5728401, 57.02208],
  [-76.5709599, 57.0221101],
  [-76.57102, 57.02339],
  [-76.56914, 57.02341],
  [-76.56824, 57.0244501],
  [-76.5636001, 57.02566],
  [-76.55999, 57.02541],
  [-76.55837, 57.02406],
  [-76.55576, 57.02332],
  [-76.5541899, 57.0213799],
  [-76.5550699, 57.02384],
  [-76.55791, 57.02432],
  [-76.5579301, 57.02483],
  [-76.5612701, 57.0262],
  [-76.5683099, 57.02611],
  [-76.56946, 57.0254601],
  [-76.5696499, 57.02417],
  [-76.5710501, 57.0241599],
  [-76.5686101, 57.03878],
  [-76.5701, 57.04056],
  [-76.5717599, 57.0410499],
  [-76.5710799, 57.0415701],
  [-76.5718699, 57.0436099],
  [-76.57093, 57.04362],
  [-76.5721801, 57.0453899],
  [-76.57203, 57.04745],
  [-76.57344, 57.04743],
  [-76.5741801, 57.04819],
  [-76.57355, 57.04999],
  [-76.5726, 57.04974],
  [-76.57089, 57.05386],
  [-76.57182, 57.05385],
  [-76.5719601, 57.0569201],
  [-76.5696201, 57.05708],
  [-76.56677, 57.0564701],
  [-76.5667499, 57.05596],
  [-76.56392, 57.05574],
  [-76.5639699, 57.05652],
  [-76.56302, 57.05652],
  [-76.5578401, 57.0678499],
  [-76.5550401, 57.0681401],
  [-76.55522, 57.0724901],
  [-76.5542899, 57.0725099],
  [-76.5533901, 57.07354],
  [-76.55576, 57.07402],
  [-76.5562701, 57.0750401],
  [-76.5539599, 57.0758399],
  [-76.5551, 57.08069],
  [-76.55744, 57.0803999],
  [-76.55888, 57.0811501],
  [-76.5589599, 57.0829499],
  [-76.5599, 57.0829301],
  [-76.559, 57.0839699],
  [-76.5580601, 57.08398],
  [-76.5580799, 57.0844901],
  [-76.55621, 57.08477],
  [-76.55578, 57.0858],
  [-76.55958, 57.0865199],
  [-76.5596101, 57.0872899],
  [-76.5608, 57.0875199],
  [-76.5610899, 57.0890601],
  [-76.5559301, 57.0893801],
  [-76.55591, 57.08887],
  [-76.5526001, 57.08866],
  [-76.55265, 57.08968],
  [-76.5517101, 57.08969],
  [-76.55076, 57.0950799],
  [-76.5515001, 57.0958401],
  [-76.5496101, 57.0956101],
  [-76.5525001, 57.09724],
  [-76.55578, 57.0969401],
  [-76.5572801, 57.0991],
  [-76.55402, 57.09991],
  [-76.55432, 57.10144],
  [-76.5557499, 57.10193],
  [-76.5560501, 57.10347],
  [-76.55891, 57.1041999],
  [-76.55655, 57.1039699],
  [-76.55823, 57.1049799],
  [-76.5579501, 57.10933],
  [-76.56481, 57.11027],
  [-76.5648301, 57.11078],
  [-76.56389, 57.1108],
  [-76.5659401, 57.1148699],
  [-76.56689, 57.1148501],
  [-76.5667201, 57.1163901],
  [-76.5697301, 57.1209601],
  [-76.57411, 57.12424],
  [-76.57369, 57.1255201],
  [-76.57489, 57.1257699],
  [-76.5756201, 57.1279701],
  [-76.5768501, 57.12779],
  [-76.57688, 57.12856],
  [-76.5749799, 57.1280701],
  [-76.5745399, 57.1288401],
  [-76.57313, 57.1288601],
  [-76.57245, 57.1296401],
  [-76.5719, 57.13323],
  [-76.57549, 57.13446],
  [-76.57504, 57.1349799],
  [-76.5755299, 57.13549],
  [-76.5754001, 57.13806],
  [-76.57399, 57.1380701],
  [-76.5749501, 57.1384399],
  [-76.58104, 57.1378501],
  [-76.58203, 57.1389899],
  [-76.5806201, 57.13901],
  [-76.5794199, 57.1438899],
  [-76.5756399, 57.14368],
  [-76.57566, 57.1441901],
  [-76.5785001, 57.1444101],
  [-76.5785399, 57.1454399],
  [-76.57666, 57.14546],
  [-76.5761701, 57.14495],
  [-76.5758501, 57.1485401],
  [-76.57704, 57.1487799],
  [-76.57779, 57.1498],
  [-76.57591, 57.14982],
  [-76.5774199, 57.15236],
  [-76.58002, 57.15259],
  [-76.5793901, 57.15439],
  [-76.5779799, 57.1544101],
  [-76.57801, 57.15517],
  [-76.58038, 57.1554],
  [-76.58089, 57.1564201],
  [-76.5799501, 57.1564301],
  [-76.5805201, 57.15872],
  [-76.58146, 57.15871],
  [-76.5815299, 57.16025],
  [-76.58247, 57.1602399],
  [-76.58048, 57.1633399],
  [-76.58079, 57.1651199],
  [-76.58268, 57.1652299],
  [-76.58312, 57.1643299],
  [-76.5845399, 57.1645699],
  [-76.5850701, 57.1661001],
  [-76.5827201, 57.1661199],
  [-76.58301, 57.1674],
  [-76.5842099, 57.1676401],
  [-76.5847301, 57.16893],
  [-76.5837599, 57.1686699],
  [-76.5831899, 57.1712399],
  [-76.58491, 57.17327],
  [-76.5839699, 57.17328],
  [-76.5836399, 57.17661],
  [-76.5742901, 57.17852],
  [-76.57388, 57.17981],
  [-76.5767201, 57.1800299],
  [-76.5781601, 57.1807801],
  [-76.57822, 57.18206],
  [-76.57916, 57.18205],
  [-76.5792099, 57.1833301],
  [-76.57737, 57.18437],
  [-76.5769401, 57.1854],
  [-76.5746, 57.1854301],
  [-76.5747, 57.1880001],
  [-76.57893, 57.1876799],
  [-76.5789699, 57.1884501],
  [-76.5745044, 57.1895266],
  [-76.58116, 57.1902101],
  [-76.5823901, 57.1914799],
  [-76.5847599, 57.19171],
  [-76.5863256, 57.1934266],
  [-76.5877325, 57.1933468],
  [-76.5880901, 57.1944126],
  [-76.5897592, 57.19522],
  [-76.5905043, 57.1961081],
  [-76.5896102, 57.1967862],
  [-76.5895207, 57.1982717],
  [-76.5883286, 57.1986915],
  [-76.5922032, 57.1991759],
  [-76.5908023, 57.2011618],
  [-76.5938424, 57.2018399],
  [-76.5927501, 57.2021],
  [-76.59234, 57.20339],
  [-76.59415, 57.20721],
  [-76.59301, 57.20799],
  [-76.5939501, 57.20798],
  [-76.59504, 57.2113],
  [-76.60058, 57.2140399],
  [-76.60092, 57.2163401],
  [-76.60237, 57.21709],
  [-76.6014201, 57.2171],
  [-76.6008401, 57.2199199],
  [-76.60237, 57.2224701],
  [-76.60145, 57.2232501],
  [-76.60223, 57.22477],
  [-76.5975399, 57.2255999],
  [-76.59878, 57.22686],
  [-76.59837, 57.22841],
  [-76.60105, 57.2301599],
  [-76.5981999, 57.2299399],
  [-76.59824, 57.2307099],
  [-76.59634, 57.2304799],
  [-76.5987401, 57.2313501],
  [-76.60484, 57.2304999],
  [-76.6067501, 57.23112],
  [-76.60636, 57.2329101],
  [-76.6092199, 57.23364],
  [-76.60926, 57.23441],
  [-76.61093, 57.2349],
  [-76.6112001, 57.23567],
  [-76.6088501, 57.2359499],
  [-76.60935, 57.2365901],
  [-76.6102901, 57.23645],
  [-76.61017, 57.23901],
  [-76.6109, 57.2395099],
  [-76.60191, 57.23912],
  [-76.6029799, 57.2420499],
  [-76.6048699, 57.24201],
  [-76.6044299, 57.2429201],
  [-76.60915, 57.24286],
  [-76.6091901, 57.24363],
  [-76.6073001, 57.24366],
  [-76.60615, 57.24444],
  [-76.6064299, 57.24546],
  [-76.61159, 57.2446199],
  [-76.61091, 57.2451501],
  [-76.6116599, 57.2462899],
  [-76.6145, 57.24612],
  [-76.6145201, 57.24689],
  [-76.6126401, 57.2469201],
  [-76.6126599, 57.24743],
  [-76.6155401, 57.24841],
  [-76.6151199, 57.2497001],
  [-76.61607, 57.2498201],
  [-76.6167, 57.2535099],
  [-76.61483, 57.2538],
  [-76.61415, 57.2545801],
  [-76.6159801, 57.2585199],
  [-76.6178601, 57.2583701],
  [-76.61673, 57.2594099],
  [-76.6193299, 57.25964],
  [-76.6186599, 57.2604099],
  [-76.6206401, 57.26243],
  [-76.62193, 57.26498],
  [-76.6181901, 57.26579],
  [-76.61817, 57.2652799],
  [-76.6150099, 57.2651501],
  [-76.6163001, 57.2659401],
  [-76.61914, 57.2660501],
  [-76.6195301, 57.26936],
  [-76.62191, 57.2698399],
  [-76.62171, 57.2706101],
  [-76.61983, 57.27089],
  [-76.6213399, 57.27267],
  [-76.62038, 57.27268],
  [-76.6212101, 57.2754801],
  [-76.62382, 57.2757101],
  [-76.62236, 57.2800799],
  [-76.62316, 57.28224],
  [-76.6230901, 57.2859599],
  [-76.6254, 57.29003],
  [-76.62681, 57.28988],
  [-76.6273101, 57.2905101],
  [-76.62637, 57.2905299],
  [-76.62669, 57.29257],
  [-76.6288801, 57.29382],
  [-76.6318, 57.3009501],
  [-76.6316501, 57.303],
  [-76.6307, 57.30302],
  [-76.6312001, 57.3035201],
  [-76.6297899, 57.3038],
  [-76.6288999, 57.30534],
  [-76.6275, 57.30536],
  [-76.6290801, 57.3091801],
  [-76.63237, 57.3086301],
  [-76.63239, 57.30915],
  [-76.63381, 57.30912],
  [-76.63383, 57.3096301],
  [-76.6381199, 57.31022],
  [-76.6391101, 57.3110999],
  [-76.62826, 57.31201],
  [-76.6278701, 57.31381],
  [-76.6335501, 57.3139899],
  [-76.6331199, 57.31502],
  [-76.63503, 57.3149899],
  [-76.63408, 57.31525],
  [-76.63625, 57.3159999],
  [-76.63986, 57.32261],
  [-76.6372178, 57.3240575],
  [-76.6397628, 57.3231962],
  [-76.64107, 57.32337],
  [-76.6420901, 57.3248899],
  [-76.6430199, 57.3248799],
  [-76.64382, 57.3266599],
  [-76.64649, 57.32803],
  [-76.6484699, 57.32993],
  [-76.65251, 57.33039],
  [-76.6518801, 57.3321901],
  [-76.6495, 57.33183],
  [-76.64575, 57.3327799],
  [-76.64631, 57.3345599],
  [-76.6453499, 57.33458],
  [-76.64881, 57.33773],
  [-76.65027, 57.33848],
  [-76.6535699, 57.33818],
  [-76.65587, 57.33661],
  [-76.6582201, 57.3363299],
  [-76.6592, 57.33696],
  [-76.65639, 57.3377601],
  [-76.6568999, 57.3385201],
  [-76.65595, 57.3385301],
  [-76.65598, 57.33905],
  [-76.6606701, 57.3380901],
  [-76.66118, 57.3389801],
  [-76.6602399, 57.3389999],
  [-76.66005, 57.34002],
  [-76.66786, 57.33991],
  [-76.6645899, 57.3409801],
  [-76.6634501, 57.3403201],
  [-76.6603301, 57.34104],
  [-76.66031, 57.3405301],
  [-76.65653, 57.34083],
  [-76.6565501, 57.3413399],
  [-76.6546701, 57.34162],
  [-76.65426, 57.34291],
  [-76.65331, 57.34293],
  [-76.65437, 57.34547],
  [-76.6576701, 57.34517],
  [-76.65827, 57.3479801],
  [-76.6545401, 57.34905],
  [-76.65508, 57.35071],
  [-76.66076, 57.35063],
  [-76.66149, 57.3510099],
  [-76.66129, 57.3517799],
  [-76.6626999, 57.3515],
  [-76.66272, 57.3520199],
  [-76.66439, 57.35225],
  [-76.6646601, 57.3530199],
  [-76.6623301, 57.35381],
  [-76.66284, 57.35458],
  [-76.66143, 57.35485],
  [-76.6605501, 57.3564],
  [-76.6596, 57.35641],
  [-76.65872, 57.35796],
  [-76.6577801, 57.35797],
  [-76.6572301, 57.3615501],
  [-76.65841, 57.3615501],
  [-76.6584401, 57.36206],
  [-76.6598801, 57.3625501],
  [-76.65893, 57.3625699],
  [-76.65898, 57.36359],
  [-76.65993, 57.3635799],
  [-76.65938, 57.36717],
  [-76.6605699, 57.36741],
  [-76.6602201, 57.36997],
  [-76.66116, 57.36996],
  [-76.6629409, 57.372272],
  [-76.6582096, 57.373249],
  [-76.66276, 57.3737801],
  [-76.6633401, 57.3726601],
  [-76.6641299, 57.37274],
  [-76.66396, 57.37428],
  [-76.6694699, 57.37548],
  [-76.6685899, 57.3770299],
  [-76.66696, 57.3775699],
  [-76.6682499, 57.37985],
  [-76.66919, 57.37984],
  [-76.6712, 57.3822499],
  [-76.6731, 57.3823499],
  [-76.6742301, 57.38643],
  [-76.6785, 57.38638],
  [-76.67852, 57.3868901],
  [-76.6870201, 57.3860101],
  [-76.6871, 57.3877999],
  [-76.6828199, 57.3876],
  [-76.68286, 57.3883699],
  [-76.6776899, 57.3894601],
  [-76.6785701, 57.3930299],
  [-76.6774799, 57.39509],
  [-76.67653, 57.3951101],
  [-76.6761201, 57.39666],
  [-76.67377, 57.39695],
  [-76.67366, 57.39975],
  [-76.6758099, 57.4002401],
  [-76.6772699, 57.40099],
  [-76.67683, 57.40176],
  [-76.6777801, 57.40174],
  [-76.6764, 57.40266],
  [-76.67451, 57.4028199],
  [-76.67507, 57.4045999],
  [-76.67364, 57.40462],
  [-76.6731201, 57.4035999],
  [-76.67217, 57.40362],
  [-76.67221, 57.4043799],
  [-76.6684401, 57.40496],
  [-76.67272, 57.4051399],
  [-76.67229, 57.40617],
  [-76.67041, 57.4064601],
  [-76.67229, 57.40643],
  [-76.67232, 57.4069399],
  [-76.6746899, 57.4067801],
  [-76.67561, 57.4061301],
  [-76.67276, 57.40617],
  [-76.6736701, 57.4052599],
  [-76.6760399, 57.4052301],
  [-76.6774899, 57.40585],
  [-76.67753, 57.40662],
  [-76.67609, 57.4063799],
  [-76.67709, 57.40739],
  [-76.68606, 57.4063699],
  [-76.6867901, 57.40675],
  [-76.68664, 57.4088001],
  [-76.6894999, 57.4090201],
  [-76.68898, 57.408],
  [-76.6899299, 57.40799],
  [-76.68965, 57.40722],
  [-76.69175, 57.40643],
  [-76.6917901, 57.40719],
  [-76.6932, 57.4069199],
  [-76.6937099, 57.40742],
  [-76.6945999, 57.40652],
  [-76.6972501, 57.4073799],
  [-76.69706, 57.4081599],
  [-76.698, 57.4081301],
  [-76.69663, 57.40943],
  [-76.69521, 57.4094501],
  [-76.6947901, 57.4104899],
  [-76.6981499, 57.41133],
  [-76.6989201, 57.41273],
  [-76.6982501, 57.41351],
  [-76.7001401, 57.4134799],
  [-76.6997099, 57.4142599],
  [-76.6978001, 57.4140301],
  [-76.70032, 57.41732],
  [-76.69588, 57.4189201],
  [-76.7003899, 57.41886],
  [-76.6992699, 57.4201499],
  [-76.69962, 57.4227099],
  [-76.69867, 57.4227099],
  [-76.69874, 57.4242599],
  [-76.69733, 57.42453],
  [-76.6973799, 57.42556],
  [-76.70688, 57.4256801],
  [-76.70554, 57.4274899],
  [-76.7045901, 57.4275099],
  [-76.70463, 57.4282699],
  [-76.7023, 57.42907],
  [-76.7001, 57.43319],
  [-76.6967918, 57.4333962],
  [-76.6990114, 57.4339899],
  [-76.6993899, 57.43808],
  [-76.70866, 57.43846],
  [-76.7084801, 57.43974],
  [-76.70942, 57.4397299],
  [-76.70877, 57.44076],
  [-76.7109101, 57.44756],
  [-76.71652, 57.44898],
  [-76.71743, 57.44819],
  [-76.72932, 57.44855],
  [-76.7302999, 57.4491799],
  [-76.72935, 57.44919],
  [-76.7328442, 57.4540077],
  [-76.7382554, 57.4571802],
  [-76.7382977, 57.4575949],
  [-76.7332354, 57.457573],
  [-76.7306798, 57.4582214],
  [-76.7273787, 57.4602648],
  [-76.7264944, 57.4617452],
  [-76.7280786, 57.464343],
  [-76.72625, 57.4649868],
  [-76.7264193, 57.4655778],
  [-76.7282243, 57.4653407],
  [-76.7289909, 57.4665218],
  [-76.7278034, 57.4671155],
  [-76.7267195, 57.4660708],
  [-76.7256687, 57.4664523],
  [-76.7273534, 57.4677219],
  [-76.7288101, 57.4680112],
  [-76.7305989, 57.4668161],
  [-76.7295974, 57.4657895],
  [-76.7297726, 57.4646566],
  [-76.73191, 57.46324],
  [-76.734785, 57.4623995],
  [-76.7353129, 57.4598063],
  [-76.7381432, 57.457985],
  [-76.7449455, 57.4574923],
  [-76.7462324, 57.4569652],
  [-76.74677, 57.45599],
  [-76.7504072, 57.4569797],
  [-76.752193, 57.4568316],
  [-76.7526937, 57.4578153],
  [-76.7588567, 57.4593431],
  [-76.7608524, 57.4606272],
  [-76.7586697, 57.4611996],
  [-76.7589799, 57.4628599],
  [-76.75757, 57.46314],
  [-76.75858, 57.4644099],
  [-76.7595299, 57.4643901],
  [-76.7600799, 57.46592],
  [-76.75392, 57.4662601],
  [-76.75348, 57.4670401],
  [-76.75067, 57.4678401],
  [-76.7506399, 57.46733],
  [-76.7444801, 57.46767],
  [-76.7448, 57.4697201],
  [-76.7517301, 57.47013],
  [-76.75322, 57.471650001],
  [-76.7546201, 57.471120001],
  [-76.75492, 57.472390101],
  [-76.7576001, 57.473639901],
  [-76.7591701, 57.476690001],
  [-76.76012, 57.476680001],
  [-76.7606399, 57.477690001],
  [-76.7625001, 57.476770001],
  [-76.76349, 57.477649901],
  [-76.7625399, 57.477670001],
  [-76.76259, 57.478690001],
  [-76.76402, 57.478800001],
  [-76.7645299, 57.479430001],
  [-76.76358, 57.479440001],
  [-76.76435, 57.480710001],
  [-76.76678, 57.481700001],
  [-76.76659, 57.482730101],
  [-76.768, 57.482330001],
  [-76.7685201, 57.483220001],
  [-76.7670999, 57.483490001],
  [-76.76713, 57.484000001],
  [-76.76903, 57.484100001],
  [-76.77003, 57.484990001],
  [-76.76623, 57.485030001],
  [-76.76569, 57.488630101],
  [-76.76737, 57.489120001],
  [-76.7677499, 57.487079901],
  [-76.7689501, 57.487309901],
  [-76.7685001, 57.487820001],
  [-76.76901, 57.488590001],
  [-76.7726399, 57.489810001],
  [-76.7729601, 57.491600001],
  [-76.77637, 57.493089901],
  [-76.7762099, 57.494890001],
  [-76.77434, 57.495549901],
  [-76.7705399, 57.495480001],
  [-76.7706, 57.496759901],
  [-76.77155, 57.496740101],
  [-76.77157, 57.497260001],
  [-76.77205, 57.497250001],
  [-76.77201, 57.496480001],
  [-76.77871, 57.497419901],
  [-76.77902, 57.498690001],
  [-76.7785499, 57.498950101],
  [-76.7798401, 57.500979901],
  [-76.7779601, 57.501520101],
  [-76.7775299, 57.502290101],
  [-76.77416, 57.501570001],
  [-76.7776399, 57.504600001],
  [-76.77669, 57.504610001],
  [-76.7767699, 57.506400101],
  [-76.7753199, 57.505910001],
  [-76.7746301, 57.506429901],
  [-76.77491, 57.507199901],
  [-76.7739601, 57.507209901],
  [-76.77447, 57.507970101],
  [-76.7726101, 57.508759901],
  [-76.77423, 57.512840001],
  [-76.7754299, 57.513080101],
  [-76.7759799, 57.514610001],
  [-76.7771801, 57.514840001],
  [-76.7765201, 57.515879901],
  [-76.7780701, 57.518420101],
  [-76.7797499, 57.518649901],
  [-76.7790899, 57.519680001],
  [-76.7804199, 57.522740101],
  [-76.78326, 57.522439901],
  [-76.7833001, 57.523209901],
  [-76.7818799, 57.523229901],
  [-76.7819, 57.523740101],
  [-76.78382, 57.523970101],
  [-76.78193, 57.524250001],
  [-76.78249, 57.526040001],
  [-76.78624, 57.524960101],
  [-76.7863001, 57.526250001],
  [-76.7846499, 57.526520101],
  [-76.7859899, 57.529569901],
  [-76.78837, 57.529540101],
  [-76.7898601, 57.530800001],
  [-76.7912901, 57.530779901],
  [-76.7908899, 57.532320101],
  [-76.78947, 57.532600001],
  [-76.7886499, 57.535170001],
  [-76.7877, 57.535180001],
  [-76.78981, 57.539250001],
  [-76.7909999, 57.539500001],
  [-76.7927501, 57.541129901],
  [-76.79369, 57.540990101],
  [-76.79546, 57.543009901],
  [-76.7978799, 57.543740001],
  [-76.79815, 57.544510001],
  [-76.79958, 57.544490001],
  [-76.7996301, 57.545510001],
  [-76.7982199, 57.545789901],
  [-76.80062, 57.546260001],
  [-76.8026301, 57.548280001],
  [-76.8016699, 57.548300001],
  [-76.8009999, 57.549080001],
  [-76.8013399, 57.551119901],
  [-76.8027699, 57.551100101],
  [-76.80279, 57.551610001],
  [-76.8037401, 57.551600001],
  [-76.80429, 57.553129901],
  [-76.80572, 57.553110101],
  [-76.8085098, 57.558662001],
  [-76.8122971, 57.562270401],
  [-76.8204296, 57.565521701],
  [-76.8225324, 57.565026801],
  [-76.8211806, 57.566131601],
  [-76.8227685, 57.566396301],
  [-76.8225324, 57.567495301],
  [-76.8241632, 57.568226101],
  [-76.8211833, 57.570003901],
  [-76.8200755, 57.572512401],
  [-76.8201131, 57.574770401],
  [-76.8216151, 57.575846201],
  [-76.8258691, 57.575713901],
  [-76.8254641, 57.576886001],
  [-76.8224224, 57.576957901],
  [-76.8263572, 57.578095401],
  [-76.8272343, 57.581041901],
  [-76.8269822, 57.582098801],
  [-76.8310511, 57.583303701],
  [-76.8307132, 57.583910501],
  [-76.8274516, 57.584008201],
  [-76.8242571, 57.585796901],
  [-76.828047, 57.587575301],
  [-76.8274301, 57.588285501],
  [-76.8278539, 57.588997201],
  [-76.82992, 57.589120101],
  [-76.8350154, 57.587916101],
  [-76.8355036, 57.588982801],
  [-76.8348223, 57.589382501],
  [-76.8354714, 57.590201901],
  [-76.8392533, 57.592019001],
  [-76.8377352, 57.592878601],
  [-76.8414366, 57.594500001],
  [-76.8397737, 57.595641301],
  [-76.8413079, 57.596581301],
  [-76.8409753, 57.597650701],
  [-76.8420643, 57.599070701],
  [-76.8452239, 57.599085101],
  [-76.8458247, 57.599608201],
  [-76.8453902, 57.600447601],
  [-76.8428528, 57.601031101],
  [-76.8424022, 57.601422001],
  [-76.8430084, 57.601732401],
  [-76.8416458, 57.603140701],
  [-76.8456692, 57.603428201],
  [-76.845519, 57.603997201],
  [-76.8492204, 57.605077901],
  [-76.8490648, 57.606710301],
  [-76.8497568, 57.606572301],
  [-76.8504542, 57.606787901],
  [-76.8476325, 57.607365501],
  [-76.8495315, 57.607563801],
  [-76.8498641, 57.608497801],
  [-76.8481368, 57.608816801],
  [-76.84861, 57.609840001],
  [-76.8473643, 57.610558201],
  [-76.8457282, 57.610699001],
  [-76.8456101, 57.612230601],
  [-76.8486518, 57.613931601],
  [-76.8569076, 57.615204401],
  [-76.8558884, 57.616583501],
  [-76.8572617, 57.616445601],
  [-76.8587744, 57.617204101],
  [-76.858592, 57.617985601],
  [-76.8564034, 57.618663601],
  [-76.8591285, 57.619413401],
  [-76.8588978, 57.619982201],
  [-76.8577498, 57.620085701],
  [-76.8585116, 57.620456301],
  [-76.8565536, 57.622539001],
  [-76.8572295, 57.623659301],
  [-76.8557328, 57.624391801],
  [-76.8538553, 57.626701101],
  [-76.8566877, 57.625994601],
  [-76.8581414, 57.626546001],
  [-76.8591338, 57.625596701],
  [-76.8626905, 57.626100801],
  [-76.8612635, 57.624785301],
  [-76.8597508, 57.625443101],
  [-76.8585062, 57.624549701],
  [-76.8613225, 57.624342901],
  [-76.8622023, 57.623728201],
  [-76.8639726, 57.624291201],
  [-76.8644232, 57.624980601],
  [-76.8638921, 57.625362601],
  [-76.8660593, 57.625500501],
  [-76.8670464, 57.626790201],
  [-76.8624759, 57.628909901],
  [-76.8603087, 57.628533601],
  [-76.8598259, 57.629754301],
  [-76.8569452, 57.630771001],
  [-76.8541154, 57.630190801],
  [-76.8535656, 57.628872501],
  [-76.8526134, 57.629916501],
  [-76.8527341, 57.630590001],
  [-76.8535709, 57.630702001],
  [-76.8531632, 57.631078201],
  [-76.8508056, 57.630412001],
  [-76.8504918, 57.629169801],
  [-76.8465516, 57.629228701],
  [-76.8425095, 57.630845601],
  [-76.8406105, 57.633166101],
  [-76.8385054, 57.642527701],
  [-76.8399063, 57.644850301],
  [-76.839046, 57.645546601],
  [-76.8425354, 57.647282101],
  [-76.8423552, 57.651087001],
  [-76.8452643, 57.652009601],
  [-76.8486548, 57.650519801],
  [-76.8495861, 57.650949301],
  [-76.847221, 57.651726401],
  [-76.8498297, 57.651320601],
  [-76.8538273, 57.651982401],
  [-76.85353, 57.654839901],
  [-76.8543301, 57.656619901],
  [-76.85528, 57.656350001],
  [-76.8543699, 57.657390101],
  [-76.85532, 57.657370001],
  [-76.85586, 57.658390101],
  [-76.8568099, 57.658370001],
  [-76.8573301, 57.659130001],
  [-76.8559001, 57.659160101],
  [-76.85597, 57.660690001],
  [-76.85883, 57.660260001],
  [-76.85909, 57.660900001],
  [-76.85843, 57.661930101],
  [-76.8551201, 57.662749901],
  [-76.85516, 57.663519901],
  [-76.84462, 57.662910001],
  [-76.84372, 57.663940101],
  [-76.84182, 57.664230001],
  [-76.84186, 57.664990001],
  [-76.8404301, 57.665000001],
  [-76.84029, 57.667060101],
  [-76.8415401, 57.668060101],
  [-76.84629, 57.667620101],
  [-76.8496701, 57.668220001],
  [-76.85006, 57.671280101],
  [-76.8533401, 57.674680001],
  [-76.8581, 57.674100001],
  [-76.8600399, 57.674840101],
  [-76.8643599, 57.675030001],
  [-76.86488, 57.675920001],
  [-76.8620101, 57.675969901],
  [-76.8620399, 57.676480001],
  [-76.85919, 57.676780001],
  [-76.85921, 57.677290101],
  [-76.8577901, 57.677560001],
  [-76.8572301, 57.680639901],
  [-76.8640001, 57.682079901],
  [-76.86428, 57.682850101],
  [-76.8652201, 57.682830001],
  [-76.8653401, 57.684879901],
  [-76.87164, 57.686319901],
  [-76.87233, 57.690659901],
  [-76.8713699, 57.690680001],
  [-76.8711, 57.691370001],
  [-76.8737801, 57.690900001],
  [-76.8711499, 57.695549901],
  [-76.87786, 57.695830001],
  [-76.8792301, 57.694659901],
  [-76.88253, 57.693710001],
  [-76.88444, 57.693810001],
  [-76.8835801, 57.695620101],
  [-76.88263, 57.695630101],
  [-76.88266, 57.696140001],
  [-76.8835999, 57.695869901],
  [-76.8857001, 57.699429901],
  [-76.88473, 57.699439901],
  [-76.8850201, 57.700209901],
  [-76.88367, 57.701759901],
  [-76.88395, 57.702649901],
  [-76.88584, 57.702240001],
  [-76.88441, 57.702260001],
  [-76.88533, 57.701610001],
  [-76.8872501, 57.701710001],
  [-76.88729, 57.702480001],
  [-76.8905901, 57.701530101],
  [-76.8920399, 57.701889901],
  [-76.8911301, 57.702680001],
  [-76.89208, 57.702659901],
  [-76.89211, 57.703170001],
  [-76.8902401, 57.703970101],
  [-76.8914601, 57.704460001],
  [-76.89153, 57.705740101],
  [-76.8905999, 57.706270001],
  [-76.8913701, 57.707280001],
  [-76.8956801, 57.707470001],
  [-76.89378, 57.707759901],
  [-76.8933799, 57.709300101],
  [-76.88956, 57.709360001],
  [-76.88943, 57.711410101],
  [-76.8916099, 57.711900001],
  [-76.89223, 57.714690001],
  [-76.8912699, 57.714710001],
  [-76.8905999, 57.715490001],
  [-76.89521, 57.716690001],
  [-76.89555, 57.718730001],
  [-76.89678, 57.719360001],
  [-76.89964, 57.719190101],
  [-76.9009499, 57.721219901],
  [-76.90075, 57.722250001],
  [-76.89744, 57.722820001],
  [-76.8986901, 57.723820001],
  [-76.89874, 57.724840001],
  [-76.9033999, 57.726950001],
  [-76.9039101, 57.727580001],
  [-76.90296, 57.727590001],
  [-76.90399, 57.728980101],
  [-76.90735, 57.729320101],
  [-76.9073899, 57.730100101],
  [-76.90597, 57.730360001],
  [-76.9060301, 57.731390001],
  [-76.9098499, 57.731200101],
  [-76.9108499, 57.732080001],
  [-76.90942, 57.732100101],
  [-76.90898, 57.732880101],
  [-76.90611, 57.732920001],
  [-76.9077299, 57.736480001],
  [-76.9120599, 57.736930001],
  [-76.9138299, 57.738700001],
  [-76.91442, 57.740730001],
  [-76.9153999, 57.741229901],
  [-76.91602, 57.743789901],
  [-76.91869, 57.744500001],
  [-76.9187299, 57.745280001],
  [-76.9215901, 57.744980101],
  [-76.92069, 57.746019901],
  [-76.9164099, 57.746590001],
  [-76.91691, 57.747229901],
  [-76.92075, 57.747300001],
  [-76.92078, 57.747810001],
  [-76.9198201, 57.747820001],
  [-76.9198499, 57.748330101],
  [-76.9164701, 57.747870101],
  [-76.9158, 57.748650101],
  [-76.91876, 57.750400001],
  [-76.9176199, 57.751440101],
  [-76.9214999, 57.752410001],
  [-76.9230401, 57.754180001],
  [-76.9220799, 57.754190001],
  [-76.92266, 57.756230101],
  [-76.9258101, 57.756689901],
  [-76.92642, 57.759249901],
  [-76.9239699, 57.762870001],
  [-76.92302, 57.762880101],
  [-76.92603, 57.765520001],
  [-76.9279499, 57.765630001],
  [-76.9280599, 57.767670101],
  [-76.93777, 57.769950001],
  [-76.94348, 57.769230101],
  [-76.94191, 57.766689901],
  [-76.9452701, 57.766770101],
  [-76.9457, 57.765860001],
  [-76.94789, 57.766600001],
  [-76.9472801, 57.768660101],
  [-76.94493, 57.769460101],
  [-76.9452801, 57.771500001],
  [-76.94777, 57.773259901],
  [-76.94756, 57.773770001],
  [-76.95211, 57.773699901],
  [-76.95149, 57.775510001],
  [-76.95436, 57.775460101],
  [-76.9537401, 57.777259901],
  [-76.955, 57.778520001],
  [-76.95648, 57.779269901],
  [-76.95936, 57.779230101],
  [-76.95938, 57.779740001],
  [-76.9584299, 57.779740001],
  [-76.95989, 57.780240101],
  [-76.96079, 57.783560101],
  [-76.96158, 57.784819901],
  [-76.96301, 57.784800101],
  [-76.9628699, 57.786599901],
  [-76.9667401, 57.791530001],
  [-76.9696201, 57.791870001],
  [-76.9704199, 57.793149901],
  [-76.97189, 57.793630001],
  [-76.9742199, 57.796920101],
  [-76.9751801, 57.796900101],
  [-76.9759899, 57.798430001],
  [-76.9755301, 57.798949901],
  [-76.9781999, 57.799420001],
  [-76.9782099, 57.799929901],
  [-76.97581, 57.799709901],
  [-76.9767401, 57.803540001],
  [-76.97845, 57.804020101],
  [-76.97817, 57.804709901],
  [-76.98001, 57.806300001],
  [-76.98268, 57.806770001],
  [-76.98294, 57.807279901],
  [-76.9819899, 57.807300001],
  [-76.9834499, 57.807790001],
  [-76.98348, 57.808300001],
  [-76.97869, 57.808370101],
  [-76.97923, 57.809520001],
  [-76.9816301, 57.809480101],
  [-76.98481, 57.810580101],
  [-76.9838899, 57.811619901],
  [-76.98473, 57.813650001],
  [-76.98671, 57.814650001],
  [-76.99291, 57.814040101],
  [-76.9925201, 57.815580101],
  [-76.99394, 57.815300001],
  [-76.9949801, 57.816820101],
  [-76.98824, 57.816420001],
  [-76.9861901, 57.818240001],
  [-76.9865399, 57.820279901],
  [-76.9889601, 57.820629901],
  [-76.9899601, 57.821509901],
  [-76.9919, 57.821739901],
  [-76.99095, 57.822010001],
  [-76.9924201, 57.822499901],
  [-76.99125, 57.827380101],
  [-76.99317, 57.827350001],
  [-76.99494, 57.824629901],
  [-76.99781, 57.824460001],
  [-76.99858, 57.825460001],
  [-76.99713, 57.829590101],
  [-76.99617, 57.829609901],
  [-76.9962001, 57.830120001],
  [-76.9908701, 57.829179901],
  [-76.9897501, 57.830729901],
  [-76.9901099, 57.832780001],
  [-76.99346, 57.832720101],
  [-76.9934201, 57.831959901],
  [-76.9948501, 57.831680001],
  [-76.99547, 57.834230001],
  [-76.99691, 57.834330001],
  [-76.99847, 57.832130001],
  [-77.00061, 57.831710101],
  [-77.0011099, 57.832090001],
  [-76.99918, 57.832120001],
  [-76.99971, 57.832739901],
  [-77.0038, 57.833069901],
  [-77.0042001, 57.835880001],
  [-77.00324, 57.835900001],
  [-77.00351, 57.836409901],
  [-77.00192, 57.837969901],
  [-77.00094, 57.837729901],
  [-77.0009801, 57.838490101],
  [-76.99861, 57.838790001],
  [-76.9985699, 57.838289901],
  [-76.9959899, 57.839079901],
  [-76.99617, 57.842670001],
  [-76.9974301, 57.843930101],
  [-76.9988799, 57.843900001],
  [-77.00037, 57.844780001],
  [-77.0027601, 57.844480001],
  [-77.00415, 57.843570001],
  [-77.0042001, 57.844590001],
  [-77.0056401, 57.844570001],
  [-77.0076799, 57.846590001],
  [-77.01051, 57.845770001],
  [-77.01183, 57.847800001],
  [-77.0076401, 57.850170101],
  [-77.00747, 57.851450001],
  [-77.01219, 57.850100001],
  [-77.01204, 57.851629901],
  [-77.01484, 57.854529901],
  [-77.01774, 57.854739901],
  [-77.0210199, 57.853540001],
  [-77.0202101, 57.851760001],
  [-77.0208901, 57.850979901],
  [-77.02185, 57.850969901],
  [-77.02182, 57.850450001],
  [-77.02326, 57.850430001],
  [-77.0220099, 57.853780001],
  [-77.02105, 57.853800001],
  [-77.02014, 57.854830101],
  [-77.0225699, 57.855309901],
  [-77.02204, 57.854550001],
  [-77.02541, 57.854490101],
  [-77.02404, 57.855800001],
  [-77.0254401, 57.855130001],
  [-77.0355601, 57.855600101],
  [-77.0366899, 57.858660001],
  [-77.0376601, 57.858780001],
  [-77.0397999, 57.862710001],
  [-77.04174, 57.862940101],
  [-77.0411299, 57.864740101],
  [-77.04519, 57.864419901],
  [-77.0445501, 57.865969901],
  [-77.04821, 57.866930001],
  [-77.04776, 57.867450001],
  [-77.0492, 57.867429901],
  [-77.04977, 57.868950101],
  [-77.0488099, 57.868969901],
  [-77.04863, 57.870000001],
  [-77.04963, 57.870759901],
  [-77.0494501, 57.871780001],
  [-77.04754, 57.872070101],
  [-77.0471, 57.872840101],
  [-77.04832, 57.873089901],
  [-77.0472499, 57.875400101],
  [-77.04629, 57.875419901],
  [-77.0463301, 57.876180101],
  [-77.0401499, 57.877309901],
  [-77.0410399, 57.880370001],
  [-77.04519, 57.881450001],
  [-77.0461, 57.880539901],
  [-77.0494799, 57.880740101],
  [-77.04951, 57.881250001],
  [-77.05096, 57.881480001],
  [-77.0509299, 57.880970101],
  [-77.0523599, 57.880690001],
  [-77.0505799, 57.883280001],
  [-77.04774, 57.884099901],
  [-77.04529, 57.887460001],
  [-77.0481201, 57.890620101],
  [-77.04908, 57.890730001],
  [-77.04752, 57.893060001],
  [-77.04883, 57.894960101],
  [-77.05372, 57.896160001],
  [-77.0565999, 57.895989901],
  [-77.0577099, 57.894429901],
  [-77.0593501, 57.893769901],
  [-77.0622301, 57.893720001],
  [-77.06298, 57.894090101],
  [-77.0630499, 57.895370001],
  [-77.06865, 57.896429901],
  [-77.0706901, 57.898319901],
  [-77.0734799, 57.896860101],
  [-77.07564, 57.900800001],
  [-77.0732301, 57.900840001],
  [-77.0732699, 57.901610001],
  [-77.07132, 57.901130001],
  [-77.0713501, 57.901640101],
  [-77.07529, 57.903240001],
  [-77.07575, 57.902850101],
  [-77.0747901, 57.902860101],
  [-77.0752, 57.901570001],
  [-77.07953, 57.901760101],
  [-77.0795701, 57.902270001],
  [-77.08053, 57.902380001],
  [-77.08112, 57.904290001],
  [-77.0782799, 57.905109901],
  [-77.07887, 57.906889901],
  [-77.07741, 57.906659901],
  [-77.07678, 57.908210101],
  [-77.07754, 57.908960001],
  [-77.0794701, 57.908930001],
  [-77.08055, 57.910960001],
  [-77.08151, 57.910950001],
  [-77.08244, 57.914520001],
  [-77.0841501, 57.914999901],
  [-77.085, 57.917290001],
  [-77.08574, 57.917530101],
  [-77.0870499, 57.915339901],
  [-77.08879, 57.916200101],
  [-77.08822, 57.918770101],
  [-77.08532, 57.918559901],
  [-77.0859, 57.920349901],
  [-77.0849201, 57.920119901],
  [-77.0840201, 57.921150001],
  [-77.0830499, 57.921160001],
  [-77.0812699, 57.923500001],
  [-77.08465, 57.923820001],
  [-77.08554, 57.922400001],
  [-77.08698, 57.922380001],
  [-77.0892699, 57.920290001],
  [-77.09164, 57.919999901],
  [-77.09009, 57.922070001],
  [-77.0903799, 57.922830001],
  [-77.09134, 57.922820001],
  [-77.09168, 57.924610001],
  [-77.0929, 57.924840001],
  [-77.0929301, 57.925360001],
  [-77.08723, 57.926730001],
  [-77.09032, 57.930129901],
  [-77.0912799, 57.930119901],
  [-77.09355, 57.931740001],
  [-77.0932899, 57.935590001],
  [-77.09144, 57.936899901],
  [-77.0911501, 57.940229901],
  [-77.0939101, 57.942239901],
  [-77.09809, 57.943710001],
  [-77.0979, 57.944730001],
  [-77.0993999, 57.945479901],
  [-77.1112899, 57.947070001],
  [-77.11312, 57.949600001],
  [-77.11076, 57.950410001],
  [-77.1108499, 57.951940001],
  [-77.1120699, 57.952180001],
  [-77.11236, 57.952940001],
  [-77.1138, 57.952919901],
  [-77.1139699, 57.955730001],
  [-77.1197399, 57.955650001],
  [-77.11979, 57.952310001],
  [-77.12257, 57.950469901],
  [-77.12169, 57.947930001],
  [-77.1158301, 57.946479901],
  [-77.1157101, 57.944440101],
  [-77.121, 57.944349901],
  [-77.1209599, 57.943579901],
  [-77.1223901, 57.943430101],
  [-77.12826, 57.945000101],
  [-77.12877, 57.945630001],
  [-77.1317, 57.945960001],
  [-77.1372901, 57.950990101],
  [-77.14021, 57.951450101],
  [-77.14478, 57.955469901],
  [-77.14913, 57.955780101],
  [-77.1494101, 57.956420001],
  [-77.1487401, 57.957200001],
  [-77.14537, 57.957510001],
  [-77.1445399, 57.959579901],
  [-77.13689, 57.960469901],
  [-77.1367301, 57.961750001],
  [-77.1380899, 57.964550001],
  [-77.13479, 57.965630001],
  [-77.13435, 57.966400001],
  [-77.1290601, 57.966750001],
  [-77.1268201, 57.965259901],
  [-77.1268401, 57.961660001],
  [-77.12781, 57.961650001],
  [-77.1277499, 57.960890101],
  [-77.1306299, 57.960579901],
  [-77.1300501, 57.958790101],
  [-77.12565, 57.957840001],
  [-77.1257, 57.958610001],
  [-77.12666, 57.958589901],
  [-77.1267201, 57.959620001],
  [-77.12576, 57.959630001],
  [-77.12376, 57.962479901],
  [-77.1240799, 57.963760001],
  [-77.12069, 57.963560101],
  [-77.1212, 57.964190001],
  [-77.1231501, 57.964410001],
  [-77.12469, 57.966050001],
  [-77.12711, 57.966139901],
  [-77.1286399, 57.967520001],
  [-77.1320501, 57.968230101],
  [-77.1318599, 57.969010101],
  [-77.13335, 57.969750001],
  [-77.1406399, 57.970910101],
  [-77.1424299, 57.972540001],
  [-77.14433, 57.972120101],
  [-77.1439501, 57.969830001],
  [-77.1494299, 57.968840001],
  [-77.1512801, 57.967400001],
  [-77.1599601, 57.967630001],
  [-77.16349, 57.970139901],
  [-77.16593, 57.970730001],
  [-77.1655301, 57.972020101],
  [-77.1665, 57.972010101],
  [-77.16704, 57.973020101],
  [-77.16948, 57.973620001],
  [-77.1710701, 57.976020101],
  [-77.17204, 57.976010101],
  [-77.17117, 57.977560101],
  [-77.17213, 57.977540001],
  [-77.1720801, 57.976780001],
  [-77.17405, 57.977510001],
  [-77.1649899, 57.979070001],
  [-77.15914, 57.978020101],
  [-77.1586201, 57.977259901],
  [-77.15568, 57.976540001],
  [-77.15571, 57.977039901],
  [-77.1547499, 57.977070001],
  [-77.1555499, 57.978340101],
  [-77.15871, 57.978800101],
  [-77.1587499, 57.979560001],
  [-77.1607, 57.979790101],
  [-77.16024, 57.980310001],
  [-77.1621701, 57.980269901],
  [-77.1621999, 57.980780001],
  [-77.16316, 57.980770001],
  [-77.1627, 57.981290001],
  [-77.16515, 57.981760001],
  [-77.1647301, 57.982790101],
  [-77.16717, 57.983259901],
  [-77.1663199, 57.985070001],
  [-77.1706499, 57.984990001],
  [-77.17068, 57.985510001],
  [-77.1728799, 57.985980001],
  [-77.17323, 57.987770001],
  [-77.1718, 57.988049901],
  [-77.17183, 57.988560001],
  [-77.16948, 57.989489901],
  [-77.16417, 57.989200001],
  [-77.16213, 57.987440001],
  [-77.16068, 57.987470101],
  [-77.16037, 57.986450001],
  [-77.1609899, 57.984650001],
  [-77.1595101, 57.984030101],
  [-77.1570999, 57.984200001],
  [-77.1570601, 57.983430001],
  [-77.15124, 57.982890001],
  [-77.1486, 57.984259901],
  [-77.1516, 57.984810101],
  [-77.14936, 57.987660001],
  [-77.15013, 57.988540001],
  [-77.1549799, 57.989100001],
  [-77.15502, 57.989609901],
  [-77.1579899, 57.990839901],
  [-77.1609, 57.991180001],
  [-77.1623099, 57.990640001],
  [-77.16571, 57.990970001],
  [-77.1657401, 57.991480101],
  [-77.1705599, 57.991650001],
  [-77.17012, 57.992169901],
  [-77.17349, 57.992120001],
  [-77.1732001, 57.992460101],
  [-77.18339, 57.996300001],
  [-77.18483, 57.996150101],
  [-77.1898799, 57.999900001],
  [-77.19428, 58.000850001],
  [-77.19569, 58.000310001],
  [-77.19981, 58.000640001],
  [-77.2001001, 58.001389901],
  [-77.19772, 58.001949901],
  [-77.19636, 58.003250101],
  [-77.1910901, 58.003990001],
  [-77.1872199, 58.003660001],
  [-77.1868, 58.004690101],
  [-77.1892001, 58.004650001],
  [-77.18939, 58.007719901],
  [-77.19658, 58.006949901],
  [-77.1973499, 58.007580101],
  [-77.1962199, 58.008880001],
  [-77.19196, 58.010250101],
  [-77.1904, 58.008470101],
  [-77.1840901, 58.007680001],
  [-77.18114, 58.006839901],
  [-77.1811099, 58.006320001],
  [-77.1796599, 58.006350001],
  [-77.1796301, 58.005839901],
  [-77.1705399, 58.007020001],
  [-77.1705599, 58.007530001],
  [-77.16293, 58.008939901],
  [-77.1629, 58.008430001],
  [-77.1609501, 58.008200001],
  [-77.16093, 58.007690101],
  [-77.1489501, 58.009049901],
  [-77.1485201, 58.009820101],
  [-77.1505001, 58.010810101],
  [-77.1534001, 58.010890001],
  [-77.1534299, 58.011399901],
  [-77.17183, 58.012499901],
  [-77.17661, 58.011650001],
  [-77.18293, 58.012440001],
  [-77.1829601, 58.012949901],
  [-77.1856599, 58.013670001],
  [-77.18594, 58.014440001],
  [-77.1886301, 58.014910001],
  [-77.18806, 58.017470001],
  [-77.1857, 58.018279901],
  [-77.1878, 58.021059901],
  [-77.19072, 58.021519901],
  [-77.1907699, 58.022289901],
  [-77.1922101, 58.022270101],
  [-77.19227, 58.023030001],
  [-77.19618, 58.023990001],
  [-77.1967, 58.024619901],
  [-77.1990901, 58.024200001],
  [-77.1999099, 58.021880001],
  [-77.2153201, 58.021350001],
  [-77.2185501, 58.022830101],
  [-77.2196701, 58.025380101],
  [-77.2224401, 58.027250001],
  [-77.22875, 58.027780001],
  [-77.22904, 58.028540001],
  [-77.2260001, 58.030130001],
  [-77.2255699, 58.030910001],
  [-77.23662, 58.030069901],
  [-77.23751, 58.032749901],
  [-77.23598, 58.035330001],
  [-77.2331, 58.035639901],
  [-77.2324799, 58.037189901],
  [-77.2343599, 58.040230001],
  [-77.23532, 58.040210001],
  [-77.23587, 58.041220001],
  [-77.24032, 58.042810001],
  [-77.2451, 58.042089901],
  [-77.24595, 58.040280101],
  [-77.24883, 58.039969901],
  [-77.24886, 58.040480001],
  [-77.25355, 58.042189901],
  [-77.25507, 58.047030001],
  [-77.2512699, 58.048120001],
  [-77.25185, 58.049649901],
  [-77.26254, 58.050610101],
  [-77.2631401, 58.052519901],
  [-77.2612301, 58.052810001],
  [-77.26152, 58.053570001],
  [-77.2632401, 58.054060101],
  [-77.26551, 58.051840101],
  [-77.26743, 58.051680001],
  [-77.267, 58.052450001],
  [-77.26896, 58.052930001],
  [-77.26866, 58.051910001],
  [-77.2696901, 58.051800001],
  [-77.26933, 58.051130001],
  [-77.2712601, 58.051100001],
  [-77.2700301, 58.051969901],
  [-77.26951, 58.053940101],
  [-77.2673899, 58.054749901],
  [-77.26721, 58.055780001],
  [-77.2691699, 58.056250001],
  [-77.2691499, 58.055740101],
  [-77.27674, 58.053690001],
  [-77.2840401, 58.054580001],
  [-77.28546, 58.054170101],
  [-77.28599, 58.054810001],
  [-77.29235, 58.056099901],
  [-77.29312, 58.056850101],
  [-77.2929599, 58.058130001],
  [-77.29054, 58.058050001],
  [-77.2853601, 58.060060101],
  [-77.2860301, 58.063240001],
  [-77.2881401, 58.065769901],
  [-77.291, 58.065209901],
  [-77.29175, 58.065570001],
  [-77.29109, 58.066620101],
  [-77.29258, 58.067230001],
  [-77.2969499, 58.067539901],
  [-77.29409, 58.068099901],
  [-77.2941799, 58.069759901],
  [-77.29734, 58.069830001],
  [-77.2974099, 58.070860101],
  [-77.29912, 58.071209901],
  [-77.3009499, 58.069639901],
  [-77.3048101, 58.069700001],
  [-77.30487, 58.070470001],
  [-77.3068301, 58.070940001],
  [-77.3052, 58.071740101],
  [-77.3054901, 58.072500001],
  [-77.3045199, 58.072520101],
  [-77.3056199, 58.074549901],
  [-77.30712, 58.075290101],
  [-77.3076001, 58.075280001],
  [-77.30755, 58.074520101],
  [-77.3113901, 58.074190101],
  [-77.31191, 58.074690001],
  [-77.3109401, 58.074450001],
  [-77.3104801, 58.074970101],
  [-77.3119301, 58.074950101],
  [-77.3119599, 58.075460001],
  [-77.3172701, 58.075360001],
  [-77.3172999, 58.075869901],
  [-77.32292, 58.076800001],
  [-77.32188, 58.079380001],
  [-77.3316399, 58.080730001],
  [-77.3321999, 58.081750101],
  [-77.33488, 58.082209901],
  [-77.33567, 58.083219901],
  [-77.33424, 58.083500001],
  [-77.33369, 58.082490001],
  [-77.33081, 58.082800001],
  [-77.33047, 58.085109901],
  [-77.33237, 58.084559901],
  [-77.33204, 58.086870101],
  [-77.33347, 58.086590001],
  [-77.3335101, 58.087099901],
  [-77.3325399, 58.087119901],
  [-77.33259, 58.087889901],
  [-77.33503, 58.088229901],
  [-77.33733, 58.086390001],
  [-77.3421999, 58.086940001],
  [-77.3408699, 58.088760101],
  [-77.33893, 58.088800001],
  [-77.339, 58.089820001],
  [-77.3428699, 58.089870101],
  [-77.34934, 58.092570001],
  [-77.3532199, 58.092760101],
  [-77.35461, 58.092090101],
  [-77.35457, 58.091319901],
  [-77.3555399, 58.091310101],
  [-77.3557301, 58.090530101],
  [-77.3602199, 58.088920001],
  [-77.3601801, 58.088400001],
  [-77.3679701, 58.089150001],
  [-77.37405, 58.093270001],
  [-77.36838, 58.095420101],
  [-77.3684399, 58.096449901],
  [-77.3713399, 58.096260001],
  [-77.3718799, 58.097150001],
  [-77.37669, 58.096669901],
  [-77.37918, 58.097779901],
  [-77.37925, 58.098800001],
  [-77.37735, 58.099350001],
  [-77.3767, 58.100390001],
  [-77.37707, 58.102430101],
  [-77.3785201, 58.102400001],
  [-77.3785599, 58.102910001],
  [-77.38448, 58.101009901],
  [-77.3846501, 58.099980101],
  [-77.38562, 58.099970101],
  [-77.3855599, 58.099200101],
  [-77.3889099, 58.098620001],
  [-77.3888601, 58.097860101],
  [-77.3879099, 58.098130001],
  [-77.3878801, 58.097620001],
  [-77.3946501, 58.097750101],
  [-77.3944499, 58.098260001],
  [-77.3954499, 58.098760101],
  [-77.3954499, 58.102339901],
  [-77.3968801, 58.102060001],
  [-77.39748, 58.103840001],
  [-77.39652, 58.103860101],
  [-77.3953101, 58.107470001],
  [-77.3919801, 58.108300001],
  [-77.3915501, 58.109070001],
  [-77.3901001, 58.109100101],
  [-77.3892299, 58.110650101],
  [-77.38827, 58.110669901],
  [-77.3885599, 58.111430101],
  [-77.39341, 58.111600001],
  [-77.39425, 58.113380001],
  [-77.39329, 58.113390001],
  [-77.3928899, 58.114679901],
  [-77.39386, 58.114660101],
  [-77.39418, 58.115940001],
  [-77.3958999, 58.116290001],
  [-77.3982299, 58.114970001],
  [-77.4011399, 58.115040001],
  [-77.40117, 58.115550101],
  [-77.39974, 58.115830001],
  [-77.3993101, 58.116610001],
  [-77.3983399, 58.116630001],
  [-77.3992101, 58.118660101],
  [-77.4036601, 58.120110101],
  [-77.40463, 58.123679901],
  [-77.40559, 58.123660101],
  [-77.40539, 58.124180001],
  [-77.40787, 58.125160001],
  [-77.4093401, 58.128970001],
  [-77.41374, 58.129650101],
  [-77.41431, 58.131050001],
  [-77.41676, 58.131400001],
  [-77.41738, 58.133170001],
  [-77.41884, 58.133270001],
  [-77.4202, 58.132090001],
  [-77.42842, 58.131940001],
  [-77.42252, 58.134100101],
  [-77.42332, 58.135230101],
  [-77.4257701, 58.135699901],
  [-77.43207, 58.135710001],
  [-77.43217, 58.137239901],
  [-77.4341, 58.137080001],
  [-77.4343799, 58.137710001],
  [-77.4332599, 58.139010101],
  [-77.4342301, 58.139000101],
  [-77.4347801, 58.140010101],
  [-77.4377099, 58.140340101],
  [-77.43961, 58.139790101],
  [-77.4411301, 58.140780101],
  [-77.44744, 58.141050001],
  [-77.4477901, 58.138990101],
  [-77.45118, 58.139050001],
  [-77.45267, 58.139670101],
  [-77.45189, 58.142239901],
  [-77.4509201, 58.142259901],
  [-77.4510301, 58.143919901],
  [-77.4524601, 58.143760001],
  [-77.45244, 58.143259901],
  [-77.4549001, 58.143850001],
  [-77.4554601, 58.144990001],
  [-77.4544899, 58.145010101],
  [-77.45432, 58.146039901],
  [-77.4551, 58.146790101],
  [-77.45752, 58.146740001],
  [-77.457, 58.146249901],
  [-77.4616901, 58.143970001],
  [-77.46945, 58.144210001],
  [-77.4689301, 58.143710001],
  [-77.47479, 58.144359901],
  [-77.4748299, 58.144870001],
  [-77.4762601, 58.144589901],
  [-77.4793899, 58.147860001],
  [-77.4891401, 58.148689901],
  [-77.48869, 58.149210001],
  [-77.4906101, 58.149050001],
  [-77.49658, 58.151359901],
  [-77.4964801, 58.153160001],
  [-77.50087, 58.153579901],
  [-77.5011799, 58.154599901],
  [-77.5021501, 58.154579901],
  [-77.5039699, 58.156340101],
  [-77.50715, 58.156790101],
  [-77.50748, 58.158060001],
  [-77.50977, 58.159680101],
  [-77.51123, 58.159790101],
  [-77.51167, 58.159130101],
  [-77.52478, 58.159510001],
  [-77.52482, 58.160029901],
  [-77.52679, 58.160500001],
  [-77.5279, 58.162530001],
  [-77.5254299, 58.161809901],
  [-77.5240701, 58.163110001],
  [-77.51145, 58.162850001],
  [-77.5093901, 58.161350101],
  [-77.50647, 58.161029901],
  [-77.4968499, 58.161980001],
  [-77.4910301, 58.161840001],
  [-77.4881799, 58.162670101],
  [-77.4803899, 58.162180001],
  [-77.4798499, 58.161420001],
  [-77.47842, 58.161699901],
  [-77.47788, 58.160939901],
  [-77.47643, 58.160970001],
  [-77.4763801, 58.160210001],
  [-77.47344, 58.159750001],
  [-77.4733999, 58.159240101],
  [-77.4680599, 58.159090001],
  [-77.46696, 58.160650001],
  [-77.4673601, 58.162939901],
  [-77.4621, 58.163939901],
  [-77.44852, 58.163819901],
  [-77.44789, 58.165110001],
  [-77.4491599, 58.166110001],
  [-77.4540201, 58.166269901],
  [-77.4540599, 58.166780001],
  [-77.4570499, 58.168010101],
  [-77.45608, 58.168030101],
  [-77.4567199, 58.170320001],
  [-77.4606099, 58.170499901],
  [-77.46122, 58.172279901],
  [-77.4708201, 58.170809901],
  [-77.47088, 58.171840001],
  [-77.46945, 58.172120101],
  [-77.46954, 58.173400001],
  [-77.4705099, 58.173379901],
  [-77.47197, 58.177190001],
  [-77.47423, 58.178169901],
  [-77.4748201, 58.179700101],
  [-77.47579, 58.179680101],
  [-77.4758299, 58.180190001],
  [-77.4821, 58.179810101],
  [-77.48579, 58.183970001],
  [-77.4865099, 58.183829901],
  [-77.48664, 58.182290001],
  [-77.48809, 58.182260101],
  [-77.4881799, 58.183540001],
  [-77.4891501, 58.183509901],
  [-77.49121, 58.185260101],
  [-77.49414, 58.185599901],
  [-77.4961799, 58.186970001],
  [-77.4947101, 58.186740001],
  [-77.49431, 58.188040101],
  [-77.49335, 58.188049901],
  [-77.4939699, 58.190080001],
  [-77.5017201, 58.189920101],
  [-77.50176, 58.190440001],
  [-77.5027299, 58.190420001],
  [-77.5036199, 58.192710101],
  [-77.50924, 58.193370101],
  [-77.51103, 58.198200001],
  [-77.50868, 58.199010001],
  [-77.5089699, 58.199780001],
  [-77.50803, 58.200310001],
  [-77.5084199, 58.202350001],
  [-77.50745, 58.202370101],
  [-77.50647, 58.205719901],
  [-77.5046399, 58.207289901],
  [-77.5051081, 58.211634201],
  [-77.5040756, 58.212060001],
  [-77.5000223, 58.211277601],
  [-77.4974181, 58.212379901],
  [-77.5071701, 58.215690001],
  [-77.507, 58.216710101],
  [-77.50603, 58.216739901],
  [-77.50732, 58.217729901],
  [-77.50856, 58.221550001],
  [-77.5118401, 58.223409901],
  [-77.5142701, 58.223360001],
  [-77.51482, 58.224250001],
  [-77.5136399, 58.224780001],
  [-77.5159799, 58.226900001],
  [-77.5169501, 58.227020001],
  [-77.51701, 58.227790001],
  [-77.5243199, 58.228160101],
  [-77.52389, 58.228930101],
  [-77.5378799, 58.227760001],
  [-77.54113, 58.229100001],
  [-77.5409601, 58.230130001],
  [-77.5366, 58.230220001],
  [-77.5358501, 58.233309901],
  [-77.5348699, 58.233309901],
  [-77.5320601, 58.237990001],
  [-77.53438, 58.239990001],
  [-77.5373099, 58.240189901],
  [-77.5378699, 58.241199901],
  [-77.53547, 58.241510101],
  [-77.53369, 58.243850101],
  [-77.5294101, 58.245209901],
  [-77.52945, 58.245720101],
  [-77.5270799, 58.246539901],
  [-77.52712, 58.247050001],
  [-77.5251899, 58.247350001],
  [-77.52482, 58.248890001],
  [-77.5219501, 58.249590001],
  [-77.5170899, 58.249550001],
  [-77.5161999, 58.250600001],
  [-77.51813, 58.250429901],
  [-77.5191601, 58.251309901],
  [-77.51724, 58.251600001],
  [-77.5163801, 58.253150001],
  [-77.5149, 58.252930001],
  [-77.51455, 58.254730101],
  [-77.5107, 58.255309901],
  [-77.50915, 58.254060101],
  [-77.5061899, 58.253350001],
  [-77.50458, 58.254670001],
  [-77.50466, 58.255690001],
  [-77.50589, 58.256050001],
  [-77.51025, 58.255830001],
  [-77.51137, 58.257860101],
  [-77.5075199, 58.258450001],
  [-77.5070899, 58.259240001],
  [-77.5051899, 58.259780001],
  [-77.50522, 58.260290101],
  [-77.50233, 58.260600001],
  [-77.50236, 58.261109901],
  [-77.4989499, 58.260920001],
  [-77.4966101, 58.258659901],
  [-77.49766, 58.256340001],
  [-77.50198, 58.255480001],
  [-77.50167, 58.254730101],
  [-77.50322, 58.252639901],
  [-77.5017499, 58.252419901],
  [-77.49898, 58.254520101],
  [-77.4966101, 58.255329901],
  [-77.4961799, 58.256110001],
  [-77.49379, 58.256670001],
  [-77.4910401, 58.259030001],
  [-77.4858499, 58.261180101],
  [-77.4840501, 58.263260001],
  [-77.48942, 58.263539901],
  [-77.4908499, 58.263260001],
  [-77.49176, 58.262340001],
  [-77.4980501, 58.261960101],
  [-77.49809, 58.262480001],
  [-77.50613, 58.262830001],
  [-77.50779, 58.265610001],
  [-77.50644, 58.267180101],
  [-77.5063801, 58.269740101],
  [-77.5053999, 58.269759901],
  [-77.50301, 58.274840001],
  [-77.50336, 58.275180101],
  [-77.4971601, 58.276960101],
  [-77.48788, 58.276250001],
  [-77.4883999, 58.276750101],
  [-77.47779, 58.277980101],
  [-77.48509, 58.278090101],
  [-77.48513, 58.278610001],
  [-77.48558, 58.278080101],
  [-77.4885099, 58.278280001],
  [-77.49067, 58.281439901],
  [-77.49457, 58.281620001],
  [-77.4956001, 58.282370001],
  [-77.49656, 58.282219901],
  [-77.4966001, 58.282740101],
  [-77.50148, 58.283020001],
  [-77.5038301, 58.281830001],
  [-77.5096101, 58.281070001],
  [-77.5105001, 58.279899901],
  [-77.51147, 58.279879901],
  [-77.5114001, 58.278860101],
  [-77.50992, 58.278630101],
  [-77.5102999, 58.277090101],
  [-77.50881, 58.276860101],
  [-77.50715, 58.273820001],
  [-77.50948, 58.272370001],
  [-77.51046, 58.272480001],
  [-77.5107599, 58.269910001],
  [-77.51412, 58.269329901],
  [-77.518, 58.262340001],
  [-77.5194099, 58.261670001],
  [-77.5251999, 58.261050001],
  [-77.5246101, 58.259649901],
  [-77.5276399, 58.261250001],
  [-77.52958, 58.261209901],
  [-77.5305201, 58.260560001],
  [-77.52659, 58.260120001],
  [-77.5262801, 58.259099901],
  [-77.5274199, 58.258180101],
  [-77.53178, 58.257970101],
  [-77.53133, 58.258490001],
  [-77.53282, 58.258850101],
  [-77.5357, 58.258410101],
  [-77.5356399, 58.257639901],
  [-77.53223, 58.257450001],
  [-77.53193, 58.256690001],
  [-77.53404, 58.255620101],
  [-77.53393, 58.254089901],
  [-77.53102, 58.254130001],
  [-77.53048, 58.253390101],
  [-77.53334, 58.252560001],
  [-77.5333001, 58.252050001],
  [-77.53136, 58.252089901],
  [-77.5315399, 58.251060101],
  [-77.5339, 58.250250001],
  [-77.53383, 58.249220001],
  [-77.5347599, 58.248690001],
  [-77.5340601, 58.245630101],
  [-77.5349, 58.243820001],
  [-77.5381901, 58.242220001],
  [-77.53834, 58.240940101],
  [-77.5395299, 58.240660001],
  [-77.53924, 58.240150001],
  [-77.5411901, 58.240110001],
  [-77.5417301, 58.237290101],
  [-77.55024, 58.234299901],
  [-77.54816, 58.232290101],
  [-77.5487401, 58.230230001],
  [-77.55213, 58.230160101],
  [-77.55249, 58.228360001],
  [-77.5515201, 58.228380101],
  [-77.54957, 58.224840101],
  [-77.55312, 58.223610101],
  [-77.5667899, 58.224749901],
  [-77.56683, 58.225260001],
  [-77.57113, 58.224280101],
  [-77.58283, 58.225060101],
  [-77.58336, 58.225690001],
  [-77.5865501, 58.226140001],
  [-77.58597, 58.228199901],
  [-77.58694, 58.228179901],
  [-77.58804, 58.229950101],
  [-77.59671, 58.229010001],
  [-77.59582, 58.230050101],
  [-77.6002499, 58.231110001],
  [-77.6022101, 58.231199901],
  [-77.6016701, 58.230440001],
  [-77.6036, 58.230280101],
  [-77.60415, 58.231160101],
  [-77.60561, 58.231140001],
  [-77.6052201, 58.232419901],
  [-77.6042499, 58.232450001],
  [-77.60384, 58.233470001],
  [-77.6063919, 58.234114801],
  [-77.6033932, 58.235572101],
  [-77.5984431, 58.234797801],
  [-77.5957435, 58.235442101],
  [-77.5909231, 58.234124001],
  [-77.5930184, 58.233225201],
  [-77.5904918, 58.232485301],
  [-77.5832831, 58.236348301],
  [-77.5825954, 58.237111101],
  [-77.5833464, 58.237800101],
  [-77.5830241, 58.238391201],
  [-77.5783628, 58.240629401],
  [-77.584771, 58.239448801],
  [-77.5929952, 58.239081501],
  [-77.596294, 58.240733801],
  [-77.5986487, 58.240341601],
  [-77.6059492, 58.243062801],
  [-77.60541, 58.244960101],
  [-77.60863, 58.245800001],
  [-77.6105701, 58.245759901],
  [-77.61221, 58.241749901],
  [-77.6136899, 58.241979901],
  [-77.61306, 58.243270001],
  [-77.61383, 58.243900001],
  [-77.61964, 58.243660001],
  [-77.6202401, 58.245170101],
  [-77.61874, 58.244690001],
  [-77.61929, 58.245450001],
  [-77.61785, 58.245740101],
  [-77.61888, 58.246480001],
  [-77.61884, 58.245969901],
  [-77.62363, 58.245099901],
  [-77.6256701, 58.246340001],
  [-77.62652, 58.244790001],
  [-77.62917, 58.244480001],
  [-77.62863, 58.247050001],
  [-77.6266901, 58.247089901],
  [-77.62653, 58.248120001],
  [-77.62783, 58.249370001],
  [-77.62929, 58.249340001],
  [-77.6293699, 58.250620101],
  [-77.6270101, 58.251440001],
  [-77.6299299, 58.251380001],
  [-77.62997, 58.251890001],
  [-77.6319399, 58.252360001],
  [-77.62864, 58.253710001],
  [-77.63019, 58.254960101],
  [-77.63217, 58.255429901],
  [-77.6324701, 58.256190101],
  [-77.6361401, 58.256630101],
  [-77.63655, 58.255600001],
  [-77.6355801, 58.255610001],
  [-77.63543, 58.253570001],
  [-77.63733, 58.253020001],
  [-77.6373699, 58.253529901],
  [-77.64032, 58.253979901],
  [-77.6404, 58.255000001],
  [-77.64233, 58.254830001],
  [-77.64241, 58.255989901],
  [-77.64098, 58.256270001],
  [-77.63941, 58.258099901],
  [-77.64076, 58.259860101],
  [-77.64221, 58.259830001],
  [-77.64374, 58.260700001],
  [-77.64664, 58.260510101],
  [-77.64553, 58.258740101],
  [-77.6438001, 58.258260001],
  [-77.64531, 58.255800001],
  [-77.65096, 58.256580001],
  [-77.65076, 58.257089901],
  [-77.6517299, 58.257070101],
  [-77.6504801, 58.259920001],
  [-77.65145, 58.259900001],
  [-77.64998, 58.262999901],
  [-77.65478, 58.265460001],
  [-77.6548199, 58.265970101],
  [-77.6631699, 58.267080101],
  [-77.66324, 58.268099901],
  [-77.6686299, 58.268630101],
  [-77.6690499, 58.267730101],
  [-77.67242, 58.267400101],
  [-77.67177, 58.268439901],
  [-77.67353, 58.269170001],
  [-77.67357, 58.269680001],
  [-77.6726001, 58.269700001],
  [-77.6731799, 58.270970101],
  [-77.6741501, 58.270950001],
  [-77.6732, 58.271219901],
  [-77.6732601, 58.271989901],
  [-77.67422, 58.271970101],
  [-77.6743801, 58.274020001],
  [-77.6756099, 58.274250001],
  [-77.67744, 58.275740101],
  [-77.6841699, 58.274840001],
  [-77.68412, 58.274319901],
  [-77.6870501, 58.274390001],
  [-77.6874901, 58.273740101],
  [-77.6913099, 58.272889901],
  [-77.69135, 58.273400101],
  [-77.69448, 58.273080101],
  [-77.6941999, 58.272580001],
  [-77.6961999, 58.273300101],
  [-77.69477, 58.273590001],
  [-77.6958499, 58.275099901],
  [-77.6972899, 58.274820001],
  [-77.697, 58.275160001],
  [-77.69889, 58.276700001],
  [-77.70132, 58.276520101],
  [-77.70292, 58.278539901],
  [-77.7062901, 58.278080101],
  [-77.7086201, 58.276750101],
  [-77.71056, 58.276700001],
  [-77.71577, 58.274940001],
  [-77.71581, 58.275449901],
  [-77.71969, 58.275360001],
  [-77.71981, 58.276900001],
  [-77.72471, 58.277429901],
  [-77.72581, 58.279080101],
  [-77.7292199, 58.279130001],
  [-77.7320701, 58.278300101],
  [-77.7333199, 58.278659901],
  [-77.7346301, 58.279920001],
  [-77.7376, 58.280620001],
  [-77.7374001, 58.281130001],
  [-77.7392001, 58.282500001],
  [-77.7411801, 58.282840001],
  [-77.7444299, 58.280850101],
  [-77.74781, 58.280650101],
  [-77.74824, 58.282950001],
  [-77.75392, 58.284109901],
  [-77.75648, 58.285840001],
  [-77.7618, 58.285470001],
  [-77.7616599, 58.286760101],
  [-77.76291, 58.287109901],
  [-77.76383, 58.286580001],
  [-77.76894, 58.286600001],
  [-77.7687799, 58.287620001],
  [-77.76781, 58.287650101],
  [-77.7678701, 58.288420101],
  [-77.76269, 58.290580001],
  [-77.7630099, 58.291590001],
  [-77.7657701, 58.292690001],
  [-77.7716501, 58.293200101],
  [-77.7716899, 58.293710001],
  [-77.76974, 58.293750101],
  [-77.77186, 58.295880101],
  [-77.78161, 58.296180001],
  [-77.78393, 58.297800001],
  [-77.7843201, 58.299720001],
  [-77.7859901, 58.299290001],
  [-77.78639, 58.298250001],
  [-77.7880299, 58.297449901],
  [-77.78909, 58.298580001],
  [-77.7903201, 58.298679901],
  [-77.7906999, 58.300470001],
  [-77.7893, 58.301140001],
  [-77.78637, 58.301080001],
  [-77.7857999, 58.303140001],
  [-77.78706, 58.303620001],
  [-77.78674, 58.302600001],
  [-77.78985, 58.301889901],
  [-77.79276, 58.301700001],
  [-77.79283, 58.302730001],
  [-77.7962399, 58.302909901],
  [-77.7962101, 58.302400001],
  [-77.7952201, 58.302420101],
  [-77.79561, 58.300999901],
  [-77.79908, 58.301820001],
  [-77.7994599, 58.300530101],
  [-77.80388, 58.300950001],
  [-77.80394, 58.301720001],
  [-77.8049099, 58.301689901],
  [-77.80485, 58.303999901],
  [-77.8089001, 58.305960001],
  [-77.8102499, 58.307730001],
  [-77.8112201, 58.307700001],
  [-77.8116701, 58.310249901],
  [-77.81244, 58.310750001],
  [-77.8114699, 58.310770101],
  [-77.81109, 58.312320101],
  [-77.81207, 58.312290001],
  [-77.8121101, 58.312810001],
  [-77.8155601, 58.313239901],
  [-77.8154601, 58.312220101],
  [-77.81643, 58.311950001],
  [-77.8154501, 58.311960001],
  [-77.81677, 58.310139901],
  [-77.8182499, 58.310370001],
  [-77.81821, 58.309850001],
  [-77.8196999, 58.310330101],
  [-77.81873, 58.310349901],
  [-77.81906, 58.311370001],
  [-77.82228, 58.312070001],
  [-77.82264, 58.310520001],
  [-77.82663, 58.308640001],
  [-77.8268099, 58.307860001],
  [-77.82733, 58.308370001],
  [-77.8302501, 58.308310101],
  [-77.83033, 58.309330101],
  [-77.82887, 58.309359901],
  [-77.82752, 58.310669901],
  [-77.82655, 58.310689901],
  [-77.82622, 58.312750001],
  [-77.8276801, 58.312720001],
  [-77.8273, 58.314009901],
  [-77.83555, 58.313700001],
  [-77.83744, 58.313009901],
  [-77.8383, 58.311590001],
  [-77.84023, 58.311420001],
  [-77.8433999, 58.314550101],
  [-77.84531, 58.314249901],
  [-77.84977, 58.315040001],
  [-77.8503601, 58.316320101],
  [-77.8493999, 58.316330101],
  [-77.8495099, 58.317870001],
  [-77.8507101, 58.317590001],
  [-77.85275, 58.315750001],
  [-77.8545099, 58.316480001],
  [-77.8556199, 58.318239901],
  [-77.85544, 58.319019901],
  [-77.85987, 58.319689901],
  [-77.8601799, 58.320450101],
  [-77.8629, 58.320899901],
  [-77.8629301, 58.321400001],
  [-77.8657101, 58.321520001],
  [-77.86565, 58.318780101],
  [-77.8676001, 58.318740001],
  [-77.8679, 58.319510001],
  [-77.86942, 58.320249901],
  [-77.8766299, 58.322000101],
  [-77.8779401, 58.323120101],
  [-77.8801501, 58.323579901],
  [-77.8733901, 58.324249901],
  [-77.8726299, 58.326830001],
  [-77.8736001, 58.326809901],
  [-77.8727299, 58.328100101],
  [-77.8672526, 58.329884101],
  [-77.8685199, 58.330249901],
  [-77.87387, 58.330129901],
  [-77.873, 58.328359901],
  [-77.8746399, 58.327679901],
  [-77.8780601, 58.327730001],
  [-77.8788499, 58.328479901],
  [-77.8792601, 58.330520001],
  [-77.8807101, 58.330490001],
  [-77.88076, 58.331000101],
  [-77.8870899, 58.330980001],
  [-77.8915299, 58.331780101],
  [-77.89148, 58.331010101],
  [-77.8926299, 58.330469901],
  [-77.89811, 58.328809901],
  [-77.89823, 58.327270001],
  [-77.89988, 58.326720001],
  [-77.9017301, 58.328479901],
  [-77.90157, 58.329500001],
  [-77.9030501, 58.329730001],
  [-77.9039401, 58.328680101],
  [-77.9064101, 58.329139901],
  [-77.9040701, 58.330210001],
  [-77.90457, 58.330589901],
  [-77.90947, 58.330860001],
  [-77.9087, 58.331220101],
  [-77.9094001, 58.332919901],
  [-77.9083099, 58.334469901],
  [-77.9092801, 58.334450101],
  [-77.90935, 58.335220101],
  [-77.90836, 58.335249901],
  [-77.9090601, 58.337660101],
  [-77.93203, 58.338670101],
  [-77.93873, 58.337230101],
  [-77.94703, 58.337420001],
  [-77.94707, 58.337940001],
  [-77.9584599, 58.340110101],
  [-77.9637899, 58.339860001],
  [-77.9639801, 58.342160001],
  [-77.9625401, 58.342190001],
  [-77.9618999, 58.343489901],
  [-77.96319, 58.344350101],
  [-77.97539, 58.344579901],
  [-77.97675, 58.343390001],
  [-77.97904, 58.347440001],
  [-77.9800101, 58.347290001],
  [-77.98037, 58.348690101],
  [-77.9878801, 58.348259901],
  [-77.98875, 58.349780101],
  [-77.98783, 58.350570101],
  [-77.9889099, 58.351819901],
  [-77.99317, 58.353130101],
  [-77.99855, 58.353390001],
  [-78.0000001, 58.355680101],
  [-78.0015601, 58.356340101],
  [-78.01209, 58.358510001],
  [-78.0120499, 58.359020101],
  [-78.0144799, 58.359070001],
  [-78.01395, 58.359570101],
  [-78.01775, 58.360690101],
  [-78.0177099, 58.361200001],
  [-78.02062, 58.361389901],
  [-78.0243899, 58.362890001],
  [-78.02443, 58.362379901],
  [-78.02541, 58.362400001],
  [-78.02534, 58.363169901],
  [-78.0263, 58.363190001],
  [-78.0259001, 58.365230001],
  [-78.0265701, 58.365890001],
  [-78.0280499, 58.365790101],
  [-78.0274801, 58.366810101],
  [-78.0299, 58.366860001],
  [-78.0309399, 58.366120001],
  [-78.0340401, 58.366960001],
  [-78.0351301, 58.368520001],
  [-78.03367, 58.368489901],
  [-78.0320599, 58.370240101],
  [-78.03109, 58.370220001],
  [-78.0312401, 58.371250101],
  [-78.0348201, 58.372220001],
  [-78.0365801, 58.374440001],
  [-78.0423801, 58.375090001],
  [-78.04233, 58.375599901],
  [-78.04666, 58.376340001],
  [-78.0491, 58.376269901],
  [-78.0491601, 58.375499901],
  [-78.056, 58.375399901],
  [-78.05687, 58.376709901],
  [-78.0559, 58.376680001],
  [-78.05654, 58.377719901],
  [-78.06445, 58.379179901],
  [-78.06442, 58.379700101],
  [-78.06801, 58.380550001],
  [-78.06903, 58.382880001],
  [-78.0670699, 58.383090001],
  [-78.06711, 58.382570001],
  [-78.06421, 58.382250101],
  [-78.06465, 58.382770001],
  [-78.06167, 58.383470101],
  [-78.06142, 58.389360101],
  [-78.06209, 58.390130001],
  [-78.0688201, 58.391320001],
  [-78.06814, 58.393609901],
  [-78.06133, 58.393460001],
  [-78.0616101, 58.392949901],
  [-78.0604801, 58.391900001],
  [-78.0527299, 58.391210001],
  [-78.05278, 58.390700101],
  [-78.05134, 58.390410001],
  [-78.05142, 58.389389901],
  [-78.0465099, 58.389790001],
  [-78.0464701, 58.390300001],
  [-78.04302, 58.390729901],
  [-78.04297, 58.391240001],
  [-78.0410499, 58.390939901],
  [-78.0410201, 58.391440001],
  [-78.03754, 58.392010001],
  [-78.00799, 58.389660001],
  [-78.00795, 58.390169901],
  [-78.0035999, 58.389810101],
  [-78.00363, 58.389300001],
  [-77.9986899, 58.389499901],
  [-77.99827, 58.390289901],
  [-77.9949001, 58.390870001],
  [-77.99783, 58.390930101],
  [-78.00018, 58.389729901],
  [-78.01265, 58.392200001],
  [-78.0170201, 58.392430001],
  [-78.01745, 58.393080001],
  [-78.0208299, 58.393540001],
  [-78.02167, 58.395100001],
  [-78.02265, 58.395120001],
  [-78.0225801, 58.395890001],
  [-78.0235801, 58.395660001],
  [-78.0224999, 58.396910001],
  [-78.0234701, 58.396940101],
  [-78.0233601, 58.398210001],
  [-78.0267299, 58.398800001],
  [-78.02664, 58.399830101],
  [-78.0300301, 58.400160101],
  [-78.03007, 58.399650001],
  [-78.0330301, 58.399330001],
  [-78.0365099, 58.401590101],
  [-78.0343601, 58.403849901],
  [-78.03453, 58.404619901],
  [-78.04132, 58.405160101],
  [-78.04309, 58.407380101],
  [-78.0488201, 58.408790001],
  [-78.0513801, 58.410390101],
  [-78.0510699, 58.411150101],
  [-78.0520401, 58.411170101],
  [-78.046, 58.413340001],
  [-78.04577, 58.416150001],
  [-78.04675, 58.416040101],
  [-78.04743, 58.419770001],
  [-78.0483899, 58.419800001],
  [-78.04791, 58.422600101],
  [-78.0505099, 58.423690001],
  [-78.05042, 58.424710001],
  [-78.0513999, 58.424730101],
  [-78.0513601, 58.425240001],
  [-78.0542999, 58.425050101],
  [-78.0561, 58.426890001],
  [-78.06102, 58.426360001],
  [-78.06156, 58.425730101],
  [-78.067687, 58.425733201],
  [-78.0686101, 58.426935101],
  [-78.0724367, 58.429278101],
  [-78.0741225, 58.429117901],
  [-78.0733229, 58.429920601],
  [-78.0759847, 58.431141701],
  [-78.0829112, 58.429867801],
  [-78.0912426, 58.430656801],
  [-78.0947691, 58.429546201],
  [-78.0959488, 58.428816801],
  [-78.0960021, 58.428117701],
  [-78.0991493, 58.427210701],
  [-78.1021091, 58.426945601],
  [-78.103008, 58.427494101],
  [-78.0972011, 58.429912501],
  [-78.0943148, 58.429930301],
  [-78.0900803, 58.432229201],
  [-78.0900242, 58.433529701],
  [-78.0946422, 58.434141301],
  [-78.095, 58.434364301],
  [-78.094267, 58.435109701],
  [-78.0980763, 58.435911401],
  [-78.0987743, 58.437233601],
  [-78.0971134, 58.437942101],
  [-78.090951, 58.438093901],
  [-78.088786, 58.439036001],
  [-78.0877419, 58.440415201],
  [-78.0893599, 58.443893701],
  [-78.0889672, 58.444439401],
  [-78.090781, 58.445472101],
  [-78.0923382, 58.447385001],
  [-78.0919163, 58.448022001],
  [-78.0934899, 58.450815901],
  [-78.0931648, 58.452985501],
  [-78.0982007, 58.457008801],
  [-78.0979238, 58.457752501],
  [-78.0947992, 58.458839001],
  [-78.0956202, 58.460012401],
  [-78.0968048, 58.460047601],
  [-78.0961718, 58.461055801],
  [-78.0965348, 58.461585101],
  [-78.0998874, 58.459446201],
  [-78.1007066, 58.458149401],
  [-78.1002826, 58.455768801],
  [-78.1011241, 58.454923301],
  [-78.1007808, 58.452555101],
  [-78.1065499, 58.450692301],
  [-78.1068053, 58.450312201],
  [-78.1063907, 58.449058801],
  [-78.1059958, 58.448994501],
  [-78.1063683, 58.449278601],
  [-78.1047968, 58.451014401],
  [-78.1043306, 58.448412101],
  [-78.1058425, 58.446885601],
  [-78.1074023, 58.446477701],
  [-78.1102427, 58.446496501],
  [-78.1106958, 58.447343301],
  [-78.1131053, 58.447958401],
  [-78.1145951, 58.446953501],
  [-78.1149957, 58.447447501],
  [-78.1137117, 58.448173301],
  [-78.1150388, 58.449266801],
  [-78.1204206, 58.449794601],
  [-78.1234975, 58.451063801],
  [-78.1268809, 58.450371001],
  [-78.1365351, 58.450804001],
  [-78.1362411, 58.452422101],
  [-78.1363698, 58.450909201],
  [-78.1350448, 58.450889601],
  [-78.1351575, 58.451487401],
  [-78.1341777, 58.451935101],
  [-78.1354525, 58.452099301],
  [-78.1316919, 58.452689101],
  [-78.1320481, 58.453273801],
  [-78.1363166, 58.453433301],
  [-78.1368011, 58.454256701],
  [-78.1364246, 58.455616501],
  [-78.1409214, 58.455482801],
  [-78.1456622, 58.458678201],
  [-78.1482218, 58.457781701],
  [-78.1463685, 58.455484101],
  [-78.1475617, 58.455290901],
  [-78.1468619, 58.452947301],
  [-78.1400566, 58.451559001],
  [-78.1419582, 58.450349401],
  [-78.1488022, 58.448811501],
  [-78.1489347, 58.448133201],
  [-78.1474632, 58.447914401],
  [-78.1432803, 58.448006901],
  [-78.1447333, 58.446924401],
  [-78.1484772, 58.446483001],
  [-78.1487282, 58.446948401],
  [-78.1546429, 58.447627201],
  [-78.1541571, 58.448178801],
  [-78.1566352, 58.448846201],
  [-78.1599613, 58.448402301],
  [-78.1617516, 58.448991001],
  [-78.1653808, 58.448313101],
  [-78.1675252, 58.449696701],
  [-78.1712506, 58.450206801],
  [-78.1763002, 58.449634801],
  [-78.1789913, 58.451124401],
  [-78.1789419, 58.452454101],
  [-78.1800917, 58.452731901],
  [-78.1795066, 58.453441401],
  [-78.1788356, 58.453823501],
  [-78.1784721, 58.453046301],
  [-78.1770804, 58.453765301],
  [-78.1770564, 58.453036301],
  [-78.1778781, 58.452503601],
  [-78.1771974, 58.451423601],
  [-78.1738765, 58.451610901],
  [-78.1727356, 58.452126801],
  [-78.1726513, 58.452783201],
  [-78.1713849, 58.452660701],
  [-78.1698609, 58.453494601],
  [-78.1697946, 58.454010301],
  [-78.1680559, 58.453286001],
  [-78.1626973, 58.454911901],
  [-78.162711, 58.455906901],
  [-78.1620411, 58.456251001],
  [-78.1630052, 58.456817601],
  [-78.1609286, 58.457133701],
  [-78.1599999, 58.458119001],
  [-78.1692467, 58.458277101],
  [-78.1696985, 58.458742301],
  [-78.1672233, 58.459336101],
  [-78.1681894, 58.462013201],
  [-78.1702628, 58.462584501],
  [-78.1717597, 58.461804001],
  [-78.1714967, 58.463017501],
  [-78.1733941, 58.463048201],
  [-78.1741919, 58.464536401],
  [-78.175896, 58.464835501],
  [-78.1783029, 58.466771701],
  [-78.1806781, 58.467041801],
  [-78.1821095, 58.468715301],
  [-78.1846293, 58.469235001],
  [-78.1850574, 58.468159501],
  [-78.1889532, 58.467814501],
  [-78.1935741, 58.468288201],
  [-78.1991527, 58.469766201],
  [-78.199576, 58.470675901],
  [-78.1987017, 58.471946001],
  [-78.1957811, 58.472802001],
  [-78.1958969, 58.473601301],
  [-78.2014419, 58.473804501],
  [-78.2034169, 58.474886301],
  [-78.1984184, 58.474789201],
  [-78.1943905, 58.476271201],
  [-78.1940868, 58.476753301],
  [-78.1948536, 58.478303701],
  [-78.1978125, 58.479563101],
  [-78.2027926, 58.478779101],
  [-78.2046827, 58.479037201],
  [-78.200441, 58.480756601],
  [-78.1996363, 58.481750101],
  [-78.2007233, 58.482610601],
  [-78.2096526, 58.483486401],
  [-78.2107143, 58.482119501],
  [-78.211774, 58.481906001],
  [-78.2149355, 58.482654801],
  [-78.2152671, 58.483630101],
  [-78.2127998, 58.485032201],
  [-78.2129279, 58.485791001],
  [-78.2155286, 58.487176701],
  [-78.2129185, 58.489179801],
  [-78.2139148, 58.490237301],
  [-78.2225034, 58.490284201],
  [-78.2335001, 58.493073201],
  [-78.2414391, 58.494141501],
  [-78.2402537, 58.494462901],
  [-78.2413786, 58.495602801],
  [-78.2367405, 58.496196701],
  [-78.2352978, 58.497390301],
  [-78.2349653, 58.498367901],
  [-78.2360652, 58.498895701],
  [-78.2335067, 58.498822801],
  [-78.2293089, 58.499897001],
  [-78.2244453, 58.498470501],
  [-78.2224567, 58.499626101],
  [-78.2280993, 58.502310501],
  [-78.2260459, 58.502978701],
  [-78.2266543, 58.504708701],
  [-78.2308474, 58.508019701],
  [-78.2353657, 58.508310801],
  [-78.2426014, 58.507704101],
  [-78.247464, 58.508451101],
  [-78.249822, 58.506880301],
  [-78.2489793, 58.506141801],
  [-78.2511, 58.505740001],
  [-78.2549018, 58.508051801],
  [-78.2541597, 58.509130201],
  [-78.2508229, 58.510893601],
  [-78.2509556, 58.512136601],
  [-78.2494763, 58.512511001],
  [-78.2481799, 58.511830001],
  [-78.2401881, 58.513530001],
  [-78.2393475, 58.514359201],
  [-78.240406, 58.515426301],
  [-78.2378681, 58.517737301],
  [-78.2423761, 58.517989801],
  [-78.2461279, 58.520521401],
  [-78.2418234, 58.523134801],
  [-78.2428693, 58.524028201],
  [-78.2427551, 58.524786501],
  [-78.2444531, 58.524827001],
  [-78.2377583, 58.527809601],
  [-78.2378933, 58.528492301],
  [-78.2386843, 58.528605601],
  [-78.2347475, 58.530423301],
  [-78.2339872, 58.531325401],
  [-78.2339568, 58.532898401],
  [-78.2328302, 58.533352301],
  [-78.2322511, 58.535292101],
  [-78.22496, 58.536749301],
  [-78.2193438, 58.538694801],
  [-78.2184488, 58.540396601],
  [-78.2206037, 58.540882901],
  [-78.2199295, 58.542524701],
  [-78.2184044, 58.543075201],
  [-78.2170937, 58.542313901],
  [-78.2146065, 58.543589801],
  [-78.2137452, 58.547325601],
  [-78.2172443, 58.546387801],
  [-78.2184784, 58.546703401],
  [-78.21882, 58.548335001],
  [-78.2182121, 58.549999201],
  [-78.2145032, 58.551062401],
  [-78.2150733, 58.551605901],
  [-78.2143037, 58.552507801],
  [-78.2111401, 58.553030101],
  [-78.2117692, 58.553984001],
  [-78.2093982, 58.557810001],
  [-78.2113437, 58.558924301],
  [-78.2123728, 58.558775301],
  [-78.2101888, 58.558028601],
  [-78.2100503, 58.557415801],
  [-78.2122104, 58.556225801],
  [-78.2124707, 58.555259101],
  [-78.2158568, 58.553739401],
  [-78.215377, 58.553434101],
  [-78.2174276, 58.552521901],
  [-78.2183611, 58.553053001],
  [-78.2196886, 58.551133801],
  [-78.2210574, 58.550757101],
  [-78.220875, 58.549282901],
  [-78.2220142, 58.546877901],
  [-78.2245606, 58.544154601],
  [-78.2265733, 58.543484001],
  [-78.2275318, 58.543884801],
  [-78.2287089, 58.542243801],
  [-78.2286779, 58.541844701],
  [-78.2270348, 58.542945801],
  [-78.2247235, 58.543217901],
  [-78.2247906, 58.541699201],
  [-78.2224015, 58.541830801],
  [-78.2293902, 58.539076401],
  [-78.2299088, 58.538219601],
  [-78.2312426, 58.538244401],
  [-78.230377, 58.539592801],
  [-78.2343406, 58.538168901],
  [-78.2357481, 58.536526701],
  [-78.2377256, 58.535984801],
  [-78.2379909, 58.535290801],
  [-78.237288, 58.534538701],
  [-78.2452946, 58.534090001],
  [-78.2454339, 58.533607701],
  [-78.24164, 58.532819901],
  [-78.2401136, 58.531395301],
  [-78.240566, 58.528502801],
  [-78.2433176, 58.527841201],
  [-78.2435855, 58.527005601],
  [-78.2508556, 58.524632101],
  [-78.2576572, 58.524849001],
  [-78.2608297, 58.525920101],
  [-78.2665883, 58.526657701],
  [-78.2726896, 58.526349301],
  [-78.2719349, 58.527544301],
  [-78.2753564, 58.529005201],
  [-78.2874792, 58.531480801],
  [-78.2895566, 58.530890801],
  [-78.288953, 58.530660801],
  [-78.289499, 58.529744601],
  [-78.2879173, 58.528854901],
  [-78.2898016, 58.526396701],
  [-78.293087, 58.524435201],
  [-78.297164, 58.523834101],
  [-78.2975045, 58.523234501],
  [-78.291523, 58.521948201],
  [-78.2886005, 58.522391101],
  [-78.2877833, 58.523315701],
  [-78.2853477, 58.523839601],
  [-78.2771183, 58.522925801],
  [-78.2737305, 58.521664201],
  [-78.2685676, 58.521308601],
  [-78.2678743, 58.518669201],
  [-78.2667788, 58.517699901],
  [-78.266986, 58.516660401],
  [-78.2662664, 58.516018301],
  [-78.2622407, 58.515265001],
  [-78.2596836, 58.515719701],
  [-78.2577302, 58.514791901],
  [-78.2587983, 58.513539701],
  [-78.2589372, 58.512331401],
  [-78.2654095, 58.512520401],
  [-78.2684571, 58.512267101],
  [-78.2699015, 58.511425101],
  [-78.2726551, 58.512086301],
  [-78.2746173, 58.511558001],
  [-78.2781873, 58.511881201],
  [-78.2798842, 58.510894501],
  [-78.282349, 58.511225001],
  [-78.2855306, 58.510029901],
  [-78.285909, 58.508971701],
  [-78.288199, 58.508483101],
  [-78.2918713, 58.509025501],
  [-78.2955287, 58.511389001],
  [-78.3020472, 58.511888501],
  [-78.3022259, 58.511307001],
  [-78.303537, 58.511387301],
  [-78.304701, 58.512523601],
  [-78.3083854, 58.512740301],
  [-78.3091062, 58.514336301],
  [-78.3108666, 58.514989501],
  [-78.3112831, 58.515775101],
  [-78.3124313, 58.515692801],
  [-78.3135153, 58.517723701],
  [-78.3148843, 58.518333101],
  [-78.316044, 58.521299301],
  [-78.3132217, 58.520691701],
  [-78.3144798, 58.520793401],
  [-78.3140681, 58.520187801],
  [-78.311511, 58.519935801],
  [-78.3095872, 58.521551801],
  [-78.3074997, 58.521995901],
  [-78.3076789, 58.523329601],
  [-78.3068004, 58.523928601],
  [-78.3045535, 58.524248301],
  [-78.3050404, 58.525294301],
  [-78.3097699, 58.525689901],
  [-78.310935, 58.526622301],
  [-78.3098083, 58.526868801],
  [-78.3098286, 58.527491301],
  [-78.3157231, 58.528334101],
  [-78.3147448, 58.529784401],
  [-78.3131296, 58.530005601],
  [-78.3121725, 58.533429901],
  [-78.3135406, 58.534564001],
  [-78.3113432, 58.535985201],
  [-78.3122489, 58.536666801],
  [-78.314586, 58.535769101],
  [-78.3146736, 58.536373701],
  [-78.3177861, 58.536639301],
  [-78.3194105, 58.538234501],
  [-78.3217509, 58.539253201],
  [-78.3263324, 58.540010401],
  [-78.3267025, 58.541651201],
  [-78.3285347, 58.542557001],
  [-78.3277501, 58.543096701],
  [-78.3287535, 58.543398101],
  [-78.3292068, 58.545546401],
  [-78.3253451, 58.545303301],
  [-78.3215257, 58.546359201],
  [-78.3201839, 58.547666301],
  [-78.3221812, 58.551324701],
  [-78.3246228, 58.553977001],
  [-78.3227, 58.555720801],
  [-78.3224362, 58.556742301],
  [-78.3279306, 58.559272501],
  [-78.3274853, 58.561396001],
  [-78.332327, 58.561045701],
  [-78.3306195, 58.554568601],
  [-78.3325702, 58.553808701],
  [-78.3332055, 58.552512701],
  [-78.3342564, 58.552177601],
  [-78.3432462, 58.553727701],
  [-78.34085, 58.556103301],
  [-78.3436089, 58.559075201],
  [-78.3448733, 58.559633801],
  [-78.345564, 58.561184101],
  [-78.3484208, 58.563055201],
  [-78.349202, 58.565231701],
  [-78.35173, 58.566509901],
  [-78.35304, 58.566450001],
  [-78.3527999, 58.565250101],
  [-78.3562499, 58.565059901],
  [-78.3566601, 58.566100001],
  [-78.3542, 58.566169901],
  [-78.35316, 58.567059901],
  [-78.35609, 58.567110001],
  [-78.35651, 58.568140101],
  [-78.3535501, 58.568340001],
  [-78.3534699, 58.569360101],
  [-78.3499901, 58.570059901],
  [-78.34918, 58.571069901],
  [-78.3513399, 58.571619901],
  [-78.3504501, 58.573650001],
  [-78.35126, 58.575970001],
  [-78.3536501, 58.576790001],
  [-78.3537601, 58.578590101],
  [-78.3596, 58.578959901],
  [-78.35954, 58.579990001],
  [-78.36243, 58.580680001],
  [-78.3687701, 58.581069901],
  [-78.3693401, 58.583260101],
  [-78.3685501, 58.584010001],
  [-78.3675701, 58.583990001],
  [-78.3670199, 58.584750001],
  [-78.36506, 58.584710101],
  [-78.3635, 58.585959901],
  [-78.3591101, 58.585609901],
  [-78.3585601, 58.586370101],
  [-78.36593, 58.586260101],
  [-78.3670001, 58.588330001],
  [-78.36819, 58.588610101],
  [-78.3695, 58.590949901],
  [-78.37049, 58.590839901],
  [-78.37228, 58.593310001],
  [-78.37129, 58.593289901],
  [-78.3695, 58.597350001],
  [-78.37149, 58.597130001],
  [-78.37051, 58.597110001],
  [-78.37309, 58.595370101],
  [-78.3721101, 58.595350001],
  [-78.3726999, 58.594079901],
  [-78.3754699, 58.593969901],
  [-78.37419, 58.593859901],
  [-78.3742301, 58.593350001],
  [-78.37816, 58.593169901],
  [-78.3781201, 58.593680001],
  [-78.38443, 58.594580001],
  [-78.3830001, 58.594040101],
  [-78.3818901, 58.592480101],
  [-78.3841499, 58.591760001],
  [-78.37683, 58.591350001],
  [-78.3773599, 58.590590101],
  [-78.3749299, 58.590540001],
  [-78.37463, 58.587980001],
  [-78.37316, 58.587949901],
  [-78.37239, 58.585100001],
  [-78.37806, 58.584719901],
  [-78.37776, 58.582150101],
  [-78.3763599, 58.581350001],
  [-78.37663, 58.580849901],
  [-78.3751499, 58.581069901],
  [-78.3747099, 58.580310001],
  [-78.3766899, 58.580080001],
  [-78.37665, 58.580600101],
  [-78.3795701, 58.580910001],
  [-78.37951, 58.581680001],
  [-78.38341, 58.582010001],
  [-78.3848199, 58.582810101],
  [-78.38495, 58.584600101],
  [-78.3875999, 58.585040101],
  [-78.3890101, 58.585710101],
  [-78.38798, 58.586460001],
  [-78.3927901, 58.587839901],
  [-78.39167, 58.589610101],
  [-78.39363, 58.589650001],
  [-78.3935899, 58.590160101],
  [-78.39011, 58.590849901],
  [-78.39007, 58.591360001],
  [-78.38911, 58.591090001],
  [-78.3885899, 58.591590101],
  [-78.39097, 58.592409901],
  [-78.3884701, 58.593130001],
  [-78.38841, 58.593890001],
  [-78.3869399, 58.593859901],
  [-78.3869001, 58.594370101],
  [-78.38788, 58.594390101],
  [-78.38677, 58.596160101],
  [-78.38176, 58.597600101],
  [-78.38119, 58.598610101],
  [-78.37874, 58.598560001],
  [-78.3787801, 58.598050101],
  [-78.3778199, 58.597780001],
  [-78.3777801, 58.598289901],
  [-78.3760299, 58.598760001],
  [-78.3757099, 58.601990001],
  [-78.3780201, 58.601619901],
  [-78.37809, 58.600600101],
  [-78.37906, 58.600870001],
  [-78.37964, 58.599600101],
  [-78.38597, 58.600240001],
  [-78.3853699, 58.601770001],
  [-78.38284, 58.602739901],
  [-78.3833, 58.603260001],
  [-78.38183, 58.603230001],
  [-78.3781499, 58.606490101],
  [-78.3746999, 58.606670001],
  [-78.37416, 58.607170101],
  [-78.37398, 58.606399901],
  [-78.3752301, 58.606170101],
  [-78.3753, 58.605150001],
  [-78.37432, 58.605130001],
  [-78.3753599, 58.602160101],
  [-78.37107, 58.602760001],
  [-78.36971, 58.604519901],
  [-78.3700299, 58.606830101],
  [-78.36821, 58.608079901],
  [-78.36672, 58.608299901],
  [-78.36661, 58.609840101],
  [-78.3651299, 58.609810001],
  [-78.3645699, 58.610820001],
  [-78.3615899, 58.611270101],
  [-78.36154, 58.612050101],
  [-78.3605501, 58.612020001],
  [-78.35897, 58.613519901],
  [-78.3545, 58.614199901],
  [-78.35342, 58.615460001],
  [-78.35142, 58.615930001],
  [-78.35097, 58.615409901],
  [-78.35195, 58.615429901],
  [-78.3520099, 58.614670001],
  [-78.3540099, 58.614189901],
  [-78.3528999, 58.612620101],
  [-78.3527899, 58.610830101],
  [-78.3558099, 58.609869901],
  [-78.3550199, 58.607289901],
  [-78.34871, 58.606399901],
  [-78.34816, 58.607150001],
  [-78.33985, 58.606730101],
  [-78.3393, 58.607480001],
  [-78.3373201, 58.607700001],
  [-78.33729, 58.608210001],
  [-78.33582, 58.608179901],
  [-78.3359999, 58.608950101],
  [-78.3352101, 58.609700001],
  [-78.33374, 58.609670001],
  [-78.33315, 58.610940101],
  [-78.3341299, 58.610959901],
  [-78.33394, 58.613519901],
  [-78.3329801, 58.613240001],
  [-78.33192, 58.614240001],
  [-78.3270801, 58.613370001],
  [-78.3265301, 58.614130001],
  [-78.3231001, 58.614060101],
  [-78.32235, 58.617370001],
  [-78.3233299, 58.617390101],
  [-78.32303, 58.618150001],
  [-78.32663, 58.619260001],
  [-78.32725, 58.617480001],
  [-78.32872, 58.617510101],
  [-78.32782, 58.616460001],
  [-78.3322199, 58.616680001],
  [-78.3331199, 58.617850101],
  [-78.3367501, 58.618440001],
  [-78.33615, 58.619969901],
  [-78.33662, 58.620230001],
  [-78.33617, 58.623040001],
  [-78.33714, 58.623060101],
  [-78.33671, 58.624500101],
  [-78.33759, 58.623580001],
  [-78.3395501, 58.623620101],
  [-78.33961, 58.622850101],
  [-78.34251, 58.623429901],
  [-78.3424499, 58.624189901],
  [-78.3449801, 58.623220001],
  [-78.3490099, 58.618690001],
  [-78.3507701, 58.618089901],
  [-78.35617, 58.618070101],
  [-78.3593401, 58.615070101],
  [-78.3603201, 58.615089901],
  [-78.36116, 58.613820001],
  [-78.3713599, 58.611979901],
  [-78.37405, 58.615370001],
  [-78.3750299, 58.615400101],
  [-78.37497, 58.616150001],
  [-78.37399, 58.616130001],
  [-78.37395, 58.616649901],
  [-78.3724799, 58.616620101],
  [-78.3723699, 58.618020001],
  [-78.3753401, 58.617830101],
  [-78.3774, 58.616460001],
  [-78.38655, 58.618950101],
  [-78.3857099, 58.620209901],
  [-78.3883401, 58.621160101],
  [-78.3895899, 58.620810001],
  [-78.3897099, 58.619270101],
  [-78.39452, 58.617319901],
  [-78.39354, 58.617299901],
  [-78.3931599, 58.615759901],
  [-78.3892401, 58.615690001],
  [-78.38907, 58.614649901],
  [-78.3923799, 58.613050101],
  [-78.39776, 58.613419901],
  [-78.39951, 58.612810001],
  [-78.3998199, 58.612050101],
  [-78.4037299, 58.612380101],
  [-78.4093701, 58.615570001],
  [-78.4099299, 58.614690001],
  [-78.41243, 58.614100001],
  [-78.41174, 58.613570001],
  [-78.4125999, 58.611800001],
  [-78.41407, 58.611820001],
  [-78.4141, 58.611309901],
  [-78.4238499, 58.612280101],
  [-78.42389, 58.611770001],
  [-78.4258499, 58.611810001],
  [-78.42512, 58.614859901],
  [-78.4268101, 58.615409901],
  [-78.42645, 58.616940101],
  [-78.423, 58.617130001],
  [-78.42308, 58.616100001],
  [-78.4201501, 58.615790001],
  [-78.42019, 58.615280101],
  [-78.4152501, 58.615690001],
  [-78.4151799, 58.616710001],
  [-78.4171699, 58.616370001],
  [-78.42202, 58.617110001],
  [-78.42198, 58.617620101],
  [-78.4229599, 58.617639901],
  [-78.42311, 58.618920001],
  [-78.4273, 58.618749901],
  [-78.4282, 58.619790001],
  [-78.4282601, 58.619020001],
  [-78.43169, 58.619089901],
  [-78.4316299, 58.619859901],
  [-78.43554, 58.620189901],
  [-78.4360699, 58.619560001],
  [-78.44098, 58.619660001],
  [-78.44265, 58.620350001],
  [-78.4428201, 58.621370001],
  [-78.4418301, 58.621340001],
  [-78.44178, 58.622110001],
  [-78.4362899, 58.623280101],
  [-78.4362601, 58.623790001],
  [-78.43424, 58.624520101],
  [-78.44114, 58.624020001],
  [-78.4427299, 58.622510101],
  [-78.4451899, 58.622429901],
  [-78.4451501, 58.622950101],
  [-78.44613, 58.622969901],
  [-78.4458301, 58.623730101],
  [-78.4504199, 58.624840101],
  [-78.450634, 58.626456901],
  [-78.45244, 58.627440001],
  [-78.45177, 58.629740101],
  [-78.4523999, 58.631280101],
  [-78.45337, 58.631300101],
  [-78.4526399, 58.634620101],
  [-78.4491601, 58.635319901],
  [-78.4531, 58.635140001],
  [-78.4530699, 58.635649901],
  [-78.45503, 58.635690001],
  [-78.4549301, 58.636970101],
  [-78.4578599, 58.637280001],
  [-78.45669, 58.636490001],
  [-78.45637, 58.634170101],
  [-78.4554099, 58.633649901],
  [-78.4550799, 58.631590001],
  [-78.4543801, 58.631070101],
  [-78.4558301, 58.631350001],
  [-78.45426, 58.629529901],
  [-78.45455, 58.628769901],
  [-78.45357, 58.628749901],
  [-78.4541899, 58.626969901],
  [-78.4571701, 58.626510101],
  [-78.45759, 58.627290101],
  [-78.45911, 58.626810001],
  [-78.4580601, 58.627810001],
  [-78.4614299, 58.628649901],
  [-78.4613801, 58.629410101],
  [-78.46236, 58.629429901],
  [-78.4637, 58.631250001],
  [-78.46468, 58.631270001],
  [-78.4636299, 58.632150001],
  [-78.4617, 58.631720001],
  [-78.4586101, 58.633710001],
  [-78.4635101, 58.633810001],
  [-78.46355, 58.633309901],
  [-78.47314, 58.636560001],
  [-78.47204, 58.634740101],
  [-78.4687201, 58.633140001],
  [-78.46966, 58.630340001],
  [-78.47291, 58.630580001],
  [-78.4728599, 58.630150001],
  [-78.4707499, 58.628830001],
  [-78.4676, 58.628260001],
  [-78.4674099, 58.627470001],
  [-78.46433, 58.625890001],
  [-78.46548, 58.623600001],
  [-78.46088, 58.622749901],
  [-78.46069, 58.621969901],
  [-78.45857, 58.620770001],
  [-78.45545, 58.623150001],
  [-78.44958, 58.622780001],
  [-78.44655, 58.617340001],
  [-78.44509, 58.617050101],
  [-78.44577, 58.614510101],
  [-78.4497301, 58.614070101],
  [-78.44933, 58.612790001],
  [-78.4528201, 58.612089901],
  [-78.4531601, 58.610810001],
  [-78.4515199, 58.609759901],
  [-78.4525101, 58.609519901],
  [-78.4486299, 58.608930001],
  [-78.44867, 58.608419901],
  [-78.4467499, 58.607869901],
  [-78.4450501, 58.604250001],
  [-78.4397101, 58.603380101],
  [-78.4396001, 58.604900001],
  [-78.43811, 58.605140001],
  [-78.4381501, 58.604610101],
  [-78.4352701, 58.603800001],
  [-78.4341401, 58.602500101],
  [-78.43478, 58.600460001],
  [-78.43386, 58.599419901],
  [-78.4341899, 58.598399901],
  [-78.4317399, 58.598350001],
  [-78.4322701, 58.597849901],
  [-78.4312899, 58.597830101],
  [-78.4307001, 58.599100001],
  [-78.4272799, 58.599030001],
  [-78.42645, 58.596959901],
  [-78.42743, 58.596980001],
  [-78.4261401, 58.594399901],
  [-78.4281, 58.594440001],
  [-78.42943, 58.596380101],
  [-78.43234, 58.596830101],
  [-78.4311899, 58.595780001],
  [-78.4309599, 58.592450001],
  [-78.42954, 58.591650001],
  [-78.4303999, 58.589870001],
  [-78.4372899, 58.589500101],
  [-78.43733, 58.588990001],
  [-78.4388399, 58.588509901],
  [-78.43788, 58.588230001],
  [-78.4406399, 58.588460001],
  [-78.44188, 58.587299901],
  [-78.44087, 58.587519901],
  [-78.44143, 58.586509901],
  [-78.4449401, 58.585550001],
  [-78.4439599, 58.585530001],
  [-78.4421601, 58.583190001],
  [-78.4431501, 58.583220001],
  [-78.44121, 58.582930101],
  [-78.43955, 58.585450001],
  [-78.43609, 58.585890001],
  [-78.43567, 58.584860001],
  [-78.4341799, 58.585080001],
  [-78.4328399, 58.583260101],
  [-78.43187, 58.583240001],
  [-78.4317399, 58.581710101],
  [-78.432861, 58.580123001],
  [-78.4361, 58.578980001],
  [-78.43802, 58.577310001],
  [-78.44214, 58.576920101],
  [-78.44747, 58.577790001],
  [-78.448, 58.577159901],
  [-78.44702, 58.577140101],
  [-78.44565, 58.575839901],
  [-78.43977, 58.575719901],
  [-78.43798, 58.573379901],
  [-78.437, 58.573360101],
  [-78.43764, 58.571320001],
  [-78.43331, 58.570210001],
  [-78.4309401, 58.565810101],
  [-78.42932, 58.564499901],
  [-78.4273999, 58.563949901],
  [-78.4273601, 58.564460101],
  [-78.4243701, 58.565169901],
  [-78.42441, 58.564660001],
  [-78.4194901, 58.564810101],
  [-78.41946, 58.565330001],
  [-78.4184801, 58.565310001],
  [-78.4180401, 58.564530001],
  [-78.41509, 58.564850001],
  [-78.41366, 58.564190001],
  [-78.4136199, 58.564700101],
  [-78.41264, 58.564680001],
  [-78.4108399, 58.565920101],
  [-78.41101, 58.566949901],
  [-78.41198, 58.566970001],
  [-78.41231, 58.569020101],
  [-78.4069, 58.569430001],
  [-78.4069399, 58.568920101],
  [-78.405, 58.568619901],
  [-78.4045701, 58.567839901],
  [-78.4021301, 58.567790001],
  [-78.4028001, 58.568580101],
  [-78.40144, 58.570340001],
  [-78.39406, 58.570700101],
  [-78.3946901, 58.568670001],
  [-78.39276, 58.568370101],
  [-78.3928099, 58.567860001],
  [-78.3883799, 58.568030101],
  [-78.3876801, 58.567499901],
  [-78.38808, 58.565460001],
  [-78.38955, 58.565489901],
  [-78.3892501, 58.562920101],
  [-78.38733, 58.562370101],
  [-78.3862699, 58.563370101],
  [-78.38332, 58.563570001],
  [-78.38311, 58.563049901],
  [-78.3839001, 58.562300001],
  [-78.38663, 58.561850001],
  [-78.38644, 58.561070001],
  [-78.3854701, 58.561049901],
  [-78.38553, 58.560290001],
  [-78.38262, 58.559719901],
  [-78.3822301, 58.558430001],
  [-78.3754401, 58.557520001],
  [-78.37372, 58.554159901],
  [-78.37275, 58.554140101],
  [-78.3702599, 58.548190001],
  [-78.37276, 58.547479901],
  [-78.37288, 58.545929901],
  [-78.36374, 58.543709901],
  [-78.3614401, 58.541610001],
  [-78.3604699, 58.541589901],
  [-78.36009, 58.540049901],
  [-78.3566701, 58.539980001],
  [-78.35675, 58.538939901],
  [-78.3557701, 58.538929901],
  [-78.3558099, 58.538420001],
  [-78.35483, 58.538400001],
  [-78.3534599, 58.537090001],
  [-78.3501001, 58.536130101],
  [-78.34763, 58.536460101],
  [-78.34729, 58.534410001],
  [-78.3463201, 58.534390001],
  [-78.34617, 58.533100001],
  [-78.34897, 58.531489901],
  [-78.34995, 58.531640001],
  [-78.3500099, 58.530880001],
  [-78.3582499, 58.531940001],
  [-78.35983, 58.530570101],
  [-78.36661, 58.531469901],
  [-78.36796, 58.533039901],
  [-78.36943, 58.533070001],
  [-78.36998, 58.534530001],
  [-78.3726801, 58.535440001],
  [-78.3727801, 58.534159901],
  [-78.37398, 58.534440001],
  [-78.3736801, 58.535200001],
  [-78.3751499, 58.535230101],
  [-78.3762401, 58.537049901],
  [-78.3779101, 58.537599901],
  [-78.3787801, 58.539020101],
  [-78.38074, 58.539060001],
  [-78.3823501, 58.537170001],
  [-78.3813699, 58.537149901],
  [-78.3811101, 58.534080001],
  [-78.38209, 58.534100001],
  [-78.38166, 58.533320001],
  [-78.3831201, 58.533350101],
  [-78.38308, 58.533860001],
  [-78.38641, 58.535210001],
  [-78.3864501, 58.534699901],
  [-78.38861, 58.535249901],
  [-78.39019, 58.537080001],
  [-78.39117, 58.537100001],
  [-78.3930101, 58.538670101],
  [-78.39452, 58.538190001],
  [-78.3949299, 58.539220001],
  [-78.39395, 58.539200001],
  [-78.39389, 58.539960001],
  [-78.39543, 58.539230101],
  [-78.4042599, 58.538900101],
  [-78.40418, 58.539919901],
  [-78.4104701, 58.540939901],
  [-78.41077, 58.540310001],
  [-78.41009, 58.539540001],
  [-78.4125099, 58.539829901],
  [-78.4129201, 58.540980001],
  [-78.4192501, 58.541510001],
  [-78.41932, 58.540479901],
  [-78.4222701, 58.540410001],
  [-78.4251601, 58.540980001],
  [-78.4264701, 58.543190001],
  [-78.42446, 58.543919901],
  [-78.4243701, 58.545190001],
  [-78.4263, 58.545489901],
  [-78.4269599, 58.546530001],
  [-78.43355, 58.546910101],
  [-78.43399, 58.547560001],
  [-78.43644, 58.547479901],
  [-78.4359201, 58.547990001],
  [-78.4402801, 58.548589901],
  [-78.4406199, 58.550640001],
  [-78.4382, 58.550340001],
  [-78.4383701, 58.551360101],
  [-78.44443, 58.552250101],
  [-78.44414, 58.552760001],
  [-78.4458, 58.556890001],
  [-78.44789, 58.558470101],
  [-78.45337, 58.560630001],
  [-78.4532999, 58.561650001],
  [-78.4545001, 58.561929901],
  [-78.45446, 58.562440001],
  [-78.4559699, 58.561960001],
  [-78.4564099, 58.562609901],
  [-78.46002, 58.563570001],
  [-78.4588401, 58.566370101],
  [-78.46004, 58.566650001],
  [-78.45944, 58.568169901],
  [-78.4628499, 58.568499901],
  [-78.4641601, 58.570700101],
  [-78.46534, 58.571360101],
  [-78.4708799, 58.572619901],
  [-78.47133, 58.573269901],
  [-78.4781701, 58.573530001],
  [-78.47933, 58.574450001],
  [-78.47905, 58.574959901],
  [-78.48003, 58.574980001],
  [-78.48069, 58.576020001],
  [-78.4798599, 58.577279901],
  [-78.47189, 58.579040101],
  [-78.46311, 58.578360101],
  [-78.4616201, 58.578710101],
  [-78.4612801, 58.579990001],
  [-78.4628301, 58.582320001],
  [-78.46381, 58.582330001],
  [-78.46455, 58.585680001],
  [-78.4655299, 58.585710101],
  [-78.46633, 58.588030101],
  [-78.46927, 58.588210001],
  [-78.47045, 58.588880001],
  [-78.4728501, 58.592890001],
  [-78.47491, 58.591519901],
  [-78.47346, 58.591240001],
  [-78.47503, 58.589859901],
  [-78.48005, 58.588299901],
  [-78.4819701, 58.588849901],
  [-78.4779699, 58.593380101],
  [-78.47756, 58.595670001],
  [-78.47658, 58.595650001],
  [-78.47626, 58.596670001],
  [-78.47714, 58.604880001],
  [-78.47892, 58.607480001],
  [-78.4786, 58.608500101],
  [-78.47958, 58.608519901],
  [-78.4806599, 58.610580001],
  [-78.4832901, 58.611409901],
  [-78.4867699, 58.617620101],
  [-78.49115, 58.618220001],
  [-78.49134, 58.618990001],
  [-78.49083, 58.619240001],
  [-78.49238, 58.621570001],
  [-78.4943101, 58.622120001],
  [-78.49495, 58.623409901],
  [-78.4930899, 58.625429901],
  [-78.4943001, 58.625710001],
  [-78.4939799, 58.626720101],
  [-78.4919999, 58.626940001],
  [-78.49213, 58.628480001],
  [-78.4946499, 58.631089901],
  [-78.50202, 58.631099901],
  [-78.5025301, 58.630730101],
  [-78.4996401, 58.629910001],
  [-78.49926, 58.628360001],
  [-78.4973199, 58.628070101],
  [-78.4971099, 58.627550001],
  [-78.4986401, 58.626810001],
  [-78.49825, 58.625270001],
  [-78.50051, 58.624550001],
  [-78.5005599, 58.624030001],
  [-78.5069701, 58.623520101],
  [-78.5077, 58.623660001],
  [-78.5067601, 58.626450001],
  [-78.5057799, 58.626440001],
  [-78.50595, 58.627470001],
  [-78.5046501, 58.628470001],
  [-78.5056301, 58.628490001],
  [-78.50603, 58.629769901],
  [-78.5075, 58.629800001],
  [-78.50768, 58.630820001],
  [-78.5088601, 58.631490001],
  [-78.5142199, 58.631979901],
  [-78.5165499, 58.633820001],
  [-78.52093, 58.634419901],
  [-78.5205401, 58.636460001],
  [-78.5343301, 58.639280001],
  [-78.5352399, 58.640319901],
  [-78.53938, 58.640920001],
  [-78.5395501, 58.641940001],
  [-78.54252, 58.641620101],
  [-78.54539, 58.642690001],
  [-78.55173, 58.643329901],
  [-78.5572499, 58.641640101],
  [-78.5655701, 58.642050001],
  [-78.56774, 58.642740101],
  [-78.56744, 58.643500001],
  [-78.5743, 58.643759901],
  [-78.57474, 58.644400101],
  [-78.5792301, 58.643460001],
  [-78.5792599, 58.642950001],
  [-78.58242, 58.643520101],
  [-78.58231, 58.645060001],
  [-78.5845899, 58.647659901],
  [-78.58318, 58.650200101],
  [-78.57975, 58.650130001],
  [-78.57929, 58.649620001],
  [-78.5786999, 58.651140001],
  [-78.5796801, 58.651160001],
  [-78.57887, 58.655750101],
  [-78.57789, 58.655720001],
  [-78.57817, 58.658810001],
  [-78.57517, 58.659520101],
  [-78.57508, 58.660800001],
  [-78.5720399, 58.662020001],
  [-78.5725701, 58.661520101],
  [-78.56964, 58.661209901],
  [-78.5692201, 58.660180001],
  [-78.56588, 58.658830001],
  [-78.5660201, 58.656790001],
  [-78.56372, 58.658020001],
  [-78.5639199, 58.658800001],
  [-78.56539, 58.658820001],
  [-78.5674899, 58.660400001],
  [-78.5680299, 58.663229901],
  [-78.56705, 58.663210101],
  [-78.56583, 58.666510001],
  [-78.5709001, 58.667889901],
  [-78.5720399, 58.669190101],
  [-78.57106, 58.669170001],
  [-78.57042, 58.671210101],
  [-78.57675, 58.672100101],
  [-78.5763501, 58.670810001],
  [-78.5798001, 58.670750101],
  [-78.58317, 58.671710001],
  [-78.5831301, 58.672219901],
  [-78.58706, 58.672290001],
  [-78.5861, 58.672020001],
  [-78.58664, 58.671260001],
  [-78.58566, 58.671239901],
  [-78.5857099, 58.670470001],
  [-78.5881599, 58.670650101],
  [-78.58943, 58.670030001],
  [-78.5902799, 58.668380001],
  [-78.5932, 58.668820001],
  [-78.59311, 58.673679901],
  [-78.59529, 58.674239901],
  [-78.59477, 58.674740001],
  [-78.5962401, 58.674640101],
  [-78.5971301, 58.676060001],
  [-78.5961401, 58.676050001],
  [-78.59609, 58.676810001],
  [-78.5926901, 58.676239901],
  [-78.59274, 58.675470001],
  [-78.59032, 58.674909901],
  [-78.5890399, 58.675660101],
  [-78.58962, 58.677970001],
  [-78.58664, 58.678430101],
  [-78.5866901, 58.677660101],
  [-78.5847099, 58.677880101],
  [-78.58466, 58.678650101],
  [-78.58318, 58.678620001],
  [-78.5832501, 58.677600001],
  [-78.5773501, 58.677610001],
  [-78.56672, 58.674980101],
  [-78.56628, 58.674200101],
  [-78.56386, 58.673650101],
  [-78.56395, 58.672370001],
  [-78.5615, 58.672320101],
  [-78.5611101, 58.670779901],
  [-78.5596601, 58.670490001],
  [-78.5587901, 58.668679901],
  [-78.5573499, 58.668400001],
  [-78.55531, 58.666070001],
  [-78.5555799, 58.665549901],
  [-78.5526899, 58.664860101],
  [-78.55116, 58.665470001],
  [-78.5502499, 58.664430101],
  [-78.5487999, 58.664140001],
  [-78.5493201, 58.663640101],
  [-78.54787, 58.663360001],
  [-78.5475799, 58.663860101],
  [-78.54872, 58.665290001],
  [-78.5496899, 58.665439901],
  [-78.5475601, 58.667700001],
  [-78.5402399, 58.667050001],
  [-78.54163, 58.664769901],
  [-78.54075, 58.663219901],
  [-78.5363399, 58.659549901],
  [-78.5397999, 58.659360001],
  [-78.53983, 58.658850101],
  [-78.5364699, 58.657769901],
  [-78.53651, 58.656989901],
  [-78.5345599, 58.656960101],
  [-78.53418, 58.655410101],
  [-78.5322101, 58.655370001],
  [-78.5317999, 58.654339901],
  [-78.5274, 58.653999901],
  [-78.5274301, 58.653490001],
  [-78.52504, 58.652680001],
  [-78.5247, 58.653950001],
  [-78.5255501, 58.655760101],
  [-78.5267701, 58.656040001],
  [-78.5266401, 58.657830001],
  [-78.5334301, 58.658980101],
  [-78.53207, 58.657420101],
  [-78.53449, 58.657980101],
  [-78.5344301, 58.658750101],
  [-78.5359099, 58.658650101],
  [-78.5361199, 58.659290001],
  [-78.5347999, 58.660549901],
  [-78.52983, 58.661470001],
  [-78.5297799, 58.661980101],
  [-78.5268701, 58.661420101],
  [-78.5253, 58.662669901],
  [-78.5272399, 58.663219901],
  [-78.5272101, 58.663740001],
  [-78.52571, 58.663960001],
  [-78.5257501, 58.663449901],
  [-78.5237999, 58.663160001],
  [-78.5238701, 58.662140001],
  [-78.5199999, 58.661290001],
  [-78.52004, 58.660769901],
  [-78.51904, 58.661009901],
  [-78.5196, 58.659999901],
  [-78.5181099, 58.660229901],
  [-78.51871, 58.658700001],
  [-78.5207, 58.658339901],
  [-78.5227601, 58.656989901],
  [-78.5217799, 58.656970101],
  [-78.52207, 58.656460001],
  [-78.52091, 58.655410101],
  [-78.5146, 58.654260001],
  [-78.51404, 58.655280001],
  [-78.51061, 58.655209901],
  [-78.5106799, 58.654190101],
  [-78.5063199, 58.653339901],
  [-78.50637, 58.652570001],
  [-78.50737, 58.652339901],
  [-78.50495, 58.651779901],
  [-78.5059701, 58.651280001],
  [-78.5020999, 58.650439901],
  [-78.50158, 58.650940001],
  [-78.5011801, 58.649649901],
  [-78.49928, 58.648720001],
  [-78.49683, 58.648549901],
  [-78.4997, 58.649630101],
  [-78.49936, 58.649960101],
  [-78.5003101, 58.651429901],
  [-78.5003299, 58.658090101],
  [-78.50101, 58.658870101],
  [-78.4955499, 58.659530101],
  [-78.4954001, 58.658250001],
  [-78.49425, 58.657200101],
  [-78.4923299, 58.656520101],
  [-78.48937, 58.656720001],
  [-78.4879501, 58.655930001],
  [-78.48647, 58.656030001],
  [-78.4854001, 58.657290001],
  [-78.48637, 58.657429901],
  [-78.4896, 58.660310101],
  [-78.4915399, 58.660730001],
  [-78.49157, 58.660219901],
  [-78.49595, 58.660830001],
  [-78.4959899, 58.660310101],
  [-78.50139, 58.660410101],
  [-78.5020701, 58.661200101],
  [-78.50059, 58.664760101],
  [-78.50158, 58.664769901],
  [-78.50102, 58.665530101],
  [-78.5019999, 58.665549901],
  [-78.50158, 58.668109901],
  [-78.5128601, 58.671899901],
  [-78.5128, 58.672669901],
  [-78.5137899, 58.672559901],
  [-78.5146599, 58.674239901],
  [-78.5161099, 58.674530101],
  [-78.5166799, 58.673510001],
  [-78.5200801, 58.673960001],
  [-78.52295, 58.675170001],
  [-78.5229099, 58.675679901],
  [-78.5238999, 58.675569901],
  [-78.5249901, 58.674190001],
  [-78.5239901, 58.674170001],
  [-78.52381, 58.673390001],
  [-78.52481, 58.669710001],
  [-78.5292001, 58.670170001],
  [-78.5287701, 58.669390001],
  [-78.5277899, 58.669370001],
  [-78.52761, 58.668349901],
  [-78.5298899, 58.667380001],
  [-78.52995, 58.666600001],
  [-78.53093, 58.666620001],
  [-78.53072, 58.666100101],
  [-78.5359099, 58.665690001],
  [-78.53695, 58.668270001],
  [-78.54075, 58.670390001],
  [-78.5404401, 58.671150001],
  [-78.54142, 58.671170001],
  [-78.54105, 58.672960001],
  [-78.54173, 58.673740001],
  [-78.54075, 58.673720001],
  [-78.5407, 58.674490001],
  [-78.5358901, 58.672990101],
  [-78.5344, 58.673090101],
  [-78.5344599, 58.672320101],
  [-78.53104, 58.671999901],
  [-78.53238, 58.673940001],
  [-78.53649, 58.674920001],
  [-78.5374, 58.679540101],
  [-78.5393399, 58.679840001],
  [-78.53928, 58.680610001],
  [-78.5407601, 58.680630001],
  [-78.54186, 58.682449901],
  [-78.54308, 58.682730001],
  [-78.5417, 58.684750001],
  [-78.54229, 58.686810001],
  [-78.54131, 58.686789901],
  [-78.5412499, 58.687559901],
  [-78.5363399, 58.687459901],
  [-78.5357601, 58.688730001],
  [-78.5337801, 58.688950001],
  [-78.5327, 58.690210101],
  [-78.5351399, 58.690510001],
  [-78.5351001, 58.691019901],
  [-78.53905, 58.690840001],
  [-78.53918, 58.689050001],
  [-78.5376899, 58.689280001],
  [-78.53826, 58.688139901],
  [-78.54562, 58.688410001],
  [-78.5470101, 58.689710001],
  [-78.54798, 58.689730001],
  [-78.5484, 58.690770101],
  [-78.5542301, 58.691899901],
  [-78.5535899, 58.693940001],
  [-78.5521201, 58.693909901],
  [-78.55205, 58.694930001],
  [-78.55054, 58.695290001],
  [-78.54564, 58.695070001],
  [-78.5456799, 58.694550101],
  [-78.5393201, 58.694050001],
  [-78.53596, 58.692830001],
  [-78.5348901, 58.694090001],
  [-78.5314301, 58.694280001],
  [-78.5314699, 58.693770101],
  [-78.5295, 58.693720001],
  [-78.52896, 58.694490001],
  [-78.52652, 58.694190001],
  [-78.52648, 58.694700001],
  [-78.52297, 58.695660101],
  [-78.52208, 58.694359901],
  [-78.5211001, 58.694339901],
  [-78.5183299, 58.691730001],
  [-78.51384, 58.692660101],
  [-78.5137799, 58.693430101],
  [-78.5113201, 58.693380001],
  [-78.51136, 58.692870101],
  [-78.51038, 58.692850001],
  [-78.51094, 58.691830001],
  [-78.5126799, 58.691620001],
  [-78.51202, 58.690579901],
  [-78.5091199, 58.689880101],
  [-78.5056699, 58.689950001],
  [-78.5056301, 58.690459901],
  [-78.5036699, 58.690420001],
  [-78.5020999, 58.691670101],
  [-78.5011199, 58.691650101],
  [-78.5011901, 58.690630001],
  [-78.50846, 58.688720001],
  [-78.5087799, 58.687700001],
  [-78.5034101, 58.687080001],
  [-78.5029801, 58.686310101],
  [-78.5039601, 58.686330101],
  [-78.5039999, 58.685820001],
  [-78.4975201, 58.686970001],
  [-78.4975599, 58.686459901],
  [-78.4960701, 58.686689901],
  [-78.4960999, 58.686170001],
  [-78.49417, 58.685620001],
  [-78.49425, 58.684600001],
  [-78.49181, 58.684300001],
  [-78.4905, 58.685300001],
  [-78.4898, 58.688359901],
  [-78.49101, 58.688640001],
  [-78.49039, 58.690420001],
  [-78.4968, 58.690160001],
  [-78.49723, 58.691060001],
  [-78.49624, 58.691040001],
  [-78.4961801, 58.691810001],
  [-78.4927699, 58.691490001],
  [-78.49245, 58.692510001],
  [-78.4955301, 58.694100101],
  [-78.4982001, 58.694670101],
  [-78.4999999, 58.697009901],
  [-78.5001099, 58.698799901],
  [-78.50206, 58.699090001],
  [-78.50203, 58.699610001],
  [-78.50104, 58.699589901],
  [-78.5012299, 58.700359901],
  [-78.5036401, 58.701170001],
  [-78.50381, 58.702200001],
  [-78.50479, 58.702220101],
  [-78.5047501, 58.702730001],
  [-78.50672, 58.702770101],
  [-78.50703, 58.705340101],
  [-78.50605, 58.705320001],
  [-78.5065, 58.705840001],
  [-78.5050099, 58.706060001],
  [-78.5034399, 58.707310001],
  [-78.49951, 58.707239901],
  [-78.49836, 58.706190001],
  [-78.49903, 58.703640001],
  [-78.50028, 58.703410001],
  [-78.50037, 58.702129901],
  [-78.4984201, 58.701840001],
  [-78.4978601, 58.702850001],
  [-78.49884, 58.702870001],
  [-78.4964299, 58.705640001],
  [-78.4969, 58.705909901],
  [-78.4966, 58.706670101],
  [-78.49758, 58.706680101],
  [-78.49702, 58.707699901],
  [-78.49991, 58.708530001],
  [-78.50048, 58.710840001],
  [-78.50268, 58.714469901],
  [-78.50182, 58.716249901],
  [-78.49535, 58.717139901],
  [-78.48995, 58.717039901],
  [-78.5016501, 58.718550001],
  [-78.50328, 58.719860001],
  [-78.5020999, 58.722650001],
  [-78.5104399, 58.723070001],
  [-78.51103, 58.725130101],
  [-78.50902, 58.725599901],
  [-78.50848, 58.726359901],
  [-78.50351, 58.727029901],
  [-78.50346, 58.727540001],
  [-78.5005, 58.727740001],
  [-78.50129, 58.726990001],
  [-78.5012299, 58.724430001],
  [-78.4997601, 58.724400001],
  [-78.50014, 58.725939901],
  [-78.49915, 58.725919901],
  [-78.49992, 58.729010101],
  [-78.5009, 58.729029901],
  [-78.5008601, 58.729540001],
  [-78.50235, 58.729440001],
  [-78.5044101, 58.728200001],
  [-78.5083101, 58.728660001],
  [-78.5088799, 58.727650001],
  [-78.5078999, 58.727630001],
  [-78.5089701, 58.726369901],
  [-78.51096, 58.726029901],
  [-78.5144201, 58.725960001],
  [-78.51673, 58.728060001],
  [-78.5182001, 58.728100001],
  [-78.5190801, 58.729640001],
  [-78.5180901, 58.729620001],
  [-78.5169801, 58.731390001],
  [-78.5179701, 58.731410001],
  [-78.5174, 58.732420001],
  [-78.51192, 58.733330001],
  [-78.51103, 58.735379901],
  [-78.5136699, 58.736450101],
  [-78.51363, 58.736960001],
  [-78.51462, 58.736850001],
  [-78.51738, 58.739589901],
  [-78.5217601, 58.740450001],
  [-78.52306, 58.742900101],
  [-78.52504, 58.742810101],
  [-78.5251099, 58.741790101],
  [-78.5241199, 58.741770001],
  [-78.52469, 58.740770001],
  [-78.5237, 58.740740001],
  [-78.52329, 58.739709901],
  [-78.5257601, 58.739499901],
  [-78.52683, 58.738369901],
  [-78.5302299, 58.739070001],
  [-78.53019, 58.739580101],
  [-78.53449, 58.741460101],
  [-78.53396, 58.741960001],
  [-78.53641, 58.742130101],
  [-78.5400001, 58.743609901],
  [-78.5413301, 58.745939901],
  [-78.5403399, 58.749250101],
  [-78.5383599, 58.749470101],
  [-78.5379901, 58.751260101],
  [-78.5383499, 58.753049901],
  [-78.5408999, 58.755279901],
  [-78.54239, 58.755169901],
  [-78.54182, 58.756190001],
  [-78.54037, 58.755910101],
  [-78.5408099, 58.756560001],
  [-78.54508, 58.755489901],
  [-78.5449199, 58.754200001],
  [-78.54394, 58.754190001],
  [-78.5425501, 58.752880001],
  [-78.55083, 58.754320001],
  [-78.5507801, 58.754829901],
  [-78.55272, 58.755379901],
  [-78.55276, 58.754870001],
  [-78.5552201, 58.754910101],
  [-78.55518, 58.755420001],
  [-78.5596, 58.755750001],
  [-78.5601499, 58.758330001],
  [-78.56277, 58.759660001],
  [-78.5603301, 58.759360101],
  [-78.5607701, 58.760140101],
  [-78.5597801, 58.760120001],
  [-78.5596801, 58.761650001],
  [-78.5586899, 58.761640001],
  [-78.55918, 58.765230001],
  [-78.56032, 58.766540001],
  [-78.5632401, 58.767100001],
  [-78.5639001, 58.768389901],
  [-78.56351, 58.770179901],
  [-78.56253, 58.770160101],
  [-78.56221, 58.771179901],
  [-78.56342, 58.771460001],
  [-78.5644899, 58.770310001],
  [-78.57096, 58.769420001],
  [-78.5723, 58.771490101],
  [-78.5745899, 58.771580001],
  [-78.57364, 58.769860001],
  [-78.57539, 58.769380101],
  [-78.57677, 58.770940101],
  [-78.57821, 58.771490101],
  [-78.57695, 58.771970001],
  [-78.5805999, 58.772550001],
  [-78.58063, 58.772040101],
  [-78.58375, 58.773370101],
  [-78.5834701, 58.773880001],
  [-78.58518, 58.774169901],
  [-78.5838199, 58.775940101],
  [-78.57795, 58.775310001],
  [-78.57619, 58.772220001],
  [-78.57521, 58.772190001],
  [-78.5752401, 58.771680001],
  [-78.57322, 58.772409901],
  [-78.57186, 58.774179901],
  [-78.5725601, 58.774700101],
  [-78.5711101, 58.774420001],
  [-78.5707901, 58.775440001],
  [-78.5745, 58.778830101],
  [-78.57444, 58.779600101],
  [-78.57806, 58.780690001],
  [-78.5780101, 58.781460001],
  [-78.5770299, 58.781440001],
  [-78.57697, 58.782210001],
  [-78.575, 58.782169901],
  [-78.57472, 58.782680001],
  [-78.5759299, 58.782959901],
  [-78.5768299, 58.784260101],
  [-78.5783501, 58.783639901],
  [-78.5822699, 58.783969901],
  [-78.58533, 58.782619901],
  [-78.58542, 58.781340001],
  [-78.5839201, 58.781570001],
  [-78.5834601, 58.781050101],
  [-78.58445, 58.781069901],
  [-78.5848199, 58.780399901],
  [-78.58254, 58.780270101],
  [-78.5825999, 58.779240001],
  [-78.5855801, 58.779040101],
  [-78.5856801, 58.779980001],
  [-78.59096, 58.779660001],
  [-78.59041, 58.780409901],
  [-78.5919101, 58.780179901],
  [-78.59032, 58.781690001],
  [-78.59129, 58.781959901],
  [-78.5893, 58.782179901],
  [-78.5892699, 58.782690001],
  [-78.59322, 58.782639901],
  [-78.5953701, 58.783580001],
  [-78.59818, 58.789260101],
  [-78.5991699, 58.789280101],
  [-78.60033, 58.790330001],
  [-78.6000301, 58.791090001],
  [-78.6010301, 58.791110001],
  [-78.6007199, 58.791859901],
  [-78.60153, 58.794450001],
  [-78.6007001, 58.795710101],
  [-78.59818, 58.796430001],
  [-78.5968399, 58.794360001],
  [-78.59586, 58.794350001],
  [-78.5941, 58.791240001],
  [-78.59312, 58.791220001],
  [-78.5927099, 58.789930101],
  [-78.59173, 58.789910001],
  [-78.59132, 58.788619901],
  [-78.58986, 58.788340001],
  [-78.5899001, 58.787830101],
  [-78.5860101, 58.786990001],
  [-78.58589, 58.786310001],
  [-78.5835999, 58.786170101],
  [-78.583, 58.787700001],
  [-78.5859201, 58.788270101],
  [-78.58819, 58.791130001],
  [-78.5901599, 58.791160101],
  [-78.5910399, 58.792720101],
  [-78.5920399, 58.792480001],
  [-78.59197, 58.793500101],
  [-78.5944, 58.794060101],
  [-78.5943601, 58.794570001],
  [-78.5923799, 58.794790001],
  [-78.59241, 58.794280101],
  [-78.59042, 58.794500101],
  [-78.5903699, 58.795260001],
  [-78.5893799, 58.795250001],
  [-78.58884, 58.796000001],
  [-78.5947299, 58.796370001],
  [-78.5951499, 58.797409901],
  [-78.58873, 58.797540001],
  [-78.5883799, 58.795480001],
  [-78.5792501, 58.791980001],
  [-78.57654, 58.788350001],
  [-78.5745701, 58.788309901],
  [-78.5744501, 58.786519901],
  [-78.5772, 58.786040101],
  [-78.57752, 58.785040101],
  [-78.57584, 58.784490101],
  [-78.5762, 58.783820101],
  [-78.5749001, 58.783710101],
  [-78.5738001, 58.785480101],
  [-78.5693501, 58.785399901],
  [-78.5685601, 58.786150101],
  [-78.5692401, 58.786930101],
  [-78.56828, 58.786660001],
  [-78.56652, 58.790720101],
  [-78.56551, 58.790959901],
  [-78.5655601, 58.790189901],
  [-78.5645999, 58.789910001],
  [-78.5645601, 58.790430001],
  [-78.56306, 58.790780001],
  [-78.5592, 58.789560001],
  [-78.5583, 58.791840101],
  [-78.55697, 58.793100001],
  [-78.54916, 58.791930101],
  [-78.5492, 58.791419901],
  [-78.5442301, 58.791830101],
  [-78.54427, 58.791320001],
  [-78.54329, 58.791299901],
  [-78.5428901, 58.790010001],
  [-78.54095, 58.789470001],
  [-78.5421001, 58.787179901],
  [-78.5411101, 58.787160101],
  [-78.54073, 58.785619901],
  [-78.53974, 58.785600101],
  [-78.5395699, 58.784570001],
  [-78.54082, 58.784340001],
  [-78.5408701, 58.783560001],
  [-78.54186, 58.783590101],
  [-78.54209, 58.780270101],
  [-78.5401399, 58.779980001],
  [-78.54018, 58.779460001],
  [-78.53919, 58.779450001],
  [-78.5392299, 58.778930101],
  [-78.5382499, 58.778920101],
  [-78.53781, 58.778140001],
  [-78.5353599, 58.778090001],
  [-78.5344499, 58.776790001],
  [-78.5427999, 58.777210001],
  [-78.5427701, 58.777720101],
  [-78.54563, 58.779050101],
  [-78.5466799, 58.778189901],
  [-78.55253, 58.779189901],
  [-78.55261, 58.781490101],
  [-78.55383, 58.781640001],
  [-78.55538, 58.780650001],
  [-78.5583501, 58.780580001],
  [-78.5589299, 58.779310001],
  [-78.5570001, 58.778770001],
  [-78.5570399, 58.777990001],
  [-78.5580299, 58.778010001],
  [-78.5581101, 58.776990001],
  [-78.5571201, 58.776969901],
  [-78.55716, 58.776460001],
  [-78.5581399, 58.776480101],
  [-78.55818, 58.775970001],
  [-78.5524, 58.774059901],
  [-78.55175, 58.772770001],
  [-78.5530299, 58.772030101],
  [-78.55027, 58.772739901],
  [-78.55095, 58.773530001],
  [-78.5484501, 58.774120001],
  [-78.5416, 58.773350001],
  [-78.5418801, 58.772839901],
  [-78.54072, 58.771790001],
  [-78.53974, 58.771770001],
  [-78.5405699, 58.770509901],
  [-78.5428901, 58.769020001],
  [-78.54387, 58.769040101],
  [-78.5439099, 58.768519901],
  [-78.5429199, 58.768509901],
  [-78.54159, 58.766430001],
  [-78.5371299, 58.766860001],
  [-78.53583, 58.764269901],
  [-78.5334401, 58.763200001],
  [-78.53162, 58.761120001],
  [-78.52919, 58.760570001],
  [-78.5292299, 58.760049901],
  [-78.5261199, 58.758710101],
  [-78.5259901, 58.757169901],
  [-78.52451, 58.757140101],
  [-78.5247899, 58.756640001],
  [-78.52318, 58.755070001],
  [-78.52619, 58.754489901],
  [-78.5260099, 58.753330001],
  [-78.52737, 58.751560001],
  [-78.52786, 58.751570101],
  [-78.52781, 58.752340001],
  [-78.53127, 58.752150101],
  [-78.53041, 58.750340001],
  [-78.52697, 58.750279901],
  [-78.5242199, 58.754320001],
  [-78.5207701, 58.754250101],
  [-78.52306, 58.753269901],
  [-78.5228899, 58.752250101],
  [-78.5195, 58.751410001],
  [-78.5194599, 58.751920101],
  [-78.5175, 58.751760001],
  [-78.5159999, 58.752110001],
  [-78.5154499, 58.752870001],
  [-78.51448, 58.752599901],
  [-78.5134301, 58.753599901],
  [-78.5144101, 58.753619901],
  [-78.51437, 58.754130101],
  [-78.51339, 58.754110001],
  [-78.51385, 58.754630001],
  [-78.5128799, 58.754360101],
  [-78.51294, 58.756930101],
  [-78.51558, 58.758000001],
  [-78.5155401, 58.758509901],
  [-78.5115699, 58.758939901],
  [-78.5099801, 58.760450001],
  [-78.50549, 58.761260101],
  [-78.5010999, 58.760530001],
  [-78.50072, 58.758990001],
  [-78.49781, 58.758420001],
  [-78.4977501, 58.759190001],
  [-78.49524, 58.759780001],
  [-78.48893, 58.758499901],
  [-78.4899999, 58.757240101],
  [-78.49249, 58.756910101],
  [-78.4935399, 58.756030101],
  [-78.4969999, 58.755839901],
  [-78.4969701, 58.756350001],
  [-78.49494, 58.757080001],
  [-78.49683, 58.758269901],
  [-78.49884, 58.757670001],
  [-78.49873, 58.755880001],
  [-78.5005599, 58.754379901],
  [-78.49958, 58.754360101],
  [-78.50061, 58.753609901],
  [-78.49914, 58.753580101],
  [-78.50001, 58.751800101],
  [-78.4984299, 58.749729901],
  [-78.49794, 58.749719901],
  [-78.4967501, 58.752509901],
  [-78.49325, 58.753210001],
  [-78.4962001, 58.753269901],
  [-78.49513, 58.754530001],
  [-78.49061, 58.755719901],
  [-78.49006, 58.756480101],
  [-78.4850601, 58.757530001],
  [-78.48092, 58.756810101],
  [-78.4809799, 58.756040101],
  [-78.4856499, 58.756130101],
  [-78.48569, 58.755619901],
  [-78.48667, 58.755640001],
  [-78.4867301, 58.754880001],
  [-78.4823199, 58.754530001],
  [-78.48025, 58.755910101],
  [-78.4773199, 58.755460001],
  [-78.4766201, 58.754930101],
  [-78.4769, 58.754430001],
  [-78.4754299, 58.754399901],
  [-78.47571, 58.753890001],
  [-78.4742099, 58.750790001],
  [-78.47323, 58.750770001],
  [-78.47046, 58.748159901],
  [-78.46948, 58.748140101],
  [-78.46815, 58.746059901],
  [-78.4652701, 58.744990001],
  [-78.46533, 58.744210001],
  [-78.4638501, 58.744180001],
  [-78.46247, 58.742880001],
  [-78.46148, 58.742860001],
  [-78.46201, 58.742369901],
  [-78.4596299, 58.741159901],
  [-78.45622, 58.740700101],
  [-78.4560699, 58.742750001],
  [-78.4536001, 58.742960001],
  [-78.45356, 58.743470101],
  [-78.45112, 58.743159901],
  [-78.4510799, 58.743680101],
  [-78.4451701, 58.743690101],
  [-78.4427399, 58.743000001],
  [-78.4418499, 58.741960001],
  [-78.4399, 58.741660001],
  [-78.4409699, 58.740400001],
  [-78.43955, 58.739860001],
  [-78.44079, 58.739630001],
  [-78.44109, 58.738860001],
  [-78.4386299, 58.738819901],
  [-78.4391501, 58.738450001],
  [-78.4416299, 58.738240101],
  [-78.44234, 58.738640001],
  [-78.4420501, 58.739140101],
  [-78.4440501, 58.738800101],
  [-78.44792, 58.739770001],
  [-78.44977, 58.738020101],
  [-78.44878, 58.734670001],
  [-78.44544, 58.733320001],
  [-78.4450699, 58.731520001],
  [-78.44409, 58.731500001],
  [-78.44466, 58.730489901],
  [-78.4456399, 58.730510001],
  [-78.4457499, 58.728960001],
  [-78.44188, 58.728130101],
  [-78.44101, 58.726580101],
  [-78.43953, 58.726550001],
  [-78.43946, 58.724240101],
  [-78.4385099, 58.723709901],
  [-78.4397399, 58.720400001],
  [-78.4392601, 58.720139901],
  [-78.43966, 58.718100001],
  [-78.4381699, 58.718320001],
  [-78.4387299, 58.717310001],
  [-78.43775, 58.717290001],
  [-78.4378599, 58.715760001],
  [-78.43688, 58.715740001],
  [-78.4371203, 58.714041901],
  [-78.4429928, 58.713459901],
  [-78.4474629, 58.714167301],
  [-78.4553218, 58.713526501],
  [-78.4555954, 58.712802201],
  [-78.455087, 58.712424901],
  [-78.451458, 58.712006901],
  [-78.45375, 58.711570801],
  [-78.4604287, 58.713161601],
  [-78.4662607, 58.712198801],
  [-78.4719954, 58.712281601],
  [-78.4792365, 58.714764201],
  [-78.4819776, 58.714718901],
  [-78.476425, 58.712333601],
  [-78.4749166, 58.709825601],
  [-78.4702722, 58.711741101],
  [-78.462891, 58.710417401],
  [-78.4575158, 58.711264401],
  [-78.4541363, 58.710790701],
  [-78.4451294, 58.707790001],
  [-78.4379785, 58.707970701],
  [-78.4285212, 58.706435901],
  [-78.4240401, 58.702930001],
  [-78.42212, 58.702380001],
  [-78.4205099, 58.700809901],
  [-78.42079, 58.700310001],
  [-78.41835, 58.700000101],
  [-78.41845, 58.698720001],
  [-78.42045, 58.698249901],
  [-78.4188299, 58.696940001],
  [-78.4191699, 58.695670101],
  [-78.4161799, 58.696119901],
  [-78.41622, 58.695610001],
  [-78.41475, 58.695579901],
  [-78.41312, 58.694260001],
  [-78.41344, 58.693249901],
  [-78.40912, 58.691880101],
  [-78.4087001, 58.690850001],
  [-78.4039399, 58.688700001],
  [-78.4039101, 58.689210101],
  [-78.4029399, 58.688940001],
  [-78.40109, 58.690689901],
  [-78.4012401, 58.691990101],
  [-78.39774, 58.692799901],
  [-78.3930852, 58.692893601],
  [-78.3917856, 58.691006901],
  [-78.3890069, 58.690822901],
  [-78.3823104, 58.686800401],
  [-78.3818757, 58.685574301],
  [-78.3797487, 58.686257801],
  [-78.3828431, 58.688662401],
  [-78.3825266, 58.689353801],
  [-78.3793999, 58.688655401],
  [-78.3780635, 58.687254601],
  [-78.3746773, 58.686004301],
  [-78.3696983, 58.685879801],
  [-78.3683925, 58.684925501],
  [-78.3689957, 58.683807301],
  [-78.3646524, 58.683504601],
  [-78.3615625, 58.684856901],
  [-78.3590861, 58.683745701],
  [-78.3531199, 58.684339901],
  [-78.35408, 58.684610001],
  [-78.3508099, 58.685569901],
  [-78.3514699, 58.686610001],
  [-78.3524501, 58.686630001],
  [-78.3517899, 58.688930001],
  [-78.35373, 58.689210101],
  [-78.3521199, 58.690980101],
  [-78.3511399, 58.690970001],
  [-78.35108, 58.691720001],
  [-78.35207, 58.691740001],
  [-78.3520099, 58.692510001],
  [-78.3529901, 58.692530001],
  [-78.35295, 58.693040001],
  [-78.3573499, 58.693390001],
  [-78.35627, 58.694650101],
  [-78.3592201, 58.694710001],
  [-78.3597899, 58.693689901],
  [-78.3617999, 58.693090001],
  [-78.3652301, 58.693300001],
  [-78.3657999, 58.692280001],
  [-78.3643301, 58.692249901],
  [-78.3643599, 58.691740001],
  [-78.35784, 58.693400001],
  [-78.3572201, 58.691850001],
  [-78.3560099, 58.691569901],
  [-78.3596701, 58.688830001],
  [-78.36116, 58.688600001],
  [-78.3612201, 58.687830001],
  [-78.3646701, 58.687650101],
  [-78.3655701, 58.688689901],
  [-78.3665601, 58.688710001],
  [-78.36595, 58.690229901],
  [-78.37075, 58.691870101],
  [-78.3708901, 58.696480001],
  [-78.37284, 58.696780101],
  [-78.37231, 58.697280001],
  [-78.3762201, 58.697610001],
  [-78.3780611, 58.693703501],
  [-78.3791253, 58.692740201],
  [-78.3781728, 58.691651901],
  [-78.3794188, 58.691071201],
  [-78.3823288, 58.691264301],
  [-78.3824462, 58.689882201],
  [-78.3835831, 58.689609701],
  [-78.3909647, 58.692065101],
  [-78.3918987, 58.693199801],
  [-78.399298, 58.694965001],
  [-78.3982953, 58.697264401],
  [-78.3989066, 58.699031001],
  [-78.397891, 58.699792401],
  [-78.3989954, 58.700957401],
  [-78.39751, 58.702400001],
  [-78.3994799, 58.702440101],
  [-78.4001622, 58.701407101],
  [-78.4105099, 58.699589901],
  [-78.41119, 58.700370001],
  [-78.40919, 58.704170001],
  [-78.4037299, 58.704830001],
  [-78.4031599, 58.705840001],
  [-78.40463, 58.705870001],
  [-78.40408, 58.706630001],
  [-78.4016099, 58.706840001],
  [-78.4017001, 58.705560101],
  [-78.3982501, 58.705740001],
  [-78.3976801, 58.706760001],
  [-78.3966999, 58.706740001],
  [-78.3960301, 58.709029901],
  [-78.3940499, 58.709239901],
  [-78.39144, 58.711239901],
  [-78.3904501, 58.711220101],
  [-78.39089, 58.712000101],
  [-78.3879199, 58.712190001],
  [-78.3874899, 58.711420001],
  [-78.3884899, 58.711180001],
  [-78.38751, 58.711160001],
  [-78.3876801, 58.708860001],
  [-78.38573, 58.708560101],
  [-78.3853699, 58.706760001],
  [-78.3829199, 58.706710001],
  [-78.38396, 58.705970001],
  [-78.38297, 58.705950001],
  [-78.3825601, 58.704909901],
  [-78.3776999, 58.704170001],
  [-78.37619, 58.704530001],
  [-78.3761301, 58.705300001],
  [-78.3677801, 58.705129901],
  [-78.36721, 58.706139901],
  [-78.3652301, 58.706349901],
  [-78.36519, 58.706860001],
  [-78.35976, 58.707280001],
  [-78.35971, 58.707780101],
  [-78.35872, 58.707760001],
  [-78.3587999, 58.706730001],
  [-78.35639, 58.706040001],
  [-78.35141, 58.706840001],
  [-78.3513, 58.708120101],
  [-78.3525601, 58.707890101],
  [-78.3523599, 58.707369901],
  [-78.35484, 58.707039901],
  [-78.35576, 58.707950001],
  [-78.35819, 58.708259901],
  [-78.3591001, 58.709300001],
  [-78.36008, 58.709320001],
  [-78.3601201, 58.708809901],
  [-78.36328, 58.709390001],
  [-78.36361, 58.711440101],
  [-78.36482, 58.711720001],
  [-78.36408, 58.715039901],
  [-78.3671299, 58.713830001],
  [-78.36754, 58.714850001],
  [-78.37098, 58.714919901],
  [-78.3710199, 58.714400001],
  [-78.3759199, 58.714770001],
  [-78.37575, 58.713740001],
  [-78.3770201, 58.713120101],
  [-78.3792201, 58.713300001],
  [-78.3789001, 58.714310001],
  [-78.3764401, 58.714270001],
  [-78.37639, 58.715029901],
  [-78.3798099, 58.715359901],
  [-78.3782201, 58.716860001],
  [-78.3772301, 58.716840001],
  [-78.3776701, 58.717620001],
  [-78.3756801, 58.717830001],
  [-78.37572, 58.717310001],
  [-78.37474, 58.717300001],
  [-78.3748199, 58.716280001],
  [-78.37383, 58.716259901],
  [-78.3705, 58.721310001],
  [-78.3724601, 58.721350101],
  [-78.37243, 58.721870001],
  [-78.37095, 58.721840001],
  [-78.3704, 58.722589901],
  [-78.37138, 58.722610001],
  [-78.3713501, 58.723120101],
  [-78.3743, 58.723060001],
  [-78.37473, 58.723960001],
  [-78.3757099, 58.723980001],
  [-78.37461, 58.725500001],
  [-78.3761101, 58.725269901],
  [-78.3761201, 58.728340101],
  [-78.3755899, 58.728840001],
  [-78.37627, 58.729630001],
  [-78.37975, 58.729190001],
  [-78.3787901, 58.728910101],
  [-78.3791499, 58.727379901],
  [-78.3785799, 58.725060001],
  [-78.3803599, 58.724330001],
  [-78.3799101, 58.720479901],
  [-78.3789299, 58.720460101],
  [-78.37874, 58.719689901],
  [-78.3797099, 58.716640001],
  [-78.3851, 58.717000101],
  [-78.38761, 58.719610001],
  [-78.38733, 58.720120101],
  [-78.38929, 58.720160001],
  [-78.38986, 58.722479901],
  [-78.3893501, 58.722720001],
  [-78.3895, 58.724010101],
  [-78.38851, 58.723990001],
  [-78.3893601, 58.725800101],
  [-78.39063, 58.725310001],
  [-78.3906901, 58.724540001],
  [-78.3929199, 58.724330001],
  [-78.39233, 58.725599901],
  [-78.3938299, 58.725369901],
  [-78.39489, 58.726589901],
  [-78.3956999, 58.726690101],
  [-78.3953, 58.725400001],
  [-78.39722, 58.726080001],
  [-78.40262, 58.726320001],
  [-78.40266, 58.725809901],
  [-78.40462, 58.725850001],
  [-78.40401, 58.727359901],
  [-78.4030301, 58.727350101],
  [-78.4009101, 58.729360101],
  [-78.3971945, 58.729750701],
  [-78.3953002, 58.728624401],
  [-78.3942843, 58.728776401],
  [-78.3945942, 58.729723901],
  [-78.3973839, 58.730376401],
  [-78.39677, 58.732090001],
  [-78.39776, 58.732110001],
  [-78.3969001, 58.733620001],
  [-78.40153, 58.734360101],
  [-78.4023699, 58.736300001],
  [-78.3994, 58.736499901],
  [-78.39945, 58.735730001],
  [-78.39354, 58.735870001],
  [-78.3934601, 58.736890001],
  [-78.3949299, 58.736920101],
  [-78.3953799, 58.737570101],
  [-78.3983601, 58.737240101],
  [-78.3983, 58.738010101],
  [-78.3992799, 58.738030101],
  [-78.39898, 58.738790101],
  [-78.40063, 58.739719901],
  [-78.40409, 58.739660001],
  [-78.40342, 58.738630001],
  [-78.4042401, 58.737620001],
  [-78.40619, 58.737910101],
  [-78.4045801, 58.736350101],
  [-78.4049, 58.735330001],
  [-78.40977, 58.736070001],
  [-78.41054, 58.735699901],
  [-78.4103501, 58.734929901],
  [-78.4152699, 58.735020101],
  [-78.4153799, 58.733489901],
  [-78.4179399, 58.732130101],
  [-78.4183899, 58.732780001],
  [-78.42087, 58.732580101],
  [-78.4208299, 58.733090001],
  [-78.42279, 58.733250101],
  [-78.4238499, 58.732120101],
  [-78.4278299, 58.731690101],
  [-78.42788, 58.730919901],
  [-78.42887, 58.730939901],
  [-78.42931, 58.731580101],
  [-78.43244, 58.732550001],
  [-78.43335, 58.733589901],
  [-78.4327101, 58.735630001],
  [-78.43432, 58.737070001],
  [-78.43579, 58.737230101],
  [-78.4357299, 58.738000001],
  [-78.43224, 58.738560001],
  [-78.4293701, 58.737479901],
  [-78.4269, 58.737550001],
  [-78.42789, 58.740650001],
  [-78.4268399, 58.741660001],
  [-78.43142, 58.743030101],
  [-78.43253, 58.741389901],
  [-78.43422, 58.741800101],
  [-78.43487, 58.743100001],
  [-78.4368, 58.743650001],
  [-78.4381699, 58.745080001],
  [-78.4391601, 58.744990001],
  [-78.4391, 58.745740001],
  [-78.4412801, 58.746300001],
  [-78.44257, 58.748880001],
  [-78.4395001, 58.750360101],
  [-78.4514901, 58.751619901],
  [-78.4511799, 58.752379901],
  [-78.4558201, 58.753000001],
  [-78.4558599, 58.752480101],
  [-78.4582999, 58.752780001],
  [-78.45822, 58.753800101],
  [-78.4597499, 58.753190001],
  [-78.46311, 58.754410001],
  [-78.4620501, 58.755420001],
  [-78.45959, 58.755370101],
  [-78.45769, 58.754310001],
  [-78.4577301, 58.753790001],
  [-78.45625, 58.753770001],
  [-78.45624, 58.757350001],
  [-78.45878, 58.759700101],
  [-78.46314, 58.760810101],
  [-78.4630899, 58.761330001],
  [-78.46212, 58.761310001],
  [-78.46502, 58.761880001],
  [-78.4650601, 58.761360101],
  [-78.47935, 58.761520001],
  [-78.48134, 58.761169901],
  [-78.48138, 58.760660001],
  [-78.4907, 58.761230001],
  [-78.4916, 58.762399901],
  [-78.4906401, 58.762120001],
  [-78.49005, 58.763389901],
  [-78.4850801, 58.764049901],
  [-78.4845299, 58.764820101],
  [-78.4855101, 58.764839901],
  [-78.4854399, 58.765860001],
  [-78.4874299, 58.765640001],
  [-78.48846, 58.768480101],
  [-78.48961, 58.769530001],
  [-78.49258, 58.769330001],
  [-78.4898, 58.770300001],
  [-78.4906599, 58.771000001],
  [-78.4919799, 58.770849901],
  [-78.4909601, 58.771350001],
  [-78.4924399, 58.771370101],
  [-78.49212, 58.772389901],
  [-78.49358, 58.776010001],
  [-78.4955499, 58.776040101],
  [-78.4937401, 58.770380101],
  [-78.4950801, 58.768870001],
  [-78.4960701, 58.768880001],
  [-78.4991901, 58.766640001],
  [-78.5031199, 58.766719901],
  [-78.5046599, 58.765980001],
  [-78.5032299, 58.765179901],
  [-78.5032001, 58.765690101],
  [-78.49971, 58.766140101],
  [-78.49928, 58.765360101],
  [-78.50126, 58.765140101],
  [-78.5013001, 58.764630001],
  [-78.50227, 58.764910001],
  [-78.50384, 58.763660001],
  [-78.5081099, 58.764285701],
  [-78.5070749, 58.764936101],
  [-78.5100825, 58.764614001],
  [-78.51694, 58.766470101],
  [-78.51736, 58.767499901],
  [-78.51638, 58.767490101],
  [-78.51606, 58.768499901],
  [-78.5179901, 58.769050101],
  [-78.51818, 58.770080001],
  [-78.5162101, 58.769790001],
  [-78.51591, 58.770550001],
  [-78.5210099, 58.771670001],
  [-78.52105, 58.771160101],
  [-78.52447, 58.771490101],
  [-78.52416, 58.772509901],
  [-78.5257701, 58.774069901],
  [-78.53025, 58.773389901],
  [-78.5307, 58.774030101],
  [-78.5355599, 58.775020001],
  [-78.53549, 58.776050101],
  [-78.53108, 58.775700101],
  [-78.5305401, 58.776210001],
  [-78.5305799, 58.775700101],
  [-78.5252199, 58.774839901],
  [-78.52519, 58.775340001],
  [-78.52317, 58.775940101],
  [-78.5192101, 58.776120001],
  [-78.5153399, 58.775150101],
  [-78.51537, 58.774640001],
  [-78.51195, 58.774310001],
  [-78.5105599, 58.773010001],
  [-78.5080999, 58.772959901],
  [-78.50724, 58.771150101],
  [-78.5082099, 58.771420001],
  [-78.5087699, 58.770409901],
  [-78.50237, 58.770289901],
  [-78.5013001, 58.771550001],
  [-78.50228, 58.771570001],
  [-78.5030801, 58.774140101],
  [-78.5049999, 58.774949901],
  [-78.5040099, 58.774930101],
  [-78.50262, 58.777210001],
  [-78.5046401, 58.780059901],
  [-78.50904, 58.780399901],
  [-78.5081199, 58.779360001],
  [-78.5091199, 58.779380101],
  [-78.50926, 58.777330001],
  [-78.50681, 58.777030101],
  [-78.50737, 58.776270101],
  [-78.50883, 58.776560001],
  [-78.5088601, 58.776050101],
  [-78.50985, 58.776069901],
  [-78.5097799, 58.777090001],
  [-78.5124101, 58.778160101],
  [-78.51158, 58.779430001],
  [-78.50959, 58.779650001],
  [-78.51047, 58.781200001],
  [-78.5134201, 58.781260101],
  [-78.51339, 58.781770001],
  [-78.5163001, 58.782340001],
  [-78.51627, 58.782849901],
  [-78.51972, 58.782910001],
  [-78.51962, 58.784189901],
  [-78.51815, 58.784160101],
  [-78.5181099, 58.784680001],
  [-78.5132001, 58.784320001],
  [-78.51324, 58.783810001],
  [-78.51081, 58.783250001],
  [-78.51247, 58.787639901],
  [-78.5148801, 58.788450001],
  [-78.5164399, 58.790790001],
  [-78.5201001, 58.791370001],
  [-78.5135793, 58.794742901],
  [-78.514335, 58.795069201],
  [-78.5181576, 58.793165701],
  [-78.52195, 58.793069901],
  [-78.52239, 58.793720101],
  [-78.52338, 58.793739901],
  [-78.5233499, 58.794250001],
  [-78.52438, 58.793629901],
  [-78.5253201, 58.794289901],
  [-78.5263399, 58.793800001],
  [-78.5263, 58.794309901],
  [-78.5279901, 58.794849901],
  [-78.52818, 58.795620101],
  [-78.52917, 58.795639901],
  [-78.52906, 58.797179901],
  [-78.5324499, 58.798010001],
  [-78.53242, 58.798519901],
  [-78.5317899, 58.798079901],
  [-78.52897, 58.798460001],
  [-78.5293399, 58.797780001],
  [-78.5285401, 58.797540001],
  [-78.5210199, 58.799199901],
  [-78.51606, 58.799490101],
  [-78.5161001, 58.798979901],
  [-78.5136699, 58.798430001],
  [-78.51528, 58.796660001],
  [-78.51627, 58.796680001],
  [-78.5156501, 58.795979901],
  [-78.5128, 58.796859901],
  [-78.51238, 58.795830101],
  [-78.51139, 58.795810001],
  [-78.5113399, 58.796580001],
  [-78.50937, 58.796540001],
  [-78.50926, 58.798079901],
  [-78.50729, 58.798040001],
  [-78.50818, 58.799340001],
  [-78.52096, 58.800089901],
  [-78.5225699, 58.801660001],
  [-78.5231099, 58.804490101],
  [-78.5210901, 58.805220001],
  [-78.5225301, 58.805759901],
  [-78.5202199, 58.806990001],
  [-78.52039, 58.808020001],
  [-78.5158801, 58.808960101],
  [-78.51583, 58.809730101],
  [-78.51484, 58.809710001],
  [-78.5148, 58.810209901],
  [-78.51283, 58.810180101],
  [-78.5112299, 58.811940101],
  [-78.50927, 58.811649901],
  [-78.5088701, 58.810370001],
  [-78.50757, 58.811360001],
  [-78.50869, 58.812920001],
  [-78.51417, 58.812130001],
  [-78.51473, 58.811230001],
  [-78.5186401, 58.811830101],
  [-78.51883, 58.812600001],
  [-78.52103, 58.812900001],
  [-78.5206799, 58.813240001],
  [-78.52141, 58.814429901],
  [-78.5243201, 58.815270001],
  [-78.52437, 58.814500101],
  [-78.52536, 58.814520101],
  [-78.5240099, 58.816030001],
  [-78.52404, 58.819089901],
  [-78.51902, 58.820290101],
  [-78.51847, 58.821040001],
  [-78.5194599, 58.821060101],
  [-78.51937, 58.822340001],
  [-78.5158801, 58.822790001],
  [-78.5154399, 58.822020001],
  [-78.5164201, 58.822030001],
  [-78.5159801, 58.821250001],
  [-78.5169701, 58.821270001],
  [-78.5169999, 58.820759901],
  [-78.5127699, 58.821450001],
  [-78.51228, 58.824769901],
  [-78.5137601, 58.824790001],
  [-78.5141199, 58.826590001],
  [-78.5151199, 58.826610001],
  [-78.51515, 58.826099901],
  [-78.5200801, 58.826199901],
  [-78.52025, 58.827219901],
  [-78.5192101, 58.827970101],
  [-78.5203299, 58.829530101],
  [-78.5188601, 58.829500001],
  [-78.51772, 58.831530101],
  [-78.5162199, 58.831759901],
  [-78.51617, 58.832520101],
  [-78.5181901, 58.831920001],
  [-78.5201199, 58.832600001],
  [-78.5200801, 58.833109901],
  [-78.5230199, 58.833420101],
  [-78.5232101, 58.834190101],
  [-78.5216601, 58.835190101],
  [-78.5218701, 58.835690001],
  [-78.52037, 58.835930001],
  [-78.51904, 58.837190101],
  [-78.51973, 58.837970101],
  [-78.5232001, 58.837779901],
  [-78.52307, 58.836240001],
  [-78.52552, 58.832960101],
  [-78.52304, 58.833170001],
  [-78.5212101, 58.831080101],
  [-78.5226799, 58.831109901],
  [-78.5219901, 58.830590001],
  [-78.5227899, 58.829700001],
  [-78.52826, 58.829170001],
  [-78.52761, 58.827879901],
  [-78.5280199, 58.825580001],
  [-78.52459, 58.825260001],
  [-78.5251199, 58.824759901],
  [-78.5231299, 58.824970101],
  [-78.5232001, 58.823950101],
  [-78.5246799, 58.823979901],
  [-78.5250901, 58.821680001],
  [-78.5327799, 58.821190101],
  [-78.5333101, 58.820560001],
  [-78.5382101, 58.821170101],
  [-78.53816, 58.821930001],
  [-78.53937, 58.822209901],
  [-78.53909, 58.822720001],
  [-78.54008, 58.822730101],
  [-78.5399901, 58.824020001],
  [-78.53697, 58.824730101],
  [-78.53842, 58.825270001],
  [-78.5396601, 58.828620101],
  [-78.5376899, 58.828580001],
  [-78.5385501, 58.830130001],
  [-78.5395501, 58.830150001],
  [-78.54071, 58.831199901],
  [-78.53826, 58.834230001],
  [-78.53926, 58.834250001],
  [-78.53826, 58.834480001],
  [-78.53862, 58.836290101],
  [-78.5376501, 58.836010001],
  [-78.53851, 58.837820001],
  [-78.5361754, 58.838024301],
  [-78.54059, 58.839900001],
  [-78.54126, 58.840810001],
  [-78.54518, 58.841270001],
  [-78.5446501, 58.841769901],
  [-78.5475799, 58.842209901],
  [-78.5486701, 58.840830001],
  [-78.55163, 58.840879901],
  [-78.5516, 58.841390001],
  [-78.5525799, 58.841410101],
  [-78.55387, 58.844250001],
  [-78.5568801, 58.843539901],
  [-78.55654, 58.844820001],
  [-78.5576899, 58.846119901],
  [-78.55517, 58.846840001],
  [-78.55483, 58.848109901],
  [-78.5572201, 58.849180001],
  [-78.5563599, 58.850960001],
  [-78.5553699, 58.850940001],
  [-78.55553, 58.852219901],
  [-78.5572399, 58.852510001],
  [-78.55743, 58.853280001],
  [-78.5561399, 58.854030001],
  [-78.55907, 58.854590001],
  [-78.55799, 58.855850001],
  [-78.5570001, 58.855840001],
  [-78.5583699, 58.857400001],
  [-78.55538, 58.857850001],
  [-78.55432, 58.858860101],
  [-78.55531, 58.858880101],
  [-78.5540199, 58.859620001],
  [-78.5542301, 58.860140001],
  [-78.55273, 58.860360001],
  [-78.55163, 58.861880101],
  [-78.55262, 58.861899901],
  [-78.55207, 58.862660101],
  [-78.55454, 58.862700001],
  [-78.55397, 58.863720001],
  [-78.5565, 58.862999901],
  [-78.55553, 58.862720001],
  [-78.5555601, 58.862210101],
  [-78.55754, 58.862250001],
  [-78.5575, 58.862760101],
  [-78.55919, 58.863300001],
  [-78.55884, 58.864830001],
  [-78.5627999, 58.864779901],
  [-78.56441, 58.866470001],
  [-78.5647801, 58.868270001],
  [-78.56366, 58.870040001],
  [-78.56431, 58.871339901],
  [-78.5658099, 58.871110101],
  [-78.5671101, 58.873689901],
  [-78.56861, 58.873469901],
  [-78.5681, 58.877300001],
  [-78.56708, 58.877789901],
  [-78.56798, 58.879080001],
  [-78.5676701, 58.879850001],
  [-78.56866, 58.879870101],
  [-78.5691, 58.880650101],
  [-78.5675999, 58.880870101],
  [-78.5664, 58.883919901],
  [-78.56438, 58.884400001],
  [-78.5644401, 58.883630001],
  [-78.56299, 58.883090001],
  [-78.56243, 58.883850001],
  [-78.5614601, 58.883830001],
  [-78.5614, 58.884600001],
  [-78.56041, 58.884579901],
  [-78.56032, 58.885860001],
  [-78.55831, 58.886330101],
  [-78.55875, 58.887110101],
  [-78.5572301, 58.887589901],
  [-78.5621201, 58.888450101],
  [-78.5634, 58.891290001],
  [-78.5649001, 58.891060001],
  [-78.56598, 58.889799901],
  [-78.5669199, 58.890600001],
  [-78.56594, 58.890569901],
  [-78.56586, 58.891589901],
  [-78.56384, 58.892070001],
  [-78.5643, 58.892589901],
  [-78.5618099, 58.892799901],
  [-78.56075, 58.893790101],
  [-78.5597701, 58.893780101],
  [-78.5593401, 58.892750001],
  [-78.5563599, 58.892950001],
  [-78.5564, 58.892440101],
  [-78.55541, 58.892420001],
  [-78.55486, 58.896510001],
  [-78.5575601, 58.896940001],
  [-78.5581, 58.896310001],
  [-78.5571101, 58.896300001],
  [-78.5571499, 58.895780101],
  [-78.5602201, 58.894310001],
  [-78.56963, 58.894239901],
  [-78.5682699, 58.892660101],
  [-78.57021, 58.892960001],
  [-78.57041, 58.893730001],
  [-78.5723501, 58.894280001],
  [-78.57295, 58.896340101],
  [-78.57586, 58.897160001],
  [-78.5758199, 58.897670101],
  [-78.5788001, 58.897469901],
  [-78.57944, 58.899019901],
  [-78.5789101, 58.899520001],
  [-78.5793799, 58.899790101],
  [-78.5790101, 58.901570101],
  [-78.57652, 58.901780101],
  [-78.5764899, 58.902290001],
  [-78.5794799, 58.902090001],
  [-78.5794, 58.903120101],
  [-78.58039, 58.903129901],
  [-78.58074, 58.905190001],
  [-78.57876, 58.905160001],
  [-78.5806801, 58.905960001],
  [-78.5796999, 58.905940001],
  [-78.5795899, 58.907469901],
  [-78.5785899, 58.907450101],
  [-78.57941, 58.910029901],
  [-78.5804, 58.910050001],
  [-78.5803401, 58.910819901],
  [-78.5790299, 58.910880001],
  [-78.5783799, 58.914110101],
  [-78.57886, 58.914369901],
  [-78.5777099, 58.916650001],
  [-78.57841, 58.917180001],
  [-78.57743, 58.916910101],
  [-78.57584, 58.918670101],
  [-78.57484, 58.918650001],
  [-78.57529, 58.919430001],
  [-78.57228, 58.919880001],
  [-78.5722301, 58.920650001],
  [-78.5712401, 58.920630001],
  [-78.56965, 58.922140101],
  [-78.5671399, 58.922599901],
  [-78.56728, 58.920560101],
  [-78.5682699, 58.920699901],
  [-78.56988, 58.918809901],
  [-78.56696, 58.918249901],
  [-78.56699, 58.917730001],
  [-78.56552, 58.917450101],
  [-78.5655601, 58.916939901],
  [-78.5640001, 58.917929901],
  [-78.56408, 58.916910101],
  [-78.5611399, 58.916340101],
  [-78.56072, 58.915310001],
  [-78.5572599, 58.915249901],
  [-78.55721, 58.916010101],
  [-78.55171, 58.916680101],
  [-78.5517999, 58.915400001],
  [-78.54928, 58.916120101],
  [-78.54908, 58.918929901],
  [-78.5471001, 58.918890001],
  [-78.5468801, 58.921960001],
  [-78.54541, 58.921939901],
  [-78.5467899, 58.923240101],
  [-78.54529, 58.923470101],
  [-78.5460099, 58.927320001],
  [-78.5470101, 58.927210001],
  [-78.5486601, 58.928400001],
  [-78.5483699, 58.928910101],
  [-78.5493599, 58.928920101],
  [-78.54874, 58.930700101],
  [-78.55094, 58.931259901],
  [-78.5505899, 58.932520001],
  [-78.5471299, 58.932470101],
  [-78.5459901, 58.934750001],
  [-78.54295, 58.935460101],
  [-78.5430299, 58.934440001],
  [-78.54253, 58.934430001],
  [-78.5424599, 58.935450001],
  [-78.54098, 58.935420001],
  [-78.54228, 58.938010001],
  [-78.54028, 58.938230001],
  [-78.5403499, 58.937200001],
  [-78.5395501, 58.937020101],
  [-78.5387999, 58.938200001],
  [-78.5412201, 58.939010001],
  [-78.54061, 58.940530001],
  [-78.5421101, 58.940310001],
  [-78.5417801, 58.941580101],
  [-78.54248, 58.942110001],
  [-78.5400199, 58.942059901],
  [-78.53963, 58.942729901],
  [-78.54193, 58.942860001],
  [-78.5407601, 58.945399901],
  [-78.54175, 58.945420001],
  [-78.54171, 58.945939901],
  [-78.54072, 58.945930101],
  [-78.54116, 58.946690101],
  [-78.54019, 58.946420001],
  [-78.5402499, 58.945650001],
  [-78.53781, 58.945090001],
  [-78.53919, 58.944260101],
  [-78.53893, 58.943320001],
  [-78.5399199, 58.943340001],
  [-78.5379901, 58.942540001],
  [-78.53794, 58.943310001],
  [-78.53695, 58.943279901],
  [-78.5365501, 58.945320001],
  [-78.5370299, 58.945590101],
  [-78.53561, 58.948120001],
  [-78.53274, 58.946790001],
  [-78.5331299, 58.944750001],
  [-78.5348901, 58.944399901],
  [-78.5362201, 58.943020101],
  [-78.53416, 58.940670001],
  [-78.5321299, 58.941399901],
  [-78.53194, 58.940630001],
  [-78.53271, 58.940130101],
  [-78.53172, 58.940110001],
  [-78.5317601, 58.939599901],
  [-78.53025, 58.939829901],
  [-78.52959, 58.938780001],
  [-78.53138, 58.938070001],
  [-78.5288999, 58.938010001],
  [-78.52894, 58.937499901],
  [-78.52592, 58.938200001],
  [-78.52549, 58.937180001],
  [-78.51918, 58.935389901],
  [-78.51269, 58.936159901],
  [-78.5127601, 58.935140101],
  [-78.50838, 58.934030101],
  [-78.5125401, 58.931290001],
  [-78.50459, 58.931660001],
  [-78.5034299, 58.930609901],
  [-78.50481, 58.928580101],
  [-78.5087699, 58.928660001],
  [-78.50881, 58.928149901],
  [-78.51029, 58.928180001],
  [-78.51036, 58.927149901],
  [-78.51135, 58.927170001],
  [-78.5105499, 58.924599901],
  [-78.50906, 58.924570101],
  [-78.50862, 58.923800101],
  [-78.50637, 58.924010101],
  [-78.50568, 58.923479901],
  [-78.50768, 58.923010101],
  [-78.50524, 58.922699901],
  [-78.50538, 58.920660001],
  [-78.5044, 58.920640001],
  [-78.50371, 58.919850001],
  [-78.5044101, 58.917049901],
  [-78.50313, 58.914210001],
  [-78.50583, 58.907610001],
  [-78.5073101, 58.907640001],
  [-78.5106401, 58.906160001],
  [-78.5109801, 58.904890101],
  [-78.5150801, 58.902919901],
  [-78.5112199, 58.901560101],
  [-78.5125301, 58.900570101],
  [-78.5137, 58.898029901],
  [-78.5135, 58.897259901],
  [-78.51151, 58.897469901],
  [-78.5115499, 58.896960001],
  [-78.5105599, 58.896940001],
  [-78.5120801, 58.896459901],
  [-78.5118899, 58.895680101],
  [-78.5147601, 58.893569901],
  [-78.5157601, 58.893459901],
  [-78.5164, 58.891420001],
  [-78.5154, 58.891400001],
  [-78.5177, 58.887090001],
  [-78.5227701, 58.885270001],
  [-78.52525, 58.885190001],
  [-78.52485, 58.883899901],
  [-78.51891, 58.884029901],
  [-78.51848, 58.883009901],
  [-78.51794, 58.883770101],
  [-78.51695, 58.883740001],
  [-78.51604, 58.886040001],
  [-78.5113201, 58.889789901],
  [-78.5091901, 58.895380001],
  [-78.5052299, 58.895300001],
  [-78.5046401, 58.896830001],
  [-78.50315, 58.896799901],
  [-78.5040999, 58.897330101],
  [-78.4937599, 58.896620001],
  [-78.4927, 58.897620001],
  [-78.4907, 58.897840001],
  [-78.49026, 58.897060001],
  [-78.48927, 58.897039901],
  [-78.48935, 58.896019901],
  [-78.47903, 58.895050001],
  [-78.47956, 58.894550101],
  [-78.4776201, 58.894000101],
  [-78.4771999, 58.892970001],
  [-78.4820701, 58.891870001],
  [-78.4763199, 58.891410001],
  [-78.4774199, 58.883239901],
  [-78.4784001, 58.883260001],
  [-78.4775299, 58.881700001],
  [-78.4785101, 58.881730001],
  [-78.4785101, 58.878400001],
  [-78.4827, 58.875150001],
  [-78.4824101, 58.872330101],
  [-78.4841701, 58.868520001],
  [-78.4837, 58.868260001],
  [-78.48627, 58.863439901],
  [-78.4877301, 58.863720001],
  [-78.48927, 58.862990101],
  [-78.48923, 58.863500001],
  [-78.4913001, 58.862260001],
  [-78.4903101, 58.862239901],
  [-78.49234, 58.861510001],
  [-78.4926599, 58.860490001],
  [-78.4944101, 58.860270001],
  [-78.4954201, 58.863370001],
  [-78.49614, 58.863630001],
  [-78.4961801, 58.863119901],
  [-78.49717, 58.863140001],
  [-78.4969801, 58.862370001],
  [-78.4987599, 58.861630001],
  [-78.4999899, 58.858329901],
  [-78.50249, 58.857870101],
  [-78.5025301, 58.857349901],
  [-78.5059999, 58.857160001],
  [-78.50751, 58.853350001],
  [-78.50905, 58.852610001],
  [-78.50859, 58.852090101],
  [-78.5089801, 58.847830001],
  [-78.50816, 58.847730001],
  [-78.5070099, 58.850009901],
  [-78.5045401, 58.849960001],
  [-78.50493, 58.848180001],
  [-78.50595, 58.847690001],
  [-78.50558, 58.845900001],
  [-78.50692, 58.844380001],
  [-78.50593, 58.844360001],
  [-78.50535, 58.845630101],
  [-78.50436, 58.845610001],
  [-78.5007501, 58.851170001],
  [-78.50094, 58.851950001],
  [-78.49995, 58.851930001],
  [-78.49825, 58.854970101],
  [-78.49524, 58.855679901],
  [-78.4930999, 58.857940001],
  [-78.4910801, 58.858420101],
  [-78.49105, 58.858920001],
  [-78.48756, 58.859370001],
  [-78.4875, 58.860140001],
  [-78.48245, 58.861569901],
  [-78.4824199, 58.862080101],
  [-78.47892, 58.862530101],
  [-78.4792801, 58.860999901],
  [-78.4781801, 58.859190101],
  [-78.47722, 58.858779901],
  [-78.47224, 58.859329901],
  [-78.4719401, 58.863420101],
  [-78.4709501, 58.863400001],
  [-78.47147, 58.866480001],
  [-78.4706301, 58.867740001],
  [-78.4736101, 58.867550101],
  [-78.4741899, 58.866270001],
  [-78.47568, 58.866310101],
  [-78.4756399, 58.866820001],
  [-78.4766301, 58.866840001],
  [-78.47578, 58.868360001],
  [-78.477, 58.868640101],
  [-78.4776599, 58.869669901],
  [-78.4773199, 58.870950001],
  [-78.4758401, 58.870920001],
  [-78.47578, 58.871689901],
  [-78.4748, 58.871669901],
  [-78.4747599, 58.872180001],
  [-78.4759799, 58.872459901],
  [-78.47567, 58.873220101],
  [-78.4771701, 58.872990101],
  [-78.47681, 58.873330101],
  [-78.4775201, 58.875050001],
  [-78.4755399, 58.875019901],
  [-78.4759601, 58.876040001],
  [-78.4739501, 58.876520001],
  [-78.4768699, 58.877080001],
  [-78.47678, 58.878370001],
  [-78.47579, 58.878339901],
  [-78.4746301, 58.880630001],
  [-78.4736499, 58.880610001],
  [-78.47627, 58.881940001],
  [-78.4756399, 58.887300001],
  [-78.4746499, 58.887280001],
  [-78.47349, 58.889579901],
  [-78.4719899, 58.889789901],
  [-78.4714001, 58.891060001],
  [-78.46992, 58.891019901],
  [-78.4686499, 58.894850001],
  [-78.4604001, 58.897080001],
  [-78.45457, 58.897900101],
  [-78.45455, 58.898410001],
  [-78.4605199, 58.897760001],
  [-78.46755, 58.896359901],
  [-78.4675101, 58.897129901],
  [-78.46848, 58.897149901],
  [-78.46792, 58.898170001],
  [-78.4634099, 58.898840001],
  [-78.46233, 58.900100001],
  [-78.4633199, 58.900120101],
  [-78.46279, 58.900620001],
  [-78.46377, 58.900630001],
  [-78.4637399, 58.901149901],
  [-78.4661999, 58.901460101],
  [-78.4667201, 58.900960001],
  [-78.46666, 58.901720001],
  [-78.4686499, 58.901630001],
  [-78.46958, 58.902420001],
  [-78.47182, 58.902340101],
  [-78.47181, 58.899010101],
  [-78.4728, 58.899029901],
  [-78.47312, 58.898010101],
  [-78.47533, 58.898300001],
  [-78.4743199, 58.898540001],
  [-78.47524, 58.899460101],
  [-78.4821999, 58.898950001],
  [-78.4828799, 58.899990001],
  [-78.4814299, 58.902780101],
  [-78.47948, 58.902490001],
  [-78.4794001, 58.903510001],
  [-78.4784101, 58.903490001],
  [-78.47581, 58.905230101],
  [-78.47433, 58.905200001],
  [-78.4742901, 58.905709901],
  [-78.47526, 58.905990001],
  [-78.47378, 58.905960001],
  [-78.4731899, 58.907230101],
  [-78.4721999, 58.907210001],
  [-78.4706101, 58.908709901],
  [-78.46667, 58.908379901],
  [-78.46671, 58.907870001],
  [-78.4651899, 58.908350101],
  [-78.46481, 58.906809901],
  [-78.46237, 58.906249901],
  [-78.4612901, 58.907510001],
  [-78.4587499, 58.908479901],
  [-78.46533, 58.909890101],
  [-78.4649601, 58.911420001],
  [-78.4688301, 58.912780101],
  [-78.46944, 58.914580101],
  [-78.46811, 58.915840001],
  [-78.4636399, 58.916000101],
  [-78.4627201, 58.914960001],
  [-78.4617399, 58.914939901],
  [-78.46278, 58.914190001],
  [-78.4519599, 58.913210001],
  [-78.45158, 58.911670101],
  [-78.4491601, 58.911110101],
  [-78.4513099, 58.908589901],
  [-78.4488201, 58.908799901],
  [-78.44791, 58.907760001],
  [-78.44643, 58.907730001],
  [-78.4469599, 58.907230101],
  [-78.44546, 58.907450101],
  [-78.4450601, 58.906160001],
  [-78.4470699, 58.905689901],
  [-78.4391799, 58.905280001],
  [-78.43876, 58.904249901],
  [-78.4372799, 58.904220101],
  [-78.4354901, 58.901620001],
  [-78.43402, 58.901589901],
  [-78.43344, 58.900390001],
  [-78.4280599, 58.901730001],
  [-78.42476, 58.906270001],
  [-78.42377, 58.906249901],
  [-78.4231799, 58.907520001],
  [-78.4171699, 58.908430001],
  [-78.4171301, 58.908929901],
  [-78.41111, 58.910110101],
  [-78.41107, 58.910610001],
  [-78.40711, 58.910530001],
  [-78.4071501, 58.910020101],
  [-78.40419, 58.909950001],
  [-78.40422, 58.909450101],
  [-78.39186, 58.909330101],
  [-78.3899299, 58.908640001],
  [-78.39155, 58.906890101],
  [-78.3895701, 58.906850001],
  [-78.3890101, 58.907599901],
  [-78.38706, 58.907310001],
  [-78.3876701, 58.905780101],
  [-78.38372, 58.905699901],
  [-78.38417, 58.906230101],
  [-78.3821599, 58.906699901],
  [-78.3822699, 58.905160001],
  [-78.38129, 58.905139901],
  [-78.3843501, 58.903799901],
  [-78.3858299, 58.903950001],
  [-78.38587, 58.903440101],
  [-78.3876999, 58.903060001],
  [-78.38741, 58.902709901],
  [-78.3933401, 58.902830001],
  [-78.3930201, 58.900510001],
  [-78.3940101, 58.900530001],
  [-78.3938001, 58.900019901],
  [-78.3951401, 58.898760001],
  [-78.38918, 58.898900101],
  [-78.38922, 58.898390001],
  [-78.3877199, 58.898620001],
  [-78.3872599, 58.898090001],
  [-78.38722, 58.898610001],
  [-78.3842, 58.899310001],
  [-78.38409, 58.900850001],
  [-78.38161, 58.900799901],
  [-78.37928, 58.902290001],
  [-78.3789299, 58.903560101],
  [-78.37794, 58.903540001],
  [-78.3773599, 58.904550101],
  [-78.36698, 58.904469901],
  [-78.36362, 58.905380001],
  [-78.3665501, 58.906890101],
  [-78.3661499, 58.908929901],
  [-78.3636999, 58.908630001],
  [-78.3641499, 58.909149901],
  [-78.36216, 58.909359901],
  [-78.36109, 58.910369901],
  [-78.35963, 58.910080001],
  [-78.35831, 58.911080001],
  [-78.35709, 58.914130101],
  [-78.35929, 58.914430001],
  [-78.3571001, 58.917200001],
  [-78.35562, 58.917170001],
  [-78.35493, 58.919719901],
  [-78.35394, 58.919690101],
  [-78.35438, 58.920470101],
  [-78.3523599, 58.920939901],
  [-78.3517899, 58.921960001],
  [-78.3507999, 58.921939901],
  [-78.3502399, 58.922690101],
  [-78.34427, 58.923080001],
  [-78.34416, 58.922400001],
  [-78.3433399, 58.922290001],
  [-78.3432399, 58.923570101],
  [-78.3392299, 58.924259901],
  [-78.3386601, 58.925269901],
  [-78.3270801, 58.927730001],
  [-78.3176501, 58.927910101],
  [-78.3170999, 58.928670001],
  [-78.3116401, 58.928810101],
  [-78.3116, 58.929330001],
  [-78.30616, 58.929210001],
  [-78.30612, 58.929719901],
  [-78.30114, 58.930000001],
  [-78.29559, 58.931290001],
  [-78.29504, 58.932049901],
  [-78.28824, 58.932499901],
  [-78.2949501, 58.933070001],
  [-78.295, 58.932560001],
  [-78.2979601, 58.932750001],
  [-78.3057501, 58.931250101],
  [-78.30604, 58.930740001],
  [-78.3115399, 58.930090001],
  [-78.31158, 58.929580101],
  [-78.3284499, 58.929159901],
  [-78.32849, 58.928650001],
  [-78.3324301, 58.928980001],
  [-78.33239, 58.929489901],
  [-78.33338, 58.929520001],
  [-78.3333201, 58.930279901],
  [-78.3303299, 58.930479901],
  [-78.33037, 58.929970001],
  [-78.32938, 58.929950001],
  [-78.3290901, 58.930450001],
  [-78.33319, 58.931949901],
  [-78.3406, 58.932220001],
  [-78.3394499, 58.934250101],
  [-78.33797, 58.934220001],
  [-78.33785, 58.935750001],
  [-78.33429, 58.936960001],
  [-78.33385, 58.938400001],
  [-78.33563, 58.938780001],
  [-78.33318, 58.938470101],
  [-78.3331199, 58.939240101],
  [-78.3321299, 58.939220001],
  [-78.33219, 58.938450001],
  [-78.3317, 58.938440001],
  [-78.3316, 58.939719901],
  [-78.3301299, 58.939440001],
  [-78.33091, 58.942269901],
  [-78.3323299, 58.943070001],
  [-78.3343101, 58.943110001],
  [-78.3309801, 58.944570001],
  [-78.33219, 58.944860001],
  [-78.33215, 58.945370101],
  [-78.3336601, 58.945140101],
  [-78.33362, 58.945650001],
  [-78.33607, 58.945960001],
  [-78.33562, 58.945440001],
  [-78.3380901, 58.945480101],
  [-78.33908, 58.943289901],
  [-78.33728, 58.943169901],
  [-78.3363499, 58.942379901],
  [-78.33883, 58.942300001],
  [-78.33975, 58.943220001],
  [-78.3403201, 58.942210001],
  [-78.3417799, 58.942619901],
  [-78.3470944, 58.942176801],
  [-78.34774, 58.942360101],
  [-78.346421, 58.943102401],
  [-78.347674, 58.942760801],
  [-78.34962, 58.943810101],
  [-78.3511101, 58.943709901],
  [-78.3504, 58.946499901],
  [-78.34581, 58.948210001],
  [-78.34518, 58.949990001],
  [-78.34419, 58.949970001],
  [-78.3444699, 58.952790001],
  [-78.3434599, 58.953030101],
  [-78.3430199, 58.952250101],
  [-78.34051, 58.952710101],
  [-78.33936, 58.954729901],
  [-78.33837, 58.954710101],
  [-78.33882, 58.955250101],
  [-78.3373399, 58.955210001],
  [-78.3377799, 58.955980001],
  [-78.33926, 58.956010001],
  [-78.33962, 58.957810101],
  [-78.33716, 58.957509901],
  [-78.33605, 58.959020001],
  [-78.33706, 58.958780001],
  [-78.3370199, 58.959300001],
  [-78.33874, 58.959590101],
  [-78.3378999, 58.960849901],
  [-78.3348899, 58.961299901],
  [-78.3354599, 58.960289901],
  [-78.33249, 58.960230001],
  [-78.3325501, 58.959470101],
  [-78.3315699, 58.959310001],
  [-78.33006, 58.959660001],
  [-78.32996, 58.960940101],
  [-78.32847, 58.960910001],
  [-78.3286, 58.961590101],
  [-78.32992, 58.961450001],
  [-78.32963, 58.961959901],
  [-78.33085, 58.962240001],
  [-78.33128, 58.963020001],
  [-78.3293, 58.962980001],
  [-78.3297, 58.964260101],
  [-78.32671, 58.964460001],
  [-78.32717, 58.964980001],
  [-78.3253201, 58.965370101],
  [-78.32663, 58.965480101],
  [-78.32659, 58.965990001],
  [-78.3245699, 58.966470001],
  [-78.3249701, 58.965540001],
  [-78.32416, 58.965440001],
  [-78.32324, 58.966610101],
  [-78.32429, 58.966969901],
  [-78.3239801, 58.967729901],
  [-78.32047, 58.968169901],
  [-78.31924, 58.971220001],
  [-78.31826, 58.971200001],
  [-78.3182099, 58.971710101],
  [-78.31918, 58.971980001],
  [-78.3181901, 58.971969901],
  [-78.31748, 58.974770001],
  [-78.3109999, 58.975150101],
  [-78.3109601, 58.975660001],
  [-78.3074125, 58.975415301],
  [-78.3054001, 58.977090001],
  [-78.3044101, 58.977060101],
  [-78.3029, 58.979500101],
  [-78.3031801, 58.980110001],
  [-78.30268, 58.980100001],
  [-78.3018501, 58.978030001],
  [-78.2999, 58.977480001],
  [-78.29935, 58.978230001],
  [-78.2968601, 58.978440001],
  [-78.29648, 58.976890001],
  [-78.29549, 58.976870001],
  [-78.2926599, 58.975020001],
  [-78.29238, 58.975529901],
  [-78.2930701, 58.976310001],
  [-78.2845399, 58.977409901],
  [-78.2837499, 58.978160101],
  [-78.28392, 58.978930101],
  [-78.28493, 58.978700001],
  [-78.28438, 58.979460001],
  [-78.28238, 58.979670001],
  [-78.28378, 58.980730101],
  [-78.28126, 58.981179901],
  [-78.28244, 58.985309901],
  [-78.28144, 58.985280101],
  [-78.2813199, 58.986820101],
  [-78.28033, 58.986800001],
  [-78.28227, 58.987350001],
  [-78.2822099, 58.988120001],
  [-78.28516, 58.988450001],
  [-78.2865399, 58.989759901],
  [-78.28859, 58.989030001],
  [-78.2885399, 58.989529901],
  [-78.2895399, 58.989550001],
  [-78.29013, 58.988280101],
  [-78.29269, 58.987309901],
  [-78.2929, 58.990900001],
  [-78.29191, 58.990880001],
  [-78.2927599, 58.992690001],
  [-78.2917699, 58.992670001],
  [-78.2906399, 58.994440001],
  [-78.2916301, 58.994460001],
  [-78.2910601, 58.995470001],
  [-78.288, 58.996429901],
  [-78.2889501, 58.996969901],
  [-78.2855001, 58.996880001],
  [-78.2864299, 58.997550001],
  [-78.28792, 58.997460001],
  [-78.2897599, 58.999290101],
  [-78.2867499, 58.999740101],
  [-78.2876399, 59.001040001],
  [-78.2866499, 59.001010001],
  [-78.28746, 59.003340001],
  [-78.28647, 59.003319901],
  [-78.2863901, 59.004340001],
  [-78.28789, 59.004110001],
  [-78.2878501, 59.004630101],
  [-78.29171, 59.002910001],
  [-78.29202, 59.002150001],
  [-78.2937, 59.002960101],
  [-78.2948, 59.004639901],
  [-78.29578, 59.004790001],
  [-78.2937401, 59.005520101],
  [-78.2951701, 59.006309901],
  [-78.2952099, 59.005800001],
  [-78.2971901, 59.005840101],
  [-78.2957401, 59.007510101],
  [-78.2970899, 59.007120001],
  [-78.29703, 59.007890001],
  [-78.29604, 59.007869901],
  [-78.29536, 59.010160001],
  [-78.2948699, 59.010150001],
  [-78.29548, 59.008630101],
  [-78.29449, 59.008610101],
  [-78.294, 59.006380001],
  [-78.2931901, 59.006270001],
  [-78.2936201, 59.007050001],
  [-78.2926201, 59.007030001],
  [-78.29257, 59.007800001],
  [-78.29078, 59.008539901],
  [-78.2913001, 59.011360001],
  [-78.2947301, 59.011940001],
  [-78.29426, 59.014750101],
  [-78.29538, 59.016309901],
  [-78.29637, 59.016329901],
  [-78.2954199, 59.018879901],
  [-78.29713, 59.019170001],
  [-78.2965, 59.020940001],
  [-78.2949701, 59.021420101],
  [-78.2974101, 59.021979901],
  [-78.29847, 59.021240001],
  [-78.2989, 59.022010001],
  [-78.29791, 59.021989901],
  [-78.29701, 59.024020001],
  [-78.30068, 59.024610001],
  [-78.3006401, 59.025120001],
  [-78.3016599, 59.025020001],
  [-78.3016, 59.025659901],
  [-78.30259, 59.025680001],
  [-78.3036301, 59.028260001],
  [-78.3048501, 59.028539901],
  [-78.3045599, 59.029050001],
  [-78.30701, 59.032680001],
  [-78.30872, 59.032970101],
  [-78.3084101, 59.033740101],
  [-78.3094101, 59.033760101],
  [-78.3097599, 59.035559901],
  [-78.3127, 59.036130001],
  [-78.3123299, 59.037650101],
  [-78.3136, 59.037430101],
  [-78.31279, 59.038180001],
  [-78.3128701, 59.040490001],
  [-78.30984, 59.040940001],
  [-78.3097599, 59.041960001],
  [-78.3142199, 59.042050001],
  [-78.31416, 59.042820001],
  [-78.3163199, 59.043889901],
  [-78.3159899, 59.044899901],
  [-78.31748, 59.044930001],
  [-78.31658, 59.045850001],
  [-78.31783, 59.046730001],
  [-78.31492, 59.045910001],
  [-78.31472, 59.048459901],
  [-78.31571, 59.048480001],
  [-78.31584, 59.050020001],
  [-78.3145201, 59.051019901],
  [-78.31303, 59.050990101],
  [-78.31146, 59.054200101],
  [-78.3132299, 59.054830001],
  [-78.3126501, 59.055850001],
  [-78.3116599, 59.055830001],
  [-78.3120901, 59.056600001],
  [-78.3130901, 59.056620001],
  [-78.31391, 59.058820001],
  [-78.31602, 59.057200101],
  [-78.31702, 59.057219901],
  [-78.31706, 59.056710001],
  [-78.31902, 59.056999901],
  [-78.3177899, 59.060050001],
  [-78.3187799, 59.060070001],
  [-78.3181901, 59.061339901],
  [-78.31614, 59.062060001],
  [-78.3155201, 59.063590001],
  [-78.3119701, 59.064540101],
  [-78.31227, 59.060700001],
  [-78.3143001, 59.060220101],
  [-78.31438, 59.059200101],
  [-78.3109, 59.059140001],
  [-78.3104399, 59.058630001],
  [-78.3114399, 59.058640101],
  [-78.3115, 59.057870101],
  [-78.3099701, 59.058349901],
  [-78.3100701, 59.057070001],
  [-78.30659, 59.056999901],
  [-78.30725, 59.054970001],
  [-78.3112199, 59.055050001],
  [-78.3111801, 59.055559901],
  [-78.31206, 59.054899901],
  [-78.3102901, 59.054250001],
  [-78.31037, 59.053229901],
  [-78.30735, 59.053690001],
  [-78.30781, 59.054210101],
  [-78.30683, 59.053930001],
  [-78.30679, 59.054440101],
  [-78.3033199, 59.054370001],
  [-78.30336, 59.053860101],
  [-78.30483, 59.054150001],
  [-78.3044101, 59.053109901],
  [-78.3024299, 59.053070001],
  [-78.30237, 59.053840001],
  [-78.2993299, 59.054540101],
  [-78.2997699, 59.055320101],
  [-78.2977599, 59.055540101],
  [-78.2977301, 59.056050001],
  [-78.3037, 59.055920001],
  [-78.3020801, 59.057420101],
  [-78.29815, 59.056830001],
  [-78.29913, 59.057100101],
  [-78.2983199, 59.057850001],
  [-78.30149, 59.058689901],
  [-78.29849, 59.058880101],
  [-78.29838, 59.058200101],
  [-78.29659, 59.057820001],
  [-78.29767, 59.059889901],
  [-78.2951901, 59.062909901],
  [-78.2961801, 59.062930001],
  [-78.29614, 59.063440101],
  [-78.2900999, 59.064469901],
  [-78.28812, 59.064310101],
  [-78.2881999, 59.063290001],
  [-78.2851899, 59.063469901],
  [-78.2860799, 59.065019901],
  [-78.2850799, 59.065000101],
  [-78.2847301, 59.063200101],
  [-78.28326, 59.062920001],
  [-78.28334, 59.061889901],
  [-78.2848301, 59.061920001],
  [-78.28489, 59.061160001],
  [-78.2809699, 59.060310101],
  [-78.28102, 59.059799901],
  [-78.27849, 59.060380001],
  [-78.2760501, 59.059689901],
  [-78.27804, 59.059730001],
  [-78.27966, 59.057980101],
  [-78.2781801, 59.057940001],
  [-78.27779, 59.056660101],
  [-78.28082, 59.055950001],
  [-78.2795399, 59.053360001],
  [-78.2805299, 59.053390001],
  [-78.28057, 59.052870101],
  [-78.2790601, 59.053100101],
  [-78.2806101, 59.052370001],
  [-78.2771999, 59.051520001],
  [-78.27548, 59.048160001],
  [-78.27356, 59.047349901],
  [-78.27455, 59.047370001],
  [-78.2746299, 59.046349901],
  [-78.2657399, 59.045650101],
  [-78.2658201, 59.047710001],
  [-78.2674001, 59.049779901],
  [-78.2689, 59.049549901],
  [-78.26934, 59.050329901],
  [-78.26835, 59.050310101],
  [-78.2682999, 59.050820001],
  [-78.2638401, 59.050730001],
  [-78.2634099, 59.049950001],
  [-78.2644199, 59.049720001],
  [-78.26343, 59.049690001],
  [-78.2635101, 59.048669901],
  [-78.25809, 59.048050001],
  [-78.25847, 59.049459901],
  [-78.26046, 59.049500001],
  [-78.2613999, 59.050160001],
  [-78.25746, 59.049700001],
  [-78.2565001, 59.051430101],
  [-78.25679, 59.051860101],
  [-78.2559401, 59.052180001],
  [-78.2561899, 59.053129901],
  [-78.25468, 59.053349901],
  [-78.2556199, 59.051999901],
  [-78.25534, 59.051320101],
  [-78.2561701, 59.051250001],
  [-78.2559599, 59.049789901],
  [-78.25679, 59.049720001],
  [-78.2576101, 59.047779901],
  [-78.25414, 59.047710001],
  [-78.253, 59.046400001],
  [-78.2532899, 59.045889901],
  [-78.25033, 59.045580001],
  [-78.25077, 59.046349901],
  [-78.2448399, 59.045970101],
  [-78.24477, 59.046740001],
  [-78.24725, 59.046920001],
  [-78.2481899, 59.047580001],
  [-78.2467001, 59.047549901],
  [-78.24713, 59.048320101],
  [-78.24614, 59.048300101],
  [-78.2461, 59.048820001],
  [-78.24119, 59.048070001],
  [-78.2391501, 59.048669901],
  [-78.23923, 59.047640101],
  [-78.23624, 59.047840001],
  [-78.23612, 59.049119901],
  [-78.2312, 59.048630001],
  [-78.22879, 59.047679901],
  [-78.22888, 59.046659901],
  [-78.22598, 59.045569901],
  [-78.22642, 59.046349901],
  [-78.2249301, 59.046310101],
  [-78.22534, 59.044270001],
  [-78.2243899, 59.043740001],
  [-78.22342, 59.040390001],
  [-78.2219301, 59.040360001],
  [-78.2212699, 59.042390001],
  [-78.21823, 59.043100101],
  [-78.21922, 59.043119901],
  [-78.22119, 59.046490001],
  [-78.2202501, 59.048779901],
  [-78.2212501, 59.048800001],
  [-78.22077, 59.051600001],
  [-78.22179, 59.054440101],
  [-78.22278, 59.054459901],
  [-78.22189, 59.056239901],
  [-78.2253899, 59.059129901],
  [-78.22389, 59.059100101],
  [-78.2242699, 59.060640001],
  [-78.22719, 59.061469901],
  [-78.2271401, 59.061980101],
  [-78.22867, 59.061500001],
  [-78.22956, 59.062799901],
  [-78.2312799, 59.063100101],
  [-78.23143, 59.064380001],
  [-78.23043, 59.064359901],
  [-78.2305901, 59.065390001],
  [-78.2320599, 59.065930001],
  [-78.23223, 59.066700001],
  [-78.23023, 59.066930001],
  [-78.2301699, 59.067679901],
  [-78.2321599, 59.067600001],
  [-78.2315901, 59.068480001],
  [-78.23308, 59.068510001],
  [-78.2320599, 59.069000101],
  [-78.2330401, 59.069019901],
  [-78.23298, 59.069789901],
  [-78.2344701, 59.069820001],
  [-78.2349, 59.070600001],
  [-78.23391, 59.070579901],
  [-78.23478, 59.072129901],
  [-78.2311699, 59.073590001],
  [-78.2311401, 59.074110101],
  [-78.2291501, 59.074050001],
  [-78.2290499, 59.075340101],
  [-78.2300599, 59.075110101],
  [-78.2299, 59.077150001],
  [-78.2319301, 59.076670101],
  [-78.2312899, 59.078459901],
  [-78.2328201, 59.077980001],
  [-78.2327399, 59.079000101],
  [-78.23488, 59.077129901],
  [-78.2365801, 59.077560101],
  [-78.23678, 59.078190001],
  [-78.2392701, 59.078120101],
  [-78.24065, 59.079689901],
  [-78.2381799, 59.079380001],
  [-78.23764, 59.079890101],
  [-78.24008, 59.080440101],
  [-78.2331763, 59.080641101],
  [-78.2313126, 59.081267301],
  [-78.2335586, 59.082347701],
  [-78.2350042, 59.082292401],
  [-78.2349803, 59.081181301],
  [-78.2379313, 59.080819101],
  [-78.2397101, 59.081970001],
  [-78.23911, 59.083239901],
  [-78.24075, 59.084550101],
  [-78.23975, 59.084530001],
  [-78.23969, 59.085300001],
  [-78.2411401, 59.085840001],
  [-78.2391699, 59.085540001],
  [-78.23857, 59.086809901],
  [-78.2375801, 59.086790101],
  [-78.2372501, 59.087809901],
  [-78.2384701, 59.088090001],
  [-78.23887, 59.089380001],
  [-78.2373801, 59.089340101],
  [-78.2368, 59.090359901],
  [-78.2383, 59.090259901],
  [-78.23922, 59.091180001],
  [-78.24077, 59.090440101],
  [-78.2422501, 59.090730001],
  [-78.2421799, 59.091500001],
  [-78.2431699, 59.091520001],
  [-78.2422501, 59.093799901],
  [-78.2427201, 59.094070001],
  [-78.24131, 59.096090001],
  [-78.2449201, 59.097699901],
  [-78.2459301, 59.100540001],
  [-78.2504099, 59.100640001],
  [-78.25034, 59.101400001],
  [-78.24533, 59.101809901],
  [-78.24612, 59.104390001],
  [-78.24513, 59.104359901],
  [-78.2464801, 59.106190001],
  [-78.244, 59.106130101],
  [-78.2433899, 59.107410001],
  [-78.2413999, 59.107360101],
  [-78.24186, 59.107880001],
  [-78.24086, 59.107860001],
  [-78.2408201, 59.108369901],
  [-78.23889, 59.107560101],
  [-78.2380501, 59.105750001],
  [-78.2370501, 59.105730001],
  [-78.2371601, 59.104450101],
  [-78.23521, 59.103900101],
  [-78.2351501, 59.104660001],
  [-78.23413, 59.104900101],
  [-78.2342601, 59.103369901],
  [-78.2322501, 59.103580101],
  [-78.23219, 59.104350101],
  [-78.23022, 59.104060001],
  [-78.2301501, 59.104809901],
  [-78.23167, 59.104589901],
  [-78.23208, 59.105620001],
  [-78.2290599, 59.106070001],
  [-78.2291, 59.105560101],
  [-78.22811, 59.105540001],
  [-78.2281699, 59.104770001],
  [-78.22287, 59.102610001],
  [-78.2229101, 59.102100001],
  [-78.2184801, 59.101489901],
  [-78.21852, 59.100980001],
  [-78.22101, 59.100910101],
  [-78.2220599, 59.100290001],
  [-78.2206099, 59.099740001],
  [-78.2271301, 59.099120101],
  [-78.22746, 59.098100001],
  [-78.2243799, 59.093170001],
  [-78.2252699, 59.091380001],
  [-78.22378, 59.091359901],
  [-78.2233999, 59.089819901],
  [-78.22241, 59.089799901],
  [-78.22245, 59.089280001],
  [-78.22901, 59.088139901],
  [-78.2268399, 59.084259901],
  [-78.22478, 59.085239901],
  [-78.2248101, 59.084730001],
  [-78.22231, 59.084930001],
  [-78.22243, 59.083390001],
  [-78.22144, 59.083369901],
  [-78.2214801, 59.082860001],
  [-78.2259301, 59.083210101],
  [-78.22599, 59.082450101],
  [-78.2210401, 59.082080001],
  [-78.2202401, 59.079760001],
  [-78.21877, 59.079469901],
  [-78.2188101, 59.078960001],
  [-78.2168001, 59.079180001],
  [-78.2163899, 59.078129901],
  [-78.21542, 59.077870001],
  [-78.2153501, 59.078630001],
  [-78.21434, 59.078870001],
  [-78.2134701, 59.077310001],
  [-78.21544, 59.077610001],
  [-78.2154801, 59.077100101],
  [-78.2144801, 59.077080001],
  [-78.2146901, 59.074520001],
  [-78.21322, 59.074229901],
  [-78.21329, 59.073469901],
  [-78.2122699, 59.073699901],
  [-78.20886, 59.069789901],
  [-78.2096901, 59.068780101],
  [-78.2072401, 59.068339901],
  [-78.20367, 59.069420001],
  [-78.204, 59.071469901],
  [-78.20544, 59.072009901],
  [-78.2044501, 59.072000101],
  [-78.2044899, 59.074560101],
  [-78.20344, 59.075300001],
  [-78.20377, 59.077359901],
  [-78.2032301, 59.077860001],
  [-78.21188, 59.078560101],
  [-78.21142, 59.078040001],
  [-78.2119201, 59.078050001],
  [-78.2130599, 59.079349901],
  [-78.21273, 59.080369901],
  [-78.20776, 59.080260001],
  [-78.2071401, 59.081770101],
  [-78.1990499, 59.083400001],
  [-78.20295, 59.084380001],
  [-78.20428, 59.086330101],
  [-78.1993799, 59.085459901],
  [-78.2003501, 59.085740001],
  [-78.2000399, 59.086500001],
  [-78.20051, 59.086760001],
  [-78.20018, 59.087780101],
  [-78.1986701, 59.088000101],
  [-78.1995999, 59.088790101],
  [-78.19566, 59.088450101],
  [-78.19573, 59.087430001],
  [-78.1932699, 59.087120101],
  [-78.1931599, 59.088400001],
  [-78.19118, 59.088349901],
  [-78.19129, 59.087080001],
  [-78.1907901, 59.087070001],
  [-78.1902, 59.088080001],
  [-78.1886899, 59.088300001],
  [-78.18962, 59.089090001],
  [-78.1881101, 59.089310001],
  [-78.18818, 59.088550101],
  [-78.18617, 59.088770101],
  [-78.1861101, 59.089530001],
  [-78.18207, 59.090210001],
  [-78.1820101, 59.090970001],
  [-78.1800199, 59.090929901],
  [-78.17998, 59.091440101],
  [-78.17151, 59.091510001],
  [-78.1714699, 59.092019901],
  [-78.1584499, 59.093019901],
  [-78.1577601, 59.092240101],
  [-78.15805, 59.091730001],
  [-78.15659, 59.091440101],
  [-78.15649, 59.090500001],
  [-78.15518, 59.090390001],
  [-78.1541001, 59.091380001],
  [-78.1553101, 59.091670101],
  [-78.15549, 59.092689901],
  [-78.1465, 59.093010101],
  [-78.1464599, 59.093520001],
  [-78.14252, 59.093050001],
  [-78.1354599, 59.094180001],
  [-78.1299701, 59.094310001],
  [-78.1205399, 59.093980001],
  [-78.12058, 59.093460101],
  [-78.11762, 59.093139901],
  [-78.1176699, 59.092630001],
  [-78.1161801, 59.092599901],
  [-78.1162199, 59.092090001],
  [-78.11034, 59.090929901],
  [-78.11039, 59.090420001],
  [-78.09948, 59.089919901],
  [-78.10158, 59.088689901],
  [-78.0996101, 59.088390001],
  [-78.0996499, 59.087880101],
  [-78.09668, 59.087680101],
  [-78.0916399, 59.088460101],
  [-78.0917301, 59.087440101],
  [-78.0901999, 59.087919901],
  [-78.0907599, 59.087170001],
  [-78.08332, 59.086880101],
  [-78.0803901, 59.086160001],
  [-78.07981, 59.087170001],
  [-78.08425, 59.087530001],
  [-78.08471, 59.088050001],
  [-78.08325, 59.087760001],
  [-78.0821601, 59.088770101],
  [-78.0935399, 59.089530001],
  [-78.0935001, 59.090039901],
  [-78.09692, 59.090630001],
  [-78.0969799, 59.090120101],
  [-78.1073001, 59.091630001],
  [-78.10726, 59.092139901],
  [-78.11413, 59.093320001],
  [-78.1143101, 59.094090001],
  [-78.11794, 59.095330101],
  [-78.1244299, 59.095090001],
  [-78.12436, 59.095850001],
  [-78.12337, 59.095830001],
  [-78.12528, 59.096780101],
  [-78.1311901, 59.097540001],
  [-78.1308799, 59.098300001],
  [-78.13204, 59.099350101],
  [-78.13005, 59.099310001],
  [-78.12996, 59.100330001],
  [-78.12746, 59.100530001],
  [-78.1270901, 59.098990001],
  [-78.1251099, 59.098819901],
  [-78.1232299, 59.099500001],
  [-78.1235, 59.100190001],
  [-78.1209999, 59.100390001],
  [-78.1195944, 59.099735201],
  [-78.1182934, 59.100144201],
  [-78.11336, 59.105340101],
  [-78.1149701, 59.106910101],
  [-78.1159701, 59.106939901],
  [-78.11634, 59.108479901],
  [-78.11534, 59.108460101],
  [-78.1157699, 59.109240101],
  [-78.11824, 59.109550001],
  [-78.11817, 59.110320001],
  [-78.1082099, 59.110350101],
  [-78.10813, 59.111369901],
  [-78.10912, 59.111390001],
  [-78.10994, 59.113460101],
  [-78.11094, 59.113479901],
  [-78.11035, 59.114489901],
  [-78.0985001, 59.113330001],
  [-78.0977201, 59.113699901],
  [-78.09793, 59.114220001],
  [-78.1003901, 59.114530001],
  [-78.10035, 59.115039901],
  [-78.1033099, 59.115360101],
  [-78.10279, 59.115599901],
  [-78.1041701, 59.116920101],
  [-78.1080801, 59.117770001],
  [-78.10813, 59.117259901],
  [-78.1136599, 59.116620001],
  [-78.11372, 59.115840001],
  [-78.1236699, 59.116070001],
  [-78.12529, 59.114570101],
  [-78.1297401, 59.114920101],
  [-78.1297799, 59.114410001],
  [-78.1285599, 59.114130101],
  [-78.12837, 59.113360101],
  [-78.12937, 59.113379901],
  [-78.1289717, 59.112478901],
  [-78.1205, 59.112290001],
  [-78.11962, 59.110860001],
  [-78.1211099, 59.110890001],
  [-78.1216699, 59.110139901],
  [-78.1173299, 59.108500001],
  [-78.1195499, 59.105730001],
  [-78.1187242, 59.104421801],
  [-78.1214299, 59.101170001],
  [-78.12447, 59.100589901],
  [-78.12792, 59.100929901],
  [-78.12926, 59.102880001],
  [-78.13081, 59.102139901],
  [-78.13596, 59.103020101],
  [-78.1370801, 59.101510001],
  [-78.13696, 59.099970001],
  [-78.1359701, 59.099950001],
  [-78.1370901, 59.098440001],
  [-78.1356, 59.098410001],
  [-78.1356401, 59.097900101],
  [-78.1366401, 59.097919901],
  [-78.1354599, 59.097120101],
  [-78.1355201, 59.096359901],
  [-78.13729, 59.096010101],
  [-78.13774, 59.096660001],
  [-78.13627, 59.096369901],
  [-78.1372101, 59.097029901],
  [-78.1426601, 59.097280001],
  [-78.1427501, 59.096259901],
  [-78.14518, 59.096950001],
  [-78.14819, 59.096760001],
  [-78.1531001, 59.097510001],
  [-78.15305, 59.098020101],
  [-78.1545599, 59.097929901],
  [-78.15597, 59.098860001],
  [-78.1575599, 59.097740001],
  [-78.16005, 59.097660001],
  [-78.1600901, 59.097149901],
  [-78.16229, 59.097709901],
  [-78.16148, 59.098460101],
  [-78.16396, 59.098530001],
  [-78.16438, 59.099550001],
  [-78.1598999, 59.099450101],
  [-78.1603201, 59.100489901],
  [-78.16483, 59.100070001],
  [-78.16348, 59.100390001],
  [-78.1642299, 59.101340101],
  [-78.1632299, 59.101320001],
  [-78.1626501, 59.102330001],
  [-78.16417, 59.102110101],
  [-78.16275, 59.104130101],
  [-78.1659199, 59.104960001],
  [-78.16339, 59.105420001],
  [-78.1643201, 59.106210001],
  [-78.16184, 59.106149901],
  [-78.16171, 59.107699901],
  [-78.15426, 59.107520001],
  [-78.15304, 59.104170001],
  [-78.15205, 59.104149901],
  [-78.1520099, 59.104660001],
  [-78.1466501, 59.103259901],
  [-78.1466, 59.103770001],
  [-78.14262, 59.103809901],
  [-78.14206, 59.104440001],
  [-78.1445301, 59.104750001],
  [-78.14494, 59.105780101],
  [-78.14939, 59.106139901],
  [-78.1493399, 59.106650001],
  [-78.1517899, 59.107210001],
  [-78.14383, 59.107170001],
  [-78.1407701, 59.108000001],
  [-78.1396501, 59.109510001],
  [-78.14263, 59.109570101],
  [-78.1439999, 59.111140101],
  [-78.1474599, 59.111470101],
  [-78.1476401, 59.112250101],
  [-78.15127, 59.113479901],
  [-78.1529099, 59.111719901],
  [-78.1536501, 59.111870001],
  [-78.15373, 59.113919901],
  [-78.1582201, 59.113760001],
  [-78.1578701, 59.114090001],
  [-78.1591199, 59.114929901],
  [-78.1621101, 59.114870001],
  [-78.1630199, 59.115910101],
  [-78.16409, 59.115039901],
  [-78.1655699, 59.115200001],
  [-78.16562, 59.114690101],
  [-78.1665699, 59.115220001],
  [-78.16618, 59.113929901],
  [-78.1686, 59.114750001],
  [-78.1676, 59.114730001],
  [-78.1685, 59.115900101],
  [-78.16949, 59.116049901],
  [-78.16961, 59.117589901],
  [-78.1685501, 59.118340001],
  [-78.1681399, 59.120379901],
  [-78.1674903, 59.120343301],
  [-78.1659099, 59.117130101],
  [-78.1644301, 59.116970001],
  [-78.1648901, 59.117489901],
  [-78.16339, 59.117460101],
  [-78.1633499, 59.117970001],
  [-78.1628999, 59.117440001],
  [-78.15492, 59.117530001],
  [-78.1550199, 59.116250101],
  [-78.1536, 59.115450001],
  [-78.15261, 59.115430001],
  [-78.1525599, 59.115939901],
  [-78.1385499, 59.113840001],
  [-78.1373399, 59.113300001],
  [-78.1379701, 59.111780001],
  [-78.1388, 59.111709901],
  [-78.13749, 59.111510001],
  [-78.13803, 59.111010101],
  [-78.1355599, 59.110829901],
  [-78.1340099, 59.111430001],
  [-78.1350099, 59.111450101],
  [-78.1344599, 59.112080001],
  [-78.13095, 59.112390001],
  [-78.13103, 59.114440001],
  [-78.13024, 59.114929901],
  [-78.1326599, 59.115760001],
  [-78.12613, 59.116379901],
  [-78.12602, 59.117660001],
  [-78.12504, 59.117640001],
  [-78.1254399, 59.118670001],
  [-78.12293, 59.118870001],
  [-78.1233101, 59.117340001],
  [-78.12258, 59.117200001],
  [-78.1202199, 59.118550001],
  [-78.12036, 59.119840001],
  [-78.12136, 59.119860001],
  [-78.12179, 59.120640001],
  [-78.12479, 59.120450001],
  [-78.12484, 59.119939901],
  [-78.1238601, 59.119660001],
  [-78.1303101, 59.120059901],
  [-78.12926, 59.120790101],
  [-78.13325, 59.120640001],
  [-78.1328799, 59.119090001],
  [-78.13437, 59.119120101],
  [-78.1344101, 59.118620001],
  [-78.1354101, 59.118630001],
  [-78.13536, 59.119149901],
  [-78.13638, 59.118780001],
  [-78.1388701, 59.118970001],
  [-78.13882, 59.119479901],
  [-78.1472399, 59.120049901],
  [-78.15381, 59.118900101],
  [-78.15875, 59.119400001],
  [-78.15831, 59.118750001],
  [-78.1563499, 59.118330001],
  [-78.16429, 59.118630001],
  [-78.1675899, 59.120880001],
  [-78.1661199, 59.120590101],
  [-78.16728, 59.121640001],
  [-78.16695, 59.122660001],
  [-78.16594, 59.122630001],
  [-78.16515, 59.123379901],
  [-78.1653499, 59.123900101],
  [-78.1617899, 59.124850001],
  [-78.16118, 59.126120001],
  [-78.1636501, 59.126430001],
  [-78.16327, 59.127949901],
  [-78.1656999, 59.128770001],
  [-78.16516, 59.129279901],
  [-78.16528, 59.130810101],
  [-78.16928, 59.130650001],
  [-78.16897, 59.131410001],
  [-78.1698999, 59.132200001],
  [-78.16907, 59.133200001],
  [-78.17204, 59.133389901],
  [-78.1724599, 59.134300001],
  [-78.17443, 59.134599901],
  [-78.1745, 59.133829901],
  [-78.17697, 59.134010001],
  [-78.17841, 59.134690101],
  [-78.1764, 59.134900001],
  [-78.1765701, 59.135930101],
  [-78.17972, 59.137020101],
  [-78.18027, 59.136389901],
  [-78.18732, 59.135389901],
  [-78.18865, 59.137470101],
  [-78.1871301, 59.137680001],
  [-78.1867999, 59.138700101],
  [-78.1880299, 59.138990001],
  [-78.18796, 59.139760001],
  [-78.18896, 59.139780001],
  [-78.18788, 59.140780001],
  [-78.1913799, 59.140609901],
  [-78.1893301, 59.141320001],
  [-78.19065, 59.143399901],
  [-78.18783, 59.144370101],
  [-78.1874401, 59.146150101],
  [-78.18395, 59.146069901],
  [-78.18487, 59.147120001],
  [-78.18541, 59.146619901],
  [-78.1899001, 59.146460001],
  [-78.18986, 59.146970001],
  [-78.19183, 59.147270101],
  [-78.1912699, 59.148020001],
  [-78.1892599, 59.148240001],
  [-78.19273, 59.148310001],
  [-78.1931599, 59.149350001],
  [-78.1911499, 59.149560001],
  [-78.1915999, 59.150080001],
  [-78.1910201, 59.151080001],
  [-78.1885701, 59.150530001],
  [-78.1874899, 59.151530001],
  [-78.18652, 59.151250101],
  [-78.1864601, 59.152020001],
  [-78.18441, 59.152609901],
  [-78.18095, 59.152409901],
  [-78.1805899, 59.150609901],
  [-78.1796, 59.150590101],
  [-78.17827, 59.148509901],
  [-78.1757859, 59.148379601],
  [-78.1726701, 59.149930101],
  [-78.17205, 59.151450001],
  [-78.1665699, 59.151330001],
  [-78.16696, 59.152619901],
  [-78.1654699, 59.152590101],
  [-78.16541, 59.153350001],
  [-78.1638901, 59.153580001],
  [-78.16383, 59.154340001],
  [-78.1613399, 59.154279901],
  [-78.1627701, 59.155090001],
  [-78.1612499, 59.155440001],
  [-78.1563201, 59.154690001],
  [-78.1562001, 59.153150101],
  [-78.15504, 59.152110001],
  [-78.1526, 59.151540001],
  [-78.15264, 59.151030101],
  [-78.15463, 59.151069901],
  [-78.1546699, 59.150560001],
  [-78.16162, 59.150970001],
  [-78.16093, 59.150179901],
  [-78.1612299, 59.149680001],
  [-78.15928, 59.149120001],
  [-78.15982, 59.148619901],
  [-78.15838, 59.147949901],
  [-78.1524201, 59.147690001],
  [-78.15186, 59.148450001],
  [-78.1498701, 59.148399901],
  [-78.1499099, 59.147890001],
  [-78.1484401, 59.147600101],
  [-78.14885, 59.148640001],
  [-78.1478701, 59.148360101],
  [-78.14792, 59.147849901],
  [-78.1458801, 59.148310001],
  [-78.14582, 59.149080001],
  [-78.1413299, 59.148980001],
  [-78.14027, 59.149860001],
  [-78.1387899, 59.149700101],
  [-78.1393499, 59.148940101],
  [-78.13593, 59.148100001],
  [-78.13485, 59.149100001],
  [-78.13284, 59.149310001],
  [-78.1325301, 59.150059901],
  [-78.13472, 59.150629901],
  [-78.1346799, 59.151140101],
  [-78.13813, 59.151470001],
  [-78.1336301, 59.151890001],
  [-78.1336599, 59.151370101],
  [-78.1297, 59.151030101],
  [-78.13028, 59.150279901],
  [-78.1288, 59.149990001],
  [-78.12884, 59.149490101],
  [-78.12436, 59.149380101],
  [-78.1244, 59.148870001],
  [-78.12148, 59.148020001],
  [-78.1214399, 59.148540001],
  [-78.12049, 59.148010001],
  [-78.1204001, 59.149030101],
  [-78.11892, 59.149000001],
  [-78.11871, 59.151299901],
  [-78.11971, 59.151320001],
  [-78.1194001, 59.152080001],
  [-78.1211901, 59.154440001],
  [-78.1209999, 59.156729901],
  [-78.12417, 59.157570001],
  [-78.12413, 59.158079901],
  [-78.12514, 59.157849901],
  [-78.12514, 59.160920001],
  [-78.12657, 59.161720101],
  [-78.1327, 59.162870001],
  [-78.1326599, 59.163390101],
  [-78.13818, 59.163000001],
  [-78.13874, 59.162240001],
  [-78.1343201, 59.161380101],
  [-78.13691, 59.160280101],
  [-78.14136, 59.160760001],
  [-78.1414, 59.160250001],
  [-78.1476799, 59.162700001],
  [-78.1466799, 59.162670001],
  [-78.1461199, 59.163430001],
  [-78.1446501, 59.163140001],
  [-78.1435699, 59.164140001],
  [-78.1410801, 59.164090001],
  [-78.1409901, 59.165110001],
  [-78.1399701, 59.165470001],
  [-78.1359701, 59.165509901],
  [-78.1354101, 59.166270101],
  [-78.1344101, 59.166250001],
  [-78.1351001, 59.167030001],
  [-78.13481, 59.167529901],
  [-78.1358, 59.167560001],
  [-78.13574, 59.168320001],
  [-78.13771, 59.168619901],
  [-78.1381199, 59.169660001],
  [-78.1371099, 59.169629901],
  [-78.13806, 59.170419901],
  [-78.13706, 59.170399901],
  [-78.13772, 59.171440001],
  [-78.13691, 59.172189901],
  [-78.1413299, 59.173060101],
  [-78.1407899, 59.173560001],
  [-78.14228, 59.173590001],
  [-78.1422399, 59.174100001],
  [-78.14372, 59.174260001],
  [-78.14417, 59.174910001],
  [-78.14717, 59.174720101],
  [-78.14736, 59.175490101],
  [-78.1485599, 59.176030001],
  [-78.1435401, 59.176430001],
  [-78.14395, 59.177470001],
  [-78.1399901, 59.177120001],
  [-78.1404, 59.178160101],
  [-78.1394301, 59.177869901],
  [-78.1388701, 59.178639901],
  [-78.13984, 59.178910001],
  [-78.1383201, 59.179140001],
  [-78.13839, 59.178370001],
  [-78.1343299, 59.179180101],
  [-78.13135, 59.178979901],
  [-78.13149, 59.180270101],
  [-78.1304101, 59.181270101],
  [-78.1305499, 59.182550001],
  [-78.12757, 59.182480001],
  [-78.12629, 59.179900001],
  [-78.12479, 59.179859901],
  [-78.12462, 59.181910001],
  [-78.1230999, 59.182130001],
  [-78.12304, 59.182900001],
  [-78.12204, 59.182869901],
  [-78.12173, 59.183639901],
  [-78.1239, 59.184570001],
  [-78.12591, 59.184370001],
  [-78.12646, 59.183739901],
  [-78.13092, 59.184100001],
  [-78.13057, 59.185370001],
  [-78.13229, 59.185660001],
  [-78.13272, 59.186440001],
  [-78.12917, 59.187130001],
  [-78.1283399, 59.188140001],
  [-78.1285, 59.189160001],
  [-78.1326699, 59.190020001],
  [-78.1323399, 59.191040001],
  [-78.13405, 59.191340001],
  [-78.13471, 59.192500101],
  [-78.1376799, 59.192820001],
  [-78.13858, 59.194000001],
  [-78.1405301, 59.194550001],
  [-78.13804, 59.194500101],
  [-78.1372299, 59.195250001],
  [-78.1384499, 59.195529901],
  [-78.13841, 59.196040001],
  [-78.1412001, 59.195590001],
  [-78.14161, 59.193550001],
  [-78.1471099, 59.193419901],
  [-78.14717, 59.192900001],
  [-78.1491001, 59.193590001],
  [-78.1521001, 59.193520101],
  [-78.15228, 59.194300101],
  [-78.1512299, 59.194790001],
  [-78.15097, 59.198110001],
  [-78.15015, 59.198859901],
  [-78.14916, 59.198850101],
  [-78.1492101, 59.198330001],
  [-78.14272, 59.198180101],
  [-78.14229, 59.197410101],
  [-78.13039, 59.196380001],
  [-78.13093, 59.195879901],
  [-78.12747, 59.195539901],
  [-78.12636, 59.196800001],
  [-78.1293299, 59.197120001],
  [-78.12781, 59.197340001],
  [-78.1277699, 59.197850101],
  [-78.1292199, 59.198400101],
  [-78.12824, 59.198380001],
  [-78.1286401, 59.199410101],
  [-78.12562, 59.199869901],
  [-78.1245201, 59.200859901],
  [-78.1225301, 59.200810001],
  [-78.12247, 59.201580001],
  [-78.12491, 59.202150001],
  [-78.12448, 59.201370001],
  [-78.1259999, 59.201140001],
  [-78.12514, 59.201460001],
  [-78.1266599, 59.202180101],
  [-78.12726, 59.203989901],
  [-78.1241801, 59.204950101],
  [-78.12424, 59.204180101],
  [-78.1228899, 59.205429901],
  [-78.12461, 59.205720001],
  [-78.1238601, 59.208780001],
  [-78.1278701, 59.208610001],
  [-78.1285499, 59.209400101],
  [-78.12817, 59.210920001],
  [-78.12915, 59.211199901],
  [-78.1276301, 59.211420101],
  [-78.1287501, 59.212989901],
  [-78.1284101, 59.213999901],
  [-78.1134299, 59.213930001],
  [-78.1137699, 59.212910001],
  [-78.1155399, 59.212690001],
  [-78.11614, 59.211429901],
  [-78.11714, 59.211450001],
  [-78.1164299, 59.210920001],
  [-78.1175399, 59.209659901],
  [-78.11805, 59.206600001],
  [-78.11657, 59.206309901],
  [-78.1167, 59.204780001],
  [-78.10336, 59.203199901],
  [-78.1017499, 59.204450001],
  [-78.09872, 59.204890001],
  [-78.09915, 59.205670001],
  [-78.09816, 59.205649901],
  [-78.09859, 59.206429901],
  [-78.1035101, 59.207300101],
  [-78.0955299, 59.207130001],
  [-78.09558, 59.206610001],
  [-78.09459, 59.206340001],
  [-78.0927301, 59.207830001],
  [-78.09293, 59.208350001],
  [-78.09193, 59.208329901],
  [-78.0895399, 59.210050001],
  [-78.0891601, 59.211590001],
  [-78.08712, 59.212060001],
  [-78.0871801, 59.211549901],
  [-78.0831801, 59.211460001],
  [-78.08278, 59.213240001],
  [-78.0849799, 59.213680001],
  [-78.0854099, 59.214580001],
  [-78.0864299, 59.214350001],
  [-78.08711, 59.215130001],
  [-78.08682, 59.215640101],
  [-78.0921999, 59.216910001],
  [-78.0938301, 59.218350001],
  [-78.0929699, 59.219620001],
  [-78.0939501, 59.219900001],
  [-78.0859, 59.220480001],
  [-78.0858699, 59.220989901],
  [-78.0848599, 59.220970101],
  [-78.08478, 59.221989901],
  [-78.08281, 59.221690001],
  [-78.0838699, 59.220950001],
  [-78.07646, 59.219889901],
  [-78.0734199, 59.220460001],
  [-78.07335, 59.221229901],
  [-78.07291, 59.220700001],
  [-78.06034, 59.221439901],
  [-78.0602601, 59.222470001],
  [-78.0614801, 59.222750101],
  [-78.06058, 59.224520101],
  [-78.0590799, 59.224490001],
  [-78.0585199, 59.225240001],
  [-78.06001, 59.225280001],
  [-78.0623801, 59.226870101],
  [-78.0624099, 59.226370001],
  [-78.06414, 59.226650101],
  [-78.0625199, 59.228150001],
  [-78.06322, 59.228679901],
  [-78.0571699, 59.229310101],
  [-78.05712, 59.229820001],
  [-78.0501799, 59.229150001],
  [-78.0502701, 59.228130001],
  [-78.0492799, 59.228119901],
  [-78.0514801, 59.225730001],
  [-78.0524901, 59.225620001],
  [-78.05256, 59.224850101],
  [-78.05355, 59.224879901],
  [-78.0542501, 59.222590001],
  [-78.05525, 59.222610001],
  [-78.0550401, 59.222090101],
  [-78.05586, 59.221339901],
  [-78.04887, 59.221180101],
  [-78.0485099, 59.219640101],
  [-78.0475099, 59.219620001],
  [-78.0475801, 59.218850101],
  [-78.0407001, 59.217539901],
  [-78.03667, 59.217960101],
  [-78.02879, 59.216759901],
  [-78.01931, 59.216410101],
  [-78.01877, 59.216910001],
  [-78.0241699, 59.218060001],
  [-78.0241301, 59.218570001],
  [-78.0285901, 59.218930001],
  [-78.02854, 59.219439901],
  [-78.0369101, 59.220910001],
  [-78.03709, 59.221680001],
  [-78.03832, 59.221970101],
  [-78.03777, 59.222470001],
  [-78.04675, 59.222669901],
  [-78.0465901, 59.224460001],
  [-78.0450499, 59.224940001],
  [-78.0475099, 59.225380001],
  [-78.04843, 59.226300101],
  [-78.04945, 59.226060001],
  [-78.0494099, 59.226570001],
  [-78.04689, 59.226769901],
  [-78.0464801, 59.225740101],
  [-78.04501, 59.225449901],
  [-78.0445801, 59.224669901],
  [-78.0451, 59.224429901],
  [-78.04308, 59.224640101],
  [-78.04279, 59.225140001],
  [-78.04498, 59.225830001],
  [-78.0458299, 59.227520101],
  [-78.04234, 59.227439901],
  [-78.04175, 59.228449901],
  [-78.0357001, 59.229080101],
  [-78.0352699, 59.228300101],
  [-78.0338101, 59.227879901],
  [-78.03096, 59.228070001],
  [-78.03121, 59.228980101],
  [-78.03021, 59.228950001],
  [-78.0308299, 59.227690001],
  [-78.02384, 59.227520101],
  [-78.0249101, 59.226779901],
  [-78.0224601, 59.226209901],
  [-78.0220301, 59.225439901],
  [-78.0200301, 59.225390001],
  [-78.0205999, 59.224640101],
  [-78.01963, 59.224229901],
  [-78.01566, 59.224009901],
  [-78.0156099, 59.224520101],
  [-78.01363, 59.224219901],
  [-78.0136801, 59.223710001],
  [-78.0101399, 59.224140001],
  [-78.01022, 59.223370001],
  [-78.00722, 59.223300101],
  [-78.00729, 59.222539901],
  [-78.0043401, 59.221960101],
  [-78.00441, 59.221190101],
  [-78.0029199, 59.221160001],
  [-78.0060399, 59.219690001],
  [-78.0021301, 59.218580001],
  [-78.0028199, 59.216549901],
  [-78.0083699, 59.215900001],
  [-78.0090499, 59.213869901],
  [-78.0080499, 59.213850101],
  [-78.0081399, 59.212830001],
  [-78.0056801, 59.212380001],
  [-78.00464, 59.212869901],
  [-77.9992301, 59.212910001],
  [-77.99928, 59.213680001],
  [-78.0000001, 59.213429901],
  [-78.00051, 59.214439901],
  [-77.98832, 59.213930001],
  [-77.9872499, 59.213190101],
  [-77.9808099, 59.213720001],
  [-77.9737, 59.212470001],
  [-77.9736601, 59.211960101],
  [-77.97216, 59.211999901],
  [-77.9723301, 59.210970101],
  [-77.97149, 59.209960101],
  [-77.96997, 59.209740101],
  [-77.9676699, 59.206209901],
  [-77.9666699, 59.206230001],
  [-77.9654646, 59.204415401],
  [-77.9625131, 59.203272001],
  [-77.9611573, 59.204957301],
  [-77.95908, 59.205130001],
  [-77.9569901, 59.206970101],
  [-77.95737, 59.211310101],
  [-77.96107, 59.213790001],
  [-77.96119, 59.215070101],
  [-77.96597, 59.218290101],
  [-77.9674599, 59.221070101],
  [-77.96451, 59.221649901],
  [-77.9636, 59.222690001],
  [-77.96118, 59.223520101],
  [-77.96029, 59.224820001],
  [-77.9552299, 59.224040001],
  [-77.95426, 59.224439901],
  [-77.95469, 59.223659901],
  [-77.95318, 59.223439901],
  [-77.95204, 59.221930001],
  [-77.9475599, 59.222030001],
  [-77.9464499, 59.220779901],
  [-77.9394399, 59.220549901],
  [-77.9315399, 59.221630101],
  [-77.93135, 59.222400001],
  [-77.93271, 59.223650101],
  [-77.9303101, 59.224860101],
  [-77.9248601, 59.225360001],
  [-77.9248799, 59.228439901],
  [-77.92669, 59.229160001],
  [-77.92673, 59.229679901],
  [-77.9197301, 59.229580001],
  [-77.92127, 59.230050001],
  [-77.9198501, 59.230980101],
  [-77.91435, 59.230980101],
  [-77.91893, 59.231889901],
  [-77.9199999, 59.232779901],
  [-77.92545, 59.232260001],
  [-77.9255, 59.232779901],
  [-77.93969, 59.235140001],
  [-77.9416799, 59.234970101],
  [-77.9423399, 59.233930001],
  [-77.94427, 59.233119901],
  [-77.94436, 59.231329901],
  [-77.93981, 59.230659901],
  [-77.9398701, 59.231430101],
  [-77.93937, 59.231439901],
  [-77.9393101, 59.230669901],
  [-77.93528, 59.230250001],
  [-77.93415, 59.228740101],
  [-77.93315, 59.228760101],
  [-77.9329899, 59.226970101],
  [-77.94806, 59.227779901],
  [-77.95451, 59.227250001],
  [-77.9545501, 59.227769901],
  [-77.95849, 59.227160001],
  [-77.9584301, 59.226400001],
  [-77.9660001, 59.227250001],
  [-77.96604, 59.227760101],
  [-77.97405, 59.227960001],
  [-77.9778999, 59.229030001],
  [-77.9782101, 59.229789901],
  [-77.97965, 59.229109901],
  [-77.9836999, 59.229659901],
  [-77.98272, 59.229940001],
  [-77.9827801, 59.230449901],
  [-77.98676, 59.230360001],
  [-77.9861399, 59.231910001],
  [-77.98842, 59.232240001],
  [-77.99039, 59.231940001],
  [-77.9922499, 59.230360001],
  [-77.99727, 59.230630101],
  [-77.99662, 59.231669901],
  [-77.99843, 59.232400001],
  [-77.9964799, 59.232950001],
  [-77.9971101, 59.234470001],
  [-77.99616, 59.235009901],
  [-77.99516, 59.235030001],
  [-77.9951201, 59.234510001],
  [-77.99596, 59.234669901],
  [-77.99605, 59.233730001],
  [-77.99405, 59.233779901],
  [-77.99416, 59.235050001],
  [-77.9916601, 59.234860101],
  [-77.99099, 59.233080101],
  [-77.98553, 59.233459901],
  [-77.9854401, 59.235260001],
  [-77.98773, 59.238530101],
  [-77.9889901, 59.238760101],
  [-77.9890399, 59.239270001],
  [-77.9914601, 59.238459901],
  [-77.99187, 59.237410001],
  [-77.9903499, 59.237190101],
  [-77.99076, 59.236160001],
  [-77.9927999, 59.236620001],
  [-77.99285, 59.237130001],
  [-77.99432, 59.236840001],
  [-77.99387, 59.237370001],
  [-77.99485, 59.237339901],
  [-77.99597, 59.238590001],
  [-77.99498, 59.238620001],
  [-77.9950199, 59.239129901],
  [-77.9981, 59.240090101],
  [-77.9961301, 59.240390001],
  [-77.9972599, 59.241899901],
  [-77.99627, 59.241920001],
  [-77.99808, 59.242650101],
  [-77.9984, 59.243410001],
  [-77.9974, 59.243430101],
  [-77.9973401, 59.242660101],
  [-77.9907999, 59.242300001],
  [-77.9924799, 59.244310101],
  [-78.00031, 59.245170001],
  [-78.0011499, 59.244170001],
  [-78.0000001, 59.242860101],
  [-78.0010101, 59.242880101],
  [-78.00163, 59.241490001],
  [-78.0080499, 59.242400001],
  [-78.01509, 59.241789901],
  [-78.01951, 59.242800001],
  [-78.02007, 59.242170001],
  [-78.0222799, 59.242600001],
  [-78.02164, 59.243270001],
  [-78.0234801, 59.243140001],
  [-78.02344, 59.243650101],
  [-78.02096, 59.243339901],
  [-78.02141, 59.243990101],
  [-78.02389, 59.244180001],
  [-78.02276, 59.245689901],
  [-78.02178, 59.245410001],
  [-78.0207001, 59.246410001],
  [-78.0202, 59.246400001],
  [-78.0208101, 59.245129901],
  [-78.01618, 59.246559901],
  [-78.01675, 59.245810001],
  [-78.0138001, 59.245229901],
  [-78.01376, 59.245740001],
  [-78.00829, 59.245360001],
  [-78.0091499, 59.246909901],
  [-78.0081599, 59.246889901],
  [-78.0078099, 59.247920001],
  [-78.01644, 59.249380001],
  [-78.01687, 59.250160001],
  [-78.0153699, 59.250129901],
  [-78.01528, 59.251150001],
  [-78.0142401, 59.251640001],
  [-78.01374, 59.251630001],
  [-78.01431, 59.250870101],
  [-78.01039, 59.249880101],
  [-78.00195, 59.249180001],
  [-77.9993401, 59.248510001],
  [-77.9993, 59.247999901],
  [-77.9967999, 59.248050001],
  [-77.9966701, 59.246520001],
  [-77.9951399, 59.246300001],
  [-77.9971, 59.245740001],
  [-77.9941201, 59.246070001],
  [-77.9942, 59.246830001],
  [-77.99018, 59.246669901],
  [-77.9918099, 59.248170001],
  [-77.9908199, 59.248190001],
  [-77.99141, 59.249200101],
  [-77.99441, 59.249390001],
  [-77.99465, 59.251950001],
  [-77.9931, 59.251469901],
  [-77.99483, 59.253990101],
  [-77.9933301, 59.254040001],
  [-77.9933699, 59.254540101],
  [-77.9914401, 59.255349901],
  [-77.99294, 59.255310001],
  [-77.99274, 59.255830001],
  [-77.99532, 59.256789901],
  [-77.99516, 59.257810001],
  [-77.99171, 59.258420001],
  [-77.9909199, 59.257920001],
  [-77.9893599, 59.254370001],
  [-77.99121, 59.254750001],
  [-77.99183, 59.254060001],
  [-77.9887701, 59.253359901],
  [-77.9893401, 59.254119901],
  [-77.9883401, 59.254140001],
  [-77.9888099, 59.256689901],
  [-77.9893301, 59.256930001],
  [-77.98928, 59.259239901],
  [-77.99028, 59.259220101],
  [-77.9909199, 59.260740001],
  [-77.9899199, 59.260760101],
  [-77.9900101, 59.261780101],
  [-77.98853, 59.262070001],
  [-77.9872, 59.261080001],
  [-77.9870199, 59.259040001],
  [-77.9853599, 59.257280001],
  [-77.9843499, 59.254229901],
  [-77.9823401, 59.254019901],
  [-77.98205, 59.256590001],
  [-77.97806, 59.256679901],
  [-77.9774699, 59.258490001],
  [-77.9783, 59.259490001],
  [-77.9765601, 59.260420001],
  [-77.97694, 59.261060001],
  [-77.9764401, 59.261070001],
  [-77.9758901, 59.260430101],
  [-77.9763499, 59.260050001],
  [-77.9753499, 59.260070001],
  [-77.97626, 59.259029901],
  [-77.97474, 59.258810001],
  [-77.97415, 59.257789901],
  [-77.9736601, 59.257810001],
  [-77.97372, 59.258569901],
  [-77.9686601, 59.257920001],
  [-77.96862, 59.257410001],
  [-77.9610901, 59.257070001],
  [-77.96104, 59.256559901],
  [-77.96005, 59.256579901],
  [-77.9588999, 59.254810001],
  [-77.95739, 59.254850001],
  [-77.9581001, 59.254330101],
  [-77.9577799, 59.253559901],
  [-77.9527899, 59.253669901],
  [-77.95284, 59.254170001],
  [-77.95184, 59.254210101],
  [-77.9517899, 59.253689901],
  [-77.9493201, 59.254009901],
  [-77.94936, 59.254520001],
  [-77.9483001, 59.253770101],
  [-77.9453299, 59.254100101],
  [-77.94539, 59.254860001],
  [-77.93637, 59.254559901],
  [-77.93425, 59.250250001],
  [-77.93491, 59.249210101],
  [-77.9359, 59.249190001],
  [-77.93584, 59.248420101],
  [-77.93883, 59.248360001],
  [-77.93879, 59.247840001],
  [-77.94307, 59.248260001],
  [-77.94217, 59.246490001],
  [-77.94117, 59.246510001],
  [-77.9411199, 59.245999901],
  [-77.9345499, 59.245119901],
  [-77.93429, 59.242050001],
  [-77.9377401, 59.241459901],
  [-77.9377, 59.240950001],
  [-77.9401901, 59.240899901],
  [-77.9409899, 59.238569901],
  [-77.93895, 59.238109901],
  [-77.93804, 59.239150001],
  [-77.9345301, 59.238980101],
  [-77.9351099, 59.239990101],
  [-77.9311901, 59.240840001],
  [-77.9298799, 59.240100101],
  [-77.92882, 59.236540101],
  [-77.92381, 59.236400001],
  [-77.9225301, 59.238990101],
  [-77.9195201, 59.238800001],
  [-77.9201099, 59.239940001],
  [-77.9236201, 59.239990101],
  [-77.92368, 59.240760101],
  [-77.9267401, 59.241459901],
  [-77.92334, 59.242559901],
  [-77.9237799, 59.244850001],
  [-77.92861, 59.245770101],
  [-77.9286499, 59.246280001],
  [-77.9276599, 59.246300001],
  [-77.9282199, 59.247060001],
  [-77.9272199, 59.247080001],
  [-77.9280999, 59.248600001],
  [-77.9276699, 59.249380001],
  [-77.92946, 59.249850001],
  [-77.92862, 59.251660101],
  [-77.92561, 59.251600001],
  [-77.92367, 59.252290001],
  [-77.92135, 59.254380001],
  [-77.9188799, 59.254689901],
  [-77.9185299, 59.256490001],
  [-77.9199601, 59.255689901],
  [-77.9239899, 59.255990101],
  [-77.9243199, 59.254060001],
  [-77.93335, 59.254370001],
  [-77.93339, 59.254880101],
  [-77.9358799, 59.254820001],
  [-77.93593, 59.255330101],
  [-77.93746, 59.255679901],
  [-77.9404499, 59.255480001],
  [-77.94049, 59.256000101],
  [-77.9455499, 59.256660101],
  [-77.94549, 59.256139901],
  [-77.94759, 59.257119901],
  [-77.94414, 59.257710001],
  [-77.94429, 59.259500001],
  [-77.9472199, 59.258660101],
  [-77.94782, 59.259930001],
  [-77.9493299, 59.259899901],
  [-77.94894, 59.261190001],
  [-77.9459901, 59.261760101],
  [-77.94507, 59.262679901],
  [-77.9435599, 59.262590001],
  [-77.94347, 59.261569901],
  [-77.93992, 59.261009901],
  [-77.93301, 59.262060001],
  [-77.93305, 59.262569901],
  [-77.9350701, 59.262780101],
  [-77.9340801, 59.262799901],
  [-77.93413, 59.263569901],
  [-77.93659, 59.263000101],
  [-77.9365301, 59.262229901],
  [-77.9389999, 59.261919901],
  [-77.9406501, 59.263679901],
  [-77.93761, 59.263229901],
  [-77.9374299, 59.264009901],
  [-77.93825, 59.264760001],
  [-77.93673, 59.264540101],
  [-77.93627, 59.265060001],
  [-77.93568, 59.264050001],
  [-77.93214, 59.263610001],
  [-77.93225, 59.264890101],
  [-77.9343399, 59.265870001],
  [-77.93284, 59.265899901],
  [-77.9342199, 59.267410001],
  [-77.93358, 59.268699901],
  [-77.9356501, 59.269430101],
  [-77.9336499, 59.269469901],
  [-77.9341901, 59.269970001],
  [-77.9316699, 59.269770101],
  [-77.9330801, 59.268720001],
  [-77.93058, 59.268640001],
  [-77.9291099, 59.269060001],
  [-77.9291801, 59.269830001],
  [-77.9162801, 59.271139901],
  [-77.9162199, 59.270369901],
  [-77.9152199, 59.270400001],
  [-77.91713, 59.269330101],
  [-77.9170801, 59.268820001],
  [-77.9116599, 59.269710001],
  [-77.90836, 59.266200001],
  [-77.90579, 59.265490001],
  [-77.9057499, 59.264970001],
  [-77.9012099, 59.264560101],
  [-77.90115, 59.263799901],
  [-77.9026201, 59.263510001],
  [-77.89958, 59.263050001],
  [-77.89789, 59.260799901],
  [-77.8908699, 59.260569901],
  [-77.88891, 59.261000101],
  [-77.88945, 59.261500001],
  [-77.888, 59.262170001],
  [-77.884, 59.262129901],
  [-77.8840401, 59.262640001],
  [-77.87801, 59.262260001],
  [-77.87909, 59.263260001],
  [-77.88059, 59.263229901],
  [-77.8802, 59.264520001],
  [-77.88324, 59.264960001],
  [-77.8832801, 59.265469901],
  [-77.88579, 59.265670101],
  [-77.8867101, 59.264630001],
  [-77.88721, 59.264620001],
  [-77.88676, 59.265139901],
  [-77.88877, 59.265349901],
  [-77.88678, 59.265400001],
  [-77.8868401, 59.266160001],
  [-77.8848599, 59.266340101],
  [-77.8752601, 59.265270001],
  [-77.87079, 59.265750001],
  [-77.8708399, 59.266270001],
  [-77.8738499, 59.266320101],
  [-77.8759401, 59.267420001],
  [-77.87199, 59.268029901],
  [-77.8736101, 59.269400001],
  [-77.87512, 59.269500001],
  [-77.87555, 59.268720001],
  [-77.87453, 59.268490001],
  [-77.8775099, 59.268160001],
  [-77.8768399, 59.269200001],
  [-77.87766, 59.269950001],
  [-77.87666, 59.269970001],
  [-77.87733, 59.272010101],
  [-77.87936, 59.272349901],
  [-77.88098, 59.273720001],
  [-77.87498, 59.273850001],
  [-77.8750699, 59.274880101],
  [-77.8740699, 59.274900101],
  [-77.87401, 59.274129901],
  [-77.87101, 59.274200001],
  [-77.86969, 59.276280001],
  [-77.8709599, 59.276500001],
  [-77.87131, 59.277780101],
  [-77.86522, 59.276760001],
  [-77.8627199, 59.276809901],
  [-77.8612799, 59.277479901],
  [-77.8617399, 59.276960001],
  [-77.85623, 59.276950001],
  [-77.85368, 59.276369901],
  [-77.85364, 59.275860001],
  [-77.8472, 59.276770101],
  [-77.84388, 59.278890101],
  [-77.8451501, 59.279120101],
  [-77.8452401, 59.280139901],
  [-77.8491401, 59.281850001],
  [-77.8537001, 59.282520001],
  [-77.85374, 59.283029901],
  [-77.8547399, 59.283000101],
  [-77.8542799, 59.283530001],
  [-77.8588499, 59.284450101],
  [-77.85945, 59.285460101],
  [-77.86301, 59.286149901],
  [-77.8621401, 59.287709901],
  [-77.8611401, 59.287730001],
  [-77.8635099, 59.289220101],
  [-77.8638499, 59.290230101],
  [-77.8619, 59.290790101],
  [-77.8622, 59.291550001],
  [-77.8644801, 59.291760001],
  [-77.8659, 59.293770001],
  [-77.86822, 59.294489901],
  [-77.8682601, 59.295000001],
  [-77.8712799, 59.295190001],
  [-77.87132, 59.295699901],
  [-77.8772899, 59.295190001],
  [-77.88235, 59.295840001],
  [-77.8840401, 59.295159901],
  [-77.8841799, 59.293880001],
  [-77.8911701, 59.293599901],
  [-77.9052901, 59.294819901],
  [-77.9094299, 59.296390001],
  [-77.91367, 59.299110001],
  [-77.91905, 59.300530001],
  [-77.91912, 59.301300001],
  [-77.92547, 59.302440001],
  [-77.92558, 59.303709901],
  [-77.92458, 59.303740001],
  [-77.9246301, 59.304250101],
  [-77.92206, 59.303530001],
  [-77.9226499, 59.304550001],
  [-77.91917, 59.304880001],
  [-77.91991, 59.307680101],
  [-77.91737, 59.307230101],
  [-77.9174101, 59.307740001],
  [-77.9168699, 59.307240101],
  [-77.90689, 59.307719901],
  [-77.9068501, 59.307210001],
  [-77.90279, 59.306530001],
  [-77.9027401, 59.306020101],
  [-77.90026, 59.306330001],
  [-77.8996, 59.307369901],
  [-77.9012099, 59.308609901],
  [-77.90111, 59.310410001],
  [-77.90015, 59.310810101],
  [-77.8956299, 59.310790101],
  [-77.89559, 59.310269901],
  [-77.88592, 59.308440001],
  [-77.8848301, 59.307440001],
  [-77.88233, 59.307489901],
  [-77.8810699, 59.310340001],
  [-77.8820699, 59.310320001],
  [-77.88211, 59.310829901],
  [-77.8850501, 59.310000001],
  [-77.8846399, 59.311030101],
  [-77.8792, 59.311920101],
  [-77.87759, 59.310680101],
  [-77.8716199, 59.311059901],
  [-77.8718399, 59.307730001],
  [-77.87477, 59.306880001],
  [-77.8753901, 59.305340101],
  [-77.8729301, 59.302840001],
  [-77.87088, 59.302379901],
  [-77.86977, 59.301110001],
  [-77.86568, 59.300049901],
  [-77.8641899, 59.300210001],
  [-77.8633601, 59.302280001],
  [-77.8608201, 59.301819901],
  [-77.86143, 59.303090001],
  [-77.8589301, 59.303149901],
  [-77.85889, 59.302630001],
  [-77.8573899, 59.302670001],
  [-77.85578, 59.301420001],
  [-77.85724, 59.300880001],
  [-77.8557199, 59.300650001],
  [-77.8571699, 59.300110001],
  [-77.8564999, 59.298080001],
  [-77.8489399, 59.297470101],
  [-77.8483601, 59.296460101],
  [-77.84654, 59.295730001],
  [-77.8472, 59.294699901],
  [-77.84621, 59.294720001],
  [-77.8461599, 59.294210001],
  [-77.8446901, 59.294489901],
  [-77.8452899, 59.295760001],
  [-77.83877, 59.295650001],
  [-77.8402501, 59.295359901],
  [-77.84021, 59.294850001],
  [-77.84221, 59.294809901],
  [-77.84256, 59.292880001],
  [-77.84556, 59.292939901],
  [-77.8453899, 59.290890101],
  [-77.8382601, 59.289510001],
  [-77.8412, 59.288680101],
  [-77.8405901, 59.287410001],
  [-77.8362, 59.285720001],
  [-77.8353, 59.283940001],
  [-77.8338001, 59.283980001],
  [-77.83264, 59.282210001],
  [-77.8311499, 59.282240101],
  [-77.8316099, 59.281720001],
  [-77.83009, 59.281500001],
  [-77.8300201, 59.280730001],
  [-77.82497, 59.280070001],
  [-77.8242599, 59.280600001],
  [-77.8245801, 59.284430001],
  [-77.82387, 59.284960001],
  [-77.82487, 59.284940001],
  [-77.82708, 59.287450101],
  [-77.83089, 59.288139901],
  [-77.83129, 59.289919901],
  [-77.8328299, 59.290400001],
  [-77.82933, 59.290479901],
  [-77.8289201, 59.291510001],
  [-77.8279201, 59.291530001],
  [-77.829, 59.292530001],
  [-77.828, 59.292550001],
  [-77.8290201, 59.292790101],
  [-77.82863, 59.294080001],
  [-77.83118, 59.294660001],
  [-77.8388001, 59.298980001],
  [-77.83912, 59.299740001],
  [-77.8281599, 59.300489901],
  [-77.8281, 59.299719901],
  [-77.82554, 59.299010101],
  [-77.8244501, 59.298010101],
  [-77.81854, 59.299159901],
  [-77.8185999, 59.299929901],
  [-77.8161201, 59.300240101],
  [-77.81554, 59.302300001],
  [-77.8174501, 59.304180001],
  [-77.8259199, 59.303609901],
  [-77.82588, 59.303110001],
  [-77.82845, 59.303939901],
  [-77.83195, 59.303860001],
  [-77.83963, 59.305860001],
  [-77.8400201, 59.304580101],
  [-77.8428101, 59.305030101],
  [-77.84221, 59.306840001],
  [-77.84321, 59.306819901],
  [-77.84378, 59.307570101],
  [-77.8412799, 59.307630001],
  [-77.8422, 59.309660001],
  [-77.8444999, 59.310250101],
  [-77.8449499, 59.309599901],
  [-77.8504999, 59.310120001],
  [-77.85155, 59.310730001],
  [-77.8500699, 59.311020101],
  [-77.8501301, 59.311530001],
  [-77.84256, 59.310929901],
  [-77.84164, 59.311970001],
  [-77.83963, 59.312020101],
  [-77.8402501, 59.313300001],
  [-77.84152, 59.313510001],
  [-77.8418299, 59.314279901],
  [-77.8432601, 59.313350101],
  [-77.8450401, 59.313690101],
  [-77.84521, 59.315740001],
  [-77.84707, 59.316980001],
  [-77.84311, 59.317580101],
  [-77.84344, 59.318590101],
  [-77.84207, 59.320159901],
  [-77.84241, 59.321180001],
  [-77.84089, 59.320949901],
  [-77.8408399, 59.320440001],
  [-77.8363799, 59.321049901],
  [-77.8368199, 59.320269901],
  [-77.82717, 59.318690101],
  [-77.8266099, 59.317939901],
  [-77.8240399, 59.317230001],
  [-77.824, 59.316709901],
  [-77.822, 59.316760001],
  [-77.8209101, 59.315750001],
  [-77.82264, 59.315460101],
  [-77.8207901, 59.314220001],
  [-77.8245801, 59.314650001],
  [-77.82441, 59.315680101],
  [-77.82817, 59.315599901],
  [-77.8278099, 59.314320001],
  [-77.8181, 59.311970001],
  [-77.8180299, 59.311210001],
  [-77.8170299, 59.311230001],
  [-77.8168901, 59.309440001],
  [-77.81284, 59.309010101],
  [-77.8128001, 59.308499901],
  [-77.80676, 59.308120101],
  [-77.8072201, 59.307599901],
  [-77.8031499, 59.306920101],
  [-77.8027901, 59.305650001],
  [-77.8040299, 59.305360101],
  [-77.8044401, 59.304330001],
  [-77.8019001, 59.303870001],
  [-77.8005501, 59.302620001],
  [-77.80071, 59.301589901],
  [-77.8041499, 59.300750001],
  [-77.8041, 59.300240101],
  [-77.7886501, 59.301080001],
  [-77.78841, 59.304159901],
  [-77.7854501, 59.304740001],
  [-77.78526, 59.305510001],
  [-77.79027, 59.308730001],
  [-77.7917801, 59.308700101],
  [-77.79141, 59.310230001],
  [-77.7942201, 59.310949901],
  [-77.7968901, 59.312939901],
  [-77.7963301, 59.315260101],
  [-77.78598, 59.317400001],
  [-77.7743499, 59.315990001],
  [-77.77439, 59.316489901],
  [-77.77241, 59.316800101],
  [-77.7771101, 59.319130101],
  [-77.78217, 59.319790001],
  [-77.7925799, 59.318410001],
  [-77.79297, 59.317120001],
  [-77.7954699, 59.317070001],
  [-77.7966999, 59.319729901],
  [-77.7986701, 59.319430001],
  [-77.8014799, 59.320020101],
  [-77.8017701, 59.320520001],
  [-77.79875, 59.320330001],
  [-77.8034401, 59.322530001],
  [-77.8074401, 59.322450001],
  [-77.8075, 59.323210001],
  [-77.8065, 59.323240101],
  [-77.8065701, 59.324000001],
  [-77.80863, 59.324729901],
  [-77.80365, 59.325080001],
  [-77.8032499, 59.326379901],
  [-77.7954, 59.328340001],
  [-77.79371, 59.332230001],
  [-77.79127, 59.333040101],
  [-77.79186, 59.334049901],
  [-77.79085, 59.334069901],
  [-77.7904301, 59.334849901],
  [-77.7879199, 59.334900001],
  [-77.78852, 59.336169901],
  [-77.7870001, 59.335949901],
  [-77.7864401, 59.335190001],
  [-77.78194, 59.335289901],
  [-77.7820199, 59.336310001],
  [-77.78352, 59.336279901],
  [-77.7835699, 59.337040101],
  [-77.7785401, 59.336640001],
  [-77.77839, 59.337920101],
  [-77.7814499, 59.341570001],
  [-77.78296, 59.341670001],
  [-77.7861199, 59.343519901],
  [-77.7966, 59.342910001],
  [-77.7957801, 59.342160101],
  [-77.79749, 59.341609901],
  [-77.7974501, 59.341110001],
  [-77.8020001, 59.341509901],
  [-77.8020399, 59.342030101],
  [-77.8001, 59.342839901],
  [-77.8045899, 59.342609901],
  [-77.8055, 59.341440001],
  [-77.80627, 59.341680001],
  [-77.8071399, 59.343069901],
  [-77.80965, 59.343150101],
  [-77.81186, 59.345660001],
  [-77.80499, 59.347340001],
  [-77.8048199, 59.348370101],
  [-77.8061399, 59.349240001],
  [-77.8086899, 59.349700101],
  [-77.8095899, 59.348519901],
  [-77.8161499, 59.349020001],
  [-77.81696, 59.346700101],
  [-77.8194799, 59.346770001],
  [-77.82065, 59.348790001],
  [-77.82419, 59.349230001],
  [-77.8242599, 59.350000001],
  [-77.82655, 59.350460001],
  [-77.82685, 59.350969901],
  [-77.82584, 59.350990001],
  [-77.82565, 59.351760001],
  [-77.8280201, 59.352990001],
  [-77.82806, 59.353500101],
  [-77.82708, 59.353780001],
  [-77.82685, 59.353100001],
  [-77.82399, 59.352820101],
  [-77.8239299, 59.352050101],
  [-77.8213601, 59.351340001],
  [-77.8218001, 59.350560001],
  [-77.81975, 59.350090001],
  [-77.8193401, 59.351130001],
  [-77.81731, 59.350920001],
  [-77.8172499, 59.350150101],
  [-77.8116999, 59.349760001],
  [-77.81106, 59.351050101],
  [-77.8154, 59.351980001],
  [-77.81498, 59.353010001],
  [-77.8104699, 59.353110001],
  [-77.8113699, 59.354890001],
  [-77.8182699, 59.356399901],
  [-77.8222599, 59.356189901],
  [-77.82222, 59.355680001],
  [-77.82375, 59.355900001],
  [-77.82419, 59.355250001],
  [-77.82621, 59.355330001],
  [-77.82676, 59.355950101],
  [-77.83073, 59.355490101],
  [-77.83078, 59.356000001],
  [-77.83484, 59.356690001],
  [-77.8360599, 59.359220001],
  [-77.83412, 59.360040101],
  [-77.8352599, 59.361670001],
  [-77.8469101, 59.363079901],
  [-77.83177, 59.361870001],
  [-77.8323699, 59.363140001],
  [-77.8333699, 59.363120001],
  [-77.8334999, 59.364650001],
  [-77.83054, 59.365230001],
  [-77.83098, 59.364450001],
  [-77.82698, 59.364540001],
  [-77.82689, 59.363510101],
  [-77.8253799, 59.363550001],
  [-77.82584, 59.363020001],
  [-77.81988, 59.363660001],
  [-77.82029, 59.362629901],
  [-77.81828, 59.362550001],
  [-77.8158099, 59.362979901],
  [-77.81587, 59.363749901],
  [-77.8148901, 59.363900001],
  [-77.80577, 59.362940101],
  [-77.80621, 59.362170101],
  [-77.80521, 59.362189901],
  [-77.80564, 59.361409901],
  [-77.7955899, 59.361110001],
  [-77.79164, 59.358890001],
  [-77.79129, 59.357619901],
  [-77.7897899, 59.357650001],
  [-77.78974, 59.357140001],
  [-77.78175, 59.357570001],
  [-77.7817999, 59.358079901],
  [-77.7807899, 59.358100001],
  [-77.7814, 59.359370001],
  [-77.77942, 59.359670001],
  [-77.77948, 59.360430001],
  [-77.78048, 59.360409901],
  [-77.78029, 59.361189901],
  [-77.7815699, 59.361409901],
  [-77.78282, 59.364460001],
  [-77.7813301, 59.364620101],
  [-77.7797999, 59.364270101],
  [-77.7796899, 59.362990001],
  [-77.7753101, 59.361550001],
  [-77.7755, 59.360780001],
  [-77.77449, 59.360800001],
  [-77.7746, 59.362079901],
  [-77.76907, 59.361940101],
  [-77.7678999, 59.359910001],
  [-77.76638, 59.359690001],
  [-77.76652, 59.361480001],
  [-77.7639801, 59.361020001],
  [-77.76327, 59.361550001],
  [-77.76469, 59.363570001],
  [-77.76216, 59.363360001],
  [-77.7621199, 59.362849901],
  [-77.75905, 59.362150001],
  [-77.7589801, 59.361130001],
  [-77.7574499, 59.360900001],
  [-77.75739, 59.360140001],
  [-77.7558799, 59.360170101],
  [-77.75484, 59.362749901],
  [-77.7557601, 59.364910001],
  [-77.7578, 59.365380101],
  [-77.7596799, 59.363680001],
  [-77.76184, 59.365550001],
  [-77.77325, 59.364020001],
  [-77.7743499, 59.365150001],
  [-77.7753499, 59.365120001],
  [-77.77506, 59.367700001],
  [-77.77408, 59.367979901],
  [-77.7740099, 59.367210001],
  [-77.7730099, 59.367230001],
  [-77.7735799, 59.367990001],
  [-77.77108, 59.368170101],
  [-77.7624301, 59.366690001],
  [-77.7624599, 59.367199901],
  [-77.76049, 59.367500101],
  [-77.7604499, 59.366990001],
  [-77.75394, 59.367120001],
  [-77.7539801, 59.367629901],
  [-77.7529801, 59.367660001],
  [-77.75461, 59.369160101],
  [-77.75361, 59.369309901],
  [-77.7469899, 59.368050101],
  [-77.74615, 59.367030001],
  [-77.7468701, 59.366500101],
  [-77.7448, 59.365780001],
  [-77.74472, 59.364759901],
  [-77.7416301, 59.363800001],
  [-77.74225, 59.365320001],
  [-77.74125, 59.365340001],
  [-77.74157, 59.366100001],
  [-77.74091, 59.367400101],
  [-77.7435301, 59.368629901],
  [-77.74602, 59.368309901],
  [-77.7458, 59.368830101],
  [-77.7505401, 59.371550001],
  [-77.7506401, 59.372830101],
  [-77.7529801, 59.373800001],
  [-77.75302, 59.374309901],
  [-77.76147, 59.373370001],
  [-77.7617799, 59.374130001],
  [-77.76869, 59.375770001],
  [-77.76872, 59.376280101],
  [-77.77325, 59.376189901],
  [-77.7723399, 59.377490001],
  [-77.7577601, 59.377160101],
  [-77.7568, 59.377690001],
  [-77.7517899, 59.377790001],
  [-77.7386301, 59.376409901],
  [-77.73868, 59.376930001],
  [-77.7371901, 59.377220001],
  [-77.73612, 59.376189901],
  [-77.73304, 59.375500101],
  [-77.7335, 59.374979901],
  [-77.72502, 59.375539901],
  [-77.7215399, 59.376000001],
  [-77.72135, 59.376770001],
  [-77.7232901, 59.379160001],
  [-77.7267501, 59.378450001],
  [-77.7271901, 59.377670001],
  [-77.7261701, 59.377440001],
  [-77.7287, 59.377639901],
  [-77.7275499, 59.378950101],
  [-77.72934, 59.379419901],
  [-77.7295101, 59.381460001],
  [-77.71368, 59.384480001],
  [-77.7066299, 59.384120001],
  [-77.7017101, 59.385360001],
  [-77.70181, 59.386649901],
  [-77.7002999, 59.386429901],
  [-77.70025, 59.385910001],
  [-77.69065, 59.385089901],
  [-77.68946, 59.382810001],
  [-77.68846, 59.382830101],
  [-77.6868499, 59.381590001],
  [-77.6838499, 59.381639901],
  [-77.68379, 59.380879901],
  [-77.68179, 59.381170101],
  [-77.68176, 59.380660001],
  [-77.67868, 59.379700001],
  [-77.6785099, 59.377660001],
  [-77.6699301, 59.376940101],
  [-77.66786, 59.376209901],
  [-77.65076, 59.375660001],
  [-77.64966, 59.374409901],
  [-77.6481499, 59.374440001],
  [-77.64853, 59.372890001],
  [-77.6534899, 59.372150001],
  [-77.6590599, 59.372810001],
  [-77.66052, 59.372140001],
  [-77.65944, 59.371130001],
  [-77.64475, 59.369380101],
  [-77.64385, 59.370680001],
  [-77.64184, 59.370720101],
  [-77.64343, 59.371720101],
  [-77.64242, 59.371739901],
  [-77.64298, 59.372490001],
  [-77.64766, 59.374570001],
  [-77.6486099, 59.376990001],
  [-77.65188, 59.377180101],
  [-77.652, 59.378710001],
  [-77.6600401, 59.378800001],
  [-77.65753, 59.378850101],
  [-77.65711, 59.379890001],
  [-77.65412, 59.380199901],
  [-77.6541599, 59.380720101],
  [-77.6531599, 59.380740101],
  [-77.6527199, 59.381510101],
  [-77.6482501, 59.382120001],
  [-77.64764, 59.383920001],
  [-77.6486801, 59.384410101],
  [-77.64934, 59.386450001],
  [-77.65121, 59.387820001],
  [-77.65221, 59.387800001],
  [-77.6543501, 59.386350001],
  [-77.65541, 59.383759901],
  [-77.6533799, 59.383550001],
  [-77.65479, 59.382370001],
  [-77.6598299, 59.382520101],
  [-77.6619499, 59.384010001],
  [-77.66655, 59.385070101],
  [-77.6665901, 59.385580001],
  [-77.67045, 59.385670001],
  [-77.6700599, 59.385000001],
  [-77.67254, 59.384690001],
  [-77.67223, 59.383930001],
  [-77.67598, 59.383600001],
  [-77.67601, 59.384110001],
  [-77.6788301, 59.384820001],
  [-77.6797001, 59.386340001],
  [-77.6817101, 59.386300101],
  [-77.6822899, 59.387309901],
  [-77.67064, 59.386010001],
  [-77.6747101, 59.386700001],
  [-77.6778399, 59.388170101],
  [-77.6773801, 59.388690001],
  [-77.67986, 59.388380001],
  [-77.6799201, 59.389160001],
  [-77.6749499, 59.389769901],
  [-77.67725, 59.390230001],
  [-77.6781401, 59.392000001],
  [-77.67964, 59.391970101],
  [-77.6797101, 59.392740101],
  [-77.68331, 59.393950101],
  [-77.6833801, 59.394710001],
  [-77.6873801, 59.394500001],
  [-77.68956, 59.393560001],
  [-77.68967, 59.391769901],
  [-77.69859, 59.392520101],
  [-77.6991899, 59.391570001],
  [-77.6981899, 59.391590001],
  [-77.6976101, 59.390580001],
  [-77.70367, 59.391089901],
  [-77.70635, 59.393209901],
  [-77.70535, 59.393240001],
  [-77.70589, 59.393740101],
  [-77.70489, 59.393759901],
  [-77.70445, 59.394529901],
  [-77.7014001, 59.394089901],
  [-77.70148, 59.395109901],
  [-77.69944, 59.394640101],
  [-77.69933, 59.393360001],
  [-77.6962701, 59.392659901],
  [-77.69633, 59.393419901],
  [-77.69089, 59.394560001],
  [-77.69022, 59.395600001],
  [-77.69202, 59.396070101],
  [-77.6920601, 59.396590001],
  [-77.69856, 59.396190101],
  [-77.6986001, 59.396700001],
  [-77.70471, 59.397860101],
  [-77.70482, 59.399140001],
  [-77.70323, 59.398140001],
  [-77.69778, 59.399030001],
  [-77.69734, 59.399800001],
  [-77.7001799, 59.400769901],
  [-77.70348, 59.401340001],
  [-77.7042701, 59.400769901],
  [-77.70391, 59.400429901],
  [-77.7049401, 59.400669901],
  [-77.7055399, 59.401940001],
  [-77.71001, 59.401329901],
  [-77.70814, 59.399840101],
  [-77.7087499, 59.398160001],
  [-77.71827, 59.397830001],
  [-77.7187201, 59.397180101],
  [-77.72279, 59.397869901],
  [-77.7283099, 59.397740101],
  [-77.7305101, 59.400140001],
  [-77.728, 59.400190101],
  [-77.72789, 59.401979901],
  [-77.7290601, 59.403999901],
  [-77.7304001, 59.404999901],
  [-77.73447, 59.405690001],
  [-77.7345499, 59.406710001],
  [-77.73606, 59.406680001],
  [-77.73601, 59.406170001],
  [-77.73702, 59.406150001],
  [-77.7367301, 59.402570001],
  [-77.7402199, 59.402240001],
  [-77.74013, 59.401219901],
  [-77.73861, 59.400989901],
  [-77.7337699, 59.396999901],
  [-77.73224, 59.396769901],
  [-77.7326599, 59.395740101],
  [-77.73015, 59.395539901],
  [-77.73105, 59.394360001],
  [-77.7345, 59.393780001],
  [-77.74139, 59.395040001],
  [-77.74204, 59.399900001],
  [-77.74304, 59.399869901],
  [-77.7434201, 59.401400101],
  [-77.74471, 59.401889901],
  [-77.7446699, 59.401380001],
  [-77.7456699, 59.401360001],
  [-77.74573, 59.402120001],
  [-77.74771, 59.401700001],
  [-77.75285, 59.403250001],
  [-77.75281, 59.402740101],
  [-77.75181, 59.402759901],
  [-77.7519999, 59.401989901],
  [-77.7541901, 59.401180101],
  [-77.7548799, 59.397319901],
  [-77.7568999, 59.397400101],
  [-77.75902, 59.398769901],
  [-77.7625699, 59.399209901],
  [-77.76303, 59.398680001],
  [-77.76361, 59.399700001],
  [-77.7662001, 59.400669901],
  [-77.7702001, 59.400450001],
  [-77.77097, 59.400560001],
  [-77.7707601, 59.401080101],
  [-77.77427, 59.401010001],
  [-77.7743101, 59.401520101],
  [-77.77684, 59.401720001],
  [-77.7785599, 59.404370001],
  [-77.78716, 59.405080101],
  [-77.78995, 59.408480001],
  [-77.7914501, 59.408449901],
  [-77.79374, 59.409720001],
  [-77.79451, 59.409020001],
  [-77.7970001, 59.408720001],
  [-77.79743, 59.407810001],
  [-77.80695, 59.407600001],
  [-77.80719, 59.404400101],
  [-77.80851, 59.405140001],
  [-77.8088901, 59.406669901],
  [-77.81455, 59.408339901],
  [-77.8145999, 59.408850101],
  [-77.81261, 59.409150001],
  [-77.8162699, 59.410860101],
  [-77.81631, 59.411370001],
  [-77.81086, 59.412260001],
  [-77.8107999, 59.411750101],
  [-77.80931, 59.411779901],
  [-77.80927, 59.411270001],
  [-77.8066601, 59.410170001],
  [-77.80465, 59.410090101],
  [-77.8030399, 59.408840001],
  [-77.79909, 59.409680001],
  [-77.7991299, 59.410209901],
  [-77.7937801, 59.410150001],
  [-77.7936601, 59.410840001],
  [-77.7886601, 59.411200101],
  [-77.7886, 59.410429901],
  [-77.7865699, 59.410219901],
  [-77.78641, 59.411250001],
  [-77.7892499, 59.412209901],
  [-77.78941, 59.413999901],
  [-77.78394, 59.414879901],
  [-77.7826899, 59.411840001],
  [-77.77718, 59.411960101],
  [-77.7772399, 59.412720001],
  [-77.7682399, 59.413300101],
  [-77.7536599, 59.413219901],
  [-77.75473, 59.413970101],
  [-77.75372, 59.413989901],
  [-77.7547899, 59.414730001],
  [-77.75382, 59.415140001],
  [-77.7412001, 59.414510001],
  [-77.7406699, 59.414009901],
  [-77.7527401, 59.414270001],
  [-77.7520999, 59.412490001],
  [-77.7510901, 59.412510001],
  [-77.75047, 59.410989901],
  [-77.74947, 59.411010001],
  [-77.74993, 59.410480001],
  [-77.7484, 59.410260001],
  [-77.7488601, 59.409740101],
  [-77.7473399, 59.409510001],
  [-77.74725, 59.408490001],
  [-77.74625, 59.408510001],
  [-77.74717, 59.410539901],
  [-77.74602, 59.411850101],
  [-77.7375101, 59.412280001],
  [-77.73747, 59.411769901],
  [-77.73392, 59.411329901],
  [-77.73227, 59.412650101],
  [-77.73258, 59.413410101],
  [-77.72558, 59.413810001],
  [-77.72472, 59.415620001],
  [-77.7203099, 59.416989901],
  [-77.72012, 59.417769901],
  [-77.72789, 59.417600001],
  [-77.7271901, 59.415060001],
  [-77.73369, 59.414669901],
  [-77.7337301, 59.415180001],
  [-77.73625, 59.415380001],
  [-77.7363001, 59.415889901],
  [-77.7368, 59.415879901],
  [-77.7367401, 59.415109901],
  [-77.73927, 59.415319901],
  [-77.7393199, 59.415830001],
  [-77.74079, 59.415540101],
  [-77.7408699, 59.416559901],
  [-77.7434, 59.416769901],
  [-77.74372, 59.417530101],
  [-77.74604, 59.418380001],
  [-77.75061, 59.418920001],
  [-77.7506501, 59.419430101],
  [-77.7553001, 59.420999901],
  [-77.76181, 59.420730001],
  [-77.7605, 59.416920001],
  [-77.7650199, 59.416950001],
  [-77.7672299, 59.416260001],
  [-77.7669099, 59.415500001],
  [-77.7698999, 59.415180001],
  [-77.76984, 59.414420101],
  [-77.77061, 59.414659901],
  [-77.77095, 59.415669901],
  [-77.7749801, 59.415840001],
  [-77.77553, 59.416470001],
  [-77.7821299, 59.417099901],
  [-77.7826701, 59.417730001],
  [-77.7892301, 59.418099901],
  [-77.7903499, 59.419480001],
  [-77.79393, 59.420180001],
  [-77.79851, 59.417899901],
  [-77.79839, 59.416370001],
  [-77.7965, 59.414870101],
  [-77.7994799, 59.414420101],
  [-77.8025, 59.414500001],
  [-77.8025601, 59.415250001],
  [-77.80507, 59.415200101],
  [-77.8051101, 59.415710001],
  [-77.80663, 59.415810001],
  [-77.80718, 59.416439901],
  [-77.8102401, 59.417009901],
  [-77.8168001, 59.417250001],
  [-77.81686, 59.418020001],
  [-77.81941, 59.418480001],
  [-77.8194501, 59.418989901],
  [-77.82543, 59.418350001],
  [-77.8253401, 59.417320101],
  [-77.8286801, 59.417160001],
  [-77.8284999, 59.415970101],
  [-77.8438399, 59.416150001],
  [-77.84388, 59.416659901],
  [-77.85198, 59.417380001],
  [-77.85413, 59.413109901],
  [-77.85263, 59.413140001],
  [-77.8531, 59.412750101],
  [-77.8606299, 59.412710001],
  [-77.8608399, 59.412200101],
  [-77.86453, 59.411470001],
  [-77.8676101, 59.412050001],
  [-77.86743, 59.409999901],
  [-77.87024, 59.410449901],
  [-77.87055, 59.411219901],
  [-77.8720501, 59.411180101],
  [-77.87312, 59.411930001],
  [-77.87358, 59.411400001],
  [-77.87365, 59.412170001],
  [-77.87766, 59.412080101],
  [-77.8776101, 59.411570001],
  [-77.8786199, 59.411549901],
  [-77.87855, 59.410779901],
  [-77.87955, 59.410760101],
  [-77.87469, 59.406779901],
  [-77.8757, 59.406750101],
  [-77.87609, 59.405470001],
  [-77.8806199, 59.405620001],
  [-77.8815199, 59.404310101],
  [-77.88503, 59.404240001],
  [-77.8847101, 59.402539901],
  [-77.88235, 59.402250001],
  [-77.88481, 59.401669901],
  [-77.8845099, 59.402030001],
  [-77.8853901, 59.402559901],
  [-77.88737, 59.402250001],
  [-77.8919599, 59.403060001],
  [-77.8916001, 59.404740101],
  [-77.8961, 59.404500001],
  [-77.8961701, 59.405270001],
  [-77.89849, 59.405989901],
  [-77.89989, 59.407750101],
  [-77.9009, 59.407730001],
  [-77.8939401, 59.408649901],
  [-77.89448, 59.409020001],
  [-77.9030899, 59.409850101],
  [-77.90801, 59.408720001],
  [-77.90878, 59.408830001],
  [-77.90912, 59.409850101],
  [-77.9131901, 59.410530101],
  [-77.91315, 59.410010001],
  [-77.9148601, 59.409460001],
  [-77.91504, 59.408690001],
  [-77.9180899, 59.409010001],
  [-77.9185101, 59.408099901],
  [-77.92104, 59.408300101],
  [-77.92145, 59.407270001],
  [-77.91945, 59.407310101],
  [-77.9194001, 59.406800001],
  [-77.91546, 59.407659901],
  [-77.91489, 59.406900001],
  [-77.91237, 59.406830001],
  [-77.9099501, 59.407910001],
  [-77.90744, 59.407840001],
  [-77.90635, 59.406840001],
  [-77.9023099, 59.406669901],
  [-77.9013901, 59.404640101],
  [-77.9020899, 59.404109901],
  [-77.8981801, 59.403260001],
  [-77.8975399, 59.403700001],
  [-77.8979699, 59.402930001],
  [-77.89593, 59.402460001],
  [-77.8958499, 59.401439901],
  [-77.8998501, 59.401350001],
  [-77.89978, 59.400580001],
  [-77.9058401, 59.400960101],
  [-77.9058, 59.400450001],
  [-77.90381, 59.400750101],
  [-77.9047, 59.399319901],
  [-77.91683, 59.400199901],
  [-77.9161901, 59.398680001],
  [-77.91771, 59.398769901],
  [-77.9186499, 59.398109901],
  [-77.91212, 59.397999901],
  [-77.91271, 59.399010001],
  [-77.9121999, 59.399020001],
  [-77.9108599, 59.398030001],
  [-77.91559, 59.397539901],
  [-77.91604, 59.396889901],
  [-77.91401, 59.396680001],
  [-77.9139701, 59.396170001],
  [-77.9215499, 59.396769901],
  [-77.9219601, 59.395730101],
  [-77.92705, 59.396510101],
  [-77.92849, 59.395700001],
  [-77.92917, 59.391989901],
  [-77.9301801, 59.391960101],
  [-77.9284299, 59.389180101],
  [-77.9158401, 59.388700001],
  [-77.9155399, 59.388190101],
  [-77.91625, 59.387670001],
  [-77.91422, 59.387450001],
  [-77.92014, 59.386170101],
  [-77.9227699, 59.387649901],
  [-77.92528, 59.387590001],
  [-77.92636, 59.388460001],
  [-77.92758, 59.388180101],
  [-77.9281901, 59.386370001],
  [-77.9266599, 59.386150001],
  [-77.9266201, 59.385639901],
  [-77.92861, 59.385470001],
  [-77.93169, 59.386290101],
  [-77.9317401, 59.386810001],
  [-77.9292099, 59.386610101],
  [-77.9303001, 59.387610001],
  [-77.9293001, 59.387630101],
  [-77.9298899, 59.388639901],
  [-77.93706, 59.390270001],
  [-77.9370099, 59.389759901],
  [-77.94124, 59.392230001],
  [-77.9387401, 59.392280101],
  [-77.9382299, 59.395109901],
  [-77.93907, 59.396120001],
  [-77.93806, 59.396150001],
  [-77.9392199, 59.397780001],
  [-77.94318, 59.397180101],
  [-77.94451, 59.398030001],
  [-77.9438999, 59.399590001],
  [-77.94184, 59.399130001],
  [-77.9416599, 59.399900001],
  [-77.9446, 59.402010001],
  [-77.9461099, 59.401979901],
  [-77.9505599, 59.404050001],
  [-77.95184, 59.410170001],
  [-77.9497899, 59.409700001],
  [-77.94959, 59.410219901],
  [-77.95041, 59.410970101],
  [-77.9484, 59.411020001],
  [-77.94829, 59.409740101],
  [-77.94729, 59.409760101],
  [-77.94617, 59.408500001],
  [-77.9366699, 59.408980101],
  [-77.9358, 59.410530101],
  [-77.9368, 59.410510001],
  [-77.93793, 59.411760101],
  [-77.9408999, 59.411439901],
  [-77.9409899, 59.412460001],
  [-77.9429999, 59.412420101],
  [-77.94393, 59.411630101],
  [-77.94616, 59.414140001],
  [-77.94057, 59.413370001],
  [-77.93561, 59.414119901],
  [-77.9356501, 59.414630101],
  [-77.9346599, 59.414790001],
  [-77.9321099, 59.414329901],
  [-77.92412, 59.414889901],
  [-77.92402, 59.416690001],
  [-77.92534, 59.417420101],
  [-77.92434, 59.417449901],
  [-77.92595, 59.418690001],
  [-77.92895, 59.418500001],
  [-77.9370999, 59.419720001],
  [-77.93714, 59.420229901],
  [-77.93005, 59.419620001],
  [-77.93012, 59.420390001],
  [-77.92816, 59.420950001],
  [-77.9281099, 59.420439901],
  [-77.9260999, 59.420350001],
  [-77.92114, 59.421100101],
  [-77.92083, 59.423420101],
  [-77.91983, 59.423439901],
  [-77.9206301, 59.423930001],
  [-77.9201901, 59.424710001],
  [-77.92306, 59.425930001],
  [-77.92204, 59.425690001],
  [-77.92135, 59.426480001],
  [-77.92257, 59.429009901],
  [-77.92439, 59.429610001],
  [-77.9299, 59.429480001],
  [-77.93905, 59.430690001],
  [-77.94015, 59.431690001],
  [-77.93914, 59.431710001],
  [-77.9392099, 59.432480001],
  [-77.9386599, 59.431980101],
  [-77.93617, 59.432290001],
  [-77.9359701, 59.432810001],
  [-77.93806, 59.433779901],
  [-77.93818, 59.435060001],
  [-77.9394499, 59.435290001],
  [-77.94018, 59.437830001],
  [-77.93614, 59.437669901],
  [-77.9362199, 59.435620001],
  [-77.93537, 59.434610001],
  [-77.93227, 59.433660101],
  [-77.93273, 59.433140001],
  [-77.93173, 59.433160001],
  [-77.9284499, 59.430160001],
  [-77.9264299, 59.429950001],
  [-77.92704, 59.431219901],
  [-77.92256, 59.431830001],
  [-77.9214101, 59.430060001],
  [-77.91992, 59.430349901],
  [-77.9199701, 59.430860101],
  [-77.91593, 59.430700001],
  [-77.9159799, 59.431210101],
  [-77.9129899, 59.431520001],
  [-77.91389, 59.433300001],
  [-77.9166301, 59.432990101],
  [-77.91672, 59.434009901],
  [-77.9097401, 59.434679901],
  [-77.90983, 59.435700001],
  [-77.9083199, 59.435730001],
  [-77.9088599, 59.433160001],
  [-77.90801, 59.432150001],
  [-77.9034299, 59.431490001],
  [-77.90302, 59.432520001],
  [-77.90402, 59.432500001],
  [-77.9039601, 59.431730001],
  [-77.90446, 59.431720001],
  [-77.9050899, 59.433239901],
  [-77.89903, 59.432870101],
  [-77.89881, 59.430310101],
  [-77.8952701, 59.430129901],
  [-77.89521, 59.429360001],
  [-77.8961899, 59.429090101],
  [-77.89466, 59.428860101],
  [-77.8946201, 59.428349901],
  [-77.8850401, 59.428050001],
  [-77.88446, 59.427040001],
  [-77.87932, 59.425750101],
  [-77.8728301, 59.426270001],
  [-77.8717001, 59.424760101],
  [-77.8686199, 59.423930001],
  [-77.86311, 59.424180001],
  [-77.86319, 59.425210101],
  [-77.86219, 59.425229901],
  [-77.8628201, 59.426750101],
  [-77.86032, 59.426810001],
  [-77.8604801, 59.428850001],
  [-77.8559399, 59.428690001],
  [-77.8566001, 59.430470001],
  [-77.85509, 59.430500001],
  [-77.85564, 59.430999901],
  [-77.8535801, 59.430410001],
  [-77.8441, 59.431260001],
  [-77.84467, 59.432009901],
  [-77.84366, 59.432030001],
  [-77.84423, 59.432789901],
  [-77.84322, 59.432810001],
  [-77.84254, 59.433600001],
  [-77.8433701, 59.434600001],
  [-77.8403899, 59.434920001],
  [-77.8404701, 59.435950001],
  [-77.84252, 59.436410001],
  [-77.8425901, 59.437180001],
  [-77.8435901, 59.437160001],
  [-77.84363, 59.437669901],
  [-77.8446099, 59.437390001],
  [-77.8478201, 59.439630001],
  [-77.8448299, 59.439950001],
  [-77.84489, 59.440710001],
  [-77.8364, 59.441410001],
  [-77.8364701, 59.442180001],
  [-77.8402601, 59.442349901],
  [-77.8401501, 59.444150001],
  [-77.84155, 59.442840001],
  [-77.84565, 59.443770101],
  [-77.8452601, 59.445060001],
  [-77.84722, 59.444500001],
  [-77.8471799, 59.443990101],
  [-77.8517299, 59.444150001],
  [-77.8521, 59.445679901],
  [-77.8531501, 59.446170001],
  [-77.8529399, 59.446679901],
  [-77.8539399, 59.446660101],
  [-77.85374, 59.447180001],
  [-77.85752, 59.447349901],
  [-77.8574801, 59.446840001],
  [-77.8584801, 59.446810001],
  [-77.8583701, 59.445540101],
  [-77.8710401, 59.446660101],
  [-77.87212, 59.447550101],
  [-77.87112, 59.447569901],
  [-77.8730699, 59.449830001],
  [-77.87098, 59.451919901],
  [-77.8724901, 59.451890101],
  [-77.8720501, 59.452670101],
  [-77.87357, 59.452630001],
  [-77.8736001, 59.453150001],
  [-77.87909, 59.452770101],
  [-77.8777, 59.451009901],
  [-77.8794099, 59.450450101],
  [-77.88123, 59.448239901],
  [-77.88533, 59.449050001],
  [-77.88512, 59.449560101],
  [-77.8865001, 59.451070001],
  [-77.8875101, 59.451050001],
  [-77.8876201, 59.452320101],
  [-77.8856299, 59.452620001],
  [-77.8857399, 59.453899901],
  [-77.8787299, 59.454310001],
  [-77.8792999, 59.455070001],
  [-77.88845, 59.456150001],
  [-77.8884001, 59.455640001],
  [-77.89121, 59.456090001],
  [-77.8915199, 59.456850001],
  [-77.8966101, 59.457500001],
  [-77.89159, 59.457610001],
  [-77.8917, 59.458890101],
  [-77.89467, 59.458310001],
  [-77.89425, 59.459349901],
  [-77.89223, 59.459259901],
  [-77.8917599, 59.459660101],
  [-77.89277, 59.459640001],
  [-77.89281, 59.460150001],
  [-77.88834, 59.460760001],
  [-77.8875001, 59.459750001],
  [-77.8917201, 59.459150001],
  [-77.88912, 59.458180001],
  [-77.88869, 59.458960001],
  [-77.8866001, 59.458110101],
  [-77.8836001, 59.458300001],
  [-77.8827301, 59.459860001],
  [-77.8811999, 59.459640001],
  [-77.88133, 59.461170001],
  [-77.8748599, 59.462080001],
  [-77.8805001, 59.463239901],
  [-77.88045, 59.462730001],
  [-77.8849599, 59.462500001],
  [-77.88567, 59.464919901],
  [-77.89132, 59.466320001],
  [-77.8924199, 59.467330101],
  [-77.89847, 59.467320001],
  [-77.9056201, 59.468699901],
  [-77.90812, 59.468520001],
  [-77.9081901, 59.469280001],
  [-77.9099601, 59.469500001],
  [-77.91025, 59.470000101],
  [-77.90781, 59.470689901],
  [-77.9047599, 59.470380001],
  [-77.9046, 59.471410001],
  [-77.9059, 59.471890101],
  [-77.8861701, 59.470790101],
  [-77.8860501, 59.472330101],
  [-77.8868699, 59.473080001],
  [-77.8909599, 59.473760001],
  [-77.8920501, 59.474760001],
  [-77.89054, 59.474799901],
  [-77.89036, 59.475570101],
  [-77.89192, 59.476050001],
  [-77.8917399, 59.477070001],
  [-77.8907599, 59.477350101],
  [-77.8959, 59.478650001],
  [-77.89858, 59.480510001],
  [-77.8926201, 59.481410001],
  [-77.8921801, 59.482190001],
  [-77.8897101, 59.482750001],
  [-77.8916001, 59.484249901],
  [-77.89144, 59.485280001],
  [-77.89043, 59.485300001],
  [-77.8902999, 59.486840001],
  [-77.8961801, 59.487740001],
  [-77.89622, 59.488240101],
  [-77.90024, 59.488149901],
  [-77.90166, 59.487220001],
  [-77.90933, 59.488450101],
  [-77.9105201, 59.490489901],
  [-77.90293, 59.490140101],
  [-77.90204, 59.491440001],
  [-77.9005299, 59.491479901],
  [-77.90046, 59.490709901],
  [-77.90147, 59.490690101],
  [-77.90088, 59.489680101],
  [-77.8962999, 59.489140101],
  [-77.8928499, 59.489980001],
  [-77.88826, 59.489310001],
  [-77.88377, 59.489800101],
  [-77.88323, 59.492369901],
  [-77.8846001, 59.493620001],
  [-77.89014, 59.493630001],
  [-77.8930899, 59.492790101],
  [-77.8966101, 59.492719901],
  [-77.8989501, 59.493550001],
  [-77.89926, 59.494320001],
  [-77.8972701, 59.494620001],
  [-77.8969501, 59.496680101],
  [-77.89299, 59.497530001],
  [-77.8931701, 59.499580101],
  [-77.89168, 59.499870001],
  [-77.89154, 59.501149901],
  [-77.8928699, 59.502020101],
  [-77.8958499, 59.501570101],
  [-77.8959401, 59.502590101],
  [-77.8919599, 59.503190001],
  [-77.89203, 59.503960001],
  [-77.89102, 59.503980001],
  [-77.89059, 59.504760001],
  [-77.8894901, 59.503760001],
  [-77.8849699, 59.503860001],
  [-77.8845099, 59.504379901],
  [-77.88603, 59.504470101],
  [-77.8871, 59.505360101],
  [-77.8886101, 59.505180001],
  [-77.89026, 59.506800101],
  [-77.88877, 59.507090001],
  [-77.8887, 59.506330001],
  [-77.8871799, 59.506110001],
  [-77.88777, 59.507120001],
  [-77.8710599, 59.506210001],
  [-77.87047, 59.505210001],
  [-77.8663999, 59.504650001],
  [-77.8647101, 59.505580101],
  [-77.8650501, 59.506599901],
  [-77.86201, 59.506410001],
  [-77.8619301, 59.505389901],
  [-77.85987, 59.504920101],
  [-77.8598201, 59.507230001],
  [-77.86112, 59.507709901],
  [-77.85657, 59.507550001],
  [-77.85676, 59.509850001],
  [-77.8548201, 59.510660001],
  [-77.8563899, 59.511399901],
  [-77.84885, 59.511560001],
  [-77.84797, 59.513120001],
  [-77.8504899, 59.513190001],
  [-77.85112, 59.514590101],
  [-77.85909, 59.516460001],
  [-77.8594701, 59.517990001],
  [-77.85288, 59.517619901],
  [-77.8519399, 59.518410001],
  [-77.85135, 59.517399901],
  [-77.84635, 59.517760001],
  [-77.84719, 59.518770001],
  [-77.8470401, 59.520049901],
  [-77.84453, 59.520110001],
  [-77.8445801, 59.520870001],
  [-77.8461401, 59.521350001],
  [-77.8436099, 59.521150101],
  [-77.84468, 59.521900001],
  [-77.8416901, 59.522350001],
  [-77.83908, 59.521250101],
  [-77.83979, 59.520719901],
  [-77.8387001, 59.519719901],
  [-77.83932, 59.518169901],
  [-77.83832, 59.518190001],
  [-77.8377099, 59.516930101],
  [-77.8362, 59.516959901],
  [-77.8362401, 59.517470101],
  [-77.83375, 59.517780001],
  [-77.83344, 59.520090001],
  [-77.8309399, 59.520399901],
  [-77.83107, 59.521939901],
  [-77.8315801, 59.521930101],
  [-77.83151, 59.521159901],
  [-77.8325999, 59.522159901],
  [-77.8336099, 59.522140101],
  [-77.83365, 59.522650001],
  [-77.8321401, 59.522670001],
  [-77.83222, 59.523450001],
  [-77.82565, 59.523330001],
  [-77.82544, 59.523849901],
  [-77.8267199, 59.524080001],
  [-77.82531, 59.525389901],
  [-77.82431, 59.525409901],
  [-77.8211201, 59.523430001],
  [-77.82118, 59.524200001],
  [-77.81563, 59.524059901],
  [-77.8154601, 59.525090001],
  [-77.81733, 59.526330001],
  [-77.8103, 59.526739901],
  [-77.80797, 59.522949901],
  [-77.80643, 59.522719901],
  [-77.80605, 59.524010001],
  [-77.8050399, 59.524030101],
  [-77.80499, 59.523270101],
  [-77.79883, 59.522120001],
  [-77.7981299, 59.522900001],
  [-77.79994, 59.523380101],
  [-77.79953, 59.524410001],
  [-77.79831, 59.524949901],
  [-77.79965, 59.525939901],
  [-77.7981299, 59.525970001],
  [-77.79871, 59.526729901],
  [-77.7962201, 59.527040101],
  [-77.79632, 59.528320001],
  [-77.79784, 59.528409901],
  [-77.7989199, 59.529289901],
  [-77.7964501, 59.529849901],
  [-77.79584, 59.528580001],
  [-77.79328, 59.528130001],
  [-77.79208, 59.525849901],
  [-77.79108, 59.525870001],
  [-77.79104, 59.525360101],
  [-77.7880099, 59.525289901],
  [-77.78082, 59.523780001],
  [-77.7807801, 59.523270101],
  [-77.77726, 59.523350001],
  [-77.77617, 59.522350001],
  [-77.77516, 59.522370101],
  [-77.7789099, 59.525100001],
  [-77.77992, 59.525080001],
  [-77.78104, 59.526340001],
  [-77.7785799, 59.527160101],
  [-77.7785401, 59.526650001],
  [-77.77597, 59.526059901],
  [-77.7714201, 59.525900001],
  [-77.76793, 59.526360101],
  [-77.76797, 59.526870001],
  [-77.7669701, 59.527020001],
  [-77.76292, 59.526719901],
  [-77.7624599, 59.527250101],
  [-77.7665301, 59.527670001],
  [-77.7676601, 59.529179901],
  [-77.76269, 59.530059901],
  [-77.7616, 59.529059901],
  [-77.75713, 59.529920101],
  [-77.7545301, 59.532020001],
  [-77.75369, 59.537160101],
  [-77.7547, 59.537140001],
  [-77.75361, 59.539210001],
  [-77.7568899, 59.539270101],
  [-77.7574399, 59.539900001],
  [-77.7544201, 59.539959901],
  [-77.75558, 59.541729901],
  [-77.7570901, 59.541700001],
  [-77.75713, 59.542210001],
  [-77.75813, 59.542060101],
  [-77.7607601, 59.543409901],
  [-77.7657899, 59.543310001],
  [-77.76537, 59.544340001],
  [-77.7688999, 59.544270101],
  [-77.77049, 59.545260101],
  [-77.7670099, 59.545840101],
  [-77.76705, 59.546350001],
  [-77.75592, 59.545950101],
  [-77.74715, 59.543570001],
  [-77.74414, 59.543760001],
  [-77.7483001, 59.545210001],
  [-77.74835, 59.545720101],
  [-77.75728, 59.547069901],
  [-77.7596, 59.547790001],
  [-77.7596401, 59.548309901],
  [-77.75461, 59.548409901],
  [-77.75404, 59.547650001],
  [-77.74236, 59.546619901],
  [-77.7423199, 59.546110001],
  [-77.74081, 59.546140001],
  [-77.7391901, 59.544890001],
  [-77.72648, 59.543619901],
  [-77.72594, 59.543120001],
  [-77.726, 59.543890001],
  [-77.7219807, 59.547300401],
  [-77.7223001, 59.548060101],
  [-77.7238, 59.547900001],
  [-77.72602, 59.550419901],
  [-77.73128, 59.549920001],
  [-77.7310899, 59.550690001],
  [-77.73617, 59.551100001],
  [-77.7351801, 59.551380101],
  [-77.73583, 59.553160101],
  [-77.7403101, 59.552419901],
  [-77.7418799, 59.553160101],
  [-77.74491, 59.553220001],
  [-77.7449601, 59.553730101],
  [-77.7511901, 59.555910001],
  [-77.7507501, 59.556680001],
  [-77.7477799, 59.557390101],
  [-77.74172, 59.557390101],
  [-77.74491, 59.559380101],
  [-77.74592, 59.559350001],
  [-77.74513, 59.559959901],
  [-77.74461, 59.561930001],
  [-77.7441099, 59.561940101],
  [-77.74403, 59.560920001],
  [-77.73548, 59.561360001],
  [-77.7349999, 59.564700001],
  [-77.7358, 59.565189901],
  [-77.7333199, 59.565759901],
  [-77.73392, 59.567020001],
  [-77.73649, 59.567480001],
  [-77.73705, 59.568240001],
  [-77.7355399, 59.568270001],
  [-77.7365399, 59.571319901],
  [-77.73583, 59.571850101],
  [-77.7464101, 59.571630101],
  [-77.74625, 59.572659901],
  [-77.7475401, 59.573010001],
  [-77.74979, 59.572840101],
  [-77.74948, 59.572079901],
  [-77.75049, 59.572050001],
  [-77.7510999, 59.573450001],
  [-77.75338, 59.573529901],
  [-77.75324, 59.574800001],
  [-77.7603399, 59.575300101],
  [-77.7608999, 59.575930001],
  [-77.7598701, 59.575700001],
  [-77.75995, 59.576720001],
  [-77.75913, 59.576820001],
  [-77.7585499, 59.578030001],
  [-77.75703, 59.578060101],
  [-77.7576, 59.578820001],
  [-77.75561, 59.579120001],
  [-77.75517, 59.579890001],
  [-77.75725, 59.580749901],
  [-77.76182, 59.581030001],
  [-77.76191, 59.582060101],
  [-77.76291, 59.582030001],
  [-77.7554201, 59.582950101],
  [-77.7555, 59.583970101],
  [-77.7540099, 59.584260001],
  [-77.7538799, 59.582740101],
  [-77.7522001, 59.583800001],
  [-77.75306, 59.585060101],
  [-77.7475401, 59.585439901],
  [-77.74769, 59.587219901],
  [-77.7437401, 59.588329901],
  [-77.7463101, 59.588790001],
  [-77.74592, 59.590329901],
  [-77.7484399, 59.590280001],
  [-77.74838, 59.589520101],
  [-77.7498899, 59.589480001],
  [-77.74968, 59.589999901],
  [-77.7507699, 59.590999901],
  [-77.7506, 59.592020001],
  [-77.75571, 59.592690001],
  [-77.7563201, 59.593950001],
  [-77.7517899, 59.594180101],
  [-77.74971, 59.593449901],
  [-77.7477, 59.593620001],
  [-77.7465499, 59.594930001],
  [-77.74737, 59.595680001],
  [-77.73537, 59.597209901],
  [-77.7354101, 59.597720001],
  [-77.7344001, 59.597740101],
  [-77.73535, 59.600030002],
  [-77.73348, 59.603060002],
  [-77.7343299, 59.603119902],
  [-77.73427, 59.602350002],
  [-77.7357799, 59.602319902],
  [-77.7357401, 59.601810002],
  [-77.7407401, 59.601190102],
  [-77.7425499, 59.598600002],
  [-77.74813, 59.598860102],
  [-77.75259, 59.597740101],
  [-77.7545499, 59.600150002],
  [-77.7568601, 59.600600002],
  [-77.7568999, 59.601109902],
  [-77.76303, 59.601879902],
  [-77.7663201, 59.605009902],
  [-77.7653101, 59.605030002],
  [-77.76361, 59.602769902],
  [-77.7626, 59.602779902],
  [-77.7627501, 59.604830002],
  [-77.76226, 59.604840002],
  [-77.76161, 59.603060002],
  [-77.7556401, 59.604209902],
  [-77.7552199, 59.605229902],
  [-77.7542101, 59.605260002],
  [-77.7543201, 59.606540102],
  [-77.75126, 59.606099902],
  [-77.7511901, 59.605329902],
  [-77.7496699, 59.605360002],
  [-77.75129, 59.606610002],
  [-77.7408, 59.608109902],
  [-77.7409899, 59.610410102],
  [-77.7399999, 59.610679902],
  [-77.74101, 59.610659902],
  [-77.74326, 59.613430102],
  [-77.74626, 59.613109902],
  [-77.7463101, 59.613620002],
  [-77.7473101, 59.613600002],
  [-77.7485, 59.615750102],
  [-77.75302, 59.615400002],
  [-77.7543299, 59.616020002],
  [-77.7544, 59.616779902],
  [-77.75595, 59.617260002],
  [-77.7557601, 59.618040002],
  [-77.7567699, 59.618009902],
  [-77.75736, 59.619030002],
  [-77.75484, 59.619080102],
  [-77.7544399, 59.620370002],
  [-77.75194, 59.620679902],
  [-77.7529701, 59.620909902],
  [-77.75238, 59.622970002],
  [-77.7532101, 59.623850002],
  [-77.7542101, 59.623700002],
  [-77.75425, 59.624210102],
  [-77.7564599, 59.623400002],
  [-77.7561001, 59.622119902],
  [-77.7550901, 59.622150002],
  [-77.75651, 59.620970102],
  [-77.7603299, 59.621270002],
  [-77.7598999, 59.622300002],
  [-77.7622001, 59.622510002],
  [-77.76093, 59.619459902],
  [-77.7583499, 59.618750102],
  [-77.7603499, 59.618449902],
  [-77.76003, 59.617690002],
  [-77.7607601, 59.617290002],
  [-77.7684599, 59.618789902],
  [-77.7684201, 59.618280002],
  [-77.76972, 59.618770102],
  [-77.77003, 59.619530102],
  [-77.7725301, 59.619090102],
  [-77.77405, 59.619190002],
  [-77.77461, 59.619810002],
  [-77.77662, 59.619640102],
  [-77.7765599, 59.618880102],
  [-77.7775599, 59.618860102],
  [-77.77873, 59.620750102],
  [-77.78583, 59.620980102],
  [-77.7847601, 59.620229902],
  [-77.7862499, 59.619950002],
  [-77.7863401, 59.621229902],
  [-77.78785, 59.620940002],
  [-77.7878901, 59.621449902],
  [-77.7888099, 59.620410002],
  [-77.79085, 59.620620002],
  [-77.7925601, 59.622889902],
  [-77.7915501, 59.622909902],
  [-77.7915799, 59.623420102],
  [-77.79262, 59.623660102],
  [-77.79062, 59.623960002],
  [-77.79006, 59.623200102],
  [-77.78709, 59.624030002],
  [-77.7876501, 59.624789902],
  [-77.78619, 59.625339902],
  [-77.78642, 59.628140002],
  [-77.7874501, 59.628380002],
  [-77.7854501, 59.628669902],
  [-77.78527, 59.629449902],
  [-77.78615, 59.630960002],
  [-77.7851399, 59.630990102],
  [-77.7852, 59.631750002],
  [-77.78773, 59.631700002],
  [-77.7876501, 59.630930002],
  [-77.7912, 59.630870102],
  [-77.7933569, 59.631847302],
  [-77.7923401, 59.632500002],
  [-77.7898099, 59.632420002],
  [-77.78939, 59.633459902],
  [-77.7873499, 59.633239902],
  [-77.78748, 59.634780102],
  [-77.7864699, 59.634789902],
  [-77.7881001, 59.636040002],
  [-77.7855401, 59.635840002],
  [-77.78563, 59.636870102],
  [-77.78462, 59.636890102],
  [-77.78371, 59.638190002],
  [-77.7827, 59.638210102],
  [-77.78205, 59.639500002],
  [-77.7834, 59.640500002],
  [-77.78239, 59.640520002],
  [-77.78273, 59.641540102],
  [-77.7865799, 59.642230102],
  [-77.78662, 59.642730002],
  [-77.78737, 59.642459902],
  [-77.7884301, 59.640129902],
  [-77.7894501, 59.640239902],
  [-77.79085, 59.638799902],
  [-77.7937999, 59.637840002],
  [-77.8018801, 59.637670102],
  [-77.8070299, 59.638710002],
  [-77.8065799, 59.639490002],
  [-77.80509, 59.639780102],
  [-77.8051201, 59.640290002],
  [-77.7970101, 59.639950002],
  [-77.7980001, 59.642750002],
  [-77.79783, 59.643780102],
  [-77.79682, 59.643799902],
  [-77.7966601, 59.644820002],
  [-77.79996, 59.645009902],
  [-77.8000001, 59.645520002],
  [-77.8010101, 59.645500002],
  [-77.80162, 59.646770102],
  [-77.80108, 59.646270002],
  [-77.7970399, 59.646349902],
  [-77.79708, 59.646860002],
  [-77.79407, 59.647180002],
  [-77.79362, 59.647960002],
  [-77.79464, 59.647940002],
  [-77.79572, 59.648809902],
  [-77.8003, 59.649100102],
  [-77.8008901, 59.650110102],
  [-77.7977999, 59.649410002],
  [-77.7974401, 59.651210102],
  [-77.79694, 59.651220102],
  [-77.7968901, 59.650710002],
  [-77.79538, 59.650740002],
  [-77.7953401, 59.650239902],
  [-77.7922399, 59.649530002],
  [-77.7928801, 59.651050002],
  [-77.79187, 59.651070002],
  [-77.79195, 59.652090002],
  [-77.7940001, 59.652430002],
  [-77.7944799, 59.652039902],
  [-77.7934699, 59.652060002],
  [-77.7939099, 59.651410002],
  [-77.7964699, 59.651740002],
  [-77.79706, 59.652750002],
  [-77.7950199, 59.652540002],
  [-77.7961499, 59.653929902],
  [-77.80742, 59.655610002],
  [-77.8074899, 59.656369902],
  [-77.80339, 59.655689902],
  [-77.8036999, 59.656450102],
  [-77.80273, 59.656990002],
  [-77.80332, 59.658000102],
  [-77.8032599, 59.660300002],
  [-77.80427, 59.660270002],
  [-77.80483, 59.661039902],
  [-77.7998099, 59.661400002],
  [-77.7993699, 59.662180002],
  [-77.7983599, 59.662200002],
  [-77.7984, 59.662709902],
  [-77.80242, 59.662369902],
  [-77.80406, 59.663870002],
  [-77.80741, 59.664570102],
  [-77.8072499, 59.665599902],
  [-77.80977, 59.665540002],
  [-77.8101301, 59.666809902],
  [-77.8130001, 59.667780102],
  [-77.8130399, 59.668280002],
  [-77.80641, 59.667660002],
  [-77.8063599, 59.667149902],
  [-77.8053501, 59.667170002],
  [-77.80474, 59.665910102],
  [-77.8021499, 59.665190002],
  [-77.80272, 59.665950002],
  [-77.8006999, 59.665990002],
  [-77.80061, 59.664970002],
  [-77.79649, 59.664029902],
  [-77.79942, 59.665760002],
  [-77.79927, 59.667050002],
  [-77.80329, 59.666709902],
  [-77.8021101, 59.667750002],
  [-77.8075, 59.668660002],
  [-77.8075701, 59.669430002],
  [-77.80405, 59.669760002],
  [-77.80384, 59.670280002],
  [-77.80916, 59.670290002],
  [-77.80963, 59.669900102],
  [-77.8085999, 59.669660002],
  [-77.8095899, 59.669390002],
  [-77.8085799, 59.669410002],
  [-77.80851, 59.668640002],
  [-77.81051, 59.668469902],
  [-77.81572, 59.670020102],
  [-77.81473, 59.670300002],
  [-77.81574, 59.670280002],
  [-77.8201, 59.674029902],
  [-77.81907, 59.673790102],
  [-77.81963, 59.674420002],
  [-77.82322, 59.674990002],
  [-77.82329, 59.675750002],
  [-77.8196801, 59.675060002],
  [-77.8193501, 59.677120102],
  [-77.8203601, 59.677090002],
  [-77.8214701, 59.678220002],
  [-77.8275701, 59.678470102],
  [-77.82507, 59.679039902],
  [-77.82465, 59.679819902],
  [-77.81961, 59.680180002],
  [-77.8196701, 59.680960002],
  [-77.8171201, 59.680750002],
  [-77.81706, 59.679980002],
  [-77.8150201, 59.679770002],
  [-77.81508, 59.680540002],
  [-77.8161101, 59.680770002],
  [-77.8090101, 59.680670002],
  [-77.80872, 59.680159902],
  [-77.81096, 59.679599902],
  [-77.8109101, 59.679090002],
  [-77.8099199, 59.679369902],
  [-77.80987, 59.678860002],
  [-77.8067801, 59.678149902],
  [-77.8068199, 59.678660002],
  [-77.7926701, 59.678970002],
  [-77.79309, 59.677929902],
  [-77.7915799, 59.678220002],
  [-77.7915501, 59.677709902],
  [-77.78694, 59.677039902],
  [-77.7868999, 59.676530002],
  [-77.7908999, 59.675929902],
  [-77.7908099, 59.674910102],
  [-77.7887899, 59.674950002],
  [-77.78887, 59.675970002],
  [-77.7863401, 59.676029902],
  [-77.7857801, 59.675280002],
  [-77.7822201, 59.675090002],
  [-77.78281, 59.676100002],
  [-77.78131, 59.676390002],
  [-77.7813599, 59.676900102],
  [-77.78574, 59.677829902],
  [-77.7837601, 59.681460102],
  [-77.7817601, 59.681760002],
  [-77.78117, 59.680750002],
  [-77.7828999, 59.680200002],
  [-77.78309, 59.679430002],
  [-77.7790199, 59.679259902],
  [-77.77864, 59.680800102],
  [-77.7804499, 59.681280002],
  [-77.7807701, 59.682039902],
  [-77.7787899, 59.682589902],
  [-77.7803499, 59.683070002],
  [-77.7777999, 59.682870002],
  [-77.77841, 59.684140102],
  [-77.7768701, 59.683910102],
  [-77.77629, 59.683159902],
  [-77.7727701, 59.683230102],
  [-77.77314, 59.681690102],
  [-77.77418, 59.681920102],
  [-77.7740901, 59.680900102],
  [-77.77561, 59.680870002],
  [-77.7759901, 59.679320002],
  [-77.77293, 59.679130102],
  [-77.7726, 59.681190002],
  [-77.7701299, 59.682010102],
  [-77.7695599, 59.681250102],
  [-77.76706, 59.681560002],
  [-77.76883, 59.684599902],
  [-77.7657701, 59.684400002],
  [-77.76685, 59.685149902],
  [-77.7647899, 59.684929902],
  [-77.76438, 59.685970002],
  [-77.76539, 59.685950002],
  [-77.7649701, 59.686980002],
  [-77.76796, 59.686400002],
  [-77.7680099, 59.686929902],
  [-77.7705599, 59.687120102],
  [-77.77026, 59.686609902],
  [-77.7709999, 59.686350102],
  [-77.7718999, 59.688110002],
  [-77.77319, 59.688340002],
  [-77.77228, 59.689640002],
  [-77.7743301, 59.689860002],
  [-77.7749801, 59.691630002],
  [-77.77092, 59.691460102],
  [-77.77414, 59.693700102],
  [-77.7766799, 59.693760002],
  [-77.7772201, 59.694149902],
  [-77.7762001, 59.694169902],
  [-77.7777601, 59.694650002],
  [-77.7778701, 59.695929902],
  [-77.78375, 59.696570102],
  [-77.7835601, 59.697340002],
  [-77.77949, 59.697169902],
  [-77.7788999, 59.696159902],
  [-77.77741, 59.696450002],
  [-77.7785599, 59.698090002],
  [-77.78162, 59.698410002],
  [-77.7801001, 59.698440002],
  [-77.7806601, 59.699200002],
  [-77.7836799, 59.698750002],
  [-77.78473, 59.699240102],
  [-77.78928, 59.699140102],
  [-77.79161, 59.699729902],
  [-77.79151, 59.701530002],
  [-77.79507, 59.701709902],
  [-77.7950299, 59.701200002],
  [-77.79294, 59.700460002],
  [-77.79418, 59.700190002],
  [-77.7943699, 59.699420002],
  [-77.7958901, 59.699379902],
  [-77.79593, 59.699900002],
  [-77.79493, 59.700180002],
  [-77.79595, 59.700150102],
  [-77.7960199, 59.700920102],
  [-77.79809, 59.701509902],
  [-77.79909, 59.701360102],
  [-77.7991499, 59.702130102],
  [-77.7961201, 59.702200002],
  [-77.80007, 59.703900002],
  [-77.8004501, 59.705430002],
  [-77.80399, 59.705360102],
  [-77.8040299, 59.705870002],
  [-77.8085601, 59.705520002],
  [-77.8092401, 59.707550002],
  [-77.8021399, 59.707460002],
  [-77.8020101, 59.708980002],
  [-77.80431, 59.709190002],
  [-77.8043501, 59.709700102],
  [-77.79682, 59.710629902],
  [-77.7952, 59.709389902],
  [-77.7911299, 59.709220002],
  [-77.79063, 59.706150102],
  [-77.7892101, 59.704389902],
  [-77.78715, 59.703920102],
  [-77.7871001, 59.703410002],
  [-77.7881199, 59.703389902],
  [-77.78805, 59.702619902],
  [-77.78704, 59.702640002],
  [-77.78696, 59.701619902],
  [-77.78594, 59.701640002],
  [-77.7863401, 59.706499902],
  [-77.78174, 59.705959902],
  [-77.7736699, 59.706509902],
  [-77.77359, 59.705489902],
  [-77.7710199, 59.705030102],
  [-77.76741, 59.701269902],
  [-77.77119, 59.700930102],
  [-77.7710199, 59.698890002],
  [-77.7654399, 59.698750002],
  [-77.7646401, 59.698250102],
  [-77.76485, 59.697740002],
  [-77.7627799, 59.697269902],
  [-77.7632199, 59.696489902],
  [-77.7621901, 59.696260102],
  [-77.7603201, 59.698090002],
  [-77.7582299, 59.697360102],
  [-77.75705, 59.698410002],
  [-77.7574499, 59.700200002],
  [-77.7584599, 59.700180002],
  [-77.7585, 59.700690102],
  [-77.7625301, 59.700350102],
  [-77.7625699, 59.700860002],
  [-77.7615599, 59.700880002],
  [-77.76141, 59.702159902],
  [-77.7627, 59.702389902],
  [-77.76327, 59.703150102],
  [-77.75526, 59.704340002],
  [-77.75461, 59.702560002],
  [-77.75207, 59.702609902],
  [-77.7497, 59.698310002],
  [-77.7471199, 59.697850002],
  [-77.7470801, 59.697340002],
  [-77.7435201, 59.697159902],
  [-77.7421099, 59.698470102],
  [-77.74061, 59.698760002],
  [-77.7406699, 59.699520002],
  [-77.7421901, 59.699489902],
  [-77.7433299, 59.701010002],
  [-77.74484, 59.700970002],
  [-77.7444499, 59.702260102],
  [-77.7469899, 59.702340002],
  [-77.74691, 59.701440002],
  [-77.7497601, 59.702150102],
  [-77.7496301, 59.703690102],
  [-77.7481099, 59.703719902],
  [-77.74817, 59.704489902],
  [-77.74415, 59.704839902],
  [-77.7442299, 59.705860002],
  [-77.74124, 59.706430002],
  [-77.7427799, 59.706650002],
  [-77.74282, 59.707169902],
  [-77.74181, 59.707179902],
  [-77.74349, 59.709210002],
  [-77.73581, 59.708320002],
  [-77.7358799, 59.709100002],
  [-77.73539, 59.709110002],
  [-77.73534, 59.708600102],
  [-77.7323001, 59.708660002],
  [-77.73226, 59.708150102],
  [-77.73078, 59.708690102],
  [-77.7301901, 59.707680002],
  [-77.7263993, 59.707489402],
  [-77.7253001, 59.706760002],
  [-77.72703, 59.706210002],
  [-77.7254399, 59.702150102],
  [-77.7260899, 59.700850002],
  [-77.7270999, 59.700829902],
  [-77.7273199, 59.694169902],
  [-77.72625, 59.693420002],
  [-77.72636, 59.691630002],
  [-77.72481, 59.691410002],
  [-77.7240801, 59.688599902],
  [-77.7260999, 59.688560002],
  [-77.72726, 59.687259902],
  [-77.72689, 59.685730002],
  [-77.7278799, 59.685450002],
  [-77.71869, 59.684620002],
  [-77.7186499, 59.684110002],
  [-77.71503, 59.683159902],
  [-77.7147201, 59.682400002],
  [-77.71544, 59.681870002],
  [-77.71389, 59.681640002],
  [-77.7130601, 59.677570102],
  [-77.71156, 59.677850002],
  [-77.7093099, 59.675080002],
  [-77.7082901, 59.675100002],
  [-77.7066399, 59.673340102],
  [-77.6979301, 59.671990002],
  [-77.6968499, 59.671240102],
  [-77.6959, 59.672029902],
  [-77.6936199, 59.668750002],
  [-77.69156, 59.668280002],
  [-77.6915099, 59.667770002],
  [-77.6814901, 59.669000102],
  [-77.68056, 59.670039902],
  [-77.6790401, 59.670070002],
  [-77.67979, 59.673130102],
  [-77.68131, 59.673100002],
  [-77.6808599, 59.673870002],
  [-77.6823901, 59.673970002],
  [-77.6829499, 59.674599902],
  [-77.6819201, 59.674360102],
  [-77.68254, 59.675890002],
  [-77.67458, 59.677589902],
  [-77.67589, 59.678070002],
  [-77.67744, 59.681630002],
  [-77.67642, 59.681650002],
  [-77.6774901, 59.682389902],
  [-77.67401, 59.683100002],
  [-77.67143, 59.682520002],
  [-77.6713701, 59.681750002],
  [-77.6698499, 59.681780002],
  [-77.6698101, 59.681269902],
  [-77.6652799, 59.681620002],
  [-77.66257, 59.676300002],
  [-77.66524, 59.674699902],
  [-77.66798, 59.674139902],
  [-77.66764, 59.673120102],
  [-77.66663, 59.673139902],
  [-77.6671, 59.672620002],
  [-77.66502, 59.672020102],
  [-77.66046, 59.671990002],
  [-77.6600201, 59.672760002],
  [-77.6580201, 59.673060002],
  [-77.6566199, 59.674620002],
  [-77.6576199, 59.674599902],
  [-77.6593701, 59.677379902],
  [-77.6567099, 59.675650002],
  [-77.65444, 59.679020102],
  [-77.65197, 59.679840002],
  [-77.65201, 59.680350102],
  [-77.6419101, 59.680550002],
  [-77.64186, 59.680039902],
  [-77.64085, 59.680060002],
  [-77.64131, 59.679540002],
  [-77.6352301, 59.679410002],
  [-77.6341401, 59.678410002],
  [-77.6347901, 59.680190002],
  [-77.6270399, 59.678290002],
  [-77.62698, 59.677530002],
  [-77.62597, 59.677550002],
  [-77.62541, 59.676790102],
  [-77.6324799, 59.676650002],
  [-77.63243, 59.676139902],
  [-77.6334501, 59.676120102],
  [-77.6334899, 59.676630002],
  [-77.63685, 59.674249902],
  [-77.6383899, 59.674479902],
  [-77.6383501, 59.673970002],
  [-77.64187, 59.673640002],
  [-77.6417099, 59.674919902],
  [-77.6440301, 59.675390002],
  [-77.64395, 59.674369902],
  [-77.6470599, 59.675330002],
  [-77.64712, 59.676100002],
  [-77.6501499, 59.676029902],
  [-77.65011, 59.675520002],
  [-77.65401, 59.676740002],
  [-77.65242, 59.672660002],
  [-77.6493501, 59.672210002],
  [-77.64842, 59.673249902],
  [-77.64387, 59.673350102],
  [-77.6436901, 59.671039902],
  [-77.6447001, 59.671020102],
  [-77.64555, 59.668960002],
  [-77.6470599, 59.668929902],
  [-77.6470201, 59.668420002],
  [-77.64954, 59.668240102],
  [-77.64999, 59.667589902],
  [-77.65554, 59.667350102],
  [-77.65634, 59.664640002],
  [-77.6456801, 59.664359902],
  [-77.64512, 59.663589902],
  [-77.6471499, 59.663550002],
  [-77.64608, 59.662929902],
  [-77.64308, 59.663369902],
  [-77.64244, 59.661599902],
  [-77.6404701, 59.662400002],
  [-77.6403899, 59.661380002],
  [-77.63639, 59.661970002],
  [-77.63595, 59.662760002],
  [-77.6354, 59.662249902],
  [-77.62986, 59.662620002],
  [-77.6298099, 59.662110102],
  [-77.6268099, 59.662420002],
  [-77.62677, 59.661909902],
  [-77.6207099, 59.662029902],
  [-77.62065, 59.661259902],
  [-77.62217, 59.661359902],
  [-77.62263, 59.660720002],
  [-77.6195799, 59.660520002],
  [-77.6194601, 59.658980002],
  [-77.62809, 59.659320002],
  [-77.62709, 59.656270002],
  [-77.6277901, 59.655490002],
  [-77.6287901, 59.655479902],
  [-77.62764, 59.653440102],
  [-77.6261101, 59.653469902],
  [-77.62607, 59.652960002],
  [-77.62706, 59.652689902],
  [-77.6260499, 59.652699902],
  [-77.62599, 59.651940002],
  [-77.6251399, 59.651790102],
  [-77.6250399, 59.652730002],
  [-77.6175601, 59.654170002],
  [-77.61685, 59.654680102],
  [-77.61716, 59.655450102],
  [-77.61417, 59.656019902],
  [-77.61421, 59.656530002],
  [-77.6096601, 59.656620002],
  [-77.6096999, 59.657129902],
  [-77.5947, 59.659479902],
  [-77.59527, 59.660230102],
  [-77.59375, 59.660259902],
  [-77.59319, 59.662830002],
  [-77.59483, 59.664340102],
  [-77.60118, 59.664720002],
  [-77.60029, 59.666280002],
  [-77.6065899, 59.669230102],
  [-77.6025699, 59.669560102],
  [-77.6018901, 59.670599902],
  [-77.60543, 59.673860002],
  [-77.60642, 59.673580102],
  [-77.6070299, 59.674850002],
  [-77.60854, 59.674819902],
  [-77.60965, 59.675950002],
  [-77.61416, 59.675479902],
  [-77.6143, 59.677269902],
  [-77.6127901, 59.677300002],
  [-77.61285, 59.678060002],
  [-77.61184, 59.678080002],
  [-77.6121499, 59.678850002],
  [-77.61343, 59.679080002],
  [-77.6135501, 59.680609902],
  [-77.61561, 59.681080002],
  [-77.6168199, 59.683620002],
  [-77.6107999, 59.684250102],
  [-77.6095899, 59.685039902],
  [-77.6100199, 59.687080002],
  [-77.60752, 59.687640002],
  [-77.60686, 59.688939902],
  [-77.60863, 59.688900102],
  [-77.6086899, 59.689670002],
  [-77.6052, 59.690510002],
  [-77.6057999, 59.691520002],
  [-77.6076, 59.691990002],
  [-77.6090299, 59.694010002],
  [-77.60976, 59.693740002],
  [-77.6094699, 59.693240102],
  [-77.61076, 59.693470102],
  [-77.61228, 59.696770002],
  [-77.60874, 59.696839902],
  [-77.60765, 59.695829902],
  [-77.60462, 59.696150102],
  [-77.6045699, 59.695379902],
  [-77.5995401, 59.695740002],
  [-77.59915, 59.697279902],
  [-77.5976501, 59.697570002],
  [-77.5975501, 59.696290002],
  [-77.5985601, 59.696269902],
  [-77.59848, 59.695250102],
  [-77.5974699, 59.695269902],
  [-77.5968999, 59.694510002],
  [-77.5944, 59.694819902],
  [-77.5943599, 59.694310002],
  [-77.59284, 59.694340002],
  [-77.5920197, 59.693572702],
  [-77.5882363, 59.692462202],
  [-77.5818532, 59.692506502],
  [-77.5732088, 59.692669402],
  [-77.5706052, 59.694446402],
  [-77.5685, 59.694049902],
  [-77.5681001, 59.695330002],
  [-77.5666, 59.695619902],
  [-77.5666401, 59.696130102],
  [-77.56514, 59.696420002],
  [-77.5650999, 59.695910102],
  [-77.56117, 59.697520002],
  [-77.56059, 59.696509902],
  [-77.55193, 59.695910102],
  [-77.5518501, 59.694880002],
  [-77.55084, 59.694900002],
  [-77.5507799, 59.694140102],
  [-77.54625, 59.694480102],
  [-77.54569, 59.693719902],
  [-77.5410601, 59.692790102],
  [-77.54013, 59.690499902],
  [-77.5408401, 59.690100002],
  [-77.54238, 59.690200002],
  [-77.5439899, 59.691450002],
  [-77.5470701, 59.691900002],
  [-77.5477699, 59.691120002],
  [-77.54604, 59.688340002],
  [-77.5461901, 59.687059902],
  [-77.53949, 59.685520002],
  [-77.5369899, 59.685950002],
  [-77.53735, 59.687479902],
  [-77.5364101, 59.688269902],
  [-77.5367, 59.688780002],
  [-77.53569, 59.688800102],
  [-77.5356301, 59.688030102],
  [-77.5340701, 59.687420002],
  [-77.53205, 59.687590102],
  [-77.5318599, 59.688360102],
  [-77.53345, 59.689350102],
  [-77.53326, 59.690120002],
  [-77.5297201, 59.690190002],
  [-77.5283901, 59.692780002],
  [-77.5309899, 59.693630002],
  [-77.53425, 59.696499902],
  [-77.53323, 59.696520002],
  [-77.5318699, 59.698599902],
  [-77.5308599, 59.698619902],
  [-77.52994, 59.699920102],
  [-77.52582, 59.698970002],
  [-77.52471, 59.701040102],
  [-77.5256399, 59.703330002],
  [-77.52111, 59.703660002],
  [-77.5201801, 59.704710102],
  [-77.5166101, 59.704530002],
  [-77.51655, 59.703760002],
  [-77.5155399, 59.703780002],
  [-77.5166499, 59.705040102],
  [-77.5146499, 59.705330002],
  [-77.51468, 59.705839902],
  [-77.5121, 59.705130002],
  [-77.51213, 59.705640002],
  [-77.5086101, 59.705959902],
  [-77.50844, 59.703660002],
  [-77.5063801, 59.703190002],
  [-77.50369, 59.701200002],
  [-77.50646, 59.700880002],
  [-77.50288, 59.697110002],
  [-77.50033, 59.696900002],
  [-77.4993999, 59.697939902],
  [-77.49834, 59.697190002],
  [-77.4983701, 59.697690102],
  [-77.49736, 59.697719902],
  [-77.49779, 59.696690102],
  [-77.49625, 59.696460102],
  [-77.4967001, 59.695690102],
  [-77.50179, 59.695980002],
  [-77.5026399, 59.693780002],
  [-77.50109, 59.693560002],
  [-77.50114, 59.694070002],
  [-77.50014, 59.694340002],
  [-77.5005099, 59.692540002],
  [-77.50256, 59.692760002],
  [-77.50145, 59.691499902],
  [-77.4952999, 59.690470102],
  [-77.49278, 59.690640002],
  [-77.49313, 59.691920102],
  [-77.49544, 59.692379902],
  [-77.4954801, 59.692900002],
  [-77.4923999, 59.692440002],
  [-77.49244, 59.692949902],
  [-77.4909401, 59.693240102],
  [-77.4922899, 59.690910102],
  [-77.4912799, 59.690929902],
  [-77.4911899, 59.689900102],
  [-77.4901899, 59.689920102],
  [-77.4901501, 59.689410002],
  [-77.4876299, 59.689460102],
  [-77.4871799, 59.690240102],
  [-77.4836001, 59.689790102],
  [-77.4837299, 59.691590102],
  [-77.4827001, 59.691350102],
  [-77.48287, 59.693650002],
  [-77.4783601, 59.694240102],
  [-77.47841, 59.695010002],
  [-77.4769, 59.695039902],
  [-77.47609, 59.697870002],
  [-77.47808, 59.697320002],
  [-77.47864, 59.698080002],
  [-77.4786001, 59.697570002],
  [-77.47989, 59.697800102],
  [-77.48078, 59.699570002],
  [-77.47875, 59.699609902],
  [-77.47733, 59.700920102],
  [-77.4728101, 59.701520002],
  [-77.47787, 59.701420002],
  [-77.47931, 59.700240102],
  [-77.4838299, 59.699770002],
  [-77.4839401, 59.701310002],
  [-77.48546, 59.701279902],
  [-77.48755, 59.702260102],
  [-77.488, 59.704560002],
  [-77.48877, 59.704930102],
  [-77.4902799, 59.704770002],
  [-77.49024, 59.704260102],
  [-77.4917001, 59.703460002],
  [-77.4937299, 59.703430002],
  [-77.49368, 59.702660002],
  [-77.49266, 59.702680002],
  [-77.49331, 59.701130102],
  [-77.4922601, 59.700640002],
  [-77.49447, 59.699829902],
  [-77.49444, 59.699320002],
  [-77.4969699, 59.699399902],
  [-77.49757, 59.700540002],
  [-77.4996101, 59.700750002],
  [-77.50054, 59.699709902],
  [-77.50067, 59.701250102],
  [-77.50169, 59.701489902],
  [-77.4942, 59.702910102],
  [-77.50091, 59.704570002],
  [-77.5009599, 59.705080002],
  [-77.50599, 59.704729902],
  [-77.5081999, 59.707250102],
  [-77.51534, 59.707880002],
  [-77.51622, 59.709399902],
  [-77.5190501, 59.709980002],
  [-77.5194299, 59.708310002],
  [-77.5199401, 59.708300002],
  [-77.5199699, 59.708810102],
  [-77.5236101, 59.710020002],
  [-77.52357, 59.709509902],
  [-77.52613, 59.709849902],
  [-77.52822, 59.710700102],
  [-77.5277201, 59.714040102],
  [-77.53057, 59.714890002],
  [-77.5350899, 59.714409902],
  [-77.5369501, 59.712200002],
  [-77.5399701, 59.712010002],
  [-77.54113, 59.713780002],
  [-77.54316, 59.713870002],
  [-77.54581, 59.715230002],
  [-77.5504399, 59.716289902],
  [-77.55092, 59.715890002],
  [-77.54991, 59.715920102],
  [-77.54648, 59.710860002],
  [-77.5479899, 59.710700102],
  [-77.5532199, 59.712910002],
  [-77.55526, 59.713000002],
  [-77.55471, 59.712490102],
  [-77.5604, 59.713920102],
  [-77.56026, 59.715470102],
  [-77.56184, 59.716200002],
  [-77.56126, 59.718509902],
  [-77.55925, 59.718820102],
  [-77.5592099, 59.718299902],
  [-77.55613, 59.717839902],
  [-77.5554399, 59.718629902],
  [-77.5563101, 59.720150102],
  [-77.5573199, 59.720130002],
  [-77.55727, 59.719360002],
  [-77.5588, 59.719580002],
  [-77.5586301, 59.720609902],
  [-77.5594101, 59.720860002],
  [-77.55984, 59.719949902],
  [-77.5618799, 59.720040102],
  [-77.56114, 59.723640002],
  [-77.56369, 59.723839902],
  [-77.56504, 59.724839902],
  [-77.56472, 59.727409902],
  [-77.56851, 59.730399902],
  [-77.56829, 59.730920002],
  [-77.5693001, 59.730890002],
  [-77.56948, 59.733200002],
  [-77.57302, 59.733130002],
  [-77.5730801, 59.733900002],
  [-77.5715599, 59.733930102],
  [-77.5716, 59.734440002],
  [-77.5776799, 59.734320002],
  [-77.5777799, 59.735600102],
  [-77.5787899, 59.735580002],
  [-77.57836, 59.736610102],
  [-77.5727701, 59.736470002],
  [-77.57273, 59.735959902],
  [-77.5660901, 59.735320002],
  [-77.56539, 59.736100002],
  [-77.5666799, 59.736330002],
  [-77.5663101, 59.738130002],
  [-77.5678701, 59.738620102],
  [-77.56781, 59.737840102],
  [-77.56859, 59.738079902],
  [-77.56892, 59.739100002],
  [-77.5714499, 59.739060102],
  [-77.57149, 59.739560002],
  [-77.56872, 59.739870002],
  [-77.56882, 59.741150002],
  [-77.5701099, 59.741390102],
  [-77.5707601, 59.743289902],
  [-77.57227, 59.743120002],
  [-77.57196, 59.745700002],
  [-77.5755301, 59.745900002],
  [-77.5754301, 59.744610102],
  [-77.5764399, 59.744590002],
  [-77.5767601, 59.745350002],
  [-77.57804, 59.745580002],
  [-77.5786601, 59.747100002],
  [-77.5766401, 59.747140002],
  [-77.5767601, 59.748680002],
  [-77.5736401, 59.747720102],
  [-77.5736799, 59.748230002],
  [-77.56861, 59.748330002],
  [-77.5694101, 59.748820002],
  [-77.56817, 59.752429902],
  [-77.5698, 59.753940102],
  [-77.56969, 59.755730102],
  [-77.56817, 59.755759902],
  [-77.56807, 59.754480002],
  [-77.56603, 59.754390102],
  [-77.5645401, 59.754810002],
  [-77.5640901, 59.755580002],
  [-77.56257, 59.755610102],
  [-77.56261, 59.756120002],
  [-77.54492, 59.756979902],
  [-77.5448601, 59.756209902],
  [-77.53669, 59.755600002],
  [-77.53613, 59.754850102],
  [-77.5320899, 59.755180102],
  [-77.53046, 59.753680002],
  [-77.52481, 59.752759902],
  [-77.52344, 59.751510102],
  [-77.52366, 59.751000002],
  [-77.52212, 59.750759902],
  [-77.5210899, 59.743869902],
  [-77.51955, 59.743639902],
  [-77.5195001, 59.742880002],
  [-77.51848, 59.742900002],
  [-77.51844, 59.742380102],
  [-77.51536, 59.741930102],
  [-77.51533, 59.741419902],
  [-77.5132999, 59.741460002],
  [-77.5149401, 59.742959902],
  [-77.51344, 59.743250002],
  [-77.5136499, 59.742730102],
  [-77.51167, 59.739950102],
  [-77.5127399, 59.740700002],
  [-77.51399, 59.740419902],
  [-77.51414, 59.739140002],
  [-77.51313, 59.739160102],
  [-77.51303, 59.737880002],
  [-77.5146201, 59.738739902],
  [-77.51588, 59.738590002],
  [-77.51625, 59.736790002],
  [-77.5144199, 59.736060102],
  [-77.5200501, 59.736730102],
  [-77.52014, 59.738000002],
  [-77.5186201, 59.738040102],
  [-77.5184199, 59.738800002],
  [-77.51922, 59.739170102],
  [-77.52123, 59.739000002],
  [-77.5239, 59.737409902],
  [-77.5230701, 59.736409902],
  [-77.51892, 59.735210002],
  [-77.5167599, 59.733460002],
  [-77.51058, 59.732289902],
  [-77.51159, 59.735350002],
  [-77.51113, 59.736120002],
  [-77.5119599, 59.737130002],
  [-77.5109699, 59.737409902],
  [-77.5103901, 59.736390102],
  [-77.50781, 59.735930102],
  [-77.50948, 59.737820102],
  [-77.5105001, 59.737930102],
  [-77.5106001, 59.739210002],
  [-77.50958, 59.739230002],
  [-77.5092899, 59.742050102],
  [-77.51057, 59.742280102],
  [-77.51092, 59.743550002],
  [-77.5096201, 59.743069902],
  [-77.5098301, 59.742540002],
  [-77.5021799, 59.741930102],
  [-77.5021, 59.740900002],
  [-77.49653, 59.741010002],
  [-77.49657, 59.741519902],
  [-77.5009599, 59.742460002],
  [-77.50077, 59.743490102],
  [-77.49925, 59.743519902],
  [-77.4998201, 59.744280102],
  [-77.5008301, 59.744260002],
  [-77.5003801, 59.745030002],
  [-77.49792, 59.746100002],
  [-77.4978499, 59.745079902],
  [-77.4881501, 59.744240002],
  [-77.4877299, 59.745529902],
  [-77.4851601, 59.745069902],
  [-77.4842501, 59.746360002],
  [-77.4782, 59.746990002],
  [-77.47823, 59.747490102],
  [-77.47723, 59.747519902],
  [-77.47756, 59.748539902],
  [-77.47886, 59.749030002],
  [-77.47633, 59.749070102],
  [-77.4759, 59.750110002],
  [-77.47085, 59.750460002],
  [-77.4709301, 59.751480002],
  [-77.4719399, 59.751460002],
  [-77.4708201, 59.753529902],
  [-77.47365, 59.753990002],
  [-77.4716199, 59.754030002],
  [-77.4711699, 59.754800002],
  [-77.4726901, 59.754770002],
  [-77.47308, 59.756560002],
  [-77.47467, 59.757550002],
  [-77.47479, 59.759089902],
  [-77.4821501, 59.759209902],
  [-77.48219, 59.759720102],
  [-77.4850401, 59.760429902],
  [-77.48533, 59.760940002],
  [-77.48332, 59.761230002],
  [-77.4833899, 59.765580002],
  [-77.48732, 59.767290102],
  [-77.4887101, 59.768810002],
  [-77.4877299, 59.769329902],
  [-77.4877399, 59.772930002],
  [-77.48468, 59.772730102],
  [-77.4835901, 59.771720002],
  [-77.48101, 59.771010002],
  [-77.48108, 59.768700002],
  [-77.4797199, 59.767450002],
  [-77.47767, 59.767230002],
  [-77.47754, 59.765439902],
  [-77.476, 59.765209902],
  [-77.4753899, 59.767270002],
  [-77.4779599, 59.771060102],
  [-77.47644, 59.771080102],
  [-77.4763999, 59.770580002],
  [-77.4748399, 59.770099902],
  [-77.4750499, 59.772910002],
  [-77.4729301, 59.771659902],
  [-77.46988, 59.771730102],
  [-77.4691501, 59.775580002],
  [-77.46765, 59.775869902],
  [-77.4658101, 59.778460002],
  [-77.4619, 59.776999902],
  [-77.4599101, 59.774219902],
  [-77.45789, 59.774260002],
  [-77.4572401, 59.775800002],
  [-77.4579101, 59.778099902],
  [-77.45721, 59.778879902],
  [-77.4531699, 59.779209902],
  [-77.4532699, 59.780490002],
  [-77.45176, 59.780769902],
  [-77.45207, 59.781530102],
  [-77.4511, 59.782060002],
  [-77.45142, 59.783080102],
  [-77.44895, 59.783889902],
  [-77.4483799, 59.783140002],
  [-77.4473601, 59.783160002],
  [-77.44731, 59.782390002],
  [-77.4463, 59.782410102],
  [-77.44729, 59.782130002],
  [-77.4472501, 59.781620102],
  [-77.4462401, 59.781640102],
  [-77.4446901, 59.777830002],
  [-77.4453799, 59.776790002],
  [-77.44384, 59.776570002],
  [-77.44429, 59.775790002],
  [-77.4416999, 59.775070102],
  [-77.44164, 59.774300102],
  [-77.4390499, 59.773580002],
  [-77.43899, 59.772820002],
  [-77.4349199, 59.772640102],
  [-77.4335601, 59.771370002],
  [-77.43384, 59.768300102],
  [-77.43205, 59.768080102],
  [-77.42986, 59.769400102],
  [-77.43009, 59.772470002],
  [-77.4330001, 59.774209902],
  [-77.43199, 59.774230002],
  [-77.43308, 59.775230002],
  [-77.43464, 59.775710002],
  [-77.4326701, 59.776520102],
  [-77.4350201, 59.777500002],
  [-77.43542, 59.779539902],
  [-77.4320001, 59.778920002],
  [-77.43343, 59.780089902],
  [-77.4319101, 59.780120002],
  [-77.43251, 59.781380002],
  [-77.43398, 59.780720002],
  [-77.43455, 59.781600002],
  [-77.43352, 59.781370002],
  [-77.4335999, 59.782390002],
  [-77.4305701, 59.782700002],
  [-77.4291301, 59.783759902],
  [-77.4301399, 59.783730002],
  [-77.43176, 59.784980102],
  [-77.4286899, 59.784779902],
  [-77.4281301, 59.784030002],
  [-77.4246801, 59.785510002],
  [-77.42165, 59.785680002],
  [-77.4213599, 59.786290102],
  [-77.42198, 59.786700002],
  [-77.42176, 59.787219902],
  [-77.42664, 59.787889902],
  [-77.42696, 59.788910002],
  [-77.42097, 59.790300102],
  [-77.42016, 59.786219902],
  [-77.4210001, 59.786120002],
  [-77.42109, 59.784910002],
  [-77.42007, 59.784940002],
  [-77.41998, 59.783659902],
  [-77.41896, 59.783680002],
  [-77.4193401, 59.781879902],
  [-77.4177999, 59.781659902],
  [-77.41784, 59.782170002],
  [-77.4153301, 59.782740102],
  [-77.4148901, 59.783500002],
  [-77.41337, 59.783530102],
  [-77.41393, 59.784290102],
  [-77.4108901, 59.784329902],
  [-77.41083, 59.783580002],
  [-77.40982, 59.783600002],
  [-77.40974, 59.782570002],
  [-77.40619, 59.782640102],
  [-77.4047801, 59.784200102],
  [-77.3996899, 59.784050002],
  [-77.39975, 59.785070102],
  [-77.4013399, 59.785800002],
  [-77.4033499, 59.785510002],
  [-77.40306, 59.784999902],
  [-77.40431, 59.784720002],
  [-77.4054699, 59.786750102],
  [-77.40649, 59.786730002],
  [-77.40508, 59.788290002],
  [-77.40006, 59.789150002],
  [-77.4000199, 59.788640102],
  [-77.3969801, 59.788690002],
  [-77.39637, 59.790750102],
  [-77.3976799, 59.791370002],
  [-77.40275, 59.791280002],
  [-77.4043201, 59.791889902],
  [-77.40184, 59.792700002],
  [-77.4018701, 59.793209902],
  [-77.39729, 59.793040002],
  [-77.3973499, 59.793810002],
  [-77.3963301, 59.793830002],
  [-77.3968899, 59.794580002],
  [-77.38784, 59.795899902],
  [-77.38325, 59.795600002],
  [-77.3842001, 59.794690002],
  [-77.38926, 59.794339902],
  [-77.3902101, 59.793430102],
  [-77.39173, 59.793400002],
  [-77.39115, 59.792380002],
  [-77.3901299, 59.792400002],
  [-77.38952, 59.790879902],
  [-77.39105, 59.790980102],
  [-77.3920099, 59.790190102],
  [-77.3947701, 59.789760102],
  [-77.3944401, 59.788740102],
  [-77.39294, 59.789020002],
  [-77.39238, 59.788260002],
  [-77.3919099, 59.788790002],
  [-77.38884, 59.788590002],
  [-77.3886401, 59.785769902],
  [-77.38004, 59.786190102],
  [-77.3799999, 59.785669902],
  [-77.3658899, 59.787209902],
  [-77.3658501, 59.786700002],
  [-77.36484, 59.786720002],
  [-77.3647799, 59.785950002],
  [-77.36272, 59.785470002],
  [-77.36183, 59.787280002],
  [-77.3583199, 59.787860102],
  [-77.35825, 59.787090102],
  [-77.3546599, 59.786390002],
  [-77.35328, 59.784879902],
  [-77.3535, 59.784360002],
  [-77.3407201, 59.783180102],
  [-77.33724, 59.784260002],
  [-77.3265299, 59.783559902],
  [-77.32513, 59.785380002],
  [-77.32312, 59.785669902],
  [-77.32521, 59.786400002],
  [-77.3241999, 59.786659902],
  [-77.32424, 59.787180102],
  [-77.31911, 59.786510002],
  [-77.31915, 59.787020002],
  [-77.3161, 59.787070102],
  [-77.31623, 59.784080102],
  [-77.31537, 59.783759902],
  [-77.3159401, 59.784779902],
  [-77.3139301, 59.785070102],
  [-77.3144901, 59.785820002],
  [-77.3115001, 59.786640102],
  [-77.31198, 59.786120002],
  [-77.31045, 59.786150002],
  [-77.3104199, 59.785640102],
  [-77.3053801, 59.786240002],
  [-77.30542, 59.786750102],
  [-77.3024099, 59.787310102],
  [-77.3019499, 59.788090102],
  [-77.2983999, 59.788150002],
  [-77.299, 59.789420102],
  [-77.29801, 59.789820002],
  [-77.2929399, 59.789910002],
  [-77.28998, 59.791240002],
  [-77.2879399, 59.791150002],
  [-77.28757, 59.793200102],
  [-77.28656, 59.793209902],
  [-77.28309, 59.798150002],
  [-77.2833899, 59.798910002],
  [-77.28141, 59.799710002],
  [-77.28546, 59.799380002],
  [-77.2877101, 59.802549902],
  [-77.29079, 59.802999902],
  [-77.2923899, 59.804130002],
  [-77.2913701, 59.804150002],
  [-77.29197, 59.805420102],
  [-77.29543, 59.804080102],
  [-77.29547, 59.804590002],
  [-77.2984901, 59.804150002],
  [-77.30209, 59.804850002],
  [-77.3122601, 59.804930002],
  [-77.3162701, 59.804090102],
  [-77.3188201, 59.804180002],
  [-77.3188499, 59.804690002],
  [-77.3233901, 59.804219902],
  [-77.32488, 59.807400002],
  [-77.32782, 59.809520002],
  [-77.32935, 59.809500002],
  [-77.33126, 59.807800002],
  [-77.33582, 59.807590002],
  [-77.33946, 59.808679902],
  [-77.3413099, 59.806080102],
  [-77.34281, 59.805800002],
  [-77.3469899, 59.807390002],
  [-77.3525399, 59.806779902],
  [-77.35246, 59.805630002],
  [-77.3504101, 59.805410002],
  [-77.3518699, 59.804490002],
  [-77.3596, 59.806009902],
  [-77.3594299, 59.807040002],
  [-77.3582199, 59.808080102],
  [-77.35514, 59.807640102],
  [-77.35291, 59.812019902],
  [-77.34525, 59.811520002],
  [-77.34325, 59.812190002],
  [-77.3433199, 59.812960002],
  [-77.3408, 59.813260002],
  [-77.3388501, 59.814579902],
  [-77.3393099, 59.813799902],
  [-77.3367301, 59.813330102],
  [-77.33768, 59.812300002],
  [-77.33666, 59.812310102],
  [-77.3366301, 59.811799902],
  [-77.33257, 59.811870102],
  [-77.32999, 59.814990102],
  [-77.3308699, 59.816770102],
  [-77.32736, 59.817339902],
  [-77.3273801, 59.817850002],
  [-77.32891, 59.817820002],
  [-77.3289501, 59.818339902],
  [-77.33024, 59.818569902],
  [-77.3298699, 59.820620002],
  [-77.33067, 59.821119902],
  [-77.3240501, 59.820980102],
  [-77.3246499, 59.822249902],
  [-77.32214, 59.822810002],
  [-77.32157, 59.821789902],
  [-77.31901, 59.821579902],
  [-77.3173999, 59.820330102],
  [-77.3163801, 59.820349902],
  [-77.3152601, 59.818830002],
  [-77.3182899, 59.818520002],
  [-77.31725, 59.818290002],
  [-77.3177101, 59.817510002],
  [-77.31466, 59.817559902],
  [-77.31346, 59.818610002],
  [-77.31388, 59.820909902],
  [-77.3128401, 59.820669902],
  [-77.31258, 59.824260002],
  [-77.3100799, 59.824820002],
  [-77.3105199, 59.823780102],
  [-77.3059599, 59.824119902],
  [-77.30488, 59.823110102],
  [-77.3038599, 59.823129902],
  [-77.3044099, 59.823630002],
  [-77.3023701, 59.823670102],
  [-77.30165, 59.824190002],
  [-77.3019401, 59.824700002],
  [-77.3009201, 59.824720002],
  [-77.3012799, 59.826249902],
  [-77.30512, 59.826440102],
  [-77.30514, 59.826950002],
  [-77.3036199, 59.826970002],
  [-77.3027399, 59.829029902],
  [-77.30024, 59.829590002],
  [-77.3078399, 59.829200002],
  [-77.30787, 59.829720002],
  [-77.31045, 59.830180002],
  [-77.31109, 59.831960002],
  [-77.3118, 59.831440102],
  [-77.31187, 59.828620002],
  [-77.3216399, 59.830110102],
  [-77.32313, 59.829699902],
  [-77.3248, 59.826940002],
  [-77.3229501, 59.827150002],
  [-77.32299, 59.827660102],
  [-77.3194199, 59.827459902],
  [-77.3197301, 59.824640002],
  [-77.3181899, 59.824410002],
  [-77.3180601, 59.822620002],
  [-77.3201, 59.822590002],
  [-77.32013, 59.823100102],
  [-77.32166, 59.823200102],
  [-77.3259401, 59.826070002],
  [-77.32492, 59.826090002],
  [-77.32514, 59.826760102],
  [-77.3259799, 59.826840002],
  [-77.3259501, 59.826330102],
  [-77.3285101, 59.826660102],
  [-77.32879, 59.823330102],
  [-77.3334099, 59.823890102],
  [-77.33344, 59.824400002],
  [-77.3347301, 59.824630002],
  [-77.3346201, 59.826679902],
  [-77.3356301, 59.826670102],
  [-77.33567, 59.827180002],
  [-77.3371801, 59.826890102],
  [-77.3382801, 59.828150002],
  [-77.3408699, 59.828880102],
  [-77.34091, 59.829390002],
  [-77.34601, 59.829550102],
  [-77.3469601, 59.828640002],
  [-77.3489701, 59.828349902],
  [-77.35183, 59.829190002],
  [-77.35167, 59.830480002],
  [-77.35268, 59.830459902],
  [-77.35346, 59.834270002],
  [-77.35245, 59.834300002],
  [-77.35248, 59.834809902],
  [-77.348, 59.836180002],
  [-77.3436301, 59.831899902],
  [-77.3410899, 59.831940002],
  [-77.3417599, 59.834239902],
  [-77.34024, 59.834260002],
  [-77.3398501, 59.836060002],
  [-77.3372801, 59.835600002],
  [-77.33824, 59.838400002],
  [-77.3400899, 59.839390002],
  [-77.34313, 59.839200002],
  [-77.3451999, 59.839820002],
  [-77.3479601, 59.842830002],
  [-77.3489799, 59.842809902],
  [-77.35046, 59.845860002],
  [-77.3517699, 59.846350102],
  [-77.3507501, 59.846369902],
  [-77.35079, 59.846880002],
  [-77.34925, 59.846650002],
  [-77.34867, 59.845630002],
  [-77.34611, 59.845420002],
  [-77.3460701, 59.844900102],
  [-77.3419699, 59.844479902],
  [-77.3417499, 59.844990002],
  [-77.34983, 59.847660102],
  [-77.3498601, 59.848170002],
  [-77.35425, 59.845410002],
  [-77.3608501, 59.845290002],
  [-77.36569, 59.848909902],
  [-77.3682099, 59.848610002],
  [-77.3676, 59.850670102],
  [-77.3699801, 59.852050002],
  [-77.3740801, 59.852350102],
  [-77.3757601, 59.854620002],
  [-77.3747501, 59.854640002],
  [-77.37618, 59.856919902],
  [-77.3758, 59.858720002],
  [-77.37963, 59.858900102],
  [-77.3796, 59.858390002],
  [-77.38061, 59.858369902],
  [-77.3804101, 59.859149902],
  [-77.3813001, 59.860790102],
  [-77.38281, 59.860640002],
  [-77.3822299, 59.859630002],
  [-77.38729, 59.859280002],
  [-77.3876, 59.860039902],
  [-77.3868999, 59.860819902],
  [-77.3848601, 59.860860002],
  [-77.38593, 59.861610002],
  [-77.3844399, 59.862020102],
  [-77.37428, 59.862330002],
  [-77.3741199, 59.860280002],
  [-77.37259, 59.860060002],
  [-77.37228, 59.862880002],
  [-77.37126, 59.862900102],
  [-77.3700701, 59.863939902],
  [-77.37137, 59.864170002],
  [-77.37618, 59.867540002],
  [-77.3805301, 59.867850002],
  [-77.38036, 59.869130102],
  [-77.3783299, 59.869170002],
  [-77.37996, 59.870550002],
  [-77.38149, 59.870650002],
  [-77.38313, 59.872149902],
  [-77.3852001, 59.872630002],
  [-77.38525, 59.873400002],
  [-77.38627, 59.873379902],
  [-77.38685, 59.874389902],
  [-77.3878701, 59.874369902],
  [-77.3898701, 59.877410002],
  [-77.39117, 59.877640002],
  [-77.39172, 59.878389902],
  [-77.3876501, 59.878470102],
  [-77.3865699, 59.881049902],
  [-77.3904201, 59.881499902],
  [-77.39038, 59.880980002],
  [-77.39394, 59.880920102],
  [-77.3925599, 59.882990002],
  [-77.39105, 59.883279902],
  [-77.3910901, 59.883780002],
  [-77.3870199, 59.883860002],
  [-77.3869801, 59.883340002],
  [-77.38439, 59.882630002],
  [-77.3843499, 59.882120002],
  [-77.38282, 59.882140102],
  [-77.3828999, 59.883169902],
  [-77.38391, 59.883149902],
  [-77.38526, 59.884150102],
  [-77.3856, 59.885420002],
  [-77.38662, 59.885400002],
  [-77.3862199, 59.886949902],
  [-77.3888, 59.887410002],
  [-77.3889999, 59.886640002],
  [-77.38848, 59.886389902],
  [-77.3896699, 59.885350102],
  [-77.3908601, 59.887630002],
  [-77.3934499, 59.888220002],
  [-77.3926601, 59.891440002],
  [-77.39417, 59.891159902],
  [-77.3942299, 59.891920102],
  [-77.39217, 59.891700102],
  [-77.3922399, 59.892729902],
  [-77.39528, 59.892420002],
  [-77.3972299, 59.894680002],
  [-77.39704, 59.895710102],
  [-77.39603, 59.895719902],
  [-77.39586, 59.897010002],
  [-77.40217, 59.899710102],
  [-77.40361, 59.901980002],
  [-77.40464, 59.902100002],
  [-77.4057601, 59.903490102],
  [-77.4077701, 59.902939902],
  [-77.4076799, 59.901920102],
  [-77.4102301, 59.901870002],
  [-77.4096701, 59.901120002],
  [-77.4086501, 59.901130002],
  [-77.4072101, 59.898849902],
  [-77.4078801, 59.897560002],
  [-77.4063301, 59.897320002],
  [-77.40517, 59.895310002],
  [-77.4082299, 59.895379902],
  [-77.4106601, 59.897260102],
  [-77.4100099, 59.898800102],
  [-77.41261, 59.899520002],
  [-77.4140101, 59.901289902],
  [-77.4138099, 59.902059902],
  [-77.4127899, 59.902080002],
  [-77.41439, 59.903080002],
  [-77.41486, 59.902430002],
  [-77.4163699, 59.902270102],
  [-77.4166799, 59.899450002],
  [-77.4186601, 59.898650002],
  [-77.4183016, 59.897660702],
  [-77.4191499, 59.898380102],
  [-77.42161, 59.897190002],
  [-77.42416, 59.897260102],
  [-77.4245701, 59.899300002],
  [-77.4290101, 59.900760002],
  [-77.42828, 59.901279902],
  [-77.4302499, 59.903810102],
  [-77.4424501, 59.903330002],
  [-77.44186, 59.902310002],
  [-77.43575, 59.902430002],
  [-77.4356801, 59.901410002],
  [-77.4372401, 59.901890002],
  [-77.43664, 59.900619902],
  [-77.4376801, 59.900860002],
  [-77.43764, 59.900340002],
  [-77.4402, 59.900550002],
  [-77.4402599, 59.901320002],
  [-77.44128, 59.901300002],
  [-77.44132, 59.901810102],
  [-77.44231, 59.901540002],
  [-77.4426901, 59.899739902],
  [-77.44422, 59.899710102],
  [-77.4425801, 59.898210002],
  [-77.43953, 59.898260102],
  [-77.4394701, 59.897490102],
  [-77.43686, 59.896650002],
  [-77.42875, 59.897310002],
  [-77.4246601, 59.897000002],
  [-77.42249, 59.895250102],
  [-77.42096, 59.895269902],
  [-77.42041, 59.894640002],
  [-77.4188901, 59.894800102],
  [-77.41906, 59.897100002],
  [-77.4185602, 59.897452502],
  [-77.4174401, 59.895849902],
  [-77.4143699, 59.895650002],
  [-77.41482, 59.894880002],
  [-77.41793, 59.895590102],
  [-77.41819, 59.892250102],
  [-77.41561, 59.891800102],
  [-77.4134501, 59.890040102],
  [-77.42054, 59.889520002],
  [-77.42876, 59.890389902],
  [-77.4317801, 59.890080002],
  [-77.43494, 59.891300002],
  [-77.4374601, 59.890870002],
  [-77.43443, 59.887870002],
  [-77.4337901, 59.886080002],
  [-77.4348901, 59.883619902],
  [-77.4374, 59.883190002],
  [-77.4352699, 59.881949902],
  [-77.43733, 59.882169902],
  [-77.4385701, 59.885220002],
  [-77.4395899, 59.885200002],
  [-77.43996, 59.886729902],
  [-77.44529, 59.893550002],
  [-77.44819, 59.894770002],
  [-77.4505999, 59.899849902],
  [-77.44952, 59.902430002],
  [-77.4454999, 59.903270102],
  [-77.4454601, 59.902760002],
  [-77.4439399, 59.902790002],
  [-77.44399, 59.903550002],
  [-77.4485899, 59.903719902],
  [-77.44863, 59.904240002],
  [-77.44965, 59.904220002],
  [-77.4525999, 59.906200002],
  [-77.454, 59.907980002],
  [-77.44944, 59.908320002],
  [-77.44899, 59.909090002],
  [-77.45153, 59.909050102],
  [-77.4521201, 59.910059902],
  [-77.4511, 59.910079902],
  [-77.45118, 59.911230002],
  [-77.4527001, 59.911069902],
  [-77.4530301, 59.912090002],
  [-77.45433, 59.912450002],
  [-77.4547901, 59.911800002],
  [-77.45762, 59.912010002],
  [-77.4573639, 59.910581502],
  [-77.4592501, 59.909930102],
  [-77.45919, 59.909160102],
  [-77.46042, 59.908629902],
  [-77.4641699, 59.907660002],
  [-77.46675, 59.907990002],
  [-77.46743, 59.910279902],
  [-77.4649, 59.910590102],
  [-77.46442, 59.914440002],
  [-77.4659, 59.917230002],
  [-77.4633601, 59.917270102],
  [-77.45809, 59.912600102],
  [-77.4582799, 59.914040102],
  [-77.46156, 59.917050102],
  [-77.4614, 59.918340002],
  [-77.4603799, 59.918360002],
  [-77.4604801, 59.919629902],
  [-77.4594601, 59.919650002],
  [-77.4600599, 59.920910002],
  [-77.45855, 59.921200002],
  [-77.45666, 59.919700002],
  [-77.45652, 59.917920002],
  [-77.45874, 59.916849902],
  [-77.4579201, 59.916100002],
  [-77.4581301, 59.915580002],
  [-77.4571, 59.915340002],
  [-77.4561499, 59.916390102],
  [-77.4546099, 59.916160102],
  [-77.4539101, 59.913610102],
  [-77.45132, 59.913020002],
  [-77.44829, 59.913460002],
  [-77.44784, 59.914240002],
  [-77.4468199, 59.914260102],
  [-77.44642, 59.915800002],
  [-77.44239, 59.916390102],
  [-77.44231, 59.915360002],
  [-77.4407801, 59.915390102],
  [-77.4419101, 59.916910002],
  [-77.4373599, 59.917370102],
  [-77.4358001, 59.917020002],
  [-77.4360001, 59.916250002],
  [-77.43442, 59.915509902],
  [-77.43352, 59.913729902],
  [-77.4298901, 59.913030002],
  [-77.42985, 59.912260102],
  [-77.42063, 59.911670002],
  [-77.4205701, 59.910900002],
  [-77.41908, 59.911440002],
  [-77.4187901, 59.910930102],
  [-77.41928, 59.910670002],
  [-77.41819, 59.909660002],
  [-77.4184, 59.908890002],
  [-77.4168801, 59.909179902],
  [-77.41684, 59.908660002],
  [-77.41482, 59.908959902],
  [-77.4149099, 59.910240002],
  [-77.41753, 59.911210002],
  [-77.41865, 59.912480102],
  [-77.41664, 59.913020002],
  [-77.4166799, 59.913530002],
  [-77.4111399, 59.914399902],
  [-77.41105, 59.913120002],
  [-77.40384, 59.912230002],
  [-77.40219, 59.910470002],
  [-77.4032, 59.910450002],
  [-77.4031299, 59.909430002],
  [-77.4015799, 59.909200002],
  [-77.4009801, 59.907930102],
  [-77.39997, 59.907949902],
  [-77.39904, 59.912830102],
  [-77.4003301, 59.913059902],
  [-77.40037, 59.913570002],
  [-77.39583, 59.914040102],
  [-77.3916501, 59.912839902],
  [-77.38962, 59.913000002],
  [-77.3874599, 59.914830102],
  [-77.3873201, 59.916370102],
  [-77.37974, 59.917399902],
  [-77.37715, 59.916810002],
  [-77.3736401, 59.910470002],
  [-77.37262, 59.910490102],
  [-77.3719999, 59.908970002],
  [-77.37048, 59.909250002],
  [-77.3708, 59.910010002],
  [-77.3694201, 59.912090002],
  [-77.37048, 59.912830102],
  [-77.3710901, 59.917690002],
  [-77.3675499, 59.918010002],
  [-77.36751, 59.917500102],
  [-77.36546, 59.917270102],
  [-77.3658, 59.918550002],
  [-77.37815, 59.923700002],
  [-77.3782001, 59.924470002],
  [-77.3792299, 59.924450002],
  [-77.37926, 59.924959902],
  [-77.3807899, 59.924940102],
  [-77.3783001, 59.925749902],
  [-77.3791001, 59.926250002],
  [-77.3785401, 59.929079902],
  [-77.3795599, 59.929060102],
  [-77.3812199, 59.930950102],
  [-77.3829999, 59.930790002],
  [-77.38263, 59.929260002],
  [-77.3871299, 59.928020002],
  [-77.3912399, 59.928330002],
  [-77.39017, 59.927710102],
  [-77.38814, 59.927880002],
  [-77.38758, 59.927120002],
  [-77.3835, 59.927189902],
  [-77.3815401, 59.924670002],
  [-77.3820099, 59.924150102],
  [-77.3788799, 59.919600102],
  [-77.3844399, 59.918980002],
  [-77.3848899, 59.918079902],
  [-77.38619, 59.918450002],
  [-77.3865401, 59.919710102],
  [-77.3942399, 59.920470002],
  [-77.3987999, 59.920000002],
  [-77.3986699, 59.918210002],
  [-77.3976501, 59.918230002],
  [-77.3975401, 59.916700102],
  [-77.3995599, 59.916409902],
  [-77.4006699, 59.917660002],
  [-77.40219, 59.917639902],
  [-77.4022399, 59.918140002],
  [-77.4104301, 59.918639902],
  [-77.4108901, 59.917990002],
  [-77.41293, 59.917949902],
  [-77.4140101, 59.918830102],
  [-77.4155501, 59.918930102],
  [-77.4164799, 59.917760002],
  [-77.4180199, 59.917849902],
  [-77.41806, 59.921950102],
  [-77.4194799, 59.923980002],
  [-77.4164501, 59.924289902],
  [-77.4147899, 59.922529902],
  [-77.4122399, 59.922570002],
  [-77.4117899, 59.923350002],
  [-77.4107701, 59.923370102],
  [-77.4102, 59.922610102],
  [-77.4076899, 59.923170102],
  [-77.40908, 59.921100002],
  [-77.4101001, 59.921079902],
  [-77.4104699, 59.919280102],
  [-77.40482, 59.918739902],
  [-77.4013201, 59.919700002],
  [-77.40138, 59.920470002],
  [-77.3963101, 59.920820102],
  [-77.39583, 59.921340002],
  [-77.39994, 59.921770002],
  [-77.3999801, 59.922289902],
  [-77.3969801, 59.923110002],
  [-77.3975501, 59.923870002],
  [-77.39907, 59.923840102],
  [-77.3991101, 59.924350002],
  [-77.40219, 59.924680002],
  [-77.4043499, 59.926309902],
  [-77.4017999, 59.926089902],
  [-77.4031299, 59.927089902],
  [-77.4037601, 59.928620102],
  [-77.40316, 59.930930102],
  [-77.40629, 59.931900002],
  [-77.4063399, 59.932670002],
  [-77.4012299, 59.932500102],
  [-77.3992399, 59.929720102],
  [-77.3994699, 59.929210002],
  [-77.3979099, 59.928979902],
  [-77.39652, 59.930800002],
  [-77.39197, 59.931390102],
  [-77.3919999, 59.931900002],
  [-77.3909801, 59.931920002],
  [-77.39104, 59.932690002],
  [-77.3859801, 59.933299902],
  [-77.3858899, 59.932010002],
  [-77.38283, 59.932069902],
  [-77.3828899, 59.932840102],
  [-77.38037, 59.933270102],
  [-77.3671199, 59.933500102],
  [-77.3626, 59.934480002],
  [-77.3626499, 59.935250002],
  [-77.3570999, 59.935859902],
  [-77.35712, 59.936370002],
  [-77.3495101, 59.936890002],
  [-77.3439799, 59.938270102],
  [-77.3394001, 59.938220002],
  [-77.33858, 59.941050102],
  [-77.3400999, 59.941030002],
  [-77.34014, 59.941539902],
  [-77.3417, 59.941890002],
  [-77.34625, 59.941309902],
  [-77.3471999, 59.940390102],
  [-77.3512901, 59.940440002],
  [-77.3563101, 59.939330002],
  [-77.3659801, 59.938770002],
  [-77.3659999, 59.939280102],
  [-77.3700999, 59.939470002],
  [-77.36995, 59.937419902],
  [-77.3735301, 59.937480002],
  [-77.3739899, 59.936840102],
  [-77.37551, 59.936680002],
  [-77.38941, 59.938350002],
  [-77.38893, 59.938869902],
  [-77.39096, 59.938710002],
  [-77.3913301, 59.936780002],
  [-77.3852199, 59.936890002],
  [-77.38515, 59.935869902],
  [-77.3841299, 59.935890002],
  [-77.3922001, 59.934590002],
  [-77.3935501, 59.932120002],
  [-77.39406, 59.932120002],
  [-77.3941001, 59.932629902],
  [-77.39664, 59.932460002],
  [-77.40959, 59.935170102],
  [-77.41073, 59.936940102],
  [-77.40972, 59.936959902],
  [-77.41061, 59.938730102],
  [-77.4118999, 59.938979902],
  [-77.41196, 59.939730102],
  [-77.4088801, 59.939529902],
  [-77.41027, 59.941040002],
  [-77.4101001, 59.942330002],
  [-77.40652, 59.942140002],
  [-77.4065699, 59.942910002],
  [-77.40506, 59.943199902],
  [-77.4044635, 59.942423302],
  [-77.4036194, 59.942554302],
  [-77.4043031, 59.942038502],
  [-77.40386, 59.940649902],
  [-77.4023499, 59.940930002],
  [-77.4017801, 59.940170102],
  [-77.40026, 59.940199902],
  [-77.40037, 59.941730102],
  [-77.4024599, 59.942460002],
  [-77.3978701, 59.942550002],
  [-77.39848, 59.943820002],
  [-77.3964599, 59.944110002],
  [-77.39651, 59.944880002],
  [-77.39905, 59.944700002],
  [-77.3996601, 59.946099902],
  [-77.3966, 59.946160002],
  [-77.3987701, 59.947910002],
  [-77.40161, 59.948370002],
  [-77.40192, 59.949130002],
  [-77.39937, 59.949180102],
  [-77.3984699, 59.950730102],
  [-77.39337, 59.950820002],
  [-77.39226, 59.949560002],
  [-77.3912399, 59.949580002],
  [-77.3900901, 59.947810002],
  [-77.3926, 59.947250002],
  [-77.39296, 59.945199902],
  [-77.3955501, 59.945660002],
  [-77.39549, 59.944900002],
  [-77.39394, 59.944670002],
  [-77.3925799, 59.943409902],
  [-77.3922101, 59.941890002],
  [-77.3932299, 59.941869902],
  [-77.3926501, 59.940850102],
  [-77.3798, 59.939560002],
  [-77.37871, 59.938539902],
  [-77.37359, 59.938380102],
  [-77.37327, 59.940950102],
  [-77.3748, 59.941050102],
  [-77.37594, 59.942690002],
  [-77.37902, 59.942890002],
  [-77.38074, 59.945550002],
  [-77.3818, 59.946170102],
  [-77.38028, 59.946199902],
  [-77.38069, 59.948240002],
  [-77.3827, 59.951529902],
  [-77.38015, 59.951580002],
  [-77.3784599, 59.949299902],
  [-77.37571, 59.950120002],
  [-77.37636, 59.952160002],
  [-77.3756501, 59.952940002],
  [-77.37314, 59.953490002],
  [-77.3730999, 59.952979902],
  [-77.37158, 59.953010002],
  [-77.3719801, 59.951470002],
  [-77.3694101, 59.951260002],
  [-77.36935, 59.950490002],
  [-77.3677799, 59.949879902],
  [-77.3616499, 59.949859902],
  [-77.36169, 59.950370002],
  [-77.3606699, 59.950390102],
  [-77.3613199, 59.952170102],
  [-77.3654201, 59.952610102],
  [-77.36671, 59.956170102],
  [-77.36879, 59.956900002],
  [-77.3642901, 59.958260002],
  [-77.3642199, 59.957240002],
  [-77.36214, 59.956759902],
  [-77.3609799, 59.954740102],
  [-77.3563199, 59.953800002],
  [-77.35628, 59.953280102],
  [-77.35424, 59.953319902],
  [-77.3548401, 59.954590002],
  [-77.3508, 59.955170102],
  [-77.35003, 59.958780002],
  [-77.34645, 59.958580002],
  [-77.3465201, 59.959600002],
  [-77.3455, 59.959620102],
  [-77.34545, 59.958850102],
  [-77.3398, 59.958439902],
  [-77.3368401, 59.952610102],
  [-77.33533, 59.952890002],
  [-77.33526, 59.951869902],
  [-77.3331801, 59.951390102],
  [-77.3326, 59.950380002],
  [-77.3310501, 59.950150002],
  [-77.32913, 59.951969902],
  [-77.32304, 59.952340002],
  [-77.3195199, 59.949580002],
  [-77.3197499, 59.949070102],
  [-77.3187101, 59.948830102],
  [-77.3180899, 59.950890002],
  [-77.3186101, 59.951140002],
  [-77.31847, 59.952670002],
  [-77.31745, 59.952690002],
  [-77.3170401, 59.954230002],
  [-77.31448, 59.954020002],
  [-77.31433, 59.955560002],
  [-77.31613, 59.955790002],
  [-77.3161701, 59.956549902],
  [-77.3091, 59.957450002],
  [-77.3073901, 59.958500002],
  [-77.3077101, 59.959520102],
  [-77.3087299, 59.959510102],
  [-77.30879, 59.960270002],
  [-77.31299, 59.961989902],
  [-77.3146299, 59.963490002],
  [-77.3171899, 59.963700002],
  [-77.31648, 59.964490002],
  [-77.31754, 59.964979902],
  [-77.31748, 59.967810002],
  [-77.32189, 59.968740102],
  [-77.3219799, 59.970020002],
  [-77.3232999, 59.970640102],
  [-77.32735, 59.970180102],
  [-77.3273801, 59.970690002],
  [-77.3291899, 59.970920002],
  [-77.3300799, 59.972690002],
  [-77.32856, 59.972720002],
  [-77.32815, 59.974260002],
  [-77.3291701, 59.974250002],
  [-77.3291999, 59.974759902],
  [-77.3353099, 59.974390002],
  [-77.33536, 59.975160002],
  [-77.33434, 59.975180102],
  [-77.33389, 59.975950002],
  [-77.3303099, 59.976020002],
  [-77.33035, 59.976530102],
  [-77.3273801, 59.977860102],
  [-77.3262999, 59.977109902],
  [-77.3261, 59.977879902],
  [-77.3279401, 59.978620002],
  [-77.3263901, 59.978390002],
  [-77.3259699, 59.979680002],
  [-77.32191, 59.980009902],
  [-77.3218699, 59.979500002],
  [-77.32034, 59.979520102],
  [-77.3202899, 59.978760102],
  [-77.32132, 59.978860102],
  [-77.32202, 59.977960102],
  [-77.3211999, 59.977200102],
  [-77.30787, 59.976669902],
  [-77.3072999, 59.975400002],
  [-77.3062701, 59.975420102],
  [-77.30621, 59.974640102],
  [-77.3051899, 59.974669902],
  [-77.3051601, 59.974160002],
  [-77.30358, 59.973539902],
  [-77.3020599, 59.973700002],
  [-77.30288, 59.974449902],
  [-77.3023801, 59.978300102],
  [-77.29731, 59.978900002],
  [-77.2973999, 59.980170002],
  [-77.30355, 59.980460002],
  [-77.30436, 59.981080102],
  [-77.30432, 59.984160002],
  [-77.3058499, 59.984130002],
  [-77.30589, 59.984640102],
  [-77.30435, 59.984669902],
  [-77.30446, 59.986200102],
  [-77.3003801, 59.986270002],
  [-77.2992701, 59.985009902],
  [-77.2977399, 59.985040002],
  [-77.29543, 59.981240002],
  [-77.2944099, 59.981260002],
  [-77.2943, 59.983310102],
  [-77.29597, 59.985320102],
  [-77.29575, 59.985840002],
  [-77.29678, 59.985950002],
  [-77.2979301, 59.987850002],
  [-77.2938499, 59.987920002],
  [-77.29243, 59.985899902],
  [-77.29032, 59.984910002],
  [-77.28809, 59.982130002],
  [-77.28698, 59.977290002],
  [-77.2911401, 59.978109902],
  [-77.29098, 59.975940002],
  [-77.28265, 59.973649902],
  [-77.2796099, 59.974090102],
  [-77.27956, 59.973319902],
  [-77.2790401, 59.973329902],
  [-77.2772699, 59.977200102],
  [-77.27676, 59.977209902],
  [-77.27611, 59.975170002],
  [-77.27564, 59.975690002],
  [-77.2735801, 59.975470002],
  [-77.2733799, 59.976240002],
  [-77.27421, 59.977250002],
  [-77.27319, 59.977260002],
  [-77.27231, 59.979329902],
  [-77.27129, 59.979350002],
  [-77.27141, 59.981140002],
  [-77.27444, 59.980580002],
  [-77.2744801, 59.981090102],
  [-77.27345, 59.981109902],
  [-77.273, 59.981879902],
  [-77.2740201, 59.981860102],
  [-77.27398, 59.981350002],
  [-77.2785901, 59.981530102],
  [-77.27863, 59.982040002],
  [-77.2801599, 59.982009902],
  [-77.28019, 59.982530102],
  [-77.2814899, 59.982760102],
  [-77.28153, 59.983270002],
  [-77.2849599, 59.984999902],
  [-77.28342, 59.984769902],
  [-77.28257, 59.987350002],
  [-77.28053, 59.987380002],
  [-77.28311, 59.987850002],
  [-77.2831501, 59.988360002],
  [-77.28466, 59.988080102],
  [-77.2847199, 59.989100102],
  [-77.28419, 59.988600002],
  [-77.28064, 59.989170002],
  [-77.279, 59.987410102],
  [-77.28001, 59.987270002],
  [-77.28046, 59.986360002],
  [-77.2783999, 59.986140002],
  [-77.27754, 59.988459902],
  [-77.27856, 59.988439902],
  [-77.2781301, 59.989730002],
  [-77.2709, 59.988449902],
  [-77.26476, 59.988420102],
  [-77.26496, 59.987650102],
  [-77.26409, 59.986130002],
  [-77.261, 59.985669902],
  [-77.2601599, 59.984659902],
  [-77.26065, 59.984410102],
  [-77.2603401, 59.983640102],
  [-77.26534, 59.982009902],
  [-77.26522, 59.980219902],
  [-77.26118, 59.980930002],
  [-77.2585899, 59.980339902],
  [-77.2564601, 59.979090102],
  [-77.2574799, 59.979080102],
  [-77.2569199, 59.978319902],
  [-77.2518001, 59.978150002],
  [-77.25176, 59.977640102],
  [-77.25074, 59.977659902],
  [-77.2501301, 59.976130002],
  [-77.24911, 59.976150002],
  [-77.24855, 59.975390002],
  [-77.24399, 59.975980102],
  [-77.2459101, 59.977999902],
  [-77.2454899, 59.979280002],
  [-77.24864, 59.980510002],
  [-77.24894, 59.981270002],
  [-77.24587, 59.981070002],
  [-77.24583, 59.980559902],
  [-77.24027, 59.981420102],
  [-77.24139, 59.982810002],
  [-77.24241, 59.982920002],
  [-77.24141, 59.983190102],
  [-77.24197, 59.983950002],
  [-77.2404699, 59.984229902],
  [-77.2412599, 59.984730002],
  [-77.24106, 59.985500002],
  [-77.2425899, 59.985470002],
  [-77.2422301, 59.987779902],
  [-77.2432499, 59.987769902],
  [-77.24341, 59.990070002],
  [-77.2419001, 59.990349902],
  [-77.24408, 59.992360002],
  [-77.24766, 59.992430102],
  [-77.2481201, 59.991779902],
  [-77.25017, 59.991750102],
  [-77.2502, 59.992270002],
  [-77.24918, 59.992270002],
  [-77.2499001, 59.995329902],
  [-77.24631, 59.995140002],
  [-77.24517, 59.993370002],
  [-77.24363, 59.993390002],
  [-77.2436701, 59.993899902],
  [-77.24216, 59.994190102],
  [-77.2417099, 59.995219902],
  [-77.24684, 59.995510002],
  [-77.24743, 59.996660102],
  [-77.25207, 59.997219902],
  [-77.2556099, 59.996520002],
  [-77.25495, 59.994480002],
  [-77.2590399, 59.994540102],
  [-77.26155, 59.993860102],
  [-77.263, 59.992549902],
  [-77.26555, 59.992510002],
  [-77.26541, 59.990459902],
  [-77.2674601, 59.990549902],
  [-77.27007, 59.991410102],
  [-77.27011, 59.991920002],
  [-77.2757099, 59.991690002],
  [-77.2771699, 59.990640102],
  [-77.2812799, 59.990830002],
  [-77.2843, 59.990260002],
  [-77.2853899, 59.991140002],
  [-77.29568, 59.992239902],
  [-77.29832, 59.993480002],
  [-77.29836, 59.993990102],
  [-77.30144, 59.994190102],
  [-77.30146, 59.994700002],
  [-77.30512, 59.995660102],
  [-77.3051501, 59.996170002],
  [-77.3071601, 59.995630002],
  [-77.30721, 59.996390002],
  [-77.30824, 59.996380002],
  [-77.3082701, 59.996889902],
  [-77.32311, 59.997140002],
  [-77.32315, 59.997660102],
  [-77.330033, 59.999999902],
  [-77.33293, 60.002339902],
  [-77.33271, 60.002860002],
  [-77.33477, 60.003080002],
  [-77.3347401, 60.002569902],
  [-77.3360501, 60.003050002],
  [-77.3375299, 60.005840002],
  [-77.3365001, 60.005860002],
  [-77.33866, 60.007359902],
  [-77.3376301, 60.007380002],
  [-77.33767, 60.007889902],
  [-77.3350799, 60.007420102],
  [-77.3346101, 60.007940002],
  [-77.33348, 60.006430102],
  [-77.33193, 60.006200102],
  [-77.3319799, 60.006980102],
  [-77.33302, 60.007200102],
  [-77.32846, 60.007799902],
  [-77.3280799, 60.009600002],
  [-77.32399, 60.009669902],
  [-77.32403, 60.010180002],
  [-77.323, 60.010200102],
  [-77.3230601, 60.010960002],
  [-77.3215299, 60.010990102],
  [-77.32157, 60.011500002],
  [-77.3195199, 60.011540102],
  [-77.3189, 60.010009902],
  [-77.3142701, 60.009579902],
  [-77.31491, 60.011359902],
  [-77.3118399, 60.011420002],
  [-77.3113801, 60.012190002],
  [-77.3098301, 60.011960002],
  [-77.3104901, 60.014000102],
  [-77.30335, 60.014260002],
  [-77.2987101, 60.013569902],
  [-77.29622, 60.014890102],
  [-77.29367, 60.014810002],
  [-77.29277, 60.016610002],
  [-77.29144, 60.015870102],
  [-77.29155, 60.013820002],
  [-77.28845, 60.013359902],
  [-77.2884901, 60.013870102],
  [-77.28444, 60.014449902],
  [-77.2838001, 60.016260002],
  [-77.28521, 60.018280002],
  [-77.2822, 60.019100102],
  [-77.28009, 60.018110102],
  [-77.2791401, 60.019150002],
  [-77.2776199, 60.019440102],
  [-77.2780599, 60.018400002],
  [-77.2733899, 60.017459902],
  [-77.27345, 60.018229902],
  [-77.27243, 60.018249902],
  [-77.2724999, 60.019270002],
  [-77.2689399, 60.019590002],
  [-77.26897, 60.020100102],
  [-77.2638299, 60.019670102],
  [-77.2631599, 60.017380002],
  [-77.26163, 60.017410002],
  [-77.2620499, 60.016119902],
  [-77.2569199, 60.015700002],
  [-77.25731, 60.014150002],
  [-77.2585701, 60.013870102],
  [-77.2582301, 60.012600002],
  [-77.2525601, 60.011930002],
  [-77.25253, 60.011420002],
  [-77.2515, 60.011440102],
  [-77.25139, 60.009650102],
  [-77.2442201, 60.009510002],
  [-77.2442401, 60.010019902],
  [-77.24118, 60.010080002],
  [-77.2412201, 60.010590002],
  [-77.24329, 60.010930002],
  [-77.24441, 60.012320102],
  [-77.2463799, 60.011270002],
  [-77.24594, 60.012300002],
  [-77.24444, 60.012840002],
  [-77.24751, 60.012780102],
  [-77.24729, 60.013300002],
  [-77.24863, 60.014300002],
  [-77.24555, 60.014100102],
  [-77.2459101, 60.015630002],
  [-77.2434899, 60.017720002],
  [-77.2433401, 60.019249902],
  [-77.2386899, 60.018569902],
  [-77.2386601, 60.018050002],
  [-77.2351299, 60.018880102],
  [-77.23517, 60.019390002],
  [-77.2377701, 60.020119902],
  [-77.23808, 60.020880102],
  [-77.23738, 60.021919902],
  [-77.2353699, 60.022210102],
  [-77.2338901, 60.023249902],
  [-77.23386, 60.022740002],
  [-77.2323, 60.022510002],
  [-77.2322201, 60.021249902],
  [-77.23118, 60.020870002],
  [-77.22664, 60.021840002],
  [-77.22696, 60.022860002],
  [-77.23153, 60.026110102],
  [-77.2285501, 60.027440102],
  [-77.22927, 60.030500002],
  [-77.22675, 60.031060002],
  [-77.2261001, 60.032860002],
  [-77.2269001, 60.033359902],
  [-77.22384, 60.033410002],
  [-77.2222201, 60.032170002],
  [-77.21861, 60.031960002],
  [-77.2180299, 60.030950002],
  [-77.2145444, 60.031292102],
  [-77.21462, 60.033310002],
  [-77.2110901, 60.034129902],
  [-77.2108701, 60.034650002],
  [-77.2122201, 60.035650002],
  [-77.2096601, 60.035689902],
  [-77.2095599, 60.034160002],
  [-77.2049099, 60.033479902],
  [-77.20395, 60.034510002],
  [-77.19996, 60.035980002],
  [-77.19791, 60.035890102],
  [-77.19859, 60.038440102],
  [-77.1975501, 60.038200002],
  [-77.1971199, 60.039489902],
  [-77.1955699, 60.039259902],
  [-77.19537, 60.040029902],
  [-77.19695, 60.041960002],
  [-77.1977899, 60.041909902],
  [-77.1972399, 60.041280002],
  [-77.1987601, 60.041000102],
  [-77.1993399, 60.042010102],
  [-77.19683, 60.042819902],
  [-77.19661, 60.042139902],
  [-77.1957501, 60.042070002],
  [-77.1957899, 60.042579902],
  [-77.1947701, 60.042599902],
  [-77.1943101, 60.043379902],
  [-77.19126, 60.043680102],
  [-77.1905401, 60.044460102],
  [-77.1905599, 60.048560102],
  [-77.1920901, 60.048530002],
  [-77.19229, 60.051599902],
  [-77.19127, 60.051620002],
  [-77.1913101, 60.052130102],
  [-77.19284, 60.052100002],
  [-77.1928899, 60.052870002],
  [-77.1986242, 60.052890802],
  [-77.1982291, 60.052008802],
  [-77.19581, 60.050520002],
  [-77.19683, 60.050500002],
  [-77.19728, 60.049599902],
  [-77.20141, 60.050039902],
  [-77.2021099, 60.049130102],
  [-77.2017701, 60.047860002],
  [-77.20481, 60.047310002],
  [-77.20484, 60.047809902],
  [-77.2084201, 60.047750002],
  [-77.20939, 60.046960002],
  [-77.2094201, 60.047469902],
  [-77.21282, 60.048440002],
  [-77.21292, 60.049980002],
  [-77.2139801, 60.050470102],
  [-77.2167429, 60.049221702],
  [-77.21742, 60.048359902],
  [-77.21653, 60.046589902],
  [-77.2190001, 60.045259902],
  [-77.21798, 60.045280002],
  [-77.21839, 60.043740002],
  [-77.2219199, 60.042910102],
  [-77.2213499, 60.042149902],
  [-77.22387, 60.041599902],
  [-77.22331, 60.040840002],
  [-77.22439, 60.041589902],
  [-77.22687, 60.040530002],
  [-77.22841, 60.040630002],
  [-77.22938, 60.039720002],
  [-77.2355601, 60.040380002],
  [-77.2356, 60.040890102],
  [-77.23253, 60.040940002],
  [-77.23207, 60.041720002],
  [-77.2336, 60.041689902],
  [-77.2346999, 60.042699902],
  [-77.2419299, 60.043730002],
  [-77.2511201, 60.043310002],
  [-77.2555757, 60.041989802],
  [-77.2572903, 60.040644502],
  [-77.2574899, 60.039240102],
  [-77.2593877, 60.038421202],
  [-77.2589566, 60.037919902],
  [-77.2606367, 60.037659002],
  [-77.2554416, 60.035893602],
  [-77.2559152, 60.033909502],
  [-77.258554, 60.033120802],
  [-77.2628963, 60.034879402],
  [-77.2635162, 60.034409602],
  [-77.2632814, 60.032992002],
  [-77.26182, 60.033118102],
  [-77.26339, 60.032657902],
  [-77.262953, 60.032156202],
  [-77.2651232, 60.032051202],
  [-77.2692656, 60.033690402],
  [-77.2752609, 60.031959402],
  [-77.2839632, 60.034865602],
  [-77.2853838, 60.034596402],
  [-77.2844149, 60.033413702],
  [-77.285242, 60.031507702],
  [-77.2874231, 60.031528702],
  [-77.2874994, 60.030801702],
  [-77.2845026, 60.031184902],
  [-77.2905126, 60.029821902],
  [-77.294913, 60.030942802],
  [-77.2970304, 60.030754102],
  [-77.2958394, 60.029949902],
  [-77.2969468, 60.029542302],
  [-77.30143, 60.030799902],
  [-77.3013899, 60.030290002],
  [-77.30553, 60.030980002],
  [-77.30499, 60.030479902],
  [-77.3085299, 60.029909902],
  [-77.3111701, 60.031149902],
  [-77.31124, 60.032170002],
  [-77.3147301, 60.030820002],
  [-77.3119401, 60.027540002],
  [-77.3103901, 60.027320102],
  [-77.3102999, 60.025909902],
  [-77.31544, 60.026330102],
  [-77.32202, 60.025310002],
  [-77.3218201, 60.022510002],
  [-77.32437, 60.022330102],
  [-77.3272901, 60.020230102],
  [-77.32832, 60.020340102],
  [-77.32922, 60.018540102],
  [-77.33177, 60.018490002],
  [-77.33249, 60.017710002],
  [-77.33157, 60.015679902],
  [-77.3320799, 60.015670102],
  [-77.3323901, 60.016430102],
  [-77.3347301, 60.016899902],
  [-77.3347599, 60.017410002],
  [-77.33893, 60.018490002],
  [-77.34458, 60.018780102],
  [-77.34461, 60.019290002],
  [-77.34613, 60.019000102],
  [-77.34616, 60.019520002],
  [-77.3599899, 60.019789902],
  [-77.3602901, 60.020540102],
  [-77.36317, 60.021260002],
  [-77.36361, 60.020359902],
  [-77.3703001, 60.020880102],
  [-77.3723101, 60.023909902],
  [-77.3720801, 60.024430102],
  [-77.3751901, 60.024890102],
  [-77.37603, 60.025890102],
  [-77.37559, 60.026670102],
  [-77.3759999, 60.028970002],
  [-77.37348, 60.029530002],
  [-77.37328, 60.030300002],
  [-77.3746699, 60.031940002],
  [-77.3787799, 60.032249902],
  [-77.3784399, 60.034560102],
  [-77.38313, 60.035620002],
  [-77.3842101, 60.036500002],
  [-77.3814399, 60.037060002],
  [-77.3823499, 60.039090002],
  [-77.38338, 60.039080002],
  [-77.3834301, 60.039840002],
  [-77.38496, 60.039809902],
  [-77.3843101, 60.041359902],
  [-77.3866601, 60.041830002],
  [-77.3875301, 60.039770002],
  [-77.38907, 60.040000102],
  [-77.39074, 60.041890102],
  [-77.3917799, 60.042000102],
  [-77.39183, 60.042760002],
  [-77.39438, 60.042589902],
  [-77.3943101, 60.041689902],
  [-77.39482, 60.041680102],
  [-77.39752, 60.043560102],
  [-77.4016899, 60.044630002],
  [-77.40283, 60.046149902],
  [-77.40182, 60.046420002],
  [-77.3999901, 60.044750002],
  [-77.39704, 60.043950002],
  [-77.3987, 60.045709902],
  [-77.3844399, 60.046740002],
  [-77.3844, 60.046230102],
  [-77.38338, 60.046240102],
  [-77.3827799, 60.044980002],
  [-77.38174, 60.044740002],
  [-77.38241, 60.047029902],
  [-77.3804101, 60.047579902],
  [-77.37896, 60.048880002],
  [-77.3774301, 60.048900102],
  [-77.37725, 60.049939902],
  [-77.37862, 60.051190002],
  [-77.38225, 60.051900102],
  [-77.38196, 60.051390002],
  [-77.3827, 60.050990002],
  [-77.3847899, 60.051589902],
  [-77.3827601, 60.051890102],
  [-77.38284, 60.052910102],
  [-77.3870199, 60.054110002],
  [-77.3873499, 60.055130102],
  [-77.39082, 60.057120102],
  [-77.3936501, 60.057320002],
  [-77.39319, 60.054520002],
  [-77.3942299, 60.054500002],
  [-77.39416, 60.053730002],
  [-77.3959901, 60.054210002],
  [-77.3963201, 60.055230102],
  [-77.3973399, 60.055210002],
  [-77.39738, 60.055719902],
  [-77.4004599, 60.055919902],
  [-77.4005401, 60.056939902],
  [-77.4015599, 60.056919902],
  [-77.4016701, 60.058460102],
  [-77.4032299, 60.058690102],
  [-77.4036799, 60.058039902],
  [-77.4044699, 60.058149902],
  [-77.4042499, 60.058670002],
  [-77.4055501, 60.058900102],
  [-77.4050199, 60.059840002],
  [-77.40639, 60.059910102],
  [-77.4063499, 60.059400002],
  [-77.4120001, 60.059690102],
  [-77.41218, 60.058510002],
  [-77.4107701, 60.056760002],
  [-77.40974, 60.056760002],
  [-77.4096701, 60.055740002],
  [-77.4122399, 60.055829902],
  [-77.41619, 60.057430002],
  [-77.41652, 60.058440002],
  [-77.41804, 60.058159902],
  [-77.41764, 60.059699902],
  [-77.4212, 60.059379902],
  [-77.4211499, 60.058870002],
  [-77.42219, 60.058850002],
  [-77.4221299, 60.058070002],
  [-77.4262401, 60.058269902],
  [-77.42561, 60.056740002],
  [-77.42864, 60.056049902],
  [-77.4291, 60.055400002],
  [-77.42651, 60.054929902],
  [-77.42541, 60.053929902],
  [-77.42342, 60.054740002],
  [-77.4217701, 60.053230102],
  [-77.4202399, 60.053249902],
  [-77.4196701, 60.052500002],
  [-77.42272, 60.052190002],
  [-77.4226801, 60.051680102],
  [-77.4170001, 60.051010102],
  [-77.41697, 60.050500002],
  [-77.4123499, 60.050330002],
  [-77.4117999, 60.049819902],
  [-77.4163599, 60.049100002],
  [-77.4175799, 60.048440002],
  [-77.41729, 60.047929902],
  [-77.42194, 60.048489902],
  [-77.42353, 60.049230102],
  [-77.42608, 60.049180002],
  [-77.4265701, 60.048790102],
  [-77.4213401, 60.047350102],
  [-77.42575, 60.044579902],
  [-77.4267801, 60.044689902],
  [-77.42674, 60.044170002],
  [-77.4339001, 60.044039902],
  [-77.4362201, 60.047840002],
  [-77.4397801, 60.047520002],
  [-77.4379101, 60.049860002],
  [-77.43429, 60.049410002],
  [-77.43372, 60.048650002],
  [-77.4275601, 60.048510002],
  [-77.42788, 60.049269902],
  [-77.4380001, 60.051139902],
  [-77.4401201, 60.052120102],
  [-77.4391201, 60.052410002],
  [-77.4391599, 60.052910102],
  [-77.44074, 60.053520002],
  [-77.4438001, 60.053330002],
  [-77.4419199, 60.048770002],
  [-77.44295, 60.048740002],
  [-77.4423401, 60.047469902],
  [-77.4407901, 60.047240102],
  [-77.43966, 60.045730002],
  [-77.43864, 60.045750002],
  [-77.4400101, 60.043670102],
  [-77.43742, 60.043210002],
  [-77.4389299, 60.042929902],
  [-77.43886, 60.041900102],
  [-77.43676, 60.041170002],
  [-77.4371499, 60.039630002],
  [-77.43818, 60.039610002],
  [-77.43821, 60.040120102],
  [-77.43918, 60.039340102],
  [-77.44088, 60.041610002],
  [-77.44808, 60.041990002],
  [-77.4492301, 60.043760002],
  [-77.45162, 60.041410002],
  [-77.4482, 60.040190002],
  [-77.4484501, 60.036610002],
  [-77.4469001, 60.033050002],
  [-77.4479299, 60.033039902],
  [-77.44789, 60.032520002],
  [-77.4463601, 60.032550102],
  [-77.4447099, 60.031039902],
  [-77.44263, 60.030569902],
  [-77.44199, 60.028790102],
  [-77.4440499, 60.029010102],
  [-77.4439199, 60.027340102],
  [-77.4470399, 60.027930002],
  [-77.4524601, 60.032180002],
  [-77.4550101, 60.032000102],
  [-77.45745, 60.030420002],
  [-77.46012, 60.031909902],
  [-77.4614801, 60.031919902],
  [-77.4612401, 60.029710002],
  [-77.46352, 60.029410002],
  [-77.46334, 60.030440102],
  [-77.46922, 60.030330102],
  [-77.4700599, 60.031340102],
  [-77.46996, 60.033390002],
  [-77.47619, 60.034809902],
  [-77.4758399, 60.036860002],
  [-77.4773701, 60.036709902],
  [-77.478, 60.038359902],
  [-77.47954, 60.038330102],
  [-77.4813601, 60.042139902],
  [-77.48034, 60.042160002],
  [-77.48041, 60.043180002],
  [-77.4839599, 60.042730002],
  [-77.48588, 60.044359902],
  [-77.4877101, 60.044819902],
  [-77.4900799, 60.042359902],
  [-77.49623, 60.042489902],
  [-77.49858, 60.046550002],
  [-77.5041701, 60.045929902],
  [-77.50487, 60.045020102],
  [-77.504, 60.043760002],
  [-77.50247, 60.043790102],
  [-77.5010799, 60.042280002],
  [-77.5017201, 60.040599902],
  [-77.50792, 60.041379902],
  [-77.50648, 60.039359902],
  [-77.50711, 60.037540002],
  [-77.503, 60.037120102],
  [-77.5029301, 60.036350102],
  [-77.50191, 60.036369902],
  [-77.50099, 60.034349902],
  [-77.50498, 60.032990102],
  [-77.50492, 60.032220102],
  [-77.50591, 60.031689902],
  [-77.5041799, 60.029160002],
  [-77.5064199, 60.028349902],
  [-77.50646, 60.028860002],
  [-77.51058, 60.029290002],
  [-77.5114901, 60.031070002],
  [-77.5122701, 60.031310002],
  [-77.51221, 60.030540002],
  [-77.5127201, 60.030530002],
  [-77.5133099, 60.031550102],
  [-77.5148301, 60.031390002],
  [-77.5171999, 60.032239902],
  [-77.5173801, 60.034540002],
  [-77.51868, 60.034900102],
  [-77.5199501, 60.034750002],
  [-77.5196399, 60.033990002],
  [-77.5204199, 60.034230102],
  [-77.52245, 60.037249902],
  [-77.5239799, 60.037230102],
  [-77.52199, 60.038039902],
  [-77.5220899, 60.039320002],
  [-77.52311, 60.039290002],
  [-77.52369, 60.040180002],
  [-77.5278, 60.040359902],
  [-77.5304199, 60.041210002],
  [-77.53036, 60.040440102],
  [-77.53448, 60.040740002],
  [-77.53502, 60.041120102],
  [-77.53094, 60.041200002],
  [-77.53522, 60.043670102],
  [-77.5359701, 60.043400002],
  [-77.53534, 60.041880002],
  [-77.5366, 60.041599902],
  [-77.5366399, 60.042110102],
  [-77.5381701, 60.042080002],
  [-77.53803, 60.043620002],
  [-77.54039, 60.044340102],
  [-77.54069, 60.041520002],
  [-77.5422199, 60.041489902],
  [-77.54115, 60.040740002],
  [-77.5445, 60.041180002],
  [-77.5458601, 60.042190002],
  [-77.54845, 60.042650002],
  [-77.54849, 60.043160002],
  [-77.55003, 60.043130102],
  [-77.5499799, 60.042620002],
  [-77.5484299, 60.042400002],
  [-77.5489, 60.041870002],
  [-77.54735, 60.041650002],
  [-77.54879, 60.040469902],
  [-77.5503199, 60.040320002],
  [-77.55038, 60.041080002],
  [-77.55303, 60.042310002],
  [-77.55914, 60.041929902],
  [-77.5595599, 60.040640002],
  [-77.5585301, 60.040660002],
  [-77.55846, 60.039900102],
  [-77.5615401, 60.039840002],
  [-77.56158, 60.040350102],
  [-77.56361, 60.040050002],
  [-77.56407, 60.039400002],
  [-77.56914, 60.038790102],
  [-77.57151, 60.039640002],
  [-77.57169, 60.041950002],
  [-77.5729801, 60.042180002],
  [-77.57302, 60.042689902],
  [-77.5813399, 60.044320002],
  [-77.58119, 60.045599902],
  [-77.58251, 60.046210002],
  [-77.5847999, 60.046039902],
  [-77.58449, 60.045280002],
  [-77.5834599, 60.045300002],
  [-77.5828899, 60.044530002],
  [-77.5854599, 60.044620002],
  [-77.5882299, 60.047249902],
  [-77.58952, 60.047489902],
  [-77.58986, 60.048500002],
  [-77.5908801, 60.048479902],
  [-77.59206, 60.050500002],
  [-77.6006701, 60.049310002],
  [-77.60131, 60.050840002],
  [-77.60028, 60.050860002],
  [-77.6021299, 60.051589902],
  [-77.6020001, 60.053120102],
  [-77.5999901, 60.053680102],
  [-77.5997999, 60.054450102],
  [-77.6021201, 60.054660002],
  [-77.6015501, 60.053910102],
  [-77.6078099, 60.055450102],
  [-77.60916, 60.056320002],
  [-77.6090001, 60.057340102],
  [-77.6146801, 60.058000002],
  [-77.6157801, 60.059000002],
  [-77.61942, 60.059699902],
  [-77.61876, 60.057919902],
  [-77.61672, 60.057960002],
  [-77.6165999, 60.056430002],
  [-77.6191201, 60.055990002],
  [-77.61952, 60.054589902],
  [-77.6215701, 60.054530002],
  [-77.62161, 60.055049902],
  [-77.62263, 60.055029902],
  [-77.6242699, 60.056269902],
  [-77.6222201, 60.056310002],
  [-77.6223, 60.057340102],
  [-77.6249199, 60.058049902],
  [-77.62496, 60.058560002],
  [-77.62752, 60.058510002],
  [-77.6260001, 60.055470102],
  [-77.6272301, 60.054929902],
  [-77.6272699, 60.055450102],
  [-77.6288099, 60.055420002],
  [-77.6285899, 60.055929902],
  [-77.63039, 60.056020102],
  [-77.6356999, 60.058350102],
  [-77.6372301, 60.058320002],
  [-77.6368001, 60.059350102],
  [-77.6352501, 60.059130102],
  [-77.6380201, 60.061760002],
  [-77.6411201, 60.062080002],
  [-77.6403899, 60.062609902],
  [-77.6411301, 60.065410002],
  [-77.64064, 60.065680102],
  [-77.6414601, 60.066430002],
  [-77.63533, 60.066550002],
  [-77.6352501, 60.065530002],
  [-77.63218, 60.065590102],
  [-77.6310499, 60.064330002],
  [-77.62639, 60.063660002],
  [-77.62543, 60.064440002],
  [-77.62907, 60.065140102],
  [-77.62911, 60.065660002],
  [-77.61843, 60.066630002],
  [-77.61839, 60.066120002],
  [-77.61538, 60.066949902],
  [-77.6153401, 60.066440002],
  [-77.60974, 60.066800102],
  [-77.60982, 60.067839902],
  [-77.60882, 60.068230002],
  [-77.60572, 60.067910102],
  [-77.6042399, 60.065379902],
  [-77.6023599, 60.064259902],
  [-77.5946601, 60.064030102],
  [-77.5945501, 60.065819902],
  [-77.59771, 60.067990002],
  [-77.59907, 60.068039902],
  [-77.5975799, 60.068580102],
  [-77.59652, 60.068090002],
  [-77.5964, 60.069880002],
  [-77.59782, 60.071650002],
  [-77.59987, 60.071609902],
  [-77.5996899, 60.072630002],
  [-77.5977701, 60.074210002],
  [-77.60004, 60.076980002],
  [-77.6053599, 60.079440002],
  [-77.60683, 60.081970002],
  [-77.6129001, 60.080949902],
  [-77.6150199, 60.081800102],
  [-77.61299, 60.082110002],
  [-77.6125601, 60.083130002],
  [-77.6141399, 60.083609902],
  [-77.60485, 60.082770002],
  [-77.6048099, 60.082260102],
  [-77.6063201, 60.081980002],
  [-77.60018, 60.082110002],
  [-77.59952, 60.083389902],
  [-77.59985, 60.084410002],
  [-77.6022, 60.084880002],
  [-77.60253, 60.085900002],
  [-77.60407, 60.085860002],
  [-77.6041299, 60.086630002],
  [-77.60515, 60.086609902],
  [-77.60908, 60.090880002],
  [-77.60805, 60.090900002],
  [-77.6083699, 60.091670002],
  [-77.6096701, 60.091900002],
  [-77.6094799, 60.092670002],
  [-77.6117901, 60.092750002],
  [-77.6147701, 60.091540002],
  [-77.6163599, 60.092279902],
  [-77.61651, 60.094069902],
  [-77.6099099, 60.094959902],
  [-77.60954, 60.096760002],
  [-77.6064699, 60.096820102],
  [-77.6074599, 60.099619902],
  [-77.61361, 60.102830102],
  [-77.6095701, 60.103680002],
  [-77.60921, 60.105480102],
  [-77.6061101, 60.105280102],
  [-77.60564, 60.105800002],
  [-77.6074699, 60.106270102],
  [-77.60807, 60.107299902],
  [-77.61041, 60.107760002],
  [-77.6104599, 60.108270102],
  [-77.6134799, 60.107700002],
  [-77.61528, 60.110990002],
  [-77.60752, 60.110120002],
  [-77.6079901, 60.109600102],
  [-77.6043699, 60.109160102],
  [-77.6043301, 60.108639902],
  [-77.59316, 60.110140002],
  [-77.59206, 60.109140002],
  [-77.59052, 60.109170102],
  [-77.59048, 60.108660002],
  [-77.5863599, 60.108480102],
  [-77.5864201, 60.109250002],
  [-77.5848899, 60.109280102],
  [-77.58429, 60.108270102],
  [-77.58327, 60.108289902],
  [-77.5833101, 60.108800002],
  [-77.5817601, 60.108830102],
  [-77.5820901, 60.109590002],
  [-77.5809901, 60.111920002],
  [-77.57997, 60.111940102],
  [-77.5795501, 60.113230002],
  [-77.5728601, 60.112840102],
  [-77.5715, 60.115170102],
  [-77.56996, 60.115199902],
  [-77.5705699, 60.116460002],
  [-77.5675499, 60.117170102],
  [-77.5634399, 60.117120002],
  [-77.56325, 60.117900002],
  [-77.56506, 60.118120002],
  [-77.56514, 60.119140002],
  [-77.56974, 60.118920002],
  [-77.57036, 60.120320002],
  [-77.5632299, 60.120969902],
  [-77.56315, 60.119950102],
  [-77.5626401, 60.119969902],
  [-77.56268, 60.120470002],
  [-77.5601099, 60.120519902],
  [-77.56015, 60.121030002],
  [-77.5529701, 60.121040002],
  [-77.5509801, 60.121840102],
  [-77.54435, 60.122230002],
  [-77.5412199, 60.121650002],
  [-77.5413199, 60.119600102],
  [-77.53992, 60.118089902],
  [-77.5358601, 60.118680002],
  [-77.53582, 60.118170102],
  [-77.5368401, 60.118150002],
  [-77.53625, 60.117140002],
  [-77.52904, 60.116749902],
  [-77.52792, 60.115500102],
  [-77.52179, 60.115880002],
  [-77.5217499, 60.115360002],
  [-77.5207301, 60.115380102],
  [-77.52222, 60.114710102],
  [-77.52782, 60.114220002],
  [-77.5278599, 60.114739902],
  [-77.53147, 60.115050102],
  [-77.5324299, 60.114130002],
  [-77.5339701, 60.114110002],
  [-77.52979, 60.113030002],
  [-77.5257, 60.113500102],
  [-77.5256301, 60.112470002],
  [-77.52411, 60.112500102],
  [-77.5229501, 60.110729902],
  [-77.52138, 60.110509902],
  [-77.5212801, 60.109230002],
  [-77.5161999, 60.109839902],
  [-77.5157499, 60.110610102],
  [-77.5137201, 60.110910002],
  [-77.51378, 60.111680002],
  [-77.5148, 60.111670002],
  [-77.5148401, 60.112170102],
  [-77.5192901, 60.113360002],
  [-77.51913, 60.114650002],
  [-77.51503, 60.114730102],
  [-77.5150701, 60.115240002],
  [-77.50991, 60.114820102],
  [-77.5098699, 60.114309902],
  [-77.5068201, 60.114619902],
  [-77.50677, 60.114110002],
  [-77.5052801, 60.114650002],
  [-77.50648, 60.113610102],
  [-77.50548, 60.110550002],
  [-77.5033999, 60.110079902],
  [-77.50302, 60.111880002],
  [-77.5040401, 60.111859902],
  [-77.5051999, 60.113629902],
  [-77.50014, 60.114490102],
  [-77.50059, 60.117060102],
  [-77.5028201, 60.119309902],
  [-77.5021, 60.120089902],
  [-77.49956, 60.120390102],
  [-77.49911, 60.121170102],
  [-77.50591, 60.122830102],
  [-77.5065299, 60.124360002],
  [-77.505, 60.124390102],
  [-77.5050899, 60.125670002],
  [-77.5030601, 60.125959902],
  [-77.50287, 60.126730102],
  [-77.5048301, 60.129000002],
  [-77.5017, 60.128290102],
  [-77.4999699, 60.125759902],
  [-77.50202, 60.125720102],
  [-77.5021799, 60.124440002],
  [-77.4997201, 60.122430002],
  [-77.49769, 60.122720102],
  [-77.49723, 60.123510102],
  [-77.49057, 60.123639902],
  [-77.4904901, 60.122610102],
  [-77.48634, 60.121920002],
  [-77.4862799, 60.121160102],
  [-77.48489, 60.120759902],
  [-77.48423, 60.121189902],
  [-77.4851799, 60.120150002],
  [-77.48009, 60.120759902],
  [-77.47767, 60.122600102],
  [-77.4776099, 60.121830102],
  [-77.47255, 60.122950102],
  [-77.4724999, 60.122179902],
  [-77.46423, 60.121309902],
  [-77.4639399, 60.120810002],
  [-77.4651401, 60.119760002],
  [-77.4398199, 60.117280102],
  [-77.4352201, 60.117500102],
  [-77.43866, 60.118969902],
  [-77.43844, 60.119490102],
  [-77.4529101, 60.120880002],
  [-77.4565901, 60.122089902],
  [-77.4622799, 60.122759902],
  [-77.46945, 60.122500102],
  [-77.4695801, 60.124280102],
  [-77.4721401, 60.124240002],
  [-77.47167, 60.124759902],
  [-77.47901, 60.126800002],
  [-77.4839301, 60.127340002],
  [-77.4832501, 60.128639902],
  [-77.4842799, 60.128620102],
  [-77.48408, 60.129390102],
  [-77.4853801, 60.129620102],
  [-77.4854901, 60.131160102],
  [-77.4870301, 60.131130002],
  [-77.4877101, 60.133160102],
  [-77.4851699, 60.133720102],
  [-77.48498, 60.134490002],
  [-77.4858201, 60.135500102],
  [-77.48787, 60.135460002],
  [-77.48846, 60.136480002],
  [-77.49101, 60.136170102],
  [-77.4909699, 60.135660002],
  [-77.49199, 60.135639902],
  [-77.49203, 60.136150002],
  [-77.49458, 60.135850102],
  [-77.4946101, 60.136360002],
  [-77.49614, 60.136330002],
  [-77.4947101, 60.137639902],
  [-77.49879, 60.137309902],
  [-77.50045, 60.138810002],
  [-77.5035099, 60.138620102],
  [-77.50512, 60.139490002],
  [-77.4917299, 60.138979902],
  [-77.491, 60.139500102],
  [-77.49187, 60.140759902],
  [-77.4960501, 60.141710002],
  [-77.4961, 60.142480002],
  [-77.4991799, 60.142419902],
  [-77.49922, 60.142930002],
  [-77.5033801, 60.143620102],
  [-77.5034199, 60.144140002],
  [-77.5091, 60.144539902],
  [-77.5101799, 60.145280102],
  [-77.51014, 60.144769902],
  [-77.5152701, 60.144670002],
  [-77.5153099, 60.145190102],
  [-77.51944, 60.145620102],
  [-77.51746, 60.146429902],
  [-77.5206299, 60.147649902],
  [-77.52067, 60.148160002],
  [-77.5268401, 60.148290102],
  [-77.5269, 60.149060102],
  [-77.5258799, 60.149089902],
  [-77.5259699, 60.150360002],
  [-77.527, 60.150340002],
  [-77.5258401, 60.151900002],
  [-77.5281899, 60.152370002],
  [-77.5298501, 60.153999902],
  [-77.5356399, 60.155810002],
  [-77.54134, 60.156470002],
  [-77.5414399, 60.157740102],
  [-77.53938, 60.157779902],
  [-77.5397, 60.158539902],
  [-77.5364101, 60.159120002],
  [-77.5359899, 60.160410102],
  [-77.5375299, 60.160380002],
  [-77.53745, 60.159360002],
  [-77.5383001, 60.159250002],
  [-77.5401901, 60.161600002],
  [-77.54169, 60.161070102],
  [-77.54156, 60.162610002],
  [-77.5424001, 60.163610002],
  [-77.54602, 60.163930002],
  [-77.5467599, 60.163530102],
  [-77.54645, 60.162779902],
  [-77.5482001, 60.165549902],
  [-77.55081, 60.166010002],
  [-77.5451901, 60.166380002],
  [-77.5457599, 60.167130002],
  [-77.5442199, 60.167160002],
  [-77.54435, 60.168700002],
  [-77.5468601, 60.168140002],
  [-77.54746, 60.169150002],
  [-77.55006, 60.169620002],
  [-77.5500999, 60.170119902],
  [-77.5537501, 60.170820002],
  [-77.55371, 60.170310102],
  [-77.55539, 60.172070002],
  [-77.55848, 60.172140002],
  [-77.5601901, 60.174280002],
  [-77.55916, 60.174300102],
  [-77.5597501, 60.175310102],
  [-77.5623299, 60.175390002],
  [-77.5628899, 60.176030002],
  [-77.55979, 60.175830002],
  [-77.5598899, 60.177100102],
  [-77.5588701, 60.177119902],
  [-77.5579999, 60.179190102],
  [-77.5593001, 60.179420102],
  [-77.5596401, 60.180439902],
  [-77.5648701, 60.181620002],
  [-77.5623001, 60.181669902],
  [-77.5621901, 60.183459902],
  [-77.56404, 60.184190102],
  [-77.5640999, 60.184960002],
  [-77.56617, 60.185040002],
  [-77.56692, 60.184650102],
  [-77.56661, 60.184140002],
  [-77.5684499, 60.184620002],
  [-77.5688, 60.185889902],
  [-77.5713499, 60.185590002],
  [-77.57038, 60.186370002],
  [-77.5719801, 60.187109902],
  [-77.5704399, 60.187140002],
  [-77.57003, 60.188430102],
  [-77.5689801, 60.188190002],
  [-77.5678799, 60.190520002],
  [-77.5683399, 60.193070002],
  [-77.57039, 60.193030002],
  [-77.57029, 60.191740002],
  [-77.5731199, 60.195030002],
  [-77.57202, 60.194019902],
  [-77.5709801, 60.194040002],
  [-77.57073, 60.197380002],
  [-77.5725699, 60.197850002],
  [-77.57273, 60.199909902],
  [-77.57403, 60.200129902],
  [-77.57358, 60.200909902],
  [-77.5710099, 60.200960002],
  [-77.57105, 60.201469902],
  [-77.56216, 60.199590002],
  [-77.5625301, 60.197789902],
  [-77.5609701, 60.197579902],
  [-77.5608701, 60.196290002],
  [-77.55058, 60.196229902],
  [-77.55048, 60.194960002],
  [-77.5489601, 60.195250002],
  [-77.54802, 60.196280002],
  [-77.54525, 60.193770102],
  [-77.5422099, 60.194339902],
  [-77.54004, 60.192850002],
  [-77.54061, 60.193600002],
  [-77.5390899, 60.193889902],
  [-77.5394399, 60.191830002],
  [-77.5378799, 60.191610002],
  [-77.5378501, 60.191100102],
  [-77.53159, 60.189940002],
  [-77.53048, 60.188930002],
  [-77.5227401, 60.188569902],
  [-77.5229, 60.190610002],
  [-77.52647, 60.190300002],
  [-77.5264101, 60.189520002],
  [-77.5279501, 60.189490002],
  [-77.5269501, 60.189770102],
  [-77.52914, 60.191520002],
  [-77.53212, 60.190180002],
  [-77.53222, 60.191449902],
  [-77.53324, 60.191440102],
  [-77.5328501, 60.192990102],
  [-77.52922, 60.192540102],
  [-77.52969, 60.195349902],
  [-77.5313099, 60.196339902],
  [-77.53068, 60.198160002],
  [-77.52805, 60.197420102],
  [-77.52894, 60.195620002],
  [-77.52736, 60.195140002],
  [-77.5274001, 60.195650102],
  [-77.52534, 60.195689902],
  [-77.5255201, 60.197990102],
  [-77.52244, 60.198050002],
  [-77.5208, 60.196799902],
  [-77.51821, 60.196590002],
  [-77.51711, 60.195590002],
  [-77.5135101, 60.195660102],
  [-77.51167, 60.198510002],
  [-77.51068, 60.198909902],
  [-77.50757, 60.198600002],
  [-77.50857, 60.198310102],
  [-77.50899, 60.197019902],
  [-77.5063999, 60.196820002],
  [-77.50599, 60.198100102],
  [-77.50292, 60.198420002],
  [-77.5028401, 60.197400002],
  [-77.5038499, 60.197119902],
  [-77.50077, 60.197180002],
  [-77.5008, 60.197689902],
  [-77.49722, 60.197760102],
  [-77.4972601, 60.198270002],
  [-77.49623, 60.198290002],
  [-77.4962999, 60.199060002],
  [-77.49478, 60.199469902],
  [-77.4911699, 60.199410002],
  [-77.4907201, 60.200180002],
  [-77.4871401, 60.200510002],
  [-77.48708, 60.199740002],
  [-77.4839301, 60.198899902],
  [-77.4823999, 60.199060002],
  [-77.48355, 60.200569902],
  [-77.48001, 60.201410002],
  [-77.4816099, 60.202150002],
  [-77.47643, 60.201730002],
  [-77.47598, 60.202510002],
  [-77.47856, 60.202720002],
  [-77.4786001, 60.203229902],
  [-77.47655, 60.203270002],
  [-77.4765901, 60.203780102],
  [-77.4776099, 60.203760102],
  [-77.4773999, 60.204260002],
  [-77.47822, 60.205029902],
  [-77.48134, 60.205480002],
  [-77.48131, 60.204970002],
  [-77.48233, 60.204950002],
  [-77.4822601, 60.203930002],
  [-77.48123, 60.203950002],
  [-77.4821601, 60.202650102],
  [-77.4867101, 60.201670102],
  [-77.4882799, 60.202019902],
  [-77.48832, 60.202530002],
  [-77.48936, 60.202640002],
  [-77.49124, 60.207090002],
  [-77.49021, 60.207110102],
  [-77.4895901, 60.205840002],
  [-77.4880799, 60.206119902],
  [-77.4883999, 60.206880102],
  [-77.4877101, 60.207919902],
  [-77.4887299, 60.207899902],
  [-77.4888499, 60.209440102],
  [-77.494, 60.209600002],
  [-77.4939401, 60.208830002],
  [-77.49701, 60.208510002],
  [-77.4958399, 60.206740002],
  [-77.49788, 60.206459902],
  [-77.4988301, 60.205410002],
  [-77.5018599, 60.204700002],
  [-77.50701, 60.204740002],
  [-77.5060601, 60.205780102],
  [-77.5029599, 60.205590002],
  [-77.50232, 60.207390002],
  [-77.5047101, 60.208370002],
  [-77.50424, 60.208890102],
  [-77.5088599, 60.208799902],
  [-77.5089, 60.209310002],
  [-77.5134901, 60.208840002],
  [-77.5148499, 60.209710002],
  [-77.51601, 60.214809902],
  [-77.5128499, 60.213850002],
  [-77.51344, 60.214860002],
  [-77.5147499, 60.215090002],
  [-77.51425, 60.218689902],
  [-77.51579, 60.218660102],
  [-77.51535, 60.219689902],
  [-77.51847, 60.220010102],
  [-77.5190501, 60.220900102],
  [-77.52222, 60.221860002],
  [-77.5236399, 60.220440102],
  [-77.52569, 60.220259902],
  [-77.5268799, 60.222290002],
  [-77.5299601, 60.222230102],
  [-77.53338, 60.226510002],
  [-77.53549, 60.227240102],
  [-77.52923, 60.226080002],
  [-77.5291899, 60.225570102],
  [-77.5301999, 60.225300002],
  [-77.5276101, 60.225100002],
  [-77.52755, 60.224320002],
  [-77.5265201, 60.224340102],
  [-77.5250701, 60.225520002],
  [-77.5235101, 60.225420002],
  [-77.5239899, 60.224900102],
  [-77.51936, 60.224990002],
  [-77.5209601, 60.225730002],
  [-77.5179, 60.226039902],
  [-77.5190799, 60.228070002],
  [-77.52059, 60.227530002],
  [-77.51956, 60.227550102],
  [-77.52004, 60.227149902],
  [-77.5252099, 60.227440102],
  [-77.52525, 60.227950002],
  [-77.52622, 60.227160002],
  [-77.52725, 60.227139902],
  [-77.5272901, 60.227650002],
  [-77.53034, 60.227210002],
  [-77.5366599, 60.229010102],
  [-77.53512, 60.229039902],
  [-77.5346599, 60.229819902],
  [-77.52893, 60.228900102],
  [-77.5281999, 60.229430002],
  [-77.5285299, 60.230450102],
  [-77.5187301, 60.230120102],
  [-77.51936, 60.231650002],
  [-77.51724, 60.230909902],
  [-77.5172999, 60.231689902],
  [-77.51424, 60.232000102],
  [-77.51315, 60.231249902],
  [-77.51321, 60.232020102],
  [-77.5096101, 60.232090002],
  [-77.50866, 60.233130102],
  [-77.50659, 60.232910102],
  [-77.5106001, 60.234880002],
  [-77.51157, 60.237430002],
  [-77.5151601, 60.237359902],
  [-77.51481, 60.239410002],
  [-77.51378, 60.239430002],
  [-77.5154001, 60.240430002],
  [-77.5138599, 60.240460102],
  [-77.51392, 60.241220002],
  [-77.51235, 60.241000002],
  [-77.5123901, 60.241510002],
  [-77.5169799, 60.240780102],
  [-77.52011, 60.241360102],
  [-77.52001, 60.243410002],
  [-77.5239699, 60.244740002],
  [-77.52537, 60.246130102],
  [-77.5251701, 60.246900102],
  [-77.52769, 60.246210002],
  [-77.53055, 60.246540002],
  [-77.5307301, 60.245510002],
  [-77.53256, 60.245730002],
  [-77.5328799, 60.246750002],
  [-77.53391, 60.246730002],
  [-77.5339501, 60.247240102],
  [-77.53905, 60.246630002],
  [-77.53901, 60.246120102],
  [-77.54168, 60.247350102],
  [-77.54323, 60.247320002],
  [-77.5444101, 60.249350102],
  [-77.5480701, 60.250039902],
  [-77.54814, 60.250810102],
  [-77.54657, 60.250580102],
  [-77.5451099, 60.251640002],
  [-77.5450801, 60.251130102],
  [-77.54194, 60.250670002],
  [-77.5438799, 60.249100002],
  [-77.5407501, 60.248650002],
  [-77.54079, 60.249159902],
  [-77.5377501, 60.249730002],
  [-77.53771, 60.249220002],
  [-77.53516, 60.249520002],
  [-77.53512, 60.249010102],
  [-77.53148, 60.248570102],
  [-77.53055, 60.249870002],
  [-77.5274001, 60.249159902],
  [-77.5285101, 60.250159902],
  [-77.5342901, 60.251599902],
  [-77.53549, 60.253870002],
  [-77.53704, 60.253840002],
  [-77.53656, 60.254350102],
  [-77.5418, 60.255540002],
  [-77.54149, 60.254780002],
  [-77.5427501, 60.254510002],
  [-77.5418799, 60.253230002],
  [-77.54625, 60.253149902],
  [-77.5462901, 60.253660002],
  [-77.54893, 60.254379902],
  [-77.5489701, 60.254890002],
  [-77.54794, 60.254910102],
  [-77.5479999, 60.255680102],
  [-77.54594, 60.255719902],
  [-77.5458799, 60.254949902],
  [-77.5438, 60.254730002],
  [-77.5438401, 60.255250102],
  [-77.54234, 60.255790102],
  [-77.54337, 60.255770002],
  [-77.5439601, 60.256780002],
  [-77.5449899, 60.256760002],
  [-77.5466201, 60.257880002],
  [-77.5492001, 60.257960002],
  [-77.54859, 60.256680102],
  [-77.5512001, 60.257149902],
  [-77.55124, 60.257660002],
  [-77.5533001, 60.257620002],
  [-77.55126, 60.254590102],
  [-77.5524499, 60.253290002],
  [-77.55557, 60.253740002],
  [-77.5547, 60.252479902],
  [-77.5554201, 60.251819902],
  [-77.5579899, 60.251650002],
  [-77.5574101, 60.250890002],
  [-77.5636, 60.251020102],
  [-77.5647699, 60.252790102],
  [-77.56784, 60.252479902],
  [-77.56774, 60.254530002],
  [-77.57006, 60.254479902],
  [-77.5701001, 60.255000002],
  [-77.5713201, 60.254200002],
  [-77.5717899, 60.250350102],
  [-77.57749, 60.250760002],
  [-77.5775301, 60.251269902],
  [-77.57906, 60.251110002],
  [-77.58072, 60.252489902],
  [-77.57917, 60.252520002],
  [-77.5782299, 60.253560002],
  [-77.57714, 60.252810102],
  [-77.57506, 60.252599902],
  [-77.57484, 60.253110002],
  [-77.5766601, 60.253330002],
  [-77.5756601, 60.253609902],
  [-77.57569, 60.254120102],
  [-77.5746501, 60.253880002],
  [-77.57469, 60.254389902],
  [-77.57571, 60.254369902],
  [-77.5755401, 60.255400002],
  [-77.5763499, 60.255900102],
  [-77.5753201, 60.255920102],
  [-77.57538, 60.256680102],
  [-77.57437, 60.256960002],
  [-77.57858, 60.258030102],
  [-77.5816601, 60.257839902],
  [-77.58174, 60.258880002],
  [-77.5833301, 60.259470102],
  [-77.5854, 60.259560002],
  [-77.5845699, 60.258810102],
  [-77.5878701, 60.258230002],
  [-77.5874599, 60.259520002],
  [-77.5858899, 60.259300002],
  [-77.58574, 60.260580102],
  [-77.58764, 60.261819902],
  [-77.58661, 60.261839902],
  [-77.5883499, 60.264370102],
  [-77.5863399, 60.264920102],
  [-77.5863, 60.264399902],
  [-77.5806501, 60.264650002],
  [-77.5785401, 60.264049902],
  [-77.57869, 60.262770002],
  [-77.57814, 60.262260102],
  [-77.58047, 60.262480102],
  [-77.5805501, 60.263499902],
  [-77.58182, 60.263220002],
  [-77.5805699, 60.260430002],
  [-77.5813, 60.259900002],
  [-77.58027, 60.259920102],
  [-77.58017, 60.258640002],
  [-77.57661, 60.259220002],
  [-77.5772299, 60.260489902],
  [-77.57671, 60.260499902],
  [-77.57503, 60.258740002],
  [-77.57293, 60.258269902],
  [-77.57338, 60.257489902],
  [-77.5682001, 60.257080002],
  [-77.5654499, 60.254829902],
  [-77.56335, 60.254350102],
  [-77.5640099, 60.252810102],
  [-77.56214, 60.252080002],
  [-77.5620999, 60.251570102],
  [-77.55903, 60.251890002],
  [-77.5590901, 60.252650002],
  [-77.5601199, 60.252630002],
  [-77.55971, 60.253929902],
  [-77.55761, 60.253440002],
  [-77.55603, 60.256290002],
  [-77.5569701, 60.258450002],
  [-77.56306, 60.257310002],
  [-77.5682299, 60.257340002],
  [-77.57362, 60.260560002],
  [-77.5762101, 60.260640002],
  [-77.5767701, 60.261269902],
  [-77.57369, 60.261330002],
  [-77.57436, 60.263360102],
  [-77.5722399, 60.262630002],
  [-77.5723101, 60.263400002],
  [-77.5717899, 60.263410002],
  [-77.5712101, 60.262650002],
  [-77.5661001, 60.263140102],
  [-77.5588501, 60.262770002],
  [-77.55569, 60.261800102],
  [-77.5505101, 60.261520002],
  [-77.55047, 60.261010002],
  [-77.5427699, 60.261410002],
  [-77.5427301, 60.260900002],
  [-77.53912, 60.260839902],
  [-77.5356, 60.262059902],
  [-77.53678, 60.263829902],
  [-77.5357699, 60.264100002],
  [-77.5346399, 60.262850002],
  [-77.5299699, 60.262420002],
  [-77.53104, 60.262920102],
  [-77.52848, 60.262960002],
  [-77.5306, 60.263949902],
  [-77.52905, 60.263980002],
  [-77.52382, 60.262810102],
  [-77.52492, 60.263800102],
  [-77.52959, 60.264220002],
  [-77.5296301, 60.264729902],
  [-77.53802, 60.266489902],
  [-77.53872, 60.265580102],
  [-77.5409799, 60.264900002],
  [-77.5456401, 60.265200002],
  [-77.5457599, 60.266729902],
  [-77.5472901, 60.266450002],
  [-77.54739, 60.267719902],
  [-77.54636, 60.267740002],
  [-77.54695, 60.268760002],
  [-77.54957, 60.269220002],
  [-77.54961, 60.269729902],
  [-77.55269, 60.269670002],
  [-77.55273, 60.270179902],
  [-77.5571901, 60.271120002],
  [-77.5581199, 60.273150102],
  [-77.5575599, 60.272650002],
  [-77.55604, 60.272930102],
  [-77.5560801, 60.273440002],
  [-77.5545599, 60.273729902],
  [-77.5546, 60.274240002],
  [-77.55357, 60.274260102],
  [-77.55414, 60.275020002],
  [-77.55105, 60.275080002],
  [-77.5510901, 60.275590102],
  [-77.54701, 60.276179902],
  [-77.54581, 60.273900002],
  [-77.54371, 60.273440002],
  [-77.5436499, 60.272660002],
  [-77.5389799, 60.272240002],
  [-77.5393299, 60.273509902],
  [-77.54494, 60.275959902],
  [-77.5439, 60.275980002],
  [-77.5439701, 60.276750002],
  [-77.54145, 60.277570002],
  [-77.54258, 60.278829902],
  [-77.5415499, 60.278839902],
  [-77.5427, 60.280360102],
  [-77.54167, 60.280380102],
  [-77.5420701, 60.282160102],
  [-77.5459899, 60.282860002],
  [-77.54603, 60.283370102],
  [-77.54756, 60.283210002],
  [-77.54868, 60.284340002],
  [-77.55025, 60.284690002],
  [-77.5533299, 60.284509902],
  [-77.55338, 60.285020002],
  [-77.5523399, 60.285040102],
  [-77.5524001, 60.285800002],
  [-77.5539, 60.285140102],
  [-77.5552199, 60.285490102],
  [-77.55403, 60.286800002],
  [-77.5571199, 60.286739902],
  [-77.55724, 60.288270102],
  [-77.56027, 60.287450002],
  [-77.55979, 60.287970002],
  [-77.5690099, 60.287150102],
  [-77.5722001, 60.288370102],
  [-77.5752399, 60.287670002],
  [-77.57213, 60.287470002],
  [-77.5720901, 60.286959902],
  [-77.57106, 60.286970002],
  [-77.56724, 60.284240002],
  [-77.56827, 60.284220002],
  [-77.5682299, 60.283710102],
  [-77.5704599, 60.282640002],
  [-77.57061, 60.281230002],
  [-77.5716501, 60.281340002],
  [-77.5697501, 60.280090002],
  [-77.5698801, 60.278550002],
  [-77.57195, 60.278640002],
  [-77.57226, 60.275949902],
  [-77.5691901, 60.276260102],
  [-77.56974, 60.276760002],
  [-77.56871, 60.276790002],
  [-77.5675401, 60.275020002],
  [-77.5712001, 60.275580102],
  [-77.57162, 60.274420002],
  [-77.5726501, 60.274399902],
  [-77.57271, 60.275169902],
  [-77.5742199, 60.274630002],
  [-77.57341, 60.275240002],
  [-77.5749801, 60.277820102],
  [-77.5765401, 60.277910002],
  [-77.57739, 60.278920102],
  [-77.5792299, 60.279410002],
  [-77.57915, 60.278370102],
  [-77.58515, 60.279279902],
  [-77.5854699, 60.280040102],
  [-77.58392, 60.280069902],
  [-77.58505, 60.281330002],
  [-77.5863, 60.280800002],
  [-77.5864, 60.278750002],
  [-77.58741, 60.278470102],
  [-77.58585, 60.278230002],
  [-77.5857799, 60.277480102],
  [-77.5826699, 60.277279902],
  [-77.58259, 60.276260102],
  [-77.5882399, 60.276020002],
  [-77.58871, 60.275370102],
  [-77.59241, 60.276580102],
  [-77.59231, 60.275300002],
  [-77.59284, 60.275289902],
  [-77.59352, 60.277580102],
  [-77.59507, 60.277550002],
  [-77.59396, 60.276550002],
  [-77.5965401, 60.276630002],
  [-77.59673, 60.275729902],
  [-77.59894, 60.274399902],
  [-77.5996999, 60.277460002],
  [-77.60073, 60.277440002],
  [-77.6007701, 60.277939902],
  [-77.5992301, 60.277980002],
  [-77.5975601, 60.276480102],
  [-77.5976601, 60.277760002],
  [-77.5961399, 60.278040102],
  [-77.59659, 60.277270102],
  [-77.5955601, 60.277289902],
  [-77.59572, 60.279330002],
  [-77.5946899, 60.279350002],
  [-77.59459, 60.278069902],
  [-77.5935699, 60.278100002],
  [-77.59364, 60.279120002],
  [-77.5916501, 60.279920102],
  [-77.5910901, 60.282499902],
  [-77.5924301, 60.283360102],
  [-77.5934499, 60.283220002],
  [-77.5930001, 60.283990002],
  [-77.5955699, 60.283940102],
  [-77.5952399, 60.282930102],
  [-77.59751, 60.282370102],
  [-77.59887, 60.286440002],
  [-77.60042, 60.286530002],
  [-77.6017999, 60.287660002],
  [-77.6012301, 60.290230002],
  [-77.59931, 60.289849902],
  [-77.5985, 60.288370102],
  [-77.5964501, 60.288530002],
  [-77.5970099, 60.289040102],
  [-77.5985699, 60.289260102],
  [-77.59919, 60.290530002],
  [-77.6033499, 60.290959902],
  [-77.60339, 60.291470002],
  [-77.60607, 60.292700102],
  [-77.60753, 60.291770002],
  [-77.60938, 60.292120002],
  [-77.60916, 60.292640002],
  [-77.61019, 60.292619902],
  [-77.61243, 60.294880002],
  [-77.6108801, 60.294910002],
  [-77.6121, 60.297189902],
  [-77.59585, 60.293920002],
  [-77.5967, 60.294930102],
  [-77.60071, 60.296639902],
  [-77.6007701, 60.297409902],
  [-77.6022499, 60.296610102],
  [-77.6023499, 60.297890002],
  [-77.6008099, 60.297920002],
  [-77.6003301, 60.298440002],
  [-77.6013599, 60.298419902],
  [-77.60194, 60.299179902],
  [-77.60041, 60.299200002],
  [-77.59996, 60.300240002],
  [-77.59582, 60.300069902],
  [-77.59586, 60.300580002],
  [-77.59228, 60.300910002],
  [-77.59239, 60.302440002],
  [-77.5908701, 60.302729902],
  [-77.5912101, 60.303739902],
  [-77.5919901, 60.303859902],
  [-77.5934401, 60.302550002],
  [-77.59448, 60.302660002],
  [-77.5948901, 60.301370102],
  [-77.59642, 60.301079902],
  [-77.5964599, 60.301590002],
  [-77.59882, 60.302060102],
  [-77.5968801, 60.303629902],
  [-77.5977899, 60.305409902],
  [-77.59575, 60.305700002],
  [-77.5953, 60.306480002],
  [-77.59318, 60.305749902],
  [-77.59375, 60.306509902],
  [-77.5907, 60.307079902],
  [-77.5905599, 60.305289902],
  [-77.58903, 60.305580002],
  [-77.5889901, 60.305069902],
  [-77.58695, 60.305360002],
  [-77.5871101, 60.307409902],
  [-77.58404, 60.307720102],
  [-77.58396, 60.306700002],
  [-77.5814, 60.306880002],
  [-77.57941, 60.307810002],
  [-77.5793301, 60.306790002],
  [-77.57316, 60.307170102],
  [-77.5731199, 60.306650002],
  [-77.57048, 60.305810002],
  [-77.56894, 60.305969902],
  [-77.5688999, 60.305460002],
  [-77.5646799, 60.304260002],
  [-77.56472, 60.304770002],
  [-77.5585599, 60.305140002],
  [-77.5586, 60.305660002],
  [-77.5596401, 60.305639902],
  [-77.55969, 60.306399902],
  [-77.5607401, 60.306639902],
  [-77.55971, 60.306660002],
  [-77.55981, 60.307950102],
  [-77.5572299, 60.307990002],
  [-77.5567799, 60.308760002],
  [-77.5557501, 60.308780002],
  [-77.55579, 60.309289902],
  [-77.55838, 60.309500102],
  [-77.5585, 60.311030002],
  [-77.56005, 60.311010002],
  [-77.5601099, 60.311770002],
  [-77.56114, 60.311749902],
  [-77.5628, 60.313260002],
  [-77.56651, 60.314460002],
  [-77.56826, 60.316990002],
  [-77.56929, 60.316979902],
  [-77.56883, 60.317749902],
  [-77.5708999, 60.317710002],
  [-77.5713101, 60.316419902],
  [-77.56925, 60.316460002],
  [-77.5733299, 60.315869902],
  [-77.5734599, 60.314330002],
  [-77.5742199, 60.314050102],
  [-77.57482, 60.315070102],
  [-77.57739, 60.315150002],
  [-77.57905, 60.316400102],
  [-77.5798899, 60.316120002],
  [-77.5789801, 60.315500102],
  [-77.5800099, 60.315480002],
  [-77.58119, 60.317250002],
  [-77.58428, 60.317189902],
  [-77.5842201, 60.316419902],
  [-77.58473, 60.316409902],
  [-77.5863499, 60.317280102],
  [-77.5873101, 60.316360002],
  [-77.5883399, 60.316340002],
  [-77.5898801, 60.312990002],
  [-77.58881, 60.312490102],
  [-77.5884499, 60.311220002],
  [-77.58641, 60.311519902],
  [-77.58637, 60.311010002],
  [-77.58815, 60.310720102],
  [-77.58783, 60.309950102],
  [-77.5899099, 60.310170102],
  [-77.5896899, 60.307370002],
  [-77.5912399, 60.307330002],
  [-77.5913399, 60.308610102],
  [-77.5928801, 60.308570002],
  [-77.59326, 60.306770002],
  [-77.5963499, 60.306720102],
  [-77.59863, 60.309490102],
  [-77.5971101, 60.309770002],
  [-77.59715, 60.310280102],
  [-77.5986701, 60.310000002],
  [-77.59871, 60.310510102],
  [-77.60109, 60.311230002],
  [-77.6014301, 60.312250002],
  [-77.59275, 60.313440002],
  [-77.59408, 60.313930002],
  [-77.59627, 60.318749902],
  [-77.5998999, 60.318940102],
  [-77.59998, 60.316629902],
  [-77.60118, 60.315580002],
  [-77.60429, 60.315780002],
  [-77.6049099, 60.317040002],
  [-77.60287, 60.317340002],
  [-77.60119, 60.318910002],
  [-77.6035501, 60.319380002],
  [-77.6041201, 60.320130002],
  [-77.59951, 60.320480002],
  [-77.59841, 60.319730102],
  [-77.59751, 60.321290102],
  [-77.59648, 60.321309902],
  [-77.5953, 60.322869902],
  [-77.5962399, 60.324900002],
  [-77.60193, 60.325040002],
  [-77.60164, 60.324529902],
  [-77.60651, 60.324180102],
  [-77.60643, 60.323160002],
  [-77.61008, 60.323600002],
  [-77.6093699, 60.324380002],
  [-77.6099901, 60.325649902],
  [-77.6155, 60.326820002],
  [-77.6156601, 60.328869902],
  [-77.61461, 60.328630102],
  [-77.6144401, 60.329659902],
  [-77.61575, 60.329890002],
  [-77.6158099, 60.330649902],
  [-77.6147801, 60.330680002],
  [-77.6143401, 60.331710002],
  [-77.6169099, 60.331539902],
  [-77.6178199, 60.329979902],
  [-77.61861, 60.330089902],
  [-77.61896, 60.331360002],
  [-77.6204899, 60.331079902],
  [-77.6205899, 60.332350002],
  [-77.6232201, 60.332940002],
  [-77.6262401, 60.331989902],
  [-77.62628, 60.332500102],
  [-77.62951, 60.330900002],
  [-77.62911, 60.329110002],
  [-77.6307199, 60.329850102],
  [-77.6322501, 60.329690002],
  [-77.6327199, 60.329040002],
  [-77.63119, 60.329330002],
  [-77.63055, 60.327800002],
  [-77.62952, 60.327830102],
  [-77.6291, 60.325790002],
  [-77.6303799, 60.325630102],
  [-77.6313, 60.324209902],
  [-77.63209, 60.324450002],
  [-77.6341599, 60.327730102],
  [-77.63519, 60.327710002],
  [-77.63478, 60.329000002],
  [-77.6358001, 60.328850102],
  [-77.63639, 60.329740102],
  [-77.6343401, 60.329790002],
  [-77.63544, 60.330780002],
  [-77.6339001, 60.330810002],
  [-77.6344701, 60.331570002],
  [-77.6354899, 60.331549902],
  [-77.6355601, 60.332309902],
  [-77.6386099, 60.331610102],
  [-77.64122, 60.331950102],
  [-77.6401, 60.330940002],
  [-77.6423601, 60.330130002],
  [-77.64142, 60.328099902],
  [-77.64245, 60.328079902],
  [-77.6413, 60.326570002],
  [-77.6433501, 60.326529902],
  [-77.64453, 60.328300102],
  [-77.6491599, 60.328070102],
  [-77.65289, 60.329410102],
  [-77.6517199, 60.330969902],
  [-77.65344, 60.332979902],
  [-77.6527199, 60.333769902],
  [-77.65376, 60.333740102],
  [-77.6543501, 60.334759902],
  [-77.6528101, 60.334790002],
  [-77.6533899, 60.335539902],
  [-77.6554701, 60.335759902],
  [-77.6529499, 60.336580002],
  [-77.65356, 60.337590002],
  [-77.65145, 60.337120002],
  [-77.65141, 60.336610002],
  [-77.6493601, 60.336900002],
  [-77.64908, 60.339730102],
  [-77.64755, 60.340010002],
  [-77.64751, 60.339500002],
  [-77.6449299, 60.339549902],
  [-77.64497, 60.340060102],
  [-77.64078, 60.339380002],
  [-77.6400499, 60.339910002],
  [-77.6404701, 60.341950102],
  [-77.6379201, 60.342250002],
  [-77.6374601, 60.343040002],
  [-77.6426801, 60.343690002],
  [-77.6430201, 60.344710002],
  [-77.64231, 60.345490002],
  [-77.6388001, 60.346840002],
  [-77.6404899, 60.351680002],
  [-77.64244, 60.353429902],
  [-77.64397, 60.353270002],
  [-77.64596, 60.355420102],
  [-77.64567, 60.358229902],
  [-77.6447001, 60.359020002],
  [-77.6460399, 60.359640102],
  [-77.64809, 60.359460002],
  [-77.6481401, 60.359970102],
  [-77.6543701, 60.360370002],
  [-77.6532, 60.361920002],
  [-77.65412, 60.363690002],
  [-77.65309, 60.363720002],
  [-77.65352, 60.365740002],
  [-77.65487, 60.366370002],
  [-77.66165, 60.367260002],
  [-77.66257, 60.365830002],
  [-77.66934, 60.366459902],
  [-77.66945, 60.367740002],
  [-77.6740301, 60.366879902],
  [-77.67433, 60.367390002],
  [-77.67669, 60.367850002],
  [-77.6766299, 60.367080102],
  [-77.6798101, 60.368040002],
  [-77.6797001, 60.366760102],
  [-77.68223, 60.366200102],
  [-77.6812799, 60.363920002],
  [-77.68198, 60.363009902],
  [-77.68376, 60.362590002],
  [-77.6836299, 60.361050002],
  [-77.6843701, 60.360659902],
  [-77.68957, 60.361060002],
  [-77.69067, 60.361810002],
  [-77.69376, 60.361620002],
  [-77.6938, 60.362130002],
  [-77.6969501, 60.362580002],
  [-77.6969799, 60.363090102],
  [-77.6996299, 60.363930002],
  [-77.7017, 60.363889902],
  [-77.7021601, 60.363240002],
  [-77.70425, 60.363449902],
  [-77.70381, 60.364480002],
  [-77.7068699, 60.363910002],
  [-77.7047599, 60.363439902],
  [-77.70468, 60.362420102],
  [-77.7092701, 60.361680002],
  [-77.71147, 60.363300102],
  [-77.7078401, 60.363119902],
  [-77.7074199, 60.364410102],
  [-77.71167, 60.365730002],
  [-77.7137401, 60.365690002],
  [-77.7141999, 60.365040002],
  [-77.7131701, 60.365060002],
  [-77.71312, 60.364549902],
  [-77.7173001, 60.364980102],
  [-77.71734, 60.365490002],
  [-77.72311, 60.366520102],
  [-77.7247599, 60.367650102],
  [-77.72579, 60.367620002],
  [-77.72583, 60.368130002],
  [-77.72738, 60.368100102],
  [-77.7279799, 60.369109902],
  [-77.7295499, 60.369339902],
  [-77.7269501, 60.369130002],
  [-77.7267301, 60.369650102],
  [-77.72814, 60.371030002],
  [-77.72971, 60.371130002],
  [-77.7292901, 60.372410002],
  [-77.72826, 60.372430102],
  [-77.72938, 60.373439902],
  [-77.72469, 60.373020002],
  [-77.72615, 60.371970102],
  [-77.7215399, 60.372569902],
  [-77.72159, 60.373080102],
  [-77.72471, 60.373280002],
  [-77.7243001, 60.374559902],
  [-77.72223, 60.374610002],
  [-77.7228401, 60.375620002],
  [-77.7197301, 60.375550102],
  [-77.7187401, 60.376080002],
  [-77.7088599, 60.375390002],
  [-77.7086399, 60.375909902],
  [-77.7116101, 60.377380002],
  [-77.71057, 60.377410002],
  [-77.7111801, 60.378410002],
  [-77.71014, 60.378440102],
  [-77.71225, 60.378909902],
  [-77.7122901, 60.379420102],
  [-77.71515, 60.379620002],
  [-77.7153099, 60.378329902],
  [-77.7160999, 60.378569902],
  [-77.71593, 60.379600002],
  [-77.7210899, 60.379490002],
  [-77.72105, 60.378980102],
  [-77.7230899, 60.378690002],
  [-77.72305, 60.378170002],
  [-77.7250899, 60.377880102],
  [-77.72514, 60.378390002],
  [-77.7334299, 60.378600002],
  [-77.73493, 60.378060002],
  [-77.74016, 60.378720002],
  [-77.74524, 60.377590002],
  [-77.74869, 60.378669902],
  [-77.75095, 60.380930002],
  [-77.7507799, 60.381960002],
  [-77.74826, 60.382779902],
  [-77.7494, 60.384030002],
  [-77.75091, 60.383490002],
  [-77.75151, 60.384500002],
  [-77.7525401, 60.384480002],
  [-77.7531199, 60.385239902],
  [-77.74904, 60.385830002],
  [-77.7491199, 60.386870102],
  [-77.75168, 60.386550102],
  [-77.75173, 60.387060002],
  [-77.75381, 60.387270002],
  [-77.75372, 60.386250002],
  [-77.75784, 60.385909902],
  [-77.7577699, 60.385140002],
  [-77.7588701, 60.385889902],
  [-77.7641299, 60.386930002],
  [-77.7663201, 60.388290002],
  [-77.7673499, 60.388270002],
  [-77.76739, 60.388780102],
  [-77.7712101, 60.387930002],
  [-77.7703, 60.386420102],
  [-77.77386, 60.385689902],
  [-77.7751001, 60.388110102],
  [-77.77406, 60.388129902],
  [-77.7741299, 60.395040002],
  [-77.77052, 60.395119902],
  [-77.7697899, 60.395650102],
  [-77.7700901, 60.396150002],
  [-77.76804, 60.396450102],
  [-77.7678601, 60.397220102],
  [-77.76917, 60.397450102],
  [-77.7716799, 60.399699902],
  [-77.7715, 60.400469902],
  [-77.77252, 60.400450102],
  [-77.77328, 60.403249902],
  [-77.77225, 60.403280002],
  [-77.7725501, 60.403780102],
  [-77.77182, 60.404310002],
  [-77.7744401, 60.404780102],
  [-77.7632101, 60.406540002],
  [-77.7628899, 60.408840002],
  [-77.75982, 60.409280002],
  [-77.75507, 60.408239902],
  [-77.75569, 60.409510002],
  [-77.75416, 60.409799902],
  [-77.75473, 60.410300002],
  [-77.7496, 60.410909902],
  [-77.7502001, 60.411929902],
  [-77.7449999, 60.411520002],
  [-77.7440701, 60.412819902],
  [-77.74303, 60.412840002],
  [-77.74314, 60.414120102],
  [-77.7421099, 60.414139902],
  [-77.74217, 60.414909902],
  [-77.73802, 60.414740002],
  [-77.7397799, 60.417259902],
  [-77.74137, 60.417740002],
  [-77.73982, 60.417770002],
  [-77.74013, 60.418280002],
  [-77.7392001, 60.419579902],
  [-77.74059, 60.420699902],
  [-77.7426499, 60.420530002],
  [-77.74327, 60.421799902],
  [-77.7416699, 60.421190002],
  [-77.7380801, 60.421650002],
  [-77.7386401, 60.422149902],
  [-77.7370899, 60.422180002],
  [-77.73868, 60.422660002],
  [-77.7387401, 60.423170002],
  [-77.7397401, 60.422900102],
  [-77.7397799, 60.423420002],
  [-77.7387501, 60.423430002],
  [-77.73883, 60.424450102],
  [-77.73315, 60.424570102],
  [-77.73459, 60.426330002],
  [-77.73412, 60.426850002],
  [-77.7380999, 60.428049902],
  [-77.7374299, 60.429340102],
  [-77.73935, 60.430580102],
  [-77.73112, 60.431280002],
  [-77.73103, 60.430240102],
  [-77.72116, 60.429929902],
  [-77.7221801, 60.429660002],
  [-77.72214, 60.429149902],
  [-77.7195299, 60.428950002],
  [-77.7207301, 60.430970002],
  [-77.71413, 60.432379902],
  [-77.71401, 60.431100002],
  [-77.71138, 60.430650002],
  [-77.71311, 60.432660002],
  [-77.7105399, 60.432970002],
  [-77.71033, 60.430410002],
  [-77.71134, 60.430130102],
  [-77.70979, 60.430170002],
  [-77.7097499, 60.429650002],
  [-77.71278, 60.428819902],
  [-77.70457, 60.429760002],
  [-77.70436, 60.430280002],
  [-77.7078699, 60.431870002],
  [-77.7094001, 60.431699902],
  [-77.7084199, 60.432369902],
  [-77.69915, 60.433070002],
  [-77.69757, 60.432719902],
  [-77.69692, 60.434269902],
  [-77.69876, 60.434609902],
  [-77.69989, 60.435740002],
  [-77.69834, 60.435770002],
  [-77.6976299, 60.436560002],
  [-77.7010501, 60.437259902],
  [-77.69899, 60.437300002],
  [-77.69826, 60.437819902],
  [-77.69857, 60.438589902],
  [-77.70224, 60.439020102],
  [-77.7007201, 60.439580102],
  [-77.69934, 60.441640002],
  [-77.7019699, 60.442100002],
  [-77.70202, 60.442609902],
  [-77.70567, 60.442929902],
  [-77.70812, 60.441210002],
  [-77.70844, 60.441970002],
  [-77.7097499, 60.442330002],
  [-77.71544, 60.442080002],
  [-77.71549, 60.442599902],
  [-77.71703, 60.442560002],
  [-77.7170999, 60.443330002],
  [-77.7145201, 60.443379902],
  [-77.71357, 60.444430002],
  [-77.7182099, 60.444200002],
  [-77.7187599, 60.444580102],
  [-77.7167499, 60.445130102],
  [-77.71934, 60.445330002],
  [-77.71844, 60.446890002],
  [-77.71589, 60.447450002],
  [-77.7162801, 60.445910102],
  [-77.7147301, 60.445939902],
  [-77.7153099, 60.446700102],
  [-77.7107201, 60.447560002],
  [-77.7090601, 60.449379902],
  [-77.70914, 60.450410002],
  [-77.71049, 60.451020102],
  [-77.7130701, 60.450839902],
  [-77.7134299, 60.452110002],
  [-77.7163901, 60.453460102],
  [-77.71716, 60.453310002],
  [-77.7162801, 60.452049902],
  [-77.7204101, 60.451839902],
  [-77.72201, 60.452450002],
  [-77.7238, 60.455230002],
  [-77.7206101, 60.454269902],
  [-77.7206499, 60.454780002],
  [-77.71757, 60.455100002],
  [-77.7176301, 60.455870002],
  [-77.7166201, 60.456140102],
  [-77.7170701, 60.455360102],
  [-77.71401, 60.455939902],
  [-77.7131701, 60.455190002],
  [-77.7133901, 60.454670002],
  [-77.71181, 60.454450002],
  [-77.71135, 60.455230002],
  [-77.7093099, 60.455520002],
  [-77.70925, 60.454760002],
  [-77.7102801, 60.454740002],
  [-77.70968, 60.453719902],
  [-77.7086399, 60.453740002],
  [-77.7069601, 60.452250102],
  [-77.70433, 60.451780002],
  [-77.70425, 60.450760002],
  [-77.6995101, 60.449829902],
  [-77.70358, 60.448980002],
  [-77.7050701, 60.448180002],
  [-77.70503, 60.447670002],
  [-77.7086499, 60.447599902],
  [-77.7086, 60.446829902],
  [-77.70182, 60.446460102],
  [-77.70059, 60.447250102],
  [-77.7009501, 60.448520002],
  [-77.69992, 60.448540002],
  [-77.6985199, 60.447279902],
  [-77.69977, 60.446760002],
  [-77.69567, 60.447350102],
  [-77.69668, 60.450149902],
  [-77.69834, 60.451389902],
  [-77.6981701, 60.452420002],
  [-77.69713, 60.452440002],
  [-77.6968499, 60.455260102],
  [-77.69788, 60.455240102],
  [-77.69903, 60.456630002],
  [-77.7006001, 60.456719902],
  [-77.6980699, 60.457540002],
  [-77.69899, 60.458200002],
  [-77.7001601, 60.457760002],
  [-77.69936, 60.458410002],
  [-77.69977, 60.459300002],
  [-77.69868, 60.458560002],
  [-77.69826, 60.459839902],
  [-77.69724, 60.459860002],
  [-77.69736, 60.461399902],
  [-77.6952899, 60.461440002],
  [-77.6957, 60.460150102],
  [-77.69358, 60.459680002],
  [-77.6927101, 60.461750002],
  [-77.6901701, 60.462310002],
  [-77.69013, 60.461800102],
  [-77.69098, 60.461700102],
  [-77.6908, 60.460509902],
  [-77.692, 60.459330002],
  [-77.6966299, 60.458850002],
  [-77.6903099, 60.457700102],
  [-77.6886299, 60.459269902],
  [-77.68854, 60.461320002],
  [-77.68503, 60.462800102],
  [-77.6778101, 60.463330002],
  [-77.67775, 60.462560002],
  [-77.6761799, 60.462340002],
  [-77.6752701, 60.463880002],
  [-77.67421, 60.463660002],
  [-77.67543, 60.465939902],
  [-77.6743999, 60.465959902],
  [-77.67457, 60.468000002],
  [-77.67353, 60.468020002],
  [-77.67347, 60.467260102],
  [-77.66987, 60.467580102],
  [-77.6698, 60.466820102],
  [-77.66419, 60.467949902],
  [-77.6641501, 60.467440002],
  [-77.66096, 60.466480102],
  [-77.6633, 60.469760002],
  [-77.6622699, 60.469780002],
  [-77.66346, 60.471680002],
  [-77.67031, 60.472949902],
  [-77.66932, 60.473609902],
  [-77.6667101, 60.473409902],
  [-77.6631699, 60.474380102],
  [-77.6634901, 60.475140102],
  [-77.66154, 60.476710102],
  [-77.663, 60.478729902],
  [-77.66823, 60.479270102],
  [-77.6691601, 60.477969902],
  [-77.6707199, 60.477940102],
  [-77.67133, 60.479079902],
  [-77.66978, 60.479110002],
  [-77.67035, 60.479739902],
  [-77.67809, 60.479330002],
  [-77.67866, 60.479949902],
  [-77.6724901, 60.480590102],
  [-77.6713, 60.481890002],
  [-77.6716199, 60.482660002],
  [-77.6700699, 60.482690002],
  [-77.67098, 60.481130002],
  [-77.66941, 60.480910002],
  [-77.6694701, 60.481670002],
  [-77.66843, 60.481700102],
  [-77.6683899, 60.481179902],
  [-77.66634, 60.481480102],
  [-77.6679599, 60.482220002],
  [-77.6612899, 60.483120002],
  [-77.6609101, 60.484920002],
  [-77.6583701, 60.485480102],
  [-77.6584701, 60.486760002],
  [-77.6579499, 60.486770002],
  [-77.65789, 60.486000002],
  [-77.6527099, 60.486110002],
  [-77.65279, 60.487130002],
  [-77.6487099, 60.487980002],
  [-77.64867, 60.487470002],
  [-77.6440499, 60.488069902],
  [-77.64399, 60.487299902],
  [-77.64243, 60.487340002],
  [-77.6423899, 60.486820102],
  [-77.6398001, 60.486870002],
  [-77.63966, 60.485079902],
  [-77.6391301, 60.485090002],
  [-77.6392, 60.485859902],
  [-77.6381599, 60.485870002],
  [-77.6382699, 60.487160102],
  [-77.63663, 60.486160102],
  [-77.63143, 60.486030002],
  [-77.6311201, 60.488580002],
  [-77.62954, 60.488360002],
  [-77.62866, 60.490170102],
  [-77.62762, 60.490189902],
  [-77.6280101, 60.488650002],
  [-77.62697, 60.488660002],
  [-77.62639, 60.487920002],
  [-77.6222301, 60.487729902],
  [-77.62229, 60.488490102],
  [-77.6212499, 60.488519902],
  [-77.6208199, 60.489550002],
  [-77.62241, 60.490030002],
  [-77.6203799, 60.490590002],
  [-77.62042, 60.491100002],
  [-77.6177901, 60.490639902],
  [-77.6168199, 60.491419902],
  [-77.6181301, 60.491650002],
  [-77.61798, 60.492940102],
  [-77.62061, 60.493399902],
  [-77.62229, 60.494910002],
  [-77.61974, 60.495460002],
  [-77.61952, 60.495979902],
  [-77.6255601, 60.496880002],
  [-77.6255999, 60.497399902],
  [-77.62884, 60.498869902],
  [-77.6210201, 60.498510102],
  [-77.62098, 60.498000002],
  [-77.61943, 60.498030002],
  [-77.6182, 60.495749902],
  [-77.6151201, 60.496060102],
  [-77.61454, 60.495309902],
  [-77.60473, 60.496010002],
  [-77.6037801, 60.497060102],
  [-77.6012101, 60.497360002],
  [-77.60075, 60.498140002],
  [-77.5992301, 60.498550002],
  [-77.59453, 60.498260002],
  [-77.5949901, 60.497480002],
  [-77.5903301, 60.497700002],
  [-77.58873, 60.497079902],
  [-77.58841, 60.499660002],
  [-77.5894301, 60.499380102],
  [-77.59006, 60.500910002],
  [-77.5880001, 60.500950102],
  [-77.5893301, 60.501430002],
  [-77.5886799, 60.502979902],
  [-77.5845501, 60.503320002],
  [-77.58459, 60.503830102],
  [-77.5893201, 60.504519902],
  [-77.5891001, 60.505020002],
  [-77.5923599, 60.506749902],
  [-77.59126, 60.509330002],
  [-77.5882299, 60.510409902],
  [-77.5873599, 60.512480002],
  [-77.5863201, 60.512500102],
  [-77.5858899, 60.513529902],
  [-77.58381, 60.513570002],
  [-77.5824, 60.515390102],
  [-77.56794, 60.516440002],
  [-77.5687799, 60.517189902],
  [-77.5683001, 60.517710002],
  [-77.56969, 60.518710002],
  [-77.57117, 60.517900002],
  [-77.57261, 60.519680002],
  [-77.5730901, 60.522480002],
  [-77.5704201, 60.521510102],
  [-77.57027, 60.522800002],
  [-77.57217, 60.523910002],
  [-77.5753, 60.524110002],
  [-77.58025, 60.527590002],
  [-77.58262, 60.527930002],
  [-77.58282, 60.530490002],
  [-77.5855599, 60.532230002],
  [-77.58506, 60.532490002],
  [-77.58539, 60.533380002],
  [-77.58793, 60.532690002],
  [-77.58804, 60.533970102],
  [-77.5859801, 60.534270002],
  [-77.5840199, 60.535710002],
  [-77.5808999, 60.535649902],
  [-77.5804499, 60.536680002],
  [-77.5819901, 60.536390002],
  [-77.5812001, 60.539480002],
  [-77.57495, 60.539350002],
  [-77.5748899, 60.538580002],
  [-77.57282, 60.538620002],
  [-77.5720801, 60.539150002],
  [-77.5724, 60.539910002],
  [-77.56982, 60.540209902],
  [-77.56925, 60.539449902],
  [-77.56513, 60.540050002],
  [-77.5650901, 60.539539902],
  [-77.55626, 60.539580002],
  [-77.55047, 60.538659902],
  [-77.5437401, 60.538920002],
  [-77.5437, 60.538410102],
  [-77.54161, 60.538319902],
  [-77.5375299, 60.539290102],
  [-77.53749, 60.538779902],
  [-77.5172701, 60.539420102],
  [-77.51723, 60.538910002],
  [-77.5120799, 60.539520102],
  [-77.5116201, 60.540300102],
  [-77.5090501, 60.540600002],
  [-77.50859, 60.541380002],
  [-77.5050501, 60.542600002],
  [-77.50091, 60.542930002],
  [-77.4767299, 60.539280002],
  [-77.44728, 60.541620002],
  [-77.44621, 60.541260002],
  [-77.44575, 60.542030002],
  [-77.44322, 60.542850102],
  [-77.4424, 60.545800002],
  [-77.45852, 60.545889902],
  [-77.45856, 60.546400002],
  [-77.46276, 60.546970102],
  [-77.46821, 60.550319902],
  [-77.4712899, 60.549750102],
  [-77.47133, 60.550270002],
  [-77.47712, 60.551180002],
  [-77.4777299, 60.552449902],
  [-77.4782501, 60.552439902],
  [-77.4779, 60.551170002],
  [-77.4801699, 60.550360002],
  [-77.48021, 60.550870102],
  [-77.4848399, 60.550270002],
  [-77.4848, 60.549500002],
  [-77.48842, 60.549439902],
  [-77.4869401, 60.550490002],
  [-77.48798, 60.550470002],
  [-77.48755, 60.551760102],
  [-77.48832, 60.551490002],
  [-77.4884999, 60.550460002],
  [-77.4900401, 60.550170002],
  [-77.4895901, 60.551209902],
  [-77.4911501, 60.551180002],
  [-77.49067, 60.551700002],
  [-77.49221, 60.551540102],
  [-77.4938599, 60.552530102],
  [-77.4969599, 60.552350002],
  [-77.497, 60.552850002],
  [-77.49832, 60.553090102],
  [-77.50043, 60.556889902],
  [-77.50354, 60.556710002],
  [-77.50466, 60.557840002],
  [-77.50621, 60.557679902],
  [-77.50768, 60.556490002],
  [-77.50934, 60.557750102],
  [-77.5082999, 60.557769902],
  [-77.5078599, 60.558800002],
  [-77.50888, 60.558540102],
  [-77.51054, 60.559769902],
  [-77.51157, 60.559750102],
  [-77.5116101, 60.560270002],
  [-77.5147201, 60.560080102],
  [-77.51533, 60.561219902],
  [-77.5195101, 60.561520002],
  [-77.5199799, 60.560880102],
  [-77.5266399, 60.559600002],
  [-77.53136, 60.560020002],
  [-77.5344001, 60.559070002],
  [-77.53483, 60.557910002],
  [-77.53793, 60.557590002],
  [-77.54058, 60.558310102],
  [-77.54105, 60.557669902],
  [-77.54317, 60.558130002],
  [-77.5432099, 60.558640102],
  [-77.54581, 60.558590002],
  [-77.5457599, 60.558080102],
  [-77.5499601, 60.558520002],
  [-77.5499, 60.557750102],
  [-77.5509899, 60.558370002],
  [-77.5525301, 60.558210102],
  [-77.5529999, 60.557559902],
  [-77.55613, 60.557630002],
  [-77.5567699, 60.559150002],
  [-77.5588701, 60.559370002],
  [-77.55984, 60.558580002],
  [-77.5598799, 60.559090102],
  [-77.56302, 60.559290002],
  [-77.56414, 60.560290002],
  [-77.56885, 60.560710002],
  [-77.5688899, 60.561219902],
  [-77.5845401, 60.561820002],
  [-77.5954401, 60.561480002],
  [-77.5958099, 60.559679902],
  [-77.5986501, 60.559370002],
  [-77.5980099, 60.557850002],
  [-77.5989199, 60.556290002],
  [-77.6023201, 60.556480002],
  [-77.6023599, 60.556990102],
  [-77.6056899, 60.556420102],
  [-77.6069199, 60.555500002],
  [-77.6090199, 60.555840002],
  [-77.6084, 60.554569902],
  [-77.61043, 60.553889902],
  [-77.61362, 60.554850002],
  [-77.6177801, 60.554769902],
  [-77.62031, 60.553950002],
  [-77.6234799, 60.554530102],
  [-77.62344, 60.554030002],
  [-77.62186, 60.553789902],
  [-77.6205701, 60.550750102],
  [-77.61608, 60.549810002],
  [-77.6162499, 60.548769902],
  [-77.61731, 60.549020002],
  [-77.61721, 60.547740102],
  [-77.61617, 60.547750102],
  [-77.6179099, 60.546700002],
  [-77.6184899, 60.544260002],
  [-77.62476, 60.544649902],
  [-77.62628, 60.544229902],
  [-77.6255999, 60.542200102],
  [-77.6276801, 60.542290102],
  [-77.62853, 60.543170002],
  [-77.6335899, 60.544600002],
  [-77.6332699, 60.543840002],
  [-77.6350399, 60.543290102],
  [-77.63508, 60.543810002],
  [-77.64089, 60.544720002],
  [-77.64098, 60.545989902],
  [-77.6472799, 60.546760102],
  [-77.6480301, 60.546370002],
  [-77.6479399, 60.545339902],
  [-77.64478, 60.541570002],
  [-77.6458101, 60.541539902],
  [-77.64577, 60.541030002],
  [-77.6494899, 60.541980102],
  [-77.6495701, 60.543010002],
  [-77.6513501, 60.542460002],
  [-77.6510499, 60.541950002],
  [-77.6525999, 60.541920002],
  [-77.6530201, 60.540630102],
  [-77.6587101, 60.540390002],
  [-77.66186, 60.540710002],
  [-77.6619, 60.541230002],
  [-77.6687001, 60.541730002],
  [-77.66968, 60.541070102],
  [-77.66977, 60.542090102],
  [-77.6723899, 60.542420102],
  [-77.6754901, 60.542109902],
  [-77.6771601, 60.543480002],
  [-77.6787201, 60.543449902],
  [-77.6798399, 60.544449902],
  [-77.6788, 60.544470002],
  [-77.6788399, 60.544980102],
  [-77.67621, 60.544520102],
  [-77.67631, 60.545800002],
  [-77.67476, 60.545830002],
  [-77.67591, 60.547219902],
  [-77.6774801, 60.547310102],
  [-77.6775199, 60.547830002],
  [-77.6863901, 60.548290002],
  [-77.6891, 60.549500002],
  [-77.69278, 60.550080102],
  [-77.6934099, 60.551350002],
  [-77.69077, 60.550889902],
  [-77.69081, 60.551390002],
  [-77.68666, 60.551480002],
  [-77.6849301, 60.552540102],
  [-77.68525, 60.553300102],
  [-77.6876299, 60.553769902],
  [-77.6888, 60.555280002],
  [-77.6959301, 60.556669902],
  [-77.69713, 60.558569902],
  [-77.70315, 60.559090102],
  [-77.7035199, 60.560360002],
  [-77.70813, 60.559500002],
  [-77.7081899, 60.560260002],
  [-77.70923, 60.560239902],
  [-77.71035, 60.561239902],
  [-77.71189, 60.560960002],
  [-77.7113099, 60.560200102],
  [-77.71235, 60.560180002],
  [-77.71358, 60.562459902],
  [-77.71202, 60.562490002],
  [-77.71311, 60.563109902],
  [-77.71646, 60.562660102],
  [-77.71716, 60.561750102],
  [-77.7181899, 60.561600002],
  [-77.7210999, 60.565370002],
  [-77.72214, 60.565360002],
  [-77.7219699, 60.566390002],
  [-77.72305, 60.566880102],
  [-77.72283, 60.567390002],
  [-77.7238699, 60.567370002],
  [-77.7227699, 60.569700002],
  [-77.7246499, 60.570430102],
  [-77.72469, 60.570940002],
  [-77.72316, 60.571229902],
  [-77.72246, 60.572260002],
  [-77.7256399, 60.572970002],
  [-77.72603, 60.574500002],
  [-77.7281801, 60.575220102],
  [-77.7266599, 60.575760102],
  [-77.7263099, 60.577820002],
  [-77.72735, 60.577799902],
  [-77.72714, 60.578310002],
  [-77.72916, 60.580700002],
  [-77.74958, 60.582210102],
  [-77.7496699, 60.583229902],
  [-77.7538799, 60.583650102],
  [-77.7539999, 60.585190002],
  [-77.7571199, 60.585119902],
  [-77.75718, 60.585890102],
  [-77.76741, 60.586960002],
  [-77.7683101, 60.588220102],
  [-77.7703499, 60.587670102],
  [-77.7697601, 60.586909902],
  [-77.7705599, 60.587150002],
  [-77.7707799, 60.589710002],
  [-77.77418, 60.589890102],
  [-77.77459, 60.588600002],
  [-77.7751101, 60.588589902],
  [-77.77518, 60.589359902],
  [-77.77719, 60.588550102],
  [-77.77726, 60.589320102],
  [-77.77829, 60.589290002],
  [-77.7782499, 60.588780102],
  [-77.77929, 60.588760002],
  [-77.7793301, 60.589270002],
  [-77.78083, 60.588469902],
  [-77.78129, 60.590770102],
  [-77.7836799, 60.591359902],
  [-77.78438, 60.590450102],
  [-77.78193, 60.589220102],
  [-77.7818701, 60.588450102],
  [-77.78239, 60.588440102],
  [-77.78315, 60.589100102],
  [-77.78504, 60.588899902],
  [-77.78507, 60.589410002],
  [-77.7897701, 60.589569902],
  [-77.7914501, 60.590940002],
  [-77.7925, 60.591050002],
  [-77.7917701, 60.591569902],
  [-77.79207, 60.592080002],
  [-77.79051, 60.592110102],
  [-77.7909901, 60.591579902],
  [-77.7883401, 60.591129902],
  [-77.78893, 60.591890102],
  [-77.7878901, 60.591909902],
  [-77.78728, 60.590899902],
  [-77.7862399, 60.590919902],
  [-77.7861399, 60.592710002],
  [-77.7885, 60.592919902],
  [-77.7885401, 60.593430002],
  [-77.79164, 60.593110102],
  [-77.7916999, 60.593880102],
  [-77.79471, 60.592540002],
  [-77.7947999, 60.593560102],
  [-77.7968901, 60.593640002],
  [-77.79842, 60.593230102],
  [-77.7983301, 60.592200002],
  [-77.7991299, 60.592440102],
  [-77.79961, 60.594990102],
  [-77.80171, 60.595210102],
  [-77.8017701, 60.595970002],
  [-77.79918, 60.596029902],
  [-77.79875, 60.597060002],
  [-77.7966701, 60.597100102],
  [-77.79571, 60.598019902],
  [-77.7910101, 60.597730002],
  [-77.79063, 60.599530002],
  [-77.7943, 60.599840002],
  [-77.79474, 60.598940002],
  [-77.7992301, 60.599610002],
  [-77.7995799, 60.600630002],
  [-77.79906, 60.600640002],
  [-77.7989901, 60.599870002],
  [-77.79743, 60.599900102],
  [-77.79784, 60.601689902],
  [-77.79971, 60.602160002],
  [-77.80019, 60.601640002],
  [-77.80095, 60.604440102],
  [-77.7999099, 60.604460102],
  [-77.8004601, 60.604830002],
  [-77.8040199, 60.603860002],
  [-77.8047701, 60.606410002],
  [-77.80373, 60.606430002],
  [-77.8036601, 60.605660102],
  [-77.80206, 60.605180002],
  [-77.8007701, 60.608280002],
  [-77.7966, 60.608369902],
  [-77.79565, 60.609420002],
  [-77.7966899, 60.609390002],
  [-77.7976, 60.610910102],
  [-77.79893, 60.611139902],
  [-77.79939, 60.610489902],
  [-77.8024, 60.611230102],
  [-77.80198, 60.610310002],
  [-77.8051, 60.610240102],
  [-77.80517, 60.611010102],
  [-77.8039099, 60.611550002],
  [-77.8053699, 60.613310002],
  [-77.8027701, 60.613359902],
  [-77.80317, 60.612070002],
  [-77.79951, 60.611890102],
  [-77.7990399, 60.612420002],
  [-77.7989901, 60.611910102],
  [-77.7957999, 60.611200002],
  [-77.79507, 60.611730002],
  [-77.79542, 60.612750002],
  [-77.7938099, 60.612269902],
  [-77.7955501, 60.614280002],
  [-77.79709, 60.613990002],
  [-77.7992599, 60.614970002],
  [-77.79921, 60.614460102],
  [-77.8018099, 60.614410002],
  [-77.80186, 60.614919902],
  [-77.80805, 60.614280002],
  [-77.8094699, 60.612579902],
  [-77.81051, 60.612550002],
  [-77.8133, 60.614680102],
  [-77.8122401, 60.614440002],
  [-77.81155, 60.615479902],
  [-77.81194, 60.617010102],
  [-77.80929, 60.616550002],
  [-77.8087099, 60.615800102],
  [-77.80975, 60.615780102],
  [-77.8091399, 60.614770002],
  [-77.80808, 60.614530002],
  [-77.80765, 60.615560102],
  [-77.80552, 60.615100002],
  [-77.8065, 60.617379902],
  [-77.8105999, 60.619599902],
  [-77.8090399, 60.619630002],
  [-77.80861, 60.620670102],
  [-77.81019, 60.620890002],
  [-77.8101499, 60.620379902],
  [-77.81117, 60.620100002],
  [-77.8112201, 60.620740002],
  [-77.81275, 60.620320002],
  [-77.8128199, 60.621090002],
  [-77.8169299, 60.620489902],
  [-77.8170201, 60.621510002],
  [-77.8175, 60.620990002],
  [-77.82433, 60.621609902],
  [-77.8250201, 60.623650002],
  [-77.82143, 60.624240102],
  [-77.8214701, 60.624750002],
  [-77.8256099, 60.624400002],
  [-77.82676, 60.625670002],
  [-77.82419, 60.625970002],
  [-77.8240201, 60.627000002],
  [-77.82488, 60.628000002],
  [-77.81962, 60.627350102],
  [-77.8183699, 60.628130102],
  [-77.8187099, 60.628900102],
  [-77.81665, 60.629200002],
  [-77.81785, 60.630970002],
  [-77.82043, 60.630660002],
  [-77.8204701, 60.631169902],
  [-77.8251499, 60.631070002],
  [-77.8252, 60.631580102],
  [-77.83255, 60.632190002],
  [-77.83264, 60.633220002],
  [-77.8342, 60.633180002],
  [-77.83541, 60.635210002],
  [-77.8364601, 60.635180002],
  [-77.8365701, 60.636460102],
  [-77.8376099, 60.636430002],
  [-77.8389499, 60.639740002],
  [-77.83689, 60.640039902],
  [-77.83774, 60.640790002],
  [-77.8382401, 60.643599902],
  [-77.8367001, 60.643880002],
  [-77.83677, 60.644650002],
  [-77.8316801, 60.646040102],
  [-77.83299, 60.649080002],
  [-77.8334601, 60.648560002],
  [-77.8355701, 60.648770002],
  [-77.8356099, 60.649279902],
  [-77.83353, 60.649330002],
  [-77.8329101, 60.651130002],
  [-77.83522, 60.653650002],
  [-77.83477, 60.657499902],
  [-77.8312, 60.658340002],
  [-77.83075, 60.659370102],
  [-77.8188099, 60.659870002],
  [-77.8189001, 60.660890002],
  [-77.8111499, 60.661839902],
  [-77.81161, 60.661059902],
  [-77.80373, 60.660450002],
  [-77.8044699, 60.663000002],
  [-77.8034501, 60.663279902],
  [-77.80287, 60.662519902],
  [-77.8023401, 60.662530002],
  [-77.80241, 60.663299902],
  [-77.7965699, 60.662140102],
  [-77.7958801, 60.663179902],
  [-77.79751, 60.663910002],
  [-77.7968801, 60.665720102],
  [-77.7947801, 60.665509902],
  [-77.79385, 60.666810102],
  [-77.7956899, 60.667020002],
  [-77.79551, 60.668050102],
  [-77.7944799, 60.668069902],
  [-77.79452, 60.668580002],
  [-77.7918999, 60.668380102],
  [-77.7914501, 60.669160102],
  [-77.7964799, 60.670079902],
  [-77.79631, 60.671110002],
  [-77.79276, 60.672330002],
  [-77.78083, 60.673220002],
  [-77.78149, 60.674739902],
  [-77.77993, 60.675030002],
  [-77.77881, 60.674030002],
  [-77.77464, 60.673990002],
  [-77.77159, 60.674949902],
  [-77.7697899, 60.678320002],
  [-77.7682399, 60.678480002],
  [-77.7628701, 60.676790002],
  [-77.75559, 60.677069902],
  [-77.7556501, 60.677839902],
  [-77.75359, 60.678140002],
  [-77.7526799, 60.679690002],
  [-77.7351801, 60.682610102],
  [-77.7342901, 60.684419902],
  [-77.73227, 60.685230002],
  [-77.7323099, 60.685739902],
  [-77.73127, 60.685760002],
  [-77.73036, 60.687320002],
  [-77.7288501, 60.687990002],
  [-77.7232099, 60.689260002],
  [-77.72313, 60.688230002],
  [-77.7164101, 60.689140002],
  [-77.71646, 60.689649902],
  [-77.7144299, 60.690330002],
  [-77.7102701, 60.690540002],
  [-77.70889, 60.692620102],
  [-77.70279, 60.694790002],
  [-77.7026299, 60.695820002],
  [-77.7006201, 60.696880002],
  [-77.7007599, 60.704820002],
  [-77.70148, 60.707110002],
  [-77.70344, 60.708730102],
  [-77.71182, 60.708940002],
  [-77.7118599, 60.709460002],
  [-77.71605, 60.709630102],
  [-77.71612, 60.710410102],
  [-77.70512, 60.710120002],
  [-77.70532, 60.712410102],
  [-77.70745, 60.712869902],
  [-77.7038401, 60.713470002],
  [-77.70388, 60.713970102],
  [-77.7008499, 60.715180102],
  [-77.69877, 60.715360002],
  [-77.69881, 60.715869902],
  [-77.6951999, 60.716450002],
  [-77.69525, 60.716950102],
  [-77.69121, 60.718710002],
  [-77.6901601, 60.718600002],
  [-77.6902, 60.719109902],
  [-77.6855099, 60.719209902],
  [-77.6842899, 60.720250002],
  [-77.6846101, 60.721020002],
  [-77.6830501, 60.721050002],
  [-77.6816299, 60.722869902],
  [-77.6780401, 60.723710002],
  [-77.6792999, 60.726240002],
  [-77.6854901, 60.725219902],
  [-77.6891501, 60.725280002],
  [-77.6891899, 60.725790002],
  [-77.69234, 60.725989902],
  [-77.6923901, 60.726490002],
  [-77.67797, 60.729090102],
  [-77.67846, 60.731889902],
  [-77.6777399, 60.732669902],
  [-77.67411, 60.732999902],
  [-77.67298, 60.735070102],
  [-77.6753701, 60.735539902],
  [-77.6723, 60.736370002],
  [-77.67188, 60.737659902],
  [-77.67086, 60.737930002],
  [-77.6717101, 60.735610002],
  [-77.67012, 60.735390002],
  [-77.66854, 60.738490002],
  [-77.6709399, 60.738950002],
  [-77.67142, 60.738430102],
  [-77.67109, 60.740740102],
  [-77.6741799, 60.740170002],
  [-77.67465, 60.739520102],
  [-77.67831, 60.739319902],
  [-77.67677, 60.739860102],
  [-77.67734, 60.740360002],
  [-77.6717199, 60.742009902],
  [-77.67179, 60.742779902],
  [-77.6697199, 60.743080102],
  [-77.66976, 60.743590002],
  [-77.66045, 60.744800002],
  [-77.66078, 60.742490002],
  [-77.6581699, 60.742540102],
  [-77.65752, 60.741009902],
  [-77.65654, 60.741800002],
  [-77.6549101, 60.741070002],
  [-77.6550301, 60.742600002],
  [-77.65299, 60.743150002],
  [-77.65357, 60.743910002],
  [-77.65254, 60.743930002],
  [-77.6525701, 60.744439902],
  [-77.6535999, 60.744290002],
  [-77.65407, 60.743640102],
  [-77.65464, 60.744140002],
  [-77.6511699, 60.746520002],
  [-77.65222, 60.746500002],
  [-77.6528299, 60.747510002],
  [-77.64819, 60.748370002],
  [-77.64677, 60.750190102],
  [-77.6353401, 60.751180002],
  [-77.6353, 60.750669902],
  [-77.6342501, 60.750690002],
  [-77.63463, 60.748889902],
  [-77.6335899, 60.748910002],
  [-77.63365, 60.749679902],
  [-77.62997, 60.749490002],
  [-77.62939, 60.748740002],
  [-77.6242599, 60.749990102],
  [-77.6169199, 60.749880102],
  [-77.6125932, 60.750825502],
  [-77.6128841, 60.751361302],
  [-77.61495, 60.751310102],
  [-77.6150101, 60.752090102],
  [-77.6275899, 60.752360002],
  [-77.6276901, 60.753630002],
  [-77.63366, 60.756210102],
  [-77.63619, 60.758330102],
  [-77.6360101, 60.759360002],
  [-77.6317901, 60.758930002],
  [-77.63288, 60.759420102],
  [-77.6256801, 60.760970002],
  [-77.62099, 60.761190002],
  [-77.6210299, 60.761700002],
  [-77.63554, 60.760009902],
  [-77.6421699, 60.757830002],
  [-77.6663701, 60.753129902],
  [-77.66633, 60.752620002],
  [-77.6724701, 60.750960002],
  [-77.6725099, 60.751459902],
  [-77.6689399, 60.752559902],
  [-77.6684801, 60.753339902],
  [-77.6623601, 60.755260002],
  [-77.6619, 60.756030002],
  [-77.66031, 60.755810002],
  [-77.66035, 60.756320102],
  [-77.6582899, 60.756620002],
  [-77.6568201, 60.757920002],
  [-77.64453, 60.760980102],
  [-77.6440599, 60.761760102],
  [-77.64299, 60.761530102],
  [-77.6430399, 60.762040002],
  [-77.6414899, 60.762320102],
  [-77.64153, 60.762840002],
  [-77.6328199, 60.765050002],
  [-77.63185, 60.766100102],
  [-77.6257099, 60.767750002],
  [-77.62475, 60.768799902],
  [-77.62218, 60.769359902],
  [-77.6222499, 60.770129902],
  [-77.62119, 60.770150002],
  [-77.6212401, 60.770660102],
  [-77.62307, 60.770620002],
  [-77.62285, 60.771139902],
  [-77.62808, 60.771160002],
  [-77.6326999, 60.770050002],
  [-77.6331599, 60.769400002],
  [-77.63887, 60.768640002],
  [-77.63984, 60.767730002],
  [-77.64295, 60.767420002],
  [-77.6419299, 60.767689902],
  [-77.64197, 60.768200102],
  [-77.65741, 60.764950002],
  [-77.6658001, 60.765170002],
  [-77.66576, 60.764660102],
  [-77.67387, 60.761550102],
  [-77.67647, 60.761360002],
  [-77.6765199, 60.761889902],
  [-77.67547, 60.761909902],
  [-77.6745099, 60.762950002],
  [-77.6683899, 60.764870102],
  [-77.6687199, 60.765630002],
  [-77.6677101, 60.766160002],
  [-77.66734, 60.767960002],
  [-77.66788, 60.768200102],
  [-77.66768, 60.768970002],
  [-77.67389, 60.768080002],
  [-77.6738499, 60.767569902],
  [-77.67542, 60.767540102],
  [-77.6753701, 60.767030002],
  [-77.6800699, 60.766810002],
  [-77.69804, 60.762730002],
  [-77.698, 60.762220102],
  [-77.7114099, 60.759899902],
  [-77.71235, 60.758610002],
  [-77.7282099, 60.754310102],
  [-77.73134, 60.754250002],
  [-77.7323299, 60.753459902],
  [-77.73849, 60.752200102],
  [-77.73845, 60.751669902],
  [-77.74359, 60.750669902],
  [-77.7514101, 60.750380002],
  [-77.75496, 60.749030002],
  [-77.75492, 60.748520002],
  [-77.7595599, 60.747659902],
  [-77.7602199, 60.746360002],
  [-77.77063, 60.742690002],
  [-77.79182, 60.739810002],
  [-77.79137, 60.740600002],
  [-77.78619, 60.741219902],
  [-77.7862399, 60.741730002],
  [-77.78061, 60.743130002],
  [-77.7806501, 60.743640102],
  [-77.77804, 60.743690002],
  [-77.77808, 60.744200102],
  [-77.7724599, 60.745730002],
  [-77.76162, 60.747360002],
  [-77.7616599, 60.747870102],
  [-77.7478701, 60.751740002],
  [-77.74791, 60.752250002],
  [-77.7437799, 60.752850002],
  [-77.7433199, 60.753620002],
  [-77.74228, 60.753640102],
  [-77.7423199, 60.754160002],
  [-77.73405, 60.755349902],
  [-77.7340899, 60.755860102],
  [-77.7325301, 60.755889902],
  [-77.73213, 60.757430102],
  [-77.72904, 60.758009902],
  [-77.72813, 60.759569902],
  [-77.72404, 60.760669902],
  [-77.72358, 60.761449902],
  [-77.72147, 60.761239902],
  [-77.72205, 60.761990102],
  [-77.7168699, 60.762610002],
  [-77.71692, 60.763119902],
  [-77.7138501, 60.763950002],
  [-77.71391, 60.764710002],
  [-77.7128699, 60.764740002],
  [-77.71291, 60.765249902],
  [-77.70724, 60.766260002],
  [-77.7031801, 60.767750002],
  [-77.70322, 60.768260002],
  [-77.69814, 60.770160002],
  [-77.6977101, 60.771190002],
  [-77.6915299, 60.772469902],
  [-77.68288, 60.775459902],
  [-77.67769, 60.775940002],
  [-77.6777299, 60.776450102],
  [-77.6761799, 60.776740002],
  [-77.67623, 60.777249902],
  [-77.6663899, 60.778600002],
  [-77.65564, 60.781500002],
  [-77.6541699, 60.782809902],
  [-77.65212, 60.783359902],
  [-77.65156, 60.782860002],
  [-77.6515999, 60.783369902],
  [-77.6500499, 60.783660102],
  [-77.6501, 60.784170002],
  [-77.6532501, 60.784380002],
  [-77.65543, 60.785349902],
  [-77.65133, 60.786450102],
  [-77.6512699, 60.785689902],
  [-77.65232, 60.785790102],
  [-77.65279, 60.785139902],
  [-77.64966, 60.785210102],
  [-77.6508399, 60.786840002],
  [-77.6570599, 60.786080002],
  [-77.6574999, 60.785050002],
  [-77.6611301, 60.784469902],
  [-77.6615901, 60.783820002],
  [-77.6898499, 60.777359902],
  [-77.69032, 60.776720002],
  [-77.70013, 60.775110102],
  [-77.70356, 60.772349902],
  [-77.71234, 60.770899902],
  [-77.71237, 60.771420002],
  [-77.72637, 60.769710002],
  [-77.7299601, 60.768750002],
  [-77.72991, 60.768229902],
  [-77.7314101, 60.767440102],
  [-77.7457401, 60.763559902],
  [-77.7453001, 60.764590002],
  [-77.75303, 60.763150002],
  [-77.7529899, 60.762640002],
  [-77.75403, 60.762620002],
  [-77.75492, 60.760810002],
  [-77.76113, 60.759920002],
  [-77.7610801, 60.759400002],
  [-77.7667799, 60.758770102],
  [-77.76674, 60.758260002],
  [-77.7735401, 60.758119902],
  [-77.77364, 60.759400002],
  [-77.7587699, 60.762909902],
  [-77.75614, 60.762830002],
  [-77.7562101, 60.763600002],
  [-77.7495, 60.764760102],
  [-77.7485499, 60.765810002],
  [-77.7444299, 60.766660102],
  [-77.74348, 60.767700002],
  [-77.73424, 60.769940002],
  [-77.7332901, 60.770970002],
  [-77.73172, 60.771009902],
  [-77.7317599, 60.771530002],
  [-77.7307, 60.771290002],
  [-77.72926, 60.772860002],
  [-77.70477, 60.780390002],
  [-77.6847201, 60.784760002],
  [-77.68425, 60.785530002],
  [-77.6760401, 60.787630002],
  [-77.6620699, 60.789570102],
  [-77.66211, 60.790080002],
  [-77.6604999, 60.789600002],
  [-77.6600599, 60.790640002],
  [-77.6584899, 60.790660102],
  [-77.65755, 60.791960002],
  [-77.65698, 60.791460102],
  [-77.6497299, 60.792369902],
  [-77.64977, 60.792880002],
  [-77.6487199, 60.792900102],
  [-77.6482599, 60.793680102],
  [-77.64722, 60.793699902],
  [-77.6472599, 60.794210002],
  [-77.64366, 60.795050002],
  [-77.6432, 60.795830002],
  [-77.64163, 60.795860002],
  [-77.64167, 60.796369902],
  [-77.63596, 60.797000102],
  [-77.6350201, 60.798290002],
  [-77.62674, 60.799479902],
  [-77.6267801, 60.799990002],
  [-77.61852, 60.801560102],
  [-77.6124, 60.803599902],
  [-77.6124699, 60.804369902],
  [-77.6046999, 60.805540002],
  [-77.6032301, 60.806850002],
  [-77.6001399, 60.807420002],
  [-77.60018, 60.807929902],
  [-77.5944599, 60.808560102],
  [-77.5919901, 60.810400002],
  [-77.5894, 60.810699902],
  [-77.5894401, 60.811210002],
  [-77.5878901, 60.811500002],
  [-77.58793, 60.812010102],
  [-77.57708, 60.814010102],
  [-77.5756601, 60.815829902],
  [-77.57302, 60.815630002],
  [-77.5729801, 60.815110002],
  [-77.5693399, 60.815440002],
  [-77.56892, 60.816730002],
  [-77.5658, 60.817039902],
  [-77.56483, 60.818090002],
  [-77.5590901, 60.818440002],
  [-77.55913, 60.818960002],
  [-77.55038, 60.820929902],
  [-77.5504201, 60.821430002],
  [-77.54835, 60.821730002],
  [-77.54839, 60.822240102],
  [-77.5431901, 60.822850002],
  [-77.54323, 60.823360102],
  [-77.5421901, 60.823379902],
  [-77.5427501, 60.823880002],
  [-77.5411801, 60.823910102],
  [-77.5412199, 60.824410002],
  [-77.5288599, 60.827220002],
  [-77.5289, 60.827729902],
  [-77.53045, 60.827440002],
  [-77.53103, 60.828210002],
  [-77.5259401, 60.830100002],
  [-77.52856, 60.830039902],
  [-77.5291801, 60.831310002],
  [-77.52288, 60.831169902],
  [-77.52292, 60.831680002],
  [-77.52036, 60.832499902],
  [-77.51899, 60.835080002],
  [-77.52057, 60.835179902],
  [-77.52114, 60.835810102],
  [-77.5159599, 60.836420002],
  [-77.5147399, 60.837719902],
  [-77.5157201, 60.840260102],
  [-77.51468, 60.840279902],
  [-77.51425, 60.841570002],
  [-77.50555, 60.844289902],
  [-77.49677, 60.846120002],
  [-77.49414, 60.846040102],
  [-77.4941799, 60.846540002],
  [-77.4962801, 60.846509902],
  [-77.5122999, 60.843399902],
  [-77.5142701, 60.841829902],
  [-77.51532, 60.841810102],
  [-77.5152801, 60.841300002],
  [-77.51889, 60.840460002],
  [-77.5223464, 60.838188802],
  [-77.5276101, 60.837990002],
  [-77.52715, 60.838770002],
  [-77.5313199, 60.838440002],
  [-77.53112, 60.839210002],
  [-77.5366499, 60.839489902],
  [-77.54479, 60.836389902],
  [-77.54636, 60.836350002],
  [-77.5463001, 60.835590102],
  [-77.54892, 60.835540002],
  [-77.55035, 60.833719902],
  [-77.55505, 60.833509902],
  [-77.5555301, 60.832870002],
  [-77.55837, 60.832290002],
  [-77.5595599, 60.830860002],
  [-77.56318, 60.830150102],
  [-77.5631199, 60.829640002],
  [-77.5663101, 60.829960002],
  [-77.5735, 60.828159902],
  [-77.5734599, 60.827650002],
  [-77.5802101, 60.826740002],
  [-77.5806799, 60.826100002],
  [-77.58228, 60.826460102],
  [-77.5822201, 60.825690102],
  [-77.5843399, 60.826030102],
  [-77.6028901, 60.821960002],
  [-77.60285, 60.821450002],
  [-77.6146801, 60.818660002],
  [-77.61563, 60.817489902],
  [-77.6197901, 60.817030102],
  [-77.6238099, 60.814900102],
  [-77.62377, 60.814389902],
  [-77.6253401, 60.814360102],
  [-77.62628, 60.813049902],
  [-77.6330299, 60.812290002],
  [-77.63609, 60.811330002],
  [-77.6360499, 60.810819902],
  [-77.64229, 60.810180002],
  [-77.64276, 60.809530002],
  [-77.6567299, 60.807340102],
  [-77.65998, 60.805479902],
  [-77.66333, 60.804780102],
  [-77.66442, 60.805269902],
  [-77.68053, 60.803670102],
  [-77.68111, 60.804290002],
  [-77.68266, 60.804130102],
  [-77.6836399, 60.803220002],
  [-77.6857399, 60.803310002],
  [-77.68933, 60.802340102],
  [-77.68843, 60.801080002],
  [-77.69021, 60.800400002],
  [-77.6938599, 60.800200002],
  [-77.69444, 60.800819902],
  [-77.70068, 60.800320002],
  [-77.70293, 60.798990002],
  [-77.70147, 60.797230102],
  [-77.7046299, 60.797420002],
  [-77.70402, 60.796410002],
  [-77.70666, 60.796610002],
  [-77.7081601, 60.795809902],
  [-77.70812, 60.795300002],
  [-77.7112801, 60.795500002],
  [-77.71123, 60.794980002],
  [-77.7149, 60.794909902],
  [-77.7143099, 60.794149902],
  [-77.71693, 60.794230102],
  [-77.7189699, 60.793540002],
  [-77.71901, 60.794060002],
  [-77.72183, 60.793230102],
  [-77.72148, 60.792220102],
  [-77.72467, 60.792790102],
  [-77.7293199, 60.792060002],
  [-77.72928, 60.791550102],
  [-77.7355101, 60.790909902],
  [-77.73591, 60.789490002],
  [-77.7390701, 60.789680102],
  [-77.74262, 60.788330102],
  [-77.7457799, 60.788520002],
  [-77.7555401, 60.786259902],
  [-77.7634, 60.786369902],
  [-77.76495, 60.785950002],
  [-77.76483, 60.784670102],
  [-77.7674401, 60.784620002],
  [-77.7674, 60.784110102],
  [-77.7663299, 60.783870002],
  [-77.7694599, 60.783550102],
  [-77.76949, 60.784070002],
  [-77.77105, 60.784029902],
  [-77.7710099, 60.783520002],
  [-77.77574, 60.783679902],
  [-77.7763301, 60.784440102],
  [-77.77529, 60.784459902],
  [-77.7753499, 60.785220102],
  [-77.77328, 60.785520002],
  [-77.77283, 60.786300002],
  [-77.7738701, 60.786280002],
  [-77.77396, 60.787300002],
  [-77.7719099, 60.787860002],
  [-77.7720199, 60.789129902],
  [-77.77307, 60.789110102],
  [-77.7731101, 60.789620002],
  [-77.7752201, 60.789710002],
  [-77.7766799, 60.788530002],
  [-77.7834401, 60.787860002],
  [-77.78339, 60.787359902],
  [-77.7844401, 60.787340102],
  [-77.78437, 60.786569902],
  [-77.78542, 60.786550102],
  [-77.78561, 60.785780102],
  [-77.7874, 60.785230102],
  [-77.7873499, 60.784720002],
  [-77.79254, 60.784100102],
  [-77.7980299, 60.781040002],
  [-77.8062, 60.778699902],
  [-77.80616, 60.778180002],
  [-77.8092201, 60.777220102],
  [-77.8100101, 60.777330102],
  [-77.8103599, 60.778349902],
  [-77.8119299, 60.778320102],
  [-77.81175, 60.776270002],
  [-77.81332, 60.776239902],
  [-77.8168099, 60.774249902],
  [-77.8209299, 60.773520002],
  [-77.8224, 60.772339902],
  [-77.8280499, 60.771070002],
  [-77.8300201, 60.769740002],
  [-77.84696, 60.765930002],
  [-77.86243, 60.763290002],
  [-77.86558, 60.763359902],
  [-77.8660401, 60.762710002],
  [-77.8686001, 60.762009902],
  [-77.86855, 60.761500002],
  [-77.87064, 60.761459902],
  [-77.8710599, 60.760290002],
  [-77.87892, 60.760380002],
  [-77.8794099, 60.759990102],
  [-77.8772701, 60.759520002],
  [-77.87768, 60.758229902],
  [-77.8812899, 60.757640102],
  [-77.88124, 60.757129902],
  [-77.8838, 60.756440102],
  [-77.8847399, 60.755260002],
  [-77.8878, 60.754430102],
  [-77.8889699, 60.752870102],
  [-77.89226, 60.751640102],
  [-77.89693, 60.751160002],
  [-77.89891, 60.749960002],
  [-77.9114299, 60.749439902],
  [-77.9180801, 60.747880102],
  [-77.91801, 60.747119902],
  [-77.9221099, 60.746130002],
  [-77.92447, 60.746210102],
  [-77.92382, 60.747760102],
  [-77.9248699, 60.747740002],
  [-77.9235, 60.750070002],
  [-77.9209899, 60.751150002],
  [-77.92106, 60.751920002],
  [-77.9154299, 60.753320102],
  [-77.91548, 60.753830002],
  [-77.9144399, 60.753850002],
  [-77.91437, 60.753090102],
  [-77.91591, 60.752800002],
  [-77.91378, 60.752329902],
  [-77.91382, 60.752840002],
  [-77.9107, 60.752910002],
  [-77.9097599, 60.754210102],
  [-77.9123199, 60.753640102],
  [-77.91237, 60.754150002],
  [-77.90881, 60.755260002],
  [-77.91049, 60.756500002],
  [-77.90945, 60.756520002],
  [-77.90902, 60.757559902],
  [-77.90548, 60.758909902],
  [-77.9055201, 60.759420102],
  [-77.8993901, 60.761090102],
  [-77.89944, 60.761600002],
  [-77.89326, 60.762760102],
  [-77.8930501, 60.763280002],
  [-77.89388, 60.763770102],
  [-77.8928301, 60.763799902],
  [-77.89292, 60.764820002],
  [-77.88989, 60.766019902],
  [-77.88625, 60.766239902],
  [-77.8861999, 60.765730002],
  [-77.88466, 60.766019902],
  [-77.88459, 60.765249902],
  [-77.8792999, 60.764600002],
  [-77.8845099, 60.761410002],
  [-77.88467, 60.760390002],
  [-77.8820501, 60.760310102],
  [-77.8795001, 60.761009902],
  [-77.87954, 60.761520002],
  [-77.87125, 60.762469902],
  [-77.86932, 60.764300002],
  [-77.8682799, 60.764320102],
  [-77.86835, 60.765090102],
  [-77.86731, 60.765110102],
  [-77.8663701, 60.766410002],
  [-77.86533, 60.766430102],
  [-77.86358, 60.770310002],
  [-77.86154, 60.770870102],
  [-77.85713, 60.774290002],
  [-77.8524701, 60.774899902],
  [-77.85252, 60.775410002],
  [-77.85043, 60.775459902],
  [-77.84997, 60.776230102],
  [-77.8432699, 60.777400002],
  [-77.8439, 60.778670102],
  [-77.83829, 60.780320102],
  [-77.83887, 60.780950002],
  [-77.8414899, 60.781029902],
  [-77.8399499, 60.781310002],
  [-77.8390201, 60.782610002],
  [-77.8405801, 60.782579902],
  [-77.8410301, 60.781670102],
  [-77.8425999, 60.781640002],
  [-77.84742, 60.779870002],
  [-77.8476099, 60.779100102],
  [-77.85122, 60.778510002],
  [-77.8511799, 60.778000102],
  [-77.8568, 60.776469902],
  [-77.8625099, 60.775970002],
  [-77.86321, 60.774930002],
  [-77.8680599, 60.773540102],
  [-77.8684901, 60.772510002],
  [-77.8659201, 60.773080002],
  [-77.86686, 60.772029902],
  [-77.86376, 60.772359902],
  [-77.86333, 60.773390002],
  [-77.8602601, 60.774220102],
  [-77.86143, 60.772660102],
  [-77.86322, 60.772239902],
  [-77.8674099, 60.769210102],
  [-77.86734, 60.768440102],
  [-77.87021, 60.768510002],
  [-77.8747499, 60.766490002],
  [-77.87632, 60.766449902],
  [-77.8762801, 60.765940002],
  [-77.87732, 60.765920002],
  [-77.87591, 60.767740002],
  [-77.8743701, 60.768030002],
  [-77.8719699, 60.770400002],
  [-77.8750599, 60.769940002],
  [-77.88267, 60.767210102],
  [-77.88454, 60.767559902],
  [-77.88289, 60.769640002],
  [-77.87832, 60.771280002],
  [-77.8783901, 60.772040002],
  [-77.8758301, 60.772610002],
  [-77.87587, 60.773119902],
  [-77.8742999, 60.773170002],
  [-77.8743701, 60.773919902],
  [-77.8718101, 60.774490002],
  [-77.8723901, 60.775239902],
  [-77.8698301, 60.775809902],
  [-77.86988, 60.776320102],
  [-77.86265, 60.777500002],
  [-77.8627199, 60.778270002],
  [-77.8648399, 60.778610002],
  [-77.87465, 60.776990102],
  [-77.8746001, 60.776480002],
  [-77.8832899, 60.774110102],
  [-77.89245, 60.774050002],
  [-77.8918301, 60.775850002],
  [-77.89078, 60.775870002],
  [-77.8909, 60.777150002],
  [-77.8898499, 60.777170002],
  [-77.8899, 60.777689902],
  [-77.88833, 60.777720002],
  [-77.88837, 60.778230102],
  [-77.8868301, 60.778520002],
  [-77.88688, 60.779029902],
  [-77.8832899, 60.779880102],
  [-77.88333, 60.780390002],
  [-77.88179, 60.780679902],
  [-77.8818301, 60.781190002],
  [-77.87567, 60.782600002],
  [-77.87524, 60.783630002],
  [-77.87365, 60.783410002],
  [-77.8737201, 60.784180002],
  [-77.8665901, 60.786380002],
  [-77.8641501, 60.788479902],
  [-77.8625901, 60.788510002],
  [-77.8616299, 60.789560102],
  [-77.86009, 60.789850002],
  [-77.86013, 60.790359902],
  [-77.8570599, 60.791190002],
  [-77.85713, 60.791960002],
  [-77.8561, 60.791980002],
  [-77.8553899, 60.793019902],
  [-77.8567001, 60.792990002],
  [-77.85622, 60.793510002],
  [-77.8572899, 60.793750002],
  [-77.8531699, 60.794599902],
  [-77.85322, 60.795110102],
  [-77.85168, 60.795400002],
  [-77.8517299, 60.795909902],
  [-77.85067, 60.795940002],
  [-77.85074, 60.796699902],
  [-77.84764, 60.797280002],
  [-77.84708, 60.796780102],
  [-77.84522, 60.799379902],
  [-77.8514999, 60.799379902],
  [-77.8524701, 60.798460102],
  [-77.8540401, 60.798420002],
  [-77.85399, 60.797919902],
  [-77.86122, 60.796740002],
  [-77.86056, 60.795210002],
  [-77.86213, 60.795180002],
  [-77.86209, 60.794670102],
  [-77.86733, 60.794680102],
  [-77.86779, 60.794029902],
  [-77.86991, 60.794249902],
  [-77.8703701, 60.793589902],
  [-77.87299, 60.793410002],
  [-77.8730699, 60.794430002],
  [-77.8735901, 60.794430002],
  [-77.87398, 60.792880002],
  [-77.8765901, 60.792809902],
  [-77.87702, 60.791790102],
  [-77.8786399, 60.792270002],
  [-77.8790699, 60.791230102],
  [-77.88268, 60.790640002],
  [-77.8826399, 60.790129902],
  [-77.88579, 60.790320002],
  [-77.8857, 60.789300002],
  [-77.8975399, 60.787000102],
  [-77.8976, 60.787510002],
  [-77.8991601, 60.787469902],
  [-77.8987499, 60.788760002],
  [-77.9019, 60.788820002],
  [-77.9009826, 60.790541402],
  [-77.90267, 60.791490002],
  [-77.91245, 60.789490002],
  [-77.91248, 60.790000102],
  [-77.91615, 60.790060002],
  [-77.9186499, 60.788710002],
  [-77.92123, 60.788270002],
  [-77.9217, 60.787620002],
  [-77.94135, 60.784890102],
  [-77.9507, 60.784040002],
  [-77.9507501, 60.784550102],
  [-77.9497, 60.784579902],
  [-77.9497501, 60.785090002],
  [-77.9507899, 60.785070002],
  [-77.9508601, 60.785820002],
  [-77.9544599, 60.785110102],
  [-77.95818, 60.785670102],
  [-77.9586501, 60.785019902],
  [-77.9690099, 60.783770102],
  [-77.96995, 60.782720002],
  [-77.9797801, 60.781349902],
  [-77.97919, 60.780600002],
  [-77.98438, 60.780100102],
  [-77.98484, 60.779450102],
  [-77.9858901, 60.779430102],
  [-77.9858099, 60.778660102],
  [-77.98686, 60.778630002],
  [-77.9868099, 60.778120102],
  [-77.9881, 60.777840002],
  [-77.9890199, 60.776540102],
  [-77.9907901, 60.775860002],
  [-77.99428, 60.776679902],
  [-77.99432, 60.777190002],
  [-77.9965505, 60.778157202],
  [-77.99496, 60.781270002],
  [-77.99239, 60.781840002],
  [-77.9912499, 60.780840002],
  [-77.9804699, 60.783129902],
  [-77.97862, 60.785730002],
  [-77.97976, 60.786730002],
  [-77.9854501, 60.785960002],
  [-77.9903301, 60.787770102],
  [-77.99038, 60.788290002],
  [-77.9972599, 60.789039902],
  [-78.00053, 60.788589902],
  [-78.00118, 60.787330102],
  [-78.00486, 60.787160002],
  [-78.0031499, 60.788650002],
  [-78.0052699, 60.788440102],
  [-78.0045801, 60.790220102],
  [-78.0134999, 60.790160002],
  [-78.01318, 60.790670102],
  [-78.0139399, 60.790940002],
  [-78.0156999, 60.788929902],
  [-78.01944, 60.788120102],
  [-78.02176, 60.788560102],
  [-78.02197, 60.789070002],
  [-78.0209001, 60.789300002],
  [-78.02397, 60.790010102],
  [-78.0350499, 60.789359902],
  [-78.03509, 60.788850002],
  [-78.0372, 60.788640002],
  [-78.0372501, 60.788129902],
  [-78.04042, 60.787820002],
  [-78.0424801, 60.788249902],
  [-78.0409399, 60.790770102],
  [-78.04165, 60.791560102],
  [-78.0406001, 60.791530002],
  [-78.04023, 60.792809902],
  [-78.03911, 60.793540002],
  [-78.04088, 60.794230102],
  [-78.0450499, 60.794450102],
  [-78.045, 60.794960002],
  [-78.04343, 60.794919902],
  [-78.0428201, 60.795940002],
  [-78.0443899, 60.795970002],
  [-78.0448101, 60.797000102],
  [-78.0533497, 60.796890002],
  [-78.0590599, 60.795909902],
  [-78.0637754, 60.793663702],
  [-78.0756549, 60.792078302],
  [-78.0866736, 60.791932502],
  [-78.092041, 60.793207302],
  [-78.0939098, 60.795216402],
  [-78.0897912, 60.798294902],
  [-78.08756, 60.802290002],
  [-78.0732665, 60.811894802],
  [-78.071945, 60.814216202],
  [-78.0745106, 60.815563702],
  [-78.0796474, 60.814884802],
  [-78.088286, 60.810707902],
  [-78.0991625, 60.808038602],
  [-78.1049725, 60.808159302],
  [-78.1146351, 60.803391102],
  [-78.1214822, 60.802061002],
  [-78.13225, 60.797750002],
  [-78.1378071, 60.797491202],
  [-78.1453547, 60.795753702],
  [-78.154145, 60.795604602],
  [-78.16298, 60.793919902],
  [-78.1643926, 60.792799902],
  [-78.1705348, 60.792288402],
  [-78.169849, 60.792859702],
  [-78.1714401, 60.792950002],
  [-78.1735914, 60.792177402],
  [-78.1793399, 60.792974202],
  [-78.1843153, 60.791306202],
  [-78.1850942, 60.790133202],
  [-78.1843599, 60.788990102],
  [-78.1828269, 60.788343802],
  [-78.1869338, 60.786855702],
  [-78.1893865, 60.785235502],
  [-78.1949982, 60.784729102],
  [-78.19511, 60.785890102],
  [-78.2013699, 60.786149902],
  [-78.2036312, 60.785202402],
  [-78.2030933, 60.783158002],
  [-78.2140904, 60.779813302],
  [-78.2185914, 60.779771102],
  [-78.2198797, 60.780458102],
  [-78.2191123, 60.780684202],
  [-78.21819, 60.782530002],
  [-78.21945, 60.783070002],
  [-78.2194899, 60.782560102],
  [-78.2221699, 60.781840002],
  [-78.2221301, 60.782359902],
  [-78.2247199, 60.782670102],
  [-78.2235901, 60.783670102],
  [-78.2267199, 60.783730002],
  [-78.2250201, 60.785230102],
  [-78.22607, 60.785239902],
  [-78.2239301, 60.788790102],
  [-78.22679, 60.788980002],
  [-78.2272601, 60.789630002],
  [-78.2240499, 60.790589902],
  [-78.22399, 60.791100002],
  [-78.2154601, 60.792970002],
  [-78.2122755, 60.797019402],
  [-78.21034, 60.797469902],
  [-78.20755, 60.799460102],
  [-78.20053, 60.801880002],
  [-78.2005701, 60.801369902],
  [-78.19841, 60.802090002],
  [-78.1984601, 60.801579902],
  [-78.1954009, 60.801213902],
  [-78.2010461, 60.799280202],
  [-78.1986273, 60.798051502],
  [-78.1988729, 60.797583302],
  [-78.194716, 60.797557802],
  [-78.1945986, 60.797100002],
  [-78.1913942, 60.797756502],
  [-78.1894424, 60.799975102],
  [-78.1843529, 60.801728502],
  [-78.1837319, 60.802734302],
  [-78.1851786, 60.803813302],
  [-78.1873774, 60.804168302],
  [-78.1813362, 60.807696602],
  [-78.18141, 60.809038802],
  [-78.1824412, 60.809016202],
  [-78.1822683, 60.808256402],
  [-78.1841499, 60.808229202],
  [-78.1838209, 60.809032402],
  [-78.1775383, 60.810690902],
  [-78.1745655, 60.810732802],
  [-78.1766886, 60.811579002],
  [-78.1762604, 60.811778702],
  [-78.1717595, 60.811201602],
  [-78.1725111, 60.811969202],
  [-78.1765066, 60.812075002],
  [-78.1801474, 60.810553402],
  [-78.1840871, 60.810141602],
  [-78.1854952, 60.808839802],
  [-78.1853134, 60.806706302],
  [-78.1902258, 60.806017202],
  [-78.1921001, 60.806454102],
  [-78.1904132, 60.806618302],
  [-78.1919129, 60.807087002],
  [-78.1998179, 60.804884602],
  [-78.2011067, 60.804374602],
  [-78.2007708, 60.803790202],
  [-78.2019371, 60.802991102],
  [-78.2034689, 60.803312302],
  [-78.2043983, 60.802512802],
  [-78.2056436, 60.803034402],
  [-78.2112376, 60.801862902],
  [-78.2140277, 60.802599202],
  [-78.2194759, 60.802155102],
  [-78.2224898, 60.800691102],
  [-78.2244181, 60.800663202],
  [-78.225412, 60.801286702],
  [-78.2226481, 60.803153102],
  [-78.2175738, 60.803747602],
  [-78.2117507, 60.805729002],
  [-78.2079001, 60.807410002],
  [-78.2078501, 60.808157002],
  [-78.2062, 60.808432802],
  [-78.2068635, 60.807674202],
  [-78.2052047, 60.807763002],
  [-78.2053589, 60.808609702],
  [-78.2050054, 60.807738802],
  [-78.1984602, 60.809422102],
  [-78.197255, 60.810276902],
  [-78.1920832, 60.810038102],
  [-78.1877523, 60.811975402],
  [-78.1821082, 60.812543402],
  [-78.17784, 60.814537702],
  [-78.1770145, 60.815658902],
  [-78.1718576, 60.817737802],
  [-78.1718207, 60.819574702],
  [-78.1765286, 60.819915602],
  [-78.1797407, 60.818370902],
  [-78.1838506, 60.818160102],
  [-78.1862658, 60.816296802],
  [-78.19197, 60.815780002],
  [-78.1937037, 60.814892402],
  [-78.1981637, 60.814721202],
  [-78.2000642, 60.813297802],
  [-78.2132218, 60.809497902],
  [-78.220636, 60.808741602],
  [-78.2241987, 60.809024502],
  [-78.2253147, 60.809723502],
  [-78.2258827, 60.811585402],
  [-78.2231085, 60.813767102],
  [-78.22208, 60.816149902],
  [-78.22066, 60.817400002],
  [-78.2166155, 60.818420402],
  [-78.2109005, 60.821743102],
  [-78.2109613, 60.822603502],
  [-78.2121097, 60.823441702],
  [-78.2134172, 60.823102502],
  [-78.2127077, 60.823746702],
  [-78.216099, 60.824292102],
  [-78.20831, 60.826740002],
  [-78.20676, 60.826590102],
  [-78.20721, 60.827360102],
  [-78.2061599, 60.827340002],
  [-78.20444, 60.829100002],
  [-78.20339, 60.829080002],
  [-78.20499, 60.830829902],
  [-78.2063799, 60.830929902],
  [-78.20629, 60.831949902],
  [-78.20733, 60.831980002],
  [-78.2072699, 60.832740002],
  [-78.20567, 60.832970002],
  [-78.20552, 60.834760002],
  [-78.20654, 60.835030102],
  [-78.2039199, 60.834980002],
  [-78.2033601, 60.835489902],
  [-78.20543, 60.835780002],
  [-78.2043799, 60.835760002],
  [-78.2014899, 60.838770002],
  [-78.1978099, 60.838949902],
  [-78.19776, 60.839460002],
  [-78.19464, 60.839140102],
  [-78.19351, 60.840150102],
  [-78.19508, 60.840169902],
  [-78.1950101, 60.840939902],
  [-78.1965999, 60.840710102],
  [-78.19774, 60.839710102],
  [-78.19931, 60.839750002],
  [-78.19787, 60.840320002],
  [-78.1975601, 60.841760002],
  [-78.1917801, 60.841880002],
  [-78.1917099, 60.842660002],
  [-78.1901399, 60.842630002],
  [-78.19007, 60.843389902],
  [-78.1890201, 60.843370102],
  [-78.19039, 60.845700102],
  [-78.18777, 60.845650002],
  [-78.1883401, 60.848220002],
  [-78.1872201, 60.848970002],
  [-78.1874401, 60.849480102],
  [-78.1832499, 60.849399902],
  [-78.1833699, 60.850930102],
  [-78.1857099, 60.851240002],
  [-78.1852301, 60.850719902],
  [-78.1873301, 60.850760002],
  [-78.18829, 60.851680002],
  [-78.19097, 60.851090002],
  [-78.1910101, 60.850580002],
  [-78.1983501, 60.850739902],
  [-78.1977801, 60.851240002],
  [-78.1988299, 60.851260102],
  [-78.19922, 60.852800002],
  [-78.19651, 60.853770002],
  [-78.19644, 60.854540002],
  [-78.19542, 60.854260102],
  [-78.1948199, 60.855010002],
  [-78.1937801, 60.855000002],
  [-78.1937099, 60.855760002],
  [-78.19266, 60.855739902],
  [-78.19429, 60.858079902],
  [-78.1934, 60.859340002],
  [-78.1864, 60.861240002],
  [-78.1863599, 60.861749902],
  [-78.1842599, 60.861710102],
  [-78.1842201, 60.862220002],
  [-78.18317, 60.862200002],
  [-78.1831, 60.862959902],
  [-78.18098, 60.863170102],
  [-78.1797999, 60.864690002],
  [-78.17498, 60.865859902],
  [-78.1749199, 60.866380102],
  [-78.1701399, 60.867040102],
  [-78.1695501, 60.867800002],
  [-78.1663401, 60.868500102],
  [-78.1652101, 60.869240002],
  [-78.16627, 60.869270102],
  [-78.1662, 60.870030002],
  [-78.15818, 60.871660002],
  [-78.1575599, 60.872670002],
  [-78.15651, 60.872639902],
  [-78.1540199, 60.874130002],
  [-78.15419, 60.875160102],
  [-78.15314, 60.875130002],
  [-78.1514699, 60.876260002],
  [-78.1462399, 60.876010002],
  [-78.14481, 60.877260002],
  [-78.1455501, 60.877790002],
  [-78.1445, 60.877770002],
  [-78.1436501, 60.878519902],
  [-78.1438601, 60.879030002],
  [-78.14063, 60.879990002],
  [-78.14082, 60.880759902],
  [-78.1394399, 60.881500102],
  [-78.14073, 60.881790002],
  [-78.14104, 60.884089902],
  [-78.13969, 60.884580002],
  [-78.1385, 60.886089902],
  [-78.1395499, 60.886110002],
  [-78.1402299, 60.887409902],
  [-78.1392101, 60.889930002],
  [-78.14026, 60.889969902],
  [-78.13907, 60.891480002],
  [-78.1401199, 60.891500102],
  [-78.14025, 60.893040002],
  [-78.1363001, 60.896030002],
  [-78.1369901, 60.897079902],
  [-78.13492, 60.896769902],
  [-78.13536, 60.897539902],
  [-78.13269, 60.898130002],
  [-78.1295599, 60.897930002],
  [-78.12949, 60.898700002],
  [-78.1236, 60.899850102],
  [-78.1235599, 60.900360002],
  [-78.12048, 60.899529902],
  [-78.1204101, 60.900300102],
  [-78.1183101, 60.900250002],
  [-78.1186301, 60.902560002],
  [-78.1154299, 60.903010002],
  [-78.11616, 60.906610002],
  [-78.11402, 60.907070102],
  [-78.11392, 60.908099902],
  [-78.11023, 60.908270002],
  [-78.1111901, 60.909190102],
  [-78.1125, 60.909340002],
  [-78.11212, 60.910610002],
  [-78.1134101, 60.910900002],
  [-78.11358, 60.911920002],
  [-78.1105, 60.911089902],
  [-78.1104299, 60.911860102],
  [-78.1095499, 60.911920002],
  [-78.1113001, 60.913800002],
  [-78.11492, 60.914520102],
  [-78.10748, 60.915380002],
  [-78.1088, 60.918230002],
  [-78.1072199, 60.918180102],
  [-78.1062901, 60.916889902],
  [-78.10471, 60.916860102],
  [-78.1028601, 60.919889902],
  [-78.1012901, 60.919860102],
  [-78.1017, 60.921150002],
  [-78.1000999, 60.921370002],
  [-78.0996399, 60.920590002],
  [-78.09494, 60.920219902],
  [-78.0926301, 60.922490002],
  [-78.09492, 60.923310102],
  [-78.09459, 60.924070102],
  [-78.09188, 60.924900002],
  [-78.0871801, 60.924669902],
  [-78.08621, 60.923630102],
  [-78.0851601, 60.923610002],
  [-78.08292, 60.925090102],
  [-78.0839601, 60.925119902],
  [-78.08479, 60.927690002],
  [-78.0811601, 60.927099902],
  [-78.0810501, 60.928370002],
  [-78.08002, 60.928099902],
  [-78.08157, 60.930350002],
  [-78.08246, 60.930200102],
  [-78.0823901, 60.930970102],
  [-78.07613, 60.930320102],
  [-78.0771, 60.931240002],
  [-78.07866, 60.931400002],
  [-78.079, 60.933459902],
  [-78.0743901, 60.932080102],
  [-78.0738201, 60.932580002],
  [-78.07487, 60.932610002],
  [-78.0762801, 60.934420102],
  [-78.07411, 60.935140002],
  [-78.0717301, 60.938170002],
  [-78.05865, 60.937370002],
  [-78.05767, 60.936580002],
  [-78.0570501, 60.937590002],
  [-78.05545, 60.937810002],
  [-78.05535, 60.938830002],
  [-78.0542999, 60.938810002],
  [-78.05478, 60.939329902],
  [-78.0494901, 60.939600002],
  [-78.0440401, 60.941660102],
  [-78.04342, 60.942669902],
  [-78.04019, 60.943360002],
  [-78.04041, 60.943880102],
  [-78.0386199, 60.946150002],
  [-78.0393601, 60.946679902],
  [-78.03778, 60.946640102],
  [-78.0369301, 60.947390002],
  [-78.0371401, 60.947909902],
  [-78.0328399, 60.948840002],
  [-78.03277, 60.949600002],
  [-78.0306199, 60.950070002],
  [-78.02876, 60.953100102],
  [-78.0298101, 60.953119902],
  [-78.02976, 60.953630002],
  [-78.0287099, 60.953610002],
  [-78.0296901, 60.954400002],
  [-78.02863, 60.954380002],
  [-78.0284999, 60.955909902],
  [-78.0300499, 60.956200102],
  [-78.0289499, 60.956689902],
  [-78.0291, 60.957970002],
  [-78.02823, 60.958720002],
  [-78.0292601, 60.959000102],
  [-78.02821, 60.958980102],
  [-78.03023, 60.959789902],
  [-78.0301501, 60.960810002],
  [-78.02909, 60.960789902],
  [-78.0290499, 60.961300002],
  [-78.03012, 60.961070002],
  [-78.02953, 60.961820002],
  [-78.0305801, 60.961850002],
  [-78.03054, 60.962359902],
  [-78.0321401, 60.962139902],
  [-78.03299, 60.964190002],
  [-78.02565, 60.963780102],
  [-78.0256099, 60.964300002],
  [-78.0199399, 60.962880102],
  [-78.0205899, 60.961620002],
  [-78.0184899, 60.961569902],
  [-78.01763, 60.959510002],
  [-78.01508, 60.958559902],
  [-78.0097901, 60.958820002],
  [-78.00939, 60.957540102],
  [-78.0084799, 60.957860002],
  [-78.0090101, 60.958820002],
  [-78.0069001, 60.961569902],
  [-78.00795, 60.961600002],
  [-78.00762, 60.962359902],
  [-78.00996, 60.962670102],
  [-78.00931, 60.963940002],
  [-78.0129399, 60.964530002],
  [-78.0139199, 60.965320102],
  [-78.01187, 60.964760102],
  [-78.01208, 60.965280002],
  [-78.0177099, 60.966940002],
  [-78.01777, 60.969239902],
  [-78.01622, 60.968950002],
  [-78.01562, 60.969710002],
  [-78.0140399, 60.969670102],
  [-78.0141401, 60.968650002],
  [-78.01098, 60.968579902],
  [-78.01039, 60.969340102],
  [-78.0088099, 60.969290002],
  [-78.00943, 60.968290002],
  [-78.0046701, 60.968440102],
  [-78.00463, 60.968950002],
  [-78.00142, 60.969390002],
  [-78.0020001, 60.968890102],
  [-78.0000001, 60.968720002],
  [-77.9942201, 60.969490002],
  [-77.99428, 60.970000102],
  [-77.9932401, 60.970280002],
  [-77.99319, 60.969770102],
  [-77.9905899, 60.970080002],
  [-77.9901101, 60.970600002],
  [-77.99174, 60.971080002],
  [-77.9906801, 60.971100102],
  [-77.9885601, 60.973710002],
  [-77.98854, 60.976270002],
  [-77.99231, 60.977210002],
  [-77.9883699, 60.978160002],
  [-77.98727, 60.979640002],
  [-77.9936801, 60.980500002],
  [-77.99385, 60.982300002],
  [-77.9927901, 60.982320002],
  [-77.9923599, 60.983359902],
  [-77.98917, 60.983170002],
  [-77.99105, 60.986200002],
  [-77.9900001, 60.986230102],
  [-77.9900001, 60.989039902],
  [-77.99163, 60.989520002],
  [-77.99196, 60.990280002],
  [-77.9909099, 60.990300002],
  [-77.98806, 60.993699902],
  [-77.9849099, 60.993770002],
  [-77.98486, 60.993249902],
  [-77.9832599, 60.993020102],
  [-77.98443, 60.994290002],
  [-77.97616, 60.996010102],
  [-77.9763, 60.997550002],
  [-77.97317, 60.997870002],
  [-77.9732101, 60.998379902],
  [-77.9684301, 60.997970002],
  [-77.9670199, 60.999790102],
  [-77.9644301, 61.000360102],
  [-77.96451, 61.001130102],
  [-77.9624, 61.001190002],
  [-77.96194, 61.001950002],
  [-77.9603399, 61.001730002],
  [-77.9599801, 61.003530002],
  [-77.9574, 61.004090002],
  [-77.9587899, 61.007660002],
  [-77.95458, 61.007750002],
  [-77.9546501, 61.008520002],
  [-77.9531099, 61.009059902],
  [-77.9484, 61.009420002],
  [-77.94696, 61.010990002],
  [-77.94438, 61.011560002],
  [-77.94316, 61.012609902],
  [-77.9436599, 61.015169902],
  [-77.9394499, 61.015250102],
  [-77.9395201, 61.016020102],
  [-77.9368799, 61.016070002],
  [-77.93693, 61.016580102],
  [-77.9406599, 61.017010002],
  [-77.94071, 61.017530002],
  [-77.9386301, 61.017829902],
  [-77.9382199, 61.019120002],
  [-77.9324101, 61.018990002],
  [-77.93226, 61.017450002],
  [-77.9298, 61.017340002],
  [-77.9296499, 61.017770002],
  [-77.9313001, 61.018499902],
  [-77.92661, 61.019110002],
  [-77.9278501, 61.021140102],
  [-77.9257699, 61.021440002],
  [-77.9261099, 61.022200002],
  [-77.92745, 61.022420002],
  [-77.92761, 61.024210002],
  [-77.92502, 61.024780002],
  [-77.9238799, 61.023780002],
  [-77.9200701, 61.022580102],
  [-77.92003, 61.022070002],
  [-77.91625, 61.021140102],
  [-77.9174399, 61.022640002],
  [-77.9121999, 61.023010002],
  [-77.9117401, 61.023790002],
  [-77.91327, 61.023240102],
  [-77.9129799, 61.023850002],
  [-77.9219501, 61.025740002],
  [-77.92669, 61.025640002],
  [-77.9278, 61.026260102],
  [-77.92517, 61.026310002],
  [-77.9244201, 61.026839902],
  [-77.9247301, 61.027350002],
  [-77.92267, 61.027900002],
  [-77.92256, 61.026630002],
  [-77.91934, 61.026059902],
  [-77.9099601, 61.027410002],
  [-77.9116399, 61.028399902],
  [-77.9095101, 61.028190002],
  [-77.9077599, 61.026430002],
  [-77.9068699, 61.028250102],
  [-77.90369, 61.028059902],
  [-77.90357, 61.029600102],
  [-77.9060601, 61.030949902],
  [-77.9103199, 61.031370102],
  [-77.91082, 61.030980002],
  [-77.90923, 61.031010002],
  [-77.9091901, 61.030499902],
  [-77.9149601, 61.030120002],
  [-77.91501, 61.030630002],
  [-77.9160601, 61.030609902],
  [-77.91615, 61.031630002],
  [-77.9113901, 61.031480102],
  [-77.9111701, 61.031990002],
  [-77.9144299, 61.032949902],
  [-77.91481, 61.034220002],
  [-77.90748, 61.034890002],
  [-77.90781, 61.035650002],
  [-77.90944, 61.036120002],
  [-77.91037, 61.037640002],
  [-77.90616, 61.037739902],
  [-77.9086, 61.039389902],
  [-77.9131801, 61.039499902],
  [-77.91269, 61.039900002],
  [-77.9137699, 61.040130002],
  [-77.90958, 61.040480102],
  [-77.9095399, 61.039970002],
  [-77.90737, 61.039499902],
  [-77.90503, 61.036990002],
  [-77.90233, 61.036270102],
  [-77.9022801, 61.035770002],
  [-77.9007, 61.035800002],
  [-77.90082, 61.037080002],
  [-77.9018699, 61.037059902],
  [-77.90091, 61.038100002],
  [-77.8998301, 61.037870002],
  [-77.8999, 61.038640002],
  [-77.89521, 61.039250102],
  [-77.89478, 61.040279902],
  [-77.8926201, 61.039820102],
  [-77.8921601, 61.040600102],
  [-77.89323, 61.040570002],
  [-77.89326, 61.041090002],
  [-77.89168, 61.041120002],
  [-77.89233, 61.042380102],
  [-77.88868, 61.042980002],
  [-77.8886101, 61.042210002],
  [-77.8838401, 61.041930102],
  [-77.88025, 61.043150102],
  [-77.8798201, 61.044189902],
  [-77.8767299, 61.045020002],
  [-77.8758599, 61.047090002],
  [-77.8732801, 61.047660002],
  [-77.87233, 61.048959902],
  [-77.8677101, 61.050460002],
  [-77.8655901, 61.050380102],
  [-77.8657101, 61.051660002],
  [-77.86831, 61.051220002],
  [-77.871, 61.051800002],
  [-77.8716001, 61.052560002],
  [-77.87055, 61.052590102],
  [-77.8696101, 61.053880002],
  [-77.8665199, 61.054839902],
  [-77.85854, 61.054110002],
  [-77.85902, 61.053590102],
  [-77.8579599, 61.053610102],
  [-77.85778, 61.051570002],
  [-77.85402, 61.050880002],
  [-77.85366, 61.049859902],
  [-77.8551599, 61.047949902],
  [-77.8565801, 61.044169902],
  [-77.85553, 61.044189902],
  [-77.85546, 61.043430002],
  [-77.8570401, 61.043389902],
  [-77.85688, 61.041600102],
  [-77.85001, 61.041490102],
  [-77.8500499, 61.042000002],
  [-77.8463899, 61.042340002],
  [-77.84701, 61.043350002],
  [-77.8449399, 61.043900002],
  [-77.8448299, 61.042629902],
  [-77.8443899, 61.043660002],
  [-77.84332, 61.043430002],
  [-77.8433899, 61.044189902],
  [-77.84233, 61.044210002],
  [-77.84221, 61.045750002],
  [-77.8431401, 61.047270102],
  [-77.84419, 61.047250002],
  [-77.8437299, 61.048030002],
  [-77.8473999, 61.047690002],
  [-77.84911, 61.049189902],
  [-77.8475099, 61.048969902],
  [-77.84708, 61.050000002],
  [-77.8454899, 61.050040102],
  [-77.84554, 61.050550002],
  [-77.84179, 61.049990002],
  [-77.83707, 61.050340002],
  [-77.8288299, 61.052690002],
  [-77.82932, 61.055240002],
  [-77.8286901, 61.057050102],
  [-77.83109, 61.057250002],
  [-77.83242, 61.060309902],
  [-77.8318101, 61.062360002],
  [-77.8286901, 61.062940102],
  [-77.82728, 61.067830102],
  [-77.82841, 61.071649902],
  [-77.8273501, 61.071670002],
  [-77.828, 61.072940102],
  [-77.82589, 61.072979902],
  [-77.8265801, 61.074890002],
  [-77.8326901, 61.075140002],
  [-77.8325801, 61.076940102],
  [-77.83153, 61.076960102],
  [-77.8322799, 61.079500102],
  [-77.83334, 61.079480002],
  [-77.8333501, 61.082550002],
  [-77.83077, 61.086190102],
  [-77.8297099, 61.086209902],
  [-77.8267099, 61.091140002],
  [-77.8286099, 61.091600002],
  [-77.8281499, 61.092390002],
  [-77.8260399, 61.092439902],
  [-77.8263699, 61.093199902],
  [-77.82521, 61.095010002],
  [-77.8241499, 61.095030002],
  [-77.8248199, 61.096549902],
  [-77.8232301, 61.096590002],
  [-77.8236801, 61.098630102],
  [-77.82076, 61.101500002],
  [-77.81899, 61.105380002],
  [-77.82108, 61.110980102],
  [-77.8230299, 61.112080102],
  [-77.8272301, 61.111740102],
  [-77.8278199, 61.112370002],
  [-77.82676, 61.112390002],
  [-77.82696, 61.114690002],
  [-77.8280201, 61.114669902],
  [-77.8279001, 61.116219902],
  [-77.82845, 61.116449902],
  [-77.8289399, 61.118999902],
  [-77.82788, 61.119020002],
  [-77.82873, 61.122590002],
  [-77.82666, 61.123150002],
  [-77.8286999, 61.128219902],
  [-77.82765, 61.128250002],
  [-77.8266999, 61.129549902],
  [-77.82565, 61.129569902],
  [-77.8253, 61.131620002],
  [-77.8211, 61.131970102],
  [-77.82117, 61.132730002],
  [-77.82011, 61.132760102],
  [-77.82044, 61.133520002],
  [-77.8228299, 61.133590002],
  [-77.82342, 61.134219902],
  [-77.8218299, 61.134260002],
  [-77.82111, 61.135040002],
  [-77.82363, 61.136520002],
  [-77.82096, 61.136320102],
  [-77.82164, 61.138100102],
  [-77.8226999, 61.138080002],
  [-77.82277, 61.138850002],
  [-77.82544, 61.139050002],
  [-77.82711, 61.143110102],
  [-77.82518, 61.148009902],
  [-77.8184, 61.149180002],
  [-77.81722, 61.147920002],
  [-77.8145799, 61.147980102],
  [-77.81517, 61.148730002],
  [-77.8141399, 61.149009902],
  [-77.81407, 61.148239902],
  [-77.8124899, 61.148280002],
  [-77.81132, 61.147019902],
  [-77.8071499, 61.147880102],
  [-77.8068901, 61.148820002],
  [-77.8120001, 61.148799902],
  [-77.81207, 61.149569902],
  [-77.81051, 61.149840002],
  [-77.8105799, 61.150620002],
  [-77.80798, 61.151190002],
  [-77.80687, 61.153770102],
  [-77.8075, 61.154780102],
  [-77.8064899, 61.155310002],
  [-77.8077099, 61.157080002],
  [-77.80761, 61.158870002],
  [-77.80654, 61.158899902],
  [-77.8061301, 61.160180002],
  [-77.80454, 61.160220102],
  [-77.8016601, 61.163610002],
  [-77.7980001, 61.164070002],
  [-77.79231, 61.165850002],
  [-77.79127, 61.168940002],
  [-77.79276, 61.170699902],
  [-77.7916899, 61.170730002],
  [-77.79176, 61.171490002],
  [-77.7896999, 61.172050002],
  [-77.78964, 61.171540002],
  [-77.78753, 61.171579902],
  [-77.78708, 61.172359902],
  [-77.7849901, 61.172909902],
  [-77.78495, 61.172400002],
  [-77.7801199, 61.171730002],
  [-77.7803, 61.173780102],
  [-77.7813599, 61.173750002],
  [-77.78142, 61.174520002],
  [-77.78862, 61.174900102],
  [-77.7884599, 61.176170002],
  [-77.78315, 61.176020102],
  [-77.7841199, 61.178050002],
  [-77.7834401, 61.179340102],
  [-77.77971, 61.179170002],
  [-77.77662, 61.177180002],
  [-77.77505, 61.174400002],
  [-77.7676601, 61.174799902],
  [-77.7677, 61.175310002],
  [-77.7630099, 61.176180002],
  [-77.7626799, 61.172350102],
  [-77.75228, 61.172640002],
  [-77.75346, 61.173050002],
  [-77.7529801, 61.173570102],
  [-77.75395, 61.175599902],
  [-77.75551, 61.175310002],
  [-77.7553399, 61.176340102],
  [-77.7573001, 61.177579902],
  [-77.75413, 61.177640002],
  [-77.75393, 61.178410002],
  [-77.7555401, 61.179570102],
  [-77.7602001, 61.180330002],
  [-77.7599999, 61.181100002],
  [-77.76058, 61.181610002],
  [-77.76007, 61.181870002],
  [-77.76103, 61.183770002],
  [-77.76372, 61.184230102],
  [-77.76582, 61.183929902],
  [-77.76629, 61.183280002],
  [-77.76897, 61.183610002],
  [-77.77037, 61.184479902],
  [-77.77139, 61.187020102],
  [-77.76347, 61.187430002],
  [-77.76304, 61.188719902],
  [-77.75873, 61.187790102],
  [-77.7577799, 61.189090002],
  [-77.7604201, 61.189029902],
  [-77.76129, 61.189780002],
  [-77.7610901, 61.190560102],
  [-77.7579801, 61.191390002],
  [-77.75917, 61.192900102],
  [-77.75814, 61.193180002],
  [-77.75807, 61.192410002],
  [-77.7570099, 61.192430002],
  [-77.75529, 61.190929902],
  [-77.7488, 61.189530002],
  [-77.7475599, 61.187500002],
  [-77.7465, 61.187520002],
  [-77.7464399, 61.186760002],
  [-77.74104, 61.185709902],
  [-77.73737, 61.186170002],
  [-77.73594, 61.187990002],
  [-77.73727, 61.188220002],
  [-77.7381901, 61.189479902],
  [-77.73714, 61.189630002],
  [-77.7311901, 61.188350102],
  [-77.73168, 61.187819902],
  [-77.7300701, 61.187599902],
  [-77.7299899, 61.186840002],
  [-77.72515, 61.185910102],
  [-77.7256201, 61.185390002],
  [-77.7218501, 61.184440002],
  [-77.7209799, 61.186760002],
  [-77.7193901, 61.186790102],
  [-77.71944, 61.187300002],
  [-77.71838, 61.187320002],
  [-77.7174101, 61.188369902],
  [-77.7142901, 61.189200002],
  [-77.71434, 61.189709902],
  [-77.7132801, 61.189730002],
  [-77.71335, 61.190499902],
  [-77.7117599, 61.190530002],
  [-77.7126, 61.191020102],
  [-77.71244, 61.192310002],
  [-77.7167, 61.192479902],
  [-77.71526, 61.194300002],
  [-77.7141801, 61.194060002],
  [-77.71423, 61.194580102],
  [-77.71213, 61.194870002],
  [-77.7069401, 61.202400002],
  [-77.708, 61.202379902],
  [-77.71114, 61.204880002],
  [-77.71155, 61.206660002],
  [-77.7126101, 61.206630002],
  [-77.7122099, 61.208180002],
  [-77.7111601, 61.208210002],
  [-77.71071, 61.209240102],
  [-77.71177, 61.209220002],
  [-77.71215, 61.210489902],
  [-77.71113, 61.211020102],
  [-77.7132801, 61.214430002],
  [-77.7172901, 61.214740002],
  [-77.7183901, 61.218300002],
  [-77.72005, 61.219040102],
  [-77.7254199, 61.219700102],
  [-77.7251999, 61.220210002],
  [-77.7265499, 61.220570002],
  [-77.7291901, 61.220389902],
  [-77.72978, 61.221020002],
  [-77.73061, 61.220450002],
  [-77.73023, 61.220110002],
  [-77.73213, 61.220590102],
  [-77.7327301, 61.224410002],
  [-77.73705, 61.225220002],
  [-77.7402199, 61.225030102],
  [-77.74059, 61.223230002],
  [-77.7416499, 61.223210002],
  [-77.74158, 61.222440002],
  [-77.7479701, 61.222570002],
  [-77.7482299, 61.223499902],
  [-77.75071, 61.223540002],
  [-77.7524101, 61.224910002],
  [-77.75349, 61.225020002],
  [-77.7530099, 61.225540002],
  [-77.7584201, 61.226710102],
  [-77.7624201, 61.229959902],
  [-77.76349, 61.229940102],
  [-77.7635699, 61.230959902],
  [-77.76628, 61.231530002],
  [-77.76992, 61.236720102],
  [-77.7636, 61.237370102],
  [-77.76472, 61.237969902],
  [-77.7716699, 61.238470002],
  [-77.7620901, 61.238279902],
  [-77.75793, 61.239260102],
  [-77.75762, 61.238760002],
  [-77.75893, 61.238600102],
  [-77.76013, 61.237169902],
  [-77.75918, 61.235399902],
  [-77.75704, 61.235189902],
  [-77.75604, 61.235969902],
  [-77.7553399, 61.233940102],
  [-77.7532101, 61.233980002],
  [-77.75385, 61.235250002],
  [-77.7537899, 61.237560002],
  [-77.7527799, 61.238079902],
  [-77.7536699, 61.239220002],
  [-77.75684, 61.239030002],
  [-77.75691, 61.239800002],
  [-77.75585, 61.239820102],
  [-77.75751, 61.240550002],
  [-77.7575699, 61.241320002],
  [-77.75651, 61.241340002],
  [-77.75915, 61.244100002],
  [-77.75815, 61.244890002],
  [-77.75973, 61.247680002],
  [-77.7565599, 61.248000002],
  [-77.75663, 61.248760002],
  [-77.75239, 61.248849902],
  [-77.75296, 61.249350002],
  [-77.75137, 61.249380102],
  [-77.7520099, 61.250650002],
  [-77.7546401, 61.250340002],
  [-77.7553101, 61.251859902],
  [-77.75316, 61.251650002],
  [-77.75239, 61.250979902],
  [-77.7477699, 61.250739902],
  [-77.74781, 61.251250002],
  [-77.7467501, 61.251270102],
  [-77.7458, 61.252570002],
  [-77.7468701, 61.252550002],
  [-77.7505201, 61.254780002],
  [-77.7498799, 61.256590002],
  [-77.7461801, 61.256910002],
  [-77.7439801, 61.259000002],
  [-77.74772, 61.265330002],
  [-77.7475, 61.265850102],
  [-77.75427, 61.267240002],
  [-77.7545699, 61.267749902],
  [-77.7525, 61.268299902],
  [-77.75261, 61.269580002],
  [-77.75526, 61.269529902],
  [-77.7551099, 61.270810002],
  [-77.75761, 61.272040002],
  [-77.7646, 61.272920002],
  [-77.76584, 61.274950102],
  [-77.76852, 61.275150002],
  [-77.7738801, 61.281690002],
  [-77.7686601, 61.282820002],
  [-77.76849, 61.286920002],
  [-77.76482, 61.287510102],
  [-77.7648899, 61.288280002],
  [-77.76117, 61.288350002],
  [-77.7602399, 61.289910002],
  [-77.76237, 61.289869902],
  [-77.76093, 61.291690002],
  [-77.7619901, 61.291680002],
  [-77.7612399, 61.292190102],
  [-77.76162, 61.293460002],
  [-77.7626799, 61.293439902],
  [-77.7619801, 61.294480002],
  [-77.7623101, 61.295240002],
  [-77.76125, 61.295260002],
  [-77.76105, 61.296050002],
  [-77.7628, 61.297779902],
  [-77.76071, 61.301420102],
  [-77.75915, 61.301710002],
  [-77.75949, 61.305540102],
  [-77.7605499, 61.305520102],
  [-77.7606599, 61.306800002],
  [-77.7633299, 61.306870102],
  [-77.7643201, 61.305960002],
  [-77.7685699, 61.305870102],
  [-77.7686401, 61.306640102],
  [-77.7697, 61.306610002],
  [-77.7697701, 61.307380002],
  [-77.77563, 61.307520102],
  [-77.76715, 61.307950002],
  [-77.7672399, 61.308970102],
  [-77.7683101, 61.308950002],
  [-77.76782, 61.309470002],
  [-77.7688801, 61.309449902],
  [-77.76893, 61.309960002],
  [-77.77048, 61.309410102],
  [-77.7723101, 61.312050002],
  [-77.7782399, 61.312840002],
  [-77.77828, 61.313349902],
  [-77.7665301, 61.312950002],
  [-77.76327, 61.312250002],
  [-77.75115, 61.313650102],
  [-77.75124, 61.314669902],
  [-77.7565499, 61.314559902],
  [-77.75618, 61.316360002],
  [-77.7683399, 61.315349902],
  [-77.76838, 61.315860102],
  [-77.7726401, 61.315769902],
  [-77.7726799, 61.316290002],
  [-77.7668601, 61.316659902],
  [-77.76628, 61.316160002],
  [-77.7663499, 61.316920002],
  [-77.7647501, 61.316950002],
  [-77.7647899, 61.317470002],
  [-77.7573399, 61.317490002],
  [-77.7540999, 61.316920002],
  [-77.7538999, 61.317690002],
  [-77.75639, 61.318789902],
  [-77.75851, 61.318620002],
  [-77.7586, 61.319640102],
  [-77.7596401, 61.319360002],
  [-77.75973, 61.320390002],
  [-77.76347, 61.320569902],
  [-77.7636699, 61.319789902],
  [-77.7681099, 61.318810002],
  [-77.76948, 61.319160002],
  [-77.7704301, 61.320930002],
  [-77.76503, 61.320150002],
  [-77.7639801, 61.320300002],
  [-77.76403, 61.320810002],
  [-77.75929, 61.321420102],
  [-77.7598899, 61.322170002],
  [-77.75674, 61.322750102],
  [-77.7568799, 61.324280002],
  [-77.7616799, 61.324440102],
  [-77.76216, 61.323789902],
  [-77.76374, 61.323630002],
  [-77.7637, 61.323129902],
  [-77.76529, 61.323090102],
  [-77.76525, 61.322580002],
  [-77.77575, 61.320830002],
  [-77.7757, 61.320320102],
  [-77.7773101, 61.320410002],
  [-77.7784499, 61.321280002],
  [-77.77739, 61.321310102],
  [-77.7775, 61.322580002],
  [-77.7764499, 61.322600002],
  [-77.77648, 61.323129902],
  [-77.77174, 61.323720002],
  [-77.77293, 61.325109902],
  [-77.7772399, 61.325660102],
  [-77.7773101, 61.326430102],
  [-77.7752001, 61.326730002],
  [-77.77381, 61.329060002],
  [-77.77794, 61.330510002],
  [-77.7777801, 61.331789902],
  [-77.7741199, 61.332380002],
  [-77.7726899, 61.334459902],
  [-77.76841, 61.334290002],
  [-77.76793, 61.334810002],
  [-77.76781, 61.333530002],
  [-77.7625599, 61.334410002],
  [-77.7614399, 61.336740002],
  [-77.76385, 61.336940002],
  [-77.7638999, 61.337449902],
  [-77.76651, 61.336890102],
  [-77.7666501, 61.338420002],
  [-77.7603299, 61.339320102],
  [-77.76149, 61.340459902],
  [-77.7642199, 61.341040002],
  [-77.76426, 61.341540102],
  [-77.76474, 61.341019902],
  [-77.7716699, 61.341009902],
  [-77.7754499, 61.341569902],
  [-77.77338, 61.342249902],
  [-77.77175, 61.341899902],
  [-77.7708899, 61.344220102],
  [-77.76983, 61.344249902],
  [-77.76936, 61.345019902],
  [-77.76827, 61.344789902],
  [-77.76894, 61.346310002],
  [-77.7653299, 61.347670102],
  [-77.76515, 61.348689902],
  [-77.7675501, 61.348640002],
  [-77.7677, 61.350430002],
  [-77.76296, 61.351040002],
  [-77.7636401, 61.352560102],
  [-77.7684301, 61.352720002],
  [-77.7685699, 61.351180002],
  [-77.77039, 61.350630002],
  [-77.7709901, 61.351390002],
  [-77.76993, 61.351410002],
  [-77.77108, 61.352410002],
  [-77.7726401, 61.352000102],
  [-77.77427, 61.352349902],
  [-77.7743201, 61.352860002],
  [-77.77695, 61.352420002],
  [-77.7780901, 61.353290002],
  [-77.7674499, 61.353510002],
  [-77.7680199, 61.354010102],
  [-77.76696, 61.354029902],
  [-77.76758, 61.355039902],
  [-77.7713201, 61.355100002],
  [-77.77215, 61.355459902],
  [-77.77195, 61.356239902],
  [-77.77464, 61.356440102],
  [-77.7746799, 61.356950002],
  [-77.76728, 61.357610002],
  [-77.76761, 61.358369902],
  [-77.76685, 61.358900102],
  [-77.76792, 61.358880002],
  [-77.7677, 61.359390002],
  [-77.7685401, 61.359890102],
  [-77.7669701, 61.360180002],
  [-77.7663499, 61.362240102],
  [-77.77038, 61.362540002],
  [-77.7716699, 61.362130102],
  [-77.77286, 61.360440102],
  [-77.7739099, 61.360290002],
  [-77.7734201, 61.360809902],
  [-77.77449, 61.360790102],
  [-77.77628, 61.362929902],
  [-77.7779099, 61.363280002],
  [-77.76529, 61.365330102],
  [-77.7659701, 61.366850002],
  [-77.76804, 61.366300002],
  [-77.7678701, 61.367330102],
  [-77.77194, 61.368139902],
  [-77.7725301, 61.368770002],
  [-77.7683499, 61.369750002],
  [-77.7624599, 61.369489902],
  [-77.7615301, 61.371039902],
  [-77.76259, 61.371020102],
  [-77.76248, 61.372809902],
  [-77.7633299, 61.373440002],
  [-77.76492, 61.373280002],
  [-77.7649701, 61.373790102],
  [-77.76761, 61.373479902],
  [-77.76826, 61.374740002],
  [-77.76559, 61.374800102],
  [-77.7656401, 61.375310002],
  [-77.76295, 61.375100002],
  [-77.76291, 61.374599902],
  [-77.7592001, 61.374929902],
  [-77.7603299, 61.375670102],
  [-77.75929, 61.375950002],
  [-77.7635301, 61.375610002],
  [-77.76369, 61.377400002],
  [-77.76262, 61.377420002],
  [-77.76274, 61.378699902],
  [-77.7637701, 61.378420002],
  [-77.76382, 61.378929902],
  [-77.7691001, 61.378300002],
  [-77.76952, 61.377020102],
  [-77.7716601, 61.376980002],
  [-77.77175, 61.378000002],
  [-77.7706799, 61.378010102],
  [-77.76971, 61.379070002],
  [-77.77292, 61.379259902],
  [-77.7728801, 61.378740002],
  [-77.7750001, 61.378570102],
  [-77.7785699, 61.376699902],
  [-77.78226, 61.376249902],
  [-77.78207, 61.377020102],
  [-77.78639, 61.377699902],
  [-77.7862399, 61.378980002],
  [-77.78417, 61.379540002],
  [-77.7817701, 61.382660002],
  [-77.78283, 61.382640002],
  [-77.78263, 61.384350102],
  [-77.7841101, 61.385039902],
  [-77.7873201, 61.385110002],
  [-77.78727, 61.384599902],
  [-77.78886, 61.384430002],
  [-77.7900099, 61.385310002],
  [-77.78308, 61.385450002],
  [-77.7828801, 61.386220002],
  [-77.78528, 61.386180002],
  [-77.7858999, 61.387180002],
  [-77.78486, 61.387460102],
  [-77.78428, 61.386960002],
  [-77.78282, 61.388530002],
  [-77.7817601, 61.388550002],
  [-77.7810199, 61.389330002],
  [-77.7813599, 61.390090002],
  [-77.7823301, 61.389049902],
  [-77.78551, 61.388730002],
  [-77.7854599, 61.388220002],
  [-77.78753, 61.387410002],
  [-77.7851199, 61.393340002],
  [-77.78561, 61.395890002],
  [-77.7866799, 61.395870002],
  [-77.7873499, 61.397400002],
  [-77.7838801, 61.397470102],
  [-77.7842, 61.401040102],
  [-77.7882499, 61.401470102],
  [-77.7877601, 61.402000002],
  [-77.7904301, 61.401939902],
  [-77.78727, 61.402520002],
  [-77.7876501, 61.403800102],
  [-77.7927999, 61.404580102],
  [-77.7955601, 61.405690102],
  [-77.7953401, 61.406190002],
  [-77.79674, 61.406930102],
  [-77.7849199, 61.406150102],
  [-77.7836601, 61.406939902],
  [-77.78331, 61.409000002],
  [-77.7847999, 61.410760002],
  [-77.78373, 61.410770002],
  [-77.7837801, 61.411289902],
  [-77.78596, 61.411760002],
  [-77.7858999, 61.411250102],
  [-77.7885601, 61.410939902],
  [-77.7883401, 61.411460002],
  [-77.7923699, 61.411760002],
  [-77.79296, 61.412389902],
  [-77.7892499, 61.412719902],
  [-77.7890299, 61.413230002],
  [-77.79738, 61.414090002],
  [-77.79617, 61.415389902],
  [-77.7970101, 61.415890002],
  [-77.79541, 61.415920102],
  [-77.79806, 61.418680002],
  [-77.79784, 61.419200002],
  [-77.7994401, 61.419160102],
  [-77.8006701, 61.420930102],
  [-77.8033599, 61.421130002],
  [-77.80341, 61.421640002],
  [-77.8050399, 61.422000002],
  [-77.8076999, 61.421810102],
  [-77.80774, 61.422320002],
  [-77.8066701, 61.422340002],
  [-77.80674, 61.423110002],
  [-77.8015, 61.424240002],
  [-77.8021, 61.425000002],
  [-77.8000199, 61.425550002],
  [-77.80061, 61.426310002],
  [-77.80221, 61.426279902],
  [-77.8021499, 61.425509902],
  [-77.8032201, 61.425480102],
  [-77.8031101, 61.427279902],
  [-77.8044699, 61.427509902],
  [-77.80451, 61.428020002],
  [-77.80529, 61.427749902],
  [-77.80499, 61.427240002],
  [-77.80764, 61.427189902],
  [-77.80827, 61.428200002],
  [-77.8066701, 61.428230002],
  [-77.8079199, 61.430250002],
  [-77.80943, 61.429200002],
  [-77.81007, 61.430470002],
  [-77.80799, 61.431010002],
  [-77.80884, 61.431519902],
  [-77.8087099, 61.433050102],
  [-77.81619, 61.433160102],
  [-77.8162499, 61.433670002],
  [-77.8146801, 61.434069902],
  [-77.81096, 61.434289902],
  [-77.81105, 61.435309902],
  [-77.8100001, 61.435590002],
  [-77.8122201, 61.436440002],
  [-77.81172, 61.436830102],
  [-77.8128001, 61.436940102],
  [-77.81455, 61.438570002],
  [-77.8135, 61.438840102],
  [-77.8154, 61.439060102],
  [-77.8160399, 61.440330002],
  [-77.81963, 61.441529902],
  [-77.8174899, 61.441580002],
  [-77.8170101, 61.442100002],
  [-77.8191599, 61.442309902],
  [-77.81921, 61.442820102],
  [-77.8155, 61.443160102],
  [-77.81554, 61.443670002],
  [-77.8139199, 61.443440002],
  [-77.8161101, 61.446979902],
  [-77.81598, 61.448519902],
  [-77.8170499, 61.448500102],
  [-77.81277, 61.451409902],
  [-77.8143501, 61.454189902],
  [-77.81307, 61.454730102],
  [-77.81407, 61.457010002],
  [-77.8151399, 61.456989902],
  [-77.81577, 61.458000002],
  [-77.81419, 61.458290102],
  [-77.8124601, 61.459859902],
  [-77.8127901, 61.460620102],
  [-77.81386, 61.460600002],
  [-77.8144699, 61.461350002],
  [-77.8134, 61.461380002],
  [-77.8140001, 61.462130002],
  [-77.8129299, 61.462150002],
  [-77.8130201, 61.463180102],
  [-77.8041, 61.465150002],
  [-77.80276, 61.462110002],
  [-77.8006, 61.461900002],
  [-77.7993401, 61.462690002],
  [-77.79965, 61.463189902],
  [-77.79729, 61.463759902],
  [-77.79763, 61.464520102],
  [-77.7944, 61.464330002],
  [-77.7944699, 61.465099902],
  [-77.79287, 61.465130002],
  [-77.79298, 61.466410102],
  [-77.79084, 61.466450002],
  [-77.79094, 61.467470002],
  [-77.7844599, 61.466830002],
  [-77.78385, 61.466080102],
  [-77.7794699, 61.464890002],
  [-77.77886, 61.464130002],
  [-77.7725, 61.464780002],
  [-77.7725501, 61.465290102],
  [-77.7666401, 61.465020002],
  [-77.7668781, 61.464370902],
  [-77.7707952, 61.463559002],
  [-77.7699488, 61.462762602],
  [-77.7730524, 61.459766502],
  [-77.7759508, 61.460317902],
  [-77.778567, 61.459545902],
  [-77.7799264, 61.458418402],
  [-77.7753352, 61.458075302],
  [-77.7754891, 61.457058102],
  [-77.7720264, 61.455060302],
  [-77.7709748, 61.453663102],
  [-77.7760277, 61.451138002],
  [-77.775566, 61.449838602],
  [-77.7626901, 61.446829002],
  [-77.7589901, 61.445079902],
  [-77.7456718, 61.445112602],
  [-77.7446458, 61.443764002],
  [-77.7396955, 61.442586902],
  [-77.7370793, 61.440864102],
  [-77.7257499, 61.438069902],
  [-77.7230801, 61.438120002],
  [-77.7221901, 61.437120002],
  [-77.7239601, 61.435930102],
  [-77.73126, 61.433859902],
  [-77.73171, 61.432830102],
  [-77.73805, 61.431930102],
  [-77.73801, 61.428350002],
  [-77.7370899, 61.427079902],
  [-77.7344201, 61.427140002],
  [-77.73437, 61.426629902],
  [-77.7327301, 61.426150102],
  [-77.7327699, 61.426660002],
  [-77.73171, 61.426690002],
  [-77.7317699, 61.427450002],
  [-77.7301901, 61.427739902],
  [-77.7296201, 61.427240002],
  [-77.7281099, 61.428289902],
  [-77.7280601, 61.427780002],
  [-77.72646, 61.427810102],
  [-77.72526, 61.426299902],
  [-77.72739, 61.426260102],
  [-77.7267501, 61.424990002],
  [-77.72192, 61.424830102],
  [-77.7218501, 61.423810102],
  [-77.7245399, 61.424140002],
  [-77.72413, 61.422490102],
  [-77.7258699, 61.421040102],
  [-77.73545, 61.420590102],
  [-77.73593, 61.419940102],
  [-77.7369899, 61.419920102],
  [-77.7369, 61.418900002],
  [-77.7341901, 61.418440002],
  [-77.7329899, 61.416930102],
  [-77.71813, 61.418120002],
  [-77.70811, 61.419600102],
  [-77.70377, 61.418800002],
  [-77.70345, 61.421360002],
  [-77.7024099, 61.421640002],
  [-77.70225, 61.419849902],
  [-77.7006499, 61.419880002],
  [-77.70059, 61.419110002],
  [-77.70192, 61.419090002],
  [-77.70037, 61.416560002],
  [-77.69603, 61.415870002],
  [-77.69543, 61.415120002],
  [-77.69701, 61.414829902],
  [-77.6948401, 61.414360102],
  [-77.69532, 61.413829902],
  [-77.69426, 61.413860002],
  [-77.6925299, 61.412360102],
  [-77.68978, 61.411520002],
  [-77.6752899, 61.410650002],
  [-77.67469, 61.409900002],
  [-77.68698, 61.410040102],
  [-77.6873999, 61.408619902],
  [-77.6884801, 61.408860002],
  [-77.6884199, 61.408090002],
  [-77.6894901, 61.408069902],
  [-77.6893801, 61.406790002],
  [-77.68831, 61.406810102],
  [-77.68824, 61.406049902],
  [-77.6829699, 61.406660002],
  [-77.68389, 61.405110002],
  [-77.68603, 61.405069902],
  [-77.68598, 61.404560002],
  [-77.68491, 61.404580102],
  [-77.6848201, 61.403550002],
  [-77.6821601, 61.403609902],
  [-77.6828499, 61.402310002],
  [-77.68198, 61.401560002],
  [-77.6766099, 61.401159902],
  [-77.67665, 61.401670002],
  [-77.6702501, 61.401790002],
  [-77.66713, 61.399550002],
  [-77.66786, 61.398770002],
  [-77.6689499, 61.399000002],
  [-77.66935, 61.397460102],
  [-77.67042, 61.397440002],
  [-77.67111, 61.396140102],
  [-77.6729301, 61.395599902],
  [-77.67197, 61.394680102],
  [-77.67075, 61.395130102],
  [-77.67045, 61.394609902],
  [-77.6759499, 61.393489902],
  [-77.67358, 61.390719902],
  [-77.6725099, 61.390740002],
  [-77.67397, 61.389049902],
  [-77.67764, 61.388220002],
  [-77.67811, 61.387560002],
  [-77.68924, 61.386570102],
  [-77.6891899, 61.386060002],
  [-77.69368, 61.385460102],
  [-77.69492, 61.384410002],
  [-77.6911501, 61.383970002],
  [-77.6905299, 61.382960002],
  [-77.68676, 61.382520002],
  [-77.68613, 61.381520002],
  [-77.6872, 61.381489902],
  [-77.68711, 61.380470102],
  [-77.68611, 61.381259902],
  [-77.67654, 61.381699902],
  [-77.67613, 61.382990002],
  [-77.6750599, 61.383010102],
  [-77.6745901, 61.383790102],
  [-77.6767399, 61.384000002],
  [-77.67679, 61.384510002],
  [-77.67522, 61.384929902],
  [-77.66935, 61.384910102],
  [-77.66977, 61.383630002],
  [-77.66713, 61.383929902],
  [-77.6682799, 61.384939902],
  [-77.65443, 61.385210002],
  [-77.6543899, 61.384699902],
  [-77.64798, 61.384690102],
  [-77.63486, 61.387379902],
  [-77.6349199, 61.387890002],
  [-77.63175, 61.388460102],
  [-77.63128, 61.389240102],
  [-77.62553, 61.390630002],
  [-77.62506, 61.391420002],
  [-77.6234799, 61.391690102],
  [-77.62353, 61.392210002],
  [-77.6219299, 61.392240102],
  [-77.6225701, 61.393499902],
  [-77.6162599, 61.394650002],
  [-77.6153, 61.395949902],
  [-77.6142301, 61.395970002],
  [-77.6143, 61.396740002],
  [-77.6132301, 61.396760002],
  [-77.61184, 61.399340002],
  [-77.60872, 61.400289902],
  [-77.6017999, 61.400560002],
  [-77.6003301, 61.402120002],
  [-77.6093301, 61.401180002],
  [-77.60928, 61.400670002],
  [-77.6108801, 61.400640002],
  [-77.61084, 61.400130102],
  [-77.61318, 61.399320002],
  [-77.61386, 61.397900002],
  [-77.62128, 61.397230002],
  [-77.62398, 61.397570102],
  [-77.62518, 61.399210002],
  [-77.62685, 61.399949902],
  [-77.6322, 61.400100002],
  [-77.6330499, 61.400740002],
  [-77.63232, 61.401509902],
  [-77.6312499, 61.401530002],
  [-77.63129, 61.402049902],
  [-77.6284, 61.402609902],
  [-77.63028, 61.405900002],
  [-77.6316999, 61.406900002],
  [-77.6398101, 61.408020002],
  [-77.63987, 61.408790002],
  [-77.6495901, 61.410000002],
  [-77.64919, 61.408350002],
  [-77.65208, 61.407910102],
  [-77.6537199, 61.408260102],
  [-77.65376, 61.408770002],
  [-77.65677, 61.408570002],
  [-77.65609, 61.407959902],
  [-77.65688, 61.407810102],
  [-77.65756, 61.409460002],
  [-77.66022, 61.409410002],
  [-77.6602799, 61.410059902],
  [-77.6646001, 61.410609902],
  [-77.6662601, 61.411340002],
  [-77.66577, 61.411860002],
  [-77.6721501, 61.411480102],
  [-77.6743801, 61.412460002],
  [-77.67442, 61.412980002],
  [-77.67602, 61.412939902],
  [-77.6773701, 61.416250102],
  [-77.68536, 61.415959902],
  [-77.69059, 61.418710102],
  [-77.69147, 61.418650002],
  [-77.69203, 61.419030102],
  [-77.6906199, 61.419140102],
  [-77.6902999, 61.420600102],
  [-77.69114, 61.421090002],
  [-77.6890501, 61.421650002],
  [-77.6966201, 61.422650002],
  [-77.69692, 61.423030102],
  [-77.6959, 61.423560002],
  [-77.69623, 61.424330002],
  [-77.6951601, 61.424340002],
  [-77.69625, 61.424580002],
  [-77.69632, 61.425350002],
  [-77.7011701, 61.425760002],
  [-77.70214, 61.424720102],
  [-77.70321, 61.424690002],
  [-77.70203, 61.423440002],
  [-77.71548, 61.424450002],
  [-77.7154101, 61.423680002],
  [-77.71757, 61.423900002],
  [-77.7181899, 61.424910002],
  [-77.7118501, 61.425800002],
  [-77.71168, 61.426830102],
  [-77.71066, 61.427360002],
  [-77.7115199, 61.428100002],
  [-77.71045, 61.428130002],
  [-77.7105001, 61.428650002],
  [-77.70892, 61.428930102],
  [-77.7088301, 61.427910002],
  [-77.70192, 61.428299902],
  [-77.7019599, 61.428820102],
  [-77.70089, 61.428830102],
  [-77.7009799, 61.429859902],
  [-77.7101, 61.430189902],
  [-77.71004, 61.429680002],
  [-77.7142801, 61.429079902],
  [-77.71546, 61.430340002],
  [-77.7186599, 61.430280102],
  [-77.7189899, 61.431040102],
  [-77.7177499, 61.432090002],
  [-77.71245, 61.432700002],
  [-77.7115199, 61.434260102],
  [-77.7078501, 61.435090002],
  [-77.7079, 61.435610102],
  [-77.7057599, 61.435650002],
  [-77.70534, 61.436940102],
  [-77.7064001, 61.436920002],
  [-77.70592, 61.437440002],
  [-77.71214, 61.438340002],
  [-77.71248, 61.439100002],
  [-77.70934, 61.439930102],
  [-77.7089401, 61.441480002],
  [-77.7017, 61.442220002],
  [-77.70212, 61.443020002],
  [-77.7090799, 61.443140002],
  [-77.7102701, 61.444519902],
  [-77.7011999, 61.444700002],
  [-77.70059, 61.443680002],
  [-77.69791, 61.443739902],
  [-77.6997, 61.446010002],
  [-77.71256, 61.446270102],
  [-77.71316, 61.447030002],
  [-77.7113901, 61.448079902],
  [-77.7117401, 61.449100002],
  [-77.70849, 61.448649902],
  [-77.7085399, 61.449170102],
  [-77.70747, 61.449189902],
  [-77.7074001, 61.448419902],
  [-77.7047301, 61.448470002],
  [-77.7043099, 61.449759902],
  [-77.70324, 61.449780002],
  [-77.7038499, 61.450670002],
  [-77.7081801, 61.451220002],
  [-77.70814, 61.450710002],
  [-77.7091999, 61.450690002],
  [-77.7092701, 61.451460002],
  [-77.7144299, 61.452380102],
  [-77.71477, 61.453140002],
  [-77.71047, 61.452969902],
  [-77.7104099, 61.452199902],
  [-77.70445, 61.451419902],
  [-77.70235, 61.451849902],
  [-77.7044901, 61.454880002],
  [-77.70693, 61.455330002],
  [-77.70644, 61.455859902],
  [-77.7091601, 61.456319902],
  [-77.7091999, 61.456830102],
  [-77.7108, 61.456800002],
  [-77.7114299, 61.457820002],
  [-77.70825, 61.458130002],
  [-77.7081601, 61.457110002],
  [-77.7022901, 61.457350002],
  [-77.70021, 61.458040002],
  [-77.70014, 61.457270002],
  [-77.69323, 61.457660002],
  [-77.69265, 61.457160002],
  [-77.69225, 61.458700002],
  [-77.69122, 61.459240002],
  [-77.69454, 61.460450002],
  [-77.69577, 61.462480002],
  [-77.6968499, 61.462450002],
  [-77.69689, 61.462969902],
  [-77.70122, 61.463520102],
  [-77.7102701, 61.462960102],
  [-77.71023, 61.462440002],
  [-77.71666, 61.462570002],
  [-77.7165001, 61.463859902],
  [-77.7181899, 61.464850102],
  [-77.71748, 61.465879902],
  [-77.72078, 61.466830002],
  [-77.7127401, 61.466749902],
  [-77.71282, 61.467510102],
  [-77.7106499, 61.467300102],
  [-77.7106, 61.466790002],
  [-77.7079501, 61.467099902],
  [-77.7071801, 61.466429902],
  [-77.70525, 61.466639902],
  [-77.7051701, 61.465869902],
  [-77.70355, 61.465649902],
  [-77.70404, 61.465130002],
  [-77.6965001, 61.464759902],
  [-77.69647, 61.464250002],
  [-77.6943099, 61.464040002],
  [-77.6938201, 61.464560002],
  [-77.69376, 61.463800002],
  [-77.6857201, 61.463700002],
  [-77.68576, 61.464209902],
  [-77.6847001, 61.464230002],
  [-77.68465, 61.463720102],
  [-77.67875, 61.463450002],
  [-77.6771501, 61.463610102],
  [-77.6772899, 61.465140002],
  [-77.67566, 61.464920002],
  [-77.6744999, 61.463920002],
  [-77.6696299, 61.463250002],
  [-77.6697001, 61.464010002],
  [-77.6664701, 61.463820002],
  [-77.66598, 61.464340002],
  [-77.6648001, 61.463079902],
  [-77.6637101, 61.462850102],
  [-77.6643999, 61.464630102],
  [-77.6632601, 61.463879902],
  [-77.66168, 61.464170102],
  [-77.6617299, 61.464680002],
  [-77.6563601, 61.464529902],
  [-77.65522, 61.463659902],
  [-77.6514999, 61.463979902],
  [-77.6487901, 61.463520102],
  [-77.6472401, 61.464199902],
  [-77.64719, 61.463680002],
  [-77.64555, 61.463189902],
  [-77.6455901, 61.463720102],
  [-77.64185, 61.463790002],
  [-77.6413601, 61.464309902],
  [-77.6390301, 61.462050002],
  [-77.6349399, 61.461110002],
  [-77.6351401, 61.460330002],
  [-77.6216899, 61.459440002],
  [-77.61852, 61.459890002],
  [-77.6172499, 61.460680002],
  [-77.61709, 61.461960102],
  [-77.60797, 61.461749902],
  [-77.6053599, 61.462440002],
  [-77.6048901, 61.463220002],
  [-77.6016601, 61.463030002],
  [-77.6012101, 61.464060102],
  [-77.5942499, 61.463920002],
  [-77.59428, 61.464450002],
  [-77.59215, 61.464490002],
  [-77.59226, 61.465759902],
  [-77.5933301, 61.465740102],
  [-77.5951399, 61.468270002],
  [-77.59407, 61.468290102],
  [-77.5928801, 61.466780002],
  [-77.5907, 61.466309902],
  [-77.59029, 61.467850102],
  [-77.5865799, 61.468180102],
  [-77.58653, 61.467670002],
  [-77.5848899, 61.467309902],
  [-77.5768899, 61.467590002],
  [-77.57693, 61.468099902],
  [-77.57426, 61.468140002],
  [-77.5743201, 61.468920002],
  [-77.56902, 61.469529902],
  [-77.5693399, 61.473370002],
  [-77.56769, 61.472890002],
  [-77.56729, 61.474429902],
  [-77.56836, 61.474419902],
  [-77.5684399, 61.475419902],
  [-77.56558, 61.473180102],
  [-77.5647, 61.475500002],
  [-77.5620099, 61.475300102],
  [-77.5620999, 61.476570002],
  [-77.56102, 61.476340002],
  [-77.56035, 61.477889902],
  [-77.56191, 61.480680002],
  [-77.5657799, 61.482140002],
  [-77.56582, 61.482649902],
  [-77.5668899, 61.482630102],
  [-77.56685, 61.482120002],
  [-77.57016, 61.483329902],
  [-77.5706401, 61.482690002],
  [-77.57262, 61.482940002],
  [-77.57217, 61.481759902],
  [-77.5732399, 61.481740102],
  [-77.5729999, 61.483109902],
  [-77.5758999, 61.484759902],
  [-77.5809999, 61.484920002],
  [-77.58094, 61.484150002],
  [-77.58148, 61.484140002],
  [-77.58262, 61.485020002],
  [-77.5842299, 61.485120002],
  [-77.58419, 61.484610002],
  [-77.5869099, 61.485180102],
  [-77.58952, 61.487580002],
  [-77.5951299, 61.487470002],
  [-77.59642, 61.489999902],
  [-77.5991399, 61.490590002],
  [-77.6065899, 61.489940002],
  [-77.6090299, 61.493600002],
  [-77.61253, 61.493790002],
  [-77.6113401, 61.495610002],
  [-77.6161101, 61.494999902],
  [-77.6164699, 61.496020002],
  [-77.6154799, 61.497060002],
  [-77.61685, 61.497549902],
  [-77.6168199, 61.497040002],
  [-77.62275, 61.497439902],
  [-77.62339, 61.498700002],
  [-77.6144699, 61.500920002],
  [-77.61452, 61.501690002],
  [-77.6124401, 61.502240002],
  [-77.6124799, 61.502750102],
  [-77.61621, 61.502549902],
  [-77.61921, 61.503140002],
  [-77.6195799, 61.504400002],
  [-77.62065, 61.504390002],
  [-77.6205701, 61.503370002],
  [-77.62219, 61.503590002],
  [-77.62162, 61.503090102],
  [-77.6254799, 61.504420102],
  [-77.62872, 61.504750102],
  [-77.6281201, 61.503990102],
  [-77.6325, 61.505060002],
  [-77.6394899, 61.505420102],
  [-77.6411499, 61.506040002],
  [-77.63953, 61.505820002],
  [-77.6391, 61.507100102],
  [-77.64555, 61.507360002],
  [-77.6510499, 61.509050002],
  [-77.6607101, 61.509239902],
  [-77.6586199, 61.509800002],
  [-77.66242, 61.510360002],
  [-77.6619201, 61.510760102],
  [-77.66352, 61.510730002],
  [-77.66286, 61.509329902],
  [-77.6681799, 61.508840002],
  [-77.6682501, 61.509610002],
  [-77.66721, 61.510009902],
  [-77.66452, 61.509930002],
  [-77.6656901, 61.511070002],
  [-77.6678, 61.510640102],
  [-77.66787, 61.511410002],
  [-77.6689399, 61.511390002],
  [-77.66909, 61.513180002],
  [-77.66746, 61.512950002],
  [-77.66733, 61.511420102],
  [-77.6657299, 61.511449902],
  [-77.6670401, 61.514239902],
  [-77.6691601, 61.513940002],
  [-77.66911, 61.513440102],
  [-77.6699399, 61.513669902],
  [-77.6697299, 61.514440102],
  [-77.67189, 61.514530102],
  [-77.67346, 61.514119902],
  [-77.6736199, 61.512830002],
  [-77.6743999, 61.512550102],
  [-77.67457, 61.514610002],
  [-77.6756199, 61.514330102],
  [-77.67621, 61.514960002],
  [-77.6793899, 61.514510002],
  [-77.6803701, 61.513470002],
  [-77.68156, 61.514850002],
  [-77.68531, 61.514779902],
  [-77.6857399, 61.513620002],
  [-77.69002, 61.513530102],
  [-77.6914901, 61.511970002],
  [-77.6926101, 61.512459902],
  [-77.69156, 61.512740002],
  [-77.6997599, 61.514620002],
  [-77.6998301, 61.515390002],
  [-77.70436, 61.515030002],
  [-77.70471, 61.516060002],
  [-77.70678, 61.515250002],
  [-77.70632, 61.516030002],
  [-77.705, 61.516310102],
  [-77.7048401, 61.517590002],
  [-77.7027301, 61.517889902],
  [-77.70334, 61.518899902],
  [-77.7044099, 61.518750002],
  [-77.70558, 61.519880102],
  [-77.7066499, 61.519860002],
  [-77.70632, 61.519100102],
  [-77.7076299, 61.518830002],
  [-77.70881, 61.520070002],
  [-77.7077399, 61.520100102],
  [-77.70701, 61.520880102],
  [-77.7073199, 61.521380002],
  [-77.7051801, 61.521430102],
  [-77.70799, 61.522909902],
  [-77.7058499, 61.522950002],
  [-77.70444, 61.525280002],
  [-77.70391, 61.525290002],
  [-77.70382, 61.524270002],
  [-77.7027499, 61.524290002],
  [-77.70324, 61.523780102],
  [-77.6961899, 61.522880102],
  [-77.69615, 61.522370002],
  [-77.69402, 61.522669902],
  [-77.6933099, 61.526780102],
  [-77.68959, 61.527110102],
  [-77.6886101, 61.528150002],
  [-77.69077, 61.528370002],
  [-77.69081, 61.528880102],
  [-77.6923999, 61.528590002],
  [-77.692, 61.530139902],
  [-77.6930601, 61.530110102],
  [-77.69279, 61.533190002],
  [-77.69548, 61.533390002],
  [-77.6958301, 61.534160002],
  [-77.6971899, 61.534380002],
  [-77.69768, 61.533730002],
  [-77.70147, 61.534300002],
  [-77.70234, 61.535050002],
  [-77.7022701, 61.537359902],
  [-77.70334, 61.537340102],
  [-77.70348, 61.538870002],
  [-77.70667, 61.538550102],
  [-77.70656, 61.537270002],
  [-77.71278, 61.537919902],
  [-77.71314, 61.538930002],
  [-77.7120701, 61.538950002],
  [-77.71057, 61.540259902],
  [-77.70624, 61.539840002],
  [-77.70689, 61.541090002],
  [-77.7079601, 61.541080002],
  [-77.7080701, 61.542359902],
  [-77.70968, 61.542330102],
  [-77.7102999, 61.543340102],
  [-77.7006399, 61.543290002],
  [-77.70014, 61.543799902],
  [-77.6989699, 61.542540002],
  [-77.69737, 61.542830002],
  [-77.69799, 61.543589902],
  [-77.69524, 61.542870002],
  [-77.69424, 61.543660102],
  [-77.6941701, 61.542890102],
  [-77.6930799, 61.542660102],
  [-77.6931701, 61.543680102],
  [-77.69154, 61.543459902],
  [-77.69158, 61.543970002],
  [-77.6905299, 61.544239902],
  [-77.69145, 61.542430002],
  [-77.6881701, 61.541730002],
  [-77.6877, 61.542510002],
  [-77.68611, 61.542670102],
  [-77.6801601, 61.542139902],
  [-77.6802701, 61.543420002],
  [-77.6792, 61.543440102],
  [-77.6780401, 61.542440102],
  [-77.67911, 61.542420002],
  [-77.6784901, 61.541410002],
  [-77.67522, 61.540830002],
  [-77.67311, 61.541259902],
  [-77.6723999, 61.539220102],
  [-77.66919, 61.539290002],
  [-77.6691401, 61.538780102],
  [-77.6596099, 61.540239902],
  [-77.65933, 61.536919902],
  [-77.6567199, 61.537740002],
  [-77.6577099, 61.536820002],
  [-77.65931, 61.536660102],
  [-77.6587299, 61.536160002],
  [-77.6598001, 61.536139902],
  [-77.6591501, 61.534880102],
  [-77.65699, 61.534660102],
  [-77.6563701, 61.533650102],
  [-77.6526099, 61.533589902],
  [-77.64996, 61.534029902],
  [-77.65063, 61.535550102],
  [-77.6495801, 61.535830002],
  [-77.64952, 61.535060002],
  [-77.6468101, 61.534730002],
  [-77.64523, 61.535150002],
  [-77.6452799, 61.535660102],
  [-77.6404999, 61.536260002],
  [-77.6423, 61.538530002],
  [-77.64121, 61.538300002],
  [-77.64056, 61.540100102],
  [-77.64256, 61.541600002],
  [-77.6436099, 61.541320002],
  [-77.64366, 61.541820002],
  [-77.65008, 61.541710002],
  [-77.6494999, 61.541210102],
  [-77.6521599, 61.540900102],
  [-77.65077, 61.541270002],
  [-77.65089, 61.541689902],
  [-77.6570201, 61.541320002],
  [-77.6572899, 61.544380002],
  [-77.6551, 61.543909902],
  [-77.65422, 61.546239902],
  [-77.65307, 61.545230102],
  [-77.65199, 61.545249902],
  [-77.6526099, 61.546139902],
  [-77.6521301, 61.546790102],
  [-77.6515901, 61.546799902],
  [-77.6514801, 61.545520002],
  [-77.64833, 61.546349902],
  [-77.64876, 61.545060002],
  [-77.64075, 61.545460102],
  [-77.6404701, 61.548550102],
  [-77.6399399, 61.548560102],
  [-77.63907, 61.546870002],
  [-77.6360399, 61.546840002],
  [-77.63607, 61.547369902],
  [-77.6350299, 61.547630002],
  [-77.63499, 61.547120102],
  [-77.63141, 61.547019902],
  [-77.6307901, 61.548239902],
  [-77.62972, 61.548249902],
  [-77.6297099, 61.551320002],
  [-77.6305999, 61.552320002],
  [-77.63763, 61.552960002],
  [-77.6376901, 61.553720002],
  [-77.63664, 61.554000102],
  [-77.63877, 61.553830002],
  [-77.63999, 61.555469902],
  [-77.6383799, 61.555500002],
  [-77.6384501, 61.556269902],
  [-77.64177, 61.557489902],
  [-77.63806, 61.558070002],
  [-77.6374601, 61.557310002],
  [-77.6342501, 61.557369902],
  [-77.6342, 61.556860002],
  [-77.6331201, 61.556760002],
  [-77.6315701, 61.557430002],
  [-77.6315, 61.556660002],
  [-77.62398, 61.556550002],
  [-77.6234899, 61.557070002],
  [-77.62286, 61.556060002],
  [-77.62074, 61.556350102],
  [-77.62029, 61.557390002],
  [-77.6154799, 61.557610002],
  [-77.61273, 61.556760002],
  [-77.6127701, 61.557280002],
  [-77.60898, 61.556840002],
  [-77.6074599, 61.557890102],
  [-77.60741, 61.557379902],
  [-77.5993499, 61.557280002],
  [-77.5994, 61.557790102],
  [-77.6004501, 61.557510002],
  [-77.6001201, 61.557860002],
  [-77.60107, 61.558520002],
  [-77.6000001, 61.558540002],
  [-77.59884, 61.557540002],
  [-77.5956501, 61.557860002],
  [-77.5960299, 61.556190002],
  [-77.5982399, 61.556780102],
  [-77.5984401, 61.556010102],
  [-77.5978901, 61.555770002],
  [-77.59848, 61.553200002],
  [-77.5974, 61.553220102],
  [-77.5967999, 61.552450102],
  [-77.59841, 61.552430002],
  [-77.5996501, 61.554450102],
  [-77.60064, 61.553410002],
  [-77.60172, 61.553390002],
  [-77.60286, 61.554259902],
  [-77.6060199, 61.553560102],
  [-77.6072, 61.554819902],
  [-77.6082599, 61.554799902],
  [-77.60849, 61.553170002],
  [-77.60752, 61.552259902],
  [-77.5993599, 61.550880002],
  [-77.5982, 61.549870002],
  [-77.58517, 61.548070002],
  [-77.5850901, 61.547050002],
  [-77.5796501, 61.546129902],
  [-77.5801199, 61.545349902],
  [-77.57695, 61.545919902],
  [-77.57628, 61.544400002],
  [-77.57519, 61.544170002],
  [-77.5748, 61.545960002],
  [-77.57427, 61.545980002],
  [-77.57363, 61.544710002],
  [-77.57451, 61.544600002],
  [-77.5741001, 61.543929902],
  [-77.57249, 61.543960002],
  [-77.57206, 61.545249902],
  [-77.5709999, 61.545270002],
  [-77.5697699, 61.543500002],
  [-77.5687601, 61.544290002],
  [-77.56814, 61.543270002],
  [-77.5665401, 61.543300002],
  [-77.5666, 61.544070002],
  [-77.5655301, 61.544090002],
  [-77.56547, 61.543320002],
  [-77.5623201, 61.544149902],
  [-77.56227, 61.543640002],
  [-77.5612001, 61.543660102],
  [-77.56127, 61.544430002],
  [-77.5590999, 61.544210002],
  [-77.55814, 61.545510002],
  [-77.5559701, 61.545290002],
  [-77.5555, 61.546070002],
  [-77.5500999, 61.545660102],
  [-77.55004, 61.544890102],
  [-77.5495, 61.544900102],
  [-77.5496499, 61.546689902],
  [-77.5475301, 61.546990002],
  [-77.54706, 61.547770102],
  [-77.53902, 61.547919902],
  [-77.53735, 61.547180002],
  [-77.53582, 61.544900102],
  [-77.53739, 61.544359902],
  [-77.5380899, 61.543070002],
  [-77.5397401, 61.543550102],
  [-77.5396301, 61.542270002],
  [-77.54291, 61.542980002],
  [-77.5429701, 61.543750002],
  [-77.5435101, 61.543740002],
  [-77.5433199, 61.541440102],
  [-77.54225, 61.541459902],
  [-77.5398799, 61.538679902],
  [-77.5382901, 61.538970002],
  [-77.5381901, 61.537689902],
  [-77.53605, 61.537730002],
  [-77.53483, 61.535960002],
  [-77.53212, 61.535500002],
  [-77.52969, 61.532210102],
  [-77.5259501, 61.532290002],
  [-77.52549, 61.533320102],
  [-77.5244199, 61.533340102],
  [-77.5235299, 61.535400002],
  [-77.52191, 61.535430102],
  [-77.5204099, 61.536740002],
  [-77.51969, 61.534450102],
  [-77.52292, 61.534650002],
  [-77.5246301, 61.532569902],
  [-77.52424, 61.531040002],
  [-77.52, 61.531640002],
  [-77.5199501, 61.531119902],
  [-77.52127, 61.530840002],
  [-77.52199, 61.529799902],
  [-77.5230601, 61.529780102],
  [-77.52302, 61.529270002],
  [-77.51878, 61.529860002],
  [-77.5187399, 61.529349902],
  [-77.5160601, 61.529400002],
  [-77.514, 61.527129902],
  [-77.51423, 61.526620002],
  [-77.5131601, 61.526640002],
  [-77.5128499, 61.526129902],
  [-77.51343, 61.525689902],
  [-77.5082801, 61.525950002],
  [-77.5070401, 61.523930002],
  [-77.50492, 61.524229902],
  [-77.5034199, 61.522210102],
  [-77.50358, 61.520920002],
  [-77.49978, 61.520229902],
  [-77.4991799, 61.526119902],
  [-77.4986001, 61.525620002],
  [-77.49703, 61.525909902],
  [-77.49866, 61.526400002],
  [-77.49889, 61.529200002],
  [-77.4978201, 61.529220102],
  [-77.4977399, 61.528200002],
  [-77.4966399, 61.527960002],
  [-77.49614, 61.528480002],
  [-77.4955099, 61.527220102],
  [-77.49658, 61.527200002],
  [-77.49642, 61.525150002],
  [-77.49535, 61.525170002],
  [-77.49709, 61.522410002],
  [-77.4961899, 61.522339902],
  [-77.49576, 61.523620002],
  [-77.49203, 61.523950002],
  [-77.4939, 61.520459902],
  [-77.49712, 61.520530002],
  [-77.4971601, 61.521040002],
  [-77.4981799, 61.520510002],
  [-77.4949401, 61.520060002],
  [-77.4949, 61.519540102],
  [-77.4921601, 61.518830002],
  [-77.4921, 61.518060002],
  [-77.4899, 61.517330102],
  [-77.4886001, 61.514540102],
  [-77.4833801, 61.513960002],
  [-77.48755, 61.514820002],
  [-77.48857, 61.517610002],
  [-77.49154, 61.520030002],
  [-77.4928201, 61.520349902],
  [-77.4919201, 61.520200102],
  [-77.49187, 61.521909902],
  [-77.4908, 61.521930002],
  [-77.49046, 61.524490002],
  [-77.4931699, 61.524700002],
  [-77.49465, 61.529789902],
  [-77.49358, 61.529809902],
  [-77.4935199, 61.529310002],
  [-77.49143, 61.529850002],
  [-77.4916001, 61.531890102],
  [-77.4910599, 61.531899902],
  [-77.49033, 61.529359902],
  [-77.48925, 61.529380002],
  [-77.48944, 61.531679902],
  [-77.4883899, 61.531950002],
  [-77.4882, 61.529650102],
  [-77.48621, 61.529260002],
  [-77.4871699, 61.530170002],
  [-77.48611, 61.530200002],
  [-77.4867201, 61.531220102],
  [-77.48567, 61.531480002],
  [-77.484, 61.530750002],
  [-77.4846199, 61.531770102],
  [-77.4831799, 61.531620002],
  [-77.48478, 61.533809902],
  [-77.4858499, 61.533789902],
  [-77.48591, 61.534560102],
  [-77.48956, 61.533210102],
  [-77.4907001, 61.534090002],
  [-77.49335, 61.533650102],
  [-77.4934099, 61.534420002],
  [-77.49502, 61.534390002],
  [-77.4927299, 61.532640002],
  [-77.4952601, 61.533019902],
  [-77.4985901, 61.532150002],
  [-77.4997201, 61.532899902],
  [-77.50078, 61.532750002],
  [-77.5008201, 61.533260002],
  [-77.4976101, 61.533320102],
  [-77.4958201, 61.534380002],
  [-77.4977101, 61.534600002],
  [-77.4982999, 61.535230102],
  [-77.50098, 61.535180002],
  [-77.50159, 61.536060002],
  [-77.5005099, 61.536080002],
  [-77.4998301, 61.538569902],
  [-77.50079, 61.539530002],
  [-77.50348, 61.539610002],
  [-77.5036199, 61.541400002],
  [-77.5047, 61.541380002],
  [-77.5046299, 61.540620002],
  [-77.50601, 61.541090002],
  [-77.5070601, 61.544149902],
  [-77.51033, 61.544730002],
  [-77.51112, 61.544600002],
  [-77.51081, 61.544090002],
  [-77.51188, 61.544070002],
  [-77.51193, 61.544579902],
  [-77.51456, 61.544019902],
  [-77.5136299, 61.545570102],
  [-77.51201, 61.545600002],
  [-77.5120501, 61.546110102],
  [-77.50667, 61.545950002],
  [-77.5066399, 61.545440102],
  [-77.49701, 61.545870002],
  [-77.4952801, 61.547699902],
  [-77.4956101, 61.548460102],
  [-77.49724, 61.548689902],
  [-77.4972, 61.548170002],
  [-77.49967, 61.548900102],
  [-77.50056, 61.550029902],
  [-77.5021501, 61.549750002],
  [-77.50498, 61.551620002],
  [-77.50824, 61.552070002],
  [-77.50926, 61.551280002],
  [-77.5103901, 61.552160002],
  [-77.51944, 61.551350102],
  [-77.5200899, 61.552620002],
  [-77.52493, 61.552780102],
  [-77.5249699, 61.553300002],
  [-77.52177, 61.553610002],
  [-77.52155, 61.554130102],
  [-77.523, 61.555379902],
  [-77.52677, 61.555570102],
  [-77.5267201, 61.555060002],
  [-77.5278, 61.555039902],
  [-77.52838, 61.555650002],
  [-77.53327, 61.556469902],
  [-77.5330801, 61.557240102],
  [-77.5345201, 61.558489902],
  [-77.5454101, 61.560460102],
  [-77.55127, 61.559850002],
  [-77.5572299, 61.560630002],
  [-77.5574399, 61.559860002],
  [-77.5587501, 61.559579902],
  [-77.55884, 61.560599902],
  [-77.5604499, 61.560570102],
  [-77.5604101, 61.560060002],
  [-77.56316, 61.560910102],
  [-77.5658, 61.560340102],
  [-77.5706501, 61.560630002],
  [-77.57069, 61.561149902],
  [-77.5723299, 61.561500002],
  [-77.5749999, 61.561320002],
  [-77.5756401, 61.562589902],
  [-77.5782001, 61.561000102],
  [-77.5788801, 61.562780102],
  [-77.5769099, 61.562640002],
  [-77.57629, 61.563860002],
  [-77.5784301, 61.563819902],
  [-77.5787601, 61.564580102],
  [-77.5812201, 61.565039902],
  [-77.58126, 61.565550002],
  [-77.5931101, 61.566100002],
  [-77.59316, 61.566609902],
  [-77.59474, 61.566320002],
  [-77.5947899, 61.566829902],
  [-77.59905, 61.566500002],
  [-77.5991001, 61.567010102],
  [-77.60017, 61.566990002],
  [-77.5995699, 61.566230102],
  [-77.60064, 61.566210002],
  [-77.60042, 61.565790102],
  [-77.5963499, 61.566280002],
  [-77.59598, 61.565020102],
  [-77.5928801, 61.563290002],
  [-77.59983, 61.562900102],
  [-77.60152, 61.563890102],
  [-77.6008999, 61.562880002],
  [-77.6041201, 61.562950002],
  [-77.60529, 61.564070002],
  [-77.6057801, 61.563550002],
  [-77.6074599, 61.564290002],
  [-77.6101101, 61.563980002],
  [-77.6113401, 61.565750002],
  [-77.60917, 61.565540002],
  [-77.6082499, 61.567350102],
  [-77.6120199, 61.567530002],
  [-77.61207, 61.568029902],
  [-77.61794, 61.567670102],
  [-77.61798, 61.568190002],
  [-77.61906, 61.568159902],
  [-77.6190101, 61.567650002],
  [-77.62276, 61.567580102],
  [-77.6228099, 61.568090002],
  [-77.62442, 61.568060002],
  [-77.6238099, 61.567300002],
  [-77.62518, 61.567530002],
  [-77.6255701, 61.569049902],
  [-77.6330499, 61.568530002],
  [-77.6345801, 61.567609902],
  [-77.63466, 61.568630002],
  [-77.63732, 61.568320002],
  [-77.6372599, 61.567560102],
  [-77.6377901, 61.567550002],
  [-77.63784, 61.568060002],
  [-77.6389101, 61.568029902],
  [-77.63817, 61.568819902],
  [-77.63967, 61.570709902],
  [-77.64286, 61.570259902],
  [-77.64243, 61.571550002],
  [-77.64565, 61.571489902],
  [-77.6471301, 61.570049902],
  [-77.6503601, 61.570120102],
  [-77.65154, 61.571379902],
  [-77.65477, 61.571440002],
  [-77.6553601, 61.572070002],
  [-77.6472799, 61.571709902],
  [-77.64678, 61.572240102],
  [-77.64788, 61.572340102],
  [-77.6484801, 61.573230102],
  [-77.64099, 61.573630002],
  [-77.6407901, 61.574400002],
  [-77.6438299, 61.575369902],
  [-77.6425801, 61.576410002],
  [-77.6427099, 61.577949902],
  [-77.64384, 61.578690102],
  [-77.64366, 61.579719902],
  [-77.64584, 61.579929902],
  [-77.6464701, 61.581200002],
  [-77.64221, 61.581540002],
  [-77.64122, 61.582580102],
  [-77.6304501, 61.582279902],
  [-77.63041, 61.581770002],
  [-77.6287901, 61.581800102],
  [-77.62764, 61.580800102],
  [-77.6194699, 61.579420002],
  [-77.6194, 61.578650002],
  [-77.6091299, 61.577819902],
  [-77.60684, 61.576080002],
  [-77.60415, 61.576130102],
  [-77.6029099, 61.574100002],
  [-77.6002101, 61.573900102],
  [-77.60017, 61.573389902],
  [-77.59483, 61.573740002],
  [-77.59493, 61.575020102],
  [-77.59386, 61.575039902],
  [-77.5937999, 61.574279902],
  [-77.5911199, 61.574330002],
  [-77.59118, 61.575090002],
  [-77.5900901, 61.574860002],
  [-77.5901299, 61.575369902],
  [-77.5857899, 61.574810102],
  [-77.5799801, 61.575950002],
  [-77.5735401, 61.576070002],
  [-77.5704201, 61.577279902],
  [-77.5753299, 61.578210002],
  [-77.57527, 61.577440002],
  [-77.5763399, 61.577420002],
  [-77.5764, 61.578190002],
  [-77.57904, 61.577630002],
  [-77.57915, 61.578910102],
  [-77.58208, 61.578590102],
  [-77.58297, 61.579730002],
  [-77.59095, 61.578819902],
  [-77.59239, 61.579929902],
  [-77.5922399, 61.581470102],
  [-77.58682, 61.580810102],
  [-77.58637, 61.581839902],
  [-77.58363, 61.581120002],
  [-77.5837601, 61.582660002],
  [-77.5859099, 61.582750002],
  [-77.58764, 61.584120002],
  [-77.59029, 61.583690102],
  [-77.59407, 61.584000002],
  [-77.5963401, 61.585370102],
  [-77.60062, 61.585150102],
  [-77.6006799, 61.585920102],
  [-77.59961, 61.585939902],
  [-77.5991299, 61.586719902],
  [-77.6021101, 61.586920102],
  [-77.6024501, 61.587680002],
  [-77.60029, 61.587719902],
  [-77.6002499, 61.587210002],
  [-77.5917801, 61.588650002],
  [-77.59184, 61.589420002],
  [-77.58493, 61.590320002],
  [-77.5855599, 61.591340002],
  [-77.58692, 61.591560002],
  [-77.5864201, 61.592080002],
  [-77.58804, 61.595379902],
  [-77.5951014, 61.596907402],
  [-77.60042, 61.595650002],
  [-77.6005601, 61.594120002],
  [-77.60182, 61.593069902],
  [-77.61038, 61.592650002],
  [-77.61042, 61.593159902],
  [-77.6179199, 61.592890002],
  [-77.62664, 61.594379902],
  [-77.6278001, 61.595389902],
  [-77.62888, 61.595370102],
  [-77.62986, 61.594320002],
  [-77.6299001, 61.594820102],
  [-77.63152, 61.594930102],
  [-77.6335701, 61.593740002],
  [-77.64284, 61.595350002],
  [-77.64454, 61.596340002],
  [-77.6439001, 61.595080002],
  [-77.64553, 61.595300002],
  [-77.6455701, 61.595810102],
  [-77.65011, 61.595470102],
  [-77.6495801, 61.592410002],
  [-77.6564899, 61.591499902],
  [-77.6577199, 61.590200002],
  [-77.6606199, 61.589630002],
  [-77.6605099, 61.588350102],
  [-77.6669599, 61.588350102],
  [-77.66742, 61.587450002],
  [-77.6745099, 61.588850002],
  [-77.67457, 61.589360102],
  [-77.68053, 61.590010002],
  [-77.6804801, 61.589499902],
  [-77.6977, 61.589670002],
  [-77.69765, 61.589159902],
  [-77.70113, 61.587980002],
  [-77.70069, 61.587049902],
  [-77.69912, 61.587599902],
  [-77.6979401, 61.586340002],
  [-77.69955, 61.586310002],
  [-77.69993, 61.584510002],
  [-77.70592, 61.585410002],
  [-77.70114, 61.586020102],
  [-77.70199, 61.586509902],
  [-77.70134, 61.588320002],
  [-77.70344, 61.587770002],
  [-77.7036001, 61.589560002],
  [-77.7025199, 61.589580102],
  [-77.7042901, 61.591340002],
  [-77.7064299, 61.591289902],
  [-77.7061, 61.590530002],
  [-77.7074199, 61.590250102],
  [-77.70782, 61.591780002],
  [-77.7097201, 61.591990002],
  [-77.70979, 61.592760002],
  [-77.71245, 61.592450002],
  [-77.71124, 61.590939902],
  [-77.7128501, 61.590910102],
  [-77.7128, 61.590399902],
  [-77.7144299, 61.590619902],
  [-77.7145201, 61.591640002],
  [-77.7188599, 61.592070002],
  [-77.7189, 61.592580102],
  [-77.7237401, 61.592609902],
  [-77.72637, 61.591910102],
  [-77.7264101, 61.592430002],
  [-77.72778, 61.592660002],
  [-77.7293199, 61.594930102],
  [-77.72824, 61.594949902],
  [-77.7282901, 61.595460002],
  [-77.73368, 61.595609902],
  [-77.7337699, 61.596630002],
  [-77.73156, 61.595910102],
  [-77.7321999, 61.597179902],
  [-77.73113, 61.597200002],
  [-77.73069, 61.598230002],
  [-77.7339, 61.598169902],
  [-77.73467, 61.600710102],
  [-77.7357401, 61.600690102],
  [-77.7329899, 61.603049902],
  [-77.73248, 61.606389902],
  [-77.7340999, 61.606470002],
  [-77.7365301, 61.609640002],
  [-77.73546, 61.609660002],
  [-77.73449, 61.610959902],
  [-77.7334201, 61.610980002],
  [-77.73403, 61.611729902],
  [-77.73192, 61.612289902],
  [-77.7315, 61.613580002],
  [-77.7294199, 61.614389902],
  [-77.7287501, 61.615949902],
  [-77.72991, 61.616940102],
  [-77.73347, 61.617629902],
  [-77.7337, 61.620189902],
  [-77.73204, 61.619710102],
  [-77.73182, 61.620230002],
  [-77.7337301, 61.620570002],
  [-77.7355201, 61.622710102],
  [-77.73661, 61.622690002],
  [-77.73647, 61.624230002],
  [-77.7421801, 61.624880002],
  [-77.74225, 61.625650002],
  [-77.7433199, 61.625629902],
  [-77.74337, 61.626140002],
  [-77.7542199, 61.627199902],
  [-77.75624, 61.631770002],
  [-77.75516, 61.631790002],
  [-77.7552299, 61.632560002],
  [-77.76117, 61.632820102],
  [-77.7617701, 61.633450002],
  [-77.76069, 61.633470002],
  [-77.76296, 61.634710102],
  [-77.76115, 61.635510102],
  [-77.7612399, 61.637470002],
  [-77.76273, 61.638170102],
  [-77.76362, 61.638189902],
  [-77.7635699, 61.637600102],
  [-77.7620901, 61.637030002],
  [-77.76537, 61.637730102],
  [-77.76661, 61.639500102],
  [-77.7648899, 61.638250002],
  [-77.7635, 61.638700002],
  [-77.7638801, 61.639040002],
  [-77.76115, 61.638580002],
  [-77.7608801, 61.637649902],
  [-77.7594499, 61.637590002],
  [-77.7616, 61.640620102],
  [-77.7614201, 61.641649902],
  [-77.76518, 61.641560002],
  [-77.76514, 61.641060102],
  [-77.76706, 61.641529902],
  [-77.7668601, 61.642309902],
  [-77.76362, 61.642120002],
  [-77.7634, 61.642629902],
  [-77.7674599, 61.642930002],
  [-77.7776, 61.642089902],
  [-77.7776501, 61.642600002],
  [-77.77873, 61.642580002],
  [-77.7783499, 61.644380002],
  [-77.7826601, 61.644419902],
  [-77.78384, 61.645550002],
  [-77.78659, 61.646260002],
  [-77.7867999, 61.648550002],
  [-77.78626, 61.648570002],
  [-77.7862201, 61.648060102],
  [-77.7851399, 61.648079902],
  [-77.78507, 61.647309902],
  [-77.7823399, 61.646859902],
  [-77.7823, 61.646350002],
  [-77.77906, 61.646409902],
  [-77.78017, 61.646639902],
  [-77.78084, 61.648170102],
  [-77.78192, 61.648150002],
  [-77.7821299, 61.650460002],
  [-77.78095, 61.649440002],
  [-77.78051, 61.650480002],
  [-77.7788999, 61.650510102],
  [-77.77895, 61.651020002],
  [-77.77362, 61.651639902],
  [-77.77271, 61.653450002],
  [-77.77594, 61.653500102],
  [-77.77763, 61.654390102],
  [-77.7769801, 61.653110002],
  [-77.7783499, 61.653340002],
  [-77.77873, 61.654610102],
  [-77.7766501, 61.655419902],
  [-77.77773, 61.655410102],
  [-77.7769901, 61.656180102],
  [-77.7775501, 61.656429902],
  [-77.77695, 61.658740102],
  [-77.7751199, 61.659290102],
  [-77.77492, 61.663140002],
  [-77.7784599, 61.663580002],
  [-77.77974, 61.665850102],
  [-77.77759, 61.665900002],
  [-77.7776601, 61.666659902],
  [-77.7765799, 61.666690002],
  [-77.7768901, 61.670010002],
  [-77.7785, 61.669979902],
  [-77.7792201, 61.672010002],
  [-77.78059, 61.672240002],
  [-77.7821101, 61.674250002],
  [-77.7874699, 61.673900002],
  [-77.78752, 61.674400102],
  [-77.7902301, 61.674600002],
  [-77.79209, 61.677380002],
  [-77.7904799, 61.677410102],
  [-77.7911101, 61.678420102],
  [-77.7937801, 61.678109902],
  [-77.7938972, 61.679801802],
  [-77.7926123, 61.680773402],
  [-77.7930844, 61.681623302],
  [-77.7898555, 61.681217302],
  [-77.786057, 61.683409702],
  [-77.7871192, 61.684341002],
  [-77.7899534, 61.684163602],
  [-77.7910245, 61.685134802],
  [-77.787101, 61.687270802],
  [-77.7866578, 61.687745302],
  [-77.787313, 61.688132802],
  [-77.78506, 61.689030002],
  [-77.7869688, 61.689185802],
  [-77.8073301, 61.684999902],
  [-77.81443, 61.683060002],
  [-77.81463, 61.682290002],
  [-77.81522, 61.682790002],
  [-77.8242401, 61.681329902],
  [-77.82429, 61.681840002],
  [-77.8253601, 61.681820002],
  [-77.82585, 61.681180002],
  [-77.8328299, 61.680889902],
  [-77.83278, 61.680380002],
  [-77.8349399, 61.680470002],
  [-77.8401696, 61.679209902],
  [-77.8413496, 61.680460002],
  [-77.8429697, 61.680549902],
  [-77.8441797, 61.681940002],
  [-77.8479895, 61.682370002],
  [-77.8485896, 61.682999902],
  [-77.8512696, 61.682810002],
  [-77.8512195, 61.682300102],
  [-77.8528697, 61.682650102],
  [-77.8635495, 61.681659902],
  [-77.8765397, 61.682290002],
  [-77.8764996, 61.681769902],
  [-77.8775695, 61.681750102],
  [-77.8779897, 61.680460002],
  [-77.8806296, 61.679900002],
  [-77.8806996, 61.680659902],
  [-77.8833595, 61.680219902],
  [-77.8839296, 61.680600002],
  [-77.8812896, 61.681160002],
  [-77.8811896, 61.682960102],
  [-77.8823595, 61.683960002],
  [-77.8848496, 61.684659902],
  [-77.8848995, 61.685180002],
  [-77.8902126, 61.685486002],
  [-77.8945795, 61.684980102],
  [-77.9002297, 61.682040002],
  [-77.9065996, 61.681009902],
  [-77.9103796, 61.681060002],
  [-77.9097696, 61.680310102],
  [-77.9145496, 61.679559902],
  [-77.9285496, 61.679390002],
  [-77.9284995, 61.678879902],
  [-77.9300696, 61.678460002],
  [-77.9392795, 61.678900002],
  [-77.9393096, 61.679420102],
  [-77.9409395, 61.679380002],
  [-77.9420996, 61.680250002],
  [-77.9416396, 61.681160002],
  [-77.9503496, 61.681999902],
  [-77.9503995, 61.682510002],
  [-77.9493196, 61.682530102],
  [-77.9488596, 61.683310102],
  [-77.9504196, 61.682760102],
  [-77.9504697, 61.683270002],
  [-77.9526496, 61.683480002],
  [-77.9520096, 61.682470002],
  [-77.9530896, 61.682449902],
  [-77.9531595, 61.683219902],
  [-77.9606896, 61.683050002],
  [-77.9607596, 61.683820002],
  [-77.9628996, 61.683769902],
  [-77.9622996, 61.683020002],
  [-77.9638896, 61.682730002],
  [-77.9637896, 61.684530102],
  [-77.9622996, 61.685840002],
  [-77.9626496, 61.686590002],
  [-77.9653296, 61.686540102],
  [-77.9652895, 61.686020002],
  [-77.9664296, 61.686769902],
  [-77.9623496, 61.687200102],
  [-77.9606396, 61.688170002],
  [-77.9633797, 61.688630102],
  [-77.9634296, 61.689140002],
  [-77.9661196, 61.689080102],
  [-77.9667395, 61.689960002],
  [-77.9703396, 61.690789902],
  [-77.9706396, 61.691290002],
  [-77.9689796, 61.690810002],
  [-77.9686596, 61.693119902],
  [-77.9697395, 61.693100102],
  [-77.9690595, 61.694390002],
  [-77.9748297, 61.695549902],
  [-77.9748996, 61.696310102],
  [-77.9770397, 61.696009902],
  [-77.9777097, 61.697530102],
  [-77.9820196, 61.697439902],
  [-77.9806796, 61.698320102],
  [-77.9816496, 61.699239902],
  [-77.9848397, 61.698800002],
  [-77.9851497, 61.699160002],
  [-77.9843897, 61.699690002],
  [-77.9903696, 61.700200102],
  [-77.9927297, 61.699510002],
  [-77.9923897, 61.698750102],
  [-77.9934696, 61.698730002],
  [-77.9923696, 61.701310102],
  [-77.9927097, 61.702070002],
  [-77.9906097, 61.702630002],
  [-77.9923596, 61.703999902],
  [-77.9947696, 61.703820002],
  [-77.9948397, 61.704579902],
  [-77.9968397, 61.705559902],
  [-77.9964997, 61.704119902],
  [-77.9955996, 61.704060002],
  [-77.9955496, 61.703540102],
  [-77.9966295, 61.703520002],
  [-77.9978595, 61.705030002],
  [-77.9989296, 61.705009902],
  [-77.9988297, 61.703980102],
  [-77.9999397, 61.704889902],
  [-78.0034796, 61.705190002],
  [-78.0039296, 61.706100102],
  [-77.9999397, 61.705740002],
  [-77.9969296, 61.706590002],
  [-77.9951895, 61.708160002],
  [-77.9966395, 61.709150002],
  [-77.9987395, 61.708600002],
  [-77.9985696, 61.709620002],
  [-78.0024796, 61.709899902],
  [-78.0029397, 61.710679902],
  [-77.9936999, 61.711520002],
  [-77.9940101, 61.714840002],
  [-78.0003401, 61.715870102],
  [-78.0017901, 61.717569902],
  [-78.00343, 61.717349902],
  [-78.0033799, 61.717860002],
  [-78.0060499, 61.718170002],
  [-78.0060001, 61.718689902],
  [-78.00921, 61.719009902],
  [-78.01019, 61.720060002],
  [-78.0085999, 61.719770102],
  [-78.00852, 61.720530002],
  [-78.00742, 61.720770102],
  [-78.01219, 61.721640002],
  [-78.0146999, 61.723610002],
  [-78.0163, 61.723780102],
  [-78.0157099, 61.724280002],
  [-78.0167901, 61.724300002],
  [-78.0167199, 61.725060002],
  [-78.0190201, 61.726390002],
  [-78.01865, 61.727410002],
  [-78.0208299, 61.727200002],
  [-78.0200399, 61.729750002],
  [-78.0211201, 61.729770102],
  [-78.02051, 61.730520002],
  [-78.01833, 61.730730002],
  [-78.0182799, 61.731239902],
  [-78.01933, 61.731520002],
  [-78.0165899, 61.731970002],
  [-78.01585, 61.734010102],
  [-78.01039, 61.734660102],
  [-78.0162401, 61.735550102],
  [-78.01088, 61.735180002],
  [-78.0108299, 61.735689902],
  [-78.0086701, 61.735640002],
  [-78.0069299, 61.736880002],
  [-78.0155999, 61.736560102],
  [-78.01421, 61.737039902],
  [-78.01386, 61.737799902],
  [-78.01554, 61.737330102],
  [-78.0135701, 61.740880002],
  [-78.01464, 61.740890102],
  [-78.01555, 61.742709902],
  [-78.0171599, 61.742740002],
  [-78.01707, 61.743760002],
  [-78.0181401, 61.743780102],
  [-78.018, 61.745320002],
  [-78.01907, 61.745340102],
  [-78.01897, 61.746359902],
  [-78.02164, 61.746680102],
  [-78.0220201, 61.748350102],
  [-78.02309, 61.748500002],
  [-78.02299, 61.749520002],
  [-78.01919, 61.749699902],
  [-78.0161499, 61.753220002],
  [-78.01507, 61.753190002],
  [-78.01789, 61.754790102],
  [-78.0174, 61.757080002],
  [-78.02009, 61.757140102],
  [-78.0200201, 61.757900102],
  [-78.0151201, 61.758310002],
  [-78.0130299, 61.763130102],
  [-78.01411, 61.763159902],
  [-78.01398, 61.764430002],
  [-78.01506, 61.764470102],
  [-78.0150101, 61.764970002],
  [-78.0182599, 61.764910102],
  [-78.01974, 61.766350102],
  [-78.0250599, 61.767240102],
  [-78.02655, 61.768550002],
  [-78.0232599, 61.769130102],
  [-78.02221, 61.768709902],
  [-78.0221401, 61.769480102],
  [-78.0210599, 61.769450002],
  [-78.01986, 61.770709902],
  [-78.0243699, 61.771570102],
  [-78.0245701, 61.772350102],
  [-78.0234899, 61.772320002],
  [-78.0233899, 61.773350102],
  [-78.0244701, 61.773370102],
  [-78.0253899, 61.775049902],
  [-78.02996, 61.775279902],
  [-78.0282401, 61.779080002],
  [-78.02611, 61.778780002],
  [-78.02576, 61.779540002],
  [-78.02709, 61.779820102],
  [-78.02588, 61.781080002],
  [-78.0248001, 61.781070002],
  [-78.02554, 61.781839902],
  [-78.0252, 61.782599902],
  [-78.0262699, 61.782619902],
  [-78.02632, 61.782110002],
  [-78.0279201, 61.782399902],
  [-78.0273, 61.783159902],
  [-78.0283701, 61.783310002],
  [-78.02978, 61.785520002],
  [-78.0281599, 61.785480102],
  [-78.02696, 61.786729902],
  [-78.02532, 61.786959902],
  [-78.0247001, 61.787710102],
  [-78.02952, 61.788190002],
  [-78.03285, 61.787370102],
  [-78.0316199, 61.788880002],
  [-78.03054, 61.788860002],
  [-78.0327001, 61.788910002],
  [-78.03201, 61.790430002],
  [-78.03852, 61.790179902],
  [-78.0417001, 61.790890002],
  [-78.04209, 61.792440002],
  [-78.04376, 61.791959902],
  [-78.0431699, 61.792460002],
  [-78.04479, 61.792499902],
  [-78.0447399, 61.793010002],
  [-78.0458101, 61.793030102],
  [-78.0463, 61.793680002],
  [-78.04899, 61.793870002],
  [-78.0463601, 61.795860002],
  [-78.0469499, 61.798179902],
  [-78.0447399, 61.798640002],
  [-78.04521, 61.799420002],
  [-78.04413, 61.799399902],
  [-78.0423899, 61.800640002],
  [-78.03642, 61.800770002],
  [-78.0363601, 61.801530002],
  [-78.0396099, 61.801350002],
  [-78.03955, 61.801860002],
  [-78.0384801, 61.801830102],
  [-78.0389201, 61.802870002],
  [-78.04324, 61.802959902],
  [-78.04288, 61.803980002],
  [-78.0442, 61.804260102],
  [-78.0441501, 61.804770002],
  [-78.0463, 61.804949902],
  [-78.0470499, 61.805600102],
  [-78.04666, 61.806870002],
  [-78.04901, 61.807690002],
  [-78.04911, 61.809490102],
  [-78.05242, 61.808790002],
  [-78.0515199, 61.809800002],
  [-78.05333, 61.810600102],
  [-78.0522501, 61.810580002],
  [-78.0521799, 61.811340002],
  [-78.05058, 61.811050102],
  [-78.05044, 61.812590002],
  [-78.04879, 61.812810002],
  [-78.04899, 61.813580002],
  [-78.05191, 61.814160102],
  [-78.05186, 61.814670002],
  [-78.05078, 61.814639902],
  [-78.05122, 61.815680002],
  [-78.05255, 61.815959902],
  [-78.0534099, 61.818280102],
  [-78.05576, 61.819100002],
  [-78.0537001, 61.820849902],
  [-78.0523999, 61.825940102],
  [-78.05611, 61.826790002],
  [-78.05547, 61.827810002],
  [-78.0538499, 61.827770002],
  [-78.05476, 61.829580002],
  [-78.0563701, 61.829739902],
  [-78.0569699, 61.831930002],
  [-78.05867, 61.834010002],
  [-78.0602899, 61.834050102],
  [-78.0601899, 61.835070102],
  [-78.0612701, 61.835089902],
  [-78.06112, 61.836630102],
  [-78.0600401, 61.836600002],
  [-78.05914, 61.837610102],
  [-78.0605901, 61.839429902],
  [-78.06343, 61.840769902],
  [-78.06557, 61.841079902],
  [-78.0655299, 61.841590002],
  [-78.06823, 61.841639902],
  [-78.06843, 61.842419902],
  [-78.0726299, 61.843790002],
  [-78.0736101, 61.844830002],
  [-78.0732701, 61.845590002],
  [-78.0746001, 61.845879902],
  [-78.0742801, 61.846380002],
  [-78.07643, 61.846549902],
  [-78.08111, 61.848570002],
  [-78.0806001, 61.851120002],
  [-78.0818301, 61.852429902],
  [-78.08348, 61.852209902],
  [-78.0825399, 61.853470002],
  [-78.0844001, 61.853769902],
  [-78.08514, 61.857620102],
  [-78.0840501, 61.857600002],
  [-78.08391, 61.861950002],
  [-78.08523, 61.862230002],
  [-78.08789, 61.865620002],
  [-78.0916301, 61.866209902],
  [-78.0906499, 61.867980102],
  [-78.08522, 61.868119902],
  [-78.0843099, 61.869119902],
  [-78.085, 61.870420102],
  [-78.08391, 61.870400002],
  [-78.0838301, 61.871420102],
  [-78.08549, 61.870940002],
  [-78.0845299, 61.872460002],
  [-78.08579, 61.876320102],
  [-78.0876399, 61.876879902],
  [-78.08725, 61.878150002],
  [-78.0879799, 61.878930002],
  [-78.0890601, 61.878950002],
  [-78.08944, 61.880750102],
  [-78.0909899, 61.881559902],
  [-78.0877599, 61.881229902],
  [-78.08767, 61.882250002],
  [-78.089, 61.882540102],
  [-78.08868, 61.883040002],
  [-78.0897599, 61.883070002],
  [-78.08971, 61.883580002],
  [-78.09269, 61.883640102],
  [-78.09382, 61.885970102],
  [-78.0910701, 61.886420102],
  [-78.0907, 61.890250002],
  [-78.0922801, 61.890799902],
  [-78.0911999, 61.890779902],
  [-78.09112, 61.891540102],
  [-78.08844, 61.891229902],
  [-78.0877599, 61.892750102],
  [-78.0867, 61.892470002],
  [-78.08714, 61.893510002],
  [-78.0855199, 61.893480002],
  [-78.0834299, 61.895480002],
  [-78.0847301, 61.896019902],
  [-78.07988, 61.895650102],
  [-78.0792901, 61.896160002],
  [-78.08035, 61.896430102],
  [-78.0792701, 61.896410002],
  [-78.0782801, 61.898180002],
  [-78.0790501, 61.898720002],
  [-78.07745, 61.898430102],
  [-78.07932, 61.901530002],
  [-78.07824, 61.901510002],
  [-78.0791999, 61.902810002],
  [-78.0752801, 61.904009902],
  [-78.0747201, 61.907070002],
  [-78.07881, 61.909720002],
  [-78.0775101, 61.911990102],
  [-78.0779, 61.913540102],
  [-78.0761601, 61.914780102],
  [-78.07635, 61.915550102],
  [-78.0752701, 61.915530002],
  [-78.07456, 61.917310002],
  [-78.08001, 61.917040002],
  [-78.0826201, 61.918120102],
  [-78.08957, 61.919290002],
  [-78.0912801, 61.918430002],
  [-78.09727, 61.918300002],
  [-78.0949799, 61.919530002],
  [-78.0851, 61.920600002],
  [-78.08501, 61.921620002],
  [-78.08392, 61.921600002],
  [-78.08311, 61.924400002],
  [-78.08203, 61.924380002],
  [-78.0821999, 61.925400002],
  [-78.0816101, 61.925900102],
  [-78.0826499, 61.929249902],
  [-78.0837301, 61.929280002],
  [-78.08356, 61.931070002],
  [-78.0859799, 61.931369902],
  [-78.0876201, 61.934090002],
  [-78.08923, 61.934259902],
  [-78.0891899, 61.934770002],
  [-78.0881, 61.934750002],
  [-78.08736, 61.936780102],
  [-78.0862801, 61.936760002],
  [-78.08702, 61.937540002],
  [-78.0860799, 61.938800102],
  [-78.0879601, 61.939100002],
  [-78.08789, 61.939860002],
  [-78.08989, 61.941699902],
  [-78.09122, 61.941980002],
  [-78.0911701, 61.942489902],
  [-78.095, 61.942190002],
  [-78.09536, 61.944120102],
  [-78.09645, 61.944149902],
  [-78.0947699, 61.944620002],
  [-78.0967, 61.947220002],
  [-78.0988699, 61.947259902],
  [-78.0987699, 61.948280002],
  [-78.09771, 61.948010102],
  [-78.0972901, 61.949540002],
  [-78.09616, 61.950020102],
  [-78.0986301, 61.952630002],
  [-78.0982099, 61.954159902],
  [-78.0993001, 61.954180002],
  [-78.0999799, 61.958290002],
  [-78.1010999, 61.958059902],
  [-78.10093, 61.959850002],
  [-78.10203, 61.959860002],
  [-78.1021901, 61.960900002],
  [-78.1035299, 61.961190002],
  [-78.10449, 61.962480102],
  [-78.10558, 61.962510002],
  [-78.1055299, 61.963020102],
  [-78.10661, 61.963039902],
  [-78.1081099, 61.964480102],
  [-78.11138, 61.964420002],
  [-78.1091801, 61.964630002],
  [-78.10667, 61.968159902],
  [-78.1077599, 61.968190002],
  [-78.10759, 61.969980002],
  [-78.10867, 61.970000002],
  [-78.1083299, 61.970760002],
  [-78.10958, 61.971939902],
  [-78.1140899, 61.973179902],
  [-78.1137599, 61.973690102],
  [-78.11705, 61.973499902],
  [-78.1167799, 61.976310002],
  [-78.12006, 61.976120002],
  [-78.1185599, 61.976690102],
  [-78.1190999, 61.977640002],
  [-78.1204101, 61.978179902],
  [-78.11503, 61.977560002],
  [-78.11417, 61.978049902],
  [-78.1146201, 61.979080002],
  [-78.1139999, 61.979839902],
  [-78.1147, 61.981120002],
  [-78.1175, 61.980169902],
  [-78.1163001, 61.981410002],
  [-78.1146699, 61.981389902],
  [-78.1154101, 61.982169902],
  [-78.1147501, 61.983440002],
  [-78.1149999, 61.985660002],
  [-78.1164299, 61.985780002],
  [-78.11636, 61.986540002],
  [-78.11805, 61.986069902],
  [-78.1179899, 61.986580002],
  [-78.12068, 61.986890002],
  [-78.12061, 61.987660002],
  [-78.12172, 61.987420002],
  [-78.12157, 61.988959902],
  [-78.1232299, 61.988739902],
  [-78.12315, 61.989499902],
  [-78.12424, 61.989519902],
  [-78.1252299, 61.990570002],
  [-78.1236, 61.990540002],
  [-78.1241099, 61.990930102],
  [-78.12903, 61.990650002],
  [-78.1290801, 61.990140002],
  [-78.12802, 61.989870002],
  [-78.13024, 61.989389902],
  [-78.13515, 61.989240002],
  [-78.13528, 61.989920102],
  [-78.13671, 61.990040102],
  [-78.1366599, 61.990550002],
  [-78.13303, 61.991500102],
  [-78.13261, 61.993279902],
  [-78.13151, 61.993260102],
  [-78.13117, 61.994020002],
  [-78.13191, 61.994800002],
  [-78.1351901, 61.994619902],
  [-78.13569, 61.995140002],
  [-78.13574, 61.994629902],
  [-78.13791, 61.994660002],
  [-78.13785, 61.995440002],
  [-78.13895, 61.995210002],
  [-78.13836, 61.995710102],
  [-78.1394399, 61.995729902],
  [-78.13937, 61.996500102],
  [-78.1443101, 61.996090002],
  [-78.1430199, 61.992490102],
  [-78.1408899, 61.991920102],
  [-78.1394399, 61.989839902],
  [-78.14106, 61.990130002],
  [-78.1419999, 61.991690002],
  [-78.1446501, 61.992509902],
  [-78.1450901, 61.993540002],
  [-78.14672, 61.993580002],
  [-78.14541, 61.995849902],
  [-78.14705, 61.995890002],
  [-78.14562, 61.996629902],
  [-78.1451299, 61.998920002],
  [-78.1462199, 61.998940102],
  [-78.1472101, 61.999990002],
  [-78.1477999, 61.999490102],
  [-78.14995, 61.999790002],
  [-78.14927, 62.001309902],
  [-78.14705, 62.001770002],
  [-78.1466799, 62.002790002],
  [-78.14837, 62.005130002],
  [-78.1505599, 62.004920002],
  [-78.15052, 62.005430002],
  [-78.14886, 62.005650002],
  [-78.1487701, 62.006670002],
  [-78.15148, 62.006730102],
  [-78.15236, 62.009050102],
  [-78.1534401, 62.009089902],
  [-78.1523301, 62.009309902],
  [-78.15339, 62.009590002],
  [-78.15293, 62.011620102],
  [-78.1553101, 62.012440002],
  [-78.1554301, 62.013120002],
  [-78.15618, 62.012800002],
  [-78.15585, 62.012450002],
  [-78.1574599, 62.012739902],
  [-78.15739, 62.013510102],
  [-78.1534499, 62.014840102],
  [-78.1502001, 62.014639902],
  [-78.1488999, 62.016920002],
  [-78.1466699, 62.017390102],
  [-78.1466, 62.018150002],
  [-78.14439, 62.018360002],
  [-78.14416, 62.020920002],
  [-78.1430801, 62.020890002],
  [-78.1416, 62.022140002],
  [-78.1412001, 62.023409902],
  [-78.14227, 62.023690002],
  [-78.1406401, 62.023660002],
  [-78.1405401, 62.024680002],
  [-78.14274, 62.024470002],
  [-78.1426, 62.026000002],
  [-78.1398999, 62.025690002],
  [-78.14203, 62.026250002],
  [-78.14171, 62.026749902],
  [-78.14307, 62.026780002],
  [-78.1426799, 62.027120002],
  [-78.14436, 62.030390102],
  [-78.14328, 62.030370002],
  [-78.14316, 62.031659902],
  [-78.1462199, 62.030940002],
  [-78.14526, 62.032460002],
  [-78.1468899, 62.032490002],
  [-78.1452399, 62.032710002],
  [-78.14428, 62.034230002],
  [-78.14503, 62.034890002],
  [-78.14495, 62.035780002],
  [-78.1427501, 62.035989902],
  [-78.1423499, 62.037260002],
  [-78.1435699, 62.038820002],
  [-78.14269, 62.038649902],
  [-78.1416, 62.042370002],
  [-78.14269, 62.042390002],
  [-78.14304, 62.041630102],
  [-78.1444499, 62.041150002],
  [-78.1443, 62.042680002],
  [-78.1426699, 62.042649902],
  [-78.1447501, 62.043710002],
  [-78.14517, 62.044999902],
  [-78.14886, 62.046350002],
  [-78.1472299, 62.046319902],
  [-78.14605, 62.047319902],
  [-78.14496, 62.047300102],
  [-78.1454401, 62.050900002],
  [-78.14672, 62.051690002],
  [-78.1456401, 62.051669902],
  [-78.1456501, 62.053549902],
  [-78.1467501, 62.057319902],
  [-78.14783, 62.057350002],
  [-78.14839, 62.060170002],
  [-78.14995, 62.060970102],
  [-78.15014, 62.067630002],
  [-78.1517799, 62.067669902],
  [-78.15248, 62.068960002],
  [-78.15381, 62.069240002],
  [-78.15295, 62.069740002],
  [-78.15442, 62.071559902],
  [-78.15285, 62.073830002],
  [-78.1566699, 62.073659902],
  [-78.15606, 62.074410002],
  [-78.15272, 62.075109902],
  [-78.1542399, 62.079239902],
  [-78.15375, 62.081530102],
  [-78.15484, 62.081550102],
  [-78.1546, 62.084110102],
  [-78.1518999, 62.083799902],
  [-78.15241, 62.084320102],
  [-78.14981, 62.084899902],
  [-78.1501199, 62.085300002],
  [-78.1414499, 62.084610002],
  [-78.14785, 62.086270002],
  [-78.14683, 62.085490002],
  [-78.1506, 62.086080002],
  [-78.1506699, 62.085310102],
  [-78.1535599, 62.086390002],
  [-78.15314, 62.088050002],
  [-78.15586, 62.088229902],
  [-78.15581, 62.088740002],
  [-78.1536501, 62.088449902],
  [-78.1505401, 62.092469902],
  [-78.1494499, 62.092449902],
  [-78.14938, 62.093220102],
  [-78.15159, 62.093009902],
  [-78.15082, 62.095300002],
  [-78.1519099, 62.095320102],
  [-78.1515, 62.096850002],
  [-78.15272, 62.098410002],
  [-78.1493301, 62.099620002],
  [-78.14925, 62.100390002],
  [-78.1503301, 62.100520002],
  [-78.15075, 62.101950002],
  [-78.15184, 62.101970002],
  [-78.1528701, 62.108380002],
  [-78.15181, 62.108120102],
  [-78.1529801, 62.110190002],
  [-78.1514499, 62.111950002],
  [-78.1536, 62.112249902],
  [-78.15249, 62.112479902],
  [-78.15239, 62.113500002],
  [-78.15403, 62.113540002],
  [-78.1539801, 62.114050002],
  [-78.1528899, 62.114029902],
  [-78.1526799, 62.116330102],
  [-78.1537701, 62.116350102],
  [-78.1534301, 62.117110102],
  [-78.1547601, 62.117390002],
  [-78.1566899, 62.120120102],
  [-78.1577701, 62.120270002],
  [-78.1594, 62.123379902],
  [-78.16115, 62.125080002],
  [-78.1622299, 62.125230102],
  [-78.16218, 62.125740002],
  [-78.1654301, 62.126060002],
  [-78.16631, 62.128379902],
  [-78.16793, 62.128540002],
  [-78.17049, 62.130390002],
  [-78.1732599, 62.130060002],
  [-78.1726701, 62.130560102],
  [-78.17694, 62.131670102],
  [-78.1768306, 62.135081002],
  [-78.1753496, 62.136531602],
  [-78.17538, 62.139580102],
  [-78.17752, 62.140140102],
  [-78.1776, 62.142180001],
  [-78.1786899, 62.142200001],
  [-78.17927, 62.144780001],
  [-78.18032, 62.145310001],
  [-78.1804601, 62.149660001],
  [-78.17775, 62.152420001],
  [-78.17665, 62.152400001],
  [-78.1749073, 62.155556101],
  [-78.17304, 62.155910101],
  [-78.17097, 62.154590101],
  [-78.1681399, 62.155560001],
  [-78.1653961, 62.157657001],
  [-78.1644392, 62.159892201],
  [-78.1625724, 62.160904301],
  [-78.162036, 62.162938401],
  [-78.15871, 62.162790001],
  [-78.1540322, 62.165989301],
  [-78.15904, 62.165100001],
  [-78.15942, 62.166900001],
  [-78.1631101, 62.165440001],
  [-78.16351, 62.164169901],
  [-78.1684301, 62.164269901],
  [-78.1676601, 62.166560001],
  [-78.1741499, 62.167460001],
  [-78.17353, 62.168210001],
  [-78.1713, 62.168680001],
  [-78.1683201, 62.171179901],
  [-78.1650001, 62.171619901],
  [-78.1652499, 62.180590101],
  [-78.1705899, 62.182100001],
  [-78.1721001, 62.183540001],
  [-78.16993, 62.183240001],
  [-78.1712399, 62.186849901],
  [-78.1717899, 62.186859901],
  [-78.17186, 62.186100001],
  [-78.1760101, 62.185670001],
  [-78.17564, 62.186690001],
  [-78.17453, 62.186660001],
  [-78.1745, 62.187170101],
  [-78.17584, 62.187460001],
  [-78.1759001, 62.189760001],
  [-78.17138, 62.191210001],
  [-78.17105, 62.191959901],
  [-78.1716601, 62.194030001],
  [-78.1705699, 62.194000001],
  [-78.1710001, 62.195299901],
  [-78.1656, 62.194419901],
  [-78.1661001, 62.194940101],
  [-78.1627701, 62.195380101],
  [-78.1624, 62.196399901],
  [-78.1606899, 62.197130001],
  [-78.1607899, 62.198930101],
  [-78.15915, 62.198890001],
  [-78.1590299, 62.200170101],
  [-78.1601299, 62.200189901],
  [-78.1553101, 62.204700001],
  [-78.1531001, 62.204910001],
  [-78.1534301, 62.207220001],
  [-78.1523399, 62.207199901],
  [-78.15217, 62.208990001],
  [-78.15108, 62.208969901],
  [-78.1515, 62.210260001],
  [-78.1526, 62.210280101],
  [-78.1507899, 62.212030001],
  [-78.1496899, 62.212010001],
  [-78.1503, 62.214330001],
  [-78.14916, 62.214820001],
  [-78.1497701, 62.217000001],
  [-78.1563201, 62.217390101],
  [-78.15695, 62.216510101],
  [-78.1596701, 62.216700001],
  [-78.1606799, 62.217610101],
  [-78.15959, 62.217590001],
  [-78.15952, 62.218360001],
  [-78.15461, 62.218000001],
  [-78.15427, 62.218749901],
  [-78.15597, 62.221099901],
  [-78.1515599, 62.221270001],
  [-78.1514699, 62.222290101],
  [-78.1531101, 62.222319901],
  [-78.15329, 62.223350001],
  [-78.15237, 62.224350001],
  [-78.15827, 62.225759901],
  [-78.1574599, 62.228549901],
  [-78.1585501, 62.228580001],
  [-78.1582201, 62.229080101],
  [-78.1628801, 62.229180101],
  [-78.16284, 62.229690001],
  [-78.16117, 62.229910001],
  [-78.1618999, 62.230950101],
  [-78.16153, 62.231970101],
  [-78.1598801, 62.231930001],
  [-78.16183, 62.234530101],
  [-78.16293, 62.234549901],
  [-78.16286, 62.235319901],
  [-78.16504, 62.235490001],
  [-78.16552, 62.236140001],
  [-78.1688801, 62.235439901],
  [-78.16693, 62.238730001],
  [-78.16409, 62.239700001],
  [-78.16226, 62.241710001],
  [-78.1633499, 62.241730001],
  [-78.16104, 62.242960101],
  [-78.16239, 62.243250001],
  [-78.1623201, 62.244010001],
  [-78.1670001, 62.243850101],
  [-78.1674699, 62.244630101],
  [-78.1629901, 62.245559901],
  [-78.1628999, 62.246590001],
  [-78.1617801, 62.246820001],
  [-78.16186, 62.245920001],
  [-78.1607801, 62.245769901],
  [-78.1612, 62.247060001],
  [-78.15793, 62.246740101],
  [-78.1567, 62.248250001],
  [-78.1544599, 62.248720001],
  [-78.15548, 62.249510001],
  [-78.15382, 62.249730001],
  [-78.1548901, 62.250009901],
  [-78.1542399, 62.251020001],
  [-78.15317, 62.250740101],
  [-78.1515599, 62.253260001],
  [-78.15162, 62.255569901],
  [-78.15271, 62.255590001],
  [-78.1530199, 62.258160001],
  [-78.1541199, 62.258180001],
  [-78.15251, 62.260710001],
  [-78.15259, 62.262760101],
  [-78.1509901, 62.262210101],
  [-78.15085, 62.263760101],
  [-78.15219, 62.264030001],
  [-78.1512001, 62.265800001],
  [-78.1489801, 62.266009901],
  [-78.14929, 62.268580001],
  [-78.1466799, 62.269129901],
  [-78.1456601, 62.272090101],
  [-78.1467601, 62.272109901],
  [-78.14494, 62.273870101],
  [-78.1433201, 62.273579901],
  [-78.1418601, 62.274569901],
  [-78.13994, 62.277349901],
  [-78.14047, 62.277610001],
  [-78.13906, 62.280909901],
  [-78.13729, 62.282160001],
  [-78.13769, 62.283710001],
  [-78.1361199, 62.285720001],
  [-78.13503, 62.285689901],
  [-78.13483, 62.287740001],
  [-78.13147, 62.288440101],
  [-78.1284301, 62.291450101],
  [-78.1295301, 62.291469901],
  [-78.1277601, 62.292710001],
  [-78.1246501, 62.293670101],
  [-78.1241801, 62.295710001],
  [-78.1264201, 62.295239901],
  [-78.1288501, 62.292860001],
  [-78.1310801, 62.292520001],
  [-78.13114, 62.291760001],
  [-78.13614, 62.291349901],
  [-78.13651, 62.290330101],
  [-78.1389999, 62.290129901],
  [-78.13905, 62.289620001],
  [-78.1404499, 62.289390001],
  [-78.1426401, 62.286490001],
  [-78.1437601, 62.286390001],
  [-78.14393, 62.287410001],
  [-78.14473, 62.287689901],
  [-78.14508, 62.286930001],
  [-78.14762, 62.286210101],
  [-78.14772, 62.285190001],
  [-78.14994, 62.284980001],
  [-78.1504, 62.282940001],
  [-78.1522001, 62.281440101],
  [-78.1522101, 62.278370001],
  [-78.14997, 62.278830001],
  [-78.15115, 62.277960001],
  [-78.15281, 62.277870101],
  [-78.1546, 62.276370001],
  [-78.15028, 62.275510001],
  [-78.1523201, 62.274270001],
  [-78.15229, 62.271710001],
  [-78.15483, 62.270999901],
  [-78.1551001, 62.268190001],
  [-78.1604401, 62.263940001],
  [-78.16341, 62.258630001],
  [-78.16451, 62.258650101],
  [-78.1656701, 62.255090101],
  [-78.17186, 62.253430101],
  [-78.1722599, 62.252150001],
  [-78.17721, 62.251999901],
  [-78.17919, 62.254210101],
  [-78.18195, 62.254150001],
  [-78.1819001, 62.254650101],
  [-78.1857801, 62.254350001],
  [-78.1866801, 62.253470001],
  [-78.18921, 62.252879901],
  [-78.19139, 62.253050001],
  [-78.1933401, 62.255650101],
  [-78.1922499, 62.255630101],
  [-78.1922, 62.256140001],
  [-78.19005, 62.255590001],
  [-78.18943, 62.256339901],
  [-78.1835, 62.255200101],
  [-78.1833301, 62.256989901],
  [-78.1800001, 62.257430101],
  [-78.18008, 62.256659901],
  [-78.1762301, 62.256590001],
  [-78.17618, 62.257100101],
  [-78.17509, 62.257080101],
  [-78.17494, 62.258610001],
  [-78.16996, 62.259020001],
  [-78.1704401, 62.259800001],
  [-78.1693401, 62.259769901],
  [-78.1703699, 62.260559901],
  [-78.16927, 62.260540101],
  [-78.1679901, 62.262559901],
  [-78.1663599, 62.262270001],
  [-78.16604, 62.262779901],
  [-78.1667899, 62.263559901],
  [-78.16896, 62.263860101],
  [-78.1666999, 62.264580001],
  [-78.16662, 62.265360001],
  [-78.16772, 62.265370001],
  [-78.1684, 62.266920001],
  [-78.1672499, 62.267410001],
  [-78.16738, 62.268950001],
  [-78.16485, 62.268730001],
  [-78.16462, 62.269150001],
  [-78.16783, 62.269980101],
  [-78.1671001, 62.272019901],
  [-78.16618, 62.272080001],
  [-78.1664599, 62.272899901],
  [-78.17029, 62.273110101],
  [-78.1690101, 62.275129901],
  [-78.1678999, 62.275100101],
  [-78.1668999, 62.274060001],
  [-78.1630199, 62.274490001],
  [-78.1636501, 62.276550101],
  [-78.16273, 62.277559901],
  [-78.1600099, 62.277249901],
  [-78.16094, 62.279060001],
  [-78.15927, 62.279280001],
  [-78.1592201, 62.279789901],
  [-78.1608701, 62.279820001],
  [-78.16183, 62.284200001],
  [-78.16086, 62.285710001],
  [-78.1592101, 62.285679901],
  [-78.1585699, 62.286689901],
  [-78.1569099, 62.286660101],
  [-78.1563, 62.287410001],
  [-78.1547, 62.286870001],
  [-78.15404, 62.288129901],
  [-78.15239, 62.288100101],
  [-78.1528899, 62.288620001],
  [-78.1512299, 62.288589901],
  [-78.1512001, 62.289100101],
  [-78.14681, 62.289009901],
  [-78.14636, 62.290789901],
  [-78.1479099, 62.291850001],
  [-78.14582, 62.293610001],
  [-78.14792, 62.294660101],
  [-78.14959, 62.294440101],
  [-78.14974, 62.292909901],
  [-78.15195, 62.292699901],
  [-78.15353, 62.293500001],
  [-78.15214, 62.296540001],
  [-78.1543599, 62.296330101],
  [-78.1543101, 62.296840001],
  [-78.15882, 62.295660101],
  [-78.1569801, 62.297670101],
  [-78.1558901, 62.297640001],
  [-78.1568115, 62.299054601],
  [-78.1555796, 62.299299301],
  [-78.149523, 62.296864701],
  [-78.1480082, 62.296734401],
  [-78.1467819, 62.297384101],
  [-78.1478977, 62.298421501],
  [-78.1462827, 62.298963801],
  [-78.1498826, 62.299887801],
  [-78.147887, 62.300835401],
  [-78.1427956, 62.301329001],
  [-78.1453228, 62.302979701],
  [-78.1470931, 62.302406301],
  [-78.1490135, 62.302959801],
  [-78.1477525, 62.304425501],
  [-78.1403124, 62.301715601],
  [-78.1401774, 62.302286001],
  [-78.1374818, 62.301823201],
  [-78.1325823, 62.302174401],
  [-78.1354091, 62.302945801],
  [-78.1303922, 62.303899001],
  [-78.1262577, 62.303443501],
  [-78.1276719, 62.302079101],
  [-78.1260334, 62.301198501],
  [-78.1215853, 62.302535901],
  [-78.1177229, 62.301688201],
  [-78.1140053, 62.303391101],
  [-78.1133562, 62.304495701],
  [-78.111763, 62.304425901],
  [-78.1087021, 62.305583001],
  [-78.1089735, 62.306592401],
  [-78.1083786, 62.306893201],
  [-78.1076539, 62.306515101],
  [-78.1076485, 62.305627601],
  [-78.1064042, 62.305334201],
  [-78.1079415, 62.304824701],
  [-78.10893, 62.303670101],
  [-78.1069799, 62.303029901],
  [-78.1065299, 62.302000101],
  [-78.10004, 62.300840001],
  [-78.0991701, 62.301330101],
  [-78.09869, 62.303369901],
  [-78.1008335, 62.303923601],
  [-78.1005943, 62.304625301],
  [-78.1015242, 62.306035701],
  [-78.1049936, 62.306799901],
  [-78.1025563, 62.307943801],
  [-78.1006, 62.306479901],
  [-78.0983412, 62.306609901],
  [-78.0942633, 62.307917101],
  [-78.0938459, 62.308838601],
  [-78.0947, 62.310579901],
  [-78.0958092, 62.310947501],
  [-78.1003359, 62.309633801],
  [-78.1014741, 62.308524501],
  [-78.10251, 62.308639001],
  [-78.1018943, 62.309980301],
  [-78.1002472, 62.310608401],
  [-78.10014, 62.311340101],
  [-78.1027669, 62.313310401],
  [-78.1065488, 62.313288001],
  [-78.1072815, 62.312012401],
  [-78.1097431, 62.311899601],
  [-78.1132051, 62.313818701],
  [-78.11481, 62.315748001],
  [-78.1162691, 62.314965401],
  [-78.1154966, 62.313943501],
  [-78.1159043, 62.313450001],
  [-78.115046, 62.313001401],
  [-78.1165122, 62.312379401],
  [-78.1232942, 62.312173801],
  [-78.1240661, 62.311474501],
  [-78.1262308, 62.312193801],
  [-78.1271242, 62.311630001],
  [-78.1300449, 62.311755101],
  [-78.1299672, 62.312476701],
  [-78.1312949, 62.311937001],
  [-78.1311607, 62.312954001],
  [-78.1300128, 62.313350301],
  [-78.1304634, 62.313549701],
  [-78.1328988, 62.313335401],
  [-78.1326091, 62.312891701],
  [-78.1336337, 62.312378201],
  [-78.1347388, 62.312816901],
  [-78.1339395, 62.313213201],
  [-78.1353718, 62.313295501],
  [-78.1361336, 62.314105501],
  [-78.1385579, 62.313549501],
  [-78.1385583, 62.314108001],
  [-78.1312895, 62.319406501],
  [-78.1249648, 62.318536801],
  [-78.1245035, 62.319633301],
  [-78.1279361, 62.320096701],
  [-78.1284732, 62.322379301],
  [-78.1276524, 62.322935001],
  [-78.1237306, 62.322274101],
  [-78.1190495, 62.322933601],
  [-78.1186699, 62.324520001],
  [-78.12004, 62.324550001],
  [-78.1190701, 62.326080001],
  [-78.1168699, 62.326020101],
  [-78.1177799, 62.328090001],
  [-78.11668, 62.328070001],
  [-78.11661, 62.328829901],
  [-78.1155101, 62.328809901],
  [-78.1131099, 62.330810101],
  [-78.11146, 62.330770001],
  [-78.11082, 62.331790101],
  [-78.1118899, 62.332059901],
  [-78.1085201, 62.332760001],
  [-78.10769, 62.329929901],
  [-78.10659, 62.329910101],
  [-78.10734, 62.327870001],
  [-78.1061701, 62.328620001],
  [-78.1045201, 62.328580101],
  [-78.1035201, 62.330350101],
  [-78.1020801, 62.331090001],
  [-78.1059, 62.331430001],
  [-78.1055, 62.332700101],
  [-78.10602, 62.332970001],
  [-78.1050899, 62.334220001],
  [-78.11005, 62.334070001],
  [-78.1094199, 62.334829901],
  [-78.1071801, 62.335290001],
  [-78.11323, 62.335159901],
  [-78.1160901, 62.334200001],
  [-78.11525, 62.337250101],
  [-78.11415, 62.337230001],
  [-78.1126599, 62.335660001],
  [-78.11045, 62.335620001],
  [-78.1114201, 62.336920101],
  [-78.10978, 62.336880001],
  [-78.1097, 62.337650001],
  [-78.1075, 62.337599901],
  [-78.10601, 62.341670001],
  [-78.1026201, 62.342619901],
  [-78.1024399, 62.344410001],
  [-78.0990999, 62.344850001],
  [-78.0970999, 62.348399901],
  [-78.096, 62.348370101],
  [-78.0958799, 62.352470101],
  [-78.0919401, 62.356220001],
  [-78.0908599, 62.355949901],
  [-78.09079, 62.356710101],
  [-78.0885399, 62.357179901],
  [-78.08779, 62.359210001],
  [-78.08669, 62.359190001],
  [-78.0861899, 62.360619901],
  [-78.0870899, 62.360719901],
  [-78.08704, 62.361240001],
  [-78.0882701, 62.359990001],
  [-78.08993, 62.359770001],
  [-78.09037, 62.361059901],
  [-78.0892901, 62.360780001],
  [-78.08914, 62.362310001],
  [-78.08412, 62.362970001],
  [-78.08558, 62.362240001],
  [-78.0848699, 62.360939901],
  [-78.08479, 62.361710101],
  [-78.08312, 62.361930101],
  [-78.0801701, 62.363910001],
  [-78.0779401, 62.364120001],
  [-78.07854, 62.363619901],
  [-78.0763901, 62.363069901],
  [-78.0776399, 62.361560001],
  [-78.0787399, 62.361580001],
  [-78.0819599, 62.356780001],
  [-78.0803099, 62.356750001],
  [-78.0802701, 62.357260101],
  [-78.07532, 62.357159901],
  [-78.07402, 62.359179901],
  [-78.07237, 62.359140101],
  [-78.07279, 62.360430001],
  [-78.0757399, 62.361260101],
  [-78.0752701, 62.363299901],
  [-78.07246, 62.363750001],
  [-78.07344, 62.365050101],
  [-78.0694901, 62.365990001],
  [-78.07045, 62.367550001],
  [-78.0643701, 62.367680001],
  [-78.06389, 62.366900001],
  [-78.0707399, 62.364480101],
  [-78.0679699, 62.364680001],
  [-78.0680699, 62.363660001],
  [-78.07087, 62.363210001],
  [-78.0665199, 62.362600101],
  [-78.0660699, 62.361570001],
  [-78.0649699, 62.361550001],
  [-78.06492, 62.362059901],
  [-78.0605199, 62.361959901],
  [-78.06092, 62.363509901],
  [-78.05034, 62.364570001],
  [-78.0502899, 62.365080001],
  [-78.0481601, 62.364389901],
  [-78.0453899, 62.364460001],
  [-78.04545, 62.363949901],
  [-78.04267, 62.364150101],
  [-78.04277, 62.363120001],
  [-78.03887, 62.363550001],
  [-78.03812, 62.362760001],
  [-78.0396199, 62.361519901],
  [-78.03742, 62.361470101],
  [-78.03875, 62.359200001],
  [-78.0398399, 62.359350001],
  [-78.0402, 62.358460001],
  [-78.0421699, 62.357990001],
  [-78.04224, 62.357350001],
  [-78.0443999, 62.357780001],
  [-78.04445, 62.357270101],
  [-78.04555, 62.357289901],
  [-78.04488, 62.355739901],
  [-78.0473999, 62.355289901],
  [-78.0470499, 62.353230001],
  [-78.0447001, 62.354719901],
  [-78.04246, 62.355040101],
  [-78.04085, 62.354630001],
  [-78.0409301, 62.353870001],
  [-78.0398299, 62.353849901],
  [-78.0404801, 62.352829901],
  [-78.0438299, 62.352389901],
  [-78.0442799, 62.350609901],
  [-78.0434801, 62.350340001],
  [-78.04357, 62.349440001],
  [-78.04466, 62.349599901],
  [-78.0447299, 62.348829901],
  [-78.0458299, 62.348850001],
  [-78.0496001, 62.346880001],
  [-78.0496001, 62.344070001],
  [-78.0506299, 62.344850001],
  [-78.0499499, 62.346120001],
  [-78.0521799, 62.345910101],
  [-78.05213, 62.346430001],
  [-78.051, 62.346660001],
  [-78.05702, 62.347049901],
  [-78.05612, 62.350609901],
  [-78.0621899, 62.350480101],
  [-78.06224, 62.349970001],
  [-78.05902, 62.349130101],
  [-78.06, 62.344800101],
  [-78.0611, 62.344819901],
  [-78.0607499, 62.342770001],
  [-78.05912, 62.342480101],
  [-78.0585001, 62.343230001],
  [-78.0546001, 62.343660001],
  [-78.0550699, 62.344440001],
  [-78.0528499, 62.344650001],
  [-78.0529, 62.344140101],
  [-78.0518, 62.344110001],
  [-78.0517299, 62.344880001],
  [-78.0511799, 62.344870001],
  [-78.0508, 62.343070001],
  [-78.04688, 62.343750001],
  [-78.0463801, 62.343230001],
  [-78.0474801, 62.343250101],
  [-78.04711, 62.341460101],
  [-78.04488, 62.341660001],
  [-78.0449301, 62.341150101],
  [-78.0427399, 62.341110001],
  [-78.0428101, 62.340350101],
  [-78.04064, 62.340039901],
  [-78.0405901, 62.340550001],
  [-78.0389399, 62.340510001],
  [-78.03889, 62.341020101],
  [-78.04156, 62.341850001],
  [-78.0419301, 62.343650001],
  [-78.0408299, 62.343630001],
  [-78.04133, 62.344150101],
  [-78.04023, 62.344120001],
  [-78.03901, 62.345379901],
  [-78.04011, 62.345399901],
  [-78.03746, 62.347140101],
  [-78.03696, 62.349430001],
  [-78.03831, 62.349719901],
  [-78.0384801, 62.350740001],
  [-78.03568, 62.351200001],
  [-78.03646, 62.351729901],
  [-78.0359499, 62.354020001],
  [-78.0286801, 62.355140101],
  [-78.02855, 62.356420001],
  [-78.0269, 62.356389901],
  [-78.0268299, 62.357150101],
  [-78.0257199, 62.357130001],
  [-78.02563, 62.358150101],
  [-78.0233899, 62.358360101],
  [-78.02333, 62.359120001],
  [-78.0271599, 62.359330001],
  [-78.02819, 62.360120001],
  [-78.03043, 62.359790001],
  [-78.0302699, 62.361320001],
  [-78.02697, 62.361250101],
  [-78.02575, 62.362509901],
  [-78.0294999, 62.363609901],
  [-78.0267001, 62.364059901],
  [-78.02664, 62.364570001],
  [-78.02277, 62.364750001],
  [-78.02287, 62.363719901],
  [-78.02122, 62.363690001],
  [-78.0212699, 62.363179901],
  [-78.0162699, 62.363580001],
  [-78.0165701, 62.366150101],
  [-78.0149199, 62.366110001],
  [-78.01487, 62.366619901],
  [-78.01318, 62.366970001],
  [-78.0088199, 62.366490101],
  [-78.00877, 62.367010001],
  [-78.0071399, 62.366710101],
  [-78.00708, 62.367230001],
  [-78.00599, 62.367200001],
  [-78.00619, 62.367969901],
  [-78.00861, 62.368540001],
  [-78.0085601, 62.369050101],
  [-78.0107801, 62.368969901],
  [-78.0112599, 62.369619901],
  [-78.00909, 62.369310001],
  [-78.0090399, 62.369830101],
  [-78.0062599, 62.370020001],
  [-78.00453, 62.373570001],
  [-78.0064, 62.374130001],
  [-78.00562, 62.376409901],
  [-78.00452, 62.376390101],
  [-78.00497, 62.377419901],
  [-78.0040445, 62.377957301],
  [-78.0001776, 62.378772201],
  [-77.9981186, 62.377864101],
  [-77.9927953, 62.379493901],
  [-77.9912887, 62.379191301],
  [-77.9871205, 62.379982901],
  [-77.9866807, 62.381418501],
  [-77.98218, 62.382820101],
  [-77.9816999, 62.383600101],
  [-77.9806, 62.383619901],
  [-77.98065, 62.384130001],
  [-77.9790001, 62.384160101],
  [-77.97963, 62.384920001],
  [-77.97853, 62.384940101],
  [-77.9781001, 62.386230001],
  [-77.97431, 62.386950101],
  [-77.9693, 62.386419901],
  [-77.9700001, 62.387940101],
  [-77.96229, 62.388110001],
  [-77.9630001, 62.389629901],
  [-77.95482, 62.390570001],
  [-77.95274, 62.389079901],
  [-77.9550797, 62.386222301],
  [-77.9603068, 62.385039501],
  [-77.94981, 62.384529901],
  [-77.94938, 62.385820101],
  [-77.94828, 62.385840101],
  [-77.94848, 62.387890001],
  [-77.9463101, 62.388189901],
  [-77.9464101, 62.389210001],
  [-77.9442299, 62.389510101],
  [-77.9443101, 62.390280101],
  [-77.9432001, 62.390299901],
  [-77.94225, 62.391859901],
  [-77.94115, 62.391880001],
  [-77.94238, 62.393140001],
  [-77.9396501, 62.393450001],
  [-77.9396, 62.392940101],
  [-77.9356401, 62.391869901],
  [-77.9329, 62.392060101],
  [-77.9328501, 62.391550001],
  [-77.92834, 62.390620101],
  [-77.9253199, 62.387859901],
  [-77.9264299, 62.387840101],
  [-77.92739, 62.386540001],
  [-77.9295399, 62.385979901],
  [-77.92947, 62.385220001],
  [-77.93059, 62.385450001],
  [-77.93082, 62.384930101],
  [-77.9348601, 62.384079901],
  [-77.9358799, 62.383160101],
  [-77.9375201, 62.383000001],
  [-77.9374499, 62.382230001],
  [-77.9400801, 62.380900001],
  [-77.94493, 62.379770001],
  [-77.9448799, 62.379260101],
  [-77.9459801, 62.379240001],
  [-77.9488, 62.377130001],
  [-77.95228, 62.376030001],
  [-77.9527, 62.374739901],
  [-77.9538, 62.374720101],
  [-77.95538, 62.373920001],
  [-77.9553299, 62.373409901],
  [-77.9579999, 62.372580001],
  [-77.95795, 62.372069901],
  [-77.96225, 62.370830101],
  [-77.9647999, 62.368849901],
  [-77.9658901, 62.368700101],
  [-77.9668901, 62.367780001],
  [-77.9657999, 62.367810101],
  [-77.96618, 62.366010001],
  [-77.96937, 62.364910001],
  [-77.9695699, 62.364140101],
  [-77.9714499, 62.363590101],
  [-77.97137, 62.362820101],
  [-77.9724699, 62.362800001],
  [-77.9734599, 62.361499901],
  [-77.9745401, 62.361470101],
  [-77.97449, 62.360959901],
  [-77.97559, 62.360939901],
  [-77.97552, 62.360169901],
  [-77.97664, 62.360409901],
  [-77.97649, 62.358870001],
  [-77.9784401, 62.359090001],
  [-77.97684, 62.356820101],
  [-77.9752101, 62.357110001],
  [-77.97461, 62.356609901],
  [-77.97571, 62.356590101],
  [-77.97559, 62.355310001],
  [-77.97771, 62.354490101],
  [-77.9781299, 62.353210001],
  [-77.9792301, 62.353179901],
  [-77.9794301, 62.352410001],
  [-77.97886, 62.352169901],
  [-77.97953, 62.350619901],
  [-77.98118, 62.350580101],
  [-77.9829001, 62.348489901],
  [-77.9825, 62.347220001],
  [-77.98026, 62.346890001],
  [-77.9797701, 62.347540001],
  [-77.9776, 62.347839901],
  [-77.9773599, 62.350920101],
  [-77.9719801, 62.352310001],
  [-77.9710099, 62.353609901],
  [-77.9693599, 62.353650001],
  [-77.9681199, 62.363399901],
  [-77.96272, 62.364540001],
  [-77.9582299, 62.369250001],
  [-77.95658, 62.369279901],
  [-77.9566799, 62.370299901],
  [-77.95295, 62.371660001],
  [-77.95303, 62.372430001],
  [-77.95193, 62.372450001],
  [-77.94885, 62.374820101],
  [-77.9466401, 62.374739901],
  [-77.94505, 62.375420001],
  [-77.94385, 62.374409901],
  [-77.93835, 62.374530001],
  [-77.9363299, 62.376370101],
  [-77.9352199, 62.376390101],
  [-77.9359701, 62.378419901],
  [-77.93173, 62.380179901],
  [-77.91928, 62.382610101],
  [-77.9178401, 62.384690001],
  [-77.92003, 62.384519901],
  [-77.9206401, 62.385150001],
  [-77.91736, 62.385470001],
  [-77.9178601, 62.384950101],
  [-77.9162199, 62.385240001],
  [-77.91258, 62.381729901],
  [-77.9142099, 62.381440001],
  [-77.9041801, 62.380370101],
  [-77.9035101, 62.379100001],
  [-77.8957301, 62.378500101],
  [-77.8958499, 62.379780001],
  [-77.89725, 62.380000001],
  [-77.89768, 62.381529901],
  [-77.9022085, 62.383188701],
  [-77.9046437, 62.385278201],
  [-77.9068962, 62.386032901],
  [-77.9086635, 62.388241401],
  [-77.9113689, 62.389201701],
  [-77.9113736, 62.389995901],
  [-77.9125909, 62.391011001],
  [-77.9142994, 62.391343101],
  [-77.9208116, 62.394887201],
  [-77.9246655, 62.395768201],
  [-77.929692, 62.398854201],
  [-77.9302126, 62.399301301],
  [-77.9298121, 62.399682301],
  [-77.9388316, 62.403981201],
  [-77.940199, 62.405526801],
  [-77.9385492, 62.409734601],
  [-77.9370838, 62.410938101],
  [-77.938549, 62.411150401],
  [-77.938352, 62.411989601],
  [-77.939488, 62.412660101],
  [-77.941481, 62.413009101],
  [-77.9407457, 62.414661701],
  [-77.9396143, 62.415367501],
  [-77.9400378, 62.416018101],
  [-77.9418454, 62.415979901],
  [-77.9407926, 62.417346001],
  [-77.9395583, 62.417778301],
  [-77.9402762, 62.418153501],
  [-77.9279523, 62.425037401],
  [-77.923839, 62.425799001],
  [-77.9234, 62.425262001],
  [-77.9215148, 62.426252801],
  [-77.9221888, 62.426376801],
  [-77.9211271, 62.427335201],
  [-77.9121471, 62.429386001],
  [-77.9113488, 62.427492801],
  [-77.9117924, 62.426730601],
  [-77.9141162, 62.426206301],
  [-77.9133816, 62.423496601],
  [-77.9117931, 62.422511301],
  [-77.9125766, 62.422440001],
  [-77.9122402, 62.420627301],
  [-77.9093194, 62.419040101],
  [-77.9099218, 62.418347201],
  [-77.9091752, 62.417592501],
  [-77.9070689, 62.417926801],
  [-77.9049005, 62.416696201],
  [-77.9032452, 62.416970301],
  [-77.9047514, 62.416620801],
  [-77.9031242, 62.416022601],
  [-77.9021128, 62.416139401],
  [-77.9023822, 62.417284101],
  [-77.9012818, 62.415878201],
  [-77.9001482, 62.415560201],
  [-77.9019194, 62.415317401],
  [-77.9003957, 62.414610801],
  [-77.8993491, 62.415054001],
  [-77.8997284, 62.414486701],
  [-77.89532, 62.414220001],
  [-77.89248, 62.413380001],
  [-77.89166, 62.410580001],
  [-77.88389, 62.410240001],
  [-77.8814625, 62.406306201],
  [-77.8762322, 62.404032601],
  [-77.8750401, 62.404270001],
  [-77.8746957, 62.404669901],
  [-77.8806101, 62.413370001],
  [-77.8836587, 62.415224101],
  [-77.8853116, 62.417026801],
  [-77.8806821, 62.418704701],
  [-77.883502, 62.419915501],
  [-77.8870335, 62.420197301],
  [-77.8884447, 62.419568301],
  [-77.8914413, 62.421384101],
  [-77.8939599, 62.425900001],
  [-77.89546, 62.427140001],
  [-77.8776199, 62.431099901],
  [-77.8777, 62.429050001],
  [-77.87676, 62.428040001],
  [-77.8750699, 62.427570001],
  [-77.87512, 62.428080101],
  [-77.87131, 62.428669901],
  [-77.87088, 62.429960101],
  [-77.86053, 62.431449901],
  [-77.86058, 62.431960101],
  [-77.85786, 62.432400001],
  [-77.85332, 62.431209901],
  [-77.8483799, 62.431439901],
  [-77.84833, 62.430930001],
  [-77.84557, 62.430989901],
  [-77.8456199, 62.431500001],
  [-77.83898, 62.431380001],
  [-77.8383601, 62.430610001],
  [-77.83332, 62.429960101],
  [-77.83321, 62.434570001],
  [-77.8304899, 62.435010001],
  [-77.8193, 62.433570001],
  [-77.82051, 62.440460001],
  [-77.82161, 62.440439901],
  [-77.8207199, 62.442760101],
  [-77.81962, 62.442779901],
  [-77.8196801, 62.443549901],
  [-77.8078199, 62.446600001],
  [-77.80787, 62.447109901],
  [-77.7995501, 62.446769901],
  [-77.7995899, 62.447280001],
  [-77.79794, 62.447310101],
  [-77.7974599, 62.448100101],
  [-77.7946999, 62.448150001],
  [-77.7928901, 62.449459901],
  [-77.79306, 62.451250001],
  [-77.7942, 62.451740001],
  [-77.7937999, 62.453280001],
  [-77.7885501, 62.456200101],
  [-77.78738, 62.458530101],
  [-77.78083, 62.459430101],
  [-77.7803499, 62.460210101],
  [-77.7755, 62.461579901],
  [-77.7745, 62.462630001],
  [-77.7706799, 62.463220101],
  [-77.7702001, 62.463990101],
  [-77.7646699, 62.463980101],
  [-77.76252, 62.464660101],
  [-77.7576799, 62.460150001],
  [-77.7505499, 62.460799901],
  [-77.7503299, 62.464390001],
  [-77.7416599, 62.466349901],
  [-77.7417599, 62.467370001],
  [-77.74016, 62.468050001],
  [-77.7318401, 62.467820001],
  [-77.7311099, 62.465789901],
  [-77.7221999, 62.465190001],
  [-77.7217301, 62.469040001],
  [-77.72044, 62.470090101],
  [-77.71933, 62.470110101],
  [-77.71938, 62.470630001],
  [-77.71614, 62.471459901],
  [-77.7110799, 62.476670101],
  [-77.7099799, 62.476699901],
  [-77.7080701, 62.480060001],
  [-77.7069601, 62.480080001],
  [-77.7049599, 62.482420001],
  [-77.6934099, 62.486230101],
  [-77.69111, 62.491400001],
  [-77.6924199, 62.493660101],
  [-77.6909301, 62.495500001],
  [-77.69244, 62.496880001],
  [-77.6941, 62.496970001],
  [-77.69446, 62.497990001],
  [-77.6823901, 62.508200001],
  [-77.6801899, 62.508500001],
  [-77.67875, 62.510829901],
  [-77.67658, 62.511390001],
  [-77.67609, 62.512170001],
  [-77.67066, 62.513290001],
  [-77.67022, 62.514580101],
  [-77.66688, 62.514390001],
  [-77.66377, 62.510610001],
  [-77.66041, 62.510159901],
  [-77.6574899, 62.508420001],
  [-77.6558299, 62.508450101],
  [-77.65534, 62.509230101],
  [-77.65154, 62.510070001],
  [-77.6499, 62.513430001],
  [-77.6475701, 62.515269901],
  [-77.64243, 62.513440001],
  [-77.6358299, 62.513950001],
  [-77.6356901, 62.515489901],
  [-77.63806, 62.517240101],
  [-77.6358099, 62.520100001],
  [-77.62986, 62.521620001],
  [-77.62653, 62.521550001],
  [-77.62531, 62.520290001],
  [-77.6202599, 62.519620001],
  [-77.6187801, 62.521700101],
  [-77.61718, 62.522369901],
  [-77.6127701, 62.522580101],
  [-77.61175, 62.523620001],
  [-77.61062, 62.523379901],
  [-77.6101399, 62.524159901],
  [-77.5986999, 62.526420001],
  [-77.59826, 62.527709901],
  [-77.59683, 62.527230001],
  [-77.5974401, 62.524650001],
  [-77.59407, 62.524200001],
  [-77.5936899, 62.522929901],
  [-77.59139, 62.521950001],
  [-77.58983, 62.519929901],
  [-77.5847601, 62.519010101],
  [-77.5832299, 62.517240101],
  [-77.5837, 62.513130101],
  [-77.57863, 62.512200001],
  [-77.5732001, 62.516660001],
  [-77.57394, 62.522020101],
  [-77.57506, 62.522120101],
  [-77.5772101, 62.524520001],
  [-77.5771001, 62.526570101],
  [-77.56894, 62.528250101],
  [-77.5668999, 62.530340001],
  [-77.5635401, 62.529890001],
  [-77.5637401, 62.532190001],
  [-77.56849, 62.532609901],
  [-77.56728, 62.534680001],
  [-77.56617, 62.534700101],
  [-77.56624, 62.535470101],
  [-77.56194, 62.537080001],
  [-77.55918, 62.540460001],
  [-77.55961, 62.542250101],
  [-77.5546, 62.541949901],
  [-77.5518899, 62.542640001],
  [-77.5514001, 62.543420001],
  [-77.5395499, 62.547480101],
  [-77.5385499, 62.548770001],
  [-77.5374399, 62.548790001],
  [-77.53813, 62.550320001],
  [-77.5359799, 62.551120001],
  [-77.53558, 62.552920101],
  [-77.5268599, 62.554739901],
  [-77.5201601, 62.554220001],
  [-77.52025, 62.555250001],
  [-77.51914, 62.555270101],
  [-77.5204001, 62.557040101],
  [-77.5192901, 62.557059901],
  [-77.5205299, 62.558570001],
  [-77.51834, 62.558859901],
  [-77.51736, 62.563749901],
  [-77.51858, 62.565000001],
  [-77.51826, 62.567820101],
  [-77.51967, 62.568060101],
  [-77.51933, 62.570619901],
  [-77.51822, 62.570639901],
  [-77.5162901, 62.574000001],
  [-77.50755, 62.575700001],
  [-77.5093901, 62.577710101],
  [-77.5105001, 62.577690001],
  [-77.51137, 62.581510101],
  [-77.5047201, 62.581629901],
  [-77.5040401, 62.580110001],
  [-77.49843, 62.579440001],
  [-77.4983901, 62.578930001],
  [-77.49612, 62.578330001],
  [-77.4851799, 62.580189901],
  [-77.4829182, 62.578147801],
  [-77.4797057, 62.577150901],
  [-77.4752552, 62.577557201],
  [-77.4690028, 62.580340601],
  [-77.45974, 62.581409901],
  [-77.4538497, 62.581191101],
  [-77.4535195, 62.581771801],
  [-77.4473408, 62.579481301],
  [-77.4440299, 62.579250001],
  [-77.43796, 62.579739901],
  [-77.4362078, 62.580666901],
  [-77.4239477, 62.580701001],
  [-77.4225398, 62.580254101],
  [-77.4217266, 62.577891701],
  [-77.41653, 62.575510101],
  [-77.41498, 62.573490101],
  [-77.4150109, 62.569047901],
  [-77.4144803, 62.567950901],
  [-77.41594, 62.564770001],
  [-77.4197568, 62.562101801],
  [-77.4207355, 62.560227901],
  [-77.4171292, 62.558036101],
  [-77.4178202, 62.556431601],
  [-77.4171156, 62.553639701],
  [-77.4173831, 62.551854301],
  [-77.4135911, 62.549067001],
  [-77.4136309, 62.546006501],
  [-77.4118872, 62.542635701],
  [-77.4112884, 62.535137601],
  [-77.4097524, 62.533332501],
  [-77.4085, 62.527530001],
  [-77.4081374, 62.526873801],
  [-77.4055204, 62.526354601],
  [-77.4035799, 62.525049901],
  [-77.3894023, 62.526426201],
  [-77.38871, 62.536570001],
  [-77.3876799, 62.537360101],
  [-77.3876799, 62.544269901],
  [-77.38327, 62.548179901],
  [-77.38163, 62.548460001],
  [-77.37728, 62.556470001],
  [-77.3680701, 62.559200001],
  [-77.3681099, 62.559700101],
  [-77.35702, 62.559760001],
  [-77.35139, 62.558700101],
  [-77.34816, 62.556450001],
  [-77.3481898, 62.555862201],
  [-77.346107, 62.555351801],
  [-77.3443199, 62.553450001],
  [-77.3432494, 62.553447401],
  [-77.3413901, 62.551450001],
  [-77.3402801, 62.551480101],
  [-77.3344514, 62.547540201],
  [-77.3316662, 62.547344101],
  [-77.3270676, 62.548132401],
  [-77.32434, 62.553010001],
  [-77.32214, 62.553560001],
  [-77.3195199, 62.555389901],
  [-77.31136, 62.557450001],
  [-77.2906099, 62.554340001],
  [-77.2900699, 62.551020001],
  [-77.28788, 62.547729901],
  [-77.2862511, 62.546620001],
  [-77.2822022, 62.546180001],
  [-77.2796749, 62.546652801],
  [-77.2787795, 62.547622501],
  [-77.2789054, 62.548775501],
  [-77.2749055, 62.551101901],
  [-77.2665044, 62.551232901],
  [-77.2615999, 62.548410001],
  [-77.2552902, 62.546219701],
  [-77.2464895, 62.546710401],
  [-77.2382431, 62.545538601],
  [-77.2276557, 62.546771701],
  [-77.2222492, 62.545934801],
  [-77.22119, 62.545470101],
  [-77.221382, 62.541719201],
  [-77.2178648, 62.540312401],
  [-77.2115291, 62.540304401],
  [-77.2027019, 62.541835501],
  [-77.1970446, 62.544137301],
  [-77.1891078, 62.544294401],
  [-77.188056, 62.543900401],
  [-77.1870657, 62.541631201],
  [-77.1854887, 62.540254401],
  [-77.17193, 62.538829901],
  [-77.1718899, 62.538320001],
  [-77.16346, 62.536660001],
  [-77.1618501, 62.529770001],
  [-77.1596201, 62.529550001],
  [-77.15702, 62.528049901],
  [-77.15726, 62.527540001],
  [-77.15616, 62.527560001],
  [-77.1560801, 62.526530001],
  [-77.1543099, 62.525020101],
  [-77.1559799, 62.521420001],
  [-77.16046, 62.518269901],
  [-77.16157, 62.518259901],
  [-77.16205, 62.517230101],
  [-77.16092, 62.516990001],
  [-77.1611999, 62.509310001],
  [-77.1591701, 62.508060001],
  [-77.15581, 62.507470101],
  [-77.1486499, 62.507970001],
  [-77.1487599, 62.513340101],
  [-77.14079, 62.521910101],
  [-77.13968, 62.521910101],
  [-77.13878, 62.524750001],
  [-77.13767, 62.524770001],
  [-77.1367499, 62.531180001],
  [-77.1328499, 62.534819901],
  [-77.1306399, 62.534860001],
  [-77.12647, 62.530829901],
  [-77.1225099, 62.529609901],
  [-77.1207499, 62.528350101],
  [-77.1158101, 62.528939901],
  [-77.1175001, 62.533260101],
  [-77.1153901, 62.534590101],
  [-77.11656, 62.535580101],
  [-77.11636, 62.536609901],
  [-77.1119401, 62.536800101],
  [-77.08957, 62.533939901],
  [-77.0862501, 62.533990001],
  [-77.0867299, 62.532960001],
  [-77.08174, 62.533030101],
  [-77.07987, 62.525900101],
  [-77.0760599, 62.522880001],
  [-77.07495, 62.522900101],
  [-77.0693401, 62.517860001],
  [-77.0686099, 62.515320001],
  [-77.0709101, 62.508630001],
  [-77.0683601, 62.499709901],
  [-77.0672501, 62.499730001],
  [-77.06663, 62.498709901],
  [-77.0601399, 62.498130101],
  [-77.0583599, 62.503180001],
  [-77.0589299, 62.503430001],
  [-77.0582301, 62.521350101],
  [-77.0593401, 62.521340001],
  [-77.06218, 62.526410001],
  [-77.06097, 62.528990001],
  [-77.05876, 62.529020101],
  [-77.0588099, 62.529790001],
  [-77.0569101, 62.530330001],
  [-77.05731, 62.532120001],
  [-77.0562, 62.532130101],
  [-77.0562599, 62.533159901],
  [-77.0551444, 62.533663201],
  [-77.05352, 62.533450001],
  [-77.0508901, 62.531440001],
  [-77.04196, 62.518269901],
  [-77.0353401, 62.518620001],
  [-77.0343, 62.523750001],
  [-77.03584, 62.526030101],
  [-77.03418, 62.526059901],
  [-77.03398, 62.527340001],
  [-77.0355, 62.529110001],
  [-77.03716, 62.529080001],
  [-77.0372, 62.529599901],
  [-77.03609, 62.529609901],
  [-77.0361399, 62.530379901],
  [-77.0350299, 62.530400001],
  [-77.0340099, 62.531690101],
  [-77.0329199, 62.531970001],
  [-77.03339, 62.530680001],
  [-77.03173, 62.530700101],
  [-77.03052, 62.529180001],
  [-77.02829, 62.528960001],
  [-77.02818, 62.527420001],
  [-77.02708, 62.527440001],
  [-77.02759, 62.526920101],
  [-77.02317, 62.526990001],
  [-77.0206799, 62.531120001],
  [-77.0162842, 62.532828401],
  [-77.0099373, 62.534201701],
  [-77.0018009, 62.534515901],
  [-76.9995784, 62.533507901],
  [-76.9908409, 62.532320301],
  [-76.9878034, 62.532819501],
  [-76.9829607, 62.535187301],
  [-76.97656, 62.532713101],
  [-76.9749356, 62.533386101],
  [-76.9744199, 62.535360101],
  [-76.9733615, 62.535841401],
  [-76.9686528, 62.535685701],
  [-76.9609962, 62.537047201],
  [-76.9576918, 62.535993801],
  [-76.9540387, 62.536108401],
  [-76.9484782, 62.537641501],
  [-76.9449377, 62.537839401],
  [-76.9276863, 62.537819001],
  [-76.9230505, 62.537328801],
  [-76.9225901, 62.535059901],
  [-76.9241501, 62.533250101],
  [-76.92365, 62.529929901],
  [-76.92531, 62.529770001],
  [-76.92689, 62.528599901],
  [-76.928, 62.528590101],
  [-76.9279699, 62.528080001],
  [-76.9290699, 62.528059901],
  [-76.9290401, 62.527550001],
  [-76.9301501, 62.527530001],
  [-76.93011, 62.527020101],
  [-76.9360699, 62.524890001],
  [-76.93914, 62.521010101],
  [-76.94348, 62.519670001],
  [-76.94345, 62.519159901],
  [-76.94455, 62.519140101],
  [-76.9471899, 62.517060001],
  [-76.95533, 62.514630001],
  [-76.95849, 62.507950001],
  [-76.9569699, 62.505660001],
  [-76.9547499, 62.505699901],
  [-76.95477, 62.506210001],
  [-76.9496499, 62.508070001],
  [-76.9499601, 62.508580101],
  [-76.9483099, 62.508860001],
  [-76.94781, 62.509630001],
  [-76.94558, 62.509410001],
  [-76.9450899, 62.510440001],
  [-76.9315199, 62.514460101],
  [-76.92431, 62.518660001],
  [-76.9198399, 62.522300001],
  [-76.9187199, 62.522190001],
  [-76.9138299, 62.523670001],
  [-76.9122501, 62.524960001],
  [-76.90734, 62.526059901],
  [-76.90098, 62.530489901],
  [-76.8939201, 62.532640001],
  [-76.8921401, 62.530870001],
  [-76.88989, 62.530389901],
  [-76.88841, 62.528870001],
  [-76.8924701, 62.518580101],
  [-76.8935701, 62.518560001],
  [-76.89618, 62.515970001],
  [-76.8972799, 62.515960001],
  [-76.90064, 62.512070001],
  [-76.89878, 62.504680101],
  [-76.89989, 62.504660001],
  [-76.8999201, 62.500819901],
  [-76.8967001, 62.498050001],
  [-76.8950201, 62.497819901],
  [-76.89233, 62.494530001],
  [-76.89364, 62.489139901],
  [-76.8886801, 62.484850001],
  [-76.88307, 62.483780101],
  [-76.88198, 62.483919901],
  [-76.88121, 62.484950001],
  [-76.88365, 62.488500001],
  [-76.8832699, 62.491320001],
  [-76.88018, 62.494950001],
  [-76.88378, 62.503870001],
  [-76.88489, 62.503840001],
  [-76.88373, 62.511790101],
  [-76.87506, 62.514719901],
  [-76.86851, 62.520690101],
  [-76.8656294, 62.525258201],
  [-76.8608237, 62.525705101],
  [-76.8551168, 62.524885601],
  [-76.8558884, 62.521943101],
  [-76.8549199, 62.515760001],
  [-76.85163, 62.511960001],
  [-76.85053, 62.511980001],
  [-76.8504799, 62.511210001],
  [-76.84384, 62.511300001],
  [-76.8394599, 62.521080001],
  [-76.8383599, 62.521090001],
  [-76.8379037, 62.522274601],
  [-76.8362761, 62.522745001],
  [-76.8218672, 62.522235101],
  [-76.8163955, 62.522508601],
  [-76.8168514, 62.523001601],
  [-76.8131559, 62.523607101],
  [-76.8084997, 62.522979801],
  [-76.8055487, 62.521309401],
  [-76.7999773, 62.520716201],
  [-76.7995599, 62.520934101],
  [-76.8006276, 62.522107401],
  [-76.796116, 62.522189301],
  [-76.7928458, 62.521309401],
  [-76.7920304, 62.520378701],
  [-76.7886323, 62.519039101],
  [-76.7869235, 62.519091501],
  [-76.7825652, 62.517453401],
  [-76.7831964, 62.517217201],
  [-76.781786, 62.516474701],
  [-76.7849672, 62.515468901],
  [-76.7853633, 62.514312901],
  [-76.7846159, 62.514310301],
  [-76.7856789, 62.513348001],
  [-76.7851372, 62.511886701],
  [-76.7828036, 62.510971001],
  [-76.78013, 62.510460101],
  [-76.77245, 62.511709901],
  [-76.7678946, 62.515020801],
  [-76.7685754, 62.515538201],
  [-76.7681265, 62.516002001],
  [-76.7659636, 62.516651101],
  [-76.762805, 62.515625701],
  [-76.7604232, 62.513664901],
  [-76.758492, 62.513575701],
  [-76.7580739, 62.513134301],
  [-76.7599599, 62.511100001],
  [-76.75957, 62.509060001],
  [-76.76067, 62.509039901],
  [-76.7625199, 62.502880001],
  [-76.7636299, 62.502870001],
  [-76.76656, 62.501039901],
  [-76.76614, 62.498479901],
  [-76.76503, 62.498500001],
  [-76.765, 62.497990001],
  [-76.76113, 62.498029901],
  [-76.7599599, 62.497029901],
  [-76.7479599, 62.499990001],
  [-76.7426185, 62.504850701],
  [-76.7339499, 62.506717401],
  [-76.7317558, 62.504958401],
  [-76.7277539, 62.503923201],
  [-76.721703, 62.503700801],
  [-76.7178941, 62.502590701],
  [-76.7177439, 62.501758501],
  [-76.7166389, 62.501589801],
  [-76.7132592, 62.497834801],
  [-76.7082781, 62.497713201],
  [-76.7063359, 62.497101401],
  [-76.7038399, 62.492860001],
  [-76.7049499, 62.492850001],
  [-76.7057299, 62.487210001],
  [-76.7068399, 62.487200001],
  [-76.7055999, 62.484909901],
  [-76.7039299, 62.484670101],
  [-76.7039, 62.484160001],
  [-76.7000399, 62.484459901],
  [-76.70063, 62.484970001],
  [-76.69952, 62.485239901],
  [-76.69833, 62.483459901],
  [-76.69722, 62.483469901],
  [-76.6989101, 62.479100101],
  [-76.701, 62.477029901],
  [-76.70067, 62.476009901],
  [-76.69398, 62.475070001],
  [-76.6924999, 62.478160001],
  [-76.69139, 62.478170001],
  [-76.68876, 62.480510001],
  [-76.6854701, 62.481060001],
  [-76.68284, 62.483390001],
  [-76.68173, 62.483410001],
  [-76.6802, 62.485730001],
  [-76.6683076, 62.488634401],
  [-76.6685629, 62.487309901],
  [-76.6660763, 62.485798001],
  [-76.6613961, 62.485525601],
  [-76.6549617, 62.486379401],
  [-76.6535211, 62.485902301],
  [-76.6545296, 62.485218301],
  [-76.6532383, 62.484895801],
  [-76.6544438, 62.484464901],
  [-76.6556025, 62.482551601],
  [-76.6553235, 62.481213201],
  [-76.6489184, 62.479894601],
  [-76.6494441, 62.479265001],
  [-76.6485751, 62.478218901],
  [-76.6447449, 62.477876901],
  [-76.6391328, 62.478950301],
  [-76.6373294, 62.478233701],
  [-76.6363228, 62.477009301],
  [-76.6345566, 62.477894001],
  [-76.6348999, 62.476410001],
  [-76.6365501, 62.476270001],
  [-76.6383101, 62.473180001],
  [-76.6365301, 62.470890101],
  [-76.6373101, 62.470119901],
  [-76.66319, 62.468150001],
  [-76.66509, 62.467490001],
  [-76.66575, 62.464410001],
  [-76.66185, 62.463940001],
  [-76.65984, 62.462689901],
  [-76.6597899, 62.461660101],
  [-76.6627701, 62.460600001],
  [-76.6632101, 62.458550101],
  [-76.66431, 62.458540101],
  [-76.6674799, 62.445960001],
  [-76.6663699, 62.445980101],
  [-76.6667999, 62.443669901],
  [-76.6656899, 62.443679901],
  [-76.6631001, 62.441669901],
  [-76.6630399, 62.440640101],
  [-76.6619099, 62.440140001],
  [-76.66339, 62.437060001],
  [-76.66353, 62.434500001],
  [-76.66187, 62.434520101],
  [-76.6629199, 62.433480001],
  [-76.65798, 62.434050001],
  [-76.65693, 62.435090101],
  [-76.65475, 62.435620001],
  [-76.65241, 62.438209901],
  [-76.6521199, 62.442820001],
  [-76.65451, 62.446119901],
  [-76.6507899, 62.453840001],
  [-76.64859, 62.454119901],
  [-76.64672, 62.455170001],
  [-76.6465, 62.456190001],
  [-76.6421101, 62.456760101],
  [-76.6416, 62.457530101],
  [-76.6372299, 62.458480001],
  [-76.6245599, 62.459260001],
  [-76.62336, 62.457490001],
  [-76.62058, 62.457129901],
  [-76.6089999, 62.457650101],
  [-76.6095301, 62.457140001],
  [-76.6078701, 62.457150001],
  [-76.60547, 62.453600001],
  [-76.60437, 62.453610001],
  [-76.6015, 62.451740001],
  [-76.6018601, 62.451510001],
  [-76.6008799, 62.450329901],
  [-76.5992199, 62.450349901],
  [-76.59947, 62.449830001],
  [-76.5979899, 62.448060001],
  [-76.5929501, 62.446580001],
  [-76.5917599, 62.445060001],
  [-76.5906499, 62.445070001],
  [-76.58678, 62.429250001],
  [-76.58416, 62.426720001],
  [-76.5830601, 62.426730001],
  [-76.5807401, 62.424710001],
  [-76.57793, 62.423720001],
  [-76.5756399, 62.437820001],
  [-76.5729799, 62.439899901],
  [-76.57333, 62.441170001],
  [-76.57222, 62.441180001],
  [-76.57158, 62.444769901],
  [-76.57735, 62.449310101],
  [-76.5772999, 62.453669901],
  [-76.57402, 62.454200101],
  [-76.5724199, 62.455510001],
  [-76.5620501, 62.457799901],
  [-76.5537299, 62.457260001],
  [-76.55425, 62.456740001],
  [-76.5509301, 62.456520001],
  [-76.5506101, 62.455760101],
  [-76.55198, 62.455480001],
  [-76.5519301, 62.454459901],
  [-76.5412799, 62.451510001],
  [-76.5398301, 62.450250001],
  [-76.5394099, 62.442060001],
  [-76.5368101, 62.439800001],
  [-76.53233, 62.438559901],
  [-76.5323, 62.438040001],
  [-76.53008, 62.437820001],
  [-76.5283601, 62.436559901],
  [-76.5233501, 62.435710001],
  [-76.5211699, 62.436380001],
  [-76.5203799, 62.437150001],
  [-76.52496, 62.440170001],
  [-76.52508, 62.442730001],
  [-76.52168, 62.446339901],
  [-76.51351, 62.448610001],
  [-76.51074, 62.448520001],
  [-76.5107199, 62.448009901],
  [-76.5084799, 62.447769901],
  [-76.5084701, 62.447260001],
  [-76.5073899, 62.447789901],
  [-76.507, 62.445490001],
  [-76.505, 62.443970101],
  [-76.50165, 62.443240001],
  [-76.5010499, 62.442480001],
  [-76.4933699, 62.443590001],
  [-76.4934, 62.444099901],
  [-76.4885, 62.445559901],
  [-76.48132, 62.445510001],
  [-76.4815701, 62.444989901],
  [-76.4790001, 62.443229901],
  [-76.47732, 62.442989901],
  [-76.4773, 62.442480001],
  [-76.47228, 62.441380001],
  [-76.4662301, 62.441960001],
  [-76.45897, 62.440370001],
  [-76.4553599, 62.434270001],
  [-76.4464499, 62.432570001],
  [-76.44595, 62.433600001],
  [-76.44485, 62.433860101],
  [-76.43759, 62.426260001],
  [-76.43914, 62.422240001],
  [-76.43616, 62.421250001],
  [-76.4348701, 62.421429901],
  [-76.43491, 62.422199901],
  [-76.4327, 62.422219901],
  [-76.4316599, 62.423510101],
  [-76.4300099, 62.423530101],
  [-76.4284301, 62.425080101],
  [-76.4229601, 62.426160001],
  [-76.42137, 62.427700001],
  [-76.41704, 62.429539901],
  [-76.41816, 62.429790001],
  [-76.4159701, 62.430070101],
  [-76.41539, 62.429559901],
  [-76.41545, 62.430580001],
  [-76.4132199, 62.430350001],
  [-76.4112099, 62.428590001],
  [-76.4114399, 62.427810001],
  [-76.4091901, 62.426940001],
  [-76.4042099, 62.426600001],
  [-76.40371, 62.427630101],
  [-76.40482, 62.427620101],
  [-76.4048401, 62.428130001],
  [-76.40372, 62.427889901],
  [-76.4027, 62.429690001],
  [-76.3922801, 62.431209901],
  [-76.38893, 62.430339901],
  [-76.38777, 62.429329901],
  [-76.38669, 62.429590001],
  [-76.3860899, 62.428570001],
  [-76.3877301, 62.428300101],
  [-76.3855199, 62.428319901],
  [-76.3838, 62.426810001],
  [-76.3743901, 62.426260001],
  [-76.36834, 62.426700001],
  [-76.3626101, 62.422150001],
  [-76.3648201, 62.422130001],
  [-76.3658301, 62.420080101],
  [-76.3614099, 62.419860101],
  [-76.3608301, 62.419360001],
  [-76.3608499, 62.419869901],
  [-76.3559499, 62.421319901],
  [-76.35153, 62.421240001],
  [-76.35011, 62.420230001],
  [-76.3491601, 62.417419901],
  [-76.3474999, 62.417439901],
  [-76.3469, 62.416419901],
  [-76.3397199, 62.416490001],
  [-76.34135, 62.415710001],
  [-76.3449599, 62.410300101],
  [-76.3466199, 62.410160001],
  [-76.34766, 62.408740101],
  [-76.34377, 62.408010001],
  [-76.3448299, 62.407099901],
  [-76.3459399, 62.407220001],
  [-76.3457101, 62.406290101],
  [-76.34208, 62.407260001],
  [-76.3403701, 62.405989901],
  [-76.3343, 62.406050001],
  [-76.3345999, 62.406560001],
  [-76.3338101, 62.407340001],
  [-76.3321699, 62.407620101],
  [-76.33166, 62.408639901],
  [-76.33056, 62.408649901],
  [-76.32663, 62.413300101],
  [-76.31964, 62.417190101],
  [-76.31965, 62.417710001],
  [-76.32185, 62.417690001],
  [-76.32111, 62.419480001],
  [-76.31887, 62.418860101],
  [-76.3078299, 62.418979901],
  [-76.3050399, 62.418089901],
  [-76.3038901, 62.417089901],
  [-76.29453, 62.417429901],
  [-76.29327, 62.414120001],
  [-76.2811201, 62.413460001],
  [-76.2773599, 62.409400101],
  [-76.2777899, 62.406580001],
  [-76.2774599, 62.405309901],
  [-76.2763599, 62.405319901],
  [-76.2768901, 62.404800001],
  [-76.2735501, 62.404060101],
  [-76.27296, 62.403299901],
  [-76.26583, 62.404270001],
  [-76.2603, 62.403930001],
  [-76.2579801, 62.401130001],
  [-76.2568701, 62.401140001],
  [-76.2573201, 62.398580001],
  [-76.2523101, 62.397350001],
  [-76.25229, 62.396830101],
  [-76.2434399, 62.396280101],
  [-76.23251, 62.398550001],
  [-76.2314499, 62.399580001],
  [-76.21815, 62.398030001],
  [-76.2086499, 62.394920001],
  [-76.2013099, 62.390629901],
  [-76.1979699, 62.389890001],
  [-76.19392, 62.385069901],
  [-76.1922801, 62.385330001],
  [-76.19058, 62.384069901],
  [-76.1822899, 62.383500101],
  [-76.17901, 62.384179901],
  [-76.1772899, 62.382650001],
  [-76.17067, 62.382720101],
  [-76.16965, 62.384509901],
  [-76.1658201, 62.385309901],
  [-76.16524, 62.384540001],
  [-76.1542601, 62.385529901],
  [-76.15033, 62.383770001],
  [-76.1414701, 62.382690001],
  [-76.14145, 62.382179901],
  [-76.13923, 62.381560001],
  [-76.11432, 62.378690001],
  [-76.1034401, 62.375069901],
  [-76.1031299, 62.374050101],
  [-76.1014699, 62.374059901],
  [-76.1004799, 62.369470001],
  [-76.09961, 62.368440001],
  [-76.09796, 62.368200001],
  [-76.09454, 62.365160101],
  [-76.0914799, 62.364420001],
  [-76.09249, 62.361590101],
  [-76.0919099, 62.361090001],
  [-76.0932, 62.358519901],
  [-76.09728, 62.357210001],
  [-76.0972399, 62.356179901],
  [-76.0996999, 62.355650001],
  [-76.0996701, 62.354890001],
  [-76.10374, 62.353320001],
  [-76.1045, 62.351520001],
  [-76.1034, 62.351530001],
  [-76.1038901, 62.349740001],
  [-76.10608, 62.349460001],
  [-76.1054, 62.345890001],
  [-76.10431, 62.346150101],
  [-76.1036899, 62.344360101],
  [-76.1047901, 62.344360101],
  [-76.1069099, 62.342039901],
  [-76.1080101, 62.342030101],
  [-76.11041, 62.339960001],
  [-76.12052, 62.338090001],
  [-76.1223799, 62.336279901],
  [-76.12206, 62.335259901],
  [-76.12096, 62.335269901],
  [-76.1203799, 62.334250101],
  [-76.1187199, 62.334269901],
  [-76.1187099, 62.333760001],
  [-76.11706, 62.333770001],
  [-76.1170399, 62.333259901],
  [-76.11264, 62.333420001],
  [-76.10884, 62.334730001],
  [-76.10718, 62.334609901],
  [-76.1072, 62.335130101],
  [-76.09565, 62.335220001],
  [-76.0956801, 62.335990001],
  [-76.0945601, 62.335480101],
  [-76.0945799, 62.335990001],
  [-76.09076, 62.336920101],
  [-76.0891001, 62.336800101],
  [-76.0891201, 62.337320001],
  [-76.07816, 62.338420001],
  [-76.07818, 62.339190001],
  [-76.07708, 62.339200001],
  [-76.07392, 62.343059901],
  [-76.0733499, 62.342560001],
  [-76.0656799, 62.343379901],
  [-76.0614499, 62.348279901],
  [-76.0570901, 62.349590101],
  [-76.05439, 62.351269901],
  [-76.0499999, 62.351430001],
  [-76.0500099, 62.351939901],
  [-76.0467401, 62.352860001],
  [-76.0417799, 62.352770001],
  [-76.0418, 62.353279901],
  [-76.0407, 62.353289901],
  [-76.04072, 62.353800101],
  [-76.03637, 62.355499901],
  [-76.0314299, 62.355790001],
  [-76.0286499, 62.355040101],
  [-76.02424, 62.354949901],
  [-76.02349, 62.349069901],
  [-76.0223901, 62.349069901],
  [-76.02235, 62.347800101],
  [-76.02069, 62.347560001],
  [-76.0195399, 62.346030101],
  [-76.0178699, 62.345790001],
  [-76.0158699, 62.343499901],
  [-76.0166301, 62.341190001],
  [-76.0149699, 62.341200001],
  [-76.0153901, 62.337360101],
  [-76.0165001, 62.337609901],
  [-76.0205001, 62.334000001],
  [-76.0207401, 62.332970001],
  [-76.0218401, 62.332970001],
  [-76.02005, 62.328890001],
  [-76.02557, 62.329230101],
  [-76.0306599, 62.325479901],
  [-76.0300601, 62.323950001],
  [-76.03058, 62.322919901],
  [-76.0288699, 62.321400001],
  [-76.02572, 62.309400001],
  [-76.027, 62.306830001],
  [-76.0215001, 62.306740001],
  [-76.01526, 62.308960001],
  [-76.01705, 62.313300001],
  [-76.01459, 62.321510001],
  [-76.01849, 62.323269901],
  [-76.0182801, 62.325320001],
  [-76.0072801, 62.325400001],
  [-76.0010699, 62.336949901],
  [-75.98965, 62.341130101],
  [-75.9842899, 62.345770001],
  [-75.9793899, 62.347090001],
  [-75.97889, 62.348880001],
  [-75.9739499, 62.349300001],
  [-75.9706199, 62.348040101],
  [-75.96841, 62.347930101],
  [-75.9672701, 62.346660001],
  [-75.9639499, 62.346159901],
  [-75.9618201, 62.348489901],
  [-75.9569, 62.349550001],
  [-75.9558299, 62.350580101],
  [-75.9492601, 62.351770001],
  [-75.9443, 62.351560001],
  [-75.93376, 62.349059901],
  [-75.9299001, 62.348960001],
  [-75.92932, 62.347939901],
  [-75.9232301, 62.346570001],
  [-75.91828, 62.346990001],
  [-75.91827, 62.346480101],
  [-75.9123625, 62.346288801],
  [-75.9076214, 62.343774001],
  [-75.9009245, 62.339039201],
  [-75.8960822, 62.334590701],
  [-75.8906428, 62.331611801],
  [-75.8842201, 62.330330001],
  [-75.8842001, 62.329810101],
  [-75.8825599, 62.330080001],
  [-75.8830901, 62.329310001],
  [-75.8819801, 62.329060001],
  [-75.8819999, 62.329560001],
  [-75.8797999, 62.329589901],
  [-75.88086, 62.328300001],
  [-75.8797601, 62.328310001],
  [-75.8791001, 62.324479901],
  [-75.8802001, 62.324479901],
  [-75.8774, 62.322950001],
  [-75.8707899, 62.322479901],
  [-75.8713201, 62.321970001],
  [-75.86581, 62.321489901],
  [-75.8657899, 62.320970001],
  [-75.86414, 62.320990001],
  [-75.86413, 62.320479901],
  [-75.85915, 62.319350101],
  [-75.84871, 62.319550001],
  [-75.84868, 62.318780101],
  [-75.84317, 62.318430001],
  [-75.83824, 62.319100001],
  [-75.83823, 62.318589901],
  [-75.83492, 62.318350101],
  [-75.83546, 62.317840001],
  [-75.83327, 62.318110001],
  [-75.83045, 62.315819901],
  [-75.82715, 62.315579901],
  [-75.82714, 62.315070001],
  [-75.8243901, 62.315340101],
  [-75.82492, 62.314579901],
  [-75.81833, 62.314870001],
  [-75.8183199, 62.314359901],
  [-75.81667, 62.314369901],
  [-75.81666, 62.313850001],
  [-75.81501, 62.314120101],
  [-75.815, 62.313610001],
  [-75.8083801, 62.312880001],
  [-75.80781, 62.312120101],
  [-75.81001, 62.311589901],
  [-75.8099799, 62.310819901],
  [-75.8110799, 62.310819901],
  [-75.81159, 62.309530001],
  [-75.8082801, 62.309029901],
  [-75.80881, 62.308530001],
  [-75.8077201, 62.308530001],
  [-75.80769, 62.307770001],
  [-75.8005299, 62.306909901],
  [-75.7906299, 62.307100001],
  [-75.7906199, 62.306330101],
  [-75.78513, 62.306870001],
  [-75.7844801, 62.303039901],
  [-75.7833999, 62.303560101],
  [-75.7828299, 62.302790101],
  [-75.77731, 62.301929901],
  [-75.7712799, 62.302469901],
  [-75.7669101, 62.303640001],
  [-75.7669201, 62.304160001],
  [-75.76197, 62.304060001],
  [-75.7537901, 62.306410001],
  [-75.7383599, 62.305340101],
  [-75.7366901, 62.304070001],
  [-75.7344899, 62.304080001],
  [-75.7350299, 62.303560101],
  [-75.7339299, 62.303570101],
  [-75.7333599, 62.302550101],
  [-75.72839, 62.301680101],
  [-75.7168801, 62.302890101],
  [-75.7168901, 62.303400001],
  [-75.70253, 62.300660101],
  [-75.70003, 62.299129901],
  [-75.6996899, 62.296330101],
  [-75.69804, 62.296070001],
  [-75.6985599, 62.295310001],
  [-75.69194, 62.293940001],
  [-75.6875401, 62.294210101],
  [-75.68317, 62.295390001],
  [-75.6832101, 62.296670101],
  [-75.6810099, 62.296420001],
  [-75.6815401, 62.295909901],
  [-75.67769, 62.295670101],
  [-75.6782199, 62.294900101],
  [-75.67603, 62.295170001],
  [-75.67547, 62.294400001],
  [-75.66724, 62.294950001],
  [-75.66725, 62.295459901],
  [-75.6634201, 62.296520001],
  [-75.6634299, 62.297019901],
  [-75.6596, 62.296909901],
  [-75.6557301, 62.296290001],
  [-75.65571, 62.295520001],
  [-75.6490999, 62.295040001],
  [-75.65015, 62.292720001],
  [-75.6479501, 62.292230101],
  [-75.64681, 62.290440101],
  [-75.64516, 62.290450101],
  [-75.6451701, 62.291220101],
  [-75.6435399, 62.291870001],
  [-75.63916, 62.292270001],
  [-75.63914, 62.291760001],
  [-75.6363901, 62.291260001],
  [-75.63581, 62.289730001],
  [-75.6369, 62.289720001],
  [-75.63689, 62.289210101],
  [-75.62702, 62.289890101],
  [-75.6237499, 62.291060001],
  [-75.6231801, 62.290550101],
  [-75.6242901, 62.290550101],
  [-75.62368, 62.287990101],
  [-75.62259, 62.288249901],
  [-75.62257, 62.287100101],
  [-75.62147, 62.287229901],
  [-75.62145, 62.286459901],
  [-75.6165299, 62.287260001],
  [-75.6195199, 62.285710001],
  [-75.61866, 62.284180001],
  [-75.61701, 62.283930001],
  [-75.6169799, 62.282650101],
  [-75.61588, 62.282650101],
  [-75.6169799, 62.282400001],
  [-75.6169699, 62.281880101],
  [-75.6158599, 62.281630001],
  [-75.61859, 62.280600001],
  [-75.6193801, 62.279060001],
  [-75.6188301, 62.278799901],
  [-75.6190799, 62.278030001],
  [-75.61524, 62.278050001],
  [-75.61522, 62.277030001],
  [-75.61246, 62.276400001],
  [-75.6091701, 62.276540101],
  [-75.6091799, 62.277060001],
  [-75.6020501, 62.277600001],
  [-75.60201, 62.275540101],
  [-75.5987101, 62.275310101],
  [-75.5987001, 62.274799901],
  [-75.5981601, 62.275310101],
  [-75.59542, 62.275320101],
  [-75.59598, 62.275830001],
  [-75.59487, 62.275579901],
  [-75.5938, 62.277119901],
  [-75.5916001, 62.276750001],
  [-75.5855801, 62.277669901],
  [-75.5831599, 62.275970001],
  [-75.5811501, 62.275770101],
  [-75.56964, 62.276970001],
  [-75.56963, 62.276200101],
  [-75.56853, 62.276200101],
  [-75.56988, 62.275180001],
  [-75.5699201, 62.272700001],
  [-75.5684701, 62.273009901],
  [-75.5651599, 62.272380001],
  [-75.5651699, 62.272889901],
  [-75.56243, 62.272899901],
  [-75.56488, 62.271870101],
  [-75.56241, 62.271880101],
  [-75.5630636, 62.270343901],
  [-75.5652094, 62.269365401],
  [-75.5615509, 62.269200701],
  [-75.5640721, 62.269085901],
  [-75.5644906, 62.268871201],
  [-75.5635786, 62.268386901],
  [-75.5662984, 62.267772901],
  [-75.5663574, 62.267041401],
  [-75.5670762, 62.266814301],
  [-75.5662823, 62.266901601],
  [-75.5661214, 62.266240101],
  [-75.5685139, 62.265351401],
  [-75.57264, 62.261480001],
  [-75.5764701, 62.260820001],
  [-75.5764601, 62.260050001],
  [-75.5808299, 62.259400001],
  [-75.5813899, 62.260030001],
  [-75.58854, 62.259880101],
  [-75.6098399, 62.255559901],
  [-75.6092801, 62.255050001],
  [-75.6152999, 62.253989901],
  [-75.6152899, 62.253490001],
  [-75.6240501, 62.252810001],
  [-75.62569, 62.252170001],
  [-75.6257, 62.252679901],
  [-75.62788, 62.252030001],
  [-75.63036, 62.252390001],
  [-75.6306499, 62.253170001],
  [-75.63667, 62.252630101],
  [-75.6366499, 62.251860101],
  [-75.64157, 62.251070001],
  [-75.64156, 62.250559901],
  [-75.6426599, 62.250549901],
  [-75.64482, 62.249009901],
  [-75.6538836, 62.246626201],
  [-75.6552706, 62.245684201],
  [-75.657623, 62.245496301],
  [-75.6613129, 62.243289901],
  [-75.6601269, 62.243247001],
  [-75.6603101, 62.242800001],
  [-75.6636818, 62.241908201],
  [-75.6637477, 62.241147101],
  [-75.6664097, 62.239686301],
  [-75.6679912, 62.240422801],
  [-75.6740137, 62.239244301],
  [-75.6741587, 62.239876501],
  [-75.6797727, 62.240447401],
  [-75.6828433, 62.241423301],
  [-75.682929, 62.239936401],
  [-75.6798452, 62.238684201],
  [-75.68569, 62.236790001],
  [-75.6899927, 62.232704801],
  [-75.6939331, 62.230242701],
  [-75.6941703, 62.229677801],
  [-75.6920617, 62.229969501],
  [-75.69164, 62.229220301],
  [-75.6944866, 62.228615501],
  [-75.6944338, 62.227872501],
  [-75.6974517, 62.225839901],
  [-75.6956726, 62.224875701],
  [-75.7115899, 62.218490001],
  [-75.7210264, 62.216502901],
  [-75.7230691, 62.214875001],
  [-75.7281296, 62.213523501],
  [-75.7289731, 62.212681801],
  [-75.7267261, 62.212849201],
  [-75.7355689, 62.209734201],
  [-75.7362015, 62.208345601],
  [-75.7401682, 62.205770901],
  [-75.7520684, 62.206047401],
  [-75.7712036, 62.204068601],
  [-75.788942, 62.204151801],
  [-75.8052569, 62.200193701],
  [-75.8097, 62.198380101],
  [-75.8108, 62.198510101],
  [-75.8142862, 62.196924301],
  [-75.81455, 62.195409901],
  [-75.8210412, 62.194633201],
  [-75.8242694, 62.193567101],
  [-75.826588, 62.191211301],
  [-75.8254555, 62.190990001],
  [-75.8262615, 62.190252601],
  [-75.8263153, 62.187961501],
  [-75.8433098, 62.180031301],
  [-75.8480801, 62.179990001],
  [-75.84806, 62.179350001],
  [-75.8524499, 62.179710101],
  [-75.8663001, 62.175910001],
  [-75.86762, 62.174240001],
  [-75.86871, 62.174110001],
  [-75.86929, 62.175250101],
  [-75.87147, 62.175110001],
  [-75.8719801, 62.173829901],
  [-75.86706, 62.173860001],
  [-75.8691901, 62.171800001],
  [-75.87083, 62.171790001],
  [-75.87082, 62.171279901],
  [-75.87572, 62.170609901],
  [-75.87896, 62.169179901],
  [-75.8817, 62.169160101],
  [-75.8822201, 62.168520001],
  [-75.8925699, 62.166790001],
  [-75.8920001, 62.166030101],
  [-75.89361, 62.164990001],
  [-75.8930199, 62.163460001],
  [-75.88974, 62.163480101],
  [-75.8885799, 62.161190001],
  [-75.8871299, 62.161279901],
  [-75.88807, 62.162470101],
  [-75.8858901, 62.162740001],
  [-75.8815799, 62.165200001],
  [-75.87229, 62.165380101],
  [-75.87227, 62.164870001],
  [-75.8689701, 62.164379901],
  [-75.86892, 62.162340001],
  [-75.8590099, 62.160350001],
  [-75.8589999, 62.159839901],
  [-75.85459, 62.158590101],
  [-75.85457, 62.158080001],
  [-75.84692, 62.158120001],
  [-75.8464001, 62.159660001],
  [-75.8455101, 62.159750001],
  [-75.8458899, 62.160430001],
  [-75.84317, 62.160960001],
  [-75.84314, 62.159939901],
  [-75.8415, 62.159949901],
  [-75.84148, 62.159179901],
  [-75.84093, 62.159179901],
  [-75.8398799, 62.160719901],
  [-75.8415101, 62.160460001],
  [-75.8415301, 62.160970001],
  [-75.83936, 62.161619901],
  [-75.83605, 62.160619901],
  [-75.8278501, 62.160920101],
  [-75.8267699, 62.161440001],
  [-75.81802, 62.161110001],
  [-75.8169401, 62.162140101],
  [-75.81314, 62.163190001],
  [-75.81315, 62.163700101],
  [-75.80933, 62.164100001],
  [-75.8055001, 62.163490101],
  [-75.8031654, 62.161561901],
  [-75.7945045, 62.169150901],
  [-75.79558, 62.171990001],
  [-75.79345, 62.173790001],
  [-75.7935199, 62.176350001],
  [-75.7890301, 62.183030001],
  [-75.78524, 62.184330001],
  [-75.78366, 62.186890001],
  [-75.78476, 62.186890001],
  [-75.78479, 62.188170101],
  [-75.7826001, 62.188189901],
  [-75.7820301, 62.187170101],
  [-75.7787399, 62.187179901],
  [-75.77875, 62.187690001],
  [-75.7749201, 62.187710101],
  [-75.7754901, 62.188480101],
  [-75.77165, 62.188240001],
  [-75.77166, 62.188749901],
  [-75.7634601, 62.189310001],
  [-75.76345, 62.188800001],
  [-75.7612601, 62.188550001],
  [-75.7595701, 62.186770001],
  [-75.7568399, 62.187040101],
  [-75.7573701, 62.186529901],
  [-75.7491599, 62.186700001],
  [-75.74263, 62.188270101],
  [-75.72785, 62.188350001],
  [-75.7138673, 62.192774201],
  [-75.7115907, 62.194430201],
  [-75.7103174, 62.194272701],
  [-75.7032851, 62.196221101],
  [-75.6980857, 62.199060201],
  [-75.6922689, 62.200405401],
  [-75.6907158, 62.201890001],
  [-75.690108, 62.204328201],
  [-75.6857285, 62.206176901],
  [-75.6858443, 62.206937101],
  [-75.68116, 62.206110001],
  [-75.6786287, 62.204433901],
  [-75.6740949, 62.203471201],
  [-75.6663391, 62.206075701],
  [-75.666262, 62.207069801],
  [-75.6647315, 62.208331801],
  [-75.6615865, 62.208726001],
  [-75.6595597, 62.209661601],
  [-75.6522676, 62.209387401],
  [-75.6513011, 62.207427901],
  [-75.6387309, 62.208466401],
  [-75.6407669, 62.209421201],
  [-75.6404131, 62.209764901],
  [-75.6417703, 62.209795301],
  [-75.6405728, 62.212418001],
  [-75.6429638, 62.216250001],
  [-75.6402373, 62.218991501],
  [-75.6399555, 62.220773601],
  [-75.6320452, 62.224118701],
  [-75.6309991, 62.225153601],
  [-75.6306499, 62.227329901],
  [-75.6288378, 62.228052101],
  [-75.6285199, 62.230150001],
  [-75.62635, 62.231190101],
  [-75.62143, 62.231970101],
  [-75.62059, 62.230960101],
  [-75.6224199, 62.226600001],
  [-75.61856, 62.225850101],
  [-75.618, 62.225080101],
  [-75.6141701, 62.225360001],
  [-75.6141799, 62.226120001],
  [-75.6016299, 62.229380001],
  [-75.59944, 62.228869901],
  [-75.5972601, 62.229520101],
  [-75.5986299, 62.229769901],
  [-75.5983801, 62.230800001],
  [-75.5972799, 62.230800001],
  [-75.5945901, 62.233630101],
  [-75.59186, 62.234150001],
  [-75.59135, 62.236199901],
  [-75.5891601, 62.236209901],
  [-75.5891699, 62.236720001],
  [-75.5880699, 62.236730101],
  [-75.58809, 62.237490001],
  [-75.58644, 62.237500001],
  [-75.58645, 62.238010001],
  [-75.58043, 62.238410101],
  [-75.57553, 62.240230001],
  [-75.57334, 62.240370001],
  [-75.575, 62.240869901],
  [-75.56622, 62.241290101],
  [-75.55695, 62.244020001],
  [-75.55533, 62.245810001],
  [-75.55261, 62.246590001],
  [-75.55207, 62.247360001],
  [-75.54497, 62.248669901],
  [-75.5438901, 62.249950001],
  [-75.5356899, 62.251260001],
  [-75.5356701, 62.250240001],
  [-75.53128, 62.250260001],
  [-75.53075, 62.251280001],
  [-75.5244799, 62.253350001],
  [-75.51463, 62.271300001],
  [-75.49473, 62.280070001],
  [-75.4883001, 62.289809901],
  [-75.48694, 62.290830001],
  [-75.47873, 62.292660101],
  [-75.47762, 62.291899901],
  [-75.4727501, 62.296770101],
  [-75.4688242, 62.298350001],
  [-75.4575516, 62.299624601],
  [-75.4456697, 62.298066701],
  [-75.4342448, 62.287832201],
  [-75.4305888, 62.281243401],
  [-75.4311981, 62.277558801],
  [-75.4301318, 62.267707201],
  [-75.42559, 62.264439901],
  [-75.42557, 62.263160001],
  [-75.4222701, 62.262270001],
  [-75.41898, 62.262410001],
  [-75.41842, 62.261770101],
  [-75.4129401, 62.261789901],
  [-75.40632, 62.260410001],
  [-75.40633, 62.260910001],
  [-75.39752, 62.259020001],
  [-75.38463, 62.259180001],
  [-75.3849101, 62.259950001],
  [-75.3920699, 62.261339901],
  [-75.40034, 62.264510001],
  [-75.40256, 62.266550101],
  [-75.40477, 62.267060001],
  [-75.40533, 62.268329901],
  [-75.40808, 62.268580001],
  [-75.4092, 62.269860001],
  [-75.4102899, 62.269850001],
  [-75.4105901, 62.271640101],
  [-75.4073899, 62.278320101],
  [-75.4129401, 62.282640001],
  [-75.41299, 62.285960001],
  [-75.41409, 62.286980001],
  [-75.4143999, 62.289290001],
  [-75.4155099, 62.289540101],
  [-75.4160501, 62.289019901],
  [-75.4220737, 62.291935301],
  [-75.432282, 62.301564601],
  [-75.4352677, 62.308213801],
  [-75.434148, 62.314225801],
  [-75.4244444, 62.317000201],
  [-75.4166991, 62.317251701],
  [-75.4108995, 62.318877501],
  [-75.3974506, 62.319342001],
  [-75.3888513, 62.315695401],
  [-75.3863138, 62.313178501],
  [-75.37953, 62.311900101],
  [-75.3669299, 62.315510001],
  [-75.34552, 62.318640001],
  [-75.3362101, 62.321730001],
  [-75.33509, 62.319690101],
  [-75.3285527, 62.317346601],
  [-75.3107798, 62.313065001],
  [-75.2897156, 62.304270401],
  [-75.2816519, 62.302587701],
  [-75.2694742, 62.297845001],
  [-75.2387007, 62.296812201],
  [-75.2260293, 62.292757201],
  [-75.2168137, 62.292757201],
  [-75.2110539, 62.294057901],
  [-75.2059525, 62.293981401],
  [-75.1870276, 62.289275601],
  [-75.1811033, 62.287056301],
  [-75.174027, 62.286979801],
  [-75.1689255, 62.285908301],
  [-75.155925, 62.286061401],
  [-75.1518109, 62.284683801],
  [-75.1473676, 62.286903201],
  [-75.1388111, 62.287210101],
  [-75.1349007, 62.283712501],
  [-75.1299394, 62.282290901],
  [-75.1303599, 62.281670101],
  [-75.12871, 62.281670101],
  [-75.12871, 62.281150001],
  [-75.1237701, 62.281410001],
  [-75.1237601, 62.280890101],
  [-75.11937, 62.280390001],
  [-75.1179901, 62.279630001],
  [-75.11771, 62.278090101],
  [-75.11441, 62.277320101],
  [-75.11441, 62.276810001],
  [-75.1100099, 62.274770101],
  [-75.10946, 62.272720001],
  [-75.10836, 62.272720001],
  [-75.10972, 62.269909901],
  [-75.10916, 62.265810001],
  [-75.10695, 62.263770101],
  [-75.1096699, 62.257109901],
  [-75.11104, 62.256860101],
  [-75.11104, 62.255830001],
  [-75.1126799, 62.255320101],
  [-75.11159, 62.255320101],
  [-75.11159, 62.254810001],
  [-75.1126799, 62.254810001],
  [-75.11596, 62.250970101],
  [-75.11706, 62.250970101],
  [-75.11814, 62.247640101],
  [-75.11706, 62.247640101],
  [-75.1173201, 62.246360001],
  [-75.1156699, 62.245339901],
  [-75.11593, 62.242020001],
  [-75.1170199, 62.242010001],
  [-75.13094, 62.226649901],
  [-75.13093, 62.222300101],
  [-75.1284499, 62.220769901],
  [-75.12516, 62.220260001],
  [-75.1284499, 62.219480001],
  [-75.12406, 62.219500101],
  [-75.1207899, 62.223340001],
  [-75.1136699, 62.224370001],
  [-75.1106599, 62.226930001],
  [-75.1104, 62.229999901],
  [-75.1087501, 62.229999901],
  [-75.10739, 62.232050001],
  [-75.10128, 62.235539901],
  [-75.1016501, 62.235889901],
  [-75.0994499, 62.235380001],
  [-75.09891, 62.236659901],
  [-75.09726, 62.236659901],
  [-75.09617, 62.239219901],
  [-75.0950801, 62.239219901],
  [-75.0939801, 62.241010001],
  [-75.09234, 62.241010001],
  [-75.09234, 62.242539901],
  [-75.09015, 62.242549901],
  [-75.0896, 62.243830001],
  [-75.0879601, 62.244080101],
  [-75.0879701, 62.246640101],
  [-75.0866, 62.248690001],
  [-75.0874299, 62.249710001],
  [-75.08935, 62.249970101],
  [-75.0896301, 62.250730001],
  [-75.08524, 62.251250001],
  [-75.0855201, 62.255339901],
  [-75.08717, 62.256610001],
  [-75.0869, 62.258669901],
  [-75.08581, 62.258669901],
  [-75.08636, 62.259430101],
  [-75.08416, 62.259430101],
  [-75.0830701, 62.260459901],
  [-75.0819701, 62.260200101],
  [-75.0800601, 62.265070001],
  [-75.08061, 62.265320101],
  [-75.0795201, 62.266860101],
  [-75.07979, 62.267370001],
  [-75.07815, 62.267370001],
  [-75.0787, 62.268140001],
  [-75.07759, 62.268140001],
  [-75.0759601, 62.269420101],
  [-75.0748599, 62.269420101],
  [-75.0748599, 62.269930001],
  [-75.0732099, 62.269930001],
  [-75.0726599, 62.269160001],
  [-75.0644299, 62.269420101],
  [-75.06388, 62.270190001],
  [-75.06115, 62.270960001],
  [-75.06169, 62.271730001],
  [-75.0594901, 62.271980101],
  [-75.0589401, 62.271480001],
  [-75.0578499, 62.272370001],
  [-75.0463099, 62.270200101],
  [-75.0441, 62.268150001],
  [-75.0303899, 62.268030001],
  [-75.0216001, 62.265980101],
  [-75.01721, 62.265850001],
  [-75.01776, 62.266380001],
  [-75.0056901, 62.267009901],
  [-74.99965, 62.266239901],
  [-74.9958001, 62.267520001],
  [-74.98922, 62.267899901],
  [-74.98153, 62.263810001],
  [-74.98153, 62.264580001],
  [-74.97605, 62.264180001],
  [-74.97385, 62.264960001],
  [-74.9672599, 62.265470001],
  [-74.96397, 62.264569901],
  [-74.96287, 62.263549901],
  [-74.9584799, 62.263040001],
  [-74.95793, 62.262270001],
  [-74.94806, 62.259960001],
  [-74.94642, 62.258430101],
  [-74.9348899, 62.257789901],
  [-74.9316, 62.256769901],
  [-74.92996, 62.256879901],
  [-74.92996, 62.256370001],
  [-74.9277701, 62.256119901],
  [-74.9266699, 62.255090101],
  [-74.91515, 62.253040001],
  [-74.90968, 62.247920001],
  [-74.9047401, 62.247160001],
  [-74.9047501, 62.246640101],
  [-74.9030999, 62.246889901],
  [-74.90392, 62.246380001],
  [-74.9025599, 62.243050001],
  [-74.90092, 62.243050001],
  [-74.90092, 62.242030001],
  [-74.8987301, 62.241520101],
  [-74.8976301, 62.240490001],
  [-74.8965399, 62.240490001],
  [-74.8965399, 62.239979901],
  [-74.8828401, 62.237150001],
  [-74.8828401, 62.236390001],
  [-74.8811999, 62.236390001],
  [-74.8817599, 62.235869901],
  [-74.87736, 62.235610001],
  [-74.876, 62.234329901],
  [-74.87627, 62.233820001],
  [-74.87244, 62.233309901],
  [-74.87244, 62.232539901],
  [-74.8697, 62.232280001],
  [-74.8691601, 62.230999901],
  [-74.8664199, 62.230740101],
  [-74.86478, 62.229460001],
  [-74.86424, 62.226390001],
  [-74.8626001, 62.226130001],
  [-74.86123, 62.225110001],
  [-74.8615199, 62.224089901],
  [-74.8631601, 62.224089901],
  [-74.8615199, 62.222539901],
  [-74.8483701, 62.221260001],
  [-74.8483701, 62.220749901],
  [-74.8467299, 62.220230001],
  [-74.8478201, 62.220240001],
  [-74.84756, 62.218440001],
  [-74.8448301, 62.216390101],
  [-74.84377, 62.209230001],
  [-74.8451601, 62.205140001],
  [-74.8462601, 62.205140001],
  [-74.8468201, 62.202840101],
  [-74.84901, 62.202460001],
  [-74.85066, 62.201179901],
  [-74.8528499, 62.201050101],
  [-74.8545299, 62.196959901],
  [-74.8534199, 62.196959901],
  [-74.85233, 62.194660001],
  [-74.8561701, 62.194160101],
  [-74.8561799, 62.193639901],
  [-74.8572701, 62.193639901],
  [-74.8572801, 62.191460001],
  [-74.85892, 62.191590001],
  [-74.8589301, 62.191079901],
  [-74.8567299, 62.191079901],
  [-74.8567691, 62.190035601],
  [-74.8583801, 62.189800001],
  [-74.857102, 62.188228901],
  [-74.86168, 62.188270101],
  [-74.86168, 62.187500101],
  [-74.8567784, 62.187857301],
  [-74.8572801, 62.189550001],
  [-74.8553701, 62.190570001],
  [-74.8550799, 62.192610101],
  [-74.85125, 62.192610101],
  [-74.85125, 62.193120001],
  [-74.84714, 62.194399901],
  [-74.84358, 62.194390101],
  [-74.8413701, 62.196440001],
  [-74.83754, 62.197460001],
  [-74.83588, 62.199759901],
  [-74.8336901, 62.200260001],
  [-74.83313, 62.201280101],
  [-74.8282, 62.201540001],
  [-74.82765, 62.202299901],
  [-74.82655, 62.202299901],
  [-74.82655, 62.202810001],
  [-74.81834, 62.202550001],
  [-74.81833, 62.203320001],
  [-74.8155901, 62.203060101],
  [-74.81232, 62.200620101],
  [-74.81123, 62.200749901],
  [-74.81123, 62.199720101],
  [-74.8101401, 62.199720101],
  [-74.8090499, 62.198700001],
  [-74.80795, 62.198950101],
  [-74.8084999, 62.198189901],
  [-74.80741, 62.198179901],
  [-74.80687, 62.197160101],
  [-74.80195, 62.195870001],
  [-74.80032, 62.192800001],
  [-74.7981399, 62.192289901],
  [-74.79297, 62.187420001],
  [-74.7926999, 62.186150101],
  [-74.79052, 62.185619901],
  [-74.7872599, 62.182550001],
  [-74.7823301, 62.182030001],
  [-74.7823401, 62.181519901],
  [-74.77906, 62.180619901],
  [-74.7730399, 62.180350001],
  [-74.77139, 62.180990001],
  [-74.77194, 62.180480101],
  [-74.7692101, 62.180460001],
  [-74.76975, 62.181500101],
  [-74.7681001, 62.181750001],
  [-74.76429, 62.179440001],
  [-74.7555401, 62.178660001],
  [-74.7538999, 62.177110001],
  [-74.75281, 62.177629901],
  [-74.74437, 62.171990001],
  [-74.7414301, 62.164560001],
  [-74.7403299, 62.164560001],
  [-74.74063, 62.162000001],
  [-74.73904, 62.156120001],
  [-74.7398701, 62.155100001],
  [-74.74096, 62.155100001],
  [-74.7409701, 62.154330001],
  [-74.74206, 62.154330001],
  [-74.74207, 62.153560001],
  [-74.74316, 62.153570101],
  [-74.74317, 62.152790001],
  [-74.74426, 62.152790001],
  [-74.7486088, 62.148200001],
  [-74.75086, 62.147709901],
  [-74.7508701, 62.146929901],
  [-74.7580001, 62.144770001],
  [-74.75964, 62.144900101],
  [-74.7604699, 62.143620001],
  [-74.7651101, 62.142090001],
  [-74.7667801, 62.140560002],
  [-74.76787, 62.140560002],
  [-74.7673401, 62.138259902],
  [-74.77063, 62.137499902],
  [-74.7709199, 62.134430002],
  [-74.7695699, 62.133149902],
  [-74.76919, 62.133829902],
  [-74.7701001, 62.134680102],
  [-74.7679199, 62.133400002],
  [-74.76573, 62.134929902],
  [-74.7602499, 62.135690102],
  [-74.7602499, 62.136210002],
  [-74.75203, 62.138870002],
  [-74.7482001, 62.139250102],
  [-74.74819, 62.140020102],
  [-74.7459999, 62.140910102],
  [-74.74436, 62.140780002],
  [-74.7448999, 62.141550002],
  [-74.7412831, 62.142156201],
  [-74.7376621, 62.144434601],
  [-74.73283, 62.146379901],
  [-74.73173, 62.147149901],
  [-74.7302002, 62.149808101],
  [-74.7257423, 62.151647501],
  [-74.7258765, 62.152762601],
  [-74.7233123, 62.154519101],
  [-74.7235698, 62.155441201],
  [-74.7227061, 62.156177801],
  [-74.7227061, 62.157991701],
  [-74.7203028, 62.158482801],
  [-74.7092521, 62.155641601],
  [-74.7020906, 62.151537201],
  [-74.695825, 62.145868301],
  [-74.6919132, 62.146033101],
  [-74.6920762, 62.145164601],
  [-74.6949409, 62.145093001],
  [-74.6931952, 62.141530202],
  [-74.6902929, 62.140112302],
  [-74.6837417, 62.138916402],
  [-74.6782922, 62.136643802],
  [-74.6738255, 62.136104402],
  [-74.6733481, 62.135853702],
  [-74.6740937, 62.135675702],
  [-74.6696317, 62.134581802],
  [-74.6667879, 62.133279602],
  [-74.6663529, 62.131538502],
  [-74.6614953, 62.131210302],
  [-74.6607149, 62.130003802],
  [-74.6567291, 62.128722402],
  [-74.6534085, 62.125489702],
  [-74.6505976, 62.124330902],
  [-74.6404675, 62.123729302],
  [-74.6365857, 62.121045002],
  [-74.6349609, 62.118963102],
  [-74.6345591, 62.118925202],
  [-74.6351204, 62.119594502],
  [-74.6325222, 62.118737202],
  [-74.6318204, 62.119072902],
  [-74.6314359, 62.118092502],
  [-74.6310681, 62.118864502],
  [-74.6299768, 62.118905302],
  [-74.627949, 62.117934402],
  [-74.6272168, 62.118353402],
  [-74.6270666, 62.117603902],
  [-74.621943, 62.116681202],
  [-74.6070492, 62.119735602],
  [-74.6066308, 62.117884302],
  [-74.6017599, 62.118636902],
  [-74.6006843, 62.118028502],
  [-74.598316, 62.118165202],
  [-74.598493, 62.117520502],
  [-74.6002686, 62.117071402],
  [-74.5995176, 62.116614802],
  [-74.5958671, 62.117071402],
  [-74.5965162, 62.116494402],
  [-74.5954514, 62.115680302],
  [-74.5962828, 62.115322802],
  [-74.595513, 62.115232502],
  [-74.5944643, 62.115791902],
  [-74.5945019, 62.116406602],
  [-74.5874047, 62.117273402],
  [-74.5859134, 62.118500102],
  [-74.5869461, 62.119319202],
  [-74.586871, 62.120140702],
  [-74.5841351, 62.118190302],
  [-74.5861334, 62.116934702],
  [-74.5855165, 62.116619902],
  [-74.5821262, 62.117352402],
  [-74.5815683, 62.118406102],
  [-74.5802593, 62.118626802],
  [-74.5794171, 62.118476302],
  [-74.5802218, 62.117814002],
  [-74.5776415, 62.117763802],
  [-74.5786768, 62.118644402],
  [-74.5777488, 62.118907802],
  [-74.5716226, 62.117743802],
  [-74.5763219, 62.119259002],
  [-74.5706678, 62.118692002],
  [-74.5687151, 62.120137002],
  [-74.5680177, 62.119700502],
  [-74.5687902, 62.119133602],
  [-74.567374, 62.118712102],
  [-74.5677495, 62.117849102],
  [-74.5661187, 62.118355902],
  [-74.5650351, 62.117889302],
  [-74.5647562, 62.118481302],
  [-74.5637047, 62.117909302],
  [-74.5633507, 62.116860702],
  [-74.5592415, 62.117036302],
  [-74.5575786, 62.116188302],
  [-74.5575142, 62.115300202],
  [-74.5563555, 62.114266502],
  [-74.5528901, 62.112811302],
  [-74.551903, 62.112580402],
  [-74.5553148, 62.114567602],
  [-74.5511198, 62.114276502],
  [-74.5498753, 62.113298002],
  [-74.5505405, 62.112856402],
  [-74.5434494, 62.110300402],
  [-74.5403051, 62.109850402],
  [-74.5385778, 62.110492802],
  [-74.5353699, 62.109719902],
  [-74.5309067, 62.107847802],
  [-74.531014, 62.105523902],
  [-74.5329666, 62.104213802],
  [-74.5399285, 62.102239402],
  [-74.541496, 62.099670702],
  [-74.5428586, 62.099013002],
  [-74.5375264, 62.098209702],
  [-74.5312286, 62.095312702],
  [-74.5276022, 62.094966202],
  [-74.5217013, 62.096417302],
  [-74.5157576, 62.098907602],
  [-74.5103395, 62.097434002],
  [-74.5054954, 62.098074202],
  [-74.4999111, 62.097265802],
  [-74.492873, 62.097993802],
  [-74.4856471, 62.099577802],
  [-74.4864678, 62.100541702],
  [-74.4944368, 62.098930002],
  [-74.4999701, 62.098744402],
  [-74.5083064, 62.099419702],
  [-74.5092505, 62.100009602],
  [-74.5085746, 62.100441302],
  [-74.5132792, 62.100810302],
  [-74.5218837, 62.098867402],
  [-74.5256817, 62.096859202],
  [-74.5282352, 62.096507702],
  [-74.537344, 62.099700802],
  [-74.5383954, 62.100805302],
  [-74.5372689, 62.101990002],
  [-74.5289648, 62.103631502],
  [-74.5259607, 62.108394902],
  [-74.5314968, 62.111882902],
  [-74.542923, 62.116065402],
  [-74.5458949, 62.118534002],
  [-74.5518708, 62.120535802],
  [-74.55392, 62.120578502],
  [-74.5554811, 62.122198902],
  [-74.5603842, 62.123643702],
  [-74.5587748, 62.124077602],
  [-74.5592254, 62.124847602],
  [-74.5582491, 62.125126002],
  [-74.5624495, 62.127465902],
  [-74.5713544, 62.128474102],
  [-74.5758283, 62.130181902],
  [-74.5846903, 62.131357902],
  [-74.5876086, 62.132912602],
  [-74.5931929, 62.133486802],
  [-74.5956016, 62.134863302],
  [-74.5985842, 62.135580402],
  [-74.6082616, 62.136904202],
  [-74.6225524, 62.140594502],
  [-74.6351266, 62.142740201],
  [-74.64589, 62.146700101],
  [-74.65711, 62.153862601],
  [-74.6648884, 62.157515701],
  [-74.6677315, 62.160206401],
  [-74.6698558, 62.163768601],
  [-74.6742118, 62.165812501],
  [-74.6780419, 62.168893201],
  [-74.6768349, 62.169519301],
  [-74.6782941, 62.169687101],
  [-74.6783477, 62.168798001],
  [-74.6789968, 62.168798001],
  [-74.6857238, 62.174502601],
  [-74.6877569, 62.178018101],
  [-74.6906215, 62.180093601],
  [-74.6951169, 62.184747301],
  [-74.7009588, 62.191800401],
  [-74.7106791, 62.197395601],
  [-74.7124386, 62.197715901],
  [-74.7131896, 62.198886801],
  [-74.7159791, 62.200628201],
  [-74.7227597, 62.201859001],
  [-74.7323513, 62.206682001],
  [-74.7397757, 62.207362401],
  [-74.74269, 62.208560001],
  [-74.74708, 62.208319901],
  [-74.74708, 62.207810001],
  [-74.7478999, 62.208070101],
  [-74.74707, 62.210110001],
  [-74.7454201, 62.210110001],
  [-74.7445, 62.211810001],
  [-74.74486, 62.212030001],
  [-74.7314072, 62.219096101],
  [-74.7230387, 62.220806301],
  [-74.7208071, 62.221606301],
  [-74.7204209, 62.222456301],
  [-74.7176099, 62.223256301],
  [-74.7176743, 62.224436301],
  [-74.7192621, 62.225456201],
  [-74.7210431, 62.229315601],
  [-74.7179532, 62.231195201],
  [-74.7159362, 62.234754001],
  [-74.7135973, 62.235123801],
  [-74.7135759, 62.236383301],
  [-74.7146487, 62.237043001],
  [-74.7147131, 62.238332401],
  [-74.7138548, 62.239032001],
  [-74.7143269, 62.240531201],
  [-74.7135973, 62.241140801],
  [-74.7099281, 62.241620501],
  [-74.7062159, 62.244548501],
  [-74.7066128, 62.248415501],
  [-74.7115803, 62.250783401],
  [-74.71325, 62.250980101],
  [-74.7133184, 62.251817401],
  [-74.7124708, 62.252656601],
  [-74.7075892, 62.253535701],
  [-74.7060013, 62.255224001],
  [-74.7015634, 62.255328601],
  [-74.691571, 62.252811401],
  [-74.6871936, 62.254354901],
  [-74.6829879, 62.253955301],
  [-74.681164, 62.254729501],
  [-74.6802999, 62.254490001],
  [-74.6767867, 62.256043101],
  [-74.6680319, 62.253910301],
  [-74.662689, 62.254020201],
  [-74.66001, 62.253420101],
  [-74.6575606, 62.253975301],
  [-74.6530438, 62.252896401],
  [-74.6517778, 62.253016201],
  [-74.6515095, 62.253580701],
  [-74.6504045, 62.253201101],
  [-74.6444607, 62.253945301],
  [-74.6396649, 62.256018101],
  [-74.6356952, 62.255233901],
  [-74.6329594, 62.257791101],
  [-74.6284104, 62.258745001],
  [-74.6167374, 62.258330501],
  [-74.6054828, 62.259334301],
  [-74.6017801, 62.258630001],
  [-74.6017901, 62.257870101],
  [-74.5995899, 62.257600001],
  [-74.5995999, 62.257090101],
  [-74.59795, 62.257090101],
  [-74.5957999, 62.253760101],
  [-74.5941499, 62.254009901],
  [-74.59362, 62.252970101],
  [-74.5854, 62.252449901],
  [-74.5854, 62.251930001],
  [-74.57773, 62.251910001],
  [-74.57716, 62.252679901],
  [-74.57495, 62.252930001],
  [-74.57607, 62.252420101],
  [-74.56783, 62.252779901],
  [-74.5579901, 62.250830001],
  [-74.5579999, 62.250060001],
  [-74.5563499, 62.250050001],
  [-74.55583, 62.247999901],
  [-74.55528, 62.248510001],
  [-74.5519901, 62.247989901],
  [-74.5519999, 62.247480001],
  [-74.5377401, 62.247429901],
  [-74.5377501, 62.246920001],
  [-74.5350099, 62.246659901],
  [-74.53447, 62.245889901],
  [-74.52681, 62.244710001],
  [-74.5158501, 62.244160001],
  [-74.51203, 62.242870101],
  [-74.49611, 62.243449901],
  [-74.4961, 62.243960101],
  [-74.49336, 62.243700001],
  [-74.49336, 62.244209901],
  [-74.4906001, 62.244710001],
  [-74.48348, 62.244170001],
  [-74.48402, 62.244940001],
  [-74.47892, 62.243480001],
  [-74.4653701, 62.244870101],
  [-74.4648, 62.246150001],
  [-74.43898, 62.248090101],
  [-74.43901, 62.246300101],
  [-74.4303131, 62.246257201],
  [-74.4244874, 62.244268701],
  [-74.4224233, 62.242659901],
  [-74.4034593, 62.245585201],
  [-74.3892539, 62.250034101],
  [-74.3827905, 62.253139301],
  [-74.3849634, 62.255480201],
  [-74.3844778, 62.256152001],
  [-74.3787319, 62.257324701],
  [-74.3770833, 62.258049701],
  [-74.3776023, 62.258779901],
  [-74.376787, 62.258994701],
  [-74.3741691, 62.259014601],
  [-74.3736617, 62.258359601],
  [-74.3699474, 62.258969401],
  [-74.3672061, 62.258395401],
  [-74.36167, 62.259254401],
  [-74.3614769, 62.258645101],
  [-74.3640099, 62.256300101],
  [-74.3586445, 62.255338801],
  [-74.3529162, 62.255930801],
  [-74.34161, 62.260200101],
  [-74.3302139, 62.267344201],
  [-74.3291187, 62.269714901],
  [-74.3303311, 62.269829701],
  [-74.3309722, 62.271254301],
  [-74.335556, 62.273973001],
  [-74.3361956, 62.275939601],
  [-74.3295468, 62.279126401],
  [-74.3230384, 62.280812101],
  [-74.3221395, 62.281475801],
  [-74.3212974, 62.283919401],
  [-74.3184377, 62.284083801],
  [-74.3155826, 62.283305001],
  [-74.3165086, 62.281989701],
  [-74.3144117, 62.281280001],
  [-74.3144874, 62.280633801],
  [-74.312017, 62.278994001],
  [-74.3120813, 62.277866101],
  [-74.3151103, 62.275849301],
  [-74.315536, 62.273723401],
  [-74.3166301, 62.272870101],
  [-74.3147811, 62.271445101],
  [-74.3118775, 62.270873101],
  [-74.3062878, 62.271097701],
  [-74.2987883, 62.272520401],
  [-74.2939425, 62.274723301],
  [-74.2940462, 62.275824801],
  [-74.298495, 62.276824101],
  [-74.3001187, 62.278424501],
  [-74.2956101, 62.279420001],
  [-74.2960847, 62.279997101],
  [-74.2954946, 62.280321501],
  [-74.2928685, 62.280304701],
  [-74.2925012, 62.280800601],
  [-74.2938321, 62.281685001],
  [-74.2932522, 62.281933401],
  [-74.2907632, 62.281589101],
  [-74.2908812, 62.280880501],
  [-74.2886789, 62.281144601],
  [-74.2852008, 62.283078201],
  [-74.2817856, 62.286038201],
  [-74.2876625, 62.289744601],
  [-74.2876779, 62.290434401],
  [-74.2861712, 62.291388401],
  [-74.2829503, 62.291610101],
  [-74.2785859, 62.293947401],
  [-74.2732528, 62.294509701],
  [-74.268126, 62.297616101],
  [-74.2630077, 62.302426201],
  [-74.2627692, 62.303371101],
  [-74.266094, 62.304187501],
  [-74.2663089, 62.305505301],
  [-74.2672241, 62.306260801],
  [-74.2645419, 62.308766301],
  [-74.2612749, 62.309030501],
  [-74.2609707, 62.309556701],
  [-74.2633081, 62.310608501],
  [-74.2622888, 62.311720201],
  [-74.2714955, 62.313835001],
  [-74.2719877, 62.314322401],
  [-74.271301, 62.314929801],
  [-74.2642497, 62.316526301],
  [-74.2589951, 62.316221601],
  [-74.2514866, 62.317898101],
  [-74.2492533, 62.319755401],
  [-74.2475689, 62.319974701],
  [-74.2495859, 62.320816901],
  [-74.2492318, 62.321479801],
  [-74.2431593, 62.323139301],
  [-74.2393184, 62.322770501],
  [-74.2371941, 62.325187401],
  [-74.2303705, 62.325222301],
  [-74.2295444, 62.326303601],
  [-74.224062, 62.324275501],
  [-74.2207468, 62.325596001],
  [-74.2139769, 62.326876601],
  [-74.2140734, 62.327409701],
  [-74.2156077, 62.327673801],
  [-74.2167235, 62.329736501],
  [-74.2199636, 62.330464001],
  [-74.213537, 62.331231201],
  [-74.2130165, 62.331964801],
  [-74.19971, 62.333650001],
  [-74.1996899, 62.334159901],
  [-74.19804, 62.334410001],
  [-74.1974699, 62.335169901],
  [-74.19638, 62.334910101],
  [-74.19609, 62.335670001],
  [-74.19797, 62.336450001],
  [-74.19797, 62.336960001],
  [-74.1952201, 62.336949901],
  [-74.19409, 62.337970001],
  [-74.1935501, 62.337450001],
  [-74.1918999, 62.337700101],
  [-74.1918801, 62.338210001],
  [-74.18803, 62.338190001],
  [-74.1868999, 62.339460101],
  [-74.1846899, 62.339700101],
  [-74.1846699, 62.340210001],
  [-74.17863, 62.339920101],
  [-74.17916, 62.340690101],
  [-74.18575, 62.340990001],
  [-74.1851299, 62.343800101],
  [-74.18127, 62.343900001],
  [-74.17196, 62.342440001],
  [-74.17529, 62.341180001],
  [-74.1758601, 62.340420001],
  [-74.1747601, 62.340410001],
  [-74.1747899, 62.339389901],
  [-74.16985, 62.339100001],
  [-74.16983, 62.339619901],
  [-74.167067, 62.340108901],
  [-74.1673857, 62.338575901],
  [-74.1685, 62.338070901],
  [-74.1682382, 62.337557601],
  [-74.1699028, 62.337056001],
  [-74.1713571, 62.335709401],
  [-74.17215, 62.335530001],
  [-74.17158, 62.336039901],
  [-74.17295, 62.336310001],
  [-74.17261, 62.338609901],
  [-74.17592, 62.338240101],
  [-74.1786601, 62.338770001],
  [-74.1792201, 62.338389901],
  [-74.1765, 62.337350101],
  [-74.1792399, 62.337620001],
  [-74.17982, 62.336730001],
  [-74.18147, 62.336609901],
  [-74.18237, 62.333800101],
  [-74.18573, 62.331780001],
  [-74.1811536, 62.326049401],
  [-74.1691698, 62.323928801],
  [-74.1609105, 62.324081701],
  [-74.1515672, 62.326522801],
  [-74.1503763, 62.328525801],
  [-74.1476083, 62.330434101],
  [-74.1425574, 62.332734901],
  [-74.1334665, 62.334996101],
  [-74.1317296, 62.337054601],
  [-74.1250348, 62.337388401],
  [-74.1192367, 62.336586601],
  [-74.115571, 62.337438301],
  [-74.101983, 62.337625501],
  [-74.0944091, 62.340199301],
  [-74.0842976, 62.344731201],
  [-74.0812298, 62.346871101],
  [-74.0798301, 62.347018901],
  [-74.0794423, 62.349138101],
  [-74.0842, 62.350104101],
  [-74.0837449, 62.350859001],
  [-74.0841901, 62.351665501],
  [-74.0865347, 62.352274001],
  [-74.0804243, 62.354520201],
  [-74.0726015, 62.354559901],
  [-74.0560377, 62.356111101],
  [-74.0504002, 62.353490301],
  [-74.0472508, 62.352775701],
  [-74.0350428, 62.357192501],
  [-74.0354705, 62.357942901],
  [-74.0341187, 62.358943401],
  [-74.0281918, 62.358610701],
  [-74.0236215, 62.360949101],
  [-74.0169451, 62.362022601],
  [-74.0116872, 62.364218701],
  [-74.0128206, 62.365648901],
  [-74.0048289, 62.365841401],
  [-74.001934, 62.364803901],
  [-74.0005052, 62.363233701],
  [-74.0005529, 62.362534001],
  [-74.0031338, 62.360660601],
  [-74.0008164, 62.359072801],
  [-73.9975119, 62.359386401],
  [-73.9947803, 62.361069101],
  [-73.9919103, 62.361208301],
  [-73.989419, 62.362959001],
  [-73.9824968, 62.364444501],
  [-73.9812846, 62.365992101],
  [-73.9786432, 62.367263601],
  [-73.9805818, 62.368657801],
  [-73.9756646, 62.369276701],
  [-73.975621, 62.370555701],
  [-73.9717658, 62.370527101],
  [-73.970061, 62.372049701],
  [-73.9717219, 62.371806101],
  [-73.9722114, 62.373600601],
  [-73.9738552, 62.373868601],
  [-73.9738725, 62.373357001],
  [-73.972771, 62.373348901],
  [-73.9738945, 62.372717101],
  [-73.974996, 62.372725201],
  [-73.975525, 62.373369101],
  [-73.9771948, 62.372869801],
  [-73.9771687, 62.373637101],
  [-73.9793632, 62.373909101],
  [-73.9798791, 62.374936101],
  [-73.9859037, 62.376003601],
  [-73.9803866, 62.376219001],
  [-73.9792415, 62.377489901],
  [-73.9781398, 62.377481801],
  [-73.9741963, 62.380011301],
  [-73.9769508, 62.380031601],
  [-73.9769071, 62.381310301],
  [-73.978009, 62.381318501],
  [-73.9768463, 62.383100901],
  [-73.9757442, 62.383092801],
  [-73.9746074, 62.384107701],
  [-73.9696221, 62.384838601],
  [-73.967082, 62.386610601],
  [-73.9673301, 62.387379901],
  [-73.9662279, 62.387371701],
  [-73.9645131, 62.389149901],
  [-73.9628597, 62.389137601],
  [-73.9616956, 62.390919801],
  [-73.9594647, 62.391670701],
  [-73.9594292, 62.392693801],
  [-73.9583269, 62.392685701],
  [-73.9526458, 62.397504001],
  [-73.9504229, 62.397999001],
  [-73.9522549, 62.400827001],
  [-73.9519604, 62.401336401],
  [-73.9530631, 62.401344701],
  [-73.9518531, 62.404405701],
  [-73.9457883, 62.404360001],
  [-73.9457612, 62.405127301],
  [-73.9429684, 62.406129601],
  [-73.9432179, 62.406899001],
  [-73.9406724, 62.408670501],
  [-73.939009, 62.408913801],
  [-73.9389909, 62.409425201],
  [-73.9367851, 62.409408401],
  [-73.9368032, 62.408897001],
  [-73.9334855, 62.409127301],
  [-73.9334583, 62.409894701],
  [-73.93071, 62.409617801],
  [-73.9295615, 62.410888301],
  [-73.9279072, 62.410875601],
  [-73.9202421, 62.416956501],
  [-73.9184489, 62.420780301],
  [-73.912195, 62.425848601],
  [-73.913776, 62.427907501],
  [-73.9134436, 62.429440101],
  [-73.91234, 62.429431401],
  [-73.9125889, 62.430200801],
  [-73.9109055, 62.430955301],
  [-73.9105636, 62.432743501],
  [-73.9078134, 62.432466201],
  [-73.9083374, 62.433237901],
  [-73.9072336, 62.433229201],
  [-73.9066537, 62.433992201],
  [-73.9022293, 62.434213301],
  [-73.9016492, 62.434976101],
  [-73.9005453, 62.434967401],
  [-73.8999371, 62.436497801],
  [-73.8982718, 62.436740401],
  [-73.8982436, 62.437507801],
  [-73.8921436, 62.438226901],
  [-73.8921153, 62.438994301],
  [-73.8887749, 62.439735101],
  [-73.8881943, 62.440498101],
  [-73.8826742, 62.440454101],
  [-73.8843207, 62.440723001],
  [-73.8853867, 62.441755001],
  [-73.8842826, 62.441746101],
  [-73.8848061, 62.442517801],
  [-73.8798186, 62.442989501],
  [-73.8797802, 62.444012601],
  [-73.8786761, 62.444003701],
  [-73.8791994, 62.444775601],
  [-73.8775432, 62.444762201],
  [-73.8769528, 62.445780901],
  [-73.8758484, 62.445772001],
  [-73.8747058, 62.446786001],
  [-73.8713835, 62.447015001],
  [-73.8708023, 62.447777801],
  [-73.868032, 62.448011301],
  [-73.8663174, 62.449532501],
  [-73.8646608, 62.449518901],
  [-73.8623452, 62.452314401],
  [-73.8612408, 62.452305301],
  [-73.8623064, 62.453337501],
  [-73.8595158, 62.454082101],
  [-73.8586007, 62.456377301],
  [-73.8599707, 62.456644401],
  [-73.8599316, 62.457667501],
  [-73.858265, 62.457909601],
  [-73.8582357, 62.458676901],
  [-73.8537778, 62.459663701],
  [-73.8543007, 62.460435601],
  [-73.8526436, 62.460421901],
  [-73.8509275, 62.461942801],
  [-73.8425723, 62.463664801],
  [-73.8430851, 62.464692401],
  [-73.8414278, 62.464678601],
  [-73.840915, 62.463651001],
  [-73.835973, 62.462842301],
  [-73.8360127, 62.461819201],
  [-73.834908, 62.461810201],
  [-73.8333206, 62.460006001],
  [-73.8322159, 62.459996701],
  [-73.8311811, 62.458197001],
  [-73.8267525, 62.458415701],
  [-73.8262203, 62.457899601],
  [-73.8262002, 62.458411201],
  [-73.8189895, 62.459117801],
  [-73.8175692, 62.460129201],
  [-73.8183563, 62.461159201],
  [-73.8166892, 62.461401001],
  [-73.8166385, 62.462679701],
  [-73.8105418, 62.463139601],
  [-73.810562, 62.462628101],
  [-73.8055956, 62.462457301],
  [-73.7911867, 62.463484901],
  [-73.7906032, 62.464247501],
  [-73.786972, 62.465239401],
  [-73.7872056, 62.466264701],
  [-73.7855482, 62.466250301],
  [-73.784975, 62.466757001],
  [-73.7860695, 62.467022401],
  [-73.7849646, 62.467012801],
  [-73.784923, 62.468035801],
  [-73.7832655, 62.468021401],
  [-73.7832447, 62.468532901],
  [-73.7788141, 62.468750001],
  [-73.7766355, 62.467963401],
  [-73.7766773, 62.466940401],
  [-73.7755724, 62.466930701],
  [-73.7755514, 62.467442201],
  [-73.7738941, 62.467427601],
  [-73.7744675, 62.466921101],
  [-73.7705896, 62.467142801],
  [-73.7694426, 62.468156001],
  [-73.7627386, 62.469887601],
  [-73.7627174, 62.470399301],
  [-73.7599546, 62.470374701],
  [-73.7597949, 62.469692801],
  [-73.7577764, 62.469587701],
  [-73.7577552, 62.470099301],
  [-73.7544186, 62.470581301],
  [-73.7543972, 62.471092701],
  [-73.7521551, 62.471840201],
  [-73.7516025, 62.471835301],
  [-73.7527716, 62.470310601],
  [-73.7505401, 62.470802401],
  [-73.7510714, 62.471318901],
  [-73.7488718, 62.471043401],
  [-73.7488505, 62.471554801],
  [-73.7471927, 62.471540101],
  [-73.7443547, 62.473305501],
  [-73.7421444, 62.473285501],
  [-73.7426755, 62.473802001],
  [-73.741023, 62.473658701],
  [-73.7382332, 62.474273601],
  [-73.7370846, 62.475286601],
  [-73.7354267, 62.475271701],
  [-73.7359145, 62.476811101],
  [-73.7320026, 62.477799001],
  [-73.7320351, 62.477031801],
  [-73.7298029, 62.477523201],
  [-73.7298681, 62.475988701],
  [-73.7287628, 62.475978701],
  [-73.7282427, 62.475206301],
  [-73.7243744, 62.475171101],
  [-73.7244071, 62.474403801],
  [-73.7144602, 62.474312501],
  [-73.7144383, 62.474824101],
  [-73.7122168, 62.475059401],
  [-73.7113557, 62.475819001],
  [-73.7103383, 62.480159001],
  [-73.7122517, 62.480688401],
  [-73.7118972, 62.482476201],
  [-73.7068671, 62.483709001],
  [-73.7063587, 62.482680901],
  [-73.7036059, 62.482399401],
  [-73.7035838, 62.482910901],
  [-73.701395, 62.482378801],
  [-73.7013727, 62.482890501],
  [-73.7008422, 62.482373701],
  [-73.6975035, 62.482854401],
  [-73.697537, 62.482087001],
  [-73.6925398, 62.482552101],
  [-73.6910495, 62.478700301],
  [-73.6893916, 62.478684801],
  [-73.6894139, 62.478173201],
  [-73.6849477, 62.479154701],
  [-73.6855228, 62.478648301],
  [-73.6833009, 62.478883301],
  [-73.6833347, 62.478116101],
  [-73.6822293, 62.478105501],
  [-73.6816315, 62.479123301],
  [-73.6827256, 62.479389601],
  [-73.6721962, 62.479929001],
  [-73.6705551, 62.479530001],
  [-73.6705893, 62.478762901],
  [-73.6683899, 62.478486001],
  [-73.6684242, 62.477718801],
  [-73.665131, 62.477175701],
  [-73.6651654, 62.476408301],
  [-73.6629433, 62.476642901],
  [-73.6629662, 62.476131401],
  [-73.6613198, 62.475859801],
  [-73.6613542, 62.475092501],
  [-73.6591667, 62.474559901],
  [-73.6594669, 62.474051101],
  [-73.6578551, 62.473012201],
  [-73.6590749, 62.470465401],
  [-73.6567026, 62.467884001],
  [-73.6539632, 62.467345801],
  [-73.6542633, 62.466837001],
  [-73.6524096, 62.465028101],
  [-73.6535143, 62.465038801],
  [-73.6524671, 62.463749501],
  [-73.6491758, 62.463205901],
  [-73.6492337, 62.461927101],
  [-73.6503384, 62.461937901],
  [-73.651593, 62.458623801],
  [-73.6445174, 62.456252601],
  [-73.6431957, 62.454960401],
  [-73.6435867, 62.452405701],
  [-73.6391927, 62.451851201],
  [-73.6391694, 62.452362601],
  [-73.6374954, 62.452729601],
  [-73.6330836, 62.452559001],
  [-73.6331188, 62.451791801],
  [-73.6314622, 62.451775601],
  [-73.6293356, 62.449963601],
  [-73.6265985, 62.449425001],
  [-73.6261401, 62.447373801],
  [-73.6207018, 62.445528901],
  [-73.6207489, 62.444506101],
  [-73.6190929, 62.444489601],
  [-73.6191282, 62.443722501],
  [-73.6158398, 62.443178201],
  [-73.6145081, 62.442141501],
  [-73.6154652, 62.439336501],
  [-73.6143613, 62.439325601],
  [-73.6166578, 62.437429001],
  [-73.619975, 62.437334301],
  [-73.6205682, 62.436444301],
  [-73.6227816, 62.436338601],
  [-73.622542, 62.435568601],
  [-73.6236809, 62.434812401],
  [-73.623451, 62.433786701],
  [-73.6245546, 62.433797501],
  [-73.623545, 62.431740601],
  [-73.617493, 62.431296701],
  [-73.6146926, 62.432164701],
  [-73.6133497, 62.431383801],
  [-73.6145953, 62.428326001],
  [-73.616537, 62.428089301],
  [-73.615469, 62.427311201],
  [-73.6165843, 62.427066301],
  [-73.612758, 62.426260801],
  [-73.6133333, 62.425754801],
  [-73.6045064, 62.425666701],
  [-73.6055979, 62.425933401],
  [-73.6041481, 62.427454101],
  [-73.6046522, 62.428482601],
  [-73.6037281, 62.430520301],
  [-73.602061, 62.430759301],
  [-73.6026962, 62.428974801],
  [-73.5994218, 62.428174401],
  [-73.5986429, 62.427143001],
  [-73.5992664, 62.425614201],
  [-73.6034745, 62.424121301],
  [-73.6038585, 62.421822301],
  [-73.6020462, 62.419245501],
  [-73.5976578, 62.418689801],
  [-73.5976937, 62.417922501],
  [-73.5960271, 62.418161501],
  [-73.5974668, 62.416896801],
  [-73.5975386, 62.415362301],
  [-73.602273, 62.414386501],
  [-73.6056711, 62.412501201],
  [-73.6095548, 62.412028101],
  [-73.6167056, 62.412483601],
  [-73.6171239, 62.409417501],
  [-73.6147835, 62.406323901],
  [-73.6131415, 62.406051601],
  [-73.6131887, 62.405028701],
  [-73.6087843, 62.404856301],
  [-73.605998, 62.405468701],
  [-73.6059742, 62.405980101],
  [-73.6020973, 62.406324701],
  [-73.5943966, 62.405863901],
  [-73.5943726, 62.406375301],
  [-73.5893687, 62.407219801],
  [-73.5772211, 62.407480601],
  [-73.5742749, 62.405659401],
  [-73.5745739, 62.405150801],
  [-73.5718661, 62.404099601],
  [-73.5719635, 62.402053601],
  [-73.5665, 62.400974101],
  [-73.5643687, 62.399416901],
  [-73.5627151, 62.399399901],
  [-73.5627641, 62.398377001],
  [-73.5611107, 62.398359801],
  [-73.5596414, 62.394506701],
  [-73.5607435, 62.394518201],
  [-73.5602415, 62.393489501],
  [-73.5564332, 62.392426501],
  [-73.555993, 62.390119201],
  [-73.5510461, 62.389812101],
  [-73.5510708, 62.389300501],
  [-73.5499688, 62.389289101],
  [-73.547577, 62.387473001],
  [-73.5471003, 62.385932901],
  [-73.5482765, 62.384410101],
  [-73.5519057, 62.383424301],
  [-73.5558605, 62.381418401],
  [-73.5542079, 62.381401401],
  [-73.5543063, 62.379355501],
  [-73.5493986, 62.378281001],
  [-73.5478079, 62.376985101],
  [-73.5472324, 62.377490801],
  [-73.5389584, 62.377660201],
  [-73.5383702, 62.378421501],
  [-73.5367053, 62.378660001],
  [-73.5366679, 62.379427101],
  [-73.5355662, 62.379415601],
  [-73.5349656, 62.380432601],
  [-73.5310283, 62.382053901],
  [-73.5217205, 62.380804401],
  [-73.5216449, 62.382338901],
  [-73.5133693, 62.382506501],
  [-73.5115392, 62.386069201],
  [-73.50931, 62.386557201],
  [-73.509272, 62.387324301],
  [-73.507619, 62.387306501],
  [-73.507581, 62.388073701],
  [-73.5064789, 62.388062001],
  [-73.505788, 62.390869001],
  [-73.5046859, 62.390857301],
  [-73.5051987, 62.391630301],
  [-73.5012968, 62.392483401],
  [-73.4887324, 62.390173301],
  [-73.4888095, 62.388639001],
  [-73.4849915, 62.387829901],
  [-73.4850174, 62.387318401],
  [-73.4750223, 62.388744601],
  [-73.4749965, 62.389256101],
  [-73.4744714, 62.388738601],
  [-73.4722416, 62.389225801],
  [-73.4722675, 62.388714401],
  [-73.4656428, 62.388897401],
  [-73.4632955, 62.386312801],
  [-73.4635962, 62.385804401],
  [-73.4624943, 62.385792301],
  [-73.4626385, 62.382979201],
  [-73.457753, 62.381517501],
  [-73.456094, 62.381627501],
  [-73.4561203, 62.381116001],
  [-73.4544546, 62.381353401],
  [-73.4539434, 62.380580201],
  [-73.4495302, 62.380658701],
  [-73.4472806, 62.381529601],
  [-73.4473202, 62.380762401],
  [-73.446232, 62.380494201],
  [-73.4462055, 62.381005801],
  [-73.4423497, 62.380962801],
  [-73.4407636, 62.379665601],
  [-73.4418653, 62.379677901],
  [-73.4416572, 62.378396101],
  [-73.4422212, 62.378146701],
  [-73.4415799, 62.374557201],
  [-73.4399279, 62.374538801],
  [-73.4446117, 62.369217901],
  [-73.4456998, 62.369485901],
  [-73.449438, 62.366457001],
  [-73.4508029, 62.361354801],
  [-73.4502658, 62.361093001],
  [-73.4513278, 62.356499201],
  [-73.4502271, 62.356486901],
  [-73.4506119, 62.356066501],
  [-73.4425418, 62.356016801],
  [-73.4375419, 62.356856701],
  [-73.4375153, 62.357368201],
  [-73.4352872, 62.357854901],
  [-73.4329523, 62.360387501],
  [-73.4318513, 62.360375001],
  [-73.4317979, 62.361397901],
  [-73.4306971, 62.361385401],
  [-73.4294223, 62.364697401],
  [-73.4249646, 62.365670601],
  [-73.4243738, 62.366431501],
  [-73.421621, 62.366400301],
  [-73.4187336, 62.368926301],
  [-73.4103868, 62.370494001],
  [-73.4018484, 62.370147301],
  [-73.4001597, 62.370700101],
  [-73.3910742, 62.371039701],
  [-73.3882868, 62.371647701],
  [-73.3876415, 62.372429601],
  [-73.3889205, 62.373070301],
  [-73.3877127, 62.373324501],
  [-73.3890053, 62.373825701],
  [-73.3872687, 62.374844701],
  [-73.3839922, 62.375998901],
  [-73.3819699, 62.375938201],
  [-73.3806349, 62.375191501],
  [-73.3817156, 62.374611701],
  [-73.3808044, 62.374582301],
  [-73.3829388, 62.373678301],
  [-73.376486, 62.368188701],
  [-73.3731464, 62.366354001],
  [-73.3712733, 62.364170801],
  [-73.3583631, 62.359702001],
  [-73.362419, 62.358753501],
  [-73.3624269, 62.357514301],
  [-73.3598889, 62.356428101],
  [-73.3583631, 62.356506701],
  [-73.3543469, 62.358088701],
  [-73.3545445, 62.357616601],
  [-73.3514461, 62.356531401],
  [-73.3476639, 62.356738101],
  [-73.3446088, 62.355912701],
  [-73.3377718, 62.352155601],
  [-73.3243686, 62.347473801],
  [-73.3215377, 62.345135001],
  [-73.3216112, 62.342200901],
  [-73.3194394, 62.341663001],
  [-73.3185538, 62.337814101],
  [-73.3191467, 62.337053501],
  [-73.3180467, 62.337040301],
  [-73.3175683, 62.335755101],
  [-73.3164255, 62.336508801],
  [-73.3164684, 62.335741901],
  [-73.3126473, 62.335183701],
  [-73.3166116, 62.333184701],
  [-73.3177114, 62.333197901],
  [-73.3178545, 62.330640901],
  [-73.3162049, 62.330620901],
  [-73.3162478, 62.329853801],
  [-73.3143529, 62.329319101],
  [-73.3146555, 62.328811001],
  [-73.3097717, 62.327599701],
  [-73.291592, 62.328017801],
  [-73.2909551, 62.329545301],
  [-73.2843716, 62.329208301],
  [-73.2844009, 62.328696901],
  [-73.2794378, 62.328891601],
  [-73.2789611, 62.327606201],
  [-73.2675627, 62.324905701],
  [-73.266002, 62.323351001],
  [-73.2594867, 62.321861701],
  [-73.2534329, 62.321914201],
  [-73.2533735, 62.322937101],
  [-73.2450235, 62.324623201],
  [-73.2450683, 62.323856001],
  [-73.2434565, 62.323195501],
  [-73.2412428, 62.323423401],
  [-73.2357981, 62.322459301],
  [-73.2363029, 62.323233301],
  [-73.2263932, 62.323363101],
  [-73.2264233, 62.322851701],
  [-73.2226057, 62.322291301],
  [-73.2221163, 62.321261401],
  [-73.207367, 62.319536601],
  [-73.2073974, 62.319025401],
  [-73.2062982, 62.319011201],
  [-73.204741, 62.317455701],
  [-73.2014433, 62.317413001],
  [-73.201474, 62.316901601],
  [-73.1960548, 62.315551901],
  [-73.1960856, 62.315040501],
  [-73.1928036, 62.314742101],
  [-73.1910041, 62.312671601],
  [-73.1914008, 62.310629601],
  [-73.190302, 62.310615301],
  [-73.1883367, 62.304843801],
  [-73.1854677, 62.303034601],
  [-73.1849849, 62.301354001],
  [-73.1871511, 62.298262001],
  [-73.1870663, 62.297528801],
  [-73.1902742, 62.297025001],
  [-73.1979561, 62.297458901],
  [-73.1991281, 62.296868001],
  [-73.1974572, 62.296132201],
  [-73.1964755, 62.294890201],
  [-73.1970672, 62.294503001],
  [-73.1981975, 62.295082201],
  [-73.1980956, 62.293526901],
  [-73.1999999, 62.294658201],
  [-73.2020654, 62.294601701],
  [-73.2007402, 62.291999501],
  [-73.1987178, 62.291755001],
  [-73.1977779, 62.290001301],
  [-73.1944639, 62.287676601],
  [-73.1942117, 62.286124901],
  [-73.194914, 62.285942101],
  [-73.1937665, 62.284870001],
  [-73.1940662, 62.284252101],
  [-73.1925401, 62.282900201],
  [-73.192946, 62.282300801],
  [-73.192479, 62.281531701],
  [-73.1906337, 62.280848001],
  [-73.1910467, 62.279760101],
  [-73.1917656, 62.279620401],
  [-73.1920928, 62.278100701],
  [-73.1944048, 62.276219101],
  [-73.2044792, 62.275320701],
  [-73.2094741, 62.275622901],
  [-73.2110197, 62.274273501],
  [-73.2116997, 62.272048701],
  [-73.2107771, 62.269505201],
  [-73.2094173, 62.268250901],
  [-73.2073953, 62.267699401],
  [-73.2039857, 62.268808801],
  [-73.2025697, 62.268309501],
  [-73.1926453, 62.269175701],
  [-73.1933816, 62.269728801],
  [-73.1879675, 62.269772301],
  [-73.1834078, 62.268182201],
  [-73.1823694, 62.268361401],
  [-73.1820667, 62.267942601],
  [-73.1832308, 62.267495801],
  [-73.1823385, 62.267025901],
  [-73.1825656, 62.266414801],
  [-73.1810429, 62.266540901],
  [-73.1818414, 62.265758301],
  [-73.1761874, 62.266477201],
  [-73.1768471, 62.265815701],
  [-73.1755938, 62.262833501],
  [-73.1770948, 62.263517701],
  [-73.1778553, 62.262976301],
  [-73.1723356, 62.262460301],
  [-73.1658295, 62.260511001],
  [-73.165791, 62.259741301],
  [-73.1664895, 62.259369301],
  [-73.1658822, 62.258320501],
  [-73.1664991, 62.258050801],
  [-73.1663597, 62.256837201],
  [-73.1636238, 62.253758001],
  [-73.1598795, 62.253518201],
  [-73.1539786, 62.254617101],
  [-73.1515592, 62.254117601],
  [-73.149569, 62.251243001],
  [-73.1505078, 62.248100701],
  [-73.1456945, 62.246916401],
  [-73.1442269, 62.245196101],
  [-73.1361328, 62.242107001],
  [-73.1342912, 62.242944601],
  [-73.1311403, 62.242010001],
  [-73.1332183, 62.240096401],
  [-73.1354602, 62.240312201],
  [-73.1370048, 62.239166401],
  [-73.1364603, 62.238394701],
  [-73.1271733, 62.238126901],
  [-73.1250429, 62.237157901],
  [-73.1235166, 62.235306801],
  [-73.1173704, 62.234190001],
  [-73.1149685, 62.234709001],
  [-73.11234, 62.232784701],
  [-73.1125921, 62.232144901],
  [-73.1094378, 62.232017401],
  [-73.1088316, 62.230837801],
  [-73.1108433, 62.229513101],
  [-73.1086195, 62.229125201],
  [-73.1092018, 62.228785701],
  [-73.1080592, 62.228165901],
  [-73.1084454, 62.227566001],
  [-73.106761, 62.226161101],
  [-73.1085098, 62.225381201],
  [-73.1059671, 62.224641301],
  [-73.1088541, 62.224089901],
  [-73.1088965, 62.222823801],
  [-73.1020381, 62.221258101],
  [-73.1014894, 62.219097101],
  [-73.1003937, 62.219082001],
  [-73.1010702, 62.217044001],
  [-73.0966561, 62.217495301],
  [-73.096801, 62.215194301],
  [-73.1000872, 62.215239201],
  [-73.0980091, 62.213419401],
  [-73.0893353, 62.211892201],
  [-73.0882159, 62.212261201],
  [-73.0882643, 62.211494001],
  [-73.078399, 62.211485301],
  [-73.0762329, 62.211071801],
  [-73.0762654, 62.210560401],
  [-73.0719008, 62.210244001],
  [-73.0697759, 62.209190701],
  [-73.0698084, 62.208679401],
  [-73.0720314, 62.208198401],
  [-73.0665884, 62.207610801],
  [-73.0671687, 62.207107101],
  [-73.0567327, 62.207473001],
  [-73.0534873, 62.202565301],
  [-73.0564758, 62.194418201],
  [-73.0575869, 62.194177801],
  [-73.055414, 62.193891501],
  [-73.0555127, 62.192357401],
  [-73.0544182, 62.192342101],
  [-73.0556113, 62.190823301],
  [-73.0567139, 62.190710401],
  [-73.0584624, 62.189072001],
  [-73.0573679, 62.189056501],
  [-73.0584788, 62.188816301],
  [-73.0576918, 62.188293301],
  [-73.0576635, 62.184454401],
  [-73.0587905, 62.183958401],
  [-73.0576962, 62.183943101],
  [-73.0580364, 62.182924201],
  [-73.0572639, 62.182145501],
  [-73.05559, 62.182633901],
  [-73.0556391, 62.181866901],
  [-73.051826, 62.181557401],
  [-73.051892, 62.180534701],
  [-73.046985, 62.180209801],
  [-73.0470509, 62.179187101],
  [-73.0443652, 62.178381401],
  [-73.0443983, 62.177870001],
  [-73.041531, 62.179876801],
  [-73.0398733, 62.180109201],
  [-73.0399065, 62.179598001],
  [-73.0371381, 62.180070501],
  [-73.0359612, 62.181333401],
  [-73.0315681, 62.181527101],
  [-73.0321483, 62.181023401],
  [-73.0289326, 62.179954101],
  [-73.028319, 62.180969001],
  [-73.0256004, 62.180674401],
  [-73.0251368, 62.179388201],
  [-73.0240426, 62.179372701],
  [-73.0233065, 62.178082601],
  [-73.0236125, 62.177575101],
  [-73.0225185, 62.177559401],
  [-73.0212908, 62.179589301],
  [-73.0229236, 62.179740101],
  [-73.0239591, 62.180650901],
  [-73.0208845, 62.181630701],
  [-73.0211237, 62.182145901],
  [-73.0189187, 62.182370401],
  [-73.0188852, 62.182881701],
  [-73.0160994, 62.183609701],
  [-73.0150723, 62.182571301],
  [-73.0117898, 62.182524301],
  [-73.0118234, 62.182012801],
  [-73.0101823, 62.181989201],
  [-73.0102326, 62.181222301],
  [-73.0080444, 62.181190901],
  [-73.0070177, 62.180152301],
  [-73.0042825, 62.180113001],
  [-73.0022294, 62.178036101],
  [-73.0011186, 62.178276101],
  [-72.9998026, 62.177489301],
  [-73.0001597, 62.176214901],
  [-73.0018006, 62.176238501],
  [-73.0013549, 62.174696701],
  [-72.9947917, 62.174601801],
  [-72.9947578, 62.175113201],
  [-72.9886736, 62.176048601],
  [-72.9885207, 62.178349701],
  [-72.9840937, 62.179053101],
  [-72.9841277, 62.178541701],
  [-72.979786, 62.177966501],
  [-72.9782474, 62.176408601],
  [-72.9760597, 62.176376701],
  [-72.9766407, 62.175873401],
  [-72.9722823, 62.175553801],
  [-72.9723508, 62.174531101],
  [-72.971257, 62.174515001],
  [-72.9702489, 62.173220801],
  [-72.9691552, 62.173204701],
  [-72.9690198, 62.171155401],
  [-72.9658418, 62.169573201],
  [-72.965636, 62.168546601],
  [-72.9694209, 62.169241401],
  [-72.974368, 62.168930501],
  [-72.9749575, 62.168298801],
  [-72.9766065, 62.168195401],
  [-72.9766407, 62.167684001],
  [-72.9931041, 62.167027401],
  [-72.9939506, 62.166656201],
  [-72.9937609, 62.165374001],
  [-72.9926673, 62.165358201],
  [-72.9926165, 62.166125101],
  [-72.9773241, 62.165646801],
  [-72.9768628, 62.164360401],
  [-72.9730185, 62.164560201],
  [-72.9734797, 62.165846501],
  [-72.9702162, 62.165542801],
  [-72.9697208, 62.164767701],
  [-72.9670215, 62.164216201],
  [-72.9664232, 62.164975301],
  [-72.964783, 62.164951301],
  [-72.9639983, 62.164427901],
  [-72.964305, 62.163920401],
  [-72.9572839, 62.162537501],
  [-72.9573355, 62.161770601],
  [-72.9508877, 62.160011401],
  [-72.9454646, 62.159291601],
  [-72.9463721, 62.158025501],
  [-72.9453483, 62.156986601],
  [-72.9467312, 62.156751301],
  [-72.9467658, 62.156239901],
  [-72.9456727, 62.156223801],
  [-72.9462886, 62.155209201],
  [-72.9446316, 62.155440601],
  [-72.9446662, 62.154929101],
  [-72.9424627, 62.155152401],
  [-72.9424279, 62.155663801],
  [-72.939643, 62.156390101],
  [-72.9389921, 62.157915901],
  [-72.9368055, 62.157883401],
  [-72.9368405, 62.157372001],
  [-72.9319036, 62.157554401],
  [-72.9319734, 62.156531701],
  [-72.9308978, 62.156259701],
  [-72.9308628, 62.156771001],
  [-72.9270892, 62.155946701],
  [-72.9262178, 62.156701401],
  [-72.9270017, 62.157225101],
  [-72.9253533, 62.157327901],
  [-72.9210418, 62.156368001],
  [-72.9198961, 62.157118501],
  [-72.9215534, 62.156887601],
  [-72.921413, 62.158932701],
  [-72.9225064, 62.158949201],
  [-72.9229828, 62.159980001],
  [-72.9213429, 62.159955401],
  [-72.9213077, 62.160466701],
  [-72.9180102, 62.160673001],
  [-72.917534, 62.159642201],
  [-72.9143159, 62.158697601],
  [-72.9104542, 62.159151201],
  [-72.9083119, 62.158479501],
  [-72.9094845, 62.157345101],
  [-72.9127904, 62.157011601],
  [-72.907413, 62.155650801],
  [-72.9069547, 62.154364201],
  [-72.9151352, 62.154743601],
  [-72.912032, 62.152137601],
  [-72.9065848, 62.151799501],
  [-72.9070872, 62.152446401],
  [-72.9087179, 62.152599501],
  [-72.9086825, 62.153110801],
  [-72.9059324, 62.153325101],
  [-72.9065141, 62.152822001],
  [-72.9010668, 62.152483701],
  [-72.9007591, 62.152991001],
  [-72.9021066, 62.153267301],
  [-72.9009694, 62.153889301],
  [-72.8977169, 62.153456501],
  [-72.8983522, 62.152186601],
  [-72.8945799, 62.151361601],
  [-72.8979564, 62.150004801],
  [-72.899005, 62.150661001],
  [-72.9023013, 62.150455001],
  [-72.9029806, 62.148545501],
  [-72.9043565, 62.148438701],
  [-72.9041354, 62.147667601],
  [-72.9019674, 62.147378801],
  [-72.9014918, 62.146348001],
  [-72.9048099, 62.145890001],
  [-72.9040201, 62.145360101],
  [-72.9043699, 62.144340001],
  [-72.90218, 62.144310001],
  [-72.90118, 62.142760001],
  [-72.9001, 62.142740001],
  [-72.9013401, 62.140719902],
  [-72.8952499, 62.141650002],
  [-72.89521, 62.142159901],
  [-72.8924401, 62.142760001],
  [-72.8870199, 62.142030101],
  [-72.8869099, 62.140840002],
  [-72.88162, 62.141180002],
  [-72.88083, 62.140660002],
  [-72.8811399, 62.140149902],
  [-72.87253, 62.138230102],
  [-72.8725601, 62.137719902],
  [-72.87871, 62.135770002],
  [-72.8797899, 62.136039902],
  [-72.87982, 62.135520002],
  [-72.8809199, 62.135540002],
  [-72.88097, 62.134770002],
  [-72.8823499, 62.134540002],
  [-72.88082, 62.132980002],
  [-72.8812499, 62.130939902],
  [-72.88015, 62.130919902],
  [-72.8802301, 62.129900102],
  [-72.88295, 62.129929902],
  [-72.88309, 62.127910102],
  [-72.8857999, 62.128200002],
  [-72.8857701, 62.128709902],
  [-72.88905, 62.128620002],
  [-72.89028, 62.126730002],
  [-72.8941, 62.126790102],
  [-72.8941499, 62.126020102],
  [-72.8957901, 62.126039902],
  [-72.89583, 62.125530002],
  [-72.90233, 62.126400002],
  [-72.90209, 62.125880002],
  [-72.90429, 62.125660002],
  [-72.90421, 62.122840002],
  [-72.9091401, 62.122650002],
  [-72.90807, 62.122390002],
  [-72.90918, 62.122149902],
  [-72.9086701, 62.121630002],
  [-72.91797, 62.121390002],
  [-72.92574, 62.119709902],
  [-72.9263701, 62.118430002],
  [-72.92208, 62.117230102],
  [-72.9215901, 62.116450102],
  [-72.91675, 62.115359902],
  [-72.9162699, 62.114320002],
  [-72.9101, 62.112699902],
  [-72.91041, 62.112190002],
  [-72.89363, 62.109890102],
  [-72.8935999, 62.110400002],
  [-72.86527, 62.109200002],
  [-72.8652399, 62.109710002],
  [-72.8613499, 62.110670102],
  [-72.85815, 62.113440102],
  [-72.85836, 62.114220102],
  [-72.85727, 62.114190002],
  [-72.8572399, 62.114699902],
  [-72.85058, 62.116129902],
  [-72.8505401, 62.116650002],
  [-72.8439901, 62.116550102],
  [-72.8419899, 62.117790102],
  [-72.8418799, 62.119330102],
  [-72.8437601, 62.119870002],
  [-72.8447, 62.121940002],
  [-72.83973, 62.122620002],
  [-72.8396599, 62.123640002],
  [-72.83471, 62.124080002],
  [-72.83468, 62.124589902],
  [-72.8259601, 62.124200002],
  [-72.8230801, 62.126200002],
  [-72.8208799, 62.126420002],
  [-72.82139, 62.126940002],
  [-72.81647, 62.126860002],
  [-72.8165101, 62.126350102],
  [-72.8154199, 62.126330002],
  [-72.8160899, 62.124560102],
  [-72.8171901, 62.124570102],
  [-72.8167, 62.123790102],
  [-72.81779, 62.123809902],
  [-72.8196201, 62.121149902],
  [-72.82072, 62.121039902],
  [-72.82083, 62.119510002],
  [-72.8176, 62.118689902],
  [-72.81768, 62.117670102],
  [-72.8073901, 62.116479902],
  [-72.8074101, 62.115970002],
  [-72.8057699, 62.116200002],
  [-72.8058, 62.115689902],
  [-72.79827, 62.114029902],
  [-72.79778, 62.113259902],
  [-72.79669, 62.113239902],
  [-72.7967301, 62.112730002],
  [-72.7950899, 62.112699902],
  [-72.79513, 62.112190002],
  [-72.79293, 62.112410002],
  [-72.7929799, 62.111640002],
  [-72.7875101, 62.111809902],
  [-72.7857301, 62.113830002],
  [-72.78681, 62.113850002],
  [-72.7859401, 62.114600002],
  [-72.7911, 62.122359902],
  [-72.7921999, 62.122379902],
  [-72.79236, 62.123919902],
  [-72.79371, 62.124200002],
  [-72.7915199, 62.128000102],
  [-72.7928699, 62.128280002],
  [-72.7928301, 62.128790102],
  [-72.795, 62.128950002],
  [-72.7967996, 62.130612802],
  [-72.7969189, 62.132694202],
  [-72.7958267, 62.132676902],
  [-72.7957524, 62.133699502],
  [-72.7930124, 62.133783302],
  [-72.789646, 62.132409402],
  [-72.7738514, 62.134116702],
  [-72.7737014, 62.136161802],
  [-72.7670722, 62.137078702],
  [-72.7640962, 62.140357802],
  [-72.7607433, 62.141327402],
  [-72.75901, 62.142579101],
  [-72.7573903, 62.142297001],
  [-72.7573523, 62.142808201],
  [-72.7523791, 62.143495201],
  [-72.7523412, 62.144006501],
  [-72.7436766, 62.142841801],
  [-72.7437148, 62.142330601],
  [-72.7295219, 62.141970001],
  [-72.7263402, 62.140638102],
  [-72.7170834, 62.140102302],
  [-72.717122, 62.139591002],
  [-72.7139122, 62.138641902],
  [-72.711162, 62.138852202],
  [-72.7090352, 62.138049402],
  [-72.7008137, 62.138297302],
  [-72.7013987, 62.137795202],
  [-72.6981411, 62.137485002],
  [-72.6981798, 62.136973802],
  [-72.6970876, 62.136955602],
  [-72.6976725, 62.136453502],
  [-72.6950003, 62.135641102],
  [-72.6961021, 62.135531102],
  [-72.6973594, 62.133376902],
  [-72.6946678, 62.132820302],
  [-72.6954665, 62.129506202],
  [-72.6883879, 62.129132202],
  [-72.6888755, 62.129908202],
  [-72.6839613, 62.129826002],
  [-72.6839224, 62.130337202],
  [-72.6817187, 62.130556302],
  [-72.6816795, 62.131067502],
  [-72.6778379, 62.131259202],
  [-72.6777595, 62.132281702],
  [-72.6744637, 62.132482202],
  [-72.6734892, 62.130930102],
  [-72.6701934, 62.131130602],
  [-72.6702524, 62.130363702],
  [-72.6680683, 62.130327002],
  [-72.6681666, 62.129048802],
  [-72.6633511, 62.127687802],
  [-72.660779, 62.125596902],
  [-72.6596871, 62.125578402],
  [-72.6597266, 62.125067102],
  [-72.6553989, 62.124482002],
  [-72.6549321, 62.123450302],
  [-72.6457017, 62.122653202],
  [-72.6423966, 62.122981302],
  [-72.6419103, 62.122205102],
  [-72.639727, 62.122167902],
  [-72.6397667, 62.121656602],
  [-72.638675, 62.121638102],
  [-72.6392608, 62.121136102],
  [-72.6381691, 62.121117602],
  [-72.6353471, 62.118765702],
  [-72.6362247, 62.118012902],
  [-72.6340814, 62.117464402],
  [-72.6330895, 62.116167702],
  [-72.6304208, 62.115354102],
  [-72.629955, 62.114322302],
  [-72.6288635, 62.114303602],
  [-72.6283976, 62.113271802],
  [-72.6267607, 62.113243702],
  [-72.6271538, 62.112825602],
  [-72.6158971, 62.112416602],
  [-72.6121478, 62.111456502],
  [-72.6104201, 62.109123102],
  [-72.6107724, 62.108105302],
  [-72.6096611, 62.108342202],
  [-72.6079338, 62.106008602],
  [-72.6086, 62.103030902],
  [-72.6070899, 62.099340102],
  [-72.6049501, 62.098789902],
  [-72.6020899, 62.096950002],
  [-72.60192, 62.095670102],
  [-72.6008301, 62.095650102],
  [-72.6007, 62.093850002],
  [-72.6027, 62.092740002],
  [-72.6076101, 62.092820002],
  [-72.60957, 62.092220102],
  [-72.61046, 62.091200102],
  [-72.60612, 62.090880102],
  [-72.6059699, 62.089339902],
  [-72.60848, 62.088610002],
  [-72.60856, 62.087590002],
  [-72.60426, 62.086750002],
  [-72.6022801, 62.084150002],
  [-72.60067, 62.083870102],
  [-72.60024, 62.082320102],
  [-72.6024299, 62.082370002],
  [-72.60221, 62.081590002],
  [-72.6058301, 62.080760102],
  [-72.60592, 62.079610002],
  [-72.6092099, 62.079420102],
  [-72.60927, 62.078640102],
  [-72.6081801, 62.078630002],
  [-72.60748, 62.077090102],
  [-72.6099401, 62.076990102],
  [-72.61248, 62.079470002],
  [-72.6163099, 62.079280002],
  [-72.61591, 62.077480002],
  [-72.617, 62.077500002],
  [-72.6155201, 62.075430102],
  [-72.6144299, 62.075410002],
  [-72.61415, 62.072070002],
  [-72.6130601, 62.072060002],
  [-72.61312, 62.071290002],
  [-72.61147, 62.071520002],
  [-72.60871, 62.068400002],
  [-72.6117599, 62.067810002],
  [-72.6176599, 62.069060002],
  [-72.6176, 62.069830002],
  [-72.6195299, 62.069610002],
  [-72.6190801, 62.068320102],
  [-72.62479, 62.068549902],
  [-72.6259799, 62.067160002],
  [-72.63193, 62.068030002],
  [-72.63172, 62.067260002],
  [-72.6342099, 62.066789902],
  [-72.63524, 62.067569902],
  [-72.6348501, 62.065520102],
  [-72.6386499, 62.065710002],
  [-72.63979, 62.065090102],
  [-72.6387, 62.065070002],
  [-72.63824, 62.064040002],
  [-72.6393299, 62.064060002],
  [-72.6384001, 62.061999902],
  [-72.6553101, 62.062030002],
  [-72.6553399, 62.061520102],
  [-72.66085, 62.060840002],
  [-72.66036, 62.060319902],
  [-72.6625301, 62.060360002],
  [-72.66317, 62.059090102],
  [-72.6513299, 62.057099902],
  [-72.65136, 62.056590002],
  [-72.64792, 62.055250002],
  [-72.6482299, 62.054740102],
  [-72.64607, 62.054449902],
  [-72.64613, 62.053680002],
  [-72.6380801, 62.052010002],
  [-72.63571, 62.054530102],
  [-72.63072, 62.055470002],
  [-72.63068, 62.055980102],
  [-72.6164001, 62.057270002],
  [-72.61644, 62.056760102],
  [-72.61537, 62.056480002],
  [-72.61533, 62.056980102],
  [-72.61424, 62.056970102],
  [-72.6142801, 62.056460002],
  [-72.60879, 62.057010002],
  [-72.59788, 62.056820002],
  [-72.59469, 62.056109902],
  [-72.5941899, 62.055350002],
  [-72.59092, 62.055290102],
  [-72.58977, 62.056040002],
  [-72.5898201, 62.055539902],
  [-72.5860401, 62.054960102],
  [-72.5837399, 62.053120002],
  [-72.5819401, 62.048219902],
  [-72.58803, 62.047050002],
  [-72.58956, 62.045030002],
  [-72.58847, 62.045010002],
  [-72.5875001, 62.043460002],
  [-72.59355, 62.042790002],
  [-72.5924901, 62.042260002],
  [-72.60334, 62.043089902],
  [-72.6050601, 62.041970102],
  [-72.6105101, 62.042060102],
  [-72.61471, 62.044060002],
  [-72.62391, 62.044979902],
  [-72.6247501, 62.044620102],
  [-72.6255, 62.042070102],
  [-72.6224101, 62.039710002],
  [-72.62278, 62.038439902],
  [-72.62115, 62.038410102],
  [-72.6186301, 62.035810002],
  [-72.62083, 62.035590002],
  [-72.6186499, 62.035549902],
  [-72.6197599, 62.035309902],
  [-72.6198, 62.034800002],
  [-72.6187, 62.034780002],
  [-72.61824, 62.033749902],
  [-72.6166101, 62.033720002],
  [-72.6151801, 62.031140002],
  [-72.6125201, 62.030330002],
  [-72.61103, 62.028510102],
  [-72.60835, 62.027820002],
  [-72.60615, 62.028170102],
  [-72.6061801, 62.027660002],
  [-72.60288, 62.028110002],
  [-72.6028301, 62.028620102],
  [-72.5979401, 62.028660002],
  [-72.59579, 62.028240002],
  [-72.59346, 62.026670002],
  [-72.59411, 62.025400102],
  [-72.5966201, 62.024670002],
  [-72.59724, 62.023660002],
  [-72.6070366, 62.020293902],
  [-72.5989799, 62.022280102],
  [-72.59301, 62.022050102],
  [-72.59313, 62.020519902],
  [-72.5947499, 62.020670002],
  [-72.59566, 62.019539902],
  [-72.59392, 62.017460002],
  [-72.5922999, 62.017180102],
  [-72.59266, 62.016160102],
  [-72.59165, 62.015120002],
  [-72.5926299, 62.013089902],
  [-72.5948699, 62.012230002],
  [-72.59923, 62.012179902],
  [-72.5991899, 62.012690002],
  [-72.60137, 62.012730102],
  [-72.60133, 62.013240002],
  [-72.6026499, 62.013770002],
  [-72.6087599, 62.015670002],
  [-72.6087201, 62.016180102],
  [-72.61736, 62.017230002],
  [-72.61891, 62.018280102],
  [-72.62213, 62.018830102],
  [-72.62436, 62.018240002],
  [-72.62059, 62.017680002],
  [-72.6196399, 62.015859902],
  [-72.6131999, 62.014720102],
  [-72.61326, 62.013959902],
  [-72.6121701, 62.013940102],
  [-72.61168, 62.013170102],
  [-72.6106, 62.013140002],
  [-72.61013, 62.012110002],
  [-72.6085001, 62.012079902],
  [-72.60802, 62.011309902],
  [-72.60477, 62.010990002],
  [-72.6036399, 62.008160102],
  [-72.6048401, 62.006639902],
  [-72.60411, 62.005610102],
  [-72.60248, 62.005580002],
  [-72.60082, 62.002480002],
  [-72.60177, 62.000700002],
  [-72.5968599, 62.000870002],
  [-72.59569, 62.001880002],
  [-72.59354, 62.001580002],
  [-72.5936001, 62.000820102],
  [-72.5925099, 62.000800002],
  [-72.5910601, 61.998470002],
  [-72.5899699, 61.998460002],
  [-72.59033, 62.000760002],
  [-72.58925, 62.000739902],
  [-72.5893099, 61.999969902],
  [-72.5866101, 61.999670002],
  [-72.58657, 62.000179902],
  [-72.58546, 62.000419902],
  [-72.5822601, 61.999600102],
  [-72.5829699, 61.997560002],
  [-72.58514, 61.997600102],
  [-72.58689, 61.996090002],
  [-72.5852999, 61.995550002],
  [-72.58621, 61.990959902],
  [-72.58759, 61.990729902],
  [-72.58792, 61.989969902],
  [-72.5896101, 61.989230002],
  [-72.5907, 61.989250002],
  [-72.5921799, 61.987739902],
  [-72.5946399, 61.987540002],
  [-72.59533, 61.985750002],
  [-72.5964199, 61.985770002],
  [-72.59648, 61.985000002],
  [-72.5992701, 61.984020002],
  [-72.5999, 61.983010002],
  [-72.59555, 61.982810102],
  [-72.59443, 61.983300002],
  [-72.58581, 61.982250102],
  [-72.5858499, 61.981739902],
  [-72.5819699, 61.982700102],
  [-72.58189, 61.983719902],
  [-72.577, 61.983629902],
  [-72.5757201, 61.982590102],
  [-72.5760401, 61.981829902],
  [-72.57822, 61.981739902],
  [-72.5810799, 61.980120002],
  [-72.5832801, 61.979900002],
  [-72.5889, 61.977570002],
  [-72.5947, 61.976520002],
  [-72.5948201, 61.974990002],
  [-72.5930401, 61.973420002],
  [-72.5870799, 61.973059902],
  [-72.58712, 61.972550002],
  [-72.58499, 61.972000002],
  [-72.58503, 61.971489902],
  [-72.5839401, 61.971470102],
  [-72.5834801, 61.970440002],
  [-72.59079, 61.967370102],
  [-72.59958, 61.966240102],
  [-72.60503, 61.966210002],
  [-72.6050701, 61.965700102],
  [-72.62728, 61.966719902],
  [-72.63057, 61.966389902],
  [-72.63061, 61.965880002],
  [-72.62737, 61.965570002],
  [-72.6285399, 61.964560002],
  [-72.63338, 61.965159902],
  [-72.63224, 61.965910102],
  [-72.65316, 61.962550002],
  [-72.6558601, 61.962729902],
  [-72.65528, 61.963230002],
  [-72.6572199, 61.962750002],
  [-72.65726, 61.962240102],
  [-72.66806, 61.959609902],
  [-72.6720099, 61.955410002],
  [-72.6711199, 61.955049902],
  [-72.67384, 61.955100002],
  [-72.6733399, 61.954580102],
  [-72.6744301, 61.954599902],
  [-72.67493, 61.955120102],
  [-72.67497, 61.954609902],
  [-72.6775401, 61.954049902],
  [-72.6777601, 61.953630002],
  [-72.6734, 61.953810102],
  [-72.6734499, 61.953039902],
  [-72.6707601, 61.952740002],
  [-72.67083, 61.951719902],
  [-72.67262, 61.952169902],
  [-72.67441, 61.951269902],
  [-72.6751101, 61.949230102],
  [-72.67292, 61.945870002],
  [-72.67129, 61.945840002],
  [-72.6724599, 61.944709902],
  [-72.67741, 61.943900102],
  [-72.67697, 61.942610002],
  [-72.67607, 61.942430002],
  [-72.6719901, 61.943809902],
  [-72.67263, 61.942550002],
  [-72.67371, 61.942560102],
  [-72.67374, 61.942039902],
  [-72.67051, 61.941730002],
  [-72.67061, 61.940460102],
  [-72.6689999, 61.940170002],
  [-72.6686601, 61.937610002],
  [-72.6725, 61.937029902],
  [-72.6765, 61.939200002],
  [-72.6752299, 61.936950002],
  [-72.67631, 61.936970002],
  [-72.6763499, 61.936460102],
  [-72.67315, 61.935630002],
  [-72.67319, 61.935110102],
  [-72.6595187, 61.932449202],
  [-72.6575401, 61.930249902],
  [-72.6571155, 61.925431602],
  [-72.65474, 61.924320002],
  [-72.6584029, 61.920382002],
  [-72.65761, 61.918730002],
  [-72.65652, 61.918720002],
  [-72.6559997, 61.917993302],
  [-72.6618362, 61.914705202],
  [-72.6618254, 61.912543302],
  [-72.6656555, 61.910193202],
  [-72.6678976, 61.905633002],
  [-72.669963, 61.903723902],
  [-72.6709235, 61.901185802],
  [-72.6698613, 61.899457602],
  [-72.6755798, 61.896556702],
  [-72.6794636, 61.896314102],
  [-72.6836264, 61.894878802],
  [-72.6844418, 61.894267202],
  [-72.683959, 61.893615202],
  [-72.6872528, 61.892437502],
  [-72.6890981, 61.890253802],
  [-72.6935077, 61.888944502],
  [-72.6952779, 61.887448102],
  [-72.7109206, 61.883241702],
  [-72.7128625, 61.880243202],
  [-72.7125836, 61.878650302],
  [-72.7201367, 61.876061102],
  [-72.7211559, 61.874913002],
  [-72.7209306, 61.874235302],
  [-72.7223468, 61.873608102],
  [-72.7216065, 61.871817602],
  [-72.7252436, 61.870239502],
  [-72.7248144, 61.869470602],
  [-72.7258712, 61.867500302],
  [-72.7258176, 61.862719302],
  [-72.7268261, 61.861497402],
  [-72.7250075, 61.863172202],
  [-72.724675, 61.862904002],
  [-72.7261233, 61.859928902],
  [-72.7299053, 61.856991402],
  [-72.7359939, 61.855197402],
  [-72.7472055, 61.854585002],
  [-72.7542758, 61.853107202],
  [-72.7584493, 61.851619202],
  [-72.7587497, 61.850799202],
  [-72.7621293, 61.849761602],
  [-72.7622366, 61.849128902],
  [-72.7704656, 61.845134802],
  [-72.7733088, 61.844410902],
  [-72.7764952, 61.841904802],
  [-72.7755618, 61.841798502],
  [-72.7852178, 61.840036502],
  [-72.7934575, 61.836730002],
  [-72.7951205, 61.835504502],
  [-72.7950454, 61.834825902],
  [-72.7971912, 61.833686502],
  [-72.796526, 61.832795102],
  [-72.7970517, 61.831544202],
  [-72.7957106, 61.830551502],
  [-72.7954638, 61.829351102],
  [-72.7962363, 61.827907502],
  [-72.7940476, 61.826696902],
  [-72.7919126, 61.826661402],
  [-72.7921272, 61.826165002],
  [-72.790727, 61.825748902],
  [-72.7916604, 61.825058102],
  [-72.7967942, 61.824108302],
  [-72.796247, 61.823728302],
  [-72.7877873, 61.825607702],
  [-72.783764, 61.825762202],
  [-72.7829862, 61.827476902],
  [-72.7831793, 61.828755902],
  [-72.7801859, 61.829791702],
  [-72.7767795, 61.832526702],
  [-72.75465, 61.839220002],
  [-72.7481818, 61.842841502],
  [-72.7438367, 61.846557402],
  [-72.7420771, 61.847326802],
  [-72.7306938, 61.847352102],
  [-72.7222288, 61.846314402],
  [-72.7117252, 61.848324002],
  [-72.7061892, 61.851204102],
  [-72.7012754, 61.855384602],
  [-72.7005565, 61.857054602],
  [-72.7008999, 61.858572802],
  [-72.7000845, 61.859382402],
  [-72.7000952, 61.861937602],
  [-72.6990116, 61.864381402],
  [-72.6956749, 61.867371302],
  [-72.6894307, 61.870664402],
  [-72.6886904, 61.871595102],
  [-72.689184, 61.875064702],
  [-72.6887763, 61.875408602],
  [-72.6836693, 61.876410002],
  [-72.680043, 61.878104202],
  [-72.673316, 61.878564302],
  [-72.6664401, 61.881260002],
  [-72.66582, 61.882270002],
  [-72.6619701, 61.882970102],
  [-72.66138, 61.883730002],
  [-72.65859, 61.884580002],
  [-72.64605, 61.885520002],
  [-72.6447799, 61.884470002],
  [-72.6437699, 61.879850002],
  [-72.64269, 61.879830002],
  [-72.64091, 61.878260002],
  [-72.64183, 61.873420102],
  [-72.6444, 61.871669902],
  [-72.64548, 61.871690002],
  [-72.6434201, 61.866790002],
  [-72.64405, 61.862190102],
  [-72.6424201, 61.862160002],
  [-72.6428899, 61.859610002],
  [-72.64038, 61.857010002],
  [-72.6403001, 61.854439902],
  [-72.6392199, 61.854429902],
  [-72.6377599, 61.848759902],
  [-72.63984, 61.842920002],
  [-72.63868, 61.840340002],
  [-72.63601, 61.839790002],
  [-72.6355301, 61.839020002],
  [-72.63661, 61.839030002],
  [-72.63472, 61.835409902],
  [-72.63313, 61.835130002],
  [-72.6334299, 61.834620102],
  [-72.6316599, 61.833060102],
  [-72.6188599, 61.830529902],
  [-72.6189, 61.830020002],
  [-72.61514, 61.829700002],
  [-72.6151999, 61.828930002],
  [-72.60982, 61.828330002],
  [-72.60935, 61.827550002],
  [-72.60827, 61.827529902],
  [-72.6081999, 61.824969902],
  [-72.61091, 61.821430002],
  [-72.6119899, 61.821450002],
  [-72.6121899, 61.818900002],
  [-72.61058, 61.818610102],
  [-72.61078, 61.816060102],
  [-72.6097, 61.816040102],
  [-72.60878, 61.813959902],
  [-72.6115001, 61.813770002],
  [-72.61158, 61.812739902],
  [-72.61482, 61.812810002],
  [-72.61333, 61.811240002],
  [-72.60956, 61.810920002],
  [-72.6096, 61.810409902],
  [-72.60634, 61.810600102],
  [-72.60536, 61.809299902],
  [-72.60644, 61.809330002],
  [-72.6059601, 61.808550002],
  [-72.60434, 61.808519902],
  [-72.6044001, 61.807760002],
  [-72.60279, 61.807470002],
  [-72.60147, 61.803610102],
  [-72.5987499, 61.803820102],
  [-72.5978499, 61.801499902],
  [-72.5936299, 61.800130002],
  [-72.5950501, 61.795820102],
  [-72.59613, 61.795839902],
  [-72.59858, 61.788710102],
  [-72.59347, 61.781460002],
  [-72.59134, 61.781159902],
  [-72.59076, 61.781660002],
  [-72.5917301, 61.782960002],
  [-72.5938, 61.784020102],
  [-72.5927499, 61.783750002],
  [-72.59244, 61.784250102],
  [-72.59424, 61.788890002],
  [-72.5927301, 61.790910002],
  [-72.59165, 61.790890002],
  [-72.59157, 61.791920102],
  [-72.5939401, 61.792729902],
  [-72.5917499, 61.796530002],
  [-72.59067, 61.796509902],
  [-72.5899301, 61.799059902],
  [-72.5888301, 61.799289902],
  [-72.58991, 61.799310002],
  [-72.59066, 61.800090002],
  [-72.59035, 61.800600102],
  [-72.5924901, 61.800890002],
  [-72.59526, 61.803500102],
  [-72.5954199, 61.805040102],
  [-72.59435, 61.804760002],
  [-72.59324, 61.808590102],
  [-72.59212, 61.809079902],
  [-72.59399, 61.809370102],
  [-72.5949097, 61.811633402],
  [-72.5938, 61.811670002],
  [-72.59566, 61.812210002],
  [-72.5956822, 61.814390302],
  [-72.5943732, 61.819224302],
  [-72.59255, 61.820859902],
  [-72.5883007, 61.822284502],
  [-72.5870699, 61.825110002],
  [-72.5891, 61.826940102],
  [-72.58854, 61.827189902],
  [-72.5886226, 61.828444402],
  [-72.5938, 61.832400102],
  [-72.6004458, 61.835555202],
  [-72.6083422, 61.837327502],
  [-72.6096726, 61.838937802],
  [-72.6090718, 61.841489702],
  [-72.6111102, 61.843646402],
  [-72.6143825, 61.845676502],
  [-72.6151121, 61.846861102],
  [-72.616539, 61.850444902],
  [-72.6175046, 61.851209202],
  [-72.6173866, 61.852813702],
  [-72.6153374, 61.854392702],
  [-72.6117754, 61.853932202],
  [-72.6117969, 61.855754102],
  [-72.6152623, 61.858446302],
  [-72.6175583, 61.863374602],
  [-72.6191247, 61.864401602],
  [-72.6147473, 61.870952702],
  [-72.6135349, 61.873901502],
  [-72.6134491, 61.878144602],
  [-72.6143181, 61.879838702],
  [-72.6137817, 61.880293802],
  [-72.6206374, 61.887195302],
  [-72.62483, 61.890019902],
  [-72.6293171, 61.895050602],
  [-72.6270533, 61.898477202],
  [-72.6222253, 61.900124602],
  [-72.61978, 61.902469902],
  [-72.6135349, 61.904379302],
  [-72.6119149, 61.907991802],
  [-72.6105201, 61.908466602],
  [-72.608943, 61.910805602],
  [-72.6092863, 61.911896802],
  [-72.6087284, 61.913018202],
  [-72.610209, 61.915786102],
  [-72.6117969, 61.917175102],
  [-72.6098013, 61.920341602],
  [-72.6102901, 61.923300002],
  [-72.6127201, 61.926919902],
  [-72.60678, 61.933469902],
  [-72.6070501, 61.936809902],
  [-72.6078301, 61.937330002],
  [-72.6067499, 61.937310002],
  [-72.60669, 61.938080002],
  [-72.60235, 61.938000102],
  [-72.6017399, 61.938750002],
  [-72.59957, 61.938720002],
  [-72.5961113, 61.945959902],
  [-72.5953925, 61.946343402],
  [-72.5956929, 61.947448402],
  [-72.5851035, 61.950324202],
  [-72.585125, 61.951474502],
  [-72.5812411, 61.952069702],
  [-72.5801146, 61.951807402],
  [-72.5803614, 61.951156602],
  [-72.5772929, 61.950712702],
  [-72.5674117, 61.950859002],
  [-72.5626695, 61.949350502],
  [-72.5570691, 61.946363502],
  [-72.5588101, 61.945690102],
  [-72.5550091, 61.945076802],
  [-72.5552881, 61.944632802],
  [-72.5582814, 61.944723602],
  [-72.5570401, 61.944120102],
  [-72.5606847, 61.944249302],
  [-72.5604272, 61.943880902],
  [-72.5622404, 61.943270302],
  [-72.56179, 61.942410002],
  [-72.5579488, 61.941660502],
  [-72.5576699, 61.940232302],
  [-72.5558889, 61.939167502],
  [-72.5566292, 61.937961202],
  [-72.5542367, 61.937466602],
  [-72.5545907, 61.937007302],
  [-72.5540757, 61.936694402],
  [-72.5420272, 61.935296202],
  [-72.5399244, 61.933978802],
  [-72.5315774, 61.932711702],
  [-72.5285601, 61.930039902],
  [-72.52964, 61.930060002],
  [-72.5282599, 61.926970002],
  [-72.52242, 61.925330102],
  [-72.5224699, 61.924819902],
  [-72.5192301, 61.924500002],
  [-72.51917, 61.925270002],
  [-72.5164601, 61.925220102],
  [-72.51639, 61.925990002],
  [-72.5126, 61.925919902],
  [-72.5125701, 61.926430002],
  [-72.50541, 61.927450102],
  [-72.50163, 61.927259902],
  [-72.50317, 61.928310002],
  [-72.4961001, 61.928430002],
  [-72.49549, 61.929190002],
  [-72.4922201, 61.929390002],
  [-72.49218, 61.929900102],
  [-72.48784, 61.929819902],
  [-72.4878801, 61.929310002],
  [-72.48415, 61.928469902],
  [-72.48419, 61.927960002],
  [-72.47829, 61.927090002],
  [-72.4783301, 61.926570102],
  [-72.4735301, 61.925460102],
  [-72.4707, 61.923620002],
  [-72.47028, 61.922070002],
  [-72.47116, 61.921320002],
  [-72.4727601, 61.921610002],
  [-72.4728701, 61.920330102],
  [-72.4712399, 61.920300002],
  [-72.4713101, 61.919530002],
  [-72.46484, 61.918900102],
  [-72.46335, 61.917340102],
  [-72.4526499, 61.915349902],
  [-72.45217, 61.914569902],
  [-72.44848, 61.913230102],
  [-72.4485301, 61.912710002],
  [-72.4453001, 61.912410002],
  [-72.44436, 61.910589902],
  [-72.44936, 61.909150002],
  [-72.44916, 61.908370002],
  [-72.4505399, 61.908139902],
  [-72.4506699, 61.906349902],
  [-72.4523101, 61.906380002],
  [-72.45237, 61.905620002],
  [-72.4512901, 61.905600002],
  [-72.4513299, 61.905090002],
  [-72.4486699, 61.904530002],
  [-72.44771, 61.902970002],
  [-72.44879, 61.902990102],
  [-72.4495201, 61.900960002],
  [-72.4468, 61.900909902],
  [-72.4469701, 61.898850002],
  [-72.4426599, 61.898400002],
  [-72.43769, 61.899459902],
  [-72.4376499, 61.899970002],
  [-72.436, 61.900190002],
  [-72.43594, 61.900960002],
  [-72.4343099, 61.900930002],
  [-72.43425, 61.901700002],
  [-72.4309601, 61.902150002],
  [-72.4280701, 61.904139902],
  [-72.4269899, 61.904119902],
  [-72.4269, 61.905139902],
  [-72.4252801, 61.905110102],
  [-72.4251899, 61.906129902],
  [-72.42033, 61.905919902],
  [-72.3948499, 61.899170002],
  [-72.3921, 61.893229902],
  [-72.3883899, 61.892129902],
  [-72.3884801, 61.891109902],
  [-72.3873999, 61.891090102],
  [-72.38766, 61.888019902],
  [-72.38446, 61.887320102],
  [-72.37843, 61.888100102],
  [-72.37801, 61.886559902],
  [-72.3760599, 61.887290002],
  [-72.37599, 61.888060002],
  [-72.3737001, 61.889550102],
  [-72.373, 61.891330102],
  [-72.3733899, 61.893129902],
  [-72.37443, 61.893660102],
  [-72.37346, 61.895430102],
  [-72.3679399, 61.896610002],
  [-72.36798, 61.896100102],
  [-72.36374, 61.894860002],
  [-72.3490301, 61.895600002],
  [-72.3463601, 61.895170002],
  [-72.34643, 61.894400002],
  [-72.34061, 61.892620002],
  [-72.33685, 61.892170002],
  [-72.3339101, 61.888530102],
  [-72.33231, 61.888239902],
  [-72.33188, 61.886950002],
  [-72.32861, 61.887140002],
  [-72.3271201, 61.885580002],
  [-72.32274, 61.886009902],
  [-72.3226701, 61.886779902],
  [-72.3193599, 61.887480002],
  [-72.3202399, 61.889799902],
  [-72.3142599, 61.889940002],
  [-72.3142101, 61.890449902],
  [-72.31094, 61.890640102],
  [-72.3108999, 61.891150002],
  [-72.30073, 61.889530102],
  [-72.2925401, 61.890019902],
  [-72.2926, 61.889510002],
  [-72.27783, 61.890760102],
  [-72.2768701, 61.889459902],
  [-72.27525, 61.889300002],
  [-72.2665301, 61.889760102],
  [-72.26502, 61.888449902],
  [-72.2634299, 61.888170002],
  [-72.2629701, 61.887129902],
  [-72.25979, 61.886300002],
  [-72.25803, 61.884740002],
  [-72.2584201, 61.883459902],
  [-72.25146, 61.882420102],
  [-72.24382, 61.882910002],
  [-72.2438699, 61.882410002],
  [-72.2427599, 61.882630002],
  [-72.24283, 61.881870102],
  [-72.2320501, 61.881140002],
  [-72.2320999, 61.880630002],
  [-72.23047, 61.880590002],
  [-72.23127, 61.877789902],
  [-72.22689, 61.878219902],
  [-72.22537, 61.877040002],
  [-72.2237301, 61.877130002],
  [-72.22434, 61.876380002],
  [-72.21957, 61.875250002],
  [-72.2195199, 61.875769902],
  [-72.2135299, 61.876030002],
  [-72.2090601, 61.874530102],
  [-72.2089599, 61.872480002],
  [-72.2100601, 61.872510002],
  [-72.20869, 61.869659902],
  [-72.20657, 61.869099902],
  [-72.2066299, 61.868339902],
  [-72.2077201, 61.868360002],
  [-72.20779, 61.867590002],
  [-72.2041, 61.866490002],
  [-72.20344, 61.867760102],
  [-72.19643, 61.867360002],
  [-72.19647, 61.866850102],
  [-72.1953899, 61.866830002],
  [-72.1966299, 61.865190102],
  [-72.1998399, 61.865640102],
  [-72.1993899, 61.864600002],
  [-72.19725, 61.864310102],
  [-72.1958101, 61.862230002],
  [-72.1926099, 61.861649902],
  [-72.19265, 61.861140002],
  [-72.19158, 61.861120002],
  [-72.1892899, 61.859530102],
  [-72.1897001, 61.858010002],
  [-72.1853701, 61.857920002],
  [-72.18541, 61.857410102],
  [-72.18278, 61.856590002],
  [-72.1829201, 61.855050002],
  [-72.18132, 61.854759902],
  [-72.18045, 61.852439902],
  [-72.1823899, 61.851979902],
  [-72.18152, 61.849649902],
  [-72.18243, 61.848639902],
  [-72.1800599, 61.847830002],
  [-72.1807101, 61.843740102],
  [-72.18209, 61.843510102],
  [-72.1812601, 61.840680002],
  [-72.18023, 61.840150002],
  [-72.1817299, 61.838510102],
  [-72.18545, 61.839360002],
  [-72.18978, 61.839319902],
  [-72.19042, 61.838309902],
  [-72.1976001, 61.836660002],
  [-72.1878299, 61.836969902],
  [-72.1858101, 61.835400102],
  [-72.18419, 61.835380002],
  [-72.1837199, 61.834590002],
  [-72.17944, 61.833990002],
  [-72.17857, 61.831670002],
  [-72.17697, 61.831380002],
  [-72.1761501, 61.828539902],
  [-72.17185, 61.828199902],
  [-72.17119, 61.829470002],
  [-72.16956, 61.829560002],
  [-72.1637199, 61.828290102],
  [-72.16362, 61.829309902],
  [-72.1614601, 61.829270102],
  [-72.16151, 61.828749902],
  [-72.16043, 61.828730102],
  [-72.1605899, 61.826940102],
  [-72.16219, 61.827230002],
  [-72.16096, 61.825930002],
  [-72.1613699, 61.824400102],
  [-72.1585999, 61.825110002],
  [-72.15874, 61.823570002],
  [-72.15977, 61.824110002],
  [-72.15787, 61.821240002],
  [-72.15628, 61.820969902],
  [-72.15484, 61.818890002],
  [-72.1532501, 61.818600102],
  [-72.15332, 61.817830102],
  [-72.1448901, 61.815350002],
  [-72.13898, 61.809090002],
  [-72.13043, 61.807880002],
  [-72.13049, 61.807370102],
  [-72.12731, 61.806550002],
  [-72.12593, 61.803949902],
  [-72.1243301, 61.803660002],
  [-72.12386, 61.802880002],
  [-72.12227, 61.802590102],
  [-72.1223201, 61.802079902],
  [-72.12072, 61.801790002],
  [-72.1207701, 61.801279902],
  [-72.1134301, 61.798820102],
  [-72.1106701, 61.796460002],
  [-72.1100099, 61.794910002],
  [-72.1149099, 61.794499902],
  [-72.11486, 61.795010002],
  [-72.11974, 61.794990002],
  [-72.12197, 61.794260102],
  [-72.1252201, 61.794200002],
  [-72.12517, 61.794719902],
  [-72.1288901, 61.795560002],
  [-72.1284599, 61.797090002],
  [-72.13031, 61.797640002],
  [-72.1302599, 61.798150102],
  [-72.13617, 61.798540002],
  [-72.1361201, 61.799040102],
  [-72.14531, 61.799100002],
  [-72.1525899, 61.796440002],
  [-72.1660201, 61.794540002],
  [-72.16742, 61.794059902],
  [-72.16754, 61.792780002],
  [-72.1685901, 61.793059902],
  [-72.1693701, 61.790509902],
  [-72.17153, 61.790560002],
  [-72.17212, 61.789930102],
  [-72.18833, 61.790140102],
  [-72.1882799, 61.790650002],
  [-72.1909499, 61.791090002],
  [-72.19367, 61.790760002],
  [-72.1937299, 61.790250102],
  [-72.19854, 61.790860002],
  [-72.1984901, 61.791370102],
  [-72.20011, 61.791389902],
  [-72.2000599, 61.791910002],
  [-72.21024, 61.793010002],
  [-72.2129599, 61.792810102],
  [-72.21414, 61.791700102],
  [-72.22978, 61.792260102],
  [-72.22982, 61.791750002],
  [-72.2336201, 61.791570002],
  [-72.23313, 61.791049902],
  [-72.2390701, 61.791169902],
  [-72.23902, 61.791680002],
  [-72.2409601, 61.791200002],
  [-72.2397499, 61.789640002],
  [-72.2548799, 61.789690102],
  [-72.2566201, 61.788450002],
  [-72.25657, 61.788959902],
  [-72.2614201, 61.789179902],
  [-72.2630701, 61.788829902],
  [-72.2632299, 61.787040102],
  [-72.2654101, 61.786829902],
  [-72.2678799, 61.789440002],
  [-72.26872, 61.789200002],
  [-72.26602, 61.786069902],
  [-72.2676401, 61.786110002],
  [-72.26604, 61.785820102],
  [-72.2675401, 61.785169902],
  [-72.26339, 61.785250102],
  [-72.2622299, 61.786130002],
  [-72.26116, 61.785980002],
  [-72.26112, 61.786490102],
  [-72.2573001, 61.786670002],
  [-72.25736, 61.786150102],
  [-72.2557599, 61.785860002],
  [-72.2569999, 61.784100002],
  [-72.2527699, 61.783120002],
  [-72.2376301, 61.783069902],
  [-72.23012, 61.782410002],
  [-72.22523, 61.782700102],
  [-72.2218, 61.784800102],
  [-72.2207301, 61.784650002],
  [-72.2195299, 61.785910102],
  [-72.20658, 61.785640002],
  [-72.2036199, 61.782509902],
  [-72.1859499, 61.780630002],
  [-72.186, 61.780100002],
  [-72.17854, 61.778930102],
  [-72.1791501, 61.778169902],
  [-72.1770201, 61.777870002],
  [-72.17709, 61.777100002],
  [-72.17387, 61.776780002],
  [-72.1721, 61.775470102],
  [-72.17597, 61.774520002],
  [-72.1758001, 61.773480102],
  [-72.17799, 61.773149902],
  [-72.1861, 61.773070002],
  [-72.1934901, 61.775010002],
  [-72.19412, 61.774130102],
  [-72.1985001, 61.773450002],
  [-72.20079, 61.772090002],
  [-72.2040501, 61.771900002],
  [-72.2068599, 61.770670002],
  [-72.21124, 61.769990002],
  [-72.2149599, 61.770709902],
  [-72.2162701, 61.768180002],
  [-72.21735, 61.768200002],
  [-72.2158499, 61.766890002],
  [-72.21477, 61.766870002],
  [-72.21412, 61.765059902],
  [-72.2152701, 61.764310002],
  [-72.2157, 61.762530002],
  [-72.21679, 61.762430002],
  [-72.21969, 61.760310002],
  [-72.2218699, 61.760100002],
  [-72.22191, 61.759589902],
  [-72.22299, 61.759609902],
  [-72.2247201, 61.758360102],
  [-72.22634, 61.758400002],
  [-72.2253801, 61.757100002],
  [-72.2227, 61.756790102],
  [-72.22147, 61.755479902],
  [-72.22188, 61.753950002],
  [-72.2208, 61.753929902],
  [-72.2208499, 61.753420002],
  [-72.2144099, 61.752910102],
  [-72.21059, 61.753340102],
  [-72.2083901, 61.753939902],
  [-72.20768, 61.755709902],
  [-72.20554, 61.755410002],
  [-72.2054099, 61.753880002],
  [-72.2066299, 61.752359902],
  [-72.20544, 61.750550002],
  [-72.2027299, 61.750750002],
  [-72.2037299, 61.748720002],
  [-72.20577, 61.746970002],
  [-72.20792, 61.747010102],
  [-72.20858, 61.745750002],
  [-72.2104901, 61.745540002],
  [-72.2109, 61.744000102],
  [-72.204, 61.742709902],
  [-72.19799, 61.743479902],
  [-72.1980301, 61.742970002],
  [-72.19269, 61.742350102],
  [-72.1908399, 61.744870002],
  [-72.18976, 61.744850002],
  [-72.1882701, 61.743410002],
  [-72.18557, 61.743500002],
  [-72.1838101, 61.744990002],
  [-72.18111, 61.744929902],
  [-72.1815801, 61.745709902],
  [-72.1750301, 61.746469902],
  [-72.1637901, 61.745469902],
  [-72.15564, 61.745940002],
  [-72.1538199, 61.751020102],
  [-72.1553699, 61.751819902],
  [-72.15621, 61.754400002],
  [-72.15533, 61.755149902],
  [-72.1476901, 61.756010102],
  [-72.14764, 61.756530002],
  [-72.1432599, 61.757200002],
  [-72.14431, 61.757479902],
  [-72.14273, 61.760010102],
  [-72.14016, 61.761489902],
  [-72.14176, 61.761650002],
  [-72.1432499, 61.763090002],
  [-72.1367999, 61.762699902],
  [-72.1350399, 61.764200002],
  [-72.13396, 61.764180002],
  [-72.13262, 61.766970002],
  [-72.1254599, 61.768480102],
  [-72.1167801, 61.768810102],
  [-72.1073, 61.766180002],
  [-72.1073499, 61.765670002],
  [-72.09625, 61.763000002],
  [-72.0898801, 61.761850002],
  [-72.08129, 61.761269902],
  [-72.08006, 61.759970002],
  [-72.0804499, 61.758699902],
  [-72.0815301, 61.758719902],
  [-72.08057, 61.757420002],
  [-72.0724399, 61.757770002],
  [-72.07261, 61.755970002],
  [-72.07591, 61.755269902],
  [-72.07635, 61.750670102],
  [-72.0851001, 61.749580102],
  [-72.08506, 61.750090002],
  [-72.09416, 61.750790102],
  [-72.0936699, 61.750269902],
  [-72.09526, 61.750560102],
  [-72.0950901, 61.749530002],
  [-72.0933299, 61.748220002],
  [-72.09173, 61.747929902],
  [-72.0917799, 61.747420002],
  [-72.0848899, 61.745990002],
  [-72.0844499, 61.744960002],
  [-72.08335, 61.745190002],
  [-72.0828799, 61.744410002],
  [-72.07105, 61.743900102],
  [-72.0710999, 61.743390002],
  [-72.05971, 61.743919902],
  [-72.0578, 61.746950002],
  [-72.05337, 61.748130102],
  [-72.05272, 61.749139902],
  [-72.05002, 61.749210002],
  [-72.04533, 61.747450102],
  [-72.04416, 61.745630002],
  [-72.0452099, 61.743090002],
  [-72.0462901, 61.743120102],
  [-72.04636, 61.742350102],
  [-72.0477301, 61.742120102],
  [-72.04712, 61.740060002],
  [-72.04604, 61.740039902],
  [-72.0456, 61.739000102],
  [-72.04034, 61.737610002],
  [-72.03434, 61.732620002],
  [-72.0354199, 61.732640002],
  [-72.0366399, 61.731129902],
  [-72.03755, 61.730980002],
  [-72.0307201, 61.731000102],
  [-72.0307599, 61.730490002],
  [-72.02969, 61.730469902],
  [-72.02922, 61.729689902],
  [-72.0228201, 61.728909902],
  [-72.01632, 61.729149902],
  [-72.0138101, 61.727180002],
  [-72.00736, 61.726909902],
  [-72.0069, 61.723310002],
  [-72.00823, 61.720770102],
  [-72.01247, 61.716009902],
  [-72.01301, 61.713200102],
  [-72.0140799, 61.713229902],
  [-72.0147201, 61.712220102],
  [-72.0163999, 61.711620002],
  [-72.01803, 61.711520002],
  [-72.0171501, 61.706640002],
  [-72.0159, 61.705590002],
  [-72.0138, 61.705030002],
  [-72.01011, 61.701370002],
  [-72.01467, 61.693270002],
  [-72.0177201, 61.692310102],
  [-72.01733, 61.690769902],
  [-72.0157499, 61.690480002],
  [-72.0158201, 61.689710002],
  [-72.01353, 61.689240002],
  [-72.0088, 61.689810002],
  [-72.00875, 61.690329902],
  [-72.00334, 61.690590002],
  [-71.9973899, 61.688659902],
  [-71.99679, 61.687899902],
  [-71.9945901, 61.687430102],
  [-71.9910401, 61.684190102],
  [-71.98554, 61.683030002],
  [-71.98576, 61.682510002],
  [-71.9823601, 61.680790002],
  [-71.98042, 61.677500002],
  [-71.9823799, 61.675410102],
  [-71.9821301, 61.670040002],
  [-71.9800301, 61.670600002],
  [-71.9796901, 61.669840002],
  [-71.98012, 61.668810002],
  [-71.98144, 61.668520102],
  [-71.9801699, 61.663680002],
  [-71.98243, 61.661970102],
  [-71.98668, 61.661380002],
  [-71.9923799, 61.659060102],
  [-71.9922799, 61.658040002],
  [-71.9946199, 61.657220002],
  [-71.99533, 61.656180102],
  [-71.99641, 61.656160002],
  [-71.99734, 61.654730102],
  [-71.99841, 61.654580002],
  [-72.0018399, 61.644859902],
  [-72.0002601, 61.644570002],
  [-72.00219, 61.641270102],
  [-72.0032701, 61.641299902],
  [-72.00368, 61.639780002],
  [-72.0050501, 61.639550002],
  [-72.0063999, 61.636770002],
  [-72.0100599, 61.635050102],
  [-72.01111, 61.632510102],
  [-72.0121899, 61.632550002],
  [-72.0118201, 61.630739902],
  [-72.0145099, 61.630800002],
  [-72.01514, 61.629780002],
  [-72.0172899, 61.629830102],
  [-72.0168301, 61.629040102],
  [-72.0210799, 61.629529902],
  [-72.0287101, 61.628540002],
  [-72.02866, 61.629060102],
  [-72.03382, 61.631340002],
  [-72.04535, 61.632660002],
  [-72.04557, 61.632240002],
  [-72.0439601, 61.632199902],
  [-72.04355, 61.630910002],
  [-72.04247, 61.630890002],
  [-72.04255, 61.630120002],
  [-72.0404199, 61.629820102],
  [-72.0389799, 61.628000002],
  [-72.04036, 61.627770002],
  [-72.03979, 61.625199902],
  [-72.0408599, 61.625220002],
  [-72.04103, 61.623430002],
  [-72.04435, 61.622480102],
  [-72.04633, 61.621500102],
  [-72.04667, 61.620739902],
  [-72.0482901, 61.620650002],
  [-72.0495001, 61.619270102],
  [-72.05057, 61.619299902],
  [-72.0506499, 61.618519902],
  [-72.0566201, 61.617880002],
  [-72.05667, 61.617370102],
  [-72.05827, 61.617660002],
  [-72.0584001, 61.616130002],
  [-72.05948, 61.616150102],
  [-72.0576201, 61.613040102],
  [-72.05869, 61.613059902],
  [-72.0587699, 61.612310002],
  [-72.0598401, 61.612320002],
  [-72.05989, 61.611810102],
  [-72.06482, 61.610890002],
  [-72.0648601, 61.610380102],
  [-72.06223, 61.609810102],
  [-72.06281, 61.609310002],
  [-72.0617401, 61.609289902],
  [-72.06179, 61.608780002],
  [-72.06337, 61.609069902],
  [-72.06323, 61.608640002],
  [-72.05757, 61.607920102],
  [-72.0610999, 61.604670002],
  [-72.0633001, 61.604200002],
  [-72.0606201, 61.604140102],
  [-72.0612099, 61.603519902],
  [-72.0782001, 61.602990002],
  [-72.07851, 61.602480102],
  [-72.08173, 61.602540002],
  [-72.0796301, 61.601990002],
  [-72.0802299, 61.601360102],
  [-72.0958801, 61.600670002],
  [-72.11839, 61.601399902],
  [-72.13521, 61.605470102],
  [-72.13517, 61.605980002],
  [-72.13729, 61.606279902],
  [-72.1372401, 61.606790002],
  [-72.14762, 61.610839902],
  [-72.1491599, 61.611650002],
  [-72.14911, 61.612150102],
  [-72.15644, 61.614360002],
  [-72.1569101, 61.615010002],
  [-72.15851, 61.615169902],
  [-72.1584701, 61.615690002],
  [-72.16374, 61.616810102],
  [-72.1636901, 61.617330002],
  [-72.1893999, 61.618500102],
  [-72.18999, 61.617870002],
  [-72.1889201, 61.617849902],
  [-72.18924, 61.617350002],
  [-72.18752, 61.615519902],
  [-72.18942, 61.615299902],
  [-72.1909201, 61.613670002],
  [-72.19197, 61.613820102],
  [-72.1920401, 61.613050102],
  [-72.1974801, 61.612389902],
  [-72.2000599, 61.610660002],
  [-72.2031, 61.609690002],
  [-72.20303, 61.610460002],
  [-72.20788, 61.610299902],
  [-72.2073901, 61.609780002],
  [-72.2111999, 61.609350002],
  [-72.2131799, 61.611179902],
  [-72.21748, 61.611270102],
  [-72.22115, 61.609289902],
  [-72.23545, 61.608690002],
  [-72.2445499, 61.609130002],
  [-72.2505301, 61.608350002],
  [-72.25058, 61.607839902],
  [-72.2537501, 61.608420002],
  [-72.2528799, 61.606100002],
  [-72.25181, 61.606069902],
  [-72.2518501, 61.605560002],
  [-72.2485201, 61.606780002],
  [-72.24858, 61.606260102],
  [-72.24946, 61.606179902],
  [-72.2491901, 61.605250102],
  [-72.25081, 61.605160102],
  [-72.25401, 61.603470102],
  [-72.2537599, 61.602270102],
  [-72.25268, 61.602260102],
  [-72.2519701, 61.598140102],
  [-72.25039, 61.597849902],
  [-72.24927, 61.595260102],
  [-72.2514299, 61.592110002],
  [-72.25305, 61.592020102],
  [-72.2581099, 61.589560002],
  [-72.2582001, 61.588540002],
  [-72.2625, 61.588499902],
  [-72.2644299, 61.587890002],
  [-72.26591, 61.586389902],
  [-72.2669801, 61.586410002],
  [-72.2682199, 61.584389902],
  [-72.27038, 61.584430002],
  [-72.2722299, 61.581650002],
  [-72.2706501, 61.581360102],
  [-72.2707799, 61.579839902],
  [-72.27183, 61.580110002],
  [-72.27306, 61.578340002],
  [-72.2744201, 61.578110002],
  [-72.27659, 61.574829902],
  [-72.2755201, 61.574800102],
  [-72.27563, 61.573530002],
  [-72.2767, 61.573550002],
  [-72.2767899, 61.572530002],
  [-72.27574, 61.572250102],
  [-72.27514, 61.573000002],
  [-72.27407, 61.572980002],
  [-72.2741901, 61.571450102],
  [-72.2761199, 61.570980002],
  [-72.2755201, 61.568660002],
  [-72.27659, 61.568680102],
  [-72.27673, 61.567149902],
  [-72.27836, 61.566919902],
  [-72.2767699, 61.566640002],
  [-72.27696, 61.564340102],
  [-72.2758999, 61.564320002],
  [-72.27718, 61.562039902],
  [-72.2750099, 61.562249902],
  [-72.27383, 61.557359902],
  [-72.27115, 61.557310002],
  [-72.2711099, 61.557819902],
  [-72.2695, 61.557790102],
  [-72.26695, 61.559269902],
  [-72.26657, 61.560540002],
  [-72.2682001, 61.560310002],
  [-72.26659, 61.563359902],
  [-72.26891, 61.564430002],
  [-72.26894, 61.567250102],
  [-72.2677699, 61.568250102],
  [-72.2681099, 61.570560002],
  [-72.2658501, 61.574870002],
  [-72.2637, 61.574819902],
  [-72.26384, 61.573290002],
  [-72.26117, 61.573110002],
  [-72.25846, 61.573440002],
  [-72.2584101, 61.573950002],
  [-72.2557799, 61.573379902],
  [-72.25312, 61.576149902],
  [-72.25495, 61.576700102],
  [-72.2549, 61.577220002],
  [-72.25059, 61.577379902],
  [-72.2499, 61.579149902],
  [-72.24882, 61.579130102],
  [-72.2487501, 61.579900102],
  [-72.24768, 61.579880002],
  [-72.24761, 61.580640002],
  [-72.2459799, 61.580870002],
  [-72.24558, 61.582400002],
  [-72.2440801, 61.584159902],
  [-72.24191, 61.584370102],
  [-72.2400801, 61.586890002],
  [-72.239, 61.586870002],
  [-72.23837, 61.587880002],
  [-72.23735, 61.587350102],
  [-72.2373001, 61.587860002],
  [-72.23511, 61.588330002],
  [-72.2339501, 61.589330002],
  [-72.2323199, 61.589550002],
  [-72.23225, 61.590320002],
  [-72.2311801, 61.590300002],
  [-72.23113, 61.590810102],
  [-72.21689, 61.593850002],
  [-72.21398, 61.596350002],
  [-72.20481, 61.596670002],
  [-72.2047499, 61.597440002],
  [-72.2003701, 61.598120002],
  [-72.1996199, 61.600660002],
  [-72.19798, 61.600880002],
  [-72.19735, 61.601900002],
  [-72.1962799, 61.601870002],
  [-72.194, 61.603360102],
  [-72.1929201, 61.603340002],
  [-72.1933701, 61.604370102],
  [-72.1907101, 61.604059902],
  [-72.19113, 61.605350002],
  [-72.18634, 61.604870002],
  [-72.17555, 61.605289902],
  [-72.1754999, 61.605800002],
  [-72.1700599, 61.606450002],
  [-72.1686901, 61.603849902],
  [-72.15854, 61.603140102],
  [-72.15887, 61.602380102],
  [-72.16564, 61.601880002],
  [-72.1711501, 61.600460002],
  [-72.1723701, 61.598820102],
  [-72.1681599, 61.597839902],
  [-72.1494701, 61.596550002],
  [-72.1484899, 61.595509902],
  [-72.14742, 61.595489902],
  [-72.1474701, 61.594980002],
  [-72.14588, 61.594690102],
  [-72.1459299, 61.594169902],
  [-72.14486, 61.594150102],
  [-72.14439, 61.593379902],
  [-72.13698, 61.592190002],
  [-72.13499, 61.590360102],
  [-72.12042, 61.585320002],
  [-72.11406, 61.584420002],
  [-72.0897799, 61.585560002],
  [-72.09074, 61.586870002],
  [-72.08914, 61.586580102],
  [-72.0874301, 61.587700102],
  [-72.0819801, 61.588470102],
  [-72.08193, 61.588980002],
  [-72.0776699, 61.588630002],
  [-72.07672, 61.587330002],
  [-72.0756401, 61.587310002],
  [-72.07572, 61.586540002],
  [-72.07679, 61.586570102],
  [-72.07817, 61.583269902],
  [-72.0771099, 61.583120002],
  [-72.0733001, 61.583680102],
  [-72.0694965, 61.585671402],
  [-72.0699303, 61.586324202],
  [-72.068399, 61.586779602],
  [-72.0643061, 61.587847402],
  [-72.0581714, 61.588369602],
  [-72.0500428, 61.587707802],
  [-72.0410785, 61.585002702],
  [-72.0361082, 61.584838002],
  [-72.0293706, 61.583149902],
  [-72.0228201, 61.583480102],
  [-72.0207444, 61.584534402],
  [-72.0179881, 61.584704402],
  [-72.0162016, 61.587048202],
  [-72.0160484, 61.589719702],
  [-72.0164568, 61.590982502],
  [-72.0174266, 61.591583602],
  [-72.0165972, 61.592330302],
  [-72.0166354, 61.595911802],
  [-72.0158315, 61.596142502],
  [-72.01614, 61.597030102],
  [-72.0141854, 61.598127302],
  [-72.0139429, 61.599323002],
  [-72.0122202, 61.599760002],
  [-72.0090301, 61.598248702],
  [-72.0037001, 61.597910102],
  [-71.992454, 61.595605302],
  [-71.9732109, 61.595286602],
  [-71.9669823, 61.596158502],
  [-71.9644628, 61.594726602],
  [-71.9643932, 61.595156702],
  [-71.9664093, 61.595939902],
  [-71.9650377, 61.597673602],
  [-71.9583767, 61.600987502],
  [-71.9568085, 61.606507502],
  [-71.9568569, 61.608122202],
  [-71.9582044, 61.609161502],
  [-71.9579237, 61.610641902],
  [-71.9586127, 61.611382102],
  [-71.9579492, 61.612904902],
  [-71.9497099, 61.618260102],
  [-71.9447291, 61.623247002],
  [-71.9457245, 61.623295502],
  [-71.944895, 61.624757102],
  [-71.9460435, 61.626394402],
  [-71.9459031, 61.628904902],
  [-71.9469686, 61.632477602],
  [-71.9464072, 61.633623502],
  [-71.9468027, 61.636876002],
  [-71.9477917, 61.638123202],
  [-71.9358031, 61.644578402],
  [-71.9288995, 61.646720702],
  [-71.9246885, 61.649314302],
  [-71.9247523, 61.650029302],
  [-71.9169811, 61.652192502],
  [-71.9156923, 61.653143702],
  [-71.9159602, 61.653967702],
  [-71.9124383, 61.655337002],
  [-71.9100648, 61.657517902],
  [-71.9148883, 61.661201002],
  [-71.9148947, 61.665036502],
  [-71.91339, 61.666659902],
  [-71.91383, 61.668439902],
  [-71.91285, 61.669490002],
  [-71.9133101, 61.671520102],
  [-71.9122399, 61.671549902],
  [-71.91208, 61.672590002],
  [-71.9167799, 61.674010002],
  [-71.9194401, 61.676520102],
  [-71.91949, 61.679840002],
  [-71.91841, 61.679870102],
  [-71.91814, 61.682690002],
  [-71.91706, 61.682710002],
  [-71.9178701, 61.685510002],
  [-71.91625, 61.685549902],
  [-71.9157601, 61.691700002],
  [-71.9206701, 61.692360002],
  [-71.92127, 61.692990102],
  [-71.9324501, 61.691600002],
  [-71.93241, 61.691090102],
  [-71.9376701, 61.689690002],
  [-71.93772, 61.690210102],
  [-71.9414401, 61.689740002],
  [-71.94539, 61.691580002],
  [-71.9454401, 61.692100102],
  [-71.94928, 61.692769902],
  [-71.95031, 61.697870102],
  [-71.9513799, 61.697850002],
  [-71.9508901, 61.698370002],
  [-71.95283, 61.698840002],
  [-71.95288, 61.702170002],
  [-71.9481499, 61.703550102],
  [-71.9476899, 61.704320102],
  [-71.93938, 61.707710002],
  [-71.9211199, 61.708480002],
  [-71.92051, 61.707730002],
  [-71.9172001, 61.707030002],
  [-71.9154, 61.705019902],
  [-71.9126699, 61.704689902],
  [-71.9095, 61.705400002],
  [-71.9096401, 61.706930002],
  [-71.9124301, 61.707899902],
  [-71.9133399, 61.708899902],
  [-71.91314, 61.709679902],
  [-71.90604, 61.708799902],
  [-71.90463, 61.708060002],
  [-71.9048, 61.707040002],
  [-71.90372, 61.707060002],
  [-71.9035499, 61.705270002],
  [-71.8997899, 61.705339902],
  [-71.8993201, 61.706129902],
  [-71.89661, 61.705920002],
  [-71.89282, 61.702940002],
  [-71.89283, 61.700119902],
  [-71.8938999, 61.700109902],
  [-71.89535, 61.698280002],
  [-71.8964201, 61.698250002],
  [-71.8968999, 61.697600002],
  [-71.9001468, 61.697083202],
  [-71.8995834, 61.695779102],
  [-71.8976799, 61.694390002],
  [-71.88583, 61.694510002],
  [-71.88212, 61.695229902],
  [-71.88217, 61.695730002],
  [-71.88058, 61.696020002],
  [-71.8806301, 61.696540102],
  [-71.87693, 61.697380002],
  [-71.87647, 61.698160002],
  [-71.8716499, 61.698520002],
  [-71.8711801, 61.699300002],
  [-71.86903, 61.699339902],
  [-71.86961, 61.699840002],
  [-71.8674101, 61.699370002],
  [-71.86805, 61.700400002],
  [-71.8638, 61.701129902],
  [-71.86002, 61.701070002],
  [-71.8599699, 61.700559902],
  [-71.85671, 61.700239902],
  [-71.85078, 61.700239902],
  [-71.8507301, 61.699720002],
  [-71.85024, 61.700239902],
  [-71.8458538, 61.699413402],
  [-71.84159, 61.700040002],
  [-71.8388401, 61.699329902],
  [-71.8356001, 61.699400002],
  [-71.82567, 61.696920002],
  [-71.8252, 61.697690002],
  [-71.82033, 61.697540102],
  [-71.8202701, 61.696770102],
  [-71.81921, 61.697040002],
  [-71.8183801, 61.695870102],
  [-71.8148, 61.696119902],
  [-71.8147399, 61.695349902],
  [-71.8099201, 61.695700002],
  [-71.80986, 61.694940002],
  [-71.8077001, 61.694980102],
  [-71.80368, 61.692250002],
  [-71.79989, 61.692070002],
  [-71.79953, 61.691050002],
  [-71.8002699, 61.690270002],
  [-71.80244, 61.690480002],
  [-71.80497, 61.682750102],
  [-71.80735, 61.682200102],
  [-71.8094701, 61.678820002],
  [-71.81054, 61.678790002],
  [-71.8098399, 61.677020002],
  [-71.81145, 61.676980102],
  [-71.81234, 61.674920002],
  [-71.8112701, 61.674940002],
  [-71.81008, 61.673680002],
  [-71.8062899, 61.673500002],
  [-71.80678, 61.672979902],
  [-71.8020301, 61.674099902],
  [-71.8000201, 61.675680002],
  [-71.7953, 61.677060002],
  [-71.7938199, 61.678620002],
  [-71.7896099, 61.679730002],
  [-71.7861, 61.682620002],
  [-71.7850301, 61.682640102],
  [-71.78507, 61.683150002],
  [-71.7819201, 61.684109902],
  [-71.77177, 61.685090102],
  [-71.7695601, 61.684490002],
  [-71.7683699, 61.683240002],
  [-71.7673, 61.683260002],
  [-71.76407, 61.680250002],
  [-71.76354, 61.677449902],
  [-71.7624699, 61.677470002],
  [-71.76242, 61.676960102],
  [-71.7645799, 61.676910002],
  [-71.76441, 61.675130002],
  [-71.76864, 61.674140002],
  [-71.7685601, 61.673250002],
  [-71.76965, 61.673480002],
  [-71.7689299, 61.671450002],
  [-71.7700101, 61.671429902],
  [-71.7688001, 61.669920002],
  [-71.76606, 61.669460002],
  [-71.7660201, 61.668950002],
  [-71.76066, 61.669310102],
  [-71.7607099, 61.669810002],
  [-71.75963, 61.669850102],
  [-71.75865, 61.670889902],
  [-71.7554699, 61.671470002],
  [-71.7534501, 61.673040002],
  [-71.75238, 61.673060002],
  [-71.7524699, 61.674090102],
  [-71.75086, 61.674120002],
  [-71.7494501, 61.676449902],
  [-71.7465799, 61.677539902],
  [-71.7469199, 61.678290002],
  [-71.74585, 61.678439902],
  [-71.7442101, 61.678090102],
  [-71.73898, 61.673840002],
  [-71.72753, 61.672280002],
  [-71.72748, 61.671769902],
  [-71.72641, 61.671790002],
  [-71.7263201, 61.670769902],
  [-71.7252399, 61.670790002],
  [-71.72461, 61.669779902],
  [-71.7229801, 61.669560002],
  [-71.72292, 61.669040002],
  [-71.7124499, 61.666170002],
  [-71.71196, 61.663630102],
  [-71.7136401, 61.661290102],
  [-71.71115, 61.660570002],
  [-71.71137, 61.660070102],
  [-71.70871, 61.660370002],
  [-71.7034, 61.667380002],
  [-71.70182, 61.667800002],
  [-71.6952001, 61.666010002],
  [-71.69569, 61.665490002],
  [-71.69462, 61.665510102],
  [-71.6923101, 61.663759902],
  [-71.69124, 61.663780002],
  [-71.69227, 61.663250002],
  [-71.6889899, 61.662800002],
  [-71.68784, 61.661800002],
  [-71.6850999, 61.661340002],
  [-71.68503, 61.660580002],
  [-71.6839601, 61.660600002],
  [-71.6838899, 61.659830002],
  [-71.68226, 61.659610002],
  [-71.6821901, 61.658840102],
  [-71.6784299, 61.658910002],
  [-71.67494, 61.655910002],
  [-71.67014, 61.656520102],
  [-71.66714, 61.659140002],
  [-71.66179, 61.659500002],
  [-71.6617401, 61.658979902],
  [-71.6411799, 61.657850102],
  [-71.64166, 61.657070102],
  [-71.64002, 61.656850102],
  [-71.63992, 61.655820002],
  [-71.63613, 61.655380002],
  [-71.6360799, 61.654869902],
  [-71.63501, 61.654890002],
  [-71.63492, 61.653869902],
  [-71.6338499, 61.653890002],
  [-71.63443, 61.654390102],
  [-71.6312, 61.654460002],
  [-71.6311601, 61.653940002],
  [-71.62957, 61.654230002],
  [-71.62953, 61.653720002],
  [-71.6257001, 61.653030002],
  [-71.6263999, 61.651730102],
  [-71.62602, 61.650460002],
  [-71.6244099, 61.650490002],
  [-71.62378, 61.649480002],
  [-71.6221601, 61.649510102],
  [-71.62211, 61.648740102],
  [-71.6210301, 61.648759902],
  [-71.62102, 61.645440002],
  [-71.6201501, 61.644680002],
  [-71.61798, 61.644470002],
  [-71.6179301, 61.643960102],
  [-71.6163, 61.643730102],
  [-71.61677, 61.642960102],
  [-71.6151599, 61.642990002],
  [-71.61512, 61.642480002],
  [-71.6118201, 61.641770002],
  [-71.6111799, 61.640500102],
  [-71.60899, 61.640030002],
  [-71.6090301, 61.640539902],
  [-71.6069301, 61.641100002],
  [-71.60633, 61.640340002],
  [-71.60196, 61.639660002],
  [-71.6019, 61.638890002],
  [-71.59808, 61.638189902],
  [-71.5985701, 61.637670002],
  [-71.5909399, 61.636539902],
  [-71.58866, 61.635040002],
  [-71.5875899, 61.635060102],
  [-71.58753, 61.634299902],
  [-71.5837901, 61.634620102],
  [-71.5838299, 61.635140002],
  [-71.5783699, 61.634210002],
  [-71.57632, 61.632199902],
  [-71.57655, 61.631690002],
  [-71.5754799, 61.631710102],
  [-71.57318, 61.629959902],
  [-71.57155, 61.629730102],
  [-71.5714601, 61.628710102],
  [-71.57039, 61.628730102],
  [-71.56972, 61.627210002],
  [-71.5681101, 61.627240002],
  [-71.5667862, 61.626354902],
  [-71.5654, 61.627030002],
  [-71.56427, 61.626289902],
  [-71.5642, 61.625519902],
  [-71.5625701, 61.625299902],
  [-71.5618901, 61.623519902],
  [-71.5602499, 61.623289902],
  [-71.5595699, 61.621509902],
  [-71.55796, 61.621540002],
  [-71.55716, 61.620619902],
  [-71.5546701, 61.620839902],
  [-71.5494599, 61.616330002],
  [-71.54839, 61.616350002],
  [-71.5466899, 61.612030102],
  [-71.5473301, 61.609980002],
  [-71.54972, 61.609660002],
  [-71.5501101, 61.607870002],
  [-71.54885, 61.605590102],
  [-71.5495501, 61.604300002],
  [-71.5524401, 61.603470102],
  [-71.5521101, 61.602710102],
  [-71.55105, 61.602990002],
  [-71.55064, 61.601200002],
  [-71.5530099, 61.600520002],
  [-71.55464, 61.600870002],
  [-71.55404, 61.600110002],
  [-71.5524301, 61.600140102],
  [-71.5504501, 61.598900002],
  [-71.55397, 61.596020102],
  [-71.5543599, 61.594220002],
  [-71.55617, 61.593289902],
  [-71.5572499, 61.593399902],
  [-71.5572101, 61.592890002],
  [-71.55927, 61.591820102],
  [-71.5566601, 61.592770002],
  [-71.55229, 61.591949902],
  [-71.5523401, 61.592460002],
  [-71.5496999, 61.593030102],
  [-71.55019, 61.592489902],
  [-71.5422101, 61.593420002],
  [-71.54103, 61.592159902],
  [-71.53996, 61.592179902],
  [-71.5358899, 61.588430002],
  [-71.5326501, 61.588220002],
  [-71.53205, 61.587470102],
  [-71.5309801, 61.587489902],
  [-71.52947, 61.585470102],
  [-71.5296799, 61.584690102],
  [-71.5286, 61.584709902],
  [-71.52795, 61.583450002],
  [-71.5263499, 61.583480102],
  [-71.52625, 61.582200002],
  [-71.5252001, 61.582470102],
  [-71.52293, 61.580980002],
  [-71.5207601, 61.580760002],
  [-71.51983, 61.579240102],
  [-71.52293, 61.577640002],
  [-71.5225599, 61.576620002],
  [-71.5236501, 61.576609902],
  [-71.52352, 61.575080002],
  [-71.52484, 61.574800102],
  [-71.52449, 61.573780002],
  [-71.5271299, 61.573220002],
  [-71.5264499, 61.571440002],
  [-71.5329999, 61.569530002],
  [-71.5332101, 61.568770002],
  [-71.5355599, 61.567950002],
  [-71.5362201, 61.566149902],
  [-71.5373101, 61.566379902],
  [-71.5414499, 61.564510002],
  [-71.54141, 61.564000002],
  [-71.5437999, 61.563699902],
  [-71.5575899, 61.564970002],
  [-71.55685, 61.565760002],
  [-71.5663401, 61.570190002],
  [-71.56794, 61.573230102],
  [-71.5690101, 61.573210002],
  [-71.5693, 61.576790102],
  [-71.57076, 61.574970002],
  [-71.5724, 61.575190002],
  [-71.57283, 61.573910102],
  [-71.5765799, 61.573829902],
  [-71.5793401, 61.574810102],
  [-71.58077, 61.572599902],
  [-71.58399, 61.572540002],
  [-71.58544, 61.570599902],
  [-71.5875899, 61.570680102],
  [-71.5879001, 61.567990002],
  [-71.58898, 61.567970002],
  [-71.58778, 61.566450102],
  [-71.5893601, 61.566170002],
  [-71.58941, 61.566680102],
  [-71.59532, 61.566819902],
  [-71.5953899, 61.567589902],
  [-71.59918, 61.568029902],
  [-71.59922, 61.568540002],
  [-71.60144, 61.569390002],
  [-71.6057199, 61.569190002],
  [-71.60566, 61.568420002],
  [-71.60679, 61.569170002],
  [-71.6083899, 61.569010102],
  [-71.6105901, 61.569599902],
  [-71.61122, 61.570740002],
  [-71.61022, 61.571660002],
  [-71.61208, 61.571369902],
  [-71.61177, 61.570860002],
  [-71.6133999, 61.571100002],
  [-71.6116901, 61.569840002],
  [-71.6129499, 61.569980002],
  [-71.6136099, 61.569379902],
  [-71.6101699, 61.567819902],
  [-71.612, 61.567269902],
  [-71.61265, 61.568540002],
  [-71.6158499, 61.568350102],
  [-71.617, 61.569230102],
  [-71.6202799, 61.569800102],
  [-71.62077, 61.569410002],
  [-71.6174901, 61.568699902],
  [-71.61852, 61.568170002],
  [-71.6163801, 61.568210002],
  [-71.61631, 61.567460102],
  [-71.6152601, 61.567719902],
  [-71.61488, 61.566450102],
  [-71.61833, 61.565870002],
  [-71.6140401, 61.565950002],
  [-71.6120599, 61.564709902],
  [-71.6122799, 61.564200002],
  [-71.6079101, 61.563259902],
  [-71.6081301, 61.562740002],
  [-71.60611, 61.560990002],
  [-71.6039399, 61.560770002],
  [-71.60441, 61.559990002],
  [-71.6011499, 61.559540002],
  [-71.60109, 61.558780102],
  [-71.6000201, 61.558800102],
  [-71.5993501, 61.557280002],
  [-71.60043, 61.557249902],
  [-71.60007, 61.556249902],
  [-71.6010599, 61.555190002],
  [-71.6007299, 61.554430002],
  [-71.5964601, 61.554770002],
  [-71.5969499, 61.554249902],
  [-71.5953501, 61.554280002],
  [-71.59475, 61.553520002],
  [-71.5984999, 61.553450102],
  [-71.5972799, 61.551680102],
  [-71.6025901, 61.551070002],
  [-71.60254, 61.553630002],
  [-71.6012601, 61.554420002],
  [-71.60342, 61.554510002],
  [-71.60489, 61.552950002],
  [-71.6070499, 61.553029902],
  [-71.60998, 61.556050002],
  [-71.6163701, 61.555410002],
  [-71.61423, 61.555460102],
  [-71.6141401, 61.554430002],
  [-71.6130699, 61.554450102],
  [-71.61187, 61.552940002],
  [-71.6085901, 61.552240102],
  [-71.60855, 61.551720002],
  [-71.6122699, 61.551270002],
  [-71.61257, 61.551650002],
  [-71.6118101, 61.552160002],
  [-71.6145801, 61.553270002],
  [-71.6172699, 61.553350102],
  [-71.61733, 61.554120102],
  [-71.62211, 61.553510002],
  [-71.6221601, 61.554020102],
  [-71.62654, 61.555090002],
  [-71.62865, 61.554670102],
  [-71.6295199, 61.555420002],
  [-71.62932, 61.556190002],
  [-71.6303899, 61.556170002],
  [-71.63043, 61.556670102],
  [-71.63121, 61.556410002],
  [-71.63086, 61.555390002],
  [-71.6339499, 61.554050002],
  [-71.6333901, 61.553550002],
  [-71.64189, 61.552620002],
  [-71.6419401, 61.553130102],
  [-71.6521, 61.552799902],
  [-71.65645, 61.553489902],
  [-71.6573801, 61.551809902],
  [-71.6589799, 61.551650002],
  [-71.6601601, 61.552900102],
  [-71.66603, 61.552530002],
  [-71.6665101, 61.551880002],
  [-71.66837, 61.551589902],
  [-71.66849, 61.549919902],
  [-71.6727499, 61.549589902],
  [-71.6735201, 61.549190002],
  [-71.6731901, 61.548430002],
  [-71.6753001, 61.548000102],
  [-71.67857, 61.548579902],
  [-71.6780999, 61.549350102],
  [-71.6835, 61.549760002],
  [-71.68357, 61.550530002],
  [-71.6868501, 61.551230102],
  [-71.68691, 61.552000102],
  [-71.6858601, 61.552270002],
  [-71.69169, 61.551390002],
  [-71.69227, 61.551890102],
  [-71.6936499, 61.549300002],
  [-71.6978899, 61.548579902],
  [-71.7065301, 61.549300002],
  [-71.70646, 61.548540002],
  [-71.7075401, 61.548520002],
  [-71.70948, 61.546170002],
  [-71.7084101, 61.546190002],
  [-71.70836, 61.545680102],
  [-71.7169999, 61.546280002],
  [-71.7176699, 61.547790102],
  [-71.7240099, 61.549720002],
  [-71.72439, 61.551010102],
  [-71.73138, 61.551240102],
  [-71.73664, 61.549990002],
  [-71.7350299, 61.550019902],
  [-71.73496, 61.549259902],
  [-71.7333599, 61.549280002],
  [-71.73106, 61.547540002],
  [-71.73483, 61.547720002],
  [-71.73542, 61.548340102],
  [-71.7396899, 61.548129902],
  [-71.73974, 61.548640002],
  [-71.7413499, 61.548610002],
  [-71.74139, 61.549120102],
  [-71.7451201, 61.548790102],
  [-71.7455899, 61.548010102],
  [-71.74396, 61.547790102],
  [-71.74717, 61.547720002],
  [-71.7443401, 61.545990002],
  [-71.7377999, 61.544840002],
  [-71.7393201, 61.543909902],
  [-71.7511201, 61.543799902],
  [-71.75143, 61.544310002],
  [-71.75497, 61.544890102],
  [-71.7624501, 61.544600002],
  [-71.76241, 61.544090002],
  [-71.7764899, 61.545589902],
  [-71.7725999, 61.544139902],
  [-71.77195, 61.542870002],
  [-71.77088, 61.542890102],
  [-71.77019, 61.541110102],
  [-71.7733799, 61.540790102],
  [-71.77342, 61.541300002],
  [-71.7744799, 61.541149902],
  [-71.77562, 61.541900102],
  [-71.78184, 61.542400002],
  [-71.78542, 61.543490002],
  [-71.79501, 61.542780102],
  [-71.8014899, 61.543160002],
  [-71.8059101, 61.544610002],
  [-71.8106506, 61.544512602],
  [-71.8112501, 61.544369902],
  [-71.8106927, 61.543487602],
  [-71.8079, 61.542900102],
  [-71.8078101, 61.541880102],
  [-71.80508, 61.541430002],
  [-71.80499, 61.540400002],
  [-71.80064, 61.539720002],
  [-71.7989, 61.538220102],
  [-71.80474, 61.537589902],
  [-71.80478, 61.538100102],
  [-71.81279, 61.537679902],
  [-71.8133999, 61.538440102],
  [-71.81021, 61.538760002],
  [-71.8155901, 61.538909902],
  [-71.8172601, 61.539650002],
  [-71.81666, 61.538880102],
  [-71.82199, 61.538520002],
  [-71.8221, 61.539780102],
  [-71.82846, 61.538890102],
  [-71.8285001, 61.539410002],
  [-71.83276, 61.539070002],
  [-71.82899, 61.538880102],
  [-71.82769, 61.536349902],
  [-71.8266199, 61.536369902],
  [-71.834, 61.534940002],
  [-71.8329301, 61.534960002],
  [-71.83232, 61.534210102],
  [-71.83598, 61.533110102],
  [-71.83491, 61.533129902],
  [-71.8359301, 61.532600002],
  [-71.8348599, 61.532620002],
  [-71.83425, 61.531860002],
  [-71.8337201, 61.531870002],
  [-71.8338301, 61.533150002],
  [-71.83169, 61.533200002],
  [-71.83123, 61.533970002],
  [-71.8286001, 61.534540102],
  [-71.82736, 61.532770102],
  [-71.8299699, 61.531950002],
  [-71.8283701, 61.531980002],
  [-71.82832, 61.531469902],
  [-71.81711, 61.532090002],
  [-71.809, 61.531359902],
  [-71.8096099, 61.532119902],
  [-71.80533, 61.532200002],
  [-71.8052601, 61.531440102],
  [-71.80311, 61.531480002],
  [-71.8037199, 61.532239902],
  [-71.7956901, 61.532400002],
  [-71.79522, 61.533180002],
  [-71.7936199, 61.533210102],
  [-71.79354, 61.532450102],
  [-71.7849499, 61.532370002],
  [-71.7849201, 61.531850002],
  [-71.78278, 61.531899902],
  [-71.78273, 61.531390002],
  [-71.7794701, 61.530940002],
  [-71.77996, 61.530420002],
  [-71.7722401, 61.528019902],
  [-71.77077, 61.526510002],
  [-71.7709399, 61.525480002],
  [-71.76764, 61.524530002],
  [-71.76699, 61.523260002],
  [-71.7691301, 61.523220102],
  [-71.7695999, 61.522440102],
  [-71.7663401, 61.521990102],
  [-71.7671, 61.521459902],
  [-71.76676, 61.520700002],
  [-71.7724799, 61.520750002],
  [-71.7715801, 61.520610002],
  [-71.7714899, 61.519579902],
  [-71.77341, 61.520060002],
  [-71.7726901, 61.521090102],
  [-71.77455, 61.520799902],
  [-71.7735899, 61.519280002],
  [-71.7746701, 61.519250002],
  [-71.77463, 61.518750002],
  [-71.77628, 61.519229902],
  [-71.7764799, 61.518459902],
  [-71.77186, 61.514710002],
  [-71.7720299, 61.513679902],
  [-71.77043, 61.513730002],
  [-71.7703599, 61.512950002],
  [-71.76506, 61.513569902],
  [-71.76443, 61.512550102],
  [-71.7489304, 61.508873102],
  [-71.7449246, 61.507320502],
  [-71.7453201, 61.505520002],
  [-71.74949, 61.504160002],
  [-71.7455101, 61.503525702],
  [-71.7506871, 61.503324902],
  [-71.7509185, 61.503914002],
  [-71.7498443, 61.504225102],
  [-71.75462, 61.504690002],
  [-71.7582135, 61.504592602],
  [-71.7579917, 61.504062402],
  [-71.7615, 61.503659902],
  [-71.7516114, 61.502296002],
  [-71.7580299, 61.500779902],
  [-71.7615799, 61.501610002],
  [-71.7613799, 61.502380002],
  [-71.7624501, 61.502360002],
  [-71.7629001, 61.501320102],
  [-71.7661499, 61.501769902],
  [-71.76555, 61.501009902],
  [-71.7682401, 61.501219902],
  [-71.76829, 61.501730002],
  [-71.77328, 61.500600002],
  [-71.7729101, 61.499449902],
  [-71.781, 61.500060002],
  [-71.7825899, 61.499899902],
  [-71.78255, 61.499390002],
  [-71.7809201, 61.499170002],
  [-71.7900201, 61.499109902],
  [-71.7937099, 61.498390002],
  [-71.79378, 61.499170002],
  [-71.79531, 61.498360002],
  [-71.7995901, 61.498270002],
  [-71.79963, 61.498779902],
  [-71.8043899, 61.498040002],
  [-71.80487, 61.497400002],
  [-71.8080699, 61.497329902],
  [-71.8064701, 61.497360002],
  [-71.8047299, 61.495860102],
  [-71.7971301, 61.494740102],
  [-71.79708, 61.494229902],
  [-71.7938299, 61.493769902],
  [-71.79378, 61.493270002],
  [-71.7894601, 61.492850102],
  [-71.78774, 61.491600002],
  [-71.7861401, 61.491630102],
  [-71.7843799, 61.489879902],
  [-71.7746099, 61.488539902],
  [-71.77508, 61.487759902],
  [-71.7702, 61.487099902],
  [-71.7695999, 61.486339902],
  [-71.76744, 61.486130002],
  [-71.7679101, 61.485350002],
  [-71.7646801, 61.485160002],
  [-71.7642301, 61.483120002],
  [-71.7657901, 61.482580002],
  [-71.7654501, 61.481820002],
  [-71.76162, 61.480869902],
  [-71.76153, 61.479850102],
  [-71.75186, 61.479530102],
  [-71.7506801, 61.478270002],
  [-71.74362, 61.477140002],
  [-71.7435799, 61.476630102],
  [-71.7414401, 61.476670002],
  [-71.7413499, 61.475649902],
  [-71.73759, 61.475470002],
  [-71.73752, 61.474710002],
  [-71.73538, 61.474740102],
  [-71.7353399, 61.474230002],
  [-71.7311299, 61.475080102],
  [-71.73028, 61.474590002],
  [-71.72997, 61.474080102],
  [-71.7331101, 61.473240002],
  [-71.7320099, 61.472890002],
  [-71.7283, 61.473350002],
  [-71.72826, 61.472840102],
  [-71.72559, 61.472890002],
  [-71.7256699, 61.473910002],
  [-71.7235599, 61.474209902],
  [-71.72372, 61.475999902],
  [-71.7151001, 61.475400102],
  [-71.71336, 61.473900002],
  [-71.71229, 61.473920002],
  [-71.7116501, 61.472670002],
  [-71.71005, 61.472690002],
  [-71.70907, 61.470659902],
  [-71.70795, 61.470170102],
  [-71.70838, 61.468869902],
  [-71.71117, 61.467160002],
  [-71.71815, 61.467410102],
  [-71.71819, 61.467920002],
  [-71.7256501, 61.467510102],
  [-71.72574, 61.468539902],
  [-71.7236699, 61.469340002],
  [-71.72383, 61.471130002],
  [-71.7333499, 61.469920002],
  [-71.7334, 61.470429902],
  [-71.7414599, 61.470780002],
  [-71.74139, 61.470010002],
  [-71.7503599, 61.468549902],
  [-71.75029, 61.467790002],
  [-71.75183, 61.466989902],
  [-71.7693599, 61.465610102],
  [-71.7691399, 61.463060102],
  [-71.77173, 61.462099902],
  [-71.7721599, 61.460940002],
  [-71.78062, 61.459869902],
  [-71.7815999, 61.458960102],
  [-71.7832401, 61.459180102],
  [-71.78288, 61.458160002],
  [-71.7866099, 61.455010002],
  [-71.78553, 61.455050102],
  [-71.7860301, 61.454510102],
  [-71.7843799, 61.454040002],
  [-71.7858101, 61.452089902],
  [-71.7963701, 61.450460002],
  [-71.7963, 61.449700002],
  [-71.7983899, 61.449140002],
  [-71.79834, 61.448629902],
  [-71.8010201, 61.448700002],
  [-71.81099, 61.446580002],
  [-71.8110401, 61.447089902],
  [-71.8327101, 61.444330002],
  [-71.83276, 61.444859902],
  [-71.8296299, 61.445680002],
  [-71.8298499, 61.446100002],
  [-71.83655, 61.445280102],
  [-71.8366201, 61.446050102],
  [-71.8436001, 61.446409902],
  [-71.84356, 61.445900002],
  [-71.85248, 61.444050102],
  [-71.85625, 61.444350002],
  [-71.8559401, 61.443849902],
  [-71.8571899, 61.442930102],
  [-71.85827, 61.443030002],
  [-71.85822, 61.442529902],
  [-71.86055, 61.441700002],
  [-71.86172, 61.439890002],
  [-71.8660999, 61.438940102],
  [-71.86694, 61.438500102],
  [-71.8658501, 61.438260002],
  [-71.86794, 61.437710102],
  [-71.86634, 61.437739902],
  [-71.8662901, 61.437230002],
  [-71.86736, 61.437210002],
  [-71.8673099, 61.436690002],
  [-71.86949, 61.437160102],
  [-71.8677699, 61.435920002],
  [-71.8602801, 61.435830102],
  [-71.87146, 61.435330002],
  [-71.8714199, 61.434820102],
  [-71.8671701, 61.435160102],
  [-71.8666201, 61.432100002],
  [-71.8646399, 61.430880002],
  [-71.8678401, 61.430800002],
  [-71.86789, 61.431320002],
  [-71.8717301, 61.432509902],
  [-71.87167, 61.431739902],
  [-71.87004, 61.431519902],
  [-71.87, 61.431010002],
  [-71.87104, 61.430720102],
  [-71.86457, 61.430100002],
  [-71.8645201, 61.429590102],
  [-71.8607401, 61.429150102],
  [-71.86069, 61.428639902],
  [-71.8564001, 61.428480102],
  [-71.85345, 61.425470002],
  [-71.85713, 61.424750002],
  [-71.8588401, 61.423050102],
  [-71.8573901, 61.421800002],
  [-71.8575101, 61.423079902],
  [-71.85589, 61.422860002],
  [-71.85649, 61.423610102],
  [-71.8549401, 61.424160102],
  [-71.85489, 61.423650002],
  [-71.8505199, 61.422580002],
  [-71.83932, 61.422820102],
  [-71.83334, 61.421790002],
  [-71.83392, 61.422289902],
  [-71.83177, 61.422079902],
  [-71.83167, 61.421059902],
  [-71.82299, 61.419570002],
  [-71.8150501, 61.420380102],
  [-71.81458, 61.421160102],
  [-71.8135199, 61.421179902],
  [-71.81321, 61.423750002],
  [-71.8079301, 61.424370102],
  [-71.80802, 61.425389902],
  [-71.80642, 61.425420002],
  [-71.80646, 61.425930102],
  [-71.80008, 61.426320002],
  [-71.7989499, 61.425580002],
  [-71.79899, 61.426090002],
  [-71.79579, 61.426150102],
  [-71.7958399, 61.426660002],
  [-71.79478, 61.426810102],
  [-71.7925999, 61.426350002],
  [-71.7867901, 61.427100002],
  [-71.78675, 61.426590102],
  [-71.7845999, 61.426500102],
  [-71.7777901, 61.428059902],
  [-71.77775, 61.427550002],
  [-71.7766801, 61.427570002],
  [-71.7766099, 61.426790002],
  [-71.77341, 61.426870002],
  [-71.7722499, 61.425870002],
  [-71.7647801, 61.426020002],
  [-71.7647099, 61.425250002],
  [-71.76042, 61.425079902],
  [-71.7603599, 61.424330002],
  [-71.7645799, 61.423720102],
  [-71.7634899, 61.423480102],
  [-71.76398, 61.422959902],
  [-71.7623799, 61.422990002],
  [-71.7627999, 61.421710102],
  [-71.77073, 61.420780002],
  [-71.7707801, 61.421289902],
  [-71.7734601, 61.421360002],
  [-71.77552, 61.420550002],
  [-71.7840301, 61.420120002],
  [-71.7869101, 61.419420002],
  [-71.78762, 61.418380102],
  [-71.7843899, 61.418190002],
  [-71.7842799, 61.416920102],
  [-71.7826801, 61.416949902],
  [-71.78206, 61.415939902],
  [-71.7793899, 61.415990002],
  [-71.7789201, 61.416770002],
  [-71.76552, 61.416270102],
  [-71.7654799, 61.415760002],
  [-71.75906, 61.415640002],
  [-71.7584799, 61.415140102],
  [-71.75852, 61.415650002],
  [-71.75641, 61.415949902],
  [-71.7570399, 61.416959902],
  [-71.75597, 61.416980002],
  [-71.7555, 61.417760002],
  [-71.74917, 61.418650002],
  [-71.7492201, 61.419160102],
  [-71.74709, 61.419460002],
  [-71.74717, 61.420230002],
  [-71.7461001, 61.420250102],
  [-71.74615, 61.420760002],
  [-71.7403301, 61.421389902],
  [-71.73861, 61.420150102],
  [-71.7396799, 61.420130002],
  [-71.7401001, 61.418839902],
  [-71.73904, 61.418860002],
  [-71.73895, 61.417839902],
  [-71.7357, 61.417389902],
  [-71.7356601, 61.416880002],
  [-71.7335, 61.416670002],
  [-71.7334599, 61.416150102],
  [-71.7318999, 61.416700102],
  [-71.7313, 61.415939902],
  [-71.7297, 61.415970002],
  [-71.73017, 61.415200002],
  [-71.7291001, 61.415220002],
  [-71.72848, 61.414210002],
  [-71.7242101, 61.414289902],
  [-71.71961, 61.410540002],
  [-71.71418, 61.409499902],
  [-71.7104599, 61.409700102],
  [-71.7105, 61.410210002],
  [-71.7078899, 61.410900002],
  [-71.70361, 61.410860002],
  [-71.7035499, 61.410100002],
  [-71.7014399, 61.410389902],
  [-71.70193, 61.409870002],
  [-71.69602, 61.409480102],
  [-71.69428, 61.407980002],
  [-71.69323, 61.408000002],
  [-71.69315, 61.407230002],
  [-71.6867, 61.406719902],
  [-71.68458, 61.406890002],
  [-71.6838, 61.408090002],
  [-71.6841801, 61.408430002],
  [-71.6825499, 61.408210002],
  [-71.68315, 61.408959902],
  [-71.67938, 61.408530002],
  [-71.6795101, 61.410059902],
  [-71.6784399, 61.410080002],
  [-71.67848, 61.410590102],
  [-71.6731801, 61.411080002],
  [-71.671, 61.410480102],
  [-71.6719501, 61.409169902],
  [-71.67089, 61.409200002],
  [-71.66916, 61.407700102],
  [-71.66811, 61.407980002],
  [-71.66747, 61.406710102],
  [-71.66644, 61.407000002],
  [-71.66638, 61.406480102],
  [-71.6648, 61.406760002],
  [-71.6656201, 61.410080002],
  [-71.66402, 61.410110002],
  [-71.6634199, 61.409350002],
  [-71.66022, 61.409410002],
  [-71.6601801, 61.408900002],
  [-71.65054, 61.408580102],
  [-71.65101, 61.407800102],
  [-71.64568, 61.408030102],
  [-71.6429301, 61.407059902],
  [-71.6402999, 61.407499902],
  [-71.64036, 61.408260102],
  [-71.6392999, 61.408279902],
  [-71.63943, 61.409820102],
  [-71.63734, 61.410370102],
  [-71.63636, 61.411410002],
  [-71.64658, 61.412230002],
  [-71.6466201, 61.412750002],
  [-71.6444901, 61.412790002],
  [-71.64455, 61.413560002],
  [-71.64348, 61.413580102],
  [-71.6435299, 61.414090002],
  [-71.64088, 61.414399902],
  [-71.64148, 61.415140102],
  [-71.6404099, 61.415179902],
  [-71.6395001, 61.416990002],
  [-71.63632, 61.417299902],
  [-71.6369, 61.417800002],
  [-71.6358101, 61.417570002],
  [-71.6358599, 61.418080002],
  [-71.61991, 61.419160102],
  [-71.61987, 61.418650002],
  [-71.60854, 61.417340002],
  [-71.60674, 61.414800002],
  [-71.6018299, 61.413739902],
  [-71.6017299, 61.412590102],
  [-71.6001, 61.412230002],
  [-71.5868199, 61.413130002],
  [-71.5867901, 61.412619902],
  [-71.58306, 61.412810102],
  [-71.57944, 61.414300002],
  [-71.5800101, 61.414800002],
  [-71.57628, 61.414870002],
  [-71.5734799, 61.413250102],
  [-71.56918, 61.412970002],
  [-71.56599, 61.409939902],
  [-71.56651, 61.409680002],
  [-71.56607, 61.407640002],
  [-71.5671399, 61.407619902],
  [-71.5669099, 61.404800102],
  [-71.56798, 61.404910102],
  [-71.56999, 61.403340002],
  [-71.5763799, 61.403090002],
  [-71.5763401, 61.402580102],
  [-71.57896, 61.402020102],
  [-71.57954, 61.402520002],
  [-71.57906, 61.403300002],
  [-71.58488, 61.402670002],
  [-71.5849299, 61.403179902],
  [-71.58889, 61.402599902],
  [-71.5885801, 61.402090002],
  [-71.60245, 61.401949902],
  [-71.60733, 61.402880002],
  [-71.6090301, 61.404000002],
  [-71.6155099, 61.404900002],
  [-71.61502, 61.405430002],
  [-71.61609, 61.405410002],
  [-71.6166901, 61.406159902],
  [-71.61509, 61.406190002],
  [-71.6157101, 61.407200002],
  [-71.61997, 61.407120002],
  [-71.61844, 61.407920102],
  [-71.62545, 61.408810102],
  [-71.6255199, 61.409580102],
  [-71.6287299, 61.409640002],
  [-71.6297001, 61.408470102],
  [-71.6365199, 61.407059902],
  [-71.63588, 61.405790002],
  [-71.6337101, 61.405320002],
  [-71.6333701, 61.404560002],
  [-71.63598, 61.403740002],
  [-71.6356199, 61.402719902],
  [-71.634, 61.402499902],
  [-71.6333701, 61.401489902],
  [-71.6221601, 61.401450002],
  [-71.62212, 61.400939902],
  [-71.6178399, 61.400760002],
  [-71.6173701, 61.401540002],
  [-71.61419, 61.401860002],
  [-71.6141501, 61.401350102],
  [-71.61308, 61.401370102],
  [-71.6130201, 61.400599902],
  [-71.6108399, 61.400130102],
  [-71.60952, 61.397080002],
  [-71.6073701, 61.396870002],
  [-71.6072799, 61.395850002],
  [-71.60512, 61.395630002],
  [-71.6044899, 61.394360102],
  [-71.6080201, 61.395059902],
  [-71.6094899, 61.396700102],
  [-71.6148201, 61.396599902],
  [-71.6200301, 61.395090002],
  [-71.6206099, 61.395590102],
  [-71.62054, 61.394819902],
  [-71.6280401, 61.395059902],
  [-71.63077, 61.395790002],
  [-71.63255, 61.397920102],
  [-71.6314901, 61.397939902],
  [-71.63366, 61.398410002],
  [-71.6337001, 61.398920102],
  [-71.6374099, 61.398590102],
  [-71.6371899, 61.399110002],
  [-71.63854, 61.399340002],
  [-71.63869, 61.401130102],
  [-71.6478599, 61.402230002],
  [-71.64669, 61.400970002],
  [-71.6439599, 61.400260102],
  [-71.64414, 61.399230002],
  [-71.64598, 61.398939902],
  [-71.6453901, 61.398180002],
  [-71.65392, 61.398140102],
  [-71.65725, 61.399740002],
  [-71.65713, 61.398210002],
  [-71.6587599, 61.398430002],
  [-71.6587201, 61.397920102],
  [-71.6560501, 61.397960002],
  [-71.65592, 61.396440002],
  [-71.6521799, 61.396520002],
  [-71.65267, 61.395990002],
  [-71.64899, 61.396580102],
  [-71.64892, 61.395810102],
  [-71.6472999, 61.395590102],
  [-71.6478, 61.395059902],
  [-71.6429501, 61.394650002],
  [-71.6455099, 61.393320002],
  [-71.6451799, 61.392550002],
  [-71.6553901, 61.393379902],
  [-71.65499, 61.391860002],
  [-71.6522801, 61.391389902],
  [-71.65169, 61.390640002],
  [-71.6527499, 61.390609902],
  [-71.6531899, 61.389330002],
  [-71.65211, 61.389350102],
  [-71.6541601, 61.388279902],
  [-71.6540601, 61.387010102],
  [-71.6502999, 61.386819902],
  [-71.65025, 61.386310002],
  [-71.6481, 61.386100002],
  [-71.64859, 61.385580102],
  [-71.6389499, 61.385250102],
  [-71.6405299, 61.384960002],
  [-71.6402, 61.384200002],
  [-71.64091, 61.383170002],
  [-71.64198, 61.383140102],
  [-71.6392899, 61.382939902],
  [-71.63925, 61.382430002],
  [-71.64189, 61.382120102],
  [-71.6417399, 61.380330002],
  [-71.6396101, 61.380369902],
  [-71.63965, 61.380890002],
  [-71.6386101, 61.381159902],
  [-71.6385199, 61.380140102],
  [-71.6363701, 61.379929902],
  [-71.63633, 61.379410002],
  [-71.6347499, 61.379699902],
  [-71.6349699, 61.379190002],
  [-71.63413, 61.378690102],
  [-71.63598, 61.378400002],
  [-71.6371701, 61.376699902],
  [-71.63878, 61.376809902],
  [-71.6387299, 61.376300002],
  [-71.6429301, 61.375450102],
  [-71.64289, 61.374929902],
  [-71.6572801, 61.374780102],
  [-71.6577599, 61.374130102],
  [-71.6598699, 61.373960002],
  [-71.66101, 61.374709902],
  [-71.67012, 61.375170002],
  [-71.67003, 61.374149902],
  [-71.67326, 61.374470102],
  [-71.68217, 61.372640002],
  [-71.6822199, 61.373139902],
  [-71.6927799, 61.372029902],
  [-71.71069, 61.369369902],
  [-71.7198999, 61.370860002],
  [-71.71985, 61.370340102],
  [-71.72361, 61.370650002],
  [-71.7264, 61.372010102],
  [-71.7264401, 61.372520002],
  [-71.72964, 61.372450102],
  [-71.7286, 61.372730002],
  [-71.7286601, 61.373510002],
  [-71.7276, 61.373520002],
  [-71.7276601, 61.374280002],
  [-71.73616, 61.373860002],
  [-71.7362299, 61.374620002],
  [-71.7394301, 61.374560102],
  [-71.7394699, 61.375070002],
  [-71.74162, 61.375280002],
  [-71.7416701, 61.375790102],
  [-71.74538, 61.375460102],
  [-71.74542, 61.375970002],
  [-71.7485899, 61.375650002],
  [-71.74864, 61.376170002],
  [-71.7614699, 61.376410002],
  [-71.76152, 61.376929902],
  [-71.7652201, 61.376599902],
  [-71.7675, 61.378220002],
  [-71.7704799, 61.378790102],
  [-71.7708199, 61.379560102],
  [-71.77241, 61.379520002],
  [-71.77219, 61.380039902],
  [-71.77408, 61.380259902],
  [-71.7741301, 61.380770002],
  [-71.77573, 61.380860002],
  [-71.7774701, 61.382360102],
  [-71.78065, 61.382170002],
  [-71.78017, 61.382690102],
  [-71.7823899, 61.383670002],
  [-71.7841401, 61.382360102],
  [-71.7842699, 61.380819902],
  [-71.788, 61.380740002],
  [-71.7885901, 61.381360102],
  [-71.79155, 61.381690102],
  [-71.7930399, 61.383450002],
  [-71.79409, 61.383180002],
  [-71.79412, 61.383690102],
  [-71.8107199, 61.384240102],
  [-71.81132, 61.384870002],
  [-71.814, 61.385070002],
  [-71.81343, 61.384580102],
  [-71.81502, 61.384540002],
  [-71.81509, 61.385290002],
  [-71.81831, 61.385489902],
  [-71.8182701, 61.384980002],
  [-71.8172, 61.385000002],
  [-71.8171401, 61.384240102],
  [-71.8160699, 61.384259902],
  [-71.81598, 61.383240102],
  [-71.8174099, 61.383369902],
  [-71.8174999, 61.382440002],
  [-71.81644, 61.382460102],
  [-71.81635, 61.381440002],
  [-71.8179301, 61.381280002],
  [-71.8184099, 61.380630002],
  [-71.82578, 61.379580102],
  [-71.8272899, 61.378520002],
  [-71.8320501, 61.378039902],
  [-71.832, 61.377530002],
  [-71.84004, 61.377880002],
  [-71.8417499, 61.379120102],
  [-71.8455001, 61.379290002],
  [-71.8458401, 61.380049902],
  [-71.8471999, 61.380280002],
  [-71.8471, 61.379259902],
  [-71.84979, 61.379460102],
  [-71.8504199, 61.380470102],
  [-71.84935, 61.380499902],
  [-71.8505101, 61.381489902],
  [-71.8548401, 61.382170002],
  [-71.8546201, 61.382690102],
  [-71.85602, 61.383300002],
  [-71.85811, 61.382870002],
  [-71.85815, 61.383379902],
  [-71.88127, 61.385200002],
  [-71.8813399, 61.385960002],
  [-71.8845599, 61.386020102],
  [-71.89058, 61.387690102],
  [-71.89291, 61.389819902],
  [-71.8939701, 61.389790102],
  [-71.8941099, 61.391320002],
  [-71.9000099, 61.391580102],
  [-71.90049, 61.390929902],
  [-71.9015499, 61.390910102],
  [-71.9011901, 61.389890002],
  [-71.90407, 61.389320002],
  [-71.9044301, 61.390340002],
  [-71.9063201, 61.390550002],
  [-71.90584, 61.391070002],
  [-71.90805, 61.391920102],
  [-71.91128, 61.392240102],
  [-71.91136, 61.393000002],
  [-71.9134599, 61.392700102],
  [-71.91352, 61.393470102],
  [-71.9154, 61.393430002],
  [-71.91693, 61.395570102],
  [-71.91796, 61.395169902],
  [-71.9168999, 61.395190002],
  [-71.91671, 61.393149902],
  [-71.9106799, 61.391480102],
  [-71.90838, 61.389740002],
  [-71.9105401, 61.389949902],
  [-71.91049, 61.389440002],
  [-71.9088899, 61.389470102],
  [-71.90807, 61.388300002],
  [-71.90717, 61.388230002],
  [-71.9075501, 61.386690102],
  [-71.9059701, 61.386719902],
  [-71.9042299, 61.385220002],
  [-71.90207, 61.385000002],
  [-71.9003499, 61.383770002],
  [-71.89873, 61.383560002],
  [-71.89869, 61.383030102],
  [-71.89594, 61.382200002],
  [-71.89328, 61.382379902],
  [-71.8915499, 61.380880002],
  [-71.88946, 61.381440002],
  [-71.8894201, 61.380939902],
  [-71.88726, 61.380719902],
  [-71.88661, 61.379450102],
  [-71.88279, 61.378510002],
  [-71.88284, 61.379020102],
  [-71.8806599, 61.378550002],
  [-71.88017, 61.379080002],
  [-71.88013, 61.378560102],
  [-71.87591, 61.379170002],
  [-71.8741999, 61.377919902],
  [-71.8704199, 61.377489902],
  [-71.87035, 61.376719902],
  [-71.8692901, 61.376750002],
  [-71.8692199, 61.375970002],
  [-71.86158, 61.374220002],
  [-71.85414, 61.374500002],
  [-71.85215, 61.376080002],
  [-71.8485101, 61.377180002],
  [-71.84856, 61.377690102],
  [-71.8458699, 61.377489902],
  [-71.84455, 61.374699902],
  [-71.83926, 61.375330002],
  [-71.83921, 61.374560102],
  [-71.83545, 61.374369902],
  [-71.83536, 61.373350102],
  [-71.8229499, 61.371950002],
  [-71.81769, 61.372699902],
  [-71.8177399, 61.373220002],
  [-71.81357, 61.374330002],
  [-71.81365, 61.375090002],
  [-71.8057299, 61.376149902],
  [-71.7928001, 61.374750002],
  [-71.79109, 61.373510002],
  [-71.7900201, 61.373530002],
  [-71.7894, 61.372520002],
  [-71.78834, 61.372540002],
  [-71.7888199, 61.372020102],
  [-71.78234, 61.371130102],
  [-71.78218, 61.369340102],
  [-71.7768099, 61.368940002],
  [-71.77674, 61.368170002],
  [-71.7712401, 61.366230102],
  [-71.7711, 61.364699902],
  [-71.76839, 61.364240102],
  [-71.76799, 61.362720002],
  [-71.7750399, 61.361039902],
  [-71.775, 61.360530002],
  [-71.78822, 61.359239902],
  [-71.7882599, 61.359740002],
  [-71.79356, 61.359390002],
  [-71.7932501, 61.358870002],
  [-71.79696, 61.357600002],
  [-71.7970499, 61.356490002],
  [-71.7985801, 61.355820002],
  [-71.8012501, 61.355880102],
  [-71.80089, 61.354860002],
  [-71.8027299, 61.354579902],
  [-71.8026901, 61.354070002],
  [-71.8084999, 61.353440102],
  [-71.80845, 61.352930002],
  [-71.81789, 61.351190002],
  [-71.8183899, 61.347860002],
  [-71.8199401, 61.347310002],
  [-71.8200699, 61.345770102],
  [-71.82211, 61.344710002],
  [-71.812, 61.344919902],
  [-71.8119499, 61.344400002],
  [-71.81089, 61.344430102],
  [-71.811, 61.345699902],
  [-71.80677, 61.346050002],
  [-71.8067199, 61.345540102],
  [-71.8051, 61.345310002],
  [-71.80464, 61.346090002],
  [-71.802, 61.346400002],
  [-71.8020499, 61.346909902],
  [-71.78608, 61.347239902],
  [-71.78518, 61.349050002],
  [-71.78411, 61.349070002],
  [-71.7846901, 61.349569902],
  [-71.7831201, 61.349860002],
  [-71.7831599, 61.350369902],
  [-71.78208, 61.350139902],
  [-71.7821401, 61.350909902],
  [-71.78055, 61.350940002],
  [-71.7805801, 61.351450102],
  [-71.77742, 61.351770102],
  [-71.7774701, 61.352280002],
  [-71.7763699, 61.351790102],
  [-71.7764, 61.352300002],
  [-71.76694, 61.353780102],
  [-71.76698, 61.354290002],
  [-71.7611499, 61.354660102],
  [-71.76173, 61.355149902],
  [-71.75961, 61.355459902],
  [-71.7595701, 61.354950002],
  [-71.7479199, 61.355830002],
  [-71.7339801, 61.354960002],
  [-71.7344699, 61.354420002],
  [-71.73227, 61.353710002],
  [-71.7323201, 61.354220102],
  [-71.7200199, 61.353710002],
  [-71.71994, 61.352930002],
  [-71.7114, 61.352850002],
  [-71.7114499, 61.353359902],
  [-71.70774, 61.353689902],
  [-71.7069899, 61.351139902],
  [-71.69893, 61.350410002],
  [-71.68028, 61.350520002],
  [-71.67703, 61.349950002],
  [-71.67672, 61.349440102],
  [-71.68258, 61.346249902],
  [-71.6836401, 61.346230102],
  [-71.6828, 61.345740002],
  [-71.68348, 61.344310002],
  [-71.6927401, 61.343490002],
  [-71.6929601, 61.342980002],
  [-71.6918899, 61.343000102],
  [-71.69223, 61.340689902],
  [-71.69116, 61.340710002],
  [-71.6889, 61.339220102],
  [-71.6868, 61.339510002],
  [-71.6873001, 61.339119902],
  [-71.6920901, 61.339019902],
  [-71.6945, 61.338170002],
  [-71.6936, 61.338100102],
  [-71.6935399, 61.337330102],
  [-71.6908601, 61.337129902],
  [-71.6914299, 61.337630002],
  [-71.68984, 61.337660102],
  [-71.68979, 61.337150002],
  [-71.6866599, 61.337970002],
  [-71.68713, 61.337200002],
  [-71.68971, 61.336129902],
  [-71.68812, 61.336410002],
  [-71.6880701, 61.335640002],
  [-71.68592, 61.335430102],
  [-71.6863199, 61.333890102],
  [-71.68312, 61.333950002],
  [-71.6825499, 61.333449902],
  [-71.6826, 61.333960002],
  [-71.6815299, 61.333980102],
  [-71.6801801, 61.330679902],
  [-71.6823299, 61.330899902],
  [-71.68226, 61.330129902],
  [-71.68067, 61.330160002],
  [-71.6800701, 61.329410002],
  [-71.68113, 61.329390002],
  [-71.6810701, 61.328620002],
  [-71.67947, 61.328650102],
  [-71.6783199, 61.327650102],
  [-71.6761999, 61.327689902],
  [-71.67615, 61.327180002],
  [-71.67356, 61.327999902],
  [-71.67214, 61.326999902],
  [-71.67236, 61.326490002],
  [-71.66656, 61.327109902],
  [-71.66737, 61.330420102],
  [-71.6662901, 61.330190002],
  [-71.66635, 61.330960002],
  [-71.6652901, 61.330980102],
  [-71.6648599, 61.332260002],
  [-71.6627599, 61.332559902],
  [-71.6637599, 61.331770102],
  [-71.66269, 61.331799902],
  [-71.6626299, 61.331030002],
  [-71.66156, 61.331050002],
  [-71.6609699, 61.330290002],
  [-71.6535199, 61.330440102],
  [-71.65343, 61.329420102],
  [-71.6513099, 61.329459902],
  [-71.64958, 61.327960002],
  [-71.6409599, 61.326840002],
  [-71.64088, 61.325820002],
  [-71.64344, 61.324620002],
  [-71.6481799, 61.324009902],
  [-71.6498401, 61.324750102],
  [-71.6541, 61.324669902],
  [-71.65632, 61.325779902],
  [-71.6654001, 61.325980102],
  [-71.6650501, 61.325090102],
  [-71.66627, 61.323789902],
  [-71.66523, 61.324070002],
  [-71.6629501, 61.322320102],
  [-71.66134, 61.322090102],
  [-71.6619601, 61.320030002],
  [-71.6616201, 61.319270002],
  [-71.6648599, 61.319720002],
  [-71.66482, 61.319210102],
  [-71.6733099, 61.318789902],
  [-71.67326, 61.318280002],
  [-71.68002, 61.319420102],
  [-71.6800601, 61.319930002],
  [-71.68225, 61.320660102],
  [-71.68205, 61.321430102],
  [-71.6834101, 61.321660102],
  [-71.68317, 61.322180002],
  [-71.6864201, 61.322620002],
  [-71.6867501, 61.323390002],
  [-71.6880999, 61.323610002],
  [-71.68735, 61.324150002],
  [-71.6890999, 61.325899902],
  [-71.68836, 61.326679902],
  [-71.69058, 61.327660102],
  [-71.6879601, 61.328229902],
  [-71.69284, 61.329150002],
  [-71.69349, 61.330540102],
  [-71.69612, 61.330119902],
  [-71.69649, 61.328310102],
  [-71.70168, 61.326669902],
  [-71.70172, 61.327180002],
  [-71.7027501, 61.326779902],
  [-71.7092299, 61.327799902],
  [-71.7081901, 61.328080002],
  [-71.70935, 61.329080002],
  [-71.7135599, 61.328480002],
  [-71.7123399, 61.326720002],
  [-71.71338, 61.326440102],
  [-71.7100801, 61.325229902],
  [-71.71274, 61.325040002],
  [-71.7138701, 61.325920002],
  [-71.71651, 61.325750102],
  [-71.72193, 61.326779902],
  [-71.7223301, 61.328310102],
  [-71.72185, 61.328830002],
  [-71.7269801, 61.329620002],
  [-71.72728, 61.330000102],
  [-71.7265401, 61.330779902],
  [-71.7291299, 61.329840002],
  [-71.73126, 61.329920002],
  [-71.7306899, 61.329420102],
  [-71.74029, 61.329610002],
  [-71.7407701, 61.328960002],
  [-71.7421201, 61.329190002],
  [-71.74195, 61.330220102],
  [-71.7445799, 61.329909902],
  [-71.74517, 61.330540102],
  [-71.74671, 61.329870102],
  [-71.7440299, 61.329660102],
  [-71.74363, 61.328140002],
  [-71.74219, 61.326889902],
  [-71.74696, 61.326530102],
  [-71.74562, 61.325620002],
  [-71.7436899, 61.325830002],
  [-71.7435799, 61.324550102],
  [-71.74205, 61.325349902],
  [-71.7385501, 61.321840002],
  [-71.7364501, 61.322140002],
  [-71.73585, 61.321370002],
  [-71.73106, 61.321480002],
  [-71.7304401, 61.320470002],
  [-71.72938, 61.320490002],
  [-71.7296, 61.319970102],
  [-71.72871, 61.318960002],
  [-71.72818, 61.318970102],
  [-71.72827, 61.319999902],
  [-71.7224201, 61.320109902],
  [-71.7235, 61.320349902],
  [-71.7235699, 61.321119902],
  [-71.7204301, 61.321690002],
  [-71.71814, 61.319940002],
  [-71.7154599, 61.319740002],
  [-71.7154201, 61.319229902],
  [-71.7064499, 61.320180002],
  [-71.7070901, 61.321449902],
  [-71.7038799, 61.321250002],
  [-71.70718, 61.322470002],
  [-71.70559, 61.322750102],
  [-71.70182, 61.322060002],
  [-71.70284, 61.321530102],
  [-71.7017699, 61.321550102],
  [-71.70173, 61.321040002],
  [-71.6996401, 61.321590002],
  [-71.70128, 61.322070002],
  [-71.69858, 61.321610002],
  [-71.6986301, 61.322129902],
  [-71.6948799, 61.321940002],
  [-71.69384, 61.319150002],
  [-71.7042001, 61.318810002],
  [-71.7046799, 61.318180002],
  [-71.6997799, 61.316980102],
  [-71.69916, 61.315970102],
  [-71.6980999, 61.315980102],
  [-71.6985599, 61.315210102],
  [-71.69691, 61.314610002],
  [-71.69105, 61.314600002],
  [-71.69039, 61.313070002],
  [-71.6945599, 61.311970102],
  [-71.69503, 61.311320102],
  [-71.6988, 61.311750102],
  [-71.6994101, 61.312630002],
  [-71.7035499, 61.311280002],
  [-71.6960901, 61.311170002],
  [-71.6975599, 61.309720002],
  [-71.7066401, 61.310060002],
  [-71.7068701, 61.309669902],
  [-71.7045499, 61.307420102],
  [-71.7052101, 61.305870102],
  [-71.7063001, 61.306099902],
  [-71.70625, 61.305590002],
  [-71.7104599, 61.304989902],
  [-71.7103101, 61.303200102],
  [-71.70871, 61.303240002],
  [-71.70913, 61.301950002],
  [-71.71229, 61.301630102],
  [-71.7121901, 61.300350002],
  [-71.71537, 61.300160002],
  [-71.71817, 61.298690002],
  [-71.71781, 61.297680002],
  [-71.7188801, 61.297659902],
  [-71.7185699, 61.297150002],
  [-71.7225, 61.296429902],
  [-71.72942, 61.296549902],
  [-71.7305501, 61.297290102],
  [-71.7375699, 61.298429902],
  [-71.7406999, 61.297730002],
  [-71.7408801, 61.296700002],
  [-71.7400199, 61.295950002],
  [-71.7324699, 61.294820002],
  [-71.73185, 61.293810002],
  [-71.7344599, 61.293250002],
  [-71.73439, 61.292480002],
  [-71.7428901, 61.292310102],
  [-71.74283, 61.291539902],
  [-71.74549, 61.291620102],
  [-71.7510199, 61.293940002],
  [-71.7526, 61.293779902],
  [-71.7538099, 61.295420102],
  [-71.7585799, 61.295190102],
  [-71.7623501, 61.295740102],
  [-71.76216, 61.296530102],
  [-71.76461, 61.297240002],
  [-71.76633, 61.298620002],
  [-71.77273, 61.298740102],
  [-71.7743, 61.298449902],
  [-71.7752699, 61.297410102],
  [-71.77717, 61.297759902],
  [-71.7780499, 61.298759902],
  [-71.78295, 61.299940002],
  [-71.78166, 61.297410102],
  [-71.7751301, 61.295740102],
  [-71.77466, 61.293450002],
  [-71.77539, 61.292669902],
  [-71.78063, 61.291800002],
  [-71.78107, 61.290759902],
  [-71.78366, 61.289940002],
  [-71.78556, 61.287340002],
  [-71.7871499, 61.287309902],
  [-71.7881, 61.286010002],
  [-71.7891599, 61.285999902],
  [-71.7895999, 61.284960102],
  [-71.7978299, 61.281710002],
  [-71.79776, 61.280950102],
  [-71.7988201, 61.280930002],
  [-71.7995099, 61.276810002],
  [-71.7979201, 61.276850102],
  [-71.7971401, 61.274060102],
  [-71.7998001, 61.273989902],
  [-71.7997299, 61.273230002],
  [-71.8023799, 61.273170102],
  [-71.80234, 61.272660002],
  [-71.80075, 61.272690002],
  [-71.80065, 61.271670002],
  [-71.79957, 61.271440002],
  [-71.7997101, 61.272969902],
  [-71.79601, 61.273300102],
  [-71.79507, 61.274600002],
  [-71.7934999, 61.274890002],
  [-71.7928101, 61.276180102],
  [-71.79365, 61.276680002],
  [-71.7883701, 61.277040002],
  [-71.78844, 61.277810002],
  [-71.7806099, 61.279510102],
  [-71.7807199, 61.280790002],
  [-71.7765601, 61.281900002],
  [-71.7721499, 61.286080102],
  [-71.77108, 61.286099902],
  [-71.77175, 61.287630102],
  [-71.76965, 61.287920002],
  [-71.76974, 61.288950002],
  [-71.7686701, 61.288970102],
  [-71.7666801, 61.290549902],
  [-71.76199, 61.291680002],
  [-71.76194, 61.291150002],
  [-71.7597999, 61.290950002],
  [-71.7592, 61.290190102],
  [-71.75706, 61.289970102],
  [-71.7571, 61.290480002],
  [-71.75275, 61.289420102],
  [-71.7490199, 61.289370002],
  [-71.74661, 61.286089902],
  [-71.74607, 61.286099902],
  [-71.7461399, 61.286860102],
  [-71.7365601, 61.286800002],
  [-71.73651, 61.286290102],
  [-71.7354401, 61.286309902],
  [-71.73539, 61.285549902],
  [-71.73381, 61.285840102],
  [-71.73371, 61.284560002],
  [-71.73153, 61.284089902],
  [-71.7345699, 61.282360002],
  [-71.7382399, 61.281649902],
  [-71.73808, 61.279859902],
  [-71.73383, 61.279950102],
  [-71.7338801, 61.280470002],
  [-71.7317999, 61.281010002],
  [-71.7318701, 61.281780002],
  [-71.72719, 61.283020002],
  [-71.71915, 61.282290102],
  [-71.7191001, 61.281780002],
  [-71.71369, 61.280730102],
  [-71.70781, 61.280470002],
  [-71.7077699, 61.279960102],
  [-71.6976699, 61.280030002],
  [-71.69601, 61.279290102],
  [-71.69014, 61.279030002],
  [-71.6884299, 61.277780002],
  [-71.67459, 61.277539902],
  [-71.6731901, 61.276800002],
  [-71.67282, 61.275529902],
  [-71.6738799, 61.275500102],
  [-71.6731999, 61.273730102],
  [-71.6683099, 61.272550002],
  [-71.66826, 61.272040002],
  [-71.66722, 61.272299902],
  [-71.6671801, 61.271800002],
  [-71.6645299, 61.271850102],
  [-71.6630601, 61.273419902],
  [-71.65989, 61.273610102],
  [-71.65504, 61.272810002],
  [-71.65457, 61.273580002],
  [-71.65189, 61.273380002],
  [-71.65178, 61.272099902],
  [-71.65389, 61.271810002],
  [-71.65358, 61.271299902],
  [-71.6548, 61.270000002],
  [-71.6595299, 61.269400102],
  [-71.6583801, 61.268390102],
  [-71.6584299, 61.268900002],
  [-71.6510601, 61.269810002],
  [-71.65112, 61.270580002],
  [-71.6485101, 61.271150002],
  [-71.6490899, 61.271639902],
  [-71.64378, 61.271749902],
  [-71.6433099, 61.272520102],
  [-71.63267, 61.272480002],
  [-71.62923, 61.269470002],
  [-71.6270599, 61.269000002],
  [-71.62702, 61.268490002],
  [-71.6232799, 61.268309902],
  [-71.62376, 61.267780002],
  [-71.61732, 61.266880002],
  [-71.61664, 61.265110002],
  [-71.61557, 61.265130002],
  [-71.61498, 61.264370002],
  [-71.60965, 61.264220002],
  [-71.6090301, 61.263199902],
  [-71.60742, 61.262979902],
  [-71.6073799, 61.262470002],
  [-71.59873, 61.260590002],
  [-71.5980599, 61.259050102],
  [-71.597, 61.259079902],
  [-71.5969299, 61.258320002],
  [-71.59532, 61.258089902],
  [-71.5952799, 61.257580002],
  [-71.5931599, 61.257620102],
  [-71.5920101, 61.256620102],
  [-71.5882, 61.255540002],
  [-71.58443, 61.254969902],
  [-71.5844799, 61.255480002],
  [-71.5818199, 61.255529902],
  [-71.5817801, 61.255020002],
  [-71.57855, 61.254570002],
  [-71.57841, 61.252780002],
  [-71.5800201, 61.253010002],
  [-71.5804, 61.251210002],
  [-71.57332, 61.249040102],
  [-71.5729101, 61.247250002],
  [-71.5706801, 61.246020002],
  [-71.57284, 61.243160102],
  [-71.5741399, 61.242880002],
  [-71.5770101, 61.238980002],
  [-71.58409, 61.237830102],
  [-71.5835999, 61.238350002],
  [-71.59107, 61.238720102],
  [-71.5910301, 61.238210002],
  [-71.5931599, 61.238289902],
  [-71.59445, 61.237880002],
  [-71.5941401, 61.237380102],
  [-71.59573, 61.237220002],
  [-71.5979499, 61.235260102],
  [-71.59763, 61.234490102],
  [-71.59922, 61.234460002],
  [-71.60007, 61.231890002],
  [-71.60212, 61.231080002],
  [-71.60151, 61.230069902],
  [-71.6025701, 61.230050102],
  [-71.60277, 61.229279902],
  [-71.60509, 61.228460002],
  [-71.60653, 61.226519902],
  [-71.6075801, 61.226370102],
  [-71.6086901, 61.223790002],
  [-71.61324, 61.217810102],
  [-71.61454, 61.217530002],
  [-71.61665, 61.214159902],
  [-71.6177101, 61.214140102],
  [-71.6192899, 61.210780002],
  [-71.6176199, 61.209790002],
  [-71.6172, 61.208010002],
  [-71.61501, 61.207150102],
  [-71.6159301, 61.205480102],
  [-71.6191, 61.205410002],
  [-71.6190699, 61.204900002],
  [-71.62189, 61.203819902],
  [-71.62197, 61.201510002],
  [-71.6230301, 61.201489902],
  [-71.62309, 61.202259902],
  [-71.62387, 61.201990002],
  [-71.62319, 61.200210002],
  [-71.6244901, 61.199929902],
  [-71.6258, 61.196580102],
  [-71.62335, 61.195850002],
  [-71.6234701, 61.194060002],
  [-71.62241, 61.194080002],
  [-71.62155, 61.193330002],
  [-71.62175, 61.192560002],
  [-71.6206901, 61.192580102],
  [-71.6183999, 61.187249902],
  [-71.6197001, 61.186970002],
  [-71.61908, 61.185950002],
  [-71.6201401, 61.185929902],
  [-71.6201, 61.185420002],
  [-71.6248, 61.184560102],
  [-71.62476, 61.184049902],
  [-71.62902, 61.184220002],
  [-71.62876, 61.183290002],
  [-71.62787, 61.183220002],
  [-71.62791, 61.183730002],
  [-71.6204901, 61.183750002],
  [-71.61843, 61.184430002],
  [-71.6170301, 61.186760002],
  [-71.6143799, 61.186809902],
  [-71.61341, 61.187860002],
  [-71.6118399, 61.188149902],
  [-71.61199, 61.189929902],
  [-71.6109301, 61.189950002],
  [-71.6126901, 61.191960002],
  [-71.61375, 61.191939902],
  [-71.6139399, 61.194240102],
  [-71.61288, 61.194269902],
  [-71.61196, 61.199149902],
  [-71.6130599, 61.199640002],
  [-71.6115999, 61.201200002],
  [-71.61152, 61.203510002],
  [-71.60841, 61.204340002],
  [-71.6080499, 61.206389902],
  [-71.6061599, 61.207110002],
  [-71.60607, 61.208220002],
  [-71.6034, 61.208020102],
  [-71.60345, 61.208520002],
  [-71.60188, 61.208810102],
  [-71.6019399, 61.209580102],
  [-71.60088, 61.209599902],
  [-71.6014701, 61.210360102],
  [-71.59776, 61.210430002],
  [-71.5968101, 61.211740002],
  [-71.5903799, 61.210949902],
  [-71.5893501, 61.211360102],
  [-71.58931, 61.210850002],
  [-71.5845801, 61.211450002],
  [-71.5851499, 61.211949902],
  [-71.5725799, 61.213980002],
  [-71.57163, 61.215530002],
  [-71.5690101, 61.215839902],
  [-71.56973, 61.218130002],
  [-71.57498, 61.217520002],
  [-71.57396, 61.218049902],
  [-71.58417, 61.219389902],
  [-71.5842301, 61.220159902],
  [-71.58317, 61.220169902],
  [-71.58321, 61.220690102],
  [-71.5758099, 61.221090002],
  [-71.57588, 61.221849902],
  [-71.5732, 61.221650002],
  [-71.5723501, 61.220900002],
  [-71.5725701, 61.220380102],
  [-71.56774, 61.219700102],
  [-71.56761, 61.218169902],
  [-71.56606, 61.218710102],
  [-71.56549, 61.218210002],
  [-71.56553, 61.218729902],
  [-71.5644699, 61.218740002],
  [-71.5640101, 61.219520002],
  [-71.5582599, 61.220650002],
  [-71.55963, 61.221140102],
  [-71.5594401, 61.222169902],
  [-71.5573201, 61.222210002],
  [-71.5573599, 61.222719902],
  [-71.5563201, 61.222990002],
  [-71.5562599, 61.222230002],
  [-71.5541201, 61.222010002],
  [-71.55365, 61.222790002],
  [-71.5494501, 61.223389902],
  [-71.55006, 61.221059902],
  [-71.5492299, 61.220570002],
  [-71.55029, 61.220550002],
  [-71.5485699, 61.219040102],
  [-71.54172, 61.219680002],
  [-71.5407801, 61.217910102],
  [-71.5420901, 61.217630002],
  [-71.54227, 61.216600102],
  [-71.54117, 61.216110002],
  [-71.54053, 61.211509902],
  [-71.5394699, 61.211530002],
  [-71.53617, 61.206729902],
  [-71.53741, 61.205680102],
  [-71.5390001, 61.205650002],
  [-71.53808, 61.204140102],
  [-71.53851, 61.202860002],
  [-71.5413599, 61.202030102],
  [-71.54229, 61.200470102],
  [-71.5433499, 61.200450002],
  [-71.54352, 61.199169902],
  [-71.5461001, 61.198350102],
  [-71.5457601, 61.197340002],
  [-71.54684, 61.197570102],
  [-71.54671, 61.196039902],
  [-71.5456501, 61.196060002],
  [-71.54584, 61.195030102],
  [-71.5471399, 61.194750002],
  [-71.5470099, 61.193220002],
  [-71.5496, 61.192400002],
  [-71.5479901, 61.192170002],
  [-71.5480299, 61.192680102],
  [-71.5464599, 61.192970002],
  [-71.54685, 61.191170002],
  [-71.5478999, 61.191149902],
  [-71.54562, 61.189149902],
  [-71.5397601, 61.188740002],
  [-71.5384499, 61.185699902],
  [-71.53739, 61.185720002],
  [-71.53715, 61.182650002],
  [-71.54049, 61.181310002],
  [-71.54014, 61.180290002],
  [-71.54439, 61.180469902],
  [-71.54452, 61.178670102],
  [-71.54626, 61.177359902],
  [-71.5473399, 61.177589902],
  [-71.5493301, 61.175890102],
  [-71.5518901, 61.174819902],
  [-71.55349, 61.174919902],
  [-71.5534501, 61.174410002],
  [-71.5549901, 61.173870002],
  [-71.55394, 61.173890102],
  [-71.5538901, 61.173369902],
  [-71.55649, 61.172809902],
  [-71.5485, 61.172200002],
  [-71.54884, 61.169890102],
  [-71.5498999, 61.169870002],
  [-71.5493301, 61.169369902],
  [-71.5541101, 61.169530002],
  [-71.55405, 61.168760002],
  [-71.5589199, 61.169950002],
  [-71.55831, 61.168940002],
  [-71.56995, 61.168720002],
  [-71.5699099, 61.168210002],
  [-71.5761399, 61.166680102],
  [-71.5786701, 61.165220102],
  [-71.57707, 61.165000102],
  [-71.5781201, 61.164980002],
  [-71.5778199, 61.164469902],
  [-71.5795899, 61.163420002],
  [-71.5759199, 61.164000102],
  [-71.5753301, 61.163239902],
  [-71.5726701, 61.163029902],
  [-71.5725999, 61.162270002],
  [-71.56996, 61.162320002],
  [-71.57042, 61.161540002],
  [-71.5667801, 61.162380002],
  [-71.56619, 61.161620002],
  [-71.56463, 61.161909902],
  [-71.5640101, 61.160900102],
  [-71.5586601, 61.160230102],
  [-71.55862, 61.159720002],
  [-71.5564599, 61.159249902],
  [-71.5550001, 61.160809902],
  [-71.5528999, 61.161110102],
  [-71.5525501, 61.156760002],
  [-71.5520199, 61.156770102],
  [-71.55008, 61.159110102],
  [-71.54114, 61.159789902],
  [-71.54039, 61.160320102],
  [-71.54073, 61.161330102],
  [-71.5381299, 61.161900102],
  [-71.5380901, 61.161380002],
  [-71.5360099, 61.161930002],
  [-71.53473, 61.159139902],
  [-71.5342001, 61.159150002],
  [-71.5333499, 61.161730002],
  [-71.5275501, 61.162090002],
  [-71.52759, 61.162600002],
  [-71.5234499, 61.163710002],
  [-71.52339, 61.163190002],
  [-71.5186799, 61.163919902],
  [-71.51491, 61.163110102],
  [-71.51495, 61.163610002],
  [-71.5133399, 61.163380002],
  [-71.5128701, 61.164160002],
  [-71.51025, 61.164459902],
  [-71.5087799, 61.166029902],
  [-71.5008799, 61.166680102],
  [-71.4994001, 61.167990002],
  [-71.49472, 61.169100002],
  [-71.49304, 61.167980002],
  [-71.4871801, 61.167699902],
  [-71.4870601, 61.169500002],
  [-71.49082, 61.173530002],
  [-71.4889899, 61.173819902],
  [-71.4893199, 61.174579902],
  [-71.48771, 61.174350102],
  [-71.4877499, 61.174860002],
  [-71.4846101, 61.175430002],
  [-71.4845499, 61.174670102],
  [-71.48349, 61.174680102],
  [-71.4835299, 61.175200002],
  [-71.47936, 61.176039902],
  [-71.48001, 61.177560102],
  [-71.4816599, 61.178300002],
  [-71.48268, 61.177640002],
  [-71.4858401, 61.177579902],
  [-71.49338, 61.178980002],
  [-71.49493, 61.178570102],
  [-71.49436, 61.178070002],
  [-71.50551, 61.178249902],
  [-71.5085301, 61.182929902],
  [-71.5145301, 61.184990002],
  [-71.5166599, 61.185080002],
  [-71.5209801, 61.189350102],
  [-71.5194301, 61.196550002],
  [-71.5205499, 61.197300002],
  [-71.5182101, 61.201180002],
  [-71.5174, 61.204269902],
  [-71.51824, 61.204880002],
  [-71.5171099, 61.207340002],
  [-71.5190099, 61.207819902],
  [-71.5222101, 61.211340002],
  [-71.52329, 61.214910102],
  [-71.5222299, 61.214930102],
  [-71.5224499, 61.217740002],
  [-71.5193101, 61.218310002],
  [-71.51936, 61.218820102],
  [-71.5183001, 61.218839902],
  [-71.5188899, 61.219590102],
  [-71.5157501, 61.220169902],
  [-71.5153001, 61.221190002],
  [-71.51424, 61.221220002],
  [-71.5143001, 61.221990002],
  [-71.51324, 61.222010002],
  [-71.51328, 61.222520002],
  [-71.50635, 61.222130002],
  [-71.50647, 61.220340002],
  [-71.50971, 61.217719902],
  [-71.5107699, 61.217710102],
  [-71.5097501, 61.214900002],
  [-71.5107699, 61.214370102],
  [-71.51116, 61.212570002],
  [-71.51061, 61.212330002],
  [-71.5107501, 61.210790002],
  [-71.5059801, 61.210880002],
  [-71.50557, 61.212420002],
  [-71.5045201, 61.212440002],
  [-71.5040701, 61.213470102],
  [-71.5029899, 61.213240102],
  [-71.5031901, 61.215790002],
  [-71.5021099, 61.215560002],
  [-71.5021901, 61.216580102],
  [-71.50113, 61.216600102],
  [-71.50068, 61.217630002],
  [-71.4954201, 61.218240002],
  [-71.49548, 61.219020002],
  [-71.49391, 61.219289902],
  [-71.49448, 61.219790002],
  [-71.49291, 61.220080002],
  [-71.4929899, 61.221100002],
  [-71.49191, 61.220860002],
  [-71.4899601, 61.222939902],
  [-71.4772999, 61.224080002],
  [-71.46344, 61.223430002],
  [-71.46199, 61.225250102],
  [-71.4609301, 61.225279902],
  [-71.4615001, 61.225770002],
  [-71.44664, 61.225790002],
  [-71.44713, 61.225260102],
  [-71.4331601, 61.223210002],
  [-71.4335801, 61.221670002],
  [-71.4367001, 61.220839902],
  [-71.4381899, 61.219540002],
  [-71.4366001, 61.219570002],
  [-71.4362, 61.217780002],
  [-71.4372, 61.217000002],
  [-71.43687, 61.216230002],
  [-71.439, 61.216200002],
  [-71.4389499, 61.215680002],
  [-71.4373801, 61.215970002],
  [-71.43655, 61.215470102],
  [-71.4367299, 61.214440002],
  [-71.4329399, 61.213489902],
  [-71.43243, 61.210430002],
  [-71.4360301, 61.208829902],
  [-71.43624, 61.208049902],
  [-71.4372999, 61.208030102],
  [-71.43612, 61.206520002],
  [-71.43743, 61.206240102],
  [-71.43856, 61.203660002],
  [-71.4396099, 61.203640002],
  [-71.44053, 61.201839902],
  [-71.4415901, 61.201810102],
  [-71.4415099, 61.200790102],
  [-71.4428201, 61.200510002],
  [-71.44247, 61.199489902],
  [-71.4392, 61.198269902],
  [-71.44122, 61.196960002],
  [-71.44335, 61.197049902],
  [-71.44477, 61.194850002],
  [-71.4431601, 61.194620002],
  [-71.4430599, 61.193340102],
  [-71.442, 61.193360102],
  [-71.4424901, 61.192709902],
  [-71.4440599, 61.192550002],
  [-71.44447, 61.191010102],
  [-71.44235, 61.191049902],
  [-71.4417201, 61.189780002],
  [-71.44013, 61.189809902],
  [-71.44056, 61.188520002],
  [-71.4423801, 61.187980002],
  [-71.4432601, 61.185660002],
  [-71.44455, 61.185249902],
  [-71.4482701, 61.185310002],
  [-71.4482999, 61.185809902],
  [-71.44992, 61.186049902],
  [-71.45014, 61.185530002],
  [-71.4540799, 61.184950002],
  [-71.454, 61.183929902],
  [-71.4502899, 61.183990002],
  [-71.4501899, 61.182720002],
  [-71.4475901, 61.183269902],
  [-71.44699, 61.182520002],
  [-71.4459301, 61.182540002],
  [-71.44531, 61.181269902],
  [-71.44369, 61.180910102],
  [-71.4410699, 61.181340102],
  [-71.4411501, 61.182369902],
  [-71.44009, 61.182390002],
  [-71.44013, 61.182910102],
  [-71.4369301, 61.182699902],
  [-71.43689, 61.182190002],
  [-71.43313, 61.181489902],
  [-71.4330301, 61.180210002],
  [-71.4372999, 61.180640002],
  [-71.4372601, 61.180130102],
  [-71.43832, 61.180110102],
  [-71.4377299, 61.179359902],
  [-71.43879, 61.179340102],
  [-71.43875, 61.178819902],
  [-71.4439699, 61.177709902],
  [-71.4425199, 61.172740002],
  [-71.4428, 61.171750002],
  [-71.44177, 61.169809902],
  [-71.43853, 61.169100002],
  [-71.43732, 61.167080002],
  [-71.4357101, 61.166850002],
  [-71.43508, 61.165570102],
  [-71.43402, 61.165600002],
  [-71.4333999, 61.164330102],
  [-71.41587, 61.163880102],
  [-71.4159101, 61.164390002],
  [-71.41432, 61.164420002],
  [-71.4156199, 61.167460102],
  [-71.41456, 61.167479902],
  [-71.41656, 61.172819902],
  [-71.41289, 61.173400002],
  [-71.41301, 61.174940002],
  [-71.40977, 61.174230102],
  [-71.4088101, 61.175520002],
  [-71.41042, 61.175750002],
  [-71.4066901, 61.175560102],
  [-71.40675, 61.176330102],
  [-71.3977099, 61.175980002],
  [-71.39766, 61.175210002],
  [-71.3965999, 61.175230102],
  [-71.3962401, 61.173950002],
  [-71.39428, 61.172720002],
  [-71.39663, 61.172149902],
  [-71.39617, 61.169600002],
  [-71.39511, 61.169620002],
  [-71.39388, 61.167340102],
  [-71.39518, 61.167060002],
  [-71.39539, 61.166290002],
  [-71.3938001, 61.166320002],
  [-71.39372, 61.165290002],
  [-71.39319, 61.165300002],
  [-71.3922699, 61.167110102],
  [-71.39121, 61.167129902],
  [-71.3912499, 61.167640002],
  [-71.3891201, 61.167420002],
  [-71.3896801, 61.167929902],
  [-71.38443, 61.168530002],
  [-71.38329, 61.167530002],
  [-71.3822301, 61.167550102],
  [-71.38273, 61.167019902],
  [-71.3816701, 61.167039902],
  [-71.38021, 61.165290002],
  [-71.3819299, 61.163450102],
  [-71.38298, 61.163440102],
  [-71.38475, 61.158799902],
  [-71.3858099, 61.158780102],
  [-71.3862401, 61.157490002],
  [-71.38729, 61.157469902],
  [-71.3872599, 61.156960002],
  [-71.3919299, 61.155600002],
  [-71.3963401, 61.150909902],
  [-71.39742, 61.151150002],
  [-71.3989399, 61.150359902],
  [-71.3994, 61.149320102],
  [-71.41076, 61.145540102],
  [-71.41229, 61.144740002],
  [-71.41374, 61.142920002],
  [-71.42211, 61.141620002],
  [-71.4241799, 61.140950002],
  [-71.42512, 61.139390002],
  [-71.42724, 61.139349902],
  [-71.4277199, 61.138710002],
  [-71.4292899, 61.138550102],
  [-71.4297399, 61.137520002],
  [-71.4308, 61.137500002],
  [-71.43097, 61.132889902],
  [-71.4320499, 61.133119902],
  [-71.43197, 61.132100102],
  [-71.4309201, 61.132119902],
  [-71.4308, 61.130590002],
  [-71.42655, 61.130410002],
  [-71.4259, 61.128889902],
  [-71.42485, 61.128899902],
  [-71.42512, 61.125569902],
  [-71.4267001, 61.125410102],
  [-71.42932, 61.121650102],
  [-71.42875, 61.121150002],
  [-71.43054, 61.120350002],
  [-71.42841, 61.120130002],
  [-71.42643, 61.121960002],
  [-71.42486, 61.122250002],
  [-71.42454, 61.121480002],
  [-71.42633, 61.120679902],
  [-71.42621, 61.119150002],
  [-71.4251501, 61.119170002],
  [-71.424, 61.117910002],
  [-71.4202601, 61.117460002],
  [-71.42032, 61.118229902],
  [-71.4213899, 61.118339902],
  [-71.42308, 61.119720002],
  [-71.4215099, 61.120009902],
  [-71.42199, 61.119229902],
  [-71.40999, 61.121490002],
  [-71.41041, 61.123530102],
  [-71.41622, 61.130329902],
  [-71.41689, 61.132119902],
  [-71.4191, 61.133360002],
  [-71.4195999, 61.136420102],
  [-71.41801, 61.136459902],
  [-71.41809, 61.137480002],
  [-71.4154999, 61.138290002],
  [-71.41556, 61.139050002],
  [-71.40985, 61.140559902],
  [-71.4024, 61.140050002],
  [-71.4009101, 61.141359902],
  [-71.3978199, 61.142440102],
  [-71.3971201, 61.143740002],
  [-71.39799, 61.144740002],
  [-71.3969299, 61.144760102],
  [-71.39597, 61.146070002],
  [-71.39439, 61.146080002],
  [-71.39444, 61.146850002],
  [-71.39022, 61.147050002],
  [-71.38662, 61.148400002],
  [-71.3829001, 61.148330102],
  [-71.38232, 61.147579902],
  [-71.3774401, 61.146129902],
  [-71.3770399, 61.144339902],
  [-71.37872, 61.142009902],
  [-71.3813599, 61.141830002],
  [-71.38506, 61.138310102],
  [-71.38551, 61.133700002],
  [-71.3865601, 61.133679902],
  [-71.3880101, 61.131730002],
  [-71.3932401, 61.130999902],
  [-71.3947099, 61.129439902],
  [-71.39577, 61.129420102],
  [-71.3947901, 61.126880102],
  [-71.39573, 61.125329902],
  [-71.39464, 61.121510002],
  [-71.3829001, 61.120180002],
  [-71.38043, 61.118940002],
  [-71.37952, 61.117420102],
  [-71.3784601, 61.117439902],
  [-71.37841, 61.116930002],
  [-71.37362, 61.116370002],
  [-71.37131, 61.117310102],
  [-71.3719901, 61.119339902],
  [-71.3715, 61.119870102],
  [-71.37453, 61.124930002],
  [-71.3744799, 61.127999902],
  [-71.3729099, 61.128030002],
  [-71.3710001, 61.130880102],
  [-71.36994, 61.130899902],
  [-71.3704, 61.133449902],
  [-71.36929, 61.136290002],
  [-71.3682301, 61.136300002],
  [-71.3664699, 61.141200102],
  [-71.3638901, 61.142009902],
  [-71.3601399, 61.144890102],
  [-71.35995, 61.145909902],
  [-71.3588901, 61.145940002],
  [-71.3584401, 61.146970002],
  [-71.35583, 61.147530002],
  [-71.35551, 61.150349902],
  [-71.3544499, 61.150370002],
  [-71.3541101, 61.152679902],
  [-71.34894, 61.154560102],
  [-71.34718, 61.155870002],
  [-71.34749, 61.156380002],
  [-71.34437, 61.157200002],
  [-71.34441, 61.157720002],
  [-71.3406601, 61.157260002],
  [-71.33929, 61.156520002],
  [-71.3394499, 61.155230102],
  [-71.3346699, 61.155060002],
  [-71.33474, 61.155830002],
  [-71.33263, 61.156120102],
  [-71.33269, 61.156890102],
  [-71.33163, 61.156909902],
  [-71.33169, 61.157670102],
  [-71.3269999, 61.158780102],
  [-71.3270999, 61.160060002],
  [-71.3255499, 61.160600002],
  [-71.32496, 61.159840002],
  [-71.32339, 61.160120102],
  [-71.3225401, 61.159369902],
  [-71.32194, 61.154770102],
  [-71.31769, 61.154589902],
  [-71.3176499, 61.154080002],
  [-71.31549, 61.153469902],
  [-71.31175, 61.153250002],
  [-71.302, 61.157750002],
  [-71.2965, 61.157750002],
  [-71.295, 61.159250002],
  [-71.29175, 61.159250002],
  [-71.29, 61.157000002],
  [-71.28775, 61.156500002],
  [-71.2885, 61.154500002],
  [-71.2865, 61.153000002],
  [-71.2855, 61.151500002],
  [-71.28625, 61.151000002],
  [-71.28475, 61.150000002],
  [-71.2845, 61.148750002],
  [-71.28325, 61.149000002],
  [-71.2825, 61.147500002],
  [-71.278, 61.146250002],
  [-71.2755, 61.146750002],
  [-71.27675, 61.148000002],
  [-71.27275, 61.149750002],
  [-71.271, 61.151500002],
  [-71.271, 61.152250002],
  [-71.26925, 61.152750002],
  [-71.2695, 61.153250002],
  [-71.26525, 61.154500002],
  [-71.26525, 61.155000002],
  [-71.266, 61.155000002],
  [-71.26525, 61.155250002],
  [-71.2655, 61.156000002],
  [-71.264, 61.156500002],
  [-71.26025, 61.156000002],
  [-71.25925, 61.157250002],
  [-71.26075, 61.157750002],
  [-71.25825, 61.157750002],
  [-71.258, 61.156500002],
  [-71.2565, 61.157250002],
  [-71.25725, 61.156250002],
  [-71.256, 61.155750002],
  [-71.25375, 61.156750002],
  [-71.25525, 61.157500002],
  [-71.25475, 61.158250002],
  [-71.25225, 61.157750002],
  [-71.2455, 61.159000002],
  [-71.246, 61.159750002],
  [-71.24925, 61.159500002],
];

let polygons = [];
boundaries.map((boundary) => 
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;