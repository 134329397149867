import QuebecRegionItem from "../lits-items/QuebecRegionItem";

export default function QuebecRegionsList({
  areas,
  mountains,
  quebecId,
  currentCountryId,
  currentCountryName,
  onAreaClick,
  handleShowDonate,
}) {
  let filteredRegions = areas.filter(
    (region) => region.country === currentCountryName
  );

  return (
    <div className="mt-4">
      <ul className="p-0 region-list">
        {filteredRegions.map((area, index) => (
          <QuebecRegionItem
            key={index}
            area={area}
            mountains={mountains}
            onClickHandler={onAreaClick}
            handleShowDonate={handleShowDonate}
          />
        ))}
      </ul>
    </div>
  );
}
