const boundaries = [
  [-72.5441516, 47.1918032],
  [-72.537129, 47.1835827],
  [-72.5148054, 47.1442479],
  [-72.4589638, 47.1437398],
  [-72.4209816, 47.1165929],
  [-72.4144181, 47.1201167],
  [-72.4081342, 47.1198122],
  [-72.4022565, 47.120109],
  [-72.3947258, 47.1189425],
  [-72.3789501, 47.0966781],
  [-72.4367446, 47.056757],
  [-72.40651, 47.0365993],
  [-72.3583721, 47.0040236],
  [-72.3517652, 47.0000068],
  [-72.1676851, 46.8742531],
  [-72.1979515, 46.8554035],
  [-72.2185453, 46.841985],
  [-72.2273015, 46.8356736],
  [-72.2319741, 46.8386854],
  [-72.2484975, 46.8283951],
  [-72.2725669, 46.8450442],
  [-72.2859088, 46.8369271],
  [-72.2894803, 46.8341073],
  [-72.298298, 46.8289257],
  [-72.3059859, 46.8338969],
  [-72.3342008, 46.8144353],
  [-72.3440949, 46.8217889],
  [-72.3515831, 46.8165591],
  [-72.3527666, 46.8173084],
  [-72.3576179, 46.8139748],
  [-72.3932845, 46.7919503],
  [-72.367215, 46.7738214],
  [-72.3669245, 46.7734138],
  [-72.3579815, 46.7674113],
  [-72.3372881, 46.7521904],
  [-72.303507, 46.728424],
  [-72.2868061, 46.7176558],
  [-72.1479893, 46.6169324],
  [-72.0829904, 46.570168],
  [-72.0814437, 46.5700255],
  [-72.0611802, 46.5715233],
  [-72.0535423, 46.5723017],
  [-72.0438984, 46.5743864],
  [-72.0304331, 46.5788858],
  [-72.0122439, 46.5872739],
  [-72.0047382, 46.591536],
  [-71.9895045, 46.6034354],
  [-71.9812303, 46.6104908],
  [-71.9674161, 46.6194757],
  [-71.9624754, 46.6218212],
  [-71.9494549, 46.6269147],
  [-71.9420417, 46.6294712],
  [-71.9237676, 46.6352666],
  [-71.918023, 46.6380115],
  [-71.9145806, 46.6410875],
  [-71.9075133, 46.6497396],
  [-71.8885947, 46.6695275],
  [-71.885312, 46.6725733],
  [-71.8784655, 46.6760013],
  [-71.8712028, 46.6779347],
  [-71.8618928, 46.6790274],
  [-71.8509389, 46.6784828],
  [-71.8413956, 46.6771007],
  [-71.8386237, 46.6763977],
  [-71.8335622, 46.6747243],
  [-71.8275044, 46.672227],
  [-71.8175081, 46.6669432],
  [-71.7883732, 46.6531155],
  [-71.7792656, 46.6508053],
  [-71.7737605, 46.6499862],
  [-71.7645005, 46.6495055],
  [-71.7489461, 46.6493944],
  [-71.7221878, 46.6483722],
  [-71.704343, 46.6484488],
  [-71.6817588, 46.6511272],
  [-71.6712369, 46.6529463],
  [-71.6443745, 46.6607542],
  [-71.6243498, 46.6728822],
  [-71.6110579, 46.6785143],
  [-71.596614, 46.6814873],
  [-71.5762008, 46.6820506],
  [-71.5673983, 46.6826866],
  [-71.5584533, 46.6855256],
  [-71.5465638, 46.6910196],
  [-71.5337025, 46.6953618],
  [-71.5177339, 46.698327],
  [-71.5038936, 46.701984],
  [-71.4748507, 46.7104525],
  [-71.4669615, 46.7118969],
  [-71.4564162, 46.7131215],
  [-71.4475373, 46.7149144],
  [-71.4393214, 46.7158968],
  [-71.4188205, 46.7170355],
  [-71.4095701, 46.717841],
  [-71.3934584, 46.7202576],
  [-71.3622349, 46.7253408],
  [-71.3553839, 46.7277099],
  [-71.333967, 46.736474],
  [-71.3154873, 46.7420817],
  [-71.2837432, 46.7462335],
  [-71.2670535, 46.7522352],
  [-71.2489389, 46.7614348],
  [-71.2346502, 46.7705784],
  [-71.2274105, 46.7754785],
  [-71.2193236, 46.7824965],
  [-71.2111275, 46.7905075],
  [-71.2022749, 46.7998761],
  [-71.1955009, 46.8089518],
  [-71.1824242, 46.8356359],
  [-71.1789412, 46.838621],
  [-71.1708639, 46.8422475],
  [-71.1567317, 46.8413318],
  [-71.1487681, 46.8397902],
  [-71.1410299, 46.8390414],
  [-71.0800624, 46.8395952],
  [-71.0729167, 46.8403782],
  [-71.0042641, 46.8499338],
  [-71.0021624, 46.8504876],
  [-70.9727471, 46.8617886],
  [-70.9680236, 46.8647322],
  [-70.9352197, 46.8811287],
  [-70.9210538, 46.8878047],
  [-70.9101346, 46.89259],
  [-70.8777974, 46.9063561],
  [-70.8432784, 46.918869],
  [-70.8262413, 46.9254328],
  [-70.8162135, 46.9303142],
  [-70.8096647, 46.9338014],
  [-70.8021326, 46.9398143],
  [-70.7858115, 46.9517147],
  [-70.6980783, 47.0188824],
  [-70.6919955, 47.0303591],
  [-70.6834387, 47.059426],
  [-70.6789452, 47.064771],
  [-70.6758645, 47.067838],
  [-70.6717529, 47.0704716],
  [-70.4791874, 47.2068528],
  [-70.4719398, 47.2190747],
  [-70.4617222, 47.2395903],
  [-70.4505672, 47.2538088],
  [-70.4335719, 47.2666248],
  [-70.4058808, 47.2790069],
  [-70.3418355, 47.3099634],
  [-70.3294897, 47.3148345],
  [-70.3136005, 47.3235216],
  [-70.2514623, 47.3710578],
  [-70.2033428, 47.4109862],
  [-70.1575832, 47.4480011],
  [-70.1462732, 47.4568393],
  [-70.135126, 47.4677467],
  [-70.1267644, 47.4797042],
  [-70.1056492, 47.5191176],
  [-70.0951206, 47.5349605],
  [-70.088999, 47.5420204],
  [-70.0693826, 47.5577119],
  [-70.0332963, 47.581248],
  [-70.0118578, 47.5930034],
  [-69.9867986, 47.6048725],
  [-69.9678368, 47.6157615],
  [-69.9428795, 47.6326133],
  [-69.9216868, 47.6504273],
  [-69.8753834, 47.6978733],
  [-69.8635776, 47.7114849],
  [-69.8518879, 47.7265391],
  [-69.8351762, 47.7470273],
  [-69.7196779, 47.8915981],
  [-69.6868311, 47.9266239],
  [-69.6720256, 47.9410441],
  [-69.639362, 47.9712598],
  [-69.6398576, 47.9707596],
  [-69.5877181, 48.0161853],
  [-69.5413584, 48.053092],
  [-69.6579507, 48.1120049],
  [-69.6680341, 48.1163656],
  [-69.6766734, 48.1195045],
  [-69.6864515, 48.1222824],
  [-69.6943963, 48.1250879],
  [-69.708427, 48.129559],
  [-69.7445951, 48.1372055],
  [-69.7677026, 48.1401638],
  [-69.7859599, 48.1450383],
  [-69.7904778, 48.1450716],
  [-69.7960568, 48.1439834],
  [-69.8015928, 48.1432675],
  [-69.8060159, 48.1432297],
  [-69.823587, 48.1468469],
  [-69.8332858, 48.149925],
  [-69.8435641, 48.1541481],
  [-69.8564816, 48.1625933],
  [-69.8655796, 48.1719529],
  [-69.8687983, 48.177648],
  [-69.8713941, 48.185746],
  [-69.9561512, 48.0567907],
  [-70.036585, 48.0825006],
  [-70.172305, 48.1247583],
  [-70.26062, 47.9973323],
  [-70.5295274, 47.9976401],
  [-70.52945, 47.9953967],
  [-70.7416706, 47.9969034],
  [-70.9142808, 47.9969291],
  [-71.1354769, 47.9999928],
  [-71.4111765, 47.9959711],
  [-71.4116139, 47.9941092],
  [-71.4102913, 47.9899211],
  [-71.4107167, 47.9888213],
  [-71.4105421, 47.9868004],
  [-71.4113596, 47.9844978],
  [-71.4105448, 47.9822564],
  [-71.4103971, 47.9793711],
  [-71.4107944, 47.9772572],
  [-71.4106317, 47.9766398],
  [-71.4101284, 47.9761914],
  [-71.4081984, 47.9761869],
  [-71.4055201, 47.976801],
  [-71.4043545, 47.9768074],
  [-71.402764, 47.975455],
  [-71.4019227, 47.9752296],
  [-71.3968939, 47.9757307],
  [-71.3930627, 47.9754687],
  [-71.3912083, 47.9747785],
  [-71.3900345, 47.974605],
  [-71.3877694, 47.974886],
  [-71.3857653, 47.9744328],
  [-71.3842591, 47.9737353],
  [-71.3828347, 47.9736479],
  [-71.3791512, 47.9742642],
  [-71.3781459, 47.974258],
  [-71.3778009, 47.9740402],
  [-71.3777263, 47.9735828],
  [-71.3787286, 47.9723563],
  [-71.3763876, 47.971568],
  [-71.37513, 47.9704423],
  [-71.374544, 47.9684748],
  [-71.37496, 47.9681963],
  [-71.3755518, 47.968247],
  [-71.3766415, 47.9689263],
  [-71.377566, 47.9689252],
  [-71.3780678, 47.9684739],
  [-71.3784771, 47.9674666],
  [-71.3812417, 47.9649438],
  [-71.3831804, 47.9625281],
  [-71.3833476, 47.9617958],
  [-71.3830107, 47.9614609],
  [-71.3819259, 47.9611775],
  [-71.3805801, 47.9601706],
  [-71.3794979, 47.9599411],
  [-71.3758884, 47.9601058],
  [-71.3748893, 47.9599376],
  [-71.3757282, 47.9569059],
  [-71.3762281, 47.9561217],
  [-71.3783265, 47.9542696],
  [-71.3795876, 47.951688],
  [-71.3806537, 47.9502598],
  [-71.4037246, 47.9500846],
  [-71.9948622, 47.9498808],
  [-72.0339137, 47.9510138],
  [-72.0737278, 47.9502394],
  [-71.8925244, 47.7722032],
  [-72, 47.6972861],
  [-72.0758237, 47.647986],
  [-72.2392377, 47.5360341],
  [-72.2355991, 47.5344206],
  [-72.2304605, 47.53104],
  [-72.2287481, 47.5293524],
  [-72.2279923, 47.5282016],
  [-72.2272072, 47.5249725],
  [-72.226566, 47.5240299],
  [-72.2257194, 47.5234568],
  [-72.223028, 47.5236038],
  [-72.2210478, 47.5214275],
  [-72.2202646, 47.5210227],
  [-72.2200326, 47.5204624],
  [-72.2189733, 47.519662],
  [-72.2182569, 47.518675],
  [-72.2178758, 47.517146],
  [-72.2144555, 47.5150163],
  [-72.214493, 47.514297],
  [-72.2149762, 47.5135423],
  [-72.2148655, 47.5126371],
  [-72.2143519, 47.5116508],
  [-72.2126747, 47.5104671],
  [-72.211545, 47.5088892],
  [-72.209589, 47.5071715],
  [-72.2088937, 47.5060213],
  [-72.2072742, 47.5046627],
  [-72.2069135, 47.5031339],
  [-72.2055929, 47.5009646],
  [-72.2045944, 47.500544],
  [-72.2039097, 47.5005858],
  [-72.2016913, 47.5009205],
  [-72.1991996, 47.5015669],
  [-72.1986458, 47.5019564],
  [-72.1974683, 47.5020645],
  [-72.1959116, 47.5008464],
  [-72.1949669, 47.4993509],
  [-72.1949308, 47.4975919],
  [-72.194213, 47.494957],
  [-72.1959872, 47.492454],
  [-72.1968047, 47.4921349],
  [-72.1985505, 47.4921971],
  [-72.1990404, 47.491895],
  [-72.1993689, 47.4906843],
  [-72.2008588, 47.4891989],
  [-72.2020011, 47.4872733],
  [-72.201747, 47.4870861],
  [-72.2017328, 47.4863123],
  [-72.2009401, 47.4852837],
  [-72.1994727, 47.4848654],
  [-72.1933592, 47.4820908],
  [-72.1897065, 47.4812417],
  [-72.1884441, 47.4813022],
  [-72.1875289, 47.480167],
  [-72.1851931, 47.4792578],
  [-72.1837295, 47.4772107],
  [-72.1828332, 47.4767794],
  [-72.1810124, 47.4770526],
  [-72.1791446, 47.4779309],
  [-72.1782266, 47.4780011],
  [-72.1749664, 47.4777961],
  [-72.1741179, 47.477273],
  [-72.1716951, 47.4765932],
  [-72.1703954, 47.4759783],
  [-72.1668603, 47.4730243],
  [-72.164738, 47.470156],
  [-72.1632945, 47.4694989],
  [-72.1609704, 47.4649613],
  [-72.1609729, 47.4637848],
  [-72.167322, 47.4642625],
  [-72.1707978, 47.4623591],
  [-72.1713004, 47.4618458],
  [-72.1725034, 47.4583644],
  [-72.1725699, 47.4571654],
  [-72.1714836, 47.4543957],
  [-72.1714735, 47.4533574],
  [-72.1694034, 47.4523989],
  [-72.1686031, 47.450342],
  [-72.1710514, 47.448167],
  [-72.1722175, 47.4443333],
  [-72.1721511, 47.4437326],
  [-72.1714626, 47.4429921],
  [-72.1714674, 47.4424442],
  [-72.17367, 47.4399203],
  [-72.1738632, 47.4390862],
  [-72.1697963, 47.4357455],
  [-72.1680006, 47.432558],
  [-72.1673776, 47.4306704],
  [-72.1653082, 47.4297235],
  [-72.1640158, 47.4295815],
  [-72.1635273, 47.4291894],
  [-72.1631205, 47.4284348],
  [-72.1635966, 47.4271714],
  [-72.1633511, 47.4264878],
  [-72.1616932, 47.4243286],
  [-72.1594441, 47.4222605],
  [-72.1584182, 47.4209161],
  [-72.1574749, 47.4205994],
  [-72.1565289, 47.4211016],
  [-72.1556325, 47.4210738],
  [-72.1540255, 47.4204954],
  [-72.1531561, 47.4204737],
  [-72.1476782, 47.4192614],
  [-72.1439183, 47.4174924],
  [-72.1437387, 47.4169019],
  [-72.144098, 47.415966],
  [-72.1425927, 47.4149561],
  [-72.1421106, 47.4118645],
  [-72.1413202, 47.4105458],
  [-72.1415979, 47.4089052],
  [-72.1427997, 47.4074313],
  [-72.1433267, 47.406134],
  [-72.1451961, 47.4035259],
  [-72.1453431, 47.4006206],
  [-72.1459577, 47.3992608],
  [-72.150804, 47.3974548],
  [-72.1533367, 47.3954484],
  [-72.1574596, 47.3935819],
  [-72.1601826, 47.3919064],
  [-72.1605161, 47.391247],
  [-72.1600544, 47.3898169],
  [-72.1602937, 47.3889487],
  [-72.1614499, 47.3872779],
  [-72.1614372, 47.3867107],
  [-72.1620474, 47.3862455],
  [-72.1648635, 47.3851274],
  [-72.1706619, 47.3817611],
  [-72.1706607, 47.3815362],
  [-72.1717527, 47.3806902],
  [-72.1816012, 47.3704221],
  [-72.1839801, 47.3667667],
  [-72.1854047, 47.3660056],
  [-72.1872195, 47.3656583],
  [-72.1875941, 47.3652637],
  [-72.1897585, 47.3643022],
  [-72.1925152, 47.3626263],
  [-72.1966889, 47.362022],
  [-72.2008482, 47.360708],
  [-72.2016963, 47.3600774],
  [-72.2029468, 47.3559501],
  [-72.2044373, 47.3542078],
  [-72.2046816, 47.3534723],
  [-72.2043473, 47.3510517],
  [-72.2038918, 47.3500659],
  [-72.2017453, 47.3483111],
  [-72.201246, 47.3472731],
  [-72.2012196, 47.3461364],
  [-72.2016692, 47.3451436],
  [-72.2041396, 47.3425356],
  [-72.2047617, 47.3415158],
  [-72.2061318, 47.3361728],
  [-72.2055927, 47.3334327],
  [-72.2060858, 47.3313905],
  [-72.2058474, 47.3292999],
  [-72.207944, 47.3252974],
  [-72.2091498, 47.3235055],
  [-72.2114718, 47.3209995],
  [-72.2127542, 47.3192085],
  [-72.2136673, 47.3184689],
  [-72.2152814, 47.3141898],
  [-72.2175835, 47.3128428],
  [-72.219303, 47.3109123],
  [-72.2221398, 47.3091329],
  [-72.2235371, 47.3078045],
  [-72.2244259, 47.3073184],
  [-72.2245462, 47.3064487],
  [-72.225682, 47.3047078],
  [-72.2276317, 47.3018224],
  [-72.2291173, 47.3001892],
  [-72.2300432, 47.2996573],
  [-72.2325356, 47.3001463],
  [-72.2335372, 47.3000305],
  [-72.2349776, 47.2991466],
  [-72.2357944, 47.2982501],
  [-72.2360301, 47.2974624],
  [-72.2357067, 47.2951054],
  [-72.2370379, 47.2927207],
  [-72.2383578, 47.29157],
  [-72.2386223, 47.2909933],
  [-72.2871859, 47.2916949],
  [-72.3206047, 47.303],
  [-72.344035, 47.279625],
  [-72.3584441, 47.2912397],
  [-72.4137183, 47.292588],
  [-72.4150915, 47.3468496],
  [-72.4625595, 47.3714064],
  [-72.462261, 47.3873271],
  [-72.4886004, 47.3694094],
  [-72.4739222, 47.3330707],
  [-72.5066811, 47.3133691],
  [-72.5136721, 47.2693822],
  [-72.5419263, 47.2200522],
  [-72.5441516, 47.1918032],
];

let polygons = [];
boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons