const containerStyle = {
  width: "100%",
  height: "530px",
};

const infoWindow = {
  divStyle: {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    width: "300px",
  },
};

const infobox = {
  style: {
    height: "auto",
  },
  firstDivStyle: {
    backgroundColor: "#fff",
    padding: "0.9rem",
    borderRadius: "8px",
    height: "auto",
  },
  secondDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};

export { infobox, infoWindow, containerStyle };
