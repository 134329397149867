const boundaries1 = [
  [-61.6845, 47.2833],
  [-61.6822, 47.2769],
  [-61.6933, 47.2673],
  [-61.7, 47.2689],
  [-61.7061, 47.2672],
  [-61.7133, 47.2692],
  [-61.7134, 47.2737],
  [-61.7175, 47.2775],
  [-61.7136, 47.2819],
  [-61.705, 47.285],
  [-61.6987, 47.2844],
  [-61.6889, 47.2886],
  [-61.6858, 47.2873],
  [-61.6845, 47.2833],
];
const boundaries2 = [
  [-61.9052, 47.3512],
  [-61.9141, 47.3487],
  [-61.9191, 47.3442],
  [-61.9247, 47.3429],
  [-61.9294, 47.3389],
  [-61.9345, 47.3265],
  [-61.9347, 47.3119],
  [-61.9299, 47.2959],
  [-61.9169, 47.2715],
  [-61.9041, 47.2562],
  [-61.8955, 47.2493],
  [-61.8799, 47.2421],
  [-61.8691, 47.239],
  [-61.8663, 47.241],
  [-61.8391, 47.2371],
  [-61.8316, 47.2396],
  [-61.8269, 47.2379],
  [-61.8269, 47.2352],
  [-61.8294, 47.234],
  [-61.8272, 47.2368],
  [-61.8324, 47.2382],
  [-61.8403, 47.234],
  [-61.8333, 47.2316],
  [-61.8368, 47.2292],
  [-61.8359, 47.226],
  [-61.8317, 47.2287],
  [-61.8252, 47.2282],
  [-61.8208, 47.2305],
  [-61.8041, 47.2446],
  [-61.8022, 47.2507],
  [-61.7897, 47.2621],
  [-61.7877, 47.2671],
  [-61.7785, 47.2707],
  [-61.7757, 47.2697],
  [-61.7935, 47.2548],
  [-61.8088, 47.2326],
  [-61.8236, 47.2232],
  [-61.838, 47.2176],
  [-61.8516, 47.2165],
  [-61.8812, 47.2209],
  [-61.8839, 47.2236],
  [-61.8831, 47.225],
  [-61.8744, 47.2215],
  [-61.8652, 47.2204],
  [-61.8613, 47.2221],
  [-61.8605, 47.2248],
  [-61.8853, 47.2346],
  [-61.8887, 47.2345],
  [-61.8922, 47.239],
  [-61.895, 47.2399],
  [-61.9012, 47.2289],
  [-61.9063, 47.2318],
  [-61.9115, 47.2299],
  [-61.8936, 47.2268],
  [-61.8913, 47.2243],
  [-61.8841, 47.2229],
  [-61.8833, 47.2212],
  [-61.9035, 47.2229],
  [-61.9229, 47.2221],
  [-61.928, 47.2195],
  [-61.9449, 47.2165],
  [-61.9488, 47.2129],
  [-61.9708, 47.2118],
  [-61.973, 47.214],
  [-61.9819, 47.2176],
  [-61.9858, 47.2173],
  [-61.9883, 47.2193],
  [-61.9961, 47.219],
  [-62.004, 47.2153],
  [-62.0065, 47.2156],
  [-62.0086, 47.2189],
  [-62.0176, 47.2244],
  [-62.0175, 47.2273],
  [-62.0206, 47.2281],
  [-62.0189, 47.2328],
  [-62.0214, 47.2361],
  [-62.0143, 47.2471],
  [-62.0133, 47.2517],
  [-62.0079, 47.2546],
  [-61.9983, 47.2632],
  [-61.9791, 47.2854],
  [-61.9599, 47.3196],
  [-61.9499, 47.3423],
  [-61.9497, 47.3504],
  [-61.9533, 47.358],
  [-61.9592, 47.362],
  [-61.9571, 47.3648],
  [-61.9602, 47.365],
  [-61.9629, 47.3629],
  [-61.9644, 47.3643],
  [-61.9541, 47.369],
  [-61.9552, 47.3712],
  [-61.9584, 47.3725],
  [-61.9561, 47.3823],
  [-61.9583, 47.3851],
  [-61.9538, 47.3863],
  [-61.9433, 47.3961],
  [-61.9391, 47.3979],
  [-61.9361, 47.4009],
  [-61.9352, 47.4055],
  [-61.9249, 47.4087],
  [-61.9157, 47.4152],
  [-61.9063, 47.4165],
  [-61.8977, 47.4223],
  [-61.8933, 47.4201],
  [-61.8844, 47.4215],
  [-61.8663, 47.4273],
  [-61.8411, 47.4393],
  [-61.7934, 47.4665],
  [-61.7633, 47.4868],
  [-61.7166, 47.5245],
  [-61.7122, 47.5299],
  [-61.7126, 47.5315],
  [-61.7052, 47.5323],
  [-61.6699, 47.5507],
  [-61.6455, 47.5671],
  [-61.6169, 47.5832],
  [-61.5972, 47.5971],
  [-61.5605, 47.6187],
  [-61.5469, 47.6243],
  [-61.5375, 47.6265],
  [-61.525, 47.6319],
  [-61.5088, 47.6251],
  [-61.4944, 47.624],
  [-61.4748, 47.6263],
  [-61.4394, 47.6343],
  [-61.4218, 47.6343],
  [-61.4083, 47.6322],
  [-61.4073, 47.6304],
  [-61.396, 47.6265],
  [-61.3917, 47.6235],
  [-61.3869, 47.6171],
  [-61.3876, 47.611],
  [-61.4122, 47.6131],
  [-61.4524, 47.5997],
  [-61.4672, 47.5832],
  [-61.4705, 47.5735],
  [-61.4649, 47.569],
  [-61.4878, 47.5701],
  [-61.4917, 47.5659],
  [-61.4957, 47.5653],
  [-61.4958, 47.5621],
  [-61.5, 47.5615],
  [-61.5091, 47.5485],
  [-61.5056, 47.5564],
  [-61.5031, 47.5579],
  [-61.5037, 47.5592],
  [-61.507, 47.5593],
  [-61.5121, 47.5547],
  [-61.5154, 47.5554],
  [-61.5239, 47.5517],
  [-61.5274, 47.5522],
  [-61.5264, 47.5487],
  [-61.531, 47.5468],
  [-61.5312, 47.5445],
  [-61.5274, 47.5439],
  [-61.5192, 47.5473],
  [-61.5114, 47.5478],
  [-61.5174, 47.5467],
  [-61.5224, 47.5437],
  [-61.5314, 47.5425],
  [-61.5346, 47.544],
  [-61.544, 47.5415],
  [-61.5478, 47.5452],
  [-61.5585, 47.5455],
  [-61.5575, 47.5526],
  [-61.5617, 47.5568],
  [-61.5586, 47.5567],
  [-61.5549, 47.5532],
  [-61.5504, 47.5532],
  [-61.5425, 47.5575],
  [-61.5361, 47.5579],
  [-61.5232, 47.5624],
  [-61.516, 47.5676],
  [-61.5085, 47.5705],
  [-61.5047, 47.5755],
  [-61.4986, 47.5768],
  [-61.4949, 47.5821],
  [-61.4933, 47.5899],
  [-61.4916, 47.5907],
  [-61.4897, 47.5885],
  [-61.4947, 47.5787],
  [-61.4883, 47.5784],
  [-61.4844, 47.5751],
  [-61.4799, 47.5757],
  [-61.4836, 47.5784],
  [-61.4847, 47.5857],
  [-61.4782, 47.6054],
  [-61.4794, 47.6101],
  [-61.4841, 47.6131],
  [-61.4735, 47.6169],
  [-61.4717, 47.6205],
  [-61.4666, 47.6204],
  [-61.4669, 47.6159],
  [-61.4644, 47.6159],
  [-61.4573, 47.6213],
  [-61.4458, 47.6259],
  [-61.4444, 47.6273],
  [-61.4452, 47.6304],
  [-61.4567, 47.629],
  [-61.4577, 47.6268],
  [-61.4658, 47.6258],
  [-61.4746, 47.619],
  [-61.48, 47.6175],
  [-61.4955, 47.6179],
  [-61.5008, 47.6129],
  [-61.5118, 47.6141],
  [-61.5391, 47.6071],
  [-61.5414, 47.6101],
  [-61.5399, 47.6179],
  [-61.5333, 47.6184],
  [-61.5166, 47.6237],
  [-61.5356, 47.6239],
  [-61.5383, 47.6204],
  [-61.5512, 47.6191],
  [-61.5566, 47.616],
  [-61.5588, 47.6112],
  [-61.5569, 47.6095],
  [-61.5577, 47.6084],
  [-61.5599, 47.6101],
  [-61.5664, 47.6092],
  [-61.589, 47.5999],
  [-61.5938, 47.5962],
  [-61.5913, 47.5921],
  [-61.5969, 47.5901],
  [-61.6057, 47.5815],
  [-61.6186, 47.5729],
  [-61.6463, 47.5615],
  [-61.6644, 47.5506],
  [-61.6759, 47.5461],
  [-61.6799, 47.5426],
  [-61.6944, 47.5368],
  [-61.7002, 47.5315],
  [-61.6983, 47.5259],
  [-61.7043, 47.5251],
  [-61.7135, 47.5187],
  [-61.7203, 47.5168],
  [-61.7268, 47.5119],
  [-61.7305, 47.5068],
  [-61.7403, 47.5011],
  [-61.7451, 47.4942],
  [-61.7728, 47.4772],
  [-61.7799, 47.4699],
  [-61.7885, 47.4662],
  [-61.8008, 47.4554],
  [-61.8055, 47.4548],
  [-61.8185, 47.4452],
  [-61.827, 47.4419],
  [-61.8331, 47.4338],
  [-61.841, 47.4313],
  [-61.8447, 47.4241],
  [-61.8573, 47.4235],
  [-61.8648, 47.4197],
  [-61.8694, 47.4107],
  [-61.8658, 47.4093],
  [-61.8666, 47.4073],
  [-61.8638, 47.4054],
  [-61.8702, 47.3998],
  [-61.8694, 47.3984],
  [-61.8652, 47.3976],
  [-61.8543, 47.3996],
  [-61.8545, 47.3979],
  [-61.8466, 47.3962],
  [-61.8454, 47.4001],
  [-61.8438, 47.401],
  [-61.8447, 47.4046],
  [-61.8436, 47.4057],
  [-61.8419, 47.4057],
  [-61.8396, 47.4021],
  [-61.8432, 47.3965],
  [-61.8502, 47.3921],
  [-61.8543, 47.3857],
  [-61.8557, 47.3831],
  [-61.8539, 47.3814],
  [-61.8567, 47.3794],
  [-61.8527, 47.3773],
  [-61.8619, 47.3779],
  [-61.8653, 47.376],
  [-61.8707, 47.3649],
  [-61.8719, 47.3593],
  [-61.8703, 47.3545],
  [-61.8745, 47.3548],
  [-61.8788, 47.3529],
  [-61.8805, 47.3487],
  [-61.8783, 47.3454],
  [-61.8801, 47.3444],
  [-61.8827, 47.348],
  [-61.8911, 47.3521],
  [-61.9052, 47.3512],
];

const boundaries3 = [
  [-61.5819, 47.5597],
  [-61.6109, 47.5547],
  [-61.6371, 47.5466],
  [-61.6733, 47.5263],
  [-61.6845, 47.5173],
  [-61.6931, 47.5075],
  [-61.7038, 47.4916],
  [-61.7097, 47.486],
  [-61.7091, 47.485],
  [-61.7189, 47.4804],
  [-61.726, 47.4749],
  [-61.7377, 47.4611],
  [-61.7461, 47.4483],
  [-61.7508, 47.4358],
  [-61.7541, 47.4236],
  [-61.7536, 47.4169],
  [-61.755, 47.4144],
  [-61.7538, 47.4122],
  [-61.7599, 47.4041],
  [-61.7702, 47.3977],
  [-61.7736, 47.39],
  [-61.8033, 47.3905],
  [-61.8073, 47.3945],
  [-61.8163, 47.3963],
  [-61.8238, 47.3999],
  [-61.8315, 47.4008],
  [-61.838, 47.4046],
  [-61.8394, 47.4074],
  [-61.835, 47.4094],
  [-61.8327, 47.4074],
  [-61.8357, 47.4061],
  [-61.8294, 47.4019],
  [-61.8252, 47.4017],
  [-61.8202, 47.4041],
  [-61.8285, 47.4064],
  [-61.8252, 47.4113],
  [-61.8127, 47.4136],
  [-61.8029, 47.4193],
  [-61.7941, 47.4177],
  [-61.7917, 47.4202],
  [-61.7939, 47.4205],
  [-61.7938, 47.4235],
  [-61.7877, 47.4297],
  [-61.7811, 47.4294],
  [-61.7766, 47.4319],
  [-61.7743, 47.4357],
  [-61.7667, 47.4342],
  [-61.7624, 47.4409],
  [-61.7637, 47.4531],
  [-61.7694, 47.4611],
  [-61.7675, 47.4655],
  [-61.7585, 47.4731],
  [-61.7467, 47.4785],
  [-61.7125, 47.4861],
  [-61.7014, 47.4994],
  [-61.703, 47.5025],
  [-61.6999, 47.505],
  [-61.7022, 47.5077],
  [-61.6909, 47.5134],
  [-61.6916, 47.515],
  [-61.6893, 47.5153],
  [-61.6881, 47.5206],
  [-61.6827, 47.5218],
  [-61.6837, 47.5239],
  [-61.6635, 47.5369],
  [-61.6578, 47.5441],
  [-61.6486, 47.5471],
  [-61.6444, 47.5463],
  [-61.6328, 47.55],
  [-61.6289, 47.5499],
  [-61.6219, 47.5547],
  [-61.5955, 47.5586],
  [-61.5833, 47.5622],
  [-61.5727, 47.5628],
  [-61.5819, 47.5597],
];
const boundaries4 = [
  [-61.7476, 47.4899],
  [-61.7466, 47.491],
  [-61.7441, 47.4906],
  [-61.7464, 47.4885],
  [-61.7476, 47.4899],
];

const boundaries5 = [
  [-61.5501, 47.5635],
  [-61.5558, 47.5586],
  [-61.5578, 47.5597],
  [-61.5496, 47.5676],
  [-61.5474, 47.5653],
  [-61.5501, 47.5635],
];

const boundaries6 = [
  [-61.5472, 47.5967],
  [-61.5506, 47.5983],
  [-61.5514, 47.6003],
  [-61.5424, 47.5959],
  [-61.5472, 47.5967],
];

const boundaries7 = [
  [-61.4877, 47.5993],
  [-61.4897, 47.5967],
  [-61.4944, 47.5979],
  [-61.4934, 47.6003],
  [-61.4911, 47.6009],
  [-61.4881, 47.6006],
  [-61.4877, 47.5993],
];

const boundaries8 = [
  [-61.4444, 47.8027],
  [-61.4368, 47.8],
  [-61.4347, 47.7977],
  [-61.4375, 47.7945],
  [-61.4424, 47.7959],
  [-61.4441, 47.7936],
  [-61.4496, 47.7915],
  [-61.4552, 47.7928],
  [-61.461, 47.7917],
  [-61.4719, 47.7848],
  [-61.4713, 47.7836],
  [-61.4691, 47.785],
  [-61.4691, 47.7825],
  [-61.4819, 47.7778],
  [-61.4972, 47.7768],
  [-61.5119, 47.7812],
  [-61.5021, 47.7894],
  [-61.4894, 47.7947],
  [-61.4756, 47.7913],
  [-61.4695, 47.7975],
  [-61.4632, 47.7981],
  [-61.4577, 47.8023],
  [-61.4444, 47.8027],
];

const boundaries9 = [
  [-61.4243, 47.7985],
  [-61.4203, 47.7991],
  [-61.4143, 47.795],
  [-61.4194, 47.7925],
  [-61.4208, 47.7951],
  [-61.4283, 47.7953],
  [-61.4326, 47.798],
  [-61.4299, 47.7993],
  [-61.4243, 47.7985],
];

let polygons = [[], [], [], [], [], [], [], [], []];
boundaries1.map((boundary) =>
  polygons[0].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries2.map((boundary) =>
  polygons[1].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries3.map((boundary) =>
  polygons[2].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries4.map((boundary) =>
  polygons[3].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries5.map((boundary) =>
  polygons[4].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries6.map((boundary) =>
  polygons[5].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries7.map((boundary) =>
  polygons[6].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries8.map((boundary) =>
  polygons[7].push({ lat: boundary[1], lng: boundary[0] })
);
boundaries9.map((boundary) =>
  polygons[8].push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;
