import { InfoBox } from "@react-google-maps/api";

import { infobox } from "../../styles";
import mountainIcon from "../../assets/images/ico-mountain.svg";

export default function MountainInfoBox({ mountain }) {
  return (
    <InfoBox
      position={{ lat: mountain.lat, lng: mountain.lng }}
      options={{ styles: infobox.style }}
    >
      <div style={infobox.firstDivStyle}>
        <div style={infobox.secondDivStyle}>
          <img
            id="mountain-icon"
            src={mountainIcon}
            width="45px"
            height="30px"
            alt={mountain.description}
          />

          <div
            style={{
              fontFamily: "SofiaPro-Bold",
              fontSize: "1.25rem",
              fontWeight: "500",
              color: `#042058`,
              paddingLeft: "0.9rem",
            }}
          >
            {mountain.name}
          </div>
        </div>
      </div>
    </InfoBox>
  );
}
