const boundaries = [
  [-73.9221, 45.4017],
  [-73.9345, 45.4001],
  [-73.9532, 45.4025],
  [-73.9689, 45.4113],
  [-73.9725, 45.4159],
  [-73.9698, 45.4255],
  [-73.9569, 45.4363],
  [-73.9527, 45.4423],
  [-73.9437, 45.4448],
  [-73.9412, 45.4538],
  [-73.9349, 45.4562],
  [-73.9347, 45.4599],
  [-73.9254, 45.4683],
  [-73.9395, 45.4673],
  [-73.9313, 45.4841],
  [-73.9199, 45.4864],
  [-73.9154, 45.491],
  [-73.907, 45.492],
  [-73.9039, 45.4997],
  [-73.8968, 45.501],
  [-73.8948, 45.5089],
  [-73.8763, 45.5125],
  [-73.8551, 45.5097],
  [-73.859, 45.5039],
  [-73.859, 45.4979],
  [-73.8708, 45.4904],
  [-73.8548, 45.4907],
  [-73.8485, 45.4939],
  [-73.8467, 45.4982],
  [-73.8251, 45.5138],
  [-73.8185, 45.5143],
  [-73.8142, 45.5103],
  [-73.8094, 45.5092],
  [-73.8173, 45.5149],
  [-73.8108, 45.515],
  [-73.8022, 45.5101],
  [-73.7939, 45.5075],
  [-73.7675, 45.5098],
  [-73.7641, 45.5084],
  [-73.7553, 45.5104],
  [-73.7471, 45.5174],
  [-73.7378, 45.5205],
  [-73.7259, 45.5299],
  [-73.6983, 45.5385],
  [-73.6756, 45.5482],
  [-73.6542, 45.5675],
  [-73.6416, 45.5911],
  [-73.6326, 45.5999],
  [-73.6278, 45.6114],
  [-73.608, 45.6266],
  [-73.5849, 45.633],
  [-73.5737, 45.6415],
  [-73.5496, 45.655],
  [-73.5265, 45.6659],
  [-73.5181, 45.667],
  [-73.4925, 45.6931],
  [-73.4785, 45.6961],
  [-73.4827, 45.689],
  [-73.4872, 45.6694],
  [-73.4728, 45.6414],
  [-73.4867, 45.632],
  [-73.5001, 45.6094],
  [-73.5028, 45.5988],
  [-73.498, 45.5792],
  [-73.512, 45.5569],
  [-73.5285, 45.5359],
  [-73.5226, 45.5105],
  [-73.5257, 45.4993],
  [-73.5204, 45.4963],
  [-73.5203, 45.4945],
  [-73.5316, 45.4905],
  [-73.5264, 45.4612],
  [-73.5369, 45.4572],
  [-73.5388, 45.4465],
  [-73.5451, 45.4377],
  [-73.5656, 45.4211],
  [-73.5835, 45.414],
  [-73.6278, 45.4098],
  [-73.6368, 45.4108],
  [-73.6572, 45.4176],
  [-73.6739, 45.4211],
  [-73.7002, 45.4246],
  [-73.7102, 45.4258],
  [-73.7137, 45.4237],
  [-73.7325, 45.4258],
  [-73.7427, 45.4233],
  [-73.7505, 45.4267],
  [-73.7624, 45.4264],
  [-73.7634, 45.4303],
  [-73.7743, 45.4352],
  [-73.7832, 45.4362],
  [-73.7921, 45.4307],
  [-73.7925, 45.4234],
  [-73.7944, 45.4219],
  [-73.8055, 45.4205],
  [-73.8123, 45.4136],
  [-73.8195, 45.4151],
  [-73.8242, 45.4132],
  [-73.8399, 45.4165],
  [-73.8661, 45.4071],
  [-73.8679, 45.4051],
  [-73.8872, 45.4101],
  [-73.9221, 45.4017],
];

let polygons = [];
boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons