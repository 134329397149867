const boundaries = [
  [-77.9316134, 47.2697153],
  [-77.9311636, 47.2659205],
  [-77.930174, 47.2647457],
  [-77.9297275, 47.263561],
  [-77.9283825, 47.2635961],
  [-77.9259237, 47.2631413],
  [-77.9240107, 47.2619815],
  [-77.9223296, 47.2596501],
  [-77.9212507, 47.2538184],
  [-77.9178715, 47.2531793],
  [-77.9164338, 47.2518102],
  [-77.9156828, 47.251417],
  [-77.9157901, 47.2510529],
  [-77.9153609, 47.2506742],
  [-77.9143739, 47.2507907],
  [-77.9109407, 47.2498439],
  [-77.904203, 47.2455032],
  [-77.9017353, 47.2426189],
  [-77.8708901, 47.2427064],
  [-77.858293, 47.236128],
  [-77.8572868, 47.2305022],
  [-77.8568931, 47.2261294],
  [-77.8571974, 47.2246639],
  [-77.8568511, 47.2168691],
  [-77.8560515, 47.2136684],
  [-77.8565412, 47.2134402],
  [-77.8573726, 47.2134284],
  [-77.8573632, 47.2127627],
  [-77.855237, 47.2114384],
  [-77.855757, 47.209579],
  [-77.8565751, 47.2094528],
  [-77.8571463, 47.2091395],
  [-77.8572321, 47.2087751],
  [-77.8569102, 47.2073976],
  [-77.8562772, 47.2068874],
  [-77.856642, 47.2033449],
  [-77.8545284, 47.1994815],
  [-77.8542495, 47.1978486],
  [-77.8534556, 47.1960917],
  [-77.8534722, 47.1931547],
  [-77.8542429, 47.1913319],
  [-77.8539406, 47.1878366],
  [-77.8546073, 47.1846856],
  [-77.8546768, 47.181752],
  [-77.8523218, 47.1778975],
  [-77.8551369, 47.1750215],
  [-77.8557451, 47.17363],
  [-77.8559572, 47.1723164],
  [-77.8552195, 47.1684119],
  [-77.855792, 47.1656192],
  [-77.8557394, 47.1647012],
  [-77.8548169, 47.1632739],
  [-77.853447, 47.161836],
  [-77.8531841, 47.1602879],
  [-77.8535162, 47.1599239],
  [-77.8527045, 47.1575639],
  [-77.8530693, 47.1551418],
  [-77.8527904, 47.152953],
  [-77.8513098, 47.1510414],
  [-77.851224, 47.1504577],
  [-77.8526831, 47.1484438],
  [-77.8541851, 47.1474369],
  [-77.8545284, 47.1459629],
  [-77.8539277, 47.142971],
  [-77.8492499, 47.1388551],
  [-77.8467179, 47.1257171],
  [-77.8469325, 47.1233519],
  [-77.8463316, 47.1215706],
  [-77.845645, 47.1206069],
  [-77.8456879, 47.118884],
  [-77.8480053, 47.114591],
  [-77.848177, 47.1092461],
  [-77.8468895, 47.1064129],
  [-77.8469754, 47.1056534],
  [-77.847662, 47.1050984],
  [-77.8461171, 47.1041344],
  [-77.8455162, 47.1030536],
  [-77.8449154, 47.1028783],
  [-77.8435421, 47.1034334],
  [-77.8429413, 47.1033165],
  [-77.842083, 47.1036963],
  [-77.8406239, 47.1013009],
  [-77.841568, 47.1002492],
  [-77.8434134, 47.0992267],
  [-77.8445292, 47.0989054],
  [-77.8456665, 47.0990661],
  [-77.8464711, 47.0994458],
  [-77.8472436, 47.1001762],
  [-77.8489924, 47.0997818],
  [-77.8498936, 47.0990003],
  [-77.8499633, 47.0969151],
  [-77.849561, 47.0963016],
  [-77.8468466, 47.0942455],
  [-77.840066, 47.0845886],
  [-77.8382636, 47.0762305],
  [-77.837534, 47.0592473],
  [-77.841096, 47.0523472],
  [-77.8407526, 47.049891],
  [-77.8415251, 47.0445982],
  [-77.842598, 47.043019],
  [-77.8444434, 47.041118],
  [-77.8474474, 47.0395972],
  [-77.8486062, 47.0373452],
  [-77.8493357, 47.0328994],
  [-77.8514386, 47.0251475],
  [-77.854743, 47.0180382],
  [-77.8537131, 47.0136786],
  [-77.8531123, 47.0124203],
  [-77.8500224, 47.0084406],
  [-77.8487778, 47.0055727],
  [-77.8473616, 47.0018852],
  [-77.846632, 46.9973193],
  [-77.8469754, 46.9928116],
  [-77.8482199, 46.9864884],
  [-77.8465891, 46.9670752],
  [-77.8465891, 46.9631507],
  [-77.8472758, 46.9581422],
  [-77.8483487, 46.9542463],
  [-77.8522112, 46.9452233],
  [-77.8570176, 46.9428207],
  [-77.8583909, 46.9411799],
  [-77.8599358, 46.9305722],
  [-77.8611375, 46.9267036],
  [-77.8624249, 46.9237141],
  [-77.8677464, 46.9158584],
  [-77.8728963, 46.9110507],
  [-77.8881741, 46.9004369],
  [-77.8934098, 46.8942788],
  [-77.8952981, 46.8872401],
  [-77.8947831, 46.8823711],
  [-77.8925515, 46.8777363],
  [-77.8916074, 46.8736879],
  [-77.892809, 46.8676441],
  [-77.8945256, 46.8635363],
  [-77.897873, 46.8588412],
  [-77.9001046, 46.8543805],
  [-77.9005338, 46.8426988],
  [-77.9022504, 46.8391762],
  [-77.9013062, 46.8348313],
  [-77.8976155, 46.8292529],
  [-77.8912211, 46.8229984],
  [-77.8843546, 46.821677],
  [-77.8815651, 46.8214127],
  [-77.8794623, 46.8215595],
  [-77.8768015, 46.822558],
  [-77.8742266, 46.8225873],
  [-77.8714234, 46.8221189],
  [-77.8698337, 46.8222644],
  [-77.8687334, 46.8220588],
  [-77.8642506, 46.8199058],
  [-77.8626726, 46.8196697],
  [-77.8611374, 46.8198563],
  [-77.8604508, 46.8225286],
  [-77.8595066, 46.8236298],
  [-77.8586912, 46.8240409],
  [-77.8578115, 46.8239528],
  [-77.8580475, 46.8226754],
  [-77.8568888, 46.821075],
  [-77.8572321, 46.8193864],
  [-77.8549791, 46.8179915],
  [-77.8544426, 46.8178887],
  [-77.8535414, 46.8180649],
  [-77.8535414, 46.8184907],
  [-77.8546143, 46.8190781],
  [-77.8548503, 46.8197241],
  [-77.8549147, 46.820238],
  [-77.8544426, 46.8208401],
  [-77.852726, 46.8210897],
  [-77.8518248, 46.8214421],
  [-77.8512025, 46.8213833],
  [-77.8494215, 46.8206785],
  [-77.8492499, 46.8203702],
  [-77.8488636, 46.8188284],
  [-77.8492713, 46.8169342],
  [-77.85146, 46.8158182],
  [-77.8521157, 46.815148],
  [-77.8528548, 46.8132777],
  [-77.8526187, 46.8130721],
  [-77.8506446, 46.8123085],
  [-77.847662, 46.8116036],
  [-77.8437996, 46.811633],
  [-77.841611, 46.8125141],
  [-77.8392077, 46.8122791],
  [-77.8371478, 46.811633],
  [-77.836032, 46.8103113],
  [-77.8359032, 46.8094595],
  [-77.8373838, 46.8080202],
  [-77.8375983, 46.8075355],
  [-77.8371906, 46.8070949],
  [-77.8360534, 46.8070655],
  [-77.8346372, 46.8075796],
  [-77.8333068, 46.807609],
  [-77.8325343, 46.8074621],
  [-77.8322554, 46.8069774],
  [-77.8319121, 46.8058906],
  [-77.8319335, 46.8039372],
  [-77.831161, 46.8021452],
  [-77.8313971, 46.8017633],
  [-77.8333926, 46.8008967],
  [-77.8333497, 46.8002357],
  [-77.8326523, 46.7998758],
  [-77.8325236, 46.7992956],
  [-77.8351629, 46.7981352],
  [-77.8354418, 46.7972979],
  [-77.8353667, 46.7963798],
  [-77.837137, 46.7943379],
  [-77.8376412, 46.7932434],
  [-77.837534, 46.7917597],
  [-77.8383065, 46.7863972],
  [-77.8380061, 46.7831941],
  [-77.8362894, 46.7792562],
  [-77.8365899, 46.7764347],
  [-77.8381992, 46.7744802],
  [-77.8384352, 46.7736426],
  [-77.8383215, 46.7732517],
  [-77.8369761, 46.7726579],
  [-77.8368688, 46.771541],
  [-77.8353453, 46.771394],
  [-77.833736, 46.7705122],
  [-77.8326845, 46.7687633],
  [-77.8322768, 46.7657943],
  [-77.8326202, 46.7647655],
  [-77.8339076, 46.7635602],
  [-77.8344655, 46.7625607],
  [-77.8344226, 46.7619433],
  [-77.8338433, 46.76137],
  [-77.8318048, 46.760297],
  [-77.8315258, 46.7595032],
  [-77.8316975, 46.7587388],
  [-77.8333068, 46.7581802],
  [-77.8344226, 46.7571512],
  [-77.8340578, 46.7562838],
  [-77.8328133, 46.7554018],
  [-77.8324485, 46.7542992],
  [-77.8334999, 46.753373],
  [-77.8344012, 46.7521087],
  [-77.8350234, 46.7509619],
  [-77.8351307, 46.7498886],
  [-77.8347016, 46.748389],
  [-77.8334141, 46.7464628],
  [-77.8317619, 46.7448307],
  [-77.8299809, 46.7411988],
  [-77.8297663, 46.7391401],
  [-77.8300667, 46.7358018],
  [-77.8308392, 46.7343165],
  [-77.8312469, 46.7319045],
  [-77.8303886, 46.7209758],
  [-77.8300023, 46.7197843],
  [-77.8292942, 46.7192547],
  [-77.8279424, 46.7188134],
  [-77.822752, 46.7160934],
  [-77.8223892, 46.7155371],
  [-77.8227926, 46.7142528],
  [-77.8196597, 46.7114868],
  [-77.8198529, 46.7091327],
  [-77.8202605, 46.7077643],
  [-77.8209901, 46.7072052],
  [-77.822195, 46.7065279],
  [-77.8242088, 46.7059545],
  [-77.8236938, 46.7036148],
  [-77.8243482, 46.702055],
  [-77.8248847, 46.701665],
  [-77.827524, 46.7007306],
  [-77.8282321, 46.699752],
  [-77.8289294, 46.6980081],
  [-77.8300452, 46.6975004],
  [-77.8343582, 46.6972281],
  [-77.8383386, 46.6957711],
  [-77.8389287, 46.694395],
  [-77.8371477, 46.6902666],
  [-77.8362358, 46.688596],
  [-77.8345621, 46.6876172],
  [-77.8337467, 46.6867266],
  [-77.8337896, 46.6854681],
  [-77.8330922, 46.6829288],
  [-77.8343475, 46.6816702],
  [-77.8343046, 46.6793884],
  [-77.8361392, 46.6778205],
  [-77.8393579, 46.6770402],
  [-77.8432202, 46.6764587],
  [-77.8451085, 46.6734847],
  [-77.8450549, 46.6719387],
  [-77.8453446, 46.6714381],
  [-77.8471148, 46.6706209],
  [-77.8495073, 46.6703559],
  [-77.850945, 46.6699289],
  [-77.8530049, 46.6686552],
  [-77.8531122, 46.6680147],
  [-77.8525972, 46.6672858],
  [-77.8523719, 46.6656513],
  [-77.8513527, 46.6647015],
  [-77.8512776, 46.6642671],
  [-77.8524792, 46.663413],
  [-77.8532195, 46.6624263],
  [-77.8533161, 46.6619035],
  [-77.8537667, 46.6615575],
  [-77.8544641, 46.6614176],
  [-77.857039, 46.6615722],
  [-77.859335, 46.6603499],
  [-77.8592169, 46.6594663],
  [-77.8602469, 46.6589508],
  [-77.8611589, 46.6578904],
  [-77.8611052, 46.6571467],
  [-77.8617382, 46.656668],
  [-77.8615987, 46.6560863],
  [-77.859807, 46.6539949],
  [-77.8605688, 46.6501432],
  [-77.8603435, 46.6484124],
  [-77.8576076, 46.6440374],
  [-77.8565776, 46.6410542],
  [-77.8561163, 46.6407007],
  [-77.8526187, 46.6395516],
  [-77.8505159, 46.6386234],
  [-77.8482628, 46.6370764],
  [-77.8445721, 46.6337024],
  [-77.8437889, 46.6319638],
  [-77.8430593, 46.6313375],
  [-77.8415144, 46.6307334],
  [-77.8390575, 46.6306302],
  [-77.8372657, 46.6297756],
  [-77.835635, 46.6269538],
  [-77.8358281, 46.626438],
  [-77.8353667, 46.6259443],
  [-77.8348303, 46.6245591],
  [-77.8334248, 46.623196],
  [-77.8302062, 46.6219286],
  [-77.8241444, 46.6215822],
  [-77.8204858, 46.6203369],
  [-77.8189731, 46.6194305],
  [-77.8167951, 46.6186273],
  [-77.8151965, 46.6177356],
  [-77.8146064, 46.6169471],
  [-77.8098321, 46.614611],
  [-77.8078151, 46.6131518],
  [-77.8074932, 46.6125696],
  [-77.8037381, 46.6107934],
  [-77.8006321, 46.6107012],
  [-77.7993474, 46.611289],
  [-77.798599, 46.6112724],
  [-77.7951872, 46.6107418],
  [-77.7938354, 46.6097542],
  [-77.7929127, 46.6094962],
  [-77.7930737, 46.6085823],
  [-77.7927411, 46.6078084],
  [-77.7894366, 46.6051991],
  [-77.7875483, 46.6048232],
  [-77.7864647, 46.6032237],
  [-77.7854455, 46.6024423],
  [-77.7857995, 46.60186],
  [-77.7848554, 46.5995232],
  [-77.7850914, 46.5985059],
  [-77.7830851, 46.5954465],
  [-77.7820384, 46.5923062],
  [-77.7819693, 46.5910082],
  [-77.7813363, 46.5896368],
  [-77.7799201, 46.5885898],
  [-77.7781391, 46.5890543],
  [-77.7768087, 46.588988],
  [-77.776165, 46.5871373],
  [-77.774251, 46.5846813],
  [-77.7744913, 46.583856],
  [-77.7772164, 46.5822264],
  [-77.7787185, 46.5797781],
  [-77.7793193, 46.5775805],
  [-77.7811432, 46.5763932],
  [-77.7842867, 46.5756336],
  [-77.7851772, 46.5738562],
  [-77.7883852, 46.5722484],
  [-77.7892971, 46.571142],
  [-77.7874196, 46.5684646],
  [-77.7874196, 46.5670484],
  [-77.783525, 46.5626816],
  [-77.7828813, 46.5614718],
  [-77.7827096, 46.5603357],
  [-77.7809715, 46.5572816],
  [-77.7809501, 46.5551273],
  [-77.781272, 46.5542125],
  [-77.7821732, 46.5531795],
  [-77.7826452, 46.5521171],
  [-77.7815294, 46.5493576],
  [-77.7791476, 46.5472325],
  [-77.7779246, 46.5456387],
  [-77.7759719, 46.5445761],
  [-77.770114, 46.5422295],
  [-77.7687836, 46.5411078],
  [-77.7686119, 46.5390562],
  [-77.7684322, 46.5383883],
  [-77.7680112, 46.5380967],
  [-77.7679038, 46.5372407],
  [-77.7686548, 46.5359122],
  [-77.7687192, 46.5346428],
  [-77.7696204, 46.5341704],
  [-77.7713371, 46.5324581],
  [-77.774207, 46.530683],
  [-77.7768087, 46.5301774],
  [-77.7777744, 46.529801],
  [-77.7792629, 46.5287473],
  [-77.7813578, 46.5280294],
  [-77.7834606, 46.5279556],
  [-77.7844477, 46.5275423],
  [-77.784791, 46.5268927],
  [-77.7833104, 46.5262726],
  [-77.7831388, 46.5259626],
  [-77.7831173, 46.525564],
  [-77.7837396, 46.5248258],
  [-77.7839756, 46.5240728],
  [-77.7828464, 46.5227256],
  [-77.7811218, 46.5213414],
  [-77.7805209, 46.5212233],
  [-77.7792764, 46.5214891],
  [-77.7784825, 46.5214448],
  [-77.7775598, 46.5205146],
  [-77.7775169, 46.5193039],
  [-77.7770448, 46.518477],
  [-77.7750492, 46.5171186],
  [-77.7738261, 46.5168971],
  [-77.7723456, 46.5162031],
  [-77.7709937, 46.515893],
  [-77.7664662, 46.5162031],
  [-77.7640629, 46.5142539],
  [-77.7615953, 46.5131612],
  [-77.7609086, 46.5125853],
  [-77.7603722, 46.5116549],
  [-77.7590203, 46.5067667],
  [-77.7592135, 46.5061169],
  [-77.759943, 46.5054228],
  [-77.7608228, 46.5048911],
  [-77.7617481, 46.5037353],
  [-77.7628559, 46.503331],
  [-77.7631831, 46.5028529],
  [-77.7623248, 46.5014793],
  [-77.7605868, 46.499825],
  [-77.760222, 46.4986286],
  [-77.7599645, 46.4911688],
  [-77.7615524, 46.4890858],
  [-77.7622175, 46.4866777],
  [-77.762003, 46.4840921],
  [-77.7636767, 46.4814917],
  [-77.7635908, 46.4803835],
  [-77.76299, 46.4792753],
  [-77.7631402, 46.4785956],
  [-77.7652431, 46.4767486],
  [-77.7655649, 46.4759949],
  [-77.7656079, 46.4752265],
  [-77.764771, 46.4734385],
  [-77.7651143, 46.4724631],
  [-77.7661872, 46.4710444],
  [-77.7671099, 46.4704533],
  [-77.7679897, 46.4701873],
  [-77.7689338, 46.4694336],
  [-77.7693629, 46.4687389],
  [-77.7695775, 46.4677044],
  [-77.7690625, 46.4659013],
  [-77.7695132, 46.4649258],
  [-77.7702642, 46.4641868],
  [-77.7716375, 46.4602699],
  [-77.7716589, 46.4582892],
  [-77.772882, 46.4577718],
  [-77.7742982, 46.4567519],
  [-77.7745342, 46.4560571],
  [-77.774427, 46.454638],
  [-77.7747703, 46.4540467],
  [-77.7760363, 46.4532779],
  [-77.7762079, 46.4527753],
  [-77.775929, 46.4524205],
  [-77.7750707, 46.4523022],
  [-77.7727318, 46.4511343],
  [-77.7713371, 46.4486654],
  [-77.7633119, 46.4428696],
  [-77.7613592, 46.4418789],
  [-77.7601361, 46.4409917],
  [-77.7595568, 46.4384336],
  [-77.7602005, 46.4381231],
  [-77.7624321, 46.4380048],
  [-77.76299, 46.4375759],
  [-77.7632904, 46.436526],
  [-77.7646208, 46.4355944],
  [-77.765801, 46.4321043],
  [-77.7654791, 46.4304923],
  [-77.7639985, 46.4294275],
  [-77.7613695, 46.4280894],
  [-77.7583337, 46.4249164],
  [-77.7573681, 46.4234077],
  [-77.7571964, 46.4212925],
  [-77.7531195, 46.4189998],
  [-77.7520895, 46.4176093],
  [-77.7506165, 46.4163908],
  [-77.7480126, 46.4154347],
  [-77.7447295, 46.4122984],
  [-77.7432704, 46.4103011],
  [-77.7417898, 46.4054186],
  [-77.7418542, 46.4044568],
  [-77.7438283, 46.4014383],
  [-77.744236, 46.4012015],
  [-77.7460814, 46.4010683],
  [-77.7491284, 46.399189],
  [-77.7502227, 46.3977388],
  [-77.7517033, 46.3970729],
  [-77.7526903, 46.3962442],
  [-77.7528835, 46.3949419],
  [-77.7537632, 46.3943203],
  [-77.7583337, 46.3924556],
  [-77.7667666, 46.3905464],
  [-77.7695346, 46.388948],
  [-77.7701998, 46.3883411],
  [-77.7705431, 46.3866982],
  [-77.7701783, 46.3837527],
  [-77.7705217, 46.382983],
  [-77.7717233, 46.3816212],
  [-77.772603, 46.3791269],
  [-77.7720773, 46.3780832],
  [-77.772249, 46.3770914],
  [-77.7738047, 46.3737012],
  [-77.7744521, 46.3730279],
  [-77.7767122, 46.3717543],
  [-77.7775383, 46.3716433],
  [-77.7793408, 46.3718358],
  [-77.7822912, 46.3707846],
  [-77.7828169, 46.3704292],
  [-77.78404, 46.3687192],
  [-77.7844155, 46.3669572],
  [-77.786808, 46.3662687],
  [-77.7875148, 46.3653553],
  [-77.7884817, 46.3652988],
  [-77.7890289, 46.3659503],
  [-77.7895117, 46.3661428],
  [-77.7914965, 46.3659059],
  [-77.7939856, 46.3647436],
  [-77.7979553, 46.3633591],
  [-77.7994788, 46.3615155],
  [-77.8013901, 46.360246],
  [-77.8045997, 46.3597954],
  [-77.8080234, 46.3578283],
  [-77.8104747, 46.3544066],
  [-77.8150349, 46.3465609],
  [-77.8150648, 46.3450943],
  [-77.815792, 46.3435578],
  [-77.817322, 46.3423919],
  [-77.8180018, 46.3422211],
  [-77.8190431, 46.3414789],
  [-77.8192648, 46.3410112],
  [-77.8189724, 46.3388564],
  [-77.8194806, 46.3382],
  [-77.8199296, 46.3380226],
  [-77.8215065, 46.3380364],
  [-77.8248371, 46.3353943],
  [-77.8267376, 46.3328691],
  [-77.8265411, 46.331089],
  [-77.8254803, 46.3286113],
  [-77.8254104, 46.3252533],
  [-77.8262258, 46.3227342],
  [-77.8295946, 46.3205855],
  [-77.8319121, 46.319726],
  [-77.8365898, 46.3174289],
  [-77.8391862, 46.3149688],
  [-77.8387249, 46.3135571],
  [-77.838521, 46.3110708],
  [-77.8398437, 46.3094148],
  [-77.839787, 46.3071132],
  [-77.8408385, 46.3046081],
  [-77.8409243, 46.3032295],
  [-77.8406239, 46.3029331],
  [-77.841254, 46.3018696],
  [-77.8409028, 46.3003685],
  [-77.8411174, 46.2992418],
  [-77.842319, 46.2983523],
  [-77.8428984, 46.297611],
  [-77.8452802, 46.2966029],
  [-77.8455806, 46.2967363],
  [-77.8463102, 46.296588],
  [-77.8466749, 46.2962026],
  [-77.8467393, 46.2936525],
  [-77.8465247, 46.2927629],
  [-77.8459883, 46.2921995],
  [-77.8452802, 46.2919622],
  [-77.8432417, 46.2923774],
  [-77.8425336, 46.2922884],
  [-77.8419972, 46.2917547],
  [-77.8414822, 46.2890264],
  [-77.8419543, 46.287514],
  [-77.8424659, 46.286655],
  [-77.8453446, 46.2840144],
  [-77.8475976, 46.2823239],
  [-77.8493142, 46.2805295],
  [-77.8502584, 46.2786609],
  [-77.8524256, 46.2782901],
  [-77.8535414, 46.2778452],
  [-77.8537989, 46.2773113],
  [-77.852962, 46.2762138],
  [-77.8524041, 46.2759468],
  [-77.8522325, 46.2755167],
  [-77.8525114, 46.2749531],
  [-77.8512669, 46.2712896],
  [-77.854743, 46.2655195],
  [-77.853241, 46.2633388],
  [-77.8529191, 46.2623152],
  [-77.8515029, 46.2614845],
  [-77.8500009, 46.2599268],
  [-77.8499365, 46.2582355],
  [-77.8489924, 46.2569447],
  [-77.8497005, 46.2557578],
  [-77.8497219, 46.255016],
  [-77.848075, 46.2500813],
  [-77.8484345, 46.2482944],
  [-77.8481555, 46.2474783],
  [-77.849105, 46.2461279],
  [-77.8503013, 46.2453711],
  [-77.8506446, 46.2445624],
  [-77.8507841, 46.2412753],
  [-77.8516317, 46.2402438],
  [-77.8535092, 46.2389527],
  [-77.8545177, 46.237936],
  [-77.8554297, 46.2360437],
  [-77.8557301, 46.2329045],
  [-77.8554404, 46.2317097],
  [-77.8534448, 46.2285183],
  [-77.8519643, 46.2245853],
  [-77.8492398, 46.2218353],
  [-77.8487369, 46.2204225],
  [-77.8487304, 46.2164489],
  [-77.8498824, 46.2079875],
  [-77.8216857, 46.2045745],
  [-77.795181, 46.1973456],
  [-77.7500921, 46.1932183],
  [-77.7252115, 46.192417],
  [-77.7132793, 46.1893348],
  [-77.7031091, 46.1847915],
  [-77.6959457, 46.1827125],
  [-77.6935646, 46.1827887],
  [-77.6917121, 46.1831742],
  [-77.6894833, 46.1841735],
  [-77.6880192, 46.1856385],
  [-77.6840127, 46.1907996],
  [-77.6835476, 46.1930312],
  [-77.682083, 46.1958809],
  [-77.6803892, 46.1976509],
  [-77.6779256, 46.1984216],
  [-77.6747206, 46.1976928],
  [-77.6654276, 46.1949386],
  [-77.6526941, 46.1884002],
  [-77.6389243, 46.1825486],
  [-77.619652, 46.1735992],
  [-77.6120829, 46.1735992],
  [-77.6014093, 46.1708449],
  [-77.5921168, 46.1660269],
  [-77.5859228, 46.1656838],
  [-77.5725024, 46.162243],
  [-77.5577009, 46.1546707],
  [-77.5241922, 46.1366156],
  [-77.4960255, 46.1204377],
  [-77.4623185, 46.1046981],
  [-77.4323349, 46.0902214],
  [-77.3988497, 46.0738907],
  [-77.3918307, 46.0698357],
  [-77.3850403, 46.0676613],
  [-77.3809741, 46.0666046],
  [-77.3719261, 46.0624619],
  [-77.3621677, 46.0598412],
  [-77.3575204, 46.0578307],
  [-77.3470758, 46.0508537],
  [-77.3356014, 46.0438346],
  [-77.3322142, 46.0413932],
  [-77.3209235, 46.0320092],
  [-77.3137355, 46.0275572],
  [-77.3096078, 46.025585],
  [-77.3064504, 46.024563],
  [-77.2921829, 46.0206986],
  [-77.2874603, 46.0192376],
  [-77.2816547, 46.016655],
  [-77.2790749, 46.0149229],
  [-77.2781446, 46.0139503],
  [-77.276878, 46.0119437],
  [-77.2764054, 46.0106277],
  [-77.2763974, 46.0084953],
  [-77.2768551, 46.0069846],
  [-77.2821802, 45.9968414],
  [-77.2836527, 45.9933737],
  [-77.2854085, 45.9877206],
  [-77.2857138, 45.9812166],
  [-77.285355, 45.9765701],
  [-77.2842477, 45.9707525],
  [-77.2825462, 45.9661788],
  [-77.2796941, 45.960701],
  [-77.2783884, 45.956089],
  [-77.2775873, 45.9513816],
  [-77.2766954, 45.949112],
  [-77.2753448, 45.9468994],
  [-77.2722175, 45.9429818],
  [-77.267776, 45.9392433],
  [-77.2608482, 45.9341239],
  [-77.2544099, 45.9301376],
  [-77.2502677, 45.9282152],
  [-77.2409055, 45.9244461],
  [-77.2385716, 45.923195],
  [-77.2371056, 45.9221075],
  [-77.2358018, 45.9204521],
  [-77.2353968, 45.9193076],
  [-77.2356255, 45.9147643],
  [-77.234658, 45.9127503],
  [-77.2334445, 45.91148],
  [-77.230163, 45.9088133],
  [-77.2272876, 45.9058838],
  [-77.2033314, 45.8797799],
  [-77.1987376, 45.8706969],
  [-77.193413, 45.8659973],
  [-77.1777494, 45.861927],
  [-77.153298, 45.8545648],
  [-77.1458057, 45.8513757],
  [-77.1360326, 45.8462831],
  [-77.1316302, 45.844349],
  [-77.1286395, 45.8433191],
  [-77.1255495, 45.8426019],
  [-77.1108101, 45.8401376],
  [-77.1041941, 45.8394393],
  [-77.0958246, 45.838909],
  [-77.092514, 45.8384781],
  [-77.0878226, 45.8370591],
  [-77.0777438, 45.8328782],
  [-77.0716706, 45.8310623],
  [-77.070229, 45.8303338],
  [-77.0678259, 45.8286553],
  [-77.0639035, 45.8249092],
  [-77.0603936, 45.8209227],
  [-77.0543293, 45.8120958],
  [-77.0516359, 45.8100968],
  [-77.0502622, 45.8094139],
  [-77.0464935, 45.8081246],
  [-77.0421909, 45.8072549],
  [-77.0353549, 45.8074227],
  [-77.0249326, 45.8087234],
  [-77.0205382, 45.8081817],
  [-77.0170369, 45.8070374],
  [-77.0124443, 45.8048936],
  [-77.0085142, 45.8026313],
  [-76.9938358, 45.7897378],
  [-76.9913795, 45.7883684],
  [-76.9889065, 45.7873573],
  [-76.9850762, 45.78656],
  [-76.9805367, 45.7864074],
  [-76.942894, 45.7878914],
  [-76.9387441, 45.7884026],
  [-76.934929, 45.7903481],
  [-76.9236753, 45.7972794],
  [-76.9201511, 45.7991219],
  [-76.9138413, 45.8032951],
  [-76.912415, 45.8046952],
  [-76.9115671, 45.8081397],
  [-76.9123307, 45.8110428],
  [-76.9281615, 45.8456993],
  [-76.9287334, 45.8472365],
  [-76.9287115, 45.8481637],
  [-76.9281763, 45.8489379],
  [-76.9266429, 45.8496779],
  [-76.9252169, 45.8498993],
  [-76.9222946, 45.8499641],
  [-76.919159, 45.8496209],
  [-76.9104151, 45.8475646],
  [-76.9080508, 45.8473435],
  [-76.9063106, 45.8476752],
  [-76.9029076, 45.8491018],
  [-76.9014366, 45.8500787],
  [-76.9010318, 45.8507729],
  [-76.9025577, 45.8531838],
  [-76.9030081, 45.8564645],
  [-76.9035114, 45.8569145],
  [-76.9069826, 45.8582765],
  [-76.9077, 45.8591919],
  [-76.9086155, 45.8614809],
  [-76.9095681, 45.8626097],
  [-76.9117819, 45.8640825],
  [-76.9182592, 45.8667108],
  [-76.9213173, 45.8689994],
  [-76.9231867, 45.8712538],
  [-76.9236603, 45.8727493],
  [-76.9234235, 45.8762092],
  [-76.9258571, 45.8803023],
  [-76.9264903, 45.8822325],
  [-76.9267425, 45.8843612],
  [-76.9273064, 45.8852537],
  [-76.9294359, 45.886631],
  [-76.9304349, 45.8881263],
  [-76.9305194, 45.8895837],
  [-76.9301611, 45.890793],
  [-76.929131, 45.8922348],
  [-76.9272159, 45.8935433],
  [-76.9240186, 45.894722],
  [-76.9197231, 45.895687],
  [-76.9105153, 45.8965799],
  [-76.9057694, 45.8972778],
  [-76.8932336, 45.8986586],
  [-76.8908011, 45.8985025],
  [-76.8867874, 45.8976135],
  [-76.8766707, 45.8935318],
  [-76.87297, 45.8927115],
  [-76.8705754, 45.892418],
  [-76.8667674, 45.8922995],
  [-76.8539422, 45.8944434],
  [-76.8499222, 45.8944893],
  [-76.8468545, 45.8938024],
  [-76.8450313, 45.893154],
  [-76.8036429, 45.876507],
  [-76.8002778, 45.87603],
  [-76.7887045, 45.8752289],
  [-76.7862555, 45.874901],
  [-76.7836381, 45.8743477],
  [-76.7809755, 45.8732263],
  [-76.778748, 45.8717958],
  [-76.7770692, 45.8697243],
  [-76.7762, 45.8670808],
  [-76.7754443, 45.8619729],
  [-76.7739714, 45.8593673],
  [-76.770516, 45.8558999],
  [-76.765709, 45.8526192],
  [-76.7646782, 45.8514441],
  [-76.7643583, 45.8503265],
  [-76.7645342, 45.8489991],
  [-76.7660141, 45.8457108],
  [-76.7666161, 45.841442],
  [-76.7706903, 45.8343237],
  [-76.7711034, 45.8326608],
  [-76.7707751, 45.8309823],
  [-76.768738, 45.8270455],
  [-76.7684705, 45.8223228],
  [-76.7678455, 45.8196412],
  [-76.7656248, 45.814949],
  [-76.7652811, 45.8107338],
  [-76.7655869, 45.8079758],
  [-76.7668002, 45.8054543],
  [-76.7684857, 45.8036841],
  [-76.7721551, 45.8005216],
  [-76.7740021, 45.7983513],
  [-76.77739, 45.7934915],
  [-76.7787857, 45.7909584],
  [-76.7798079, 45.7881774],
  [-76.7807538, 45.7836532],
  [-76.7806242, 45.7823982],
  [-76.7797846, 45.7802504],
  [-76.7767786, 45.7768019],
  [-76.7748641, 45.7730979],
  [-76.7742849, 45.7714158],
  [-76.7735831, 45.7660257],
  [-76.7714532, 45.7617911],
  [-76.7703398, 45.7577056],
  [-76.7697978, 45.7570113],
  [-76.7682652, 45.7561074],
  [-76.7679143, 45.7555009],
  [-76.7679514, 45.754734],
  [-76.7683871, 45.753872],
  [-76.7708276, 45.7508924],
  [-76.7710268, 45.7494775],
  [-76.7698217, 45.7487107],
  [-76.7664412, 45.7476653],
  [-76.7656712, 45.746689],
  [-76.7655795, 45.7459145],
  [-76.7658771, 45.7451096],
  [-76.769188, 45.7420273],
  [-76.7695925, 45.7411919],
  [-76.7694936, 45.7395555],
  [-76.7686689, 45.7363281],
  [-76.7690045, 45.7353935],
  [-76.7688138, 45.7343636],
  [-76.7680664, 45.733448],
  [-76.7643967, 45.731739],
  [-76.7630012, 45.7304727],
  [-76.7569966, 45.7277146],
  [-76.75296, 45.7254714],
  [-76.7488941, 45.724247],
  [-76.7344355, 45.7229385],
  [-76.7324525, 45.7223473],
  [-76.7294394, 45.7207338],
  [-76.725838, 45.7202416],
  [-76.722831, 45.7194479],
  [-76.7211759, 45.7192306],
  [-76.7197114, 45.7192344],
  [-76.7172314, 45.7196845],
  [-76.7111439, 45.7217408],
  [-76.7097089, 45.7218856],
  [-76.7043838, 45.7217598],
  [-76.7036126, 45.7209624],
  [-76.7041699, 45.7186851],
  [-76.7038963, 45.7174837],
  [-76.7026057, 45.7156371],
  [-76.7012789, 45.714283],
  [-76.6978767, 45.7117959],
  [-76.6954499, 45.7110024],
  [-76.6920163, 45.7102355],
  [-76.6917808, 45.7094995],
  [-76.6921689, 45.7080459],
  [-76.6935047, 45.7058182],
  [-76.6958161, 45.7032738],
  [-76.6959766, 45.7021828],
  [-76.695473, 45.7008859],
  [-76.692581, 45.6960143],
  [-76.6892542, 45.6921461],
  [-76.6866146, 45.688137],
  [-76.6865228, 45.686714],
  [-76.6879803, 45.6839675],
  [-76.6887204, 45.6812743],
  [-76.6899867, 45.6793898],
  [-76.6912085, 45.6785165],
  [-76.6999667, 45.6778299],
  [-76.7024453, 45.677101],
  [-76.7036595, 45.6764794],
  [-76.7105487, 45.6697884],
  [-76.7123339, 45.6668892],
  [-76.7127685, 45.6657219],
  [-76.7129371, 45.6640435],
  [-76.7126467, 45.6629334],
  [-76.7120056, 45.6620331],
  [-76.7096181, 45.6600266],
  [-76.6902624, 45.6455232],
  [-76.6882325, 45.643322],
  [-76.684609, 45.6371652],
  [-76.6830441, 45.6350899],
  [-76.6809233, 45.6332855],
  [-76.6754768, 45.6296465],
  [-76.671891, 45.6266062],
  [-76.6705321, 45.625122],
  [-76.6697534, 45.6229552],
  [-76.6699368, 45.6200714],
  [-76.6727145, 45.6109047],
  [-76.6730727, 45.6085662],
  [-76.6728741, 45.6062201],
  [-76.671387, 45.6010934],
  [-76.6713174, 45.599304],
  [-76.6719136, 45.5963708],
  [-76.6743625, 45.5903398],
  [-76.6748195, 45.5885313],
  [-76.6750565, 45.5864563],
  [-76.6747061, 45.584778],
  [-76.6730806, 45.5823098],
  [-76.6712119, 45.5784837],
  [-76.665397, 45.5703353],
  [-76.663605, 45.5668488],
  [-76.6590801, 45.5594443],
  [-76.6570894, 45.5580674],
  [-76.6521449, 45.5563125],
  [-76.6486744, 45.5541154],
  [-76.6423563, 45.5513877],
  [-76.6411282, 45.5505675],
  [-76.6384809, 45.5481377],
  [-76.6343306, 45.545578],
  [-76.6264263, 45.5418586],
  [-76.621917, 45.5401229],
  [-76.618332, 45.5379219],
  [-76.61641, 45.5370599],
  [-76.6123581, 45.5346298],
  [-76.6074072, 45.5320741],
  [-76.5969245, 45.5309678],
  [-76.5726853, 45.5274162],
  [-76.5490038, 45.5232048],
  [-76.5295036, 45.5185701],
  [-76.5189977, 45.5169641],
  [-76.5160598, 45.516735],
  [-76.5108255, 45.5168647],
  [-76.5025638, 45.5179101],
  [-76.5009689, 45.517807],
  [-76.4989173, 45.5172388],
  [-76.4968645, 45.5161401],
  [-76.4810948, 45.5050393],
  [-76.4758302, 45.5032806],
  [-76.4726711, 45.5024757],
  [-76.4635005, 45.5022316],
  [-76.4574741, 45.5014],
  [-76.4527964, 45.5001258],
  [-76.4433207, 45.49662],
  [-76.4320606, 45.4917298],
  [-76.4198145, 45.4851607],
  [-76.4172675, 45.483597],
  [-76.4081496, 45.4769859],
  [-76.3979191, 45.4704057],
  [-76.3951423, 45.4690095],
  [-76.3860163, 45.4651145],
  [-76.3725822, 45.4601289],
  [-76.3641354, 45.4578819],
  [-76.357902, 45.4570998],
  [-76.3517686, 45.4569206],
  [-76.3425676, 45.4572106],
  [-76.3257528, 45.4567758],
  [-76.3197706, 45.4570502],
  [-76.31517, 45.4576911],
  [-76.3127366, 45.4582672],
  [-76.3021476, 45.4626466],
  [-76.2989888, 45.4637452],
  [-76.2946554, 45.4649811],
  [-76.289749, 45.4659194],
  [-76.2833252, 45.4665756],
  [-76.2769997, 45.4667815],
  [-76.2729345, 45.4674492],
  [-76.2616427, 45.4681702],
  [-76.2506711, 45.4678496],
  [-76.2469408, 45.4679603],
  [-76.2446288, 45.4682464],
  [-76.2425848, 45.4687998],
  [-76.2408517, 45.4698981],
  [-76.2391125, 45.47332],
  [-76.2344892, 45.5053787],
  [-76.2339169, 45.5072326],
  [-76.233284, 45.5081825],
  [-76.2323382, 45.5088883],
  [-76.2305982, 45.5100403],
  [-76.2278137, 45.5114289],
  [-76.2172089, 45.5149994],
  [-76.2118384, 45.516388],
  [-76.2045894, 45.5176353],
  [-76.1921916, 45.5179901],
  [-76.1867215, 45.5176696],
  [-76.1814581, 45.5176966],
  [-76.1725544, 45.5160866],
  [-76.1668086, 45.515911],
  [-76.1510702, 45.516781],
  [-76.1413573, 45.5184555],
  [-76.1380617, 45.5187417],
  [-76.1148152, 45.5172768],
  [-76.0982517, 45.5173112],
  [-76.0915839, 45.5167467],
  [-76.0878903, 45.5156898],
  [-76.0813296, 45.5104142],
  [-76.0427323, 45.496254],
  [-76.0234068, 45.4902381],
  [-76.0143206, 45.4865914],
  [-76.0042185, 45.4841803],
  [-75.9913937, 45.4804725],
  [-75.9874266, 45.4797058],
  [-75.982246, 45.4790686],
  [-75.9796062, 45.4784888],
  [-75.9763481, 45.4774245],
  [-75.9736631, 45.476181],
  [-75.969558, 45.4733694],
  [-75.9678192, 45.4716111],
  [-75.9664537, 45.4697685],
  [-75.9631122, 45.466465],
  [-75.9531942, 45.457802],
  [-75.9450452, 45.4492073],
  [-75.9331967, 45.4340743],
  [-75.9274067, 45.4278566],
  [-75.9210888, 45.4218063],
  [-75.9077308, 45.4106256],
  [-75.902794, 45.4069634],
  [-75.8686748, 45.3844108],
  [-75.8552851, 45.3799591],
  [-75.8451159, 45.377388],
  [-75.8377831, 45.3740461],
  [-75.8254161, 45.3725966],
  [-75.8215794, 45.3723909],
  [-75.8172909, 45.3724669],
  [-75.8131176, 45.3728484],
  [-75.8096383, 45.373413],
  [-75.8056482, 45.3743513],
  [-75.8023759, 45.3753739],
  [-75.7915351, 45.3801118],
  [-75.7842708, 45.3841933],
  [-75.7793653, 45.3876838],
  [-75.7742534, 45.3922194],
  [-75.7696757, 45.3974799],
  [-75.7651218, 45.4046327],
  [-75.7626807, 45.4065744],
  [-75.760223, 45.4098243],
  [-75.757194, 45.4118498],
  [-75.753647, 45.413025],
  [-75.7514656, 45.4133265],
  [-75.747261, 45.4133568],
  [-75.7374504, 45.4152948],
  [-75.7346492, 45.4161567],
  [-75.7334143, 45.4160806],
  [-75.7299655, 45.4172059],
  [-75.7248686, 45.4172401],
  [-75.7239681, 45.4177131],
  [-75.7236405, 45.4215278],
  [-75.7226556, 45.4220274],
  [-75.7209625, 45.4220696],
  [-75.7195893, 45.4216113],
  [-75.7177816, 45.4215432],
  [-75.7095264, 45.4232979],
  [-75.7043235, 45.4269754],
  [-75.7028501, 45.4371489],
  [-75.6955267, 45.4487497],
  [-75.6903158, 45.4546701],
  [-75.6869583, 45.457058],
  [-75.6837006, 45.458706],
  [-75.6816024, 45.459465],
  [-75.6761625, 45.4605408],
  [-75.658272, 45.4628335],
  [-75.6365206, 45.4661445],
  [-75.6301579, 45.4677087],
  [-75.6233287, 45.4703673],
  [-75.6192473, 45.4714851],
  [-75.6148144, 45.4720039],
  [-75.5981058, 45.4721946],
  [-75.5889669, 45.472603],
  [-75.5816571, 45.4733314],
  [-75.5781396, 45.4739265],
  [-75.5705953, 45.476204],
  [-75.5673065, 45.4778862],
  [-75.5629125, 45.4808503],
  [-75.5582884, 45.483387],
  [-75.5546346, 45.4846727],
  [-75.5497129, 45.4858131],
  [-75.5475844, 45.4864998],
  [-75.5345611, 45.4933662],
  [-75.5209887, 45.4985581],
  [-75.511612, 45.50177],
  [-75.4923473, 45.5089645],
  [-75.4786065, 45.5134429],
  [-75.4681086, 45.5151595],
  [-75.4579625, 45.5183984],
  [-75.4503864, 45.5196153],
  [-75.4455331, 45.5206794],
  [-75.4338829, 45.5199469],
  [-75.4237673, 45.519661],
  [-75.4213021, 45.5199698],
  [-75.4191896, 45.5205078],
  [-75.4143829, 45.522705],
  [-75.4116056, 45.5233993],
  [-75.4092935, 45.5235403],
  [-75.4057001, 45.5232924],
  [-75.3974916, 45.5232659],
  [-75.3914268, 45.523594],
  [-75.3893961, 45.5242309],
  [-75.386757, 45.5258598],
  [-75.3838419, 45.5272101],
  [-75.3791662, 45.5287934],
  [-75.3729472, 45.5298232],
  [-75.3631817, 45.5319861],
  [-75.3522956, 45.5346529],
  [-75.34394, 45.5369414],
  [-75.3319473, 45.5406036],
  [-75.3193134, 45.5449524],
  [-75.312385, 45.5481147],
  [-75.3085405, 45.5501137],
  [-75.3057481, 45.5519218],
  [-75.3032079, 45.5543939],
  [-75.3006817, 45.5577583],
  [-75.2975991, 45.5598449],
  [-75.2536525, 45.5803175],
  [-75.2453715, 45.5850961],
  [-75.2384999, 45.5869751],
  [-75.2288597, 45.5882126],
  [-75.2154411, 45.5886466],
  [-75.2048456, 45.5883147],
  [-75.195333, 45.5861026],
  [-75.1787861, 45.578889],
  [-75.1753434, 45.5780359],
  [-75.1714806, 45.5775633],
  [-75.1653712, 45.5784618],
  [-75.1566682, 45.5791374],
  [-75.1471164, 45.5781923],
  [-75.1374891, 45.5775651],
  [-75.1299805, 45.5773348],
  [-75.1234051, 45.5778474],
  [-75.1150773, 45.5795039],
  [-75.108242, 45.5814797],
  [-75.0839369, 45.5900373],
  [-75.0766518, 45.5911337],
  [-75.0594747, 45.5910517],
  [-75.047917, 45.5919397],
  [-75.0380422, 45.5935296],
  [-75.0297852, 45.5954704],
  [-75.0191646, 45.598392],
  [-75.0073509, 45.6028102],
  [-75.0006975, 45.6056231],
  [-74.9769976, 45.6175386],
  [-74.9728539, 45.6199873],
  [-74.9694293, 45.6223069],
  [-74.9669877, 45.6242981],
  [-74.964447, 45.6268959],
  [-74.9575276, 45.6352044],
  [-74.9540862, 45.6383247],
  [-74.9513014, 45.6402244],
  [-74.948601, 45.6417237],
  [-74.9458249, 45.6429168],
  [-74.9410415, 45.6445072],
  [-74.9356682, 45.6454052],
  [-74.924532, 45.6457067],
  [-74.9032162, 45.6443132],
  [-74.8839802, 45.643383],
  [-74.8757118, 45.6424307],
  [-74.8665659, 45.6407309],
  [-74.8448452, 45.6379307],
  [-74.8377486, 45.6377132],
  [-74.82807, 45.6377718],
  [-74.8072102, 45.6400195],
  [-74.806639, 45.6425412],
  [-74.7967464, 45.6735809],
  [-74.7711147, 45.760845],
  [-74.7699387, 45.7639714],
  [-74.757744, 45.7951136],
  [-74.7406549, 45.8347927],
  [-74.7352761, 45.8522537],
  [-74.7264554, 45.852364],
  [-74.7250007, 45.8598229],
  [-74.7226226, 45.8682582],
  [-74.7150043, 45.888336],
  [-74.7137027, 45.9038491],
  [-74.7120535, 45.9146085],
  [-74.7103911, 45.9316161],
  [-74.7066003, 45.9458385],
  [-74.69876, 45.9717762],
  [-74.763146, 45.9726754],
  [-74.7630688, 45.9457257],
  [-74.8012697, 45.945147],
  [-74.8013334, 45.9454971],
  [-74.800635, 45.9457945],
  [-74.8005492, 45.9460643],
  [-74.8016372, 45.9466025],
  [-74.801481, 45.9472593],
  [-74.8009399, 45.9474098],
  [-74.8004862, 45.9467246],
  [-74.7999589, 45.9467269],
  [-74.799436, 45.9471439],
  [-74.7993021, 45.9477136],
  [-74.7982098, 45.9477512],
  [-74.7979219, 45.9479784],
  [-74.7980311, 45.9481503],
  [-74.7995738, 45.9480602],
  [-74.7998988, 45.9589688],
  [-74.8064651, 45.958619],
  [-74.8089013, 45.9730242],
  [-74.831663, 45.9731208],
  [-74.8315408, 45.9994845],
  [-74.8312965, 45.9999987],
  [-74.8311838, 46.0028341],
  [-74.8370455, 46.0028193],
  [-74.8370871, 46.0168828],
  [-74.8582261, 46.0166695],
  [-74.8586945, 46.0629749],
  [-74.8671371, 46.0628742],
  [-74.8686286, 46.063909],
  [-74.8700024, 46.0642159],
  [-74.8720802, 46.0639902],
  [-74.8744774, 46.0627861],
  [-74.9123099, 46.0623069],
  [-74.9390906, 46.0622877],
  [-74.9394339, 46.1058114],
  [-75.0154132, 46.105632],
  [-75.0280386, 46.1031861],
  [-75.0899043, 46.1032506],
  [-75.0863237, 46.1319943],
  [-75.0800421, 46.1649013],
  [-75.0777418, 46.1928459],
  [-75.07523, 46.2152867],
  [-75.1173883, 46.2152048],
  [-75.1165582, 46.1847826],
  [-75.1376435, 46.1849461],
  [-75.1372786, 46.1605682],
  [-75.1563078, 46.1606038],
  [-75.1559317, 46.1322957],
  [-75.1769785, 46.1323045],
  [-75.1769817, 46.1229099],
  [-75.1763605, 46.1034091],
  [-75.2106331, 46.1032551],
  [-75.2106398, 46.1041349],
  [-75.21306, 46.1042317],
  [-75.2131814, 46.0980696],
  [-75.4083028, 46.0976167],
  [-75.4082859, 45.9731774],
  [-75.5026126, 45.9727367],
  [-75.5515984, 45.9719301],
  [-75.5733608, 45.9718165],
  [-75.5747983, 45.9722087],
  [-75.5761094, 45.9722464],
  [-75.5771287, 45.9720861],
  [-75.5780825, 45.9713853],
  [-75.5900334, 45.9715408],
  [-75.6000234, 45.9713882],
  [-75.5998748, 45.994401],
  [-75.6203748, 45.9944166],
  [-75.6204366, 45.9823962],
  [-75.6418717, 45.9822262],
  [-75.6624788, 45.98246],
  [-75.6624422, 45.9751516],
  [-75.661455, 45.975153],
  [-75.6611233, 45.9747512],
  [-75.6617617, 45.9737621],
  [-75.6636614, 45.9726802],
  [-75.6649117, 45.9707271],
  [-75.6655465, 45.9703962],
  [-75.7215601, 45.970011],
  [-75.7376607, 45.9702455],
  [-75.743307, 45.9727839],
  [-75.7457535, 45.9730003],
  [-75.747596, 45.972612],
  [-75.7482164, 45.9720153],
  [-75.7512843, 45.9670294],
  [-75.7534568, 45.964841],
  [-75.7562424, 45.9625763],
  [-75.7624219, 45.9582911],
  [-75.7664248, 45.9565147],
  [-75.7772472, 45.9528899],
  [-75.779107, 45.9543726],
  [-75.7799352, 45.9561594],
  [-75.7808758, 45.957245],
  [-75.783755, 45.9590716],
  [-75.784676, 45.9592754],
  [-75.7850649, 45.9617539],
  [-75.7844403, 45.9619552],
  [-75.7839952, 45.9624563],
  [-75.7848112, 45.9632082],
  [-75.7848531, 45.9639643],
  [-75.7837257, 45.9643441],
  [-75.7784906, 45.9640952],
  [-75.7772965, 45.9646366],
  [-75.7766642, 45.9646416],
  [-75.774872, 45.9675185],
  [-75.7746756, 45.9707024],
  [-75.7751869, 45.9710746],
  [-75.7752815, 45.971732],
  [-75.7759455, 45.9722761],
  [-75.7781161, 45.9720649],
  [-75.7784428, 45.971752],
  [-75.7783791, 45.9706899],
  [-75.7792401, 45.9699755],
  [-75.7814535, 45.9694406],
  [-75.7827746, 45.9700607],
  [-75.7827873, 45.9720763],
  [-75.7822171, 45.9732604],
  [-75.7824564, 45.9737118],
  [-75.7815005, 45.9737867],
  [-75.7804362, 45.9742659],
  [-75.779148, 45.9740869],
  [-75.7783332, 45.9742257],
  [-75.7780966, 45.9745571],
  [-75.7782398, 45.9764475],
  [-75.7774312, 45.9770992],
  [-75.7773481, 45.9775396],
  [-75.7760749, 45.9771986],
  [-75.7753445, 45.9777878],
  [-75.775352, 45.9782018],
  [-75.7758273, 45.9783668],
  [-75.7759519, 45.9787095],
  [-75.7765583, 45.9787223],
  [-75.7778363, 45.9796931],
  [-75.7794595, 45.9799193],
  [-75.7804032, 45.9797993],
  [-75.7809515, 45.9803156],
  [-75.7812104, 45.9802543],
  [-75.7812146, 45.9809291],
  [-75.7803794, 45.9816347],
  [-75.7799726, 45.9811552],
  [-75.7794981, 45.9819171],
  [-75.7782917, 45.9823863],
  [-75.777943, 45.9824021],
  [-75.7776762, 45.9820765],
  [-75.7774947, 45.9811486],
  [-75.7770323, 45.9809747],
  [-75.7767857, 45.9810811],
  [-75.776614, 45.981386],
  [-75.7769102, 45.9814328],
  [-75.7764671, 45.9817629],
  [-75.7765469, 45.9825732],
  [-75.7751754, 45.9838242],
  [-75.7749824, 45.9847678],
  [-75.7751533, 45.9855157],
  [-75.7759967, 45.9851791],
  [-75.7763254, 45.985703],
  [-75.7746451, 45.9858813],
  [-75.7739806, 45.9853732],
  [-75.773594, 45.9853258],
  [-75.7731513, 45.9856199],
  [-75.7726913, 45.9852661],
  [-75.7722267, 45.9852631],
  [-75.771471, 45.9857982],
  [-75.7715174, 45.9852136],
  [-75.7710087, 45.9846345],
  [-75.7699671, 45.9853207],
  [-75.7686358, 45.9854651],
  [-75.7680243, 45.9858301],
  [-75.7672854, 45.9870491],
  [-75.7655111, 45.9875056],
  [-75.7641041, 45.9884773],
  [-75.7638112, 45.9891502],
  [-75.7629117, 45.9898192],
  [-75.7625271, 45.9905906],
  [-75.7601176, 45.9912318],
  [-75.759323, 45.9917665],
  [-75.7588753, 45.9924294],
  [-75.757745, 45.992971],
  [-75.7570747, 45.9938574],
  [-75.7575471, 45.9942564],
  [-75.7576558, 45.9948149],
  [-75.7566151, 45.9944392],
  [-75.7560341, 45.9944445],
  [-75.7492189, 45.9981969],
  [-75.747873, 45.9993848],
  [-75.7466376, 46.0009962],
  [-75.7479027, 46.0019583],
  [-75.7494546, 46.0027243],
  [-75.7500007, 46.0033937],
  [-75.7494831, 46.0034803],
  [-75.7488805, 46.0031704],
  [-75.7483996, 46.0034012],
  [-75.7482227, 46.0031211],
  [-75.7474764, 46.0029272],
  [-75.7445036, 46.0031595],
  [-75.7437071, 46.0038201],
  [-75.7428433, 46.0037244],
  [-75.7420785, 46.0039443],
  [-75.7426144, 46.0044157],
  [-75.7409161, 46.0049173],
  [-75.7402478, 46.0056327],
  [-75.7404322, 46.0063087],
  [-75.7411677, 46.0063406],
  [-75.7418454, 46.006831],
  [-75.7425662, 46.0069977],
  [-75.7438219, 46.0067631],
  [-75.7451633, 46.0068709],
  [-75.7457382, 46.0073246],
  [-75.745515, 46.0075931],
  [-75.7436894, 46.0079589],
  [-75.742683, 46.0078983],
  [-75.7413443, 46.0075925],
  [-75.7400611, 46.0070081],
  [-75.7391852, 46.007794],
  [-75.7381873, 46.0080573],
  [-75.7380301, 46.0082182],
  [-75.7383094, 46.008571],
  [-75.736487, 46.0086938],
  [-75.7355, 46.0091011],
  [-75.7369174, 46.0102623],
  [-75.7361655, 46.0104732],
  [-75.7347462, 46.0103917],
  [-75.7335837, 46.0113467],
  [-75.7337909, 46.0122388],
  [-75.7312954, 46.012474],
  [-75.7300165, 46.0134372],
  [-75.7295761, 46.0135332],
  [-75.727993, 46.0131536],
  [-75.7277335, 46.0132418],
  [-75.7268857, 46.0138389],
  [-75.7275173, 46.0148509],
  [-75.7272017, 46.0152627],
  [-75.725139, 46.0150058],
  [-75.7229663, 46.0161517],
  [-75.7199605, 46.0168331],
  [-75.7183003, 46.0155081],
  [-75.7169076, 46.0153636],
  [-75.7163222, 46.0156655],
  [-75.715756, 46.0164354],
  [-75.7095279, 46.0184799],
  [-75.7094021, 46.0182451],
  [-75.7089504, 46.018224],
  [-75.708609, 46.0186175],
  [-75.7070924, 46.0190029],
  [-75.7074974, 46.0195816],
  [-75.70668, 46.0198458],
  [-75.7058915, 46.0207941],
  [-75.7038383, 46.0216706],
  [-75.7029118, 46.02233],
  [-75.7022915, 46.0223526],
  [-75.7018066, 46.0219443],
  [-75.7007081, 46.0219906],
  [-75.6994996, 46.0224951],
  [-75.6992223, 46.022916],
  [-75.6993718, 46.0232992],
  [-75.7287209, 46.0230289],
  [-75.7405104, 46.0232338],
  [-75.7446346, 46.0229462],
  [-75.7505102, 46.0230299],
  [-75.7634911, 46.0227343],
  [-75.7629707, 46.0330083],
  [-75.76314, 46.0387409],
  [-75.7633285, 46.0420532],
  [-75.764535, 46.0504468],
  [-75.7654706, 46.0617538],
  [-75.7654042, 46.0862447],
  [-75.7657297, 46.0948484],
  [-75.7673326, 46.1144283],
  [-75.7673005, 46.1275914],
  [-75.7680369, 46.1511244],
  [-75.7683446, 46.1738354],
  [-75.7903108, 46.1738647],
  [-75.7946445, 46.170344],
  [-75.7973366, 46.1723281],
  [-75.7990135, 46.1741216],
  [-75.8002117, 46.1780189],
  [-75.800076, 46.1786521],
  [-75.7987871, 46.1798506],
  [-75.797385, 46.1800993],
  [-75.7971815, 46.1810039],
  [-75.7980634, 46.1822928],
  [-75.8001439, 46.1834461],
  [-75.8018625, 46.1831974],
  [-75.8036264, 46.1826773],
  [-75.8053902, 46.1830165],
  [-75.8061484, 46.1840386],
  [-75.8072278, 46.1875553],
  [-75.8099778, 46.193466],
  [-75.8102996, 46.1953826],
  [-75.8103324, 46.1973725],
  [-75.8096724, 46.2017421],
  [-75.8105473, 46.2055391],
  [-75.8101048, 46.2086523],
  [-75.8077689, 46.2110221],
  [-75.8066099, 46.2114843],
  [-75.8047037, 46.2117731],
  [-75.8042372, 46.2121832],
  [-75.8024775, 46.2168276],
  [-75.8027559, 46.2176177],
  [-75.8037671, 46.2185905],
  [-75.805426, 46.2196518],
  [-75.8100767, 46.2212567],
  [-75.8117333, 46.2225055],
  [-75.8131449, 46.2249167],
  [-75.8132795, 46.2279959],
  [-75.8128435, 46.2305837],
  [-75.8119433, 46.2333938],
  [-75.8108837, 46.234784],
  [-75.8027615, 46.2410905],
  [-75.7988462, 46.2450459],
  [-75.7981682, 46.2461868],
  [-75.797379, 46.2492603],
  [-75.7975584, 46.2532783],
  [-75.7971296, 46.2552278],
  [-75.7958166, 46.258636],
  [-75.7942808, 46.2614046],
  [-75.7925799, 46.2635339],
  [-75.7901275, 46.2679861],
  [-75.7886642, 46.2740211],
  [-75.7881477, 46.2749095],
  [-75.7854096, 46.2768446],
  [-75.7841242, 46.2780379],
  [-75.7832955, 46.2796093],
  [-75.7830332, 46.2820103],
  [-75.7832965, 46.2839641],
  [-75.7840185, 46.286146],
  [-75.7854926, 46.28822],
  [-75.7883587, 46.2909314],
  [-75.787905, 46.2925241],
  [-75.7883547, 46.2934655],
  [-75.7899355, 46.2940196],
  [-75.7938235, 46.294757],
  [-75.7949444, 46.2951581],
  [-75.8004467, 46.2983828],
  [-75.8026566, 46.2994288],
  [-75.8049027, 46.2999116],
  [-75.8067507, 46.2999041],
  [-75.8076128, 46.3002284],
  [-75.808186, 46.3005698],
  [-75.8084324, 46.3016412],
  [-75.8088302, 46.3021504],
  [-75.8125239, 46.3046504],
  [-75.8145703, 46.3063511],
  [-75.7783853, 46.307289],
  [-75.7525258, 46.3084524],
  [-75.7070422, 46.3091658],
  [-75.7066646, 46.3171754],
  [-75.7071048, 46.3298853],
  [-75.7075798, 46.3761626],
  [-75.7073146, 46.4077322],
  [-75.7068823, 46.4180325],
  [-75.7058431, 46.4320267],
  [-75.7060041, 46.4388164],
  [-75.7179026, 46.4388472],
  [-75.745214, 46.438257],
  [-75.74571, 46.4626733],
  [-75.7452682, 46.4671585],
  [-75.745531, 46.4674574],
  [-75.7458219, 46.4790712],
  [-75.7469822, 46.5378419],
  [-75.7466053, 46.5391643],
  [-75.7469311, 46.5519931],
  [-75.748277, 46.5670798],
  [-75.7489067, 46.5867645],
  [-75.7494382, 46.5939854],
  [-75.7501321, 46.6185035],
  [-75.750298, 46.6417372],
  [-75.7515336, 46.6637054],
  [-75.749265, 46.668755],
  [-75.7470607, 46.7121035],
  [-75.7469574, 46.8448244],
  [-75.8109411, 46.8448902],
  [-75.8118997, 46.8438201],
  [-75.8136874, 46.8409507],
  [-75.8141593, 46.8395343],
  [-75.8132297, 46.8361477],
  [-75.8134386, 46.8350219],
  [-75.8142991, 46.8336496],
  [-75.8178129, 46.8301226],
  [-75.8206283, 46.8279688],
  [-75.8258468, 46.8254535],
  [-75.8289727, 46.8243448],
  [-75.837743, 46.8228932],
  [-75.8422936, 46.821876],
  [-75.8462623, 46.8207299],
  [-75.8490693, 46.819118],
  [-75.8544331, 46.8155394],
  [-75.8565792, 46.8145272],
  [-75.8587552, 46.8141636],
  [-75.8617318, 46.8145977],
  [-75.8647027, 46.8155325],
  [-75.8673822, 46.8170441],
  [-75.8695055, 46.8186864],
  [-75.8714546, 46.8191222],
  [-75.8729071, 46.8189441],
  [-75.8759475, 46.817149],
  [-75.880358, 46.8164553],
  [-75.8815608, 46.8157677],
  [-75.8834692, 46.8155068],
  [-75.8871583, 46.8167374],
  [-75.8950373, 46.8201933],
  [-75.9028624, 46.8253816],
  [-75.9093812, 46.8309964],
  [-75.9123627, 46.8348059],
  [-75.9168196, 46.8397945],
  [-75.9220381, 46.8468687],
  [-75.9265931, 46.8508983],
  [-75.9532343, 46.8692092],
  [-75.958981, 46.8728059],
  [-75.9689504, 46.8782126],
  [-75.971825, 46.880762],
  [-75.9807826, 46.8808744],
  [-75.9882821, 46.881898],
  [-75.9940351, 46.8868678],
  [-76.0019819, 46.8875686],
  [-76.0075754, 46.8869468],
  [-76.0206722, 46.8860833],
  [-76.0185898, 46.8896841],
  [-76.0205808, 46.8957537],
  [-76.029787, 46.8941939],
  [-76.0372509, 46.8895328],
  [-76.0451592, 46.8914696],
  [-76.0505937, 46.894925],
  [-76.0536056, 46.8974948],
  [-76.050983, 46.9010501],
  [-76.0537148, 46.9027402],
  [-76.0582692, 46.9019022],
  [-76.0642299, 46.9004137],
  [-76.0686095, 46.9015587],
  [-76.0767757, 46.905327],
  [-76.0807179, 46.9063953],
  [-76.083792, 46.9055228],
  [-76.0860545, 46.9070982],
  [-76.0847269, 46.9096804],
  [-76.0863111, 46.9109462],
  [-76.0937301, 46.9108115],
  [-76.0981715, 46.9133079],
  [-76.0984903, 46.9142313],
  [-76.098318, 46.9144389],
  [-76.0988019, 46.9153932],
  [-76.0999465, 46.9155599],
  [-76.1015093, 46.9152941],
  [-76.1031829, 46.9133779],
  [-76.1059428, 46.9115872],
  [-76.1094962, 46.9128264],
  [-76.1127799, 46.9171987],
  [-76.1162378, 46.919204],
  [-76.1233123, 46.9206368],
  [-76.1287975, 46.920308],
  [-76.1328167, 46.9283859],
  [-76.1342184, 46.9354676],
  [-76.1376843, 46.9374572],
  [-76.136693, 46.9417921],
  [-76.1366305, 46.9472785],
  [-76.1381588, 46.949716],
  [-76.1349695, 46.9531758],
  [-76.1376199, 46.9614327],
  [-76.133578, 46.9666952],
  [-76.1342454, 46.9705823],
  [-76.1399607, 46.9772436],
  [-76.1383699, 46.9800373],
  [-76.1318929, 46.9822744],
  [-76.1303568, 46.9849179],
  [-76.1318954, 46.987837],
  [-76.1358744, 46.9903466],
  [-76.1427373, 46.996244],
  [-76.1419347, 46.997727],
  [-76.1398553, 46.9990579],
  [-76.1383763, 47.0010402],
  [-76.1492607, 47.0062438],
  [-76.1445042, 47.0090033],
  [-76.1439013, 47.0119042],
  [-76.138777, 47.0126181],
  [-76.1351361, 47.0171832],
  [-76.1322429, 47.0180426],
  [-76.1292192, 47.0205265],
  [-76.1238725, 47.021585],
  [-76.1202578, 47.0209923],
  [-76.1157122, 47.0251943],
  [-76.1084438, 47.0281581],
  [-76.1075543, 47.0305623],
  [-76.1042543, 47.0308867],
  [-76.1052935, 47.0341543],
  [-76.1030502, 47.036333],
  [-76.1021123, 47.0376615],
  [-76.1015381, 47.0425184],
  [-76.1026043, 47.0446286],
  [-76.1022514, 47.0472036],
  [-76.0987478, 47.0513645],
  [-76.0982839, 47.0544256],
  [-76.0986517, 47.056052],
  [-76.0996067, 47.0575274],
  [-76.0999809, 47.062754],
  [-76.1004704, 47.0655025],
  [-76.098996, 47.0695296],
  [-76.0993649, 47.0747714],
  [-76.0978682, 47.0816799],
  [-76.0983196, 47.0879466],
  [-76.0974483, 47.0920142],
  [-76.0973564, 47.0945373],
  [-76.0987082, 47.1008014],
  [-76.0984676, 47.1041392],
  [-76.0952601, 47.1168597],
  [-76.0945513, 47.1256783],
  [-76.0940982, 47.1272738],
  [-76.0920653, 47.1293858],
  [-76.0911763, 47.1312477],
  [-76.0927024, 47.1400102],
  [-76.0950555, 47.1471796],
  [-76.0977476, 47.1491629],
  [-76.0998284, 47.1527946],
  [-76.0987901, 47.1550412],
  [-76.1007411, 47.162403],
  [-76.100984, 47.1643417],
  [-76.0985365, 47.1675911],
  [-76.0990842, 47.1692953],
  [-76.1008181, 47.1705696],
  [-76.1007947, 47.1711012],
  [-76.0958105, 47.1862827],
  [-76.0918546, 47.1910426],
  [-76.0688062, 47.1945514],
  [-76.0568503, 47.1990226],
  [-76.0421149, 47.1998212],
  [-76.0323561, 47.1976388],
  [-76.0226145, 47.1936646],
  [-76.0098896, 47.1995528],
  [-76.0080398, 47.2002794],
  [-76.006201, 47.2005507],
  [-76.0027025, 47.2019671],
  [-75.9884877, 47.2100723],
  [-75.9859835, 47.2110478],
  [-75.9829542, 47.2119199],
  [-75.9783271, 47.2126431],
  [-75.9772136, 47.2131296],
  [-75.9759592, 47.2131402],
  [-75.9708729, 47.2150756],
  [-75.9645527, 47.2179634],
  [-75.9580076, 47.220154],
  [-75.9576244, 47.2199572],
  [-75.9553638, 47.2215835],
  [-75.9548109, 47.2216871],
  [-75.9541326, 47.2221607],
  [-75.9533312, 47.2238771],
  [-75.9518907, 47.2257336],
  [-75.9525985, 47.2291338],
  [-75.9484742, 47.2310032],
  [-75.9426826, 47.2365345],
  [-75.9396207, 47.2379063],
  [-75.9367797, 47.2382951],
  [-75.9267663, 47.2432228],
  [-75.9195396, 47.2491257],
  [-75.9186605, 47.250013],
  [-75.9181369, 47.2518348],
  [-75.916632, 47.2527018],
  [-75.9167691, 47.2529886],
  [-75.9162304, 47.2539377],
  [-75.9133428, 47.2558685],
  [-75.911699, 47.2578882],
  [-75.9101758, 47.2584762],
  [-75.9085153, 47.2595332],
  [-75.9063389, 47.262124],
  [-75.9038469, 47.2640424],
  [-75.90304, 47.2655694],
  [-75.9023476, 47.2660518],
  [-75.9018072, 47.2661461],
  [-75.9004013, 47.2658692],
  [-75.8983254, 47.2674062],
  [-75.8962435, 47.2678094],
  [-75.895146, 47.2685739],
  [-75.8938994, 47.2699154],
  [-75.8939106, 47.2705812],
  [-75.8948968, 47.2710504],
  [-75.8947678, 47.2712404],
  [-75.8936571, 47.2720139],
  [-75.8918556, 47.2725948],
  [-75.8887974, 47.2747152],
  [-75.8888182, 47.2759569],
  [-75.8882577, 47.2764381],
  [-75.886866, 47.2770248],
  [-75.8868723, 47.2774027],
  [-75.8865846, 47.2775939],
  [-75.8851786, 47.2773258],
  [-75.8843388, 47.2777102],
  [-75.8835486, 47.2802809],
  [-75.8827036, 47.2811512],
  [-75.8786591, 47.2836568],
  [-75.8777043, 47.2850949],
  [-75.8746418, 47.287026],
  [-75.8720052, 47.2891427],
  [-75.8707452, 47.2897282],
  [-75.867685, 47.291839],
  [-75.8664233, 47.2923255],
  [-75.8632063, 47.2929703],
  [-75.8582539, 47.297489],
  [-75.858263, 47.2980558],
  [-75.8596467, 47.2999527],
  [-75.8587741, 47.3015594],
  [-75.8546786, 47.3021679],
  [-75.85426, 47.302459],
  [-75.853008, 47.3027562],
  [-75.8456452, 47.3064191],
  [-75.8385625, 47.3102775],
  [-75.8353075, 47.3077367],
  [-75.834205, 47.3074568],
  [-75.8289276, 47.3109235],
  [-75.8230864, 47.313908],
  [-75.8211373, 47.3144979],
  [-75.8201524, 47.314127],
  [-75.8183266, 47.3141401],
  [-75.810637, 47.3148516],
  [-75.8056253, 47.3167946],
  [-75.8022748, 47.317484],
  [-75.7993376, 47.3175045],
  [-75.7970958, 47.3171421],
  [-75.7941401, 47.315921],
  [-75.7898742, 47.312909],
  [-75.7855354, 47.3120749],
  [-75.7848258, 47.3115129],
  [-75.7842392, 47.3094204],
  [-75.7826745, 47.3082882],
  [-75.7705742, 47.304087],
  [-75.7680446, 47.3029612],
  [-75.7656431, 47.3015556],
  [-75.7604907, 47.2992502],
  [-75.7557619, 47.2960061],
  [-75.7542078, 47.2955395],
  [-75.7502889, 47.2952773],
  [-75.7478976, 47.294528],
  [-75.7412635, 47.290198],
  [-75.7401454, 47.2896791],
  [-75.7401426, 47.2885405],
  [-75.7406847, 47.2875832],
  [-75.742164, 47.2827235],
  [-75.7422722, 47.2800593],
  [-75.741062, 47.2748524],
  [-75.7379659, 47.2732728],
  [-75.7353069, 47.2723775],
  [-75.7279732, 47.270859],
  [-75.7138922, 47.2704369],
  [-75.7116737, 47.2731417],
  [-75.7106384, 47.2753375],
  [-75.7111434, 47.2769526],
  [-75.711885, 47.2817513],
  [-75.7111346, 47.2857928],
  [-75.7085796, 47.2869572],
  [-75.7029991, 47.2833454],
  [-75.6962724, 47.2804057],
  [-75.6908717, 47.2798596],
  [-75.690577, 47.2795006],
  [-75.6901539, 47.2795032],
  [-75.6891842, 47.2801749],
  [-75.6873461, 47.2791421],
  [-75.6780229, 47.284587],
  [-75.6635472, 47.2865232],
  [-75.6572238, 47.2841044],
  [-75.6513378, 47.2831337],
  [-75.6498183, 47.2822045],
  [-75.6501888, 47.2779102],
  [-75.6499077, 47.2776329],
  [-75.6499116, 47.276868],
  [-75.6492015, 47.2761072],
  [-75.6491946, 47.2755403],
  [-75.6507107, 47.2741009],
  [-75.6508281, 47.2718147],
  [-75.6504004, 47.2714392],
  [-75.6505356, 47.2695308],
  [-75.6500986, 47.2683905],
  [-75.650223, 47.2666711],
  [-75.6504983, 47.2664805],
  [-75.6496417, 47.2656216],
  [-75.6490528, 47.2628624],
  [-75.6486264, 47.2625858],
  [-75.6491439, 47.2573368],
  [-75.6487094, 47.2563945],
  [-75.6485503, 47.2541908],
  [-75.6478372, 47.253151],
  [-75.6469976, 47.2525798],
  [-75.6426389, 47.2506067],
  [-75.6409469, 47.2494734],
  [-75.6382495, 47.2460511],
  [-75.6379503, 47.244244],
  [-75.6363736, 47.2416793],
  [-75.635638, 47.2387318],
  [-75.6312958, 47.2348631],
  [-75.629856, 47.2324649],
  [-75.6287218, 47.2315173],
  [-75.628572, 47.2311402],
  [-75.6252018, 47.228765],
  [-75.6243542, 47.2285807],
  [-75.6235153, 47.2280093],
  [-75.623786, 47.2274409],
  [-75.6265506, 47.2255182],
  [-75.6284936, 47.2222681],
  [-75.6290324, 47.2209244],
  [-75.6290189, 47.2197817],
  [-75.6293028, 47.2192132],
  [-75.6292733, 47.2167208],
  [-75.6289859, 47.2158675],
  [-75.6271418, 47.2129261],
  [-75.6275363, 47.2105394],
  [-75.6272556, 47.2102529],
  [-75.6271101, 47.209111],
  [-75.6280725, 47.2078639],
  [-75.6280602, 47.2068201],
  [-75.6279104, 47.206434],
  [-75.6263709, 47.2057676],
  [-75.6253837, 47.2049181],
  [-75.6249377, 47.2029139],
  [-75.6238074, 47.2011113],
  [-75.6238095, 47.2001575],
  [-75.6246345, 47.1995861],
  [-75.6278482, 47.2000454],
  [-75.6295234, 47.1987944],
  [-75.629388, 47.1985072],
  [-75.6300764, 47.1975496],
  [-75.6293699, 47.1969776],
  [-75.629362, 47.1963117],
  [-75.6308764, 47.1948727],
  [-75.6311663, 47.1903718],
  [-75.6304598, 47.1864524],
  [-75.6296113, 47.1850173],
  [-75.6298899, 47.1829101],
  [-75.6283712, 47.1806344],
  [-75.6201116, 47.1822976],
  [-75.6091271, 47.1818048],
  [-75.5956888, 47.184551],
  [-75.5903454, 47.190729],
  [-75.5868105, 47.1936265],
  [-75.5785074, 47.1989011],
  [-75.5758522, 47.2032317],
  [-75.5744754, 47.2068147],
  [-75.5674356, 47.2073598],
  [-75.5604156, 47.2067066],
  [-75.5574489, 47.2109817],
  [-75.5550053, 47.213323],
  [-75.5485437, 47.2177995],
  [-75.5411672, 47.2257916],
  [-75.5385338, 47.2300514],
  [-75.5345474, 47.2332434],
  [-75.532515, 47.2370851],
  [-75.5285076, 47.2429018],
  [-75.5243025, 47.2466554],
  [-75.5228334, 47.2507485],
  [-75.5216345, 47.2527119],
  [-75.5179048, 47.2543728],
  [-75.5156088, 47.256891],
  [-75.5126056, 47.2582909],
  [-75.5061621, 47.2588072],
  [-75.5032295, 47.2613944],
  [-75.5015885, 47.2653517],
  [-75.4977717, 47.2673293],
  [-75.494453, 47.2701554],
  [-75.4900336, 47.2717489],
  [-75.4843182, 47.2734145],
  [-75.4789318, 47.2771183],
  [-75.4741564, 47.2827082],
  [-75.4728463, 47.287727],
  [-75.4709801, 47.2892705],
  [-75.4684546, 47.2932846],
  [-75.4689701, 47.2965751],
  [-75.4685296, 47.3013696],
  [-75.4669119, 47.3036177],
  [-75.4663812, 47.3034962],
  [-75.4660471, 47.3051446],
  [-75.4630404, 47.3104585],
  [-75.4602138, 47.3123813],
  [-75.4589432, 47.3176807],
  [-75.46224, 47.3244671],
  [-75.4645141, 47.3267041],
  [-75.4692229, 47.3290437],
  [-75.4737602, 47.3346634],
  [-75.4746488, 47.3393621],
  [-75.4719728, 47.3471742],
  [-75.4663148, 47.358111],
  [-75.462095, 47.365033],
  [-75.4582991, 47.3723784],
  [-75.4371538, 47.3839848],
  [-75.4297098, 47.3907829],
  [-75.4279561, 47.3947825],
  [-75.4239084, 47.3979027],
  [-75.4226698, 47.4014873],
  [-75.4152533, 47.4077964],
  [-75.4087077, 47.4124718],
  [-75.3984329, 47.4213531],
  [-75.3922144, 47.4249782],
  [-75.3882939, 47.4292702],
  [-75.3872062, 47.4351808],
  [-75.3843432, 47.4391822],
  [-75.3848226, 47.4487423],
  [-75.3823731, 47.4539008],
  [-75.3828025, 47.4596573],
  [-75.3795255, 47.4674336],
  [-75.3744264, 47.4741209],
  [-75.3678704, 47.4796908],
  [-75.3690406, 47.4833496],
  [-75.3637051, 47.4873082],
  [-75.3623779, 47.4894887],
  [-75.3576151, 47.4899682],
  [-75.3534895, 47.4947593],
  [-75.3521316, 47.4983244],
  [-75.3501083, 47.5000799],
  [-75.350858, 47.5000801],
  [-75.349826, 47.5011604],
  [-75.3492822, 47.5032586],
  [-75.3488617, 47.5039257],
  [-75.3440903, 47.509366],
  [-75.3387649, 47.5134849],
  [-75.328727, 47.5241534],
  [-75.3232305, 47.5306884],
  [-75.3180283, 47.5392429],
  [-75.3081159, 47.5465891],
  [-75.308501, 47.5486281],
  [-75.310474, 47.5525483],
  [-75.3126131, 47.553555],
  [-75.3136237, 47.5536422],
  [-75.316697, 47.5519241],
  [-75.3185311, 47.5519191],
  [-75.3197972, 47.5524824],
  [-75.3209339, 47.553622],
  [-75.3236136, 47.5549461],
  [-75.3248834, 47.5560943],
  [-75.3268722, 47.5574203],
  [-75.3278623, 47.5584612],
  [-75.3284289, 47.5597913],
  [-75.329992, 47.5610285],
  [-75.3311121, 47.5615922],
  [-75.3366162, 47.5616841],
  [-75.3394399, 47.5625306],
  [-75.3414203, 47.5645222],
  [-75.3418531, 47.5656637],
  [-75.343557, 47.568034],
  [-75.3442723, 47.5696515],
  [-75.3449801, 47.5701353],
  [-75.3458196, 47.5704207],
  [-75.3470953, 47.5703178],
  [-75.3491896, 47.5693577],
  [-75.3508882, 47.5688756],
  [-75.3515897, 47.5683966],
  [-75.3524303, 47.5668734],
  [-75.3538198, 47.5659063],
  [-75.3560899, 47.5654314],
  [-75.3583457, 47.5667561],
  [-75.3611857, 47.5698874],
  [-75.3641126, 47.5737829],
  [-75.3656178, 47.5814168],
  [-75.3659992, 47.5858833],
  [-75.3653715, 47.5949614],
  [-75.361521, 47.5989953],
  [-75.3594379, 47.6024331],
  [-75.3538507, 47.608253],
  [-75.3554673, 47.6130548],
  [-75.3591788, 47.6157513],
  [-75.3654931, 47.6183622],
  [-75.366946, 47.6224801],
  [-75.3697945, 47.626104],
  [-75.3798808, 47.6202098],
  [-75.3809207, 47.6168776],
  [-75.3825564, 47.6134718],
  [-75.3868279, 47.6113392],
  [-75.3887329, 47.6088614],
  [-75.3903873, 47.6041285],
  [-75.3938317, 47.6022311],
  [-75.3957183, 47.6002237],
  [-75.3959899, 47.5957845],
  [-75.3914751, 47.5933517],
  [-75.3908746, 47.5904589],
  [-75.3923429, 47.5863696],
  [-75.3972889, 47.5871884],
  [-75.4000761, 47.5854328],
  [-75.4056703, 47.585702],
  [-75.4136489, 47.5865218],
  [-75.4165153, 47.5901016],
  [-75.4179277, 47.5960368],
  [-75.4229333, 47.5990915],
  [-75.4228214, 47.5993157],
  [-75.4233855, 47.5999795],
  [-75.4241443, 47.6066351],
  [-75.4250019, 47.6090253],
  [-75.4264215, 47.6101628],
  [-75.4272901, 47.6122561],
  [-75.428713, 47.6137714],
  [-75.4290041, 47.615201],
  [-75.4285735, 47.6162463],
  [-75.4263448, 47.618738],
  [-75.4252284, 47.6205507],
  [-75.4234049, 47.6221771],
  [-75.421734, 47.6245676],
  [-75.4207434, 47.625525],
  [-75.4192232, 47.6284819],
  [-75.4193639, 47.6294351],
  [-75.4199321, 47.6305758],
  [-75.4209262, 47.6317059],
  [-75.4214976, 47.6332424],
  [-75.4261773, 47.6357895],
  [-75.4294355, 47.6386386],
  [-75.430015, 47.6394912],
  [-75.4324239, 47.6410118],
  [-75.4342652, 47.6431013],
  [-75.4354183, 47.6456703],
  [-75.4416589, 47.6499384],
  [-75.4432361, 47.6522177],
  [-75.445219, 47.6536317],
  [-75.4460669, 47.6546812],
  [-75.4462247, 47.6560122],
  [-75.4459561, 47.6588745],
  [-75.4468361, 47.6621103],
  [-75.448817, 47.6632453],
  [-75.4492498, 47.6640084],
  [-75.4502419, 47.6647603],
  [-75.4540645, 47.6662839],
  [-75.4546305, 47.6670374],
  [-75.4552281, 47.6698964],
  [-75.4560747, 47.6707478],
  [-75.4557992, 47.6712257],
  [-75.4567915, 47.6719776],
  [-75.4593409, 47.6725433],
  [-75.4601801, 47.6725399],
  [-75.4627303, 47.6731955],
  [-75.4652653, 47.6721325],
  [-75.4669571, 47.6721256],
  [-75.4683751, 47.6727947],
  [-75.4692277, 47.6727912],
  [-75.4717484, 47.6716382],
  [-75.4727359, 47.671823],
  [-75.4734454, 47.672207],
  [-75.4751509, 47.6737206],
  [-75.4751622, 47.6749622],
  [-75.4741919, 47.6766758],
  [-75.4743522, 47.6781958],
  [-75.4756335, 47.679909],
  [-75.4769204, 47.6807675],
  [-75.4811604, 47.6825583],
  [-75.4827281, 47.6835055],
  [-75.4835754, 47.6843567],
  [-75.4861549, 47.6880618],
  [-75.4888475, 47.68958],
  [-75.4891308, 47.6899567],
  [-75.4901205, 47.6903394],
  [-75.4922374, 47.6901324],
  [-75.4936527, 47.6904142],
  [-75.4959155, 47.6915472],
  [-75.4981981, 47.6933458],
  [-75.4996171, 47.6940055],
  [-75.5000509, 47.6947774],
  [-75.5007485, 47.6952512],
  [-75.5051551, 47.6975173],
  [-75.5067232, 47.6997958],
  [-75.507895, 47.7037946],
  [-75.5086116, 47.7048352],
  [-75.5120159, 47.7067276],
  [-75.5127411, 47.7086318],
  [-75.5136007, 47.7092938],
  [-75.5162858, 47.7098486],
  [-75.5230663, 47.70935],
  [-75.523908, 47.7095351],
  [-75.5247688, 47.710296],
  [-75.5250659, 47.7120042],
  [-75.5247755, 47.7122935],
  [-75.5252069, 47.7127684],
  [-75.5253536, 47.7140993],
  [-75.5260756, 47.7156256],
  [-75.5272241, 47.717141],
  [-75.5272377, 47.7184816],
  [-75.5266844, 47.7191499],
  [-75.5265463, 47.7200054],
  [-75.5289561, 47.7222797],
  [-75.5291134, 47.7233317],
  [-75.5289783, 47.724475],
  [-75.5246382, 47.7291557],
  [-75.524644, 47.7297315],
  [-75.5253574, 47.7303941],
  [-75.5269206, 47.7306749],
  [-75.5294718, 47.7323817],
  [-75.5324422, 47.7333217],
  [-75.5333015, 47.7338845],
  [-75.533741, 47.7351241],
  [-75.5313674, 47.7377985],
  [-75.5301131, 47.7403776],
  [-75.5302621, 47.7419065],
  [-75.5306927, 47.7422824],
  [-75.5309845, 47.7434238],
  [-75.5317041, 47.7446621],
  [-75.5315517, 47.7454186],
  [-75.5322694, 47.746468],
  [-75.534406, 47.7479787],
  [-75.5359845, 47.7496899],
  [-75.5373951, 47.750637],
  [-75.5389628, 47.7512955],
  [-75.5398132, 47.7522453],
  [-75.5406868, 47.7541486],
  [-75.5423931, 47.7552833],
  [-75.5428269, 47.755947],
  [-75.5428388, 47.7570897],
  [-75.5403155, 47.7608176],
  [-75.539338, 47.7617759],
  [-75.5379232, 47.7645752],
  [-75.5310954, 47.7641258],
  [-75.5210831, 47.7627867],
  [-75.5206427, 47.8477379],
  [-75.7253164, 47.8475333],
  [-75.7261375, 47.8473101],
  [-75.7270708, 47.8465519],
  [-75.7274622, 47.8453852],
  [-75.7285562, 47.844601],
  [-75.7298266, 47.8427206],
  [-75.7302897, 47.8412216],
  [-75.7335508, 47.8393365],
  [-75.7387411, 47.8379768],
  [-75.7395236, 47.8374603],
  [-75.7408246, 47.8352742],
  [-75.742411, 47.8336746],
  [-75.7417187, 47.8316732],
  [-75.7418717, 47.8312604],
  [-75.7425064, 47.8307879],
  [-75.7447611, 47.8300652],
  [-75.7470497, 47.8297564],
  [-75.7491681, 47.8282861],
  [-75.7499987, 47.8271942],
  [-75.7509157, 47.8265885],
  [-75.7525736, 47.8264734],
  [-75.7535438, 47.825877],
  [-75.7556662, 47.8259718],
  [-75.7603089, 47.8226017],
  [-75.7634344, 47.8206969],
  [-75.7654454, 47.8201521],
  [-75.7669123, 47.8203054],
  [-75.7681563, 47.8201695],
  [-75.7721017, 47.8188183],
  [-75.7729861, 47.8176456],
  [-75.7727307, 47.815854],
  [-75.7732007, 47.8137522],
  [-75.7741485, 47.8128137],
  [-75.7747117, 47.8107305],
  [-75.7748321, 47.8087974],
  [-75.7745111, 47.8069154],
  [-75.7784318, 47.8043676],
  [-75.7782881, 47.8031794],
  [-75.7788809, 47.8008624],
  [-75.7797511, 47.800688],
  [-75.7815525, 47.8007532],
  [-75.7826558, 47.7981876],
  [-75.7821944, 47.7958281],
  [-75.7824547, 47.795344],
  [-75.7839735, 47.7945889],
  [-75.784509, 47.7935038],
  [-75.7866907, 47.7930767],
  [-75.7872956, 47.7927656],
  [-75.7882874, 47.7914945],
  [-75.7868361, 47.7892727],
  [-75.7865368, 47.7877418],
  [-75.7857199, 47.7869631],
  [-75.7856786, 47.7860814],
  [-75.7863044, 47.7852127],
  [-75.7886196, 47.7847594],
  [-75.7891181, 47.7844117],
  [-75.7893736, 47.7832889],
  [-75.7906604, 47.7809222],
  [-75.7893804, 47.7798529],
  [-75.7863586, 47.7793034],
  [-75.7855055, 47.7782637],
  [-75.7834676, 47.7770097],
  [-75.7824711, 47.7757262],
  [-75.7826857, 47.7746751],
  [-75.7837495, 47.7739712],
  [-75.7837933, 47.7737016],
  [-75.7828186, 47.7718066],
  [-75.7808295, 47.7699412],
  [-75.7804546, 47.7681039],
  [-75.7797006, 47.7676314],
  [-75.779868, 47.7671018],
  [-75.7805263, 47.7667731],
  [-75.780786, 47.766325],
  [-75.7804196, 47.7629046],
  [-75.7800454, 47.7619848],
  [-75.778068, 47.7602723],
  [-75.7781162, 47.7587165],
  [-75.7773735, 47.7574435],
  [-75.7779462, 47.7546137],
  [-75.7791896, 47.7524718],
  [-75.7826734, 47.7513152],
  [-75.7838688, 47.7507021],
  [-75.7847984, 47.7500242],
  [-75.7861334, 47.7484792],
  [-75.7860501, 47.7476181],
  [-75.7857168, 47.7472014],
  [-75.786439, 47.7464792],
  [-75.7859547, 47.7452454],
  [-75.7848834, 47.7448125],
  [-75.7843313, 47.7442106],
  [-75.7844948, 47.7420078],
  [-75.7852246, 47.7413018],
  [-75.785515, 47.740548],
  [-75.7854323, 47.7397649],
  [-75.7858526, 47.7392638],
  [-75.7848566, 47.7389608],
  [-75.7844647, 47.7383647],
  [-75.7852981, 47.7378842],
  [-75.7847754, 47.7370983],
  [-75.7856409, 47.7371937],
  [-75.7858314, 47.7359356],
  [-75.7860128, 47.7324197],
  [-75.7855959, 47.7287831],
  [-75.7857081, 47.7254557],
  [-75.7878957, 47.7224199],
  [-75.7908549, 47.7204684],
  [-75.791373, 47.719617],
  [-75.7919613, 47.7175158],
  [-75.7951241, 47.7122912],
  [-75.7971492, 47.7103607],
  [-75.7976463, 47.7090775],
  [-75.7974833, 47.7083119],
  [-75.7953524, 47.7052406],
  [-75.7943186, 47.7028505],
  [-75.7919988, 47.6990043],
  [-75.7912341, 47.6944751],
  [-75.7894742, 47.6886804],
  [-75.7870204, 47.6830073],
  [-75.7855307, 47.6808932],
  [-75.784846, 47.6803133],
  [-75.7850886, 47.6801079],
  [-75.7859935, 47.6801675],
  [-75.7862099, 47.679926],
  [-75.7871973, 47.6797972],
  [-75.7870531, 47.6806059],
  [-75.7876072, 47.6809961],
  [-75.7877839, 47.6817168],
  [-75.7903641, 47.6820026],
  [-75.7917833, 47.6814627],
  [-75.7932225, 47.6784582],
  [-75.792931, 47.677359],
  [-75.7938428, 47.6769059],
  [-75.793787, 47.676105],
  [-75.7944895, 47.6763522],
  [-75.7949098, 47.6767955],
  [-75.795524, 47.6764288],
  [-75.7954124, 47.67534],
  [-75.795756, 47.6742821],
  [-75.7973808, 47.6742831],
  [-75.8010006, 47.6714987],
  [-75.8043853, 47.6703409],
  [-75.8048965, 47.6699483],
  [-75.8056316, 47.6697458],
  [-75.8057951, 47.669468],
  [-75.8068718, 47.6696184],
  [-75.8064969, 47.669769],
  [-75.8069594, 47.6700417],
  [-75.8078384, 47.670038],
  [-75.8077344, 47.6698485],
  [-75.8080011, 47.6698141],
  [-75.8079421, 47.6702545],
  [-75.8085068, 47.6708516],
  [-75.8088006, 47.6707904],
  [-75.8093676, 47.6712166],
  [-75.8099123, 47.6713188],
  [-75.8102946, 47.6716089],
  [-75.8101312, 47.6718868],
  [-75.8103798, 47.6722211],
  [-75.8108995, 47.6721972],
  [-75.8115736, 47.6725881],
  [-75.812074, 47.6740303],
  [-75.8146132, 47.6743783],
  [-75.8147777, 47.6750359],
  [-75.8143195, 47.675447],
  [-75.8139505, 47.6751479],
  [-75.811551, 47.674306],
  [-75.8109467, 47.6746802],
  [-75.8113269, 47.6771651],
  [-75.8119289, 47.6779783],
  [-75.8117393, 47.678211],
  [-75.8134442, 47.6802361],
  [-75.8137474, 47.6814972],
  [-75.8146949, 47.681359],
  [-75.8151594, 47.6814877],
  [-75.8154888, 47.680743],
  [-75.8156905, 47.6806003],
  [-75.8158882, 47.6807634],
  [-75.8152574, 47.6821449],
  [-75.8152493, 47.6827655],
  [-75.8139304, 47.683792],
  [-75.8132114, 47.6858026],
  [-75.8127411, 47.6861057],
  [-75.8124689, 47.6875612],
  [-75.8120531, 47.6877746],
  [-75.8123121, 47.6883428],
  [-75.812895, 47.6885892],
  [-75.8132962, 47.6884747],
  [-75.8132131, 47.688717],
  [-75.8137461, 47.6887022],
  [-75.8141002, 47.6891271],
  [-75.8147768, 47.689347],
  [-75.8147273, 47.6900753],
  [-75.8143519, 47.690253],
  [-75.8144916, 47.6907756],
  [-75.8150103, 47.6908416],
  [-75.8153514, 47.6912484],
  [-75.8160691, 47.6913786],
  [-75.815865, 47.6917012],
  [-75.8161931, 47.692081],
  [-75.8159623, 47.6924124],
  [-75.8169948, 47.6919059],
  [-75.8176537, 47.6924585],
  [-75.8179287, 47.6928199],
  [-75.8176435, 47.693241],
  [-75.8177146, 47.693916],
  [-75.8186176, 47.6941463],
  [-75.8190263, 47.6944725],
  [-75.8192157, 47.6942578],
  [-75.8203362, 47.6941475],
  [-75.8209257, 47.6938991],
  [-75.8219719, 47.6923312],
  [-75.8232212, 47.691538],
  [-75.8245582, 47.6911681],
  [-75.8254767, 47.6912274],
  [-75.8271309, 47.6900138],
  [-75.8279537, 47.6902615],
  [-75.8282482, 47.6901553],
  [-75.8283378, 47.6904347],
  [-75.8288436, 47.6904736],
  [-75.8290221, 47.6900609],
  [-75.8287082, 47.6896003],
  [-75.8291374, 47.6893779],
  [-75.8300443, 47.6893023],
  [-75.8303605, 47.689574],
  [-75.8288262, 47.6907883],
  [-75.8275297, 47.6911226],
  [-75.8268251, 47.6910015],
  [-75.8269416, 47.691272],
  [-75.8242575, 47.6927854],
  [-75.8240745, 47.6935489],
  [-75.82244, 47.6952753],
  [-75.8219043, 47.695488],
  [-75.8210934, 47.6953483],
  [-75.8209554, 47.6957163],
  [-75.8204213, 47.6958031],
  [-75.8208173, 47.6960933],
  [-75.8205219, 47.6962624],
  [-75.8209832, 47.6966519],
  [-75.8207404, 47.6968844],
  [-75.8211446, 47.6975614],
  [-75.8234429, 47.6991131],
  [-75.8244742, 47.6987054],
  [-75.8258473, 47.6986505],
  [-75.8267471, 47.6991325],
  [-75.8311569, 47.6991763],
  [-75.8321855, 47.6989754],
  [-75.8319801, 47.6983626],
  [-75.8326691, 47.69762],
  [-75.833216, 47.6975782],
  [-75.8336132, 47.6977694],
  [-75.8347797, 47.6972005],
  [-75.8351993, 47.6977336],
  [-75.834297, 47.698484],
  [-75.8345243, 47.6994657],
  [-75.8335655, 47.6994242],
  [-75.8333623, 47.6996839],
  [-75.8320371, 47.7001619],
  [-75.8318194, 47.7005114],
  [-75.8307381, 47.700667],
  [-75.8314828, 47.7007883],
  [-75.8332833, 47.7006549],
  [-75.8336125, 47.7009626],
  [-75.83274, 47.7014613],
  [-75.831527, 47.7014992],
  [-75.8316961, 47.701824],
  [-75.8307453, 47.7021872],
  [-75.8306629, 47.7034191],
  [-75.8324841, 47.7037625],
  [-75.8327465, 47.7040879],
  [-75.8352039, 47.7047048],
  [-75.8384172, 47.7045614],
  [-75.8390401, 47.7037825],
  [-75.8413548, 47.7030402],
  [-75.8431269, 47.7030504],
  [-75.8445718, 47.7036523],
  [-75.8474025, 47.7042902],
  [-75.8477281, 47.7134119],
  [-75.8482704, 47.7137478],
  [-75.8497291, 47.7132703],
  [-75.8502175, 47.7136509],
  [-75.8517161, 47.7131826],
  [-75.8536404, 47.7138501],
  [-75.8546752, 47.7131543],
  [-75.8555293, 47.7130691],
  [-75.8562325, 47.713325],
  [-75.8569047, 47.7139404],
  [-75.8569258, 47.7143903],
  [-75.8562781, 47.7150343],
  [-75.8541537, 47.7154541],
  [-75.8505504, 47.7168909],
  [-75.8494695, 47.7169928],
  [-75.8479438, 47.7164264],
  [-75.8478163, 47.7170283],
  [-75.843598, 47.7185964],
  [-75.8435101, 47.7192255],
  [-75.8431474, 47.7194483],
  [-75.8424102, 47.7208294],
  [-75.842132, 47.7217542],
  [-75.8422424, 47.7225105],
  [-75.8428383, 47.7228287],
  [-75.8441026, 47.7229978],
  [-75.8464156, 47.7245852],
  [-75.8493236, 47.7255012],
  [-75.8499722, 47.7258736],
  [-75.8503509, 47.7265054],
  [-75.8497677, 47.7283821],
  [-75.84803, 47.7298114],
  [-75.8474886, 47.7315264],
  [-75.8477849, 47.7323556],
  [-75.8472496, 47.7325145],
  [-75.8471745, 47.7321273],
  [-75.8464937, 47.7321953],
  [-75.8463099, 47.7319604],
  [-75.8461082, 47.7321032],
  [-75.8457505, 47.7319213],
  [-75.8450537, 47.7321961],
  [-75.8444951, 47.732085],
  [-75.8446322, 47.7317889],
  [-75.843178, 47.7318616],
  [-75.8427291, 47.7315082],
  [-75.8419291, 47.7315126],
  [-75.8415657, 47.7317804],
  [-75.8411678, 47.7316162],
  [-75.8405491, 47.7320444],
  [-75.8392894, 47.7314975],
  [-75.8387164, 47.7314762],
  [-75.8383089, 47.7310151],
  [-75.8376136, 47.731173],
  [-75.836775, 47.7310692],
  [-75.8358516, 47.7313427],
  [-75.8353366, 47.7319963],
  [-75.8342082, 47.7326554],
  [-75.8326483, 47.7326554],
  [-75.8324296, 47.7330769],
  [-75.8319222, 47.7331369],
  [-75.8306857, 47.7339032],
  [-75.8302588, 47.7339187],
  [-75.8292027, 47.7351719],
  [-75.8281053, 47.7354893],
  [-75.8278438, 47.7361264],
  [-75.8278657, 47.7364953],
  [-75.8288004, 47.7363928],
  [-75.8289723, 47.7354673],
  [-75.8300017, 47.7362919],
  [-75.8310656, 47.736514],
  [-75.8312002, 47.7374592],
  [-75.8315559, 47.7377941],
  [-75.8323824, 47.7378079],
  [-75.8325981, 47.7386727],
  [-75.8330783, 47.7386575],
  [-75.8340318, 47.7381323],
  [-75.834505, 47.7376224],
  [-75.8346877, 47.7368858],
  [-75.8357167, 47.7367029],
  [-75.8361505, 47.7361477],
  [-75.8366848, 47.7360699],
  [-75.8362337, 47.7379922],
  [-75.8369022, 47.7378521],
  [-75.8382165, 47.7383095],
  [-75.8396886, 47.7378862],
  [-75.8401644, 47.7382217],
  [-75.8400945, 47.7384822],
  [-75.839208, 47.7389898],
  [-75.8373132, 47.7390868],
  [-75.8349604, 47.7385065],
  [-75.834355, 47.7389258],
  [-75.833232, 47.7391531],
  [-75.8329891, 47.7393766],
  [-75.8334696, 47.7403868],
  [-75.8330889, 47.7409603],
  [-75.832846, 47.7422271],
  [-75.8320631, 47.7429692],
  [-75.8312215, 47.7430812],
  [-75.8310277, 47.7436378],
  [-75.8305719, 47.743824],
  [-75.8304787, 47.7448489],
  [-75.8318942, 47.7457476],
  [-75.8318846, 47.7464942],
  [-75.8326708, 47.7465437],
  [-75.8327862, 47.7469042],
  [-75.8337928, 47.7474587],
  [-75.8339414, 47.7483501],
  [-75.8342741, 47.748406],
  [-75.8346804, 47.748966],
  [-75.8343744, 47.7499537],
  [-75.8349411, 47.7504877],
  [-75.8353511, 47.7507599],
  [-75.8357385, 47.7507172],
  [-75.836413, 47.7501184],
  [-75.8376177, 47.7497746],
  [-75.8379749, 47.75],
  [-75.8369708, 47.7513539],
  [-75.8370549, 47.752083],
  [-75.8365147, 47.753654],
  [-75.8358262, 47.7543066],
  [-75.8349904, 47.7570902],
  [-75.8339745, 47.7582987],
  [-75.8340358, 47.7587218],
  [-75.8336702, 47.7591514],
  [-75.8335102, 47.7601849],
  [-75.8339964, 47.7607634],
  [-75.834065, 47.7627067],
  [-75.8336859, 47.7631452],
  [-75.8333934, 47.7630626],
  [-75.8331767, 47.7633222],
  [-75.8337773, 47.7632987],
  [-75.8340911, 47.7637952],
  [-75.8349046, 47.7638179],
  [-75.8352609, 47.7641258],
  [-75.8340897, 47.7649556],
  [-75.8316089, 47.7656164],
  [-75.8314166, 47.7667144],
  [-75.8317825, 47.7675429],
  [-75.8331157, 47.7685707],
  [-75.8351434, 47.7695152],
  [-75.8402547, 47.7711541],
  [-75.8421991, 47.7715986],
  [-75.846644, 47.7721542],
  [-75.8500611, 47.7722375],
  [-75.8528668, 47.7728486],
  [-75.8566172, 47.773043],
  [-75.8588676, 47.7723485],
  [-75.862396, 47.7706818],
  [-75.8647286, 47.7765431],
  [-75.8631743, 47.7797116],
  [-75.8546562, 47.7810781],
  [-75.8495588, 47.7832555],
  [-75.8480481, 47.7851748],
  [-75.8481035, 47.7883142],
  [-75.8498558, 47.7919677],
  [-75.8489527, 47.7926732],
  [-75.8490524, 47.7932404],
  [-75.8496222, 47.7935764],
  [-75.8500808, 47.7942626],
  [-75.8508013, 47.7942937],
  [-75.8514879, 47.7949002],
  [-75.8520066, 47.7950471],
  [-75.8543849, 47.7948716],
  [-75.8548894, 47.7950993],
  [-75.8547784, 47.7954315],
  [-75.8534191, 47.7963233],
  [-75.8521633, 47.7964062],
  [-75.8523862, 47.7967313],
  [-75.8519411, 47.7970975],
  [-75.851638, 47.7978514],
  [-75.8536545, 47.7988702],
  [-75.8553061, 47.8002557],
  [-75.8563505, 47.8032479],
  [-75.8569091, 47.8034219],
  [-75.8586032, 47.8035483],
  [-75.8599836, 47.8031153],
  [-75.8607393, 47.8024628],
  [-75.8614989, 47.802575],
  [-75.8619439, 47.8022177],
  [-75.8624913, 47.8022117],
  [-75.8652215, 47.8005718],
  [-75.8683805, 47.7998876],
  [-75.8687193, 47.8005731],
  [-75.8694943, 47.8005144],
  [-75.8696891, 47.8009652],
  [-75.8705401, 47.8012487],
  [-75.8710522, 47.8008558],
  [-75.8721856, 47.8009699],
  [-75.8731353, 47.8008132],
  [-75.8740666, 47.8010881],
  [-75.874604, 47.8008032],
  [-75.8753648, 47.8008163],
  [-75.876602, 47.8011829],
  [-75.8790577, 47.8001258],
  [-75.8816231, 47.7999507],
  [-75.8832381, 47.7999774],
  [-75.8827385, 47.8004515],
  [-75.8837335, 47.8009875],
  [-75.8838229, 47.8001964],
  [-75.8848082, 47.8004176],
  [-75.885618, 47.7996754],
  [-75.8867682, 47.7995016],
  [-75.8867853, 47.7991779],
  [-75.8876364, 47.7983279],
  [-75.8884029, 47.7978463],
  [-75.8889244, 47.7977771],
  [-75.890204, 47.7979458],
  [-75.8910155, 47.7993263],
  [-75.8917017, 47.7988712],
  [-75.8916163, 47.7993205],
  [-75.8927265, 47.7991375],
  [-75.8936884, 47.800222],
  [-75.8946492, 47.800263],
  [-75.895386, 47.8000421],
  [-75.8955839, 47.80025],
  [-75.8963632, 47.7998133],
  [-75.897044, 47.7986656],
  [-75.896338, 47.7973846],
  [-75.8968697, 47.7975763],
  [-75.8966868, 47.7972335],
  [-75.8970599, 47.7972894],
  [-75.8972768, 47.7970027],
  [-75.897932, 47.7969162],
  [-75.898969, 47.7961211],
  [-75.8993083, 47.7956372],
  [-75.8987892, 47.7955085],
  [-75.8996196, 47.79527],
  [-75.8992901, 47.7948995],
  [-75.8995711, 47.794847],
  [-75.8997292, 47.7950277],
  [-75.8999315, 47.7948489],
  [-75.9002364, 47.7950304],
  [-75.9016045, 47.7944619],
  [-75.9026129, 47.7938285],
  [-75.9022558, 47.7935388],
  [-75.9030876, 47.7931743],
  [-75.9034337, 47.7932571],
  [-75.9039719, 47.7928911],
  [-75.9048803, 47.7928238],
  [-75.9047112, 47.7924362],
  [-75.9053145, 47.7922054],
  [-75.9065013, 47.7934799],
  [-75.9067822, 47.7934363],
  [-75.9072572, 47.7927462],
  [-75.908473, 47.7926535],
  [-75.9098307, 47.7929843],
  [-75.9105196, 47.7934466],
  [-75.9106992, 47.7940951],
  [-75.912861, 47.7953385],
  [-75.9133559, 47.7952511],
  [-75.9135873, 47.7948565],
  [-75.9143044, 47.795184],
  [-75.9159833, 47.7942661],
  [-75.9168784, 47.7941987],
  [-75.9166729, 47.7946743],
  [-75.9172055, 47.794794],
  [-75.9176796, 47.7953721],
  [-75.9172475, 47.7958106],
  [-75.9176843, 47.797342],
  [-75.9184098, 47.7981192],
  [-75.9195384, 47.7986736],
  [-75.9195185, 47.7992582],
  [-75.9203148, 47.7996849],
  [-75.9203596, 47.8004587],
  [-75.9212575, 47.8013447],
  [-75.9210414, 47.8015685],
  [-75.9217052, 47.8019047],
  [-75.9232979, 47.8015529],
  [-75.9250049, 47.8017144],
  [-75.9257178, 47.8012322],
  [-75.9261564, 47.8014143],
  [-75.928783, 47.8005099],
  [-75.9292151, 47.8000623],
  [-75.9301121, 47.7998329],
  [-75.9301165, 47.7994282],
  [-75.9311338, 47.7991814],
  [-75.9331077, 47.7993531],
  [-75.9334757, 47.7998946],
  [-75.9345129, 47.8002685],
  [-75.9346654, 47.7997475],
  [-75.9354144, 47.7996163],
  [-75.985832, 47.7717622],
  [-75.9860788, 47.7710887],
  [-75.9854706, 47.7705283],
  [-75.9851047, 47.7697441],
  [-75.9852472, 47.7688273],
  [-75.9842148, 47.7679861],
  [-75.9841682, 47.7673023],
  [-75.9836293, 47.7664813],
  [-75.9828877, 47.7659293],
  [-75.9821418, 47.7658],
  [-75.981659, 47.7660587],
  [-75.9812595, 47.7659849],
  [-75.9810363, 47.7656241],
  [-75.980582, 47.765685],
  [-75.9800249, 47.7653677],
  [-75.9800114, 47.7640454],
  [-75.9803254, 47.7633272],
  [-75.9822606, 47.7618968],
  [-75.982829, 47.7610808],
  [-75.9837124, 47.7607879],
  [-75.9838231, 47.7603837],
  [-75.9834512, 47.7602201],
  [-75.9845994, 47.7587681],
  [-75.9846215, 47.7578867],
  [-75.9854724, 47.7568111],
  [-75.9858096, 47.7550856],
  [-75.9868795, 47.7548026],
  [-75.9868957, 47.7531476],
  [-75.9862425, 47.7531177],
  [-75.9857915, 47.7528548],
  [-75.984959, 47.74937],
  [-75.9833684, 47.7483734],
  [-75.9833019, 47.7470059],
  [-75.9824607, 47.7457608],
  [-75.9826045, 47.7447001],
  [-75.9837459, 47.7439136],
  [-75.9830813, 47.7436948],
  [-75.983686, 47.7432298],
  [-75.9853004, 47.7431471],
  [-75.9857558, 47.7429422],
  [-75.9859802, 47.7431771],
  [-75.9862487, 47.7429984],
  [-75.9857577, 47.7427533],
  [-75.9862732, 47.7418651],
  [-75.9867942, 47.7390521],
  [-75.9874275, 47.7383893],
  [-75.987959, 47.7385715],
  [-75.9887599, 47.7384942],
  [-75.9905317, 47.7372967],
  [-75.991745, 47.7373111],
  [-75.9932172, 47.7367419],
  [-75.9945488, 47.7369277],
  [-75.9957231, 47.7368339],
  [-75.997051, 47.7360212],
  [-75.9977596, 47.7358264],
  [-75.9986789, 47.7359024],
  [-76.0000438, 47.7367809],
  [-76.0006165, 47.7368553],
  [-76.0008353, 47.7362806],
  [-76.000489, 47.7362431],
  [-76.0012592, 47.7351761],
  [-76.0032887, 47.7334848],
  [-76.0038498, 47.7333703],
  [-76.0046198, 47.7337154],
  [-76.0055391, 47.7337914],
  [-76.0062517, 47.7331558],
  [-76.0065703, 47.7333101],
  [-76.0071217, 47.7328087],
  [-76.0069391, 47.7323762],
  [-76.0079299, 47.7319307],
  [-76.0082421, 47.7313474],
  [-76.0076855, 47.7309852],
  [-76.0100469, 47.7293942],
  [-76.0107257, 47.7280928],
  [-76.0127071, 47.7272107],
  [-76.0131783, 47.726718],
  [-76.0132613, 47.7263856],
  [-76.012535, 47.7270751],
  [-76.0118153, 47.727054],
  [-76.0113788, 47.7252622],
  [-76.0138127, 47.724436],
  [-76.0145853, 47.7245022],
  [-76.0158055, 47.7237428],
  [-76.017204, 47.7238746],
  [-76.0189668, 47.7235312],
  [-76.0194452, 47.7236862],
  [-76.0197797, 47.7235526],
  [-76.0202422, 47.7240043],
  [-76.0209237, 47.7238183],
  [-76.0218158, 47.7239389],
  [-76.0224519, 47.7243463],
  [-76.0218625, 47.7246587],
  [-76.0228298, 47.7253014],
  [-76.0228008, 47.7255531],
  [-76.0224286, 47.7254436],
  [-76.024472, 47.7264955],
  [-76.0262254, 47.7280239],
  [-76.0308349, 47.7271028],
  [-76.0323376, 47.7262225],
  [-76.0335987, 47.7243585],
  [-76.0344169, 47.724303],
  [-76.0431663, 47.7286499],
  [-76.0473847, 47.7295039],
  [-76.053767, 47.7291462],
  [-76.0554022, 47.7297118],
  [-76.0564147, 47.7311119],
  [-76.0570651, 47.7314472],
  [-76.0576577, 47.7307479],
  [-76.0594631, 47.7300892],
  [-76.0601882, 47.7294804],
  [-76.0619312, 47.7299008],
  [-76.0621491, 47.7293529],
  [-76.0637344, 47.7279018],
  [-76.0655357, 47.7277197],
  [-76.0658252, 47.7265695],
  [-76.0671958, 47.7268715],
  [-76.0679255, 47.727297],
  [-76.0687783, 47.7273452],
  [-76.0704778, 47.726569],
  [-76.071471, 47.7257632],
  [-76.0714658, 47.7247647],
  [-76.0726511, 47.723276],
  [-76.0719228, 47.7226886],
  [-76.0718194, 47.7222834],
  [-76.0723684, 47.7219886],
  [-76.0729418, 47.7219638],
  [-76.0734448, 47.7223974],
  [-76.0744177, 47.722428],
  [-76.0739431, 47.7217786],
  [-76.074586, 47.7213942],
  [-76.0790832, 47.7224362],
  [-76.08009, 47.7232225],
  [-76.0810732, 47.7236218],
  [-76.0824047, 47.7238246],
  [-76.0858425, 47.725699],
  [-76.0873858, 47.7260823],
  [-76.0897252, 47.7252632],
  [-76.090424, 47.7245551],
  [-76.0939655, 47.7248265],
  [-76.0967553, 47.7244564],
  [-76.0979674, 47.7245751],
  [-76.0999124, 47.7251312],
  [-76.1009737, 47.7250674],
  [-76.1051349, 47.7243685],
  [-76.1060835, 47.7237847],
  [-76.106652, 47.7229048],
  [-76.108038, 47.7225432],
  [-76.1082605, 47.7221848],
  [-76.1080157, 47.721263],
  [-76.110077, 47.7188758],
  [-76.1124013, 47.7184062],
  [-76.1135446, 47.7190144],
  [-76.1141169, 47.7190417],
  [-76.1162233, 47.7180026],
  [-76.1170226, 47.7178968],
  [-76.1176894, 47.7181265],
  [-76.1190516, 47.7193146],
  [-76.124565, 47.7201592],
  [-76.1247066, 47.7218352],
  [-76.126195, 47.7230196],
  [-76.1268523, 47.7232354],
  [-76.1319726, 47.7241552],
  [-76.1356413, 47.723968],
  [-76.1367537, 47.723725],
  [-76.137716, 47.7223092],
  [-76.1396714, 47.720833],
  [-76.141467, 47.7206089],
  [-76.144288, 47.7195013],
  [-76.1480204, 47.7187296],
  [-76.1505613, 47.7162405],
  [-76.1519989, 47.7158507],
  [-76.1535519, 47.7158092],
  [-76.1558452, 47.7162838],
  [-76.1588278, 47.7175199],
  [-76.161899, 47.7183789],
  [-76.1623469, 47.7191494],
  [-76.160641, 47.7212335],
  [-76.1607724, 47.7221953],
  [-76.164321, 47.7253222],
  [-76.167089, 47.72573],
  [-76.1701334, 47.7252717],
  [-76.1762488, 47.7248531],
  [-76.1774853, 47.7253619],
  [-76.1776811, 47.7256091],
  [-76.1775336, 47.7261143],
  [-76.1750659, 47.7297984],
  [-76.1704552, 47.7331829],
  [-76.16795, 47.7344637],
  [-76.1676604, 47.7347956],
  [-76.1679072, 47.7351474],
  [-76.168693, 47.7354487],
  [-76.1722577, 47.7349652],
  [-76.1729658, 47.7349724],
  [-76.1738563, 47.7352683],
  [-76.1748089, 47.7370338],
  [-76.1758518, 47.7376927],
  [-76.1769462, 47.7376061],
  [-76.179115, 47.7361482],
  [-76.1803566, 47.7356584],
  [-76.1819136, 47.7361414],
  [-76.1823813, 47.7361623],
  [-76.1829543, 47.7358744],
  [-76.1837804, 47.7329664],
  [-76.1842847, 47.7321798],
  [-76.1865914, 47.7319128],
  [-76.1875892, 47.7316098],
  [-76.1950457, 47.7275541],
  [-76.1960113, 47.7268179],
  [-76.1972129, 47.726486],
  [-76.1990476, 47.7266014],
  [-76.2036073, 47.726089],
  [-76.2051201, 47.7247106],
  [-76.2071586, 47.7242126],
  [-76.2086606, 47.7241909],
  [-76.2104309, 47.7246456],
  [-76.2118632, 47.7263344],
  [-76.2152512, 47.7285572],
  [-76.2169325, 47.731083],
  [-76.2185204, 47.7319417],
  [-76.2201834, 47.7317685],
  [-76.2212026, 47.7306861],
  [-76.2218142, 47.7304696],
  [-76.2239599, 47.7305345],
  [-76.2262023, 47.7309747],
  [-76.2276191, 47.7310359],
  [-76.2286165, 47.7307747],
  [-76.2294638, 47.7300005],
  [-76.2293454, 47.729673],
  [-76.2273824, 47.7284922],
  [-76.2263632, 47.7273105],
  [-76.2262908, 47.7265617],
  [-76.2272859, 47.7259735],
  [-76.2284115, 47.7256935],
  [-76.2324464, 47.725656],
  [-76.2344461, 47.7253692],
  [-76.2363303, 47.7244724],
  [-76.2372851, 47.723498],
  [-76.2394371, 47.7219481],
  [-76.2403428, 47.720493],
  [-76.2418395, 47.7197773],
  [-76.2425157, 47.7178579],
  [-76.243304, 47.7169984],
  [-76.2433148, 47.7154139],
  [-76.2441731, 47.7140641],
  [-76.2442655, 47.7125224],
  [-76.245541, 47.7114797],
  [-76.2468419, 47.7111964],
  [-76.2468848, 47.710617],
  [-76.2477069, 47.7103355],
  [-76.2478879, 47.7099592],
  [-76.248592, 47.7098816],
  [-76.2489367, 47.7095386],
  [-76.2477994, 47.7060796],
  [-76.2487865, 47.7038847],
  [-76.2499666, 47.7029678],
  [-76.2526596, 47.7018125],
  [-76.2561893, 47.6998965],
  [-76.2581208, 47.6974941],
  [-76.2594943, 47.6966999],
  [-76.2621975, 47.6955809],
  [-76.2649639, 47.6951629],
  [-76.2666583, 47.6946359],
  [-76.2696171, 47.6930163],
  [-76.2703469, 47.6922601],
  [-76.2735486, 47.6908436],
  [-76.2742782, 47.6906414],
  [-76.2753725, 47.6906847],
  [-76.2772604, 47.6902063],
  [-76.2792885, 47.6891753],
  [-76.2815228, 47.6889893],
  [-76.2828914, 47.6883859],
  [-76.2841916, 47.6881678],
  [-76.2872666, 47.6879841],
  [-76.2906933, 47.6871747],
  [-76.2915784, 47.6866728],
  [-76.2919915, 47.6849682],
  [-76.2893361, 47.6839272],
  [-76.2884335, 47.6829169],
  [-76.2883705, 47.6820456],
  [-76.2892918, 47.6816475],
  [-76.2894648, 47.681122],
  [-76.2907121, 47.6802732],
  [-76.290704, 47.6797089],
  [-76.2913585, 47.6789775],
  [-76.2929544, 47.6784159],
  [-76.293228, 47.677829],
  [-76.2938572, 47.6776105],
  [-76.2941049, 47.6771843],
  [-76.2933835, 47.6768095],
  [-76.2932789, 47.6764754],
  [-76.2938999, 47.6758975],
  [-76.2940755, 47.675334],
  [-76.2951095, 47.675185],
  [-76.2959649, 47.6743242],
  [-76.2961234, 47.6738621],
  [-76.295532, 47.6733537],
  [-76.2958485, 47.6728552],
  [-76.2966652, 47.6725446],
  [-76.2989196, 47.670827],
  [-76.3001155, 47.6704469],
  [-76.3005444, 47.6700552],
  [-76.303359, 47.6702838],
  [-76.3052261, 47.6688351],
  [-76.3054329, 47.6682412],
  [-76.3061748, 47.6681348],
  [-76.3071902, 47.6669715],
  [-76.3071245, 47.6664278],
  [-76.3095945, 47.6660685],
  [-76.3111073, 47.666508],
  [-76.3118109, 47.6661389],
  [-76.3128948, 47.6663187],
  [-76.3134543, 47.6669459],
  [-76.3139219, 47.6670417],
  [-76.3148403, 47.6663833],
  [-76.3162712, 47.6664628],
  [-76.3168623, 47.6669507],
  [-76.3184729, 47.6667078],
  [-76.32123, 47.6681721],
  [-76.3228959, 47.6676155],
  [-76.3242129, 47.668925],
  [-76.3249116, 47.6682124],
  [-76.3309372, 47.6645348],
  [-76.3323346, 47.6624357],
  [-76.3313502, 47.6616101],
  [-76.3332868, 47.6613933],
  [-76.335969, 47.6594405],
  [-76.3373306, 47.6575554],
  [-76.3409905, 47.6551556],
  [-76.3470793, 47.6540811],
  [-76.3535542, 47.6540689],
  [-76.3642571, 47.6531469],
  [-76.3677108, 47.6523919],
  [-76.370569, 47.651184],
  [-76.3755902, 47.6508672],
  [-76.3758405, 47.6506614],
  [-76.3775935, 47.6508184],
  [-76.3842791, 47.650398],
  [-76.3861697, 47.6565612],
  [-76.3926097, 47.6526657],
  [-76.39816, 47.6424831],
  [-76.3910566, 47.6403179],
  [-76.3979202, 47.6343617],
  [-76.3994548, 47.6273139],
  [-76.4147588, 47.6263857],
  [-76.4290475, 47.6221173],
  [-76.4489151, 47.6205028],
  [-76.4570832, 47.6159661],
  [-76.4578455, 47.6137251],
  [-76.4707292, 47.6126881],
  [-76.4751056, 47.6128209],
  [-76.4788944, 47.6097332],
  [-76.4793897, 47.6082518],
  [-76.4786261, 47.6071123],
  [-76.4772685, 47.6063201],
  [-76.4820726, 47.6033209],
  [-76.4814629, 47.6023722],
  [-76.479726, 47.6008607],
  [-76.4681003, 47.6028336],
  [-76.4589044, 47.6033105],
  [-76.457851, 47.603643],
  [-76.4569014, 47.6029666],
  [-76.4541835, 47.6036111],
  [-76.4526166, 47.6035984],
  [-76.4507597, 47.6024426],
  [-76.4490595, 47.6018845],
  [-76.4484789, 47.6007005],
  [-76.4467686, 47.600098],
  [-76.4447926, 47.6001993],
  [-76.4422508, 47.6005709],
  [-76.4402004, 47.6015135],
  [-76.4397522, 47.6012777],
  [-76.4344296, 47.601587],
  [-76.4284453, 47.6056116],
  [-76.4209416, 47.6062992],
  [-76.4184514, 47.6035198],
  [-76.4165793, 47.6022123],
  [-76.4160773, 47.6010165],
  [-76.4168724, 47.5995249],
  [-76.4095966, 47.5608807],
  [-76.4243123, 47.5129776],
  [-76.4264425, 47.5130886],
  [-76.4416081, 47.5218522],
  [-76.4584071, 47.5326615],
  [-76.4998965, 47.5577486],
  [-76.5105682, 47.5633835],
  [-76.5178941, 47.5666471],
  [-76.5197025, 47.5676894],
  [-76.5512016, 47.5877772],
  [-76.5733757, 47.6026412],
  [-76.5737775, 47.5763453],
  [-76.5752135, 47.2880112],
  [-76.7806222, 47.2891434],
  [-77.002943, 47.2891886],
  [-77.0037136, 47.1454606],
  [-77.4327493, 47.1446938],
  [-77.4333732, 47.1448336],
  [-77.4336564, 47.1449819],
  [-77.4316947, 47.2891311],
  [-77.6464148, 47.2892174],
  [-77.6471026, 47.2694987],
  [-77.9316134, 47.2697153]
];

let polygons = [];
boundaries.map((boundary) => 
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons;