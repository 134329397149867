const boundaries = [
  [-70.2033428, 47.4109862],
  [-70.0792233, 47.32885],
  [-70.0811027, 47.3276898],
  [-70.0726389, 47.3225386],
  [-70.0717013, 47.3231207],
  [-70.0710471, 47.3227199],
  [-70.066537, 47.3195585],
  [-70.0287105, 47.2955761],
  [-70.0031218, 47.2786563],
  [-69.9743887, 47.2913677],
  [-69.874033, 47.2250482],
  [-69.8436712, 47.2060244],
  [-69.7214421, 47.1284825],
  [-69.6252841, 47.0667248],
  [-69.4254195, 47.2636698],
  [-69.22447, 47.4598397],
  [-69.2231044, 47.4594486],
  [-69.2223038, 47.4584891],
  [-69.2202548, 47.4575375],
  [-69.2189128, 47.4572452],
  [-69.2178878, 47.4566127],
  [-69.2143265, 47.4565211],
  [-69.2137299, 47.4562708],
  [-69.2129637, 47.455448],
  [-69.2114436, 47.4550769],
  [-69.2103061, 47.4552763],
  [-69.2089185, 47.4544574],
  [-69.2061309, 47.454101],
  [-69.2047628, 47.4534122],
  [-69.2056173, 47.4529446],
  [-69.2055683, 47.4521746],
  [-69.2051958, 47.4519524],
  [-69.2042247, 47.4520424],
  [-69.20397, 47.452358],
  [-69.2035516, 47.4524283],
  [-69.2032958, 47.4520785],
  [-69.2023382, 47.452226],
  [-69.2013194, 47.452943],
  [-69.2004169, 47.4540188],
  [-69.1978007, 47.4550172],
  [-69.1957926, 47.4554927],
  [-69.194337, 47.4555441],
  [-69.1928, 47.4553166],
  [-69.1916855, 47.4548822],
  [-69.1904429, 47.4535516],
  [-69.1899102, 47.4535624],
  [-69.1890682, 47.4539044],
  [-69.1890685, 47.4545021],
  [-69.1882704, 47.4550733],
  [-69.1868971, 47.4554444],
  [-69.1852626, 47.4553874],
  [-69.1845426, 47.4564108],
  [-69.1837937, 47.4564127],
  [-69.1830948, 47.455911],
  [-69.1807191, 47.456098],
  [-69.1799477, 47.4563627],
  [-69.1788961, 47.4573785],
  [-69.1781824, 47.4570635],
  [-69.178291, 47.4566977],
  [-69.177991, 47.4563313],
  [-69.1759474, 47.4567541],
  [-69.1750998, 47.4563824],
  [-69.1754962, 47.4555374],
  [-69.1750712, 47.4554046],
  [-69.1731989, 47.4557663],
  [-69.1727344, 47.4556485],
  [-69.1731189, 47.4544671],
  [-69.1721447, 47.454388],
  [-69.1725174, 47.4535407],
  [-69.1691448, 47.4532963],
  [-69.16738, 47.4520405],
  [-69.1649285, 47.4515149],
  [-69.1631276, 47.4502749],
  [-69.163512, 47.4497768],
  [-69.1647631, 47.4496771],
  [-69.1650548, 47.4492382],
  [-69.1646212, 47.448634],
  [-69.1622574, 47.4474307],
  [-69.1608132, 47.4475207],
  [-69.1590224, 47.4484198],
  [-69.158146, 47.4491362],
  [-69.1574322, 47.4513596],
  [-69.1570838, 47.4516357],
  [-69.1565002, 47.4516388],
  [-69.1548393, 47.4506399],
  [-69.1528267, 47.4502229],
  [-69.1512416, 47.4489784],
  [-69.1503729, 47.4487273],
  [-69.1486887, 47.4486945],
  [-69.1472023, 47.449124],
  [-69.1456108, 47.4485879],
  [-69.1423443, 47.4459951],
  [-69.1418122, 47.4445534],
  [-69.1410566, 47.4440031],
  [-69.1399855, 47.4440056],
  [-69.1395327, 47.4446439],
  [-69.1401917, 47.4452681],
  [-69.1399536, 47.4463453],
  [-69.1393017, 47.4466364],
  [-69.1384328, 47.4463595],
  [-69.1377697, 47.4458786],
  [-69.1358115, 47.445567],
  [-69.1345965, 47.4446281],
  [-69.1334497, 47.4441947],
  [-69.1313061, 47.4444619],
  [-69.1268103, 47.4435655],
  [-69.1246235, 47.4421047],
  [-69.1242307, 47.4409222],
  [-69.1236101, 47.4406869],
  [-69.1231573, 47.4410077],
  [-69.1233794, 47.4426066],
  [-69.1228024, 47.4428352],
  [-69.1220168, 47.4426966],
  [-69.1203037, 47.4412508],
  [-69.1192286, 47.4406638],
  [-69.1189088, 47.439956],
  [-69.1179993, 47.4391941],
  [-69.1151803, 47.4382604],
  [-69.1132853, 47.438334],
  [-69.1120866, 47.4375165],
  [-69.1129343, 47.4361526],
  [-69.1126004, 47.435801],
  [-69.1119143, 47.4357821],
  [-69.1099956, 47.4363676],
  [-69.1090531, 47.4362651],
  [-69.1077947, 47.4353754],
  [-69.1073321, 47.4343618],
  [-69.107971, 47.4329868],
  [-69.1076051, 47.4321112],
  [-69.1067153, 47.4313778],
  [-69.1065642, 47.4306748],
  [-69.1074839, 47.4296723],
  [-69.107743, 47.4276836],
  [-69.1069938, 47.4273534],
  [-69.1050615, 47.427902],
  [-69.1039396, 47.4290561],
  [-69.1044952, 47.4302589],
  [-69.104193, 47.4308648],
  [-69.1029455, 47.4306948],
  [-69.1009293, 47.430995],
  [-69.1005587, 47.4301972],
  [-69.099554, 47.4303778],
  [-69.1000518, 47.4313109],
  [-69.0988826, 47.4314475],
  [-69.0982067, 47.4304584],
  [-69.0971566, 47.4297397],
  [-69.0975827, 47.4287206],
  [-69.0972243, 47.4280053],
  [-69.0978521, 47.42689],
  [-69.0970732, 47.4265736],
  [-69.0957042, 47.4269197],
  [-69.0922753, 47.4271358],
  [-69.0912658, 47.4268302],
  [-69.0907052, 47.4260583],
  [-69.090934, 47.4242885],
  [-69.090137, 47.4241355],
  [-69.0889273, 47.4251952],
  [-69.0881395, 47.4255363],
  [-69.0871603, 47.4253646],
  [-69.0843227, 47.4242274],
  [-69.081362, 47.4237377],
  [-69.0798003, 47.4243463],
  [-69.0784528, 47.4257871],
  [-69.0758389, 47.4265144],
  [-69.074855, 47.427288],
  [-69.0735111, 47.4274627],
  [-69.0711741, 47.4268271],
  [-69.0683924, 47.4274066],
  [-69.0682886, 47.4294766],
  [-69.0673419, 47.430075],
  [-69.065668, 47.4308675],
  [-69.0645088, 47.4310725],
  [-69.062467, 47.4303783],
  [-69.0619057, 47.4305853],
  [-69.0619532, 47.4309833],
  [-69.0632799, 47.431383],
  [-69.0637408, 47.4318983],
  [-69.0641008, 47.4331019],
  [-69.0635481, 47.4340583],
  [-69.0628511, 47.4340917],
  [-69.0622272, 47.43375],
  [-69.0621519, 47.4326417],
  [-69.0615221, 47.4325078],
  [-69.0607893, 47.4330067],
  [-69.060386, 47.4329011],
  [-69.0601148, 47.4322244],
  [-69.0594879, 47.4322244],
  [-69.0584873, 47.4326672],
  [-69.0576892, 47.43206],
  [-69.0569314, 47.4319683],
  [-69.0556392, 47.4321783],
  [-69.0554041, 47.4315242],
  [-69.0568114, 47.4308589],
  [-69.0568305, 47.4299766],
  [-69.055271, 47.4297802],
  [-69.054484, 47.4290911],
  [-69.0539056, 47.4290227],
  [-69.0537226, 47.4294089],
  [-69.0542399, 47.4301986],
  [-69.053811, 47.4303844],
  [-69.0531743, 47.4304064],
  [-69.0521198, 47.4300086],
  [-69.0520351, 47.4296116],
  [-69.0532276, 47.4288352],
  [-69.0532409, 47.4285297],
  [-69.0529925, 47.4281613],
  [-69.052243, 47.4278041],
  [-69.0517388, 47.4265574],
  [-69.0503393, 47.4265163],
  [-69.0493187, 47.4257974],
  [-69.0487451, 47.4251158],
  [-69.04855, 47.4241952],
  [-69.0491083, 47.4236968],
  [-69.0511537, 47.4236591],
  [-69.0523165, 47.4232749],
  [-69.0528304, 47.4228177],
  [-69.0527162, 47.4223627],
  [-69.0521489, 47.4221421],
  [-69.051055, 47.4221065],
  [-69.0490055, 47.4227129],
  [-69.0465475, 47.4242724],
  [-69.0460158, 47.4250302],
  [-69.0462106, 47.4257155],
  [-69.046562, 47.4260819],
  [-69.0479637, 47.4266647],
  [-69.0479637, 47.4272288],
  [-69.047434, 47.4277413],
  [-69.0440773, 47.4275286],
  [-69.0432161, 47.4267836],
  [-69.042431, 47.4251363],
  [-69.0422557, 47.4235863],
  [-69.0417573, 47.4231043],
  [-69.0411189, 47.4228116],
  [-69.0400517, 47.4226982],
  [-69.0394583, 47.4222854],
  [-69.0391968, 47.4196551],
  [-69.0395609, 47.4187457],
  [-69.0418334, 47.4169257],
  [-69.0422417, 47.4163145],
  [-69.0422484, 47.4157126],
  [-69.0415647, 47.4152801],
  [-69.0403755, 47.4152154],
  [-69.038196, 47.4146101],
  [-69.0369443, 47.4146584],
  [-69.0354798, 47.4149845],
  [-69.0345176, 47.4145656],
  [-69.0344964, 47.4141551],
  [-69.0356936, 47.413287],
  [-69.0358064, 47.4126226],
  [-69.0351483, 47.4112328],
  [-69.0353627, 47.4105228],
  [-69.036628, 47.4101128],
  [-69.0370549, 47.4085445],
  [-69.0365937, 47.4076775],
  [-69.0357181, 47.4072631],
  [-69.0347437, 47.4074858],
  [-69.0330592, 47.4079975],
  [-69.0312886, 47.4090581],
  [-69.0308708, 47.4090292],
  [-69.0305514, 47.4086486],
  [-69.0305977, 47.4074197],
  [-69.0310008, 47.4069786],
  [-69.0320247, 47.4067136],
  [-69.0327424, 47.4060111],
  [-69.0334102, 47.4044419],
  [-69.0325187, 47.4035219],
  [-69.0315995, 47.4031338],
  [-69.0314353, 47.4026936],
  [-69.0323756, 47.4022213],
  [-69.0331981, 47.4022105],
  [-69.0342529, 47.403073],
  [-69.0358982, 47.4030902],
  [-69.037393, 47.4037036],
  [-69.039452, 47.4037208],
  [-69.0399426, 47.4042741],
  [-69.0396012, 47.4051944],
  [-69.0403271, 47.4056889],
  [-69.0416968, 47.4057458],
  [-69.0424935, 47.4047464],
  [-69.0422021, 47.4042444],
  [-69.0413415, 47.4039697],
  [-69.0398705, 47.4022444],
  [-69.038361, 47.4016491],
  [-69.0371235, 47.4015119],
  [-69.0356729, 47.4020044],
  [-69.0352387, 47.4018005],
  [-69.0351776, 47.4012994],
  [-69.0355376, 47.400971],
  [-69.0388429, 47.4002627],
  [-69.0400285, 47.4002574],
  [-69.040788, 47.4005516],
  [-69.0413367, 47.4018455],
  [-69.0427328, 47.4019708],
  [-69.0427289, 47.3999299],
  [-69.041851, 47.3982352],
  [-69.0403242, 47.3973016],
  [-69.039025, 47.3975354],
  [-69.0379678, 47.3973771],
  [-69.0375639, 47.3968435],
  [-69.039234, 47.3954023],
  [-69.0402208, 47.3954999],
  [-69.0407523, 47.3967615],
  [-69.043206, 47.3976649],
  [-69.0437688, 47.3974329],
  [-69.0440393, 47.3968629],
  [-69.043509, 47.3954799],
  [-69.0454114, 47.3929871],
  [-69.045686, 47.3902848],
  [-69.044846, 47.3886389],
  [-69.0435682, 47.3875556],
  [-69.0401385, 47.386012],
  [-69.0396417, 47.3842086],
  [-69.0407031, 47.3831003],
  [-69.0421429, 47.3826453],
  [-69.0441362, 47.3827342],
  [-69.044636, 47.3839139],
  [-69.0458483, 47.3838867],
  [-69.0474822, 47.3834231],
  [-69.0484961, 47.3820328],
  [-69.0508891, 47.380973],
  [-69.0496005, 47.37998],
  [-69.049521, 47.3792905],
  [-69.0500237, 47.3785491],
  [-69.0512018, 47.3782244],
  [-69.0532599, 47.3786352],
  [-69.0548816, 47.377716],
  [-69.0544227, 47.3765419],
  [-69.0538244, 47.3656765],
  [-69.0535303, 47.3406398],
  [-69.0539167, 47.3268977],
  [-69.0548671, 47.3153309],
  [-69.0492376, 47.3058494],
  [-69.0498715, 47.3047475],
  [-69.0506976, 47.3039564],
  [-69.0506975, 47.3025597],
  [-69.05127, 47.300646],
  [-69.0509608, 47.3000884],
  [-68.7996281, 47.348942],
  [-68.6987575, 47.3831226],
  [-68.6984547, 47.3829567],
  [-68.6974611, 47.383781],
  [-68.5692747, 47.4271241],
  [-68.4848891, 47.4848442],
  [-68.417815, 47.5302473],
  [-68.386409, 47.5511869],
  [-68.3834933, 47.5531985],
  [-68.3824224, 47.7786367],
  [-68.382727, 47.779838],
  [-68.38248, 47.790796],
  [-68.3827425, 47.8650758],
  [-68.3823342, 47.9161169],
  [-68.3468651, 47.9164066],
  [-68.26014, 47.9161894],
  [-68.1227929, 47.9163191],
  [-68.1226045, 47.999843],
  [-68.0441485, 47.999986],
  [-67.9761481, 47.9998395],
  [-67.5977438, 47.9999447],
  [-67.5980455, 48.0006086],
  [-67.5973964, 48.001226],
  [-67.5905138, 48.002292],
  [-67.5895429, 48.0026079],
  [-67.5895938, 48.0031516],
  [-67.5912273, 48.0047758],
  [-67.5926049, 48.0070126],
  [-67.592268, 48.0075824],
  [-67.5907123, 48.0081064],
  [-67.5882286, 48.0094056],
  [-67.5872169, 48.0105271],
  [-67.58744, 48.011392],
  [-67.5918952, 48.0122012],
  [-67.5928044, 48.0128292],
  [-67.590538, 48.0165756],
  [-67.590251, 48.0178225],
  [-67.590723, 48.0204544],
  [-67.5897333, 48.0232136],
  [-67.5900579, 48.0237769],
  [-67.5918254, 48.0247904],
  [-67.5945666, 48.0256659],
  [-67.5956288, 48.0264838],
  [-67.5946873, 48.0292965],
  [-67.5932255, 48.0300194],
  [-67.5912166, 48.0317682],
  [-67.5885719, 48.0333322],
  [-67.5871235, 48.0350971],
  [-67.5858763, 48.0377622],
  [-67.586925, 48.040628],
  [-67.5869653, 48.0419049],
  [-67.5864852, 48.0437465],
  [-67.5854096, 48.0443024],
  [-67.585144, 48.045208],
  [-67.5876948, 48.0462409],
  [-67.5883976, 48.0471822],
  [-67.5868043, 48.0482312],
  [-67.5868714, 48.0489287],
  [-67.5873542, 48.0493787],
  [-67.5901383, 48.0507074],
  [-67.5919434, 48.0518674],
  [-67.5937647, 48.0522798],
  [-67.5940329, 48.0524806],
  [-67.5935823, 48.0535779],
  [-67.5945157, 48.0539795],
  [-67.5976378, 48.0569215],
  [-67.5986409, 48.0571796],
  [-67.6014841, 48.0588433],
  [-67.6022404, 48.0590333],
  [-67.6037854, 48.0587035],
  [-67.6039249, 48.0595962],
  [-67.6049548, 48.0607686],
  [-67.6060814, 48.061016],
  [-67.6070148, 48.0618764],
  [-67.6083934, 48.0623784],
  [-67.6100028, 48.0624967],
  [-67.6110864, 48.063522],
  [-67.6125187, 48.0659921],
  [-67.6136077, 48.0666374],
  [-67.6147074, 48.0666087],
  [-67.6148415, 48.067469],
  [-67.6160216, 48.0675407],
  [-67.617926, 48.0671356],
  [-67.619825, 48.0670317],
  [-67.6202488, 48.0674475],
  [-67.6241487, 48.0690105],
  [-67.6251948, 48.0700464],
  [-67.6243955, 48.0711146],
  [-67.6245886, 48.0714588],
  [-67.6260209, 48.0725986],
  [-67.6286656, 48.0737349],
  [-67.6292557, 48.0758174],
  [-67.6310688, 48.0777851],
  [-67.633118, 48.0789337],
  [-67.6336357, 48.0789767],
  [-67.6344645, 48.0783083],
  [-67.6368463, 48.0783513],
  [-67.6375195, 48.0785556],
  [-67.6386032, 48.0796398],
  [-67.6391396, 48.0796254],
  [-67.6410654, 48.0789445],
  [-67.6415321, 48.0793298],
  [-67.6436403, 48.0797957],
  [-67.6444289, 48.0804892],
  [-67.6448581, 48.0806039],
  [-67.6475832, 48.0805178],
  [-67.6491818, 48.0808332],
  [-67.6495037, 48.0813206],
  [-67.6491684, 48.0843077],
  [-67.6508689, 48.0850423],
  [-67.6510898, 48.0884273],
  [-67.6505725, 48.0917903],
  [-67.6522307, 48.0955137],
  [-67.655503, 48.0986993],
  [-67.6576263, 48.1020468],
  [-67.6592476, 48.1056794],
  [-67.6598177, 48.108591],
  [-67.6625057, 48.112105],
  [-67.6662283, 48.1150777],
  [-67.6666152, 48.1184115],
  [-67.6017731, 48.1350013],
  [-67.3749868, 48.1952593],
  [-67.2775862, 48.0315154],
  [-67.2022792, 48.0521928],
  [-67.1602818, 48.062354],
  [-67.1206009, 48.0733045],
  [-67.097953, 48.0787993],
  [-67.0967782, 48.0816414],
  [-67.0977119, 48.0847243],
  [-67.0744698, 48.0907188],
  [-67.0390557, 48.0982212],
  [-66.977272, 48.1139088],
  [-66.8795784, 48.1369584],
  [-66.9345734, 48.1811386],
  [-66.9003771, 48.200353],
  [-66.8757255, 48.1829091],
  [-66.8396509, 48.1468225],
  [-66.8368481, 48.1475467],
  [-66.8405418, 48.1531018],
  [-66.8431507, 48.1520863],
  [-66.9753461, 48.3026796],
  [-66.8353211, 48.3414044],
  [-66.7732667, 48.3579555],
  [-66.6074952, 48.4009606],
  [-66.2801179, 48.4835809],
  [-66.130073, 48.5208536],
  [-65.9484142, 48.5671378],
  [-65.9804727, 48.5895201],
  [-65.9840578, 48.5930566],
  [-66.0000226, 48.6037398],
  [-66.0996203, 48.6688857],
  [-66.4123399, 48.8750545],
  [-66.5378745, 48.9583402],
  [-66.5656166, 48.9489396],
  [-66.7044371, 48.9029412],
  [-66.7141165, 48.9092727],
  [-66.7128752, 48.9097929],
  [-66.7123324, 48.9108571],
  [-66.7122845, 48.9115292],
  [-66.7130855, 48.9126571],
  [-66.7113964, 48.912465],
  [-66.7101959, 48.9129343],
  [-66.7102817, 48.9135886],
  [-66.709899, 48.9144941],
  [-66.7089479, 48.9155795],
  [-66.7087438, 48.916551],
  [-66.7083361, 48.9168013],
  [-66.7047603, 48.9176298],
  [-66.7026316, 48.9185045],
  [-66.7007353, 48.9189743],
  [-66.699977, 48.9191244],
  [-66.6977186, 48.9190919],
  [-66.6956863, 48.9201462],
  [-66.695334, 48.9206331],
  [-66.6951225, 48.9230763],
  [-66.6953409, 48.9234637],
  [-66.6960214, 48.9240062],
  [-66.6986328, 48.9250758],
  [-66.6993004, 48.9255943],
  [-66.6999062, 48.9265116],
  [-66.7000046, 48.9273991],
  [-66.6993179, 48.9287867],
  [-66.6967043, 48.9313119],
  [-66.6934286, 48.9339578],
  [-66.6934159, 48.9343722],
  [-66.6954413, 48.9367117],
  [-66.6964557, 48.9373989],
  [-66.6982233, 48.9381733],
  [-66.6981673, 48.9385641],
  [-66.6945816, 48.9434336],
  [-66.6945589, 48.9439244],
  [-66.6957596, 48.9464431],
  [-66.6979207, 48.9488051],
  [-66.6979478, 48.9493316],
  [-66.6969852, 48.9502643],
  [-66.6956403, 48.9499913],
  [-66.6941753, 48.9502184],
  [-66.693666, 48.951423],
  [-66.6941872, 48.9525828],
  [-66.6956727, 48.9539358],
  [-66.6960075, 48.9549773],
  [-66.6972312, 48.9557102],
  [-66.7003057, 48.9564565],
  [-66.6988548, 48.9578336],
  [-66.6986149, 48.9584157],
  [-66.6988844, 48.9597354],
  [-66.6987254, 48.9618768],
  [-66.6983798, 48.9623958],
  [-66.6969701, 48.9632979],
  [-66.6965419, 48.9643487],
  [-66.6968377, 48.9657365],
  [-66.6972769, 48.9663625],
  [-66.6992558, 48.967432],
  [-66.7002418, 48.9685982],
  [-66.7001211, 48.9690783],
  [-66.6995911, 48.9695593],
  [-66.698821, 48.9693275],
  [-66.6977435, 48.9694574],
  [-66.6970199, 48.969788],
  [-66.6967489, 48.9707887],
  [-66.6971653, 48.9727623],
  [-66.7008061, 48.9764053],
  [-66.7019981, 48.9772874],
  [-66.704195, 48.9782657],
  [-66.7040479, 48.9786778],
  [-66.7035098, 48.9788775],
  [-66.701947, 48.9789249],
  [-66.7015804, 48.9791386],
  [-66.7008096, 48.9803908],
  [-66.7004939, 48.9815369],
  [-66.701062, 48.9816299],
  [-66.7018103, 48.9848136],
  [-66.6997148, 48.9882586],
  [-66.7036242, 48.9919477],
  [-66.7066147, 48.9956757],
  [-66.7066853, 48.9974014],
  [-66.7055935, 48.9983344],
  [-66.7051875, 48.9992502],
  [-66.7067929, 49.0000355],
  [-66.733505, 49.0174386],
  [-66.7883122, 49.0000304],
  [-66.8092177, 48.992837],
  [-66.8324701, 48.9851157],
  [-66.8553762, 49.0000611],
  [-66.9087298, 49.0341985],
  [-67.1533414, 49.189062],
  [-67.2050698, 49.1686363],
  [-67.2411758, 49.1567568],
  [-67.2938852, 49.1440553],
  [-67.3332106, 49.1349754],
  [-67.4254559, 49.1182805],
  [-67.4557879, 49.1143406],
  [-67.4968638, 49.1028405],
  [-67.577792, 49.0825326],
  [-67.6310261, 49.066716],
  [-67.699465, 49.0439692],
  [-67.8135816, 49.0042538],
  [-67.9067066, 48.9729191],
  [-68.0190341, 48.9335549],
  [-68.1388552, 48.8932348],
  [-68.242536, 48.8544451],
  [-68.3820243, 48.7965309],
  [-68.5310961, 48.7250152],
  [-68.6437698, 48.6638403],
  [-68.8829054, 48.5257012],
  [-68.9896781, 48.4597183],
  [-69.122888, 48.3635498],
  [-69.2055846, 48.3044214],
  [-69.2387183, 48.2810549],
  [-69.3889621, 48.1674596],
  [-69.4535811, 48.1194071],
  [-69.5413584, 48.053092],
  [-69.5877181, 48.0161853],
  [-69.6398576, 47.9707596],
  [-69.639362, 47.9712598],
  [-69.6720256, 47.9410441],
  [-69.6868311, 47.9266239],
  [-69.7196779, 47.8915981],
  [-69.8351762, 47.7470273],
  [-69.8518879, 47.7265391],
  [-69.8635776, 47.7114849],
  [-69.8753834, 47.6978733],
  [-69.9216868, 47.6504273],
  [-69.9428795, 47.6326133],
  [-69.9678368, 47.6157615],
  [-69.9867986, 47.6048725],
  [-70.0118578, 47.5930034],
  [-70.0332963, 47.581248],
  [-70.0693826, 47.5577119],
  [-70.088999, 47.5420204],
  [-70.0951206, 47.5349605],
  [-70.1056492, 47.5191176],
  [-70.1267644, 47.4797042],
  [-70.135126, 47.4677467],
  [-70.1462732, 47.4568393],
  [-70.1575832, 47.4480011],
  [-70.2033428, 47.4109862],
];

let polygons = [];

boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons