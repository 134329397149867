import { InfoWindow } from "@react-google-maps/api";

import { infoWindow } from "../../styles";
import mountainIcon from "../../assets/images/ico-mountain.svg";
import { availableLanguages } from "../../configs/constants";

export default function InfoEventModal({
  mountain,
  currentLanguage,
  handleInfoClick,
}) {
  return (
    <InfoWindow
      onCloseClick={() => handleInfoClick()}
      position={{
        lat: mountain.lat,
        lng: mountain.lng,
      }}
    >
      <div style={infoWindow.divStyle}>
        <img
          src={mountain.imageUrl}
          alt={mountain.description}
          width="100%"
          height="195px"
        />

        <div id="mountain-description">
          <div id="container-mountain">
            <div id="container-mountain-icon">
              <img
                id="mountain-icon"
                src={mountainIcon}
                width="45px"
                height="30px"
                alt={mountain.description}
              />
            </div>

            <div id="container-mountain-description">
              <h3 className="mountain-name-display">{mountain.name}</h3>
              <p>{mountain.description}</p>
            </div>
          </div>

          <div>
            <a target="_parent" className="btn" href={mountain.link}>
              {currentLanguage === availableLanguages.FR.abbreviation
                ? "En savoir plus"
                : "Learn more"}
            </a>
          </div>
        </div>
      </div>
    </InfoWindow>
  );
}
