export default function QuebecRegionItem({
  area,
  mountains,
  onClickHandler,
  handleShowDonate,
}) {
  return (
    <li className="p-0">
      <a
        target="_parent"
        href={area.hasChallenge ? area.link : "#"}
        className={area.hasChallenge ? "area-item" : "area-item-gray"}
        onClick={(e) => {
          if (!area.hasChallenge) {
            e.preventDefault();
            const mountain = mountains.find(
              (mountain) => mountain.name === area.name
            );
            if (mountain) {
              onClickHandler(mountain.id);
            } else {
              handleShowDonate(area);
            }
            document.getElementById("app-map").scrollIntoView();
          }
        }}
      >
        {area.name}
      </a>
    </li>
  );
}
