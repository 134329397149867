import InfoEventModal from "./InfoEventModal";
import InfoWindowDonation from "./InfoWindowDonation";

export default function MontainInfoModal({
  mountain,
  currentLanguage,
  handleInfoClick,
}) {
  if (mountain.hasChallenge) {
    return (
      <InfoEventModal
        mountain={mountain}
        currentLanguage={currentLanguage}
        handleInfoClick={handleInfoClick}
      />
    );
  } else {
    return (
      <InfoWindowDonation
        mountain={mountain}
        currentLanguage={currentLanguage}
        handleInfoClick={handleInfoClick}
      />
    );
  }
}
