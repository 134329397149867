import { InfoWindow } from "@react-google-maps/api";

import { infoWindow } from "../../styles";
import { availableLanguages } from "../../configs/constants";
import mountainIcon from "../../assets/images/ico-mountain.svg";

export default function InfoWindowDonation({
  mountain,
  currentLanguage,
  handleInfoClick,
}) {
  return (
    <InfoWindow
      onCloseClick={() => handleInfoClick()}
      position={{
        lat: mountain.lat,
        lng: mountain.lng,
      }}
    >
      <div style={infoWindow.divStyle} className="donate">
        <div id="mountain-description">
          <div id="container-mountain">
            <div id="container-mountain-description">
              <div>
                <img
                  id="mountain-icon"
                  src={mountainIcon}
                  width="45px"
                  height="30px"
                  alt={mountain.description}
                />

                <h3 className="mountain-name-display">{mountain.name}</h3>
              </div>

              <p>
                {currentLanguage === availableLanguages.FR.abbreviation
                  ? `Il n'y a pas de défi dans cette région cette année.
                      Vous pouvez tout de même faire un don pour encourager 
                      le déploiement du programme Chaîne de vie dans cette région`
                  : `There is no challenge in this region this year. You can still
                      make a donation to help deployment of the Chain of Life program 
                      in this region.`}
              </p>
            </div>
          </div>

          <div>
            <a
              target="_parent"
              className="btn"
              href={`${process.env.REACT_APP_API_SERVER_BASE_URL}/${
                currentLanguage === availableLanguages.FR.abbreviation
                  ? "dons-en-ligne"
                  : "en/online-donations"
              }`}
            >
              {currentLanguage === availableLanguages.FR.abbreviation
                ? "Faire un don"
                : "Donate"}
            </a>
          </div>
        </div>
      </div>
    </InfoWindow>
  );
}
