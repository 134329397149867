export default function RegionItem({
  id,
  center,
  zoom,
  name,
  isCurrentRegion,
  onClickHandler,
}) {
  const capitalizeString = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <button
      onClick={() => onClickHandler(id, center, zoom)}
      className={isCurrentRegion ? "is-selected" : ""}
    >
      {capitalizeString(name)}
    </button>
  );
}
