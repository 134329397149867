const boundaries = [
  [-72.9877778, 46.1114532],
  [-72.9739777, 46.0987275],
  [-72.9564383, 46.0867502],
  [-72.9553483, 46.0871953],
  [-72.9545874, 46.0871762],
  [-72.9526218, 46.0863209],
  [-72.9523447, 46.0854813],
  [-72.9536299, 46.0853866],
  [-72.9537335, 46.085233],
  [-72.9448017, 46.0791092],
  [-72.9445724, 46.081398],
  [-72.9448471, 46.0857086],
  [-72.9440228, 46.0899955],
  [-72.9421692, 46.0894775],
  [-72.9432297, 46.0867615],
  [-72.942543, 46.0818672],
  [-72.9436646, 46.080265],
  [-72.9408035, 46.0784225],
  [-72.9409719, 46.0764407],
  [-72.8803072, 46.0341814],
  [-72.8746114, 46.0380515],
  [-72.8683643, 46.0336394],
  [-72.866152, 46.0350578],
  [-72.8618709, 46.0399736],
  [-72.8607209, 46.0430331],
  [-72.8374981, 46.0264015],
  [-72.8296953, 46.0211464],
  [-72.8281976, 46.0323418],
  [-72.8175601, 46.027004],
  [-72.8050018, 46.0159062],
  [-72.7986219, 46.0144471],
  [-72.8021398, 46.0119292],
  [-72.802671, 46.0112135],
  [-72.8035635, 45.9973627],
  [-72.7900555, 45.9919443],
  [-72.8052444, 45.9813216],
  [-72.7918557, 45.9721907],
  [-72.7931049, 45.9713074],
  [-72.7526579, 45.9429163],
  [-72.7937056, 45.9067143],
  [-72.7940975, 45.906553],
  [-72.7942999, 45.9068512],
  [-72.794673, 45.9069075],
  [-72.7958941, 45.9061502],
  [-72.7963656, 45.9055592],
  [-72.7972059, 45.9053575],
  [-72.7982844, 45.905688],
  [-72.7992393, 45.9066206],
  [-72.7999485, 45.906598],
  [-72.800058, 45.9060858],
  [-72.7996928, 45.9054087],
  [-72.7999176, 45.9049512],
  [-72.8002147, 45.904899],
  [-72.8008931, 45.9052721],
  [-72.8013204, 45.9051218],
  [-72.8195354, 45.8926795],
  [-72.8212249, 45.893905],
  [-72.8318566, 45.8865979],
  [-72.833839, 45.8889039],
  [-72.8379899, 45.8909076],
  [-72.8385009, 45.8914374],
  [-72.8504553, 45.883522],
  [-72.8518819, 45.8808017],
  [-72.8539976, 45.8784864],
  [-72.848664, 45.8747499],
  [-72.8530367, 45.8640136],
  [-72.8015124, 45.8281082],
  [-72.784097, 45.8156192],
  [-72.7716674, 45.8063786],
  [-72.7704029, 45.805708],
  [-72.7690879, 45.8043638],
  [-72.7621071, 45.7993451],
  [-72.7041786, 45.7588324],
  [-72.6803382, 45.775797],
  [-72.6461569, 45.7557869],
  [-72.6022906, 45.7922499],
  [-72.5755887, 45.7773977],
  [-72.5781304, 45.7753085],
  [-72.5656142, 45.7676608],
  [-72.5633193, 45.7659331],
  [-72.5780247, 45.752536],
  [-72.4850739, 45.6978507],
  [-72.4435642, 45.6729634],
  [-72.3974779, 45.646056],
  [-72.3756238, 45.632949],
  [-72.3391139, 45.6118273],
  [-72.2976749, 45.5894066],
  [-72.2791463, 45.6072087],
  [-72.2372392, 45.6462396],
  [-72.270709, 45.6656435],
  [-72.2769755, 45.6689824],
  [-72.3112598, 45.6887339],
  [-72.2978637, 45.6996157],
  [-72.2816345, 45.710827],
  [-72.2426169, 45.7448162],
  [-72.2271002, 45.7562153],
  [-72.2239953, 45.7545404],
  [-72.223097, 45.7550589],
  [-72.222738, 45.7544847],
  [-72.2218557, 45.7527734],
  [-72.2213902, 45.7504618],
  [-72.2214788, 45.7491017],
  [-72.2219661, 45.747935],
  [-72.226055, 45.7428386],
  [-72.2268746, 45.7422428],
  [-72.2277509, 45.7416854],
  [-72.2304715, 45.7410352],
  [-72.2344404, 45.7411926],
  [-72.2351938, 45.7409741],
  [-72.2360141, 45.7403404],
  [-72.2362648, 45.7394358],
  [-72.2359459, 45.7382601],
  [-72.2348814, 45.7369629],
  [-72.2327767, 45.735843],
  [-72.2288133, 45.7354588],
  [-72.2248621, 45.7345451],
  [-72.2223143, 45.7351972],
  [-72.2212208, 45.735185],
  [-72.2188201, 45.7344397],
  [-72.2180438, 45.7331455],
  [-72.2170301, 45.7327937],
  [-72.215652, 45.732646],
  [-72.2153141, 45.7323208],
  [-72.2156644, 45.7308502],
  [-72.2125417, 45.7264669],
  [-72.2127176, 45.7250699],
  [-72.2141259, 45.723271],
  [-72.215674, 45.7222676],
  [-72.2231856, 45.7184385],
  [-72.2237603, 45.71784],
  [-72.2239368, 45.7164051],
  [-72.2232852, 45.7146965],
  [-72.2220157, 45.7135858],
  [-72.2201633, 45.7128088],
  [-72.21729, 45.7126443],
  [-72.2165195, 45.7123709],
  [-72.2146831, 45.7108948],
  [-72.2128936, 45.7061295],
  [-72.2124491, 45.705444],
  [-72.2103011, 45.7037751],
  [-72.2098562, 45.7031083],
  [-72.2093188, 45.7014576],
  [-72.2096294, 45.6992113],
  [-72.2093178, 45.698376],
  [-72.2083039, 45.6974382],
  [-72.2063388, 45.6963874],
  [-72.1879187, 45.7130135],
  [-72.1512718, 45.7453052],
  [-72.1504735, 45.7462387],
  [-72.1493092, 45.7470173],
  [-72.111367, 45.7802805],
  [-72.061503, 45.8254994],
  [-72.0296451, 45.853415],
  [-72.0026639, 45.8387803],
  [-71.9375076, 45.8050594],
  [-71.9212721, 45.7963418],
  [-71.9200805, 45.7966511],
  [-71.9178398, 45.7981283],
  [-71.9176103, 45.7990622],
  [-71.9178792, 45.8008958],
  [-71.9166237, 45.8019028],
  [-71.9090291, 45.8037204],
  [-71.9059238, 45.8047048],
  [-71.9046686, 45.8048209],
  [-71.9020493, 45.8043901],
  [-71.9003173, 45.8038477],
  [-71.893434, 45.8010029],
  [-71.8911565, 45.8007287],
  [-71.8886269, 45.8014237],
  [-71.8848278, 45.8041371],
  [-71.8836992, 45.8044075],
  [-71.8826527, 45.8042793],
  [-71.8805389, 45.8044256],
  [-71.8800712, 45.8041293],
  [-71.8799327, 45.8028628],
  [-71.8790627, 45.8011811],
  [-71.8744711, 45.7983511],
  [-71.8694414, 45.8031932],
  [-71.8614426, 45.798568],
  [-71.856916, 45.8034177],
  [-71.8047755, 45.8519843],
  [-71.7980847, 45.858836],
  [-71.788016, 45.8679831],
  [-71.7699647, 45.8852954],
  [-71.7297882, 45.8644774],
  [-71.7241354, 45.8613313],
  [-71.7240142, 45.8614443],
  [-71.6940039, 45.8455654],
  [-71.6813894, 45.8384082],
  [-71.6355207, 45.8139117],
  [-71.6204277, 45.8277048],
  [-71.6193728, 45.829007],
  [-71.6173046, 45.8304973],
  [-71.6032079, 45.8228298],
  [-71.6002379, 45.8254246],
  [-71.5928436, 45.8218116],
  [-71.5931807, 45.8212633],
  [-71.5930774, 45.8205895],
  [-71.5915978, 45.8192899],
  [-71.5902726, 45.8185217],
  [-71.5942751, 45.815395],
  [-71.5415558, 45.7878052],
  [-71.522464, 45.804399],
  [-71.4979688, 45.7918856],
  [-71.4592087, 45.8181206],
  [-71.4663615, 45.8216651],
  [-71.4609292, 45.8448211],
  [-71.4554472, 45.8720996],
  [-71.4984606, 45.8938167],
  [-71.5016653, 45.8908632],
  [-71.5514049, 45.9161063],
  [-71.5621398, 45.9060419],
  [-71.617587, 45.9333658],
  [-71.6861159, 45.9667104],
  [-71.6733126, 45.979331],
  [-71.6520289, 45.9995024],
  [-71.6453557, 46.0062336],
  [-71.6451077, 46.0062377],
  [-71.6412217, 46.010075],
  [-71.6397869, 46.0108791],
  [-71.6392933, 46.0120563],
  [-71.6293743, 46.0218406],
  [-71.6129573, 46.0376767],
  [-71.6117432, 46.0383453],
  [-71.6111966, 46.0394211],
  [-71.605401, 46.0447871],
  [-71.6010061, 46.0495122],
  [-71.5991244, 46.0509882],
  [-71.5943857, 46.055493],
  [-71.5688754, 46.0805122],
  [-71.5672578, 46.0817779],
  [-71.5526343, 46.0960593],
  [-71.5516293, 46.0970903],
  [-71.5518327, 46.0973666],
  [-71.5504635, 46.098031],
  [-71.5492542, 46.0989735],
  [-71.5415294, 46.1070761],
  [-71.5365476, 46.1115638],
  [-71.5330967, 46.1148602],
  [-71.533089, 46.1152658],
  [-71.5096991, 46.1373497],
  [-71.5146631, 46.1397781],
  [-71.5148835, 46.1396571],
  [-71.5338858, 46.1491513],
  [-71.5231174, 46.1599711],
  [-71.5294772, 46.1631344],
  [-71.5178068, 46.17402],
  [-71.5065066, 46.1851108],
  [-71.4946768, 46.1959186],
  [-71.4879208, 46.2026255],
  [-71.4832512, 46.2066414],
  [-71.4879822, 46.2090711],
  [-71.4870341, 46.2116653],
  [-71.4854536, 46.2137668],
  [-71.4739184, 46.2243091],
  [-71.466821, 46.2311942],
  [-71.4454879, 46.220213],
  [-71.4345827, 46.23083],
  [-71.419272, 46.223295],
  [-71.3897488, 46.2514685],
  [-71.3885226, 46.2523959],
  [-71.3717866, 46.2680993],
  [-71.3992084, 46.2820987],
  [-71.4134181, 46.2897271],
  [-71.445555, 46.3064256],
  [-71.4660786, 46.3166458],
  [-71.4857171, 46.3270589],
  [-71.5084931, 46.3383895],
  [-71.4814287, 46.3560119],
  [-71.4949261, 46.3659034],
  [-71.4941058, 46.3664681],
  [-71.5081419, 46.3765781],
  [-71.4890922, 46.3888936],
  [-71.4789828, 46.3958651],
  [-71.4919452, 46.4054403],
  [-71.4919571, 46.4056488],
  [-71.4983892, 46.4103163],
  [-71.4995057, 46.4109094],
  [-71.5314083, 46.4343871],
  [-71.5662243, 46.4136816],
  [-71.5758024, 46.4207158],
  [-71.5760175, 46.4204773],
  [-71.5919475, 46.4319357],
  [-71.6176434, 46.4516703],
  [-71.669253, 46.4196084],
  [-71.7150936, 46.3935087],
  [-71.7601579, 46.3637874],
  [-71.7835491, 46.3804973],
  [-71.7892187, 46.3848499],
  [-71.7890656, 46.3849476],
  [-71.7952005, 46.3893993],
  [-71.8498023, 46.4282301],
  [-71.9057145, 46.4685219],
  [-71.9285568, 46.4847476],
  [-71.9733033, 46.5159799],
  [-72, 46.5353569],
  [-72.0118133, 46.5436668],
  [-72.0134064, 46.5426567],
  [-72.028406, 46.5529661],
  [-72.029647, 46.5532226],
  [-72.0307306, 46.5526968],
  [-72.0317882, 46.5531332],
  [-72.0325909, 46.5538363],
  [-72.0330842, 46.5553721],
  [-72.0339248, 46.5556348],
  [-72.0354938, 46.5569414],
  [-72.0376639, 46.5572391],
  [-72.035617, 46.557835],
  [-72.0543195, 46.5705103],
  [-72.0535423, 46.5723017],
  [-72.0611802, 46.5715233],
  [-72.0814437, 46.5700255],
  [-72.0829904, 46.570168],
  [-72.1345313, 46.5636107],
  [-72.1719397, 46.5489729],
  [-72.186126, 46.5402843],
  [-72.2126848, 46.5209849],
  [-72.2169096, 46.5169825],
  [-72.220243, 46.5132603],
  [-72.2247154, 46.5066769],
  [-72.2260766, 46.5039548],
  [-72.2283268, 46.4985103],
  [-72.2330495, 46.4853159],
  [-72.2422171, 46.4569826],
  [-72.2443297, 46.4523175],
  [-72.2481342, 46.4473994],
  [-72.2521345, 46.4435105],
  [-72.254829, 46.441205],
  [-72.2586347, 46.4384827],
  [-72.2639405, 46.4354271],
  [-72.2698018, 46.4328438],
  [-72.2775798, 46.4301771],
  [-72.2921081, 46.4263993],
  [-72.3004972, 46.424816],
  [-72.3085252, 46.4236493],
  [-72.3153865, 46.4229272],
  [-72.3255535, 46.422316],
  [-72.3329425, 46.4213993],
  [-72.341554, 46.4195937],
  [-72.3787221, 46.4101492],
  [-72.3821667, 46.4088992],
  [-72.3872504, 46.4065381],
  [-72.3935562, 46.4029826],
  [-72.4018898, 46.3991215],
  [-72.4234462, 46.3913992],
  [-72.4305576, 46.3890659],
  [-72.4355022, 46.3879548],
  [-72.4430302, 46.3867326],
  [-72.4499193, 46.3851492],
  [-72.4570863, 46.3826214],
  [-72.4638088, 46.3793714],
  [-72.4683367, 46.3767881],
  [-72.4755036, 46.3719269],
  [-72.4833928, 46.3656214],
  [-72.4907543, 46.3585103],
  [-72.4956156, 46.3545936],
  [-72.5015048, 46.3511215],
  [-72.5163944, 46.3445383],
  [-72.5223948, 46.3413161],
  [-72.5287006, 46.3370106],
  [-72.5307563, 46.335344],
  [-72.5337843, 46.3323719],
  [-72.5393401, 46.3254275],
  [-72.5418403, 46.3227886],
  [-72.5551895, 46.3108383],
  [-72.5592904, 46.3075182],
  [-72.5617225, 46.3051534],
  [-72.5682266, 46.3007952],
  [-72.5764529, 46.2960668],
  [-72.5822031, 46.2932614],
  [-72.5915924, 46.2894836],
  [-72.6080931, 46.2842337],
  [-72.6237604, 46.2787892],
  [-72.6839852, 46.2559838],
  [-72.6945524, 46.2528233],
  [-72.7056726, 46.2482364],
  [-72.7324967, 46.2341643],
  [-72.7910019, 46.2066101],
  [-72.8720659, 46.1807899],
  [-72.958076, 46.1510119],
  [-72.9568259, 46.1411289],
  [-72.9572832, 46.132556],
  [-72.9612754, 46.1205189],
  [-72.9660246, 46.1154443],
  [-72.9759754, 46.1113226],
  [-72.9877778, 46.1114532],
];

let polygons = [];
boundaries.map((boundary) =>
  polygons.push({ lat: boundary[1], lng: boundary[0] })
);

export default polygons